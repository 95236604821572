import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getAvailableCountries } from 'Impact/utils/parseStatisticsData';
import { FILTER_TYPES } from 'Common/components/filter/constants/filterConstants';

const useImpactFiltersData = data => {
	const { t } = useTranslation();
	const filtersData = useMemo(
		() => [
			{
				name: 'country',
				label: t('Country'),
				type: FILTER_TYPES.SELECT,
				options: getAvailableCountries(data),
			},
		],
		[data, t],
	);

	return filtersData;
};

export default useImpactFiltersData;
