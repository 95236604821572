const SIDE_PANEL_CONTENT_TYPES = {
	NOTIFICATIONS: 'notifications',
	DASHBOARD_PROJECT: 'dashboardProject',
	DASHBOARD_STEP: 'dashboardStep',
	PROJECT_COMMENTS: 'projectComments',
	PROJECT_STEP: 'projectStep',
	ENTITY_COMMENTS: 'entityComments',
	SIDE_PANEL_TILES: 'sidePanelTiles',
};

const NO_HEADER_CONTENT_TYPES = [SIDE_PANEL_CONTENT_TYPES.NOTIFICATIONS];

const NO_PADDING_CONTENT_TYPES = [SIDE_PANEL_CONTENT_TYPES.NOTIFICATIONS];

export { SIDE_PANEL_CONTENT_TYPES, NO_HEADER_CONTENT_TYPES, NO_PADDING_CONTENT_TYPES };
