import { pick, sumBy } from 'lodash';

const convertFormValuesToRequestData = values => ({
	...pick(values, ['name', 'maximumVolume', 'minimumVolume', 'externalId']),
	systemSize: sumBy(values.financeDetailsProjects, 'systemSizeAllocatedValue'),
	assignProjectsToFinanceDto: {
		financeDetailsProjects: values.financeDetailsProjects,
	},
	projectIds: values.projects && values.projects.map(e => e.value),
});

export default convertFormValuesToRequestData;
