import { components } from 'react-select';
import { PropTypes } from 'prop-types';

const IndustrySelectValue = props => (
	<>
		<components.SingleValue {...props}>
			{props?.hasValue ? (
				<>
					<span>
						<b>{props?.getValue()[0]?.marketSegment?.name}</b>
					</span>
					&nbsp;
					<span>{props.getValue()[0].label}</span>
				</>
			) : (
				''
			)}
		</components.SingleValue>
	</>
);

IndustrySelectValue.defaultProps = {
	getValue: () => null,
	hasValue: false,
};

IndustrySelectValue.propTypes = {
	getValue: PropTypes.func,
	hasValue: PropTypes.bool,
};

export default IndustrySelectValue;
