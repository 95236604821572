import { Fragment, memo, useCallback, useEffect, useRef, useState, useLayoutEffect } from 'react';
import { css } from 'styled-components';
import Icon from 'Common/components/icons/Icon';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { fill, isEmpty, omit } from 'lodash';
import { FixedSizeList } from 'react-window';
import { useFormik } from 'formik';
import InfiniteLoader from 'react-window-infinite-loader';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

import Button from 'Common/components/buttons/Button';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import useSetQueryParameter from 'Common/hooks/useSetQueryParameter';
import showToastError from 'Common/utils/showToastError';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const TableWrapper = styled.div``;

const StyledTable = styled.div`
	min-width: initial !important;
`;

const StyledLink = styled(Link)`
	color: #0d2220;
	text-decoration: none;
`;

const StyledP = styled.p`
	color: #0d2220;
	padding: 0;
	margin: 0;
`;

export const Table = ({ getTableProps, children, ...props }) => (
	<TableWrapper>
		<StyledTable {...props} {...getTableProps()}>
			{children}
		</StyledTable>
	</TableWrapper>
);

Table.propTypes = {
	getTableProps: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
};

export const TableHead = styled.div``;

export const TableHeadRow = styled.div`
	overflow-x: hidden;
	z-index: 1;
	gap: ${props => (props.noGap ? '0px' : '12px')};
	& > :first-child {
		padding-left: 16px;
	}
`;

const SortArrowWrapper = styled.span`
	display: ${({ $isHidden }) => ($isHidden ? 'none' : 'inline-block')};
	vertical-align: middle;

	svg {
		display: block;
	}
`;

export const TableHeadCell = styled.div`
	position: relative;
	min-width: 60px;
	margin: 0;
	font-size: 14px;
	line-height: 22.5px;
	padding: 8px 2px;
	color: ${colors.text.grey};

	&:after {
		content: '';
		position: absolute;
		top: calc(100% - 1px);
		left: 0;
		right: -12px;
		height: 1px;
		background-color: ${colors.common.lightGrey};
	}

	&:last-child {
		&:before {
			right: 0;
		}
		&:after {
			right: 0;
		}
	}

	&:hover {
		color: ${colors.text.primary};

		// ! The best way (so far) to know if a column is sortable is to check if it has set cursor pointer
		// ! The ideal way would be to have a isSortable prop on the column, that currently doesn't exist
		// ! from react-table, and thus will require some effort to add it
		${({ style }) =>
			style?.cursor === 'pointer' &&
			css`
				${SortArrowWrapper} {
					display: inline-block;
				}
			`}
	}
`;

export const TableHeadCellContent = styled.div`
	font-weight: 700;
	white-space: nowrap;
	overflow: hidden;
	padding-right: 10px;
	display: flex;

	span {
		vertical-align: middle;
	}
`;

export const TableBody = styled.div`
	overflow: auto;
	transition: height 0.3s ease-in-out;
`;

export const TableBodyCell = styled.div`
	position: relative;
	min-width: 60px;
	margin: 0;
	padding-left: 2px;
	font-size: 14px;
	line-height: 17px;
	display: inline-flex !important;
	align-items: center;
	height: 68px;
	color: ${colors.secondary.light};

	&:before {
		content: '';
		position: absolute;
		display: none;
		top: 0;
		left: 100%;
		right: -12px;
		height: 100%;
		background-color: ${colors.common.lightGrey};
	}

	&:after {
		content: '';
		position: absolute;
		top: calc(100% - 1px);
		left: 0;
		right: -12px;
		height: 1px;
		background-color: ${colors.common.lightGrey};
	}

	&:last-child {
		&:before {
			right: 0;
		}
		&:after {
			right: 0;
		}
	}
`;

export const TableBodyCellContent = styled.div`
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;

export const TableBodyCellWithEditableField = styled(TableBodyCell)``;

export const TableBodyGroupCell = styled(TableHeadCell)``;

export const TableBodyGroupCellContent = styled(TableHeadCellContent)`
	/* color: ${colors.text.black};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px; */
`;

export const TableBodyRow = styled.div`
	height: 68px;
	gap: ${props => (props.noGap ? '0px' : '12px')};

	&:hover {
		.cell-content .more-actions-button.controlledVisibility {
			visibility: visible;
			opacity: 1;
		}
	}

	${({ $rowDisabled }) =>
		$rowDisabled &&
		css`
			cursor: not-allowed;

			* {
				color: ${colors.text.grey} !important;
				text-decoration: none;
			}

			${TableBodyCell} {
				background-color: ${colors.common.tileBackgroundGrey} !important;
				&:before {
					display: block;
					background-color: ${colors.common.tileBackgroundGrey} !important;
				}
			}
		`}

	${props =>
		props.$isOnHold &&
		css`
			> div {
				background-color: rgb(253, 236, 236) !important;
				&:before {
					display: block;
					background-color: rgb(253, 236, 236) !important;
				}
			}
		`}

	
			&:hover {
		> div {
			background-color: ${colors.common.lightGrey};
			&:before {
				display: block;
				background-color: ${({ $isInEditMode }) =>
					$isInEditMode ? colors.grey.lightest : colors.common.lightGrey};
			}
		}
	}

	& > :first-child {
		padding-left: 16px;
	}
`;

export const EditableTableBodyRow = styled(TableBodyRow)`
	${TableBodyCell} {
		align-items: stretch;
		padding: 0;
		border-right: 1px solid ${colors.common.lightGrey};
		background: ${colors.grey.lightGrey};
		&:before {
			right: -15px;
		}
	}

	div:nth-last-child(3) {
		border-right: none;
	}

	& > :last-child {
		&:hover {
			background-color: transparent !important;
		}
	}

	${TableBodyCellContent} {
		width: 100%;
	}

	&:hover {
		> div {
			background: ${colors.grey.lightest};
		}
	}
`;

export const TableFooter = styled.div`
	overflow: auto;
	background: ${colors.grey.light};
`;

export const TableFooterRow = styled.div`
	overflow-x: hidden;
	gap: ${props => (props.noGap ? '0px' : '12px')};
`;

export const TableFooterCell = styled.div`
	min-width: 60px;
	padding: 8px 0px;
	font-size: 14px;
	line-height: 17px;
	position: relative;
	color: ${colors.text.primary};

	&:first-child {
		padding-left: 16px;
	}
`;

export const TableFooterCellContent = styled.div`
	font-weight: 400;
	white-space: pre;
	line-height: 17px;
`;

const SortArrow = ({ column, isHidden }) => (
	<SortArrowWrapper $isHidden={isHidden}>
		{column.isSortedDesc || isHidden ? <Icon icon="arrowDropDown" /> : <Icon icon="arrowDropUp" />}
	</SortArrowWrapper>
);

SortArrow.propTypes = {
	column: PropTypes.shape({
		isSortedDesc: PropTypes.bool,
	}).isRequired,
	isHidden: PropTypes.bool.isRequired,
};

const DefaultTableHeadCell = ({ column, before, after, ...props }) => (
	<TableHeadCell
		{...(column.getSortByToggleProps
			? column.getHeaderProps(column.getSortByToggleProps())
			: column.getHeaderProps())}
		{...props}
	>
		<TableHeadCellContent {...column.getHeaderCellContentProps()}>
			{before}
			{column.render('Header')}
			<SortArrow column={column} isHidden={!column.isSorted} />
			{after}
		</TableHeadCellContent>
	</TableHeadCell>
);

DefaultTableHeadCell.defaultProps = {
	before: null,
	after: null,
};

DefaultTableHeadCell.propTypes = {
	column: PropTypes.shape({
		getSortByToggleProps: PropTypes.func,
		getHeaderProps: PropTypes.func,
		getHeaderCellContentProps: PropTypes.func,
		render: PropTypes.func,
		isSorted: PropTypes.bool,
	}).isRequired,
	before: PropTypes.node,
	after: PropTypes.node,
};

export { DefaultTableHeadCell };

const DefaultTableHeadRow = ({ headerGroup, before, after, ...props }) => (
	<TableHeadRow {...headerGroup.getHeaderGroupProps()} {...props}>
		{before}
		{headerGroup.headers.map(column => (
			<DefaultTableHeadCell key={column.getHeaderProps().key} column={column} />
		))}
		{after}
	</TableHeadRow>
);

DefaultTableHeadRow.defaultProps = {
	before: null,
	after: null,
};

DefaultTableHeadRow.propTypes = {
	headerGroup: PropTypes.shape({
		getHeaderCellContentProps: PropTypes.func,
		headers: PropTypes.arrayOf(PropTypes.shape({})),
		getHeaderGroupProps: PropTypes.func,
	}).isRequired,
	before: PropTypes.node,
	after: PropTypes.node,
};

export { DefaultTableHeadRow };

const DefaultTableHead = ({ getTableHeaderProps, customHeader, headerGroups, before, after, ...props }) => (
	<TableHead {...getTableHeaderProps()} {...props}>
		<ScrollSyncPane group="one">
			<>
				{before}
				{customHeader
					? customHeader
					: headerGroups.map(headerGroup => (
							<DefaultTableHeadRow
								noGap={props.noGap}
								key={headerGroup.getHeaderGroupProps().key}
								headerGroup={headerGroup}
							/>
					  ))}
				{after}
			</>
		</ScrollSyncPane>
	</TableHead>
);

DefaultTableHead.defaultProps = {
	noGap: false,
	customHeader: null,
	before: null,
	after: null,
};

DefaultTableHead.propTypes = {
	headerGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	getTableHeaderProps: PropTypes.func.isRequired,
	noGap: PropTypes.bool,
	customHeader: PropTypes.node,
	before: PropTypes.node,
	after: PropTypes.node,
};

export { DefaultTableHead };

const DefaultTableBodyCell = ({ cell, before, after, className, ...props }) => {
	const Component =
		cell.row.isInEditMode && Boolean(cell.column.EditableField)
			? TableBodyCellWithEditableField
			: TableBodyCell;

	return (
		<Component {...cell.getCellProps()} className={className}>
			<TableBodyCellContent className={'cell-content'} {...cell.getCellContentProps()}>
				{before}
				{cell.render('Cell', props)}
				{after}
			</TableBodyCellContent>
		</Component>
	);
};

DefaultTableBodyCell.defaultProps = {
	className: '',
	before: null,
	after: null,
};

DefaultTableBodyCell.propTypes = {
	cell: PropTypes.shape({
		row: PropTypes.shape({ isInEditMode: PropTypes.bool }),
		column: PropTypes.shape({ EditableField: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]) }),
		getCellContentProps: PropTypes.func,
		render: PropTypes.func,
		getCellProps: PropTypes.func,
	}).isRequired,
	before: PropTypes.node,
	after: PropTypes.node,
	className: PropTypes.string,
};

export { DefaultTableBodyCell };

const DefaultTableBodyGroupRow = ({ bodyGroup, rowSample, prepareRow, ...props }) => {
	const [isPopupShown, setPopupShown] = useState(false);
	prepareRow(rowSample);

	const minWidthSum = rowSample.cells.reduce(
		(sum, cell) => sum + parseInt(cell.getCellProps().style.minWidth),
		0,
	);

	const widthSum = rowSample.cells.reduce((sum, cell) => sum + parseInt(cell.getCellProps().style.width), 0);

	const handleMouseOver = () => {
		setPopupShown(true);
	};

	const handleMouseLeave = () => {
		setPopupShown(false);
	};

	const { name, linkTo, Popup } = bodyGroup;

	return (
		<TableHeadRow
			style={{
				...rowSample.getRowProps().style,
				minWidth: widthSum,
				backgroundColor: '#F2F2F2',
			}}
			onMouseEnter={handleMouseOver}
			onMouseLeave={handleMouseLeave}
			{...props}
		>
			<TableBodyGroupCell
				style={{
					minWidth: minWidthSum,
					width: widthSum,
					flex: `${widthSum} 0 auto`,
				}}
			>
				<TableBodyGroupCellContent>
					{linkTo ? (
						<StyledLink color="#00b4d5" to={linkTo}>
							{name}
						</StyledLink>
					) : (
						<StyledP>{name}</StyledP>
					)}
				</TableBodyGroupCellContent>
			</TableBodyGroupCell>
			{isPopupShown && Popup && <Popup />}
		</TableHeadRow>
	);
};

DefaultTableBodyGroupRow.propTypes = {
	bodyGroup: PropTypes.shape({
		name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		linkTo: PropTypes.string,
		Popup: PropTypes.func,
	}).isRequired,
	rowSample: PropTypes.shape({
		cells: PropTypes.arrayOf(PropTypes.shape({})),
		getRowProps: PropTypes.func,
	}).isRequired,
	prepareRow: PropTypes.func.isRequired,
};

export { DefaultTableBodyGroupRow };

const DefaultTableBodyRow = ({ row, getCustomRowProps, before, after, ...props }) => (
	<TableBodyRow {...(getCustomRowProps ? getCustomRowProps(row) : row.getRowProps())} {...props}>
		{before}
		{row.cells.map(cell =>
			row.original.isLoading ? (
				<TableBodyCell key={cell.getCellProps().key} {...cell.getCellProps()}>
					<TableBodyCellContent className={'cell-content'} {...cell.getCellContentProps()}>
						<DataLoader />
					</TableBodyCellContent>
				</TableBodyCell>
			) : (
				<DefaultTableBodyCell key={cell.getCellProps().key} cell={cell} noGap={props.noGap} />
			),
		)}
		{after}
	</TableBodyRow>
);

DefaultTableBodyRow.defaultProps = {
	getCustomRowProps: null,
	noGap: false,
	before: null,
	after: null,
};

DefaultTableBodyRow.propTypes = {
	row: PropTypes.shape({
		getRowProps: PropTypes.func,
		original: PropTypes.shape({
			isLoading: PropTypes.bool,
		}),
		cells: PropTypes.arrayOf(PropTypes.shape({})),
	}).isRequired,
	getCustomRowProps: PropTypes.func,
	before: PropTypes.node,
	after: PropTypes.node,
	noGap: PropTypes.bool,
};

export { DefaultTableBodyRow };

const CancelAndSaveButtons = styled.div`
	position: absolute;
	display: flex;
	transform: translateY(calc(100% + 10px));
	z-index: 1;
	padding: 6px;
	right: 35px;
	background: transparent !important;
	> * {
		margin-right: 4px;

		&:last-child {
			margin-right: 16px;
		}
	}
`;

const DefaultEditableTableBodyRow = ({ row, getCustomRowProps, before, after, ...props }) => {
	const [isSaving, setIsSaving] = useState(false);
	const { t } = useTranslation();

	const handleSubmit = async values => {
		try {
			setIsSaving(true);

			const result = await row.editableRowFormikOptions.onSubmit(
				{ ...row, isNew: Boolean(row.original.__forCreation) },
				values,
			);
			setIsSaving(false);
			row.turnOffRowEditMode();

			return result;
		} catch (e) {
			showToastError(e, t("Can't submit table row"));
		}
	};

	const formik = useFormik({
		initialValues: row.getEditableRowInitialValues(),
		onSubmit: handleSubmit,
		...omit(row.editableRowFormikOptions, ['onSubmit']),
	});

	return (
		<EditableTableBodyRow {...(getCustomRowProps ? getCustomRowProps(row) : row.getRowProps())} {...props}>
			{before}
			{row.cells.map(cell => (
				<DefaultTableBodyCell key={cell.getCellProps().key} cell={cell} formik={formik} />
			))}
			{after}
			<CancelAndSaveButtons>
				<Button
					small
					secondary
					overlay
					icon="close"
					disabled={isSaving}
					data-action="cancel"
					style={{ background: 'transparent !important' }}
					{...row.getTurnOffRowEditModeProps()}
					label="Table Edit - Cancel Button"
				/>
				<Button
					small
					overlay
					icon="check"
					type="submit"
					onClick={formik.handleSubmit}
					isLoading={isSaving}
					data-action="submit"
					label="Table Edit - Save Button"
				/>
			</CancelAndSaveButtons>
		</EditableTableBodyRow>
	);
};

DefaultEditableTableBodyRow.defaultProps = {
	before: null,
	after: null,
	getCustomRowProps: () => {},
};

DefaultEditableTableBodyRow.propTypes = {
	row: PropTypes.shape({
		editableRowFormikOptions: PropTypes.shape({
			onSubmit: PropTypes.func,
		}),
		original: PropTypes.shape({
			__forCreation: PropTypes.bool,
		}),
		turnOffRowEditMode: PropTypes.func,
		getEditableRowInitialValues: PropTypes.func,
		getRowProps: PropTypes.func,
		getTurnOffRowEditModeProps: PropTypes.func,
		cells: PropTypes.arrayOf(PropTypes.shape({})),
	}).isRequired,
	getCustomRowProps: PropTypes.func,
	before: PropTypes.node,
	after: PropTypes.node,
};

export { DefaultEditableTableBodyRow };

const renderBodyRow = (row, getCustomRowProps = null, props) => {
	const Component = row.isInEditMode ? DefaultEditableTableBodyRow : DefaultTableBodyRow;

	return (
		<Component
			key={`${row.getRowProps().key}_fragment`}
			row={row}
			getCustomRowProps={getCustomRowProps}
			// Specific for projects
			$isOnHold={row?.original?.onHold || row?.original?.condition?.condition === 'ON_HOLD'}
			noGap={props ? props.noGap : false}
			$rowDisabled={row.original.$rowDisabled}
			$isInEditMode={row.isInEditMode}
		/>
	);
};

const renderBodyRows = (rows, prepareRow, props) =>
	rows.map(row => {
		prepareRow(row);

		return renderBodyRow(row, null, props);
	});

const DefaultTableBody = ({ rows, bodyGroups, getTableBodyProps, prepareRow, before, after, ...props }) => {
	const bodyGroupIds = bodyGroups.map(bodyGroup => bodyGroup.id);
	const rowsWithoutBodyGroups = rows.filter(
		row =>
			// No body groups
			!row.original.bodyGroupIds ||
			row.original.bodyGroupIds.length === 0 ||
			//
			// Has body groups, but none of them are matched
			!row.original.bodyGroupIds.some(bodyGroupId => bodyGroupIds.includes(bodyGroupId)),
	);

	const bodyGroupsWithRows = bodyGroups
		.map(bodyGroup => ({
			...bodyGroup,
			rows: rows.filter(row => row.original.bodyGroupIds && row.original.bodyGroupIds.includes(bodyGroup.id)),
		}))
		.filter(bodyGroup => bodyGroup.rows.length > 0);
	return (
		<TableBody {...getTableBodyProps()} {...props}>
			{before}
			{renderBodyRows(rowsWithoutBodyGroups, prepareRow, props)}
			{bodyGroupsWithRows.map(bodyGroup => (
				<Fragment key={bodyGroup.id}>
					<DefaultTableBodyGroupRow
						bodyGroup={bodyGroup}
						rowSample={{ ...bodyGroup.rows[0] }}
						prepareRow={prepareRow}
					/>
					{renderBodyRows(bodyGroup.rows, prepareRow, props)}
				</Fragment>
			))}
			{after}
		</TableBody>
	);
};

DefaultTableBody.defaultProps = {
	before: null,
	after: null,
};

DefaultTableBody.propTypes = {
	rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	bodyGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	getTableBodyProps: PropTypes.func.isRequired,
	prepareRow: PropTypes.func.isRequired,
	before: PropTypes.node,
	after: PropTypes.node,
};

export { DefaultTableBody };

const RawDataLoader = () => (
	<ContentLoader width="60%" height={11} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
		<rect x="0" y="0" rx="5" ry="5" width="100%" height="11" />
	</ContentLoader>
);

const DataLoader = memo(RawDataLoader);

const VirtualizedTableBody = ({
	rows,
	headRef,
	getTableBodyProps,
	prepareRow,
	totalRowsCount,
	rowHeight,
	rowsOverscanCount,
	rowsHash,
	loadMoreRows,
	before,
	after,
	...props
}) => {
	const infiniteLoaderRef = useRef(null);
	const hasMountedRef = useRef(false);

	const ref = useRef();
	const registerRef = useCallback(node => {
		ref.current = node;
	}, []);

	const offsetQueryParam = useQueryParameter('offset');
	const setOffsetQueryParam = useSetQueryParameter('offset');

	useEffect(() => {
		if (!hasMountedRef.current && offsetQueryParam) {
			ref?.current?.scrollToItem(offsetQueryParam, 'start');
		}
	}, [offsetQueryParam]);

	const handleScroll = ({ scrollOffset, scrollUpdateWasRequested }) => {
		if (!scrollUpdateWasRequested && hasMountedRef.current) {
			const offsetItem = Math.round(scrollOffset / rowHeight);

			setOffsetQueryParam(offsetItem > 0 ? offsetItem : null);
		}
	};

	// Reset the horizontal scroll position if there are no resuls in the table
	useEffect(() => {
		if (
			totalRowsCount === 0 &&
			headRef.current &&
			headRef.current.querySelector('div[role="row"]').scrollLeft !== undefined
		) {
			headRef.current.querySelector('div[role="row"]').scrollLeft = 0;
		}
	}, [headRef, totalRowsCount]);

	// Each time the hash is changed we called the method resetloadMoreItemsCache to clear the cache
	useEffect(() => {
		// This effect will run on mount too; there's no need to reset in that case.
		if (hasMountedRef.current) {
			setOffsetQueryParam(null);

			if (infiniteLoaderRef.current) {
				infiniteLoaderRef.current.resetloadMoreItemsCache();
			}
		}

		hasMountedRef.current = true;
	}, [rowsHash, setOffsetQueryParam]);

	const isItemLoaded = useCallback(
		index => Boolean(rows[index]) && Boolean(rows[index].original) && !isEmpty(rows[index].original),
		[rows],
	);

	const RenderRow = useCallback(
		({ index, style }) => {
			const row = rows[index];

			prepareRow(row);

			const getRowProps = () => row.getRowProps({ style });

			if (isItemLoaded(index)) {
				return renderBodyRow(row, getRowProps);
			}

			return (
				<TableBodyRow {...getRowProps()}>
					{row.cells.map(cell => (
						<TableBodyCell key={cell.getCellProps().key} {...cell.getCellProps()}>
							<TableBodyCellContent className={'cell-content'} {...cell.getCellContentProps()}>
								<DataLoader />
							</TableBodyCellContent>
						</TableBodyCell>
					))}
				</TableBodyRow>
			);
		},
		[isItemLoaded, prepareRow, rows],
	);

	const height = getTableBodyProps().style.height ?? window.innerHeight;

	return (
		<TableBody
			{...getTableBodyProps({
				style: {
					height: 'auto',
					overflow: 'visible',
				},
			})}
			{...props}
		>
			{before}
			<InfiniteLoader
				ref={infiniteLoaderRef}
				isItemLoaded={isItemLoaded}
				itemCount={totalRowsCount}
				loadMoreItems={loadMoreRows}
			>
				{({ onItemsRendered, ref: registerRefForInfiniteLoader }) => (
					<ScrollSyncPane group="one">
						<FixedSizeList
							ref={node => {
								registerRef(node);
								registerRefForInfiniteLoader(node);
							}}
							height={height}
							itemCount={totalRowsCount}
							itemSize={rowHeight}
							onItemsRendered={onItemsRendered}
							overscanCount={rowsOverscanCount}
							initialScrollOffset={rowHeight && offsetQueryParam ? rowHeight * offsetQueryParam : 0}
							onScroll={handleScroll}
						>
							{RenderRow}
						</FixedSizeList>
					</ScrollSyncPane>
				)}
			</InfiniteLoader>
			{after}
		</TableBody>
	);
};

VirtualizedTableBody.defaultProps = {
	rowHeight: 68,
	rowsOverscanCount: 20,
	totalRowsCount: 20,
	before: null,
	after: null,
	rowsHash: '',
	loadMoreRows: () => {},
};

VirtualizedTableBody.propTypes = {
	rows: PropTypes.arrayOf(
		PropTypes.shape({
			original: PropTypes.shape({}),
			getRowProps: PropTypes.func,
			cells: PropTypes.arrayOf(PropTypes.shape({})),
		}),
	).isRequired,
	getTableBodyProps: PropTypes.func.isRequired,
	prepareRow: PropTypes.func.isRequired,
	totalRowsCount: PropTypes.number,
	rowHeight: PropTypes.number,
	rowsOverscanCount: PropTypes.number,
	rowsHash: PropTypes.string,
	loadMoreRows: PropTypes.func,
	headRef: PropTypes.shape({
		current: PropTypes.shape({
			querySelector: PropTypes.func,
		}),
	}).isRequired,
	before: PropTypes.node,
	after: PropTypes.node,
};

export { VirtualizedTableBody };

const DefaultTableFooterCell = ({ column, before, after, ...props }) => (
	<TableFooterCell {...column.getFooterProps()} {...props}>
		<TableFooterCellContent {...column.getFooterCellContentProps()}>
			{before}
			{column.render('Footer')}
			{after}
		</TableFooterCellContent>
	</TableFooterCell>
);

DefaultTableFooterCell.defaultProps = {
	before: null,
	after: null,
};

DefaultTableFooterCell.propTypes = {
	column: PropTypes.shape({
		getFooterCellContentProps: PropTypes.func,
		render: PropTypes.func,
		getFooterProps: PropTypes.func,
	}).isRequired,
	before: PropTypes.node,
	after: PropTypes.node,
};

export { DefaultTableFooterCell };

const DefaultTableFooterRow = ({ footerGroup, before, after, ...props }) => (
	<TableFooterRow {...footerGroup.getFooterGroupProps()} {...props}>
		{before}
		{footerGroup.headers.map(column => (
			<DefaultTableFooterCell key={column.getFooterProps().key} column={column} />
		))}
		{after}
	</TableFooterRow>
);

DefaultTableFooterRow.defaultProps = {
	before: null,
	after: null,
};

DefaultTableFooterRow.propTypes = {
	footerGroup: PropTypes.shape({
		getFooterGroupProps: PropTypes.func,
		headers: PropTypes.arrayOf(PropTypes.shape({})),
	}).isRequired,
	before: PropTypes.node,
	after: PropTypes.node,
};

export { DefaultTableFooterRow };

const DefaultTableFooter = ({ customFooter, getTableFooterProps, footerGroups, before, after, ...props }) => (
	<TableFooter {...getTableFooterProps()} {...props}>
		<ScrollSyncPane group="one">
			<>
				{before}
				{customFooter
					? customFooter
					: footerGroups.map(footerGroup => (
							<DefaultTableFooterRow
								key={footerGroup.getFooterGroupProps().key}
								footerGroup={footerGroup}
								{...footerGroup.getFooterGroupProps()}
							/>
					  ))}
				{after}
			</>
		</ScrollSyncPane>
	</TableFooter>
);

DefaultTableFooter.defaultProps = {
	before: null,
	after: null,
	customFooter: null,
};

DefaultTableFooter.propTypes = {
	getTableFooterProps: PropTypes.func.isRequired,
	footerGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	customFooter: PropTypes.node,
	before: PropTypes.node,
	after: PropTypes.node,
};

export { DefaultTableFooter };

const NoResultsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;

	svg {
		font-size: 80px;
		color: #555;
		margin-bottom: 10px;
	}

	p {
		margin: 0;
		font-weight: 200;
		font-size: 28px;
	}
`;

const MarkingDot = styled.div`
	${({ $hasFlag }) =>
		$hasFlag &&
		css`
			width: 2px;
			height: 2px;
			border-radius: 50%;
			background-color: red;
			transform: scale(10);
		`}
`;

const NoResults = props => (
	<NoResultsWrapper {...props}>
		<Icon icon="notInterestedRounded" />
		<p>No results found</p>
	</NoResultsWrapper>
);

const DefaultTable = ({
	headerGroups,
	bodyGroups,
	footerGroups,
	rows,
	getTableProps,
	getTableHeaderProps,
	getTableBodyProps: getDefaultTableBodyProps,
	getTableFooterProps,
	getVirtualizedTableBodyProps,
	prepareRow,
	customHeader,
	customFooter,
	isLoading,
	customLoadingRowsCount,
	showNoResultsFound,
	...props
}) => {
	const getTableBodyProps = () => getDefaultTableBodyProps({ style: { overflow: 'visible' } });

	const [isTableMounted, setIsTableMounted] = useState(false);

	useLayoutEffect(() => {
		setIsTableMounted(true);
	}, []);

	return (
		<>
			<ScrollSync>
				<Table getTableProps={getTableProps} {...props}>
					{(headerGroups.length > 0 || customHeader) && (
						<DefaultTableHead
							getTableHeaderProps={getTableHeaderProps}
							customHeader={customHeader}
							headerGroups={headerGroups}
							noGap={props.noGap}
						/>
					)}
					<MarkingDot id="table-body-marker-dot" />
					<ScrollSyncPane group="one">
						<TableBody
							{...getDefaultTableBodyProps({
								style: { overflow: 'auto' },
							})}
						>
							{!isTableMounted ? (
								<></>
							) : isLoading ? (
								<TableBody {...getTableBodyProps()}>
									{fill(Array(customLoadingRowsCount ?? 25), {}).map((entry, index) => (
										// eslint-disable-next-line react/no-array-index-key
										<TableBodyRow {...headerGroups[0].getHeaderGroupProps()} key={`entry-${index}`}>
											{headerGroups[0].headers.map(column => (
												<TableBodyCell key={column.getHeaderProps().key} {...column.getHeaderProps()}>
													<TableBodyCellContent {...column.getHeaderCellContentProps()}>
														<DataLoader />
													</TableBodyCellContent>
												</TableBodyCell>
											))}
										</TableBodyRow>
									))}
								</TableBody>
							) : rows.length === 0 && showNoResultsFound ? (
								<NoResults />
							) : Boolean(getVirtualizedTableBodyProps) === true ? (
								<VirtualizedTableBody
									rows={rows}
									headRef={getTableHeaderProps().ref}
									bodyGroups={bodyGroups}
									getTableBodyProps={getTableBodyProps}
									prepareRow={prepareRow}
									{...getVirtualizedTableBodyProps()}
								/>
							) : (
								<DefaultTableBody
									rows={rows}
									bodyGroups={bodyGroups}
									getTableBodyProps={getTableBodyProps}
									prepareRow={prepareRow}
									noGap={props.noGap}
								/>
							)}
						</TableBody>
					</ScrollSyncPane>
					{(footerGroups.length > 0 || customFooter) && (
						<DefaultTableFooter
							id="table-footer"
							getTableFooterProps={getTableFooterProps}
							customFooter={customFooter}
							footerGroups={footerGroups}
						/>
					)}
				</Table>
			</ScrollSync>
		</>
	);
};

DefaultTable.defaultProps = {
	headerGroups: [],
	bodyGroups: [],
	footerGroups: [],
	getTableFooterProps: () => {},
	getVirtualizedTableBodyProps: undefined,
	customHeader: null,
	customFooter: null,
	isLoading: false,
	customLoadingRowsCount: undefined,
	showNoResultsFound: false,
	noGap: false,
};

DefaultTable.propTypes = {
	headerGroups: PropTypes.arrayOf(
		PropTypes.shape({
			getHeaderGroupProps: PropTypes.func,
			headers: PropTypes.arrayOf(PropTypes.shape({})),
		}),
	),
	bodyGroups: PropTypes.arrayOf(PropTypes.shape({})),
	footerGroups: PropTypes.arrayOf(PropTypes.shape({})),
	rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	getTableProps: PropTypes.func.isRequired,
	getTableHeaderProps: PropTypes.func.isRequired,
	getTableBodyProps: PropTypes.func.isRequired,
	getTableFooterProps: PropTypes.func,
	getVirtualizedTableBodyProps: PropTypes.func,
	prepareRow: PropTypes.func.isRequired,
	customHeader: PropTypes.shape({}),
	customFooter: PropTypes.node,
	isLoading: PropTypes.bool,
	customLoadingRowsCount: PropTypes.number,
	showNoResultsFound: PropTypes.bool,
	noGap: PropTypes.bool,
};

export { DefaultTable };
