import api from 'Application/api/api';

const getEmissionSpvById = (signal, id, details) => {
	const params = {
		details: details,
	};

	return api.get(`/emissionspv/by-id/${id}`, {
		params,
		signal,
	});
};

export default getEmissionSpvById;
