import api from 'Application/api/api';

const getClientOptions = (signal, query) =>
	api.get('/clients/get-partial', {
		params: {
			query,
			size: 9999,
		},
		signal,
	});

export default getClientOptions;
