import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import getEpcInquiryById from 'EpcPartner/api/getEpcInquiryById';
import EpcInquiryForm from 'EpcPartner/components/EpcPartnerPortal/EpcInquiryCreate/EpcInquiryCreateForm/EpcInquiryForm';
import { useParams } from 'react-router';
import crudModes from 'Common/constants/crudModes';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';

const EpcInquiryEdit = () => {
	const { t } = useTranslation();
	const { inquiryId } = useParams();

	const [inquiry, setInquiry] = useState(null);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				const inquiryResponse = await getEpcInquiryById(abortController.signal, inquiryId);

				setInquiry({
					...inquiryResponse.data,
					currency: inquiryResponse.data?.annualOmPrice?.currency ?? null,
				});
			} catch (e) {
				showToastError(e, t("Can't fetch EPC inquiry data"));
			}
		})();
	}, [inquiryId, t, abortController.signal]);

	const handleDataChange = changes => {
		setInquiry(prevEpcOffer => ({
			...prevEpcOffer,
			...changes,
		}));
	};

	return (
		<>
			<Helmet>
				<title>{t('EPC Inquiry')}</title>
			</Helmet>

			{inquiry && (
				<EpcInquiryForm
					data={inquiry}
					mode={crudModes.EDIT}
					onDataChange={handleDataChange}
					formParent={'epcPortal'}
					readOnly={inquiry.state !== 'NEW'}
				/>
			)}
		</>
	);
};

export default EpcInquiryEdit;
