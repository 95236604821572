import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import Loader from 'Common/components/Loader';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from 'Common/components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'Common/components/breadcrumbs/Breadcrumb';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import getCampaignById from 'Campaigns/api/getCampaignById';
import getCrowdDeskSync from 'Campaigns/api/getCrowdDeskSync';
import colors from 'Application/theme/colors';
import { newStatusesLabels, statusColorMap, statusDescriptions } from 'Campaigns/constants/campaignStatuses';
import Button from 'Common/components/buttons/Button';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import OnHoldIcon from 'Projects/components/OnHoldIcon';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import StatusPill from 'Common/components/StatusPill';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import deleteCampaign from 'Campaigns/api/deleteCampaign';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import { push } from 'redux-first-history';
import isAuthorized from 'User/utils/isAuthorized';
import { roles } from 'User/constants/roles';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import GeneralTab from 'Campaigns/components/CampaignDetails/Tabs/GeneralTab/GeneralTab';
import TranchesTab from 'Campaigns/components/CampaignDetails/Tabs/TranchesTab/TranchesTab';
import TabsContainer from 'Common/components/tabs';
import { CAMPAIGN_DETAILS_PAGE_TILES_BY_TAB } from 'Campaigns/constants/campaignTabForms';

const StyledBreadcrumbs = styled(Breadcrumbs)`
	flex: 1;
	flex-grow: 10;
	min-width: 300px;
`;

const StatusContainer = styled.div`
	display: flex;
	margin-left: 10px;
	align-items: center;
	margin-right: 10px;
`;
const SyncCrowDeskButton = styled.div`
	display: flex;
	align-items: center;
	height: 56px;
	> button {
		width: 100%;
	}
`;

const ContentWrapper = styled.div`
	background: ${colors.grey.lightest};
`;

const StyledHeaderTitle = styled(HeaderTitle)`
	line-height: 34px;
	text-transform: capitalize;
	min-width: 0;
`;

const CampaignDetails = () => {
	const [campaign, setCampaign] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [deletionError, setDeletionError] = useState(null);

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const abortController = useAbortController();

	const { campaignId } = useParams();

	const fetchCampaign = useCallback(async () => {
		try {
			const response = await getCampaignById(abortController.signal, campaignId, true);

			setCampaign(response.data);
			setIsLoading(false);
		} catch (error) {
			showToastError(error, t('An error occured while fetching campaign data'));
			if (!axios.isCancel(error)) {
				setCampaign(null);
				setIsLoading(false);
			}
		}
	}, [abortController.signal, campaignId, t]);

	useEffect(() => {
		setIsLoading(true);

		fetchCampaign();
	}, [abortController.signal, campaignId, fetchCampaign, t]);

	const handleCampaignDataChange = useCallback(
		changes => {
			if (changes) {
				setCampaign(prevCampaign => ({
					...prevCampaign,
					...changes,
				}));
				return;
			}

			fetchCampaign();
		},
		[fetchCampaign],
	);

	const syncCrowdesk = async () => {
		if (!campaign?.id) {
			return;
		}

		setIsLoading(true);

		try {
			const response = await getCrowdDeskSync(abortController.signal, campaign.auto_id);
			handleCampaignDataChange(response.data);
			setIsLoading(false);

			const synchStatus = response.data.synch_status;
			if (synchStatus) {
				notify(synchStatus, {
					type: toast.TYPE.ERROR,
				});
			} else {
				notify(t('Succesfully synced with portagon'), {
					type: toast.TYPE.SUCCESS,
				});
			}
		} catch (err) {
			showToastError(err, t('An error occured while syncing with portagon'));
			if (!axios.isCancel(err)) {
				setIsLoading(false);
			}
		}
	};

	const handleDeleteCampaign = async () => {
		try {
			await deleteCampaign(abortController.signal, campaign.auto_id);
			notify(t('Campaign deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});
			dispatch(push('/campaigns'));
			setShowConfirmModal(false);
		} catch (error) {
			showToastError(error, 'Could not delete campaign');
			if (!axios.isCancel(error)) {
				setDeletionError(error.response.data);
				setShowConfirmModal(false);
			}
		}
	};

	const tabsConfig = useMemo(
		() => [
			{
				label: 'General',
				PanelComponent: GeneralTab,
				panelProps: {
					campaign,
					onDataChange: handleCampaignDataChange,
				},
				tabForms: CAMPAIGN_DETAILS_PAGE_TILES_BY_TAB.GENERAL,
				forceRender: true,
			},
			{
				label: 'Tranches',
				PanelComponent: TranchesTab,
				panelProps: {
					campaign,
					onDataChange: handleCampaignDataChange,
				},
				forceRender: true,
			},
		],
		[campaign, handleCampaignDataChange],
	);

	return (
		<div>
			{isLoading ? (
				<LoaderWrapper $isFullPage>
					<Loader />
				</LoaderWrapper>
			) : (
				campaign !== null && (
					<>
						<HeaderContainer>
							<StyledBreadcrumbs>
								<Breadcrumb to="/campaigns">{t('Campaigns')}</Breadcrumb>
								<Breadcrumb active>
									<StyledHeaderTitle>{campaign.name}</StyledHeaderTitle>
								</Breadcrumb>
								<StatusContainer>
									<HoverTooltip placement="right" title={statusDescriptions[campaign.status] || ''} arrow>
										<StatusPill color={statusColorMap[campaign?.status]}>
											{newStatusesLabels?.[campaign?.status]}
										</StatusPill>
									</HoverTooltip>
									{campaign.on_hold && (
										<HoverTooltip placement="right" title={t('On hold and not listed on the ecoligo website.')} arrow>
											<OnHoldIcon />
										</HoverTooltip>
									)}
								</StatusContainer>
							</StyledBreadcrumbs>
							<HeaderActions>
								<SyncCrowDeskButton>
									<Button
										label="Campaign Details - Sync Button"
										text={t('Sync')}
										onClick={syncCrowdesk}
										disabled={!campaign?.id}
									/>
								</SyncCrowDeskButton>
								{isAuthorized([roles.ADMIN, roles.FINANCE, roles.MANAGEMENT]) && (
									<MoreActionsButton label="Campaigns Details More Actions">
										<MenuItem onClick={() => setShowConfirmModal(true)} label="Campaigns Details - Delete Menu Item">
											{t('Delete')}
										</MenuItem>
									</MoreActionsButton>
								)}
							</HeaderActions>
						</HeaderContainer>
						<ContentWrapper>
							<TabsContainer page="campaign" tabsConfig={tabsConfig} />
						</ContentWrapper>
						<ConfirmModal
							isOpen={showConfirmModal}
							onConfirm={() => handleDeleteCampaign()}
							onCancel={() => setShowConfirmModal(false)}
							confirmText="Delete"
							label="delete-campaign"
							heading={t('Delete Campaign')}
							text={t(`Are you sure you want to delete Campaign ${campaign.name}?`)}
						/>
						<UnsuccessfulOperationModal
							label="cannot-delete-campaign"
							isOpen={deletionError !== null}
							onCancel={() => setDeletionError(null)}
							heading={t('Cannot delete campaign')}
							text={deletionError?.violations?.[0]?.message}
						/>
					</>
				)
			)}
		</div>
	);
};

export default CampaignDetails;
