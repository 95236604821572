import api from 'Application/api/api';
import adaptProjectResponseData from 'Projects/api/adapters/adaptProjectResponseData';

const unassignEpcPartnerFromProject = async (signal, projectId) => {
	const response = await api.post(
		`/projects/unassign-epc-partner/${projectId}`,
		{},
		{
			signal,
		},
	);

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptProjectResponseData(response.data),
	};
};

export default unassignEpcPartnerFromProject;
