import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import Modal from 'Common/components/modals/Modal';
import Button from 'Common/components/buttons/Button';
import ImageViewer from 'Common/components/ImageViewer';
import FormikInput from 'Common/components/form/FormikInput';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

import setImageDescription from 'Projects/api/setImageDescription';
import getProjectImageDownloadUrl from 'Projects/api/getProjectImageDownloadUrl';

import colors from 'Application/theme/colors';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const StyledModal = styled(Modal)`
	.ReactModal__Content {
		max-width: 60%;
	}
`;

const ImageToolbar = styled.div`
	display: flex;
	border-bottom: 1px solid #e5e5e5;
	padding: 10px 20px;
	justify-content: space-between;
	margin-top: 40px;
`;

const DescriptionContainer = styled.div`
	width: 100%;
	max-width: 380px;
	margin-top: 40px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 40px;

	> * {
		margin-right: 16px;

		&:last-child {
			margin-right: 0;
		}
	}

	${({ $readOnly }) =>
		$readOnly &&
		css`
			> * {
				margin-right: 0px;
			}
		`}
`;

const DownloadLink = styled.a`
	color: ${colors.primary.main};
	text-decoration: underline;
	font-weight: 400;
	font-size: 15px;
	background: none;
	border: 0 none;
	padding: 0;
	margin-top: 10px;
	cursor: pointer;
	display: block;
`;

const ImageModal = ({ label, isOpen, onClose, openedImage, images, projectId, onImageUpdate, readOnly }) => {
	const { t } = useTranslation();
	const [currentImage, setCurrentImage] = useState(openedImage);
	const [currentImages, setCurrentImages] = useState(images);
	const [description, setDescription] = useState('');
	const [isInputFocused, setIsInputFocused] = useState(false);
	const [downloadUrl, setDownloadUrl] = useState(null);

	const [isSaving, setIsSaving] = useState(false);
	const [isLoadingNext, setIsLoadingNext] = useState(false);
	const [isLoadingPrevious, setIsLoadingPrevious] = useState(false);

	const abortController = useAbortController();

	useEffect(() => {
		setDescription(currentImage.description);
	}, [currentImage]);

	useEffect(() => {
		(async () => {
			try {
				const response = await getProjectImageDownloadUrl(
					abortController.signal,
					currentImage.id.projectId,
					currentImage.id.name,
				);

				setDownloadUrl(response.data);
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [currentImage, abortController.signal]);

	const submit = async () => {
		try {
			const response = await setImageDescription(
				abortController.signal,
				description,
				currentImage.id.name,
				projectId,
			);

			return response.data;
		} catch (err) {
			showToastError(err);
		}
	};

	const handleImageChange = async direction => {
		if (direction === 'next') {
			setIsLoadingNext(true);
		}

		if (direction === 'previous') {
			setIsLoadingPrevious(true);
		}

		try {
			if (currentImage.description !== description) {
				const response = await submit();

				setCurrentImage(response);

				const updatedImageIndex = images.findIndex(image => image.id.name === response.id.name);

				setCurrentImages(prev => {
					let newArr = prev;
					newArr.splice(updatedImageIndex, 1, response);
					return newArr;
				});

				notify(t('Success! Your image is updated.'), {
					type: toast.TYPE.SUCCESS,
				});
			}

			if (direction === 'next') {
				setCurrentImage(prevImage => currentImages[currentImages.indexOf(prevImage) + 1]);
				setIsLoadingNext(false);
			}

			if (direction === 'previous') {
				setCurrentImage(prevImage => currentImages[currentImages.indexOf(prevImage) - 1]);
				setIsLoadingPrevious(false);
			}
		} catch (error) {
			showToastError(error);
			if (!axios.isCancel(error)) {
				if (direction === 'next') {
					setIsLoadingNext(false);
				}

				if (direction === 'previous') {
					setIsLoadingPrevious(false);
				}
			}
		}
	};

	const handleSave = async () => {
		setIsSaving(true);

		try {
			const response = await submit();

			onImageUpdate(response);

			notify(t('Success! Your image is updated.'), {
				type: toast.TYPE.SUCCESS,
			});

			onClose();
			setIsSaving(false);
		} catch (error) {
			showToastError(error);
			if (!axios.isCancel(error)) {
				setIsSaving(false);
			}
		}
	};

	const isLoading = isSaving || isLoadingPrevious || isLoadingNext;

	return (
		<StyledModal label={label} isOpen={isOpen} onRequestClose={onClose}>
			<ImageViewer
				image={currentImage}
				onNextClick={() => handleImageChange('next')}
				onPrevClick={() => handleImageChange('previous')}
				hideNextButton={images.indexOf(currentImage) === images.length - 1}
				hidePrevButton={images.indexOf(currentImage) === 0}
				preventKeyboardNavigation={isInputFocused}
				isSaving={isSaving}
				isLoadingNext={isLoadingNext}
				isLoadingPrevious={isLoadingPrevious}
			/>
			<ImageToolbar>
				<span>{currentImage?.id?.name}</span>
				<DownloadLink href={downloadUrl} target="_blank">
					{t('Download')}
				</DownloadLink>
			</ImageToolbar>
			<DescriptionContainer>
				{!readOnly ? (
					<FormikInput
						id="description"
						name="description"
						label={t('Description')}
						value={description || ''}
						onChange={e => setDescription(e.target.value)}
						onFocus={() => setIsInputFocused(true)}
						onBlur={() => setIsInputFocused(false)}
					/>
				) : (
					<KeyValueVisualization
						title={t('Description')}
						value={description}
						containerStyle={{
							maxWidth: '400px',
						}}
					/>
				)}
			</DescriptionContainer>
			<ButtonContainer $readOnly={readOnly}>
				<Button
					secondary
					text={t('Cancel')}
					onClick={onClose}
					label="Project Details Images Tab - Cancel Button"
				/>
				{!readOnly && (
					<Button
						overlay
						text={t('Save')}
						onClick={handleSave}
						isLoading={isLoading}
						label="Project Details Images Tab - Save Button"
					/>
				)}
			</ButtonContainer>
		</StyledModal>
	);
};

ImageModal.defaultProps = {
	readOnly: false,
};

ImageModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	openedImage: PropTypes.shape({}).isRequired,
	images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	onClose: PropTypes.func.isRequired,
	projectId: PropTypes.number.isRequired,
	onImageUpdate: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
	label: PropTypes.string.isRequired,
};

export default ImageModal;
