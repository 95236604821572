import styled from 'styled-components/macro';
import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';

import ProjectInfoSummary from './ProjectInfoSummary';
import useProjectStats from './hooks/useProjectStats';
import ProjectInfoSystemSizeEdit from './ProjectInfoSystemSizeEdit';

import colors from 'Application/theme/colors';

import Button from 'Common/components/buttons/Button';

const InfoTile = styled.div`
	border-radius: 20px;
	border: 1px solid ${colors.primary.dark};
	padding: 0 16px;
`;

const Title = styled.div`
	font-size: 18px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
	padding: 16px 0;
`;

const Text = styled.span`
	font-size: 16px;
	margin: 5px 0;
	flex: 1;
`;

const ProjectInfoTileForm = ({
	project,
	values,
	errors,
	touched,
	namePrefix,
	handleChange,
	setFieldValue,
	handleBlur,
	removeProject,
}) => {
	const {
		projectName,
		otherCampaignsSystemSizeValue,
		otherCampaignsSystemSizePercent,
		currentCampaignSystemSizeValue,
		currentCampaignSystemSizePercent,
	} = useProjectStats(project, values);

	return (
		<InfoTile>
			<Title>
				<Text>{projectName}</Text>
				<Button
					small
					secondary
					icon="closeRounded"
					onClick={() => removeProject(project.id)}
					label="Campaign Project Info Create - Close Button"
				/>
			</Title>
			<Grid container alignItems="center" direction="column">
				<Grid item xs={12}>
					<ProjectInfoSummary
						systemSizeKwp={project?.systemSizeKwp}
						annualCO2Savings={Number(project?.annualCO2Savings)}
						otherCampaignsSystemSizeValue={otherCampaignsSystemSizeValue}
						otherCampaignsSystemSizePercent={otherCampaignsSystemSizePercent}
						currentCampaignSystemSize={currentCampaignSystemSizeValue}
						currentCampaignSystemSizePercent={currentCampaignSystemSizePercent}
					/>
				</Grid>
				<Grid container alignItems="center" direction="column">
					<ProjectInfoSystemSizeEdit
						handleBlur={handleBlur}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						values={values}
						namePrefix={namePrefix}
						systemSizeKwp={project.systemSizeKwp}
						setFieldValue={setFieldValue}
						otherCampaignsSystemSizeValue={otherCampaignsSystemSizeValue}
						currentCampaignSystemSizeValue={currentCampaignSystemSizeValue}
						currentCampaignSystemSizePercent={currentCampaignSystemSizePercent}
					/>
				</Grid>
			</Grid>
		</InfoTile>
	);
};

ProjectInfoTileForm.propTypes = {
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		systemSizeKwp: PropTypes.number,
		annualCO2Savings: PropTypes.number,
	}).isRequired,
	values: PropTypes.shape({}).isRequired,
	errors: PropTypes.shape({}).isRequired,
	touched: PropTypes.shape({}).isRequired,
	namePrefix: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	handleBlur: PropTypes.func.isRequired,
	removeProject: PropTypes.func.isRequired,
};

export default ProjectInfoTileForm;
