import api from 'Application/api/api';

const getPartnerById = (signal, id, details) =>
	api.get(`/other-partners/by-id/${id}`, {
		params: {
			details,
		},
		signal,
	});

export default getPartnerById;
