// TODO remove the eslint complexity and fix it
/* eslint-disable complexity */
import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import Icon from 'Common/components/icons/Icon';
import { sortBy } from 'lodash';
import { statusColors, statuses, statusOptions } from 'Projects/constants/offerStatuses';
import { typeColors, types, typesSortOrder } from 'Projects/constants/offerTypes';
import formatNumber from 'Common/utils/formatNumber';
import formatCurrency from 'Common/utils/formatCurrency';
import EPCOfferListActions from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/EPCOfferTile/Actions/EPCOfferListActions';
import isAuthorized from 'User/utils/isAuthorized';
import { roles } from 'User/constants/roles';
import EpcPartnerNameWithTier from 'EpcPartner/components/EpcPartnerNameWithTier';
import {
	DefaultTable,
	TableBodyCell,
	TableBodyCellContent,
	TableHeadCell,
} from 'Common/components/table/table';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import colors from 'Application/theme/colors';
import StatusPill from 'Common/components/StatusPill';
import { useCustomRowStyle } from 'Common/hooks/table/plugin-hooks/useCustomRowStyle';
import { isBefore, isToday } from 'date-fns';
import formatDate from 'Common/utils/formatDate';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import Button from 'Common/components/buttons/Button';
import { useCustomRowProps } from 'Common/hooks/table/plugin-hooks/useCustomRowProps';

const statusTitles = {
	[statuses.OPEN]: 'Waiting for the EPC Partner to respond to the RFEP.',
	[statuses.OFFER_RECEIVED]: 'The EPC Partner has provided an Offer to complete this Project.',
	[statuses.DECLINED_BY_ECOLIGO]: 'ecoligo have considered this EPC offer as not suitable.',
	[statuses.DECLINED_BY_PARTNER]: 'The EPC Partner has chosen not to proceed with this Project.',
	[statuses.SIGNED]: 'An EPC Contract has been signed and the Project has been awarded to this EPC Partner.',
	[statuses.AWARDED]: 'An EPC Contract has been signed and the Project has been awarded to this EPC Partner.',
	[statuses.CLOSED]: 'The Project was awarded to another Offer or the Project was closed.',
};

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}

	${TableBodyCell} {
		height: 70px;
		background: transparent;
	}

	${TableBodyCellContent} {
		height: 100%;
		display: flex;
		align-items: center;
		align-self: flex-start;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.hovered {
		> div {
			background-color: ${colors.common.lightGrey};
			&:before {
				display: block;
				background-color: ${colors.common.lightGrey};
			}
		}
	}
`;

const TypeCell = styled.div`
	background: ${({ type }) => typeColors[type]};
	color: ${colors.grey.lightest};
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: first baseline;
	font-size: 12px;
	width: 100%;

	span {
		text-transform: uppercase;
		transform: rotate(180deg);
		writing-mode: vertical-rl;
		margin-top: 5px;
	}
`;

const PrimaryCheck = styled.div`
	border-radius: 25px;
	height: 16px;
	width: 16px;
	color: white;
	background: ${colors.primary.main};
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;

	svg {
		height: 14px;
		width: 14px;
	}
`;

const StyledCellText = styled.span`
	font-weight: ${({ isPrimary }) => (isPrimary ? 700 : 400)};
`;

const SystemSizeWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const ValuesMask = styled.div`
	width: 37%;
	background: ${colors.common.beige};
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	min-width: 450px;
`;

const checkType = (prevType, currentType, isCollapsed) => {
	if (prevType !== currentType || isCollapsed) {
		return currentType;
	}
};

const EPCOfferList = ({ project, isCollapsed, handleUpdateProject, onEPCOfferCreate }) => {
	const { t } = useTranslation();

	const projectId = project.id;
	const projectType = project?.projectType?.name;
	const projectSystemSize = project?.systemSizeKwp;
	const epcOffers = project?.epcOffers;
	const rfepState = project?.tender?.state;

	const isAuthorizedToEditAndCreate = isAuthorized([
		roles.ADMIN,
		roles.SALES,
		roles.FINANCE,
		roles.MANAGEMENT,
	]);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: '',
				accessor: 'type',
				minWidth: 20,
				maxWidth: 20,
				customCellStyles: {
					padding: 0,
					fontWeight: 600,
					lineHeight: '20px',
					borderBottom: 0,
				},
				customCellContentStyles: {
					width: 20,
					height: '100%',
					padding: 0,
				},
			},
			{
				Header: t('Status'),
				accessor: 'status',
				minWidth: 170,
				customBodyCellContentStyles: {
					padding: '8px 0',
				},
			},
			{
				Header: t('EPC Partner'),
				accessor: 'epcPartner',
				minWidth: 100,
				maxWidth: 150,
				customBodyCellContentStyles: {
					padding: '8px 0',
				},
			},
			{
				Header: t('System size'),
				accessor: 'systemSize',
				minWidth: 80,
				maxWidth: 100,
				customBodyCellContentStyles: {
					padding: '8px 0',
				},
			},
			{
				Header: t('EPC volume'),
				accessor: 'epcVolumeExclVat',
				minWidth: 95,
				maxWidth: 95,
				customBodyCellContentStyles: {
					padding: '8px 0',
				},
			},
			{
				Header: 'Yield',
				accessor: 'yield',
				minWidth: 50,
				maxWidth: 80,
				customBodyCellContentStyles: {
					padding: '8px 0',
				},
			},
			{
				Header: 'Annual O&M price',
				accessor: 'annualOmPrice',
				minWidth: 70,
				maxWidth: 135,
				customBodyCellContentStyles: {
					padding: '8px 0',
				},
			},
			{
				accessor: 'clientOffers',
				minWidth: 230,
				maxWidth: 370,
				customBodyCellContentStyles: {
					padding: '8px 0',
				},
			},
			{
				Header: '',
				accessor: 'actions',
				minWidth: 50,
				maxWidth: 50,
				customCellStyles: {
					justifyContent: 'flex-end',
				},
				customBodyCellContentStyles: {
					padding: '8px 0',
				},
			},
		],
		[t],
	);

	const isValueMaskShow =
		isBefore(new Date(), new Date(project?.tender?.tenderCloseDate)) ||
		isToday(new Date(project?.tender?.tenderCloseDate));

	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			sortBy(epcOffers, e => typesSortOrder.indexOf(e.type)).map((offer, index, arr) => {
				const isPrimary = !!offer?.primaryOffer;
				const canDecline = offer?.type !== types.ESTIMATE;
				const isSigned = !!offer?.signed;

				return {
					...offer,
					isPrimary,
					systemSize:
						isValueMaskShow && offer?.type === types.RFEP ? (
							<ValuesMask>
								Prices will be visible when the RFEP closes on{' '}
								{project?.tender?.tenderCloseDate ? formatDate(project?.tender?.tenderCloseDate) : ''}
							</ValuesMask>
						) : offer?.status !== statuses.OPEN ? (
							<SystemSizeWrapper>
								{isPrimary && !isSigned && (
									<HoverTooltip
										placement="top"
										title="The EPC Offer most likely to succeed. The values in this Offer will be used by the Project."
										arrow
									>
										<PrimaryCheck>
											<Icon icon="check" />
										</PrimaryCheck>
									</HoverTooltip>
								)}
								<StyledCellText isPrimary={isPrimary}>{`${formatNumber(offer?.systemSize, 0)} kWp`}</StyledCellText>
							</SystemSizeWrapper>
						) : (
							'-'
						),
					epcVolumeExclVat:
						isBefore(new Date(), new Date(project?.tender?.tenderCloseDate)) && offer?.type === types.RFEP ? (
							''
						) : offer?.status !== statuses.OPEN ? (
							<StyledCellText isPrimary={isPrimary}>
								<>
									{offer?.epcVolumeExclVat?.eurValue ? formatCurrency(offer?.epcVolumeExclVat?.eurValue) : '-'}
									<br />
									{offer?.epcVolumeExclVat?.currency &&
										offer?.epcVolumeExclVat?.currency !== 'EUR' &&
										`${offer?.epcVolumeExclVat?.currency} ${formatNumber(offer?.epcVolumeExclVat?.value, 0)}`}
								</>
							</StyledCellText>
						) : (
							'-'
						),
					yield:
						isBefore(new Date(), new Date(project?.tender?.tenderCloseDate)) && offer?.type === types.RFEP ? (
							''
						) : offer?.status !== statuses.OPEN ? (
							<StyledCellText isPrimary={isPrimary}>{formatNumber(offer?.yield, 0)}</StyledCellText>
						) : (
							'-'
						),
					annualOmPrice:
						isBefore(new Date(), new Date(project?.tender?.tenderCloseDate)) && offer?.type === types.RFEP ? (
							''
						) : offer?.status !== statuses.OPEN ? (
							<StyledCellText isPrimary={isPrimary}>
								<>
									{offer?.annualOmPrice?.eurValue ? formatCurrency(offer?.annualOmPrice?.eurValue) : '-'}
									<br />
									{offer?.annualOmPrice?.currency &&
										offer?.annualOmPrice?.currency !== 'EUR' &&
										`${offer?.annualOmPrice?.currency} ${formatNumber(offer?.annualOmPrice?.value, 0)}`}
								</>
							</StyledCellText>
						) : (
							'-'
						),
					type: (
						<TypeCell type={offer?.type}>
							<span>
								{checkType(
									arr[index - 1]?.type === types.PROPOSAL ? 'RFEP' : arr[index - 1]?.type,
									offer?.type === types.PROPOSAL ? 'RFEP' : offer?.type,
									isCollapsed,
								)}
							</span>
						</TypeCell>
					),
					clientOffers: (
						<Button
							onClick={() => onEPCOfferCreate(offer.id)}
							disabled={
								!(
									offer?.status !== statuses.DECLINED_BY_ECOLIGO &&
									offer.status !== statuses.DECLINED_BY_PARTNER &&
									offer.status !== statuses.CLOSED &&
									offer?.status !== statuses.OPEN &&
									isAuthorizedToEditAndCreate
								)
							}
							text={t('Create Client Offer')}
							label="Projects Details Offers Client Offer - Create Client OfferButton"
						/>
					),
					status: (
						<HoverTooltip placement="top" title={t(statusTitles[offer?.status]) || ''} arrow>
							<StatusPill color={statusColors[offer?.status]}>{statusOptions[offer?.status]}</StatusPill>
						</HoverTooltip>
					),
					actions: (
						<EPCOfferListActions
							epcOffers={arr}
							project={project}
							offer={offer}
							reloadProject={handleUpdateProject}
							projectId={projectId}
							isPrimary={isPrimary}
							isSigned={isSigned}
							canDecline={canDecline}
							rfepState={rfepState}
							projectType={projectType}
							projectSystemSize={projectSystemSize}
						/>
					),

					epcPartner: offer?.epcPartner?.name && (
						<StyledCellText isPrimary={isPrimary}>
							<EpcPartnerNameWithTier
								name={offer?.epcPartner?.name}
								points={offer?.epcPartner?.totalPoints}
								tier={offer?.epcPartner?.tier}
							/>
						</StyledCellText>
					),

					epcOfferId: offer?.id,
				};
			}),
		[
			epcOffers,
			isValueMaskShow,
			project,
			isCollapsed,
			isAuthorizedToEditAndCreate,
			t,
			handleUpdateProject,
			projectId,
			rfepState,
			projectType,
			projectSystemSize,
			onEPCOfferCreate,
		],
	);

	const setHoverOnClientOffers = (epcOfferId, isHovered) => {
		document.querySelectorAll(`.client-offers-table [data-epc-offer="${epcOfferId}"]`).forEach(row => {
			row.classList[isHovered ? 'add' : 'remove']('hovered');
		});
	};

	const generateHeight = useCallback(
		row => {
			const isPrimary = row.original.isPrimary;

			const isRowHidden = isCollapsed && !isPrimary;

			const hiddenRowStyles = {
				height: 0,
				opacity: 0,
				padding: 0,
				overflow: 'hidden',
			};

			const visibleRowStyles = {
				height: '70px',
				opacity: 1,
			};

			return {
				...(isRowHidden ? hiddenRowStyles : visibleRowStyles),
				position: 'relative',
				transition: 'height 0.5s ease-in-out, opacity 0.5s ease-in-out, padding 0.5s ease-in-out',
			};
		},
		[isCollapsed],
	);

	const {
		getTableProps,
		getTableHeaderProps,
		getTableFooterProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useStandardTable(
		{
			data,
			columns,
			getCustomRowStyles: generateHeight,
			getCustomRowProps: row => ({
				'data-epc-offer': row.original.epcOfferId,
				onMouseEnter: () => setHoverOnClientOffers(row.original.epcOfferId, true),
				onMouseLeave: () => setHoverOnClientOffers(row.original.epcOfferId, false),
			}),
		},
		useCustomCellStyles,
		useCustomRowStyle,
		useCustomRowProps,
	);

	return (
		<TableWrapper className="epc-offers-table">
			<DefaultTable
				getTableProps={getTableProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				getTableFooterProps={getTableFooterProps}
				rows={rows}
				headerGroups={headerGroups}
				prepareRow={prepareRow}
			/>
		</TableWrapper>
	);
};

EPCOfferList.defaultProps = {
	project: undefined,
	isCollapsed: false,
	onEPCOfferCreate: () => {},
	handleUpdateProject: async () => {},
};

EPCOfferList.propTypes = {
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		projectType: PropTypes.shape({ name: PropTypes.string }),
		systemSizeKwp: PropTypes.number,
		tender: PropTypes.shape({
			tenderCloseDate: PropTypes.string,
			state: PropTypes.string,
		}),
		epcOffers: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	isCollapsed: PropTypes.bool,
	handleUpdateProject: PropTypes.func,
	onEPCOfferCreate: PropTypes.func,
};

export default EPCOfferList;
