const INVESTOR_DETAILS_PAGE_TAB_TILES = {
	GENERAL: {
		DETAILS: 'details',
		DEMOGRAPHICS: 'demographics',
		ADDRESS: 'address',
	},
};

const INVESTOR_DETAILS_PAGE_TILES_BY_TAB = {
	GENERAL: Object.values(INVESTOR_DETAILS_PAGE_TAB_TILES.GENERAL),
};

export { INVESTOR_DETAILS_PAGE_TAB_TILES, INVESTOR_DETAILS_PAGE_TILES_BY_TAB };
