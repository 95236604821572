import getRoofTypes from 'Projects/api/getRoofTypes';

const loadRoofTypeOptions = async (signal, query) => {
	const response = await getRoofTypes(signal, query);
	const roofTypes = response.data;

	return roofTypes
		.filter(roofType => roofType.name && roofType.name !== '-')
		.map(roofType => ({
			value: roofType.id,
			label: roofType.name,
		}))
		.filter(option => option.label && option.label.toLowerCase().includes(query.toLowerCase()));
};

export default loadRoofTypeOptions;
