import getProjectTypes from 'Projects/api/getProjectTypes';

const loadProjectTypeOptions = async (signal, query, withFullName = false) => {
	const response = await getProjectTypes(signal, query);
	const projectTypes = response.data.content;

	return projectTypes
		.filter(projectType => projectType.name && projectType.name !== '-')
		.map(projectType => ({
			value: projectType.id,
			label: withFullName ? `${projectType.fullName} (${projectType.name})` : projectType.name,
		}));
};

export default loadProjectTypeOptions;
