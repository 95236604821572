const useTotalsInitialValues = () => {
	return {
		totalMWpInOperation: 0,
		totalMWpSigned: 0,
		totalMWpMatured: 0,
		totalEurInvested: 0,
		totalProjectsSigned: 0,
		countries: [],
		totalProjectsFunded: 0,
		totalCrowdInvestors: 0,
		totalEurSaved: 0,
		totalLifetimeCo2InOperation: 0.0,
		totalLifetimeCo2Signed: 0.0,
		totalLifetimeCo2Funded: 0.0,
		averageCO2SavingsPerEURInvested: 0.0,
		createdDate: null,
		totalCountriesServed: 0,
		totalClientsImpacted: 0,
		totalIndustriesServed: 0,
		totalEpcPartners: 0,
		totalEmployeesImpacted: 0,
		highestKwpFunded: 0,
		lowestKwpFunded: 0,
		investorAgeGroupsDto: {
			betweenEightenAndTwentyNine: 0,
			betweenThirtyAndThirtyNine: 0,
			betweenFortyAndFortyNine: 0,
			betweenFiftyAndFiftyNine: 0,
			betweenSixtyAndSixtyNine: 0,
			overSeventy: 0,
		},
		totalProjectsInOperation: 0,
		totalProjectsMatured: 0,
		totalClientsImpactedInOperation: 0,
		projectedKWhSigned: 0,
		projectedKWhInOperation: 0,
		activeCrowdInvestors: 0,
		notActiveCrowdInvestors: 0,
		totalMaleCrowdInvestors: 0,
		totalFemaleCrowdInvestors: 0,
	};
};

export default useTotalsInitialValues;
