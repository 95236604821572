import api from 'Application/api/api';
import adaptClientOfferResponseData from './adapters/adaptClientOfferResponseData';

const getClientOfferById = async (signal, id) => {
	const response = await api.get(`/client-offers/${id}`, {
		signal,
	});

	return {
		...response,
		data: adaptClientOfferResponseData(response.data),
	};
};

export default getClientOfferById;
