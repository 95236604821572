import parseISO from 'date-fns/parseISO';
import { useEffect, useMemo, useState } from 'react';

const fallbackValues = {
	insuredSum: '',
	insuredCurrency: { label: 'EUR Euro', value: 'EUR' },
	premium: '',
	premiumPercentage: '',
	providerName: { label: '', value: '' },
	policyCost: '',
	policyCurrency: { label: 'EUR Euro', value: 'EUR' },
	insuredEntity: { label: '', value: '' },
	validityStatus: 'NOT_INSURED_YET',
	paymentVerification: false,
	policyStartDate: null,
	policyEndDate: null,
	formDocument: '',
	formDocumentName: '',
	formDocumentDisplayDocuments: [],
	signedPolicyDocument: '',
	signedPolicyDocumentName: '',
	signedPolicyDocumentDisplayDocuments: [],
	payslipDocument: '',
	payslipDocumentName: '',
	payslipDocumentDisplayDocuments: [],
	projectId: '',
};
const useInsuranceFormInitialValues = data => {
	const [provider, setProvider] = useState({
		value: data?.insurance?.providerId || fallbackValues.providerName.value,
		label: data?.insurance?.provider || fallbackValues.providerName.label,
	});

	useEffect(() => {
		setProvider({ value: data?.insurance?.providerId, label: data?.insurance?.provider });
	}, [data?.insurance?.providerId, data?.insurance?.provider]);

	const [entity, setEntity] = useState({
		value: data?.insurance?.entityId || fallbackValues.insuredEntity.value,
		label: data?.insurance?.entity || fallbackValues.insuredEntity.label,
	});
	useEffect(() => {
		setEntity({ value: data?.insurance?.entityId, label: data?.insurance?.entity });
	}, [data?.insurance?.entityId, data?.insurance?.entity]);

	const [formDocumentData, setFormDocumentData] = useState(data?.insurance?.formDocuments?.slice(-1)[0]);
	useEffect(() => {
		setFormDocumentData(data?.insurance?.formDocuments?.slice(-1)[0]);
	}, [data?.insurance?.formDocuments]);
	const [signedPolicyDocumentData, setSignedPolicyDocumentData] = useState(
		data?.insurance?.signedPolicyDocuments?.slice(-1)[0],
	);
	useEffect(() => {
		setSignedPolicyDocumentData(data?.insurance?.signedPolicyDocuments?.slice(-1)[0]);
	}, [data?.insurance?.signedPolicyDocuments]);
	const [payslipDocumentData, setPayslipDocumentData] = useState(
		data?.insurance?.signedPolicyDocuments?.slice(-1)[0],
	);
	useEffect(() => {
		setPayslipDocumentData(data?.insurance?.payslipDocuments?.slice(-1)[0]);
	}, [data?.insurance?.payslipDocuments]);

	return useMemo(
		// eslint-disable-next-line complexity
		() => ({
			...fallbackValues,
			formDocument: '',
			formDocumentName: formDocumentData?.displayName || fallbackValues.formDocumentName,
			formDocumentDisplayDocuments:
				data?.insurance?.formDocuments || fallbackValues?.formDocumentDisplayDocuments,
			signedPolicyDocument: '',
			signedPolicyDocumentName: signedPolicyDocumentData?.displayName || fallbackValues.signedPolicyDocumentName,
			signedPolicyDocumentDisplayDocuments:
				data?.insurance?.signedPolicyDocuments || fallbackValues.signedPolicyDocumentDisplayDocuments,
			payslipDocument: '',
			payslipDocumentName: payslipDocumentData?.displayName || fallbackValues.insurancepayslipName,
			payslipDocumentDisplayDocuments:
				data?.insurance?.payslipDocuments || fallbackValues.insuranceFormDisplayDocuments,

			insuredCurrency: fallbackValues?.insuredCurrency,
			policyCurrency: fallbackValues?.policyCurrency,
			premium: data?.insurance ? data?.insurance?.premium.value : fallbackValues.premium,
			policyCost: data?.insurance ? data?.insurance?.policyCost?.value : fallbackValues.policyCost,
			providerName: data?.insurance ? provider : fallbackValues.providerName,
			insuredEntity: data?.insurance ? entity : fallbackValues.insuredEntity,
			validityStatus: data?.insurance ? data?.insurance?.validityStatus : fallbackValues.validityStatus,
			insuredSum: data?.insurance?.insuredSum
				? data?.insurance?.insuredSum?.value
				: data?.epcContract?.annualOmPrice?.value
				? data?.epcContract?.annualOmPrice?.value
				: fallbackValues.insuredSum,
			paymentVerification: data?.insurance
				? data?.insurance?.paymentVerification
				: fallbackValues.paymentVerification,
			policyStartDate: data?.insurance ? parseISO(data?.insurance?.policyStartDate) : null,
			policyEndDate: data?.insurance ? parseISO(data?.insurance?.policyEndDate) : fallbackValues.policyEndDate,

			premiumPercentage: data?.insurance ? data?.insurance?.premiumPercentage : fallbackValues.premiumPercentage,
			projectId: data?.id ? data?.id : fallbackValues?.projectId,
		}),
		[formDocumentData, signedPolicyDocumentData, payslipDocumentData, entity, provider, data],
	);
};

export default useInsuranceFormInitialValues;
