import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import FormikInput from 'Common/components/form/FormikInput';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import { DefaultTable } from 'Common/components/table/table';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import ErrorMessage from 'Common/components/form/ErrorMessage';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	position: relative;
`;

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};
`;

const StyledCheckbox = styled(FormikCheckbox)`
	.MuiCheckbox-root {
		margin-left: 0 !important;
	}
`;

const CheckboxContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const StyledErrorMessage = styled(ErrorMessage)`
	left: initial;
	top: initial;
`;

const PaymentMilestonesList = ({ milestones, onCheckboxClick, onPercentageChange, validationError }) => {
	const { t } = useTranslation();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Active'),
				accessor: 'isSelected',
				maxWidth: 70,
				customCellStyles: {
					display: 'flex',
					justifyContent: 'center',
				},
			},
			{
				Header: t('Milestone'),
				accessor: 'name',
			},
			{
				Header: t('% of EPC volume'),
				accessor: 'epcVolumePercentage',
				maxWidth: 150,
			},
			{
				// This column is here because by design there is an empty column at the end
				accessor: 'empty',
				width: 30,
			},
		],
		[t],
	);
	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			milestones.map(milestone => ({
				...milestone,
				isSelected: (
					<CheckboxContainer>
						<StyledCheckbox
							id={`checkbox-${milestone.name}`}
							name={`checkbox-${milestone.name}`}
							checked={milestone.isSelected}
							onChange={e => onCheckboxClick(milestone.id, e.target.checked)}
							touched
						/>
					</CheckboxContainer>
				),
				name: milestone.name,
				epcVolumePercentage: milestone.isSelected && (
					<div>
						<FormikInput
							id={`input-${milestone.id}`}
							name={`input-${milestone.id}`}
							value={milestone.percentage}
							onChange={e => onPercentageChange(milestone.id, e.target.value)}
							error={validationError?.[milestone.id]?.message}
						/>
						{validationError?.[milestone.id]?.message && (
							<StyledErrorMessage message={validationError?.[milestone.id]?.message} />
						)}
					</div>
				),
			})),
		[onCheckboxClick, onPercentageChange, milestones, validationError],
	);

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
			},
			useCustomCellStyles,
		);

	return (
		<Wrapper>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
				/>
			</TableWrapper>
		</Wrapper>
	);
};

PaymentMilestonesList.propTypes = {
	milestones: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	onCheckboxClick: PropTypes.func.isRequired,
	onPercentageChange: PropTypes.func.isRequired,
	validationError: PropTypes.string.isRequired,
};

export default PaymentMilestonesList;
