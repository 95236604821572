import getEpcPartnersWithPortalAccess from 'EpcPartner/api/getEpcPartnersWithPortalAccess';

const loadInvitedEpcPartners = async (signal, name) => {
	const response = await getEpcPartnersWithPortalAccess(signal, name);

	const epcPartners = response.data;

	return epcPartners
		.filter(epcPartner => epcPartner.name && epcPartner.name !== '-')
		.map(epcPartner => ({
			value: epcPartner.id,
			label: `${epcPartner.name} ${
				epcPartner?.country?.name !== undefined ? `(${epcPartner?.country?.name})` : ''
			}`,
		}));
};

export default loadInvitedEpcPartners;
