import { useState } from 'react';
import styled from 'styled-components/macro';
import Thumbnail from 'Common/components/Thumbnail';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import ImageModal from 'Projects/components/ProjectDetails/Tabs/ImagesTab/ImageModal';
import colors from 'Application/theme/colors';
import { PropTypes } from 'prop-types';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const Gallery = styled.div`
	position: relative;
	padding: 32px;
	width: 100%;
	min-height: 80px;

	&:focus {
		outline: none;
	}
`;

const ThumbnailContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	${({ isDraggedOver }) =>
		isDraggedOver &&
		css`
			opacity: 0.5;
		`}
`;

const Subheader = styled.p`
	text-align: center;
	margin-block-end: 16px;
	margin-block-start: 16px;
`;

const EmptyGalleryText = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 10px;
	top: 20px;
	font-weight: 600;
	color: ${colors.text.primary};
`;

const RfepImagesSection = ({ projectId, images }) => {
	const { t } = useTranslation();
	const [openedImageInModal, setOpenedImageInModal] = useState(null);

	return (
		<Wrapper>
			<Subheader>
				The following Images will be shared with the EPC Partner. To upload images, use the Images tab in the
				Project and then return to the RFEP process.
			</Subheader>
			<Gallery onClick={e => e.stopPropagation()}>
				{!images || images?.length === 0 ? (
					<EmptyGalleryText>
						<span>{t('There are no images yet')}</span>
					</EmptyGalleryText>
				) : (
					<ThumbnailContainer>
						<>
							{images.map(image => (
								<Thumbnail
									key={image.id.name}
									image={image}
									onImageClick={setOpenedImageInModal}
									isSelectable={false}
								/>
							))}
						</>
					</ThumbnailContainer>
				)}
			</Gallery>

			{openedImageInModal && (
				<ImageModal
					isOpen={openedImageInModal !== null}
					onClose={() => setOpenedImageInModal(null)}
					openedImage={openedImageInModal}
					images={images}
					projectId={projectId}
					readOnly
					label="Project Sales Tab Open Upload Image Tab Modal"
				/>
			)}
		</Wrapper>
	);
};

RfepImagesSection.defaultProps = {
	projectId: null,
};

RfepImagesSection.propTypes = {
	projectId: PropTypes.number,
	images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default RfepImagesSection;
