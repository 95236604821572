import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import formatNumber from 'Common/utils/formatNumber';
import EditableTile from 'Common/components/Tile/EditableTile';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';
import UNITS from 'Common/constants/units';
import projectTypes from 'Projects/constants/projectTypes';

const ProjectTab = ({ epcOffer }) => {
	const { t } = useTranslation();

	const projectType = epcOffer.projectType?.name;

	let projectTypeLabel = epcOffer.projectType?.fullName
		? `${epcOffer.projectType?.fullName} (${projectType})`
		: '';

	let energyStorage = t('No');
	let gensets = t('No');

	if (projectType === projectTypes.PV) {
		projectTypeLabel += ` ${epcOffer.onGrid ? '(On-grid)' : '(Off-grid)'}`;

		energyStorage = epcOffer.energyStorage ? t('Yes') : t('No');
		gensets = epcOffer.gensets ? t('Yes') : t('No');
	}

	const mountingTypes =
		epcOffer.mountingTypes && epcOffer.mountingTypes.length > 0
			? epcOffer.mountingTypes.map(mountingType => mountingType.displayName).join(', ')
			: '';
	const availableSpace = epcOffer.availableSpace ? formatNumber(epcOffer.availableSpace) : '';

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
				<EditableTile title={t('Type')}>
					<KeyValueVisualization title={t('Project type')} value={projectTypeLabel} />
					<KeyValueVisualization title={t('Energy storage')} value={energyStorage} />
					<KeyValueVisualization title={t('Gensets')} value={gensets} />
					{projectType === projectTypes.PV && (
						<KeyValueVisualization
							title={t('System size')}
							value={epcOffer.projectSystemSize ? formatNumber(epcOffer.projectSystemSize, 0) : '-'}
							valueStyle={{ display: 'flex' }}
							unit={UNITS.KWP}
							tooltip={
								<QuestionTooltip tooltip="You can adjust the System size for your proposal on the Offer tab." />
							}
						/>
					)}
				</EditableTile>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
				<EditableTile title={t('Mounting')}>
					<KeyValueVisualization title={t('Mounting type')} value={mountingTypes} />
					<KeyValueVisualization title={t('Available space')} value={availableSpace} />
					<KeyValueVisualization title={t('Roof type')} value={epcOffer.roofType} />
				</EditableTile>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
				<EditableTile
					title={t('Gensets')}
					tooltip={<QuestionTooltip tooltip="The gensets currently installed on-site." />}
				>
					<KeyValueVisualization title={t('Generator size')} value={epcOffer.generatorSize} />
					<KeyValueVisualization title={t('Generator quanty')} value={epcOffer.generatorQuantity} />
					<KeyValueVisualization
						title={t('Include gensets in quote')}
						value={epcOffer.includeGensetsInQuote ? t('Yes') : t('No')}
					/>
				</EditableTile>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
				<EditableTile title={t('Connection')}>
					<KeyValueVisualization title={t('Meter quantity')} value={epcOffer.meterQuantity} />
					<KeyValueVisualization title={t('Connection points')} value={epcOffer.connectionPoints} />
				</EditableTile>
			</Grid>
		</TilesGridWrapper>
	);
};

ProjectTab.propTypes = {
	epcOffer: PropTypes.shape({
		projectType: PropTypes.shape({ name: PropTypes.string, fullName: PropTypes.string }),
		onGrid: PropTypes.bool,
		energyStorage: PropTypes.bool,
		gensets: PropTypes.bool,
		projectSystemSize: PropTypes.number,
		mountingTypes: PropTypes.arrayOf(PropTypes.shape({})),
		availableSpace: PropTypes.number,
		roofType: PropTypes.string,
		generatorSize: PropTypes.number,
		generatorQuantity: PropTypes.number,
		includeGensetsInQuote: PropTypes.number,
		meterQuantity: PropTypes.number,
		connectionPoints: PropTypes.number,
	}).isRequired,
};

export default ProjectTab;
