import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types';

import Button from 'Common/components/buttons/Button';

import styled from 'styled-components/macro';
import { css } from 'styled-components';
import sizes from 'Application/theme/sizes';

const StyledDialog = styled(Dialog)`
	.MuiPaper-root {
		pointer-events: none;
		position: absolute;
		bottom: 0;
		width: 100%;
		max-width: 100%;
	}
	.MuiDialog-paper {
		margin: 0;
		pointer-events: auto;
	}
	.MuiPaper-paperScrollPaper {
		align-items: flex-end;
		background-color: transparent;
		box-shadow: none;
	}
	.MuiPaper-paperFullWidth {
		width: 100%;
		max-width: 100%;
	}
	.MuiDialogContent-root {
		padding-bottom: 0;
	}

	${({ $isBlurred }) =>
		$isBlurred &&
		css`
			backdrop-filter: blur(5px);
			pointer-events: none;
		`}
`;

const StyledDialogTitle = styled(DialogTitle)`
	height: 40px;
`;

const StyledDialogContentText = styled(DialogContentText)`
	marginbottom: 0;
	padding-top: ${sizes.spacing(1)};
	padding-bottom: ${sizes.spacing(1)};
`;

const StyledDialogActions = styled(DialogActions)`
	padding: ${`0 ${sizes.spacing(3.5)} ${sizes.spacing(2.5)} 0`} !important;
`;

const CustomDialog = ({
	title,
	isOpen,
	isBlurred,
	actions,
	onClose,
	message,
	children,
	fullWidth,
	leftButtonText,
	rightButtonText,
	leftButtonAction,
	rightButtonAction,
	...props
}) => (
	<StyledDialog
		open={isOpen}
		$isBlurred={isBlurred}
		onClose={onClose}
		fullWidth={fullWidth}
		hideBackdrop={true}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
		{...props}
	>
		{children ? (
			children
		) : (
			<>
				<StyledDialogTitle id="alert-dialog-title">{title}</StyledDialogTitle>
				<DialogContent>
					<StyledDialogContentText id="alert-dialog-description">{message}</StyledDialogContentText>
				</DialogContent>
				<StyledDialogActions>
					{actions ? (
						actions
					) : (
						<>
							<Button onClick={leftButtonAction} text={leftButtonText} label={`Dialog - ${leftButtonText} Button`} />
							{rightButtonText && (
								<Button
									secondary
									onClick={rightButtonAction}
									text={rightButtonText}
									label={`Dialog - ${rightButtonText} Button`}
								/>
							)}
						</>
					)}
				</StyledDialogActions>
			</>
		)}
	</StyledDialog>
);

CustomDialog.defaultProps = {
	title: '',
	message: '',
	actions: null,
	isOpen: false,
	children: null,
	fullWidth: false,
	onClose: () => {},
	leftButtonText: '',
	isBlurred: false,
	rightButtonText: '',
	leftButtonAction: () => {},
	rightButtonAction: () => {},
};

CustomDialog.propTypes = {
	isOpen: PropTypes.bool,
	actions: PropTypes.shape({}),
	title: PropTypes.string,
	onClose: PropTypes.func,
	children: PropTypes.node,
	fullWidth: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	isBlurred: PropTypes.bool,
	leftButtonAction: PropTypes.func,
	leftButtonText: PropTypes.string,
	rightButtonText: PropTypes.string,
	rightButtonAction: PropTypes.func,
};

export default CustomDialog;
