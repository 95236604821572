import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useInquiryOverlayValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				epcPartner: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.nullable()
					.required(t('EPC Partner is Required')),
				systemSize: yup.mixed().when('isPVProject', {
					is: true,
					then: yup
						.number()
						.positive(t('System size must be positive'))
						.test(
							'decimal-places',
							t('System size must have no more than 2 decimal places'),
							value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
						)
						.typeError(t('System size must be a number'))
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						)
						.nullable()
						.required(t('System size is Required')),
					otherwise: null,
				}),
				yield: yup
					.number()
					.required(t('Yield is Required'))
					.positive(t('Yield must be positive'))
					.test(
						'decimal-places',
						t('Yield must have no more than 2 decimal places'),
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.test(
						'yield-range',
						t('Yield must be between 500 and 3000'),
						value => value === undefined || (value <= 3000 && value >= 500),
					)
					.typeError(t('Yield must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				solarUtilisationRate: yup
					.number()
					.positive(t('Solar utilization rate must be positive'))
					.test(
						'decimal-places',
						t('Solar utilization rate must have no more than 2 decimal places'),
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.typeError(t('Solar utilization rate must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.max(100, 'Solar utilization rate must be less than or equals to 100')
					.nullable()
					.required(t('Solar utilization rate is Required')),
				expectedGridInjection: yup
					.number()
					.typeError(t('Expected grid injection must be a number'))
					.integer(t('Expected grid injection must be a whole number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.min(0, t('Expected grid injection must be more than or equal to 0'))
					.max(100, t('Expected grid injection must be less than or equal to 100'))
					.nullable(),
				epcVolumeExclVat: yup
					.number()
					.typeError(t('Must be a number'))
					.positive(t('Must be positive, check System size.'))
					.test(
						'decimal-places',
						'Must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable()
					.required(t('Required')),
				epcVolumeExclVatKwp: yup.mixed().when('isPVProject', {
					is: true,
					then: yup
						.number()
						.typeError(t('Must be a number'))
						.positive(t('Must be positive, check System size.'))
						.test(
							'decimal-places',
							'Must have no more than 2 decimal places',
							value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
						)
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						)
						.nullable()
						.required(t('Required')),
					otherwise: null,
				}),
				currency: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.nullable()
					.required(t('Currency is Required')),
				annualOmPrice: yup
					.number()
					.typeError(t('Annual O&M price must be a number'))
					.positive(t('Annual O&M price must be positive'))
					.nullable()
					.required(t('Annual O&M price is Required'))
					.test('decimal-places', 'Annual O&M must have no more than 2 decimal places', value =>
						String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					),
				annualOmPriceKwp: yup.mixed().when('isPVProject', {
					is: true,
					then: yup
						.number()
						.typeError(t('Must be a number'))
						.positive(t('Must be positive, check System size.'))
						.nullable()
						.required(t('Required'))
						.test('decimal-places', 'Must have no more than 2 decimal places', value =>
							String(value).match(/^\d*(\.\d{1,2})?$/),
						)
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						),
					otherwise: null,
				}),
				annualOmEscalationRate: yup
					.number()
					.typeError(t('Annual O&M escalation rate must be a number'))
					.min(0, t('Annual O&M escalation rate must be more than or equals to 0'))
					.max(100, t('Annual O&M escalation rate must be less than or equals to 100'))
					.test(
						'decimal-places',
						'Annual O&M escalation rate must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable()
					.required(t('Annual O&M escalation rate is Required')),
			}),
		[t],
	);
};

export default useInquiryOverlayValidationSchema;
