import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Icon from 'Common/components/icons/Icon';
import LayoutWithSidebar from 'Common/components/layout/LayoutWithSidebar';
import SingleSectionLayout from 'Common/components/layout/SingleSectionLayout';
import colors from 'Application/theme/colors';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 20px;
	text-align: center;

	svg {
		margin-bottom: 10px;
		color: ${colors.error.main};
		font-size: 80px;
	}

	h1 {
		margin: 0 0 20px;
		color: ${colors.error.main};
		font-weight: 200;
		font-size: 40px;
	}

	h2 {
		margin: 0;
		font-weight: 200;
		font-size: 20px;
	}
`;

const PageNotFound = () => {
	const { t } = useTranslation();

	const isAuthenticated = useSelector(state => state.user.isAuthenticated);

	const content = (
		<Wrapper>
			<Icon icon="errorOutline" size="huge" />
			<h1>{t('Error')} 404</h1>
			<h2>{t('The page you are looking for cannot be found or you dont have rights.')}</h2>
		</Wrapper>
	);

	if (isAuthenticated) {
		return <LayoutWithSidebar>{content}</LayoutWithSidebar>;
	}

	return <SingleSectionLayout>{content}</SingleSectionLayout>;
};

export default PageNotFound;
