import { fill } from 'lodash';

const prefillTableData = (data, startIndex, stopIndex, hasStageIndicators) => {
	const itemsCount = data.totalElements ?? 0;
	let error = false;
	let itemsFilled = fill(Array(itemsCount), {}).map((item, index) => {
		if (startIndex <= index && index <= stopIndex) {
			const indexInResultSet = index - startIndex;
			if (!data.content[indexInResultSet]) {
				error = true;
				return item;
			}
			if (hasStageIndicators) {
				const stageIndicators = {};
				data?.content[indexInResultSet]?.stageIndicators?.forEach(s => {
					stageIndicators[s.stage] = s;
				});
				return { ...data.content[indexInResultSet], stageIndicators };
			}
			return data.content[indexInResultSet] || item;
		}
		return item;
	});
	return { itemsCount, itemsFilled, error };
};

export default prefillTableData;
