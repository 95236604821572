export const PaymentGuaranteeTypes = {
	PAYMENT_SECURITY: 'PAYMENT_SECURITY',
	BANK_GUARANTEE: 'BANK_GUARANTEE',
	PARENT_COMPANY_GUARANTEE: 'PARENT_COMPANY_GUARANTEE',
	NOT_PROVIDED: 'NOT_PROVIDED',
	OTHER: 'OTHER',
};

export const PaymentGuaranteeOptions = [
	{
		label: 'Payment security (deposit)',
		value: PaymentGuaranteeTypes.PAYMENT_SECURITY,
	},
	{
		label: 'Bank Guarantee',
		value: PaymentGuaranteeTypes.BANK_GUARANTEE,
	},
	{
		label: 'Parent Company Guarantee',
		value: PaymentGuaranteeTypes.PARENT_COMPANY_GUARANTEE,
	},
	{
		label: 'Not provided',
		value: PaymentGuaranteeTypes.NOT_PROVIDED,
	},
	{
		label: 'Other',
		value: PaymentGuaranteeTypes.OTHER,
	},
];
