import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';

import DueDiligenceTile from 'Client/components/ClientDetails/Tabs/FinancialTab/Tiles/DueDiligenceTile/DueDiligenceTile';
import TaxesTile from 'Client/components/ClientDetails/Tabs/FinancialTab/Tiles/TaxesTile/TaxesTile';
import { CLIENT_DETAILS_PAGE_TAB_TILES } from 'Client/constants/clientTabForms';

const FinancialTab = ({ client, onEnterEditMode, onExitEditMode, onDataChange, onDirtyForm }) => {
	const handleDueDiligenceDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(CLIENT_DETAILS_PAGE_TAB_TILES.FINANCIAL.DUE_DILIGENCE, isDirty);
		},
		[onDirtyForm],
	);

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<DueDiligenceTile
					client={client}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={onDataChange}
					onDirtyForm={handleDueDiligenceDirtyForm}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<TaxesTile
					client={client}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={onDataChange}
					onDirtyForm={handleDueDiligenceDirtyForm}
				/>
			</Grid>
		</TilesGridWrapper>
	);
};

FinancialTab.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
};

FinancialTab.propTypes = {
	client: PropTypes.shape({}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDataChange: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
};

export default FinancialTab;
