import { useRef, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import { css } from 'styled-components';
import { ClickAwayListener } from '@mui/material';
import Loader from 'Common/components/Loader';
import useReponsive from 'Common/hooks/useResponsive';
import FilterPill from './FilterPill';
import ClearFilterButton from './ClearFilterButton';
import { PropTypes } from 'prop-types';

const FilterContainer = styled.div`
	position: relative;
`;

const positions = {
	left: css`
		left: ${({ selectedOptionsList }) => (selectedOptionsList ? `${sizes.spacing(10)}` : '0')};
		transform-origin: top left;
	`,
	right: css`
		right: 0;
		transform-origin: top right;
	`,
};

const FilterContentPopup = styled.div`
	position: absolute;
	padding: ${({ hasPadding }) => (hasPadding ? `${sizes.spacing(1.2)} ${sizes.spacing(2)}` : '0')};
	display: flex;
	flex-direction: row;
	top: 140%;
	min-width: ${sizes.base(60)};
	width: max-content;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	overflow: hidden;
	background-color: ${colors.common.white};
	z-index: 1000;
	transition: all 0.3s ease-in-out;

	${({ isOpen }) =>
		isOpen
			? css`
					opacity: 1;
					transform: scale(1);
			  `
			: css`
					opacity: 0;
					transform: scale(0);
					pointer-events: none;
			  `}

	${({ position }) => positions[position]}
`;

const LoaderContainer = styled.div`
	margin: 0 auto;
	width: 120px;
	height: 120px;
`;

const FilterModal = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 997;
	display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;
const RelativeContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`;

const FilterModalContent = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: fit-content;
	height: fit-content;
	background-color: ${colors.common.white};
	border-radius: 6px;
	padding: ${({ hasPadding }) => (hasPadding ? `${sizes.spacing(1.2)} ${sizes.spacing(2)}` : '0')};
`;

const ContentWithModal = ({ opened, popupContent, onClose, hasPadding }) => {
	const handleModalClick = e => {
		e.stopPropagation();
		onClose();
	};

	ContentWithModal.displayName = 'ContentWithModal';

	return (
		<FilterModal isOpen={opened} onClick={handleModalClick}>
			<RelativeContainer>
				<FilterModalContent hasPadding={hasPadding} onClick={e => e.stopPropagation()}>
					{popupContent}
				</FilterModalContent>
			</RelativeContainer>
		</FilterModal>
	);
};

ContentWithModal.propTypes = {
	opened: PropTypes.bool.isRequired,
	popupContent: PropTypes.node.isRequired,
	onClose: PropTypes.func.isRequired,
	hasPadding: PropTypes.bool.isRequired,
};

const Filter = ({
	label,
	count,
	isLoading,
	popupContent,
	hasSelected,
	handleClearOptions,
	hasPadding,
	toggleOpen,
	opened,
	useModal,
}) => {
	const [position, setPosition] = useState('left');
	const { isMobile } = useReponsive();

	const popupRef = useRef();
	const observer = useMemo(
		() =>
			new IntersectionObserver(
				entries => {
					entries.forEach(entry => {
						if (entry.isIntersecting) {
							if (entry.intersectionRatio < 1) {
								setPosition(prevPos => (prevPos === 'right' ? 'left' : 'right'));
							}
						}
					});
				},
				{ threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] },
			),
		[],
	);

	useEffect(() => {
		if (popupRef.current) {
			observer.observe(popupRef.current);
		}
		return () => {
			observer.disconnect();
		};
	}, [observer, popupRef]);

	const handlePillClick = () => {
		requestAnimationFrame(() => {
			toggleOpen(true);
		});
	};

	const handleClearClick = e => {
		e.stopPropagation();
		handleClearOptions(e);
	};

	const closeFilter = () => opened && toggleOpen(false);

	return (
		<ClickAwayListener onClickAway={closeFilter}>
			<FilterContainer>
				<FilterPill onClick={handlePillClick} hasSelected={hasSelected || (count && count > 0)} isOpened={opened}>
					{count && count > 0 ? `${label} (${count})` : label}
					{hasSelected || (!!count && count > 0) ? <ClearFilterButton onClick={handleClearClick} /> : <></>}
				</FilterPill>
				{useModal || isMobile ? (
					<ContentWithModal
						hasPadding={hasPadding}
						opened={opened}
						popupContent={popupContent}
						onClose={closeFilter}
					/>
				) : (
					<FilterContentPopup hasPadding={hasPadding} isOpen={opened} ref={popupRef} position={position}>
						{isLoading && opened ? (
							<LoaderContainer>
								<Loader />
							</LoaderContainer>
						) : (
							popupContent
						)}
					</FilterContentPopup>
				)}
			</FilterContainer>
		</ClickAwayListener>
	);
};

Filter.defaultProps = {
	count: 0,
	isLoading: false,
	hasSelected: false,
	hasPadding: false,
	useModal: false,
};

Filter.propTypes = {
	label: PropTypes.string.isRequired,
	count: PropTypes.number,
	isLoading: PropTypes.bool,
	popupContent: PropTypes.node.isRequired,
	hasSelected: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	handleClearOptions: PropTypes.func.isRequired,
	hasPadding: PropTypes.bool,
	toggleOpen: PropTypes.func.isRequired,
	opened: PropTypes.bool.isRequired,
	useModal: PropTypes.bool,
};

export default Filter;
