import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import crudModes from 'Common/constants/crudModes';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import editEntity from 'Entities/api/editEntity';
import createEntity from 'Entities/api/createEntity';
import useEntitiesFormInitialValues from 'Entities/components/EntitiesForm/hooks/useEntitiesFormInitialValues';
import useEntitiesFormValidationSchema from 'Entities/components/EntitiesForm/hooks/useEntitiesFormValidationSchema';
import convertFormValuesToRequestData from 'Entities/components/EntitiesForm/utils/convertFormValuesToRequestData';
import loadCountryOptions from 'Country/utils/loadCountryOptions';

import useReponsive from 'Common/hooks/useResponsive';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import useEcoligoUsersOptions from 'UserManagement/hooks/useEcoligoUsersOptions';
import { useTranslation } from 'react-i18next';
import { OverlayButtons } from 'Common/components/modals/Overlay';

const Form = styled.form`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
`;

const EntitiesCreateForm = ({ data, mode, onSubmit, onCancel, onDirty, isModal }) => {
	const { isMobile } = useReponsive();
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();

	const initialValues = useEntitiesFormInitialValues(data);
	const validationSchema = useEntitiesFormValidationSchema();

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleChange,
		handleBlur,
		setFieldValue,
		setFieldError,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				let response;

				if (mode === crudModes.CREATE) {
					response = await createEntity(abortController.signal, convertFormValuesToRequestData(values));

					notify(t('Entity created successfully.'), {
						type: toast.TYPE.SUCCESS,
					});
				} else if (mode === crudModes.EDIT) {
					response = await editEntity(abortController.signal, {
						...data,
						...convertFormValuesToRequestData(values),
					});

					notify(t('Entity saved successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				}

				const createdEntity = response.data;

				// Re-set all fields and mark them as not touched
				resetForm({ values });

				onSubmit(createdEntity);

				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	const excludedUserEmails = useMemo(
		() => [values?.director1?.value, values?.director2?.value, values?.director3?.value].filter(Boolean),
		[values],
	);
	const { ecoligoUsersOptions: directorsOptions, isLoadingEcoligoUsersOptions: isLoadingDirectorsOptions } =
		useEcoligoUsersOptions(excludedUserEmails);

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form $isMobile={isMobile} onSubmit={handleSubmit}>
			<Grid container columnSpacing={10}>
				<Grid item xs={12} md={4}>
					<FormField>
						<FormikInput
							id="companyName"
							name="companyName"
							label={t('Company name')}
							value={values.companyName}
							error={errors.companyName}
							touched={touched.companyName}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="abbreviation"
							name="abbreviation"
							label={t('Abbreviation')}
							value={values.abbreviation}
							error={errors.abbreviation}
							touched={touched.abbreviation}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSingleDatePicker
							id="dateOfRegistration"
							label={t('Date of registration')}
							startDateName="dateOfRegistration"
							error={errors.dateOfRegistration}
							touched={touched.dateOfRegistration}
							startDate={values.dateOfRegistration}
							setFieldValue={setFieldValue}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="registrationNumber"
							name="registrationNumber"
							label={t('Registration number')}
							value={values.registrationNumber}
							error={errors.registrationNumber}
							touched={touched.registrationNumber}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="taxNumber"
							name="taxNumber"
							label={t('Tax number')}
							value={values.taxNumber}
							error={errors.taxNumber}
							touched={touched.taxNumber}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="vatNumber"
							name="vatNumber"
							label={t('VAT number')}
							value={values.vatNumber}
							error={errors.vatNumber}
							touched={touched.vatNumber}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
				</Grid>
				<Grid item xs={12} md={4}>
					<FormField>
						<FormikSelect
							isAsync
							id={isModal ? 'countryModal' : 'country'}
							name="country"
							label={t('Country')}
							value={values.country}
							error={errors.country}
							touched={touched.country}
							isClearable
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadCountryOptions}
							menuPosition="fixed"
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="apartmentBuilding"
							name="apartmentBuilding"
							label={t('Apartment / Building')}
							value={values.apartmentBuilding}
							error={errors.apartmentBuilding}
							touched={touched.apartmentBuilding}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="line1"
							name="line1"
							label={t('Address line 1')}
							value={values.line1}
							error={errors.line1}
							touched={touched.line1}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="line2"
							name="line2"
							label={t('Adddress line 2')}
							value={values.line2}
							error={errors.line2}
							touched={touched.line2}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="cityRegion"
							name="cityRegion"
							label={t('City / Region')}
							value={values.cityRegion}
							error={errors.cityRegion}
							touched={touched.cityRegion}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="postCodeZip"
							name="postCodeZip"
							label={t('Post code / ZIP')}
							value={values.postCodeZip}
							error={errors.postCodeZip}
							touched={touched.postCodeZip}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
				</Grid>
				<Grid item xs={12} md={4}>
					<FormField>
						<FormikSelect
							id="director1"
							name="director1"
							label={t('Director 1')}
							value={values.director1}
							error={errors.director1}
							touched={touched.director1}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							userInfo={values.director1}
							options={directorsOptions}
							isLoading={isLoadingDirectorsOptions}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSelect
							id="director2"
							name="director2"
							label={t('Director 2')}
							value={values.director2}
							error={errors.director2}
							touched={touched.director2}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							userInfo={values.director2}
							options={directorsOptions}
							isLoading={isLoadingDirectorsOptions}
							isClearable
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSelect
							id="director3"
							name="director3"
							label={t('Director 3')}
							value={values.director3}
							error={errors.director3}
							touched={touched.director3}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							userInfo={values.director3}
							options={directorsOptions}
							isLoading={isLoadingDirectorsOptions}
							isClearable
							isOverlay
						/>
					</FormField>
				</Grid>
			</Grid>
			<OverlayButtons
				onCancel={onCancel}
				isLoading={isLoading}
				label="Project Create Form"
				submitButtonText={'Create Entity'}
			/>
		</Form>
	);
};

EntitiesCreateForm.defaultProps = {
	data: null,
	mode: crudModes.CREATE,
	isModal: false,
};

EntitiesCreateForm.propTypes = {
	data: PropTypes.shape({
		localizations: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
	}),
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
	mode: PropTypes.string,
	isModal: PropTypes.bool,
};

export default EntitiesCreateForm;
