import { pick, omit } from 'lodash';

const convertFormValuesToRequestData = values => ({
	...omit(values, ['country']),
	...pick(values, [
		'name',
		'registrationNumber',
		'website',
		'employees',
		'firstName',
		'lastName',
		'phone',
		'email',
	]),
	foundationYearAsInt: values.foundationYear ? values.foundationYear.value : null,
	industryId: values.industry ? values.industry.value : null,
	countryId: values.country ? values.country.value : null,
	address: {
		line1: values.line1,
		line2: values.line2,
		apartmentBuilding: values.apartmentBuilding,
		cityRegion: values.cityRegion,
		postCodeZip: values.postCodeZip,
	},
});

export default convertFormValuesToRequestData;
