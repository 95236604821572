import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslations from 'Application/i18n/translations/en';
import languages from 'Application/i18n/languages';
import defaultLanguage from 'Application/i18n/defaultLanguage';

const resources = {
	[languages.en.value]: {
		translation: enTranslations,
	},
};

// noinspection JSIgnoredPromiseFromCall,JSCheckFunctionSignatures
i18n.use(LanguageDetector);

if (initReactI18next) {
	i18n.use(initReactI18next).init({
		resources,

		detection: {
			order: ['cookie', 'localStorage'],
			caches: ['cookie', 'localStorage'],
		},

		fallbackLng: defaultLanguage.value,
		nsSeparator: false,

		interpolation: {
			escapeValue: false,
		},
	});
}

export default i18n;
