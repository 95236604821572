import { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { push } from 'redux-first-history';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { stringify } from 'qs';

import ButtonLink from 'Common/components/buttons/ButtonLink';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import Filters from 'Common/components/filter/FiltersContainer';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import EntitiesCreateOverlay from 'Entities/components/EntitiesForm/EntitiesCreateOverlay';

import useEntitiesFilterData from 'Entities/components/EntitiesList/hooks/useEntitiesFIlterData';
import EntitiesListTable from 'Entities/components/EntitiesList/EntitiesListTable';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import getProjectsParamEncoder from 'Projects/api/customParamEncoders/getProjectsParamEncoder';

const sortPropertiesMap = {
	country: 'country.name',
	cityRegion: 'address.cityRegion',
	primaryContactName: 'firstName',
};

const EntitiesList = () => {
	const { t } = useTranslation();

	const sortByQueryParam = useQueryParameter('sortBy');
	const filters = useQueryParameter('filters');

	const storedTableState = useSelector(state => state.tables.entities);
	const [shouldTableRender, setShouldTableRender] = useState(false);
	const dispatch = useDispatch();

	const location = useLocation();

	const [isCreateOpen, setIsCreateOpen] = useState(false);

	const handleCreateClose = () => {
		setIsCreateOpen(false);
	};

	useEffect(() => {
		if (!shouldTableRender) {
			if (location.search === '' && storedTableState && storedTableState.url) {
				dispatch(push(`/entities${storedTableState.url}`));
			}
			setShouldTableRender(true);
		}
	}, [dispatch, storedTableState, location.search, shouldTableRender]);

	const openExport = useCallback(
		exportWithOptions => {
			const sortById =
				sortByQueryParam && sortPropertiesMap[sortByQueryParam.id]
					? sortPropertiesMap[sortByQueryParam.id]
					: sortByQueryParam?.id;

			if (exportWithOptions) {
				window.open(
					`api/contract-parties/export-table-view?${
						sortByQueryParam
							? 'sort=' + sortById + '%2C' + (sortByQueryParam.desc ? 'DESC' : 'ASC')
							: 'sort=name%2CASC'
					}&${stringify(filters, {
						arrayFormat: 'repeat',
						allowDots: true,
						encoder: getProjectsParamEncoder.bind(null, Object.keys(filters)),
					})}&size=9999&`,

					'_blank',
				);
			} else {
				window.open(
					`api/contract-parties/export-table-view?&size=9999&${
						sortByQueryParam
							? 'sort=' + sortById + '%2C' + (sortByQueryParam.desc ? 'DESC' : 'ASC')
							: 'sort=name%2CASC'
					}`,
					'_blank',
				);
			}
		},
		[sortByQueryParam, filters],
	);

	const filterButtonRef = useRef();
	const { filtersData } = useEntitiesFilterData();

	return (
		<>
			<Helmet>
				<title>{t('Entities')}</title>
			</Helmet>

			<HeaderContainer>
				<HeaderTitle>{t('Entities')}</HeaderTitle>
				<HeaderActions>
					<div ref={filterButtonRef}></div>
					<ButtonLink
						label="Create Entity - Entities"
						onClick={() => setIsCreateOpen(true)}
						text={t('Create Entity')}
						icon="addRounded"
					/>
					<MoreActionsButton label=" artner More Actions">
						{filters && (
							<MenuItem
								onClick={() => openExport(true)}
								type="button"
								data-action="exportFiltered"
								label="Entities - Export filtered to CSV Menu Item"
							>
								{t('Export filtered to CSV')}
							</MenuItem>
						)}
						<MenuItem
							onClick={() => openExport(false)}
							type="button"
							data-action="exportAll"
							label="Entities - Export all to CSV Menu Item"
						>
							{t('Export all to CSV')}
						</MenuItem>
					</MoreActionsButton>
				</HeaderActions>
			</HeaderContainer>
			<Filters table="entities" buttonRef={filterButtonRef} filtersData={filtersData} />
			<EntitiesListTable />

			<EntitiesCreateOverlay
				isOpen={isCreateOpen}
				onClose={handleCreateClose}
				onFormSubmit={data => dispatch(push(`/entities/details/${data.id}`))}
			/>
		</>
	);
};

export default EntitiesList;
