import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import SharepointFoldersListTable from 'Integrations/components/SharePointFolders/SharePointFoldersListTable';
import { Tab, TabList, TabPanel, Tabs as TabsWrapper } from 'Common/components/tabs';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';

const SharePointFoldersList = () => {
	const { t } = useTranslation();
	const [selectedTab, setSelectedTab] = useState(0);

	return (
		<>
			<Helmet>
				<title>{t('Integrations')}</title>
			</Helmet>
			<HeaderContainer>
				<HeaderTitle>{t('Integrations')}</HeaderTitle>
			</HeaderContainer>
			<TabsWrapper selectedIndex={selectedTab} onSelect={setSelectedTab}>
				<TabList>
					<Tab>{t('SharePoint')}</Tab>
				</TabList>
				<TabPanel>
					<SharepointFoldersListTable />
				</TabPanel>
			</TabsWrapper>
		</>
	);
};

export default SharePointFoldersList;
