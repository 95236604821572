import React, { useMemo } from 'react';
import {
	DefaultTable,
	TableBodyCell,
	TableBodyCellContent,
	TableBodyRow,
	TableHeadCell,
} from 'Common/components/table/table';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { useCustomRowStyle } from 'Common/hooks/table/plugin-hooks/useCustomRowStyle';
import { useTranslation } from 'react-i18next';
import formatNumber from 'Common/utils/formatNumber';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useCustomRowProps } from 'Common/hooks/table/plugin-hooks/useCustomRowProps';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import ClientOfferTileActions from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/ClientOfferTile/Actions/ClientOfferTileActions';
import { typesSortOrder } from 'Projects/constants/offerTypes';
import { sortBy } from 'lodash';
import { contractTypes } from '../ClientContract/constants/contractTypes';
import { ppaTariffTypes } from '../ClientContract/constants/ppaTariffTypes';
import Big from 'big.js';

const hoveredRowStyles = css`
	background-color: ${colors.common.lightGrey};

	> div {
		background: transparent !important;
		background-color: transparent !important;

		&::before {
			content: '';
			background: transparent !important;
			background-color: transparent !important;
		}
		&::after {
			content: '';
			background: transparent !important;
			background-color: transparent !important;
		}
	}
`;

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
		background-color: transparent;
	}

	${TableBodyCell} {
		background: transparent;
	}

	${TableBodyCellContent} {
		height: 100%;
		display: flex;
		align-items: center;
		align-self: flex-start;
	}

	${TableBodyRow} {
		&:hover {
			${hoveredRowStyles}
		}

		&.hovered {
			${hoveredRowStyles}
		}
	}
`;

const ClientOfferCell = styled.div`
	display: flex;
	padding: 5px;

	font-weight: ${({ $isPrimary }) => ($isPrimary ? '700' : '500')};

	transform: scaleY(1);
	opacity: 1;
	transition: all 0.5s ease-in-out;
	transform-origin: top;
`;

const EPCPartnerNameCell = styled(ClientOfferCell)`
	padding-top: ${sizes.spacing(1.5)};
`;

const ClientOfferGroupColumn = styled.div`
	display: flex;
	flex-direction: column;

	&.hiddenColumn {
		${ClientOfferCell} {
			transform: scaleY(0.5);
			opacity: 0;
			padding: 0px;
		}
	}
`;

const ActionsCell = styled.div`
	height: 27px;
	display: flex;
	align-items: center;
`;

const ClientOfferList = ({ project, isCollapsed, handleUpdateProject }) => {
	const { t } = useTranslation();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('EPC Partner'),
				accessor: 'epcPartner',
				minWidth: 100,
				maxWidth: 150,
				customBodyCellContentStyles: {
					paddingTop: '0',
					alignItems: 'center',
				},
			},
			{
				Header: t('Type'),
				accessor: 'type',
				minWidth: 80,
				maxWidth: 100,
				customBodyCellContentStyles: {
					padding: '0',
				},
			},
			{
				Header: t('Contract Term'),
				accessor: 'period',
				minWidth: 80,
				maxWidth: 100,
				customBodyCellContentStyles: {
					padding: '0',
				},
			},
			{
				Header: t('Amount'),
				accessor: 'amount',
				minWidth: 80,
				maxWidth: 100,
				customBodyCellContentStyles: {
					padding: '0',
				},
			},
			{
				Header: t('Projected IRR'),
				accessor: 'irr',
				minWidth: 80,
				maxWidth: 100,
				customBodyCellContentStyles: {
					padding: '0',
				},
			},
			{
				Header: '',
				accessor: 'actions',
				minWidth: 50,
				maxWidth: 50,
				customCellStyles: {
					justifyContent: 'flex-end',
				},
				customBodyCellContentStyles: {
					padding: '0',
				},
			},
		],
		[t],
	);

	const data = useMemo(
		() =>
			sortBy(project?.epcOffers, e => typesSortOrder.indexOf(e.type))?.reduce((prev, epcOffer) => {
				if (!epcOffer.clientOffers.length) {
					return prev;
				}
				const containsPrimaryOffer = epcOffer.clientOffers.some(clientOffer => clientOffer.primaryOffer);

				const clientOfferGroup = {
					epcPartner: (
						<ClientOfferGroupColumn className={`${isCollapsed && !containsPrimaryOffer && 'hiddenColumn'}`}>
							<EPCPartnerNameCell $isPrimary={containsPrimaryOffer}>
								{epcOffer?.epcPartner?.name ?? ''}
							</EPCPartnerNameCell>
						</ClientOfferGroupColumn>
					),
					type: (
						<ClientOfferGroupColumn className={`${isCollapsed && !containsPrimaryOffer && 'hiddenColumn'}`}>
							{epcOffer.clientOffers
								.filter(({ primaryOffer }) => (isCollapsed ? primaryOffer : true))
								.map(clientOffer => (
									<ClientOfferCell key={clientOffer.id} $isPrimary={clientOffer.primaryOffer}>
										{clientOffer?.contractType?.name || '-'}
									</ClientOfferCell>
								))}
						</ClientOfferGroupColumn>
					),
					period: (
						<ClientOfferGroupColumn className={`${isCollapsed && !containsPrimaryOffer && 'hiddenColumn'}`}>
							{epcOffer.clientOffers
								.filter(({ primaryOffer }) => (isCollapsed ? primaryOffer : true))
								.map(clientOffer => (
									<ClientOfferCell key={clientOffer.id} $isPrimary={clientOffer.primaryOffer}>
										{clientOffer?.duration ? `${clientOffer?.duration} years` : '-'}
									</ClientOfferCell>
								))}
						</ClientOfferGroupColumn>
					),
					amount: (
						<ClientOfferGroupColumn className={`${isCollapsed && !containsPrimaryOffer && 'hiddenColumn'}`}>
							{epcOffer.clientOffers
								.filter(({ primaryOffer }) => (isCollapsed ? primaryOffer : true))
								.map(clientOffer => (
									<ClientOfferCell key={clientOffer.id} $isPrimary={clientOffer.primaryOffer}>
										{clientOffer?.contractTypeName === contractTypes.PPA
											? clientOffer.ppaTariffType?.displayName === ppaTariffTypes.FIXED
												? `${clientOffer?.ppaPricePerKwh?.currency || '-'} ${
														formatNumber(clientOffer?.ppaPricePerKwh?.value, 0) || ''
												  }`
												: `${clientOffer?.floorTariff?.currency || '-'} ${
														formatNumber(clientOffer?.floorTariff?.value, 0) || ''
												  }`
											: `${clientOffer?.monthlyLeaseRate?.currency || '-'} ${
													clientOffer?.monthlyLeaseRate?.value || ''
											  }`}
									</ClientOfferCell>
								))}
						</ClientOfferGroupColumn>
					),
					irr: (
						<ClientOfferGroupColumn className={`${isCollapsed && !containsPrimaryOffer && 'hiddenColumn'}`}>
							{epcOffer.clientOffers
								.filter(({ primaryOffer }) => (isCollapsed ? primaryOffer : true))
								.map(clientOffer => (
									<ClientOfferCell key={clientOffer.id} $isPrimary={clientOffer.primaryOffer}>
										{clientOffer?.irr ? Big(clientOffer?.irr).times(100).toString() : 0} %
									</ClientOfferCell>
								))}
						</ClientOfferGroupColumn>
					),
					actions: (
						<ClientOfferGroupColumn className={`${isCollapsed && !containsPrimaryOffer && 'hiddenColumn'}`}>
							{epcOffer.clientOffers
								.filter(({ primaryOffer }) => (isCollapsed ? primaryOffer : true))
								.map(clientOffer => (
									<ActionsCell key={clientOffer.id}>
										<ClientOfferTileActions
											epcOffer={epcOffer}
											clientOfferId={clientOffer.id}
											project={project}
											reloadProject={handleUpdateProject}
										/>
									</ActionsCell>
								))}
						</ClientOfferGroupColumn>
					),

					rowsCount: isCollapsed ? 1 : epcOffer.clientOffers.length,
					epcOfferId: epcOffer.id,

					containsPrimaryOffer,
				};

				return [...prev, clientOfferGroup];
			}, []),
		[handleUpdateProject, isCollapsed, project],
	);

	const generateHeight = useCallback(
		row => {
			const isPrimary = row.original.containsPrimaryOffer;

			const rowsCount = row.original.rowsCount;

			const isRowHidden = isCollapsed && !isPrimary;

			const hiddenRowStyles = {
				height: 0,
				opacity: 0,
				padding: 0,
			};

			const visibleRowStyles = {
				height: 25 + rowsCount * 25,
				opacity: 1,
			};

			return {
				...(isRowHidden ? hiddenRowStyles : visibleRowStyles),
				transition: 'height 0.5s ease-in-out, opacity 0.5s ease-in-out, padding 0.5s ease-in-out',
			};
		},
		[isCollapsed],
	);

	const setHoverOnEpcOffer = (epcOfferId, isHovered) => {
		document.querySelectorAll(`.epc-offers-table [data-epc-offer="${epcOfferId}"]`).forEach(row => {
			row.classList[isHovered ? 'add' : 'remove']('hovered');
		});
	};

	const {
		getTableProps,
		getTableHeaderProps,
		getTableFooterProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useStandardTable(
		{
			data,
			columns,
			getRowBasedCustomCellStyles: generateHeight,
			getCustomRowStyles: generateHeight,
			getCustomRowProps: row => ({
				'data-epc-offer': row.original.epcOfferId,
				onMouseEnter: () => setHoverOnEpcOffer(row.original.epcOfferId, true),
				onMouseLeave: () => setHoverOnEpcOffer(row.original.epcOfferId, false),
			}),
		},
		useCustomCellStyles,
		useCustomRowStyle,
		useCustomRowProps,
	);

	return (
		<TableWrapper className="client-offers-table">
			<DefaultTable
				getTableProps={getTableProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				getTableFooterProps={getTableFooterProps}
				rows={rows}
				headerGroups={headerGroups}
				prepareRow={prepareRow}
			/>
		</TableWrapper>
	);
};

ClientOfferList.defaultProps = {
	project: undefined,
	isCollapsed: false,
	handleUpdateProject: async () => {},
};

ClientOfferList.propTypes = {
	project: PropTypes.shape({
		epcOffers: PropTypes.arrayOf(
			PropTypes.shape({
				clientOffers: PropTypes.arrayOf(
					PropTypes.shape({
						epcPartner: PropTypes.string,
					}),
				),
			}),
		),
	}),
	isCollapsed: PropTypes.bool,
	handleUpdateProject: PropTypes.func,
};

export default ClientOfferList;
