import { useTranslation } from 'react-i18next';
import FormikInput from 'Common/components/form/FormikInput';
import { components } from 'react-select';
import { PropTypes } from 'prop-types';
import { SectionWrapper } from 'Common/components/modals/OverlaySections';
import { Grid } from '@mui/material';
import ComparableFormField from 'Common/components/form/ComparableFormField';
import crudModes from 'Common/constants/crudModes';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

const SelectInput = ({ selectProps: { onPaste }, ...props }) => (
	<components.Input {...props} onPaste={onPaste} />
);

SelectInput.defaultProps = {
	selectProps: {
		onPaste: () => null,
	},
};

SelectInput.propTypes = {
	selectProps: PropTypes.shape({
		onPaste: PropTypes.func,
	}),
};

const OfferOutputSection = ({ errors, touched, values, handleChange, handleBlur, mode }) => {
	const { t } = useTranslation();

	const CurrencySign = values.saasCurrency
		? () => <>{values.saasCurrency.value === 'EUR' ? '€' : values.saasCurrency.value}</>
		: null;

	return mode !== crudModes.VIEW ? (
		<SectionWrapper>
			<Grid container columnSpacing={10}>
				<Grid item xs={12} md={6}>
					<ComparableFormField
						$compareValue={
							values.compareOffer && values.primaryOfferData.expectedClientSavings
								? values.primaryOfferData.expectedClientSavings.valueWithCurrency
								: ''
						}
					>
						<FormikInput
							id="expectedClientSavings"
							name="expectedClientSavings"
							label={t('Expected Client Savings')}
							value={values.expectedClientSavings}
							error={errors.expectedClientSavings}
							touched={touched.expectedClientSavings}
							tooltip={t('Year 1 and in contract term')}
							onChange={handleChange}
							onBlur={handleBlur}
							prefix={CurrencySign}
							isOverlay
						/>
					</ComparableFormField>
					<ComparableFormField
						$compareValue={values.compareOffer ? values.primaryOfferData.solarEnergyReplacement : ''}
						data-last-field
					>
						<FormikInput
							id="solarEnergyReplacement"
							name="solarEnergyReplacement"
							label={t('Solar Energy Replacement for Client')}
							value={values.solarEnergyReplacement}
							error={errors.solarEnergyReplacement}
							touched={touched.solarEnergyReplacement}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</ComparableFormField>
				</Grid>
			</Grid>
		</SectionWrapper>
	) : (
		<SectionWrapper>
			<Grid container columnSpacing={10}>
				<Grid item xs={12} md={6}>
					<KeyValueVisualization
						id="expectedClientSavings"
						title={t('Expected Client Savings')}
						value={values.expectedClientSavings}
						prefix={CurrencySign}
					/>
					<KeyValueVisualization
						id="solarEnergyReplacement"
						title={t('Solar Energy Replacement for Client')}
						value={values.solarEnergyReplacement}
					/>
				</Grid>
			</Grid>
		</SectionWrapper>
	);
};

OfferOutputSection.defaultProps = {
	selectedPrimaryOption: {},
};

OfferOutputSection.propTypes = {
	selectedPrimaryOption: PropTypes.shape({
		name: PropTypes.string,
	}),
	errors: PropTypes.shape({
		percentageOnGridTariff: PropTypes.string,
		expectedClientSavings: PropTypes.string,
		solarEnergyReplacement: PropTypes.string,
	}).isRequired,
	touched: PropTypes.shape({
		expectedClientSavings: PropTypes.bool,
		solarEnergyReplacement: PropTypes.bool,
	}).isRequired,
	values: PropTypes.shape({
		contractType: PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }),
		ppaTariffType: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
		saasCurrency: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
		expectedClientSavings: PropTypes.string,
		solarEnergyReplacement: PropTypes.string,
		compareOffer: PropTypes.bool,
		primaryOfferData: PropTypes.shape({
			expectedClientSavings: PropTypes.shape({ valueWithCurrency: PropTypes.string }),
			solarEnergyReplacement: PropTypes.string,
		}),
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	handleBlur: PropTypes.func.isRequired,
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
};

export default OfferOutputSection;
