import { useMemo } from 'react';

const fallbackValues = {
	financeDetailsProjects: [],
};

const useProjectsFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			financeDetailsProjects: data?.finance_details?.projects
				? data?.finance_details?.projects.map(e => ({
						...data.financeDetailsProjects,
						projectId: e.id,
						systemSizeAllocatedValue: data?.finance_details?.financeDetailsProjects
							? data?.finance_details?.financeDetailsProjects.filter(item => item.projectId === e.id)[0]
									.systemSizeAllocatedValue
							: 0,
						financeDetailsProjects: [
							data?.finance_details?.financeDetailsProjects.filter(item => item.projectId === e.id)[0],
						],
						financeDetailsId: data.auto_id && data.auto_id,
				  }))
				: [],
		}),
		[data],
	);
export default useProjectsFormInitialValues;
