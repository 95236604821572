import projectTypes from 'Projects/constants/projectTypes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EPCSection from '../../Sections/EPCSection';
import OMSection from '../../Sections/OMSection';
import ProposalSection from '../../Sections/ProposalSection';
import SizeSection from '../../Sections/SizeSection';
import VolumeSection from '../../Sections/VolumeSection';

const useInquiryOverlaySections = project => {
	const { t } = useTranslation();
	const isPVProject = project?.projectType?.name === projectTypes.PV;

	return useMemo(
		() => [
			{
				title: 'General',
				section: props => <EPCSection {...props} />,
			},
			{
				title: 'Size',
				section: props => <SizeSection {...props} isPVProject={isPVProject} />,
			},
			{
				title: t('EPC volume'),
				section: props => <VolumeSection {...props} isPVProject={isPVProject} />,
			},
			{
				title: t('O&M'),
				section: props => <OMSection {...props} isPVProject={isPVProject} />,
			},
			{
				title: t('Proposal'),
				section: props => <ProposalSection {...props} isPVProject={isPVProject} />,
			},
		],
		[isPVProject, t],
	);
};

export default useInquiryOverlaySections;
