import styled from 'styled-components/macro';

import sizes from 'Application/theme/sizes';
import colors from 'Application/theme/colors';

import Title from 'Common/components/title/Title';

const FixedTitle = styled(Title)`
	position: fixed;
	display: ${props => (props.isVisible ? 'flex' : 'none')};
	width: 100%;
	top: 0;
	background-color: ${colors.grey.light};
	height: 50px;
	left: ${props => props.left}px;
	z-index: 998;
	padding-left: ${sizes.spacing(5)};
	text-transform: capitalize;
`;

export default FixedTitle;
