import api from 'Application/api/api';
import getProjectsParamEncoder from 'Projects/api/customParamEncoders/getProjectsParamEncoder';

const getOlapStatistics = (signal, filters = {}, searchQuery) => {
	const params = {
		...filters,
		query: searchQuery,
	};

	return api.get('/projects/get-olap-statistics', {
		params,
		signal,
		timeout: 100000,
		paramsSerializer: {
			encode: getProjectsParamEncoder.bind(undefined, Object.keys(filters)),
			indexes: true,
			dots: true,
		},
	});
};

export default getOlapStatistics;
