import api from 'Application/api/api';

const getPartnerTypesById = (signal, id, details = false) => {
	const params = {
		details: details,
	};

	return api.get(`/partner-types/by-id/${id}`, {
		params,
		signal,
	});
};

export default getPartnerTypesById;
