import { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import sizes from 'Application/theme/sizes';
import colors from 'Application/theme/colors';
import { updateSidePanelData, updateSidePanelContentData } from 'Application/reducers/reduxSidePanel';

import CustomStatusDropdown from 'Common/components/StatusDropdown/StatusDropdown';
import AccordionSummary from 'Common/components/accordion/AccordionSummary';

import getStepById from 'PMWorkflow/api/getStepById';
import UsersPreview from 'Common/components/usersPreview/UsersPreview';
import Icon from 'Common/components/icons/Icon';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { useParams } from 'react-router';
import { SIDE_PANEL_CONTENT_TYPES } from 'Common/components/SidePanel/constants/sidePanelContentTypes';

const StyledAccordion = styled.div`
	margin: 0 !important;
	border-radius: 20px !important;

	position: relative;
	background-color: ${colors.common.sidebarGrey} !important;

	border: solid 1.5px transparent;

	.MuiCollapse-root {
		overflow: hidden !important;
	}

	&.hasMarker {
		border-color: ${colors.primary.main};
	}

	${({ $hasMarker }) =>
		$hasMarker &&
		css`
			border-color: ${colors.primary.dark};
		`}

	&:last-child {
		margin-bottom: 30px !important;
	}

	&:before {
		content: none !important;
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		border-radius: 20px !important;
		z-index: -1;

		@keyframes completeStep {
			0% {
				background: ${colors.primary.light};
			}
			100% {
				background: ${colors.grey.lightest};
			}
		}

		${({ $isCompleted }) =>
			$isCompleted
				? css`
						animation: completeStep 3s ease-in-out forwards;
				  `
				: css`
						background-color: ${colors.grey.lightest} !important;
				  `};
	}
`;

const StepHeader = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
	font-size: 15px;
`;

const StepTitle = styled.div`
	color: ${colors.text.greyDark};
	font-size: 16px;
	font-weight: 700;
	display: flex;
	align-items: center;
	display: grid;
	margin-left: 15px;

	${({ hasMargin }) =>
		hasMargin &&
		css`
			margin-bottom: ${sizes.spacing(2)};
		`}
`;

const StyledGrid = styled(Grid)`
	gap: ${sizes.spacing(1)};
`;

const Name = styled.span`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: inline-block;
`;

const StyledIcon = styled(Icon)`
	width: 30px !important;
	height: 30px !important;
`;

const UserContainer = styled.div``;

const ProgressStep = ({ step, index, status, activity, refetchProgress, setHideOverflow }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isCompleted, setIsCompleted] = useState(false);
	const [stepClicked, setStepClicked] = useState(false);
	const project = useSelector(state => state.currentProject);
	const sidePanelData = useSelector(state => state.sidePanel);
	const stepContainer = useRef();
	const isStepSidepanelOpen = useMemo(
		() => sidePanelData.isOpen && sidePanelData.contentData?.step?.id === step.id,
		[sidePanelData.isOpen, sidePanelData.contentData?.step?.id, step.id],
	);

	const { projectId } = useParams();

	const abortController = useAbortController();

	const onStatusUpdate = async status => {
		try {
			await refetchProgress();
			if (status === 'COMPLETED') {
				setIsCompleted(true);
			}
		} catch (error) {
			showToastError(error);
		}
	};

	const isProjectOnHold = !(status.condition === 'OPERATION' || status.condition === 'IN_PROGRESS');

	const projectOnHoldStatus = status.condition
		.split('_')
		.join(' ')
		.toLowerCase()
		.replace(/(?:^|\s)\S/g, function (a) {
			return a.toUpperCase();
		});

	const handleStepClick = async () => {
		if (isStepSidepanelOpen) return;

		setStepClicked(true);

		dispatch(
			updateSidePanelData({
				isOpen: true,
				type: SIDE_PANEL_CONTENT_TYPES.PROJECT_STEP,
				isLoading: true,
				headerData: {
					to: '/projects',
					isBreadcrumb: true,
					breadCrumb: 'Projects',
					activeBreadcrumb: `${project.externalId} - ${
						project?.client?.name ? project?.client?.name : t('Unknown client')
					} ${project?.description ? ` (${project?.description})` : ''}`,
					toActiveBreadcrumb: `/projects/details/${project.id}`,
				},
				contentData: {
					project,
					step: {
						id: step.id,
					},
					onDataChange: refetchProgress,
					showHistory: false,
					activityId: activity.id,
					dropdownProps: {
						isProjectOnHold,
						projectOnHoldStatus,
						activityCanBeCompleted: activity.canBeCompleted,
					},
				},
			}),
		);
	};

	const responsiblePerson = step?.responsiblePerson;

	const handleMouseEnter = () => {
		setHideOverflow(true);
	};

	const handleMouseLeave = () => {
		setHideOverflow(false);
	};

	const setSidePanelStepData = useCallback(async () => {
		try {
			dispatch(updateSidePanelData({ isSoftLoading: true }));
			const stepResponse = await getStepById(abortController.signal, step.id).then(res => res.data);

			dispatch(
				updateSidePanelContentData({
					project,
					step: { ...stepResponse, activityTitle: activity.title },
					onDataChange: refetchProgress,
					activityId: activity.id,
					showHistory: false,
					dropdownProps: {
						isProjectOnHold,
						projectOnHoldStatus,
						activityCanBeCompleted: activity.canBeCompleted,
					},
				}),
			);
			dispatch(
				updateSidePanelData({
					headerData: {
						to: '/projects',
						isBreadcrumb: true,
						breadCrumb: 'Projects',
						activeBreadcrumb: `${project.externalId} - ${
							project?.client?.name ? project?.client?.name : t('Unknown client')
						} ${project?.description ? ` (${project?.description})` : ''}`,
						toActiveBreadcrumb: `/projects/details/${project.id}`,
					},
					isSoftLoading: false,
					isLoading: false,
				}),
			);
		} catch (error) {
			showToastError(error);
		}
		// ! Adding refetch project causes memory leak
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		activity.canBeCompleted,
		activity.id,
		activity.title,
		dispatch,
		isProjectOnHold,
		project,
		projectOnHoldStatus,
		step.id,
		t,
	]);

	const scrollIntoStep = useCallback(() => {
		if (stepContainer.current) {
			// Todo: timeout to scroll in the right moment
			stepContainer.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}, [stepContainer]);

	useEffect(() => {
		if (isStepSidepanelOpen) {
			setSidePanelStepData();
			if (!stepClicked) {
				scrollIntoStep();
			}
		} else {
			setStepClicked(false);
		}
	}, [activity, dispatch, isStepSidepanelOpen, scrollIntoStep, setSidePanelStepData, step, stepClicked]);

	return (
		<StyledAccordion
			onClick={handleStepClick}
			$isCompleted={isCompleted}
			$hasMarker={isStepSidepanelOpen}
			data-project-progress-marker
			data-step-wrapper={step.title + '-' + activity.title}
			ref={stepContainer}
		>
			<AccordionSummary>
				<Grid item container xs={12}>
					<Grid item container xs={8} sm={10}>
						<StepHeader
							width={{
								xs: '104px',
							}}
							item
							xs={4}
							sm={3}
							md={2}
						>
							{t('Step ' + index)}
						</StepHeader>
						<Grid item container xs={8} sm={9} md={10}>
							<StepTitle>
								<Name>{step.title}</Name>
							</StepTitle>
						</Grid>
					</Grid>
					<StyledGrid item container xs={4} sm={2} justifyContent="flex-end" alignItems="center">
						{responsiblePerson ? (
							<UserContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
								<UsersPreview
									popupProps={{
										$alignment: 'right',
									}}
									users={{ users: responsiblePerson }}
								/>
							</UserContainer>
						) : (
							<StyledIcon icon="noUser" />
						)}
						<CustomStatusDropdown
							step={step}
							projectId={projectId}
							onStatusUpdate={onStatusUpdate}
							isProjectOnHold={isProjectOnHold}
							projectOnHoldStatus={projectOnHoldStatus}
							activityCanBeCompleted={activity.canBeCompleted}
						/>
					</StyledGrid>
				</Grid>
			</AccordionSummary>
		</StyledAccordion>
	);
};

ProgressStep.defaultValues = {
	step: {},
	index: 0,
	status: '',
	activity: {},
	refetchProgress: () => {},
	setHideOverflow: () => {},
};

ProgressStep.propTypes = {
	step: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		responsiblePerson: PropTypes.shape({}),
		title: PropTypes.string,
	}).isRequired,
	index: PropTypes.number.isRequired,
	status: PropTypes.shape({
		condition: PropTypes.string,
	}).isRequired,
	activity: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		canBeCompleted: PropTypes.bool,
		title: PropTypes.string,
	}).isRequired,
	refetchProgress: PropTypes.func.isRequired,
	setHideOverflow: PropTypes.func.isRequired,
};

export default ProgressStep;
