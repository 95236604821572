import Button from 'Common/components/buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import { updateSidePanelData } from 'Application/reducers/reduxSidePanel';
import { clearNewNotificationsEntries } from 'Notifications/reduxNotifications';
import styled from 'styled-components/macro';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SIDE_PANEL_CONTENT_TYPES } from 'Common/components/SidePanel/constants/sidePanelContentTypes';

const BouncingDot = styled.div`
	position: absolute;
	top: 2px;
	right: 2px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: red;
`;

const BtnContainer = styled.div`
	position: relative;

	&.animate {
		@keyframes bounce {
			0% {
				transform: scale(1);
			}

			50% {
				transform: scale(1.6);
			}

			100% {
				transform: scale(1);
			}
		}

		${BouncingDot} {
			animation: bounce 1s ease-in-out forwards;
		}

		@keyframes ring {
			0% {
				transform: rotate(0deg);
			}

			25% {
				transform: rotate(45deg);
			}

			75% {
				transform: rotate(-45deg);
			}

			100% {
				transform: rotate(0deg);
			}
		}

		svg {
			animation: ring 1s ease-in-out forwards;
		}
	}
`;

const NotificationsControl = () => {
	const dispatch = useDispatch();
	const notificationsBtn = useRef();
	const [showDot, setShowDot] = useState(false);
	const newNotificationsEntries = useSelector(state => state.notifications?.newNotificationsEntry);
	const mountedRef = useRef();

	const triggerAnimation = useCallback(() => {
		if (notificationsBtn.current) {
			notificationsBtn.current.classList.add('animate');

			setTimeout(() => {
				notificationsBtn.current.classList.remove('animate');
			}, 1000);
		}
	}, [notificationsBtn]);

	const handleNotificationsBtnClick = () => {
		dispatch(
			updateSidePanelData({
				isOpen: true,
				type: SIDE_PANEL_CONTENT_TYPES.NOTIFICATIONS,
				isLoading: false,
			}),
		);

		setShowDot(false);
		dispatch(clearNewNotificationsEntries());
	};

	useEffect(() => {
		if (newNotificationsEntries?.length > 0) {
			setShowDot(true);
			if (mountedRef.current) {
				triggerAnimation();
			}
		}
		if (!mountedRef.current) {
			mountedRef.current = true;
		}
	}, [newNotificationsEntries, triggerAnimation, mountedRef]);

	return (
		<BtnContainer ref={notificationsBtn}>
			<Button medium icon="notifications" onClick={handleNotificationsBtnClick} label="Notifications Button" />

			{showDot && <BouncingDot />}
		</BtnContainer>
	);
};

export default NotificationsControl;
