import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { useLocation } from 'react-router-dom';

import TranchesListTable from 'Campaigns/components/Tabs/TranchesList/TranchesListTable';
import useSmartResize from 'Common/hooks/table/useSmartResize';

const TranchesList = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const storedTableState = useSelector(state => state.tables.tranches);
	const [shouldTableRender, setShouldTableRender] = useState(false);
	const filtersRef = useRef();

	useEffect(() => {
		if (!shouldTableRender) {
			if (location.search === '' && storedTableState && storedTableState.url) {
				dispatch(push(`/tranches${storedTableState?.url}`));
			}
			setShouldTableRender(true);
		}
	}, [dispatch, storedTableState, location.search, shouldTableRender]);

	// eslint-disable-next-line
	const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
	useSmartResize(filtersRef, setForceUpdateCounter);

	return <>{(shouldTableRender || !storedTableState?.url) && <TranchesListTable />}</>;
};

export default TranchesList;
