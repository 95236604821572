import api from 'Application/api/api';
import adaptProjectResponseData from 'Projects/api/adapters/adaptProjectResponseData';

const assignCampaigns = async (signal, data) => {
	const response = await api.post('/projects/assign-campaigns', data, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptProjectResponseData(response.data),
	};
};

export default assignCampaigns;
