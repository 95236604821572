import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import AddressTile from 'Client/components/ClientDetails/Tabs/GeneralTab/Tiles/AddressTile/AddressTile';
import ClientDetailsTile from 'Client/components/ClientDetails/Tabs/GeneralTab/Tiles/ClientDetailsTile/ClientDetailsTile';
import UtilityList from 'Client/components/UtilityList/UtilityList';

import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import { CLIENT_DETAILS_PAGE_TAB_TILES } from 'Client/constants/clientTabForms';

import getClientUtilityRates from 'Client/api/getClientUtilityRates';
import useAbortController from 'Common/hooks/useAbortController';

const GeneralTab = ({ client, onEnterEditMode, onExitEditMode, onDataChange, onDirtyForm }) => {
	const abortController = useAbortController();
	const [utilityRates, setUtilityRates] = useState();

	const onSave = data => {
		onDataChange(data);
		onExitEditMode();
	};

	const handleClientDetailsDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(CLIENT_DETAILS_PAGE_TAB_TILES.GENERAL.DETAILS, isDirty);
		},
		[onDirtyForm],
	);

	const handleAddressDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(CLIENT_DETAILS_PAGE_TAB_TILES.GENERAL.ADDRESS, isDirty);
		},
		[onDirtyForm],
	);

	const fetchUtilityRates = useCallback(async () => {
		const response = await getClientUtilityRates(abortController.signal, client.id);
		setUtilityRates(response?.data);
	}, [abortController.signal, client]);

	useEffect(() => {
		fetchUtilityRates();
	}, [fetchUtilityRates]);

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<ClientDetailsTile
					client={client}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={onSave}
					onDirtyForm={handleClientDetailsDirtyForm}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<AddressTile
					client={client}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={onSave}
					onDirtyForm={handleAddressDirtyForm}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
				<UtilityList client={client} utilityRates={utilityRates} onDataChange={fetchUtilityRates} />
			</Grid>
		</TilesGridWrapper>
	);
};

GeneralTab.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
};

GeneralTab.propTypes = {
	client: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDataChange: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
};

export default GeneralTab;
