/* eslint-disable react/prop-types */

import { useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';

import GraphControl from 'Impact/components/GraphControl';
import TileHeader from 'Common/components/Tile/TileHeader';
import GraphWithLegend from 'Impact/tiles/ImpactDemographicTile/GraphWithLegend';
import ImpactChart from 'Impact/components/ImpactChart';
import { graphOptions, chartTypes, filterOptions } from 'Impact/constants/options';

import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';

import { useTranslation } from 'react-i18next';
import useReponsive from 'Common/hooks/useResponsive';

const TileContainer = styled(Grid)`
	width: 100%;
	height: 100%;
	display: flex;
	background-color: ${colors.common.tileBackgroundGrey};
	border-radius: 20px;
	padding-bottom: 10px;
	border: 1px solid transparent;
	&:hover {
		border-color: ${colors.primary.main};
	}
`;

const TitleHeaderWrapper = styled.div`
	padding: 16px;
	height: 45px;
`;

const BarGraphWrapper = styled.div`
	width: 100%;
	height: ${({ $isMobile }) => ($isMobile ? '290px' : '320px')};
	padding: ${({ $isColumn, $isMobile }) =>
		$isMobile
			? `${sizes.spacing(7.5)} ${sizes.spacing(3)} 0 0`
			: $isColumn
			? `0 ${sizes.spacing(3)} ${sizes.spacing(12)} ${sizes.spacing(6)}`
			: `${sizes.spacing(15)} ${sizes.spacing(3)} 0 0`};
`;

const LineGraphWrapper = styled.div`
	width: 100%;
	padding: 0px ${sizes.spacing(2)} 0px ${sizes.spacing(2)};
	height: 220px;
`;

const PieGraphWrapper = styled.div`
	padding-top: ${({ $isColumn, $isMobile }) =>
		$isMobile ? '0' : $isColumn ? `${sizes.spacing(0.25)}` : `${sizes.spacing(15)}`};
`;
const ControlWrapper = styled.div`
	display: flex;
	justify-content: ${({ $isColumn }) => ($isColumn ? 'center' : 'flex-end')};
	padding: ${sizes.spacing(3)} ${sizes.spacing(2.5)} ${sizes.spacing(3)} 0;
`;

const ImpactDemographicTile = ({ data, pieData, crowdPieData, lineData, isColumn }) => {
	const { t } = useTranslation();

	const { isMobile, isTablet, is4k } = useReponsive();

	const [showLineChart, setShowLineChart] = useState(false);
	const [labelIndex, setLabelIndex] = useState(0);
	const labels = ['Donut Chart', 'Growth Chart'];

	const filters = useMemo(
		() => ({
			fields: ['registeredCrowdInvestors', 'totalCrowdInvestors', 'notActiveCrowdInvestors'],
			time: 'Max',
		}),
		[],
	);

	const lineOptions = useMemo(
		() => ({ ...graphOptions[chartTypes.STACKED_LINE], ...filterOptions[filters.time] }),
		[filters],
	);

	const onControlSelect = index => {
		const idx = index < 0 ? labels.length - 1 : index === labels.length ? 0 : index;
		setShowLineChart(Boolean(idx));
		setLabelIndex(idx);
	};

	return (
		<>
			<TileContainer container>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<TitleHeaderWrapper>
						<TileHeader title={t('crowd')} />
					</TitleHeaderWrapper>
				</Grid>

				<Grid item xs={is4k ? 3 : 12}>
					{!showLineChart ? (
						<>
							<GraphWithLegend pieData={crowdPieData} type={chartTypes.DOUGHNUT} />
						</>
					) : (
						<LineGraphWrapper>
							<ImpactChart data={lineData} type={chartTypes.STACKED_LINE} filters={filters} options={lineOptions} />
						</LineGraphWrapper>
					)}
					<ControlWrapper $isColumn={isColumn}>
						<GraphControl labels={labels} selectedIndex={labelIndex} onControlSelect={onControlSelect} />
					</ControlWrapper>
				</Grid>
				<Grid item xs={is4k ? 3 : 12}>
					<PieGraphWrapper $isColumn={isColumn} $isMobile={isMobile || isTablet}>
						<GraphWithLegend pieData={pieData} type={chartTypes.PIE} />
					</PieGraphWrapper>
				</Grid>
				<Grid item xs={is4k ? 6 : 12}>
					<BarGraphWrapper $isColumn={isColumn} $isMobile={isMobile || isTablet}>
						<ImpactChart data={data} type={chartTypes.BAR} options={graphOptions[chartTypes.BAR]} />
					</BarGraphWrapper>
				</Grid>
			</TileContainer>
		</>
	);
};

ImpactDemographicTile.defaultProps = {
	isColumn: false,
};

ImpactDemographicTile.propTypes = {
	data: PropTypes.shape({}).isRequired,
	pieData: PropTypes.shape({}).isRequired,
	crowdPieData: PropTypes.shape({}).isRequired,
	lineData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	isColumn: PropTypes.bool,
};

export default ImpactDemographicTile;
