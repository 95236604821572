import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useShareholderFormValidationSchema = availableEntityShares => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				name: yup.string().required(t('Name is required')),
				shareholderNumberOfShares: yup
					.number()
					.required(t('Number of shares is required'))
					.min(0, t('Number of shares must be greater than or equal to 0'))
					.test('isInteger', t('Number of shares must be an integer'), value => Number.isInteger(value))
					.max(availableEntityShares, t(`Must be less than or equal to ${availableEntityShares}`)),
			}),
		[t, availableEntityShares],
	);
};

export default useShareholderFormValidationSchema;
