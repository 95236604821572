import Big from 'Common/utils/customBig';

const adaptEpcContractResponseData = data => ({
	...data,
	annualOmEscalationRate: data.annualOmEscalationRate
		? Number(Big(data.annualOmEscalationRate).times(100).toString())
		: data.annualOmEscalationRate,
	milestoneValues: data.milestoneValues
		? data.milestoneValues.map(value => ({
				...value,
				value: value.value ? Number(Big(value.value).times(100).toString()) : value.value,
		  }))
		: data.milestoneValues,
});

export default adaptEpcContractResponseData;
