import getRealtedBankAccounts from 'Entities/api/getRealtedBankAccounts';

const loadContractPartyBankAccounts = async (signal, inputValue) => {
	const { data: bankAccounts } = await getRealtedBankAccounts(signal, inputValue);
	return bankAccounts.map(bankAccount => ({
		label: bankAccount.name,
		value: bankAccount.id,
		subtitle: bankAccount.accountNumber,
	}));
};

export default loadContractPartyBankAccounts;
