import PropTypes from 'prop-types';
import formatNumber from 'Common/utils/formatNumber';
import { FormikNumberRange } from 'Common/components/form/FormikNumberRange';
import Filter from 'Common/components/filter/Filter';
import { omit } from 'lodash';
import { useEffect, useRef } from 'react';

const NumberRangeFilter = ({
	label,
	minName,
	maxName,
	minValue,
	maxValue,
	lowerBound,
	upperBound,
	setFilterValue,
	minProps,
	maxProps,
	opened,
	...props
}) => {
	const minRef = useRef();
	const maxRef = useRef();
	const roundedLowerBound = Math.ceil(lowerBound);
	const roundedUpperBound = Math.ceil(upperBound);

	const filledRanges = !!minValue && !!maxValue ? 2 : !!minValue || !!maxValue ? 1 : 0;

	const handleClearOptions = () => {
		setFilterValue({ [minName]: '', [maxName]: '' });
	};

	useEffect(() => {
		if (opened && minRef.current && maxRef.current) {
			if (minValue && !maxValue) {
				maxRef.current.focus();
			} else {
				minRef.current.focus();
			}
		}
	}, [opened, minRef, maxRef, minValue, maxValue]);

	return (
		<Filter
			label={label}
			count={filledRanges}
			handleClearOptions={handleClearOptions}
			opened={opened}
			hasPadding
			{...props}
			popupContent={
				<FormikNumberRange
					minId={minName}
					minName={minName}
					maxId={maxName}
					maxName={maxName}
					minValue={String(minValue)}
					maxValue={String(maxValue)}
					lowerBound={roundedLowerBound}
					upperBound={roundedUpperBound}
					setFieldValue={setFilterValue}
					minRef={minRef}
					maxRef={maxRef}
					minProps={{
						placeholder: formatNumber(roundedLowerBound),
						...minProps,
					}}
					maxProps={{
						placeholder: formatNumber(roundedUpperBound),
						...maxProps,
					}}
					{...omit(props, ['opened', 'filled'])}
					isFilterInput
				/>
			}
		/>
	);
};

NumberRangeFilter.defaultProps = {
	minValue: '',
	maxValue: '',
	lowerBound: 0,
	upperBound: 100,
	minProps: {},
	maxProps: {},
	opened: false,
};

NumberRangeFilter.propTypes = {
	label: PropTypes.string.isRequired,
	minName: PropTypes.string.isRequired,
	maxName: PropTypes.string.isRequired,
	minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	lowerBound: PropTypes.number,
	upperBound: PropTypes.number,
	setFilterValue: PropTypes.func.isRequired,
	minProps: PropTypes.shape({}),
	maxProps: PropTypes.shape({}),
	opened: PropTypes.bool,
};

export default NumberRangeFilter;
