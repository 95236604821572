import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';
import useReponsive from 'Common/hooks/useResponsive';

import { css } from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	&:not(:first-child) {
		min-width: 0;
		max-width: fit-content;
		flex: 1;
	}

	${({ $isMobile }) =>
		$isMobile &&
		css`
			max-width: 200px;

			* {
				font-size: 16px !important;
				white-space: nowrap;
			}
		`};
`;

const Arrow = styled.div`
	margin: 0 5px;
	font-size: 35px;
`;

const StyledLink = styled(Link)`
	font-size: 30px;
	font-weight: 500;
	color: ${colors.text.primary};
	text-decoration: none;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Title = styled.div`
	font-size: 26px;
	font-weight: 600;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 5px 0 0;
`;

const capitalize = word => word.charAt(0).toUpperCase() + word.slice(1);

const Breadcrumb = ({ active, to, children, ...props }) => {
	const { isMobile } = useReponsive();

	return (
		<Wrapper $isMobile={isMobile}>
			{!active ? (
				<>
					<StyledLink to={to} active={active.toString()}>
						{capitalize(children)}
					</StyledLink>
					<Arrow> / </Arrow>
				</>
			) : (
				<Title active={Number(active)} {...props}>
					{children}
				</Title>
			)}
		</Wrapper>
	);
};

Breadcrumb.defaultProps = {
	to: '',
	active: false,
	children: null,
};

Breadcrumb.propTypes = {
	to: PropTypes.string,
	active: PropTypes.bool,
	children: PropTypes.node,
};

export default Breadcrumb;
