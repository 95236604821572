import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useProjectDefaultsFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				berlinBarcelonaFlightsPerCO2Tone: yup
					.number()
					.nullable()
					.positive(t('Must be positive'))
					.typeError(t('Must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'decimal-places',
						t('Must have no more than 6 decimal places'),
						value => value === undefined || String(value).match(/^\d*(\.\d{0,6})?$/),
					)
					.required(t('Required')),
				carKmPerCO2Ton: yup
					.number()
					.nullable()
					.positive(t('Must be positive'))
					.typeError(t('Must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'decimal-places',
						t('Must have no more than 6 decimal places'),
						value => value === undefined || String(value).match(/^\d*(\.\d{0,6})?$/),
					)
					.required(t('Required')),
				hotBalloonPerCO2Tone: yup
					.number()
					.nullable()
					.positive(t('Must be positive'))
					.typeError(t('Must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'decimal-places',
						t('Must have no more than 6 decimal places'),
						value => value === undefined || String(value).match(/^\d*(\.\d{0,6})?$/),
					)
					.required(t('Required')),
				yearsOfFootprintPerCO2Tone: yup
					.number()
					.nullable()
					.positive(t('Must be positive'))
					.typeError(t('Must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'decimal-places',
						t('Must have no more than 6 decimal places'),
						value => value === undefined || String(value).match(/^\d*(\.\d{0,6})?$/),
					)
					.required(t('Required')),
			}),
		[t],
	);
};

export default useProjectDefaultsFormValidationSchema;
