import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';

import Loader from 'Common/components/Loader';
import ButtonLink from 'Common/components/buttons/ButtonLink';
import EditableTile from 'Common/components/Tile/EditableTile';

import colors from 'Application/theme/colors';
import LoaderWrapper from 'Common/components/LoaderWrapper';

const NumberPill = styled.span`
	background: ${colors.success.main};
	border-radius: 20px;
	color: ${colors.grey.lightest};
	padding: 2px 12px;
	margin-right: 5px;
	font-size: 14px;
`;

const Text = styled.span`
	color: ${colors.text.primary};
	font-size: 16px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 16px;
	margin-bottom: 32px !important;
`;

const InquiryCountText = styled(Text)`
	font-weight: 700;
`;

const TextWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-top: 16px;
	margin-bottom: 32px !important;
`;

const DashboardInquiresTile = ({ reviewedInquiriesCount, isLoading }) => {
	const { t } = useTranslation();

	return (
		<EditableTile title={t('Inquiries')}>
			{isLoading ? (
				<LoaderWrapper>
					<Loader />
				</LoaderWrapper>
			) : (
				<>
					<Text>{t('Have a new Project opportunity you want to introduce to ecoligo?')}</Text>
					<ButtonWrapper>
						<ButtonLink to="/epc/inquiries/create" text={t('Create Inquiry')} icon="addRounded" />
					</ButtonWrapper>
					{reviewedInquiriesCount > 0 && (
						<>
							<TextWrapper>
								<NumberPill>{reviewedInquiriesCount}</NumberPill>
								<InquiryCountText>
									{t(`${reviewedInquiriesCount === 1 ? 'Inquiry is' : 'Inquiries are'} being reviewed by ecoligo.`)}
								</InquiryCountText>
							</TextWrapper>

							<ButtonWrapper>
								<ButtonLink
									label="View Inquiries - EPC Partners Dashboard"
									to="/epc/inquiries"
									text={t('View Inquiries')}
								/>
							</ButtonWrapper>
						</>
					)}
				</>
			)}
		</EditableTile>
	);
};

DashboardInquiresTile.propTypes = {
	reviewedInquiriesCount: PropTypes.number.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default DashboardInquiresTile;
