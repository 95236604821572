import colors from 'Application/theme/colors';
import { useMemo } from 'react';

const calculateLabelColor = (
	isDisabled,
	hasError,
	isHighlighted,
	isTile,
	isInEditMode,
	hasHover,
	hasFocus,
) => {
	if (isDisabled) {
		return colors.text.greyLight;
	}

	if (hasError) {
		return colors.error.main;
	}

	if (isHighlighted) {
		return hasHover ? colors.text.highlightHover : colors.text.highlightDefault;
	}

	if (hasFocus) {
		return colors.primary.dark;
	}

	if (isTile) {
		if (isInEditMode) {
			if (hasHover) {
				return colors.common.darkGreen;
			} else {
				return colors.primary.dark;
			}
		} else {
			if (hasHover) {
				return colors.primary.main;
			} else {
				return colors.grey.main;
			}
		}
	}

	return hasHover ? colors.primary.main : colors.grey.main;
};

const useLabelColor = ({ isDisabled, hasError, isHighlighted, isTile, isInEditMode, hasHover, hasFocus }) =>
	useMemo(
		() => calculateLabelColor(isDisabled, hasError, isHighlighted, isTile, isInEditMode, hasHover, hasFocus),
		[isDisabled, hasError, isHighlighted, isTile, isInEditMode, hasHover, hasFocus],
	);

export { calculateLabelColor, useLabelColor };
