import api from 'Application/api/api';

const validateAutimationRules = (signal, expression) =>
	api.post(
		'/projects/expressions/validate',
		{ expression },
		{
			signal,
		},
	);

export default validateAutimationRules;
