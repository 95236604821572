import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import crudModes from 'Common/constants/crudModes';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import editShareholder from 'Shareholders/api/editShareholder';
import createShareholder from 'Shareholders/api/createShareholder';
import useShareholderFormValidationSchema from 'Shareholders/components/ShareholderForm/hooks/useShareholderFormValidationSchema';
import useShareholderFormInitialValues from 'Shareholders/components/ShareholderForm/hooks/useShareholderFormInitialValues';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import sizes from 'Application/theme/sizes';
import assignShareholder from 'Entities/api/assignShareholder';
import H4 from 'Common/components/headers/H4';
import { ModalFormButtons } from 'Common/components/form/ModalForm';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const FieldsWrapper = styled.div`
	width: 100%;
`;

const AvailableSharesContainer = styled.div`
	padding-bottom: ${sizes.spacing(2)};
	max-width: 100%;
`;

const ShareholderForm = ({ data, entityId, mode, availableEntityShares, onSubmit, onCancel, onDirty }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();

	const initialValues = useShareholderFormInitialValues(data);
	const validationSchema = useShareholderFormValidationSchema(availableEntityShares);

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		setFieldTouched,
		handleChange,
		handleBlur,
		setFieldError,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				if (mode === crudModes.CREATE) {
					const { data: createdShareholder } = await createShareholder(abortController.signal, {
						...values,
						numberOfShares: values?.shareholderNumberOfShares || 0,
					});

					await assignShareholder(abortController.signal, {
						contractPartyId: entityId,
						shareholdersIds: [createdShareholder.id],
					});

					onSubmit();

					notify(t('Shareholder created successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				} else if (mode === crudModes.EDIT) {
					await editShareholder(abortController.signal, {
						...data,
						...values,
						numberOfShares: values?.shareholderNumberOfShares || 0,
					});

					onSubmit();

					notify(t('Shareholder saved successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				}

				resetForm({ values });
				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form onSubmit={handleSubmit}>
			<FieldsWrapper>
				<FormField>
					<FormikInput
						id="name"
						name="name"
						label={t('Name')}
						value={values.name}
						error={errors.name}
						touched={touched.name}
						onChange={handleChange}
						setFieldTouched={setFieldTouched}
						onBlur={handleBlur}
						isRequired
					/>
				</FormField>
				<FormField>
					<FormikInput
						id="shareholderNumberOfShares"
						name="shareholderNumberOfShares"
						label={t('Number of shares')}
						value={values.shareholderNumberOfShares}
						error={errors.shareholderNumberOfShares}
						touched={touched.shareholderNumberOfShares}
						setFieldTouched={setFieldTouched}
						onChange={handleChange}
						onBlur={handleBlur}
						isRequired
					/>
				</FormField>
				<AvailableSharesContainer>
					<H4>{Math.max(0, availableEntityShares - (values.shareholderNumberOfShares ?? 0))} shares available</H4>
				</AvailableSharesContainer>
			</FieldsWrapper>
			<ModalFormButtons
				onCancel={onCancel}
				isLoading={isLoading}
				label="Entities Shareholder Form"
				submitButtonText={mode === crudModes.CREATE ? 'Create' : 'Save'}
			/>
		</Form>
	);
};

ShareholderForm.defaultProps = {
	data: null,
	entityId: null,
};

ShareholderForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	mode: PropTypes.string.isRequired,
	availableEntityShares: PropTypes.number.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
};

export default ShareholderForm;
