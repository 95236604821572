import defaultDateFormat from 'Common/constants/defaultDateFormat';
import en from 'date-fns/locale/en-GB';
import { format, utcToZonedTime } from 'date-fns-tz';
import defaultTimezone from 'Common/constants/defaultTimezone';

const formatDate = (date, dateFormat = defaultDateFormat, timeZone = defaultTimezone) => {
	return format(utcToZonedTime(new Date(date), timeZone), dateFormat, {
		locale: en,
		timezone: timeZone,
	});
};

export default formatDate;
