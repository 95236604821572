import styled from 'styled-components/macro';

import colors from 'Application/theme/colors';

const Title = styled.div`
	color: ${colors.text.greyDark};
	text-transform: capitalize;
	font-size: 16px;
	font-weight: 700;
	display: flex;
	align-items: center;
`;

export default Title;
