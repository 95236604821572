import { useMemo } from 'react';

const fallbackValues = {
	country: null,
	apartmentBuilding: '',
	cityRegion: '',
	line1: '',
	line2: '',
	postCodeZip: '',
};

const useAddressTileFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			country: data?.country
				? {
						label: data?.country?.name,
						value: data?.country?.id,
				  }
				: null,
			apartmentBuilding: data?.address?.apartmentBuilding,
			cityRegion: data?.address?.cityRegion,
			line1: data?.address?.line1,
			line2: data?.address?.line2,
			postCodeZip: data?.address?.postCodeZip,
		}),
		[data],
	);

export default useAddressTileFormInitialValues;
