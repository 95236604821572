import api from 'Application/api/api';

const reorderActivities = async (signal, ids) => {
	const response = await api.patch('/activity-templates/reorder', ids, {
		signal,
	});

	return response;
};

export default reorderActivities;
