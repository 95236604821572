import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Overlay, { useOverlayTitles } from 'Common/components/modals/Overlay';
import SectionContainer from 'Common/components/modals/SectionContainer';
import EntitiesCreateForm from './EntitiesCreateForm';
import crudModes from 'Common/constants/crudModes';

const EntitiesCreateOverlay = ({ isOpen, onClose, onFormSubmit }) => {
	const { t } = useTranslation();
	const { name, label } = useOverlayTitles(t('Client'), crudModes.CREATE);

	const breadcrumbList = [
		{
			label: 'Entities',
			href: '/entities',
		},
		{
			label: name,
		},
	];

	return (
		<Overlay
			label={label}
			breadcrumbList={breadcrumbList}
			isOpen={isOpen}
			onClose={onClose}
			onSave={onFormSubmit}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<SectionContainer name="Entity">
					<EntitiesCreateForm onSubmit={handleSubmit} onCancel={onCancel} onDirty={setIsFormDirty} isModal />
				</SectionContainer>
			)}
		</Overlay>
	);
};

EntitiesCreateOverlay.defaultProps = {
	data: {},
	onSubmit: undefined,
	onCancel: () => {},
	onDirty: undefined,
	isModal: false,
};

EntitiesCreateOverlay.propTypes = {
	data: PropTypes.shape({}),
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default EntitiesCreateOverlay;
