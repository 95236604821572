import React from 'react';
import FormikAsyncSelectFilter from './AsyncSelectFilter';

const StepsFilter = ({ name, values, setFilterValue, ...props }) => {
	const onStepStatusChange = (stepKey, value) => {
		const updatedStepIndex = values.findIndex(step => step.key === stepKey);

		const updatedSteps = [
			...values.slice(0, updatedStepIndex),
			{ ...values[updatedStepIndex], showCompleted: value },
			...values.slice(updatedStepIndex + 1),
		];

		setFilterValue({ [name]: updatedSteps });
	};

	return (
		<FormikAsyncSelectFilter
			{...props}
			name={name}
			values={values}
			setFilterValue={setFilterValue}
			stepFilterProps={{ onStepStatusChange }}
		/>
	);
};

StepsFilter.propTypes = {
	...FormikAsyncSelectFilter.propTypes,
};

export default StepsFilter;
