const ppaTarifOptions = [
	{ value: 'FIXED', label: 'Fixed' },
	{ value: 'FLOATING', label: 'Floating' },
];

const PPA_TARIFS = {
	FIXED: 'FIXED',
	FLOATING: 'FLOATING',
};

export { PPA_TARIFS, ppaTarifOptions };
