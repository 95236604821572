import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components/macro';
import ReactTimeAgo from 'react-time-ago';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

import ConditionalGridSection from 'Common/components/ConditionalGridSection';

import getStepHistory from 'PMWorkflow/api/getStepHistory';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';

const Wrapper = styled(ConditionalGridSection)`
	padding-bottom: 6px;
`;

const Content = styled(Grid)`
	padding-bottom: 6px;
`;

const Message = styled(Grid)`
	display: flex;
	justify-content: space-between;
	padding: 12px 0px;
`;

const Text = styled.span`
	font-size: 16px;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: left;
	color: ${colors.text.black};
`;

const Title = styled(Text)`
	text-transform: uppercase;
	color: ${colors.text.primary};
`;

const History = styled(Text)`
	font-weight: 400;
`;

const LeftSide = styled(Grid)`
	width: 300px;
`;

const Date = styled(History)`
	min-width: 85px;
	color: ${colors.common.grey};
`;

const capitalize = word => word.charAt(0).toUpperCase() + word.slice(1);

const SidePanelHistory = ({ step, showHistory }) => {
	const { t } = useTranslation();
	const [state, setState] = useState({
		isLoading: true,
		data: [],
	});

	const abortController = useAbortController();

	const user = item =>
		item?.lastModifiedBy === 'admin@ecoligo.com' ? 'pulse ' : item?.lastModifiedByUsernames + ' ';

	useEffect(() => {
		(async () => {
			if (showHistory) {
				try {
					const response = await getStepHistory(abortController.signal, step.id);
					setState(prevState => ({ ...prevState, isLoading: false, data: response.data }));
				} catch (error) {
					showToastError(error, t('An error occured while getting step history.'));
				}
			}
		})();
	}, [t, step, showHistory, abortController.signal]);

	return (
		<Wrapper hasFlag={showHistory}>
			{state.isLoading ? (
				<>
					<ContentLoader
						width="100%"
						height="120px"
						backgroundColor={colors.common.lightGrey}
						foregroundColor={colors.common.headerGrey}
					>
						<rect x="0" y="10" rx="10" ry="10" width="260" height="20" />
						<rect x="377" y="10" rx="10" ry="10" width="62" height="20" />
						<rect x="0" y="38" rx="10" ry="10" width="101" height="20" />
						<rect x="0" y="66" rx="10" ry="10" width="260" height="20" />
						<rect x="377" y="66" rx="10" ry="10" width="62" height="20" />
						<rect x="0" y="94" rx="10" ry="10" width="101" height="20" />
					</ContentLoader>
				</>
			) : (
				<Content>
					<Title>{t('History')}</Title>
					{state.data.map((item, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<Message key={`message-${index}`}>
							<LeftSide>
								<Text>{user(item)}</Text>
								<History>{t('changed the status to ') + capitalize(item.status.toLowerCase()) + '.'}</History>
							</LeftSide>
							<Date>
								<ReactTimeAgo date={item.lastModifiedDate} timeStyle="round" />
							</Date>
						</Message>
					))}
				</Content>
			)}
		</Wrapper>
	);
};

SidePanelHistory.propTypes = {
	step: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }).isRequired,
	showHistory: PropTypes.bool.isRequired,
};

export default SidePanelHistory;
