import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import OverlaySections from 'Common/components/modals/OverlaySections';
import useClientOfferOverlayValues from './hooks/useClientOfferOverlayValues';
import useClientOfferOverlaySections from './hooks/useClientOfferOverlaySections';
import useClientOfferOverlayValidationSchema from './hooks/useClientOfferOverlayValidationSchema';
import crudModes from 'Common/constants/crudModes';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import convertFormValuesToRequestData from './utils/convertFormValuesToRequestData';
import getClientOfferById from '../../../ClientContract/api/getClientOfferById';
import createClientOffer from '../../../ClientContract/api/createClientOffer';
import editClientOffer from '../../../ClientContract/api/editClientOffer';
import uploadClientOfferDocuments from '../../../ClientContract/api/uploadClientOfferDocuments';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import styled from 'styled-components';
import PopperTooltip from 'Common/components/tooltip/PopperTooltip';
import colors from 'Application/theme/colors';
import Icon from 'Common/components/icons/Icon';
import sizes from 'Application/theme/sizes';
import Switch from 'Common/components/form/Switch';
import { findProjectPrimaryOffer } from 'Projects/utils/salesHelpers';
import { useOverlayTitles } from 'Common/components/modals/Overlay';

const HeaderContentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${sizes.spacing(4)};
`;

const SwitchWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: ${sizes.spacing(1)};
`;

const SwitchText = styled.p`
	margin: 0;
	${sizes.fontSize.small};
	color: ${colors.text.grey};
`;

const ClientOfferOverlay = ({
	isOpen,
	onClose,
	onFormSubmit,
	mode,
	clientOfferId,
	epcOfferId,
	epcOffer,
	project,
}) => {
	const abortController = useAbortController();

	const [isLoading, setIsLoading] = useState(false);
	const [clientOffer, setClientOffer] = useState({});
	const { t } = useTranslation();
	const { name, label, saveBtnLabel, closeBtnLabel } = useOverlayTitles('Client Offer', mode);

	const projectId = useSelector(state => state.currentProject.id);

	const primaryOffer = findProjectPrimaryOffer(project.epcOffers);

	const shouldShowCompareOffer = useMemo(
		() => mode !== crudModes.VIEW && primaryOffer && (!clientOfferId || primaryOffer.id !== clientOfferId),
		[primaryOffer, clientOfferId, mode],
	);

	const HeaderContent = ({ values, handleChange, handleBlur, touched, setFieldValue }) => (
		<HeaderContentWrapper>
			{shouldShowCompareOffer ? (
				<SwitchWrapper>
					<PopperTooltip tooltip="Compare values with the primary offer">
						<Icon icon="helpOutline" size="small" color={colors.primary.main} />
					</PopperTooltip>
					<SwitchText>Compare</SwitchText>
					<Switch value={values.compareOffer} setValue={e => setFieldValue('compareOffer', e)} />
				</SwitchWrapper>
			) : null}
			<FormikCheckbox
				label={'Primary'}
				id="primaryOffer"
				name="primaryOffer"
				checked={values?.primaryOffer}
				onChange={handleChange}
				onBlur={handleBlur}
				isDisabled={mode === crudModes.VIEW}
				touched={touched?.primaryOffer}
			/>
		</HeaderContentWrapper>
	);

	HeaderContent.propTypes = {
		values: PropTypes.shape({ primaryOffer: PropTypes.bool, compareOffer: PropTypes.bool }).isRequired,
		touched: PropTypes.shape({ primaryOffer: PropTypes.bool }).isRequired,
		handleChange: PropTypes.func.isRequired,
		handleBlur: PropTypes.func.isRequired,
		setFieldValue: PropTypes.func.isRequired,
	};

	useEffect(() => {
		if (mode !== crudModes.CREATE && clientOfferId) {
			(async () => {
				try {
					setIsLoading(true);
					const res = await getClientOfferById(abortController.signal, clientOfferId);
					setClientOffer(res.data);
					setIsLoading(false);
				} catch (err) {
					showToastError(err, 'Something went wrong fetching client offer');
					if (!axios.isCancel(err)) {
						setIsLoading(false);
					}
				}
			})();
		}
	}, [mode, clientOfferId, abortController.signal]);

	const handleCreateSubmit = useCallback(
		async data => {
			try {
				const clientOffer = await createClientOffer(
					abortController.signal,
					convertFormValuesToRequestData(data, epcOfferId),
				);
				if (data.epcOffer) {
					const formData = new FormData();
					formData.append('document', data.epcOffer);
					await uploadClientOfferDocuments(abortController.signal, formData, clientOffer.data.id);
				}
				await onFormSubmit();
			} catch (err) {
				showToastError(err, 'Failed creating client offer');
			}
		},
		[epcOfferId, onFormSubmit, abortController.signal],
	);

	const handleEditSubmit = useCallback(
		async data => {
			try {
				await editClientOffer(abortController.signal, clientOfferId, convertFormValuesToRequestData(data));
				if (data.epcOffer) {
					const formData = new FormData();
					formData.append('document', data.epcOffer);
					await uploadClientOfferDocuments(abortController.signal, formData, clientOfferId);
				}
				await onFormSubmit();
			} catch (err) {
				showToastError(err);
			}
		},
		[clientOfferId, onFormSubmit, abortController.signal],
	);

	const breadcrumbList = [
		{
			label: t('Sales'),
			link: `/projects/details/${projectId}`,
		},
		{
			label: name,
		},
	];

	const { defaultValues, sectionFields } = useClientOfferOverlayValues(
		mode,
		clientOffer,
		epcOffer,
		project,
		shouldShowCompareOffer ? primaryOffer : null,
	);
	const sections = useClientOfferOverlaySections(epcOfferId, breadcrumbList);
	const validationSchema = useClientOfferOverlayValidationSchema();

	return (
		<OverlaySections
			label={label}
			breadcrumbList={breadcrumbList}
			isOpen={isOpen}
			onClose={onClose}
			onSaveOverlay={mode === crudModes.CREATE ? handleCreateSubmit : handleEditSubmit}
			isLoadingContent={isLoading}
			sections={sections}
			defaultValues={defaultValues}
			sectionFields={sectionFields}
			validationSchema={validationSchema}
			headerContent={HeaderContent}
			saveBtnLabel={saveBtnLabel}
			closeBtnLabel={closeBtnLabel}
			mode={mode}
		/>
	);
};

ClientOfferOverlay.defaultProps = {
	clientOfferId: undefined,
	epcOfferId: undefined,
	project: {},
	epcOffer: {},
};

ClientOfferOverlay.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	epcOfferId: PropTypes.number,
	onClose: PropTypes.func.isRequired,
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
	clientOfferId: PropTypes.number,
	project: PropTypes.shape({ epcOffers: PropTypes.arrayOf(PropTypes.shape({})) }),
	epcOffer: PropTypes.shape({}),
};

export default ClientOfferOverlay;
