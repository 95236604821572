import api from 'Application/api/api';

const createRfep = (signal, values, projectId) =>
	api.post(
		`/tenders?projectId=${projectId}`,

		values,
		{
			signal,
		},
	);

export default createRfep;
