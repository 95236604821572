import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import EditableTile from 'Common/components/Tile/EditableTile';

import editEntity from 'Entities/api/editEntity';
import useCapitalTileFormInitialValues from 'Entities/components/EntitiesDetails/Tabs/CapitalTab/CapitalTile/hooks/useCapitalTileFormInitialValues';
import useCapitalTileFormValidationSchema from 'Entities/components/EntitiesDetails/Tabs/CapitalTab/CapitalTile/hooks/useCapitalTileFormValidationSchema';
import convertCapitalTileFormValues from 'Entities/components/EntitiesDetails/Tabs/CapitalTab/CapitalTile/utils/convertCapitalTileFormValues';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import formatNumber from 'Common/utils/formatNumber';

const CapitalTile = ({ entity, onEnterEditMode, onExitEditMode, onDirtyForm, onSave, highlightLabels }) => {
	const { t } = useTranslation();

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useCapitalTileFormInitialValues(entity);
	const validationSchema = useCapitalTileFormValidationSchema();

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleBlur,
		setFieldTouched,
		handleChange,
		setFieldValue,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			let response;
			setIsSavingChanges(true);

			try {
				response = await editEntity(abortController.signal, {
					...entity,
					...convertCapitalTileFormValues(values),
				});

				if (onDirtyForm) {
					onDirtyForm(false);
				}

				onSave(response.data);
				handleCancel();
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});

	const CurrencySign = values.currency
		? () => (values.currency.value === 'EUR' ? '€' : values.currency.value)
		: null;

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Capital')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
			zIndex={1}
		>
			<FormField>
				<FormikInput
					id="shareCapital"
					name="shareCapital"
					label={t('Share capital')}
					value={isInEditMode ? values.shareCapital : formatNumber(values.shareCapital, 0)}
					error={errors.shareCapital}
					touched={touched.shareCapital}
					setFieldTouched={setFieldTouched}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isRequired
					prefix={CurrencySign}
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('shareCapital')}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					isAsync
					id="currency"
					name="currency"
					label={t('Currency')}
					value={values.currency}
					error={errors.currency}
					touched={touched.currency}
					setFieldTouched={setFieldTouched}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={loadCurrenciesOptions}
					isTile
					isRequired
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('currency')}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="numberOfShares"
					name="numberOfShares"
					label={t('Number of shares')}
					value={values.numberOfShares}
					error={errors.numberOfShares}
					touched={touched.numberOfShares}
					setFieldTouched={setFieldTouched}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('numberOfShares')}
				/>
			</FormField>
		</EditableTile>
	);
};

CapitalTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	onSave: async () => {},
	highlightLabels: [],
};

CapitalTile.propTypes = {
	entity: PropTypes.shape({}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	onSave: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default CapitalTile;
