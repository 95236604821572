export const validateStep = activity => {
	const activityCanBeCompleted = activity.canBeCompleted;

	return activityCanBeCompleted;
};

const useMyOnHoldTabStepModifier = ({ step, activity, projectStage }) => {
	const isDisabled = true;
	const hideActions = true;

	const isValid = validateStep(activity);
	const formattedStage =
		projectStage.stage.substr(0, 1).toUpperCase() + projectStage.stage.substr(1).toLowerCase();
	const responsiblePerson = step.responsiblePerson ? { [formattedStage]: step.responsiblePerson } : undefined;

	return { ...step, isValid, responsiblePerson, hideActions, isDisabled };
};

export default useMyOnHoldTabStepModifier;
