import api from 'Application/api/api';

const getTranchesFilterRange = async signal => {
	const response = await api.get('/tranches/filters-range', {
		signal,
	});

	return {
		...response,
	};
};

export default getTranchesFilterRange;
