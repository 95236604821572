import api from 'Application/api/api';

const getEntityProjects = (signal, entityId) => {
	return api.get(`/contract-parties/projects/${entityId}`, {
		signal,
		timeout: 100000,
	});
};

export default getEntityProjects;
