import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { PropTypes } from 'prop-types';

import colors from 'Application/theme/colors';
import crudModes from 'Common/constants/crudModes';
import useReponsive from 'Common/hooks/useResponsive';
import Breadcrumbs from 'Common/components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'Common/components/breadcrumbs/Breadcrumb';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import Button from 'Common/components/buttons/Button';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import StatusPill from 'Common/components/StatusPill';

const statusMap = {
	NEW: colors.success.main,
	OFFER_CREATED: colors.primary.main,
	DECLINED: colors.error.main,
};

const statusOptions = {
	NEW: 'New',
	OFFER_CREATED: 'Offer created',
	DECLINED: 'Declined',
};

const StatusContainer = styled.div`
	display: flex;
	margin-left: 10px;
`;

const ButtonWrapper = styled.div`
	display: flex;
`;

const StyledHeaderTitle = styled(HeaderTitle)`
	line-height: 34px;
	text-transform: capitalize;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
	flex: 1;
	line-height: 1.8em;
	max-width: 950px;
	overflow: hidden;
	flex-grow: 10;

	${({ $isMobile }) =>
		$isMobile &&
		css`
			max-width: calc(100vw - 110px);

			* {
				font-size: 16px;
				white-space: nowrap;
			}
		`}
`;

const Row = styled.div`
	display: flex;
	> * {
		margin-right: 8px;

		&:last-child {
			margin-right: 0px;
		}
	}
`;

const EpcInquryFormHeader = ({
	data,
	formParent,
	mode,
	isValid,
	dirty,
	docsForUpload,
	isLoading,
	isCreatedByTheUser,
	setShowDeleteModal,
	setShowConfirmModal,
	openRespondModal,
}) => {
	const { t } = useTranslation();
	const { isMobile, isTabletDown } = useReponsive();

	return (
		<HeaderContainer>
			<StyledBreadcrumbs $isMobile={isMobile}>
				<Breadcrumb to={formParent === 'epcPortal' ? '/epc/inquiries' : '/inquiries'}>
					{t('Inquiries')}
				</Breadcrumb>
				<Breadcrumb active>
					<StyledHeaderTitle>
						{mode === crudModes.CREATE ? t('Create Inquiry') : data?.clientName || <i>{t('(No client)')}</i>}
					</StyledHeaderTitle>
				</Breadcrumb>
				<StatusContainer>
					<StatusPill color={statusMap[data?.state]}>{statusOptions?.[data?.state]}</StatusPill>
				</StatusContainer>
			</StyledBreadcrumbs>
			<HeaderActions>
				<ButtonWrapper>
					{formParent === 'epcPortal' ? (
						mode === crudModes.CREATE ? (
							<Button
								icon="send"
								disabled={!isValid || (!dirty && docsForUpload === null)}
								onClick={() => setShowConfirmModal(true)}
								text={t('Send')}
								isLoading={isLoading}
								label="EPC Partner Portal EPC Inquiry Form - Send Button"
							/>
						) : (
							<Row>
								{isTabletDown ? (
									<MoreActionsButton label="EPC Inquiry More Actions">
										{isCreatedByTheUser && (
											<MenuItem
												onClick={() => setShowDeleteModal(true)}
												disabled={data.state !== 'NEW'}
												label="EPC Partner Portal EPC Inquiry Form - Delete Button"
											>
												{t('Delete')}
											</MenuItem>
										)}
										<MenuItem
											onClick={() => setShowConfirmModal(true)}
											disabled={!isValid || (!dirty && docsForUpload === null)}
											label="EPC Partner Portal EPC Inquiry Form - Update Button"
										>
											{t('Update')}
										</MenuItem>
									</MoreActionsButton>
								) : (
									isCreatedByTheUser && (
										<>
											<HoverTooltip
												arrow
												disableHoverListener={data.state === 'NEW'}
												placement="bottom"
												title={data.state !== 'NEW' && t('Cannot delete an Inquiry which has been responded to.')}
											>
												<Button
													secondary
													icon="delete"
													text={t('Delete')}
													disabled={data.state !== 'NEW'}
													onClick={() => setShowDeleteModal(true)}
													isLoading={isLoading}
													label="EPC Partner Portal EPC Inquiry Form - Delete Button"
												/>
											</HoverTooltip>
											<HoverTooltip
												arrow
												placement="bottom"
												title={t('Make your changes and then press update to send to ecoligo')}
											>
												<Button
													type="submit"
													icon="publishedWithChanges"
													text={t('Update')}
													disabled={!isValid || (!dirty && docsForUpload === null)}
													onClick={() => setShowConfirmModal(true)}
													label="EPC Partner Portal EPC Inquiry Form - Update Button"
												/>
											</HoverTooltip>
										</>
									)
								)}
							</Row>
						)
					) : (
						<Button
							type="submit"
							text={t('Respond')}
							icon="send"
							onClick={() => openRespondModal(true)}
							disabled={data.state !== 'NEW'}
							isLoading={isLoading}
							label="EPC Partner Portal EPC Inquiry Form - Respond Button"
						/>
					)}
				</ButtonWrapper>
			</HeaderActions>
		</HeaderContainer>
	);
};

EpcInquryFormHeader.propTypes = {
	data: PropTypes.shape({
		state: PropTypes.string,
		clientName: PropTypes.string,
	}),
	formParent: PropTypes.string,
	mode: PropTypes.string,
	isValid: PropTypes.bool,
	dirty: PropTypes.bool,
	docsForUpload: PropTypes.shape({}),
	isLoading: PropTypes.bool,
	isCreatedByTheUser: PropTypes.bool,
	setShowDeleteModal: PropTypes.func,
	setShowConfirmModal: PropTypes.func,
	openRespondModal: PropTypes.func,
};

EpcInquryFormHeader.defaultProps = {
	data: {
		state: 'NEW',
	},
	formParent: 'epcPortal',
	mode: crudModes.CREATE,
	isValid: false,
	dirty: false,
	docsForUpload: null,
	isLoading: false,
	isCreatedByTheUser: false,
	setShowDeleteModal: () => {},
	setShowConfirmModal: () => {},
	openRespondModal: () => {},
};

export default EpcInquryFormHeader;
