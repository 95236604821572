import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';

import LoginForm from 'User/components/Login/LoginForm/LoginForm';
import { Wrapper } from 'User/components/guestScreenComponents';
import H5 from 'Common/components/headers/H5';

const StyledH5 = styled(H5)`
	display: flex;
	text-transform: uppercase;
	margin-bottom: 8px;
	font-weight: 700;
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const Line = styled.div`
	height: 1px;
	width: 32px;
	margin-bottom: 32px;
	background: ${colors.secondary.dark};
`;

const Login = () => {
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<title>{t('Login')}</title>
			</Helmet>
			<Wrapper>
				<TitleWrapper>
					<StyledH5>{t('Welcome to Pulse')}</StyledH5>
					<Line />
				</TitleWrapper>
				<LoginForm />
			</Wrapper>
		</>
	);
};

export default Login;
