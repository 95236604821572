import api from 'Application/api/api';

const updatePostCompletion = async (signal, values, postCompletionId) => {
	const response = await api.put(`/post-completions/update/${postCompletionId}`, values, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
	};
};

export default updatePostCompletion;
