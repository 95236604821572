export const m63ExtraordinaryEvents = {
	THERE_WERE_NO_EXTRAORDINARY_EVENTS: 'THERE_WERE_NO_EXTRAORDINARY_EVENTS',
};

export const m63ExtraordinaryEventsOptions = [
	{
		label: 'There were no major challenges',
		value: m63ExtraordinaryEvents.THERE_WERE_NO_EXTRAORDINARY_EVENTS,
	},
];
