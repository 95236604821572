const parseBlockedByOptions = blockedByActivityTemplates => {
	const options = [];
	blockedByActivityTemplates?.forEach(activity => {
		options.push({
			label: activity.title,
			value: activity.id,
		});
	});
	return options;
};

export default parseBlockedByOptions;
