import api from 'Application/api/api';

const updateCampaignStatus = (signal, id, status) =>
	api.patch(
		'/finances/set-status',
		{
			financeId: id,
			status,
		},
		{
			signal,
		},
	);

export default updateCampaignStatus;
