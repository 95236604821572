import { useMemo } from 'react';

import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';

const useReactSelectCustomStyle = isDisabled =>
	useMemo(
		() => ({
			control: (provided, state) => ({
				...provided,
				minHeight: sizes.base(7),
				maxHeight: state.isMulti ? 'max-content' : sizes.base(7),
				paddingLeft: 0,
				outline: '0 none',
				border: 'none',
				borderBottom: 'solid 1px',
				borderColor: 'transparent !important',
				borderRadius: 0,
				backgroundColor: 'transparent !important',
				boxShadow: 'none',
				fontSize: sizes.fontSize.main,
				transition: 'width 100ms, height 100ms',
				cursor: state.isFocused && state.selectProps.isSearchable ? 'text' : 'pointer',
			}),
			placeholder: provided => ({
				...provided,
			}),
			valueContainer: provided => ({
				...provided,
				paddingLeft: 0,
				minHeight: sizes.base(7),
			}),
			input: provided => ({
				...provided,
				padding: 0,
				color: isDisabled ? colors.text.disabled : colors.text.primary,
				fontWeight: 400,
				lineHeight: sizes.lineHeight.main,
			}),
			singleValue: provided => ({
				...provided,
				top: '45%',
				margin: 0,
				lineHeight: sizes.lineHeight.main,
			}),
			multiValue: provided => ({
				...provided,
				alignItems: 'center',
				marginRight: sizes.base(0.5),
				padding: `${sizes.spacing(0.25)} ${sizes.spacing(1)}`,
				borderRadius: 25,
				color: colors.common.white,
				background: colors.primary.dark,
			}),
			multiValueLabel: provided => ({
				...provided,
				color: colors.common.white,
				fontWeight: 400,
				fontSize: sizes.fontSize.main,
				lineHeight: sizes.lineHeight.small,
				cursor: 'default',
			}),
			multiValueRemove: provided => ({
				...provided,
				width: sizes.base(5.5),
				height: sizes.base(5.5),
				borderRadius: 11,
				cursor: 'pointer',
				':hover': {
					backgroundColor: colors.grey.main,
					color: colors.primary.dark,
				},
				display: isDisabled ? 'none' : 'flex',
			}),
			menu: provided => ({
				...provided,
				margin: '4px 0',
				borderRadius: 6,
				boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
				zIndex: 6,
			}),
			menuPortal: provided => ({ ...provided, zIndex: 1002 }),
			menuList: provided => ({
				...provided,
				maxHeight: Math.min(provided.maxHeight, 220),
				padding: 0,
				borderRadius: 0,
			}),
			option: (provided, state) => ({
				...provided,
				padding: '10px 20px',
				background: state.isFocused ? colors.primary.dark : colors.common.white,
				color: state.isFocused ? colors.common.white : state.isSelected && colors.primary.dark,
				fontWeight: 600,
				fontSize: sizes.fontSize.main,
				lineHeight: sizes.lineHeight.big,
				cursor: 'pointer',

				':hover': {
					background: colors.primary.dark,
					color: colors.common.white,
				},
			}),
			clearIndicator: (provided, state) => ({
				...provided,
				color: state.isFocused && !isDisabled ? colors.primary.dark : 'transparent',
				cursor: 'pointer',
				padding: sizes.spacing(0.8) + ' ' + sizes.spacing(1.2),
				':hover': {
					color: state.isFocused && !isDisabled ? colors.primary.dark : 'transparent',
				},
			}),
			indicatorsContainer: provided => ({
				...provided,
				display: isDisabled ? 'none' : 'flex',
			}),
			indicatorSeparator: provided => ({
				...provided,
				margin: sizes.spacing(1) + ' ' + 0,
			}),
			dropdownIndicator: provided => ({
				...provided,
				cursor: 'pointer',
				padding: sizes.spacing(0.8) + ' ' + sizes.spacing(1.2),
			}),
		}),
		[isDisabled],
	);

export default useReactSelectCustomStyle;
