import { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';

import notify from 'Common/utils/notify';
import Loader from 'Common/components/Loader';
import EditableTile from 'Common/components/Tile/EditableTile';
import ConfirmModal from 'Common/components/modals/ConfirmModal';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import TrancheView from './TrancheView';
import EditTrancheModal from './EditTrancheModal/EditTrancheModal';
import CreateTrancheModal from './CreateTrancheModal/CreateTrancheModal';

import deleteTranche from 'Campaigns/api/deleteTranche';
import getCampaignById from 'Campaigns/api/getCampaignById';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const TranchesListTile = ({ campaign, onDataChange }) => {
	const { t } = useTranslation();
	const [trancheIdForEditing, setTrancheIdForEditing] = useState(null);
	const [trancheForDeletion, setTrancheForDeletion] = useState(null);
	const [trancheToCopy, setTrancheToCopy] = useState(null);
	const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();

	const handleTrancheUpdate = async tranche => {
		let tranches = [...campaign.tranches];

		const tranchIndex = tranches.findIndex(e => e.auto_id === tranche.auto_id);
		tranches.splice(tranchIndex, 1, tranche);

		await handleRefresh({ tranches });
	};

	const handleTrancheCreate = async tranche => {
		let tranches = campaign?.tranches ? [...campaign.tranches] : [];

		await handleRefresh({ tranches: [...tranches, tranche] });
	};

	const handleTrancheDelete = async id => {
		let tranches = [...campaign.tranches];
		const tranchIndex = tranches.findIndex(e => e.auto_id === id);
		tranches.splice(tranchIndex, 1);
		try {
			await deleteTranche(abortController.signal, id);
			notify(t('Tranche deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});

			setTrancheForDeletion(null);
			await handleRefresh({ tranches });
		} catch (error) {
			showToastError(error, t('An error occured while deleting a tranche'));
		}
	};

	const handleRefresh = (additionalData = {}) => {
		setIsLoading(true);

		(async () => {
			try {
				const response = await getCampaignById(abortController.signal, campaign.auto_id, true);
				onDataChange({
					...response.data,
					...additionalData,
				});
				setIsLoading(false);
			} catch (error) {
				showToastError(error, t('An error occured while updating tranches campaign data'));
				if (!axios.isCancel(error)) {
					setIsLoading(false);
					onDataChange(null);
				}
			}
		})();
	};

	const copyTranche = trancheData => {
		let trancheDetailsOmit = {
			...omit(trancheData.tranche_details, ['interestRate', 'tagRefs']),
		};

		let trancheDataOmitted = {
			...trancheData,
			tranche_details: trancheDetailsOmit,
		};
		let data = {
			...trancheDataOmitted,
			reference: '',
			id: '',
			status: null,
		};
		setTrancheToCopy({
			...omit(data, ['investable_time_from', 'investable_time_until']),
		});
		setIsCreateModalOpened(true);
	};

	const handleClickCreateTranche = () => {
		setTrancheToCopy(null);
		setIsCreateModalOpened(true);
	};

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.PM_FUNDRAISING,
		roles.ASSET_MARKETING,
	]);

	return (
		<EditableTile
			title={t('Tranches')}
			showRightComponent={true}
			rightComponent={isAuthorizedToEdit && <TileActionButton onClick={handleClickCreateTranche} />}
		>
			{isLoading ? (
				<LoaderWrapper>
					<Loader />
				</LoaderWrapper>
			) : (
				campaign?.tranches &&
				campaign?.tranches.map(tranche => (
					<TrancheView
						emissionSpv={campaign?.emission_spv}
						tranch={tranche}
						key={tranche.auto_id}
						setTranchIdForEditing={setTrancheIdForEditing}
						setTrancheForDeletion={setTrancheForDeletion}
						copyTranche={copyTranche}
					/>
				))
			)}
			{trancheIdForEditing !== null && (
				<EditTrancheModal
					isOpen={trancheIdForEditing !== null}
					onClose={() => setTrancheIdForEditing(null)}
					onFormSubmit={handleTrancheUpdate}
					trancheId={trancheIdForEditing}
				/>
			)}
			<CreateTrancheModal
				trancheToCopy={trancheToCopy}
				isOpen={isCreateModalOpened}
				onClose={() => setIsCreateModalOpened(false)}
				onFormSubmit={handleTrancheCreate}
				campaignData={campaign}
			/>
			<ConfirmModal
				isOpen={trancheForDeletion !== null}
				onCancel={() => setTrancheForDeletion(null)}
				onConfirm={() => handleTrancheDelete(trancheForDeletion?.auto_id)}
				confirmText={t('Delete')}
				label="delete-tranche-confirm"
				heading={t('Delete Tranche')}
				text={`${t('Are you sure you want to delete Tranche ')}${trancheForDeletion?.reference}?`}
			/>
		</EditableTile>
	);
};

TranchesListTile.defaultProps = {
	campaign: {},
	onDataChange: () => {},
};

TranchesListTile.propTypes = {
	campaign: PropTypes.shape({
		emission_spv: PropTypes.arrayOf(PropTypes.shape({})),
		auto_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		tranches: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	onDataChange: PropTypes.func,
};

export default TranchesListTile;
