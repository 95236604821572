import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import logo from 'Common/assets/images/logo.svg';

import logo_3 from 'Common/assets/images/logo-3.svg';
import Icon from 'Common/components/icons/Icon';
import isInAuthorizedGroups from 'User/utils/isInAuthorizedGroups';
import { roleGroups } from 'User/constants/roles';
import EcoligoSidebarLinks from 'Common/components/layout/EcoligoSidebarLinks';
import EpcSidebarLinks from 'EpcPartner/components/EpcPartnerPortal/EpcSidebarLinks';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { updateSidebarState } from 'Application/reducers/reduxCommon';
import colors from 'Application/theme/colors';
import useReponsive from 'Common/hooks/useResponsive';
import { css } from 'styled-components';
import { useEffect } from 'react';

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 14px 0 0 0px;
	background: ${colors.common.sidebarGrey};
	overflow-y: auto;
	overflow-x: hidden;
	white-space: nowrap;

	transition: width 0.6s ease-in-out;
	width: ${({ $width }) => $width};
	height: 101vh;

	${({ $isMobile }) =>
		$isMobile &&
		css`
			position: fixed;
			top: 60px;
			left: 0;
			z-index: 1000;
		`}

	::-webkit-scrollbar {
		width: 0; /* Remove scrollbar space */
	}
`;

const LinksContainer = styled.div`
	position: relative;
	height: calc(100vh - 80px);
	overflow-y: auto;
	overflow-x: hidden;
	padding-bottom: 60px;

	::-webkit-scrollbar {
		width: 3px;
	}

	${({ $isMobile }) =>
		!$isMobile &&
		css`
			padding-bottom: 0px;

			&::after {
				content: '';
				position: fixed;
				bottom: 66px;
				left: 0;
				width: ${({ $isShrank }) => ($isShrank ? '80px' : '208px')};
				height: 30px;
				transition: width 0.5s ease-in-out;
				background: linear-gradient(transparent 10%, ${colors.common.sidebarGrey} 90%);
			}
		`}
`;

const LogoContainer = styled.div`
	width: 120px;
	height: 28px;
	margin-bottom: 35px;
	padding-left: 32px;
`;

const CrossFade = styled.div`
	position: relative;
	img {
		position: absolute;
		left: 0;
	}

	img:last-of-type {
		border-radius: 50%;
		left: -10px;
		top: 2px;
	}
`;

export const SectionContent = styled.div`
	margin-bottom: 20px;
`;

const ButtonsWrapper = styled.div`
	position: absolute;
	bottom: 20px;
	left: 32px;
	svg {
		transform: ${props => props.isShrank && 'rotate(180deg)'};
		transition: transform 0.5s ease;
		cursor: pointer;
	}
`;

export const Sidebar = () => {
	const { isMobile } = useReponsive();
	const pathname = useSelector(state => state.router.location.pathname);
	const isShrank = useSelector(state => state.common.isSidebarShrank);
	const dispatch = useDispatch();

	let SidebarLinks = null;

	if (isInAuthorizedGroups([roleGroups.ECOLIGO])) {
		SidebarLinks = EcoligoSidebarLinks;
	}

	if (isInAuthorizedGroups([roleGroups.EPC_PARTNER])) {
		SidebarLinks = EpcSidebarLinks;
	}

	const onArrowClick = () => {
		dispatch(updateSidebarState());
	};

	const sideBarWidth = isShrank ? (isMobile ? '0%' : '80px') : isMobile ? '100%' : '208px';

	useEffect(
		() => {
			if (isMobile) {
				dispatch(updateSidebarState({ newSideBarShrink: true }));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isMobile],
	);

	useEffect(() => {
		if (isMobile) {
			dispatch(updateSidebarState({ newSideBarShrink: true }));
		}
	}, [dispatch, isMobile, pathname]);

	return (
		<Wrapper $isMobile={isMobile} $width={sideBarWidth}>
			<LinksContainer $isShrank={isShrank} $isMobile={isMobile}>
				{!isMobile && (
					<LogoContainer>
						<Link to="/">
							<CrossFade>
								<motion.img
									src={logo}
									alt="Ecoligo"
									initial={{
										opacity: isShrank ? 0 : 1,
										width: '120px',
									}}
									animate={{
										opacity: isShrank ? 0 : 1,
									}}
									transition={{ duration: 0.5 }}
								/>
								<motion.img
									src={logo_3}
									alt="Ecoligo"
									initial={{
										opacity: isShrank,
										marginLeft: 10,
										marginTop: -2,
									}}
									animate={{
										opacity: isShrank,
									}}
									transition={{ duration: 0.5 }}
								/>
							</CrossFade>
						</Link>
					</LogoContainer>
				)}

				{Boolean(SidebarLinks) === true ? <SidebarLinks isShrank={isShrank} /> : null}
			</LinksContainer>

			{!isMobile && (
				<div>
					<SectionContent>
						<ButtonsWrapper isShrank={isShrank}>
							<Icon icon="arrowLeft" onClick={onArrowClick} />
						</ButtonsWrapper>
					</SectionContent>
				</div>
			)}
		</Wrapper>
	);
};
