import { createSlice } from '@reduxjs/toolkit';

const initialTabData = {
	projects: 0,
	clients: 0,
	epcPartner: 0,
	epcPartnerOffers: 0,
	investor: 0,
	campaigns: 0,
	preferences: 0,
	myDashboard: 0,
	teamDashboard: 0,
	entities: 0,
	users: 0,
	partners: 0,
};

export const tabSlice = createSlice({
	name: 'tab',
	initialState: { ...initialTabData },
	reducers: {
		setTabsToIntital: () => initialTabData,
		clearTabData: (state, { payload }) => {
			state[payload] = 0;
		},
		updateCurrentTab: (state, { payload: { tab, tabIndex } }) => {
			state[tab] = tabIndex;
		},
	},
});

export const { setTabsToIntital, clearTabData, updateCurrentTab } = tabSlice.actions;

export default tabSlice.reducer;
