import api from 'Application/api/api';

const uploadImages = (signal, files, projectId) =>
	api.post(`/images/upload?projectId=${projectId}`, files, {
		signal,
		timeout: 100000,
		headers: {
			'Content-Type': 'multipart/form-data',
			Accept: '*/*',
		},
	});

export default uploadImages;
