import { useMemo } from 'react';
import AddressSection from '../Sections/AddressSection';
import DetailsSection from '../Sections/DetailsSection';

const useProjectDeveloperOverlaySections = () =>
	useMemo(
		() => [
			{
				title: 'Details',
				section: props => <DetailsSection {...props} />,
			},
			{
				title: 'Address',
				section: props => <AddressSection {...props} />,
			},
		],
		[],
	);

export default useProjectDeveloperOverlaySections;
