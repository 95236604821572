import { useMemo } from 'react';
import AddressSection from '../sections/AddressSection';
import DetailsSection from '../sections/DetailsSection';

const useManufacturerOverlaySections = () =>
	useMemo(
		() => [
			{
				title: 'Details',
				section: props => <DetailsSection {...props} />,
			},
			{
				title: 'Address',
				section: props => <AddressSection {...props} />,
			},
		],
		[],
	);

export default useManufacturerOverlaySections;
