import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import getTranchesFilterRange from 'Campaigns/api/getTranchesFilterRange';

import modifyNumberFilterData from 'Common/components/filter/hooks/modifyNumberFilterData';
import showToastError from 'Common/utils/showToastError';
import { FILTER_TYPES } from 'Common/components/filter/constants/filterConstants';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const fallbackFilterRanges = {
	tenorMin: 0,
	tenorMax: 0,
	interestRateMin: 0,
	interestRateMax: 0,
};

const useTranchesListFilters = () => {
	const { t } = useTranslation();

	const [filterRanges, setFilterRanges] = useState(fallbackFilterRanges);

	const abortController = useAbortController();

	const generateFiltersData = [
		{
			name: 'reference',
			label: t('Name'),
			type: FILTER_TYPES.INPUT,
		},
		{
			name: 'campaignName',
			label: t('Campaign'),
			type: FILTER_TYPES.INPUT,
		},
		{
			name: 'investable',
			label: t('Investable'),
			type: FILTER_TYPES.DATE,
			startDateName: 'investableFrom',
			endDateName: 'investableUntil',
		},
		{
			name: 'tenor',
			label: t('Tenor'),
			type: FILTER_TYPES.NUMBER,
			minName: 'tenorFrom',
			maxName: 'tenorTo',
			lowerBound: filterRanges.tenorMin,
			upperBound: filterRanges.tenorMax,
		},
		{
			name: 'interestRate',
			label: t('Interest Rate'),
			type: FILTER_TYPES.NUMBER,
			minName: 'interestRateFrom',
			maxName: 'interestRateTo',
			lowerBound: filterRanges.interestRateMin,
			upperBound: filterRanges.interestRateMax,
		},
		{
			name: 'externalId',
			label: t('ID'),
			type: FILTER_TYPES.INPUT,
			validation: 'number',
		},
	];

	useEffect(() => {
		(async () => {
			try {
				const response = await getTranchesFilterRange(abortController.signal);

				setFilterRanges(prevFilterRanges => ({
					...prevFilterRanges,
					...response.data,
				}));
			} catch (error) {
				showToastError(error, t("Can't fetch tranche filter range options"));
				if (!axios.isCancel(error)) {
					setFilterRanges(fallbackFilterRanges);
				}
			}
		})();
	}, [abortController.signal, t]);

	const [filtersData, setFiltersData] = useState(generateFiltersData);

	useEffect(() => {
		const modifyNumberFilterDataBinded = modifyNumberFilterData.bind(undefined, filterRanges);

		setFiltersData(prevFilterData => [...prevFilterData].map(modifyNumberFilterDataBinded));
	}, [filterRanges]);

	return filtersData;
};

export default useTranchesListFilters;
