import { useTranslation } from 'react-i18next';
import EditableTile from 'Common/components/Tile/EditableTile';
import DocumentsList from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/EPCOfferTile/DocumentsList/DocumentsList';
import uploadDocsFromPortal from 'EpcPartner/api/uploadDocsFromPortal';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';

const DocumentsTab = ({ epcOffer, onDataChange }) => {
	const { t } = useTranslation();

	return (
		<TilesGridWrapper>
			<Grid item xs={12}>
				<EditableTile title={t('Required Documents')}>
					<DocumentsList
						documentDefs={epcOffer.documentDefs}
						offerId={epcOffer.id}
						documents={epcOffer.documents}
						onDataChange={onDataChange}
						uploadDocsApiCall={uploadDocsFromPortal}
						isReadOnly={epcOffer.status !== 'OPEN'}
						idPropName="offerId"
					/>
				</EditableTile>
			</Grid>
		</TilesGridWrapper>
	);
};

DocumentsTab.propTypes = {
	epcOffer: PropTypes.shape({
		documentDefs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		status: PropTypes.string.isRequired,
	}).isRequired,
	onDataChange: PropTypes.func.isRequired,
};

export default DocumentsTab;
