import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { push } from 'redux-first-history';
import { useLocation } from 'react-router-dom';
import { stringify } from 'qs';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import Big from 'Common/utils/customBig';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import ButtonLink from 'Common/components/buttons/ButtonLink';
import useSmartResize from 'Common/hooks/table/useSmartResize';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import Filters from 'Common/components/filter/FiltersContainer';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import formatDateForRequestData from 'Common/utils/formatDateForRequestData';

import ProjectListTable from 'Projects/components/ProjectList/ProjectListTable';
import useProjectListFilterData from 'Projects/components/ProjectList/hooks/useProjectListFilterData';
import Overlay, { OverlayButtons } from 'Common/components/modals/Overlay';
import Button from 'Common/components/buttons/Button';
import ProjectImpact from 'Projects/components/ProjectList/components/ProjectImpact';
import ProjectCreateOverlay from 'Projects/components/ProjectCreate/ProjectCreateForm/ProjectCreateOverlay';

import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import ReactTimeAgo from 'react-time-ago';
import getProjectDimensionLastRun from 'Projects/api/getProjectDimensionLastRun';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';

const sortPropertiesMap = {
	condition: 'cond',
	country: 'country.name',
	contractType: 'contractType.name',
	epcPartner: 'epcPartner.name',
	client: 'client.name',
	stage: 'stageOrder',
	duration: 'contractDuration',
	salesChannel: 'salesChannel.name',
	industry: 'client.industry.name',
	marketSegment: 'client.marketSegment.name',
};

const LastUpdatedWrapper = styled.div`
	background-color: ${colors.common.transparent};
	color: ${colors.text.primary};
	font-size: 14px;
	margin: 0;
	padding-top: ${sizes.spacing(1.5)};
	padding-right: ${sizes.spacing(3)};
`;

const ProjectList = () => {
	const { t } = useTranslation();

	const storedTableState = useSelector(state => state.tables.projects);
	const [shouldTableRender, setShouldTableRender] = useState(false);
	const [lastUpdatedDate, setLastUpdatedDate] = useState(null);
	const dispatch = useDispatch();

	const sortByQueryParam = useQueryParameter('sortBy');
	const filters = useQueryParameter('filters');

	const authorizedRoles = [roles.ADMIN, roles.FINANCE, roles.SALES, roles.MANAGEMENT];

	const location = useLocation();

	const abortController = useAbortController();

	useEffect(() => {
		if (!shouldTableRender) {
			if (location.search === '' && storedTableState && storedTableState.url) {
				dispatch(push(`/projects${storedTableState.url}`));
			}
			setShouldTableRender(true);
		}
	}, [dispatch, storedTableState, location.search, shouldTableRender]);

	const openExport = useCallback(
		exportWithOptions => {
			const sortById =
				sortByQueryParam && sortPropertiesMap[sortByQueryParam.id]
					? sortPropertiesMap[sortByQueryParam.id]
					: sortByQueryParam?.id;

			if (exportWithOptions) {
				let onHold;
				if (filters.showOnHold === false) {
					if (filters.hideOnHold === undefined) {
						onHold = 'NO';
					} else {
						onHold = undefined;
					}
				}
				if (filters.hideOnHold === false) {
					if (filters.showOnHold === undefined) {
						onHold = 'YES';
					} else {
						onHold = undefined;
					}
				}
				const formatedFilers = {
					...filters,
					onHold,
					salesCloseDateFrom: filters.salesCloseDateFrom && formatDateForRequestData(filters.salesCloseDateFrom),
					salesCloseDateTo: filters.salesCloseDateTo && formatDateForRequestData(filters.salesCloseDateTo),
					lastModifiedDateFrom: filters.lastModifiedDateFrom && new Date(filters.lastModifiedDateFrom),
					lastModifiedDateTo: filters.lastModifiedDateTo && new Date(filters.lastModifiedDateTo),
					irrFrom: filters.irrFrom && Big(filters.irrFrom).div(100).toString(),
					irrTo: filters.irrTo && Big(filters.irrTo).div(100).toString(),
				};

				window.open(
					`api/projects/export-table-view?${
						sortByQueryParam
							? 'sort=' + sortById + '%2C' + (sortByQueryParam.desc ? 'DESC' : 'ASC')
							: 'sort=externalId%2CDESC'
					}&${stringify(formatedFilers, {
						arrayFormat: 'indices',
						allowDots: true,
					})}&size=9999`,

					'_blank',
				);
			} else {
				window.open(
					`api/projects/export-table-view?&size=9999&${
						sortByQueryParam
							? 'sort=' + sortById + '%2C' + (sortByQueryParam.desc ? 'DESC' : 'ASC')
							: 'sort=externalId%2CDESC'
					}`,
					'_blank',
				);
			}
		},
		[sortByQueryParam, filters],
	);

	// Filters logic
	const filterButtonRef = useRef(null);
	const filtersData = useProjectListFilterData();
	const filtersRef = useRef();

	// eslint-disable-next-line
	const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
	useSmartResize(filtersRef, setForceUpdateCounter);

	const [isOpenOverlay, setIsOpenOverlay] = useState(false);

	const [isCreateOpen, setIsCreateOpen] = useState(false);
	const handleCreateClose = () => {
		setIsCreateOpen(false);
	};

	const breadcrumbList = [
		{
			label: 'Projects',
			href: '/projects',
		},
		{
			label: 'Impact',
		},
	];

	const handleOverlayClose = () => {
		setIsOpenOverlay(false);
	};

	useEffect(() => {
		(async () => {
			try {
				const res = await getProjectDimensionLastRun(abortController.signal);
				setLastUpdatedDate(res.data);
			} catch (err) {
				showToastError(err, t('Could not get last updated date'));
			}
		})();
	}, [abortController.signal, t]);

	const lastUpdated = lastUpdatedDate && (
		<LastUpdatedWrapper>
			Data last calculated: <ReactTimeAgo date={new Date(lastUpdatedDate)} timeStyle="round" tooltip={false} />
		</LastUpdatedWrapper>
	);

	return (
		<>
			<Helmet>
				<title>{t('Projects')}</title>
			</Helmet>
			<HeaderContainer>
				<HeaderTitle>{t('Projects')}</HeaderTitle>
				<HeaderActions>
					<div ref={filterButtonRef}></div>
					<Button
						label="Projects - Project Impact Overlay"
						icon="projectImpact"
						medium
						active={isOpenOverlay}
						onClick={() => setIsOpenOverlay(true)}
					/>
					{isAuthorized(authorizedRoles) && (
						<ButtonLink
							label="Create Project - Projects"
							text={t('Create Project')}
							icon="addRounded"
							onClick={() => setIsCreateOpen(true)}
						/>
					)}
					<MoreActionsButton label="Projects More Actions">
						{filters && (
							<MenuItem
								onClick={() => openExport(true)}
								type="button"
								data-action="exportFiltered"
								label="Projects - Export filtered to CSV Menu Item"
							>
								{t('Export filtered to CSV')}
							</MenuItem>
						)}
						<MenuItem
							onClick={() => openExport(false)}
							type="button"
							data-action="exportAll"
							label="Projects - Export all to CSV Menu Item"
						>
							{t('Export all to CSV')}
						</MenuItem>
					</MoreActionsButton>
				</HeaderActions>
			</HeaderContainer>
			<div ref={filtersRef}>
				{shouldTableRender && <Filters table="projects" buttonRef={filterButtonRef} filtersData={filtersData} />}
			</div>

			{(shouldTableRender || !storedTableState?.url) && <ProjectListTable />}
			<Overlay
				isOpen={isOpenOverlay}
				onClose={handleOverlayClose}
				breadcrumbList={breadcrumbList}
				headerContent={lastUpdated}
			>
				{(setIsFormDirty, handleSubmit, onCancel) => (
					<>
						<ProjectImpact />
						<OverlayButtons noSubmitButton onCancel={onCancel} />
					</>
				)}
			</Overlay>

			<ProjectCreateOverlay
				isOpen={isCreateOpen}
				onClose={handleCreateClose}
				onFormSubmit={data => dispatch(push(`/projects/details/${data.id}`))}
			/>
		</>
	);
};

export default ProjectList;
