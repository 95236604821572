import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import loadCountryOptions from 'Country/utils/loadCountryOptions';
import FormikSelect from 'Common/components/form/FormikSelect';

import updateEpcPartner from 'EpcPartner/api/updateEpcPartner';
import useAddressFormInitialValues from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/AddressTile/hooks/useAddressFormInitialValues';
import useAddressFormValidationSchema from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/AddressTile/hooks/useAddressFormValidationSchema';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';
import axios from 'axios';

const AddressTile = ({
	epcPartner,
	onEnterEditMode,
	onExitEditMode,
	onSave,
	onDirtyForm,
	highlightLabels,
}) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.SALES,
		roles.PM_TECHNICAL,
		roles.ASSET_MANAGER,
	]);

	const newLoad = async () =>
		(await loadCountryOptions()).filter(item => item.value !== 11432 && item.value !== 12035);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useAddressFormInitialValues(epcPartner);
	const validationSchema = useAddressFormValidationSchema();

	const { errors, touched, values, handleSubmit, dirty, handleChange, handleBlur, setFieldValue, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			enableReinitialize: true,
			onSubmit: async values => {
				setIsSavingChanges(true);

				let response;

				try {
					response = await updateEpcPartner(abortController.signal, {
						...omit(epcPartner, ['tier', 'countryIds']),
						countryIds: values.countryIds ? values.countryIds.map(country => country.value) : [],
						address: omit(values, ['country', 'countries']),
					});

					if (onDirtyForm) {
						onDirtyForm(false);
					}

					onSave(response.data);
					handleCancel();
					setIsSavingChanges(false);
					resetForm({ values });
				} catch (e) {
					showToastError(e, t("Can't update EPC partner address info"));
					if (!axios.isCancel(e)) {
						handleCancel();
						setIsSavingChanges(false);
					}
				}
			},
		});

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Address')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<FormField>
				<FormikSelect
					isAsync
					isMulti
					id="countryIds"
					name="countryIds"
					label={t('Countries')}
					value={values.countryIds}
					error={errors.countryIds}
					touched={touched.countryIds}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={newLoad}
					isTile
					isInEditMode={isInEditMode}
					isRequired
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.EPC_PARTNER_ADDRESS.EPC_PARTNER_COUNTRY,
					)}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="apartmentBuilding"
					name="apartmentBuilding"
					label={t('Apartment / Building')}
					value={values.apartmentBuilding}
					error={errors.apartmentBuilding}
					touched={touched.apartmentBuilding}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.EPC_PARTNER_ADDRESS.EPC_PARTNER_APARTMENT_BUILDING,
					)}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="line1"
					name="line1"
					label={t('Address line 1')}
					value={values.line1}
					error={errors.line1}
					touched={touched.line1}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.EPC_PARTNER_ADDRESS.EPC_PARTNER_ADDRESS_LINE_1,
					)}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="line2"
					name="line2"
					label={t('Address line 2')}
					value={values.line2}
					error={errors.line2}
					touched={touched.line2}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.EPC_PARTNER_ADDRESS.EPC_PARTNER_ADDRESS_LINE_2,
					)}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="cityRegion"
					name="cityRegion"
					label={t('City / Region')}
					value={values.cityRegion}
					error={errors.cityRegion}
					touched={touched.cityRegion}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.EPC_PARTNER_ADDRESS.EPC_PARTNER_CITY,
					)}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="postCodeZip"
					name="postCodeZip"
					label={t('Post code / ZIP')}
					value={values.postCodeZip}
					error={errors.postCodeZip}
					touched={touched.postCodeZip}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.EPC_PARTNER_ADDRESS.EPC_PARTNER_POSTAL_CODE,
					)}
				/>
			</FormField>
		</EditableTile>
	);
};

AddressTile.defaultProps = {
	epcPartner: {},
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	highlightLabels: [],
};

AddressTile.propTypes = {
	epcPartner: PropTypes.shape({}),
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onSave: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default AddressTile;
