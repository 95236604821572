import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import Big from 'Common/utils/customBig';
import { PropTypes } from 'prop-types';

import colors from 'Application/theme/colors';

import Button from 'Common/components/buttons/Button';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';

import CustomDebounce from 'Common/utils/CustomDebounce';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import Icon from 'Common/components/icons/Icon';

const RelativeFormField = styled(FormField)`
	position: relative;
`;

const WarningContainer = styled.div`
	position: absolute;
	top: 50%;
	left: -20px;
	transform: translateY(-50%);
`;

const ThinText = styled.span`
	font-size: 14px;
	color: ${colors.text.black};
`;

const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
	> * {
		margin-right: 4px;

		&:last-child {
			margin-right: 0px;
		}
	}
`;

const PercentWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const formatNewAllocatedSize = value => Math.trunc(Number(value));

const ProjectInfoSystemSizeEdit = ({
	handleBlur,
	errors,
	touched,
	values,
	namePrefix,
	setFieldValue,
	systemSizeKwp,
	otherCampaignsSystemSizeValue,
	currentCampaignSystemSizeValue,
	currentCampaignSystemSizePercent,
}) => {
	const { t } = useTranslation();
	const [isDecreaseHeld, setIsDecreaseHeld] = useState(false);
	const [isIncreaseHeld, setIsIncreaseHeld] = useState(false);
	const maxAllocatedSpace = systemSizeKwp - otherCampaignsSystemSizeValue;
	const exceedSpaceWarning =
		currentCampaignSystemSizeValue > systemSizeKwp
			? t('The total allocated System size from all related campaign exceed the System size of the project')
			: '';

	const incrementDebouncer = useMemo(() => new CustomDebounce(), []);
	const decrementDebouncer = useMemo(() => new CustomDebounce(), []);

	const handleSystemSizeIncrement = useCallback(() => {
		incrementDebouncer.debounce(() => {
			setFieldValue(
				`${namePrefix}[systemSizeAllocatedValue]`,
				// So it doesn't go above the max available space
				formatNewAllocatedSize(Math.min(Number(Big(currentCampaignSystemSizeValue).plus(1)), maxAllocatedSpace)),
			);
		}, 100);
	}, [incrementDebouncer, setFieldValue, namePrefix, currentCampaignSystemSizeValue, maxAllocatedSpace]);

	const handleSystemSizeDecrement = useCallback(() => {
		decrementDebouncer.debounce(() => {
			setFieldValue(
				`${namePrefix}[systemSizeAllocatedValue]`,
				// So it doesn't drop below 0.1
				formatNewAllocatedSize(Math.max(Number(Big(currentCampaignSystemSizeValue).minus(1)), 0.1)),
			);
		}, 100);
	}, [decrementDebouncer, setFieldValue, namePrefix, currentCampaignSystemSizeValue]);

	useEffect(() => {
		const globalMouseUpHandler = () => {
			setIsDecreaseHeld(false);
			setIsIncreaseHeld(false);
		};
		document.addEventListener('mouseup', globalMouseUpHandler);
		return () => {
			document.removeEventListener('mouseup', globalMouseUpHandler);
		};
	}, []);

	useEffect(() => {
		if (isIncreaseHeld) {
			const interval = setInterval(handleSystemSizeIncrement, 100);
			return () => clearInterval(interval);
		}
	}, [isIncreaseHeld, handleSystemSizeIncrement]);

	useEffect(() => {
		if (isDecreaseHeld) {
			const interval = setInterval(handleSystemSizeDecrement, 100);
			return () => clearInterval(interval);
		}
	}, [isDecreaseHeld, handleSystemSizeDecrement]);

	const [savedHandleChange, setSavedHandleChange] = useState(() => {});
	const [tempValue, setTempValue] = useState(Number(values?.systemSizeAllocatedValue)?.toFixed(2));

	useEffect(() => {
		const formattedValue = Number(values?.systemSizeAllocatedValue).toFixed(2);
		setTempValue(formattedValue);
	}, [values?.systemSizeAllocatedValue]);

	const saveInputChange = useCallback(
		e => {
			const value = e.target.value;
			setTempValue(value);
			setSavedHandleChange(() => () => {
				const formattedValue = Number(value).toFixed(2);
				setFieldValue(`${namePrefix}[systemSizeAllocatedValue]`, formattedValue);

				// ! Temp value is updated twice to formatted value (here and in the useEffect above) so the systemSizeAllocatedValue doen't trigger errors
				// ? Example: systemSizeAllocatedValue is 0.0000001 and in the useEffect above it's formatted to 0.00
				// ? but in the validation schema it will throw error that it has too many numbers after the decimal point

				// ! Also this is not the only place we update the systemSizeAllocatedValue (we have increment/decrement handlers as well)

				setTempValue(formattedValue);
			});
		},
		[namePrefix, setFieldValue],
	);

	const onBlurHandler = useCallback(
		(...args) => {
			savedHandleChange();
			setSavedHandleChange(() => () => {});
			handleBlur(...args);
		},
		[savedHandleChange, handleBlur],
	);

	return (
		<>
			<RelativeFormField>
				{exceedSpaceWarning && (
					<WarningContainer>
						<HoverTooltip title={exceedSpaceWarning} placement="top">
							<Icon icon="warning" color={colors.warning.main} />
						</HoverTooltip>
					</WarningContainer>
				)}
				<FormikInput
					name={`${namePrefix}[systemSizeAllocatedValue]`}
					value={tempValue}
					error={errors?.systemSizeAllocatedValue}
					touched={touched?.systemSizeAllocatedValue}
					onChange={saveInputChange}
					onBlur={onBlurHandler}
					isRequired
				/>
			</RelativeFormField>
			<ButtonsWrapper>
				<Button
					small
					icon="remove"
					onClick={handleSystemSizeDecrement}
					onMouseDown={() => setIsDecreaseHeld(true)}
					onMouseUp={() => setIsDecreaseHeld(false)}
					label="Campaign Project Info - Remove Button"
				/>
				<PercentWrapper>
					<ThinText>
						{currentCampaignSystemSizePercent ? Number(currentCampaignSystemSizePercent).toFixed(0) : 0}%
					</ThinText>
				</PercentWrapper>
				<Button
					small
					icon="add"
					onClick={handleSystemSizeIncrement}
					onMouseDown={() => setIsIncreaseHeld(true)}
					onMouseUp={() => setIsIncreaseHeld(false)}
					label="Campaign Project Info - Add Button"
				/>
			</ButtonsWrapper>
		</>
	);
};

ProjectInfoSystemSizeEdit.defaultProps = {
	errors: null,
	touched: null,
};

ProjectInfoSystemSizeEdit.propTypes = {
	handleBlur: PropTypes.func.isRequired,
	errors: PropTypes.shape({
		systemSizeAllocatedValue: PropTypes.string.isRequired,
	}),
	touched: PropTypes.shape({
		systemSizeAllocatedValue: PropTypes.bool.isRequired,
	}),
	values: PropTypes.shape({
		systemSizeAllocatedValue: PropTypes.number.isRequired,
	}).isRequired,
	namePrefix: PropTypes.string.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	systemSizeKwp: PropTypes.number.isRequired,
	otherCampaignsSystemSizeValue: PropTypes.shape({}).isRequired,
	currentCampaignSystemSizeValue: PropTypes.number.isRequired,
	currentCampaignSystemSizePercent: PropTypes.number.isRequired,
};

export default ProjectInfoSystemSizeEdit;
