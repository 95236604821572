import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { stopShowingTip } from 'Application/reducers/reduxTipModal';
import { useCallback } from 'react';
import ConfirmModal from './ConfirmModal';
import H5 from '../headers/H5';
import styled from 'styled-components';

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const TipModal = ({
	onCancel,
	cancelText,
	checkboxLabel,
	isOpen,
	tipName,
	label,
	text,
	children,
	...props
}) => {
	const { t } = useTranslation();
	const [checkboxValue, setCheckboxValue] = useState(null);
	const dispatch = useDispatch();

	const handleClose = useCallback(() => {
		if (checkboxValue) {
			dispatch(
				stopShowingTip({
					tipName,
				}),
			);
		}
		onCancel();
	}, [checkboxValue, dispatch, onCancel, tipName]);

	return (
		<ConfirmModal
			isOpen={isOpen}
			onCancel={handleClose}
			cancelText={cancelText}
			label={`Tip Modal - ${label} - ${cancelText} Button`}
			{...props}
			heading={t('Tip')}
			text={text}
		>
			<ContentWrapper>
				{children ? <H5>{children}</H5> : null}
				<FormikCheckbox
					label={checkboxLabel ?? t("Don't show this again")}
					value={checkboxValue}
					onChange={e => setCheckboxValue(e.target.checked)}
				/>
			</ContentWrapper>
		</ConfirmModal>
	);
};

TipModal.defaultProps = {
	tipName: null,
	children: null,
	onCancel: null,
	cancelText: 'Close',
	label: 'Tip Modal',
	checkboxLabel: null,
};

TipModal.propTypes = {
	children: PropTypes.arrayOf(PropTypes.node),
	label: PropTypes.string,
	onCancel: PropTypes.func,
	tipName: PropTypes.string,
	cancelText: PropTypes.string,
	checkboxLabel: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	text: PropTypes.string.isRequired,
};

export default TipModal;
