import api from 'Application/api/api';

const getProjectDevelopers = (signal, query) =>
	api.get('/project-developers/get-pageable', {
		params: {
			query,
			page: 0,
			size: 9999,
		},
		signal,
	});

export default getProjectDevelopers;
