/* eslint-disable no-unused-vars */
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import notify from 'Common/utils/notify';
import { PropTypes } from 'prop-types';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import ExternalLink from 'Common/components/ExternalLink';
import UserAvatar from 'Common/components/usersPreview/UserAvatar';
import Modal from 'Common/components/modals/Modal';
import useResponsive from 'Common/hooks/useResponsive';
import Icon from '../icons/Icon';

const UsersContainer = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	z-index: ${props => (props.shownPopups ? '1001' : '0')};
`;

const UserPreviewPopupContainerDesktop = styled.div`
	display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
	position: absolute;
	${({ $isTop }) =>
		$isTop
			? css`
					bottom: calc(100% + ${sizes.spacing(0.5)});
			  `
			: css`
					top: calc(100% + ${sizes.spacing(0.5)});
			  `}

	${({ $alignment }) =>
		$alignment === 'left'
			? css`
					left: 0;
			  `
			: $alignment === 'right'
			? css`
					right: 0;
			  `
			: css`
					left: 50%;
					transform: translateX(-50%);
			  `};

	max-width: fit-content;
	flex-direction: column;
	padding: ${sizes.spacing(2)};
	padding-right: ${sizes.spacing(1)};
	background-color: ${colors.common.white};
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	border-radius: 20px;
	z-index: 10;
`;

const PopupAvatarsContainer = styled.div`
	display: flex;
	gap: ${sizes.spacing(0.8)};
	flex-wrap: wrap;
	margin-bottom: ${sizes.spacing(2)};
`;

const PopupAvatarCentered = styled.div`
	display: flex;
	align-items: center;
`;

const PopupAvatarContainer = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	cursor: pointer;
	border: 1px solid ${colors.common.white};

	${({ isActive }) =>
		isActive &&
		css`
			border-color: ${colors.primary.dark};
		`};
`;

const UserContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${sizes.spacing(1)};
`;

const PopupUserAvatarContainer = styled.div`
	width: 80px;
	height: 80px;
	aspect-ratio: 1;

	.user-initials {
		font-size: 42px;
	}
`;

const MobileUserPreviewPopupContainer = styled(Modal)`
	.ReactModal__Content {
		padding: 0;
		width: fit-content;
	}

	${PopupAvatarsContainer} {
		padding: ${sizes.spacing(1)} ${sizes.spacing(1.5)};
		padding-bottom: 0;
		margin-bottom: 0;
		align-items: center;
		justify-content: center;
	}

	${UserContainer} {
		padding: ${sizes.spacing(1)} ${sizes.spacing(1.5)};
		width: fit-content;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	${PopupUserAvatarContainer} {
		margin: ${sizes.spacing(1)} 0;
	}
`;

const AvatarContainer = styled.div`
	position: relative;
	width: 33px;
	height: 33px;
	display: flex;
	align-items: center;
	z-index: ${props => 4 - props.$zIndex};

	&:nth-child(2) {
		margin-left: -10px;
	}
	&:nth-child(3) {
		margin-left: -11px;
	}

	&:before {
		content: '';
		position: absolute;
		width: 32px;
		height: 50px;
		background-color: transparent;
	}
`;

const PopupTextSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const PopupTeamName = styled.div`
	font-weight: 700;
	color: ${colors.text.grey};
	padding-left: ${sizes.spacing(1)};
`;

const PopupFullName = styled.div`
	font-weight: 700;
	padding-left: ${sizes.spacing(1)};
`;

const PopupCopyElement = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${sizes.spacing(2)};
	align-items: center;
	justify-content: space-between;
	padding: ${sizes.spacing(1)};
	border-radius: 10px;

	&:last-child {
		margin-bottom: 0;
	}
`;

const CopyButton = styled.div`
	color: ${colors.text.secondary};
	cursor: pointer;
`;

const MoreUsers = styled.div`
	min-width: 100%;
	height: 100%;
	background-color: ${colors.text.greyLight};
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	aspect-ratio: 1;
`;

// !!! NOTE: When using this component in a table make sure to add this option:
// !!! customBodyCellContentStyles: {
// !!!     overflow: 'visible',
// !!! },
// !!!
// !!! Also add useCustomCellStyles in useStandardTable
const UsersPreview = ({
	users: propUsers,
	displayTeam,
	popupProps,
	disableHover,
	useArray,
	omitStopPropagation,
}) => {
	const { t } = useTranslation();
	const [isTop, setIsTop] = useState(false);
	const userPreviewPopupRefs = [useRef(null), useRef(null), useRef(null)];
	const [shownPopups, setShownPopups] = useState([false, false, false]);
	const [clickedIndex, setClickedIndex] = useState(0);
	const isShownPopup = useMemo(() => shownPopups.some(popup => popup), [shownPopups]);
	const { isMobile } = useResponsive();

	const UserPreviewPopupContainer = isMobile
		? MobileUserPreviewPopupContainer
		: UserPreviewPopupContainerDesktop;

	const handleContainerClick = e => isMobile && e.stopPropagation();

	const handleAvatarClick = useCallback(
		i => {
			setClickedIndex(i);
			setShownPopups(prev => [...prev.slice(0, i), !prev[i], ...prev.slice(i + 1)]);
		},
		[setShownPopups, setClickedIndex],
	);

	const handleAvatarMouseEnter = i => {
		setClickedIndex(i);
		setShownPopups(prev => [...prev.slice(0, i), true, ...prev.slice(i + 1)]);
	};

	const handleAvatarMouseLeave = i => {
		setClickedIndex(0);
		setShownPopups(prev => [...prev.slice(0, i), false, ...prev.slice(i + 1)]);
	};

	const handlePopupAvatarClick = i => {
		setClickedIndex(i);
	};

	const handleCopyText = (e, text) => {
		e.stopPropagation();
		navigator.clipboard
			.writeText(text)
			.then(() => {
				notify(t('Copied to clipboard'), {
					type: toast.TYPE.SUCCESS,
				});
			})
			.catch(err => {
				notify(t('Failed to copy to clipboard'), {
					type: toast.TYPE.ERROR,
				});
			});
	};

	useEffect(() => {
		const observer = new IntersectionObserver(entries =>
			entries.forEach(entry => {
				if (entry.isIntersecting && entry.intersectionRatio < 1) {
					setIsTop(prev => !prev);
				}
			}),
		);

		userPreviewPopupRefs.forEach(ref => ref.current && observer.observe(ref.current));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const userArr = displayTeam
		? [
				propUsers['Sales'],
				propUsers['Tech'],
				propUsers['Fundraising'],
				propUsers['Marketing'],
				propUsers['Asset Management'],
				propUsers['Finance'],
		  ]
		: useArray
		? propUsers
		: Object.values(propUsers);
	const users = userArr.filter(el => Boolean(el));

	const sortedEntryUsers =
		displayTeam && Object.keys(propUsers).length <= 5
			? [
					['Sales', propUsers['Sales']],
					['Tech', propUsers['Tech']],
					['Fundraising', propUsers['Fundraising']],
					['Marketing', propUsers['Marketing']],
					['Asset Management', propUsers['Asset Management']],
					['Finance', propUsers['Finance']],
			  ]
			: Object.entries(propUsers);

	const entryUsers = sortedEntryUsers.filter(el => Boolean(el[1])).slice(0, 20);

	const getAvatarContainerProps = i =>
		isMobile
			? {
					onClick: () => handleAvatarClick(i),
			  }
			: {
					onMouseEnter: () => handleAvatarMouseEnter(i),
					onMouseLeave: () => handleAvatarMouseLeave(i),
			  };

	const getPopupProps = i =>
		isMobile
			? {}
			: {
					ref: userPreviewPopupRefs[i],
			  };

	return (
		<UsersContainer shownPopups={isShownPopup} onClick={e => !omitStopPropagation && e.stopPropagation()}>
			{users.map((user, i, initialArr) => {
				if (i < 3) {
					return (
						<AvatarContainer
							// eslint-disable-next-line react/no-array-index-key
							key={`user-${user.email}-${i}`}
							$zIndex={i}
							{...getAvatarContainerProps(i)}
						>
							{!disableHover && (
								<UserPreviewPopupContainer
									isOpen={shownPopups[i]}
									$isTop={isTop}
									onClick={e => e.stopPropagation()}
									{...popupProps}
									{...getPopupProps(i)}
								>
									{users.length > 1 && (
										<PopupAvatarsContainer onClick={handleContainerClick}>
											{users.slice(0, 20).map((user, j) => (
												<PopupAvatarContainer
													// eslint-disable-next-line react/no-array-index-key
													key={`user-${user.email}-${j}`}
													isActive={j === clickedIndex}
													onClick={() => handlePopupAvatarClick(j)}
												>
													<UserAvatar imgUrl={user?.profilePhoto?.url} isNormalCursor={disableHover} {...user} />
												</PopupAvatarContainer>
											))}
										</PopupAvatarsContainer>
									)}
									{entryUsers.map(([team, user], j) =>
										clickedIndex === j ? (
											// eslint-disable-next-line react/no-array-index-key
											<UserContainer key={team + j + i} onClick={handleContainerClick}>
												<PopupAvatarCentered>
													<PopupUserAvatarContainer>
														<UserAvatar imgUrl={user?.profilePhoto?.url} isNormalCursor={!disableHover} {...user} />
													</PopupUserAvatarContainer>
												</PopupAvatarCentered>
												<PopupTextSection>
													{displayTeam && <PopupTeamName>{team}</PopupTeamName>}
													<PopupFullName>{user.fullName}</PopupFullName>
													{user.email && (
														<PopupCopyElement>
															<ExternalLink location={`mailto:${user.email}`}>{user.email} </ExternalLink>
															<CopyButton onClick={e => handleCopyText(e, user.email)}>
																<Icon icon="contentCopy" size="small" />
															</CopyButton>
														</PopupCopyElement>
													)}
													{user.phone && (
														<PopupCopyElement>
															<ExternalLink location={`tel:${user.phone}`}>{user.phone}</ExternalLink>
															<CopyButton onClick={e => handleCopyText(e, user.phone)}>
																<Icon icon="contentCopy" size="small" />
															</CopyButton>
														</PopupCopyElement>
													)}
												</PopupTextSection>
											</UserContainer>
										) : null,
									)}
								</UserPreviewPopupContainer>
							)}
							<PopupAvatarContainer>
								{i > 2 ? (
									<></>
								) : i === 2 ? (
									<MoreUsers>+{initialArr.length - 2}</MoreUsers>
								) : (
									<UserAvatar imgUrl={user?.profilePhoto?.url} isNormalCursor={disableHover} {...user} />
								)}
							</PopupAvatarContainer>
						</AvatarContainer>
					);
				}
				return null;
			})}
		</UsersContainer>
	);
};

UsersPreview.defaultProps = {
	users: {},
	displayTeam: false,
	disableHover: false,
	popupProps: {},
	useArray: false,
	omitStopPropagation: false,
};

UsersPreview.propTypes = {
	users: PropTypes.oneOfType([
		PropTypes.shape({
			firstName: PropTypes.string,
			lastName: PropTypes.string,
			fullName: PropTypes.string,
			email: PropTypes.string,
			phone: PropTypes.string,
			profilePhoto: PropTypes.shape({
				id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				url: PropTypes.string,
			}),
			Sales: PropTypes.shape({}),
			Tech: PropTypes.shape({}),
			Fundraising: PropTypes.shape({}),
			Marketing: PropTypes.shape({}),
			'Asset Management': PropTypes.shape({}),
			Finance: PropTypes.shape({}),
		}),
		PropTypes.arrayOf(PropTypes.shape({})),
	]),
	useArray: PropTypes.bool,
	displayTeam: PropTypes.bool,
	disableHover: PropTypes.bool,
	popupProps: PropTypes.shape({}),
	omitStopPropagation: PropTypes.bool,
};

export default UsersPreview;
