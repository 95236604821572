import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import { getRecentYearOptions } from 'Common/utils/getRecentYears';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

import loadIndustryOptions from 'Industries/utils/loadIndustryOptions';
import IndustrySelectOption from 'Industries/components/IndustrySelectOption';
import IndustrySelectValue from 'Industries/components/IndustrySelectValue';

import editClient from 'Client/api/editClient';
import useClientDetailsFormInitialValues from 'Client/components/ClientDetails/Tabs/GeneralTab/Tiles/ClientDetailsTile/hooks/useClientDetailsFormInitialValues';
import useClientDetailsFormValidationSchema from 'Client/components/ClientDetails/Tabs/GeneralTab/Tiles/ClientDetailsTile/hooks/useClientDetailsFormValidationSchema';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import ConditionalGridSection from 'Common/components/ConditionalGridSection';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';

const ClientDetailsTile = ({
	client,
	onEnterEditMode,
	onExitEditMode,
	onDirtyForm,
	onSave,
	highlightLabels,
}) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.SALES,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.PM_TECHNICAL,
		roles.ASSET_MANAGER,
		roles.HEAD_OF_ESG,
	]);

	const isAuthorizedToEditExcluded = isAuthorized([
		roles.ADMIN,
		roles.HEAD_OF_ASSET_MANAGEMENT,
		roles.HEAD_OF_MARKETING,
		roles.HEAD_OF_FUNDRAISING,
		roles.HEAD_OF_TECH,
		roles.HEAD_OF_SALES,
		roles.HEAD_OF_ESG,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useClientDetailsFormInitialValues(client);
	const validationSchema = useClientDetailsFormValidationSchema();

	const isIndustryFieldDisabled = useMemo(
		() => client?.industry?.excluded && !isAuthorizedToEditExcluded,
		[client, isAuthorizedToEditExcluded],
	);

	const isPINVisible = useMemo(() => client?.country?.isoCode === 'KEN', [client]);

	const { errors, touched, values, handleSubmit, dirty, handleChange, handleBlur, setFieldValue, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			enableReinitialize: true,
			onSubmit: async values => {
				setIsSavingChanges(true);
				let response;

				try {
					response = await editClient(abortController.signal, {
						...client,
						...omit(values, ['foundationYear', 'industry']),
						foundationYearAsInt: values.foundationYear ? values.foundationYear.value : null,
						industryId: values.industry ? values.industry.value : null,
						pin: values.pin && isPINVisible ? values.pin : '',
					});

					if (onDirtyForm) {
						onDirtyForm(false);
					}

					onSave(
						omit(response.data, [
							'country',
							'address',
							'projects',
							'totalProjects',
							'totalSystemSize',
							'createdDate',
							'contacts',
						]),
					);
					handleCancel();
					setIsSavingChanges(false);
					resetForm({ values });
				} catch (e) {
					showToastError(e);
					if (!axios.isCancel(e)) {
						handleCancel();
						setIsSavingChanges(false);
					}
				}
			},
		});

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	const websiteViewValue =
		values.website === '' ? (
			'-'
		) : (
			<a
				href={/https?:\/\//.test(values.website) ? values.website : `http://${values.website}`}
				target="_blank"
				rel="noreferrer noreferrer"
			>
				{values.website}
			</a>
		);

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Client details')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<FormField>
				<FormikInput
					id="name"
					name="name"
					label={t('Name')}
					value={values.name}
					error={errors.name}
					touched={touched.name}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isRequired={isInEditMode}
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DETAILS.CLIENT_NAME,
					)}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="registrationNumber"
					name="registrationNumber"
					label={t('Registration number')}
					value={values.registrationNumber}
					error={errors.registrationNumber}
					touched={touched.registrationNumber}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DETAILS.REGISTRATION_NUMBER,
					)}
				/>
			</FormField>
			<ConditionalGridSection hasFlag={isPINVisible}>
				<FormField>
					<FormikInput
						id="pin"
						name="pin"
						label={t('PIN')}
						value={values.pin}
						error={errors.pin}
						touched={touched.pin}
						onChange={handleChange}
						onBlur={handleBlur}
						isTile
						isInEditMode={isInEditMode}
						isHighlighted={highlightLabels.includes(PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DETAILS.PIN)}
					/>
				</FormField>
			</ConditionalGridSection>
			<FormField>
				{isInEditMode ? (
					<FormikInput
						id="website"
						name="website"
						label={t('Website')}
						value={values.website}
						error={errors.website}
						touched={touched.website}
						onChange={handleChange}
						onBlur={handleBlur}
						isTile
						isInEditMode={isInEditMode}
						isHighlighted={highlightLabels.includes(
							PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DETAILS.CLIENT_WEBSITE,
						)}
					/>
				) : (
					<KeyValueVisualization
						title={t('Website')}
						value={websiteViewValue}
						hasHoverEffect
						isHighlighted={highlightLabels.includes(
							PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DETAILS.CLIENT_WEBSITE,
						)}
					/>
				)}
			</FormField>
			<FormField>
				<FormikSelect
					id="industry"
					name="industry"
					label={t('Industry')}
					value={values.industry}
					error={errors.industry}
					touched={touched.industry}
					isClearable
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={loadIndustryOptions}
					isRequired={isInEditMode}
					isDisabled={isIndustryFieldDisabled}
					isTile
					components={{
						Option: IndustrySelectOption,
						SingleValue: IndustrySelectValue,
					}}
					isAsync
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DETAILS.INDUSTRY)}
				/>
			</FormField>

			<FormField>
				<FormikSelect
					id="foundationYear"
					name="foundationYear"
					label={t('Client Foundation Year')}
					value={values.foundationYear}
					error={errors.foundationYear}
					touched={touched.foundationYear}
					setFieldValue={setFieldValue}
					options={getRecentYearOptions()}
					isTile
					isClearable
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DETAILS.CLIENT_FOUNDATION_YEAR,
					)}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="employees"
					name="employees"
					label={t('Employees')}
					value={values.employees}
					error={errors.employees}
					touched={touched.employees}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DETAILS.EMPLOYEES,
					)}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="percentageOfFemaleShare"
					name="percentageOfFemaleShare"
					label={t('Female Employees')}
					value={values.percentageOfFemaleShare}
					error={errors.percentageOfFemaleShare}
					touched={touched.percentageOfFemaleShare}
					onChange={handleChange}
					onBlur={handleBlur}
					unit={'%'}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DETAILS.FEMALE_EMPLOYEES,
					)}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="managers"
					name="managers"
					label={t('Managers')}
					value={values.managers}
					error={errors.managers}
					touched={touched.managers}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DETAILS.MANAGERS)}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="percentageOfFemaleManagers"
					name="percentageOfFemaleManagers"
					label={t('Female managers')}
					value={values.percentageOfFemaleManagers}
					error={errors.percentageOfFemaleManagers}
					touched={touched.percentageOfFemaleManagers}
					onChange={handleChange}
					onBlur={handleBlur}
					unit={'%'}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DETAILS.FEMALE_MANAGERS,
					)}
				/>
			</FormField>
		</EditableTile>
	);
};

ClientDetailsTile.defaultProps = {
	client: {},
	onSave: () => {},
	onDirtyForm: () => {},
	onEnterEditMode: () => {},
	onExitEditMode: () => {},
	highlightLabels: [],
};

ClientDetailsTile.propTypes = {
	onSave: PropTypes.func,
	client: PropTypes.shape({
		industry: PropTypes.shape({ excluded: PropTypes.bool }),
		country: PropTypes.shape({ isoCode: PropTypes.string }),
	}),
	onDirtyForm: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onEnterEditMode: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default ClientDetailsTile;
