import getAllStepTemplates from 'PMWorkflow/api/getAllStepTemplates';

const loadStepNames = async (signal, searchQuery) => {
	const steps = await getAllStepTemplates(signal, searchQuery);

	return steps.data
		.map(step => ({
			key: step.id,
			value: step.id,
			label: step.title,
			title: step.title,
			showCompleted: false,
		}))
		.sort((a, b) => a.label.localeCompare(b.label));
};

export default loadStepNames;
