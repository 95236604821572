import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';

import loadPartnerTypeOptions from 'Partners/components/PartnerForm/utils/loadPartnerTypeOptions';
import updatePartner from 'Partners/api/updatePartner';
import usePartnerDetailsFormInitialValues from 'Partners/components/PartnerDetails/Tabs/DetailsTab/Tiles/PartnerDetailsTile/hooks/usePartnerDetailsFormInitialValues';
import usePartnerDetailsFormValidationSchema from 'Partners/components/PartnerDetails/Tabs/DetailsTab/Tiles/PartnerDetailsTile/hooks/usePartnerDetailsFormValidationSchema';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { pick } from 'lodash';

const PartnerDetailsTile = ({ partner, onEnterEditMode, onExitEditMode, onSave, onDirtyForm }) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.SALES,
		roles.PM_TECHNICAL,
		roles.ASSET_MANAGER,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = usePartnerDetailsFormInitialValues(partner);
	const validationSchema = usePartnerDetailsFormValidationSchema();

	const { errors, touched, values, handleSubmit, dirty, handleChange, handleBlur, resetForm, setFieldValue } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: async values => {
				setIsSavingChanges(true);

				try {
					const response = await updatePartner(abortController.signal, {
						...partner,
						partnerTypeId: values.partnerType ? values.partnerType.value : null,
						...pick(values, ['name', 'description']),
					});

					if (onDirtyForm) {
						onDirtyForm(false);
					}

					onSave(response.data);
					handleCancel();
					setIsSavingChanges(false);

					// Re-set all fields and mark them as not touched
					resetForm({ values });
				} catch (error) {
					showToastError(error, t("Can't update Partner details"));
					if (!axios.isCancel(error)) {
						setIsSavingChanges(false);
					}
				}
			},
		});

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('partner details')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<FormField>
				<FormikInput
					id="name"
					name="name"
					label={t('Name')}
					value={values.name}
					error={errors.name}
					touched={touched.name}
					onChange={handleChange}
					onBlur={handleBlur}
					isRequired
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					id="partnerType"
					name="partnerType"
					label={t('Partner Type')}
					value={values.partnerType}
					error={errors.partnerType}
					touched={touched.partnerType}
					isClearable
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={loadPartnerTypeOptions}
					isRequired={isInEditMode}
					isTile
					isAsync
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="description"
					name="description"
					label={t('Description')}
					value={values.description}
					error={errors.description}
					touched={touched.description}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
		</EditableTile>
	);
};

PartnerDetailsTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
};

PartnerDetailsTile.propTypes = {
	partner: PropTypes.shape({
		name: PropTypes.string,
		partnerType: PropTypes.shape({
			name: PropTypes.string,
		}),
		description: PropTypes.string,
		tier: PropTypes.bool,
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onSave: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
};

export default PartnerDetailsTile;
