import formatDateForRequestData from 'Common/utils/formatDateForRequestData';

const convertInquiryToRequestData = values => ({
	epcPartnerId: values.epcPartner.value,
	initialEngagementDate: values.initialEngagementDate
		? formatDateForRequestData(values.initialEngagementDate)
		: null,
	offerValidityDate: values.offerValidityDate ? formatDateForRequestData(values.offerValidityDate) : null,
});

export default convertInquiryToRequestData;
