import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import colors from 'Application/theme/colors';
import Label from 'Common/components/form/Label';
import Mandatory from 'Common/components/form/Mandatory';
import ErrorMessage from 'Common/components/form/ErrorMessage';
import FormikInput from 'Common/components/form/FormikInput';

const Wrapper = styled.div`
	label {
		color: ${({ hasFocus }) => (hasFocus ? colors.primary.main : colors.grey.main)};
	}

	&:hover {
		label {
			color: ${({ isInEditMode, isOpen }) =>
				isInEditMode && !isOpen ? colors.primary.dark : colors.primary.lightGreen};
		}
	}

	${({ isOpen }) =>
		isOpen &&
		css`
			label {
				color: ${({ isCreating }) => (isCreating ? colors.primary.main : colors.primary.dark)};
			}
		`}
`;

const SelectContainer = styled.div`
	position: ${({ isMenuFixed }) => (isMenuFixed ? 'unset' : 'relative')};
`;

const SelectOptions = styled.div`
	position: absolute;
	top: ${({ isMenuFixed }) => (isMenuFixed ? 'unset' : '42px')};
	left: ${({ isMenuFixed }) => (isMenuFixed ? 'unset' : '0px')};

	border-radius: 8px;

	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	background-color: white;

	/* opacity: 0; */
	display: none;
	transform: translateY(-10px);
	transition: all ease-out 0.2s;

	z-index: 99;
	${({ isOpen }) =>
		isOpen &&
		css`
			display: block;
			transform: translateY(0px);
		`}
`;

const optionTypes = {
	decrement: css`
		padding: 15px 38px 10px 38px;
		border-bottom: solid 1px ${colors.grey.main};
		&:before {
			transform: rotate(135deg);
		}
	`,
	increment: css`
		padding: 10px 38px 15px 38px;
		border-top: solid 1px ${colors.grey.main};
		&:before {
			transform: rotate(-45deg);
		}
	`,
};

const OptionController = styled.div`
	position: relative;
	cursor: pointer;

	&:before {
		content: '';
		width: 10px;
		height: 10px;
		display: block;
		border-bottom: solid 3px ${colors.text.main};
		border-left: solid 3px ${colors.text.main};
	}

	${({ type }) => optionTypes[type]}};
`;

const TimeOptionsContainer = styled.div`
	position: relative;
	max-width: 90px;
	max-height: 200px;
	overflow: hidden;

	${({ $isMobile }) =>
		$isMobile &&
		css`
			max-height: 120px;

			&:before {
				top: 40px !important;
			}
			&:after {
				top: 80px !important;
			}
		`}

	&:before {
		content: '';
		position: absolute;
		top: 80px;
		left: 10%;
		width: 80%;
		height: 2px;
		background-color: ${colors.grey.main};
	}
	&:after {
		content: '';
		position: absolute;
		top: 120px;
		left: 10%;
		width: 80%;
		height: 2px;
		background-color: ${colors.grey.main};
	}
`;

const InnerTimeOptionsContainer = styled.ul`
	padding: 0;
	margin: 0;
	max-height: 200px;
	list-style: none;
	min-width: 100px;
	overflow-y: auto;
	overflow-x: hidden;
`;

const TimeOption = styled.li`
	padding: 10px 24px;
	text-align: center;
	min-height: 40px;
	max-height: 40px;
	cursor: pointer;
	${({ active }) =>
		active &&
		css`
			color: ${colors.primary.main};
		`}
`;

const useDefaultHoursState = (value, isMobile) =>
	useMemo(() => {
		const hoursArr = isMobile
			? [{ active: false, value: '' }]
			: [
					{ active: false, value: '' },
					{ active: false, value: '' },
			  ];
		for (let hour = 0; hour <= 24; hour++) {
			hoursArr.push({
				value: hour,
				active: value === hour,
				offsetTop: hour * 40,
			});
		}
		hoursArr.push({ active: false, value: '' });
		if (!isMobile) {
			hoursArr.push({ active: false, value: '' });
		}
		return hoursArr;
	}, [value, isMobile]);

const FormikTimeSelect = ({
	label,
	isRequired,
	id,
	value,
	name,
	error,
	touched,
	setFieldValue,
	setFieldTouched,
	onBlur,
	isInEditMode,
	isMenuFixed,
	isCreating,
	isDisabled,
	tooltip,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const hoursScrollContainer = useRef(undefined);
	const selectRef = useRef(undefined);

	const isMobile = window.innerWidth <= 768;
	const initialHoursState = useDefaultHoursState(value, isMobile);
	const [hours, setHours] = useState(initialHoursState);

	const hasError = !!error && ((typeof touched === 'boolean' && touched) || typeof touched === 'object');

	const onKeyDown = e => {
		const numbers = /\d+/g;

		if (e.key.match(numbers)) {
			if (value === 23 && e.key === '5') {
				setFieldValue(name, 24);
			} else if ((value === 2 && Number(e.key) <= 4) || value === 1) {
				setFieldValue(name, Number(value + e.key));
			} else {
				setFieldValue(name, Number(e.key));
			}
		}
	};

	const handleActiveHour = useCallback(() => {
		const scrollTop = hoursScrollContainer.current.scrollTop;
		const activeHour = hours.find(hour => hour.value === Math.floor(scrollTop / 40)).value;

		if ((activeHour || activeHour === 0) && activeHour !== value) {
			setFieldValue(name, activeHour);
		}
	}, [hours, name, setFieldValue, value]);

	const scrollToElement = useCallback(() => {
		hoursScrollContainer.current.scroll(0, value * 40);
		setHours(prevHours => {
			const activeHourIndex = prevHours.findIndex(el => el.value === value);
			return [
				...prevHours.slice(0, activeHourIndex).map(el => ({ ...el, active: false })),
				{ ...prevHours[activeHourIndex], active: true },
				...prevHours.slice(activeHourIndex + 1).map(el => ({ ...el, active: false })),
			];
		});
	}, [value]);

	const changeHour = (e, factor) => {
		e.preventDefault();
		e.stopPropagation();
		const scrollTop = hoursScrollContainer.current.scrollTop;
		hoursScrollContainer.current.scroll(0, scrollTop + factor * 40);
		handleActiveHour();
	};

	const optionClickHandler = useCallback(
		(e, hour, index) => {
			e.preventDefault();

			if (hour.value !== '') {
				setFieldValue(name, index - (isMobile ? 1 : 2));
			}
			if (value === hour.value) {
				setIsOpen(false);
			}
		},
		[value, name, setFieldValue, isMobile],
	);

	useEffect(() => {
		if (value || value === 0) {
			scrollToElement();
		}
	}, [value, scrollToElement, isOpen]);

	const handleClickOutside = useCallback(
		e => {
			if (isOpen && selectRef && !selectRef?.current.contains(e.target)) {
				setIsOpen(false);
			}
		},
		[isOpen],
	);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);

		return () => document.removeEventListener('click', handleClickOutside);
	}, [handleClickOutside]);

	const handleBlur = (...args) => {
		if (setFieldTouched) {
			setFieldTouched(name, true);
		}

		if (onBlur) {
			onBlur(...args);
		}
	};

	return (
		<Wrapper isInEditMode={isInEditMode} isCreating={isCreating} isOpen={isOpen}>
			{!!label && (
				<Label
					tooltip={tooltip}
					label={
						<>
							{label}
							{isRequired && <Mandatory />}
						</>
					}
					hasError={hasError}
					htmlFor={id}
				/>
			)}

			<SelectContainer ref={selectRef} isMenuFixed={isMenuFixed}>
				<FormikInput
					id={id}
					autoComplete="off"
					onKeyDown={onKeyDown}
					onChange={() => {}}
					value={value !== null ? (value === 24 ? '23:59' : `${value}:00`) : ''}
					onClick={() => setIsOpen(!isOpen)}
					onBlur={handleBlur}
					isDisabled={isDisabled}
				></FormikInput>
				<SelectOptions isOpen={isOpen} isMenuFixed={isMenuFixed}>
					<OptionController type="decrement" onClick={e => changeHour(e, -1)} />
					<TimeOptionsContainer $isMobile={isMobile}>
						<InnerTimeOptionsContainer onScroll={handleActiveHour} ref={hoursScrollContainer}>
							{hours.map((hour, index) => (
								<TimeOption
									id={hour.value}
									// eslint-disable-next-line react/no-array-index-key
									key={`hour-${index}`}
									active={hour.active}
									onClick={e => optionClickHandler(e, hour, index)}
									isDisabled={isDisabled}
								>
									{hour.value !== '' ? (hour.value === 24 ? '23:59' : `${hour.value}:00`) : ''}
								</TimeOption>
							))}
						</InnerTimeOptionsContainer>
					</TimeOptionsContainer>
					<OptionController type="increment" onClick={e => changeHour(e, 1)} />
				</SelectOptions>
			</SelectContainer>

			{hasError && <ErrorMessage message={error} />}
		</Wrapper>
	);
};

FormikTimeSelect.defaultProps = {
	label: '',
	id: '',
	name: '',
	value: 0,
	error: '',
	touched: false,
	setFieldValue: undefined,
	setFieldTouched: undefined,
	onBlur: undefined,
	isInEditMode: true,
	isMenuFixed: false,
	isDisabled: false,
	isRequired: false,
	isCreating: false,
	tooltip: null,
};

FormikTimeSelect.propTypes = {
	label: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string,
	value: PropTypes.number,
	error: PropTypes.string,
	touched: PropTypes.bool,
	setFieldValue: PropTypes.func,
	setFieldTouched: PropTypes.func,
	onBlur: PropTypes.func,
	isInEditMode: PropTypes.bool,
	isMenuFixed: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isRequired: PropTypes.bool,
	isCreating: PropTypes.bool,
	tooltip: PropTypes.node,
};

export default FormikTimeSelect;
