import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import getFilterRange from 'Campaigns/api/getFilterRange';
import { statusOptions } from 'Campaigns/constants/campaignStatuses';

import modifyNumberFilterData from 'Common/components/filter/hooks/modifyNumberFilterData';
import showToastError from 'Common/utils/showToastError';
import { FILTER_TYPES } from 'Common/components/filter/constants/filterConstants';
import useAbortController from 'Common/hooks/useAbortController';
import loadCountryOptions from 'Country/utils/loadCountryOptions';
import axios from 'axios';

const fallbackFilterRanges = {
	investmentsCountMax: 0,
	investmentsCountMin: 0,
	investmentsSumMax: 0,
	investmentsSumMin: 0,
	maximumVolumeMax: 0,
	maximumVolumeMin: 0,
};

const useCampaignsListFilters = () => {
	const { t } = useTranslation();

	const [filterRanges, setFilterRanges] = useState(fallbackFilterRanges);

	const abortController = useAbortController();

	const generateFiltersData = [
		{
			name: 'status',
			label: t('Status'),
			type: FILTER_TYPES.SELECT,
			options: statusOptions,
		},
		{
			name: 'name',
			label: t('Name'),
			type: FILTER_TYPES.INPUT,
		},
		{
			name: 'maximumVolume',
			label: t('Finance vol.'),
			type: FILTER_TYPES.NUMBER,
			minProps: {
				icon: '€',
			},
			maxProps: {
				icon: '€',
			},
			minName: 'maximumVolumeFrom',
			maxName: 'maximumVolumeTo',
			lowerBound: filterRanges.maximumVolumeMin,
			upperBound: filterRanges.maximumVolumeMax,
		},
		{
			name: 'investmentsSum',
			label: t('Investments'),
			type: FILTER_TYPES.NUMBER,
			minProps: {
				icon: '€',
			},
			maxProps: {
				icon: '€',
			},
			minName: 'investmentsSumFrom',
			maxName: 'investmentsSumTo',
			lowerBound: filterRanges.investmentsSumMin,
			upperBound: filterRanges.investmentsSumMax,
		},
		{
			name: 'countryId',
			label: t('Country'),
			loadOptions: loadCountryOptions,
			type: FILTER_TYPES.ASYNC_SELECT,
		},
		{
			name: 'emissionSpvName',
			label: t('Emission SPV'),
			type: FILTER_TYPES.INPUT,
		},
		{
			name: 'projectId',
			label: t('Project ID'),
			type: FILTER_TYPES.INPUT,
			validation: 'number',
		},
		{
			name: 'externalId',
			label: t('ID'),
			type: FILTER_TYPES.INPUT,
		},
	];

	useEffect(() => {
		(async () => {
			try {
				const response = await getFilterRange(abortController.signal);

				setFilterRanges(prevFilterRanges => ({
					...prevFilterRanges,
					...response.data,
				}));
			} catch (error) {
				showToastError(error, t("Can't fetch filter range options"));
				if (!axios.isCancel(error)) {
					setFilterRanges(fallbackFilterRanges);
				}
			}
		})();
	}, [abortController.signal, t]);

	const [filtersData, setFiltersData] = useState(generateFiltersData);

	useEffect(() => {
		const modifyNumberFilterDataBinded = modifyNumberFilterData.bind(undefined, filterRanges);

		setFiltersData(prevFilterData => [...prevFilterData].map(modifyNumberFilterDataBinded));
	}, [filterRanges]);

	return filtersData;
};

export default useCampaignsListFilters;
