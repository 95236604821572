import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useBankAccountFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				name: yup.string().required(t('Bank name is required')),
			}),
		[t],
	);
};

export default useBankAccountFormValidationSchema;
