import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Filter from 'Common/components/filter/Filter';
import FormikInput from 'Common/components/form/FormikInput';

const StyledFormikInput = styled(FormikInput)`
	font-size: 14px;
`;

const InputFilter = ({
	name,
	label,
	setFilterValue,
	value,
	isClearable,
	toggleOpen,
	inputType,
	...props
}) => {
	const handleClear = () => {
		setFilterValue({ [name]: '' });
	};

	const inputRef = useRef();
	const [hasFocus, setFocus] = useState(false);
	const handleToggleOpen = (...args) => {
		toggleOpen(...args);
		setFocus(prevFocus => !prevFocus);
	};

	useEffect(() => {
		if (hasFocus && inputRef.current) {
			inputRef.current.focus();
		}
	}, [hasFocus, inputRef]);

	const handleChange = e => {
		setFilterValue({ [name]: e.target.value });
	};

	return (
		<Filter
			label={label}
			hasSelected={!!value}
			handleClearOptions={handleClear}
			hasPadding
			toggleOpen={handleToggleOpen}
			{...props}
			popupContent={
				<StyledFormikInput
					value={value}
					onChange={handleChange}
					isClearable={isClearable}
					onClear={handleClear}
					placeholder={'Search'}
					ref={inputRef}
					inputType={inputType}
				/>
			}
		/>
	);
};

InputFilter.defaultProps = {
	isClearable: true,
	inputType: 'text',
	value: '',
};

InputFilter.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	setFilterValue: PropTypes.func.isRequired,
	toggleOpen: PropTypes.func.isRequired,
	value: PropTypes.string,
	isClearable: PropTypes.bool,
	inputType: PropTypes.string,
};

export default InputFilter;
