import getPlatforms from 'Preferences/api/getPlatforms';

const loadPlatformOptions = async signal => {
	const response = await getPlatforms(signal);
	const platforms = response.data;

	return platforms.map(platform => ({
		value: platform.id,
		label: platform.name,
	}));
};

export default loadPlatformOptions;
