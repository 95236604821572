import styled from 'styled-components/macro';

const CenteredWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding-top: 20px;
`;

const CenteredContent = Story => (
	<CenteredWrapper>
		<Story />
	</CenteredWrapper>
);

export default CenteredContent;
