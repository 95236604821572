import api from 'Application/api/api';

const getInsuranceDownloadLink = async (signal, fileName, versionId) => {
	const response = await api.get(`/insurances/download-link?fileName=${fileName}&versionId=${versionId}`, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
	};
};

export default getInsuranceDownloadLink;
