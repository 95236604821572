import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';

import getProjectDefaults from 'Preferences/api/getProjectDefaults';
import editProjectDefaults from 'Preferences/api/editProjectDefaults';

import useRestrictedIndustriesValidationSchema from './hooks/useRestrictedIndustriesValidationSchema';
import useRestrictedIndustriesFormInitialValues from './hooks/useRestrictedIndustriesFormInitialValues';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const ProjectDefaultsTile = () => {
	const { t } = useTranslation();

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [projectDefaults, setProjectDefaults] = useState(null);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [isLoadingContent, setIsLoadingContent] = useState(false);

	const abortController = useAbortController();

	const initialValues = useRestrictedIndustriesFormInitialValues(projectDefaults);
	const validationSchema = useRestrictedIndustriesValidationSchema();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.SALES,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.PM_TECHNICAL,
		roles.ASSET_MANAGER,
		roles.HEAD_OF_ESG,
	]);

	const { errors, touched, values, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			setIsSavingChanges(true);

			try {
				const defaults = await getProjectDefaults(abortController.signal);
				const defaultsData = defaults.data;
				const response = await editProjectDefaults(abortController.signal, {
					...defaultsData,
					...values,
				});

				setProjectDefaults(response.data);
				setIsInEditMode(false);
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					setIsInEditMode(false);
					setIsSavingChanges(false);
					resetForm({ values });
				}
			}
		},
	});

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			setIsInEditMode(true);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				setIsLoadingContent(true);
				const response = await getProjectDefaults(abortController.signal);
				setIsLoadingContent(false);
				setProjectDefaults(response.data);
			} catch (error) {
				showToastError(error);
			}
		})();
	}, [abortController.signal]);

	return (
		<EditableTile
			title={t('Restricted Industries')}
			isLoading={isSavingChanges}
			isLoadingContent={isLoadingContent}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<FormField>
				<FormikInput
					id="allowedPercentageOfRestrictedIndustries"
					name="allowedPercentageOfRestrictedIndustries"
					label={t('Allowed % of restricted industries')}
					value={values.allowedPercentageOfRestrictedIndustries}
					error={errors.allowedPercentageOfRestrictedIndustries}
					touched={touched.allowedPercentageOfRestrictedIndustries}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
		</EditableTile>
	);
};

export default ProjectDefaultsTile;
