const manufacturedProductTypes = {
	SOLAR_MODULES: 'Solar modules',
	INVERTERS: 'Inverters',
	MOUNTINGS: 'Mountings',
	BATTERIES: 'Batteries',
	LED_LUMINAIRES: 'LED luminaires',
	AC_UNITS: 'AC units',
	SOLAR_HOT_WATER: 'Solar hot water',
};

export default manufacturedProductTypes;
