import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import { SectionWrapper } from 'Common/components/modals/OverlaySections';
import loadProductTypeOptions from 'Projects/utils/loadProductTypeOptions';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DetailsSection = ({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => {
	const { t } = useTranslation();

	return (
		<SectionWrapper $minWidth={200}>
			<FormField data-first-field>
				<FormikInput
					id="displayName"
					name="displayName"
					label={t('Name')}
					value={values.displayName}
					error={errors.displayName}
					touched={touched.displayName}
					onChange={handleChange}
					onBlur={handleBlur}
					isRequired
					isOverlay
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="website"
					name="website"
					label={t('Website')}
					value={values.website}
					error={errors.website}
					touched={touched.website}
					onChange={handleChange}
					onBlur={handleBlur}
					isOverlay
				/>
			</FormField>
			<FormField data-last-field>
				<FormikSelect
					isAsync
					id="productTypes"
					name="productTypes"
					label={t('Products')}
					value={values.productTypes}
					error={errors.productTypes}
					touched={touched.productTypes}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={loadProductTypeOptions}
					isMulti
					isRequired
					menuPosition="fixed"
					isOverlay
				/>
			</FormField>
		</SectionWrapper>
	);
};

DetailsSection.defaultProps = {
	values: {},
	errors: {},
	touched: {},
	handleBlur: () => {},
	handleChange: () => {},
	setFieldValue: () => {},
};

DetailsSection.propTypes = {
	values: PropTypes.shape({
		displayName: PropTypes.string,
		website: PropTypes.string,
		productTypes: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	errors: PropTypes.shape({
		displayName: PropTypes.string,
		website: PropTypes.string,
		productTypes: PropTypes.string,
	}),
	touched: PropTypes.shape({
		displayName: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		website: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		productTypes: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
	}),
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	setFieldValue: PropTypes.func,
};

export default DetailsSection;
