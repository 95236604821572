import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import crudModes from 'Common/constants/crudModes';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import useCustomSubmitFormInitialValues from 'Preferences/components/Tabs/CampaignsTab/SpvProfits/hooks/useCustomSubmitFormInitialValues';
import getSpvProfits from 'Preferences/api/getSpvProfits';
import createSpvProfit from 'Preferences/api/createSpvProfit';
import editSpvProfit from 'Preferences/api/editSpvProfit';
import loadEmissionSpvOptions from 'Preferences/utils/loadEmissionSpvOptions';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { ModalFormButtons } from 'Common/components/form/ModalForm';
import { pick } from 'lodash';
import formatDateForRequestData from 'Common/utils/formatDateForRequestData';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const FieldsWrapper = styled.div`
	width: 100%;
`;

const CustomSubmitForm = ({ data, mode, onSubmit, onCancel, onDirty }) => {
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);
	const abortController = useAbortController();
	const initialValues = useCustomSubmitFormInitialValues(data);
	const convertFormValuesToRequestData = values => {
		return {
			...pick(values, ['value']),
			date: values.date ? formatDateForRequestData(values.date) : null,
			emissionSpvId: values.emissionSPV ? values.emissionSPV.value : null,
		};
	};

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleChange,
		handleBlur,
		setFieldError,
		setFieldValue,
		setFieldTouched,
		resetForm,
	} = useFormik({
		initialValues,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				if (mode === crudModes.CREATE) {
					await createSpvProfit(abortController.signal, convertFormValuesToRequestData(values));
					notify(t('Created successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				} else if (mode === crudModes.EDIT) {
					await editSpvProfit(abortController.signal, {
						...data,
						...convertFormValuesToRequestData(values),
					});
					notify(t('Saved successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				}
				const response = await getSpvProfits(abortController.signal);
				onSubmit(response.data);

				// Re-set all fields and mark them as not touched
				resetForm({ values });
				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form onSubmit={handleSubmit}>
			<FieldsWrapper>
				<FormField>
					<FormikSelect
						isAsync
						isRequired
						id="emissionSPV"
						name="emissionSPV"
						label={t('Emission SPV')}
						value={values.emissionSPV}
						error={errors.emissionSPV}
						touched={touched.emissionSPV}
						setFieldValue={setFieldValue}
						onBlur={handleBlur}
						loadOptions={loadEmissionSpvOptions}
					/>
				</FormField>
				<FormField>
					<FormikInput
						id="value"
						name="value"
						label={t('Profit')}
						value={values.value}
						error={errors.value}
						touched={touched.value}
						onChange={handleChange}
						onBlur={handleBlur}
						isRequired
					/>
				</FormField>
				<FormField>
					<FormikSingleDatePicker
						id="date"
						label={t('Date')}
						startDateName="date"
						error={errors.date}
						touched={touched.date}
						startDate={values.date}
						setFieldValue={setFieldValue}
						setFieldTouched={setFieldTouched}
						isRequired
					/>
				</FormField>
			</FieldsWrapper>
			<ModalFormButtons
				onCancel={onCancel}
				isLoading={isLoading}
				label="Preferences SPV Profit Form"
				submitButtonText={mode === crudModes.CREATE ? t('Create') : t('Save')}
			/>
		</Form>
	);
};

CustomSubmitForm.defaultProps = {
	data: null,
};

CustomSubmitForm.propTypes = {
	data: PropTypes.shape({}),
	mode: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
};

export default CustomSubmitForm;
