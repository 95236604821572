import { toast } from 'react-toastify';

import notify from 'Common/utils/notify';
import capitalizeFirstLetters from 'Common/utils/capitalizeFirstLetters';

import editActivity from 'PMWorkflow/api/editActivity';
import reorderActivities from 'PMWorkflow/api/reorderActivities';
import showToastError from 'Common/utils/showToastError';

const moveActivity = async (
	signal,
	action,
	ids,
	currentIdIndex,
	activity,
	values,
	activities,
	stages,
	stageIndex,
	setStageState,
	onDataChange,
	t,
	setTemplateState,
	setActivitiesByStage,
	updateActivitiesByStage,
	setState,
) => {
	try {
		setState(prevState => ({ ...prevState, isMovingAcitivity: true }));
		setStageState(prevState => ({ ...prevState, isLoadingStage: true }));
		let swapIndex, newIds;

		if (action === 'up') {
			if (currentIdIndex === 0) {
				return stages.map(
					key =>
						key.index === stageIndex - 1 &&
						moveActivityInOtherStage(
							signal,
							key,
							activity,
							values,
							onDataChange,
							setStageState,
							t,
							setTemplateState,
							setActivitiesByStage,
							updateActivitiesByStage,
							setState,
						),
				);
			}

			swapIndex = currentIdIndex - 1;

			newIds = ids
				.slice(0, swapIndex)
				.concat(
					ids[currentIdIndex],
					ids.slice(currentIdIndex, currentIdIndex),
					ids[swapIndex],
					ids.slice(currentIdIndex + 1),
				);
		} else if (action === 'down') {
			if (currentIdIndex === activities.length - 1) {
				return stages.map(
					key =>
						key.index === stageIndex + 1 &&
						moveActivityInOtherStage(
							signal,
							key,
							activity,
							values,
							onDataChange,
							setStageState,
							t,
							setTemplateState,
							setActivitiesByStage,
							updateActivitiesByStage,
							setState,
						),
				);
			}

			swapIndex = currentIdIndex + 1;

			newIds = ids
				.slice(0, currentIdIndex)
				.concat(ids[swapIndex], ids.slice(swapIndex, swapIndex), ids[currentIdIndex], ids.slice(swapIndex + 1));
		}

		await reorderActivities(signal, newIds);

		await setTemplateState();

		await onDataChange();

		await setActivitiesByStage();

		setState(prevState => ({ ...prevState, isMovingAcitivity: false }));
		setStageState(prevState => ({ ...prevState, isLoadingStage: false }));
	} catch (error) {
		showToastError(error);
	}
};

const moveActivityInOtherStage = async (
	signal,
	stage,
	activity,
	values,
	onDataChange,
	setStageState,
	t,
	setTemplateState,
	setActivitiesByStage,
	updateActivitiesByStage,
	setState,
) => {
	const blockedByActivityTemplateIds = values.blockedByActivityTemplateIds
		? values.blockedByActivityTemplateIds.map(el => el.value)
		: activity.blockedByActivityTemplateIds
		? activity.blockedByActivityTemplateIds.map(el => el.value)
		: [];
	const blockerActivityTemplates = values.blockerActivityTemplates
		? values.blockerActivityTemplates
		: activity.blockerActivityTemplates
		? activity.blockerActivityTemplates
		: [];

	const newValues = {
		id: activity.id,
		stage: stage.stage,
		orderNumber: stage.activities.length + 1,
		title: values.name ? values.name : activity.title,
		blockedByActivityTemplateIds,
		blockerActivityTemplates,
	};

	await editActivity(signal, {
		...newValues,
	});

	await setTemplateState();

	await onDataChange();

	await setActivitiesByStage();

	await updateActivitiesByStage(stage);

	notify(t(`Activity ${values.name} has been moved to ${capitalizeFirstLetters(stage?.label)}`), {
		type: toast.TYPE.SUCCESS,
	});
	setState(prevState => ({ ...prevState, isMovingAcitivity: false }));
	setStageState(prevState => ({ ...prevState, isLoadingStage: false }));
};

export default moveActivity;
