import { useEffect, useCallback } from 'react';

let prevClosest;
const useHoverMarker = (ref, markerName) => {
	const handleMouseOver = useCallback(
		e => {
			const target = e.target.closest(`[data-${markerName}]`);
			if (prevClosest !== target && target) {
				prevClosest?.classList.remove('hasMarker');
				target.classList.add('hasMarker');
				prevClosest = target;
			} else if (!target) {
				prevClosest?.classList.remove('hasMarker');
				prevClosest = null;
			}
		},
		[markerName],
	);

	const handleMouseLeave = useCallback(() => {
		prevClosest?.classList.remove('hasMarker');
		prevClosest = null;
	}, []);

	useEffect(() => {
		const element = ref.current;
		if (element) {
			element.addEventListener('mouseover', handleMouseOver);
			element.addEventListener('mouseleave', handleMouseLeave);
		}

		return () => {
			element.removeEventListener('mouseover', handleMouseOver);
			element.removeEventListener('mouseleave', handleMouseLeave);
		};
	}, [ref, handleMouseOver, handleMouseLeave]);
};

export default useHoverMarker;
