import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useUtilityRateFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				name: yup.object().required(t('Name is required')),
				rate: yup
					.number()
					.typeError(t('Rate must be a number'))
					.min(0, t('Rate must be greater than or equal to 0'))
					.nullable()
					.test(
						'decimal-places',
						'Must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^-?\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.required(t('Rate is required')),
			}),
		[t],
	);
};

export default useUtilityRateFormValidationSchema;
