import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';
import useAbortController from 'Common/hooks/useAbortController';
import showToastError from 'Common/utils/showToastError';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import loadInvoiceTemplateOptions from 'Invoice/utils/loadInvoiceTemplateOptions';
import createProjectSettings from 'Projects/api/createProjectSettings';
import editProjectSettings from 'Projects/api/editProjectSettings';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useSettingsTileFormInitialValues from './hooks/useSettingsTileFormInitialValues';
import loadSaaSBankAccountOptionsFromEntity from 'Invoice/utils/loadSaaSBankAccountOptionsFromEntity';
import loadTariffTypeOptions from 'Invoice/utils/loadTariffTypeOptions';
import { contractTypes } from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/ClientContract/constants/contractTypes';

const SettingsTile = ({ project, onDataChange, onDirtyForm, onExitEditMode, onEnterEditMode }) => {
	const { t } = useTranslation();
	const abortController = useAbortController();
	const [isInEditMode, setIsInEditMode] = useState(false);
	let disableNonPPAFloating = false;
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const isPVProject = project?.clientContract?.contractType?.name === contractTypes.PPA;
	const loadInvoiceTemplateOptionsBinded = useMemo(
		() => loadInvoiceTemplateOptions.bind(null, [project?.country?.id]),
		[project?.country?.id],
	);
	/* eslint-disable react/prop-types */
	if (project?.contractType?.name !== 'PPA') {
		disableNonPPAFloating = true;
	} else if (project?.clientContract?.ppaTariffType?.ppaTariffType === 'FIXED') {
		disableNonPPAFloating = true;
	}
	const loadBankAccountOptionsBinded = useMemo(
		() =>
			project?.contractPartyObjectByType?.SaaS?.id
				? loadSaaSBankAccountOptionsFromEntity.bind(null, project.contractPartyObjectByType.SaaS.id)
				: () => [],
		[project?.contractPartyObjectByType?.SaaS?.id],
	);

	const loadTariffTypeOptionsBinded = useMemo(
		() => loadTariffTypeOptions.bind(null, [project?.country?.id]),
		[project?.country?.id],
	);
	const initialValues = useSettingsTileFormInitialValues(project.projectSettings, project.country);

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleChange,
		setFieldValue,
		setFieldTouched,
		handleBlur,
		resetForm,
	} = useFormik({
		initialValues,
		// validationSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			setIsSavingChanges(true);
			try {
				setIsSavingChanges(true);
				if (project?.projectSettings?.id) {
					await editProjectSettings(abortController.signal, {
						id: project.projectSettings.id,
						projectId: project.id,
						saasCurrency: values.saasCurrency.value,
						paymentCurrency: values.paymentCurrency.value,
						bankAccountId: values.bankAccount?.value,
						templateId: values.template?.value,
						takeOrPay: values.takeOrPay,
						tariffTypeId: values.tariffType ? values.tariffType.value : undefined,
					});
				} else {
					await createProjectSettings(abortController.signal, {
						projectId: project.id,
						values,
						saasCurrency: values.saasCurrency.value,
						paymentCurrency: values.paymentCurrency.value,
						bankAccountId: values.bankAccount?.value,
						templateId: values.template?.value,
						takeOrPay: values.takeOrPay,
						tariffTypeId: values.tariffType ? values.tariffType.value : undefined,
					});
				}
				onDirtyForm(false);
				onDataChange();
				handleCancel();
				setIsSavingChanges(false);
				setIsInEditMode(false);
				resetForm({ values });
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});
	const handleEditButtonClick = () => {
		setIsInEditMode(true);
		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}
		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		onDirtyForm(dirty);
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Settings')}
			isInEditMode={isInEditMode}
			isLoading={isSavingChanges}
			onCancel={handleCancel}
			onSubmit={handleSubmit}
			onClick={handleTileClick}
		>
			{/* <Grid container> */}
			{isPVProject && (
				<FormField>
					<FormikCheckbox
						id="takeOrPay"
						name="takeOrPay"
						label={t('Take or Pay')}
						checked={values.takeOrPay}
						error={errors.takeOrPay}
						touched={touched.takeOrPay}
						setFieldTouched={setFieldTouched}
						setFieldValue={setFieldValue}
						onBlur={handleBlur}
						onChange={handleChange}
						state="editable"
						isTile
						isInEditMode={isInEditMode}
					/>
				</FormField>
			)}

			<FormField>
				<FormikSelect
					isAsync
					id="template"
					name="template"
					label={t('Template')}
					value={values.template}
					error={errors.template}
					touched={touched.template}
					setFieldTouched={setFieldTouched}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={loadInvoiceTemplateOptionsBinded}
					isTile
					isClearable
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					isAsync
					id="saasCurrency"
					name="saasCurrency"
					label={t('SaaS Currency')}
					value={values.saasCurrency}
					error={errors.saasCurrency}
					touched={touched.saasCurrency}
					setFieldTouched={setFieldTouched}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={loadCurrenciesOptions}
					isTile
					isRequired
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					isAsync
					id="paymentCurrency"
					name="paymentCurrency"
					label={t('Payment Currency')}
					value={values.paymentCurrency}
					error={errors.paymentCurrency}
					touched={touched.paymentCurrency}
					setFieldTouched={setFieldTouched}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={loadCurrenciesOptions}
					isTile
					isRequired
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					isAsync
					id="bankAccount"
					name="bankAccount"
					label={t('Bank Account')}
					value={values.bankAccount}
					error={errors.bankAccount}
					touched={touched.bankAccount}
					setFieldTouched={setFieldTouched}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={loadBankAccountOptionsBinded}
					isTile
					isClearable
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					isAsync
					id="tariffType"
					name="tariffType"
					label={t('Tariff Type')}
					value={values.tariffType}
					error={errors.tariffType}
					touched={touched.tariffType}
					setFieldTouched={setFieldTouched}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					isTile
					loadOptions={loadTariffTypeOptionsBinded}
					isClearable
					isInEditMode={isInEditMode}
					isDisabled={disableNonPPAFloating}
				/>
			</FormField>
			{/* </Grid> */}
		</EditableTile>
	);
};

SettingsTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
};

SettingsTile.propTypes = {
	project: PropTypes.shape({
		clientContract: PropTypes.shape({
			contractType: PropTypes.shape({ name: PropTypes.string }),
		}),
		id: PropTypes.number,
		contractPartyObjectByType: PropTypes.shape({ SaaS: PropTypes.shape({ id: PropTypes.number }) }).isRequired,
		country: PropTypes.shape({ id: PropTypes.number }),
		projectSettings: PropTypes.shape({
			id: PropTypes.number,
			invoiceTemplate: PropTypes.shape({ fileName: PropTypes.string, id: PropTypes.number }),
			bankAccount: PropTypes.shape({ name: PropTypes.string, id: PropTypes.number }),
			saasCurrency: PropTypes.string,
			paymentCurrency: PropTypes.string,
			takeOrPay: PropTypes.bool,
			tariffTypeId: PropTypes.number,
		}),
	}).isRequired,
	onDataChange: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
};

export default SettingsTile;
