import api from 'Application/api/api';
import adaptProjectResponseData from 'Projects/api/adapters/adaptProjectResponseData';

const assignEpcPartnerToProject = async (signal, epcPartner, projectId) => {
	const response = await api.post(
		`/projects/assign-epc-partner/${projectId}?partnerName=${epcPartner}`,
		{},
		{
			signal,
		},
	);

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptProjectResponseData(response.data),
	};
};

export default assignEpcPartnerToProject;
