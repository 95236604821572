import api from 'Application/api/api';
import adaptProjectResponseData from 'Projects/api/adapters/adaptProjectResponseData';

const assignClientToProject = async (signal, clientId, projectId) => {
	const response = await api.post(
		`/projects/assign-client-by-id/${projectId}?clientId=${clientId}`,
		{},
		{
			signal,
		},
	);

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptProjectResponseData(response.data),
	};
};

export default assignClientToProject;
