const projectTypes = {
	PV: 'PV',
	EE_AC: 'EE-AC',
	EE_SHW: 'EE-SHW',
	EE_LED: 'EE-LED',
};

export default projectTypes;

export const fullNameProjectTypes = {
	PV: 'Solar photovoltaic (PV)',
	EE_AC: 'Energy efficiency - AC',
	EE_SHW: 'Energy efficiency - solar hot water',
	EE_LED: 'Energy efficiency - LED',
};
