import getPartnerByCountryIds from 'Projects/api/getPartnerByCountryIds';

const loadPartnerOptions = async (signal, query) => {
	const response = await getPartnerByCountryIds(signal, query);
	const partners = response.data.content;
	return partners
		.filter(partner => partner.name && partner.name !== '-')
		.map(partner => ({
			value: partner.id,
			label: partner.name,
		}));
};

export default loadPartnerOptions;
