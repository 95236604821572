import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useStepsFieldsFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				title: yup.string().required(t('Required')),
				weight: yup.object().shape({
					value: yup.number(),
					label: yup.string(),
				}),
				completedValue: yup.object().required(t('Required')),
				expectedDuration: yup
					.number()
					.integer()
					.typeError(t('Expected duration must be a number'))
					.positive(t('Must be positive')),
				responsibleTeam: yup.object().nullable().required(t('Required')),
			}),
		[t],
	);
};

export default useStepsFieldsFormValidationSchema;
