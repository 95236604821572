import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useEpcInquiryCreateFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				country: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.nullable()
					.required(t('Country is Required')),
				projectType: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.required(t('Project type is Required'))
					.nullable(),
				industry: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.nullable()
					.required(t('Industry is Required')),

				systemSizeKwp: yup.mixed().when('projectType', {
					is: val => val?.label === 'Solar photovoltaic (PV)',
					then: yup
						.number()
						.nullable()
						.positive(t('System size must be positive'))
						.typeError(t('System size must be a number'))
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						)
						.test(
							'decimal-places',
							t('System size must have no more than 2 decimal places'),
							value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
						)
						.required(t('System size is Required')),

					otherwise: null,
				}),

				kwhToKwpRel: yup.mixed().when('projectType', {
					is: val => val?.label === 'Solar photovoltaic (PV)',
					then: yup
						.number()
						.required(t('Yield is Required'))
						.positive(t('Yield must be positive'))
						.test(
							'decimal-places',
							t('Yield must have no more than 2 decimal places'),
							value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
						)
						.test(
							'yield-range',
							t('Yield must be between 500 and 3000'),
							value => value === undefined || (value <= 3000 && value >= 500),
						)
						.typeError(t('Yield must be a number'))
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						)
						.nullable(),

					otherwise: null,
				}),
				epcVolumeExclVat: yup
					.number()
					.typeError(t('EPC volume must be a number'))
					.positive(t('EPC volume must be positive'))
					.test(
						'decimal-places',
						'EPC volume must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable()
					.required(t('EPC volume is Required')),
				epcVolumeExclVatKwp: yup.mixed().when('projectType', {
					is: val => val?.label === 'Solar photovoltaic (PV)',
					then: yup
						.number()
						.typeError(t('Must be a number'))
						.positive(t('Must be positive, check System size.'))
						.test(
							'decimal-places',
							'Must have no more than 2 decimal places',
							value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
						)
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						)
						.nullable()
						.required(t('Required')),

					otherwise: null,
				}),
				tariffCurrency: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.nullable()
					.required(t('Required')),
				epcOfferCurrency: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.nullable()
					.required(t('Required')),
				annualOmPrice: yup
					.number()
					.typeError(t('Must be a number'))
					.positive(t('Must be positive, check System size.'))
					.nullable()
					.required(t('Required'))
					.test('decimal-places', 'Must have no more than 2 decimal places', value =>
						String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					),
				annualOmPriceKwp: yup.mixed().when('projectType', {
					is: val => val?.label === 'Solar photovoltaic (PV)',
					then: yup
						.number()
						.typeError(t('Must be a number'))
						.positive(t('Must be positive, check System size.'))
						.nullable()
						.required(t('Required'))
						.test('decimal-places', 'Must have no more than 2 decimal places', value =>
							String(value).match(/^\d*(\.\d{1,2})?$/),
						)
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						),

					otherwise: null,
				}),
				gridEnergyTariffPerKwh: yup
					.number()
					.nullable()
					.typeError(t('Grid energy tariff must be a number'))
					.positive(t('Grid energy tariff must be positive'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'decimal-places',
						'Grid energy tariff must have no more than 3 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,3})?$/),
					),
				options: yup.array().of(
					yup
						.object()
						.shape({
							contractType: yup.object().nullable(),

							duration: yup
								.number()
								.typeError(t('Must be a number'))
								.integer(t('Must be an integer'))
								.positive(t('Must be positive'))
								.transform((val, originalValue) =>
									typeof originalValue === 'string' && originalValue === '' ? null : val,
								)
								.max(30, 'Must be 30 max')
								.nullable(),
						})
						.test(
							('mandatory validations',
							'',
							function (item) {
								const isFirstItem = this.path === 'options[0]';
								if (isFirstItem && !item.contractType) {
									return this.createError({
										path: 'options[0].contractType',
										message: t('Required'),
									});
								}
								if (isFirstItem && !item.duration) {
									return this.createError({
										path: 'options[0].duration',
										message: t('Required'),
									});
								}
								return true;
							}),
						),
				),
				solarUtilisationRate: yup.mixed().when('projectType', {
					is: value => value?.label === 'Solar photovoltaic (PV)',
					then: yup
						.number()
						.typeError(t('Solar utilization rate must be a number'))
						.min(0, t('Solar utilization rate must be greater than or equals to 0'))
						.max(100, t('Solar utilization rate must be less than or equals to 100'))
						.test(
							'decimal-places',
							t('Solar utilization rate must have no more than 2 decimal places'),
							value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
						)
						.typeError(t('Solar utilization rate must be a number'))
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						)
						.nullable()
						.required(t('Solar utilization rate is Required')),
					otherwise: null,
				}),
				description: yup.string().required('Required'),
			}),
		[t],
	);
};

export default useEpcInquiryCreateFormValidationSchema;
