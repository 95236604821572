import api from 'Application/api/api';

const getBankAccountById = async (signal, bankAccountId, details = true) => {
	const response = await api.get(`/bank-accounts/by-id/${bankAccountId}`, {
		params: {
			details,
		},
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return response;
};

export default getBankAccountById;
