import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';

const H4 = styled.h4`
	margin-top: 0;
	margin-bottom: 20px;
	color: ${colors.text.primary};
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	text-transform: none;
`;

export default H4;
