import defaultTimezone from 'Common/constants/defaultTimezone';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import de from 'date-fns/locale/de';

const formatDateForRequestData = (date, dateOnly, dateFormat, timeZone = defaultTimezone) => {
	const zonedDate = zonedTimeToUtc(new Date(date), timeZone);

	if (dateFormat && !dateOnly) {
		return format(zonedDate, dateFormat, {
			locale: de,
			timezone: timeZone,
		});
	} else {
		return format(new Date(date), 'yyyy-MM-dd', {
			locale: de,
			timezone: timeZone,
		});
	}
};

export default formatDateForRequestData;
