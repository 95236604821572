const queryParamsDecoder = (str, defaultDecoder) => {
	let keywords = {
		true: true,
		false: false,
		null: null,
		undefined: undefined,
	};

	if (str in keywords) {
		return keywords[str];
	}

	return defaultDecoder(str);
};

export default queryParamsDecoder;
