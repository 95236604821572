import { forwardRef } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import {
	ErrorOutlineRounded,
	Assignment,
	Domain,
	FlashOn,
	Flare,
	Remove,
	Add,
	ImportExport,
	Person,
	Settings,
	Timeline,
	AddRounded,
	ReportProblem,
	CloseRounded,
	Sync,
	ChevronLeft,
	ChevronRight,
	Place,
	HelpOutline,
	ArrowDropDown,
	ArrowDropUp,
	CheckRounded,
	NotInterestedRounded,
	SearchRounded,
	PhotoCameraOutlined,
	FilterListRounded,
	KeyboardArrowUp,
	Close,
	KeyboardArrowDown,
	FiberManualRecord,
	ArrowForwardIos,
	ArrowBackIos,
	ArrowLeft,
	MoreVertRounded,
	Check,
	Clear,
	InfoOutlined,
	WarningRounded,
	AddCircle,
	Notifications,
	RemoveCircle,
	Redo,
	DoubleArrow,
	Send,
	Delete,
	EditOutlined,
	KeyboardArrowLeft,
	KeyboardArrowRight,
	CloudDownloadOutlined,
	PauseCircleFilledRounded,
	CloudUploadRounded,
	ExpandLess,
	ExpandMore,
	VerifiedUser,
	PictureAsPdf,
	InsertPhoto,
	InsertDriveFile,
	CloudDownload,
	RemoveRedEyeOutlined,
	ExitToAppRounded,
	SendRounded,
	CheckCircleOutline,
	Forum,
	PeopleOutlineOutlined,
	KeyboardTab,
	Menu,
	InsertDriveFileOutlined,
	AccountTreeOutlined,
	ContentCopy,
	Download,
	Euro,
	PermMedia,
} from '@mui/icons-material';

import { ReactComponent as PV } from 'Common/components/icons/custom/PV.svg';
import { ReactComponent as EEAC } from 'Common/components/icons/custom/EEAC.svg';
import { ReactComponent as EE_LED } from 'Common/components/icons/custom/EEIcon.svg';
import { ReactComponent as SHW } from 'Common/components/icons/custom/SHW.svg';
import { ReactComponent as ES } from 'Common/components/icons/custom/EnergyStorage.svg';
import { ReactComponent as EPC } from 'Common/components/icons/custom/EPCPartner.svg';
import { ReactComponent as Dashboard } from 'Common/components/icons/custom/Dashboard.svg';
import { ReactComponent as Investors } from 'Common/components/icons/custom/Investors.svg';
import { ReactComponent as IntegrationInstructions } from 'Common/components/icons/custom/integration_instructions.svg';
import { ReactComponent as Location } from 'Common/components/icons/custom/Location.svg';
import { ReactComponent as Contract } from 'Common/components/icons/custom/Contract.svg';
import { ReactComponent as Bronze } from 'Common/components/icons/custom/Bronze.svg';
import { ReactComponent as Silver } from 'Common/components/icons/custom/Silver.svg';
import { ReactComponent as Gold } from 'Common/components/icons/custom/Gold.svg';
import { ReactComponent as Platinum } from 'Common/components/icons/custom/Platinum.svg';
import { ReactComponent as Diamond } from 'Common/components/icons/custom/Diamond.svg';
import { ReactComponent as PublishedWithChanges } from 'Common/components/icons/custom/published_with_changes.svg';
import { ReactComponent as NoUser } from 'Common/components/icons/custom/NoUser.svg';
import { ReactComponent as LowPriority } from 'Common/components/icons/custom/LowPriority.svg';
import { ReactComponent as MediumPriority } from 'Common/components/icons/custom/MediumPriority.svg';
import { ReactComponent as HighPriority } from 'Common/components/icons/custom/HighPriority.svg';
import { ReactComponent as Impact } from 'Common/components/icons/custom/Impact.svg';
import { ReactComponent as Signed } from 'Common/components/icons/custom/Signed.svg';
import { ReactComponent as Leaf } from 'Common/components/icons/custom/Leaf.svg';
import { ReactComponent as Employees } from 'Common/components/icons/custom/Employees.svg';
import { ReactComponent as Invested } from 'Common/components/icons/custom/Invested.svg';
import { ReactComponent as InOperation } from 'Common/components/icons/custom/InOperation.svg';
import { ReactComponent as Warehouse } from 'Common/components/icons/custom/Warehouse.svg';
import { ReactComponent as Building } from 'Common/components/icons/custom/Building.svg';
import { ReactComponent as Female } from 'Common/components/icons/custom/Female.svg';
import { ReactComponent as ProjectImpact } from 'Common/components/icons/custom/ProjectImpact.svg';
import { ReactComponent as Handshake } from 'Common/components/icons/custom/Handshake.svg';
import { ReactComponent as Eco } from 'Common/components/icons/custom/Eco.svg';
import { ReactComponent as EcoOutlined } from 'Common/components/icons/custom/EcoOutlined.svg';
import { ReactComponent as Invoice } from 'Common/components/icons/custom/Invoice.svg';
import { ReactComponent as SignContract } from 'Common/components/icons/custom/SignContract.svg';
import { FILE_TYPES } from 'Common/constants/fileTypes';

const InvalidIcon = () => <>Invalid icon</>;

const icons = {
	default: InvalidIcon,
	// MUI Icons
	addRounded: AddRounded,
	domain: Domain,
	timeline: Timeline,
	flashOn: FlashOn,
	flare: Flare,
	importExport: ImportExport,
	person: Person,
	settings: Settings,
	ecoOutlined: EcoOutlined,
	errorOutline: ErrorOutlineRounded,
	reportProblem: ReportProblem,
	remove: Remove,
	add: Add,
	closeRounded: CloseRounded,
	sync: Sync,
	chevronleft: ChevronLeft,
	chevronright: ChevronRight,
	place: Place,
	helpOutline: HelpOutline,
	arrowDropDown: ArrowDropDown,
	arrowDropUp: ArrowDropUp,
	checkRounded: CheckRounded,
	notInterestedRounded: NotInterestedRounded,
	searchRounded: SearchRounded,
	photoCameraOutlined: PhotoCameraOutlined,
	filterListRounded: FilterListRounded,
	keyboardArrowUp: KeyboardArrowUp,
	keyboardArrowDown: KeyboardArrowDown,
	close: Close,
	fiberManualRecord: FiberManualRecord,
	arrowForwardIos: ArrowForwardIos,
	arrowBackIos: ArrowBackIos,
	arrowLeft: ArrowLeft,
	moreVertRounded: MoreVertRounded,
	check: Check,
	clear: Clear,
	infoOutlined: InfoOutlined,
	warningRounded: WarningRounded,
	warning: WarningRounded,
	addCircle: AddCircle,
	eco: Eco,
	removeCircle: RemoveCircle,
	redo: Redo,
	doubleArrow: DoubleArrow,
	send: Send,
	delete: Delete,
	editOutlined: EditOutlined,
	keyboardArrowLeft: KeyboardArrowLeft,
	keyboardArrowRight: KeyboardArrowRight,
	cloudDownloadOutlined: CloudDownloadOutlined,
	pauseCircleFilledRounded: PauseCircleFilledRounded,
	cloudUploadRounded: CloudUploadRounded,
	expandLess: ExpandLess,
	expandMore: ExpandMore,
	verifiedUser: VerifiedUser,
	pictureAsPdf: PictureAsPdf,
	insertPhoto: InsertPhoto,
	insertDriveFile: InsertDriveFile,
	cloudDownload: CloudDownload,
	removeRedEyeOutlined: RemoveRedEyeOutlined,
	exitToAppRounded: ExitToAppRounded,
	sendRounded: SendRounded,
	checkCircleOutline: CheckCircleOutline,
	peopleOutlineOutlined: PeopleOutlineOutlined,
	forum: Forum,
	notifications: Notifications,
	keyboardTab: KeyboardTab,
	sidepanelClose: KeyboardTab,
	menu: Menu,
	assignment: Assignment,
	warehouse: Warehouse,
	invoicingIcon: InsertDriveFileOutlined,
	entities: AccountTreeOutlined,
	contentCopy: ContentCopy,
	download: Download,
	euro: Euro,

	[FILE_TYPES.IMAGE]: PermMedia,
	[FILE_TYPES.DOCX]: PermMedia,
	[FILE_TYPES.PDF]: PermMedia,
	[FILE_TYPES.XLSX]: PermMedia,
	[FILE_TYPES.CSV]: PermMedia,

	// Cusotm
	ee: EEAC,
	pv: PV,
	es: ES,
	epc: EPC,
	dashboard: Dashboard,
	investors: Investors,
	integrationInstructions: IntegrationInstructions,
	location: Location,
	contract: Contract,
	Bronze: Bronze,
	Silver: Silver,
	Gold: Gold,
	Impact: Impact,
	Platinum: Platinum,
	Diamond: Diamond,
	publishedWithChanges: PublishedWithChanges,
	noUser: NoUser,
	'ee-ac': EEAC,
	'ee-led': EE_LED,
	'ee-shw': SHW,
	lowPriority: LowPriority,
	mediumPriority: MediumPriority,
	highPriority: HighPriority,
	signed: Signed,
	leaf: Leaf,
	employees: Employees,
	invested: Invested,
	inOperation: InOperation,
	building: Building,
	female: Female,
	projectImpact: ProjectImpact,
	handshake: Handshake,
	invoice: Invoice,
	signContract: SignContract,
};

export const iconOptions = Object.keys(icons).reduce(
	(prev, key) => ({
		...prev,
		[key]: key,
	}),
	{},
);

const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ $color }) => ($color ? $color : 'inherit')};

	svg {
		fill: ${({ $color }) => ($color ? $color : 'currentColor')};
		width: ${({ $size }) => $size}px;
		height: ${({ $size }) => $size}px;
		padding: ${({ $padding }) => $padding}px;
	}
`;

const sizes = {
	tiny: 8,
	smallest: 12,
	smaller: 16,
	small: 18,
	base: 20,
	large: 22,
	largest: 24,
	big: 40,
	huge: 76,
};

const Icon = forwardRef(({ icon = 'default', size = 'base', padding, color, style, ...props }, _ref) => {
	const IconComponent = icons[icon];

	if (!IconComponent) {
		return InvalidIcon;
	}

	return (
		<IconWrapper
			$size={sizes[size]}
			$padding={padding}
			$color={color}
			style={style}
			ref={_ref}
			data-icon-wrapper={icon}
		>
			<IconComponent {...props} />
		</IconWrapper>
	);
});

Icon.displayName = 'Icon';

Icon.defaultProps = {
	icon: 'default',
	size: 'base',
	color: null,
	style: null,
	padding: null,
};

Icon.propTypes = {
	icon: PropTypes.string,
	size: PropTypes.string,
	color: PropTypes.string,
	style: PropTypes.shape({}),
	padding: PropTypes.number,
};

export default Icon;
