import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import sizes from 'Application/theme/sizes';
import colors from 'Application/theme/colors';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import loadClientOptions from 'Client/utils/loadClientOptions';

import Link from 'Common/components/Link';
import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import FormikSelect from 'Common/components/form/FormikSelect';
import TileDynamicSection from 'Common/components/Tile/TileDynamicSection';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

import assignClientToProject from 'Projects/api/assignClientToProject';
import unassignClientFromProject from 'Projects/api/unassignClientFromProject';
import useClientFormValidationSchema from 'Projects/components/ProjectDetails/Tabs/ClientTab/Tiles/ClientTile/hooks/useClientFormValidationSchema';
import useClientFormInitialValues from 'Projects/components/ProjectDetails/Tabs/ClientTab/Tiles/ClientTile/hooks/useClientFormInitialValues';
import showToastError from 'Common/utils/showToastError';
import Icon from 'Common/components/icons/Icon';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';
import ClientOverlay from 'Client/components/ClientForm/ClientOverlay';
import crudModes from 'Common/constants/crudModes';

const Title = styled.div`
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 10px;
	color: ${colors.text.greyDark};
`;

const ClientsSection = styled.div`
	margin-bottom: ${sizes.spacing(1)};
`;

const ClientDisplayValueContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${sizes.spacing(1)};
`;

const ClientTile = ({
	project,
	onEnterEditMode,
	onExitEditMode,
	onDirtyForm,
	onTileSave,
	highlightLabels,
}) => {
	const { t } = useTranslation();

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [showCreateClientOverlay, setShowCreateClientOverlay] = useState(false);

	const abortController = useAbortController();

	const isAuthorizedToEdit = isAuthorized([roles.ADMIN, roles.SALES, roles.FINANCE, roles.MANAGEMENT]);
	const isAuthorizedToEditExcludedIndustry = isAuthorized([
		roles.ADMIN,
		roles.HEAD_OF_ASSET_MANAGEMENT,
		roles.HEAD_OF_MARKETING,
		roles.HEAD_OF_FUNDRAISING,
		roles.HEAD_OF_TECH,
		roles.HEAD_OF_FINANCE,
		roles.HEAD_OF_SALES,
		roles.HEAD_OF_ESG,
	]);
	const initialValues = useClientFormInitialValues(project);
	const validationSchema = useClientFormValidationSchema();

	const hasMissingInvoicingWarning = !project?.anyContactInvoicing;

	const { errors, touched, values, handleSubmit, dirty, handleChange, handleBlur, setFieldValue, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: async values => {
				setIsSavingChanges(true);
				try {
					if (values.client) {
						await assignClientToProject(abortController.signal, values.client.value, project.id);
					} else {
						await unassignClientFromProject(abortController.signal, project.id);
					}
					if (onDirtyForm) {
						onDirtyForm(false);
					}

					await onTileSave();
					handleCancel();
					setIsSavingChanges(false);
					resetForm({ values });
				} catch (e) {
					showToastError(e);
					if (!axios.isCancel(e)) {
						setIsSavingChanges(false);
					}
				}
			},
		});

	const client = project.client;
	const isUnknownClient = values.unknownClient;
	const isIndustryExcluded = values.industry.excluded;

	const isClientFieldDisabled = isUnknownClient || (isIndustryExcluded && !isAuthorizedToEditExcludedIndustry);

	const clientValue = client ? (
		<ClientDisplayValueContainer>
			<Link color={colors.primary.main} to={`/clients/details/${client.id}`}>
				{client.name}
			</Link>
		</ClientDisplayValueContainer>
	) : (
		t('Unknown client')
	);

	const handleCreateClientFormSubmit = client => {
		setFieldValue('client', {
			value: client.id,
			label: client.name,
		});

		requestAnimationFrame(() => {
			handleSubmit();
		});
	};

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	// Clear the "Client" field when the checkbox for the unknown client is checked
	useEffect(() => {
		if (isUnknownClient) {
			setFieldValue('client', null);
		}
	}, [isUnknownClient, setFieldValue]);

	const tileRef = useRef();

	return (
		<EditableTile
			title={t('Client')}
			tooltip={
				hasMissingInvoicingWarning ? (
					<HoverTooltip placement="right" title="Missing contact for invoicing">
						<Icon icon="reportProblem" color={colors.warning.main} />
					</HoverTooltip>
				) : null
			}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
			ref={tileRef}
		>
			<ClientsSection>
				<TileDynamicSection
					uniqueId="Client"
					isInEditMode={isInEditMode}
					readonlyView={
						<>
							<Title>{t('Client')}</Title>
							<KeyValueVisualization
								title={t('Client')}
								value={clientValue}
								isDisabled={isUnknownClient}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_CLIENT.CLIENT,
								)}
							/>
						</>
					}
					editView={
						<>
							<FormField>
								<FormikCheckbox
									id="unknownClient"
									name="unknownClient"
									label={t('Unknown Client')}
									checked={values.unknownClient}
									error={errors.unknownClient}
									touched={touched.unknownClient}
									onChange={handleChange}
									onBlur={handleBlur}
									state="editable"
									tooltip={{
										placement: 'top',
										tooltip: 'Tick if the Client is not yet known, e.g. a referral from an EPC Partner.',
									}}
									isHighlighted={highlightLabels.includes(
										PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_CLIENT.UNKNOWN_CLIENT,
									)}
								/>
							</FormField>
							<FormField>
								<FormikSelect
									isAsync
									id="client"
									name="client"
									label={t('Client')}
									value={values.client}
									error={errors.client}
									touched={touched.client}
									setFieldValue={setFieldValue}
									onBlur={handleBlur}
									loadOptions={loadClientOptions}
									isDisabled={isClientFieldDisabled}
									createLinkTitle="Create Client"
									onCreateClick={() => setShowCreateClientOverlay(true)}
									isTile
									isRequired={!isUnknownClient}
									isInEditMode={isInEditMode}
									isHighlighted={highlightLabels.includes(
										PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_CLIENT.CLIENT,
									)}
								/>
							</FormField>

							{showCreateClientOverlay && (
								<ClientOverlay
									isOpen={showCreateClientOverlay}
									mode={crudModes.CREATE}
									onClose={() => setShowCreateClientOverlay(false)}
									onFormSubmit={handleCreateClientFormSubmit}
								/>
							)}
						</>
					}
				/>
			</ClientsSection>
		</EditableTile>
	);
};

ClientTile.defaultProps = {
	project: undefined,
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	highlightLabels: [],
	onTileSave: () => {},
};

ClientTile.propTypes = {
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		anyContactInvoicing: PropTypes.bool,
		client: PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			name: PropTypes.string,
			industry: PropTypes.shape({
				name: PropTypes.string,
				restricted: PropTypes.bool,
			}),
			country: PropTypes.shape({
				name: PropTypes.string,
			}),
			address: PropTypes.shape({
				apartmentBuilding: PropTypes.string,
				line1: PropTypes.string,
				line2: PropTypes.string,
				cityRegion: PropTypes.string,
				postCodeZip: PropTypes.string,
			}),
			missingInvoicingWarning: PropTypes.bool,
		}),
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
	onTileSave: PropTypes.func,
};

export default ClientTile;
