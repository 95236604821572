import { useMemo } from 'react';

const fallbackValues = {
	shareCapital: '',
	numberOfShares: '',
	currency: { value: 'EUR' },
};

const useCapitalTileFormInitialValues = data => {
	const currency = useMemo(
		() => (data?.shareCapital ? { value: data?.shareCapital?.currency } : fallbackValues.currency),
		[data?.shareCapital],
	);

	return useMemo(
		() => ({
			...fallbackValues,
			shareCapital: data?.shareCapital?.value,
			currency: currency,
			numberOfShares: data?.numberOfShares || '',
		}),
		[currency, data?.numberOfShares, data?.shareCapital?.value],
	);
};

export default useCapitalTileFormInitialValues;
