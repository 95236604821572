import api from 'Application/api/api';

const changePassword = (signal, email, oldPassword, password, passwordConfirm) =>
	api.post(
		'/users/change-password',
		{
			email: email,
			oldPassword: oldPassword,
			password: password,
			passwordConfirm: passwordConfirm,
		},
		{
			signal,
		},
	);

export default changePassword;
