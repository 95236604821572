import { useMemo } from 'react';
import { pick } from 'lodash';

const fallbackValues = {
	password: '',
};

const useReLoginPopupFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['password']),
		}),
		[data],
	);

export default useReLoginPopupFormInitialValues;
