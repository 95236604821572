import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';
import Big from 'Common/utils/customBig';

const useCountryFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				country: yup.object().shape({ label: yup.string(), value: yup.number() }).required('Required').nullable(),
				regionalHub: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.required('Required')
					.nullable(),
				primaryManager: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.required('Required')
					.nullable(),
				//handing as string due to js 16 decimal limit
				gridEmissionFactor: yup
					.string()
					.nullable()
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'number',
						t('Must be a number'),
						value => value === undefined || value === null || /^-?\d*\.?\d*$/.test(value),
					)
					.test(
						'positive',
						t('Must be positive'),
						value => value === undefined || value === null || Number(value) >= 0,
					)
					.test(
						'decimal',
						t('Grid emission factor can be from 0 to 1'),
						value => value === undefined || value === null || Number(value) <= 1,
					)
					.test(
						'decimal-places',
						t('Must have no more than 20 decimal places'),
						value =>
							value === undefined ||
							value === null ||
							Big(value)
								.toString()
								.match(/^\d*(\.\d{1,20})?$/),
					),
				gridTariffEscalationRatePerYear: yup
					.string()
					.nullable()
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'number',
						t('Must be a number'),
						value => value === undefined || value === null || /^-?\d*\.?\d*$/.test(value),
					)
					.test(
						'positive',
						t('Must be positive'),
						value => value === undefined || value === null || Number(value) >= 0,
					)
					.test(
						'decimal',
						t('Grid tariff escalation rate per year can be from 0 to 100'),
						value => value === undefined || value === null || Number(value) <= 100,
					)
					.test(
						'decimal-places',
						t('Must have no more than 2 decimal places'),
						value =>
							value === undefined ||
							value === null ||
							(/^-?\d*\.?\d*$/.test(value) &&
								Big(value)
									.toString()
									.match(/^\d*(\.\d{1,2})?$/)),
					),
				vat: yup
					.string()
					.nullable()
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'number',
						t('Must be a number'),
						value => value === undefined || value === null || /^-?\d*\.?\d*$/.test(value),
					)
					.test(
						'positive',
						t('Must be positive'),
						value => value === undefined || value === null || Number(value) >= 0,
					)
					.test(
						'decimal',
						t('VAt can be from 0 to 100'),
						value => value === undefined || value === null || Number(value) <= 100,
					)
					.test(
						'decimal-places',
						t('Must have no more than 2 decimal places'),
						value =>
							value === undefined ||
							value === null ||
							(/^-?\d*\.?\d*$/.test(value) &&
								Big(value)
									.toString()
									.match(/^\d*(\.\d{1,2})?$/)),
					),
				wht: yup
					.string()
					.nullable()
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'number',
						t('Must be a number'),
						value => value === undefined || value === null || /^-?\d*\.?\d*$/.test(value),
					)
					.test(
						'positive',
						t('Must be positive'),
						value => value === undefined || value === null || Number(value) >= 0,
					)
					.test(
						'decimal',
						t('WHT can be from 0 to 100'),
						value => value === undefined || value === null || Number(value) <= 100,
					)
					.test(
						'decimal-places',
						t('Must have no more than 2 decimal places'),
						value =>
							value === undefined ||
							value === null ||
							(/^-?\d*\.?\d*$/.test(value) &&
								Big(value)
									.toString()
									.match(/^\d*(\.\d{1,2})?$/)),
					),
				vatWht: yup
					.string()
					.nullable()
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'number',
						t('Must be a number'),
						value => value === undefined || value === null || /^-?\d*\.?\d*$/.test(value),
					)
					.test(
						'positive',
						t('Must be positive'),
						value => value === undefined || value === null || Number(value) >= 0,
					)
					.test(
						'decimal',
						t('VAT WHT can be from 0 to 100'),
						value => value === undefined || value === null || Number(value) <= 100,
					)
					.test(
						'decimal-places',
						t('Must have no more than 2 decimal places'),
						value =>
							value === undefined ||
							value === null ||
							(/^-?\d*\.?\d*$/.test(value) &&
								Big(value)
									.toString()
									.match(/^\d*(\.\d{1,2})?$/)),
					),
				nhil: yup
					.string()
					.nullable()
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'number',
						t('NHIL Must be a number'),
						value => value === undefined || value === null || /^-?\d*\.?\d*$/.test(value),
					)
					.test(
						'positive',
						t('NHIL must be positive'),
						value => value === undefined || value === null || Number(value) >= 0,
					)
					.test(
						'decimal',
						t('NHIL can be from 0 to 100'),
						value => value === undefined || value === null || Number(value) <= 100,
					)
					.test(
						'decimal-places',
						t('NHIL must have no more than 2 decimal places'),
						value =>
							value === undefined ||
							value === null ||
							(/^-?\d*\.?\d*$/.test(value) &&
								Big(value)
									.toString()
									.match(/^\d*(\.\d{1,2})?$/)),
					),
				covid19Levy: yup
					.string()
					.nullable()
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'number',
						t('COVID-19 Levy must be a number'),
						value => value === undefined || value === null || /^-?\d*\.?\d*$/.test(value),
					)
					.test(
						'positive',
						t('COVID-19 Levy must be positive'),
						value => value === undefined || value === null || Number(value) >= 0,
					)
					.test(
						'decimal',
						t('COVID-19 Levy can be from 0 to 100'),
						value => value === undefined || value === null || Number(value) <= 100,
					)
					.test(
						'decimal-places',
						t('COVID-19 Levy must have no more than 2 decimal places'),
						value =>
							value === undefined ||
							value === null ||
							(/^-?\d*\.?\d*$/.test(value) &&
								Big(value)
									.toString()
									.match(/^\d*(\.\d{1,2})?$/)),
					),
				getFundLevy: yup
					.string()
					.nullable()
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'number',
						t('GETFUND Levy must be a number'),
						value => value === undefined || value === null || /^-?\d*\.?\d*$/.test(value),
					)
					.test(
						'positive',
						t('GETFUND Levy must be positive'),
						value => value === undefined || value === null || Number(value) >= 0,
					)
					.test(
						'decimal',
						t('GETFUND Levy can be from 0 to 100'),
						value => value === undefined || value === null || Number(value) <= 100,
					)
					.test(
						'decimal-places',
						t('GETFUND Levy must have no more than 2 decimal places'),
						value =>
							value === undefined ||
							value === null ||
							(/^-?\d*\.?\d*$/.test(value) &&
								Big(value)
									.toString()
									.match(/^\d*(\.\d{1,2})?$/)),
					),
			}),
		[t],
	);
};
export default useCountryFormValidationSchema;
