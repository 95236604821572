import ModalForm from 'Common/components/form/ModalForm';
import EpcContractForm from 'EpcContract/components/EpcContractForm/EpcContractForm';
import crudModes from 'Common/constants/crudModes';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

const EpcContractCreateModal = ({ isOpen, onClose, onFormSubmit, project }) => {
	const { t } = useTranslation();

	return (
		<ModalForm
			label="epc-contract-create"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			heading={t('Create EPC Contract')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<EpcContractForm
					projectId={project.id}
					currencies={project.currencies}
					systemSize={String(project.systemSizeKwp)}
					mode={crudModes.CREATE}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

EpcContractCreateModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		currencies: PropTypes.arrayOf(PropTypes.shape({})),
		systemSizeKwp: PropTypes.number,
	}).isRequired,
};

export default EpcContractCreateModal;
