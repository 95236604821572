import { parse } from 'qs';
import { useMemo } from 'react';
import queryParamsDecoder from 'Common/utils/queryParamsDecoder';

const useQueryParameter = name => {
	const search = window.location.search;

	const query = useMemo(
		() =>
			parse(search, {
				ignoreQueryPrefix: true,
				arrayLimit: 50,
				decoder: queryParamsDecoder,
			}),
		[search],
	);

	const newQueryParam = query[name];
	const newQueryParamAsString = JSON.stringify(newQueryParam);

	// noinspection UnnecessaryLocalVariableJS
	const queryParam = useMemo(() => {
		if (newQueryParamAsString) {
			return JSON.parse(newQueryParamAsString);
		}

		return undefined;
	}, [newQueryParamAsString]);

	return queryParam;
};

export default useQueryParameter;
