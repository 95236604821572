import H1 from 'Common/components/headers/H1';
import Modal from 'Common/components/modals/Modal';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from 'Common/components/buttons/Button';
import styled from 'styled-components/macro';

const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

const DeleteInvoiceTemplateViolationModal = ({ isOpen, onClose, violation }) => {
	const { t } = useTranslation();

	return (
		<Modal isOpen={isOpen}>
			<H1>{t('Cannot Delete Invoice Template')}</H1>
			<p>{violation}</p>
			<ButtonsWrapper>
				<Button onClick={onClose} text={t('Close')} />
			</ButtonsWrapper>
		</Modal>
	);
};

DeleteInvoiceTemplateViolationModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	violation: PropTypes.string.isRequired,
};

export default DeleteInvoiceTemplateViolationModal;
