import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useDueDiligenceFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				scoringProfitabilityNetIncomeProfitMargin: yup
					.number()
					.typeError(t('Profitability scoring must be a number'))
					.test(
						'decimal-places',
						t('Profitability scoring must have no more than 2 decimal places'),
						value => value === undefined || String(value).match(/^-?\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.max(100, t('Profitability scoring must be less than or equals to 100'))
					.nullable(),
				scoringLiquidityQuickRatio: yup
					.number()
					.typeError(t('Liquidity scoring must be a number'))
					.test(
						'decimal-places',
						t('Liquidity scoring must have no more than 2 decimal places'),
						value => value === undefined || String(value).match(/^-*\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				scoringSolvencyAssetCoverageRatio: yup
					.number()
					.typeError(t('Solvency scoring must be a number'))
					.test(
						'decimal-places',
						t('Solvency scoring must have no more than 2 decimal places'),
						value => value === undefined || String(value).match(/^-*\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				scoringSolvencyAssetCoverageRatioExShareholderLoans: yup
					.number()
					.typeError(t('Solvency scoring w/o SH loans must be a number'))
					.test(
						'decimal-places',
						t('Solvency scoring w/o SH loans must have no more than 2 decimal places'),
						value => value === undefined || String(value).match(/^-*\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
			}),
		[t],
	);
};

export default useDueDiligenceFormValidationSchema;
