import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import updateEpcPartner from 'EpcPartner/api/updateEpcPartner';
import useQualificationsFormInitialValues from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/QualificationsTile/hooks/useQualificationsFormInitialValues';
import useQualificationsFormValidationSchema from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/QualificationsTile/hooks/useQualificationsFormValidationSchema';
import TileDynamicSection from 'Common/components/Tile/TileDynamicSection';

import styled from 'styled-components/macro';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';
import axios from 'axios';

const FirstSection = styled.div`
	position: relative;
	z-index: 2;
`;

const SpacingElement = styled.div`
	height: 15px;
`;

const QualificationsTile = ({
	epcPartner,
	onEnterEditMode,
	onExitEditMode,
	onSave,
	onDirtyForm,
	highlightLabels,
}) => {
	const { t } = useTranslation();

	const abortController = useAbortController();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.SALES,
		roles.PM_TECHNICAL,
		roles.ASSET_MANAGER,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const initialValues = useQualificationsFormInitialValues(epcPartner);
	const validationSchema = useQualificationsFormValidationSchema();

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleChange,
		handleBlur,
		resetForm,
		setFieldTouched,
		setFieldValue,
	} = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			setIsSavingChanges(true);

			try {
				const response = await updateEpcPartner(abortController.signal, {
					...omit(epcPartner, ['tier']),
					...values,
					internalPassDate: values.internalPassDate
						? format(new Date(values.internalPassDate), 'yyyy-MM-dd')
						: null,
					externalPassDate: values.externalPassDate
						? format(new Date(values.externalPassDate), 'yyyy-MM-dd')
						: null,
					inviteToPortal: values.portalAccess,
				});

				if (onDirtyForm) {
					onDirtyForm(false);
				}

				onSave(response.data);
				handleCancel();
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (error) {
				showToastError(error, t("Can't update EPC partner qualifications data"));
				if (!axios.isCancel(error)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (errors.internalPassDate) {
			setFieldTouched('internalPassDate');
		}
		if (errors.externalPassDate) {
			setFieldTouched('externalPassDate');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [touched, setFieldTouched]);

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	const handleChangeCheckbox = (changes, field, values) => {
		handleChange(changes);
		if (!values) {
			setFieldValue(field, undefined);
		}
	};

	return (
		<EditableTile
			title={t('Qualifications')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
			isReadonly={!isAuthorizedToEdit}
		>
			<FirstSection>
				<FormField>
					<FormikCheckbox
						label={t('Internal qualifications passed')}
						id="internalQualificationPassed"
						name="internalQualificationPassed"
						checked={values.internalQualificationPassed}
						onChange={changes =>
							handleChangeCheckbox(changes, 'internalPassDate', values.internalQualificationPassed)
						}
						onBlur={handleBlur}
						touched={touched.internalQualificationPassed}
						isTile
						isInEditMode={isInEditMode}
						isHighlighted={highlightLabels.includes(
							PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.EPC_PARTNER_QUALIFICATIONS.INTERNAL_QUALIFICATION_PASSED,
						)}
					/>
				</FormField>
				<TileDynamicSection
					isInEditMode={values.internalQualificationPassed}
					editView={
						<>
							{isInEditMode && <SpacingElement />}
							<FormField>
								<FormikSingleDatePicker
									label={t('Internal passed date')}
									startDateName="internalPassDate"
									id="internalPassDate"
									error={errors.internalPassDate}
									touched={touched.internalPassDate}
									startDate={values.internalPassDate}
									setFieldValue={setFieldValue}
									setFieldTouched={setFieldTouched}
									isTile
									isRequired={values.internalQualificationPassed && isInEditMode}
									isInEditMode={isInEditMode}
									isHighlighted={highlightLabels.includes(
										PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.EPC_PARTNER_QUALIFICATIONS.INTERNAL_PASS_DATE,
									)}
								/>
							</FormField>
						</>
					}
					readonlyView={<SpacingElement />}
				/>
				<SpacingElement />
			</FirstSection>
		</EditableTile>
	);
};

QualificationsTile.defaultProps = {
	epcPartner: {},
	onEnterEditMode: () => null,
	onExitEditMode: () => null,
	onDirtyForm: () => null,
	highlightLabels: [],
};

QualificationsTile.propTypes = {
	epcPartner: PropTypes.shape({
		internalQualificationPassed: PropTypes.bool,
		externalQualificationPassed: PropTypes.bool,
	}),
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onSave: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default QualificationsTile;
