import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import crudModes from 'Common/constants/crudModes';
import getAccountingStandardById from 'AccountingStandards/api/getAccountingStandardById';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PropTypes } from 'prop-types';
import AccountingStandardsForm from 'AccountingStandards/components/Form/AccountingStandardsForm';

const AccountingStandardsEditModal = ({ isOpen, onClose, accountingStandardId, onFormSubmit }) => {
	const { t } = useTranslation();

	const [accountingStandard, setAccountingStandard] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();
	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				const response = await getAccountingStandardById(abortController.signal, accountingStandardId);

				setAccountingStandard(response.data);
				setIsLoading(false);
			} catch (error) {
				showToastError(error);
			}
		})();
	}, [accountingStandardId, abortController.signal]);

	return (
		<ModalForm
			label="accounting-standard-edit"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			isLoadingContent={isLoading}
			heading={t('Edit Accounting Standard')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<AccountingStandardsForm
					onCancel={onCancel}
					data={accountingStandard}
					mode={crudModes.EDIT}
					onSubmit={handleSubmit}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

AccountingStandardsEditModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	accountingStandardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default AccountingStandardsEditModal;
