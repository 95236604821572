import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import crudModes from 'Common/constants/crudModes';
import useAbortController from 'Common/hooks/useAbortController';
import Overlay, { useOverlayTitles } from 'Common/components/modals/Overlay';
import SectionContainer from 'Common/components/modals/SectionContainer';
import getClientById from 'Client/api/getClientById';
import showToastError from 'Common/utils/showToastError';
import ClientForm from './ClientForm';

const ClientOverlay = ({ mode, clientId, isOpen, onClose, onFormSubmit, breadcrumbList }) => {
	const { t } = useTranslation();
	const { name, label } = useOverlayTitles(t('Client'), mode);

	const [clientData, setClientData] = useState(null);

	const abortController = useAbortController();

	useEffect(() => {
		if (mode === crudModes.EDIT && isOpen && clientId) {
			(async () => {
				try {
					const response = await getClientById(abortController.signal, clientId, true);

					setClientData(response.data);
				} catch (e) {
					showToastError(e, t("Can't fetch client data"));
				}
			})();
		}
	}, [clientId, abortController.signal, t, mode, isOpen]);

	const isDataLoading = mode === crudModes.EDIT && clientData === null;

	const overlayBreadcrumbList = useMemo(
		() =>
			breadcrumbList || [
				{
					label: 'Clients',
					href: '/clients',
				},
				{
					label: name,
				},
			],
		[breadcrumbList, name],
	);

	return (
		<Overlay
			label={label}
			breadcrumbList={overlayBreadcrumbList}
			isOpen={isOpen}
			onClose={onClose}
			onSave={onFormSubmit}
			isLoadingContent={isDataLoading}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<SectionContainer name="Client">
					<ClientForm
						mode={mode}
						data={clientData}
						onSubmit={handleSubmit}
						onCancel={onCancel}
						onDirty={setIsFormDirty}
					/>
				</SectionContainer>
			)}
		</Overlay>
	);
};

ClientOverlay.defaultProps = {
	data: undefined,
	clientId: undefined,
	breadcrumbList: undefined,
};

ClientOverlay.propTypes = {
	data: PropTypes.shape({}),
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
	clientId: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	breadcrumbList: PropTypes.arrayOf(
		PropTypes.shape({ label: PropTypes.string.isRequired, href: PropTypes.string }),
	),
};

export default ClientOverlay;
