import projectTypes from 'Projects/constants/projectTypes';
import { useMemo } from 'react';

const fallbackValues = {
	pvDegradationRate: '',
	euroPerKwp: '',
	PV: '',
	'EE-AC': '',
	'EE-SHW': '',
	'EE-LED': '',
};

const useProjectDefaultsFormInitialValues = (projectDefaults, projectTypesDefaults) =>
	useMemo(
		() => ({
			...fallbackValues,
			euroPerKwp: projectDefaults?.euroPerKwp ? projectDefaults?.euroPerKwp : '',
			pvDegradationRate: projectDefaults?.pvDegradationRate ? projectDefaults?.pvDegradationRate * 100 : 0,
			PV: projectTypesDefaults.find(item => item.name === projectTypes.PV)?.lifetime,
			'EE-AC': projectTypesDefaults.find(item => item.name === projectTypes.EE_AC)?.lifetime,
			'EE-SHW': projectTypesDefaults.find(item => item.name === projectTypes.EE_SHW)?.lifetime,
			'EE-LED': projectTypesDefaults.find(item => item.name === projectTypes.EE_LED)?.lifetime,
			allowedPercentageOfRestrictedIndustries: projectDefaults?.allowedPercentageOfRestrictedIndustries,
		}),
		[projectDefaults, projectTypesDefaults],
	);

export default useProjectDefaultsFormInitialValues;
