import { useMemo } from 'react';
import { pick } from 'lodash';

const fallbackValues = {
	name: '',
	abbreviation: '',
	countryIds: [],
};

const useEmissionSpvFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['name', 'abbreviation', 'description', 'balances', 'profits']),
			countryIds: data?.countries?.map(country => ({
				value: country.id,
				label: country.name,
			})),
		}),
		[data],
	);

export default useEmissionSpvFormInitialValues;
