import getEntities from 'Entities/api/getEntities';

const loadEntitiesAbbreviationsOptions = async (signal, inputValue) => {
	const {
		data: { content: entities },
	} = await getEntities(signal, {}, { name: inputValue });

	return entities.map(entity => ({
		label: entity.name ?? entity.abbreviation ?? '',
		value: entity.id,
	}));
};

export default loadEntitiesAbbreviationsOptions;
