import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import Loader from 'Common/components/Loader';
import getEpcPartnerOfferById from 'EpcPartner/api/getEpcPartnerOfferById';
import Breadcrumb from 'Common/components/breadcrumbs/Breadcrumb';
import Button from 'Common/components/buttons/Button';
import Breadcrumbs from 'Common/components/breadcrumbs/Breadcrumbs';
import { statusColors, statusOptions, statuses } from 'Projects/constants/offerStatuses';
import Icon from 'Common/components/icons/Icon';
import declineEpcOfferFromPortal from 'EpcPartner/api/declineEpcOfferFromPortal';
import sendEpcOfferFromPortal from 'EpcPartner/api/sendEpcOfferFromPortal';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import { formatPortalStatus } from 'EpcPartner/utils/formatPortalStatus';
import { formattedDocumentTypes } from 'Projects/constants/documentTableOptions';
import TipModal from 'Common/components/modals/TipModal';
import colors from 'Application/theme/colors';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import StatusPill from 'Common/components/StatusPill';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';
import sizes from 'Application/theme/sizes';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';
import formatDate from 'Common/utils/formatDate';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import TabsContainer from 'Common/components/tabs';

import GeneralTab from 'EpcPartner/components/EpcPartnerPortal/EpcOfferDetails/Tabs/GeneralTab';
import ProjectTab from 'EpcPartner/components/EpcPartnerPortal/EpcOfferDetails/Tabs/ProjectTab';
import OffersTab from 'EpcPartner/components/EpcPartnerPortal/EpcOfferDetails/Tabs/OffersTab/OffersTab';
import ImagesTab from 'EpcPartner/components/EpcPartnerPortal/EpcOfferDetails/Tabs/ImagesTab';
import DocumentsTab from 'EpcPartner/components/EpcPartnerPortal/EpcOfferDetails/Tabs/DocumentsTab/DocumentsTab';
import { EPC_OFFER_DETAILS_PAGE_TILES_BY_TAB } from 'EpcPartner/constants/epcPartnerTabForms';
import projectTypes from 'Projects/constants/projectTypes';

const StyledBreadcrumbs = styled(Breadcrumbs)`
	flex: 1;
	line-height: 1.8em;
	max-width: 700px;

	> span {
		display: inline-flex;
		align-items: center;
	}
`;

const CloseDateContainer = styled.div`
	background-color: ${colors.common.tileBackgroundGrey};
	padding-left: ${sizes.spacing(2)};
`;

const UnknownClientTitle = styled.span`
	font-style: italic;
	margin-left: 5px;
`;

const ContentWrapper = styled.div`
	background: ${colors.grey.lightest};
`;

const FieldTitle = styled.p`
	margin-block-start: 5px;
	margin-block-end: 5px;
`;

const mandatoryDocs = docs => docs.filter(doc => doc.mandatory).map(doc => doc.documentTitle);

const requiredFields = data => [
	data.projectType.name === projectTypes.PV && 'systemSize',
	data.projectType.name === projectTypes.PV && 'yield',
	data.projectType.name === projectTypes.PV && 'solarUtilisationRate',
	'epcVolumeExclVat',
	data.projectType.name === projectTypes.PV && 'epcVolumeExclVatKwp',
	'annualOmPrice',
	data.projectType.name === projectTypes.PV && 'annualOmPriceKwp',
	'annualOmEscalationRate',
	data.requestOfferValidity && 'offerValidityDate',
	data.requestGensetQuote && 'gensetsInfo',
];

const requiredFieldsDisplayNames = {
	systemSize: 'System size',
	yield: 'Yield',
	epcVolumeExclVat: 'EPC Volume',
	epcVolumeExclVatKwp: 'EPC Volume in kWp',
	annualOmPrice: 'Annual O&M',
	annualOmPriceKwp: 'Annual O&M per kWp',
	annualOmEscalationRate: 'Annual O&M escalation rate',
	offerValidityDate: 'Offer Validity date',
	gensetsInfo: 'Gensets',
	solarUtilisationRate: 'Solar utilization rate',
	...formattedDocumentTypes,
};

const tipName = 'EPCPORTAL_SEND_TIP';

const EpcOfferDetails = () => {
	const { t } = useTranslation();

	const { id: offerId } = useParams();

	const isTipDismissed = useSelector(state => state.tipModals.modals.includes(tipName));

	const [showTipModal, setShowTipModal] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [epcOffer, setEpcOffer] = useState({});
	const [offerForDecline, setOfferForDecline] = useState(null);

	const [sendErrors, setSendErrors] = useState(null);
	const [offerForSending, setOfferForSending] = useState(null);

	const [isSending, setIsSending] = useState(false);

	const abortController = useAbortController();

	useEffect(() => {
		setIsLoading(true);

		(async () => {
			try {
				const offerResponse = await getEpcPartnerOfferById(abortController.signal, offerId);

				setEpcOffer({
					...offerResponse.data,
					status: formatPortalStatus(offerResponse.data.status),
				});
				setIsLoading(false);
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setEpcOffer(null);
					setIsLoading(false);
				}
			}
		})();
	}, [offerId, abortController.signal]);

	const handleDataChange = useCallback(changes => {
		setEpcOffer(prevEpcOffer => ({
			...prevEpcOffer,
			...changes,
			status: changes.status !== undefined ? formatPortalStatus(changes.status) : prevEpcOffer.status,
		}));
	}, []);

	const handleEpcOfferDecline = async id => {
		try {
			let response = await declineEpcOfferFromPortal(abortController.signal, id);
			notify(t('Offer declined successfully'), {
				type: toast.TYPE.SUCCESS,
			});

			handleDataChange({ status: response.data.status });

			setOfferForDecline(null);
		} catch (e) {
			showToastError(e, t("Can't decline EPC offer"));
		}
	};

	const handleEpcOfferSend = async id => {
		setIsSending(true);
		try {
			let response = await sendEpcOfferFromPortal(abortController.signal, id);
			notify(t('Offer sent successfully'), {
				type: toast.TYPE.SUCCESS,
			});

			handleDataChange({ status: response.data.status });

			setOfferForSending(null);
			setIsSending(false);
		} catch (e) {
			showToastError(e, t("Can't send EPC offer"));
			if (!axios.isCancel(e)) {
				setIsSending(false);
			}
		}
	};

	const validateBeforeSending = () => {
		let errorFields = [];
		let missingDocs = [];
		requiredFields(epcOffer).map(field => {
			if (field) {
				if (epcOffer[field] === null || epcOffer[field] === undefined) {
					errorFields.push(field);
				}
			}
			return true;
		});

		mandatoryDocs(epcOffer.documentDefs).map(mandatoryDoc => {
			if (epcOffer.documents.findIndex(doc => doc.slot === mandatoryDoc) === -1) {
				missingDocs.push(mandatoryDoc);
			}
			return true;
		});

		if (errorFields.length > 0 || missingDocs.length > 0) {
			setSendErrors({ errorFields, missingDocs });
		} else {
			setOfferForSending(epcOffer.id);
		}
	};

	const isImagesTabDisabled = useMemo(
		() => !(Boolean(epcOffer?.images) && epcOffer?.images?.length > 0),
		[epcOffer?.images],
	);

	const isDocumentsTabDisabled = useMemo(
		() => !(Boolean(epcOffer.documentDefs) && epcOffer.documentDefs.length > 0),
		[epcOffer.documentDefs],
	);

	const tabsConfig = useMemo(
		() => [
			{
				label: 'General',
				PanelComponent: GeneralTab,
				panelProps: { epcOffer },
				tabForms: EPC_OFFER_DETAILS_PAGE_TILES_BY_TAB.GENERAL,
			},
			{
				label: 'Project',
				PanelComponent: ProjectTab,
				panelProps: { epcOffer },
				tabForms: EPC_OFFER_DETAILS_PAGE_TILES_BY_TAB.PROJECT,
			},
			{
				label: 'Images',
				PanelComponent: ImagesTab,
				isDisabled: isImagesTabDisabled,
				panelProps: { images: epcOffer?.images },
			},
			{
				label: 'Offer',
				PanelComponent: OffersTab,
				panelProps: { epcOffer, onDataChange: handleDataChange },
				tabForms: EPC_OFFER_DETAILS_PAGE_TILES_BY_TAB.OFFER,
			},
			{
				label: 'Documents',
				PanelComponent: DocumentsTab,
				panelProps: { epcOffer, onDataChange: handleDataChange },
				isDisabled: isDocumentsTabDisabled,
			},
		],
		[epcOffer, isImagesTabDisabled, handleDataChange, isDocumentsTabDisabled],
	);

	if (isLoading) {
		return (
			<LoaderWrapper $isFullPage>
				<Loader />
			</LoaderWrapper>
		);
	}

	if (epcOffer !== null) {
		return (
			<>
				<Helmet>
					<title>{t('Offer Details')}</title>
				</Helmet>
				<HeaderContainer>
					<StyledBreadcrumbs>
						<Breadcrumb to="/epc/offers">{t('Offers')}</Breadcrumb>
						<Breadcrumb active title={epcOffer?.client || ''}>
							{`${epcOffer.externalId} - `}
							{epcOffer?.client ? epcOffer?.client : <UnknownClientTitle>{t('(No client)')}</UnknownClientTitle>}
						</Breadcrumb>
						<StatusPill color={statusColors[epcOffer?.status]}>{statusOptions[epcOffer?.status]}</StatusPill>
					</StyledBreadcrumbs>
					<HeaderActions>
						<Button
							secondary
							icon="clear"
							text={t('Decline')}
							onClick={() => setOfferForDecline(epcOffer.id)}
							disabled={epcOffer?.status !== statuses.OPEN && epcOffer?.status !== statuses.OFFER_SENT}
							label="EPC Partner Portal EPC Offer Details - Decline Button"
						/>
						<Button
							icon="send"
							type="submit"
							text={t('Send')}
							onClick={() => validateBeforeSending()}
							disabled={epcOffer?.status !== statuses.OPEN}
							label="EPC Partner Portal EPC Offer Details - Send Button"
						/>
					</HeaderActions>
				</HeaderContainer>
				{epcOffer?.tenderCloseDate && (
					<CloseDateContainer>
						<KeyValueVisualization
							id="interestRate"
							title={t('SaaS signature date')}
							value={formatDate(epcOffer?.tenderCloseDate).replace(/-/g, '/')}
							keyStyle={{ minWidth: '150px' }}
							tooltip={
								<QuestionTooltip
									tooltip={t('Please send your Offer before midnight (Central European Time) on this day')}
								/>
							}
						/>
					</CloseDateContainer>
				)}
				<ContentWrapper>
					<TabsContainer page="epcPartnerOffers" tabsConfig={tabsConfig} />
				</ContentWrapper>
				<ConfirmModal
					isOpen={offerForDecline !== null}
					onCancel={() => setOfferForDecline(null)}
					onConfirm={() => handleEpcOfferDecline(offerForDecline)}
					label="decline-epc-offer"
					heading={t('Decline EPC offer')}
					text={t('Are you sure you want to decline this offer?')}
				/>
				<ConfirmModal
					isOpen={offerForSending !== null}
					onCancel={() => setOfferForSending(null)}
					onConfirm={() => handleEpcOfferSend(offerForSending)}
					confirmText={
						<>
							<Icon icon="send" />
							{t('Send')}
						</>
					}
					withConfirmIcon={false}
					isLoading={isSending}
					label="send-epc-proposal"
					heading={t('Send EPC proposal')}
					text={t(
						`No further changes can be made after sending.  If you need to make changes after sending, contact your ecoligo representative.

							Are you sure you want to send this offer?`,
					)}
				/>
				{sendErrors && (
					<UnsuccessfulOperationModal
						label="cannot-send-epc-proposal"
						isOpen={sendErrors !== null}
						onCancel={() => setSendErrors(null)}
						heading={t('Cannot send EPC proposal')}
						text={t('Cannot send offer because:')}
					>
						{sendErrors.errorFields.map((field, index) => (
							// eslint-disable-next-line react/no-array-index-key
							<FieldTitle key={`field-error-${index}`}>
								<span>{`${requiredFieldsDisplayNames[field]} is empty`} </span>
							</FieldTitle>
						))}
						{sendErrors.missingDocs.map((doc, index) => (
							<FieldTitle
								// eslint-disable-next-line react/no-array-index-key
								key={`doc-${index}`}
							>{`${requiredFieldsDisplayNames[doc]} has not been uploaded (Documents tab)`}</FieldTitle>
						))}
					</UnsuccessfulOperationModal>
				)}
				<TipModal
					isOpen={showTipModal && !isTipDismissed && epcOffer?.status === statuses.OPEN}
					onCancel={() => setShowTipModal(false)}
					cancelText={t('Ok, great!')}
					label="epc-offer-details-tip"
					tipName={tipName}
				>
					Once you’ve finished preparing your offer, don’t forget to send it by pressing the <b>Send</b> button!
					<br />
					<br />
					If you don’t want to make an offer, please press the <b>Decline</b> button instead so ecoligo know you
					are not interested in this opportunity.
				</TipModal>
			</>
		);
	}

	return <Navigate to="/epc/offers" />;
};

export default EpcOfferDetails;
