import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { useLocation } from 'react-router';

const useSavedUrl = (reduxUrl, updateReduxTableData) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [isUrlSet, setIsUrlSet] = useState(false);
	const queryUrl = useMemo(() => location?.search, [location?.search]);

	useEffect(() => {
		if (isUrlSet) return;

		if (location.search === '' && reduxUrl) {
			dispatch(push(`${location.pathname}${reduxUrl}`));
		}
		setIsUrlSet(true);
	}, [dispatch, location.search, isUrlSet, location.pathname, reduxUrl]);

	useEffect(() => {
		if (!isUrlSet) return;

		updateReduxTableData({
			url: queryUrl,
		});
	}, [updateReduxTableData, isUrlSet, queryUrl]);
};

export default useSavedUrl;
