import styled from 'styled-components/macro';
import { css } from 'styled-components';
import colors from 'Application/theme/colors';
import {
	Tab as OriginalTab,
	Tabs as OriginalTabs,
	TabList as OriginalTabsList,
	TabPanel as OriginalTabPanel,
} from 'react-tabs';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { updateCurrentTab } from 'Application/reducers/reduxTabs';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import EditingIndicator from 'Common/components/EditingIndicator';

import isAuthorized from 'User/utils/isAuthorized';
import Prompt from 'Common/components/Prompt';
import { closeSidePanel } from 'Application/reducers/reduxSidePanel';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import useSetQueryParameter from 'Common/hooks/useSetQueryParameter';

export const Tab = styled(OriginalTab)`
	display: inline-block;
	padding: 5px 15px;
	font-size: 16px;
	cursor: pointer;
	color: ${colors.common.grey};
	position: relative;
	font-weight: 500;
	outline: 0;

	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.5;
		`}

	& :hover {
		color: ${colors.primary.main};
	}
`;

export const Tabs = styled(OriginalTabs)`
	& .react-tabs__tab--selected {
		color: ${colors.text.secondary};
		font-weight: 700;
		line-height: 20px;
		border-bottom: 2px solid ${colors.text.secondary};
	}
`;

export const TabList = styled(OriginalTabsList)`
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	padding-inline-start: 0;
	margin-block-end: 0;
	margin-block-start: 0;
	background: ${colors.common.tabsGrey};
	border-top: 1px solid ${colors.common.lightGrey};
	padding-top: 8px;
`;

export const TabPanel = styled(OriginalTabPanel)`
	display: ${({ forceRender }) => (forceRender ? 'none' : 'block')};
	background: ${colors.common.white};
`;

const TabLabel = styled.div`
	position: relative;
	display: inline-block;
`;

const alertUnsavedChanges = event => {
	event.preventDefault();
	event.returnValue = '';
};

const generateDataAttr = label => label.toLowerCase().replace(' ', '-');

const containsElementFrom = (array1, array2 = []) => array1.some(element => array2.includes(element));

const useTabsData = (tabsConfig, dirtyForms) =>
	useMemo(
		() =>
			tabsConfig
				.filter(({ authorizedRoles }) => (authorizedRoles ? isAuthorized(authorizedRoles) : true))
				.map(({ label, tabForms, ...rest }, index) => ({
					tabDataAttr: generateDataAttr(label),
					tabIndex: index,
					isDirty: containsElementFrom(dirtyForms, tabForms),
					label,
					tabForms,
					...rest,
				})),
		[tabsConfig, dirtyForms],
	);

const TabsContainer = ({ page, tabsConfig }) => {
	const dispatch = useDispatch();
	const selectedTab = useSelector(state => state.tabs[page] ?? 0);

	const [editModeEnabledTabs, setEditModeEnabledTabs] = useState([]);
	const [dirtyForms, setDirtyForms] = useState([]);
	const sidePanel = useSelector(state => state.sidePanel);
	const tabQueryParam = useQueryParameter('tab');
	const setTabQueryParam = useSetQueryParameter('tab');

	const tabsData = useTabsData(tabsConfig, dirtyForms);

	useEffect(() => {
		if (editModeEnabledTabs.length > 0) {
			window.addEventListener('beforeunload', alertUnsavedChanges);
		} else {
			window.removeEventListener('beforeunload', alertUnsavedChanges);
		}

		return () => window.removeEventListener('beforeunload', alertUnsavedChanges);
	}, [editModeEnabledTabs]);

	const handleEnableEditMode = tab => {
		setEditModeEnabledTabs(preEditModeEnabledTabs => [...preEditModeEnabledTabs, tab]);
	};

	const handleDisableEditMode = tab => {
		setEditModeEnabledTabs(preEditModeEnabledTabs => {
			const newTabs = [...preEditModeEnabledTabs];

			const index = newTabs.indexOf(tab);

			if (index !== -1) {
				newTabs.splice(index, 1);
			}

			return newTabs;
		});
	};

	const handleDirtyForms = useCallback(
		(form, isDirty) => {
			if (isDirty) {
				setDirtyForms(prev => [...prev, form]);
			} else {
				setDirtyForms(prev => {
					const current = [...prev];
					const formIndex = current.indexOf(form);
					if (formIndex > -1) {
						current.splice(formIndex, 1);
					}
					return current;
				});
			}
		},
		[setDirtyForms],
	);

	const handleTabChange = useCallback(
		tabIndex => {
			const customTabSelect = tabsData[tabIndex].onTabSelect;

			if (customTabSelect && typeof customTabSelect === 'function') {
				customTabSelect(tabIndex);
				return;
			}

			if (sidePanel.isOpen) {
				dispatch(closeSidePanel());
			}

			dispatch(
				updateCurrentTab({
					tab: page,
					tabIndex,
				}),
			);
		},
		[dispatch, page, sidePanel.isOpen, tabsData],
	);

	useEffect(() => {
		if (tabQueryParam) {
			const anchoredTab = tabsData.find(({ queryAnchor }) => queryAnchor === tabQueryParam);
			handleTabChange(anchoredTab.tabIndex);
			setTabQueryParam(null, true);
		}
	}, [handleTabChange, setTabQueryParam, tabQueryParam, tabsData]);

	return (
		<>
			<Prompt message="" when={dirtyForms.length > 0} />
			<Tabs selectedIndex={selectedTab} onSelect={handleTabChange}>
				<TabList>
					{tabsData.map(tab => (
						<Tab data-tab={tab.tabDataAttr} disabled={tab.isDisabled} key={tab.tabIndex}>
							<TabLabel>
								{tab.label}
								{((tab.isDirty && selectedTab !== tab.tabIndex) || tab.showRedDot) && <EditingIndicator />}
							</TabLabel>
						</Tab>
					))}
				</TabList>
				{tabsData.map(({ PanelComponent, ...tab }) => (
					<TabPanel
						key={tab.tabIndex}
						forceRender={
							(editModeEnabledTabs.includes(tab.tabIndex) || tab.forceRender) && selectedTab !== tab.tabIndex
						}
					>
						<PanelComponent
							onEnterEditMode={() => handleEnableEditMode(tab.tabIndex)}
							onExitEditMode={() => handleDisableEditMode(tab.tabIndex)}
							onDirtyForm={handleDirtyForms}
							{...tab.panelProps}
						/>
					</TabPanel>
				))}
			</Tabs>
		</>
	);
};

TabsContainer.propTypes = {
	page: PropTypes.string.isRequired,
	tabsConfig: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			PanelComponent: PropTypes.func.isRequired,
			onTabSelect: PropTypes.func,
			panelProps: PropTypes.shape({}),
			tabForms: PropTypes.arrayOf(PropTypes.string),
			authorizedRoles: PropTypes.arrayOf(PropTypes.string),
			forceRender: PropTypes.bool,
			showRedDot: PropTypes.bool,
			queryAnchor: PropTypes.string,
			isDisabled: PropTypes.bool,
		}),
	).isRequired,
};

export default TabsContainer;
