import { useMemo } from 'react';

import ProgressTab from 'Projects/components/ProjectDetails/Tabs/ProgressTab/ProgressTab';
import ClientTab from 'Projects/components/ProjectDetails/Tabs/ClientTab/ClientTab';
import ProjectTab from 'Projects/components/ProjectDetails/Tabs/ProjectTab/ProjectTab';
import SalesTab from 'Projects/components/ProjectDetails/Tabs/SalesTab/SalesTab';
import InvoicesTab from 'Projects/components/ProjectDetails/Tabs/InvoiceTab/InvoiceTab';
import EPCTab from 'Projects/components/ProjectDetails/Tabs/EPCTab/EPCTab';
import ImagesTab from 'Projects/components/ProjectDetails/Tabs/ImagesTab/ImagesTab';
import CampaignsTab from 'Projects/components/ProjectDetails/Tabs/CampaignsTab/CampaignsTab';
import OperationsTab from 'Projects/components/ProjectDetails/Tabs/OperationsTab/OperationsTab';
import { PROJECT_DETAILS_PAGE_TILES_BY_TAB } from 'Projects/constants/projectTabForms';
import MeteocontrolTab from 'Projects/components/ProjectDetails/Tabs/MeteocontrolTab/MeteocontrolTab';

const useProjectsTabsConfig = (project, handleUpdateProject) =>
	useMemo(
		() =>
			[
				{
					label: 'Progress',
					PanelComponent: ProgressTab,
				},
				{
					label: 'Client',
					PanelComponent: ClientTab,
					tabForms: PROJECT_DETAILS_PAGE_TILES_BY_TAB.CLIENT,
					forceRender: true,
				},
				{
					label: 'Project',
					PanelComponent: ProjectTab,
					tabForms: PROJECT_DETAILS_PAGE_TILES_BY_TAB.PROJECT,
					forceRender: true,
				},
				{
					label: 'Sales',
					PanelComponent: SalesTab,
					queryAnchor: 'sales',
				},
				{
					label: 'Invoices',
					PanelComponent: InvoicesTab,
					forceRender: true,
					tabForms: PROJECT_DETAILS_PAGE_TILES_BY_TAB.INVOICE,
					queryAnchor: 'invoices',
				},
				{
					label: 'EPC',
					PanelComponent: EPCTab,
					tabForms: PROJECT_DETAILS_PAGE_TILES_BY_TAB.EPC,
				},
				{
					label: 'Images',
					PanelComponent: ImagesTab,
				},
				{
					label: 'Campaigns',
					PanelComponent: CampaignsTab,
					forceRender: true,
				},
				{
					label: 'Operations',
					PanelComponent: OperationsTab,
					forceRender: true,
				},
				{
					label: 'Measurements',
					PanelComponent: MeteocontrolTab,
					tabForms: PROJECT_DETAILS_PAGE_TILES_BY_TAB.METEOCONTROL,
				},
			].map(({ ...tabConfig }) => ({
				...tabConfig,
				panelProps: {
					...tabConfig.panelProps,
					handleUpdateProject,
					project,
				},
			})),
		[project, handleUpdateProject],
	);

export default useProjectsTabsConfig;
