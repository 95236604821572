import api from 'Application/api/api';

const getInvoiceById = async (signal, invoiceId, details = true) => {
	const response = await api.get(`/invoices/by-id/${invoiceId}`, {
		params: {
			details,
		},
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return response;
};

export default getInvoiceById;
