const PROJECT_DETAILS_PAGE_TAB_TILES = {
	CLIENT: {
		CLIENT: 'client',
		DETAILS: 'client-details',
		ADDRESS: 'client-address',
		DUE_DILIGENCE: 'dueDiligence',
	},
	PROJECT: {
		PROJECT_DETAILS: 'projectDetails',
		CURRENCIES: 'currencies',
		LOCATION: 'location',
		STATUS: 'status',
		RESPONSIBLE_PEOPLE: 'responsiblePeople',
		ENGAGEMENT: 'engagement',
		PROJECT_ENTITIES: 'projectEntities',
	},
	INVOICE: {
		SETTINGS: 'settings',
	},
	EPC: {
		EPC_PARTNER: 'epcPartner',
		DETAILS: 'epc-details',
		ADDRESS: 'epc-address',
		QUALIFICATIONS: 'qualifications',
		MANAGERS: 'managers',
	},
	OPERATIONS: {
		INSURANCE: 'insurance',
		COMMISSIONING: 'commissioning',
		POSTCOMPLETION: 'postcompletion',
	},
	METEOCONTROL: {
		METEOCONTROL: 'meteocontrol',
	},
};

const PROJECT_DETAILS_PAGE_TILES_BY_TAB = {
	CLIENT: Object.values(PROJECT_DETAILS_PAGE_TAB_TILES.CLIENT),
	PROJECT: Object.values(PROJECT_DETAILS_PAGE_TAB_TILES.PROJECT),
	INVOICE: Object.values(PROJECT_DETAILS_PAGE_TAB_TILES.INVOICE),
	EPC: Object.values(PROJECT_DETAILS_PAGE_TAB_TILES.EPC),
	OPERATIONS: Object.values(PROJECT_DETAILS_PAGE_TAB_TILES.OPERATIONS),
	METEOCONTROL: Object.values(PROJECT_DETAILS_PAGE_TAB_TILES.METEOCONTROL),
};

export { PROJECT_DETAILS_PAGE_TAB_TILES, PROJECT_DETAILS_PAGE_TILES_BY_TAB };
