import {
	PM_WORKFLOW_TILES_LABELS,
	PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS,
	PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_LABELS,
	PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_OPTIONS,
} from 'PMWorkflow/constants/PMWorkflowTiles';

const getTileHighLightFieldsOptions = tiles =>
	tiles
		.map(({ value }) => value)
		.reduce((prev, tile) => [...prev, ...(PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_OPTIONS[tile] ?? [])], []);

const formatProjectTiles = tiles =>
	tiles.reduce(
		(prev, tile) => [
			...prev,
			{
				value: tile,
				label: PM_WORKFLOW_TILES_LABELS[tile],
			},
		],
		[],
	);

const formatProjectTilesHighLightFields = fields =>
	fields.reduce(
		(prev, field) => [
			...prev,
			{
				value: field,
				label: PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_LABELS[field],
			},
		],
		[],
	);

const filterHighlightedFieldsByTiles = (tiles, highlightedFields) => {
	const allowedFields = tiles.reduce(
		(prev, tileObj) => [...prev, ...Object.keys(PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS[tileObj.value] ?? {})],
		[],
	);

	return highlightedFields.filter(field => allowedFields.includes(field.value));
};

export {
	getTileHighLightFieldsOptions,
	formatProjectTilesHighLightFields,
	formatProjectTiles,
	filterHighlightedFieldsByTiles,
};
