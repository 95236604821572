import Icon from 'Common/components/icons/Icon';

import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import { PropTypes } from 'prop-types';
import sizes from 'Application/theme/sizes';
import useResponsive from 'Common/hooks/useResponsive';

const DataControlLabelMobile = styled.div`
	padding: 0 8px 0 8px;
	height: 14px;
	font-size: 12px;
	font-weight: 600;
	display: flex;
	align-items: center;
	color: ${colors.text.black};
`;

const DataControlLabel = styled.div`
	padding: 0 ${sizes.spacing(2)} 0 ${sizes.spacing(2)};
	height: 24px;
	${sizes.fontSize.tiny};
	font-weight: 500;
	display: flex;
	align-items: center;
	color: ${({ $selected }) => ($selected ? colors.text.secondary : colors.grey.black)};
	user-select: none;
	cursor: pointer;

	:not(:last-child) {
		border-right: 1px solid #d9d9d9;
	}
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: ${sizes.spacing(2)};
	margin-left: ${sizes.spacing(2)};
`;

const IconWrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 36px;
	width: 36px;
	background-color: ${colors.primary.light};
	border-radius: 999px;
`;

const GraphControl = ({ labels, selectedIndex, onControlSelect }) => {
	const { isMobile } = useResponsive();
	return isMobile ? (
		<Row>
			<IconWrapper onClick={() => onControlSelect(selectedIndex - 1)}>
				<Icon size="smallest" icon="arrowBackIos" color={colors.primary.dark} />
			</IconWrapper>
			<DataControlLabelMobile>{labels[selectedIndex]}</DataControlLabelMobile>
			<IconWrapper onClick={() => onControlSelect(selectedIndex + 1)}>
				<Icon size="smallest" icon="arrowForwardIos" color={colors.primary.dark} />
			</IconWrapper>
		</Row>
	) : (
		<Row>
			{labels &&
				labels.map((label, idx) => (
					<DataControlLabel onClick={() => onControlSelect(idx)} $selected={selectedIndex === idx} key={label}>
						{label}
					</DataControlLabel>
				))}
		</Row>
	);
};

GraphControl.propTypes = {
	labels: PropTypes.arrayOf(PropTypes.string).isRequired,
	selectedIndex: PropTypes.number.isRequired,
	onControlSelect: PropTypes.func.isRequired,
};

export default GraphControl;
