import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { statusesColors, statusesOptions } from 'Projects/constants/statuses';
import getProjects from 'Projects/api/getProjects';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import 'rc-pagination/assets/index.css';
import formatCurrency from 'Common/utils/formatCurrency';
import formatNumber from 'Common/utils/formatNumber';
import Big from 'Common/utils/customBig';
import EpcPartnerNameWithTier from 'EpcPartner/components/EpcPartnerNameWithTier';
import { DefaultTable } from 'Common/components/table/table';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import { useSortBy } from 'react-table';
import { useSortingQueryParameter } from 'Common/hooks/table/plugin-hooks/useSortingQueryParameter';
import { useScrollableTableBody } from 'Common/hooks/table/plugin-hooks/useScrollableTableBody';
import { useVirtualizedTableBody } from 'Common/hooks/table/plugin-hooks/useVirtualizedTableBody';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import ReactTimeAgo from 'react-time-ago';
import { debounce } from 'lodash';
import formatDate from 'Common/utils/formatDate';
import colors from 'Application/theme/colors';
import StatusPill from 'Common/components/StatusPill';
import TableCellText from 'Common/components/table/TableCellText';
import UsersPreview from 'Common/components/usersPreview/UsersPreview';
import sizes from 'Application/theme/sizes';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import { useCustomRowStyle } from 'Common/hooks/table/plugin-hooks/useCustomRowStyle';
import PriorityIcon from 'Common/components/icons/PriorityIcon';
import Link from 'Common/components/Link';
import { push } from 'redux-first-history';
import showToastError from 'Common/utils/showToastError';
import PropTypes from 'prop-types';
import useUniqueAbortSignal from 'Common/hooks/useUniqueAbortSignal';
import prefillTableData from 'Common/utils/prefillTableData';

const Wrapper = styled.div`
	position: relative;
`;

const StagePill = styled(StatusPill)`
	padding: ${sizes.spacing(0.5)} ${sizes.spacing(1)};
`;

const stagePercentageColors = {
	NOT_STARTED: 'transparent',
	IN_PROGRESS: colors.secondary.dark,
	COMPLETED: 'transparent',
};

const Stage = ({ stage }) => {
	const openedAt = stage.openedAt && `SD: ${formatDate(stage.openedAt)}`;
	const lastCompletedAt = stage.lastCompletedAt && `ED: ${formatDate(stage.lastCompletedAt)}`;

	const tooltipText =
		openedAt && lastCompletedAt
			? `${openedAt}
		${lastCompletedAt}`
			: !openedAt && !lastCompletedAt
			? ''
			: openedAt || lastCompletedAt;

	return (
		<HoverTooltip title={tooltipText} placement="bottom">
			<StagePill
				color={stagePercentageColors[stage?.stageStatus]}
				isBlackText={
					stage?.stageStatus === 'NOT_STARTED' || stage?.stageStatus === 'COMPLETED' || !stage?.stageStatus
				}
			>
				{stage?.completedPercentage ? Math.floor(stage?.completedPercentage) : 0}%
			</StagePill>
		</HoverTooltip>
	);
};

Stage.defaultProps = {
	stage: null,
};

Stage.propTypes = {
	stage: PropTypes.shape({
		stageStatus: PropTypes.string.isRequired,
		completedPercentage: PropTypes.string,
		openedAt: PropTypes.string,
		lastCompletedAt: PropTypes.string,
	}),
};

const StageCell = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`;

const FooterSizeText = styled.div`
	transform: translateX(-30%);
`;

const defaultSortBy = {
	id: 'externalId',
	desc: true,
};

const sortPropertiesMap = {
	condition: 'cond',
	country: 'country.isoCode',
	contractType: 'contractType.name',
	epcPartner: 'epcPartner.name',
	client: 'client.name',
	stage: 'stageOrder',
	duration: 'contractDuration',
	salesChannel: 'salesChannel.name',
	industry: 'client.industry.name',
};

const ClientProjectListTable = ({ client }) => {
	const { t } = useTranslation();

	const [totalItemsCount, setTotalItemsCount] = useState(client?.projects?.length);

	const sortByQueryParam = useQueryParameter('sortBy');
	const sortBy = sortByQueryParam ?? defaultSortBy;
	const filters = useMemo(() => ({ clientId: [client.id] }), [client]);

	const dispatch = useDispatch();

	const [totals, setTotals] = useState(null);
	const [projects, setProjects] = useState(client.projects);
	const [isLoading, setIsLoading] = useState(true);

	const statusValue = project => {
		const statusOption = statusesOptions.find(option => option.value === project.condition?.condition)?.label;

		return statusOption;
	};

	const getUniqueSignal = useUniqueAbortSignal();

	const hasLoadedFirstResultsRef = useRef(false);

	const getProjectsBatch = useCallback(
		async (startIndex, stopIndex, filters, sortBy, hasItemsChanges = false) => {
			if (hasItemsChanges) {
				setIsLoading(true);
			}

			const sortById = sortPropertiesMap[sortBy.id] ?? sortBy.id;

			try {
				let response = await getProjects(
					getUniqueSignal('get-clients'),
					{
						offset: startIndex,
						limit: stopIndex - startIndex + 1,
						sortBy: sortById,
						sortDirection: sortBy.desc ? 'DESC' : 'ASC',
					},
					filters,
					undefined,
				);

				if (hasItemsChanges || !hasLoadedFirstResultsRef.current) {
					const { itemsCount, itemsFilled, error } = prefillTableData(response.data, startIndex, stopIndex, true);
					if (error) {
						showToastError(error);
					} else {
						setProjects(itemsFilled);
						setTotalItemsCount(itemsCount);
						setTotals({
							totalEpcVolumeExclVat: response.data.totalEpcVolumeExclVat,
							totalSystemSize: response.data.totalSystemSize,
						});
					}
				} else {
					setProjects(prevProjects =>
						prevProjects.map((prevProject, index) => {
							if (startIndex <= index && index <= stopIndex) {
								const indexInResultSet = index - startIndex;

								const project = response.data.content[indexInResultSet];

								const stageIndicators = {};
								project?.stageIndicators?.forEach(s => {
									stageIndicators[s.stage] = s;
								});

								return { ...project, stageIndicators };
							}

							return prevProject;
						}),
					);
				}

				hasLoadedFirstResultsRef.current = true;

				if (hasItemsChanges) {
					setIsLoading(false);
				}
			} catch (error) {
				showToastError(error, t("Can't fetch client projects"));
				return error;
			}
		},
		[getUniqueSignal, t],
	);

	useEffect(() => {
		getProjectsBatch(
			0,
			25,
			filters,
			{
				id: sortBy.id,
				desc: sortBy.desc,
			},
			true,
		);
	}, [getProjectsBatch, filters, sortBy.desc, sortBy.id]);

	// eslint-disable-next-line
	const handleLoadMoreRows = useCallback(
		debounce(
			(startIndex, stopIndex) => {
				getProjectsBatch(startIndex, stopIndex, filters, {
					id: sortBy.id,
					desc: sortBy.desc,
				});
			},
			350,
			{
				leading: false,
				trailing: true,
			},
		),
		[getProjectsBatch, filters, sortBy.desc, sortBy.id],
	);

	const formatId = id => (id ? `${'0'.repeat(5 - String(id).length)}${id}` : '-');

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: '\u00A0',
				accessor: 'priority',
				maxWidth: 37,
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => <PriorityIcon priority={project.priority.priority} />;
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ priority: PropTypes.shape({ priority: PropTypes.string }) }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Status'),
				accessor: 'condition',
				maxWidth: 120,
				customBodyCellStyles: {
					paddingLeft: '4px',
					overflow: 'visible',
				},
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => (
						<StageCell>
							<HoverTooltip
								title={statusesOptions.find(option => option.value === project.condition?.condition)?.label}
							>
								<StatusPill color={statusesColors[project?.condition?.condition]}>{statusValue(project)}</StatusPill>
							</HoverTooltip>
						</StageCell>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ condition: PropTypes.shape({ condition: PropTypes.string }) }),
						}).isRequired,
					};
					return Cell;
				})(),
				Footer: totalItemsCount,
			},
			{
				Header: t('Sales'),
				maxWidth: 63,
				accessor: 'embeddableProjectStage.salesCompletedPercentage',
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => {
						const stage = project?.stageIndicators?.SALES;
						return <Stage stage={stage} />;
					};
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ stageIndicators: PropTypes.shape({ SALES: PropTypes.shape({}) }) }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Tech'),
				maxWidth: 63,
				accessor: 'embeddableProjectStage.techCompletedPercentage',
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => {
						const stage = project?.stageIndicators?.TECH;
						return <Stage stage={stage} />;
					};
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ stageIndicators: PropTypes.shape({ TECH: PropTypes.shape({}) }) }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Fund'),
				maxWidth: 63,
				accessor: 'embeddableProjectStage.fundraisingCompletedPercentage',
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => {
						const stage = project?.stageIndicators?.FUNDRAISING;
						return <Stage stage={stage} />;
					};
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ stageIndicators: PropTypes.shape({ FUNDRAISING: PropTypes.shape({}) }) }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Build'),
				maxWidth: 63,
				accessor: 'embeddableProjectStage.buildCompletedPercentage',
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => {
						const stage = project?.stageIndicators?.BUILD;
						return <Stage stage={stage} />;
					};
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ stageIndicators: PropTypes.shape({ BUILD: PropTypes.shape({}) }) }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Asset'),
				maxWidth: 63,
				accessor: 'embeddableProjectStage.assetManagementCompletedPercentage',
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => {
						const stage = project?.stageIndicators?.ASSET_MANAGEMENT;
						return <Stage stage={stage} />;
					};
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								stageIndicators: PropTypes.shape({ ASSET_MANAGEMENT: PropTypes.shape({}) }),
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('ID'),
				accessor: 'externalId',
				maxWidth: 54,
				highlightSearchTerm: true,
				cellStyle: {
					justifyContent: 'flex-end',
				},
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => (
						<Link
							color={colors.primary.main}
							fontSize="14px"
							to={`/projects/details/${project.id}`}
							onClick={e => e.stopPropagation()}
						>
							{formatId(project.externalId)}
						</Link>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
								externalId: PropTypes.number,
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Country'),
				accessor: 'country',
				maxWidth: 104,
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => (
						<div title={project.country?.isoCode}>
							<TableCellText>{project.country?.isoCode}</TableCellText>
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ country: PropTypes.shape({ isoCode: PropTypes.string }) }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Client'),
				accessor: 'client',
				highlightSearchTerm: true,
				customBodyCellContentStyles: {
					color: colors.primary.main,
				},
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => (
						<Link
							color={colors.primary.main}
							fontSize="14px"
							to={`/clients/details/${project.client?.id}`}
							onClick={e => e.stopPropagation()}
						>
							<TableCellText>{project.client?.name}</TableCellText>
						</Link>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								client: PropTypes.shape({
									id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
									name: PropTypes.string,
								}),
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Type'),
				accessor: 'projectType',
				maxWidth: 64,
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original } }) => original.projectType?.name ?? '-';
					Cell.propTypes = { row: PropTypes.shape({ original: PropTypes.shape({}) }).isRequired };
					return Cell;
				})(),
			},
			{
				Header: t('People'),
				width: 90,
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => (
						<UsersPreview users={project?.responsiblePeopleObjectByRole} displayTeam />
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ responsiblePeopleObjectByRole: PropTypes.shape({}) }),
						}).isRequired,
					};
					return Cell;
				})(),
				customBodyCellContentStyles: {
					overflow: 'visible',
				},
			},
			{
				Header: t('Size (kWp)'),
				accessor: 'systemSizeKwp',
				maxWidth: 105,
				isNumber: true,
				highlightSearchTerm: true,
				cellStyle: {
					justifyContent: 'flex-end',
				},
				Cell: (() => {
					const Cell = ({ value }) => <TableCellText>{value}</TableCellText>;
					Cell.propTypes = { value: PropTypes.string.isRequired };
					return Cell;
				})(),
				Footer: (
					<FooterSizeText>
						{totals?.totalSystemSize ? `${formatNumber(totals?.totalSystemSize, 0)} kWp` : '-'}
					</FooterSizeText>
				),
			},
			{
				Header: t('EPC volume'),
				accessor: 'epcVolumeExclVat',
				width: 110,
				isCurrency: true,
				highlightSearchTerm: true,
				cellStyle: {
					justifyContent: 'flex-end',
				},
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => (
						<TableCellText>{project?.epcVolumeExclVat ?? '-'}</TableCellText>
					);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ epcVolumeExclVat: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
				Footer: totals?.totalEpcVolumeExclVat ? formatCurrency(totals?.totalEpcVolumeExclVat, 0) : '-',
			},
			{
				Header: t('EPC Partner'),
				accessor: 'epcPartner',
				maxWidth: 154,
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original: project } }) =>
						project.epcPartner?.name ? (
							<Link
								color={colors.primary.main}
								fontSize="14px"
								to={`/epc-partners/details/${project?.epcPartner?.id}`}
								onClick={e => e.stopPropagation()}
								style={{
									textDecorationColor: project?.epcPartner?.internalQualificationPassed
										? colors.common.black
										: colors.error.main,
									color: project?.epcPartner?.internalQualificationPassed ? colors.common.black : colors.error.main,
								}}
							>
								<EpcPartnerNameWithTier
									name={project.epcPartner?.name}
									tier={project.epcPartner?.internalQualificationPassed}
									hasPoints={false}
									showIcon={false}
								/>
							</Link>
						) : (
							'-'
						);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								epcPartner: PropTypes.shape({
									internalQualificationPassed: PropTypes.bool,
									name: PropTypes.string,
									id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
								}),
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Contract'),
				accessor: 'contractType',
				maxWidth: 114,
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => (
						<TableCellText>{project.contractType?.name ?? '-'}</TableCellText>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								contractType: PropTypes.shape({ name: PropTypes.string }),
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Duration'),
				accessor: 'duration',
				maxWidth: 114,
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => (
						<TableCellText>{project.contractDuration ?? '-'}</TableCellText>
					);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ contractDuration: PropTypes.number }) }).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('SaaS date'),
				accessor: 'clientContract.saasSignatureDate',
				maxWidth: 114,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => (
						<TableCellText>
							{project.clientContract?.saasSignatureDate ? project.clientContract?.saasSignatureDate : '-'}
						</TableCellText>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								clientContract: PropTypes.shape({ saasSignatureDate: PropTypes.string }),
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('IRR'),
				accessor: 'irr',
				maxWidth: 64,
				cellStyle: {
					justifyContent: 'flex-end',
				},
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => (
						<TableCellText>{project.irr ? Big(project.irr).times(100).toString() : '-'}</TableCellText>
					);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ irr: PropTypes.number }) }).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Sales channel'),
				accessor: 'salesChannel',
				width: 130,
				cellStyle: {
					whiteSpace: 'flex-end',
				},
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => (
						<TableCellText>{project.salesChannel?.name ?? '-'}</TableCellText>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ salesChannel: PropTypes.shape({ name: PropTypes.string }) }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Industry'),
				accessor: 'industry',
				maxWidth: 144,
				cellStyle: {
					whiteSpace: 'flex-end',
				},
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => (
						<TableCellText>{project.client?.industry?.name ?? '-'}</TableCellText>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								client: PropTypes.shape({ industry: PropTypes.shape({ name: PropTypes.string }) }),
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Updated'),
				accessor: 'lastModifiedDate',
				maxWidth: 94,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				Cell: (() => {
					const Cell = ({ row: { original: project } }) =>
						project.lastModifiedDate ? (
							<TableCellText tooltipContent={formatDate(project?.lastModifiedDate, 'EEEE dd/MM/yyyy HH:mm')}>
								<ReactTimeAgo date={new Date(project?.lastModifiedDate)} timeStyle="round" tooltip={false} />
							</TableCellText>
						) : (
							'-'
						);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ lastModifiedDate: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
			},
		],
		[t, totals, totalItemsCount],
	);

	const data = useMemo(() => projects, [projects]);

	const itemsHash = useMemo(() => JSON.stringify({ filters, sortBy }), [filters, sortBy]);

	const handleRowClick = useCallback(
		row => {
			if (!window.getSelection().toString()) {
				dispatch(push(`/projects/details/${row.original.id}`));
			}
		},
		[dispatch],
	);

	// Fallback to default row id getter
	const getRowId = useCallback(
		(row, relativeIndex, parent) => (parent ? [parent.id, relativeIndex].join('.') : relativeIndex),
		[],
	);

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		getTableFooterProps,
		getVirtualizedTableBodyProps,
		headerGroups,
		footerGroups,
		rows,
		prepareRow,
	} = useStandardTable(
		{
			data,
			columns,
			defaultSortBy,
			searchTerm: undefined,
			onRowClick: handleRowClick,
			virtualization: {
				totalRowsCount: totalItemsCount,
				rowsHash: itemsHash,
				loadMoreRows: handleLoadMoreRows,
			},
			getRowId,
			getCustomRowStyles: () => ({
				cursor: 'default',
			}),
		},
		useClickableTableRow,
		useCustomCellStyles,
		useSortBy,
		useSortingQueryParameter,
		useScrollableTableBody,
		useVirtualizedTableBody,
		useCustomRowStyle,
	);

	return (
		<Wrapper>
			<DefaultTable
				getTableProps={getTableProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				getTableFooterProps={getTableFooterProps}
				getVirtualizedTableBodyProps={getVirtualizedTableBodyProps}
				rows={rows}
				headerGroups={headerGroups}
				footerGroups={footerGroups}
				prepareRow={prepareRow}
				isLoading={isLoading || rows.length !== totalItemsCount}
				showNoResultsFound
				customLoadingRowsCount={totalItemsCount}
			/>
		</Wrapper>
	);
};

ClientProjectListTable.propTypes = {
	client: PropTypes.shape({
		projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	}).isRequired,
};

export default ClientProjectListTable;
