import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';

import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import Filters from 'Common/components/filter/FiltersContainer';
import { useRef } from 'react';
import useDashboardListFilterData from 'Dashboard/hooks/useDashboardListFilterData';

import TeamActivitiesTab from 'Dashboard/components/TeamActivities/Tabs/TeamActivitiesTab/TeamActivitiesTab';
import TeamProjectsTab from 'Dashboard/components/TeamActivities/Tabs/TeamProjectsTab/TeamProjectsTab';
import TeamOnHoldTab from 'Dashboard/components/TeamActivities/Tabs/TeamOnHoldTab/TeamOnHoldTab';
import TabsContainer from 'Common/components/tabs';

const tabsConfig = [
	{
		label: 'Team Activities',
		PanelComponent: TeamActivitiesTab,
		forceRender: true,
	},
	{
		label: 'Team Projects',
		PanelComponent: TeamProjectsTab,
		forceRender: true,
	},
	{
		label: 'On Hold',
		PanelComponent: TeamOnHoldTab,
		forceRender: true,
	},
];

const TeamActivities = () => {
	const { t } = useTranslation();
	const currentUser = useSelector(state => state.user);
	const filterButtonRef = useRef();
	const filtersData = useDashboardListFilterData();
	const filtersRef = useRef();

	const selectedTab = useSelector(state => state.tabs.teamDashboard ?? 0);

	return (
		<>
			<Helmet>
				<title>{t('My Team Activities')}</title>
			</Helmet>
			<HeaderContainer isFixed>
				<HeaderTitle>{`${t('Welcome')}, ${currentUser?.firstName}!`}</HeaderTitle>
				<HeaderActions>
					<div ref={filterButtonRef}></div>
				</HeaderActions>
			</HeaderContainer>
			<div ref={filtersRef}>
				<Filters
					key={selectedTab}
					table={'teamActivitiesDashboard'}
					buttonRef={filterButtonRef}
					filtersData={filtersData}
				/>
			</div>
			<TabsContainer page="teamDashboard" tabsConfig={tabsConfig} />
		</>
	);
};

export default TeamActivities;
