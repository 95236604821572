import api from 'Application/api/api';

const getAllNotifications = async (signal, userEmail, pageOffset, archived = false) =>
	await api.get('/notifications', {
		signal,
		params: {
			archived,
			userEmail,
			size: 20,
			page: pageOffset - 1,
			sort: 'createdDate,DESC',
		},
	});

export default getAllNotifications;
