import { useMemo } from 'react';

const fallbackValues = {
	allowedPercentageOfRestrictedIndustries: '',
};

const useRestrictedIndustriesFormInitialValues = projectDefaults =>
	useMemo(
		() => ({
			...fallbackValues,
			allowedPercentageOfRestrictedIndustries: projectDefaults?.allowedPercentageOfRestrictedIndustries,
		}),
		[projectDefaults],
	);

export default useRestrictedIndustriesFormInitialValues;
