export const getRecentYears = () => {
	let year = new Date().getFullYear();
	const years = [];

	while (year > 1950) {
		years.push(year);
		year--;
	}

	return years;
};

export const getRecentYearOptions = () =>
	getRecentYears().map(year => ({ value: year, label: String(year) }));
