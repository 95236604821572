import { useCallback, useEffect, useState } from 'react';

const useUserOnPage = () => {
	const [userOnPage, setUserOnPage] = useState(true);

	const onFocus = useCallback(() => {
		setUserOnPage(true);
	}, []);

	const onBlur = useCallback(() => {
		setUserOnPage(false);
	}, []);

	useEffect(() => {
		window.addEventListener('focus', onFocus);
		window.addEventListener('blur', onBlur);

		onFocus();

		return () => {
			window.removeEventListener('focus', onFocus);
			window.removeEventListener('blur', onBlur);
		};
	}, [onBlur, onFocus]);

	return userOnPage;
};

export default useUserOnPage;
