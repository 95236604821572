import { useMemo } from 'react';
import { pick } from 'lodash';

const fallbackValues = {
	name: '',
	website: '',
	country: null,
	aparmentBuilding: '',
	addressLine1: '',
	addressLine2: '',
	cityRegion: '',
	postCode: '',
	firstName: '',
	lastName: '',
	phone: '',
	email: '',
	ext: '',
	comment: '',
	position: '',
};

const usePartnerFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['name', 'description']),
			partnerType: data?.partnerType
				? {
						value: data.partnerType.id,
						label: data.partnerType.name,
				  }
				: null,
			country: data?.country
				? {
						value: data.country.id,
						label: data.country.name,
				  }
				: null,
			aparmentBuilding: data?.address?.aparmentBuilding ?? '',
			addressLine1: data?.address?.addressLine1 ?? '',
			addressLine2: data?.address?.addressLine2 ?? '',
			cityRegion: data?.address?.cityRegion ?? '',
			postCode: data?.address?.postCode ?? '',
		}),
		[data],
	);

export default usePartnerFormInitialValues;
