import api from 'Application/api/api';

const getExchangeRateFromTo = async (signal, fromCurrency, toCurrency) => {
	const response = await api.get(`/invoices/exchange-rate-from-to?from=${fromCurrency}&to=${toCurrency}`, {
		signal,
	});
	return response.data;
};

export default getExchangeRateFromTo;
