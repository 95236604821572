import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import getUsersWithFullName from 'UserManagement/api/getUsersWithFullName';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';

import updateEpcPartner from 'EpcPartner/api/updateEpcPartner';
import useAccountManagersFormInitialValues from './hooks/useAccountManagersFormInitialValues';
import useAccountManagersFormValidationSchema from './hooks/useAccountManagersFormValidationSchema';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';

const AccountManagersTile = ({
	epcPartner,
	onEnterEditMode,
	onExitEditMode,
	onSave,
	onDirtyForm,
	highlightLabels,
}) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.SALES,
		roles.PM_TECHNICAL,
		roles.ASSET_MANAGER,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [managerOptions, setManagerOptions] = useState([]);
	const [selectLoader, setSelectLoader] = useState(false);

	const abortController = useAbortController();

	const initialValues = useAccountManagersFormInitialValues(epcPartner);
	const validationSchema = useAccountManagersFormValidationSchema();

	const { errors, touched, values, handleSubmit, dirty, handleBlur, resetForm, setFieldValue } = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			setIsSavingChanges(true);

			try {
				const response = await updateEpcPartner(abortController.signal, {
					...omit(epcPartner, ['tier']),
					primaryManagerEmail: values?.primaryManager?.value ?? null,
					secondaryManagerEmail: values?.secondaryManager?.value ?? null,
				});

				if (onDirtyForm) {
					onDirtyForm(false);
				}

				onSave({
					primaryManager: response?.data?.primaryManagerEmail ?? null,
					secondaryManager: response?.data?.secondaryManagerEmail ?? null,
					...response.data,
				});
				handleCancel();
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (error) {
				showToastError(error, t("Can't update EPC partner"));
				if (!axios.isCancel(error)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});
	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		(async () => {
			setSelectLoader(true);
			try {
				const response = await getUsersWithFullName(abortController.signal, {
					active: true,
					roles: [
						roles.ADMIN,
						roles.FINANCE,
						roles.ASSET_MANAGER,
						roles.ASSET_MARKETING,
						roles.MANAGEMENT,
						roles.PM_FUNDRAISING,
						roles.PM_TECHNICAL,
						roles.SALES,
					],
				});
				if (response.data.length > 0) {
					setManagerOptions(
						response.data
							.filter(
								user =>
									user.email !== values?.secondaryManager?.value && user.email !== values?.primaryManager?.value,
							)
							.map(user => ({
								value: user.email,
								label: user.fullName,
								imgUrl: user?.profilePhoto?.url,
							})),
					);
				}
				setSelectLoader(false);
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setSelectLoader(false);
				}
			}
		})();
	}, [values, abortController.signal, t]);

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Account managers')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<FormField>
				<FormikSelect
					id="primaryManager"
					name="primaryManager"
					label={t('Primary Manager')}
					value={values.primaryManager}
					error={errors.primaryManager}
					touched={touched.primaryManager}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					options={managerOptions}
					isDisabled={selectLoader}
					isLoading={selectLoader}
					isInEditMode={isInEditMode}
					userInfo={values.primaryManager}
					isTile
					isClearable
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.EPC_PARTNER_ACCOUNT_MANAGERS.PRIMARY_MANAGER,
					)}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					// ? Explicitly force rerender the select,
					// ? because isDisabled property when changed doesn't apply isClearable
					key={values.primaryManager?.label ?? ''}
					id="secondaryManager"
					name="secondaryManager"
					label={t('Secondary Manager')}
					value={values.secondaryManager}
					error={errors.secondaryManager}
					touched={touched.secondaryManager}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					options={managerOptions}
					isClearable
					isLoading={selectLoader}
					isInEditMode={isInEditMode}
					isTile
					userInfo={values.secondaryManager}
					isDisabled={Boolean(values.primaryManager) === false}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.EPC_PARTNER_ACCOUNT_MANAGERS.SECONDARY_MANAGER,
					)}
				/>
			</FormField>
		</EditableTile>
	);
};

AccountManagersTile.defaultProps = {
	epcPartner: {},
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	onSave: undefined,
	highlightLabels: [],
};

AccountManagersTile.propTypes = {
	epcPartner: PropTypes.shape({}),
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onSave: PropTypes.func,
	onDirtyForm: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default AccountManagersTile;
