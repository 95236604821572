import { useMemo, useState, useEffect } from 'react';
import Filter from 'Common/components/filter/Filter';
import { useTranslation } from 'react-i18next';
import FilterDropdown from 'Common/components/filter/FilterDropdown';
import useSearchOptions from 'Common/components/filter/hooks/useSearchOptions';
import PropTypes from 'prop-types';

const SelectFilter = ({ name, label, values, setFilterValue, options, opened, hidenOptions, ...props }) => {
	const [displayOptions, setDisplayOptions] = useState([]);
	const handleSetFieldValue = newValue => {
		if (values.includes(newValue)) {
			const oldValueIndex = values.indexOf(newValue);
			setFilterValue({
				[name]: [...values.slice(0, oldValueIndex), ...values.slice(oldValueIndex + 1)],
			});
		} else {
			setFilterValue({ [name]: [...values, newValue] });
		}
	};

	const { t } = useTranslation();

	const { searchValue, setSearchValue, filteredOptions } = useSearchOptions(options, true);

	const handleClearOptions = e => {
		e.preventDefault();
		setFilterValue({ [name]: [] });
	};

	useEffect(() => {
		if (setDisplayOptions) {
			const filteredOptions = options?.filter(
				option => values?.includes(String(option.value)) && !hidenOptions?.includes(String(option.value)),
			);
			setDisplayOptions(filteredOptions);
		}
	}, [options, values, hidenOptions]);

	const selectedOptionsCount = useMemo(() => values?.length, [values]);

	return (
		<Filter
			label={label}
			count={selectedOptionsCount}
			handleClearOptions={handleClearOptions}
			opened={opened}
			{...props}
			popupContent={
				<FilterDropdown
					selectedOptions={values}
					options={filteredOptions}
					handleOptionClick={handleSetFieldValue}
					handleBottomBtnClick={handleClearOptions}
					bottomBtnLabel={t('Clear all')}
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					displayOptions={displayOptions}
					hasFocus={opened}
				/>
			}
		/>
	);
};

SelectFilter.defaultProps = {
	values: [],
	opened: false,
	hidenOptions: [],
};

SelectFilter.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	values: PropTypes.arrayOf(PropTypes.string),
	setFilterValue: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		}),
	).isRequired,
	opened: PropTypes.bool,
	hidenOptions: PropTypes.arrayOf(PropTypes.string),
};

export default SelectFilter;
