import { stagesLabels } from 'Projects/constants/stages';

const setStages = () =>
	stagesLabels.map((item, i) => {
		const splitted = item.split('_');
		let label = splitted.join(' ');

		if (splitted[0] === 'CLOSED') {
			label = label.replace(/ /, ' - ');
		}

		return {
			label: label.toLowerCase(),
			stage: item,
			index: i,
			activities: [],
		};
	});

export default setStages;
