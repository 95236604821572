import getMyTeamOnHoldProjects from 'Dashboard/api/getMyTeamOnHoldProjects';
import DashboardTab from 'Dashboard/components/DashboardTab';
import useTeamOnHoldTabStepModifier from 'Dashboard/components/TeamActivities/Tabs/TeamOnHoldTab/hooks/useTeamOnHoldTabStepModifier';

const TeamOnHoldTab = () => (
	<DashboardTab
		helpText="Projects assigned to your team which are on hold.  To work on a Project, open it and change its status to ‘In progress’ on the General tab."
		getterFunction={getMyTeamOnHoldProjects}
		stepModifier={useTeamOnHoldTabStepModifier}
	/>
);

export default TeamOnHoldTab;
