import { store } from 'Application/store';
import { push } from 'redux-first-history';
import { authenticateUser } from 'User/reduxUser';
import loginRequest from 'User/api/login';
import getCurrentUser from 'User/api/getCurrentUser';

const login = async (signal, email, password, redirect = true) => {
	await loginRequest(signal, email, password);
	const { data } = await getCurrentUser(signal);

	const userData = {
		email: data.email,
		firstName: data.firstName,
		lastName: data.lastName,
		title: data.title,
		phone: data.phone,
		roles: data.roles.map(role => role.name),
		roleGroups: [...new Set(data.roles.map(role => role.roleGroup))],
		profilePhoto: data.profilePhoto,
	};

	store.dispatch(authenticateUser(userData));

	if (redirect) {
		store.dispatch(push('/impact'));
	} else {
		if (store.getState().common.isListPage) {
			window.location.reload();
		}
	}
};

export default login;
