import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';

import AddressTile from 'EpcPartner/components/EpcPartnerPortal/EpcPartnerProfile/Tabs/GeneralTab/Tiles/AddressTile/AddressTile';
import EpcPartnerDetailsTile from 'EpcPartner/components/EpcPartnerPortal/EpcPartnerProfile/Tabs/GeneralTab/Tiles/EpcPartnerDetailsTile/EpcPartnerDetailsTile';
import { EPC_PARTNER_PROFILE_DETAILS_PAGE_TAB_TILES } from 'EpcPartner/constants/epcPartnerTabForms';

const GeneralTab = ({ epcPartner, onEnterEditMode, onExitEditMode, onDataChange, onDirtyForm }) => {
	const handleEpcPartnerDetailsDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(EPC_PARTNER_PROFILE_DETAILS_PAGE_TAB_TILES.GENERAL.DETAILS, isDirty);
		},
		[onDirtyForm],
	);

	const handleAddressDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(EPC_PARTNER_PROFILE_DETAILS_PAGE_TAB_TILES.GENERAL.ADDRESS, isDirty);
		},
		[onDirtyForm],
	);

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
				<EpcPartnerDetailsTile
					epcPartner={epcPartner}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={onDataChange}
					onDirtyForm={handleEpcPartnerDetailsDirtyForm}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
				<AddressTile
					epcPartner={epcPartner}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={onDataChange}
					onDirtyForm={handleAddressDirtyForm}
				/>
			</Grid>
		</TilesGridWrapper>
	);
};

GeneralTab.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
};

GeneralTab.propTypes = {
	epcPartner: PropTypes.shape({}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDataChange: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
};

export default GeneralTab;
