import getLanguages from 'Preferences/api/getLanguages';

const loadLanguageOptions = async (signal, query) => {
	const response = await getLanguages(signal, query);
	const languages = response.data;

	return languages.map(language => ({
		value: language.isoCode,
		label: language.isoCode,
		fullName: language.name,
	}));
};

export default loadLanguageOptions;
