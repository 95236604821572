import colors from 'Application/theme/colors';
import styled from 'styled-components/macro';
import BasePill from './BasePill';

const ActivePill = styled(BasePill)`
	@keyframes fadeIn {
		0% {
			background-color: ${colors.common.lightBeige};
			transform: scaleY(0) translateX(100%);
			min-height: 0px;
			max-height: 0px;
		}

		8% {
			background-color: ${colors.common.lightBeige};
			transform: scaleY(1) translateX(0);
			min-height: 44px;
			max-height: 44px;
		}

		100% {
			background-color: ${colors.grey.lighter};
		}
	}

	position: relative;
	background-color: ${colors.grey.lighter};
	transform-origin: top;
	animation: ${({ $isNewlyAdded }) => ($isNewlyAdded ? 'fadeIn 5s linear forwards' : 'none')};
	z-index: ${({ $statusDropdownVisible }) => ($statusDropdownVisible ? 3 : 1)};

	.fade-section {
		@keyframes fadeInner {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}
		animation: ${({ $isNewlyAdded }) => ($isNewlyAdded ? 'fadeInner 0.8s linear forwards' : 'none')};
	}
`;

export default ActivePill;
