import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import colors from 'Application/theme/colors';
import { css } from 'styled-components';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${colors.primary.main};
	border-radius: 50%;
	cursor: ${({ isNormalCursor }) => (isNormalCursor ? 'default' : 'pointer')};
`;

const Text = styled.div`
	color: #fff;
	text-align: center;
	font-size: 14px;
	font-weight: 600;

	${({ textColor }) =>
		textColor &&
		css`
			color: ${({ textColor }) => textColor};
		`}
`;

const UserInitialsCircle = ({
	firstName,
	lastName,
	isSidebarIcon,
	color,
	textColor,
	useTooltip,
	isNormalCursor,
	title,
	...props
}) => {
	const getUserInitials = () => `${firstName.substr(0, 1)}${lastName.substr(0, 1)}`;

	if (useTooltip) {
		return (
			<HoverTooltip
				placement={isSidebarIcon ? 'right' : 'top'}
				title={firstName && lastName ? `${firstName} ${lastName}` : title}
				arrow
			>
				<Wrapper
					{...props}
					style={{
						backgroundColor: color,
					}}
					isNormalCursor={isNormalCursor}
				>
					<Text className="user-initials" textColor={textColor}>
						{getUserInitials()}
					</Text>
				</Wrapper>
			</HoverTooltip>
		);
	}

	return (
		<Wrapper
			{...props}
			style={{
				backgroundColor: color,
			}}
			isNormalCursor={isNormalCursor}
		>
			<Text className="user-initials" textColor={textColor}>
				{getUserInitials()}
			</Text>
		</Wrapper>
	);
};

UserInitialsCircle.defaultProps = {
	firstName: '',
	lastName: '',
	title: '',
	isSidebarIcon: false,
	color: undefined,
	textColor: undefined,
	useTooltip: false,
	isNormalCursor: true,
};

UserInitialsCircle.propTypes = {
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	title: PropTypes.string,
	isSidebarIcon: PropTypes.bool,
	color: PropTypes.string,
	textColor: PropTypes.string,
	useTooltip: PropTypes.bool,
	isNormalCursor: PropTypes.bool,
};

export default UserInitialsCircle;
