import { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getProjectById from 'Projects/api/getProjectById';
import deleteProject from 'Projects/api/deleteProject';
import { useParams } from 'react-router';
import Loader from 'Common/components/Loader';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from 'Common/components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'Common/components/breadcrumbs/Breadcrumb';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import isAuthorized from 'User/utils/isAuthorized';
import { roles } from 'User/constants/roles';
import { push } from 'redux-first-history';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import SharePointIcon from 'Common/components/icons/SharepointIcon';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import SharePointSettingsModal from 'Projects/components/ProjectDetails/SharePointSettingsModal/SharePointSettingsModal';
import { clearProjectData, setProjectData, updateCurrentProject } from 'Projects/reduxProject';
import colors from 'Application/theme/colors';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import BreadcrumbTitle from 'Common/components/breadcrumbs/BreadcrumbTitle';
import getDriveFolderUrl from 'Projects/api/getDriveFolderUrl';
import ProjectInfo from 'Projects/components/shared/ProjectInfo';
import Button from 'Common/components/buttons/Button';
import { updateSidePanelData } from 'Application/reducers/reduxSidePanel';
import { css } from 'styled-components';
import useReponsive from 'Common/hooks/useResponsive';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import TabsContainer from 'Common/components/tabs';
import useProjectsTabsConfig from 'Projects/hooks/useProjectsTabsConfig';
import { SIDE_PANEL_CONTENT_TYPES } from 'Common/components/SidePanel/constants/sidePanelContentTypes';
import useUniqueAbortSignal from 'Common/hooks/useUniqueAbortSignal';

const StyledBreadcrumbs = styled(Breadcrumbs)`
	flex: 1;
	line-height: 1.8em;
	max-width: 950px;
	overflow: hidden;
	flex-grow: 10;

	${({ $isMobile }) =>
		$isMobile &&
		css`
			max-width: calc(100vw - 110px);

			* {
				font-size: 16px;
				white-space: nowrap;
			}
		`}
`;

const DeleteButtonWrapper = styled.div`
	margin-right: 20px;
`;

const ContentWrapper = styled.div`
	background: ${colors.grey.lightest};
`;

const SharePointLoader = styled(Loader)`
	width: fit-content !important;
	height: fit-content !important;
`;

const SharepointIconWrapper = styled.div`
	display: flex;
	width: 32px;
	margin-left: 8px;

	cursor: pointer;

	svg {
		height: 32px;
		width: 32px;
	}
`;

const StyledBreadcrumbTitle = styled(BreadcrumbTitle)`
	display: flex;
	flex-direction: row;
`;

const ProjectDetails = () => {
	const { isMobile } = useReponsive();
	const project = useSelector(state => state.currentProject);
	const [isLoadingProject, setIsLoadingProject] = useState(true);
	const [deletionError, setDeletionError] = useState(null);
	const { t } = useTranslation();
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const comingFromLocation = useQueryParameter('comingFrom');
	const dispatch = useDispatch();
	const [isSharePointModalOpened, setIsSharePointModalOpened] = useState(false);
	const [isSharePointUrlLoading, setIsSharePointUrlLoading] = useState(false);
	const sidePanelData = useSelector(state => state.sidePanel);
	const isOpenSidepanel = sidePanelData.isOpen && sidePanelData.type === 'projectComments';
	const hasProjectData = useMemo(() => Boolean(project) && Object.keys(project ?? {}).length > 0, [project]);

	const { projectId } = useParams();

	const abortController = useAbortController();
	const getUniqueSignal = useUniqueAbortSignal();

	const refetchProject = useCallback(async () => {
		try {
			const response = await getProjectById(getUniqueSignal('project-details'), projectId, true);
			dispatch(setProjectData(response.data));
			return response.data;
		} catch (error) {
			showToastError(error);
			dispatch(clearProjectData());
		}
	}, [projectId, dispatch, getUniqueSignal]);

	const handleCommentBtnClick = async () => {
		try {
			if (!isOpenSidepanel) {
				dispatch(
					updateSidePanelData({
						isOpen: true,
						type: SIDE_PANEL_CONTENT_TYPES.PROJECT_COMMENTS,
						isLoading: true,
					}),
				);

				const projectData = await getProjectById(abortController.signal, project.id, true).then(res => res.data);

				dispatch(
					updateSidePanelData({
						isOpen: true,
						type: SIDE_PANEL_CONTENT_TYPES.PROJECT_COMMENTS,
						isLoading: false,
						contentData: { project: projectData, showHistory: false },
						headerData: {
							to: '/projects',
							isBreadcrumb: true,
							breadCrumb: 'Projects',
							activeBreadcrumb: `${projectData.externalId} - ${
								projectData?.client?.name ? projectData?.client?.name : t('Unknown client')
							} ${projectData?.description ? ` (${projectData?.description})` : ''}`,
							toActiveBreadcrumb: `/projects/details/${project.id}`,
						},
					}),
				);
			}
		} catch (err) {
			showToastError(err);
		}
	};

	const handleDeleteProjectSubmit = async () => {
		try {
			await deleteProject(abortController.signal, projectId);
			notify(t('Project deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});
			dispatch(push('/projects'));
			setShowConfirmModal(false);
		} catch (error) {
			if (!axios.isCancel(error)) {
				setDeletionError(error.response.data);
				setShowConfirmModal(false);
			}
		}
	};

	const handleSharePointLinkClick = async () => {
		if (!isSharePointUrlLoading) {
			setIsSharePointUrlLoading(true);
			try {
				const { data } = await getDriveFolderUrl(abortController.signal, project.id);
				window.open(data.webUrl, '_blank');
				setIsSharePointUrlLoading(false);
			} catch (error) {
				if (Boolean(project.driveFolderUrl) === true) {
					showToastError(error, t('Folder not found'));
				}
				if (!axios.isCancel(error)) {
					setIsSharePointModalOpened(true);
					setIsSharePointUrlLoading(false);
				}
			}
		}
	};

	const handleUpdateProject = async newProjectData => {
		if (newProjectData) {
			dispatch(updateCurrentProject(newProjectData));
			return newProjectData;
		}
		return refetchProject();
	};

	useEffect(() => {
		setIsLoadingProject(true);

		(async () => {
			try {
				const response = await getProjectById(abortController.signal, projectId, true);
				dispatch(setProjectData(response.data));
				setIsLoadingProject(false);
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					dispatch(clearProjectData());
					setIsLoadingProject(false);
				}
			}
		})();
	}, [projectId, dispatch, abortController.signal]);

	const tabsConfig = useProjectsTabsConfig(project, handleUpdateProject);

	return isLoadingProject ? (
		<LoaderWrapper $isFullPage>
			<Loader />
		</LoaderWrapper>
	) : (
		hasProjectData && (
			<>
				{/* ? id used in progress tab to calculate fixed title offset for mobile */}
				<HeaderContainer id="project-header-container">
					<StyledBreadcrumbs $isMobile={isMobile}>
						<Breadcrumb to={`/${comingFromLocation || 'projects'}`}>
							{t(comingFromLocation) || t('Projects')}
						</Breadcrumb>
						<Breadcrumb active>
							<StyledBreadcrumbTitle>
								{`${project.externalId} - `}
								{project?.client?.name ? project?.client?.name : t('Unknown client')}
								{`${project?.description ? ` (${project?.description})` : ''}`}
							</StyledBreadcrumbTitle>
						</Breadcrumb>
						{!isMobile && (
							<SharepointIconWrapper onClick={handleSharePointLinkClick}>
								{isSharePointUrlLoading ? (
									<SharePointLoader />
								) : (
									<SharePointIcon isActive={Boolean(project.driveFolderUrl)} />
								)}
							</SharepointIconWrapper>
						)}
					</StyledBreadcrumbs>
					<HeaderActions>
						<Button medium onClick={handleCommentBtnClick} icon="forum" label="Project Details - Comments Button" />
						{isAuthorized([roles.ADMIN, roles.FINANCE, roles.MANAGEMENT]) && (
							<DeleteButtonWrapper>
								<MoreActionsButton label="Projects Details More Actions">
									<MenuItem
										onClick={() => setIsSharePointModalOpened(true)}
										type="button"
										data-action="openSharepointFolder"
										label="Projects Details - SharePoint Folder Menu Item"
									>
										{t('SharePoint folder')}
									</MenuItem>
									<MenuItem
										onClick={() => setShowConfirmModal(true)}
										type="button"
										data-action="delete"
										disabled={!isAuthorized([roles.ADMIN, roles.FINANCE, roles.MANAGEMENT])}
										label="Projects Details - Delete Menu Item"
									>
										{t('Delete')}
									</MenuItem>
								</MoreActionsButton>
							</DeleteButtonWrapper>
						)}
					</HeaderActions>
				</HeaderContainer>
				<ContentWrapper>
					<ProjectInfo data={project} handleUpdateProject={handleUpdateProject} />
					<TabsContainer page="project" tabsConfig={tabsConfig} />

					<ConfirmModal
						isOpen={showConfirmModal}
						onConfirm={() => handleDeleteProjectSubmit()}
						onCancel={() => setShowConfirmModal(false)}
						confirmText={t('Delete')}
						label="delete-project"
						heading={t('Delete Project')}
						text={t(`Are you sure you want to delete Project ${project.externalId}?`)}
					/>
					<UnsuccessfulOperationModal
						label="cannot-delete-project"
						isOpen={deletionError !== null}
						onCancel={() => setDeletionError(null)}
						heading={t('Cannot delete Project')}
						text={deletionError?.violations?.[0]?.message}
					/>

					<SharePointSettingsModal
						isOpen={isSharePointModalOpened}
						onClose={() => setIsSharePointModalOpened(false)}
						project={project}
						label="sharepoint"
					/>
				</ContentWrapper>
			</>
		)
	);
};

export default ProjectDetails;
