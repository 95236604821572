import { useCallback, useEffect, useMemo, useState } from 'react';
import getPartnerById from 'Partners/api/getPartnerById';
import deletePartner from 'Partners/api/deletePartner';
import { useParams } from 'react-router';
import Loader from 'Common/components/Loader';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from 'Common/components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'Common/components/breadcrumbs/Breadcrumb';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import isAuthorized from 'User/utils/isAuthorized';
import { roles } from 'User/constants/roles';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import colors from 'Application/theme/colors';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { PARTNER_DETAILS_PAGE_TILES_BY_TAB } from 'Partners/constants/partnerTabForms';
import DetailsTab from 'Partners/components/PartnerDetails/Tabs/DetailsTab/DetailsTab';
import ContactsTab from 'Partners/components/PartnerDetails/Tabs/ContactsTab/ContactsTab';
import TabsContainer from 'Common/components/tabs';

const StyledBreadcrumbs = styled(Breadcrumbs)`
	min-width: 300px;
`;

const ContentWrapper = styled.div`
	background: ${colors.grey.lightest};
`;

const StyledHeaderTitle = styled(HeaderTitle)`
	line-height: 34px;
	text-transform: capitalize;
`;

const PartnerDetails = () => {
	const [partner, setPartner] = useState(null);
	const [isLoadingPartner, setIsLoadingPartner] = useState(true);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [deletionError, setDeletionError] = useState(null);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { partnerId } = useParams();

	const abortController = useAbortController();

	const fetchPartner = useCallback(async () => {
		try {
			const response = await getPartnerById(abortController.signal, partnerId, true);
			setPartner(response.data);
			setIsLoadingPartner(false);
		} catch (error) {
			showToastError(error, t("Can't get partner data"));
			if (!axios.isCancel(error)) {
				setPartner(null);
				setIsLoadingPartner(false);
			}
		}
	}, [abortController.signal, partnerId, t]);

	useEffect(() => {
		setIsLoadingPartner(true);

		fetchPartner();
	}, [fetchPartner]);

	const setEditedPartner = useCallback(partner => {
		setPartner(partner);
	}, []);

	const handlePartnerDataChange = useCallback(
		changes => {
			if (changes) {
				setPartner(prevPartner => ({
					...prevPartner,
					...changes,
				}));
				return;
			}
			fetchPartner();
		},
		[fetchPartner],
	);

	const handleDeletePartnerSubmit = async () => {
		try {
			await deletePartner(abortController.signal, partnerId);
			notify(t('Partner deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});
			dispatch(push('/partners'));
			setShowConfirmModal(false);
		} catch (error) {
			if (!axios.isCancel(error)) {
				setDeletionError(error.response.data);
				setShowConfirmModal(false);
			}
		}
	};

	const tabsConfig = useMemo(
		() => [
			{
				label: 'General',
				PanelComponent: DetailsTab,
				panelProps: {
					partner,
					onDataChange: handlePartnerDataChange,
					setEditedPartner,
				},
				tabForms: PARTNER_DETAILS_PAGE_TILES_BY_TAB.GENERAL,
				authorizedRoles: [roles.ADMIN],
				forceRender: true,
			},
			{
				label: 'Contacts',
				PanelComponent: ContactsTab,
				panelProps: {
					partner,
				},
				tabForms: PARTNER_DETAILS_PAGE_TILES_BY_TAB.CONTACTS,
			},
		],
		[partner, handlePartnerDataChange, setEditedPartner],
	);

	return (
		<>
			{isLoadingPartner || partner === null ? (
				<LoaderWrapper $isFullPage>
					<Loader />
				</LoaderWrapper>
			) : (
				<>
					<HeaderContainer>
						<StyledBreadcrumbs>
							<Breadcrumb to="/partners">{t('Partners')}</Breadcrumb>
							<Breadcrumb active>
								<StyledHeaderTitle>{partner.name}</StyledHeaderTitle>
							</Breadcrumb>
						</StyledBreadcrumbs>
						{isAuthorized([
							roles.ADMIN,
							roles.FINANCE,
							roles.MANAGEMENT,
							roles.SALES,
							roles.PM_TECHNICAL,
							roles.ASSET_MANAGER,
						]) && (
							<HeaderActions>
								<MoreActionsButton label="Partner Details More Actions">
									<MenuItem onClick={() => setShowConfirmModal(true)} label="Partner Details - Delete Menu Item">
										{t('Delete')}
									</MenuItem>
								</MoreActionsButton>
							</HeaderActions>
						)}
					</HeaderContainer>
					<ContentWrapper>
						<TabsContainer page="partners" tabsConfig={tabsConfig} />
						<ConfirmModal
							isOpen={showConfirmModal}
							onConfirm={() => handleDeletePartnerSubmit()}
							onCancel={() => setShowConfirmModal(false)}
							label="delete-partner"
							heading={t('Delete Partner')}
							text={t(`Are you sure you want to delete Partner ${partner.name}?`)}
						/>
						<UnsuccessfulOperationModal
							label="cannot-delete-partner"
							isOpen={deletionError !== null}
							onCancel={() => setDeletionError(null)}
							heading={t('Cannot delete Partner')}
							text={deletionError?.violations?.[0]?.message}
						/>
					</ContentWrapper>
				</>
			)}
		</>
	);
};

export default PartnerDetails;
