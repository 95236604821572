import { useEffect } from 'react';

const useSmartResize = (ref, forceUpdate) => {
	useEffect(() => {
		if (ref.current) {
			let prevHeight = ref.current.getBoundingClientRect().height;
			let prevWidth = ref.current.getBoundingClientRect().width;
			const observer = new ResizeObserver(entries => {
				entries.forEach(entry => {
					requestAnimationFrame(() => {
						const { height, width } = entry.target.getBoundingClientRect();
						if (height === prevHeight && width === prevWidth) {
							forceUpdate(prev => prev + 1);
						} else {
							prevHeight = height;
							prevWidth = width;
						}
					});
				});
			});
			observer.observe(ref.current);
		}
	}, [ref, forceUpdate]);
};

export default useSmartResize;
