// TODO remove the eslint complexity and fix it
/* eslint-disable complexity */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { css } from 'styled-components';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import sizes from 'Application/theme/sizes';
import colors from 'Application/theme/colors';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import getProjectDefaults from 'Preferences/api/getProjectDefaults';

import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';

import UNITS from 'Common/constants/units';
import EditableTile from 'Common/components/Tile/EditableTile';
import formatNumber from 'Common/utils/formatNumber';
import formatDecimal from 'Common/utils/formatDecimal';
import FormField from 'Common/components/form/FormField';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import FormikInput from 'Common/components/form/FormikInput';
import FormikRadio from 'Common/components/form/FormikRadio';
import TextButton from 'Common/components/buttons/TextButton';
import FormikSelect from 'Common/components/form/FormikSelect';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import FormikTextArea from 'Common/components/form/FormikTextArea';
import ConditionalGridSection from 'Common/components/ConditionalGridSection';

import manufacturedProductTypes from 'Manufacturer/constants/manufacturedProductTypes';

import editProject from 'Projects/api/editProject';
import getProjectById from 'Projects/api/getProjectById';
import projectTypes from 'Projects/constants/projectTypes';
import loadRoofTypeOptions from 'Projects/utils/loadRoofTypeOptions';
import checkExchangeRateDate from 'Projects/utils/checkExchangeRateDate';
import loadProjectTypeOptions from 'Projects/utils/loadProjectTypeOptions';
import assignMountingTypesToProject from 'Projects/api/assignMountingTypesToProject';
import convertFormValuesToRequestData from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/ProjectDetailsTile/utils/convertFormValuesToRequestData';
import useProjectDetailsFormInitialValues from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/ProjectDetailsTile/hooks/useProjectDetailsFormInitialValues';
import useProjectDetailsFormValidationSchema from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/ProjectDetailsTile/hooks/useProjectDetailsFormValidationSchema';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import useProductTypesOptions from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/ProjectDetailsTile/hooks/useProductTypesOptions';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';
import { mountingTypeOptions } from 'Projects/constants/mountingTypeOptions';
import ManufacturerOverlay from 'Manufacturer/components/Overlay/ManufacturerOverlay';
import crudModes from 'Common/constants/crudModes';

// import useElevator from 'Common/hooks/useElevator';

const EditLink = styled(TextButton)`
	padding: 8px 0;
	&:focus {
		outline: 2px solid ${colors.primary.main};
	}
`;

const Title = styled.div`
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 10px;
	color: ${colors.text.greyDark};
`;

const StyledFormField = styled(FormField)`
	max-width: 400px;
`;

const sidePanelOverides = ({ $isSidePanel }) =>
	$isSidePanel &&
	css`
		max-width: 100% !important;
		width: 100% !important;
		flex-basis: 100% !important;
	`;

const StyledGrid = styled(Grid)`
	padding-right: 32px;
	height: fit-content;
	position: relative;

	${sidePanelOverides}
`;

const StyledConditionalGridSection = styled(ConditionalGridSection)`
	padding-right: 32px;

	${sidePanelOverides}
`;

const TariffSection = styled.div`
	padding-bottom: ${sizes.spacing(3)};
`;

const isValid = val => !Number.isNaN(Number(val)) || Boolean(val);

const ProjectDetailsTile = ({
	project,
	onEnterEditMode,
	onExitEditMode,
	onDirtyForm,
	isSidePanel,
	onTileSave,
	highlightLabels,
}) => {
	const { t } = useTranslation();
	const elevatorContainerRef = useRef();

	// useElevator(elevatorContainerRef);

	const projectCurrencies = useSelector(state => state.currentProject.currencies);

	const isAuthorizedToEdit = isAuthorized([
		roles.FINANCE,
		roles.PM_TECHNICAL,
		roles.SALES,
		roles.ASSET_MANAGER,
		roles.ADMIN,
		roles.MANAGEMENT,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const [isCreateManufacturerModalOpened, setIsCreateManufacturerModalOpened] = useState(false);

	const [productTypeForNewManufacturer, setProductTypeForNewManufacturer] = useState(null);

	const productTypesOptionsByManufacturer = useProductTypesOptions();

	const handleCreateManufacturerButtonClick = forProductType => {
		setIsCreateManufacturerModalOpened(true);
		setProductTypeForNewManufacturer(forProductType);
	};

	const [editedManufacturer, setEditedManufacturer] = useState(null);
	const [projectDefaults, setProjectDefaults] = useState({
		lifetime: null,
		pvDegradationRate: null,
		euroPerKwp: null,
		lastModifiedDate: null,
		lastModifiedBy: null,
	});

	const abortController = useAbortController();

	const initialValues = useProjectDetailsFormInitialValues(project, isInEditMode, projectDefaults);
	const validationSchema = useProjectDetailsFormValidationSchema();

	const { errors, touched, values, handleSubmit, dirty, handleChange, handleBlur, setFieldValue, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			enableReinitialize: true,
			onSubmit: async values => {
				setIsSavingChanges(true);
				try {
					await editProject(abortController.signal, {
						...project,
						clientId: project.client?.id,
						regionalHubId: project.regionalHub?.id || null,
						...(await convertFormValuesToRequestData(abortController.signal, values)),
					});

					await assignMountingTypesToProject(
						abortController.signal,
						project.id,
						values.projectType.label === projectTypes.PV && values.mountingTypes
							? values.mountingTypes.map(type => ({ mountingType: type.value }))
							: [],
					);

					const response = await getProjectById(abortController.signal, project.id, true);

					const selectedCurrency = values?.currency?.value;

					if (selectedCurrency !== 'EUR') {
						await checkExchangeRateDate(
							abortController.signal,
							project.id,
							selectedCurrency,
							projectCurrencies.find(currency => currency.isoCode === selectedCurrency)?.exchangeRateDate,
						);
					}

					if (onDirtyForm) {
						onDirtyForm(false);
					}

					const predictedAnnualEnergySavings = response.data.predictedAnnualEnergySavings;
					// Re-set all fields and mark them as not touched
					resetForm({ values, predictedAnnualEnergySavings });
					setFieldValue('predictedAnnualEnergySavings', predictedAnnualEnergySavings);

					await onTileSave({
						...response.data,
						systemSizeKwp: response.data.systemSizeKwp || '',
					});
					handleCancel();
					setIsSavingChanges(false);
				} catch (error) {
					showToastError(error);
					if (!axios.isCancel(error)) {
						handleCancel();
						setIsSavingChanges(false);
					}
				}
			},
		});

	// Update the "systemSizeKwp" and "kwhToKwpRel" as they may have been changed
	// automatically if you had the form opened and edited the offers at the same time.
	useEffect(() => {
		resetForm({
			values: { ...initialValues, systemSizeKwp: project.systemSizeKwp },
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project.systemSizeKwp]);

	useEffect(() => {
		resetForm({
			values: { ...initialValues, kwhToKwpRel: project.kwhToKwpRel },
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [project.kwhToKwpRel]);

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	useEffect(() => {
		(async () => {
			try {
				const response = await getProjectDefaults(abortController.signal);

				setProjectDefaults(response.data);
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [abortController.signal]);

	const handleCreateManufacturerFormSubmit = manufacturer => {
		if (productTypeForNewManufacturer) {
			setFieldValue(productTypeForNewManufacturer, {
				value: manufacturer.name,
				label: manufacturer.displayName,
			});
		}
	};

	const projectType = values.projectType?.label;
	const isOnGrid = values.grid && values.grid === 'on-grid';
	const hasGensets = !!values.gensets;
	const hasEnergyStorage = !!values.energyStorage;
	const hasRoofMountings =
		values.mountingTypes &&
		values.mountingTypes.length > 0 &&
		values.mountingTypes.filter(
			mountingType => mountingType.label === 'Roof' || mountingType.label === 'Carpark',
		).length > 0;

	const CurrencySign = values.currency
		? () => (values.currency.value === 'EUR' ? '€' : values.currency.value)
		: null;

	const isMountingInfoVisible = projectType === projectTypes.PV;
	const isBatteryInfoVisible = projectType === projectTypes.PV && hasEnergyStorage;

	const isGensetsInfoVisible = projectType === projectTypes.PV && hasGensets;

	const projectTypeName = project.projectType?.name ?? null;

	const isEnergyStorageVisible = projectType === projectTypes.PV;

	const areGensetsVisible = projectTypeName === projectTypes.PV;

	const handleEditButtonClick = () => {
		setIsInEditMode(true);
		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({
			values: initialValues,
		});
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleOnDivClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	const generateManufacturerField = useCallback(
		({ manufacturer, id, label, highlightId }) => {
			return (
				<StyledFormField>
					<FormikSelect
						id={id}
						name={manufacturer}
						label={label}
						value={values[manufacturer]}
						error={errors[manufacturer]}
						touched={touched[manufacturer]}
						setFieldValue={setFieldValue}
						options={productTypesOptionsByManufacturer[manufacturer]}
						isClearable
						createLinkTitle="Create Manufacturer"
						onCreateClick={() => handleCreateManufacturerButtonClick(manufacturer)}
						isTile
						isInEditMode={isInEditMode}
						isHighlighted={highlightLabels.includes(highlightId)}
						data-elevator
					/>
					{isAuthorized([roles.PM_TECHNICAL, roles.SALES, roles.ASSET_MANAGER, roles.ADMIN]) &&
						values[manufacturer] &&
						isInEditMode && (
							<EditLink
								type="button"
								onClick={() => {
									setEditedManufacturer(values[manufacturer].value);
								}}
								text={t('Edit Manufacturer')}
								label="Projects Details Project Edit Manufaturer Button"
							/>
						)}
				</StyledFormField>
			);
		},
		[
			errors,
			highlightLabels,
			isInEditMode,
			productTypesOptionsByManufacturer,
			setFieldValue,
			t,
			touched,
			values,
		],
	);

	return (
		<EditableTile
			title={t('Project details')}
			onClick={handleOnDivClick}
			isInEditMode={isInEditMode}
			isLoading={isSavingChanges}
			onCancel={handleCancel}
			onSubmit={handleSubmit}
		>
			<Grid container ref={elevatorContainerRef}>
				<StyledGrid item xs={12} lg={6} xl={3} $isSidePanel={isSidePanel} data-elevator>
					<Title>{t('Type')}</Title>
					<StyledFormField>
						<FormikSelect
							isAsync
							id="projectType"
							name="projectType"
							label={t('Project type')}
							value={values.projectType}
							error={errors.projectType}
							touched={touched.projectType}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadProjectTypeOptions}
							isTile
							isInEditMode={isInEditMode}
							isRequired
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.PROJECT_TYPE,
							)}
						/>
					</StyledFormField>
					<ConditionalGridSection hasFlag={projectType === projectTypes.PV}>
						<StyledFormField>
							<FormikRadio
								id="grid"
								name="grid"
								value={values.grid}
								error={errors.grid}
								touched={touched.grid}
								onChange={handleChange}
								options={[
									{
										value: 'on-grid',
										label: 'On-grid',
									},
									{
										value: 'off-grid',
										label: 'Off-grid',
									},
								]}
								isRow
								isTile
								isInEditMode={isInEditMode}
							/>
						</StyledFormField>
					</ConditionalGridSection>

					<ConditionalGridSection hasFlag={isEnergyStorageVisible}>
						<StyledFormField>
							<FormikCheckbox
								id="energyStorage"
								name="energyStorage"
								label={t('Energy storage')}
								checked={values.energyStorage}
								error={errors.energyStorage}
								touched={touched.energyStorage}
								onChange={handleChange}
								onBlur={handleBlur}
								isTile
								isInEditMode={isInEditMode}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.ENERGY_STORAGE,
								)}
							/>
						</StyledFormField>
					</ConditionalGridSection>

					<ConditionalGridSection hasFlag={areGensetsVisible}>
						<StyledFormField>
							<FormikCheckbox
								id="gensets"
								name="gensets"
								label={t('Gensets')}
								checked={values.gensets}
								error={errors.gensets}
								touched={touched.gensets}
								onChange={handleChange}
								onBlur={handleBlur}
								isTile
								isInEditMode={isInEditMode}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.GENSETS,
								)}
							/>
						</StyledFormField>
					</ConditionalGridSection>

					<ConditionalGridSection hasFlag={projectType === projectTypes.PV && isOnGrid}>
						<Title>{t('Connection')}</Title>
						<StyledFormField>
							<FormikInput
								id="meterQuantity"
								name="meterQuantity"
								label={t('Meter quantity')}
								value={values.meterQuantity}
								error={errors.meterQuantity}
								touched={touched.meterQuantity}
								onChange={handleChange}
								onBlur={handleBlur}
								isTile
								isInEditMode={isInEditMode}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.METER_QUANTITY,
								)}
							/>
						</StyledFormField>
						<StyledFormField>
							<FormikTextArea
								id="connectionPoints"
								name="connectionPoints"
								label={t('Connection points')}
								value={values.connectionPoints}
								error={errors.connectionPoints}
								touched={touched.connectionPoints}
								onChange={handleChange}
								onBlur={handleBlur}
								tooltip={{
									tooltip: 'The total number of connection points and distance to each.',
								}}
								isTile
								isInEditMode={isInEditMode}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.CONNECTION_POINTS,
								)}
							/>
						</StyledFormField>
					</ConditionalGridSection>
				</StyledGrid>
				<StyledGrid item xs={12} lg={6} xl={3} $isSidePanel={isSidePanel} data-elevator>
					<Title>{t('Size')}</Title>
					<StyledFormField>
						<HoverTooltip
							placement="top-start"
							arrowplacement="start"
							title={
								project?.epcOffers && project?.epcOffers.length > 0
									? 'System size is managed on the Sales tab because an Offer exists for this Project'
									: ''
							}
						>
							<div>
								<FormikInput
									id="systemSizeKwp"
									name="systemSizeKwp"
									label={t('System size')}
									value={
										isValid(values.systemSizeKwp)
											? isInEditMode
												? values.systemSizeKwp
												: formatNumber(values.systemSizeKwp, 0)
											: ''
									}
									error={errors.systemSizeKwp}
									touched={touched.systemSizeKwp}
									onChange={handleChange}
									onBlur={handleBlur}
									isDisabled={(project?.epcOffers && project?.epcOffers.length > 0) || projectType !== projectTypes.PV}
									tooltip={
										projectType !== projectTypes.PV
											? {
													tooltip: t('System size is calculated from the EPC Volume.'),
											  }
											: ''
									}
									isTile
									isInEditMode={isInEditMode}
									unit={UNITS.KWP}
									isHighlighted={highlightLabels.includes(
										PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.SYSTEM_SIZE_KWP,
									)}
								/>
							</div>
						</HoverTooltip>
					</StyledFormField>
					<ConditionalGridSection data-elevator hasFlag={projectType === projectTypes.PV}>
						<StyledFormField>
							<HoverTooltip
								placement="top-start"
								arrowplacement="start"
								title={
									project?.epcOffers && project?.epcOffers.length
										? 'Yield is managed on the Sales tab because an Offer exists for this Project'
										: ''
								}
							>
								<div>
									<FormikInput
										id="kwhToKwpRel"
										name="kwhToKwpRel"
										label={t('Yield')}
										value={
											isValid(values.kwhToKwpRel)
												? isInEditMode
													? values.kwhToKwpRel
													: formatNumber(values.kwhToKwpRel, 0)
												: ''
										}
										error={errors.kwhToKwpRel}
										touched={touched.kwhToKwpRel}
										onChange={handleChange}
										onBlur={handleBlur}
										isDisabled={project?.epcOffers && project?.epcOffers.length > 0}
										isTile
										isInEditMode={isInEditMode}
										unit={UNITS.KWH_KWP}
										isHighlighted={highlightLabels.includes(
											PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.KWH_TO_KWP_REL,
										)}
									/>
								</div>
							</HoverTooltip>
						</StyledFormField>
					</ConditionalGridSection>
					<StyledFormField>
						<FormikInput
							id="predictedAnnualEnergySavings"
							name="predictedAnnualEnergySavings"
							label={t('Predicted annual energy savings')}
							value={
								isValid(values.predictedAnnualEnergySavings)
									? isInEditMode
										? values.predictedAnnualEnergySavings
										: formatDecimal(values.predictedAnnualEnergySavings, 3)
									: ''
							}
							error={errors.predictedAnnualEnergySavings}
							touched={touched.predictedAnnualEnergySavings}
							onChange={handleChange}
							onBlur={handleBlur}
							disabled={projectType === projectTypes.PV}
							isDisabled={projectType === projectTypes.PV}
							isTile
							isInEditMode={isInEditMode}
							unit={UNITS.MWH}
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.PREDICTED_ANNUAL_ENERGY_SAVINGS,
							)}
						/>
					</StyledFormField>

					<ConditionalGridSection hasFlag={projectType === projectTypes.PV}>
						<StyledFormField>
							<FormikInput
								id="pvDegradationRate"
								name="pvDegradationRate"
								label={t('PV degradation rate')}
								value={values.pvDegradationRate}
								unit={UNITS.PERCENT_PER_YEAR}
								error={errors.pvDegradationRate}
								touched={touched.pvDegradationRate}
								onChange={handleChange}
								onBlur={handleBlur}
								isDisabled={!isAuthorized([roles.ADMIN, roles.FINANCE, roles.MANAGEMENT])}
								tooltip={{
									tooltip: t('The % per year reduction in energy output due to ageing.'),
								}}
								isTile
								isInEditMode={isInEditMode}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.PV_DEGRADATION_RATE,
								)}
							/>
						</StyledFormField>
						<StyledFormField>
							<FormikInput
								id="solarUtilisationRate"
								name="solarUtilisationRate"
								label={t('Solar utilization rate')}
								value={
									isValid(values.solarUtilisationRate)
										? isInEditMode
											? values.solarUtilisationRate
											: formatNumber(values.solarUtilisationRate, 0)
										: ''
								}
								error={errors.solarUtilisationRate}
								touched={touched.solarUtilisationRate}
								onChange={handleChange}
								onBlur={handleBlur}
								unit={UNITS.PERCENT}
								tooltip={{
									tooltip: t(
										'The % of annual generated energy expected to be consumed.  The remaining % is the expected grid injection, if feed-in applies.',
									),
								}}
								isTile
								isRequired={isInEditMode}
								isInEditMode={isInEditMode}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.SOLAR_UTILIZATION_RATE,
								)}
							/>
						</StyledFormField>
					</ConditionalGridSection>
					<TariffSection>
						<Title>{t('Tariff')}</Title>
						<StyledFormField>
							<FormikInput
								id="gridEnergyTariffPerKwh"
								name="gridEnergyTariffPerKwh"
								label={t('Grid energy tariff')}
								value={
									isValid(values.gridEnergyTariffPerKwh)
										? isInEditMode
											? values.gridEnergyTariffPerKwh
											: formatNumber(values.gridEnergyTariffPerKwh, 3)
										: ''
								}
								error={errors.gridEnergyTariffPerKwh}
								touched={touched.gridEnergyTariffPerKwh}
								onChange={handleChange}
								onBlur={handleBlur}
								prefix={CurrencySign}
								isTile
								isInEditMode={isInEditMode}
								unit={UNITS.PER_KWP}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.GRID_ENERGY_TARIFF_PER_KWH,
								)}
							/>
							{values.gridEnergyTariffPerKwhEur && !isInEditMode && (
								<FormikInput
									id="gridEnergyTariffPerKwhEur"
									name="gridEnergyTariffPerKwhEur"
									prefix={() => 'EUR'}
									value={formatNumber(values.gridEnergyTariffPerKwhEur, 3)}
									unit={UNITS.PER_KWP}
									isTile
								/>
							)}
						</StyledFormField>
						<StyledFormField>
							<FormikSelect
								isAsync
								id="currency"
								name="currency"
								label={t('Currency')}
								value={values.currency}
								error={errors.currency}
								touched={touched.currency}
								setFieldValue={setFieldValue}
								onBlur={handleBlur}
								loadOptions={loadCurrenciesOptions}
								isTile
								isInEditMode={isInEditMode}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.CURRENCY,
								)}
							/>
						</StyledFormField>
					</TariffSection>
				</StyledGrid>
				<StyledConditionalGridSection
					hasFlag={isMountingInfoVisible || isBatteryInfoVisible || isGensetsInfoVisible}
					item
					xs={12}
					lg={6}
					xl={3}
					$isSidePanel={isSidePanel}
					data-elevator
				>
					<ConditionalGridSection data-elevator hasFlag={isMountingInfoVisible}>
						<Title>{t('Mounting')}</Title>
						<StyledFormField>
							<FormikSelect
								id="mountingTypes"
								name="mountingTypes"
								label={t('Mounting type')}
								value={values.mountingTypes}
								error={errors.mountingTypes}
								touched={touched.mountingTypes}
								isClearable
								setFieldValue={setFieldValue}
								onBlur={handleBlur}
								options={mountingTypeOptions}
								isMulti
								isTile
								isRequired
								isInEditMode={isInEditMode}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.MOUNTING_TYPES,
								)}
							/>
						</StyledFormField>
						<StyledFormField>
							<FormikInput
								id="availableSpace"
								name="availableSpace"
								label={t('Available space')}
								value={values.availableSpace || ''}
								error={errors.availableSpace}
								touched={touched.availableSpace}
								onChange={handleChange}
								onBlur={handleBlur}
								tooltip={{
									tooltip: 'The total space available for PV panels across all mounting types.',
								}}
								isTile
								isInEditMode={isInEditMode}
								unit={UNITS.M2}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.AVAILABLE_SPACE,
								)}
							/>
						</StyledFormField>
						<ConditionalGridSection data-elevator hasFlag={hasRoofMountings}>
							<StyledFormField>
								<FormikSelect
									isAsync
									id="roofType"
									name="roofType"
									label={t('Roof type')}
									value={values.roofType}
									error={errors.roofType}
									touched={touched.roofType}
									setFieldValue={setFieldValue}
									onBlur={handleBlur}
									loadOptions={loadRoofTypeOptions}
									isTile
									isRequired
									isInEditMode={isInEditMode}
									isHighlighted={highlightLabels.includes(
										PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.ROOF_TYPE,
									)}
								/>
							</StyledFormField>
						</ConditionalGridSection>
					</ConditionalGridSection>
					<ConditionalGridSection data-elevator hasFlag={isBatteryInfoVisible}>
						<Title>{t('Batteries')}</Title>
						<StyledFormField>
							<FormikInput
								id="batterySizeInKw"
								name="batterySizeInKw"
								label={t('Battery inverter size')}
								value={values.batterySizeInKw}
								error={errors.batterySizeInKw}
								touched={touched.batterySizeInKw}
								onChange={handleChange}
								onBlur={handleBlur}
								isTile
								isInEditMode={isInEditMode}
								unit={UNITS.KW}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.BATTERY_SIZE_IN_KW,
								)}
							/>
						</StyledFormField>
						<StyledFormField>
							<FormikInput
								id="batteryCapacityInKwh"
								name="batteryCapacityInKwh"
								label={t('Battery capacity')}
								value={values.batteryCapacityInKwh}
								error={errors.batteryCapacityInKwh}
								touched={touched.batteryCapacityInKwh}
								onChange={handleChange}
								onBlur={handleBlur}
								isTile
								isInEditMode={isInEditMode}
								unit={UNITS.KWP}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.BATTERY_CAPACITY_IN_KWH,
								)}
							/>
						</StyledFormField>
					</ConditionalGridSection>
					<ConditionalGridSection data-elevator hasFlag={values.gensets && projectType === projectTypes.PV}>
						<Title>{t('Gensets')}</Title>
						<StyledFormField>
							<FormikInput
								id="generatorSize"
								name="generatorSize"
								label={t('Generator size')}
								value={values.generatorSize}
								error={errors.generatorSize}
								touched={touched.generatorSize}
								onChange={handleChange}
								onBlur={handleBlur}
								isTile
								isInEditMode={isInEditMode}
								unit={UNITS.KVA}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.GENERATOR_SIZE,
								)}
							/>
						</StyledFormField>
						<StyledFormField>
							<FormikInput
								id="generatorQuantity"
								name="generatorQuantity"
								label={t('Generator quantity')}
								value={values.generatorQuantity}
								error={errors.generatorQuantity}
								touched={touched.generatorQuantity}
								onChange={handleChange}
								onBlur={handleBlur}
								isTile
								isInEditMode={isInEditMode}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.GENERATOR_QUANTITY,
								)}
							/>
						</StyledFormField>
					</ConditionalGridSection>
				</StyledConditionalGridSection>
				<StyledGrid item xs={12} lg={6} xl={3} $isSidePanel={isSidePanel} data-elevator>
					<Title>{t('Manufacturers')}</Title>
					<ConditionalGridSection data-elevator hasFlag={projectType === projectTypes.PV}>
						{generateManufacturerField({
							id: 'Solar_modules',
							label: t('Modules'),
							manufacturer: manufacturedProductTypes.SOLAR_MODULES,
							highlightId: PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.SOLAR_MODULES,
						})}
						{generateManufacturerField({
							id: 'Inverters',
							label: t('Inverters'),
							manufacturer: manufacturedProductTypes.INVERTERS,
							highlightId: PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.INVERTERS,
						})}
						{generateManufacturerField({
							id: 'Mountings',
							label: t('Mountings'),
							manufacturer: manufacturedProductTypes.MOUNTINGS,
							highlightId: PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.MOUNTING_TYPES,
						})}
					</ConditionalGridSection>
					<ConditionalGridSection data-elevator hasFlag={projectType === projectTypes.PV && hasEnergyStorage}>
						{generateManufacturerField({
							id: 'Batteries',
							label: t('Batteries'),
							manufacturer: manufacturedProductTypes.BATTERIES,
							highlightId: PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.BATTERIES,
						})}
					</ConditionalGridSection>
					<ConditionalGridSection data-elevator hasFlag={projectType === projectTypes.EE_LED}>
						{generateManufacturerField({
							id: 'LED_luminaires',
							label: t('LED'),
							manufacturer: manufacturedProductTypes.LED_LUMINAIRES,
							highlightId: PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.LED_LUMINAIRES,
						})}
					</ConditionalGridSection>
					<ConditionalGridSection data-elevator hasFlag={projectType === projectTypes.EE_AC}>
						{generateManufacturerField({
							id: 'AC_units',
							label: t('AC units'),
							manufacturer: manufacturedProductTypes.AC_UNITS,
							highlightId: PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.AC_UNITS,
						})}
					</ConditionalGridSection>
					<ConditionalGridSection data-elevator hasFlag={projectType === projectTypes.EE_SHW}>
						{generateManufacturerField({
							id: 'Solar_hot_water',
							label: t('Solar hot water'),
							manufacturer: manufacturedProductTypes.SOLAR_HOT_WATER,
							highlightId: PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.SOLAR_HOT_WATER,
						})}
					</ConditionalGridSection>
					<Title>{t('Description')}</Title>
					<StyledFormField>
						<FormikTextArea
							id="description"
							name="description"
							label={t('Description')}
							value={values.description}
							error={errors.description}
							touched={touched.description}
							onChange={handleChange}
							onBlur={handleBlur}
							isTile
							isInEditMode={isInEditMode}
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_DETAILS.PROJECT_DESCRIPTION,
							)}
						/>
					</StyledFormField>
				</StyledGrid>
			</Grid>

			{isCreateManufacturerModalOpened && (
				<ManufacturerOverlay
					mode={crudModes.CREATE}
					productTypeForNewManufacturer={productTypeForNewManufacturer}
					isOpen={isCreateManufacturerModalOpened}
					onClose={() => setIsCreateManufacturerModalOpened(false)}
					onFormSubmit={handleCreateManufacturerFormSubmit}
				/>
			)}

			{Boolean(editedManufacturer) && (
				<ManufacturerOverlay
					mode={crudModes.EDIT}
					isOpen={Boolean(editedManufacturer)}
					onClose={() => setEditedManufacturer(null)}
					manufacturerName={editedManufacturer}
					onFormSubmit={handleCreateManufacturerFormSubmit}
				/>
			)}
		</EditableTile>
	);
};

ProjectDetailsTile.defaultProps = {
	project: {},
	isSidePanel: false,
	highlightLabels: [],
	onDirtyForm: () => {},
	onExitEditMode: () => {},
	onEnterEditMode: () => {},
	onTileSave: async () => {},
};

ProjectDetailsTile.propTypes = {
	project: PropTypes.shape({
		client: PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		}),
		regionalHub: PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		}),
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		systemSizeKwp: PropTypes.number,
		kwhToKwpRel: PropTypes.number,
		projectType: PropTypes.shape({ name: PropTypes.string }),
		energyStorage: PropTypes.bool,
		gensets: PropTypes.bool,
		epcOffers: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	onTileSave: PropTypes.func,
	onDirtyForm: PropTypes.func,
	isSidePanel: PropTypes.bool,
	onExitEditMode: PropTypes.func,
	onEnterEditMode: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default ProjectDetailsTile;
