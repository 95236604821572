import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { NavLink, useLocation } from 'react-router-dom';
import isAuthorized from 'User/utils/isAuthorized';
import colors from 'Application/theme/colors';

const StyledNavLink = styled(NavLink)`
	position: relative;
	display: flex;
	align-items: center;
	//margin: 12px 0;
	font-size: 16px;
	font-weight: 500;
	line-height: 18.5px;
	color: ${colors.text.primary};
	text-decoration: none;
	height: 37px;
	padding-left: 32px;
	padding-right: 32px;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&:before {
		content: '';
		position: absolute;
		display: none;
		top: 50%;
		left: 0px;
		width: 2px;
		height: 100%;
		background: ${colors.primary.dark};
		transform: translateY(-50%);
	}

	&:hover {
		color: ${colors.primary.main};
		&:not(.active) {
			path {
				fill: ${colors.primary.main};
			}
		}
	}

	${({ $isActive }) =>
		$isActive
			? css`
					 {
						color: ${colors.primary.dark};
						font-weight: 700;
						background: linear-gradient(90deg, rgba(42, 164, 188, 0.05) -0.91%, rgba(42, 164, 188, 0) 100%);
						&:before {
							display: block;
						}
						path {
							fill: ${colors.primary.dark};
						}
					}
			  `
			: css`
					&:focus:not(:active) {
						outline: none;

						&:before {
							display: block;
							background: ${colors.primary.main};
						}
					}
			  `}

	svg {
		margin-right: 16px;
	}
`;

const SidebarLink = ({ to, authorizedRoles, children, isActive, ...props }) => {
	const { pathname } = useLocation();
	if (authorizedRoles && !isAuthorized(authorizedRoles)) {
		return null;
	}

	const handleOnClick = e => {
		if (to === window.location.pathname) {
			e.stopPropagation();
			e.preventDefault();
		} else {
			window.location.replace(to);
		}
	};

	return (
		<StyledNavLink
			to={to}
			onClick={handleOnClick}
			$isActive={isActive || RegExp(`^${to}`, 'g').test(pathname)}
			{...props}
		>
			{children}
		</StyledNavLink>
	);
};

SidebarLink.defaultProps = {
	authorizedRoles: undefined,
	isActive: false,
};

SidebarLink.propTypes = {
	to: PropTypes.string.isRequired,
	authorizedRoles: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.node.isRequired,
	isActive: PropTypes.bool,
};

export default SidebarLink;
