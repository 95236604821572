import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isAuthorized from 'User/utils/isAuthorized';
import { roles } from 'User/constants/roles';
import EditableTile from 'Common/components/Tile/EditableTile';
import EpcOfferTileActions from './Actions/EpcOfferTileActions';
import EPCOfferList from './EPCOfferList';
import { useCallback, useMemo, useState } from 'react';
import { checkProjectHasPrimaryClientOffer } from 'Projects/utils/salesHelpers';
import crudModes from 'Common/constants/crudModes';
import showToastError from 'Common/utils/showToastError';
import ClientOfferOverlay from '../ClientOfferTile/Overlays/ClientOffer/ClientOfferOverlay';

const EPCOfferTile = ({ project, handleUpdateProject }) => {
	const { t } = useTranslation();
	const isAuthorizedToCreate = isAuthorized([roles.ADMIN, roles.SALES, roles.FINANCE, roles.MANAGEMENT]);
	const [epcOfferIdForCreatingClientOffer, setEpcOfferIdForCreatingClientOffer] = useState();
	const handleEPCOfferCreate = epcOfferId => setEpcOfferIdForCreatingClientOffer(epcOfferId);

	const showCollapseButton = useMemo(() => project?.epcOffers?.length > 1, [project]);
	const hasPrimaryEpcOffer = useMemo(() => project?.epcOffers?.some(offer => offer.primaryOffer), [project]);
	const totalOffersCount = useMemo(() => project?.epcOffers?.length, [project]);
	const primaryOffersCount = useMemo(() => (hasPrimaryEpcOffer ? 1 : 0), [hasPrimaryEpcOffer]);

	// ? Collapse EPC Offer tile if there are no EPC Offers or if there is a primary EPC Offer
	const [isCollapsed, setIsCollapsed] = useState(hasPrimaryEpcOffer);

	const epcOfferValues = useMemo(
		() =>
			epcOfferIdForCreatingClientOffer
				? project.epcOffers.find(epcOffer => epcOfferIdForCreatingClientOffer === epcOffer.id)
				: undefined,
		[epcOfferIdForCreatingClientOffer, project.epcOffers],
	);

	const handleToggleCollapse = () => {
		setIsCollapsed(prev => !prev);
	};

	const handleCreateClient = useCallback(async () => {
		try {
			setEpcOfferIdForCreatingClientOffer(null);
			handleUpdateProject();
		} catch (err) {
			showToastError(err, 'Failed creating client offer');
		}
	}, [handleUpdateProject]);

	return (
		<>
			<EditableTile
				title={t('EPC Offers')}
				rightComponent={
					<EpcOfferTileActions
						data={project}
						onSubmit={handleUpdateProject}
						isAuthorized={isAuthorizedToCreate}
						noShadow
						isCollapsed={isCollapsed}
						handleToggleCollapse={handleToggleCollapse}
						showCollapseButton={showCollapseButton}
						primaryOffersCount={primaryOffersCount}
						totalOffersCount={totalOffersCount}
					/>
				}
			>
				<EPCOfferList
					project={project}
					isCollapsed={isCollapsed}
					onEPCOfferCreate={handleEPCOfferCreate}
					handleUpdateProject={handleUpdateProject}
				/>
			</EditableTile>

			{epcOfferIdForCreatingClientOffer && (
				<ClientOfferOverlay
					isOpen={Boolean(epcOfferIdForCreatingClientOffer)}
					mode={crudModes.CREATE}
					onClose={() => setEpcOfferIdForCreatingClientOffer(null)}
					onFormSubmit={handleCreateClient}
					epcOfferId={epcOfferIdForCreatingClientOffer}
					project={project}
					epcOffer={epcOfferValues}
					hasPrimaryClientOffer={checkProjectHasPrimaryClientOffer(project.epcOffers)}
				/>
			)}
		</>
	);
};

EPCOfferTile.defaultProps = {
	project: undefined,
	handleUpdateProject: async () => {},
};

EPCOfferTile.propTypes = {
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		projectType: PropTypes.shape({ name: PropTypes.string }),
		epcOffers: PropTypes.arrayOf(PropTypes.shape({})),
		systemSizeKwp: PropTypes.number,
		tender: PropTypes.shape({
			tenderCloseDate: PropTypes.string,
		}),
	}),
	handleUpdateProject: PropTypes.func,
};

export default EPCOfferTile;
