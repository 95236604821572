import { useMemo } from 'react';

const useExpellContactInitialValues = data =>
	useMemo(
		() => ({
			comment: data.comment || '',
		}),
		[data],
	);
export default useExpellContactInitialValues;
