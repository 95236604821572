import api from 'Application/api/api';

const getLanguages = (signal, query) =>
	api.get('/languages/get', {
		params: {
			query,
		},
		signal,
	});

export default getLanguages;
