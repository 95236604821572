import { projectPriorityIcons } from 'Common/constants/projectPrioritiy';
import propTypes from 'prop-types';
import Icon from './Icon';

const PriorityIcon = ({ priority }) => {
	if (!priority) {
		return <>Invalid priority</>;
	}

	return <Icon icon={projectPriorityIcons[priority].icon} color={projectPriorityIcons[priority].color} />;
};

PriorityIcon.defaultProps = {
	priority: '',
};

PriorityIcon.propTypes = {
	priority: propTypes.string.isRequired,
};

export default PriorityIcon;
