import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StompJS from 'webstomp-client';
import SockJS from 'sockjs-client';

import getAllNotifications from 'Notifications/api/getAllNotifications';

import { updateSidePanelContentData } from 'Application/reducers/reduxSidePanel';
import { addNewNotificationEntry, clearNewNotificationsEntries } from 'Notifications/reduxNotifications';
import showToastError from 'Common/utils/showToastError';
import isInAuthorizedGroups from 'User/utils/isInAuthorizedGroups';
import { roleGroups } from 'User/constants/roles';
import useAbortController from 'Common/hooks/useAbortController';

const useNotifications = () => {
	const isEpcPartner = isInAuthorizedGroups([roleGroups.EPC_PARTNER]);
	const dispatch = useDispatch();
	const [connected, setConnected] = useState(false);
	const [newNotification, setNewNotification] = useState({});
	const sidePanelData = useSelector(state => state.sidePanel);
	const [checkForNewNotifications, setCheckForNewNotifications] = useState(false);

	const abortController = useAbortController();

	const currentUser = useSelector(state => state.user);
	useEffect(() => {
		if (isEpcPartner) return;

		if (currentUser?.isAuthenticated && checkForNewNotifications) {
			(async () => {
				try {
					const hasNewNotifications = await getAllNotifications(
						abortController.signal,
						currentUser?.email,
						0,
						false,
						false,
					).then(res => res.data.totalElements > 0);
					if (hasNewNotifications) {
						dispatch(addNewNotificationEntry());
					}
				} catch (e) {
					showToastError(e);
				}
			})();
		} else if (!currentUser?.isAuthenticated) {
			dispatch(clearNewNotificationsEntries());
			setCheckForNewNotifications(true);
		}
	}, [isEpcPartner, currentUser, dispatch, checkForNewNotifications, abortController.signal]);

	const isNotificationsOpen = useMemo(
		() => sidePanelData.isOpen && sidePanelData.type === 'notifications',
		[sidePanelData],
	);

	const sockjs = useMemo(
		() =>
			new SockJS('/comment-notifications', null, {
				transports: ['websocket'],
			}),
		[],
	);

	const stompClient = useMemo(() => StompJS.over(sockjs, { debug: false }), [sockjs]);

	const onMessageReceive = useCallback(
		({ body = '{}' }) => {
			const newNotification = JSON.parse(body);
			if (isNotificationsOpen) {
				const _id = new Date(newNotification.createdDate).getTime();
				setNewNotification({ id: _id, ...newNotification });
			} else {
				dispatch(addNewNotificationEntry());
			}
		},
		[dispatch, isNotificationsOpen],
	);

	useEffect(() => {
		if (isNotificationsOpen) {
			setNewNotification([]);
		}
	}, [isNotificationsOpen]);

	useEffect(() => {
		if (isNotificationsOpen) {
			dispatch(
				updateSidePanelContentData({
					newNotification,
				}),
			);
		}
	}, [dispatch, isNotificationsOpen, newNotification]);

	useEffect(() => {
		if (isEpcPartner) return;

		stompClient.connect(
			{},
			() => {
				setConnected(true);
			},
			() => {},
		);
		// ! Don't add isEpcPartner as hook dependency
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let sub;
		if (connected) {
			sub = stompClient.subscribe('/user/queue/notifications', onMessageReceive);
		}
		return () => {
			sub && typeof sub.unsubscribe === 'function' && sub.unsubscribe();
		};
	}, [connected, onMessageReceive, stompClient]);
};

export default useNotifications;
