import styled from 'styled-components/macro';
import { css } from 'styled-components';
import Grid from '@mui/material/Grid';
import { useEffect, useRef } from 'react';
import { FOCUSABLE_ELEMENTS_SELECTOR } from 'Common/constants/focusableElementsSelector';
import PropTypes from 'prop-types';

const _ConditionalGridSection = styled(Grid)`
	transition: all 0.3s ease-in-out;
	transform-origin: top;
	margin-bottom: 10px;
	width: 100%;
	position: relative;
	z-index: ${({ zindex }) => zindex || null};

	${({ $hasFlag }) =>
		$hasFlag
			? css`
					max-height: 100%;
					opacity: 1;
					transform: scaleY(1);
					-webkit-transform: unset;
			  `
			: css`
					transform: scaleY(0.3);
					max-height: 0;
					max-width: 0 !important;
					flex-basis: 0 !important;
					padding: 0 !important;
					opacity: 0;
					margin: 0 !important;

					* {
						margin: 0 !important;
					}
			  `}
`;

const ConditionalGridSection = ({ children, className, hasFlag, ...props }) => {
	const sectionRef = useRef(null);

	useEffect(() => {
		if (sectionRef.current) {
			const tabIndex = hasFlag ? 0 : -1;
			const focusableElements = [...sectionRef.current.querySelectorAll(FOCUSABLE_ELEMENTS_SELECTOR)];
			focusableElements.forEach(el => {
				el.setAttribute('tabindex', tabIndex);
			});
		}
	}, [hasFlag]);

	return (
		<_ConditionalGridSection ref={sectionRef} {...props} $hasFlag={hasFlag} className={className}>
			{children}
		</_ConditionalGridSection>
	);
};

ConditionalGridSection.defaultProps = {
	hasFlag: false,
};

ConditionalGridSection.propTypes = {
	...Grid.propTypes,
	hasFlag: PropTypes.bool,
};

export default ConditionalGridSection;
