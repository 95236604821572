import { pick } from 'lodash';
import formatDateForRequestData from 'Common/utils/formatDateForRequestData';

const convertFormPostCompletionValuesToRequestData = values => {
	return {
		...pick(values, [
			'ms5Paid',
			'pcdPaid',
			'pctPassed',
			'pctRequiredPR',
			'pctPRCalculationResult',
			'pcdComment',
			'projectId',
		]),
		pcdAmount:
			values.pcdCurrency && values.pcdAmount
				? {
						currency: values.pcdCurrency.value,
						value: values.pcdAmount,
				  }
				: null,
		pcdCurrency: values.pcdCurrency ? values.pcdCurrency.value : 'EUR',
		pctDate: values.pctDate ? formatDateForRequestData(values.pctDate) : null,
	};
};

export default convertFormPostCompletionValuesToRequestData;
