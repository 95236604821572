import { components } from 'react-select';
import { PropTypes } from 'prop-types';

const IndustrySelectOption = props => (
	<>
		<components.Option {...props}>
			<span>
				<b>{props.data.marketSegment.name}</b>
			</span>
			&nbsp;
			<span>{props.label}</span>
		</components.Option>
	</>
);

IndustrySelectOption.propTypes = {
	data: PropTypes.shape({ marketSegment: PropTypes.shape({ name: PropTypes.string }) }).isRequired,
	label: PropTypes.string.isRequired,
};

export default IndustrySelectOption;
