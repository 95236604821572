import colors from 'Application/theme/colors';

export const statusColorMap = {
	DRAFT: colors.warning.main,
	READY: colors.success.main,
	ACTIVE: colors.primary.main,
	CLOSED_INVESTED: colors.primary.main,
	CLOSED_NOT_INVESTED: colors.primary.main,
};

export const statuses = {
	CLOSED_NOT_INVESTED: 'CLOSED_NOT_INVESTED',
	CLOSED_INVESTED: 'CLOSED_INVESTED',
	ACTIVE: 'ACTIVE',
	READY: 'READY',
	DRAFT: 'DRAFT',
};

export const statusesLabels = {
	[statuses.DRAFT]: 'Draft',
	[statuses.READY]: 'Ready',
	[statuses.ACTIVE]: 'Active',
	[statuses.CLOSED_NOT_INVESTED]: 'Closed not invested',
	[statuses.CLOSED_INVESTED]: 'Closed invested',
};

export const statusHelpTexts = {
	[statuses.DRAFT]: 'Validations are not met or Trance not synced with portagon Emission.',
	[statuses.READY]: "Synced and has 'Investible' status with portagon.",
	[statuses.ACTIVE]: 'In progress',
	[statuses.CLOSED_NOT_INVESTED]:
		'No investments received and tranche end date reached or portagon status is not ‘Investible’',
	[statuses.CLOSED_INVESTED]:
		'Investments received and tranche end date reached or portagon status is not ‘Investible’.',
};
