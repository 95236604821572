import { useMemo, useCallback } from 'react';
import styled from 'styled-components/macro';
import FormField from 'Common/components/form/FormField';
import { useTranslation } from 'react-i18next';
import FormikInput from 'Common/components/form/FormikInput';
import NoFalseValueSwitch from 'Common/components/form/NoFalseValueSwitch';
import Big from 'Common/utils/customBig';
import FormikSelect from 'Common/components/form/FormikSelect';
import { fullNameProjectTypes } from 'Projects/constants/projectTypes';
import ConditionalGridSection from 'Common/components/ConditionalGridSection';
import { PropTypes } from 'prop-types';

const FieldsRow = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;

const StyledFormField = styled(FormField)`
	margin-bottom: 20px !important;
`;

const SwitchFormField = styled(FormField)`
	margin-bottom: 20px !important;
	width: 80%;
`;

const CurrenciesStyledFormField = styled(StyledFormField)`
	width: 40%;
`;

const SwitchWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-left: 40%;
`;

const EpcOfferSection = ({
	errors,
	touched,
	values,
	handleChange,
	handleBlur,
	isDisabled,
	setFieldValue,
	setFieldTouched,
	currencyOptions,
}) => {
	const { t } = useTranslation();

	const CurrencySign = () =>
		useMemo(() => <>{values?.epcOfferCurrency?.value === 'EUR' ? '€' : values?.epcOfferCurrency?.value}</>, []);

	const systemSize = useMemo(() => Number(values.systemSizeKwp), [values.systemSizeKwp]);

	const epcVolumeSwitch = useMemo(() => values.epcVolumeSwitch, [values.epcVolumeSwitch]);

	const omPriceSwitch = useMemo(() => values.omPriceSwitch, [values.omPriceSwitch]);

	const handleEpcVolumeKwpFieldChange = useCallback(
		e => {
			handleChange(e);
			const epcVolumeKwp = Number(e.target.value);

			if (epcVolumeSwitch) {
				if (isNaN(systemSize) || systemSize === 0) {
					setFieldValue('epcVolumeExclVat', '');
				} else {
					setFieldValue(
						'epcVolumeExclVat',
						epcVolumeKwp ? Number(Big(epcVolumeKwp).times(Big(systemSize)).toString()).toFixed(2) : '',
					);
					if (errors['epcVolumeExclVat'] && epcVolumeKwp) {
						setFieldTouched('epcVolumeExclVat');
					}
				}
			}
		},
		[handleChange, epcVolumeSwitch, systemSize, setFieldTouched, setFieldValue, errors],
	);

	const handleEpcVolumeFieldChange = useCallback(
		e => {
			handleChange(e);
			const epcVolume = Number(e.target.value);

			if (!epcVolumeSwitch) {
				if (isNaN(systemSize) || systemSize === 0) {
					setFieldValue('epcVolumeExclVatKwp', '');
				} else {
					setFieldValue(
						'epcVolumeExclVatKwp',
						epcVolume ? Number(Number(Big(epcVolume).div(Big(systemSize)).toString()).toFixed(2)) : '',
					);
					if (errors['epcVolumeExclVatKwp'] && epcVolume) {
						setFieldTouched('epcVolumeExclVatKwp');
					}
				}
			}
		},
		[handleChange, epcVolumeSwitch, systemSize, setFieldTouched, setFieldValue, errors],
	);

	const handleOmPriceKwpFieldChange = useCallback(
		e => {
			handleChange(e);
			const annualOmPriceKwp = Number(e.target.value);

			if (omPriceSwitch) {
				if (isNaN(systemSize) || systemSize === 0) {
					setFieldValue('annualOmPrice', '');
				} else {
					setFieldValue(
						'annualOmPrice',
						annualOmPriceKwp ? Number(Big(annualOmPriceKwp).times(Big(systemSize)).toString()).toFixed(2) : '',
					);
					if (errors['annualOmPrice'] && annualOmPriceKwp) {
						setFieldTouched('annualOmPrice');
					}
				}
			}
		},
		[handleChange, omPriceSwitch, systemSize, setFieldTouched, setFieldValue, errors],
	);

	const handleOmPriceFieldChange = useCallback(
		e => {
			handleChange(e);
			const annualOmPrice = Number(e.target.value);
			if (!omPriceSwitch) {
				if (isNaN(systemSize) || systemSize === 0) {
					setFieldValue('annualOmPriceKwp', '');
				} else {
					setFieldValue(
						'annualOmPriceKwp',
						annualOmPrice ? Number(Number(Big(annualOmPrice).div(Big(systemSize)).toString()).toFixed(2)) : '',
					);
					if (errors['annualOmPriceKwp'] && annualOmPrice) {
						setFieldTouched('annualOmPriceKwp');
					}
				}
			}
		},
		[handleChange, omPriceSwitch, systemSize, setFieldTouched, setFieldValue, errors],
	);

	const handleEpcVolumeSwitchChange = e => setFieldValue('epcVolumeSwitch', e);

	const handleOmPriceSwitchChange = e => setFieldValue('omPriceSwitch', e);

	return (
		<>
			<FieldsRow>
				<SwitchFormField>
					<FormikInput
						prefix={CurrencySign}
						label={t('EPC price')}
						id="epcVolumeExclVat"
						name="epcVolumeExclVat"
						error={errors.epcVolumeExclVat}
						touched={touched.epcVolumeExclVat}
						value={values.epcVolumeExclVat}
						onChange={handleEpcVolumeFieldChange}
						onBlur={handleBlur}
						isRequired
						isDisabled={isDisabled || values.epcVolumeSwitch}
					/>
				</SwitchFormField>
				<ConditionalGridSection hasFlag={values?.projectType?.label === fullNameProjectTypes.PV}>
					<SwitchWrapper>
						<NoFalseValueSwitch
							setValue={handleEpcVolumeSwitchChange}
							name="epcVolumeSwitch"
							value={values.epcVolumeSwitch}
							disabled={isDisabled}
						/>
					</SwitchWrapper>
				</ConditionalGridSection>
				<ConditionalGridSection hasFlag={values?.projectType?.label === fullNameProjectTypes.PV}>
					<StyledFormField>
						<FormikInput
							prefix={CurrencySign}
							label={t('EPC volume per kWp')}
							id="epcVolumeExclVatKwp"
							name="epcVolumeExclVatKwp"
							error={errors.epcVolumeExclVatKwp}
							touched={touched.epcVolumeExclVatKwp}
							value={values.epcVolumeExclVatKwp}
							onChange={handleEpcVolumeKwpFieldChange}
							onBlur={handleBlur}
							isRequired
							isDisabled={isDisabled || !values.epcVolumeSwitch}
						/>
					</StyledFormField>
				</ConditionalGridSection>
			</FieldsRow>
			<FieldsRow>
				<SwitchFormField>
					<FormikInput
						prefix={CurrencySign}
						label={t('Annual O&M price')}
						id="annualOmPrice"
						name="annualOmPrice"
						error={errors.annualOmPrice}
						touched={touched.annualOmPrice}
						value={values.annualOmPrice}
						onChange={handleOmPriceFieldChange}
						onBlur={handleBlur}
						isRequired
						isDisabled={isDisabled || values.omPriceSwitch}
					/>
				</SwitchFormField>
				<ConditionalGridSection hasFlag={values?.projectType?.label === fullNameProjectTypes.PV}>
					<SwitchWrapper>
						<NoFalseValueSwitch
							setValue={handleOmPriceSwitchChange}
							name="omPriceSwitch"
							value={values.omPriceSwitch}
							disabled={isDisabled}
						/>
					</SwitchWrapper>
				</ConditionalGridSection>
				<ConditionalGridSection hasFlag={values?.projectType?.label === fullNameProjectTypes.PV}>
					<StyledFormField>
						<FormikInput
							prefix={CurrencySign}
							label={t('Annual O&M per kWp')}
							id="annualOmPriceKwp"
							name="annualOmPriceKwp"
							error={errors.annualOmPriceKwp}
							touched={touched.annualOmPriceKwp}
							value={values.annualOmPriceKwp}
							onChange={handleOmPriceKwpFieldChange}
							onBlur={handleBlur}
							isRequired
							isDisabled={isDisabled || !values.omPriceSwitch}
						/>
					</StyledFormField>
				</ConditionalGridSection>
			</FieldsRow>
			<FieldsRow>
				<CurrenciesStyledFormField>
					<FormikSelect
						id="epcOfferCurrency"
						name="epcOfferCurrency"
						label={t('EPC currency')}
						value={values.epcOfferCurrency}
						error={errors.epcOfferCurrency}
						touched={touched.epcOfferCurrency}
						setFieldValue={setFieldValue}
						onBlur={handleBlur}
						options={currencyOptions}
						isDisabled={isDisabled}
						isRequired
					/>
				</CurrenciesStyledFormField>
			</FieldsRow>
		</>
	);
};

EpcOfferSection.propTypes = {
	errors: PropTypes.shape({
		epcOfferCurrency: PropTypes.string,
		systemSizeKwp: PropTypes.string,
		epcVolumeSwitch: PropTypes.string,
		omPriceSwitch: PropTypes.string,
		epcVolumeExclVat: PropTypes.string,
		epcVolumeExclVatKwp: PropTypes.string,
		annualOmPrice: PropTypes.string,
		annualOmPriceKwp: PropTypes.string,
	}).isRequired,
	touched: PropTypes.shape({
		epcOfferCurrency: PropTypes.bool,
		systemSizeKwp: PropTypes.bool,
		epcVolumeSwitch: PropTypes.bool,
		omPriceSwitch: PropTypes.bool,
		epcVolumeExclVat: PropTypes.bool,
		epcVolumeExclVatKwp: PropTypes.bool,
		annualOmPrice: PropTypes.bool,
		annualOmPriceKwp: PropTypes.bool,
	}).isRequired,
	values: PropTypes.shape({
		epcOfferCurrency: PropTypes.shape({ value: PropTypes.string.isRequired }),
		systemSizeKwp: PropTypes.string,
		epcVolumeSwitch: PropTypes.bool,
		omPriceSwitch: PropTypes.bool,
		epcVolumeExclVat: PropTypes.string,
		epcVolumeExclVatKwp: PropTypes.string,
		annualOmPrice: PropTypes.string,
		annualOmPriceKwp: PropTypes.string,
		projectType: PropTypes.shape({ label: PropTypes.string.isRequired }),
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	handleBlur: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	currencyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default EpcOfferSection;
