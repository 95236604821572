import api from 'Application/api/api';

const getEpcOfferById = async (signal, id, details) => {
	const response = await api.get(`/epc-offers/${id}`, {
		params: {
			details,
		},
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return response;
};

export default getEpcOfferById;
