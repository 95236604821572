import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import crudModes from 'Common/constants/crudModes';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import ProjectDeveloperOverlay from 'ProjectDeveloper/components/Overlay/ProjectDeveloperOverlay';
import EpcPartnerOverlay from 'EpcPartner/components/EpcPartnerForm/EpcPartnerOverlay';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import loadProjectDevelopersOptions from 'ProjectDeveloper/utils/loadProjectDeveloperOptions';
import loadEpcPartnerOptions from 'EpcPartner/utils/loadEpcPartnerOptions';
import loadSalesChannelOptions from 'Projects/utils/loadSalesChannelOptions';
import projectTypes from 'Projects/constants/projectTypes';
import loadProjectTypeOptions from 'Projects/utils/loadProjectTypeOptions';
import { Grid } from '@mui/material';
import ErrorMessage from 'Common/components/form/ErrorMessage';
import loadClientOptions from 'Client/utils/loadClientOptions';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import TextButton from 'Common/components/buttons/TextButton';
import useProjectCreateFormInitialValues from './hooks/useProjectCreateFormInitialValues';
import useResponsive from 'Common/hooks/useResponsive';
import { useSelector } from 'react-redux';
import useProjectCreateFormValidationSchema from './hooks/useProjectCreateFormValidationSchema';
import createProject from 'Projects/api/createProject';
import convertFormValuesToRequestData from './utils/convertFormValuesToRequestData';
import loadCountryOptions from 'Country/utils/loadCountryOptions';
import getUsers from 'UserManagement/api/getUsers';
import { roles } from 'User/constants/roles';
import { OverlayButtons } from 'Common/components/modals/Overlay';
import ClientOverlay from 'Client/components/ClientForm/ClientOverlay';
import { useLocation } from 'react-router';

const Form = styled.form`
	width: 100%;
`;

const RelativeFormField = styled(FormField)`
	position: relative;
`;

const EditLink = styled(TextButton)`
	padding: 8px 0px;
`;

const ProjectCreateForm = ({ onSubmit, onCancel, onDirty }) => {
	const { isMobile } = useResponsive();
	const { t } = useTranslation();
	const { pathname } = useLocation();

	const abortController = useAbortController();

	const initialValues = useProjectCreateFormInitialValues();
	const validationSchema = useProjectCreateFormValidationSchema();

	const [isLoading, setIsLoading] = useState(false);

	const {
		errors,
		touched,
		values,
		handleChange,
		handleBlur,
		handleSubmit,
		dirty,
		setFieldValue,
		setFieldError,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				const response = await createProject(abortController.signal, {
					...convertFormValuesToRequestData(values),
					priority: values.priority
						? values.priority.value
						: {
								priority: 'MEDIUM',
								displayName: 'Medium',
						  },
				});

				const createdProject = response.data;

				onSubmit(createdProject);

				notify(
					<>
						{t('Project with ')}
						<b>ID {createdProject.externalId}</b>
						{t(' was created successfully.')}
					</>,
					{
						type: toast.TYPE.SUCCESS,
					},
				);

				// Re-set all fields and mark them as not touched
				resetForm({ values });

				onSubmit(createdProject);

				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	const [isLoadingUsers, setIsLoadingUsers] = useState(false);
	const [responsiblePersonOptions, setResponsiblePersonOptions] = useState([]);
	const [countries, setCountries] = useState([]);

	useEffect(() => {
		(async () => {
			setIsLoadingUsers(true);
			try {
				const response = await getUsers(
					abortController.signal,
					{
						page: 1,
						itemsPerPage: 9999,
					},
					undefined,
					undefined,
				);
				if (response.data.content.length > 0) {
					const data = [];
					response.data.content.map(item =>
						item.roles.forEach(
							role =>
								role.name === roles.SALES &&
								data.push({
									value: item.email,
									label: item.fullName,
								}),
						),
					);
					setResponsiblePersonOptions(data);
				}
				setIsLoadingUsers(false);
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setIsLoadingUsers(false);
				}
			}
		})();
	}, [abortController.signal, t]);

	const currentUser = useSelector(state => state.user);

	// Set the responsible person to be initially the currently logged-in user
	useEffect(() => {
		setFieldValue('responsiblePerson', {
			value: currentUser.email,
			label: `${currentUser.firstName} ${currentUser.lastName}`,
		});
	}, [currentUser, setFieldValue]);

	const isUnknownClient = values.unknownClient;

	// Clear the client field when the unknown client checkbox is checked
	useEffect(() => {
		if (isUnknownClient) {
			setFieldValue('client', null);
		}
	}, [isUnknownClient, setFieldValue]);

	// Load country options with gridEmissionFactor
	useEffect(() => {
		(async () => {
			try {
				const response = await loadCountryOptions(abortController.signal, undefined, ['gridEmissionFactor']);
				setCountries(response);
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [abortController.signal]);

	const handleCountryChange = e => {
		if (!e.gridEmissionFactor) {
			setShowMissingGEFWarning(true);
		} else {
			setShowMissingGEFWarning(false);
		}
		setFieldValue('country', e);
	};

	const [clientFormSubmitCount, setClientFormSubmitCount] = useState(0);

	const handleClientFormSubmit = client => {
		setClientFormSubmitCount(prevCount => prevCount + 1);

		setFieldValue('client', {
			value: client.id,
			label: client.name,
		});
	};

	const [epcPartnerFormSubmitCount, setEpcPartnerFormSubmitCount] = useState(0);

	const handleEpcPartnerFormSubmit = epcPartner => {
		setEpcPartnerFormSubmitCount(prevCount => prevCount + 1);

		setFieldValue('epcPartner', {
			value: epcPartner.id,
			label: epcPartner.name,
		});
	};

	const [projectDevFormSubmitCount, setProjectDevFormSubmitCount] = useState(0);

	const handleProjectDeveloperFormSubmit = projectDeveloper => {
		setProjectDevFormSubmitCount(prevCount => prevCount + 1);

		setFieldValue('projectDeveloper', {
			value: projectDeveloper.id,
			label: projectDeveloper.name,
		});
	};

	const [isCreateClientOverlayOpened, setIsCreateClientOverlayOpened] = useState(false);

	const [isEditClientOverlayOpened, setIsEditClientOverlayOpened] = useState(false);

	const [isCreateEpcPartnerOverlayOpened, setIsCreateEpcPartnerOverlayOpened] = useState(false);

	const [isEditEpcPartnerOverlayOpened, setIsEditEpcPartnerOverlayOpened] = useState(false);

	const [isCreateProjectDevOverlayOpened, setIsCreateProjectDevOverlayOpened] = useState(false);

	const [isEditProjectDevOverlayOpened, setIsEditProjectDevOverlayOpened] = useState(false);

	const [showMissingGEFWarning, setShowMissingGEFWarning] = useState(false);

	const hasClient = !!values.client;
	const hasEpcPartner = !!values.epcPartner;
	const hasProjectDeveloper = !!values.projectDeveloper;

	const projectType = values.projectType?.label ?? null;

	const createClientBreadcrumbList = useMemo(
		() => [
			{ label: 'Projects', href: '/projects', forceRefresh: true },
			{ label: 'Create Project', href: pathname },
			{ label: 'Create Client' },
		],
		[pathname],
	);

	const editClientBreadcrumbList = useMemo(
		() => [
			{ label: 'Projects', href: '/projects', forceRefresh: true },
			{ label: 'Create Project', href: pathname },
			{ label: 'Edit Client' },
		],
		[pathname],
	);

	const createEpcPartnerBreadcrumbList = useMemo(
		() => [
			{ label: 'Projects', href: '/projects', forceRefresh: true },
			{ label: 'Create Project', href: pathname },
			{ label: 'Create EPC Partner' },
		],
		[pathname],
	);

	const editEpcPartnerBreadcrumbList = useMemo(
		() => [
			{ label: 'Projects', href: '/projects', forceRefresh: true },
			{ label: 'Create Project', href: pathname },
			{ label: 'Edit EPC Partner' },
		],
		[pathname],
	);

	const createProjectDeveloperBreadcrumbList = useMemo(
		() => [
			{ label: 'Projects', href: '/projects', forceRefresh: true },
			{ label: 'Create Project', href: pathname },
			{ label: 'Create Project Developer' },
		],
		[pathname],
	);

	const editProjectDeveloperBreadcrumbList = useMemo(
		() => [
			{ label: 'Projects', href: '/projects', forceRefresh: true },
			{ label: 'Create Project', href: pathname },
			{ label: 'Edit Project Developer' },
		],
		[pathname],
	);

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form $isMobile={isMobile} onSubmit={handleSubmit}>
			<Grid container columnSpacing={20}>
				<Grid item xs={12} md={6}>
					<FormField>
						<FormikCheckbox
							id="unknownClient"
							name="unknownClient"
							label={t('Unknown Client')}
							checked={values.unknownClient}
							error={errors.unknownClient}
							touched={touched.unknownClient}
							onChange={handleChange}
							onBlur={handleBlur}
							tooltip={{
								placement: 'top',
								tooltip: t('Tick if the Client is not yet known, e.g. a referral from an EPC Partner.'),
							}}
						/>
					</FormField>
					<FormField>
						<FormikSelect
							isAsync
							id="client"
							name="client"
							label={t('Client')}
							value={values.client}
							error={errors.client}
							touched={touched.client}
							isClearable
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadClientOptions}
							isDisabled={isUnknownClient}
							createLinkTitle="Create Client"
							onCreateClick={() => setIsCreateClientOverlayOpened(true)}
							shouldDefaultOptionsUpdate={clientFormSubmitCount}
							isRequired={!isUnknownClient}
							isOverlay
						/>
						{hasClient && (
							<EditLink
								type="button"
								text={t('Edit Client')}
								onClick={() => setIsEditClientOverlayOpened(true)}
								label="Projects Create Form Edit Client Button"
							/>
						)}
					</FormField>
					<FormField>
						<FormikInput
							id="description"
							name="description"
							label={t('Project Description')}
							value={values.description}
							error={errors.description}
							touched={touched.description}
							onChange={handleChange}
							onBlur={handleBlur}
							tooltip={{
								placement: 'top',
								tooltip: t(
									"A short description of the Project or ID from the EPC Partner, e.g. 'Phase 2', 'Building XYZ'.",
								),
							}}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSelect
							id="responsiblePerson"
							name="responsiblePerson"
							label={t('Responsible Person - Sales')}
							value={values.responsiblePerson}
							error={errors.responsiblePerson}
							touched={touched.responsiblePerson}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							options={responsiblePersonOptions}
							disabled={isLoadingUsers}
							isLoading={isLoadingUsers}
							isRequired
							isOverlay
						/>
					</FormField>
					<RelativeFormField>
						<FormikSelect
							id="countryBase"
							name="country"
							label={t('Country')}
							value={values.country}
							error={errors.country}
							touched={touched.country}
							onBlur={handleBlur}
							options={countries}
							onChange={handleCountryChange}
							isRequired
							isOverlay
						/>
						{showMissingGEFWarning && (
							<ErrorMessage
								message={t('A Grid emission factor for this country does not exist. Please add in Preferences.')}
							/>
						)}
					</RelativeFormField>
					<FormikInput
						id="locationUrl"
						name="locationUrl"
						label={t('Google Maps Link')}
						value={values.locationUrl}
						error={errors.locationUrl}
						touched={touched.locationUrl}
						onChange={handleChange}
						onBlur={handleBlur}
						isOverlay
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormField></FormField>
					<FormField>
						<FormikSelect
							isAsync
							id="projectType"
							name="projectType"
							label={t('Project type')}
							value={values.projectType}
							error={errors.projectType}
							touched={touched.projectType}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadProjectTypeOptions}
							isRequired
							isOverlay
						/>
					</FormField>
					{projectType === projectTypes.PV && (
						<>
							<FormField>
								<FormikInput
									id="systemSizeKwp"
									name="systemSizeKwp"
									label={t('System size (kWp)')}
									value={values.systemSizeKwp}
									error={errors.systemSizeKwp}
									touched={touched.systemSizeKwp}
									onChange={handleChange}
									onBlur={handleBlur}
									isOverlay
								/>
							</FormField>
							<FormField>
								<FormikInput
									id="kwhToKwpRel"
									name="kwhToKwpRel"
									label={t('Yield (kWh/kWp)')}
									value={values.kwhToKwpRel}
									error={errors.kwhToKwpRel}
									touched={touched.kwhToKwpRel}
									onChange={handleChange}
									onBlur={handleBlur}
									isOverlay
								/>
							</FormField>
						</>
					)}
					<FormField>
						<FormikSelect
							isAsync
							id="salesChannel"
							name="salesChannel"
							label={t('Sales channel')}
							value={values.salesChannel}
							error={errors.salesChannel}
							touched={touched.salesChannel}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadSalesChannelOptions}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSelect
							isAsync
							id="epcPartner"
							name="epcPartner"
							label={t('EPC Partner')}
							value={values.epcPartner}
							error={errors.epcPartner}
							touched={touched.epcPartner}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadEpcPartnerOptions}
							createLinkTitle="Create EPC Partner"
							isClearable
							onCreateClick={() => setIsCreateEpcPartnerOverlayOpened(true)}
							shouldDefaultOptionsUpdate={epcPartnerFormSubmitCount}
							isRequired={values?.salesChannel?.label === 'EPC Partner'}
							isOverlay
						/>
						{hasEpcPartner && (
							<EditLink
								type="button"
								text={t('Edit EPC Partner')}
								onClick={() => setIsEditEpcPartnerOverlayOpened(true)}
								label="Projects Create Form Edit EPC Partner Button"
							/>
						)}
					</FormField>
					<FormField>
						<FormikSelect
							isAsync
							id="projectDeveloper"
							name="projectDeveloper"
							label={t('Project developer')}
							value={values.projectDeveloper}
							error={errors.projectDeveloper}
							touched={touched.projectDeveloper}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadProjectDevelopersOptions}
							createLinkTitle="Create Project Developer"
							onCreateClick={() => setIsCreateProjectDevOverlayOpened(true)}
							shouldDefaultOptionsUpdate={projectDevFormSubmitCount}
							isClearable
							isRequired={values?.salesChannel?.label === 'Project Developer'}
							isOverlay
						/>
						{hasProjectDeveloper && (
							<EditLink
								type="button"
								text={t('Edit Project Developer')}
								onClick={() => setIsEditProjectDevOverlayOpened(true)}
								label="Projects Create Form Edit Project Developer Button"
							/>
						)}
					</FormField>
					<FormField>
						<FormikSingleDatePicker
							label={t('Initial Engagement Date')}
							id="initialEngagementDate"
							name="initialEngagementDate"
							startDateName="initialEngagementDate"
							error={errors.initialEngagementDate}
							touched={true}
							startDate={values.initialEngagementDate}
							setFieldValue={setFieldValue}
							isRequired
							isForm
							isOverlay
						/>
					</FormField>
				</Grid>
			</Grid>

			<ClientOverlay
				label="client-create"
				mode={crudModes.CREATE}
				isOpen={isCreateClientOverlayOpened}
				breadcrumbList={createClientBreadcrumbList}
				onClose={() => {
					setIsCreateClientOverlayOpened(false);
				}}
				onFormSubmit={data => {
					handleClientFormSubmit(data);
					setIsCreateClientOverlayOpened(false);
				}}
			/>

			{hasClient && isEditClientOverlayOpened && (
				<ClientOverlay
					label="client-edit"
					mode={crudModes.EDIT}
					isOpen={isEditClientOverlayOpened}
					breadcrumbList={editClientBreadcrumbList}
					onClose={() => setIsEditClientOverlayOpened(false)}
					clientId={values.client.value}
					onFormSubmit={data => {
						handleClientFormSubmit(data);
						setIsEditClientOverlayOpened(false);
					}}
				/>
			)}

			<EpcPartnerOverlay
				mode={crudModes.CREATE}
				isOpen={isCreateEpcPartnerOverlayOpened}
				onClose={() => {
					setIsCreateEpcPartnerOverlayOpened(false);
				}}
				breadcrumbList={createEpcPartnerBreadcrumbList}
				onFormSubmit={handleEpcPartnerFormSubmit}
			/>

			{hasEpcPartner && isEditEpcPartnerOverlayOpened && (
				<EpcPartnerOverlay
					isOpen={isEditEpcPartnerOverlayOpened}
					mode={crudModes.EDIT}
					onFormSubmit={handleEpcPartnerFormSubmit}
					onClose={() => setIsEditEpcPartnerOverlayOpened(false)}
					breadcrumbList={editEpcPartnerBreadcrumbList}
					epcPartnerId={values.epcPartner.value}
				/>
			)}

			<ProjectDeveloperOverlay
				mode={crudModes.CREATE}
				isOpen={isCreateProjectDevOverlayOpened}
				onClose={() => {
					setIsCreateProjectDevOverlayOpened(false);
				}}
				breadcrumbList={createProjectDeveloperBreadcrumbList}
				onFormSubmit={handleProjectDeveloperFormSubmit}
			/>

			{isEditProjectDevOverlayOpened && (
				<ProjectDeveloperOverlay
					mode={crudModes.EDIT}
					isOpen={isEditProjectDevOverlayOpened}
					onClose={() => setIsEditProjectDevOverlayOpened(false)}
					projectDeveloperId={values.projectDeveloper.value}
					breadcrumbList={editProjectDeveloperBreadcrumbList}
					onFormSubmit={handleProjectDeveloperFormSubmit}
				/>
			)}

			<OverlayButtons
				onCancel={onCancel}
				isLoading={isLoading}
				label="Project Create Form"
				submitButtonText={'Create Project'}
			/>
		</Form>
	);
};

ProjectCreateForm.defaultProps = {
	data: null,
};

ProjectCreateForm.propTypes = {
	data: PropTypes.shape({
		localizations: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
	}),
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
};

export default ProjectCreateForm;
