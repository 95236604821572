import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getFilterRange from 'EpcPartner/api/getFilterRange';
import modifyNumberFilterData from 'Common/components/filter/hooks/modifyNumberFilterData';
import loadCountryOptions from 'Country/utils/loadCountryOptions';
import showToastError from 'Common/utils/showToastError';
import { FILTER_TYPES } from 'Common/components/filter/constants/filterConstants';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const fallbackFilterRanges = {
	totalProjectsMax: 0,
	totalProjectsMin: 0,
	totalSystemSizeMax: 0,
	totalSystemSizeMin: 0,
};

const useEpcPartnerListFilterData = () => {
	const newLoad = async () =>
		(await loadCountryOptions()).filter(item => item.value !== 11432 && item.value !== 12035);
	const { t } = useTranslation();

	const [filterRanges, setFilterRanges] = useState(fallbackFilterRanges);

	const abortController = useAbortController();

	const generateFiltersData = [
		{
			name: 'name',
			label: t('Name'),
			type: FILTER_TYPES.INPUT,
		},
		{
			name: 'countryId',
			label: t('Countries'),
			loadOptions: newLoad,
			type: FILTER_TYPES.ASYNC_SELECT,
		},
		{
			name: 'cityRegion',
			label: t('City / region'),
			type: FILTER_TYPES.INPUT,
		},
		{
			name: 'totalProjects',
			label: t('Total Projects'),
			type: FILTER_TYPES.NUMBER,
			minName: 'totalProjectsFrom',
			maxName: 'totalProjectsTo',
			lowerBound: fallbackFilterRanges.totalProjectsMin,
			upperBound: fallbackFilterRanges.totalProjectsMax,
		},
		{
			name: 'totalSystemSize',
			label: t('Total system size(kWp)'),
			type: FILTER_TYPES.NUMBER,
			minProps: { icon: 'kWp', iconPosition: 'right' },
			maxProps: { icon: 'kWp', iconPosition: 'right' },
			minName: 'totalSystemSizeFrom',
			maxName: 'totalSystemSizeTo',
			lowerBound: filterRanges.totalSystemSizeMax,
			upperBound: filterRanges.totalSystemSizeMax,
		},
		{
			name: 'internalQualificationPassed',
			label: t('Internal qualifications passed'),
			type: FILTER_TYPES.SWITCH,
			options: [
				{
					value: 'yes',
					label: 'Yes',
				},
				{
					value: 'no',
					label: 'No',
				},
			],
			hideLabelValue: true,
		},
	];

	useEffect(() => {
		(async () => {
			try {
				const response = await getFilterRange(abortController.signal);

				setFilterRanges(prevFilterRanges => ({
					...prevFilterRanges,
					...response.data,
				}));
			} catch (error) {
				showToastError(error, t("Can't fetch filter ranges"));
				if (!axios.isCancel(error)) {
					setFilterRanges(fallbackFilterRanges);
				}
			}
		})();
	}, [abortController.signal, t]);

	const [filtersData, setFiltersData] = useState(generateFiltersData);

	useEffect(() => {
		const modifyNumberFilterDataBinded = modifyNumberFilterData.bind(undefined, filterRanges);

		setFiltersData(prevFilterData => [...prevFilterData].map(modifyNumberFilterDataBinded));
	}, [filterRanges]);

	return filtersData;
};

export default useEpcPartnerListFilterData;
