import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import H5 from 'Common/components/headers/H5';
import Modal from 'Common/components/modals/Modal';
import Button from 'Common/components/buttons/Button';

import AccountModalForm from 'User/components/AccountModal/AccountModalForm/AccountModalForm';
import useReponsive from 'Common/hooks/useResponsive';

import { css } from 'styled-components';

const StyledModal = styled(Modal)`
	.ReactModal__Content {
		max-width: 580px;
		padding: 32px;
		position: relative;
		overflow: hidden;

		${({ $isMobile }) =>
			$isMobile &&
			css`
				padding: 4px;
			`}
	}
`;

const StyledH5 = styled(H5)`
	text-transform: uppercase;
	font-weight: 700;
	margin: 0 auto;
	font-size: 20px;
`;

const AccountModal = ({ label, isOpen, onClose }) => {
	const { t } = useTranslation();
	const { isMobile } = useReponsive();

	return (
		<StyledModal label={label} isOpen={isOpen} $hasEcoligoBackground $isMobile={isMobile}>
			<Grid container direction="row" justifyContent="flex-end" alignItems="center">
				<Button small icon="close" onClick={onClose} label="Account Lightbox - Close Button" />
			</Grid>
			<Grid container direction="row" justifyContent="center" alignItems="center">
				<StyledH5>{t('My Account')}</StyledH5>
			</Grid>
			<AccountModalForm label="my-account" onClose={onClose} />
		</StyledModal>
	);
};

AccountModal.defaultProps = {
	label: '',
	isOpen: false,
	onClose: () => {},
};

AccountModal.propTypes = {
	isOpen: PropTypes.bool,
	label: PropTypes.string,
	onClose: PropTypes.func,
};

export default AccountModal;
