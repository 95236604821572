import getSalesChannels from 'Preferences/api/getSalesChannels';

const loadSalesChannelOptions = async (signal, query) => {
	const response = await getSalesChannels(signal, query);
	const salesChannels = response.data.content;

	return salesChannels
		.filter(salesChannel => salesChannel.name)
		.map(salesChannel => ({
			value: salesChannel.id,
			label: salesChannel.name,
		}));
};

export default loadSalesChannelOptions;
