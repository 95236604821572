import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import getInquiries from 'Inquiries/api/getInquiries';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import 'rc-pagination/assets/index.css';
import ReactTimeAgo from 'react-time-ago';
import Link from 'Common/components/Link';
import { push } from 'redux-first-history';
import { DefaultTable } from 'Common/components/table/table';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import { useSortBy } from 'react-table';
import { useSortingQueryParameter } from 'Common/hooks/table/plugin-hooks/useSortingQueryParameter';
import { useScrollableTableBody } from 'Common/hooks/table/plugin-hooks/useScrollableTableBody';
import { useVirtualizedTableBody } from 'Common/hooks/table/plugin-hooks/useVirtualizedTableBody';
import { debounce, fill } from 'lodash';
import formatCurrency from 'Common/utils/formatCurrency';
import colors from 'Application/theme/colors';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import defaultTimezone from 'Common/constants/defaultTimezone';
import StatusPill from 'Common/components/StatusPill';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import formatDate from 'Common/utils/formatDate';
import TableCellText from 'Common/components/table/TableCellText';
import showToastError from 'Common/utils/showToastError';
import { PropTypes } from 'prop-types';
import useUniqueAbortSignal from 'Common/hooks/useUniqueAbortSignal';
import prefillTableData from 'Common/utils/prefillTableData';

const Wrapper = styled.div`
	position: relative;
	pointer-events: ${({ hasOpenFilter }) => (hasOpenFilter ? 'none' : 'all')};
`;

const statusMap = {
	NEW: colors.primary.dark,
	OFFER_CREATED: colors.primary.main,
	DECLINED: colors.common.brown,
};

const statusOptions = {
	NEW: 'Inquiry received',
	OFFER_CREATED: 'Offer created',
	DECLINED: 'Declined',
};

const defaultSortBy = {
	id: 'lastModifiedDate',
	desc: true,
};

const sortPropertiesMap = {
	country: 'country.name',
	epcPartner: 'epcPartner.name',
	type: 'projectType.name',
	industry: 'industry.name',
};

const InquiriesListTable = () => {
	const { t } = useTranslation();
	const hasOpenFilter = !!useSelector(state => state?.tables?.inquiries?.openedFilter);

	const [totalItemsCount, setTotalItemsCount] = useState(25);

	const sortByQueryParam = useQueryParameter('sortBy');
	const sortBy = sortByQueryParam ?? defaultSortBy;
	const filters = useQueryParameter('filters');

	const searchQuery = useQueryParameter('searchQuery');

	const dispatch = useDispatch();

	const [inquiries, setInquiries] = useState(() => fill(Array(25), {}));
	const [isLoading, setIsLoading] = useState(true);

	const getUniqueSignal = useUniqueAbortSignal();

	const hasLoadedFirstResultsRef = useRef(false);

	const getInquiriesBatch = useCallback(
		async (startIndex, stopIndex, filters, sortBy, searchQuery, hasItemsChanges = false) => {
			if (hasItemsChanges) {
				setIsLoading(true);
			}

			const sortById = sortPropertiesMap[sortBy.id] ?? sortBy.id;

			const customFilters = { ...filters };

			if (customFilters.lastModifiedDateFrom) {
				customFilters.lastModifiedDateFrom = zonedTimeToUtc(
					format(new Date(customFilters.lastModifiedDateFrom), "yyyy-MM-dd'T'HH:mm.sss'Z'", {
						timeZone: defaultTimezone,
					}),
					defaultTimezone,
				);
			}
			if (customFilters.lastModifiedDateTo) {
				customFilters.lastModifiedDateTo = zonedTimeToUtc(
					format(new Date(customFilters.lastModifiedDateTo), "yyyy-MM-dd'T'HH:mm.sss'Z'", {
						timeZone: defaultTimezone,
					}),
					defaultTimezone,
				);
			}

			try {
				let response = await getInquiries(
					getUniqueSignal('get-inquiries'),
					{
						offset: startIndex,
						limit: stopIndex - startIndex + 1,
						sortBy: sortById,
						sortDirection: sortBy.desc ? 'DESC' : 'ASC',
					},
					customFilters,
					searchQuery,
				);

				if (hasItemsChanges || !hasLoadedFirstResultsRef.current) {
					const { itemsCount, itemsFilled, error } = prefillTableData(response.data, startIndex, stopIndex);
					if (error) {
						showToastError(error);
					} else {
						setInquiries(itemsFilled);
						setTotalItemsCount(itemsCount);
					}
				} else {
					setInquiries(prevInquiries =>
						prevInquiries.map((prevInquiry, index) => {
							if (startIndex <= index && index <= stopIndex) {
								const indexInResultSet = index - startIndex;

								return response.data.content[indexInResultSet];
							}

							return prevInquiry;
						}),
					);
				}

				hasLoadedFirstResultsRef.current = true;

				if (hasItemsChanges) {
					setIsLoading(false);
				}
			} catch (error) {
				showToastError(error);
				return error;
			}
		},
		[getUniqueSignal],
	);

	useEffect(() => {
		getInquiriesBatch(
			0,
			25,
			filters,
			{
				id: sortBy.id,
				desc: sortBy.desc,
			},
			searchQuery,
			true,
		);
	}, [getInquiriesBatch, searchQuery, filters, sortBy.desc, sortBy.id]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleLoadMoreRows = useCallback(
		debounce(
			(startIndex, stopIndex) => {
				getInquiriesBatch(
					startIndex,
					stopIndex,
					filters,
					{
						id: sortBy.id,
						desc: sortBy.desc,
					},
					searchQuery,
				);
			},
			350,
			{
				leading: false,
				trailing: true,
			},
		),
		[getInquiriesBatch, searchQuery, filters, sortBy.desc, sortBy.id],
	);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Status'),
				accessor: 'state',
				highlightSearchTerm: true,
				cellContentStyle: {
					display: 'flex',
				},
				customBodyCellStyles: {
					paddingLeft: '4px',
				},
				Cell: (() => {
					const Cell = ({ row: { original: inquiry } }) => (
						<StatusPill color={statusMap[inquiry?.state]}>{statusOptions?.[inquiry?.state]}</StatusPill>
					);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ state: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Project'),
				accessor: 'projectId',
				width: 80,
				highlightSearchTerm: true,
				cellStyle: {
					justifyContent: 'flex-end',
				},
				Cell: (() => {
					const Cell = ({ row: { original: inquiry } }) => (
						<Link
							color="#00b4d5"
							fontSize="14px"
							to={`/projects/details/${inquiry.projectId}`}
							onClick={e => e.stopPropagation()}
						>
							{inquiry.projectExternalId}
						</Link>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ projectId: PropTypes.number, projectExternalId: PropTypes.number }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('EPC Partner'),
				accessor: 'epcPartner',
				highlightSearchTerm: true,
				Cell: ({ row: { original: inquiry } }) => inquiry.epcPartner?.name ?? '-',
			},
			{
				Header: t('Country'),
				accessor: 'country',
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original: inquiry } }) => (
						<div title={inquiry.country?.name}>{inquiry.country?.name}</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ country: PropTypes.shape({ name: PropTypes.string }) }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Client'),
				accessor: 'clientName',
				highlightSearchTerm: true,
			},
			{
				Header: t('Industry'),
				accessor: 'industry',
				maxWidth: 150,
				highlightSearchTerm: true,
				Cell: ({ row: { original: inquiry } }) => inquiry.industry?.name || '-',
			},
			{
				Header: t('Type'),
				accessor: 'type',
				Cell: ({ row: { original: inquiry } }) => inquiry.projectType?.name ?? '-',
			},
			{
				Header: t('System size (kWp)'),
				accessor: 'systemSizeKwp',
				isNumber: true,
				width: 160,
				highlightSearchTerm: true,
				cellStyle: {
					justifyContent: 'flex-end',
				},
			},
			{
				Header: t('EPC price'),
				accessor: 'epcVolumeExclVat',
				highlightSearchTerm: true,
				cellStyle: {
					justifyContent: 'flex-end',
				},
				Cell: ({ row: { original: inquiry } }) =>
					inquiry?.epcVolumeExclVat
						? formatCurrency(inquiry.epcVolumeExclVat.value, 0, inquiry.epcVolumeExclVat.currency)
						: '-',
			},

			{
				Header: t('Updated'),
				accessor: 'lastModifiedDate',
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				Cell: (() => {
					const Cell = ({ row: { original: inquiry } }) =>
						inquiry.lastModifiedDate ? (
							<TableCellText tooltipContent={formatDate(inquiry?.lastModifiedDate, 'EEEE dd/MM/yyyy HH:mm')}>
								<ReactTimeAgo date={new Date(inquiry?.lastModifiedDate)} timeStyle="round" />
							</TableCellText>
						) : (
							'-'
						);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ lastModifiedDate: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
			},
		],
		[t],
	);

	const data = useMemo(() => inquiries, [inquiries]);

	const itemsHash = useMemo(() => JSON.stringify({ filters, sortBy }), [filters, sortBy]);

	const handleRowClick = useCallback(
		row => {
			if (!window.getSelection().toString()) {
				dispatch(push(`/inquiries/details/${row.original.id}`));
			}
		},
		[dispatch],
	);

	// Fallback to default row id getter
	const getRowId = useCallback(
		(row, relativeIndex, parent) => (parent ? [parent.id, relativeIndex].join('.') : relativeIndex),
		[],
	);

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		getVirtualizedTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useStandardTable(
		{
			data,
			columns,
			defaultSortBy,
			searchTerm: searchQuery,
			onRowClick: handleRowClick,
			virtualization: {
				totalRowsCount: totalItemsCount,
				rowsHash: itemsHash,
				loadMoreRows: handleLoadMoreRows,
			},
			getRowId,
		},
		useClickableTableRow,
		useCustomCellStyles,
		useSortBy,
		useSortingQueryParameter,
		useScrollableTableBody,
		useVirtualizedTableBody,
	);

	return (
		<Wrapper hasOpenFilter={hasOpenFilter}>
			<DefaultTable
				getTableProps={getTableProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				getVirtualizedTableBodyProps={getVirtualizedTableBodyProps}
				rows={rows}
				headerGroups={headerGroups}
				prepareRow={prepareRow}
				isLoading={isLoading || rows.length !== totalItemsCount}
				showNoResultsFound
			/>
		</Wrapper>
	);
};

export default InquiriesListTable;
