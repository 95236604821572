import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';

import ForgottenPasswordForm from 'User/components/ForgottenPassword/ForgottenPasswordForm/ForgottenPasswordForm';
import Button from 'Common/components/buttons/Button';
import { Wrapper } from 'User/components/guestScreenComponents';
import H5 from 'Common/components/headers/H5';
import H6 from 'Common/components/headers/H6';
import backgroundImage from 'Common/assets/images/auth-background.jpg';
import backgroundImageMobile from 'Common/assets/images/auth-background-mobile.jpg';
import useReponsive from 'Common/hooks/useResponsive';

const StyledH5 = styled(H5)`
	display: flex;
	text-transform: uppercase;
	margin-bottom: 8px;
	font-weight: 700;
`;
const StyledH6 = styled(H6)`
	font-weight: 400;
	text-align: start;
	color: ${colors.secondary.light};
	margin-bottom: 35px;
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const TextWrapper = styled.div`
	padding-bottom: 30px;
`;

const Line = styled.div`
	height: 1px;
	width: 32px;
	margin-bottom: 32px;
	background: ${colors.secondary.dark};
`;

const ButtonWrapper = styled.div`
	display: flex;
	height: 100px;
	align-items: flex-end;
	justify-content: flex-end;
`;

const ForgottenPassword = () => {
	const { isMobile } = useReponsive();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);

	const handleFormSuccess = () => setIsEmailSubmitted(true);

	return (
		<>
			<Helmet>
				<title>{t('Forgotten Password')}</title>
			</Helmet>
			<Wrapper backgroundImage={isMobile ? backgroundImageMobile : backgroundImage}>
				{isEmailSubmitted ? (
					<>
						<TitleWrapper>
							<StyledH5>{t('Password Reset E-mail Sent')}</StyledH5>
							<Line />
						</TitleWrapper>
						<TextWrapper>
							<StyledH6>
								{t('If an account with the provided e-mail address exists, an e-mail has been sent to it.')}
							</StyledH6>
							<StyledH6>{t('Check your e-mail and follow the instructions.')}</StyledH6>
						</TextWrapper>
						<ButtonWrapper>
							<Button
								text={t('Back to log in')}
								onClick={() => dispatch(push('/login'))}
								label="Forgotten Password Form - Back to log in Button"
							/>
						</ButtonWrapper>
					</>
				) : (
					<>
						<TitleWrapper>
							<StyledH5>{t('Forgot Password?')}</StyledH5>
							<Line />
						</TitleWrapper>
						<ForgottenPasswordForm onSuccess={handleFormSuccess} />
					</>
				)}
			</Wrapper>
		</>
	);
};

export default ForgottenPassword;
