import api from 'Application/api/api';

const createEpcInquiry = (signal, values) =>
	api.post('/inquiries/partner', values, {
		signal,
		timeout: 100000,
		headers: {
			'Content-Type': 'multipart/form-data',
			Accept: '*/*',
		},
	});

export default createEpcInquiry;
