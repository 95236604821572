import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { omit, pick } from 'lodash';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import Link from 'Common/components/Link';
import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import loadTagOptions from 'Preferences/utils/loadTagOptions';

import editCampaign from 'Campaigns/api/editCampaign';
import useMarketingFormInitialValues from 'Campaigns/components/CampaignDetails/Tabs/GeneralTab/Tiles/MarketingTile/hooks/useMarketingFormInitialValues';
import useMarketingFormValidationSchema from 'Campaigns/components/CampaignDetails/Tabs/GeneralTab/Tiles/MarketingTile/hooks/useMarketingFormValidationSchema';
import TileDynamicSection from 'Common/components/Tile/TileDynamicSection';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const Tags = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const Tag = styled.div`
	display: flex;
	align-items: center;
	margin: 2px 5px 2px 0;
	padding: 3px 10px;
	color: ${colors.text.white};
	background: ${props => (props.isGrey ? colors.grey.light : colors.primary.dark)};
	border-radius: 25px;

	span {
		overflow: hidden;
		padding: 3px;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: 400;
		font-size: 15px;
		line-height: 24px;
	}
`;

const MarketingTile = ({ campaign, onEnterEditMode, onExitEditMode, onDirtyForm, onSave }) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.PM_FUNDRAISING,
		roles.ASSET_MARKETING,
		roles.HEAD_OF_ESG,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const initialValues = useMarketingFormInitialValues(campaign);
	const validationSchema = useMarketingFormValidationSchema();

	const abortController = useAbortController();

	const { errors, touched, values, handleSubmit, dirty, handleBlur, setFieldValue, resetForm } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			setIsSavingChanges(true);
			let response;

			try {
				response = await editCampaign(abortController.signal, {
					...omit(campaign, ['finance_details']),
					...pick(campaign.finance_details, [
						'co2',
						'systemSize',
						'status',
						'frontendReviewPassed',
						'documentsAvailableForDownload',
						'vibApproved',
						'trancheSetupCorrectly',
						'projectPassedPortagonPlausibility',
						'displayName',
					]),
					setTags: {
						tagIds: values.tags.map(option => option.value),
					},
					id: campaign.auto_id,
					externalId: campaign?.id,
					minimumVolume: campaign.minimum_volume,
					maximumVolume: campaign.maximum_volume,
				});

				if (onDirtyForm) {
					onDirtyForm(false);
				}

				onSave(response.data);
				handleCancel();
				setIsSavingChanges(false);
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}

			// Re-set all fields and mark them as not touched
			resetForm({ values });
		},
	});

	const maxLength = 26;

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Marketing')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<TileDynamicSection
				isInEditMode={isInEditMode}
				editView={
					<>
						<FormField id="tags">
							<FormikSelect
								id="tags"
								name="tags"
								label={t('Tags')}
								value={values.tags}
								error={errors.tags}
								touched={touched.tags}
								isClearable
								setFieldValue={setFieldValue}
								onBlur={handleBlur}
								loadOptions={loadTagOptions}
								isMulti
								isAsync
								isTile
								isInEditMode={isInEditMode}
								tooltip={{
									tooltip: t('To re-order tags, remove them and add in the required order.'),
								}}
							/>
						</FormField>
						<Link color="#00b4d5" to="/preferences?tab=marketing" target="_blank">
							<span>{t('Manage tags')}</span>
						</Link>
					</>
				}
				readonlyView={
					<KeyValueVisualization
						title={t('Tags')}
						value={
							<Tags>
								{campaign.finance_details?.tagRefs?.map(tag => (
									<Tag key={tag.tagId}>
										<span>
											{tag.tagName.length > maxLength ? tag.tagName.substring(0, maxLength) + '...' : tag.tagName}
										</span>
									</Tag>
								))}
							</Tags>
						}
					/>
				}
				uniqueId="marketing"
			/>
		</EditableTile>
	);
};

MarketingTile.defaultProps = {
	campaign: {},
	onSave: () => {},
	onDirtyForm: () => {},
	onExitEditMode: () => {},
	onEnterEditMode: () => {},
};

MarketingTile.propTypes = {
	onSave: PropTypes.func,
	campaign: PropTypes.shape({
		finance_details: PropTypes.shape({
			tagRefs: PropTypes.arrayOf(PropTypes.shape({ tagName: PropTypes.string })),
		}),
		auto_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		minimum_volume: PropTypes.string,
		maximum_volume: PropTypes.string,
	}),
	onDirtyForm: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onEnterEditMode: PropTypes.func,
};

export default MarketingTile;
