import { useMemo } from 'react';

const fallbackValues = {
	countryIds: [],
	apartmentBuilding: '',
	line1: '',
	line2: '',
	cityRegion: '',
	postCodeZip: '',
};

const useAddressFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			countryIds: data?.countries?.map(country => ({
				value: country.id,
				label: country.name,
			})),
			apartmentBuilding: data?.address?.apartmentBuilding ?? '',
			line1: data?.address?.line1 ?? '',
			line2: data?.address?.line2 ?? '',
			cityRegion: data?.address?.cityRegion ?? '',
			postCodeZip: data?.address?.postCodeZip ?? '',
		}),
		[data],
	);

export default useAddressFormInitialValues;
