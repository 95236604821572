import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';
import yupUrl from 'Common/utils/yupUrl';

const useManufacturerOverlayValidationSchema = productTypeForNewManufacturer => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				displayName: yup.string().required(t('Required')),
				website: yupUrl().nullable(),
				apartmentBuilding: yup.string(),
				line1: yup.string(),
				line2: yup.string(),
				cityRegion: yup.string(),
				postCodeZip: yup.string(),
				productTypes: yup
					.array()
					.test('productTypes', t('At least 1 Product should be selected'), value => value && value.length > 0)
					.test(
						'isSelectedType',
						t(`${productTypeForNewManufacturer} must be included in the selected product types`),
						value =>
							productTypeForNewManufacturer ? value.find(el => el.value === productTypeForNewManufacturer) : true,
					)
					.nullable(),
			}),
		[t, productTypeForNewManufacturer],
	);
};

export default useManufacturerOverlayValidationSchema;
