import { store } from 'Application/store';
import { push } from 'redux-first-history';
import { clearUserData } from 'User/reduxUser';
import { setTablesToIntital } from 'Application/reducers/reduxTables';
import logoutRequest from 'User/api/logout';

const logout = async () => {
	await logoutRequest();

	store.dispatch(clearUserData());
	store.dispatch(setTablesToIntital());
	store.dispatch(push('/login'));
};

export default logout;
