import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import crudModes from 'Common/constants/crudModes';
import getIndustryById from 'Industries/api/getIndustryById';
import IndustriesForm from './IndustriesForm/IndustriesForm';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PropTypes } from 'prop-types';

const IndustriesEditModal = ({ isOpen, onClose, industryId, onFormSubmit }) => {
	const { t } = useTranslation();

	const [industry, setIndustry] = useState(null);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				const response = await getIndustryById(abortController.signal, industryId);

				setIndustry(response.data);
			} catch (error) {
				showToastError(error);
			}
		})();
	}, [industryId, abortController.signal]);

	const isDataLoading = industry === null;

	return (
		<ModalForm
			label="industry-edit"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			isLoadingContent={isDataLoading}
			heading={t('Edit Industry')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<IndustriesForm
					data={industry}
					mode={crudModes.EDIT}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

IndustriesEditModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	industryId: PropTypes.string.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default IndustriesEditModal;
