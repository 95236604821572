import PropTypes from 'prop-types';
import Filter from 'Common/components/filter/Filter';
import FormikDatePicker from 'Common/components/form/FormikDatePicker';
import parseISO from 'date-fns/parseISO';
import { useMediaQuery } from '@mui/material';
import { useMemo } from 'react';
import { format } from 'date-fns';

const DateRangeFilter = ({
	label,
	isLocalDate,
	startDateName,
	endDateName,
	startDateValue,
	endDateValue,
	setFilterValue,
	...props
}) => {
	const widthQuery = useMediaQuery('(max-width:1200px)');
	const heightQuery = useMediaQuery('(max-height:700px)');
	const useModal = widthQuery || heightQuery;

	const handleClear = () => {
		setFilterValue({
			[startDateName]: undefined,
			[endDateName]: undefined,
		});
	};

	const startDate = useMemo(() => (startDateValue ? parseISO(startDateValue) : undefined), [startDateValue]);
	const endDate = useMemo(() => (endDateValue ? parseISO(endDateValue) : undefined), [endDateValue]);

	const _setFilterValue = (name, value) => {
		// ? Local Dates formats to yyyy-MM-dd instead of ISO
		const newDateValue = value
			? isLocalDate
				? format(new Date(value), 'yyyy-MM-dd')
				: new Date(value).toISOString()
			: undefined;

		setFilterValue({
			[name]: newDateValue,
		});
	};

	return (
		<Filter
			label={label}
			hasSelected={!!startDateValue || !!endDateValue}
			handleClearOptions={handleClear}
			useModal={useModal}
			{...props}
			popupContent={
				<FormikDatePicker
					startDateId={startDateName}
					endDateId={endDateName}
					startDateName={startDateName}
					endDateName={endDateName}
					startDateValue={startDate}
					endDateValue={endDate}
					setFieldValue={_setFilterValue}
					onClearAll={handleClear}
					isDual
					{...props}
				/>
			}
		/>
	);
};

DateRangeFilter.defaultProps = {
	isLocalDate: false,
	startDateValue: null,
	endDateValue: null,
};

DateRangeFilter.propTypes = {
	isLocalDate: PropTypes.bool,
	label: PropTypes.string.isRequired,
	startDateName: PropTypes.string.isRequired,
	endDateName: PropTypes.string.isRequired,
	startDateValue: PropTypes.string,
	endDateValue: PropTypes.string,
	setFilterValue: PropTypes.func.isRequired,
};

export default DateRangeFilter;
