import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import getProjectById from 'Projects/api/getProjectById';

const checkExchangeRateDate = async (signal, projectId, currency, oldExchangeRateDate) => {
	const projectResponse = await getProjectById(signal, projectId, true);

	const newExchangeRateDate = projectResponse.data.currencies.find(
		projectCurrency => projectCurrency.isoCode === currency,
	)?.exchangeRateDate;

	if (Boolean(oldExchangeRateDate) && oldExchangeRateDate !== newExchangeRateDate) {
		return notify(
			`Project Exchange rate date changed to ${newExchangeRateDate} because some exchange rates were not available for the selected day `,
			{
				type: toast.TYPE.WARNING,
			},
		);
	} else if (oldExchangeRateDate === newExchangeRateDate) {
		return null;
	} else {
		return notify(`Project Exchange rate date set to ${newExchangeRateDate}`, {
			type: toast.TYPE.SUCCESS,
		});
	}
};

export default checkExchangeRateDate;
