import { useTranslation } from 'react-i18next';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import { components } from 'react-select';
import { PropTypes } from 'prop-types';
import { SectionWrapper } from 'Common/components/modals/OverlaySections';
import { Grid } from '@mui/material';
import UNITS from 'Common/constants/units';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import { useCallback } from 'react';
import loadClientContractTypeOptions from '../../../../ClientContract/utils/loadClientContractTypeOptions';
import { contractTypes } from '../../../../ClientContract/constants/contractTypes';
import { ppaTariffTypes } from '../../../../ClientContract/constants/ppaTariffTypes';
import ComparableFormField from 'Common/components/form/ComparableFormField';
import ConditionalGridSection from 'Common/components/ConditionalGridSection';
import Big from 'big.js';
import crudModes from 'Common/constants/crudModes';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

const SelectInput = ({ selectProps: { onPaste }, ...props }) => (
	<components.Input {...props} onPaste={onPaste} />
);

SelectInput.defaultProps = {
	selectProps: {
		onPaste: () => null,
	},
};

SelectInput.propTypes = {
	selectProps: PropTypes.shape({
		onPaste: PropTypes.func,
	}),
};

// eslint-disable-next-line complexity
const CommercialInputsSection = ({
	errors,
	touched,
	values,
	handleChange,
	handleBlur,
	setFieldValue,
	mode,
}) => {
	const { t } = useTranslation();

	const CurrencySign = values.saasCurrency
		? () => <>{values.saasCurrency.value === 'EUR' ? '€' : values.saasCurrency.value}</>
		: null;

	const loadPpaTariffTypes = useCallback(async () => {
		const options = Object.entries(ppaTariffTypes).map(([value, label]) => ({ label, value }));
		return Promise.resolve(options);
	}, []);

	return mode !== crudModes.VIEW ? (
		<SectionWrapper>
			<Grid container columnSpacing={10}>
				<Grid item xs={12} md={6}>
					<ComparableFormField
						$compareValue={values.compareOffer ? values.primaryOfferData.contractTypeName : ''}
						data-first-field
					>
						<FormikSelect
							id="contractType"
							name="contractType"
							label={t('Contract Type')}
							error={errors.contractType}
							touched={touched.contractType}
							value={values.contractType}
							setFieldValue={setFieldValue}
							loadOptions={loadClientContractTypeOptions}
							onBlur={handleBlur}
							isAsync
							isRequired
							isOverlay
						/>
					</ComparableFormField>
					<ComparableFormField $compareValue={values.compareOffer ? values.primaryOfferData.duration : ''}>
						<FormikInput
							id="contractTerm"
							name="contractTerm"
							label={t('Contract Term')}
							value={values.contractTerm}
							error={errors.contractTerm}
							touched={touched.contractTerm}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
							isRequired
						/>
					</ComparableFormField>
					<ComparableFormField
						$compareValue={
							values.compareOffer && values.primaryOfferData.escalationRateInPercent
								? `${Big(values.primaryOfferData.escalationRateInPercent).times(100).toString()} %`
								: ''
						}
					>
						<FormikInput
							id="escalationRateInPercent"
							name="escalationRateInPercent"
							label={t('Escalation Rate')}
							value={values.escalationRateInPercent}
							error={errors.escalationRateInPercent}
							touched={touched.escalationRateInPercent}
							onChange={handleChange}
							onBlur={handleBlur}
							unit={UNITS.PERCENT}
							isOverlay
						/>
					</ComparableFormField>
					<ConditionalGridSection
						hasFlag={values.contractType?.label && values.contractType?.label === contractTypes.PPA}
					>
						<ComparableFormField
							$compareValue={
								values.compareOffer && values.primaryOfferData.ppaTariffType
									? values.primaryOfferData.ppaTariffType.displayName
									: ''
							}
						>
							<FormikSelect
								id="ppaTariffType"
								name="ppaTariffType"
								label={t('PPA Tariff Type')}
								error={errors.ppaTariffType}
								touched={touched.ppaTariffType}
								value={values.ppaTariffType}
								setFieldValue={setFieldValue}
								loadOptions={loadPpaTariffTypes} // TODO: load async from BE
								onBlur={handleBlur}
								isAsync
								isRequired
								isOverlay
							/>
						</ComparableFormField>
					</ConditionalGridSection>
					<ConditionalGridSection hasFlag={values.contractType?.label === contractTypes.Lease}>
						<ComparableFormField
							$compareValue={values.compareOffer ? values.primaryOfferData.leaseFee : ''}
							data-first-field
						>
							<FormikInput
								id="leaseFee"
								name="leaseFee"
								label={t('Lease Fee')}
								value={values.leaseFee}
								error={errors.leaseFee}
								touched={touched.leaseFee}
								onChange={handleChange}
								onBlur={handleBlur}
								isOverlay
							/>
						</ComparableFormField>
					</ConditionalGridSection>

					<ConditionalGridSection
						hasFlag={
							values.contractType?.label === contractTypes.PPA &&
							values.ppaTariffType?.label === ppaTariffTypes.FIXED
						}
					>
						<ComparableFormField
							$compareValue={
								values.compareOffer && values.primaryOfferData.ppaPricePerKwh
									? values.primaryOfferData.ppaPricePerKwh.valueWithCurrency
									: ''
							}
							data-first-field
						>
							<FormikInput
								id="ppaTariff"
								name="ppaTariff"
								label={t('PPA Tariff')}
								value={values.ppaTariff}
								error={errors.ppaTariff}
								touched={touched.ppaTariff}
								onChange={handleChange}
								onBlur={handleBlur}
								isOverlay
							/>
						</ComparableFormField>
					</ConditionalGridSection>
					<ConditionalGridSection
						hasFlag={
							values.contractType?.label === contractTypes.PPA &&
							values.ppaTariffType?.label === ppaTariffTypes.FLOATING
						}
					>
						<>
							<ComparableFormField
								$compareValue={
									values.compareOffer && values.primaryOfferData.percentageOnGridTariff
										? `${Big(values.primaryOfferData.percentageOnGridTariff).times(100).toString()} %`
										: ''
								}
								data-first-field
							>
								<FormikInput
									id="percentageOnGridTariff"
									name="percentageOnGridTariff"
									label={t('% on Grid Tariff')}
									value={values.percentageOnGridTariff}
									error={errors.percentageOnGridTariff}
									touched={touched.percentageOnGridTariff}
									onChange={handleChange}
									onBlur={handleBlur}
									unit={UNITS.PERCENT}
									isOverlay
								/>
							</ComparableFormField>
							<ComparableFormField
								$compareValue={
									values.compareOffer && values.primaryOfferData.floorTariff
										? values.primaryOfferData.floorTariff.valueWithCurrency
										: ''
								}
							>
								<FormikInput
									id="floorTariff"
									name="floorTariff"
									label={t('Floor Tariff')}
									value={values.floorTariff}
									error={errors.floorTariff}
									touched={touched.floorTariff}
									onChange={handleChange}
									onBlur={handleBlur}
									prefix={CurrencySign}
									isOverlay
								/>
							</ComparableFormField>
							<ComparableFormField
								$compareValue={
									values.compareOffer && values.primaryOfferData.ceilingTariff
										? values.primaryOfferData.ceilingTariff.valueWithCurrency
										: ''
								}
							>
								<FormikInput
									id="ceilingTariff"
									name="ceilingTariff"
									label={t('Ceiling Tariff')}
									value={values.ceilingTariff}
									error={errors.ceilingTariff}
									touched={touched.ceilingTariff}
									onChange={handleChange}
									onBlur={handleBlur}
									prefix={CurrencySign}
									isOverlay
								/>
							</ComparableFormField>
						</>
					</ConditionalGridSection>
				</Grid>
				<Grid item xs={12} md={6}>
					<ComparableFormField $compareValue={values.compareOffer ? values.primaryOfferData.saasCurrency : ''}>
						<FormikSelect
							isAsync
							id="saasCurrency"
							name="saasCurrency"
							label={t('SaaS Currency')}
							value={values.saasCurrency}
							error={errors.saasCurrency}
							touched={touched.saasCurrency}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadCurrenciesOptions}
							isRequired
							isOverlay
						/>
					</ComparableFormField>
					<ConditionalGridSection hasFlag={values.contractType?.label === contractTypes.PPA}>
						<ComparableFormField
							$compareValue={
								values.compareOffer && values.primaryOfferData.minimumPurchaseAmount
									? `${Big(values.primaryOfferData.minimumPurchaseAmount).times(100).toString()} %`
									: ''
							}
						>
							<FormikInput
								id="minimumPurchaseAmount"
								name="minimumPurchaseAmount"
								label={t('Minimum Purchase Amount')}
								value={values.minimumPurchaseAmount}
								error={errors.minimumPurchaseAmount}
								touched={touched.minimumPurchaseAmount}
								onChange={handleChange}
								onBlur={handleBlur}
								unit={UNITS.PERCENT}
								isOverlay
							/>
						</ComparableFormField>
					</ConditionalGridSection>
					<ConditionalGridSection hasFlag={values.contractType?.label === contractTypes.PPA}>
						<FormikInput
							id="minimumWarrantedFunctionality"
							name="minimumWarrantedFunctionality"
							label={t('Minimum Warranted Functionality')}
							value={values.minimumWarrantedFunctionality}
							error={errors.minimumWarrantedFunctionality}
							touched={touched.minimumWarrantedFunctionality}
							onChange={handleChange}
							onBlur={handleBlur}
							unit={UNITS.PERCENT}
							isOverlay
						/>
					</ConditionalGridSection>
					<ConditionalGridSection hasFlag={values.contractType?.label === contractTypes.PPA}>
						<FormikInput
							id="discountOnGridTariff"
							name="discountOnGridTariff"
							label={t('Discount on Grid Tariff')}
							value={values.discountOnGridTariff}
							error={errors.discountOnGridTariff}
							touched={touched.discountOnGridTariff}
							onChange={handleChange}
							onBlur={handleBlur}
							unit={UNITS.PERCENT}
							isOverlay
						/>
					</ConditionalGridSection>
					<ComparableFormField
						$compareValue={
							values.compareOffer && values.primaryOfferData.clientUpfrontPayment
								? values.primaryOfferData.clientUpfrontPayment.valueWithCurrency
								: ''
						}
					>
						<FormikInput
							id="clientDownpayment"
							name="clientDownpayment"
							label={t('Client Down-payment')}
							value={values.clientDownpayment}
							error={errors.clientDownpayment}
							touched={touched.clientDownpayment}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</ComparableFormField>
					<ComparableFormField
						$compareValue={values.compareOffer ? values.primaryOfferData.billingPeriod : ''}
						data-last-field
					>
						<FormikInput
							label={t('Billing period')}
							id="billingPeriod"
							name="billingPeriod"
							error={errors.billingPeriod}
							touched={touched.billingPeriod}
							value={values.billingPeriod}
							onChange={handleChange}
							onBlur={handleBlur}
							tooltip={t("How often in months are the invoices issues (for example '1’ means every month)")}
							isOverlay
						/>
					</ComparableFormField>
				</Grid>
			</Grid>
		</SectionWrapper>
	) : (
		<SectionWrapper>
			<Grid container columnSpacing={10}>
				<Grid item xs={12} md={6}>
					<KeyValueVisualization id="contractType" title={t('Contract Type')} value={values.contractType?.label} />
					<KeyValueVisualization id="contractTerm" title={t('Contract Term')} value={values.contractTerm} />
					<KeyValueVisualization
						id="escalationRateInPercent"
						title={t('Escalation Rate')}
						value={values.escalationRateInPercent}
						unit={UNITS.PERCENT}
					/>
					{values.contractType?.label && values.contractType?.label === contractTypes.PPA && (
						<KeyValueVisualization
							id="ppaTariffType"
							title={t('PPA Tariff Type')}
							value={values.ppaTariffType?.label}
						/>
					)}
					{values.contractType?.label === contractTypes.Lease && (
						<KeyValueVisualization id="leaseFee" title={t('Lease Fee')} value={values.leaseFee} />
					)}
					{values.contractType?.label === contractTypes.PPA &&
						values.ppaTariffType?.label === ppaTariffTypes.FIXED && (
							<KeyValueVisualization id="ppaTariff" title={t('PPA Tariff')} value={values.ppaTariff} />
						)}
					{values.contractType?.label === contractTypes.PPA &&
						values.ppaTariffType?.label === ppaTariffTypes.FLOATING && (
							<>
								<KeyValueVisualization
									id="percentageOnGridTariff"
									title={t('% on Grid Tariff')}
									value={values.percentageOnGridTariff}
									unit={UNITS.PERCENT}
								/>
								<KeyValueVisualization
									id="floorTariff"
									title={t('Floor Tariff')}
									value={values.floorTariff}
									prefix={CurrencySign}
								/>
								<KeyValueVisualization
									id="ceilingTariff"
									title={t('Ceiling Tariff')}
									value={values.ceilingTariff}
									prefix={CurrencySign}
								/>
							</>
						)}
				</Grid>
				<Grid item xs={12} md={6}>
					<KeyValueVisualization id="saasCurrency" title={t('SaaS Currency')} value={values.saasCurrency?.label} />
					<KeyValueVisualization
						id="solarUtilizationRate"
						title={t('Solar Utilization Rate')}
						value={values.solarUtilizationRate}
						unit={UNITS.PERCENT}
					/>
					{values.contractType?.label === contractTypes.PPA && (
						<KeyValueVisualization
							id="minimumPurchaseAmount"
							title={t('Minimum Purchase Amount')}
							value={values.minimumPurchaseAmount}
							unit={UNITS.PERCENT}
						/>
					)}
					<KeyValueVisualization
						id="clientDownpayment"
						title={t('Client Down-payment')}
						value={values.clientDownpayment}
					/>
					<KeyValueVisualization id="billingPeriod" title={t('Billing period')} value={values.billingPeriod} />
				</Grid>
			</Grid>
		</SectionWrapper>
	);
};

CommercialInputsSection.defaultProps = {
	selectedPrimaryOption: {},
};

CommercialInputsSection.propTypes = {
	selectedPrimaryOption: PropTypes.shape({
		name: PropTypes.string,
	}),
	errors: PropTypes.shape({
		contractType: PropTypes.string,
		contractTerm: PropTypes.string,
		escalationRateInPercent: PropTypes.string,
		ppaTariffType: PropTypes.string,
		saasCurrency: PropTypes.string,
		solarUtilizationRate: PropTypes.string,
		minimumPurchaseAmount: PropTypes.string,
		minimumWarrantedFunctionality: PropTypes.string,
		discountOnGridTariff: PropTypes.string,
		clientDownpayment: PropTypes.string,
		billingPeriod: PropTypes.string,
		leaseFee: PropTypes.string,
		ppaTariff: PropTypes.string,
		ceilingTariff: PropTypes.string,
		floorTariff: PropTypes.string,
		percentageOnGridTariff: PropTypes.string,
	}).isRequired,
	touched: PropTypes.shape({
		contractType: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.shape({ label: PropTypes.bool, value: PropTypes.bool }),
		]),
		contractTerm: PropTypes.bool,
		escalationRateInPercent: PropTypes.bool,
		ppaTariffType: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.shape({ label: PropTypes.bool, value: PropTypes.bool }),
		]),
		saasCurrency: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.shape({ label: PropTypes.bool, value: PropTypes.bool }),
		]),
		solarUtilizationRate: PropTypes.bool,
		minimumPurchaseAmount: PropTypes.bool,
		minimumWarrantedFunctionality: PropTypes.bool,
		discountOnGridTariff: PropTypes.bool,
		clientDownpayment: PropTypes.bool,
		billingPeriod: PropTypes.bool,
		leaseFee: PropTypes.bool,
		ppaTariff: PropTypes.bool,
		ceilingTariff: PropTypes.bool,
		floorTariff: PropTypes.bool,
		percentageOnGridTariff: PropTypes.bool,
	}).isRequired,
	values: PropTypes.shape({
		contractType: PropTypes.shape({ label: PropTypes.string, value: PropTypes.number }),
		currency: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
		contractTerm: PropTypes.string,
		escalationRateInPercent: PropTypes.string,
		ppaTariffType: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
		saasCurrency: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
		solarUtilizationRate: PropTypes.string,
		minimumPurchaseAmount: PropTypes.string,
		minimumWarrantedFunctionality: PropTypes.string,
		discountOnGridTariff: PropTypes.string,
		clientDownpayment: PropTypes.string,
		compareOffer: PropTypes.bool,
		billingPeriod: PropTypes.string,
		leaseFee: PropTypes.string,
		ppaTariff: PropTypes.string,
		ceilingTariff: PropTypes.string,
		floorTariff: PropTypes.string,
		percentageOnGridTariff: PropTypes.string,
		primaryOfferData: PropTypes.shape({
			contractTypeName: PropTypes.string,
			duration: PropTypes.string,
			escalationRateInPercent: PropTypes.string,
			ppaTariffType: PropTypes.string,
			saasCurrency: PropTypes.string,
			clientUpfrontPayment: PropTypes.shape({ valueWithCurrency: PropTypes.string }),
			solarUtilizationRate: PropTypes.string,
			minimumPurchaseAmount: PropTypes.string,
			billingPeriod: PropTypes.string,
			leaseFee: PropTypes.string,
			ppaTariff: PropTypes.string,
			ceilingTariff: PropTypes.string,
			floorTariff: PropTypes.string,
			percentageOnGridTariff: PropTypes.string,
			ppaPricePerKwh: PropTypes.shape({ valueWithCurrency: PropTypes.string }),
		}),
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	handleBlur: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
};

export default CommercialInputsSection;
