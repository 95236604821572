import { useMemo } from 'react';

const fallbackValues = {
	country: null,
	apartmentBuilding: '',
	line1: '',
	line2: '',
	cityRegion: '',
	postCodeZip: '',
};

const useAddressFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			country: data?.country
				? {
						value: data?.country.id,
						label: data?.country.name,
				  }
				: null,
			apartmentBuilding: data?.address?.apartmentBuilding ?? '',
			line1: data?.address?.line1 ?? '',
			line2: data?.address?.line2 ?? '',
			cityRegion: data?.address?.cityRegion ?? '',
			postCodeZip: data?.address?.postCodeZip ?? '',
		}),
		[data],
	);

export default useAddressFormInitialValues;
