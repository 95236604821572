import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FILTER_TYPES } from 'Common/components/filter/constants/filterConstants';

const useInvestorsListFilterData = () => {
	const { t } = useTranslation();

	const filtersData = useMemo(
		() => [
			{
				name: 'firstName',
				label: t('First Name'),
				type: FILTER_TYPES.INPUT,
			},
			{
				name: 'lastName',
				label: t('Last Name'),
				type: FILTER_TYPES.INPUT,
			},
			{
				name: 'email',
				label: t('E-mail'),
				type: FILTER_TYPES.INPUT,
			},
			{
				name: 'createdAt',
				label: t('Registered'),
				type: FILTER_TYPES.DATE,
				startDateName: 'createdAtFrom',
				endDateName: 'createdAtTo',
			},
		],
		[t],
	);

	return filtersData;
};

export default useInvestorsListFilterData;
