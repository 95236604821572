import 'Application/polyfills';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { createRoot } from 'react-dom/client';

import 'Application/i18n';
import 'react-app-polyfill/stable';
import 'react-popper-tooltip/dist/styles.css';

import Root from 'Application/components/Root';

import reportWebVitals from './reportWebVitals';

TimeAgo.addDefaultLocale(en);
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
