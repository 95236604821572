import sizes from 'Application/theme/sizes';
import styled from 'styled-components';

const ClearFilterButton = styled.div`
	width: ${sizes.base(5)};
	position: relative;
	line-height: normal;
	&:hover {
		&:after {
			color: red;
		}
	}
	&:after {
		content: '+';
		position: absolute;
		transform: rotate(45deg) translate(-50%, -50%);
		font-size: ${sizes.base(7)};
		font-weight: 300;
	}
`;

export default ClearFilterButton;
