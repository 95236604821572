import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import HoverTooltip from '../tooltip/HoverTooltip';
import Icon from 'Common/components/icons/Icon';

const CenteredDiv = styled.div`
	display: flex;
	align-items: center;
`;

const MenuIcon = ({ icon, withTooltip, tooltipText, ...props }) => (
	<HoverTooltip placement="right" title={withTooltip ? tooltipText : ''} arrow>
		<CenteredDiv>
			<Icon icon={icon} {...props} />
		</CenteredDiv>
	</HoverTooltip>
);

MenuIcon.defaultProps = {
	icon: 'default',
	withTooltip: false,
	tooltipText: '',
};

MenuIcon.propTypes = {
	icon: PropTypes.string,
	withTooltip: PropTypes.bool,
	tooltipText: PropTypes.string,
};

export default MenuIcon;
