import { useEffect, useMemo, useState } from 'react';
import { css } from 'styled-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import getEmissionSpvs from 'Preferences/api/getEmissionSpvs';
import deleteEmissionSpv from 'Preferences/api/deleteEmissionSpv';
import EmissionSpvOverlay from 'Preferences/components/Tabs/CampaignsTab/EmissionsSpv/EmissionSpvForm/EmissionSpvOverlay';

import crudModes from 'Common/constants/crudModes';
import notify from 'Common/utils/notify';
import EditableTile from 'Common/components/Tile/EditableTile';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { DefaultTable, TableHeadCell, TableBodyCell } from 'Common/components/table/table';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}

	${TableBodyCell} {
		background: transparent;
	}
`;

const EmissionSpvsList = () => {
	const { t } = useTranslation();

	const [emissionSpvs, setEmissionSpvs] = useState([]);
	const [emissionSpvIdForEditing, setEmissionSpvIdForEditing] = useState(null);
	const [emissionSpvIdForDeletion, setEmissionSpvIdForDeletion] = useState(null);
	const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
	const [deletionError, setDeletionError] = useState(null);
	const [isLoadingContent, setIsLoadingContent] = useState(false);

	const abortController = useAbortController();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Name'),
				accessor: 'name',
			},
			{
				Header: t('Abbr.'),
				accessor: 'abbreviation',
			},
			{
				Header: t('Countries'),
				accessor: 'countries',
			},
			{
				Header: t('Description'),
				accessor: 'description',
				customCellContentStyles: {
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
					display: 'block',
				},
			},
			{
				Header: t('Balance'),
				accessor: 'balance',
			},
			{
				Header: t('Profit'),
				accessor: 'profit',
			},
			{
				Header: '',
				accessor: 'actionDots',
				maxWidth: 70,
				customCellStyles: {
					justifyContent: 'flex-end',
				},
				customBodyCellContentStyles: {
					padding: '5px',
				},
			},
		],
		[t],
	);

	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			emissionSpvs.map(emissionSpv => ({
				...emissionSpv,
				name: emissionSpv?.name || '-',
				countries: emissionSpv?.countries ? emissionSpv?.countries.map(country => country.name).join(', ') : '-',
				balance: emissionSpv?.balances?.length > 0 ? emissionSpv?.balances[0].value : '-',
				profit: emissionSpv?.profits?.length > 0 ? emissionSpv?.profits[0].value : '-',
				actionDots: (
					<MoreActionsButton label="Preference Campaigns Emission More Actions" controlledVisibility>
						<MenuItem
							data-action="edit"
							onClick={() => setEmissionSpvIdForEditing(emissionSpv.id)}
							label="Preference Campaigns Emission - Edit Menu Item"
						>
							Edit
						</MenuItem>
						<MenuItem
							data-action="delete"
							onClick={() => setEmissionSpvIdForDeletion(emissionSpv.id)}
							label="Preference Campaigns Emission - Delete Menu Item"
						>
							Delete
						</MenuItem>
					</MoreActionsButton>
				),
			})),
		[emissionSpvs],
	);

	useEffect(() => {
		(async () => {
			try {
				setIsLoadingContent(true);
				const response = await getEmissionSpvs(abortController.signal);
				setIsLoadingContent(false);
				setEmissionSpvs(response.data);
			} catch (error) {
				showToastError(error);

				if (!axios.isCancel(error)) {
					setIsLoadingContent(false);
				}
			}
		})();
	}, [abortController.signal]);

	const handleDataUpdate = data => {
		setEmissionSpvs(data);
	};

	const handleDeleteEmissionSpv = async id => {
		try {
			await deleteEmissionSpv(abortController.signal, id);

			notify(t('Emission SPV deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});

			const response = await getEmissionSpvs(abortController.signal);
			handleDataUpdate(response.data);
			setEmissionSpvIdForDeletion(null);
		} catch (error) {
			if (!axios.isCancel(error)) {
				setDeletionError(error.response.data);
				setEmissionSpvIdForDeletion(null);
			}
		}
	};

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
			},
			useCustomCellStyles,
		);

	const emissionSpvForDeletion = emissionSpvIdForDeletion
		? emissionSpvs.find(emissionSpv => emissionSpv.id === emissionSpvIdForDeletion)
		: null;

	const hasEmissionSpvForDeletion = !!emissionSpvForDeletion && !!emissionSpvIdForDeletion;

	return (
		<EditableTile
			title={t('Emissions SPVs')}
			rightComponent={
				<TileActionButton data-button="emissionsSPV" onClick={() => setIsCreateModalOpened(true)} />
			}
			isLoadingContent={isLoadingContent}
			isTable
		>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
				/>
			</TableWrapper>
			{isCreateModalOpened && (
				<EmissionSpvOverlay
					isOpen={isCreateModalOpened}
					onClose={() => setIsCreateModalOpened(false)}
					mode={crudModes.CREATE}
					onFormSubmit={handleDataUpdate}
				/>
			)}
			{!isCreateModalOpened && emissionSpvIdForEditing && (
				<EmissionSpvOverlay
					isOpen={emissionSpvIdForEditing !== null}
					onClose={() => setEmissionSpvIdForEditing(null)}
					mode={crudModes.EDIT}
					onFormSubmit={handleDataUpdate}
					id={emissionSpvIdForEditing}
				/>
			)}

			{hasEmissionSpvForDeletion && (
				<ConfirmModal
					isOpen
					onCancel={() => setEmissionSpvIdForDeletion(null)}
					onConfirm={() => handleDeleteEmissionSpv(emissionSpvIdForDeletion)}
					label="delete-emission-spv"
					heading={t('Delete Emission SPV')}
					text={t(`Are you sure you want to delete Emission SPV ${emissionSpvForDeletion.name} `)}
				/>
			)}

			<UnsuccessfulOperationModal
				label="cannot-delete-emission-spv"
				isOpen={deletionError !== null}
				onCancel={() => setDeletionError(null)}
				heading={t('Cannot delete Emission SPV')}
				text={deletionError?.violations?.[0]?.message}
			/>
		</EditableTile>
	);
};

export default EmissionSpvsList;
