import { css } from 'styled-components';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import useReponsive from 'Common/hooks/useResponsive';
import useKeyValueVisualizationState from 'Common/components/form/hooks/useKeyValueVisualizationState';
import { useLabelColor } from './form/hooks/useLabelColor';
import { useState } from 'react';
import sizes from 'Application/theme/sizes';

const KeyContainer = styled.div`
	width: 100%;
`;

const Key = styled.span`
	color: ${({ $labelColor }) => $labelColor};
	font-size: ${({ $isMobile }) => ($isMobile ? '13px' : '14px')};
	font-weight: 700;
	line-height: 22px;
	min-width: 100px;
	display: flex;
`;

const ValueContainer = styled.div`
	flex: 0 1 calc(100% - 220px);
	min-height: 28px;
`;

const Value = styled.div`
	display: flex;
	flex-direction: row;
	font-weight: 400;
	font-size: ${({ $isMobile }) => ($isMobile ? '13px' : '16px')};
	line-height: 28px;
	text-align: left;
	overflow-wrap: break-word;
	text-transform: none;
	color: ${({ $color }) => $color};
	width: 100%;
`;

export const KeyValueVisualizationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: ${sizes.spacing(2)};

	${({ $hasHoverEffect }) =>
		$hasHoverEffect &&
		css`
			cursor: pointer;
		`}

	${({ $isDisabled }) =>
		$isDisabled &&
		css`
			pointer-events: auto !important;
			cursor: not-allowed !important;
			color: red !important;
		`}

	&:last-child {
		margin-bottom: 0;
	}
`;

const PrefixContainer = styled.div`
	display: flex;
	align-items: center;
	margin-right: 4px;
`;

const KeyValueVisualization = ({
	id,
	unit,
	title,
	tooltip,
	isHighlighted,
	hasHoverEffect,
	value: RawValue,
	disabled: isDisabled,
	placeholder: placeholder,

	prefix: Prefix,
	keyStyle,
	valueStyle,
	containerStyle,
}) => {
	const { isMobile } = useReponsive();
	const [hasHover, setHasHover] = useState(false);
	const hasPrefix = Boolean(Prefix);

	const labelColor = useLabelColor({ isDisabled, isHighlighted, hasHover });

	const isValueComponent = typeof RawValue === 'function';

	const { displayedValue, valueColor } = useKeyValueVisualizationState(
		RawValue,
		placeholder,
		isDisabled,
		unit,
	);

	const handleMouseEnter = () => {
		if (hasHoverEffect) {
			setHasHover(true);
		}
	};

	const handleMouseLeave = () => {
		if (hasHoverEffect) {
			setHasHover(false);
		}
	};

	return (
		<KeyValueVisualizationWrapper
			id={id}
			$isMobile={isMobile}
			$isDisabled={isDisabled}
			$hasHoverEffect={hasHoverEffect}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			style={containerStyle}
			tabIndex={-2} // ! OverlaySections ignores the fields otherwise and the green checkmarks are not correct
		>
			<KeyContainer>
				<Key $labelColor={labelColor} style={keyStyle}>
					{title} {tooltip && tooltip}
				</Key>
			</KeyContainer>
			<ValueContainer>
				<Value style={valueStyle} $color={valueColor}>
					{hasPrefix && <PrefixContainer>{typeof Prefix === 'string' ? Prefix : <Prefix />}</PrefixContainer>}
					{isValueComponent ? <RawValue /> : displayedValue}
				</Value>
			</ValueContainer>
		</KeyValueVisualizationWrapper>
	);
};

KeyValueVisualization.defaultProps = {
	id: '',
	unit: '',
	title: '',
	value: null,
	tooltip: null,
	disabled: false,
	hasHoverEffect: false,
	isHighlighted: false,
	placeholder: '',

	keyStyle: {},
	valueStyle: {},
	containerStyle: {},
	prefix: () => null,
};

KeyValueVisualization.propTypes = {
	title: PropTypes.string,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({}), PropTypes.func]),
	unit: PropTypes.string,
	tooltip: PropTypes.node,
	disabled: PropTypes.bool,
	hasHoverEffect: PropTypes.bool,
	isHighlighted: PropTypes.bool,
	containerStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
	keyStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
	prefix: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	valueStyle: PropTypes.shape({}),
	placeholder: PropTypes.string,
};

export default KeyValueVisualization;
