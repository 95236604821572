import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useSelectPlaceholder = (isMulti, isTile, isInEditMode, propPlaceholder, multiPlaceholder) => {
	const { t } = useTranslation();
	const isReadMode = useMemo(() => !isInEditMode && isTile, [isInEditMode, isTile]);

	const placeholder = useMemo(() => {
		if (isMulti && isReadMode && multiPlaceholder) {
			return multiPlaceholder;
		}

		if (isInEditMode || !isTile) {
			if (propPlaceholder) {
				return propPlaceholder;
			}

			return t('Select');
		}

		return '-';
	}, [isMulti, isReadMode, multiPlaceholder, isInEditMode, isTile, propPlaceholder, t]);

	return placeholder;
};

export default useSelectPlaceholder;
