import getMyTeamActiveProjects from 'Dashboard/api/getMyTeamActiveProjects';
import DashboardTab from 'Dashboard/components/DashboardTab';
import useTeamActivitiesStepModifier from 'Dashboard/components/TeamActivities/Tabs/TeamActivitiesTab/hooks/useTeamActivitiesStepModifier';
import getMyTeamActiveProjectById from 'Dashboard/api/getMyTeamActiveProjectById';

const TeamActivitiesTab = () => (
	<DashboardTab
		helpText="Projects with activities waiting for your team to complete the next step."
		getterFunction={getMyTeamActiveProjects}
		singleProjectGetter={getMyTeamActiveProjectById}
		stepModifier={useTeamActivitiesStepModifier}
	/>
);

export default TeamActivitiesTab;
