import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import AssignToProjectForm from 'Contacts/components/AssignToProjectForm/AssignToProjectForm';
import getContactById from 'Contacts/api/getContactById';
import showToastError from 'Common/utils/showToastError';
import { PropTypes } from 'prop-types';
import useAbortController from 'Common/hooks/useAbortController';

const AssignToProjectModal = ({
	isOpen,
	onClose,
	onFormSubmit,
	contactId,
	clientId,
	epcPartnerId,
	partnerId,
}) => {
	const { t } = useTranslation();
	const [contact, setContact] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				const response = await getContactById(abortController.signal, contactId, true);

				setContact(response.data);
				setIsLoading(false);
			} catch (e) {
				showToastError(e, t("Can't fetch contact data"));
			}
		})();
	}, [contactId, abortController.signal, t]);

	return (
		<ModalForm
			label="assign-to-project"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			isLoadingContent={isLoading}
			heading={t('Assign to Project')}
			helpText={
				<>
					Assign <b>{`${contact?.firstName} ${contact?.lastName}`}</b> to 1 or more Projects.
				</>
			}
		>
			{(setIsFormDirty, handleRequestClose, handleSubmit) => (
				<AssignToProjectForm
					onSubmit={handleSubmit}
					onCancel={handleRequestClose}
					contactId={contactId}
					data={{
						projects:
							contact?.projects && contact?.projects?.length > 0
								? contact?.projects?.map(project => ({
										value: project.projectId,
										label: project.projectDescription
											? `${project.projectExternalId} (${project.projectDescription})`
											: String(project.projectExternalId),
								  }))
								: [],
					}}
					onDirty={setIsFormDirty}
					clientId={clientId}
					epcPartnerId={epcPartnerId}
					partnerId={partnerId}
				/>
			)}
		</ModalForm>
	);
};

AssignToProjectModal.defaultProps = {
	clientId: '',
	epcPartnerId: '',
	partnerId: '',
};

AssignToProjectModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	contactId: PropTypes.string.isRequired,
	clientId: PropTypes.string,
	epcPartnerId: PropTypes.string,
	partnerId: PropTypes.string,
};

export default AssignToProjectModal;
