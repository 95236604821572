import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import getRegionalHubs from 'Preferences/api/getRegionalHubs';
import { roles } from 'User/constants/roles';
import { titleOptions } from 'UserManagement/constants/titles';
import { roleOptions } from 'User/constants/roles';
import { FILTER_TYPES } from 'Common/components/filter/constants/filterConstants';
import showToastError from 'Common/utils/showToastError';

const useUserListFilterData = isEpcList => {
	const { t } = useTranslation();

	const loadRegionalHubs = useCallback(async (signal, query) => {
		try {
			const hubResponse = await getRegionalHubs(signal, false, query);
			const formattedResponse = hubResponse.data.map(hub => ({
				value: hub.name,
				label: hub.name,
			}));

			return formattedResponse;
		} catch (error) {
			showToastError(error);
			return [];
		}
	}, []);

	const filtersData = useMemo(
		() =>
			[
				{
					name: 'firstName',
					label: t('First Name'),
					type: FILTER_TYPES.INPUT,
				},
				{
					name: 'lastName',
					label: t('Last Name'),
					type: FILTER_TYPES.INPUT,
				},
				{
					name: 'title',
					label: t('Gender'),
					type: FILTER_TYPES.SELECT,
					options: titleOptions,
				},
				{
					name: 'email',
					label: t('E-mail'),
					type: FILTER_TYPES.INPUT,
				},
				{
					name: 'phone',
					label: t('Phone'),
					type: FILTER_TYPES.INPUT,
				},
				!isEpcList
					? {
							name: 'roles',
							label: t('Role'),
							type: FILTER_TYPES.SELECT,
							options: roleOptions.filter(role => role.value !== roles.EPC_PARTNER),
					  }
					: undefined,
				{
					name: 'active',
					label: t('Status'),
					type: FILTER_TYPES.SWITCH,
					options: [
						{
							value: '',
							label: 'Active',
						},
						{
							value: 'all',
							label: 'All',
						},
						{
							value: 'inactive',
							label: 'Inactive',
						},
					],
				},
				{
					name: 'regionalHub',
					label: t('Regional Hub'),
					type: FILTER_TYPES.ASYNC_SELECT,
					loadOptions: loadRegionalHubs,
				},
			].filter(Boolean),
		[isEpcList, loadRegionalHubs, t],
	);

	return filtersData;
};

export default useUserListFilterData;
