import getPartners from 'Partners/api/getPartners';

const loadOtherPartnersOptions = async (signal, inputValue) => {
	const {
		data: { content: partners },
	} = await getPartners(signal, {}, { name: inputValue });

	return partners.map(partner => ({
		label: partner.name,
		value: partner.id,
		partnerTypeName: partner?.partnerType?.name,
	}));
};

export default loadOtherPartnersOptions;
