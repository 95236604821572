import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import EditableTile from 'Common/components/Tile/EditableTile';

import useDirectorsTileFormInitialValues from 'Entities/components/EntitiesDetails/Tabs/GeneralTab/Tiles/DirectorsTile/hooks/useDirectorsTileFormInitialValues';
import useDirectorsTileFormValidationSchema from 'Entities/components/EntitiesDetails/Tabs/GeneralTab/Tiles/DirectorsTile/hooks/useDirectorsTileFormValidationSchema';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';
import FormikTextArea from 'Common/components/form/FormikTextArea';
import assignEntityDirectors from 'Entities/api/assignEntityDirectors';
import editEntity from 'Entities/api/editEntity';
import useEcoligoUsersOptions from 'UserManagement/hooks/useEcoligoUsersOptions';

const DirectorsTile = ({ entity, onEnterEditMode, onExitEditMode, onDirtyForm, onSave, highlightLabels }) => {
	const { t } = useTranslation();

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useDirectorsTileFormInitialValues(entity);
	const validationSchema = useDirectorsTileFormValidationSchema();

	const { errors, touched, values, handleSubmit, dirty, handleBlur, handleChange, setFieldValue, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: async values => {
				let response;
				setIsSavingChanges(true);

				try {
					await editEntity(abortController.signal, {
						...entity,
						notes: values.notes,
					});

					response = await assignEntityDirectors(abortController.signal, {
						contractPartyId: entity.id,
						usersEmails: [values?.director1?.value, values?.director2?.value, values?.director3?.value],
					});

					if (onDirtyForm) {
						onDirtyForm(false);
					}

					onSave(response.data);
					handleCancel();
					setIsSavingChanges(false);
					resetForm({ values });
				} catch (e) {
					showToastError(e);
					if (!axios.isCancel(e)) {
						handleCancel();
						setIsSavingChanges(false);
					}
				}
			},
		});

	const excludedUserEmails = useMemo(
		() => [values?.director1?.value, values?.director2?.value, values?.director3?.value].filter(Boolean),
		[values],
	);

	const { ecoligoUsersOptions: directorsOptions, isLoadingEcoligoUsersOptions: isLoadingDirectorsOptions } =
		useEcoligoUsersOptions(excludedUserEmails);

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Directors')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
			zIndex={1}
		>
			<FormField>
				<FormikSelect
					id="director1"
					name="director1"
					label={t('Director 1')}
					value={values.director1}
					error={errors.director1}
					touched={touched.director1}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					userInfo={values.director1}
					options={directorsOptions}
					isLoading={isLoadingDirectorsOptions}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('director1')}
					isRequired
				/>
			</FormField>
			<FormField>
				<FormikSelect
					id="director2"
					name="director2"
					label={t('Director 2')}
					value={values.director2}
					error={errors.director2}
					touched={touched.director2}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					userInfo={values.director2}
					options={directorsOptions}
					isLoading={isLoadingDirectorsOptions}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('director2')}
					isClearable
				/>
			</FormField>
			<FormField>
				<FormikSelect
					id="director3"
					name="director3"
					label={t('Director 3')}
					value={values.director3}
					error={errors.director3}
					touched={touched.director3}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					userInfo={values.director3}
					options={directorsOptions}
					isLoading={isLoadingDirectorsOptions}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('director3')}
					isClearable
				/>
			</FormField>
			<FormField>
				<FormikTextArea
					id="notes"
					name="notes"
					label={t('Notes')}
					value={values.notes}
					error={errors.notes}
					touched={touched.notes}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
		</EditableTile>
	);
};

DirectorsTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	onSave: async () => {},
	highlightLabels: [],
};

DirectorsTile.propTypes = {
	entity: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	onSave: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default DirectorsTile;
