import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useUserFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				firstName: yup.string().required(t('Required')),
				lastName: yup.string().required(t('Required')),
				email: yup.string().email(t('Invalid email address')).required(t('Required')),
				phone: yup.string(),
				roles: yup
					.array()
					.test({
						message: 'Required',
						test: arr => arr.length > 0,
					})
					.nullable(),
			}),
		[t],
	);
};

export default useUserFormValidationSchema;
