import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useProjectsFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				financeDetailsProjects: yup.array().of(
					yup.object().shape({
						systemSizeAllocatedValue: yup
							.number()
							.typeError(t('Must be a number'))
							.test('decimal-places', 'Must have no more than 2 decimal place', function () {
								return String(this.originalValue).match(/^\d*(\.\d{0,2})?$/);
							})
							.nullable()
							.required(t('Required')),
						// financeDetailsProjects: yup.array().of(
						// 	yup.object().shape({
						// 		annualCo2SavingsEditable: yup
						// 			.number()
						// 			.typeError(t('Must be a number'))
						// 			.test('decimal-places', 'Must have no more than 8 decimal place', function () {
						// 				return String(this.originalValue).match(/^\d*(\.\d{0,8})?$/);
						// 			})
						// 			.nullable(),
						// 	}),
						// ),
					}),
				),
			}),
		[t],
	);
};

export default useProjectsFormValidationSchema;
