import api from 'Application/api/api';

const getCampaignById = async (signal, id, details) => {
	const response = await api.get(`/finances/by-id/${id}`, {
		params: { details },
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
	};
};

export default getCampaignById;
