import api from 'Application/api/api';

const assignTemplateAndBankAccount = async (signal, invoiceId, values) => {
	const response = await api.post(`/invoices/assign-template-and-bank-account/${invoiceId}`, values, {
		signal,
		timeout: 100000,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: response.data,
	};
};

export default assignTemplateAndBankAccount;
