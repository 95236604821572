import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useFlexLayout, useTable } from 'react-table';
import formatCurrency from 'Common/utils/formatCurrency';
import formatNumber from 'Common/utils/formatNumber';
import Link from 'Common/components/Link';
import colors from 'Application/theme/colors';
import useInitialSortByFromQueryParameter from 'Common/hooks/table/useInitialSortByFromQueryParameter';
import { useAdditionalTableProps } from 'Common/hooks/table/plugin-hooks/useAdditionalTableProps';
import { useEditableRow } from 'Common/hooks/table/plugin-hooks/useEditableRow';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';

const StyledMark = styled.mark`
	background-color: ${colors.text.primaryHighlight};
	color: ${colors.text.primary};
`;

const NegativeCurrency = styled.span`
	color: ${colors.error.main};
`;

const reformatData = (data, search, columns) => {
	const searchTerm = search ? search.toLowerCase() : null;
	const searchTermRegEx = searchTerm ? new RegExp(`(${searchTerm})`, 'i') : null;

	return data.map(itemData => {
		if (!itemData || isEmpty(itemData)) {
			return itemData;
		}

		const item = { ...itemData };

		columns.forEach(column => {
			const fieldName = column.accessor;

			if (column.isNumber || column.isCurrency) {
				if (item[fieldName] === undefined) {
					item[fieldName] = '-';
					return;
				}
				let field;
				if (typeof item[fieldName] === 'object') {
					field = item[fieldName].eurValue;
				} else {
					field = item[fieldName];
				}
				const number = Number(field);
				const decimalPlaces = column.decimalPlaces ?? 0;

				let formatted = column.isCurrency
					? formatCurrency(number, decimalPlaces)
					: formatNumber(number, decimalPlaces);

				if (column.highlightSearchTerm && searchTerm) {
					const characters = searchTerm.split('');
					const regExCharacters = characters.map(character => `${character},?`);

					const currencySearchTermRegEx = new RegExp(`(${regExCharacters.join('')})`, 'i');

					const parts = formatted.split(currencySearchTermRegEx);

					formatted = parts.map((part, i) => {
						const partWithoutSymbols = part.replace(/,/g, '').toLowerCase();

						if (partWithoutSymbols === searchTerm) {
							// eslint-disable-next-line react/no-array-index-key
							return <StyledMark key={`table-mark-${i}`}>{part}</StyledMark>;
						}

						// eslint-disable-next-line react/no-array-index-key
						return <span key={`table-mark-${i}`}>{part}</span>;
					});
				}

				if (number < 0) {
					item[fieldName] = <NegativeCurrency>{formatted}</NegativeCurrency>;
				} else {
					item[fieldName] = formatted;
				}

				return;
			}

			if (item[fieldName] === undefined || item[fieldName] === null) {
				return;
			}

			if (column.highlightSearchTerm && searchTerm) {
				let field;
				let parts;

				if (typeof item[fieldName] === 'object') {
					field = item[fieldName].props.children.toString();
					parts = field.split(searchTermRegEx);

					item[fieldName] = (
						<Link to={item[fieldName].props.to} color={item[fieldName].props.color}>
							{parts.map((part, i) => {
								const comparablePart = part.toLowerCase();

								if (comparablePart === searchTerm) {
									// eslint-disable-next-line react/no-array-index-key
									return <StyledMark key={`table-mark-${i}`}>{part}</StyledMark>;
								}

								// eslint-disable-next-line react/no-array-index-key
								return <span key={`table-mark-${i}`}>{part}</span>;
							})}
						</Link>
					);
				} else {
					field = item[fieldName].toString();
					parts = field.split(searchTermRegEx);

					item[fieldName] = parts.map((part, i) => {
						const comparablePart = part.toLowerCase();

						if (comparablePart === searchTerm) {
							// eslint-disable-next-line react/no-array-index-key
							return <StyledMark key={`table-mark-${i}`}>{part}</StyledMark>;
						}

						// eslint-disable-next-line react/no-array-index-key
						return <span key={`table-mark-${i}`}>{part}</span>;
					});
				}
			}
		});

		return item;
	});
};

const ensurePluginLoaded = (plugin, plugins, error) => {
	if (!plugins.includes(plugin)) {
		throw new Error(
			`React-Table: ${error}. The ${plugin.pluginName} plugin-hook is required, but was not provided`,
		);
	}
};

const useStandardTable = (
	{ data, searchTerm, newRowData, getRowId: customGetRowId, ...props },
	...plugins
) => {
	const [rowsForCreation, setRowsForCreation] = useState([]);

	const addRowForCreation = () => {
		ensurePluginLoaded(useEditableRow, plugins, 'Cannot add new row');

		setRowsForCreation(prevRows => [
			{
				id: uuidv4(),
				...newRowData,
				bodyGroupIds: ['general'],
				__forCreation: true,
			},
			...prevRows,
		]);
	};

	const removeRowForCreation = id => {
		ensurePluginLoaded(useEditableRow, plugins, `Cannot remove row with id "${id}"`);

		setRowsForCreation(prevRows => {
			const index = prevRows.findIndex(row => row.id === id);

			if (index === -1) {
				return prevRows;
			}

			const newRows = [...prevRows];

			newRows.splice(index, 1);

			return newRows;
		});
	};

	const reformattedData = useMemo(
		() => reformatData([...rowsForCreation, ...data], searchTerm, props.columns),
		[rowsForCreation, data, searchTerm, props.columns],
	);

	const initialSortBy = useInitialSortByFromQueryParameter(props.defaultSortBy);

	const getRowId = useCallback(
		(row, relativeIndex, parent) => {
			// Should always return the id when the row is for creation
			if (row.__forCreation) {
				return row.id;
			}

			// Custom row id getter takes precedence over anything below
			if (customGetRowId) {
				return customGetRowId(row, relativeIndex, parent);
			}

			// Use the entry id if such exists
			if (row.id) {
				return row.id;
			}

			// Fallback to default row id getter
			return parent ? [parent.id, relativeIndex].join('.') : relativeIndex;
		},
		[customGetRowId],
	);

	// noinspection UnnecessaryLocalVariableJS
	const table = useTable(
		{
			data: reformattedData,
			disableMultiSort: true,
			disableSortRemove: true,
			manualSortBy: true,
			...props,
			initialState: {
				sortBy: initialSortBy,
				...props.initialState,
			},
			removeRowForCreation,
			getRowId,
		},
		useAdditionalTableProps,
		useFlexLayout,

		// Overwrite width and min-width styles from useFlexLayout plugin-hook
		hooks => {
			const overwriteWidthAndMinWidth = props => [
				props,
				{
					style: {
						width: '100%',
						minWidth: 'initial',
					},
				},
			];

			hooks.getHeaderGroupProps.push(overwriteWidthAndMinWidth);
			hooks.getTableBodyProps.push(overwriteWidthAndMinWidth);
			hooks.getFooterGroupProps.push(overwriteWidthAndMinWidth);
		},

		...plugins,
	);

	return {
		...table,
		addRowForCreation,
		removeRowForCreation,
	};
};

export default useStandardTable;
