import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';

import { roles } from 'User/constants/roles';

import getUsersWithFullName from 'UserManagement/api/getUsersWithFullName';

import notify from 'Common/utils/notify';
import Loader from 'Common/components/Loader';
import EditableTile from 'Common/components/Tile/EditableTile';
import FormikSelect from 'Common/components/form/FormikSelect';

import assignDefaultManager from 'Preferences/api/assignDefaultManager';
import getDefaultCountryManager from 'Preferences/api/getDefaultCountryManager';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const FieldWrapper = styled.div`
	max-width: 350px;
`;

const StyledLoaderWrapper = styled(LoaderWrapper)`
	height: 50px;
	justify-content: left;
`;

const DefaultManagersTile = () => {
	const { t } = useTranslation();

	const abortController = useAbortController();

	const [isLoading, setIsLoading] = useState(false);
	const [managerOptions, setManagerOptions] = useState([]);
	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isLoadingContent, setIsLoadingContent] = useState(false);

	const [initialValues, setInitialValues] = useState({
		defaultmanager: { value: null, label: '', firstName: '', lastName: '' },
	});

	const { values, handleSubmit, onDirtyForm, setFieldValue, resetForm } = useFormik({
		initialValues,
		onSubmit: async formValues => {
			setIsLoading(true);
			try {
				await assignDefaultManager(abortController.signal, formValues.defaultmanager.value);
				notify(t('Default manager changed successfully'), {
					type: toast.TYPE.SUCCESS,
				});
				setInitialValues(formValues);
				setIsLoading(false);
				setIsInEditMode(false);
			} catch (err) {
				showToastError(err);
				if (!axios.isCancel(err)) {
					setIsLoading(false);
					setIsInEditMode(false);
				}
			}
		},
	});

	useEffect(() => {
		(async () => {
			try {
				setIsLoadingContent(true);
				const response = await getDefaultCountryManager(abortController.signal);

				const users = await getUsersWithFullName(abortController.signal, {
					active: true,
					roles: [
						roles.ADMIN,
						roles.FINANCE,
						roles.ASSET_MANAGER,
						roles.ASSET_MARKETING,
						roles.MANAGEMENT,
						roles.PM_FUNDRAISING,
						roles.PM_TECHNICAL,
						roles.SALES,
					],
				});

				setManagerOptions(
					users.data.map(user => ({
						...user,
						value: user.email,
						label: user.fullName,
					})),
				);

				if (users.data.length > 0) {
					const defaultUser = users.data.filter(user => user.email === response.data)[0];
					setFieldValue('defaultmanager', {
						...defaultUser,
						value: defaultUser.email,
						label: defaultUser.fullName,
					});
					setInitialValues({
						defaultmanager: {
							...defaultUser,
							value: defaultUser.email,
							label: defaultUser.fullName,
						},
					});
				}
				setIsLoadingContent(false);
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [setFieldValue, setInitialValues, abortController.signal]);

	const handleTileClick = () => {
		if (!isInEditMode) {
			setIsInEditMode(true);
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });

		if (onDirtyForm) {
			onDirtyForm(false);
		}
	};

	return (
		<EditableTile
			title={t('Default Manager')}
			isLoading={isLoading}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			{isLoadingContent ? (
				<StyledLoaderWrapper>
					<Loader />
				</StyledLoaderWrapper>
			) : (
				<FieldWrapper>
					<FormikSelect
						id="defaultmanager"
						name="defaultmanager"
						label={t('Default Manager')}
						value={values.defaultmanager}
						setFieldValue={setFieldValue}
						options={managerOptions}
						isDisabled={isLoading}
						isInEditMode={isInEditMode}
						userInfo={values.defaultmanager}
						tooltip={'This user will receive notifications for all countries without a manager.'}
						menuPlacement="bottom"
						isTile
					/>
				</FieldWrapper>
			)}
		</EditableTile>
	);
};

export default DefaultManagersTile;
