import styled from 'styled-components/macro';
import ProjectInfoTileFormCreate from './ProjectInfoTileFormCreate';
import ProjectInfoTileFormEdit from './ProjectInfoTileFormEdit';
import { PropTypes } from 'prop-types';

const ProjectCardWrapper = styled.div`
	margin-bottom: 10px; ;
`;

const ProjectInfoTile = ({
	project,
	campaignId,
	values,
	errors,
	touched,
	namePrefix,
	handleChange,
	setFieldValue,
	handleBlur,
	removeProject,
	isInEditMode,
	...props
}) => (
	<ProjectCardWrapper {...props}>
		{isInEditMode ? (
			<ProjectInfoTileFormEdit
				project={project}
				values={values}
				errors={errors}
				touched={touched}
				namePrefix={namePrefix}
				handleChange={handleChange}
				handleBlur={handleBlur}
				setFieldValue={setFieldValue}
				removeProject={removeProject}
				campaignId={campaignId}
				editCo2={true}
			/>
		) : (
			<ProjectInfoTileFormCreate
				project={project}
				values={values}
				errors={errors}
				touched={touched}
				namePrefix={namePrefix}
				handleChange={handleChange}
				handleBlur={handleBlur}
				setFieldValue={setFieldValue}
				removeProject={removeProject}
			/>
		)}
	</ProjectCardWrapper>
);

ProjectInfoTile.defaultProps = {
	errors: null,
	touched: null,
};

ProjectInfoTile.propTypes = {
	project: PropTypes.shape({}).isRequired,
	campaignId: PropTypes.number.isRequired,
	values: PropTypes.shape({}).isRequired,
	errors: PropTypes.shape({}),
	touched: PropTypes.shape({}),
	namePrefix: PropTypes.string.isRequired,
	handleBlur: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	removeProject: PropTypes.func.isRequired,
	isInEditMode: PropTypes.bool.isRequired,
};

export default ProjectInfoTile;
