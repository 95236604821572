import api from 'Application/api/api';

const getDriveFaults = (signal, configuration) => {
	const { sortBy = 'name', sortDirection = 'ASC' } = configuration;

	const params = {
		size: 9999,
	};

	if (sortBy && sortDirection) {
		params.sort = `${sortBy},${sortDirection}`;
	}

	return api.get('/drive-faults/get-pageable', {
		params,
		signal,
	});
};

export default getDriveFaults;
