import { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Icon from 'Common/components/icons/Icon';
import colors from 'Application/theme/colors';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import sizes from 'Application/theme/sizes';
import PropTypes from 'prop-types';

import Accordion from 'Common/components/accordion/Accordion';
import AccordionSummary from 'Common/components/accordion/AccordionSummary';
import AccordionDetails from 'Common/components/accordion/AccordionDetails';

import ProgressStep from 'PMWorkflow/components/Step/ProgressStep/ProgressStep';

const StyledAccordion = styled(Accordion)`
	margin: 0 !important;
	position: relative;
	z-index: ${({ $zIndex, $hideOverflow }) => ($hideOverflow ? '42' : $zIndex)};
	border: solid 1.5px transparent;
	overflow: ${({ $hideOverflow }) => ($hideOverflow ? 'visible' : 'hidden')};

	&.hasMarker {
		border-color: ${colors.primary.main};
	}

	&:before {
		content: none !important;
	}

	.MuiCollapse-root {
		overflow: visible;
	}
`;

const DefaultTitle = styled.div`
	margin-right: ${sizes.spacing(2)};
	font-weight: 300;
	font-size: 14px;
	line-height: 1.3;
`;

const Title = styled(Box)`
	color: ${colors.text.greyDark};
	font-size: 14px;
	font-weight: 300;
	display: flex;
	align-items: center;
	min-width: max-content;
`;

const Name = styled.span`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: inline-block;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
	padding: 0px ${sizes.spacing(1.5)} ${sizes.spacing(1.5)} 23px !important;
`;

const ActivityTitle = styled.div`
	color: ${colors.text.greyDark};
	font-size: 16px;
	font-weight: 700;
	align-items: center;
	margin-right: ${sizes.spacing(2)};
	transition: all 0.3s ease;
	display: grid;

	${({ hasMargin }) =>
		hasMargin &&
		css`
			margin-bottom: ${sizes.spacing(2)};
		`}

	${({ isTitleVisible }) =>
		isTitleVisible
			? css`
					max-width: 0px;
					max-height: 0px;
					opacity: 0;
			  `
			: css`
					max-width: 1000px;
					max-height: 100px;
					opacity: 1;
			  `}
`;

const InfoSection = styled.div`
	transition: all 0.3s ease;
	white-space: nowrap;

	${({ expanded }) =>
		expanded
			? css`
					opacity: 0;
			  `
			: css`
					opacity: 1;
			  `}
`;

const Info = styled.div`
	${({ done }) =>
		done &&
		css`
			color: ${colors.primary.dark};
		`}
`;

const BlockSectionsTitle = styled.div`
	color: ${colors.text.grey};
	font-size: 14px;
	line-height: 17px;
	font-weight: 700;
`;

const BlocksContainer = styled.div`
	margin-bottom: ${sizes.spacing(2)};
	display: flex;
	flex-direction: column;
	gap: ${sizes.spacing(0.5)};
	padding-top: ${sizes.spacing(0.5)};
`;

const BlockerPill = styled.div`
	padding: ${sizes.spacing(0.5)} ${sizes.spacing(1.5)};
	background-color: ${({ isBlocker, isCompleted }) =>
		isBlocker || isCompleted ? colors.grey.light : colors.common.blue};
	width: fit-content;
	border-radius: 32px;
	font-size: 14px;
	color: ${colors.common.white};
`;

const StyledGrid = styled(Grid)`
	align-content: start;
`;

const StepsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${sizes.spacing(1.75)};
	transition: all 0.3s ease;

	${({ $isExpanded }) =>
		$isExpanded
			? css`
					opacity: 1;
					@media (min-width: 960px) {
						transform: translateY(-31px);
					}
			  `
			: css`
					opacity: 0;
			  `}
`;

const PercentIndicator = styled.div`
	display: flex;
	flex-direction: row;

	${({ isCompleted }) =>
		isCompleted &&
		css`
			color: ${colors.primary.dark};
		`}
`;

const CompletedIcon = () => (
	<svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.9982 1.8332L13.8232 0.658203L8.53984 5.94154L9.71484 7.11654L14.9982 1.8332ZM18.5315 0.658203L9.71484 9.47487L6.23151 5.99987L5.05651 7.17487L9.71484 11.8332L19.7148 1.8332L18.5315 0.658203ZM0.339844 7.17487L4.99818 11.8332L6.17318 10.6582L1.52318 5.99987L0.339844 7.17487Z"
			fill="#2AA4BC"
		/>
	</svg>
);

const CompletedIconContainer = styled.div`
	margin-left: ${sizes.spacing(1)};
`;

const iconStyle = {
	fontSize: 20,
	color: colors.text.greyDark,
	marginRight: 8,
};

const ProgressActivity = ({
	activity,
	zIndex,
	status,
	visibleDropdowns,
	setVisibleDropdowns,
	tabName,
	...props
}) => {
	const { t } = useTranslation();
	const [isExpanded, setIsExpanded] = useState(false);
	const [hideOverflow, setHideOverflow] = useState(false);
	const hasStepInSidePanel = useSelector(state => state.sidePanel?.contentData?.activityId === activity.id);

	const doneStepsCount = activity.steps.filter(step => step.status !== 'OPEN').length;

	const stepsCount = activity.steps.length;

	const formatedPercentage = Math.floor(activity.completedPercentage);

	useEffect(() => {
		setIsExpanded((activity.canBeCompleted && activity.completedPercentage < 100) || hasStepInSidePanel);
	}, [activity.canBeCompleted, activity.completedPercentage, hasStepInSidePanel]);

	return (
		<StyledAccordion
			expanded={isExpanded}
			onChange={() => setIsExpanded(prevExpanded => !prevExpanded)}
			background={colors.grey.lighter}
			$zIndex={zIndex}
			$hideOverflow={hideOverflow}
			data-project-progress-marker
			data-activity-wrapper={`${activity.title}-${tabName}`}
		>
			<AccordionSummary>
				<Grid container justifyContent="space-between">
					<Grid
						item
						container
						xs={stepsCount ? 9 : 12}
						sm={stepsCount ? 9 : 12}
						md={stepsCount ? 10 : 12}
						lg={stepsCount ? 10 : 12}
						xl={stepsCount ? 10 : 12}
					>
						<Title
							width={{
								xs: '107px',
							}}
							xs={4}
							sm={3}
							md={2}
							lg={2}
							xl={2}
						>
							{isExpanded ? (
								<Icon icon="keyboardArrowDown" style={iconStyle} />
							) : (
								<Icon icon="keyboardArrowRight" style={iconStyle} />
							)}

							<DefaultTitle>{t('Activity ' + activity.orderNumber)}</DefaultTitle>
						</Title>
						<Grid item container xs={7} sm={7} md={9} lg={9} xl={9}>
							<Grid item xs={10} sm={11} md="auto">
								<ActivityTitle isTitleVisible={isExpanded}>
									<Name>{t(`${activity.title}`)}</Name>
								</ActivityTitle>
							</Grid>
							<Grid item xs={2} sm={1} md="auto">
								<PercentIndicator isCompleted={activity.completedPercentage === 100}>
									{formatedPercentage}%
									{activity.completedPercentage === 100 && (
										<CompletedIconContainer>
											<CompletedIcon />
										</CompletedIconContainer>
									)}
								</PercentIndicator>
							</Grid>
						</Grid>
					</Grid>
					{stepsCount > 0 && (
						<Grid item xs={3} sm={3} md={2} lg={1} xl={1} container justifyContent="flex-end">
							<InfoSection expanded={isExpanded}>
								<Info done={doneStepsCount === stepsCount}>{t(`Done: ${doneStepsCount}/${stepsCount}`)}</Info>
							</InfoSection>
						</Grid>
					)}
				</Grid>
			</AccordionSummary>
			<StyledAccordionDetails background={colors.grey.lighter}>
				<Grid container spacing={3}>
					<StyledGrid item container xs={12} sm={12} md={12} lg={3} xl={3}>
						<Grid item xs={12} sm={12} md={4} lg={12} xl={12}>
							<ActivityTitle isTitleVisible={!isExpanded} hasMargin>
								{t(`${activity.title}`)}
							</ActivityTitle>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={12} xl={12}>
							<BlockSectionsTitle>{t('Blocked by')}</BlockSectionsTitle>
							<BlocksContainer>
								{activity.blockedByActivities?.map(block => (
									<BlockerPill key={block.id} isCompleted={block.completed}>
										{block.title} {` ${Math.floor(block?.completedPercentage)}%`}
									</BlockerPill>
								))}
							</BlocksContainer>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={12} xl={12}>
							<BlockSectionsTitle>{t('Blocks')}</BlockSectionsTitle>
							<BlocksContainer>
								{activity.blockerActivities?.map(block => (
									<BlockerPill key={block.id} isBlocker>
										{block.title}
									</BlockerPill>
								))}
							</BlocksContainer>
						</Grid>
					</StyledGrid>
					<Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
						<StepsContainer data-project-progress-marker $isExpanded={isExpanded}>
							{activity.steps.map((step, index) => (
								<ProgressStep
									key={step.id}
									step={step}
									status={status}
									index={index + 1}
									activity={activity}
									visibleDropdowns={visibleDropdowns}
									setVisibleDropdowns={setVisibleDropdowns}
									setHideOverflow={setHideOverflow}
									{...props}
								/>
							))}
						</StepsContainer>
					</Grid>
				</Grid>
			</StyledAccordionDetails>
		</StyledAccordion>
	);
};

ProgressActivity.defaultProps = {
	zIndex: 1,
	visibleDropdowns: [],
	setVisibleDropdowns: () => {},
};

ProgressActivity.propTypes = {
	zIndex: PropTypes.number,
	status: PropTypes.shape({}).isRequired,
	tabName: PropTypes.string.isRequired,
	activity: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		completedPercentage: PropTypes.number.isRequired,
		canBeCompleted: PropTypes.bool.isRequired,
		title: PropTypes.string.isRequired,
		orderNumber: PropTypes.number.isRequired,
		blockedByActivities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		blockerActivities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	}).isRequired,
	setVisibleDropdowns: PropTypes.func,
	visibleDropdowns: PropTypes.arrayOf(PropTypes.string),
};

export default ProgressActivity;
