import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

import editProject from 'Projects/api/editProject';
import loadSalesChannelOptions from 'Projects/utils/loadSalesChannelOptions';
import useEngagementFormInitialValues from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/EngagementTile/hooks/useEngagementFormInitialValues';
import useEngagementFormValidationSchema from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/EngagementTile/hooks/useEngagementFormValidationSchema';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';
import axios from 'axios';

const EngagementTile = ({
	project,
	onEnterEditMode,
	onExitEditMode,
	onDirtyForm,
	onTileSave,
	highlightLabels,
}) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([roles.ADMIN, roles.SALES, roles.FINANCE, roles.MANAGEMENT]);

	const isAuthorizedToEditSalesChanel = isAuthorized([roles.ADMIN, roles.FINANCE, roles.MANAGEMENT]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useEngagementFormInitialValues(project);
	const validationSchema = useEngagementFormValidationSchema();

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleBlur,
		setFieldValue,
		setFieldTouched,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			let response;
			setIsSavingChanges(true);

			try {
				response = await editProject(abortController.signal, {
					...project,
					clientId: project.client?.id,
					regionalHubId: project.regionalHub?.id || null,
					salesChannelId: values.salesChannel ? values.salesChannel.value : null,
					initialEngagementDate: values.initialEngagementDate
						? format(new Date(values.initialEngagementDate), 'yyyy-MM-dd')
						: null,
				});

				if (onDirtyForm) {
					onDirtyForm(false);
				}

				onTileSave(response.data);
				handleCancel();
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Engagement')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<FormField>
				<FormikSingleDatePicker
					id="initialEngagementDate"
					label={t('Initial Engagement Date')}
					startDateName="initialEngagementDate"
					error={errors.initialEngagementDate}
					touched={touched.initialEngagementDate}
					startDate={values.initialEngagementDate}
					setFieldValue={setFieldValue}
					setFieldTouched={setFieldTouched}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_ENGAGEMENT.INITIAL_ENGAGEMENT_DATE,
					)}
					isRequired
				/>
			</FormField>
			{!isAuthorizedToEditSalesChanel ? (
				<KeyValueVisualization title={t('Sales channel')} value={project?.salesChannel?.name} />
			) : (
				<FormField>
					<FormikSelect
						isAsync
						id="salesChannel"
						name="salesChannel"
						label={t('Sales channel')}
						value={values.salesChannel}
						error={errors.salesChannel}
						touched={touched.salesChannel}
						setFieldValue={setFieldValue}
						onBlur={handleBlur}
						loadOptions={loadSalesChannelOptions}
						isTile
						isInEditMode={isInEditMode}
						isHighlighted={highlightLabels.includes(
							PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_ENGAGEMENT.SALES_CHANNEL,
						)}
					/>
				</FormField>
			)}
		</EditableTile>
	);
};

EngagementTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	onTileSave: async () => {},
	highlightLabels: [],
};

EngagementTile.propTypes = {
	project: PropTypes.shape({
		client: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
		regionalHub: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
		salesChannel: PropTypes.shape({ name: PropTypes.string }),
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	onTileSave: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default EngagementTile;
