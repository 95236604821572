import api from 'Application/api/api';

const getAccountingStandardById = async (signal, accountingStandardId, details = true) => {
	const response = await api.get(`/accounting-standards/by-id/${accountingStandardId}`, {
		params: {
			details,
		},
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return response;
};

export default getAccountingStandardById;
