import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useEntityFormValidationSchema = () => {
	const { t } = useTranslation();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(
		() =>
			yup.object({
				companyName: yup.string().required(t('Company name is required')),
				abbreviation: yup.string().required(t('Abbreviation is required')),
				dateOfRegistration: yup.date().required(t('Date of registration is required')).nullable(),
				registrationNumber: yup.string().required(t('Registration number is required')),
				taxNumber: yup.string().required(t('Tax number is required')),
				vatNumber: yup.string().required(t('VAT number is required')),
			}),
		[t],
	);
};

export default useEntityFormValidationSchema;
