import PropTypes from 'prop-types';

import Big from 'Common/utils/customBig';
import Grid from '@mui/material/Grid';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import OffersFormTile from 'EpcPartner/components/EpcPartnerPortal/EpcOfferDetails/Tabs/OffersTab/OffersFormTile/OffersFormTile';
import { useTranslation } from 'react-i18next';

const OffersTab = ({ epcOffer, onDataChange, onEnableEditMode, onDisableEditMode, onDirtyForm }) => {
	const { t } = useTranslation();

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={12} lg={10} xl={8} zeroMinWidth>
				<OffersFormTile
					defaultValues={{
						systemSize: epcOffer.systemSize || epcOffer.tenderSystemSize,
						yield: epcOffer.yield,
						expectedGridInjection: epcOffer.expectedGridInjection,
						epcVolumeExclVat: epcOffer?.epcVolumeExclVat?.value,
						epcVolumeExclVatKwp: epcOffer?.epcVolumeExclVatKwp?.value,
						currency: epcOffer?.epcVolumeExclVat?.currency,
						annualOmPrice: epcOffer?.annualOmPrice?.value,
						annualOmPriceKwp: epcOffer?.annualOmPriceKwp?.value,
						annualOmEscalationRate: epcOffer.annualOmEscalationRate,
						// No false switches take the values as fallows:
						// epcVolumeSwitch = false --> EPC Volume can be edited
						// epcVolumeSwitch = true --> EPC Volume per kWp can be edited
						// omPriceSwitch = false --> O&M Price can be edited
						// omPriceSwitch = true --> O&M Price per kWp can be edited
						epcVolumeSwitch: epcOffer?.epcVolumeExclVat?.calculated,
						omPriceSwitch: epcOffer?.annualOmPrice?.calculated,
						gensetsInfo: epcOffer.gensets ? t('Yes') : t('No'),
						assumptions: epcOffer?.assumptions,
						exclusions: epcOffer?.exclusions,
						variations: epcOffer?.variations,
						projectType: epcOffer?.projectType.name,
						changesMade: epcOffer?.changesMade,
						userResponded: epcOffer?.userResponded,
						dateResponded: epcOffer?.dateResponded,
						reasonForChange: epcOffer?.reasonForChange,
						solarUtilisationRate:
							epcOffer?.solarUtilisationRate && Big(epcOffer?.solarUtilisationRate).times(100).toString(),
						offerValidityDate: epcOffer?.offerValidityDate ? new Date(epcOffer?.offerValidityDate) : null,
						requestGensetQuote: epcOffer.requestGensetQuote,
						requestOfferValidity: epcOffer.requestOfferValidity,
					}}
					onSubmit={onDataChange}
					onDirtyForm={onDirtyForm}
					epcOffer={epcOffer}
					onEnableEditMode={onEnableEditMode}
					onDisableEditMode={onDisableEditMode}
				/>
			</Grid>
		</TilesGridWrapper>
	);
};

OffersTab.defaultProps = {
	epcOffer: {},
	onDirtyForm: () => {},
	onDataChange: () => {},
	onEnableEditMode: () => {},
	onDisableEditMode: () => {},
};

OffersTab.propTypes = {
	epcOffer: PropTypes.shape({
		systemSize: PropTypes.number,
		tenderSystemSize: PropTypes.number,
		yield: PropTypes.number,
		expectedGridInjection: PropTypes.number,
		epcVolumeExclVat: PropTypes.shape({
			value: PropTypes.number,
			currency: PropTypes.string,
			calculated: PropTypes.bool,
		}),
		epcVolumeExclVatKwp: PropTypes.shape({
			currency: PropTypes.string,
			value: PropTypes.number,
		}),
		annualOmPrice: PropTypes.shape({
			value: PropTypes.number,
			calculated: PropTypes.bool,
		}),
		annualOmPriceKwp: PropTypes.shape({
			value: PropTypes.number,
		}),
		gensets: PropTypes.bool,
		assumptions: PropTypes.shape({}),
		exclusions: PropTypes.shape({}),
		variations: PropTypes.shape({}),
		projectType: PropTypes.shape({ name: PropTypes.string }),
		annualOmEscalationRate: PropTypes.number,
		changesMade: PropTypes.shape({}),
		userResponded: PropTypes.bool,
		dateResponded: PropTypes.string,
		reasonForChange: PropTypes.string,
		solarUtilisationRate: PropTypes.number,
		offerValidityDate: PropTypes.string,
		requestGensetQuote: PropTypes.bool,
		requestOfferValidity: PropTypes.bool,
	}),
	onDirtyForm: PropTypes.func,
	onDataChange: PropTypes.func,
	onEnableEditMode: PropTypes.func,
	onDisableEditMode: PropTypes.func,
};

export default OffersTab;
