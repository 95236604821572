import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

import TileHeader from 'Common/components/Tile/TileHeader';
import { forwardRef, useState } from 'react';
import { css } from 'styled-components';
import useReponsive from 'Common/hooks/useResponsive';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import Loader from 'Common/components/Loader';
import FormField from 'Common/components/form/FormField';
import sizes from 'Application/theme/sizes';

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: ${({ $heightFitConent }) => ($heightFitConent ? 'fit-content' : '100%')};
	padding: ${({ $isTable }) =>
		$isTable ? `${sizes.spacing(2)} 0px ${sizes.spacing(2)} 0px` : sizes.spacing(2)};
	background: ${colors.common.tileBackgroundGrey};
	border-radius: 20px;
	border: 1px solid transparent;
	border-color: ${({ isActive }) => isActive && colors.primary.dark};

	${({ $isReadonly }) =>
		$isReadonly &&
		css`
			* {
				cursor: not-allowed !important;
			}

			label {
				color: ${colors.text.grey};
			}
		`}

	&:focus-within {
		border-color: ${colors.primary.dark};
		&:hover {
			border-color: ${colors.primary.dark};
		}
	}

	&:hover {
		border-color: ${({ $isReadonly, isActive }) => !isActive && !$isReadonly && colors.primary.main};
	}

	${({ $fieldsMaxWidth }) =>
		$fieldsMaxWidth &&
		css`
			${FormField} {
				max-width: ${typeof $fieldsMaxWidth === 'string' && $fieldsMaxWidth.includes('px')
					? $fieldsMaxWidth
					: `${$fieldsMaxWidth}px`};
			}
		`}
`;

const Tile = forwardRef(
	(
		{
			dataSection,
			children,
			tooltip,
			title,
			isLoading,
			isLoadingContent,
			rightComponent,
			isTable,
			isReadonly,
			heightFitConent,
			onClick,
			fieldsMaxWidth,
			...props
		},
		_ref,
	) => {
		// To elevate the tile that's on focus and keep popups, select menus, etc. on top of other tiles
		// set zIndex to 10 on focus (and set it back to 1 on blur)
		const [zIndex, setzIndex] = useState(1);
		const { isMobile } = useReponsive();

		return (
			<Wrapper
				data-section={dataSection}
				data-tile-wrapper={title}
				{...props}
				onClick={e => {
					if (isReadonly) e.stopPropagation();
					typeof onClick === 'function' && onClick(e);
				}}
				ref={_ref}
				onFocus={() => setzIndex(10)}
				onBlur={() => setzIndex(1)}
				$isTable={isTable}
				$heightFitConent={heightFitConent}
				$isReadonly={isReadonly}
				$fieldsMaxWidth={fieldsMaxWidth}
				$zIndex={zIndex}
			>
				{title && (
					<TileHeader
						title={title}
						tooltip={tooltip}
						isLoading={isLoading}
						rightComponent={rightComponent}
						isTableTile={isTable}
						$isMobile={isMobile}
					/>
				)}
				{isLoadingContent ? (
					<LoaderWrapper>
						<Loader />
					</LoaderWrapper>
				) : (
					<>{children}</>
				)}
			</Wrapper>
		);
	},
);

Tile.displayName = 'Tile';

Tile.defaultProps = {
	isLoading: false,
	isLoadingContent: false,
	isReadonly: false,
	dataSection: '',
	children: undefined,
	tooltip: null,
	title: undefined,
	rightComponent: undefined,
	isTable: false,
	heightFitConent: false,
	fieldsMaxWidth: undefined,
	onClick: undefined,
};

Tile.propTypes = {
	dataSection: PropTypes.string,
	children: PropTypes.node,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	title: PropTypes.string,
	isLoading: PropTypes.bool,
	isLoadingContent: PropTypes.bool,
	rightComponent: PropTypes.node,
	isTable: PropTypes.bool,
	isReadonly: PropTypes.bool,
	heightFitConent: PropTypes.bool,
	fieldsMaxWidth: PropTypes.string,
	onClick: PropTypes.func,
};

export default Tile;
