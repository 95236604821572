import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import styled, { css } from 'styled-components/macro';

import Overlay, { HeaderContentPortal, OverlayButtons } from 'Common/components/modals/Overlay';

import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import Icon from 'Common/components/icons/Icon';
import PropTypes from 'prop-types';
import axios from 'axios';
import { has } from 'lodash';
import showToastError from 'Common/utils/showToastError';
import FormField from 'Common/components/form/FormField';
import useOverlayTabTrap from 'Common/components/modals/hooks/useOverlayTabTrap';
import useSmartOverlayScroll from './hooks/useSmartOverlayScroll';
import useResponsive from 'Common/hooks/useResponsive';
import HoverTooltip from '../tooltip/HoverTooltip';
import { TableBody } from '../table/table';
import crudModes from 'Common/constants/crudModes';

const ContentWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	overflow: hidden;
	justify-content: center;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background: linear-gradient(180deg, rgba(242, 242, 242, 1) 0%, rgba(242, 242, 242, 0.5) 100%);
		z-index: 999;
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background: linear-gradient(180deg, rgba(242, 242, 242, 0.5) 0%, rgba(242, 242, 242, 1) 100%);
		z-index: 999;
	}
`;

const Sections = styled.div`
	position: relative;
	border-top: 1px solid ${colors.text.greyLight};
	border-bottom: 1px solid ${colors.text.greyLight};
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
`;

const PositionWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	transition: top 0.5s ease-in-out;
`;

const SectionContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	position: relative;
`;

const SectionTitleContainer = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateY(-50%);
`;

const SectionMainTitle = styled.div`
	writing-mode: vertical-lr;
	color: ${colors.text.greyLight};
	font-size: 20px;
	font-weight: 600;
	text-transform: uppercase;
	transform: rotate(180deg);
`;

const SectionSubTitle = styled.div`
	writing-mode: vertical-lr;
	align-self: flex-end;
	transform: rotate(180deg);
	font-size: 16px;
	font-weight: 400;
	color: ${colors.grey.main};
`;

const Section = styled.div`
	${({ $hideScrollbar }) => css`
		overflow: ${$hideScrollbar ? 'hidden' : 'auto'};
		width: 100%;
		padding: 0 !important;
	`}

	position: absolute;
	height: fit-content;
	top: 50%;
	transform: translateY(-50%);

	${TableBody} {
		overflow: visible !important;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${colors.grey.light};
	}
	&::-webkit-scrollbar-track-piece {
		background-color: ${colors.grey.lightest};
	}
	::-webkit-scrollbar-corner {
		background: rgba(0, 0, 0, 0);
	}
`;

export const SectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: flex-start;
	max-width: 100%;
	padding-top: 4vh;
	padding-bottom: ${({ $reduceBottomPadding }) => ($reduceBottomPadding ? '0' : '6vh')};
	height: max-content;
	${sizes.fontSize.main};

	${({ $maxWidth }) =>
		$maxWidth
			? css`
					${FormField} {
						max-width: ${typeof $maxWidth === 'string' && $maxWidth.includes('px') ? $maxWidth : `${$maxWidth}px`};
					}
			  `
			: css`
					${FormField} {
						max-width: 300px;
					}
			  `};

	${({ $minWidth }) =>
		$minWidth
			? css`
					${FormField} {
						min-width: ${typeof $minWidth === 'string' && $minWidth.includes('px') ? $minWidth : `${$minWidth}px`};
					}
			  `
			: css`
					${FormField} {
						min-width: fit-content;
					}
			  `}};
`;

const SectionControlsWrapper = styled.div`
	position: absolute;
	width: 40px;
	display: flex;
	gap: ${sizes.spacing(5)};
	height: fit-content;
	flex-direction: column;
	justify-content: space-between;
	padding: ${sizes.spacing(6)} 0 ${sizes.spacing(6)} 0;
`;

const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 36px;
	width: 36px;
	background-color: ${colors.primary.light};
	border-radius: 999px;
	cursor: pointer;
`;

const DotsWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const DotWrapper = styled.div`
	width: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: ${sizes.spacing(1)};
`;

const errorDotStyles = css`
	background-color: ${colors.error.main};
	transform: scale(2);
`;

const filledStyles = css`
	background-color: ${colors.success.main};
	transform: scale(2);
`;

const defaultStlyes = css`
	background-color: ${({ $isInvalid, $isFilled }) =>
		$isInvalid ? colors.error.dark : $isFilled ? colors.primary.dark : colors.primary.light};
`;

const activeStyles = css`
	background-color: ${colors.primary.light};
	filter: brightness(0.7);
`;

const SectionDot = styled.div`
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: scale(1);
	transition: transform 0.2s ease-in-out;

	${({ $isActive }) => ($isActive ? activeStyles : defaultStlyes)};

	${({ $isInvalid, $isFilled }) => ($isInvalid ? errorDotStyles : $isFilled ? filledStyles : css``)};
`;

const mobileStyles = css`
	${ContentWrapper} {
		&::before {
			display: none;
		}
		&::after {
			display: none;
		}
	}

	${Sections} {
		height: 85vh;
		width: 95%;
		border: none;
	}

	${PositionWrapper} {
		width: 80%;
		height: 85vh;
		top: calc(85vh * -${({ $selectedIndex }) => $selectedIndex});
		right: 10%;
	}

	${SectionContainer} {
		min-height: 85vh;
		max-height: 85vh;
	}

	${SectionTitleContainer} {
		left: -13%;
		top: 50%;
		flex-direction: column-reverse;
	}

	${Section} {
		max-height: 85vh;
		padding: ${sizes.spacing(9)} 0;
	}

	${SectionControlsWrapper} {
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	${SectionWrapper} {
		${FormField} {
			max-width: 260px;
		}
	}
`;
const tabletStyles = css`
	${ContentWrapper} {
		&::before {
			height: calc((100vh - 70vh - 208px) / 2);
		}
		&::after {
			height: calc((100vh - 70vh - 208px) / 2);
		}
	}

	${Sections} {
		height: 70vh;
		width: 90%;
	}

	${PositionWrapper} {
		width: 75%;
		height: 70vh;
		top: calc(70vh * -${({ $selectedIndex }) => $selectedIndex});
		right: 10%;
	}

	${SectionContainer} {
		min-height: 70vh;
		max-height: 70vh;
	}

	${SectionTitleContainer} {
		left: -15%;
		top: 50%;
	}

	${Section} {
		max-height: 70vh;
		padding: ${sizes.spacing(9)} 0;
	}

	${SectionControlsWrapper} {
		right: -2%;
		margin: auto;
	}
`;

const desktopStyles = css`
	${ContentWrapper} {
		&::before {
			height: calc((100vh - 65vh - 124px - 115px) / 2);
			@media (max-width: 1460px) {
				height: calc((100vh - 65vh - 124px - 84px) / 2);
			}
		}
		&::after {
			height: calc((100vh - 65vh - 124px - 115px) / 2);
			@media (max-width: 1460px) {
				height: calc((100vh - 65vh - 124px - 84px) / 2);
			}
		}
	}

	${Sections} {
		height: 65vh;
		width: 75%;

		@media (max-width: 1460px) {
			width: 85%;
		}
	}

	${PositionWrapper} {
		width: 85%;
		height: 65vh;
		top: calc(65vh * -${({ $selectedIndex }) => $selectedIndex});
		right: -1%;

		@media (max-width: 1460px) {
			width: 90%;
			right: -1%;
		}
	}

	${SectionContainer} {
		min-height: 65vh;
		max-height: 65vh;
	}

	${SectionTitleContainer} {
		left: -10%;
		top: 50%;
	}

	${Section} {
		max-height: 65vh;
		padding: ${sizes.spacing(9)} 0;
	}

	${SectionControlsWrapper} {
		right: -15%;
		@media (max-width: 1460px) {
			right: -8%;
		}
	}
`;

const Wrapper = styled.form`
	width: 100%;
	height: 100%;

	${({ $isMobile, $isTablet }) => ($isMobile ? mobileStyles : $isTablet ? tabletStyles : desktopStyles)}
`;

const checkValue = (values, key) => {
	const value = values[key];
	const element = document.getElementById(key) || document.getElementById(`${key}_static`);

	if (!element || element.tabIndex === -1) {
		return true;
	}

	if (element.type === 'checkbox' || element.type === 'radio') {
		return true;
	}

	if (value == null || value.length === 0) {
		return false;
	}

	return Boolean(value) || value === 0;
};

const OverlaySectionsContent = ({
	onSaveOverlay,
	onDirty,
	saveBtnLabel,
	closeBtnLabel,
	sections,
	defaultValues,
	sectionFields,
	validationSchema,
	headerContent,
	onCancel,
	isSubmitting,
	setIsSubmitting,
	mode,
}) => {
	const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
	const [isMounted, setIsMounted] = useState(false);

	const { isMobile, isTablet } = useResponsive();

	const {
		values,
		errors,
		touched,
		dirty,
		handleChange,
		setFieldTouched,
		setFieldValue,
		handleBlur,
		handleSubmit,
		validateForm,
		isValid,
	} = useFormik({
		initialValues: defaultValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			try {
				setIsSubmitting(true);
				await onSaveOverlay(values);
				setIsSubmitting(false);
			} catch (error) {
				showToastError(error, "Couldn't save overlay");
				if (!axios.isCancel(error)) {
					setIsSubmitting(false);
				}
			}
		},
	});

	const moveSectionArrow = useCallback(
		direction => {
			validateForm();
			const newIndex = selectedSectionIndex + direction;
			if (newIndex > -1 && newIndex < sections.length) {
				setSelectedSectionIndex(newIndex);
			}
		},
		[sections.length, selectedSectionIndex, validateForm],
	);

	useEffect(() => setIsMounted(true), []);

	const { sectionsContainerRef } = useOverlayTabTrap(selectedSectionIndex, moveSectionArrow);
	useSmartOverlayScroll(sectionsContainerRef, moveSectionArrow);
	const [navigation, setNavigation] = useState([]);

	useEffect(() => {
		let annualOmPriceId;
		if (isMounted) {
			// Adding requestAnimationFrame because the attributes checked in checkValue() are not updated in the same tick
			annualOmPriceId = requestAnimationFrame(() => {
				const navStates = Object.values(sectionFields).map(sectionField => {
					const hasError = sectionField.some(key => has(errors, key));
					const isInvalid = sectionField.some(key => has(errors, key) && has(touched, key));
					const isFilled = sectionField.length > 0 && sectionField.every(key => checkValue(values, key));

					return {
						hasError: hasError,
						isInvalid: isInvalid,
						isFilled: isFilled && !isInvalid,
					};
				});
				setNavigation(navStates);
			});
		}
		return () => cancelAnimationFrame(annualOmPriceId);
	}, [touched, errors, sectionFields, values, isMounted]);

	const handleSave = async e => {
		e.preventDefault();
		e.stopPropagation();
		handleSubmit();
		if (!isValid && firstErrorIndex > -1) {
			moveToSection(firstErrorIndex);
		}
	};

	const moveToSection = index => {
		setSelectedSectionIndex(index);
	};

	const firstErrorIndex = useMemo(() => navigation.findIndex(item => item.hasError), [navigation]);

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	const HeaderContent = headerContent;

	return (
		<Wrapper
			onSubmit={handleSave}
			$isMobile={isMobile}
			$isTablet={isTablet}
			$selectedIndex={selectedSectionIndex}
		>
			<ContentWrapper>
				{HeaderContent ? (
					<HeaderContentPortal>
						<HeaderContent
							values={values}
							errors={errors}
							touched={touched}
							handleChange={handleChange}
							handleBlur={handleBlur}
							setFieldTouched={setFieldTouched}
							setFieldValue={setFieldValue}
						/>
					</HeaderContentPortal>
				) : null}
				<Sections ref={sectionsContainerRef}>
					<PositionWrapper>
						{sections.map((section, index) => (
							// eslint-disable-next-line react/no-array-index-key
							<SectionContainer key={index}>
								<SectionTitleContainer>
									<SectionMainTitle>{section.title}</SectionMainTitle>
									<SectionSubTitle>Section {index + 1}</SectionSubTitle>
								</SectionTitleContainer>
								<Section
									className="overlay-section"
									data-section-index={index}
									$hideScrollbar={selectedSectionIndex !== index}
								>
									{section.section({
										values: values,
										errors: errors,
										touched: touched,
										sectionTouched: navigation[index]?.sectionTouched,
										handleChange: handleChange,
										setFieldTouched: setFieldTouched,
										setFieldValue: setFieldValue,
										handleBlur: handleBlur,
										mode,
									})}
								</Section>
							</SectionContainer>
						))}
					</PositionWrapper>
					<SectionControlsWrapper>
						<IconWrapper onClick={() => moveSectionArrow(-1)}>
							<Icon size="small" icon="keyboardArrowUp" color={colors.primary.dark} />
						</IconWrapper>
						<DotsWrapper>
							{navigation.map(({ isInvalid, isFilled }, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<DotWrapper key={index}>
									<HoverTooltip title={sections[index].title}>
										<SectionDot
											$isActive={index === selectedSectionIndex}
											$isInvalid={isInvalid}
											$isFilled={isFilled}
											onClick={() => moveToSection(index)}
										>
											{isInvalid ? (
												<Icon icon="reportProblem" size="tiny" color={colors.common.white} />
											) : isFilled ? (
												<Icon icon="check" size="tiny" color={colors.common.white} />
											) : (
												<></>
											)}
										</SectionDot>
									</HoverTooltip>
								</DotWrapper>
							))}
						</DotsWrapper>
						<IconWrapper onClick={() => moveSectionArrow(1)}>
							<Icon size="small" icon="keyboardArrowDown" color={colors.primary.dark} />
						</IconWrapper>
					</SectionControlsWrapper>
				</Sections>
			</ContentWrapper>
			<OverlayButtons
				onCancel={onCancel}
				isLoading={isSubmitting}
				label="Client Form"
				submitButtonText={saveBtnLabel}
				closeButtonText={closeBtnLabel}
				noSubmitButton={mode === crudModes.VIEW}
			/>
		</Wrapper>
	);
};

OverlaySectionsContent.defaultProps = {
	sections: [],
	defaultValues: {},
	sectionFields: {},
	validationSchema: {},
	saveBtnLabel: 'Save',
	closeBtnLabel: 'Cancel',
	onSaveOverlay: () => {},
	onDirty: () => {},
	onCancel: () => {},
	isSubmitting: false,
	headerContent: null,
	setIsSubmitting: () => {},
};

OverlaySectionsContent.propTypes = {
	sections: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			section: PropTypes.func,
		}),
	),
	defaultValues: PropTypes.shape({}),
	sectionFields: PropTypes.shape({}),
	validationSchema: PropTypes.shape({}),
	saveBtnLabel: PropTypes.string,
	closeBtnLabel: PropTypes.string,
	onSaveOverlay: PropTypes.func,
	onDirty: PropTypes.func,
	onCancel: PropTypes.func,
	isSubmitting: PropTypes.bool,
	headerContent: PropTypes.node,
	setIsSubmitting: PropTypes.func,
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
};

const OverlaySectionsWrapper = ({
	label,
	breadcrumbList,
	isOpen,
	onSaveOverlay,
	onClose,
	headerContent,
	isLoadingContent,

	sections,
	defaultValues,
	sectionFields,
	validationSchema,
	closeBtnLabel,
	saveBtnLabel,
	mode,
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	return (
		<Overlay
			label={label}
			isOpen={isOpen}
			onSave={() => {}}
			onClose={onClose}
			breadcrumbList={breadcrumbList}
			isLoadingContent={isLoadingContent}
			isSubmitting={isSubmitting}
		>
			{(setIsFormDirty, _, onCancel) => (
				<OverlaySectionsContent
					onSaveOverlay={onSaveOverlay}
					onDirty={setIsFormDirty}
					sections={sections}
					saveBtnLabel={saveBtnLabel}
					closeBtnLabel={closeBtnLabel}
					defaultValues={defaultValues}
					sectionFields={sectionFields}
					validationSchema={validationSchema}
					headerContent={headerContent}
					onCancel={onCancel}
					isSubmitting={isSubmitting}
					setIsSubmitting={setIsSubmitting}
					mode={mode}
				/>
			)}
		</Overlay>
	);
};

OverlaySectionsWrapper.defaultProps = {
	label: 'Overlay Sections',
	breadcrumbList: [],
	isOpen: false,
	onSaveOverlay: () => {},
	onClose: () => {},
	headerContent: null,
	isLoadingContent: false,
	sections: [],
	defaultValues: {},
	sectionFields: {},
	validationSchema: {},
	saveBtnLabel: 'Save',
	closeBtnLabel: 'Cancel',
};

OverlaySectionsWrapper.propTypes = {
	label: PropTypes.string,
	breadcrumbList: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			onClick: PropTypes.func,
		}),
	),

	isOpen: PropTypes.bool,
	onSaveOverlay: PropTypes.func,
	onClose: PropTypes.func,
	headerContent: PropTypes.node,
	isLoadingContent: PropTypes.bool,
	sections: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			section: PropTypes.func,
		}),
	),
	defaultValues: PropTypes.shape({}),
	sectionFields: PropTypes.shape({}),
	validationSchema: PropTypes.shape({}),
	saveBtnLabel: PropTypes.string,
	closeBtnLabel: PropTypes.string,
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
};

export default OverlaySectionsWrapper;
