import api from 'Application/api/api';

const getUserByEmail = (signal, email) =>
	api.get('/users/by-email', {
		params: {
			email,
		},
		signal,
	});

export default getUserByEmail;
