import { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { push } from 'redux-first-history';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import Filters from 'Common/components/filter/FiltersContainer';
import InquiriesListTable from 'Inquiries/components/InquiriesList/InquiriesListTable';
import useInquiriesFilterData from 'Inquiries/components/InquiriesList/hooks/useInquiriesFilterData';

const InquiriesList = () => {
	const { t } = useTranslation();
	const storedTableState = useSelector(state => state.tables.inquiries);
	const [shouldTableRender, setShouldTableRender] = useState(false);
	const dispatch = useDispatch();

	const location = useLocation();

	useEffect(() => {
		if (!shouldTableRender) {
			if (location.search === '' && storedTableState && storedTableState.url) {
				dispatch(push(`/inquiries${storedTableState.url}`));
			}
			setShouldTableRender(true);
		}
	}, [dispatch, storedTableState, location.search, shouldTableRender]);

	const filterButtonRef = useRef();

	const { filtersData } = useInquiriesFilterData();

	return (
		<>
			<Helmet>
				<title>{t('Inquiries')}</title>
			</Helmet>
			<HeaderContainer>
				<HeaderTitle>{t('Inquiries')}</HeaderTitle>
				<HeaderActions>
					<div ref={filterButtonRef}></div>
				</HeaderActions>
			</HeaderContainer>
			<Filters table="inquiries" buttonRef={filterButtonRef} filtersData={filtersData} />
			{(shouldTableRender || !storedTableState?.url) && <InquiriesListTable />}
		</>
	);
};

export default InquiriesList;
