import api from 'Application/api/api';

const getCountries = (signal, query, sort) =>
	api.get('/countries/get-pageable', {
		params: {
			query,
			sort,
			page: 0,
			size: 999,
		},
		signal,
	});

export default getCountries;
