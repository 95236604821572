import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components/macro';

const StyledContentLoaderContainer = styled(ContentLoader)`
	width: 100%;
	height: 94px;
	border-radius: 10px;
	margin-bottom: ${sizes.spacing(2)};
	transform-origin: top left;
`;

const StyledContent = styled.rect`
	width: 100%;
	height: 94px;
	x: 0;
	y: 0;
	rx: 10px;
	ry: 10px;
`;

const SkeletonNotifications = () => (
	<StyledContentLoaderContainer
		speed={3}
		backgroundColor={colors.skeleton.defaultBG}
		foregroundColor={colors.skeleton.defaultFG}
	>
		<StyledContent />
	</StyledContentLoaderContainer>
);

export default SkeletonNotifications;
