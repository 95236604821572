/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';

import FormikRadio from 'Common/components/form/FormikRadio';
import GraphControl from 'Impact/components/GraphControl';
import TileHeader from 'Common/components/Tile/TileHeader';
import GraphFilter from 'Impact/tiles/ImpactProjectsTile/GraphFilter';

import ImpactChart from 'Impact/components/ImpactChart';
import { graphOptions, chartTypes, filterOptions, projectFields } from 'Impact/constants/options';
import FormikSelectFilter from 'Common/components/filter/SelectFilter';

import useImpactFiltersData from 'Impact/hooks/useImpactFiltersData';
import useFiltersData from 'Common/components/filter/hooks/useFiltersData';

import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';

import { useTranslation } from 'react-i18next';
import useReponsive from 'Common/hooks/useResponsive';
import sizes from 'Application/theme/sizes';

const TileContainer = styled(Grid)`
	height: 100%;
	background-color: ${colors.common.tileBackgroundGrey};
	border-radius: 20px;
	border: 1px solid transparent;
	&:hover {
		border-color: ${colors.primary.main};
	}
`;

const TitleHeaderWrapper = styled.div`
	padding: 16px 16px 0px 16px;
`;

const FilterContainer = styled.div`
	padding-right: ${sizes.spacing(2)};
	padding-bottom: ${sizes.spacing(1)};
	max-width: 140px;
`;

const RadioContainer = styled.div`
	padding-bottom: ${sizes.spacing(1)};
`;

const StyledGrid = styled(Grid)`
	height: ${props => props.height || '100%;'} !important;
`;

const ChartWrapper = styled.div`
	padding: 0 3% 0 3%;
	width: 100%;
	height: 340px;
	transition: 0.2s ease-in-out;
	@media (max-width: 620px) {
		height: 350px;
	}
`;

const TimeFilterWrapper = styled.div`
	padding-right: ${sizes.spacing(2)};
`;

const HeaderControlsContainer = styled.div`
	display: flex;
	flex-direction: ${({ $isMobile }) => ($isMobile ? 'column' : 'row')};
	align-items: flex-end;
	padding-top: ${sizes.spacing(2)};
	padding-bottom: ${sizes.spacing(3)};
`;
const ImpactProjectsTile = ({ data }) => {
	const { t } = useTranslation();
	const { isMobile, isTablet } = useReponsive();

	const countryFilterData = useImpactFiltersData(data);
	const { filtersList, filtersValues, setFilterValue } = useFiltersData(countryFilterData, 'campaigns');
	const { errors, touched, values, handleChange } = useFormik({
		initialValues: { status: 'IN_OPERATION' },
		enableReinitialize: true,
	});

	const [filters, setFilters] = useState({ time: 'Max', country: [], fields: '' });
	const [labelIndex, setLabelIndex] = useState(0);
	const labels = useMemo(() => ['MWp', '# of Projects', '# of Clients', 'kWh Energy'], []);

	const onFilterSelect = value => {
		setFilters(prevState => ({
			...prevState,
			...value,
		}));
	};

	useEffect(() => {
		onFilterSelect(filtersValues);
	}, [filtersValues]);

	useEffect(() => {
		const fieldName = labels[labelIndex];
		let filterFilterName =
			values.status === 'SIGNED' ? projectFields[fieldName][0] : projectFields[fieldName][1];
		onFilterSelect({ fields: [filterFilterName] });
	}, [labelIndex, labels, values]);

	const onControlSelect = index => {
		const idx = index < 0 ? labels.length - 1 : index === labels.length ? 0 : index;
		setLabelIndex(idx);
	};

	return (
		<>
			<TileContainer container>
				<StyledGrid item xs={2} sm={2} md={2} lg={2} xl={2} height={'45px;'}>
					<TitleHeaderWrapper>
						<TileHeader title={t('Projects')} />
					</TitleHeaderWrapper>
				</StyledGrid>
				<StyledGrid item xs={10} sm={10} md={10} lg={10} xl={10} height={'45px;'}>
					<Grid container justifyContent="flex-end">
						<HeaderControlsContainer $isMobile={isMobile || isTablet}>
							<FilterContainer>
								<FormikSelectFilter
									{...filtersList[0]}
									values={filtersValues[filtersList[0].name]}
									setFilterValue={(...args) => setFilterValue(filtersList[0], ...args)}
								/>
							</FilterContainer>
							<RadioContainer>
								<FormikRadio
									id={'status'}
									name="status"
									value={values.status}
									error={errors.grid}
									touched={touched.grid}
									onChange={handleChange}
									options={[
										{
											value: 'SIGNED',
											label: 'Signed',
										},
										{
											value: 'IN_OPERATION',
											label: 'In operation',
										},
									]}
									isRow
								/>
							</RadioContainer>
						</HeaderControlsContainer>
					</Grid>
				</StyledGrid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<GraphControl labels={labels} selectedIndex={labelIndex} onControlSelect={onControlSelect} />
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<ChartWrapper>
						<ImpactChart
							data={data}
							filters={filters}
							type={chartTypes.STACKED_LINE}
							options={{ ...graphOptions[chartTypes.STACKED_LINE], ...filterOptions[filters.time] }}
						/>
					</ChartWrapper>
				</Grid>
				<Grid container>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<TimeFilterWrapper>
							<GraphFilter filter={filters.time} onFilterSelect={onFilterSelect} />
						</TimeFilterWrapper>
					</Grid>
				</Grid>
			</TileContainer>
		</>
	);
};

ImpactProjectsTile.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ImpactProjectsTile;
