const ENTITY_DETAILS_PAGE_TAB_TILES = {
	GENERAL: {
		DETAILS: 'details',
		ADDRESS: 'address',
		DIRECTORS: 'directors',
		PARTNERS: 'partners',
	},
	CAPITAL: {
		CAPITAL: 'capital',
	},
};

const ENTITY_DETAILS_PAGE_TILES_BY_TAB = {
	GENERAL: Object.values(ENTITY_DETAILS_PAGE_TAB_TILES.GENERAL),
	CAPITAL: Object.values(ENTITY_DETAILS_PAGE_TAB_TILES.CAPITAL),
};

export { ENTITY_DETAILS_PAGE_TAB_TILES, ENTITY_DETAILS_PAGE_TILES_BY_TAB };
