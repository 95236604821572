export const mountingTypes = {
	ROOF: 'ROOF',
	GROUND: 'GROUND',
	TBD: 'TBD',
	FLOATING: 'FLOATING',
	CARPARK: 'CARPARK',
};

export const mountingTypeOptions = [
	{
		label: 'Roof',
		value: mountingTypes.ROOF,
	},
	{
		label: 'Ground',
		value: mountingTypes.GROUND,
	},
	{
		label: 'TBD',
		value: mountingTypes.TBD,
	},
	{
		label: 'Floating',
		value: mountingTypes.FLOATING,
	},
	{
		label: 'Carpark',
		value: mountingTypes.CARPARK,
	},
];
