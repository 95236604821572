import PropTypes from 'prop-types';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import { useTranslation } from 'react-i18next';
import deleteAssetAndRevenueRecognition from 'AssetAndRevenueRecognition/api/deleteAssetAndRevenueRecognition';
import useAbortController from 'Common/hooks/useAbortController';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useState } from 'react';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import axios from 'axios';

const AssetAndRevenueRecognitionDeleteModal = ({ assetForDeletion, isOpen, onCancel, onFormSubmit }) => {
	const { t } = useTranslation();
	const [deletionError, setDeletionError] = useState();

	const abortController = useAbortController();

	const handleDeleteAssetAndRevenueRecognition = async id => {
		try {
			await deleteAssetAndRevenueRecognition(abortController.signal, id);

			notify(t('Asset & Revenue Recognition deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});

			onFormSubmit();
			onCancel();
		} catch (error) {
			if (!axios.isCancel(error)) {
				setDeletionError(error?.response?.data);
				onCancel();
			}
		}
	};
	return (
		<>
			<ConfirmModal
				isOpen={isOpen}
				onCancel={onCancel}
				onConfirm={() => handleDeleteAssetAndRevenueRecognition(assetForDeletion?.id)}
				label="delete-asset-revenue-confirm"
				heading={t('Delete Asset & Revenue Recognition')}
				text={t(`Are you sure you want to delete Asset & Revenue Recognition ${assetForDeletion?.name} ?`)}
			/>
			<UnsuccessfulOperationModal
				label="cannot-delete-asset-revenue"
				isOpen={Boolean(deletionError)}
				onCancel={() => setDeletionError(null)}
				heading={t('Cannot delete Asset & Revenue Recognition')}
				text={deletionError?.violations?.[0]?.message}
			/>
		</>
	);
};

AssetAndRevenueRecognitionDeleteModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	assetForDeletion: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
};

export default AssetAndRevenueRecognitionDeleteModal;
