import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { push } from 'redux-first-history';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { stringify } from 'qs';

import Filters from 'Common/components/filter/FiltersContainer';

import { updateCurrentTab } from 'Application/reducers/reduxTabs';
import ButtonLink from 'Common/components/buttons/ButtonLink';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import { zonedTimeToUtc, format } from 'date-fns-tz';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import defaultTimezone from 'Common/constants/defaultTimezone';

import useTranchesListFilters from 'Campaigns/components/Tabs/TranchesList/hooks/useTranchesListFilters';
import useCampaignsListFilters from 'Campaigns/components/Tabs/CampaignsList/hooks/useCampaignsListFilters';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import TabsContainer from 'Common/components/tabs';
import CampaignsList from 'Campaigns/components/Tabs/CampaignsList/CampaignsList';
import TranchesList from 'Campaigns/components/Tabs/TranchesList/TranchesList';
import CampaignCreateOverlay from './CampaignForm/CampaignCreateOverlay';

const FiltersWrapper = styled.div`
	background: ${colors.common.headerGrey};
`;

const campaignsSortPropertiesMap = {
	systemSize: 'financeDetails.systemSize',
	investments_sum: 'investmentsSum',
	id: 'externalId',
	maximum_volume: 'maximumVolume',
};

const tranchesSortPropertiesMap = {
	investable_time_from: 'investableFrom',
	investable_time_until: 'investableUntil',
	interestRate: 'trancheDetails.interestRate',
	name: 'trancheDetails.emissionSpv.name',
};

const Campaigns = () => {
	const routerLocation = useLocation();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const tranchesFilterButtonRef = useRef();
	const campaignsFilterButtonRef = useRef();

	const trancesFiltersData = useTranchesListFilters();
	const campaignFiltersData = useCampaignsListFilters();

	const sortByQueryParam = useQueryParameter('sortBy');
	const filters = useQueryParameter('filters');

	const isTranchesTab = useMemo(
		() => routerLocation.pathname.includes('/tranches'),
		[routerLocation.pathname],
	);

	useEffect(() => {
		dispatch(updateCurrentTab({ tab: 'campaigns', tabIndex: isTranchesTab ? 1 : 0 }));
	}, [isTranchesTab, dispatch]);

	const onTabSelect = useCallback(
		selectedTabIndex => {
			if (selectedTabIndex === 0) {
				dispatch(push('/campaigns'));
			} else {
				dispatch(push('/tranches'));
			}
		},
		[dispatch],
	);

	const openExport = useCallback(
		exportWithOptions => {
			let isTranchesTabExport = routerLocation.pathname.includes('/tranches');
			let sortPropertiesMap = isTranchesTabExport ? tranchesSortPropertiesMap : campaignsSortPropertiesMap;

			let urlBase = isTranchesTabExport ? 'api/tranches/export-table-view' : 'api/finances/export-table-view';
			const sortById =
				sortByQueryParam && sortPropertiesMap[sortByQueryParam.id]
					? sortPropertiesMap[sortByQueryParam.id]
					: sortByQueryParam?.id;

			if (exportWithOptions) {
				const formatedFilers = isTranchesTabExport
					? {
							...filters,
							investableFrom:
								filters.investableFrom &&
								zonedTimeToUtc(
									format(new Date(filters.investableFrom), "yyyy-MM-dd'T'HH:mm.sss'Z'", {
										timeZone: defaultTimezone,
									}),
									defaultTimezone,
								),
							investableUntil:
								filters.investableUntil &&
								zonedTimeToUtc(
									format(new Date(filters.investableUntil), "yyyy-MM-dd'T'HH:mm.sss'Z'", {
										timeZone: defaultTimezone,
									}),
									defaultTimezone,
								),
					  }
					: filters;

				let url = `${urlBase}?${
					sortByQueryParam
						? 'sort=' + sortById + '%2C' + (sortByQueryParam.desc ? 'DESC' : 'ASC')
						: 'sort=' + (isTranchesTabExport ? 'reference' : 'name') + '%2CASC'
				}&${stringify(formatedFilers)}&size=9999&`;

				window.open(url, '_blank');
			} else {
				let url = `${urlBase}?&size=9999&${
					sortByQueryParam
						? 'sort=' + sortById + '%2C' + (sortByQueryParam.desc ? 'DESC' : 'ASC')
						: 'sort=' + (isTranchesTabExport ? 'reference' : 'name') + '%2CASC'
				}`;

				window.open(url, '_blank');
			}
		},
		[sortByQueryParam, filters, routerLocation.pathname],
	);

	const tabsConfig = useMemo(
		() => [
			{
				label: 'Campaigns',
				PanelComponent: CampaignsList,
				onTabSelect,
			},
			{
				label: 'Tranches',
				PanelComponent: TranchesList,
				onTabSelect,
			},
		],
		[onTabSelect],
	);

	const [isCreateOpen, setIsCreateOpen] = useState(false);

	const handleCreateClose = () => {
		setIsCreateOpen(false);
	};

	return (
		<>
			<Helmet>
				<title>{t('Campaigns')}</title>
			</Helmet>
			<HeaderContainer>
				<HeaderTitle>{t('Campaigns')}</HeaderTitle>
				<HeaderActions>
					{isTranchesTab ? <div ref={tranchesFilterButtonRef}></div> : <div ref={campaignsFilterButtonRef}></div>}

					{isAuthorized([
						roles.ADMIN,
						roles.FINANCE,
						roles.MANAGEMENT,
						roles.PM_FUNDRAISING,
						roles.ASSET_MARKETING,
					]) && (
						<ButtonLink
							label="Add Campaign - Campaigns"
							type="submit"
							onClick={() => setIsCreateOpen(true)}
							text={t('Add Campaign')}
							icon="addRounded"
						/>
					)}
					<MoreActionsButton label="Campaigns More Actions">
						{filters && (
							<MenuItem
								onClick={() => openExport(true)}
								type="button"
								data-action="exportFiltered"
								label="Campaigns - Export filtered to CSV Menu Item"
							>
								{t('Export filtered to CSV')}
							</MenuItem>
						)}
						<MenuItem
							onClick={() => openExport(false)}
							type="button"
							data-action="exportAll"
							label="Campaigns - Export all to CSV Menu Item"
						>
							{t('Export all to CSV')}
						</MenuItem>
					</MoreActionsButton>
				</HeaderActions>
			</HeaderContainer>

			<FiltersWrapper>
				{isTranchesTab ? (
					<Filters
						key="1"
						table={'tranches'}
						buttonRef={tranchesFilterButtonRef}
						filtersData={trancesFiltersData}
					/>
				) : (
					<Filters
						key="2"
						table={'campaigns'}
						buttonRef={campaignsFilterButtonRef}
						filtersData={campaignFiltersData}
					/>
				)}
			</FiltersWrapper>
			<TabsContainer page="campaigns" tabsConfig={tabsConfig} />
			<CampaignCreateOverlay
				isOpen={isCreateOpen}
				onClose={handleCreateClose}
				onFormSubmit={data => dispatch(push(`/campaigns/details/${data.auto_id}`))}
			/>
		</>
	);
};

export default Campaigns;
