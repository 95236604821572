export const axisLabelPlugin = text => {
	return [
		{
			beforeDraw: function (chart) {
				const { ctx } = chart;
				ctx.restore();
				let fontSize = 0.75;
				ctx.font = fontSize + 'em sans-serif';
				ctx.textBaseline = 'top';
				ctx.fillStyle = '#B3B3B3';
				ctx.fillText(text, 4, 0);
				ctx.restore();
				ctx.save();
			},
		},
	];
};

export const dougnutPlugin = () => {
	return [
		{
			beforeDraw: function (chart) {
				const { width, height, ctx } = chart;
				ctx.restore();
				var fontSize = (height / 160).toFixed(2);
				ctx.font = '700 ' + fontSize + 'em sans-serif';
				ctx.textBaseline = 'top';
				const textFull = (chart.config.options.totalValue || '-') + ' users';
				const textX = Math.round((width - ctx.measureText(textFull).width) / 2);
				const textY = height / 2 - 5;
				ctx.fillText(textFull, textX, textY);
				ctx.save();
			},
		},
	];
};

export const activeLabelPlugin = () => {
	return [
		{
			afterDraw: function (chart) {
				if (chart?.tooltip?._active && chart?.tooltip?._active.length) {
					if (chart.tooltip?.dataPoints[0].dataset.activeLabel) {
						const { width, ctx } = chart;
						ctx.restore();
						let fontSize = 0.75;
						ctx.font = fontSize + 'em sans-serif';
						ctx.textBaseline = 'top';
						ctx.fillStyle = '#2AA4BC';
						const text = chart.tooltip?.dataPoints[0].dataset.activeLabel;
						const textX = width - ctx.measureText(text).width - 4;
						ctx.fillText(text, textX, 0);
						ctx.restore();
						ctx.save();
					}
				}
			},
		},
	];
};
