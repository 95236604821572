import api from 'Application/api/api';

const getCountriesByIds = (signal, ids) =>
	api.get('/countries/by-ids', {
		params: {
			ids,
		},
		signal,
	});

export default getCountriesByIds;
