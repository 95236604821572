import api from 'Application/api/api';
import adaptProjectResponseData from 'Projects/api/adapters/adaptProjectResponseData';

const assignMountingTypesToProject = async (signal, projectId, mountingTypes) => {
	const response = await api.post(
		'/projects/assign-mounting-types',
		{
			projectId,
			mountingTypes,
		},
		{
			signal,
		},
	);

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptProjectResponseData(response.data),
	};
};

export default assignMountingTypesToProject;
