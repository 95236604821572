import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import format from 'date-fns/format';
// import { parseISO } from 'date-fns';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import EditableTile from 'Common/components/Tile/EditableTile';
import EditableFieldset from 'Common/components/form/EditableFieldset';

import editProject from 'Projects/api/editProject';
import useCommissioningFormInitialValues from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/CommissioningTile/hooks/useCommissioningFormInitialValues';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';
import axios from 'axios';
import FormikSelect from 'Common/components/form/FormikSelect';
import { unitSystemSizeOptions } from './constants/unitSystemSizeOptions';

const StyledFormField = styled.div`
	width: 250px;
	white-space: nowrap;
`;

const CommissioningTile = ({
	project,
	onEnterEditMode,
	onExitEditMode,
	onDirtyForm,
	onTileSave,
	highlightLabels,
}) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.PM_TECHNICAL,
		roles.ASSET_MANAGER,
		roles.MANAGEMENT,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useCommissioningFormInitialValues(project);

	const { errors, touched, values, handleSubmit, dirty, setFieldValue, setFieldTouched, resetForm } =
		useFormik({
			initialValues,
			onSubmit: async values => {
				setIsSavingChanges(true);
				let response;

				try {
					response = await editProject(abortController.signal, {
						...project,
						...values,
						commercialOperationsDate: values.commercialOperationsDate
							? format(new Date(values.commercialOperationsDate), 'yyyy-MM-dd')
							: null,
						commissioningAcceptanceDate: values.commissioningAcceptanceDate
							? format(new Date(values.commissioningAcceptanceDate), 'yyyy-MM-dd')
							: null,
						projectedCommercialOperationsDate: values.projectedCommercialOperationsDate
							? format(new Date(values.projectedCommercialOperationsDate), 'yyyy-MM-dd')
							: null,
						unitSystemSize: values.unitSystemSize ? values.unitSystemSize.value : null,
						clientId: project?.client?.id,
						regionalHubId: project?.regionalHub?.id || null,
					});
					onDirtyForm(false);
					onTileSave(response.data);
					handleCancel();
					setIsSavingChanges(false);
					resetForm({ values });
				} catch (e) {
					showToastError(e);
					if (!axios.isCancel(e)) {
						handleCancel();
						setIsSavingChanges(false);
					}
				}
			},
		});

	// FormikSingleDatePicker should have date, so cant be null or - and we can't use the check for isInEditMode
	// const projectCommissionDateValue = project.projectCommissionDate
	//     ? format(parseISO(project.projectCommissionDate), 'd/M/yyyy')
	//     : '';

	// const projectCommissionDateCommunicatedToCrowdValue =
	//     project.projectCommissionDateCommunicatedToCrowd
	//         ? format(
	//               parseISO(project.projectCommissionDateCommunicatedToCrowd),
	//               'd/M/yyyy',
	//           )
	//         : '';

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		onDirtyForm(dirty);
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Commissioning')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<EditableFieldset>
				<StyledFormField>
					<FormikSingleDatePicker
						label={t('Commercial operations date (COD)')}
						id="commercialOperationsDate"
						startDateName="commercialOperationsDate"
						error={errors.commercialOperationsDate}
						touched={touched.commercialOperationsDate}
						startDate={values.commercialOperationsDate}
						setFieldValue={setFieldValue}
						setFieldTouched={setFieldTouched}
						isTile
						isInEditMode={isInEditMode}
						tooltip={{
							tooltip: t(
								'Date when system is operating and Client invoicing commences with a PPA or lease tariff. Communicated to Clients and Investors (Inbetriebnahme in German). Also the start date for CO2 impact calculations.',
							),
						}}
						isHighlighted={highlightLabels.includes(
							PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_COMMISSIONING.COMMERCIAL_OPERATIONS_DATE,
						)}
					/>
				</StyledFormField>
				<StyledFormField>
					<FormikSingleDatePicker
						label={t('Commissioning acceptance date')}
						id="commissioningAcceptanceDate"
						startDateName="commissioningAcceptanceDate"
						error={errors.commissioningAcceptanceDate}
						touched={touched.commissioningAcceptanceDate}
						startDate={values.commissioningAcceptanceDate}
						setFieldValue={setFieldValue}
						setFieldTouched={setFieldTouched}
						isTile
						isInEditMode={isInEditMode}
						tooltip={{
							tooltip: t(
								'Date when acceptance certificate is issued to EPC Partner, and the last EPC milestone payment is due. Communicated internally and to EPC Partners only',
							),
						}}
						isHighlighted={highlightLabels.includes(
							PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_COMMISSIONING.COMMISSIONING_ACCEPTANCE_DATE,
						)}
					/>
				</StyledFormField>
				<StyledFormField>
					<FormikSingleDatePicker
						label={t('Projected commercial operations date')}
						id="projectedCommercialOperationsDate"
						startDateName="projectedCommercialOperationsDate"
						error={errors.projectedCommercialOperationsDate}
						touched={touched.projectedCommercialOperationsDate}
						startDate={values.projectedCommercialOperationsDate}
						setFieldValue={setFieldValue}
						setFieldTouched={setFieldTouched}
						isTile
						isInEditMode={isInEditMode}
						tooltip={{
							tooltip: t(
								'Date when acceptance certificate is expected to be issued to EPC Partner, and the last EPC milestone payment would be due. Communicated internally only',
							),
						}}
						isHighlighted={highlightLabels.includes(
							PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_COMMISSIONING.PROJECTED_COMMERCIAL_OPERATIONS_DATE,
						)}
					/>
				</StyledFormField>
				<StyledFormField>
					<FormikSelect
						label={t('Unit System Size')}
						id="unitSystemSize"
						name="unitSystemSize"
						error={errors.unitSystemSize}
						touched={touched.unitSystemSize}
						value={values.unitSystemSize}
						setFieldValue={setFieldValue}
						setFieldTouched={setFieldTouched}
						isTile
						isOverlay
						options={unitSystemSizeOptions}
						isInEditMode={isInEditMode}
						isHighlighted={highlightLabels.includes(
							PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_COMMISSIONING.UNIT_SYSTEM_SIZE,
						)}
					/>
				</StyledFormField>
			</EditableFieldset>
		</EditableTile>
	);
};

CommissioningTile.defaultProps = {
	project: {},
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	onTileSave: async () => {},
	highlightLabels: [],
};

CommissioningTile.propTypes = {
	project: PropTypes.shape({
		client: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
		regionalHub: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
	}),
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	onTileSave: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default CommissioningTile;
