const pluginName = 'useCustomFooterStyle';

export const useCustomFooterStyle = hooks => {
	hooks.getFooterGroupProps.push(getFooterGroupProps);
};

useCustomFooterStyle.pluginName = pluginName;

const getFooterGroupProps = (props, { instance, row }) => [
	props,
	{
		style: {
			...instance.getCustomFooterStyles(row),
		},
	},
];
