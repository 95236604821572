import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import useReponsive from 'Common/hooks/useResponsive';
import sizes from 'Application/theme/sizes';
import { PropTypes } from 'prop-types';

const Wrapper = styled.div`
	padding: ${({ $isMobile }) => ($isMobile ? sizes.spacing(1) : sizes.spacing(2))};
`;

const TilesGridWrapper = ({ children, ...props }) => {
	const { isMobile } = useReponsive();

	return (
		<Wrapper $isMobile={isMobile} {...props}>
			<Grid container spacing={isMobile ? 1 : 2}>
				{children}
			</Grid>
		</Wrapper>
	);
};

TilesGridWrapper.propTypes = {
	children: PropTypes.node.isRequired,
};

export default TilesGridWrapper;
