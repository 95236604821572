export const titles = {
	MALE: 'Male',
	FEMALE: 'Female',
	OTHER: 'Other',
};

export const titleOptions = [
	{
		value: titles.MALE,
		label: titles.MALE,
	},
	{
		value: titles.FEMALE,
		label: titles.FEMALE,
	},
	{
		value: titles.OTHER,
		label: titles.OTHER,
	},
];
