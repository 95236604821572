import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import { SectionWrapper } from 'Common/components/modals/OverlaySections';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DetailsSection = ({ values, errors, touched, handleBlur, handleChange, setFieldTouched }) => {
	const { t } = useTranslation();

	return (
		<SectionWrapper>
			<FormField data-first-field>
				<FormikInput
					id="name"
					name="name"
					label={t('Name')}
					value={values.name}
					error={errors.name}
					touched={touched.name}
					onChange={handleChange}
					setFieldTouched={setFieldTouched}
					onBlur={handleBlur}
					isRequired
					isOverlay
				/>
			</FormField>
			<FormField data-last-field>
				<FormikInput
					id="website"
					name="website"
					label={t('Website')}
					value={values.website}
					error={errors.website}
					touched={touched.website}
					onChange={handleChange}
					setFieldTouched={setFieldTouched}
					onBlur={handleBlur}
					isOverlay
				/>
			</FormField>
		</SectionWrapper>
	);
};

DetailsSection.defaultProps = {
	values: {},
	errors: {},
	touched: {},
	handleBlur: () => {},
	handleChange: () => {},
	setFieldTouched: () => {},
};

DetailsSection.propTypes = {
	values: PropTypes.shape({
		name: PropTypes.string,
		website: PropTypes.string,
	}),
	errors: PropTypes.shape({
		name: PropTypes.string,
		website: PropTypes.string,
	}),
	touched: PropTypes.shape({
		name: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		website: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
	}),
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	setFieldTouched: PropTypes.func,
};

export default DetailsSection;
