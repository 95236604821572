import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import ProjectDetailsTile from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/ProjectDetailsTile/ProjectDetailsTile';
import { PROJECT_DETAILS_PAGE_TAB_TILES } from 'Projects/constants/projectTabForms';
import StatusTile from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/StatusTile/StatusTile';
import LocationTile from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/LocationTile/LocationTile';
import CurrenciesTile from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/CurrenciesTile/CurrenciesTile';
import ResponsiblePeopleTile from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/ResponsiblePeopleTile/ResponsiblePeopleTile';
import EngagementTile from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/EngagementTile/EngagementTile';
import ProjectEntitiesTile from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/ProjectEntitiesTile/ProjectEntitiesTile';

const ProjectTab = ({ project, onEnterEditMode, onExitEditMode, onDirtyForm, handleUpdateProject }) => {
	const handleEngagementDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.PROJECT.ENGAGEMENT, isDirty);
		},
		[onDirtyForm],
	);

	const handleProjectDetailsDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.PROJECT.PROJECT_DETAILS, isDirty);
		},
		[onDirtyForm],
	);

	const handleStatusDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.PROJECT.STATUS, isDirty);
		},
		[onDirtyForm],
	);

	const handleLocationDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.PROJECT.LOCATION, isDirty);
		},
		[onDirtyForm],
	);

	const handleResponsiblePeopleDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.PROJECT.RESPONSIBLE_PEOPLE, isDirty);
		},
		[onDirtyForm],
	);

	const handleCurrenciesDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.PROJECT.CURRENCIES, isDirty);
		},
		[onDirtyForm],
	);

	const handleContractPartiesDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.PROJECT.PROJECT_ENTITIES, isDirty);
		},
		[onDirtyForm],
	);

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
				<ProjectDetailsTile
					project={project}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onDirtyForm={handleProjectDetailsDirtyForm}
					onTileSave={handleUpdateProject}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<EngagementTile
					project={project}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onDirtyForm={handleEngagementDirtyForm}
					onTileSave={handleUpdateProject}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<ResponsiblePeopleTile
					project={project}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onDirtyForm={handleResponsiblePeopleDirtyForm}
					onTileSave={handleUpdateProject}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<StatusTile
					project={project}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onDirtyForm={handleStatusDirtyForm}
					onTileSave={handleUpdateProject}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<LocationTile
					project={project}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onDirtyForm={handleLocationDirtyForm}
					onTileSave={handleUpdateProject}
				/>
			</Grid>
			{project?.currencies?.length > 0 && (
				<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
					<CurrenciesTile
						project={project}
						onEnterEditMode={onEnterEditMode}
						onExitEditMode={onExitEditMode}
						onDirtyForm={handleCurrenciesDirtyForm}
						onTileSave={handleUpdateProject}
					/>
				</Grid>
			)}
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<ProjectEntitiesTile
					project={project}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onTileSave={handleUpdateProject}
					onDirtyForm={handleContractPartiesDirtyForm}
				/>
			</Grid>
		</TilesGridWrapper>
	);
};

ProjectTab.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	handleUpdateProject: async () => {},
};

ProjectTab.propTypes = {
	project: PropTypes.shape({
		currencies: PropTypes.arrayOf(PropTypes.shape({})),
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	handleUpdateProject: PropTypes.func,
};

export default ProjectTab;
