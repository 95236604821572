import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import EditableTile from 'Common/components/Tile/EditableTile';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { DefaultTable, TableHeadCell, TableBodyCell } from 'Common/components/table/table';
import PropTypes from 'prop-types';
import ShareholderDeleteModal from 'Shareholders/components/ShareholderDeleteModal/ShareholderDeleteModal';
import ShareholderCreateModal from 'Shareholders/components/ShareholderCreateModal/ShareholderCreateModal';
import ShareholderEditModal from 'Shareholders/components/ShareholderEditModal/ShareholderEditModal';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}
	${TableBodyCell} {
		background: transparent;
	}
`;

const ShareholdersList = ({ entity, onDataChange }) => {
	const { t } = useTranslation();

	const shareholders = entity.shareholders;

	const [shareholderIdForEditing, setShareholderIdForEditing] = useState(null);

	const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

	const [shareholderForDeletion, setShareholderForDeletion] = useState(null);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Name'),
				accessor: 'name',
			},
			{
				Header: t('# Shares'),
				accessor: 'numberOfShares',
			},
			{
				Header: t('% Shares'),
				accessor: 'percentageOfShares',
			},
			{
				Header: '',
				accessor: 'actionDots',
				maxWidth: 70,
				customCellStyles: {
					justifyContent: 'flex-end',
				},
				customBodyCellContentStyles: {
					padding: '5px',
				},
			},
		],
		[t],
	);

	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			shareholders.map(shareholder => ({
				...shareholder,
				name: shareholder?.name || '-',
				numberOfShares: shareholder?.numberOfShares || '-',
				percentageOfShares: Math.round((shareholder?.numberOfShares / entity.numberOfShares) * 100) || '-',
				actionDots: (
					<MoreActionsButton label="Entity Detail Capital Tab Shareholder List More Actions" controlledVisibility>
						<MenuItem
							data-action="edit"
							onClick={() => setShareholderIdForEditing(shareholder.id)}
							label="Entity Detail Capital Tab Shareholder List - Edit Menu Item"
						>
							Edit
						</MenuItem>
						<MenuItem
							data-action="delete"
							onClick={() => setShareholderForDeletion({ id: shareholder.id, name: shareholder.name })}
							label="Entity Detail Capital Tab Shareholder List - Delete Menu Item"
						>
							Delete
						</MenuItem>
					</MoreActionsButton>
				),
			})),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t, shareholders],
	);

	const handleDataUpdate = data => {
		onDataChange(data);
	};

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
			},
			useCustomCellStyles,
		);

	const availableEntityShares = useMemo(
		() =>
			(entity.numberOfShares || 0) -
			shareholders.reduce((acc, shareholder) => acc + shareholder.numberOfShares, 0),
		[entity.numberOfShares, shareholders],
	);

	return (
		<EditableTile
			title={t('Shareholders')}
			rightComponent={
				<TileActionButton data-button="shareholders" onClick={() => setIsCreateModalOpened(true)} />
			}
			isTable
		>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
				/>
			</TableWrapper>

			<ShareholderCreateModal
				isOpen={isCreateModalOpened}
				entityId={entity.id}
				onClose={() => setIsCreateModalOpened(false)}
				availableEntityShares={availableEntityShares}
				onFormSubmit={handleDataUpdate}
			/>

			{shareholderIdForEditing !== null && (
				<ShareholderEditModal
					isOpen
					onClose={() => setShareholderIdForEditing(null)}
					onFormSubmit={handleDataUpdate}
					availableEntityShares={availableEntityShares}
					shareholderId={shareholderIdForEditing}
				/>
			)}

			<ShareholderDeleteModal
				isOpen={Boolean(shareholderForDeletion)}
				onFormSubmit={handleDataUpdate}
				shareholderForDeletion={shareholderForDeletion}
				onCancel={() => setShareholderForDeletion(null)}
			/>
		</EditableTile>
	);
};

ShareholdersList.propTypes = {
	entity: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		shareholders: PropTypes.arrayOf(PropTypes.shape({})),
		numberOfShares: PropTypes.number,
	}).isRequired,
	onDataChange: PropTypes.func.isRequired,
};

export default ShareholdersList;
