import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components/macro';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import useResponsive from 'Common/hooks/useResponsive';
import { useMemo } from 'react';

const Wrapper = styled.div`
	${({ $isMobile, $isSmallScreen }) => css`
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		height: 100%;
		margin: 0 ${$isMobile ? 0 : $isSmallScreen ? sizes.spacing(4) : 'auto'};
		max-width: ${$isMobile || $isSmallScreen ? '1440px' : '80%'};
	`}
`;

const Separator = styled.hr`
	${({ $isMobile }) => css`
		width: ${$isMobile ? '90%' : '100%'};
		border-style: solid;
		border-color: ${colors.text.greyLight};
		border-width: 0px;
		border-top-width: 1px;
	`}

	&:first-of-type {
		margin: ${sizes.spacing(3)} auto 0 auto;
		margin-top: ;
	}

	&:last-of-type {
		margin: 0;
		margin-bottom: 0 auto ${sizes.spacing(3)} auto;
	}
`;

const SectionsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	overflow-y: auto;

	&::-webkit-scrollbar-thumb {
		background-color: ${colors.grey.light};
	}
	&::-webkit-scrollbar-track-piece {
		background-color: ${colors.grey.lightest};
	}
`;

const SectionTitleContainer = styled.div`
	display: flex;
	align-items: center;
	position: sticky;
	top: 0;
	height: 100%;
`;

const ChildrenContainer = styled.div`
	${({ $isMobile }) => css`
		padding-top: ${sizes.spacing(2)};
		align-items: center;
		height: 100%;
		margin-right: ${$isMobile ? sizes.spacing(2) : sizes.spacing(0)};
		${$isMobile ? 'width: 100%' : 'width: 70%'};
	`}
`;

const SectionTextContainer = styled(Grid)`
	writing-mode: vertical-lr;
	color: #cccccc;
	font-size: 30px;
	font-weight: 600;
	text-transform: uppercase;
	transform: rotate(180deg);
	user-select: none;
	padding: ${sizes.spacing(1)} 0 ${sizes.spacing(1)} ${sizes.spacing(2)};
`;

const SectionContainer = ({ name, children }) => {
	const { isMobile, isTablet, isSmallscreen, isMediumScreen } = useResponsive();

	const isSmallScreen = useMemo(
		() => isTablet || isSmallscreen || isMediumScreen,
		[isTablet, isSmallscreen, isMediumScreen],
	);

	return (
		<Wrapper $isMobile={isMobile} $isSmallScreen={isSmallScreen}>
			<Separator $isMobile={isMobile} />
			<SectionsContainer>
				<SectionTitleContainer>
					<SectionTextContainer>{name}</SectionTextContainer>
				</SectionTitleContainer>
				<ChildrenContainer $isMobile={isMobile}>{children}</ChildrenContainer>
				<SectionTitleContainer />
			</SectionsContainer>
			<Separator $isMobile={isMobile} />
		</Wrapper>
	);
};

SectionContainer.defaultProps = {
	name: '',
	children: undefined,
};

SectionContainer.propTypes = {
	name: PropTypes.string,
	children: PropTypes.node,
};

export default SectionContainer;
