import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import ModalForm from 'Common/components/form/ModalForm';
import ExpellContactForm from './ExpellContactForm';

const ExpellContactModal = ({ isOpen, onClose, onFormSubmit, contactId }) => {
	const { t } = useTranslation();
	const [deletionError, setDeletionError] = useState(null);

	return (
		<>
			<ModalForm
				isOpen={isOpen}
				onFormSubmit={onFormSubmit}
				onClose={onClose}
				label="mark-as-expelled-contract"
				heading={t('Mark as expelled')}
				helpText={`${t('This will permanently mark the contact as expelled and disable their login to pulse.')}
				
				${t('Add reason for expelling.')}`}
			>
				{(setIsFormDirty, handleSubmit, onCancel) => (
					<ExpellContactForm
						contactId={contactId}
						onDirty={setIsFormDirty}
						setDeletionError={setDeletionError}
						onSubmit={handleSubmit}
						onCancel={onCancel}
					/>
				)}
			</ModalForm>
			<UnsuccessfulOperationModal
				label="cannot-expell-contact"
				isOpen={deletionError !== null}
				onCancel={() => setDeletionError(null)}
				heading={t('Cannot expell contact')}
				text={deletionError?.violations?.[0]?.message}
			/>
		</>
	);
};

ExpellContactModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	contactId: PropTypes.string.isRequired,
};

export default ExpellContactModal;
