import { pick } from 'lodash';

const convertFormValuesToRequestData = values => ({
	...pick(values, ['website', 'firstName', 'lastName', 'phone']),
	name: values.name.trim(),
	email: values.email || null,
	countryIds: values.countryIds ? values.countryIds.map(country => country.value) : [],
	address: {
		line1: values.line1,
		line2: values.line2,
		apartmentBuilding: values.apartmentBuilding,
		cityRegion: values.cityRegion,
		postCodeZip: values.postCodeZip,
	},
	inviteToPortal: values.inviteToPortal,
});

export default convertFormValuesToRequestData;
