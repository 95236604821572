import api from 'Application/api/api';

const uploadNewPhoto = (signal, email, files) =>
	api.post(`/users/upload-new-photo?userEmail=${email}`, files, {
		signal,
		timeout: 100000,
		headers: {
			'Content-Type': 'multipart/form-data',
			Accept: '*/*',
		},
	});

export default uploadNewPhoto;
