import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import ContactsTile from './Tiles/ContactsTile/ContactsTile';

const ContactsTab = ({ epcPartner }) => {
	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<ContactsTile epcPartner={epcPartner} />
			</Grid>
		</TilesGridWrapper>
	);
};

ContactsTab.defaultProps = {
	epcPartner: {},
};

ContactsTab.propTypes = {
	epcPartner: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
};

export default ContactsTab;
