import { useMemo } from 'react';

const fallbackValues = {
	name: '',
	rate: '',
};

const useUtilityRateFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			name: data?.name || '',
			rate: data?.rate || '',
		}),
		[data],
	);

export default useUtilityRateFormInitialValues;
