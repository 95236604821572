import useAbortController from 'Common/hooks/useAbortController';
import showToastError from 'Common/utils/showToastError';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useDynamicLoadedOptions = loadOptions => {
	const { t } = useTranslation();

	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();

	const onSearch = useCallback(
		inputValue => {
			setIsLoading(true);
			loadOptions(abortController.signal, inputValue)
				.then(newOptions => {
					setOptions(newOptions);
				})
				.catch(err => {
					showToastError(err, t('Error while loading options'));
				})
				.finally(() => {
					setIsLoading(false);
				});

			return inputValue;
		},
		[abortController.signal, loadOptions, t],
	);

	useEffect(() => {
		(async () => {
			setIsLoading(true);

			const newOptions = await loadOptions(abortController.signal, '');

			setOptions(newOptions);
			setIsLoading(false);
		})();
	}, [abortController.signal, loadOptions]);

	return { options, isLoading, onSearch };
};

export default useDynamicLoadedOptions;
