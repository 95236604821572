import { useCallback, useEffect, useMemo, useState } from 'react';

import crudModes from 'Common/constants/crudModes';

import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import OverlaySections from 'Common/components/modals/OverlaySections';
import getManufacturerByName from 'Manufacturer/api/getManufacturerByName';
import useManufacturerOverlayValues from './hooks/useManufacturerOverlayValues';
import useManufacturerOverlaySections from './hooks/useManufacturerOverlaySections';
import useManufacturerOverlayValidationSchema from './hooks/useManufacturerOverlayValidationSchema';
import { useLocation } from 'react-router';
import createManufacturer from 'Manufacturer/api/createManufacturer';
import editManufacturer from 'Manufacturer/api/editManufacturer';
import convertFormValuesToRequestData from './utils/convertFormValuesToRequestData';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useOverlayTitles } from 'Common/components/modals/Overlay';

const ManufacturerOverlay = ({
	mode,
	isOpen,
	onClose,
	onFormSubmit,
	manufacturerName,
	productTypeForNewManufacturer,
}) => {
	const [manufacturer, setManufacturer] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();
	const { name, label, saveBtnLabel, closeBtnLabel } = useOverlayTitles(t('Manufacturer'), mode);

	const abortController = useAbortController();

	const isEditMode = useMemo(() => mode === crudModes.EDIT, [mode]);

	useEffect(() => {
		(async () => {
			if (!isEditMode) return;

			setIsLoading(true);
			try {
				const response = await getManufacturerByName(abortController.signal, manufacturerName);

				setManufacturer(response.data);
				setIsLoading(false);
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		})();
	}, [abortController.signal, isEditMode, manufacturerName]);

	const { pathname } = useLocation();

	const breadcrumbList = [
		{
			label: t('Project'),
			href: pathname,
		},
		{
			label: name,
		},
	];

	const { defaultValues, sectionFields } = useManufacturerOverlayValues(
		manufacturer,
		productTypeForNewManufacturer,
	);
	const sections = useManufacturerOverlaySections();
	const validationSchema = useManufacturerOverlayValidationSchema(productTypeForNewManufacturer);

	const handleCreateSubmit = useCallback(
		async values => {
			try {
				const { data: createdManufacturer } = await createManufacturer(abortController.signal, {
					// The 'name' is set only the first time (probably the back-end uses it as PK),
					// so it only needs to be set during the creation of the manufacturer.
					name: values.displayName.trim(),
					...convertFormValuesToRequestData(values),
				});

				await onFormSubmit(createdManufacturer);

				onClose();

				notify(t('Manufacturer created successfully.'), {
					type: toast.TYPE.SUCCESS,
				});
			} catch (error) {
				showToastError(error, "Couldn't create Manufacturer");

				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
		[abortController.signal, onClose, onFormSubmit, t],
	);

	const handleEditSubmit = useCallback(
		async values => {
			try {
				const { data: editedManufacturer } = await editManufacturer(abortController.signal, {
					...manufacturer,
					...values,
					...convertFormValuesToRequestData(values),
				});
				await onFormSubmit(editedManufacturer);

				onClose();

				notify(t('Manufacturer saved successfully'), {
					type: toast.TYPE.SUCCESS,
				});
			} catch (error) {
				showToastError(error, 'Manufacturer could not be saved');
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
		[abortController.signal, manufacturer, onFormSubmit, onClose, t],
	);

	return (
		<OverlaySections
			label={label}
			breadcrumbList={breadcrumbList}
			isOpen={isOpen}
			onClose={onClose}
			onSaveOverlay={mode === crudModes.CREATE ? handleCreateSubmit : handleEditSubmit}
			isLoadingContent={isLoading}
			sections={sections}
			defaultValues={defaultValues}
			sectionFields={sectionFields}
			validationSchema={validationSchema}
			saveBtnLabel={saveBtnLabel}
			closeBtnLabel={closeBtnLabel}
		/>
	);
};

ManufacturerOverlay.defaultProps = {
	mode: crudModes.CREATE,
	isOpen: false,
	onClose: () => {},
	onFormSubmit: () => {},
	manufacturerName: '',
	productTypeForNewManufacturer: '',
};

ManufacturerOverlay.propTypes = {
	mode: PropTypes.oneOf([crudModes.CREATE, crudModes.EDIT]),
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onFormSubmit: PropTypes.func,
	manufacturerName: PropTypes.string,
	productTypeForNewManufacturer: PropTypes.string,
};

export default ManufacturerOverlay;
