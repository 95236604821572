import formatNumber from 'Common/utils/formatNumber';

const formatCurrency = (number, decimalPlaces = 0, rawCurrencyPrefix = '€') => {
	const isValidNumber = !isNaN(number);

	if (isValidNumber) {
		const isEuro = rawCurrencyPrefix === 'EUR';
		const currencyPrefix = isEuro ? '€' : rawCurrencyPrefix;

		return `${currencyPrefix} ${formatNumber(number, decimalPlaces)}`;
	}

	return '';
};

export default formatCurrency;
