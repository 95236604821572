import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import styled from 'styled-components/macro';

const SidePanelLineSeparator = styled.div`
	width: calc(100% + 32px);
	height: 1px;
	background-color: ${colors.common.lightGrey};
	transform: translateX(-16px);
	margin-bottom: ${sizes.spacing(2)};
`;

export default SidePanelLineSeparator;
