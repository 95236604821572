import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';

import notify from 'Common/utils/notify';
import crudModes from 'Common/constants/crudModes';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import editPartnerTypes from 'Preferences/api/editPartnerTypes';
import getPartnerTypes from 'Preferences/api/getPartnerTypes';
import createPartnerTypes from 'Preferences/api/createPartnerTypes';

import usePartnerTypeFormValidationSchema from 'Preferences/components/Tabs/GeneralTab/PartnerTypes/PartnerTypesForm/hooks/usePartnerTypeFormValidationSchema';
import usePartnerTypeFormInitialValues from 'Preferences/components/Tabs/GeneralTab/PartnerTypes/PartnerTypesForm/hooks/usePartnerTypeFormInitialValues';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { ModalFormButtons } from 'Common/components/form/ModalForm';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const FieldsWrapper = styled.div`
	width: 100%;
`;

const PartnerTypesForm = ({ data, mode, onSubmit, onCancel, onDirty }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();

	const initialValues = usePartnerTypeFormInitialValues(data);
	const validationSchema = usePartnerTypeFormValidationSchema();

	const { errors, touched, values, handleSubmit, dirty, handleChange, handleBlur, setFieldError, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: async values => {
				try {
					setIsLoading(true);
					if (mode === crudModes.CREATE) {
						await createPartnerTypes(abortController.signal, values);

						notify(t('Partner type created successfully'), {
							type: toast.TYPE.SUCCESS,
						});
					} else if (mode === crudModes.EDIT) {
						await editPartnerTypes(abortController.signal, {
							...data,
							...values,
						});

						notify(t('Partner type saved successfully'), {
							type: toast.TYPE.SUCCESS,
						});
					}

					const response = await getPartnerTypes(abortController.signal);
					onSubmit(response.data);

					// Re-set all fields and mark them as not touched
					resetForm({ values });
					setIsLoading(false);
				} catch (error) {
					showAxiosResponseError({
						error,
						setFormikFieldError: setFieldError,
					});
					if (!axios.isCancel(error)) {
						setIsLoading(false);
					}
				}
			},
		});

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form onSubmit={handleSubmit}>
			<FieldsWrapper>
				<FormField>
					<FormikInput
						id="name"
						name="name"
						label={t('Name')}
						value={values.name}
						error={errors.name}
						touched={touched.name}
						onChange={handleChange}
						onBlur={handleBlur}
						isRequired
						isInEditMode={mode === crudModes.CREATE}
					/>
				</FormField>
			</FieldsWrapper>
			<ModalFormButtons
				onCancel={onCancel}
				isLoading={isLoading}
				label="Preferences Partner Types Form"
				submitButtonText={mode === crudModes.CREATE ? t('Create') : t('Save')}
			/>
		</Form>
	);
};

PartnerTypesForm.defaultProps = {
	data: null,
};

PartnerTypesForm.propTypes = {
	data: PropTypes.shape({}),
	mode: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
};

export default PartnerTypesForm;
