import { ensurePluginOrder, makePropGetter, useGetLatest } from 'react-table';
import { pick } from 'lodash';

const pluginName = 'useVirtualizedTableBody';

export const useVirtualizedTableBody = hooks => {
	hooks.getVirtualizedTableBodyProps = [props => ({ ...props })];
	hooks.getVirtualizedTableBodyProps.push(getVirtualizedTableBodyProps);

	hooks.useInstance.push(useInstance);
};

useVirtualizedTableBody.pluginName = pluginName;

const useInstance = instance => {
	const { getHooks, plugins } = instance;

	ensurePluginOrder(plugins, ['useAdditionalTableProps', 'useScrollableTableBody'], 'useVirtualizedTableBody');

	const getInstance = useGetLatest(instance);

	instance.getVirtualizedTableBodyProps = makePropGetter(getHooks().getVirtualizedTableBodyProps, {
		instance: getInstance(),
	});
};

const getVirtualizedTableBodyProps = (props, { instance }) => [
	props,
	{
		...pick(instance.virtualization, [
			'totalRowsCount',
			'rowHeight',
			'rowsOverscanCount',
			'rowsHash',
			'loadMoreRows',
		]),
	},
];
