import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Label from 'Common/components/form/Label';
import ErrorMessage from 'Common/components/form/ErrorMessage';
import { css } from 'styled-components';
import MuiSwitch from '@mui/material/Switch';
import colors from 'Application/theme/colors';

const StyledSwitch = styled(MuiSwitch)`
	background: ${({ checked }) => (checked ? colors.primary.dark : colors.text.greyLight)};
	border-radius: 14px;
	height: 28px !important;
	padding: 0;

	.MuiButtonBase-root {
		padding: 4px;
	}

	.MuiSwitch-thumb {
		box-shadow: none;
		color: ${colors.common.white};
	}

	.MuiSwitch-track {
		background-color: initial !important;
	}

	.MuiSwitch-switchBase.Mui-checked {
		transform: translateX(30px);
	}

	.MuiSwitch-colorSecondary.Mui-checked {
		color: ${colors.common.white};
	}

	${({ disabled }) =>
		disabled &&
		css`
			background: ${colors.grey.main}!important;
			opacity: 1 !important;
		`};
`;

const Switch = ({ label, error, setValue, value, ...props }) => {
	const handleChange = event => {
		setValue(event.target.checked);
	};

	const hasError = !!error;
	const hasLabel = label !== null;

	return (
		<>
			{hasLabel && <Label label={label} hasError={hasError} htmlFor={props.id} />}

			<StyledSwitch onChangeCapture={handleChange} checked={value} {...props} />

			{hasError && <ErrorMessage message={error.message} />}
		</>
	);
};

Switch.defaultProps = {
	label: null,
	error: null,
	value: false,
	id: null,
};

Switch.propTypes = {
	label: PropTypes.string,
	error: PropTypes.shape({
		message: PropTypes.string,
	}),
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	setValue: PropTypes.func.isRequired,
	value: PropTypes.bool,
};

export default Switch;
