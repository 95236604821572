const { useRef, useEffect, useState, useCallback } = require('react');
import useResponsive from 'Common/hooks/useResponsive';

const SCROLL_DELTA_THRESHOLD = 50;

const hasVerticalScroll = el => {
	return el?.scrollHeight > el?.clientHeight;
};

const useSmartOverlayScroll = (sectionsContainerRef, moveSectionArrow) => {
	const { isTouchDevice } = useResponsive();

	const [touchStart, setTouchStart] = useState(null);
	const [touchEnd, setTouchEnd] = useState(null);

	const onTouchStart = useCallback(
		e => {
			setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
			setTouchStart(e.targetTouches[0].clientX);
		},
		[setTouchStart],
	);

	const onTouchMove = useCallback(e => setTouchEnd(e.targetTouches[0].clientY), [setTouchEnd]);

	const onTouchEnd = useCallback(() => {
		if (!touchStart || !touchEnd) return;
		const distance = touchStart - touchEnd;
		const isDownSwipe = distance > SCROLL_DELTA_THRESHOLD;
		const isUpSwipe = distance < -SCROLL_DELTA_THRESHOLD;
		if (isDownSwipe || isUpSwipe) {
			moveSectionArrow(isDownSwipe ? 1 : -1);
		}
	}, [moveSectionArrow, touchEnd, touchStart]);

	const scrollingState = useRef({
		isScrolling: false,
		isBlocked: false,
		maxDelta: 0,
		direction: 0,
	});

	const animationFrame = useRef(null);

	useEffect(() => {
		const el = sectionsContainerRef.current;
		if (el) {
			const handleMouseWheel = e => {
				const isSelect = e.target.closest('[data-select="true"]');
				const sectionContainer = e.target.closest('[data-section-index]');

				if (isSelect) return;
				if (!sectionContainer) return;
				if (scrollingState.current.isBlocked) return;

				scrollingState.current.scrollingState = true;
				scrollingState.current.maxDelta = Math.max(scrollingState.current.maxDelta, Math.abs(e.deltaY));
				scrollingState.current.direction = e.deltaY > 0 ? 1 : -1;

				cancelAnimationFrame(animationFrame.current);
				animationFrame.current = requestAnimationFrame(() => {
					let isBlocked = false;
					let isLongBlock = false;
					const hasSectionVerticalScroll = hasVerticalScroll(sectionContainer);
					const scrolledToEndOfSection =
						scrollingState.current.direction === 1
							? sectionContainer.scrollTop + sectionContainer.clientHeight >= sectionContainer.scrollHeight
							: sectionContainer.scrollTop === 0;

					if (hasSectionVerticalScroll && !scrolledToEndOfSection) {
						isBlocked = true;
						isLongBlock = true;
					}

					if (!isBlocked && scrollingState.current.maxDelta > SCROLL_DELTA_THRESHOLD) {
						moveSectionArrow(scrollingState.current.direction);
						isBlocked = true;
					}
					scrollingState.current = {
						isScrolling: false,
						isBlocked,
						maxDelta: 0,
						direction: 0,
					};
					if (isBlocked) {
						setTimeout(
							() => {
								scrollingState.current.isBlocked = false;
							},
							isLongBlock ? 1000 : 300,
						);
					}
				});
			};

			if (!isTouchDevice) {
				el.addEventListener('mousewheel', handleMouseWheel);
				// ? Firefox
				el.addEventListener('DOMMouseScroll', handleMouseWheel);
			} else {
				el.addEventListener('touchstart', onTouchStart);
				el.addEventListener('touchmove', onTouchMove);
				el.addEventListener('touchend', onTouchEnd);
			}

			return () => {
				el.removeEventListener('mousewheel', handleMouseWheel);
				// ? Firefox
				el.removeEventListener('DOMMouseScroll', handleMouseWheel);

				el.removeEventListener('touchstart', onTouchStart);
				el.removeEventListener('touchmove', onTouchMove);
				el.removeEventListener('touchend', onTouchEnd);

				cancelAnimationFrame(animationFrame.current);
			};
		}
	}, [isTouchDevice, moveSectionArrow, onTouchEnd, onTouchMove, onTouchStart, sectionsContainerRef]);
};

export default useSmartOverlayScroll;
