import { pick } from 'lodash';

import Big from 'Common/utils/customBig';

import { fullNameProjectTypes } from 'Projects/constants/projectTypes';

const convertFormValuesToRequestData = values => ({
	...pick(values, ['clientName', 'description']),

	systemSizeKwp: values.projectType?.label === fullNameProjectTypes.PV ? values.systemSizeKwp : null,
	kwhToKwpRel: values.projectType?.label === fullNameProjectTypes.PV ? values.kwhToKwpRel : null,
	solarUtilisationRate:
		values.projectType?.label === fullNameProjectTypes.PV && values.solarUtilisationRate !== 0
			? Big(values.solarUtilisationRate).div(100)
			: null,
	currency: values.epcOfferCurrency.value,
	epcVolumeExclVat: {
		currency: values.epcOfferCurrency.value,
		value: String(values.epcVolumeExclVat).replace(/\s/g, ''),
	},
	epcVolumeExclVatKwp:
		values.epcVolumeExclVatKwp && values.projectType?.label === fullNameProjectTypes.PV
			? {
					currency: values.epcOfferCurrency.value,
					value: String(values.epcVolumeExclVatKwp).replace(/\s/g, ''),
			  }
			: null,
	annualOmPrice: {
		currency: values.epcOfferCurrency.value,
		value: String(values.annualOmPrice).replace(/\s/g, ''),
	},
	annualOmPriceKwp:
		values.annualOmPriceKwp && values.projectType?.label === fullNameProjectTypes.PV
			? {
					currency: values.epcOfferCurrency.value,
					value: String(values.annualOmPriceKwp).replace(/\s/g, ''),
			  }
			: null,
	gridEnergyTariffPerKwh: values.gridEnergyTariffPerKwh
		? {
				currency: values.tariffCurrency.value,
				value: String(values.gridEnergyTariffPerKwh).replace(/\s/g, ''),
		  }
		: null,
	countryId: values.country ? values.country.value : null,
	projectTypeId: values.projectType ? values.projectType.value : null,
	industryId: values.industry ? values.industry.value : null,
	onGrid: values.projectType?.label === fullNameProjectTypes.PV && values.onGrid === 'on-grid',
	energyStorage: values.projectType?.label === fullNameProjectTypes.PV ? values.energyStorage : false,
	gensets: values.projectType?.label === fullNameProjectTypes.PV ? values.gensets : false,
	clientOffers: values.options
		.map(option => {
			const isOptionValid =
				!!option?.contractType?.label &&
				!!option?.contractType?.value &&
				!!option?.duration &&
				!!values?.offerCurrency?.value;
			if (isOptionValid) {
				return {
					contractType: {
						name: option.contractType.label,
						id: option.contractType.value,
					},

					currency: values.offerCurrency.value,
					duration: option.duration,
				};
			} else {
				return undefined;
			}
		})
		.filter(el => !!el),
	annualOmEscalationRate:
		values?.annualOmEscalationRate && Number(Big(values?.annualOmEscalationRate).div(100).toString()),
});

export default convertFormValuesToRequestData;
