import { useMemo } from 'react';

const useRfepOverlayValues = (project, rfep, isCreateMode) => {
	const defaultValues = useMemo(
		() =>
			isCreateMode
				? {
						systemSize: project.systemSizeKwp,
						projectType: project.projectType?.name,
						requestExpectedGridInjection: true,
						hideClientName: false,
						documentDefs: [],
						description: '',
						previouslyInvitedPartners: [],
						rfepCloseDateActive: false,
						requestGensetQuote: false,
						requestOfferValidity: false,
				  }
				: {
						systemSize: rfep?.systemSize || project.systemSizeKwp,
						description: rfep?.description,
						tenderCloseDate: rfep?.tenderCloseDate,
						rfepCloseDateActive: Boolean(rfep?.tenderCloseDate),
						requestGensetQuote: rfep?.requestGensetQuote,
						requestOfferValidity: rfep?.requestOfferValidity,
						requestExpectedGridInjection: rfep?.requestExpectedGridInjection,
						hideClientName: rfep?.hideClientName,
						...rfep?.documentDefs?.reduce(
							(res, { documentTitle, request, mandatory }) => ({
								...res,
								[`${documentTitle}_request`]: request ? ['on'] : [],
								[`${documentTitle}_mandatory`]: mandatory ? ['on'] : mandatory === false ? [] : undefined,
							}),
							{},
						),
						previouslyInvitedPartners: rfep?.invitedPartners || [],
						state: rfep?.state,
						invitedPartners: rfep?.invitedPartners,
				  },
		[
			isCreateMode,
			project.projectType?.name,
			project.systemSizeKwp,
			rfep?.description,
			rfep?.documentDefs,
			rfep?.hideClientName,
			rfep?.invitedPartners,
			rfep?.requestExpectedGridInjection,
			rfep?.requestGensetQuote,
			rfep?.requestOfferValidity,
			rfep?.state,
			rfep?.systemSize,
			rfep?.tenderCloseDate,
		],
	);

	const sectionFields = useMemo(
		() => ({
			general: ['hideClientName', 'description'],
			project: [
				'systemSize',
				'requestOfferValidity',
				'rfepCloseDateActive',
				'requestGensetQuote',
				'tenderCloseDate',
			],
			images: [],
			documents: ['documentDefs'],
			partners: ['invitedPartners'],
		}),
		[],
	);

	return { sectionFields, defaultValues };
};

export default useRfepOverlayValues;
