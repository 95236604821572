import { pick } from 'lodash';
import formatDateForRequestData from 'Common/utils/formatDateForRequestData';

const convertFormValuesToRequestData = values => ({
	...pick(values, ['premiumPercentage', 'paymentVerification', 'projectId', 'validityStatus']),
	insuredSum:
		values.insuredCurrency && values.insuredSum !== ''
			? {
					currency: values.insuredCurrency.value,
					value: values.insuredSum,
			  }
			: null,
	premium:
		values.insuredCurrency && values.premium !== ''
			? {
					currency: values.insuredCurrency.value,
					value: values.premium,
			  }
			: null,
	policyCost:
		values.policyCurrency && values.policyCost !== ''
			? {
					currency: values.policyCurrency.value,
					value: values.policyCost,
			  }
			: null,
	insuredCurrency: values.insuredCurrency ? values.insuredCurrency.value : null,
	policyCurrency: values.policyCurrency ? values.policyCurrency.value : null,
	policyStartDate: values.policyStartDate ? formatDateForRequestData(values.policyStartDate) : null,
	policyEndDate: values.policyEndDate ? formatDateForRequestData(values.policyEndDate) : null,
	entityId: values.insuredEntity ? values.insuredEntity.value : null,
	providerId: values.providerName ? values.providerName.value : null,
});

export default convertFormValuesToRequestData;
