import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';

import Loader from 'Common/components/Loader';
import Breadcrumb from 'Common/components/breadcrumbs/Breadcrumb';
import Breadcrumbs from 'Common/components/breadcrumbs/Breadcrumbs';
import notify from 'Common/utils/notify';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import colors from 'Application/theme/colors';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import getEntityById from 'Entities/api/getEntityById';
import deleteEntity from 'Entities/api/deleteEntity';
import Button from 'Common/components/buttons/Button';
import { updateSidePanelData } from 'Application/reducers/reduxSidePanel';
import TabsContainer from 'Common/components/tabs';

import GeneralTab from 'Entities/components/EntitiesDetails/Tabs/GeneralTab/GeneralTab';
import EntitiesProjectsTab from 'Entities/components/EntitiesDetails/Tabs/EntitiesProjectsTab/EntitiesProjectsTab';
import CapitalTab from 'Entities/components/EntitiesDetails/Tabs/CapitalTab/CapitalTab';
import BankAccountsTab from 'Entities/components/EntitiesDetails/Tabs/BankAccountsTab/BankAccountsTab';
import { ENTITY_DETAILS_PAGE_TILES_BY_TAB } from 'Entities/constants/entityTabForms';
import { SIDE_PANEL_CONTENT_TYPES } from 'Common/components/SidePanel/constants/sidePanelContentTypes';

const StyledBreadcrumbs = styled(Breadcrumbs)`
	flex: 1;
	flex-grow: 10;
	overflow: hidden;
	line-height: 1.8em;
`;

const ContentWrapper = styled.div`
	background: ${colors.grey.lightest};
`;

const StyledHeaderTitle = styled(HeaderTitle)`
	line-height: 34px;
	text-transform: capitalize;
`;

const EntitiesDetails = () => {
	const [entity, setEntity] = useState({});
	const [isLoadingEntity, setIsLoadingEntity] = useState(true);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [deletionError, setDeletionError] = useState(undefined);
	const sidePanelData = useSelector(state => state.sidePanel);
	const isOpenSidepanel = sidePanelData.isOpen && sidePanelData.type === 'entityComments';
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const { entityId } = useParams();

	const abortController = useAbortController();

	useEffect(() => {
		setIsLoadingEntity(true);

		(async () => {
			try {
				const response = await getEntityById(abortController.signal, entityId, true);

				setEntity(response.data);
				setIsLoadingEntity(false);
			} catch (e) {
				showToastError(e);

				if (!axios.isCancel(e)) {
					setEntity(undefined);
					setIsLoadingEntity(false);
				}
			}
		})();
	}, [abortController.signal, entityId]);

	const handleEntityDataChange = useCallback(
		async changes => {
			if (changes) {
				setEntity(prevEntity => ({
					...prevEntity,
					...changes,
				}));
				return changes;
			}

			try {
				const response = await getEntityById(abortController.signal, entityId, true);

				setEntity(response.data);
				return response.data;
			} catch (e) {
				showToastError(e);

				if (!axios.isCancel(e)) {
					setEntity(undefined);
					setIsLoadingEntity(false);
				}
			}
		},
		[abortController.signal, entityId],
	);

	const handleDeleteEntitySubmit = async () => {
		try {
			await deleteEntity(abortController.signal, entityId);
			notify(t('Entity deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});
			dispatch(push('/entities'));

			setShowConfirmModal(false);
		} catch (error) {
			if (!axios.isCancel(error)) {
				setDeletionError(error.response.data);
				setShowConfirmModal(false);
			}
		}
	};

	const handleCommentBtnClick = async () => {
		try {
			if (!isOpenSidepanel) {
				dispatch(
					updateSidePanelData({
						isOpen: true,
						type: SIDE_PANEL_CONTENT_TYPES.ENTITY_COMMENTS,
						isLoading: true,
					}),
				);

				const entityData = await getEntityById(abortController.signal, entityId, true).then(res => res.data);

				dispatch(
					updateSidePanelData({
						isOpen: true,
						isLoading: false,
						contentData: { entity: entityData, showHistory: false, handleChange: handleEntityDataChange },
						headerData: {
							to: '/entities',
							isBreadcrumb: true,
							breadCrumb: 'Entities',
							activeBreadcrumb: entityData.name,
							toActiveBreadcrumb: `/entities/details/${entityData.id}`,
						},
					}),
				);
			}
		} catch (err) {
			showToastError(err);
		}
	};
	const tabsConfig = useMemo(
		() =>
			[
				{
					label: 'General',
					PanelComponent: GeneralTab,
					tabForms: ENTITY_DETAILS_PAGE_TILES_BY_TAB.GENERAL,
				},
				{
					label: 'Capital',
					PanelComponent: CapitalTab,
					tabForms: ENTITY_DETAILS_PAGE_TILES_BY_TAB.CAPITAL,
				},
				{
					label: 'Bank Accounts',
					PanelComponent: BankAccountsTab,
				},
				{
					label: 'Projects',
					PanelComponent: EntitiesProjectsTab,
				},
			].map(({ ...tabsConfig }) => ({
				...tabsConfig,
				panelProps: { ...tabsConfig.panelProps, entity, onDataChange: handleEntityDataChange },
			})),
		[entity, handleEntityDataChange],
	);

	return (
		<div>
			{isLoadingEntity || entity === null ? (
				<LoaderWrapper $isFullPage>
					<Loader />
				</LoaderWrapper>
			) : (
				<>
					<HeaderContainer>
						<StyledBreadcrumbs>
							<Breadcrumb to="/entities">{t('Entities')}</Breadcrumb>
							<Breadcrumb active>
								<StyledHeaderTitle>{entity?.name}</StyledHeaderTitle>
							</Breadcrumb>
						</StyledBreadcrumbs>
						<HeaderActions>
							<Button medium onClick={handleCommentBtnClick} icon="forum" label="Entity Details - Comments Button" />
							<MoreActionsButton label="Entity Details More Actions">
								<MenuItem
									onClick={() => setShowConfirmModal(true)}
									label="Ecoligo  Entity Details - Delete Menu Item"
								>
									{t('Delete')}
								</MenuItem>
							</MoreActionsButton>
						</HeaderActions>
					</HeaderContainer>
					<ContentWrapper>
						<TabsContainer page="entities" tabsConfig={tabsConfig} />
						<ConfirmModal
							isOpen={showConfirmModal}
							onConfirm={() => handleDeleteEntitySubmit()}
							onCancel={() => setShowConfirmModal(false)}
							label="delete-entitity"
							heading={t('Delete Entity')}
							text={t(`Are you sure you want to delete Entity ${entity.name}?`)}
						/>
						<UnsuccessfulOperationModal
							label="cannot-delete-entitity"
							isOpen={Boolean(deletionError)}
							onCancel={() => setDeletionError(null)}
							heading={t('Cannot delete Entity')}
							text={deletionError?.violations?.[0]?.message}
						/>
					</ContentWrapper>
				</>
			)}
		</div>
	);
};

export default EntitiesDetails;
