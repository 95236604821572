import { useState, useMemo, useEffect, useCallback } from 'react';
import useAbortController from 'Common/hooks/useAbortController';
import showToastError from 'Common/utils/showToastError';
import getCountryById from 'Country/api/getCountryById';
import PropTypes from 'prop-types';
import Overlay, { useOverlayTitles } from 'Common/components/modals/Overlay';
import { useTranslation } from 'react-i18next';
import crudModes from 'Common/constants/crudModes';
import CountryForm from '../Form/CountryForm';
import SectionContainer from 'Common/components/modals/SectionContainer';

const CountryOverlay = ({ countryId, isOpen, onFormSubmit, onClose, mode, countryName }) => {
	const { t } = useTranslation();
	const { name, label, saveBtnLabel } = useOverlayTitles(countryName || t('Country'), mode);

	const [countryData, setCountryData] = useState(null);

	const abortController = useAbortController();

	const fetchCountryData = useCallback(() => {
		(async () => {
			try {
				const { data: country } = await getCountryById(abortController.signal, countryId);
				setCountryData(country);
			} catch (e) {
				showToastError(e, t("Can't fetch country data"));
			}
		})();
	}, [abortController.signal, t, countryId]);

	useEffect(() => {
		if (mode === crudModes.EDIT && isOpen && countryId) {
			fetchCountryData();
		}
	}, [fetchCountryData, isOpen, mode, countryId]);

	const isDataLoading = mode === crudModes.EDIT && countryData === null;

	const breadcrumbList = useMemo(
		() => [
			{
				label: 'Countries',
				href: '/preferences',
			},
			{
				label: name,
			},
		],
		[name],
	);

	return (
		<Overlay
			label={label}
			isOpen={isOpen}
			onClose={onClose}
			onSave={onFormSubmit}
			breadcrumbList={breadcrumbList}
			isLoadingContent={isDataLoading}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<SectionContainer name={countryName || t('Country')}>
					<CountryForm
						mode={mode}
						countryId={countryId}
						data={countryData}
						onSubmit={handleSubmit}
						handleUpdate={fetchCountryData}
						onCancel={onCancel}
						onDirty={setIsFormDirty}
						saveBtnLabel={saveBtnLabel}
					/>
				</SectionContainer>
			)}
		</Overlay>
	);
};

CountryOverlay.defaultProps = {
	countryName: '',
};

CountryOverlay.propTypes = {
	countryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
	isOpen: PropTypes.bool.isRequired,
	countryName: PropTypes.string,
};

export default CountryOverlay;
