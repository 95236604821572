import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import IndustriesReassignClientForm from './IndustriesReassignClientForm.js/IndustriesReassignClientForm';
import { PropTypes } from 'prop-types';

const IndustriesReassignClientModal = ({ isOpen, onClose, onFormSubmit, errorMessage, industryId }) => {
	const { t } = useTranslation();

	return (
		<ModalForm
			label="industry-reassign-client"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			heading={t('Industry in use')}
			helpText={errorMessage}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<IndustriesReassignClientForm
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
					industryId={industryId}
				/>
			)}
		</ModalForm>
	);
};

IndustriesReassignClientModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	errorMessage: PropTypes.string.isRequired,
	industryId: PropTypes.string.isRequired,
};

export default IndustriesReassignClientModal;
