/* eslint-disable no-unused-vars */
import { useMemo } from 'react';

import { DefaultTable } from 'Common/components/table/table';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { parseTableData } from 'Impact/utils/parseStatisticsData';
import { useTranslation } from 'react-i18next';
import TableCellText from 'Common/components/table/TableCellText';
import { PropTypes } from 'prop-types';

import styled from 'styled-components/macro';

const Wrapper = styled.div`
	padding: 0px 16px;
	width: 100%;
	height: 230px;
	overflow: hidden;
	&:hover {
		overflow: scroll;
		overflow-x: hidden;
		overflow-y: overlay;
	}
`;

const StyledTable = styled(DefaultTable)`
	div:nth-child(3) {
		overflow-x: hidden !important;
	}
`;

const ImpactInvestorsTable = ({ data }) => {
	const { t } = useTranslation();

	const columns = useMemo(
		() => [
			{
				Header: t('Year'),
				accessor: 'year',
				Cell: (() => {
					const Cell = ({ row: { original: year } }) => (
						<div>
							<TableCellText>{year.year}</TableCellText>
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ year: PropTypes.number }) }).isRequired,
					};

					return Cell;
				})(),
			},
			{
				Header: t('€ Invested'),
				accessor: 'industry',
				Cell: (() => {
					const Cell = ({ row: { original: year } }) => (
						<div>
							<TableCellText>{year.invested}</TableCellText>
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ invested: PropTypes.string }) }).isRequired,
					};

					return Cell;
				})(),
			},
			{
				Header: t('Growth %'),
				accessor: 'country',
				Cell: (() => {
					const Cell = ({ row: { original: year } }) => (
						<div>
							<TableCellText>{year.growth}</TableCellText>
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ growth: PropTypes.string }) }).isRequired,
					};

					return Cell;
				})(),
			},
		],
		[t],
	);

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		getTableFooterProps,
		headerGroups,
		rows,
		prepareRow,
	} = useStandardTable({
		data: parseTableData(data),
		columns,
	});

	return (
		<Wrapper>
			<StyledTable
				getTableProps={getTableProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				getTableFooterProps={getTableFooterProps}
				rows={rows}
				headerGroups={headerGroups}
				prepareRow={prepareRow}
				showNoResultsFound
			/>
		</Wrapper>
	);
};

ImpactInvestorsTable.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ImpactInvestorsTable;
