import Icon from 'Common/components/icons/Icon';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import PropTypes from 'prop-types';

const _CollapseButton = styled(Icon).attrs({ padding: 2, color: colors.primary.dark, size: 'large' })`
	cursor: pointer;
	background-color: ${colors.primary.light};
	border-radius: 50%;
	width: 28px !important;
	height: 28px !important;
`;

const CollapseButton = ({ isCollapsed, onClick, ...props }) => (
	<_CollapseButton
		{...props}
		icon={isCollapsed ? 'keyboardArrowDown' : 'keyboardArrowUp'}
		name={isCollapsed ? 'chevron-down' : 'chevron-up'}
		onClick={onClick}
	/>
);

CollapseButton.defaultProps = {
	isCollapsed: false,
	onClick: () => {},
};
CollapseButton.propTypes = {
	isCollapsed: PropTypes.bool,
	onClick: PropTypes.func,
};

export default CollapseButton;
