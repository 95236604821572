import colors from 'Application/theme/colors';

export const types = {
	ESTIMATE: 'ESTIMATE',
	PROPOSAL: 'PROPOSAL',
	INQUIRY: 'INQUIRY',
	RFEP: 'RFEP',
};

export const typeColors = {
	[types.ESTIMATE]: colors.primary.dark,
	[types.INQUIRY]: colors.secondary.dark,
	[types.PROPOSAL]: colors.secondary.main,
	[types.RFEP]: colors.secondary.main,
};

export const typesSortOrder = ['ESTIMATE', 'RFEP', 'PROPOSAL', 'INQUIRY'];
