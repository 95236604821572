import { useState, useEffect } from 'react';

// const isIncluded = (value, searchValue) => {
// 	let lastMatchingIndex;
// 	for (let i = 0; i < searchValue.length; i++) {
// 		const matchingIndex = value.indexOf(searchValue[i]);
// 		if (matchingIndex === -1) {
// 			return false;
// 		} else if (matchingIndex <= lastMatchingIndex) {
// 			const reMatchingIndex = value.substring(lastMatchingIndex + 1).indexOf(searchValue[i]);
// 			if (reMatchingIndex === -1) {
// 				return false;
// 			} else {
// 				lastMatchingIndex = reMatchingIndex + lastMatchingIndex + 1;
// 			}
// 		} else {
// 			lastMatchingIndex = matchingIndex;
// 		}
// 	}
// 	return true;
// };

const replaceChars = ['|', '*', '^', '$'];

const transformFromSearchedText = (searchValue, { label, ...rest }) => {
	const originalLabel = Array.isArray(label) ? label.join('') : label.split('');
	const replaceChar = replaceChars.find(char => !originalLabel.includes(char));
	const transformedLabel = label
		.toLowerCase()
		.replace(new RegExp(searchValue, ''), replaceChar.repeat(searchValue.length));

	const newLabel = transformedLabel.split('').map((char, index) => ({
		value: originalLabel[index] === ' ' ? '\u00A0' : originalLabel[index],
		colored: char === '|',
	}));

	return { label: newLabel, ...rest };
};

const useSearchOptions = (options, filterOptions) => {
	const [searchValue, setSearchValue] = useState('');
	const [filteredOptions, setFilteredOptions] = useState(options);

	useEffect(() => {
		if (searchValue.length > 0) {
			const searchValueLower = searchValue.toLowerCase();
			const transformFromSearchedTextBinded = transformFromSearchedText.bind(undefined, searchValueLower);

			const filteredOptions = filterOptions
				? options.filter(option => option.label.toLowerCase().includes(searchValueLower))
				: options;
			setFilteredOptions(filteredOptions.map(transformFromSearchedTextBinded));
		} else {
			setFilteredOptions(options);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue, options, setFilteredOptions]);

	return { searchValue, setSearchValue, filteredOptions };
};

export default useSearchOptions;
