import Grid from '@mui/material/Grid';
import colors from 'Application/theme/colors';
import styled from 'styled-components/macro';
import { css } from 'styled-components';

const BasePill = styled(Grid)`
	padding: 0px 16px 0px 23px;
	border-radius: 20px;
	min-height: 44px;
	max-height: 44px;
	margin-bottom: 2px !important;
	align-items: center;
	display: flex;
	border: solid 1.5px transparent;
	cursor: initial !important;
	z-index: 1;

	&:hover {
		z-index: 2;
	}

	&.pill-exit-active {
		opacity: 0;
		transform: translate3d(100%, 0, 0);
		transition: all 300ms;
	}

	${({ $isMarked }) =>
		$isMarked &&
		css`
			border-color: ${colors.primary.dark};
		`};

	&.hasMarker {
		border-color: ${colors.primary.main};
	}
`;

export default BasePill;
