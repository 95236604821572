import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import Link from 'Common/components/Link';
import Icon from 'Common/components/icons/Icon';
import { useFormik } from 'formik';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import Button from 'Common/components/buttons/Button';
import useLoginFormInitialValues from 'User/components/Login/LoginForm/hooks/useLoginFormInitialValues';
import useLoginFormValidationSchema from 'User/components/Login/LoginForm/hooks/useLoginFormValidationSchema';
import login from 'User/services/login';
import colors from 'Application/theme/colors';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';

const ButtonWrapper = styled.div`
	display: flex;
	height: 100px;
	align-items: flex-end;
	justify-content: flex-end;
`;

const StyledFormField = styled(FormField)`
	position: relative;
	margin-bottom: 32px;
`;

const IconContainer = styled.div`
	position: absolute;
	right: 5px;
	bottom: 5px;
`;

const LoginForm = () => {
	const { t } = useTranslation();
	const [showPassword, setShowPassword] = useState(false);

	const abortController = useAbortController();

	const initialValues = useLoginFormInitialValues({});
	const validationSchema = useLoginFormValidationSchema();

	const { errors, touched, values, handleSubmit, handleChange, handleBlur } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				await login(abortController.signal, values.email, values.password, true);
			} catch (error) {
				if (error?.response?.status === 400) {
					showToastError(error, t('Invalid e-mail or password'));
				} else {
					showToastError(error);
				}
			}
		},
	});

	return (
		<form onSubmit={handleSubmit}>
			<FormField>
				<FormikInput
					id="email"
					name="email"
					label={t('E-mail')}
					value={values.email}
					error={errors.email}
					touched={touched.email}
					onChange={handleChange}
					onBlur={handleBlur}
					placeholder="E-mail"
				/>
			</FormField>
			<StyledFormField>
				<FormikInput
					id="password"
					name="password"
					inputType={showPassword ? 'text' : 'password'}
					label={t('Password')}
					value={values.password}
					error={errors.password}
					touched={touched.password}
					onChange={handleChange}
					onBlur={handleBlur}
					placeholder="Password"
				/>
				<IconContainer>
					<Icon
						icon="removeRedEyeOutlined"
						color={showPassword ? colors.primary.main : ''}
						onClick={() => setShowPassword(prev => !prev)}
					/>
				</IconContainer>
			</StyledFormField>
			<Link to="/forgotten-password">{t('Forgot Password?')}</Link>
			<ButtonWrapper>
				<Button type="submit" text={t('Log in')} icon="exitToAppRounded" label="Login Form - Log in Button" />
			</ButtonWrapper>
		</form>
	);
};

export default LoginForm;
