export const insuranceValidityStatuses = {
	NOT_INSURED_YET: 'NOT_INSURED_YET',
	ACTIVE: 'ACTIVE',
	EXPIRED: 'EXPIRED',
	NOT_NEED: 'NOT_NEED',
};

export const insuranceValidityStatusOptions = [
	{
		label: 'Not insured yet',
		value: insuranceValidityStatuses.NOT_INSURED_YET,
	},
	{
		label: 'Active',
		value: insuranceValidityStatuses.ACTIVE,
	},
	{
		label: 'Expired',
		value: insuranceValidityStatuses.EXPIRED,
	},
	{
		label: 'Not needed',
		value: insuranceValidityStatuses.NOT_NEED,
	},
];
