import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import FilterButton from 'Common/components/filter/FilterButton';
import AsyncSelectFilter from 'Common/components/filter/AsyncSelectFilter';
import InputFilter from 'Common/components/filter/InputFilter';
import MultiInputFilter from 'Common/components/filter/MultiInputFilter';
import SelectFilter from 'Common/components/filter/SelectFilter';
import RadioFilter from 'Common/components/filter/RadioFilter';
import NumberRangeFilter from 'Common/components/filter/NumberRangeFilter';
import DateRangeFilter from 'Common/components/filter/DateRangeFilter';
import StepsFilter from 'Common/components/filter/StepsFilter';
import Button from 'Common/components/buttons/Button';
import { css } from 'styled-components';
import StageFilter from './StageFilter';
import PropTypes from 'prop-types';
import useFiltersData from 'Common/components/filter/hooks/useFiltersData';
import { useSelector } from 'react-redux';

import { FILTER_TYPES } from 'Common/components/filter/constants/filterConstants';

const FiltersBtnsContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${sizes.spacing(1)};
`;

const FiltersWrapper = styled.div`
	background: ${colors.common.headerGrey};
	padding: ${({ hasActiveFilters, collapsed }) => (hasActiveFilters && !collapsed ? sizes.spacing(1) : 0)};
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: ${sizes.spacing(1)};
	transform-origin: top;
	transition: all 0.1s ease-in-out;
	max-height: 300px;

	${({ collapsed }) =>
		collapsed &&
		css`
			transform: scaleY(0);
			max-height: 0px;
		`}
`;

const FiltersContainer = ({ buttonRef, filtersData, table }) => {
	const tableData = useSelector(state => state.tables[table]);
	const filtersCollapsed = tableData?.filtersCollapsed ?? false;

	const {
		filtersList,
		filtersValues,
		onSelectFilter,
		setFilterValue,
		selectedFilters,
		onFilterCollapse,
		filledFiltersCount,
		resetFiltersValues,
	} = useFiltersData(filtersData, table);

	return (
		<>
			{buttonRef.current
				? ReactDOM.createPortal(
						<FiltersBtnsContainer>
							{filledFiltersCount > 0 && (
								<Button
									small
									secondary
									icon="close"
									onClick={() => resetFiltersValues(false)}
									label="Filters - Close Button"
								/>
							)}
							{selectedFilters.length > 0 && (
								<Button
									small
									secondary
									onClick={onFilterCollapse}
									icon={filtersCollapsed ? 'keyboardArrowDown' : 'keyboardArrowUp'}
									label={`Filters - ${filtersCollapsed ? 'Down' : 'Up'} Button`}
								/>
							)}
							<FilterButton
								filledFiltersCount={filledFiltersCount}
								filtersList={filtersList}
								onSelectFilter={onSelectFilter}
								onClearFilters={() => resetFiltersValues(true)}
							/>
						</FiltersBtnsContainer>,
						buttonRef.current,
				  )
				: ''}
			<FiltersWrapper
				collapsed={filtersCollapsed}
				hasActiveFilters={Boolean(filtersList.filter(e => e.selected).length)}
			>
				{filtersList.map(
					(filter, index) =>
						filter.selected && (
							// eslint-disable-next-line react/no-array-index-key
							<div key={`filter-${index}`}>
								{filter.type === FILTER_TYPES.STEPS && (
									<StepsFilter
										{...filter}
										values={filtersValues[filter.name]}
										setFilterValue={(...args) => setFilterValue(filter, ...args)}
									/>
								)}

								{filter.type === FILTER_TYPES.INPUT && (
									<InputFilter
										{...filter}
										value={filtersValues[filter.name]}
										setFilterValue={(...args) => setFilterValue(filter, ...args)}
										inputType={filter.validation}
									/>
								)}

								{filter.type === FILTER_TYPES.MULTI_INPUT && (
									<MultiInputFilter
										{...filter}
										value={filtersValues[filter.name]}
										setFilterValue={(...args) => setFilterValue(filter, ...args)}
										inputType={filter.validation}
									/>
								)}

								{filter.type === FILTER_TYPES.NUMBER && (
									<NumberRangeFilter
										{...filter}
										minValue={filtersValues[filter.minName]}
										maxValue={filtersValues[filter.maxName]}
										setFilterValue={(...args) => setFilterValue(filter, ...args)}
									/>
								)}
								{filter.type === FILTER_TYPES.SELECT && (
									<SelectFilter
										{...filter}
										values={filtersValues[filter.name]}
										setFilterValue={(...args) => setFilterValue(filter, ...args)}
									/>
								)}
								{filter.type === FILTER_TYPES.ASYNC_SELECT && (
									<AsyncSelectFilter
										{...filter}
										values={filtersValues[filter.name]}
										setFilterValue={(...args) => setFilterValue(filter, ...args)}
									/>
								)}
								{filter.type === FILTER_TYPES.DATE && (
									<DateRangeFilter
										{...filter}
										closePopup={() => filter.toggleOpen(filter.name, false)}
										startDateValue={filtersValues[filter.startDateName]}
										endDateValue={filtersValues[filter.endDateName]}
										setFilterValue={(...args) => setFilterValue(filter, ...args)}
									/>
								)}
								{filter.type === FILTER_TYPES.SWITCH && (
									<RadioFilter
										{...filter}
										value={filtersValues[filter.name]}
										options={filter.options}
										setFilterValue={(...args) => setFilterValue(filter, ...args)}
									/>
								)}
								{filter.type === FILTER_TYPES.STAGE && (
									<StageFilter
										{...filter}
										value={filtersValues[filter.stageStatus]}
										minValue={filtersValues[filter.minName]}
										maxValue={filtersValues[filter.maxName]}
										setFilterValue={(...args) => setFilterValue(filter, ...args)}
									/>
								)}
							</div>
						),
				)}
			</FiltersWrapper>
		</>
	);
};

FiltersContainer.defaultProps = {
	buttonRef: [],
	filtersData: [],
	table: '',
};

FiltersContainer.propTypes = {
	buttonRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
	filtersData: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			label: PropTypes.string,
			type: PropTypes.string,
			startDateName: PropTypes.string,
			endDateName: PropTypes.string,
			validation: PropTypes.string,
			selected: PropTypes.bool,
			filled: PropTypes.bool,
			handleSearch: PropTypes.func,
			minName: PropTypes.string,
			maxName: PropTypes.string,
			minProps: PropTypes.shape({
				icon: PropTypes.string,
				iconPosition: PropTypes.string,
			}),
			maxProps: PropTypes.shape({
				icon: PropTypes.string,
				iconPosition: PropTypes.string,
			}),
			minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			lowerBound: PropTypes.number,
			upperBound: PropTypes.number,
			loadOptions: PropTypes.func,
			options: PropTypes.arrayOf(
				PropTypes.shape({
					value: PropTypes.string,
					label: PropTypes.string,
				}),
			),
		}),
	),
	table: PropTypes.string,
};

export default FiltersContainer;
