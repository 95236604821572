import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import MarketSegmentsForm from 'Preferences/components/Tabs/GeneralTab/MarketSegments/MarketSegmentsForm/MarketSegmentsForm';
import crudModes from 'Common/constants/crudModes';
import { PropTypes } from 'prop-types';

const MarketSegmentsCreateModal = ({ isOpen, onClose, onFormSubmit }) => {
	const { t } = useTranslation();

	return (
		<ModalForm
			label="market-segment-create"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			heading={t('Create Market segment')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<MarketSegmentsForm
					mode={crudModes.CREATE}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

MarketSegmentsCreateModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default MarketSegmentsCreateModal;
