import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'Common/components/icons/Icon';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import Loader from 'Common/components/Loader';
import crudModes from 'Common/constants/crudModes';
import FormField from 'Common/components/form/FormField';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import editTag from 'Preferences/api/editTag';
import getTags from 'Preferences/api/getTags';
import createTag from 'Preferences/api/createTag';
import getUniqueLanguages from 'Preferences/api/getUniqueLanguages';
import loadLanguageOptions from 'Preferences/utils/loadLanguageOptions';
import useTagFormInitialValues from 'Preferences/components/Tabs/MarketingTab/Tags/TagForm/hooks/useTagFormInitialValues';
import useTagFormValidationSchema from 'Preferences/components/Tabs/MarketingTab/Tags/TagForm/hooks/useTagFormValidationSchema';

import colors from 'Application/theme/colors';

import { baseLanguages } from '../constants/baseLanguages';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { ModalFormButtons } from 'Common/components/form/ModalForm';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const FieldsWrapper = styled.div`
	width: 100%;
	padding-left: 10px;
`;

const LanguageFieldsWrapper = styled.div`
	display: flex;
	> :first-child {
		min-width: 130px;
	}
`;

const ExtendedFormField = styled(FormField)`
	min-width: calc(100% - 180px);
	margin-left: 16px;
`;

const SectionTitle = styled.div`
	font-size: 16px;
	font-weight: 600;
	margin: 30px 20px 20px 0;
	//padding: 0 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const StyledDeleteButton = styled(Icon)`
	cursor: pointer;
	margin-left: 5px;
	margin-top: 25px;
`;

const EmptySpace = styled.span`
	width: 32px;
	height: 27px;
`;

const TagForm = ({ data, mode, onSubmit, onCancel, onDirty }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [languages, setLanguages] = useState([]);
	const initialValues = useTagFormInitialValues(data);
	const validationSchema = useTagFormValidationSchema();
	// eslint-disable-next-line no-unused-vars
	const [hiddenCodes, setHiddenCodes] = useState(
		data?.localizations ? Object.keys(data?.localizations) : ['en', 'es', 'vi', 'de'],
	);

	const [mandatoryLanguages, setMandatoryLanguages] = useState(baseLanguages);
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const [isLoadingLanguages, setIsLoadingLanguages] = useState(false);

	const abortController = useAbortController();

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleChange,
		handleBlur,
		setFieldError,
		resetForm,
		setFieldValue,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				if (mode === crudModes.CREATE) {
					await createTag(abortController.signal, {
						...values,
						localizations: {
							...Object.fromEntries(
								values.localizations.map(e => [
									[e.language.value],
									{
										language: e.language.fullName,
										name: e.name,
									},
								]),
							),
						},
					});

					notify(t('Tag created successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				} else if (mode === crudModes.EDIT) {
					await editTag(abortController.signal, {
						...data,
						...values,
						localizations: {
							...Object.fromEntries(
								values.localizations.map(e => {
									if (!baseLanguages.includes(e.language.value) && e.name === '') {
										return [];
									}
									return [
										[e.language.value],
										{
											language: e.language.fullName,
											name: e.name,
										},
									];
								}),
							),
						},
					});

					notify(t('Tag saved successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				}

				const response = await getTags(abortController.signal);
				onSubmit(response.data);

				// Re-set all fields and mark them as not touched
				resetForm({ values });
				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	useEffect(() => {
		if (isInitialLoad) {
			setIsInitialLoad(false);
			setIsLoadingLanguages(true);
			(async () => {
				try {
					const langOptions = await loadLanguageOptions(abortController.signal);
					const unique = await getUniqueLanguages(abortController.signal);
					setMandatoryLanguages(Object.keys(unique.data));

					const newLocalization = Object.entries(unique.data).map(([langCode, { language: fullName }]) => ({
						language: {
							label: langCode,
							value: langCode,
							fullName,
						},
						name: data?.localizations
							? data?.localizations[langCode]
								? data?.localizations[langCode].name
								: ''
							: values?.localizations[langCode]
							? values?.localizations[langCode].name
							: '',
					}));

					setFieldValue(
						'localizations',
						newLocalization.sort((a, b) => a.language.value.localeCompare(b.language.value)),
					);
					resetForm({ values });
					const newHiddenCodes = Object.keys(unique.data);
					setHiddenCodes(newHiddenCodes);

					setLanguages(langOptions.filter(e => !newHiddenCodes.includes(e.value)));
					setIsLoadingLanguages(false);
				} catch (err) {
					showToastError(err);
				}
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInitialLoad, setFieldValue, values.localizations, abortController.signal]);

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	const handleLanguageAdd = () => {
		setHiddenCodes(prev => [...prev]);

		setFieldValue('localizations', [...values.localizations, { language: null, name: '' }]);
	};

	const handleSelectChange = (name, value) => {
		setFieldValue(name, value);
		setHiddenCodes(prev => [...prev, value.value]);
	};

	const handleLanguageRemove = index => {
		const currentSelected = [...values.localizations];
		setHiddenCodes(prev => {
			const current = [...prev];
			current.splice(index, 1);
			return current;
		});
		currentSelected.splice(index, 1);
		setFieldValue('localizations', currentSelected);
	};

	return (
		<Form onSubmit={handleSubmit}>
			<FieldsWrapper>
				<FormField>
					<FormikInput
						id="name"
						name="name"
						label={t('Name')}
						value={values.name}
						error={errors.name}
						touched={touched.name}
						onChange={handleChange}
						onBlur={handleBlur}
						isRequired
					/>
				</FormField>
				<FormField>
					<FormikInput
						id="imageId"
						name="imageId"
						label={t('Image ID')}
						value={values.imageId}
						error={errors.imageId}
						touched={touched.imageId}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
				</FormField>
				<SectionTitle>
					{t('Languages')}
					<TileActionButton onClick={handleLanguageAdd} />
				</SectionTitle>
				{isLoadingLanguages ? (
					<LoaderWrapper>
						<Loader />
					</LoaderWrapper>
				) : (
					values.localizations.map((e, i) => (
						<LanguageFieldsWrapper key={`language-${e?.language?.label}`}>
							<FormField>
								<FormikSelect
									id={`localizations[${i}].language`}
									name={`localizations[${i}].language`}
									label={t('Language')}
									value={e.language}
									error={errors?.localizations?.[i]?.language}
									touched={touched?.localizations?.[i]?.language}
									setFieldValue={setFieldValue}
									onBlur={handleBlur}
									options={languages}
									menuPosition="fixed"
									onChange={e => handleSelectChange(`localizations[${i}].language`, e)}
								/>
							</FormField>
							<ExtendedFormField>
								<FormikInput
									id={`localizations[${i}].name`}
									name={`localizations[${i}].name`}
									label={t('Translation')}
									value={e.name}
									error={errors?.localizations?.[i]?.name}
									touched={touched?.localizations?.[i]?.name}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
							</ExtendedFormField>
							{!mandatoryLanguages.includes(e?.language?.value) ? (
								<StyledDeleteButton
									icon="delete"
									color={colors.text.greyLight}
									onClick={() => handleLanguageRemove(i)}
								/>
							) : (
								<EmptySpace />
							)}
						</LanguageFieldsWrapper>
					))
				)}
			</FieldsWrapper>
			<ModalFormButtons
				onCancel={onCancel}
				isLoading={isLoading}
				label="Preferences Marketing Tag Form"
				submitButtonText={mode === crudModes.CREATE ? 'Create' : 'Save'}
			/>
		</Form>
	);
};

TagForm.defaultProps = {
	data: null,
};

TagForm.propTypes = {
	data: PropTypes.shape({
		localizations: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
	}),
	mode: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
};

export default TagForm;
