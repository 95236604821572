import ReactGA from 'react-ga';

const customGAEvent = (category, action, label = '') => {
	return ReactGA.event({
		category: category,
		action: action,
		label: label,
	});
};

export default customGAEvent;
