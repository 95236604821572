import { useState, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { omit, pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import Icon from 'Common/components/icons/Icon';
import styled from 'styled-components/macro';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

import formatDate from 'Common/utils/formatDate';
import removeTrailingZeros from 'Common/utils/removeTrailingZeros';
import formatNumber from 'Common/utils/formatNumber';
import EditableTile from 'Common/components/Tile/EditableTile';
import ProgressBar from 'Common/components/ProgressBar';
import FormField from 'Common/components/form/FormField';
import PopperTooltip from 'Common/components/tooltip/PopperTooltip';
import FormikInput from 'Common/components/form/FormikInput';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import MultiStepProgressBar from 'Common/components/MultiStepProgressBar';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';
import ChangeStatusButton from 'Campaigns/components/CampaignDetails/ChangeStatusButton';
import convertFormValuesToRequestData from './utils/convertFormValuesToRequestData';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import colors from 'Application/theme/colors';
import OnHoldIcon from 'Projects/components/OnHoldIcon';

import editCampaign from 'Campaigns/api/editCampaign';
import useOverviewInitialValues from 'Campaigns/components/CampaignDetails/Tabs/GeneralTab/Tiles/OverviewTile/hooks/useOverviewFormInitialValues';
import useOverviewFormValidationSchema from 'Campaigns/components/CampaignDetails/Tabs/GeneralTab/Tiles/OverviewTile/hooks/useOverviewValidationSchema';
import { newStatuses, newStatusesLabels, statusDescriptions } from 'Campaigns/constants/campaignStatuses';
import StatusPill from 'Common/components/StatusPill';
import formatCurrency from 'Common/utils/formatCurrency';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import TileDynamicSection from 'Common/components/Tile/TileDynamicSection';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import setCo2Savings from 'Campaigns/api/setCo2Savings';

const SectionTitle = styled.div`
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 10px;
	display: flex;
`;

const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	& label {
		padding-left: 5px !important;
	}
	.MuiCheckbox-root {
		margin-left: 0px !important;
	}
`;

const EmptyIconSpace = styled.div`
	width: 27px;
	height: 27px;
`;

const OnHoldIndicator = styled.div`
	display: flex;
	align-items: center;
	font-size: 16px;
	margin-left: 4px;
	svg {
		margin-right: 4px;
	}
`;

const TitleCheckbox = styled(FormikCheckbox)`
	.MuiCheckbox-root {
		margin-left: 0px !important;
		margin-right: 8px;
	}
`;

const StatusContainer = styled.div`
	display: flex;
	margin-top: 8px;
	margin-bottom: 8px;
`;

const AllocatedStatsContainer = styled.div`
	padding-top: 10px;
	padding-bottom: 15px;
`;

const AlllocatedStat = styled.div`
	&:before {
		content: '';
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		margin-right: 7px;
		background-color: ${props => props.color};
	}
`;

const ThinText = styled.span`
	font-size: 12px;
	line-height: 18px;
	color: ${colors.text.greyLightDark};
`;

const CO2EditableConatiner = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const WarningIcon = styled(Icon)`
	cursor: pointer;
`;

const TooltipText = styled.div`
	max-width: 240px;
`;

const RecalculateButton = styled(Icon)`
	cursor: pointer;
`;

const StyledP = styled.p`
	text-align: center;
`;

const formatedDate = date => (date ? formatDate(date) : '');

const OverviewTile = ({ campaign, onEnterEditMode, onExitEditMode, onStatusChange, onDirtyForm, onSave }) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.PM_FUNDRAISING,
		roles.ASSET_MARKETING,
		roles.HEAD_OF_ESG,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useOverviewInitialValues(campaign);
	const validationSchema = useOverviewFormValidationSchema();

	const campaignDuration = useMemo(() => (campaign?.duration ? `${campaign?.duration} days` : ''), [campaign]);

	const c0TonesValue = useMemo(
		() => (campaign?.finance_details?.co2Savings ? formatNumber(campaign?.finance_details?.co2Savings, 1) : 0),
		[campaign],
	);

	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const lifetimeCO2Savings = useMemo(
		() => (campaign?.finance_details?.lifetimeCO2 ? `${campaign?.finance_details?.lifetimeCO2} t` : ''),
		[campaign],
	);

	const showco0warn = useMemo(
		() =>
			Number(campaign?.finance_details?.co2Savings).toFixed(3) !==
			Number(campaign?.finance_details?.co2SavingsCalculated).toFixed(3),
		[campaign],
	);

	const showRecalculateModal = e => {
		e.stopPropagation();
		setShowConfirmModal(true);
	};

	const handleRecalculateSubmit = async () => {
		const {
			data: { finance_details },
		} = await setCo2Savings(abortController.signal, {
			co2Savings: campaign?.finance_details?.co2SavingsCalculated,
			financeId: campaign?.auto_id,
		});

		onSave({
			finance_details,
		});
		setShowConfirmModal(false);
	};

	const { errors, touched, values, handleSubmit, dirty, handleChange, handleBlur, resetForm, setFieldValue } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: async values => {
				setIsSavingChanges(true);
				try {
					const response = await editCampaign(abortController.signal, {
						...omit(campaign, ['finance_details', 'on_hold']),
						...pick(campaign?.finance_details, [
							'systemSize',
							'status',
							'frontendReviewPassed',
							'documentsAvailableForDownload',
							'vibApproved',
							'trancheSetupCorrectly',
							'projectPassedPortagonPlausibility',
						]),
						...convertFormValuesToRequestData(values),
						id: campaign.auto_id,
					});

					if (onDirtyForm) {
						onDirtyForm(false);
					}

					onSave({
						...response.data,
						status: response.data.status,
					});

					handleCancel();
					setIsSavingChanges(false);
					resetForm({ values });
				} catch (error) {
					showToastError(error);
					if (!axios.isCancel(error)) {
						handleCancel();
						setIsSavingChanges(false);
					}
				}
			},
		});

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit && !showConfirmModal) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	useEffect(() => {
		if (!values.externalFinance) {
			setFieldValue('externalVolume', '');
			setFieldValue('provider', '');
		}
	}, [setFieldValue, values.externalFinance]);

	const isExtFinanceDisabled =
		values.status === 'ACTIVE' || values.status === 'DRAFT' || values.status === 'READY_TO_LAUNCH';

	return (
		<EditableTile
			title={t('Campaign')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<FormField>
				<FormikInput
					id="name"
					name="name"
					label={t('Name')}
					value={values.name}
					error={errors.name}
					touched={touched.name}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isRequired={isInEditMode}
					tooltip={{
						tooltip: t('Use the same name in portagon to link the Campaign automatically.'),
					}}
					isInEditMode={isInEditMode}
				/>
			</FormField>

			<TileDynamicSection
				isInEditMode={isInEditMode}
				editView={
					<>
						<SectionTitle>{t('Finance volume')}</SectionTitle>
						<FormField>
							<FormikInput
								id="minimumVolume"
								name="minimumVolume"
								label={t('Min finance volume')}
								value={removeTrailingZeros(values.minimumVolume)}
								error={errors.minimumVolume}
								touched={touched.minimumVolume}
								onChange={handleChange}
								onBlur={handleBlur}
								isRequired
								isTile
								isInEditMode={isInEditMode}
							/>
						</FormField>
						<FormField>
							<FormikInput
								id="maximumVolume"
								name="maximumVolume"
								label={t('Max finance volume')}
								value={removeTrailingZeros(values.maximumVolume)}
								error={errors.maximumVolume}
								touched={touched.maximumVolume}
								onChange={handleChange}
								onBlur={handleBlur}
								isTile
								isInEditMode={isInEditMode}
							/>
						</FormField>
						<SectionTitle>
							<TitleCheckbox
								id="externalFinance"
								name="externalFinance"
								checked={values.externalFinance}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={touched.externalFinance}
								disabled={isExtFinanceDisabled}
							/>
							{t('External finance')}
							<QuestionTooltip
								tooltip={t('External finance can only be applied when Campaign is closed or funded.')}
							/>
						</SectionTitle>
						<FormField>
							<FormikInput
								id="externalVolume"
								name="externalVolume"
								label={t('External volume')}
								value={removeTrailingZeros(values.externalVolume)}
								error={errors.externalVolume}
								touched={touched.externalVolume}
								onChange={handleChange}
								onBlur={handleBlur}
								isDisabled={!values.externalFinance}
								isRequired={values.externalFinance}
								isTile
								isInEditMode={isInEditMode}
							/>
						</FormField>
						<FormField>
							<FormikInput
								id="provider"
								name="provider"
								label={t('Provider')}
								value={values.provider}
								error={errors.provider}
								touched={touched.provider}
								onChange={handleChange}
								onBlur={handleBlur}
								isDisabled={!values.externalFinance}
								isRequired={values.externalFinance}
								isTile
								isInEditMode={isInEditMode}
							/>
						</FormField>
						<FormField>
							<CheckboxContainer>
								<FormikCheckbox
									label={t('On hold')}
									id="onHold"
									name="onHold"
									checked={values.onHold}
									onChange={handleChange}
									onBlur={handleBlur}
									touched={touched.onHold}
									disabled={values.notRealised}
								/>
								{values.onHold ? <OnHoldIcon /> : <EmptyIconSpace />}
								<QuestionTooltip
									tooltip={t('Do not list on the ecoligo website, but the Campaign URL is accessible')}
								/>
							</CheckboxContainer>
						</FormField>
						<FormField>
							<CheckboxContainer>
								<FormikCheckbox
									label={t('Not realised')}
									id="notRealised"
									name="notRealised"
									checked={values.notRealised}
									onChange={handleChange}
									onBlur={handleBlur}
									touched={touched.notRealised}
									disabled={values.onHold}
								/>
								<QuestionTooltip
									tooltip={t(
										'When ticked the Campaign will show in the ‘Not realised’ section of the website.  This status overrides all others.',
									)}
								/>
							</CheckboxContainer>
						</FormField>
					</>
				}
				readonlyView={
					<>
						<Grid container alignItems="stretch" spacing={2} style={{ marginBottom: 10 }}>
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<KeyValueVisualization
									id="kwp"
									title={t('KWP')}
									value={formatNumber(campaign?.finance_details?.totalCampaignKwp || 0, 2)}
									hasHoverEffect
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<CO2EditableConatiner>
									<KeyValueVisualization id="co2Tonnes" title={t('CO2 tonnes')} value={c0TonesValue} hasHoverEffect />
									{showco0warn && (
										<>
											<PopperTooltip
												placement="bottom"
												tooltip={
													<TooltipText>
														{t(
															`Pulse has calculated a new CO2 value of ${formatNumber(
																campaign?.finance_details?.co2SavingsCalculated,
																2,
															)} tonnes. The Project may have changed. Press Refresh to update.`,
														)}
													</TooltipText>
												}
											>
												<WarningIcon icon="reportProblem" color={colors.warning.main} />
											</PopperTooltip>
											<RecalculateButton icon="sync" onClick={showRecalculateModal} />
										</>
									)}
								</CO2EditableConatiner>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{values.externalFinance ? (
									<>
										<MultiStepProgressBar
											steps={[
												{
													value: campaign?.investments_sum,
													color: colors.primary.dark,
												},
												{
													value: values.externalVolume,
													color: colors.common.orange,
												},
											]}
											stepText={
												campaign?.investments_sum
													? formatCurrency(parseInt(campaign?.investments_sum) + parseInt(values.externalVolume))
													: '€ 0'
											}
											totalText={campaign?.minimum_volume ? formatCurrency(campaign?.maximum_volume) : '€ 0'}
											totalSteps={Number(campaign?.maximum_volume)}
											isStacked
											warning={
												parseInt(campaign?.investments_sum) + parseInt(values.externalVolume) >
												parseInt(campaign?.maximum_volume)
											}
											warningText="Investments sum is greater than the Maximum finance volume.  Check the External finance volume or Max finance volume is correct."
										/>
										<AllocatedStatsContainer>
											<AlllocatedStat color={colors.primary.dark}>
												<ThinText>
													{campaign?.investments_sum ? formatCurrency(campaign?.investments_sum) : 0}
													{t('portagon')}
												</ThinText>
											</AlllocatedStat>
											<AlllocatedStat color={colors.common.orange}>
												<ThinText>
													{values.externalVolume ? formatCurrency(values.externalVolume) : 0}
													{' ' + values.provider}
												</ThinText>
											</AlllocatedStat>
										</AllocatedStatsContainer>
									</>
								) : (
									<ProgressBar
										step={campaign?.investments_sum ? Number(campaign?.investments_sum) : 0}
										stepText={campaign?.investments_sum ? formatCurrency(campaign?.investments_sum) : '€ 0'}
										totalText={campaign?.minimum_volume ? formatCurrency(campaign?.maximum_volume) : '€ 0'}
										totalSteps={Number(campaign?.maximum_volume)}
									/>
								)}
							</Grid>
							<Grid item xs={12}>
								<KeyValueVisualization
									id="fundingStartDate"
									title={t('Campaign start date')}
									value={formatedDate(campaign?.funding_start_date)}
									hasHoverEffect
								/>
							</Grid>
							<Grid item xs={12}>
								<KeyValueVisualization
									id="lastInvestmentDate"
									title={t('Last investment date')}
									value={formatedDate(campaign?.last_investment_date)}
									hasHoverEffect
								/>
							</Grid>
							<Grid item xs={12}>
								<KeyValueVisualization
									id="fundingEndDate"
									title={t('Campaign end date')}
									value={formatedDate(campaign?.funding_end_date)}
									hasHoverEffect
								/>
							</Grid>
							<Grid item xs={12}>
								<KeyValueVisualization id="fundedIn" title={t('Funded in')} value={campaignDuration} hasHoverEffect />
							</Grid>
							<Grid item xs={12}>
								<KeyValueVisualization
									id="lifetimeCO2Savings"
									title={t('Lifetime CO2 of campaign')}
									value={lifetimeCO2Savings}
									hasHoverEffect
								/>
							</Grid>
							<Grid item xs={12}>
								<KeyValueVisualization
									id="initialCampaignClosingDate"
									title={t('Initial campaign closing date')}
									value={formatedDate(campaign?.finance_details?.initialCampaignClosingDate)}
									hasHoverEffect
								/>
							</Grid>
							<Grid item xs={12}>
								<KeyValueVisualization
									id="finalCampaignClosingDate"
									title={t('Final campaign closing date')}
									value={formatedDate(campaign?.finance_details?.finalCampaignClosingDate)}
									hasHoverEffect
								/>
							</Grid>
						</Grid>
						<ChangeStatusButton campaign={campaign} onChange={onStatusChange} />
						{campaign.on_hold && (
							<OnHoldIndicator>
								<OnHoldIcon />
								{t('On hold')}
							</OnHoldIndicator>
						)}
						<StatusContainer>
							{campaign.status === newStatuses.NOT_REALISED && (
								<HoverTooltip placement="right" title={statusDescriptions[campaign.status] || ''} arrow>
									<StatusPill status={campaign?.status} color={colors.common.brown}>
										{newStatusesLabels?.[campaign?.status]}
									</StatusPill>
								</HoverTooltip>
							)}
						</StatusContainer>
						<ConfirmModal
							isOpen={showConfirmModal}
							onConfirm={handleRecalculateSubmit}
							onCancel={() => setShowConfirmModal(false)}
						>
							<StyledP>{t('Recalculate CO2 tones value ?')}</StyledP>
						</ConfirmModal>
					</>
				}
				uniqueId="overview"
			/>
		</EditableTile>
	);
};

OverviewTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
};

OverviewTile.propTypes = {
	campaign: PropTypes.shape({
		duration: PropTypes.number,
		finance_details: PropTypes.shape({
			co2Savings: PropTypes.number,
			co2SavingsCalculated: PropTypes.number,
			lifetimeCO2: PropTypes.number,
			systemSize: PropTypes.number,
			initialCampaignClosingDate: PropTypes.string,
			finalCampaignClosingDate: PropTypes.string,
			totalCampaignKwp: PropTypes.number,
		}),
		auto_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		investments_sum: PropTypes.string,
		maximum_volume: PropTypes.string,
		minimum_volume: PropTypes.string,
		funding_end_date: PropTypes.string,
		funding_start_date: PropTypes.string,
		last_investment_date: PropTypes.string,
		initial_campaign_closing_date: PropTypes.bool,
		final_campaign_closing_date: PropTypes.bool,
		on_hold: PropTypes.bool,
		status: PropTypes.string,
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onSave: PropTypes.func.isRequired,
	onStatusChange: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
};

export default OverviewTile;
