import { PropTypes } from 'prop-types';
import styled from 'styled-components/macro';

import colors from 'Application/theme/colors';

import formatDate from 'Common/utils/formatDate';
import UsersPreview from 'Common/components/usersPreview/UsersPreview';

const Wrapper = styled.div`
	margin: 16px 8px;
	padding: 8px 48px 16px 8px;
	background: ${props => (props.color ? props.color : colors.common.beige)};
	border-radius: 10px;
`;

const Title = styled.div`
	font-size: 20px;
	font-weight: 700;
	line-height: 30px;
	margin-bottom: 16px;
`;

const Content = styled.div`
	display: flex;
	align-items: flex-start;
`;

const MessageWrapper = styled.div`
	margin-left: 16px;
	flex: 1;
`;

const MessageMetadata = styled.div``;

const Name = styled.span`
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	color: ${colors.text.greyDark};
`;

const Date = styled.span`
	color: ${colors.text.greyDark};
	margin-left: 16px;
	font-size: 14px;
	line-height: 21px;
`;

const Message = styled.div`
	font-size: 14px;
	line-height: 21px;
	white-space: pre-line;
	word-break: break-word;
	color: ${colors.text.black};
`;

const SecondMessage = styled(Message)`
	margin-top: 16px;
`;

const YellowMessage = ({ title, user, initialsColor, message, date, secondMessage, color }) => (
	<Wrapper color={color}>
		<Title>{title}</Title>
		<Content>
			<UsersPreview
				users={{
					user: {
						...user,
						color: initialsColor,
					},
				}}
				popupProps={{
					$alignment: 'left',
				}}
			/>
			<MessageWrapper>
				<MessageMetadata>
					<Name>{`${user?.firstName} ${user?.lastName}`}</Name>
					<Date>{formatDate(date)}</Date>
				</MessageMetadata>
				<Message>{message}</Message>
				{secondMessage && <SecondMessage>{secondMessage}</SecondMessage>}
			</MessageWrapper>
		</Content>
	</Wrapper>
);

YellowMessage.defaultProps = {
	initialsColor: undefined,
};

YellowMessage.propTypes = {
	title: PropTypes.string.isRequired,
	firstName: PropTypes.string.isRequired,
	lastName: PropTypes.string.isRequired,
	initialsColor: PropTypes.string,
	message: PropTypes.string.isRequired,
	date: PropTypes.shape({}).isRequired,
	secondMessage: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
	user: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
	}).isRequired,
};

export default YellowMessage;
