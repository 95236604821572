import api from 'Application/api/api';

const getStepById = (signal, id) => {
	const params = {
		details: true,
	};

	return api.get(`/steps/${id}`, {
		params,
		signal,
	});
};

export default getStepById;
