import api from 'Application/api/api';

const getInvoiceTemplates = (signal, countriesIds, fileName) => {
	const params = {
		countriesIds,
		fileName,
	};

	return api.get('/invoice-templates', {
		params,
		signal,
		timeout: 100000,
	});
};

export default getInvoiceTemplates;
