import React from 'react';
import styled from 'styled-components/macro';
import sizes from 'Application/theme/sizes';

const SkeletonContainer = styled.div`
	max-height: ${sizes.base(82)};
	width: ${sizes.base(75)};
	overflow-y: auto;
`;

const SkeletonElement = styled.div`
	height: ${sizes.base(6)};
	background-color: ${props => props.color};
	border-radius: 20px;
	position: relative;
	overflow: hidden;

	@keyframes load {
		from {
			left: -150px;
		}
		to {
			left: 100%;
		}
	}

	&:before {
		content: '';
		position: absolute;
		left: -150px;
		top: 0;
		height: 100%;
		width: 30px;
		background: linear-gradient(
			to right,
			transparent 0%,
			rgba(224, 224, 224, ${props => props.transparency}) 50%,
			transparent 100%
		);
		animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
		z-index: 1;
	}
`;

const SkeletonRow = styled.div`
	padding: ${sizes.spacing(1)} ${sizes.spacing(1.5)};
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 10px;

	${SkeletonElement}:nth-child(1) {
		width: 40%;
	}
	${SkeletonElement}:nth-child(2) {
		width: 15%;
	}
	${SkeletonElement}:nth-child(3) {
		width: 15%;
		margin-left: auto;
	}
`;

const Filterdropdownloader = () => {
	const loaders = [
		{
			color: 'rgba(233, 233, 233, 1)',
			transparency: '1',
		},
		{
			color: 'rgba(233, 233, 233, 0.8)',
			transparency: '0.8',
		},
		{
			color: 'rgba(233, 233, 233, 0.6)',
			transparency: '0.6',
		},
		{
			color: 'rgba(233, 233, 233, 0.4)',
			transparency: '0.4',
		},
		{
			color: 'rgba(233, 233, 233, 0.2)',
			transparency: '0.2',
		},
	];

	return (
		<SkeletonContainer>
			{loaders.map((loader, index) => (
				// eslint-disable-next-line react/no-array-index-key
				<SkeletonRow key={`filter-dropdown-loader-${index}`}>
					<SkeletonElement color={loader.color} transparency={loader.transparency} />
					<SkeletonElement color={loader.color} transparency={loader.transparency} />
					<SkeletonElement color={loader.color} transparency={loader.transparency} />
				</SkeletonRow>
			))}
		</SkeletonContainer>
	);
};

export default Filterdropdownloader;
