import Grid from '@mui/material/Grid';
import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';

import EpcPartnerTile from 'Projects/components/ProjectDetails/Tabs/EPCTab/Tiles/EpcPartnerTile/EpcPartnerTile';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import { PROJECT_DETAILS_PAGE_TAB_TILES } from 'Projects/constants/projectTabForms';
import EpcPartnerDetailsTile from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/EpcPartnerDetailsTile/EpcPartnerDetailsTile';
import AddressTile from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/AddressTile/AddressTile';
import QualificationsTile from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/QualificationsTile/QualificationsTile';
import AccountManagersTile from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/AccountManagersTile/AccountManagersTile';
import ContactsTile from 'EpcPartner/components/EpcPartnerDetails/Tabs/ContactsTab/Tiles/ContactsTile/ContactsTile';
import EpcContractTile from './Tiles/EPCContractTile';
import { useCallback } from 'react';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const EPCTab = ({ project, onEnterEditMode, onExitEditMode, onDirtyForm, handleUpdateProject }) => {
	const handleEpcPartnerSubmit = project => {
		handleUpdateProject({ epcPartner: project });
		onExitEditMode();
	};

	const handleEpcPartnerDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.EPC.EPC_PARTNER, isDirty);
		},
		[onDirtyForm],
	);

	const handleEpcPartnerDetailsDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.EPC.DETAILS, isDirty);
		},
		[onDirtyForm],
	);

	const handleAddressDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.EPC.ADDRESS, isDirty);
		},
		[onDirtyForm],
	);

	const handleQualificationsDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.EPC.QUALIFICATIONS, isDirty);
		},
		[onDirtyForm],
	);

	const handleAccountManagersDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.EPC.MANAGERS, isDirty);
		},
		[onDirtyForm],
	);

	return (
		<Wrapper>
			<TilesGridWrapper>
				<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
					<EpcPartnerTile
						project={project}
						onEnterEditMode={onEnterEditMode}
						onExitEditMode={onExitEditMode}
						onSave={handleEpcPartnerSubmit}
						onDirtyForm={handleEpcPartnerDirtyForm}
						onTileSave={handleUpdateProject}
					/>
				</Grid>
				{project?.epcPartner?.id && (
					<>
						<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
							<EpcPartnerDetailsTile
								epcPartner={project?.epcPartner}
								onEnterEditMode={onEnterEditMode}
								onExitEditMode={onExitEditMode}
								onSave={() => handleUpdateProject()}
								onDirtyForm={handleEpcPartnerDetailsDirtyForm}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
							<AddressTile
								epcPartner={project?.epcPartner}
								onEnterEditMode={onEnterEditMode}
								onExitEditMode={onExitEditMode}
								onSave={() => handleUpdateProject()}
								onDirtyForm={handleAddressDirtyForm}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
							<QualificationsTile
								epcPartner={project?.epcPartner}
								onEnterEditMode={onEnterEditMode}
								onExitEditMode={onExitEditMode}
								onSave={() => handleUpdateProject()}
								onDirtyForm={handleQualificationsDirtyForm}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
							<AccountManagersTile
								epcPartner={project?.epcPartner}
								onEnterEditMode={onEnterEditMode}
								onExitEditMode={onExitEditMode}
								onSave={() => handleUpdateProject()}
								onDirtyForm={handleAccountManagersDirtyForm}
							/>
						</Grid>
					</>
				)}
			</TilesGridWrapper>
			{project?.epcPartner?.id && (
				<>
					<TilesGridWrapper>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<ContactsTile epcPartner={project?.epcPartner} />
						</Grid>
					</TilesGridWrapper>
				</>
			)}

			<TilesGridWrapper>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<EpcContractTile project={project} onFormSubmit={handleUpdateProject} />
				</Grid>
			</TilesGridWrapper>
		</Wrapper>
	);
};

EPCTab.defaultProps = {
	project: null,
	onEnterEditMode: () => {},
	onExitEditMode: () => {},
	onDirtyForm: () => {},
	handleUpdateEntity: () => {},
	handleUpdateProject: async () => {},
};

EPCTab.propTypes = {
	project: PropTypes.shape({
		epcContracts: PropTypes.arrayOf(PropTypes.shape({})),
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		epcOffers: PropTypes.arrayOf(PropTypes.shape({})),
		clientContracts: PropTypes.arrayOf(PropTypes.shape({})),
		epcPartner: PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	}).isRequired,
	onEnterEditMode: PropTypes.func.isRequired,
	onExitEditMode: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func.isRequired,
	handleUpdateProject: PropTypes.func.isRequired,
};

export default EPCTab;
