import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { parse as parseQueryString } from 'qs';
import queryParamsDecoder from 'Common/utils/queryParamsDecoder';
import { _FILTER_QUERY_KEYS_SELECTOR_BY_TYPE } from 'Common/components/filter/constants/filterConstants';
import sanitizeAndPullDirtyFromQuery from 'Common/components/filter/utils/sanitizeAndPullDirtyFromQuery';
import pullAllowedPropertiesFromFilterData from 'Common/components/filter/utils/pullAllowedPropertiesFromFilterData';
import convertLocalFilterDataToQuery from 'Common/components/filter/utils/convertLocalFilterDataToQuery';
import showToastError from 'Common/utils/showToastError';
import CustomDebounce from 'Common/utils/CustomDebounce';
import useSetQueryParameter from 'Common/hooks/useSetQueryParameter';
import { isEqual } from 'lodash';

const useQueryFilterData = filtersData => {
	const location = useLocation();
	const setFilters = useSetQueryParameter('filters');
	const prevDirtyPropertiesWarning = useRef([]);
	const query = useMemo(
		() =>
			parseQueryString(location?.search, {
				ignoreQueryPrefix: true,
				arrayLimit: 50,
				decoder: queryParamsDecoder,
			}),
		[location?.search],
	);
	const filtersFromQuery = useMemo(() => query?.filters ?? {}, [query]);

	const allowedProperties = useMemo(
		() => pullAllowedPropertiesFromFilterData(_FILTER_QUERY_KEYS_SELECTOR_BY_TYPE, filtersData),
		[filtersData],
	);

	const { dirtyProperties: dirtyQueryProperties, sanitizedQueryData } = useMemo(
		() => sanitizeAndPullDirtyFromQuery(allowedProperties, filtersFromQuery),
		[filtersFromQuery, allowedProperties],
	);

	const debouncerInstance = useMemo(() => new CustomDebounce(), []);

	const updateQuery = useCallback(
		newValues =>
			debouncerInstance.debounce(
				() => setFilters(convertLocalFilterDataToQuery(sanitizedQueryData, newValues, allowedProperties)),
				500,
			),
		[allowedProperties, debouncerInstance, sanitizedQueryData, setFilters],
	);

	const clearQuery = useCallback(() => {
		setFilters({});
	}, [setFilters]);

	useEffect(() => {
		const isSameDirtyProperties = isEqual(dirtyQueryProperties, prevDirtyPropertiesWarning.current);
		if (dirtyQueryProperties.length > 0 && !isSameDirtyProperties) {
			showToastError(
				{},
				`The following filters are not allowed: ${dirtyQueryProperties.join(
					', ',
				)}. They were removed from the URL.`,
			);

			prevDirtyPropertiesWarning.current = dirtyQueryProperties;
			updateQuery({});
		}
	}, [dirtyQueryProperties, updateQuery]);

	return { sanitizedQueryData, updateQuery, clearQuery };
};

export default useQueryFilterData;
