import { useMemo } from 'react';
import { pick } from 'lodash';

const fallbackValues = {
	name: '',
	website: '',
};

const useEpcPartnerDetailsFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['name', 'website']),
		}),
		[data],
	);

export default useEpcPartnerDetailsFormInitialValues;
