import { createSlice } from '@reduxjs/toolkit';

const initialCommonState = {
	isUnsavedChangesModalOpened: false,
	unsavedChangesCallback: undefined,
	isSidebarShrank: false,
	isListPage: false,
	openStepStatusDropdownId: false,
};

export const commonSlice = createSlice({
	name: 'common',
	initialState: {
		...initialCommonState,
	},
	reducers: {
		showUnsavedChangesModal: (state, { payload: { callback } }) => {
			state.isUnsavedChangesModalOpened = true;
			state.unsavedChangesCallback = callback;
		},
		hideUnsavedChangesModal: state => {
			state.isUnsavedChangesModalOpened = false;
			state.unsavedChangesCallback = undefined;
		},
		updateSidebarState: (state, { payload: { newSideBarShrink } = {} }) => {
			state.isSidebarShrank = newSideBarShrink !== undefined ? newSideBarShrink : !state.isSidebarShrank;
		},
		setIsListPage: (state, { payload: { isListPage } }) => {
			state.isListPage = isListPage;
		},
		updateOpenStepStatusDropdownId: (state, { payload: { id } }) => {
			state.openStepStatusDropdownId = state.openStepStatusDropdownId === id ? '' : id;
		},
	},
});

export const {
	showUnsavedChangesModal,
	hideUnsavedChangesModal,
	updateSidebarState,
	setIsListPage,
	updateOpenStepStatusDropdownId,
} = commonSlice.actions;

export default commonSlice.reducer;
