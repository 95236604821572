import { css } from 'styled-components';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

import Icon from 'Common/components/icons/Icon';
import PopperTooltip from 'Common/components/tooltip/PopperTooltip';

const TooltipWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-right: 30px;
	${({ large }) =>
		large
			? css`
					margin-left: 10px;
			  `
			: css`
					position: relative;
					margin-left: 7px;
			  `};

	button {
		outline: none !important;
	}

	svg {
		display: block !important;
		color: ${colors.primary.main};
		${({ large }) =>
			large
				? css``
				: css`
						font-size: 16px !important;
				  `};
	}
`;

const QuestionTooltip = ({ large, className, ...props }) => (
	<TooltipWrapper className={className} large={large}>
		<PopperTooltip {...props}>
			<Icon icon="helpOutline" />
		</PopperTooltip>
	</TooltipWrapper>
);

QuestionTooltip.defaultProps = {
	large: false,
	className: '',
};

QuestionTooltip.propTypes = {
	large: PropTypes.bool,
	className: PropTypes.string,
};

export default QuestionTooltip;
