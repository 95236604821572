import Grid from '@mui/material/Grid';

import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';

import EmissionSpvsList from './EmissionsSpv/EmissionSpvsList';
import PlatformsList from './Platforms/PlatformsList';
import SpvBalanceList from './SpvBalances/SpvBalanceList';
import SpvProfitList from './SpvProfits/SpvProfitList';

const CampaignsTab = () => (
	<TilesGridWrapper>
		<Grid data-form="platforms" item xs={12} sm={12} md={6} lg={5} xl={4}>
			<PlatformsList />
		</Grid>
		<Grid data-form="emissionSpvs" item xs={12} sm={12} md={6} lg={5} xl={8}>
			<EmissionSpvsList />
		</Grid>

		<Grid data-form="spvBalances" item xs={12} sm={12} md={6} lg={5} xl={4}>
			<SpvBalanceList />
		</Grid>
		<Grid data-form="spvProfits" item xs={12} sm={12} md={6} lg={5} xl={4}>
			<SpvProfitList />
		</Grid>
	</TilesGridWrapper>
);

export default CampaignsTab;
