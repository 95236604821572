import colors from 'Application/theme/colors';

const INVOICE_STATUSES_LABELS = {
	DRAFT: 'Draft',
	PENDING: 'Pending Payment',
	PAID: 'Paid',
	CANCELED: 'Canceled',

	OVERDUE: {
		ON_TIME: 'Paid/On time',
		OVERDUE_LESS_THAN_15_DAYS: 'Paid/< 15',
		OVERDUE_MORE_THAN_15_DAYS: 'Paid/> 15',
		OVERDUE_MORE_THAN_30_DAYS: 'Paid/> 30',
	},
};

const INVOICE_STATUSES = { DRAFT: 'DRAFT', PENDING: 'PENDING', PAID: 'PAID', CANCELED: 'CANCELED' };

const INVOICE_STATUS_COLORS = {
	DRAFT: colors.primary.main,
	PENDING: colors.error.light,
	PAID: colors.secondary.main,
	CANCELED: colors.text.highlightHover,

	OVERDUE: {
		ON_TIME: colors.secondary.main,
		OVERDUE_LESS_THAN_15_DAYS: colors.primary.dark,
		OVERDUE_MORE_THAN_15_DAYS: colors.primary.dark,
		OVERDUE_MORE_THAN_30_DAYS: colors.primary.dark,
	},
};

const INVOICE_STATUS_OPTIONS = [
	{ value: 'DRAFT', label: INVOICE_STATUSES_LABELS.DRAFT },
	{ value: 'PENDING', label: INVOICE_STATUSES_LABELS.PENDING },
	{ value: 'PAID', label: INVOICE_STATUSES_LABELS.PAID },
	{ value: 'CANCELED', label: INVOICE_STATUSES_LABELS.CANCELED },
];

const INVOICE_OVERDUE_STATUS_OPTIONS = [
	{ value: 'ON_TIME', label: 'On Time' },
	{ value: 'OVERDUE_LESS_THAN_15_DAYS', label: 'Less than 15 days' },
	{ value: 'OVERDUE_MORE_THAN_15_DAYS', label: 'More than 15 days' },
	{ value: 'OVERDUE_MORE_THAN_30_DAYS', label: 'More than 30 days' },
];

const RESTRICTED_INVOICE_STATUSES = ['PENDING', 'PAID'];

export {
	INVOICE_STATUSES,
	INVOICE_STATUSES_LABELS,
	RESTRICTED_INVOICE_STATUSES,
	INVOICE_STATUS_OPTIONS,
	INVOICE_STATUS_COLORS,
	INVOICE_OVERDUE_STATUS_OPTIONS,
};
