import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Overlay, { useOverlayTitles } from 'Common/components/modals/Overlay';
import SectionContainer from 'Common/components/modals/SectionContainer';
import PartnerCreateForm from './PartnerCreateForm';
import crudModes from 'Common/constants/crudModes';

const PartnerCreateOverlay = ({ isOpen, onClose, onFormSubmit }) => {
	const { t } = useTranslation();
	const { name, label } = useOverlayTitles(t('Partner'), crudModes.CREATE);

	const breadcrumbList = [
		{
			label: 'Partners',
			href: '/partners',
		},
		{
			label: name,
		},
	];

	return (
		<Overlay
			label={label}
			breadcrumbList={breadcrumbList}
			isOpen={isOpen}
			onClose={onClose}
			onSave={onFormSubmit}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<SectionContainer name="Partner">
					<PartnerCreateForm onSubmit={handleSubmit} onCancel={onCancel} onDirty={setIsFormDirty} />
				</SectionContainer>
			)}
		</Overlay>
	);
};

PartnerCreateOverlay.propTypes = {
	onFormSubmit: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default PartnerCreateOverlay;
