import api from 'Application/api/api';

const checkConfirmationCodeValidity = (signal, email, code) =>
	api.post(
		'/auth/check-confirmation-code-validity',
		{
			username: email,
			code,
		},
		{
			signal,
		},
	);

export default checkConfirmationCodeValidity;
