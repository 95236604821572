import { useMemo } from 'react';
import { pick } from 'lodash';

const fallbackValues = {
	name: '',
	partnerType: '',
	description: '',
};

const usePartnerDetailsFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['name', 'description']),
			partnerType: data.partnerType
				? {
						value: data.partnerType.id,
						label: data.partnerType.name,
				  }
				: null,
		}),
		[data],
	);

export default usePartnerDetailsFormInitialValues;
