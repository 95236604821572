import useCanBeCompletedByUser from 'PMWorkflow/hooks/useCanBeCompletedByUser';
import { useMemo } from 'react';

const COMPLETED_STATUSES = ['COMPLETED', 'SET_AS_COMPLETED'];

const isReopenActive = step => step.status !== 'OPEN' && !step.automationEnabled;

const isCompleteActive = (step, activityCanBeCompleted) =>
	step.canBeCompleted &&
	!COMPLETED_STATUSES.includes(step.status) &&
	!step.automationEnabled &&
	!step.skipped &&
	activityCanBeCompleted;

const isSkipActive = (step, activityCanBeCompleted = true) =>
	step.canBeCompleted && activityCanBeCompleted && step.canBeSkipped && step.status === 'OPEN';

// ! For testing purposes. Make sure to have the save logic as dispayDropdown.
// ! IF the content of statuses starts to influence the result ( not just by its length but by it's content as well -> CHANGE TESTS )
// ? stat = statuses
// ? acbc = activityCanBeCompleted
// ? uccs = userCanCompleteStep
// ? sa = showAutomated
// ? ste = step
const generateDisplayDropdown = (stat, acbc, uccs, sa, ste) =>
	((stat.length > 0 && acbc && uccs) ||
		sa ||
		COMPLETED_STATUSES.includes(ste.status) ||
		ste.skipped ||
		ste.status === 'SKIPPED') &&
	!ste.hideActions;

const useDropdownState = (step, activityCanBeCompleted) => {
	const triggerIconType = COMPLETED_STATUSES.includes(step.status)
		? 'check'
		: step.status === 'SKIPPED' || step.skipped
		? 'redo'
		: '';

	const userCanCompleteStep = useCanBeCompletedByUser(step);

	const showAutomated =
		step.automationEnabled &&
		!COMPLETED_STATUSES.includes(step.status) &&
		!(step.status === 'SKIPPED' || step.skipped);

	const statuses = useMemo(
		() =>
			[
				{
					label: 'Re-open',
					value: 'OPEN',

					isActive: isReopenActive(step),
				},
				{
					label: 'Complete',
					value: 'COMPLETED',
					icon: 'check',

					isActive: isCompleteActive(step, activityCanBeCompleted),
				},
				{
					label: 'Skip',
					value: 'SKIPPED',
					icon: 'redo',

					isActive: isSkipActive(step, activityCanBeCompleted),
				},
			].filter(status => status.isActive),
		[step, activityCanBeCompleted],
	);

	const displayDropdown =
		((statuses.length > 0 && activityCanBeCompleted && userCanCompleteStep) ||
			showAutomated ||
			COMPLETED_STATUSES.includes(step.status) ||
			step.skipped ||
			step.status === 'SKIPPED') &&
		!step.hideActions;

	return { showAutomated, statuses, displayDropdown, triggerIconType, generateDisplayDropdown };
};

export default useDropdownState;
