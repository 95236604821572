import { useMemo } from 'react';

const fallbackValues = {
	description: '',
	client: null,
	unknownClient: false,
	country: null,
	projectType: null,
	systemSizeKwp: '',
	kwhToKwpRel: '',
	responsiblePerson: null,
	locationUrl: '',
	epcPartner: null,
	projectDeveloper: null,
	regionalHub: null,
	salesChannel: null,
	initialEngagementDate: new Date(),
};

const useProjectCreateFormInitialValues = () =>
	useMemo(
		() => ({
			...fallbackValues,
		}),
		[],
	);

export default useProjectCreateFormInitialValues;
