import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';

import sizes from 'Application/theme/sizes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PM_WORKFLOW_TILES_COMPONENTS } from 'PMWorkflow/constants/PMWorkflowTilesComponents';
import { useDispatch } from 'react-redux';
import { setSidePanelDirtyForm } from 'Application/reducers/reduxSidePanel';
import React from 'react';

const TilesSection = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: ${sizes.spacing(1)};
	gap: ${sizes.spacing(1)};
	position: relative;
	z-index: 10;
`;

const TileContainer = styled.div`
	z-index: ${({ $zIndex }) => $zIndex};
`;

const useSidePanelTiles = (tiles, handleUpdateDirtyForms) =>
	useMemo(
		() =>
			tiles.map((tile, ind) => ({
				key: ind,
				Tile: PM_WORKFLOW_TILES_COMPONENTS[tile] ?? React.Fragment,
				onDirtyForm: isDirty => handleUpdateDirtyForms(tile, isDirty),
			})),
		[tiles, handleUpdateDirtyForms],
	);

const SidePanelTiles = ({ project, tiles: propTiles, fields, updateEntity }) => {
	const dispatch = useDispatch();

	const [dirtyForms, setDirtyForms] = useState({});

	const handleUpdateDirtyForms = useCallback((form, isDirty) => {
		setDirtyForms(prevDirtyForms => ({ ...prevDirtyForms, [form]: isDirty }));
	}, []);

	useEffect(() => {
		const dirtyFormsKeys = Object.entries(dirtyForms).reduce((prev, [name, isDirty]) => {
			if (isDirty) {
				return [...prev, name];
			}
			return prev;
		}, []);

		dispatch(setSidePanelDirtyForm(dirtyFormsKeys));
	}, [dirtyForms, dispatch]);

	const tiles = useSidePanelTiles(propTiles, handleUpdateDirtyForms);

	const handleSave = useCallback(() => {
		// ? Don't pass anything to updateEntity, as not all tiles return project
		updateEntity();
	}, [updateEntity]);

	return (
		<TilesSection>
			{tiles.map(({ Tile, key, onDirtyForm }) => (
				<TileContainer key={key} $zIndex={50 - key}>
					<Tile
						project={project}
						client={project.client}
						epcPartner={project.epcPartner}
						highlightLabels={fields}
						onEnterEditMode={() => {}}
						onExitEditMode={() => {}}
						onDirtyForm={onDirtyForm}
						onTileSave={handleSave}
						onSave={handleSave}
						isSidePanel
					/>
				</TileContainer>
			))}
		</TilesSection>
	);
};

SidePanelTiles.defaultProps = {
	fields: [],
	tiles: [],
	project: {},
	updateEntity: () => {},
};

SidePanelTiles.propTypes = {
	fields: PropTypes.arrayOf(PropTypes.shape({})),
	tiles: PropTypes.arrayOf(PropTypes.shape({})),
	project: PropTypes.shape({
		epcPartner: PropTypes.shape({}),
		client: PropTypes.shape({}),
	}),
	updateEntity: PropTypes.func,
};

export default SidePanelTiles;
