import styled, { css } from 'styled-components/macro';
import { PropTypes } from 'prop-types';

import SidePanel from 'Common/components/SidePanel/SidePanel';
import { Sidebar } from 'Common/components/layout/Sidebar';

const Layout = styled.div`
	display: flex;
	height: 100vh;

	body.is-blurred & {
		filter: blur(3px);
	}
`;

const Content = styled.div`
	flex: 1;
	overflow: ${({ $removeOverfow }) => ($removeOverfow ? 'hidden' : 'auto')};
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
`;
const MarkingDot = styled.div`
	${({ $hasFlag }) =>
		$hasFlag &&
		css`
			width: 2px;
			height: 2px;
			border-radius: 50%;
			background-color: red;
			transform: scale(10);
		`}
`;

const LayoutWithSidebar = ({ removeOverfow, children }) => (
	<Layout>
		<Sidebar />
		<MarkingDot id="page-top-marker-dot" />
		<Content $removeOverfow={removeOverfow}>{children}</Content>
		<SidePanel />
	</Layout>
);

LayoutWithSidebar.defaultProps = {
	removeOverfow: false,
};

LayoutWithSidebar.propTypes = {
	removeOverfow: PropTypes.bool,
	children: PropTypes.node.isRequired,
};

export default LayoutWithSidebar;
