import { useMemo } from 'react';
import { pick } from 'lodash';

const fallbackValues = {
	name: '',
	registrationNumber: '',
	website: '',
	marketSegment: null,
	foundationYear: null,
	employees: '',
	percentageOfFemaleShare: '',
	managers: '',
	percentageOfFemaleManagers: '',
	pin: '',
};

const useClientDetailsFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['name', 'registrationNumber', 'website', 'pin']),
			employees: data?.employees === null ? fallbackValues.employees : data?.employees,
			percentageOfFemaleManagers:
				data?.percentageOfFemaleManagers === null
					? fallbackValues.percentageOfFemaleManagers
					: data?.percentageOfFemaleManagers,
			managers: data?.managers === null ? fallbackValues.managers : data?.managers,
			percentageOfFemaleShare:
				data?.percentageOfFemaleShare === null
					? fallbackValues.percentageOfFemaleShare
					: data?.percentageOfFemaleShare,
			foundationYear: data?.foundationYearAsInt
				? {
						value: data.foundationYearAsInt,
						label: data.foundationYearAsInt,
				  }
				: null,
			industry: data?.industry
				? {
						value: data.industry.id,
						label: data.industry.name,
						marketSegment: data.industry.marketSegment,
				  }
				: null,
		}),
		[data],
	);

export default useClientDetailsFormInitialValues;
