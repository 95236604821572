import { useMemo } from 'react';
import { pick } from 'lodash';

const fallbackValues = {
	name: '',
	registrationNumber: '',
	website: '',
	marketSegment: null,
	foundationYear: null,
	employees: '',
	country: null,
	apartmentBuilding: '',
	line1: '',
	line2: '',
	cityRegion: '',
	postCodeZip: '',
	firstName: '',
	lastName: '',
	phone: '',
	ext: '',
	email: '',
	comment: '',
	position: '',
};

const useClientFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, [
				'name',
				'registrationNumber',
				'website',
				'employees',
				'firstName',
				'lastName',
				'phone',
				'ext',
				'email',
				'comment',
				'position',
			]),
			foundationYear: data?.foundationYearAsInt
				? {
						value: data.foundationYearAsInt,
						label: data.foundationYearAsInt,
				  }
				: null,
			industry: data?.industry
				? {
						value: data.industry.id,
						label: data.industry.name,
						excluded: data.industry.excluded,
						marketSegment: data.industry.marketSegment,
				  }
				: null,
			country: data?.country
				? {
						value: data.country.id,
						label: data.country.name,
				  }
				: null,
			apartmentBuilding: data?.address?.apartmentBuilding ?? '',
			line1: data?.address?.line1 ?? '',
			line2: data?.address?.line2 ?? '',
			cityRegion: data?.address?.cityRegion ?? '',
			postCodeZip: data?.address?.postCodeZip ?? '',
		}),
		[data],
	);

export default useClientFormInitialValues;
