import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import PartnerTypesForm from 'Preferences/components/Tabs/GeneralTab/PartnerTypes/PartnerTypesForm/PartnerTypesForm';
import crudModes from 'Common/constants/crudModes';
import getPartnerTypesById from 'Preferences/api/getPartnerTypesById';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PropTypes } from 'prop-types';

const PartnerTypesEditModal = ({ isOpen, onClose, partnerTypeId, onFormSubmit }) => {
	const { t } = useTranslation();

	const [partnerType, setPartnerType] = useState(null);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				const response = await getPartnerTypesById(abortController.signal, partnerTypeId);

				setPartnerType(response.data);
			} catch (error) {
				showToastError(error);
			}
		})();
	}, [partnerTypeId, abortController.signal]);

	const isDataLoading = partnerType === null;

	return (
		<ModalForm
			label="partner-type-edit"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			isLoadingContent={isDataLoading}
			heading={t('Edit Partner Type')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<PartnerTypesForm
					data={partnerType}
					mode={crudModes.EDIT}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

PartnerTypesEditModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	partnerTypeId: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default PartnerTypesEditModal;
