import { useMemo } from 'react';
import { pick } from 'lodash';

const fallbackValues = {
	epcVolumeExclVat: '',
	annualOmPrice: '',
	annualOmEscalationRate: '',
	currency: { value: 'EUR' },
};

const fromCurrencyFormat = amountWithCurrency => {
	if (!amountWithCurrency) {
		return '';
	}

	if (amountWithCurrency.currency === 'EUR') {
		return amountWithCurrency.eurValue;
	}

	return amountWithCurrency.value;
};

const useEpcContractFormInitialValues = data => {
	const currency = useMemo(
		() =>
			data?.epcVolumeExclVat
				? { value: data?.epcVolumeExclVat?.currency }
				: data?.annualOmPrice
				? { value: data?.annualOmPrice?.currency }
				: fallbackValues.currency,
		[data?.epcVolumeExclVat, data?.annualOmPrice],
	);

	return useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['annualOmEscalationRate']),
			currency: currency,
			epcVolumeExclVat: fromCurrencyFormat(data?.epcVolumeExclVat),
			annualOmPrice: fromCurrencyFormat(data?.annualOmPrice),
		}),
		[data, currency],
	);
};

export default useEpcContractFormInitialValues;
