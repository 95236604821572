import { useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import Button from 'Common/components/buttons/Button';
import { useTranslation } from 'react-i18next';
import FilterDropdown from 'Common/components/filter/FilterDropdown';
import useSearchOptions from 'Common/components/filter/hooks/useSearchOptions';
import { css } from 'styled-components';
import { ClickAwayListener } from '@mui/material';
import useReponsive from 'Common/hooks/useResponsive';
import { PropTypes } from 'prop-types';

const Wrapper = styled.div`
	position: relative;
`;

const FilterDropdownContainer = styled.div`
	${({ isOpen }) =>
		isOpen
			? css`
					opacity: 1;
					transform: scale(1);
			  `
			: css`
					opacity: 0;
					transform: scale(0);
					pointer-events: none;
			  `}

	${({ $isMobile }) =>
		$isMobile
			? css`
					position: fixed;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					transition: all 0.3s ease-in-out;
					transform-origin: top right;
					z-index: 1000;
			  `
			: css`
					position: absolute;
					right: 0;
					top: 140%;
					transition: all 0.3s ease-in-out;
					transform-origin: top right;
					z-index: 1000;
			  `}
`;

const MobileDropdownBg = styled.div`
	position: fixed;
	left: 0;
	width: 100vw;
	top: 0;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 998;
`;

const useSelectedOptions = options => useMemo(() => options.filter(option => option.selected), [options]);

const FilterButton = ({ filtersList, filledFiltersCount, onSelectFilter, onClearFilters }) => {
	const { t } = useTranslation();
	const { isMobile } = useReponsive();
	const [menuIsOpen, setMenuIsOpen] = useState(false);

	const handleTriggerClick = () => {
		setMenuIsOpen(!menuIsOpen);
	};

	const handleOptionClick = optionName => {
		onSelectFilter(optionName);
	};

	const selectedOptions = useSelectedOptions(filtersList);

	const { searchValue, setSearchValue, filteredOptions } = useSearchOptions(filtersList, true);

	return (
		<ClickAwayListener onClickAway={() => setMenuIsOpen(false)}>
			<Wrapper>
				<Button
					icon="filterListRounded"
					medium
					secondary
					active={menuIsOpen || filledFiltersCount > 0}
					count={filledFiltersCount}
					onClick={handleTriggerClick}
					label="Filter Button"
				/>
				<FilterDropdownContainer isOpen={menuIsOpen} $isMobile={isMobile}>
					<FilterDropdown
						options={filteredOptions}
						selectedOptions={selectedOptions}
						handleOptionClick={handleOptionClick}
						handleBottomBtnClick={onClearFilters}
						bottomBtnLabel={t('Reset filters')}
						searchValue={searchValue}
						setSearchValue={setSearchValue}
						hasFocus={menuIsOpen}
					/>
				</FilterDropdownContainer>
				{menuIsOpen && isMobile && <MobileDropdownBg onClick={() => setMenuIsOpen(false)} />}
			</Wrapper>
		</ClickAwayListener>
	);
};

FilterButton.propTypes = {
	filtersList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	filledFiltersCount: PropTypes.number.isRequired,
	onSelectFilter: PropTypes.func.isRequired,
	onClearFilters: PropTypes.func.isRequired,
};

export default FilterButton;
