/* eslint-disable complexity */
import useAbortController from 'Common/hooks/useAbortController';
import showToastError from 'Common/utils/showToastError';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import { INVOICE_STATUSES_LABELS, INVOICE_STATUS_OPTIONS } from 'Invoice/constants/invoiceContstants';
import { pick } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { parseISO } from 'date-fns';
import crudModes from 'Common/constants/crudModes';
import Big from 'big.js';

const editModeFallbackValues = {
	status: INVOICE_STATUS_OPTIONS[0],
	invoiceTemplate: null,
	exchangeRate: 1,
	bankAccount: null,
	eurBankAccount: null,
	saasCurrency: { value: 'EUR', label: 'EUR' },
	paymentCurrency: { value: 'EUR', label: 'EUR' },
	number: '',
	paymentDate: new Date(),
	issueDate: undefined,
	dueDate: undefined,
	billingPeriodStart: undefined,
	billingPeriodEnd: undefined,
	countryVatPercentage: '',
	countryWhtPercentage: '',
	countryWhtVatPercentage: '',
	countryNHILPercentage: '',
	countryCovid19LevyPercentage: '',
	countryGetFundLevyPercentage: '',
	leasingFee: '',
	ppaFee: '',
	maintenanceFee: '',
	subTotalExcludingTax: '',
	vat: '',
	subTotal: '',
	wht: '',
	whtVat: '',
	totalAmount: '',
};

const createModeFallbackValues = {
	status: INVOICE_STATUS_OPTIONS[0],
	invoiceTemplate: null,
	exchangeRate: 1,
	bankAccount: null,
	saasCurrency: { value: 'EUR', label: 'EUR' },
	paymentCurrency: { value: 'EUR', label: 'EUR' },
	paymentDate: new Date(),
	number: '',
	issueDate: new Date(),
	dueDate: new Date(Date.now() + 15 * 24 * 60 * 60 * 1000),
	billingPeriodStart: undefined,
	billingPeriodEnd: undefined,
	countryVatPercentage: '',
	countryWhtPercentage: '',
	countryWhtVatPercentage: '',
	countryNHILPercentage: '',
	countryCovid19LevyPercentage: '',
	countryGetFundLevyPercentage: '',
	leasingFee: '',
	ppaFee: '',
	maintenanceFee: '',
	subTotalExcludingTax: '',
	vat: '',
	subTotal: '',
	wht: '',
	whtVat: '',
	nhil: '',
	covid19Levy: '',
	getFundLevy: '',
	subTotalTaxableValue: '',
	totalAmount: '',
};

const useInvoiceInitialValues = ({ mode, newInvoiceNumber, country, client, projectSettings, ...data }) => {
	const saasCurrencyValue = useMemo(
		() => data?.saasCurrency || projectSettings?.saasCurrency || editModeFallbackValues?.saasCurrency?.value,
		[projectSettings?.saasCurrency, data?.saasCurrency],
	);
	const paymentCurrencyValue = useMemo(
		() =>
			data?.paymentCurrency ||
			projectSettings?.paymentCurrency ||
			editModeFallbackValues?.paymentCurrency?.value,
		[data?.paymentCurrency, projectSettings?.paymentCurrency],
	);
	const [saasCurrencyOption, setSaasCurrencyOption] = useState({
		value: saasCurrencyValue,
		label: 'EUR Euro',
	});
	const [paymentCurrencyOption, setPaymentCurrencyOption] = useState({
		value: paymentCurrencyValue,
		label: 'EUR Euro',
	});

	const abortController = useAbortController();

	// Set the default currency to EUR (if no currency was set)
	useEffect(() => {
		(async () => {
			try {
				const currencies = await loadCurrenciesOptions(abortController.signal, '', false);

				setSaasCurrencyOption(currencies.find(e => e.value === saasCurrencyValue));
				setPaymentCurrencyOption(currencies.find(e => e.value === paymentCurrencyValue));
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [saasCurrencyValue, paymentCurrencyValue, abortController.signal]);

	return useMemo(
		() =>
			mode === crudModes.CREATE
				? {
						...createModeFallbackValues,
						saasCurrency: saasCurrencyOption,
						paymentCurrency: paymentCurrencyOption,
						bankAccount: projectSettings?.bankAccount
							? { label: projectSettings.bankAccount.name, value: projectSettings.bankAccount.id }
							: createModeFallbackValues.bankAccount,
						invoiceTemplate: projectSettings?.invoiceTemplate
							? { label: projectSettings.invoiceTemplate.fileName, value: projectSettings.invoiceTemplate.id }
							: createModeFallbackValues.invoiceTemplate,
						number: newInvoiceNumber,
						countryVatPercentage: country?.vat && !client?.taxFree ? Big(country?.vat).times(100) : undefined,
						countryWhtPercentage: country?.wht && !client?.taxFree ? Big(country?.wht).times(100) : undefined,
						countryWhtVatPercentage:
							country?.vatWht && !client.taxFree && client?.subjectToVatWht
								? Big(country?.vatWht).times(100)
								: undefined,
						countryNHILPercentage: country?.nhil && !client.taxFree ? Big(country?.nhil).times(100) : undefined,
						countryCovid19LevyPercentage:
							country?.covid19Levy && !client.taxFree ? Big(country?.covid19Levy).times(100) : undefined,
						countryGetFundLevyPercentage:
							country?.getFundLevy && !client.taxFree ? Big(country?.getFundLevy).times(100) : undefined,
						taxFree: client?.taxFree ? client.taxFree : false,
				  }
				: {
						...editModeFallbackValues,
						...pick(data, Object.keys(editModeFallbackValues)),
						taxFree: client?.taxFree ? client.taxFree : false,
						paymentDate: data?.paymentDate ? new Date(data?.paymentDate) : editModeFallbackValues.paymentDate,
						saasCurrency: saasCurrencyOption,
						paymentCurrency: paymentCurrencyOption,
						status: data?.status
							? {
									value: data?.status?.status,
									label: INVOICE_STATUSES_LABELS[data?.status?.status],
							  }
							: editModeFallbackValues.status,
						invoiceTemplate: data?.invoiceTemplate
							? { value: data?.invoiceTemplate.id, label: data?.invoiceTemplate.fileName }
							: editModeFallbackValues.invoiceTemplate,
						bankAccount: data?.bankAccount
							? {
									value: data?.bankAccount.id,
									label: data?.bankAccount.name,
									subtitle: data?.bankAccount.accountNumber,
							  }
							: editModeFallbackValues.bankAccount,
						eurBankAccount: data?.eurBankAccount
							? { value: data?.eurBankAccount.id, label: data?.eurBankAccount.name }
							: editModeFallbackValues.eurBankAccount,
						issueDate: data?.issueDate ? parseISO(data?.issueDate) : editModeFallbackValues.issueDate,
						dueDate: data?.dueDate ? parseISO(data?.dueDate) : editModeFallbackValues.dueDate,
						billingPeriodStart: data?.billingPeriodStart
							? parseISO(data?.billingPeriodStart)
							: editModeFallbackValues.billingPeriodStart,
						billingPeriodEnd: data?.billingPeriodEnd
							? parseISO(data?.billingPeriodEnd)
							: editModeFallbackValues.billingPeriodEnd,
						countryVatPercentage: data?.countryVatPercentage
							? Big(data?.countryVatPercentage).times(100)
							: undefined,
						countryWhtPercentage: data?.countryWhtPercentage
							? Big(data?.countryWhtPercentage).times(100)
							: undefined,
						countryWhtVatPercentage: data?.countryWhtVatPercentage
							? Big(data?.countryWhtVatPercentage).times(100)
							: undefined,
						countryNHILPercentage: data?.countryNHILPercentage
							? Big(data?.countryNHILPercentage).times(100)
							: undefined,
						countryCovid19LevyPercentage: data?.countryCovid19LevyPercentage
							? Big(data?.countryCovid19LevyPercentage).times(100)
							: undefined,
						countryGetFundLevyPercentage: data?.countryGetFundLevyPercentage
							? Big(data?.countryGetFundLevyPercentage).times(100)
							: undefined,
						nhil: data?.nhil || 0,
						covid19Levy: data?.covid19Levy || 0,
						getFundLevy: data?.getFundLevy || 0,
						leasingFee: data?.leasingFee,
						ppaFee: data?.ppaFee,
				  },
		[
			saasCurrencyOption,
			paymentCurrencyOption,
			projectSettings?.bankAccount,
			projectSettings?.invoiceTemplate,
			data,
			mode,
			country,
			newInvoiceNumber,
			client?.taxFree,
			client?.subjectToVatWht,
		],
	);
};

export default useInvoiceInitialValues;
