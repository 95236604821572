import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import crudModes from 'Common/constants/crudModes';
import getBankAccountById from 'BankAccount/api/getBankAccountById';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PropTypes } from 'prop-types';
import BankAccountForm from 'BankAccount/components/Form/BankAccountForm';

const BankAccountEditModal = ({ isOpen, onClose, bankAccountId, onFormSubmit }) => {
	const { t } = useTranslation();

	const [bankAccount, setBankAccount] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				const response = await getBankAccountById(abortController.signal, bankAccountId);

				setBankAccount(response.data);
				setIsLoading(false);
			} catch (error) {
				showToastError(error);
			}
		})();
	}, [bankAccountId, abortController.signal]);

	return (
		<ModalForm
			label="bank-account-edit"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			isLoadingContent={isLoading}
			heading={t('Edit Bank Account')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<BankAccountForm
					data={bankAccount}
					mode={crudModes.EDIT}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

BankAccountEditModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	bankAccountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default BankAccountEditModal;
