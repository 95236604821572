import UnsavedChangesModal from './modals/UnsavedChangesModal';
import ReactRouterPrompt from 'react-router-prompt';
import { PropTypes } from 'prop-types';

const Prompt = ({ when }) => {
	return (
		<ReactRouterPrompt when={when}>
			{({ isActive, onConfirm, onCancel }) => (
				<UnsavedChangesModal isOpen={isActive} onClose={onCancel} onConfirm={onConfirm} />
			)}
		</ReactRouterPrompt>
	);
};

Prompt.propTypes = {
	when: PropTypes.bool.isRequired,
};

export default Prompt;
