import { useMemo } from 'react';

const fallbackValues = {
	tags: [],
};

const useMarketingFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			tags: data?.finance_details?.tagRefs
				? data.finance_details.tagRefs.map(tagRef => ({
						value: tagRef.tagId,
						label: tagRef.tagName,
				  }))
				: [],
		}),
		[data],
	);

export default useMarketingFormInitialValues;
