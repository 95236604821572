import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import { orderBy, pick, uniqBy } from 'lodash';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import styled from 'styled-components/macro';

import editContact from 'Contacts/api/editContact';
import createContact from 'Contacts/api/createContact';
import expellContact from 'Contacts/api/expellContact';

import { userStates } from 'EpcPartner/constants/userStates';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import notify from 'Common/utils/notify';
import EditableTile from 'Common/components/Tile/EditableTile';
import ExternalLink from 'Common/components/ExternalLink';
import TableInput from 'Common/components/form/Table/TableInput';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import TableCheckbox from 'Common/components/form/Table/TableCheckbox';
import { useEditableRow } from 'Common/hooks/table/plugin-hooks/useEditableRow';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import { useStickyColumns } from 'Common/hooks/table/plugin-hooks/useStickyColumns';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import {
	DefaultTable,
	TableBodyCell,
	TableBodyGroupCell,
	TableHeadCell,
	TableBody,
} from 'Common/components/table/table';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import colors from 'Application/theme/colors';
import { useCustomRowStyle } from 'Common/hooks/table/plugin-hooks/useCustomRowStyle';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import TableCellText from 'Common/components/table/TableCellText';
import FormikTextArea from 'Common/components/form/FormikTextArea';
import { useFormik } from 'formik';
import useExpellContactInitialValues from './hooks/useExpellContactFormInitialValues';
import useExpellContactValidationSchema from './hooks/useExpellContactValidationSchema';
import showToastError from 'Common/utils/showToastError';
import ProjectInfoPopup from 'Dashboard/components/ProjectInfoPopup/ProjectInfoPopup';
import { PropTypes } from 'prop-types';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import ModalForm, { ModalFormButtons } from 'Common/components/form/ModalForm';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	@media (min-width: 1250px) {
		${TableBody} {
			overflow: hidden !important;
		}
	}

	${TableHeadCell}:not(${TableBodyGroupCell}) {
		background: transparent;
	}

	${TableBodyCell} {
		background: transparent;
	}
`;

const ContactsList = ({
	contacts,
	epcPartnerId,
	onSubmit,
	deleteContact,
	openUserEdit,
	updateContacts,
	openAssignModal,
}) => {
	const { t } = useTranslation();

	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [expellContactId, setExpellContactId] = useState();
	const [deletionError, setDeletionError] = useState(null);

	const abortController = useAbortController();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.SALES,
		roles.PM_TECHNICAL,
		roles.ASSET_MANAGER,
	]);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('First name'),
				accessor: 'firstName',
				customCellContentStyles: {
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
					display: 'block',
				},
				customBodyCellStyles: {
					height: '53px',
				},
				EditableField: (() => {
					const EditableField = ({ formik: { values, errors, touched, handleChange, handleBlur } }) => (
						<TableInput
							id="firstName"
							name="firstName"
							value={values.firstName}
							error={errors.firstName}
							touched={touched.firstName}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					);
					EditableField.propTypes = {
						formik: PropTypes.shape({
							values: PropTypes.shape({
								firstName: PropTypes.string,
							}),
							errors: PropTypes.shape({
								firstName: PropTypes.string,
							}),
							touched: PropTypes.shape({
								firstName: PropTypes.string,
							}),
							handleChange: PropTypes.func,
							handleBlur: PropTypes.func,
						}).isRequired,
					};
					return EditableField;
				})(),
				Cell: (() => {
					const Cell = ({ row }) => (
						<div title={row.original?.firstName}>
							<TableCellText isDisabled={row.original?.markAsExpelled}>{row.original?.firstName}</TableCellText>
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ firstName: PropTypes.string, markAsExpelled: PropTypes.bool }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Last name'),
				accessor: 'lastName',
				customBodyCellStyles: {
					height: '53px',
				},
				customCellContentStyles: {
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
					display: 'block',
				},
				EditableField: (() => {
					const EditableField = ({ formik: { values, errors, touched, handleChange, handleBlur } }) => (
						<TableInput
							id="lastName"
							name="lastName"
							value={values.lastName}
							error={errors.lastName}
							touched={touched.lastName}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					);
					EditableField.propTypes = {
						formik: PropTypes.shape({
							values: PropTypes.shape({
								lastName: PropTypes.string,
							}),
							errors: PropTypes.shape({
								lastName: PropTypes.string,
							}),
							touched: PropTypes.shape({
								lastName: PropTypes.string,
							}),
							handleChange: PropTypes.func,
							handleBlur: PropTypes.func,
						}).isRequired,
					};
					return EditableField;
				})(),
				Cell: (() => {
					const Cell = ({ row }) => (
						<div title={row.original?.lastName}>
							<TableCellText isDisabled={row.original?.markAsExpelled}>{row.original?.lastName}</TableCellText>
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ lastName: PropTypes.string, markAsExpelled: PropTypes.bool }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Position'),
				accessor: 'position',
				customCellContentStyles: {
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
					display: 'block',
				},
				customBodyCellStyles: {
					height: '53px',
				},
				EditableField: (() => {
					const EditableField = ({ formik: { values, errors, touched, handleChange, handleBlur } }) => (
						<TableInput
							id="position"
							name="position"
							value={values.position}
							error={errors.position}
							touched={touched.position}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					);
					EditableField.propTypes = {
						formik: PropTypes.shape({
							values: PropTypes.shape({
								position: PropTypes.string,
							}),
							errors: PropTypes.shape({
								position: PropTypes.string,
							}),
							touched: PropTypes.shape({
								position: PropTypes.string,
							}),
							handleChange: PropTypes.func,
							handleBlur: PropTypes.func,
						}).isRequired,
					};
					return EditableField;
				})(),
				Cell: (() => {
					const Cell = ({ row }) => (
						<div title={row.original?.position}>
							<TableCellText isDisabled={row.original?.markAsExpelled}>{row.original?.position}</TableCellText>
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ position: PropTypes.string, markAsExpelled: PropTypes.bool }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('E-mail'),
				accessor: 'email',
				customCellContentStyles: {
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
					display: 'block',
				},
				customBodyCellStyles: {
					height: '53px',
				},
				EditableField: (() => {
					const EditableField = ({ formik: { values, errors, handleChange, handleBlur } }) => (
						<TableInput
							id="email"
							name="email"
							value={values.email}
							error={errors.email}
							touched={true}
							onChange={handleChange}
							onBlur={handleBlur}
							className="email"
						/>
					);
					EditableField.propTypes = {
						formik: PropTypes.shape({
							values: PropTypes.shape({
								email: PropTypes.string,
							}),
							errors: PropTypes.shape({
								email: PropTypes.string,
							}),
							touched: PropTypes.shape({
								email: PropTypes.string,
							}),
							handleChange: PropTypes.func,
							handleBlur: PropTypes.func,
						}).isRequired,
					};
					return EditableField;
				})(),
				Cell: (() => {
					const Cell = ({ row }) => (
						<ExternalLink isDisabled={row.original?.markAsExpelled} location={`mailto:${row.original.email}`}>
							{row.original.email}
						</ExternalLink>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ email: PropTypes.string, markAsExpelled: PropTypes.bool }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Phone'),
				accessor: 'phone',
				customCellContentStyles: {
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
					display: 'block',
				},
				customBodyCellStyles: {
					height: '53px',
				},
				EditableField: (() => {
					const EditableField = ({ formik: { values, errors, touched, handleChange, handleBlur } }) => (
						<TableInput
							id="phone"
							name="phone"
							value={values.phone}
							error={errors.phone}
							touched={touched.phone}
							onChange={handleChange}
							onBlur={handleBlur}
							style={{}}
						/>
					);
					EditableField.propTypes = {
						formik: PropTypes.shape({
							values: PropTypes.shape({
								phone: PropTypes.string,
							}),
							errors: PropTypes.shape({
								phone: PropTypes.string,
							}),
							touched: PropTypes.shape({
								phone: PropTypes.string,
							}),
							handleChange: PropTypes.func,
							handleBlur: PropTypes.func,
						}).isRequired,
					};
					return EditableField;
				})(),
				Cell: (() => {
					const Cell = ({ row }) => (
						<ExternalLink isDisabled={row.original?.markAsExpelled} location={`tel:${row.original.phone}`}>
							{row.original.phoneAndExt}
						</ExternalLink>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								phone: PropTypes.string,
								phoneAndExt: PropTypes.string,
								markAsExpelled: PropTypes.bool,
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Comment'),
				accessor: 'comment',
				customCellContentStyles: {
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
					display: 'block',
				},
				customBodyCellStyles: {
					height: '53px',
				},
				EditableField: (() => {
					const EditableField = ({ formik: { values, errors, touched, handleChange, handleBlur } }) => (
						<TableInput
							id="comment"
							name="comment"
							value={values.comment}
							error={errors.comment}
							touched={touched.comment}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					);
					EditableField.propTypes = {
						formik: PropTypes.shape({
							values: PropTypes.shape({ comment: PropTypes.string }),
							errors: PropTypes.shape({ comment: PropTypes.string }),
							touched: PropTypes.shape({ comment: PropTypes.string }),
							handleChange: PropTypes.func,
							handleBlur: PropTypes.func,
						}).isRequired,
					};
					return EditableField;
				})(),
				Cell: (() => {
					const Cell = ({ row }) => (
						<div title={row.original?.position}>
							<TableCellText isDisabled={row.original?.markAsExpelled}>{row.original?.comment}</TableCellText>
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								position: PropTypes.string,
								comment: PropTypes.string,
								markAsExpelled: PropTypes.bool,
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Portal status'),
				accessor: 'invited',
				customBodyCellStyles: {
					height: '53px',
				},
				customBodyCellContentStyles: {
					paddingLeft: '5px',
				},
				EditableField: (() => {
					const EditableField = ({
						formik: { values, touched, handleChange, handleBlur },
						row: {
							original: { inviteToPortal, invited },
						},
					}) => (
						<TableCheckbox
							id="inviteToPortal"
							name="inviteToPortal"
							label="Invite"
							checked={values.inviteToPortal}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.inviteToPortal}
							isDisabled={inviteToPortal || invited === 'Invited'}
						/>
					);
					EditableField.propTypes = {
						formik: PropTypes.shape({
							values: PropTypes.shape({ inviteToPortal: PropTypes.bool }),
							errors: PropTypes.shape({ inviteToPortal: PropTypes.bool }),
							touched: PropTypes.shape({ inviteToPortal: PropTypes.bool }),
							handleChange: PropTypes.func,
							handleBlur: PropTypes.func,
						}).isRequired,
						row: PropTypes.shape({
							original: PropTypes.shape({ invited: PropTypes.bool, inviteToPortal: PropTypes.bool }),
						}).isRequired,
					};
					return EditableField;
				})(),
				Cell: (() => {
					const Cell = ({ row }) => (
						<div title={row.original?.position}>
							<TableCellText disabled={row.original?.markAsExpelled}>{row.original?.invited}</TableCellText>
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								position: PropTypes.string,
								invited: PropTypes.bool,
								markAsExpelled: PropTypes.bool,
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: '',
				accessor: 'actions',
				maxWidth: 30,
				sticky: true,

				customCellStyles: {
					background: `${colors.common.tileBackgroundGrey}!important`,
				},
				customHeaderCellStyles: {
					background: 'transparent',
				},
				customBodyCellStyles: {
					height: '53px',
				},

				EditableField: <></>,
				Cell: (() => {
					const Cell = ({ row }) => (
						<MoreActionsButton
							disabled={!isAuthorizedToEdit}
							label="EPC Partner Contacts More Actions"
							controlledVisibility
						>
							<MenuItem
								type="button"
								data-action="edit"
								{...row.getTurnOnRowEditModeProps()}
								label="Contacts List - Edit Menu Item"
							>
								Edit
							</MenuItem>
							<MenuItem
								type="button"
								data-action="assignToProject"
								onClick={() => openAssignModal(row.original.contactId)}
								label="Contacts List - Assign to Project Menu Item"
							>
								Assign to Project
							</MenuItem>
							<MenuItem
								type="button"
								data-action="editUserAccount"
								disabled={row.original.invited !== 'Invi	ted'}
								onClick={() => openUserEdit(row.original.email)}
								label="Contacts List - Edit User Account Menu Item"
							>
								Edit User account
							</MenuItem>
							<MenuItem
								type="button"
								data-action="expelUserAccount"
								disabled={row.original.markAsExpelled}
								onClick={() => {
									setExpellContactId(row.original.contactId);
									setShowConfirmModal(true);
								}}
								label="Contacts List - Mark as Expelled Menu Item"
							>
								Mark as expelled
							</MenuItem>
							<MenuItem
								type="button"
								data-action="delete"
								onClick={() =>
									deleteContact({
										firstName: row.original.firstName,
										lastName: row.original.lastName,
										id: row.original.contactId,
									})
								}
								label="Contacts List - Delete Menu Item"
							>
								Delete
							</MenuItem>
						</MoreActionsButton>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								firstName: PropTypes.string,
								lastName: PropTypes.string,
								id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
								markAsExpelled: PropTypes.bool,
								contactId: PropTypes.number,
								invited: PropTypes.bool,
								email: PropTypes.string,
							}),
							getTurnOnRowEditModeProps: PropTypes.string,
						}).isRequired,
					};
					return Cell;
				})(),
			},
		],
		[t, deleteContact, openUserEdit, isAuthorizedToEdit, openAssignModal],
	);

	const data = useMemo(
		() =>
			orderBy(contacts, ['firstName'], ['asc']).map(contact => ({
				...contact,
				id: `${contact.id}_${contact.projectId}`,
				phoneAndExt: `${contact.phone}${contact.ext ? ',' + contact.ext : ''}`,
				invited:
					contact.userState === userStates.ACTIVE
						? 'Invited'
						: contact.userState === userStates.INACTIVE && contact.markAsExpelled
						? 'Expelled'
						: 'Not invited',
				contactId: contact.id,
				$rowDisabled: contact.markAsExpelled,
				bodyGroupIds: [contact.projectExternalId || 'general'],
			})),
		[contacts],
	);

	const categories = useMemo(
		() =>
			uniqBy(
				[
					{ id: 'general', name: 'General' },
					...(
						data?.map(item => ({
							id: item.projectExternalId,
							name: String(item.projectExternalId).padStart(5, '0'),
							linkTo: `/projects/details/${item.projectId}`,
							Popup: () => <ProjectInfoPopup projectId={item.projectId} $isInTable disableUserInfoHover />,
						})) || []
					).filter(item => !isNaN(item.id)),
				],
				'id',
			),
		[data],
	);

	const getEditableRowInitialValues = useCallback(
		row => ({
			...pick(row.original, [
				'firstName',
				'lastName',
				'email',
				'phone',
				'ext',
				'contactId',
				'position',
				'inviteToPortal',
				'comment',
				'markAsExpelled',
			]),
			inviteToPortal: row.original.invited === 'Invited',
		}),
		[],
	);

	const editableRowFormikOptions = useMemo(
		() => ({
			validationSchema: yup.object({
				firstName: yup.string().required(t('Required')),
				lastName: yup.string().required(t('Required')),
				email: yup.mixed().when('inviteToPortal', {
					is: true,
					then: yup.string().email('Invalid email').required(t('Required')),
					otherwise: yup.string().email('Invalid email'),
				}),
			}),

			onSubmit: async (row, values) => {
				try {
					let response;
					if (row.isNew) {
						response = await createContact(abortController.signal, {
							...values,
							email: values.email || null,
							epcPartnerId: epcPartnerId,
							markAsExpelled: false,
						});
						notify(t('Contact created successfully'), {
							type: toast.TYPE.SUCCESS,
						});
					} else {
						response = await editContact(abortController.signal, {
							...values,
							email: values.email || null,
							type: 'EPCPARTNER',
							id: row.original.contactId,
						});
						notify(t('Contact saved successfully'), {
							type: toast.TYPE.SUCCESS,
						});
					}
					onSubmit(response.data, row.isNew);
				} catch (error) {
					showToastError(error, row.isNew ? t("Can't create contact") : t("Can't edit contact"));
					return 'error';
				}
			},
		}),
		[t, epcPartnerId, onSubmit, abortController.signal],
	);

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		addRowForCreation,
	} = useStandardTable(
		{
			data,
			columns,
			getEditableRowInitialValues,
			editableRowFormikOptions,
			newRowData: {
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				phoneExt: '',
				position: '',
				comment: '',
				inviteToPortal: false,
				bodyGroupIds: ['NO_PROJECTS'],
			},
			getCustomRowStyles: () => ({
				height: 'auto',
			}),
		},
		useCustomCellStyles,
		useStickyColumns,
		useEditableRow,
		useCustomRowStyle,
	);

	const initialValues = useExpellContactInitialValues({});
	const validationSchema = useExpellContactValidationSchema();

	const handleExpellContact = async values => {
		try {
			const res = await expellContact(abortController.signal, expellContactId, values);

			updateContacts(res.data);

			notify(t('Contact expelled successfully'), {
				type: toast.TYPE.SUCCESS,
			});
			setShowConfirmModal(false);
		} catch (error) {
			if (!axios.isCancel(error)) {
				setDeletionError(error.response.data);
				setShowConfirmModal(false);
			}
		}
	};

	const { errors, touched, values, handleChange, handleBlur, handleSubmit, setFieldTouched } = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: handleExpellContact,
	});

	return (
		<EditableTile
			title={t('Contacts')}
			rightComponent={<TileActionButton disabled={!isAuthorizedToEdit} onClick={addRowForCreation} />}
			isTable
		>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					prepareRow={prepareRow}
					headerGroups={headerGroups}
					editableRowFormikOptions={editableRowFormikOptions}
					noGap={true}
					bodyGroups={categories}
				/>
			</TableWrapper>
			<ModalForm
				isOpen={showConfirmModal}
				onFormSubmit={() => handleSubmit()}
				onClose={() => setShowConfirmModal(false)}
				label="mark-as-expeleed"
				heading={t('Mark as expelled')}
				helpText={`${t('This will permanently mark the contact as expelled and disable their login to pulse.')}

				${t('Add reason for expelling.')}`}
			>
				{(setIsFormDirty, handleSubmit, onCancel) => (
					<>
						<FormikTextArea
							id="comment"
							name="comment"
							label={t('Comment')}
							value={values.comment}
							error={errors.comment}
							touched={touched.comment}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							setFieldTouched={setFieldTouched}
						/>
						<ModalFormButtons
							onCancel={onCancel}
							onConfirm={handleSubmit}
							label="Contacts List Mark As Expelled"
							submitButtonText={t('Save')}
						/>
					</>
				)}
			</ModalForm>
			<UnsuccessfulOperationModal
				label="cannot-expell-contact"
				isOpen={deletionError !== null}
				onCancel={() => setDeletionError(null)}
				heading={t('Cannot expell contact')}
				text={deletionError?.violations?.[0]?.message}
			/>
		</EditableTile>
	);
};

ContactsList.propTypes = {
	contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	epcPartnerId: PropTypes.number.isRequired,
	onSubmit: PropTypes.func.isRequired,
	openAssignModal: PropTypes.func.isRequired,
	deleteContact: PropTypes.func.isRequired,
	openUserEdit: PropTypes.func.isRequired,
	updateContacts: PropTypes.func.isRequired,
};

export default ContactsList;
