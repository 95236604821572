import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import sizes from 'Application/theme/sizes';
import colors from 'Application/theme/colors';
import { updateOpenStepStatusDropdownId } from 'Application/reducers/reduxCommon';

import Loader from 'Common/components/Loader';
import Icon from 'Common/components/icons/Icon';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import useDropdownState from './hooks/useStatusDropdownState';
import patchStep from 'PMWorkflow/api/patchStep';

import PropTypes from 'prop-types';
import showToastError from 'Common/utils/showToastError';
import RestrictedIndustriesWarningModal from './modals/RestrictedIndustriesWarningModal';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import getProjectById from 'Projects/api/getProjectById';

const LoaderContainer = styled.div`
	max-width: ${({ $maxWidth }) => $maxWidth};
	height: 30px;
`;

const StatusDropdownContainer = styled.div`
	position: relative;
`;

const StatusDropdownTrigger = styled.div`
	width: 25px;
	height: 25px;
`;

const AutomatedSkippedContainer = styled(HoverTooltip)`
	cursor: default;
	svg {
		width: 100%;
		height: 100%;
		color: ${colors.text.grey};
	}
`;

const StatusIcon = styled.div`
	width: 100%;
	height: 100%;
	border: solid 2.5px ${props => (props.color ? props.color : colors.common.blue)};
	border-radius: 50%;
	svg {
		width: 100%;
		height: 100%;
		color: ${colors.common.blue};
	}
`;

const StatusDropdownWrapper = styled.div`
	position: absolute;
	display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
	right: 75%;
	top: 100%;
	background-color: ${colors.common.white};
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	z-index: 10;
	border-radius: 6px;
	cursor: pointer;
`;

const StatusOptionIcon = styled.div`
	border: solid 2.5px ${colors.common.black};
	border-radius: 50%;
	aspect-ratio: 1;
	height: 20px;
	width: 20px;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 90%;
		height: 90%;
	}
`;

const StatusOption = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${sizes.spacing(1.25)};
	padding: ${sizes.spacing(1)} ${sizes.spacing(2.25)};
	white-space: nowrap;

	&:first-child {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
	}

	&:last-child {
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	&:hover {
		background-color: ${colors.common.blue};
		color: ${colors.common.white};
		${StatusOptionIcon} {
			border-color: ${colors.common.white};
		}
	}
`;

const RestrictedIndustriesModalWrapper = styled.div`
	position: absolute;
`;

const StatusDropdown = ({
	step,
	projectId,
	isProjectOnHold,
	activityCanBeCompleted,
	onStatusUpdate,
	isSoftLoading,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [restrictedIndustriesWarning, setRestrictedIndustriesWarning] = useState({
		isOpen: false,
		newStatus: '',
	});
	const openStepStatusDropdownId = useSelector(state => state.common.openStepStatusDropdownId);
	const isDropdownVisible = useMemo(
		() => openStepStatusDropdownId === step.id,
		[openStepStatusDropdownId, step.id],
	);

	const abortController = useAbortController();

	const { showAutomated, statuses, displayDropdown, triggerIconType } = useDropdownState(
		step,
		activityCanBeCompleted,
	);

	const closeDropdown = useCallback(() => {
		dispatch(updateOpenStepStatusDropdownId({ id: '' }));
	}, [dispatch]);

	const handleUpdateStatus = async (e, status, skipCalculationValidation) => {
		e && e.stopPropagation();
		closeDropdown();

		if (step.calculations.includes('TOTAL_OFFERS_SENT') && status !== 'OPEN' && !skipCalculationValidation) {
			const {
				data: {
					client: {
						industry: { restricted: isClientWithRestrictedIndustry },
					},
				},
			} = await getProjectById(abortController.signal, projectId, true);

			if (isClientWithRestrictedIndustry) {
				setRestrictedIndustriesWarning({ isOpen: true, newStatus: status });
				return;
			}
		}

		if (step.status !== status || step.isRemoving) {
			setIsLoading(true);
			try {
				await patchStep(abortController.signal, step.id, status);
				await onStatusUpdate(status);
				setIsLoading(false);
				return status;
			} catch (error) {
				showToastError(error, t('An error occured while updating step status'));
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		}
	};

	const handleToggleStatusDropdown = e => {
		if (showAutomated) {
			e.stopPropagation();

			if (!isProjectOnHold && statuses.length === 1 && statuses[0].value === 'SKIPPED') {
				dispatch(updateOpenStepStatusDropdownId({ id: step.id }));
				return;
			}
		}

		if (statuses.length === 1 && statuses[0].value === 'COMPLETED') {
			e.stopPropagation();
			handleUpdateStatus(e, statuses[0].value);
		} else if (displayDropdown) {
			e.stopPropagation();
			dispatch(updateOpenStepStatusDropdownId({ id: step.id }));
		}
	};

	const handleOnIndustryModalCancel = () => {
		setRestrictedIndustriesWarning({ isOpen: false });
	};

	const [isSavingIndustryModal, setIsSavingIndustryModal] = useState(false);
	const handleOnIndustryModalConfirm = async () => {
		try {
			setIsSavingIndustryModal(true);
			await handleUpdateStatus(undefined, restrictedIndustriesWarning.newStatus, true);
			setRestrictedIndustriesWarning({ isOpen: false });
			setIsSavingIndustryModal(false);
		} catch (e) {
			showToastError(e, t("Can't update step status"));
		}
	};

	useEffect(() => {
		return () => {
			closeDropdown();
		};
	}, [closeDropdown]);

	useEffect(() => {
		if (step.isRemoving) {
			setIsLoading(false);
		}
	}, [step.isRemoving]);

	if (isProjectOnHold) {
		return (
			<StatusDropdownContainer>
				<HoverTooltip title={"Project's status must be In progress or In operation before continuing"}>
					<StatusDropdownTrigger>
						<StatusIcon
							id={`stepStatus-${step.title}`}
							data-step-status={step.status}
							color={colors.common.grey}
						></StatusIcon>
					</StatusDropdownTrigger>
				</HoverTooltip>
			</StatusDropdownContainer>
		);
	}

	return displayDropdown ? (
		isLoading || isSoftLoading ? (
			<LoaderContainer $maxWidth={step.hideResponsiblePerson && !step.responsiblePerson ? '80px' : '30px'}>
				<Loader />
			</LoaderContainer>
		) : (
			<>
				<StatusDropdownContainer>
					<StatusDropdownTrigger onClick={handleToggleStatusDropdown}>
						{showAutomated && triggerIconType !== 'redo' ? (
							<AutomatedSkippedContainer title={'The step will automatically complete when the criteria is met'}>
								<Icon id={`stepStatus-${step.title}`} icon="doubleArrow" color={colors.grey.main} size="largest" />
							</AutomatedSkippedContainer>
						) : (
							<StatusIcon id={`stepStatus-${step.title}`} data-step-status={step.status}>
								{triggerIconType && <Icon icon={triggerIconType} />}
							</StatusIcon>
						)}
					</StatusDropdownTrigger>
					<ClickAwayListener onClickAway={closeDropdown}>
						<StatusDropdownWrapper isVisible={isDropdownVisible} key={step.id}>
							{statuses.map(status => (
								<StatusOption
									data-action={`${status.label}-${step.title}`}
									key={`dropdown-status-option-${status.label}`}
									onClick={e => handleUpdateStatus(e, status.value)}
								>
									<StatusOptionIcon>{status.icon && <Icon icon={status.icon} />}</StatusOptionIcon>
									{status.label}
								</StatusOption>
							))}
						</StatusDropdownWrapper>
					</ClickAwayListener>
				</StatusDropdownContainer>
				{restrictedIndustriesWarning.isOpen && (
					<RestrictedIndustriesModalWrapper onClick={e => e.stopPropagation()}>
						<RestrictedIndustriesWarningModal
							projectId={projectId}
							isConfirming={isSavingIndustryModal}
							isOpen={restrictedIndustriesWarning.isOpen}
							onCancel={handleOnIndustryModalCancel}
							onConfirm={handleOnIndustryModalConfirm}
						/>
					</RestrictedIndustriesModalWrapper>
				)}
			</>
		)
	) : null;
};

StatusDropdown.defaultProps = {
	step: {},
	isSoftLoading: false,
	isProjectOnHold: false,
	activityCanBeCompleted: true,
	onStatusUpdate: async () => {},
};

StatusDropdown.propTypes = {
	step: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		calculations: PropTypes.arrayOf(PropTypes.string),
		status: PropTypes.string,
		isRemoving: PropTypes.bool,
		title: PropTypes.string,
		hideResponsiblePerson: PropTypes.bool,
		responsiblePerson: PropTypes.shape({}),
	}),
	isSoftLoading: PropTypes.bool,
	onStatusUpdate: PropTypes.func,
	isProjectOnHold: PropTypes.bool,
	activityCanBeCompleted: PropTypes.bool,
	projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default StatusDropdown;
