import { useMemo } from 'react';

const fallbackValues = {
	primaryManager: null,
	secondaryManager: null,
};

const useAccountManagersFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			primaryManager: data?.primaryManager
				? {
						...data?.primaryManager,
						label: data?.primaryManager?.fullName,
						value: data?.primaryManager?.email,
						imgUrl: data?.primaryManager?.profilePhoto?.url,
				  }
				: null,
			secondaryManager: data?.secondaryManager
				? {
						...data?.secondaryManager,
						label: data?.secondaryManager?.fullName,
						value: data?.secondaryManager?.email,
						imgUrl: data?.secondaryManager?.profilePhoto?.url,
				  }
				: null,
		}),
		[data],
	);

export default useAccountManagersFormInitialValues;
