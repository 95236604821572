import { useCallback } from 'react';
import sizes from 'Application/theme/sizes';
import InvoiceList from 'Invoice/components/List/InvoiceList';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import SettingsTile from './Tiles/SettingsTile/SettingsTile';
import { Grid } from '@mui/material';
import { PROJECT_DETAILS_PAGE_TAB_TILES } from 'Projects/constants/projectTabForms';

const Wrapper = styled.div`
	padding: ${sizes.spacing(2)};
`;

const InvoiceTab = ({ project, handleUpdateProject, onExitEditMode, onEnterEditMode, onDirtyForm }) => {
	const handleSettingsDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.INVOICE.SETTINGS, isDirty);
		},
		[onDirtyForm],
	);
	return (
		<Wrapper>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={10} md={6} lg={4} xl={3}>
					<SettingsTile
						project={project}
						onDataChange={handleUpdateProject}
						onEnterEditMode={onEnterEditMode}
						onDirtyForm={handleSettingsDirtyForm}
						onExitEditMode={onExitEditMode}
					/>
				</Grid>
				<Grid item xs={12}>
					<InvoiceList project={project} onDataChange={handleUpdateProject} />
				</Grid>
			</Grid>
		</Wrapper>
	);
};

InvoiceTab.defaultProps = {
	onEnterEditMode: undefined,
	project: {},
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	handleUpdateProject: async () => {},
};

InvoiceTab.propTypes = {
	project: PropTypes.shape({
		invoices: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	onDirtyForm: PropTypes.func,
	onExitEditMode: PropTypes.func,
	handleUpdateProject: PropTypes.func.isRequired,
	onEnterEditMode: PropTypes.func,
};

export default InvoiceTab;
