const PARTNER_DETAILS_PAGE_TAB_TILES = {
	GENERAL: {
		DETAILS: 'details',
		ADDRESS: 'address',
	},
	CONTACTS: {
		PRIMARY_CONTACTS: 'primaryContacts',
	},
};

const PARTNER_DETAILS_PAGE_TILES_BY_TAB = {
	GENERAL: Object.values(PARTNER_DETAILS_PAGE_TAB_TILES.GENERAL),
	CONTACTS: Object.values(PARTNER_DETAILS_PAGE_TAB_TILES.CONTACTS),
};

export { PARTNER_DETAILS_PAGE_TAB_TILES, PARTNER_DETAILS_PAGE_TILES_BY_TAB };
