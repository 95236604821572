import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import colors from 'Application/theme/colors';
import useBodyClass from 'Common/hooks/useBodyClass';
import H5 from 'Common/components/headers/H5';
import Modal from 'Common/components/modals/Modal';
import ReLoginPopupForm from 'User/components/ReLoginPopup/ReLoginPopupForm/ReLoginPopupForm';

const StyledH5 = styled(H5)`
	display: flex;
	text-transform: uppercase;
	margin-bottom: 8px;
	font-weight: 700;
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const Line = styled.div`
	height: 1px;
	width: 32px;
	margin-bottom: 32px;
	background: ${colors.secondary.dark};
`;

const ReLoginPopup = () => {
	const { t } = useTranslation();

	const isInterrupted = useSelector(state => state.user.isInterrupted);
	const email = useSelector(state => state.user.email);

	const hasEmail = !!email;

	useBodyClass(isInterrupted ? 'is-blurred' : null);

	return (
		<Modal
			label="session-expired"
			padding="32px"
			maxWidth="480px"
			isOpen={isInterrupted && hasEmail}
			$hasEcoligoBackground
		>
			<TitleWrapper>
				<StyledH5>{t('Session expired')}</StyledH5>
				<Line />
			</TitleWrapper>
			<ReLoginPopupForm />
		</Modal>
	);
};

export default ReLoginPopup;
