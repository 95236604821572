import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import Icon from 'Common/components/icons/Icon';
import colors from 'Application/theme/colors';
import { PropTypes } from 'prop-types';

const Wrapper = styled.div`
	max-width: 220px;
`;

const ImageDescription = styled.p`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 15px;
	font-weight: 600;
	margin-right: 10px;
`;

const ImageTitle = styled.p`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 15px;
	margin-right: 10px;
`;

const ImageWrapper = styled.div`
	position: relative;
	width: 200px;
	height: 200px;
	margin-right: 20px;
	border-radius: 10px;
	overflow: hidden;
	cursor: pointer;

	${({ isSelected }) =>
		isSelected &&
		css`
			border: 4px solid ${colors.primary.main};
		`};
`;

const Image = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	pointer-events: none;

	${({ isSelected }) =>
		isSelected &&
		css`
			top: -4px;
			left: -4px;
			width: calc(100% + 8px);
			height: calc(100% + 8px);
		`};
`;

const IndicatorWrapper = styled.div`
	position: absolute;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	top: 0;
	width: 100%;
	height: 30px;
	background: linear-gradient(180deg, rgba(54, 55, 55, 0.5) 0%, rgba(54, 55, 55, 0.0001) 100%);
	z-index: 1;
`;

const SelectIndicator = styled.div`
	width: 16px;
	height: 16px;
	border-radius: 25px;
	border: 2px solid ${colors.grey.main};
	margin-right: 7px;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0.75;

	&:hover {
		opacity: 1;
	}

	${({ showIndicator }) =>
		showIndicator &&
		css`
			opacity: 1;
		`};

	${({ isSelected }) =>
		isSelected &&
		css`
			border: 2px solid ${colors.primary.main};
			background: ${colors.primary.main};
			color: ${colors.grey.main};

			svg {
				font-size: 15px;
			}
		`};
`;

const Thumbnail = ({ image, isSelected, setSelected, forceShowIndicator, onImageClick, isSelectable }) => {
	const [showIndicator, setShowIndicator] = useState(false);
	const [isHovered, setIsHovered] = useState(false);

	useEffect(() => {
		if (!isHovered) {
			setShowIndicator(forceShowIndicator);
		}
	}, [forceShowIndicator, isHovered]);

	const handleMouseEnter = () => {
		setIsHovered(true);
		if (!forceShowIndicator) {
			setShowIndicator(true);
		}
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
		if (!forceShowIndicator) {
			setShowIndicator(false);
		}
	};

	const handleImageClick = (e, image) => {
		e.stopPropagation();
		setSelected(image);
	};

	return (
		<Wrapper>
			<ImageWrapper
				isSelected={isSelected}
				onMouseEnter={() => handleMouseEnter()}
				onMouseLeave={() => handleMouseLeave()}
				onClick={e => (forceShowIndicator ? handleImageClick(e, image.id.name) : onImageClick(image))}
			>
				<Image
					isSelected={isSelected}
					style={{
						backgroundImage: `url("${image.thumbnailUrl}")`,
					}}
				/>
				{((isSelectable && showIndicator) || isSelected) && (
					<IndicatorWrapper>
						<SelectIndicator
							isSelected={isSelected}
							onClick={e => handleImageClick(e, image.id.name)}
							showIndicator={forceShowIndicator}
						>
							{isSelected && <Icon icon="checkRounded" />}
						</SelectIndicator>
					</IndicatorWrapper>
				)}
			</ImageWrapper>
			<ImageTitle>{image.id.name}</ImageTitle>
			<ImageDescription>{image.description}</ImageDescription>
		</Wrapper>
	);
};

Thumbnail.defaultProps = {
	isSelectable: true,
};

Thumbnail.propTypes = {
	image: PropTypes.shape({
		id: PropTypes.shape({ name: PropTypes.string }),
		thumbnailUrl: PropTypes.string,
		description: PropTypes.string,
	}).isRequired,
	isSelected: PropTypes.bool.isRequired,
	setSelected: PropTypes.func.isRequired,
	forceShowIndicator: PropTypes.bool.isRequired,
	onImageClick: PropTypes.func.isRequired,
	isSelectable: PropTypes.bool,
};

export default Thumbnail;
