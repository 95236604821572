import api from 'Application/api/api';
import formatDateAsISO from 'Common/utils/formatDateAsISO';

const getProjectMeteocontrolData = async (signal, projectId, systemKeys = [], datesRange) => {
	const response = await api.get(`/projects/${projectId}/meteocontrol`, {
		params: {
			systemKey: systemKeys,
			createdAtFrom: formatDateAsISO(datesRange.startDate),
			createdAtTo: formatDateAsISO(datesRange.endDate),
		},
		signal,
	});

	return response;
};

export default getProjectMeteocontrolData;
