import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import axios from 'axios';
import { ModalFormButtons } from 'Common/components/form/ModalForm';
import sizes from 'Application/theme/sizes';
import useExchangeRateFormInitialValues from './hooks/useExchangeRateFormInitialValues';
import useExchangeRateFormValidationScheme from './hooks/useExchangeRateFormValidationScheme';
import createCountryExchangeRate from 'Client/api/createCountryExchangeRate';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const FieldsWrapper = styled.div`
	width: 100%;
	padding: ${sizes.spacing(0.5)} 0 ${sizes.spacing(0.5)} 0;
`;

const CountryExchangeRateForm = ({ countryIsoCode, onSubmit, onCancel }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const initialValues = useExchangeRateFormInitialValues();
	const validationSchema = useExchangeRateFormValidationScheme();

	const { errors, touched, values, handleSubmit, handleChange, handleBlur, setFieldError, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: async values => {
				try {
					setIsLoading(true);
					await createCountryExchangeRate({
						isoCode: countryIsoCode,
						rate: values.rate,
					});
					notify(t('Exchange Rate updated successfully'), {
						type: toast.TYPE.SUCCESS,
					});
					onSubmit();
					resetForm({ values });
					setIsLoading(false);
				} catch (error) {
					showAxiosResponseError({
						error,
						setFormikFieldError: setFieldError,
					});
					if (!axios.isCancel(error)) {
						setIsLoading(false);
					}
				}
			},
		});

	return (
		<Form onSubmit={handleSubmit}>
			<FieldsWrapper>
				<FormField>
					<FormikInput
						id="rate"
						name="rate"
						label={t('Rate')}
						value={values.rate}
						error={errors.rate}
						touched={touched.rate}
						onChange={handleChange}
						onBlur={handleBlur}
						isRequired
					/>
				</FormField>
			</FieldsWrapper>
			<ModalFormButtons onCancel={onCancel} isLoading={isLoading} submitButtonText={'Create'} />
		</Form>
	);
};

CountryExchangeRateForm.defaultProps = {
	countryIsoCode: undefined,
	onDirty: () => {},
	onCancel: () => {},
	onSubmit: () => {},
};

CountryExchangeRateForm.propTypes = {
	countryIsoCode: PropTypes.number,
	onCancel: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default CountryExchangeRateForm;
