import getCurrencies from 'Country/api/getCurrencies';

const loadCurrenciesOptions = async (signal, query, withEmpty = false) => {
	const response = await getCurrencies(signal, query ? query.toLowerCase() : '');
	const currencies = response.data;

	const options = currencies.map(currency => ({
		value: currency.split(' ')[0],
		label: currency,
	}));

	if (withEmpty) {
		return [
			{
				value: '',
				label: '',
			},
			...options,
		];
	}

	return options;
};

export default loadCurrenciesOptions;
