import Grid from '@mui/material/Grid';
import { useEffect, useState, useMemo } from 'react';

import InfoTile from 'Impact/tiles/ImpactInfoTile';
import ImpactChart from 'Impact/components/ImpactChart';
import { graphOptions, chartTypes } from 'Impact/constants/options';
import { rowLabels, columnLabels, cells } from 'Projects/constants/tableFields';
import GraphLegend from 'Impact/components/GraphLegend';
import TileHeader from 'Common/components/Tile/TileHeader';
import ProjectImpactTable from 'Projects/components/ProjectList/components/ProjectImpactTable';

import useProjectImpactInitialValues from 'Projects/components/ProjectList/hooks/useProjectImpactInitialValues';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import useReponsive from 'Common/hooks/useResponsive';
import { useTranslation } from 'react-i18next';

import colors from 'Application/theme/colors';
import styled from 'styled-components/macro';
import sizes from 'Application/theme/sizes';

import getOlapStatistics from 'Projects/api/getOlapStatistics';
import showToastError from 'Common/utils/showToastError';
import getCustomFilters from 'Projects/utils/getCustomFilters';
import useAbortController from 'Common/hooks/useAbortController';

const StyledGrid = styled(Grid)`
	height: ${props => props.height || '100%;'} !important;
`;

const TileContainer = styled(Grid)`
	width: 100%;
	height: 100%;
	display: flex;
	background-color: white;
	border-radius: 20px;
	border: 1px solid transparent;
	&:hover {
		border-color: ${colors.primary.main};
	}
`;

const TitleHeaderWrapper = styled.div`
	padding: 16px;
`;

const ChartWrapper = styled.div`
	width: 100px;
	height: 100px;
	margin-right: 15px;
`;

const BarChartWrapper = styled.div`
	width: 100%;
	padding: 0 24px 0 8px;
`;

const LegendTooltip = styled.div`
	width: 95%;
	height: 100%;
	display: flex;
	padding-top: ${sizes.spacing(1.2)};
	justify-content: center;
`;

const TooltipText = styled.div`
	width: 190px;
	padding-left: ${sizes.spacing(0.5)};
	font-size: 12px;
	color: ${colors.text.greyLight};
`;

const ProjectImpact = () => {
	const { t } = useTranslation();
	const { isMobile, isTablet } = useReponsive();
	const filters = useQueryParameter('filters');

	const initialValues = useProjectImpactInitialValues();
	const [statistics, setStatistics] = useState(initialValues);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				const customFilters = getCustomFilters(filters);
				const response = await getOlapStatistics(abortController.signal, customFilters, {});
				setStatistics(response.data);
			} catch (e) {
				showToastError(e);
			}
		})();
	}, [filters, abortController.signal]);

	const salesChartData = useMemo(
		() => Object.assign({}, ...statistics.salesChannelBarChart.map(e => ({ [e.name]: e.count }))),
		[statistics],
	);

	return (
		<>
			{isMobile || isTablet ? (
				<>
					<Grid container spacing={2}>
						<StyledGrid item xs={12}>
							<ProjectImpactTable
								data={statistics?.projectStatistics}
								rowLabels={rowLabels}
								columnLabels={columnLabels}
								cells={cells}
							/>
						</StyledGrid>
						<StyledGrid item xs={12}>
							<InfoTile
								title={'mwp of projects'}
								gradient={colors.gradients.radialDark}
								data={[
									{
										value: statistics.sumTotalSigned ? (statistics.sumTotalSigned / 1000).toFixed(1) : 0,
										unit: 'MWp',
										text: 'signed',
										icon: 'signed',
									},
									{
										value: statistics.sumTotalInOperation ? (statistics.sumTotalInOperation / 1000).toFixed(1) : 0,
										unit: 'MWp',
										text: 'in operation',
										icon: 'inOperation',
									},
								]}
								$isLightbox
							/>
						</StyledGrid>

						<StyledGrid item xs={12}>
							<InfoTile
								title={'# of projects'}
								gradient={colors.gradients.radialLight}
								data={[
									{
										value: statistics.countTotalSigned,
										text: 'signed',
										icon: 'signed',
									},
									{
										value: statistics.countTotalInOperation,
										text: 'in operation',
										icon: 'leaf',
									},
								]}
								$isLightbox
							/>
						</StyledGrid>
						<StyledGrid item xs={12}>
							<InfoTile
								title={'# of clients'}
								gradient={colors.gradients.radialLight}
								data={[
									{
										value: statistics.clientCount,
										text: 'signed',
										icon: 'signed',
									},
									{
										value: statistics.clientCountInOperation,
										text: 'in operation',
										icon: 'leaf',
									},
								]}
								$isLightbox
							/>
						</StyledGrid>
						<StyledGrid item xs={12}>
							<InfoTile
								title={'# of employees impacted'}
								gradient={colors.gradients.radialDark}
								data={[
									{
										value: statistics.numberOfEmployees,
										text: 'impacted',
										icon: 'employees',
									},
									{
										value: statistics.femaleShare,
										text: t('impacted'),
										icon: 'female',
									},
								]}
								$isLightbox
							/>
						</StyledGrid>
						<StyledGrid item xs={12}>
							<InfoTile
								title={'volume invested'}
								gradient={colors.gradients.radialDark}
								data={[
									{
										value: statistics.volumeInvested,
										text: 'invested',
										unit: 'EUR',
										icon: 'invested',
									},
								]}
								$isLightbox
							/>
						</StyledGrid>
						<StyledGrid item xs={12}>
							<InfoTile
								title={'average epc price'}
								gradient={colors.gradients.radialLight}
								data={[
									{
										value: +statistics?.averageEpcPrice.toFixed(0),
										unit: 'EUR',
										icon: 'invested',
									},
								]}
								$isLightbox
							/>
						</StyledGrid>
						<StyledGrid item xs={12} height={'260px;'}>
							<TileContainer container xs={12}>
								<Grid item xs={12}>
									<TitleHeaderWrapper>
										<TileHeader title={'status'} />
									</TitleHeaderWrapper>
								</Grid>
								<Grid container xs={5} justifyContent="flex-end" alignItems="flex-start">
									<ChartWrapper>
										<ImpactChart
											data={{
												labels: ['Not Signed', 'Signed', 'Funded', 'In Operation'],
												datasets: [
													statistics.statusChartNotSigned,
													statistics.statusChartSigned,
													statistics.statusChartFunded,
													statistics.statusChartInOperation,
												],
												colors: [colors.common.darkGreen, colors.primary.dark, colors.primary.main, colors.primary.light],
											}}
											type={chartTypes.PIE}
											options={graphOptions[chartTypes.PIE]}
										/>
									</ChartWrapper>
								</Grid>
								<StyledGrid container xs={7} justifyContent="flex-start" alignItems="flex-start">
									<GraphLegend
										data={{
											labels: ['Not Signed', 'Signed', 'Funded', 'In Operation'],
											datasets: [
												statistics.statusChartNotSigned,
												statistics.statusChartSigned,
												statistics.statusChartFunded,
												statistics.statusChartInOperation,
											],
											colors: [colors.common.darkGreen, colors.primary.dark, colors.primary.main, colors.primary.light],
										}}
									/>
								</StyledGrid>
							</TileContainer>
						</StyledGrid>
						<StyledGrid item xs={12} height={'260px;'}>
							<TileContainer container xs={12}>
								<Grid item xs={12}>
									<TitleHeaderWrapper>
										<TileHeader title={'companies'} />
									</TitleHeaderWrapper>
								</Grid>
								<Grid container xs={5} justifyContent="flex-end" alignItems="flex-start">
									<ChartWrapper>
										<ImpactChart
											data={{
												labels: ['Large', 'Medium', 'Small'],
												datasets: [
													statistics.companiesChartLarge,
													statistics.companiesChartMedium,
													statistics.companiesChartSmall,
												],
												colors: [colors.primary.dark, colors.primary.main, colors.primary.light],
											}}
											type={chartTypes.PIE}
											options={graphOptions[chartTypes.PIE]}
										/>
									</ChartWrapper>
								</Grid>
								<StyledGrid container xs={7} justifyContent="flex-start" alignItems="flex-start">
									<GraphLegend
										data={{
											labels: ['Large', 'Medium', 'Small'],
											datasets: [
												statistics.companiesChartLarge,
												statistics.companiesChartMedium,
												statistics.companiesChartSmall,
											],
											colors: [colors.primary.dark, colors.primary.main, colors.primary.light],
										}}
									/>
								</StyledGrid>
							</TileContainer>
						</StyledGrid>
						<StyledGrid item xs={12} height={'260px;'}>
							<TileContainer container xs={12}>
								<Grid item xs={12}>
									<TitleHeaderWrapper>
										<TileHeader title={'industries'} />
									</TitleHeaderWrapper>
								</Grid>
								<Grid container xs={5} justifyContent="flex-end" alignItems="flex-start">
									<ChartWrapper>
										<ImpactChart
											data={{
												labels: ['Not Restricted', 'Restricted'],
												datasets: [
													+(statistics?.industriesChartNotRestricted / 1000).toFixed(0),
													+(statistics.industriesChartRestricted / 1000).toFixed(0),
												],
												colors: [colors.primary.dark, colors.primary.main],
											}}
											type={chartTypes.PIE}
											options={graphOptions[chartTypes.PIE]}
										/>
									</ChartWrapper>
								</Grid>
								<StyledGrid container xs={7} justifyContent="flex-start" alignItems="flex-start">
									<GraphLegend
										data={{
											labels: ['Not Restricted', 'Restricted'],
											datasets: [
												+(statistics?.industriesChartNotRestricted / 1000).toFixed(0),
												+(statistics.industriesChartRestricted / 1000).toFixed(0),
											],
											colors: [colors.primary.dark, colors.primary.main],
										}}
									/>
									<LegendTooltip>
										<TooltipText>{t('Based on System size [MWp]')}</TooltipText>
									</LegendTooltip>
								</StyledGrid>
							</TileContainer>
						</StyledGrid>
						<StyledGrid item xs={12}>
							<TileContainer container xs={12}>
								<Grid item xs={12}>
									<TitleHeaderWrapper>
										<TileHeader title={'sales channels'} />
									</TitleHeaderWrapper>
								</Grid>
								<Grid container xs={12}>
									<ImpactChart data={salesChartData} type={chartTypes.BAR} options={graphOptions[chartTypes.BAR]} />
								</Grid>
							</TileContainer>
						</StyledGrid>
					</Grid>
				</>
			) : (
				<Grid container spacing={2}>
					<StyledGrid item xs={12}>
						<ProjectImpactTable
							data={statistics?.projectStatistics}
							rowLabels={rowLabels}
							columnLabels={columnLabels}
							cells={cells}
						/>
					</StyledGrid>
					<StyledGrid item xs={12}>
						<StyledGrid container spacing={2} xs={12}>
							<StyledGrid item xs={4}>
								<InfoTile
									title={'mwp of projects'}
									gradient={colors.gradients.radialDark}
									data={[
										{
											value: statistics.sumTotalSigned ? (statistics.sumTotalSigned / 1000).toFixed(1) : 0,
											unit: 'MWp',
											text: 'signed',
											icon: 'signed',
										},
										{
											value: statistics.sumTotalInOperation ? (statistics.sumTotalInOperation / 1000).toFixed(1) : 0,
											unit: 'MWp',
											text: 'in operation',
											icon: 'inOperation',
										},
									]}
									$isLightbox
								/>
							</StyledGrid>
							<StyledGrid item xs={4}>
								<InfoTile
									title={'# of projects'}
									gradient={colors.gradients.radialLight}
									data={[
										{
											value: statistics.countTotalSigned,
											text: 'signed',
											icon: 'signed',
										},
										{
											value: statistics.countTotalInOperation,
											text: 'in operation',
											icon: 'leaf',
										},
									]}
									$isLightbox
								/>
							</StyledGrid>
							<StyledGrid item xs={4}>
								<InfoTile
									title={'# of clients'}
									gradient={colors.gradients.radialLight}
									data={[
										{
											value: statistics.clientCount,
											text: 'signed',
											icon: 'signed',
										},
										{
											value: statistics.clientCountInOperation,
											text: 'in operation',
											icon: 'leaf',
										},
									]}
									$isLightbox
								/>
							</StyledGrid>
						</StyledGrid>
					</StyledGrid>
					<StyledGrid item xs={12}>
						<StyledGrid container spacing={2} xs={12}>
							<StyledGrid item xs={4}>
								<InfoTile
									title={'# of employees impacted'}
									gradient={colors.gradients.radialDark}
									data={[
										{
											value: statistics.numberOfEmployees,
											text: 'impacted',
											icon: 'employees',
										},
										{
											value: statistics.femaleShare,
											text: t('impacted'),
											icon: 'female',
										},
									]}
									$isLightbox
								/>
							</StyledGrid>
							<StyledGrid item xs={4}>
								<InfoTile
									title={'volume invested'}
									gradient={colors.gradients.radialDark}
									data={[
										{
											value: statistics.volumeInvested,
											text: 'invested',
											unit: 'EUR',
											icon: 'invested',
										},
									]}
									$isLightbox
								/>
							</StyledGrid>
							<StyledGrid item xs={4}>
								<InfoTile
									title={'average epc price'}
									gradient={colors.gradients.radialLight}
									data={[
										{
											value: +statistics?.averageEpcPrice.toFixed(0),
											unit: 'EUR',
											icon: 'invested',
										},
									]}
									$isLightbox
								/>
							</StyledGrid>
						</StyledGrid>
					</StyledGrid>
					<StyledGrid item xs={12}>
						<StyledGrid container spacing={2} xs={12}>
							<StyledGrid item xs={4} height={'260px;'}>
								<TileContainer container xs={12}>
									<Grid item xs={12}>
										<TitleHeaderWrapper>
											<TileHeader title={'status'} />
										</TitleHeaderWrapper>
									</Grid>
									<Grid container xs={5} justifyContent="flex-end" alignItems="flex-start">
										<ChartWrapper>
											<ImpactChart
												data={{
													labels: ['Not Signed', 'Signed', 'Funded', 'In Operation'],
													datasets: [
														statistics.statusChartNotSigned,
														statistics.statusChartSigned,
														statistics.statusChartFunded,
														statistics.statusChartInOperation,
													],
													colors: [
														colors.common.darkGreen,
														colors.primary.dark,
														colors.primary.main,
														colors.primary.light,
													],
												}}
												type={chartTypes.PIE}
												options={graphOptions[chartTypes.PIE]}
											/>
										</ChartWrapper>
									</Grid>
									<StyledGrid container xs={7} justifyContent="flex-start" alignItems="flex-start">
										<GraphLegend
											data={{
												labels: ['Not Signed', 'Signed', 'Funded', 'In Operation'],
												datasets: [
													statistics.statusChartNotSigned,
													statistics.statusChartSigned,
													statistics.statusChartFunded,
													statistics.statusChartInOperation,
												],
												colors: [colors.common.darkGreen, colors.primary.dark, colors.primary.main, colors.primary.light],
											}}
										/>
									</StyledGrid>
								</TileContainer>
							</StyledGrid>
							<StyledGrid item xs={4} height={'260px;'}>
								<TileContainer container xs={12}>
									<Grid item xs={12}>
										<TitleHeaderWrapper>
											<TileHeader title={'companies'} />
										</TitleHeaderWrapper>
									</Grid>
									<Grid container xs={5} justifyContent="flex-end" alignItems="flex-start">
										<ChartWrapper>
											<ImpactChart
												data={{
													labels: ['Large', 'Medium', 'Small'],
													datasets: [
														statistics.companiesChartLarge,
														statistics.companiesChartMedium,
														statistics.companiesChartSmall,
													],
													colors: [colors.primary.dark, colors.primary.main, colors.primary.light],
												}}
												type={chartTypes.PIE}
												options={graphOptions[chartTypes.PIE]}
											/>
										</ChartWrapper>
									</Grid>
									<StyledGrid container xs={7} justifyContent="flex-start" alignItems="flex-start">
										<GraphLegend
											data={{
												labels: ['Large', 'Medium', 'Small'],
												datasets: [
													statistics.companiesChartLarge,
													statistics.companiesChartMedium,
													statistics.companiesChartSmall,
												],
												colors: [colors.primary.dark, colors.primary.main, colors.primary.light],
											}}
										/>
									</StyledGrid>
								</TileContainer>
							</StyledGrid>
							<StyledGrid item xs={4} height={'260px;'}>
								<TileContainer container xs={12}>
									<Grid item xs={12}>
										<TitleHeaderWrapper>
											<TileHeader title={'industries'} />
										</TitleHeaderWrapper>
									</Grid>
									<Grid container xs={5} justifyContent="flex-end" alignItems="flex-start">
										<ChartWrapper>
											<ImpactChart
												data={{
													labels: ['Not Restricted', 'Restricted'],
													datasets: [
														+(statistics?.industriesChartNotRestricted / 1000).toFixed(0),
														+(statistics.industriesChartRestricted / 1000).toFixed(0),
													],
													colors: [colors.primary.dark, colors.primary.main],
												}}
												type={chartTypes.PIE}
												options={graphOptions[chartTypes.PIE]}
											/>
										</ChartWrapper>
									</Grid>
									<StyledGrid item xs={7} justifyContent="flex-start" alignItems="flex-start">
										<GraphLegend
											data={{
												labels: ['Not Restricted', 'Restricted'],
												datasets: [
													+(statistics?.industriesChartNotRestricted / 1000).toFixed(0),
													+(statistics.industriesChartRestricted / 1000).toFixed(0),
												],
												colors: [colors.primary.dark, colors.primary.main],
											}}
										/>
										<LegendTooltip>
											<TooltipText>{t('Based on System size [MWp]')}</TooltipText>
										</LegendTooltip>
									</StyledGrid>
								</TileContainer>
							</StyledGrid>
						</StyledGrid>
					</StyledGrid>
					<StyledGrid item xs={12} height={'290px;'}>
						<StyledGrid container spacing={2} xs={12}>
							<StyledGrid item xs={12}>
								<TileContainer container xs={12}>
									<Grid item xs={12}>
										<TitleHeaderWrapper>
											<TileHeader title={'sales channels'} />
										</TitleHeaderWrapper>
									</Grid>
									<Grid container xs={12}>
										<BarChartWrapper>
											<ImpactChart data={salesChartData} type={chartTypes.BAR} options={graphOptions[chartTypes.BAR]} />
										</BarChartWrapper>
									</Grid>
								</TileContainer>
							</StyledGrid>
						</StyledGrid>
					</StyledGrid>
				</Grid>
			)}
		</>
	);
};

export default ProjectImpact;
