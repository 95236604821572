import api from 'Application/api/api';
import adaptProjectImageResponseData from 'Projects/api/adapters/adaptProjectImageResponseData';

const getProjectImages = async (signal, projectId) => {
	const response = await api.get(`/images/project/${projectId}`, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	response.data = response.data.map(image => adaptProjectImageResponseData(image));

	return response;
};

export default getProjectImages;
