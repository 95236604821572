import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useDirectorsTileFormValidationSchema = () => {
	const { t } = useTranslation();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(
		() =>
			yup.object({
				director1: yup
					.object()
					.nullable()
					.test('director', t('At least one director is required'), value => value?.value),
			}),
		[t],
	);
};

export default useDirectorsTileFormValidationSchema;
