import api from 'Application/api/api';

const getEmissionSPV = (signal, query) =>
	api.get('/emissionspv', {
		params: {
			query,
		},
		signal,
	});

export default getEmissionSPV;
