import Accordion from '@mui/material/Accordion';
import styled from 'styled-components/macro';

const StyledAccordion = styled(Accordion)`
	&.MuiAccordion-root {
		background: ${props => props.background};
		border: ${props => props.border};
	}

	&.MuiAccordion-rounded {
		border-radius: 20px;
		box-shadow: none;
		margin: 2px;
	}

	&.MuiAccordion-rounded:first-child {
		border-radius: 20px;
		box-shadow: none;
	}

	&.MuiAccordion-rounded:last-child {
		border-radius: 20px;
		box-shadow: none;
		margin: 2px;
	}
`;

export default StyledAccordion;
