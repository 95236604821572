const pluginName = 'useCustomRowStyle';

export const useCustomRowStyle = hooks => {
	hooks.getRowProps.push(getRowProps);
};

useCustomRowStyle.pluginName = pluginName;

const getRowProps = (props, { instance, row }) => [
	props,
	{
		style: {
			...instance.getCustomRowStyles(row),
		},
	},
];
