import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useIndustriesFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				name: yup.string().required(t('Name is Required')),
				marketSegment: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.nullable()
					.required(t('Required')),
			}),
		[t],
	);
};

export default useIndustriesFormValidationSchema;
