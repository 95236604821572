import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EditableTile from 'Common/components/Tile/EditableTile';
import ClientOfferList from './ClientOfferList';
import styled from 'styled-components/macro';
import { useEffect, useState } from 'react';
import colors from 'Application/theme/colors';
import { useMemo } from 'react';
import sizes from 'Application/theme/sizes';
import CollapseButton from 'Common/components/buttons/CollapseButton';

const ClientOfferActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${sizes.spacing(1)};
`;

const StyledSpan = styled.div`
	color: ${colors.common.black};
`;

const VisibleSectionsCount = styled.span`
	font-weight: 700;
	color: ${colors.secondary.dark};
`;

const TotalSectionCount = styled.span`
	color: ${colors.text.grey};
`;

const ClientOfferTile = ({ project, handleUpdateProject }) => {
	const { t } = useTranslation();
	const [isCollapsed, setIsCollapsed] = useState(true);

	const handleToggleCollapse = () => {
		setIsCollapsed(prev => !prev);
	};

	const totalClientOffersCount = useMemo(
		() => project.epcOffers?.reduce((res, epcOffer) => res + epcOffer?.clientOffers?.length || 0, 0),
		[project.epcOffers],
	);
	const showCollapseSection = useMemo(() => totalClientOffersCount > 1, [totalClientOffersCount]);

	useEffect(() => {
		if (!showCollapseSection) {
			setIsCollapsed(false);
		}
	}, [showCollapseSection]);

	const primaryClientOfferCount = useMemo(
		() =>
			project.epcOffers.some(epcOffer => epcOffer.clientOffers.some(clientOffer => clientOffer.primaryOffer))
				? 1
				: 0,
		[project.epcOffers],
	);

	return (
		<>
			<EditableTile
				title={t('Client Offers')}
				rightComponent={
					<ClientOfferActions>
						{showCollapseSection && (
							<>
								<StyledSpan>
									<VisibleSectionsCount>
										{isCollapsed ? primaryClientOfferCount : totalClientOffersCount}{' '}
									</VisibleSectionsCount>
									/<TotalSectionCount> {totalClientOffersCount}</TotalSectionCount>
								</StyledSpan>
								<CollapseButton isCollapsed={isCollapsed} onClick={handleToggleCollapse} />
							</>
						)}
					</ClientOfferActions>
				}
			>
				<ClientOfferList project={project} handleUpdateProject={handleUpdateProject} isCollapsed={isCollapsed} />
			</EditableTile>
		</>
	);
};

ClientOfferTile.defaultProps = {
	project: undefined,
	handleUpdateProject: async () => {},
};

ClientOfferTile.propTypes = {
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		projectType: PropTypes.shape({ name: PropTypes.string }),
		epcOffers: PropTypes.arrayOf(PropTypes.shape({})),
		systemSizeKwp: PropTypes.number,
		clientOfferGroups: PropTypes.arrayOf(PropTypes.shape({})),
		tender: PropTypes.shape({
			tenderCloseDate: PropTypes.string,
		}),
	}),
	handleUpdateProject: PropTypes.func,
};

export default ClientOfferTile;
