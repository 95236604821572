import usePrevious from 'Common/hooks/usePrevious';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import { PropTypes } from 'prop-types';

const RouterErrorBoundary = ({ children }) => {
	const [key, setKey] = useState(0);
	const { pathname } = useLocation();
	const previousPathname = usePrevious(pathname);
	const [trackPathChange, setTrackPathChange] = useState(false);

	useEffect(() => {
		if (trackPathChange && previousPathname !== pathname) {
			setKey(key => key + 1);
			setTrackPathChange(false);
		}
	}, [trackPathChange, previousPathname, pathname]);

	return (
		<ErrorBoundary key={key} setTrackPathChange={setTrackPathChange}>
			{children}
		</ErrorBoundary>
	);
};

RouterErrorBoundary.propTypes = {
	children: PropTypes.node.isRequired,
};

export default RouterErrorBoundary;
