import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import TranchesListTile from './Tiles/TranchesListTile/TranchesListTile';

const TranchesTab = ({ campaign, onDataChange }) => (
	<TilesGridWrapper>
		<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
			<TranchesListTile campaign={campaign} onDataChange={onDataChange} />
		</Grid>
	</TilesGridWrapper>
);

TranchesTab.defaultProps = {
	onDataChange: undefined,
};

TranchesTab.propTypes = {
	campaign: PropTypes.shape({}).isRequired,

	onDataChange: PropTypes.func.isRequired,
};

export default TranchesTab;
