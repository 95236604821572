import getEpcPartners from 'EpcPartner/api/getEpcPartners';

const loadEpcPartnerOptions = async (signal, query) => {
	const response = await getEpcPartners(
		signal,
		{
			page: 1,
			itemsPerPage: 9999,
		},
		{},
		query,
	);

	const epcPartners = response.data.content;

	return epcPartners
		.filter(epcPartner => epcPartner.name && epcPartner.name !== '-')
		.map(epcPartner => ({
			value: epcPartner.id,
			label: epcPartner.name,
			totalPoints: epcPartner.totalPoints,
			tier: epcPartner.tier,
		}));
};

export default loadEpcPartnerOptions;
