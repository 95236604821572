import { pick } from 'lodash';

const convertFormValuesToRequestData = values => ({
	...pick(values, [
		'description',
		'addressLine1',
		'addressLine2',
		'aparmentBuilding',
		'cityRegion',
		'postCode',
	]),
	name: values.name.trim(),
	partnerTypeId: values.partnerType ? values.partnerType.value : null,
	countryId: values.country ? values.country.value : null,
});

export default convertFormValuesToRequestData;
