import api from 'Application/api/api';

const assignEntityTaxAdvisor = async (signal, { contractPartyId, partnerId }) => {
	const response = await api.post(
		`/contract-parties/assign-tax-advisor-and-accountants/${contractPartyId}?partnerId=${partnerId}`,
		{
			signal,
			timeout: 100000,
		},
	);

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: response.data,
	};
};

export default assignEntityTaxAdvisor;
