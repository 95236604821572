import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import { createReduxHistoryContext } from 'redux-first-history';
import storage from 'redux-persist/lib/storage';
import history from 'Application/store/history';
import { configureStore, combineReducers } from '@reduxjs/toolkit';

import commonReducer from 'Application/reducers/reduxCommon';
import userReducer from 'User/reduxUser';
import tablesReducer from 'Application/reducers/reduxTables';
import tabsReducer from 'Application/reducers/reduxTabs';
import tipsReducer from 'Application/reducers/reduxTipModal';
import projectReducer from 'Projects/reduxProject';
import sidePanelReducer from 'Application/reducers/reduxSidePanel';
import notificationsReducer from 'Notifications/reduxNotifications';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['user', 'notifications', 'tables', 'tabs', 'common', 'tipModals', 'cleanUp', 'sidePanel'],
};
const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
	history,
});

const rootReducer = combineReducers({
	router: routerReducer,
	common: commonReducer,
	user: userReducer,
	tables: tablesReducer,
	tabs: tabsReducer,
	tipModals: tipsReducer,
	currentProject: projectReducer,
	sidePanel: sidePanelReducer,
	notifications: notificationsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const setupStore = () => {
	const store = configureStore({
		reducer: persistedReducer,
		middleware: getDefaultMiddleware =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoreState: true,
					ignoredActions: [
						'sidePanel/updateSidePanelContentData',
						'sidePanel/updateSidePanelHeaderData',
						'sidePanel/updateSidePanelData',
						'sidePanel/closeSidePanel',
						'common/showUnsavedChangesModal',
						FLUSH,
						REHYDRATE,
						PAUSE,
						PERSIST,
						PURGE,
						REGISTER,
					],
				},
			}).concat(routerMiddleware),
		devTools: process.env.NODE_ENV !== 'production',
	});
	const reduxHistory = createReduxHistory(store);

	const persistor = persistStore(store);

	return {
		store,
		persistor,
		history: reduxHistory,
	};
};

export default setupStore;
