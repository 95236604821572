import api from 'Application/api/api';

const getEpcPartnerInquires = (signal, configuration) => {
	const { sortBy = 'name', sortDirection = 'ASC', size = 9999 } = configuration;

	const params = {};

	if (size) {
		params.size = size;
	}

	if (sortBy && sortDirection) {
		params.sort = `${sortBy},${sortDirection}`;
	}

	return api.get('/inquiries/partner/all-inquiries', {
		params,
		signal,
	});
};

export default getEpcPartnerInquires;
