import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';

const ProgressContainer = styled.div`
	width: 105%;
	height: 100%;
	position: relative;
	background-color: ${colors.primary.light};
`;

const InnerProgress = styled.div`
	position: absolute;
	left: -5%;
	top: 0;
	bottom: 0;
	border-radius: 5px;
	transition: width linear 0.2s;
	width: ${({ $width }) => $width}%;
	background-color: ${colors.primary.main};
`;

const BaseProgressBar = ({ max, value }) => {
	return (
		<ProgressContainer>
			<InnerProgress $width={(value / max) * 100} />
		</ProgressContainer>
	);
};

BaseProgressBar.propTypes = {
	max: PropTypes.number,
	value: PropTypes.number.isRequired,
};

BaseProgressBar.defaultProps = {
	max: 100,
};

export default BaseProgressBar;
