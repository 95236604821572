import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import PropTypes from 'prop-types';

import { Popper, Grow, Paper as MaterialPaper, ClickAwayListener } from '@mui/material';
import StatusPill from 'Common/components/StatusPill';
import FormikDatePicker from 'Common/components/form/FormikDatePicker';

const Wrapper = styled.div`
	display: flex;
`;

const MonthButton = styled.div`
	cursor: pointer;
`;

const Paper = styled(MaterialPaper)`
	border-radius: 6px;
	overflow: hidden;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15) !important;
`;

const MonthSelector = ({ startDateName, endDateName, setFilterValue, startDate, endDate }) => {
	const { t } = useTranslation();
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = e => {
		if (anchorRef.current && anchorRef.current.contains(e.target)) {
			return;
		}
		setOpen(false);
	};

	const _setFilterValue = (name, value) => {
		const newDateValue = value ? new Date(value) : null;

		setFilterValue({
			[name]: newDateValue,
		});
	};

	const handleClear = () => {
		setFilterValue({
			[startDateName]: null,
			[endDateName]: null,
		});
	};

	return (
		<Wrapper>
			<MonthButton ref={anchorRef} onClick={handleToggle}>
				<StatusPill color={colors.primary.dark}>{t('Period')}</StatusPill>
			</MonthButton>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				placement="left-start"
				style={{
					zIndex: 9999,
				}}
			>
				{({ TransitionProps }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: 'right top',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<div>
									<FormikDatePicker
										startDateId={startDateName}
										endDateId={endDateName}
										startDateName={startDateName}
										endDateName={endDateName}
										startDateValue={startDate}
										endDateValue={endDate}
										setFieldValue={_setFilterValue}
										onClearAll={handleClear}
										isDual
									/>
								</div>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Wrapper>
	);
};

MonthSelector.defaultProps = {
	startDateName: 'startDate',
	endDateName: 'endDate',
	setFilterValue: () => {},
	startDate: null,
	endDate: null,
};

MonthSelector.propTypes = {
	startDateName: PropTypes.string,
	endDateName: PropTypes.string,
	setFilterValue: PropTypes.func,
	startDate: PropTypes.instanceOf(Date),
	endDate: PropTypes.instanceOf(Date),
};

export default MonthSelector;
