import Grid from '@mui/material/Grid';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import TagsList from './Tags/TagsList';
import MarketingTile from './Marketing/MarketingTile';

const MarketingTab = () => (
	<TilesGridWrapper>
		<Grid data-form="marketing" item xs={12} sm={12} md={12} lg={12} xl={12}>
			<MarketingTile />
		</Grid>
		<Grid data-form="tags" item xs={12} sm={12} md={12} lg={12} xl={12}>
			<TagsList />
		</Grid>
	</TilesGridWrapper>
);

export default MarketingTab;
