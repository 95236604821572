const FILTER_TYPES = {
	STEPS: 'steps',
	DATE: 'date',
	NUMBER: 'number',
	SELECT: 'select',
	ASYNC_SELECT: 'async-select',
	STAGE: 'stage',
	INPUT: 'input',
	MULTI_INPUT: 'multi-input',
	SWITCH: 'switch',
};

const FILTER_DEFAULTS = {
	[FILTER_TYPES.STEPS]: [],
	[FILTER_TYPES.DATE]: {
		startDateValue: undefined,
		endDateValue: undefined,
	},
	[FILTER_TYPES.NUMBER]: {
		minValue: '',
		maxValue: '',
	},
	[FILTER_TYPES.SELECT]: [],
	[FILTER_TYPES.ASYNC_SELECT]: [],
	[FILTER_TYPES.STAGE]: {
		stageStatus: '',
		minValue: 0,
		maxValue: 0,
	},
	[FILTER_TYPES.INPUT]: '',
	[FILTER_TYPES.MULTI_INPUT]: [],
	[FILTER_TYPES.SWITCH]: '',
};

// ? Private because it is used only in the internal filters hook
const _FILTER_QUERY_KEYS_SELECTOR_BY_TYPE = {
	[FILTER_TYPES.STEPS]: ['name'],
	[FILTER_TYPES.DATE]: ['startDateName', 'endDateName'],
	[FILTER_TYPES.NUMBER]: ['minName', 'maxName'],
	[FILTER_TYPES.SELECT]: ['name'],
	[FILTER_TYPES.ASYNC_SELECT]: ['name'],
	[FILTER_TYPES.STAGE]: ['stageStatus', 'minName', 'maxName'],
	[FILTER_TYPES.INPUT]: ['name'],
	[FILTER_TYPES.MULTI_INPUT]: ['name'],
	[FILTER_TYPES.SWITCH]: ['name'],
};

const FILTER_LIFECYCLE_HOOKS = {
	BEFORE_UPDATE_VALUE: 'beforeUpdateValue',
	BEFORE_UPDATE_QUERY: 'beforeUpdateQuery',
};

const FILTER_NAME_SUFFIXES = {
	STAGE_COMPLETED_PERCENTAGE_FROM: 'StageCompletedPercentageFrom',
	STAGE_COMPLETED_PERCENTAGE_TO: 'StageCompletedPercentageTo',
	STAGE_STATUS: 'StageStatus',
	RANGE_FROM: 'From',
	RANGE_TO: 'To',
	DATE_FROM: 'From',
	DATE_TO: 'To',
};

const FILTER_NAME_SUFFIXES_REGEX = new RegExp(`(${Object.values(FILTER_NAME_SUFFIXES).join('|')})$`);

export {
	FILTER_TYPES,
	FILTER_DEFAULTS,
	FILTER_LIFECYCLE_HOOKS,
	_FILTER_QUERY_KEYS_SELECTOR_BY_TYPE,
	FILTER_NAME_SUFFIXES,
	FILTER_NAME_SUFFIXES_REGEX,
};
