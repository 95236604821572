import './Overlay.css';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import Link from 'Common/components/Link';
import { css } from 'styled-components';
import Button from 'Common/components/buttons/Button';
import { useLocation } from 'react-router-dom';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import Loader from 'Common/components/Loader';
import { useEffect, useMemo, useRef, useState } from 'react';
import Modal from './Modal';
import useResponsive from 'Common/hooks/useResponsive';
import { createPortal } from 'react-dom';
import crudModes from 'Common/constants/crudModes';

const Wrapper = styled(Modal).attrs({
	modalClassName: 'Modal',
})`
	.ReactModal__Content {
		position: relative;
	}
`;

const OverlayContainer = styled.div`
	${({ $isOpen, $isLargeScreen, $isMobile }) => css`
		position: absolute;
		top: ${$isMobile ? '0 !important' : '60px'};
		width: 100%;
		height: ${$isMobile ? '100%' : 'calc(100% - 60px)'};
		background-color: ${colors.common.sidebarGrey};
		border-radius: 20px 20px 0 0;
		padding-top: ${sizes.spacing(4)};
		padding-bottom: ${$isLargeScreen ? sizes.spacing(12) : sizes.spacing(8)};
		display: flex;
		flex-direction: column;

		@keyframes slideUp {
			from {
				top: 100vh;
			}
			to {
				top: 60px;
			}
		}

		@keyframes slideDown {
			from {
				top: 60px;
			}
			to {
				top: 100vh;
			}
		}

		animation: ${$isOpen ? 'slideUp' : 'slideDown'} 0.4s ease-in-out forwards;
	`}
`;

const OverlayHeader = styled.div`
	padding-left: ${sizes.spacing(4)};
	padding-bottom: ${sizes.spacing(1)};
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
`;

const OverlayBreadcrumbsContainer = styled.div`
	position: relative;

	display: flex;
	flex-direction: row;
	gap: ${sizes.spacing(0.5)};
	padding-bottom: ${sizes.spacing(1)};

	&::before {
		content: '';
		position: absolute;

		left: 0;
		bottom: 0;

		width: 32px;
		height: 1px;
		background-color: ${colors.secondary.dark};
	}
`;

const OverlayBreadcrumb = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${sizes.spacing(0.5)};
	color: ${colors.text.grey};

	${({ $isLastElement }) =>
		$isLastElement &&
		css`
			color: ${colors.secondary.dark};
		`}

	${({ $isMobile }) =>
		$isMobile &&
		css`
			* {
				font-size: 14px;
			}
		`}
`;

const OverlayBreadcrumbLink = styled(Link)`
	color: inherit;
	font-weight: inherit;
	text-decoration: none;

	&:hover {
		color: ${colors.secondary.dark};
	}
`;

const OverlayBreadcrumbActiveElement = styled.div`
	font-weight: 700;
`;

const OverlayContent = styled.div`
	${({ $isMobile }) => css`
		padding-left: ${$isMobile ? 0 : sizes.spacing(2)};
		padding-right: ${$isMobile ? 0 : sizes.spacing(2)};
		padding-top: ${$isMobile ? 0 : sizes.spacing(2)};
		overflow: auto;
		flex: 1;
		overflow-x: hidden;
		overflow-y: overlay;
		align-items: baseline;
	`}
`;

const OverlayFooterWrapper = styled.div`
	display: flex;
	flex-direction: ${({ $isMobile }) => ($isMobile ? 'column' : 'row')};
	${({ $isLargeScreen }) => css`
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-bottom: ${$isLargeScreen ? sizes.spacing(4) : sizes.spacing(2)};
		margin-right: ${$isLargeScreen ? sizes.spacing(4) : sizes.spacing(2)};
		gap: ${sizes.spacing(2)};
		position: absolute;
		bottom: 0;
		right: 0;
		flex: 1;
		align-self: center;
		flex-grow: 1;
	`}
`;

const OverlayFooterButtons = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${sizes.spacing(2)};
`;

export const OverlayButtons = ({
	onCancel,
	onConfirm,
	submitButtonText,
	closeButtonText,
	noSubmitButton,
	label,
	isLoading,
	children,
	disableSubmit,
}) => {
	const { isBigScreen, isFullHd, is4k, isMobile } = useResponsive();

	const isLargeScreen = useMemo(() => isBigScreen || isFullHd || is4k, [isBigScreen, isFullHd, is4k]);

	return (
		<OverlayFooterWrapper $isLargeScreen={isLargeScreen} $isMobile={isMobile}>
			{children}
			<OverlayFooterButtons>
				{Boolean(onCancel) && (
					<Button
						text={closeButtonText}
						onClick={onCancel}
						type="button"
						secondary={!noSubmitButton}
						label={`${label} - Cancel Button`}
					/>
				)}
				{!noSubmitButton && (
					<Button
						overlay
						text={isMobile ? submitButtonText.split(' ')[0] : submitButtonText}
						type={disableSubmit ? 'button' : 'submit'}
						onClick={onConfirm}
						isLoading={isLoading}
						label={`${label} - ${submitButtonText} Button`}
					/>
				)}
			</OverlayFooterButtons>
		</OverlayFooterWrapper>
	);
};

OverlayButtons.defaultProps = {
	label: 'Overlay Buttons',
	onCancel: undefined,
	onConfirm: undefined,
	isLoading: false,
	submitButtonText: 'Submit',
	closeButtonText: 'Cancel',
	noSubmitButton: false,
	children: null,
	disableSubmit: false,
};

OverlayButtons.propTypes = {
	onCancel: PropTypes.func,
	onConfirm: PropTypes.func,
	label: PropTypes.string,
	isLoading: PropTypes.bool,
	submitButtonText: PropTypes.string,
	closeButtonText: PropTypes.string,
	noSubmitButton: PropTypes.bool,
	children: PropTypes.node,
	disableSubmit: PropTypes.bool,
};

const Breadcrumb = ({ item, index, arr, onClose }) => {
	const { label, href, forceRefresh } = item;
	const breadcrumbKey = `breadcrumb-${label + index}`;
	const isLastElement = index === arr.length - 1;
	const isFirstElement = index === 0;
	const { pathname } = useLocation();
	const { isMobile } = useResponsive();

	const handleBreadCrumbClick = () => {
		if (href === pathname && !forceRefresh) {
			onClose();
		}
	};

	return (
		<OverlayBreadcrumb key={breadcrumbKey} $isLastElement={isLastElement} $isMobile={isMobile}>
			{!isFirstElement && <span>/</span>}
			{isLastElement ? (
				<OverlayBreadcrumbActiveElement>{label}</OverlayBreadcrumbActiveElement>
			) : (
				<OverlayBreadcrumbLink to={href} reloadDocument={forceRefresh} onClick={() => handleBreadCrumbClick()}>
					{label}
				</OverlayBreadcrumbLink>
			)}
		</OverlayBreadcrumb>
	);
};

const HeaderContentPortalWrapper = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
`;

const Overlay = ({
	label,
	breadcrumbList,
	isOpen,
	onSave,
	onClose,
	children,
	headerContent,
	isLoadingContent,
}) => {
	const [isFormDirty, setIsFormDirty] = useState(false);
	const { isMobile, isBigScreen, isFullHd, is4k } = useResponsive();

	const isLargeScreen = useMemo(() => isBigScreen || isFullHd || is4k, [isBigScreen, isFullHd, is4k]);

	const onCancel = () => {
		if (isFormDirty) {
			const result = window.confirm('You have unsaved changes');
			if (result) {
				onClose();
			}
		} else {
			onClose();
		}
	};

	const handleSubmit = data => {
		onSave(data);
		onClose();
	};

	return (
		<Wrapper
			label={label}
			portalClassName="Overlay"
			onRequestClose={onClose}
			isOpen={isOpen}
			closeTimeoutMS={400}
			shouldCloseOnOverlayClick
		>
			<OverlayContainer $isOpen={isOpen} $isLargeScreen={isLargeScreen} $isMobile={isMobile}>
				<OverlayHeader>
					<OverlayBreadcrumbsContainer>
						{breadcrumbList.map((item, i) => (
							<Breadcrumb
								key={`breadcrumb-${item.label}`}
								item={item}
								index={i}
								onClose={onClose}
								arr={breadcrumbList}
							/>
						))}
					</OverlayBreadcrumbsContainer>
					<HeaderContentPortalWrapper id="header-content-portal">{headerContent}</HeaderContentPortalWrapper>
				</OverlayHeader>
				<OverlayContent $isMobile={isMobile}>
					{isLoadingContent ? (
						<LoaderWrapper>
							<Loader />
						</LoaderWrapper>
					) : (
						children(setIsFormDirty, handleSubmit, onCancel)
					)}
				</OverlayContent>
			</OverlayContainer>
		</Wrapper>
	);
};

export const HeaderContentPortal = props => {
	const el = useRef(document.createElement('div'));
	useEffect(() => {
		const portal = document.getElementById('header-content-portal');
		portal.appendChild(el.current);

		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			portal.removeChild(el.current);
		};
	}, [props.children]);

	return createPortal(props.children, el.current);
};

export const useOverlayTitles = (name, mode) => {
	const { isMobile } = useResponsive();
	return useMemo(() => {
		const overlayMode = mode === crudModes.CREATE ? 'Create' : mode === crudModes.VIEW ? 'View' : 'Edit';
		const saveButtonText = mode === crudModes.CREATE ? 'Create' : mode === crudModes.EDIT ? 'Save' : undefined;
		const cancelButtonText = mode === crudModes.VIEW ? 'Close' : undefined;

		return {
			name: `${overlayMode} ${name}`,
			label: `${name.split(' ').join('-')}-${overlayMode}-overlay`.toLocaleLowerCase(),
			saveBtnLabel: isMobile ? saveButtonText : saveButtonText && `${saveButtonText} ${name}`,
			closeBtnLabel: cancelButtonText,
		};
	}, [name, mode, isMobile]);
};

Overlay.defaultProps = {
	breadcrumbList: [],
	onSave: null,
	onClose: () => {},
	children: null,
	isOpen: true,
	headerContent: null,
	isLoadingContent: false,
	label: 'Overlay',
};

Overlay.propTypes = {
	breadcrumbList: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			href: PropTypes.string,
		}),
	),
	onSave: PropTypes.func,
	onClose: PropTypes.func,
	isOpen: PropTypes.bool,
	children: PropTypes.func,
	headerContent: PropTypes.node,
	isLoadingContent: PropTypes.bool,
	label: PropTypes.string,
};

Breadcrumb.defaultProps = {
	item: {},
	index: null,
	arr: [],
	onClose: () => {},
	forceRefresh: false,
};

Breadcrumb.propTypes = {
	item: PropTypes.shape({
		label: PropTypes.string,
		href: PropTypes.string,
		forceRefresh: PropTypes.bool,
	}),
	index: PropTypes.number,
	arr: PropTypes.arrayOf(PropTypes.shape({})),
	onClose: PropTypes.func,
	forceRefresh: PropTypes.bool,
};

export default Overlay;
