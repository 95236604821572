import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import crudModes from 'Common/constants/crudModes';
import TagForm from 'Preferences/components/Tabs/MarketingTab/Tags/TagForm/TagForm';
import getTagById from 'Preferences/api/getTagById';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PropTypes } from 'prop-types';

const TagsEditModal = ({ isOpen, onClose, tagId, onFormSubmit }) => {
	const { t } = useTranslation();

	const [tag, setTag] = useState(null);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				const response = await getTagById(abortController.signal, tagId);

				setTag(response.data);
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [tagId, abortController.signal]);

	const isDataLoading = tag === null;

	return (
		<ModalForm
			label="tag-edit"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			isLoadingContent={isDataLoading}
			heading={t('Edit Tag')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<TagForm
					data={tag}
					mode={crudModes.EDIT}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

TagsEditModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	tagId: PropTypes.number.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default TagsEditModal;
