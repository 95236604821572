import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';
import TileDynamicSection from 'Common/components/Tile/TileDynamicSection';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import useMeteocontrolFormInitialValues from './hooks/useMeteocontrolFormInitialValues';
import useMeteocontrolFormValidationSchema from './hooks/useMeteocontrolFormValidationSchema';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import getMeteocontrolSystems from 'Projects/api/getMeteocontrolSystems';
import editProject from 'Projects/api/editProject';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';

const Tags = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const Tag = styled.div`
	display: flex;
	align-items: center;
	margin: 2px 5px 2px 0;
	padding: 3px 10px;
	color: ${colors.text.white};
	background: ${colors.primary.dark};
	border-radius: 25px;

	span {
		overflow: hidden;
		padding: 3px;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: 400;
		font-size: 15px;
		line-height: 24px;
	}
`;

const MeteocontrolTile = ({
	project,
	onEnterEditMode,
	onExitEditMode,
	onDirtyForm,
	onTileSave,
	highlightLabels,
	setMeteotabSystems,
}) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.SALES,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.ASSET_MANAGER,
		roles.PM_FUNDRAISING,
		roles.PM_TECHNICAL,
		roles.HEAD_OF_ESG,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [meteoOptions, setMeteoOptions] = useState([]);
	const [isLoadingMeteoOptions, setIsLoadingMeteoOptions] = useState(false);

	const initialValues = useMeteocontrolFormInitialValues(project, meteoOptions);
	const validationSchema = useMeteocontrolFormValidationSchema();

	const abortController = useAbortController();

	const { errors, touched, values, handleSubmit, setFieldValue, setFieldTouched, dirty, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			enableReinitialize: true,
			onSubmit: () => onSubmit(),
		});

	useEffect(() => {
		(async () => {
			try {
				setIsLoadingMeteoOptions(true);
				const res = await getMeteocontrolSystems(abortController.signal);
				const meteocontrolOptions = res.data?.data?.map(({ key, name }) => ({ value: key, label: name }));
				setMeteoOptions(meteocontrolOptions);
				setMeteotabSystems(meteocontrolOptions.filter(({ value }) => project?.meteocontrolKeys?.includes(value)));
				setIsLoadingMeteoOptions(false);
			} catch (err) {
				showToastError(err, 'Failed loading Meteocontrol options');
				if (!axios.isCancel(err)) {
					setIsLoadingMeteoOptions(false);
				}
			}
		})();
	}, [abortController.signal, setMeteotabSystems, project.meteocontrolKeys]);

	const onSubmit = async () => {
		setIsSavingChanges(true);
		try {
			await editProject(abortController.signal, {
				...project,
				meteocontrolKeys: values?.meteocontrolKeys?.map(({ value }) => value),
			});
			if (onDirtyForm) {
				onDirtyForm(false);
			}
			await onTileSave();
			setMeteotabSystems(values?.meteocontrolKeys);
			handleCancel();
			setIsSavingChanges(false);
			resetForm({ values });
		} catch (e) {
			showToastError(e);
			if (!axios.isCancel(e)) {
				handleCancel();
				setIsSavingChanges(false);
			}
		}
	};

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	const maxLength = 26;

	return (
		<>
			<EditableTile
				title={t('Meteocontrol')}
				isLoading={isSavingChanges}
				isInEditMode={isInEditMode}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
				onClick={handleTileClick}
			>
				<TileDynamicSection
					isInEditMode={isInEditMode}
					editView={
						<>
							<FormField>
								<FormikSelect
									id="meteocontrolKeys"
									name="meteocontrolKeys"
									label={t('VCOM Name')}
									value={values.meteocontrolKeys}
									error={errors.meteocontrolKeys}
									touched={touched.meteocontrolKeys}
									setFieldValue={setFieldValue}
									setFieldTouched={setFieldTouched}
									options={meteoOptions}
									isMulti
									isTile
									isLoading={isLoadingMeteoOptions}
									isInEditMode={isInEditMode}
									isHighlighted={highlightLabels.includes(
										PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_METEOCONTROL.VCOM,
									)}
								/>
							</FormField>
						</>
					}
					readonlyView={
						<KeyValueVisualization
							title={t('VCOM Name')}
							value={
								<Tags>
									{values.meteocontrolKeys?.map(tag => (
										<Tag key={tag.value}>
											<span>{tag.label.length > maxLength ? tag.label.substring(0, maxLength) + '...' : tag.label}</span>
										</Tag>
									))}
								</Tags>
							}
						/>
					}
					uniqueId="meteocontrol"
				/>
			</EditableTile>
		</>
	);
};

MeteocontrolTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onSave: undefined,
	onDirtyForm: undefined,
	onTileSave: async () => {},
	highlightLabels: [],
	setMeteotabSystems: undefined,
};

MeteocontrolTile.propTypes = {
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		meteocontrolKeys: PropTypes.arrayOf(PropTypes.string),
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	onTileSave: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
	setMeteotabSystems: PropTypes.func,
};

export default MeteocontrolTile;
