import getPartnerTypes from 'Preferences/api/getPartnerTypes';

const loadPartnerTypeOptions = async signal => {
	const response = await getPartnerTypes(signal);
	const types = response.data;

	return types.map(type => ({
		value: type.id,
		label: type.name,
	}));
};

export default loadPartnerTypeOptions;
