import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import useReponsive from 'Common/hooks/useResponsive';
import Icon from 'Common/components/icons/Icon';
import logo from 'Common/assets/images/logo.svg';

import sizes from 'Application/theme/sizes';
import colors from 'Application/theme/colors';
import { updateSidebarState } from 'Application/reducers/reduxCommon';

import UserActions from 'User/components/UserActions';

import NotificationsControl from 'Notifications/components/NotificationsControl';
import isInAuthorizedGroups from 'User/utils/isInAuthorizedGroups';
import { roleGroups } from 'User/constants/roles';

const FixedWrapper = styled.div`
	position: fixed;
	top: 0;
	left: ${({ $isMobile, $isShrank }) => ($isMobile ? '0' : $isShrank ? '80' : '208')}px;
	width: calc(100% - ${({ $isMobile, $isShrank }) => ($isMobile ? '0' : $isShrank ? '80' : '208')}px);
	z-index: 995;
	transition: 0.5s ease-in-out;
`;

const Container = styled.div`
	transition: all 0.5s ease-in-out;
	width: 100%;
	display: flex;
	min-height: 60px;
	padding: ${({ $isMobile }) => ($isMobile ? '0px 8px' : '0px 16px')};
	align-items: center;
	justify-content: space-between;
	background: ${colors.grey.lightest};
	.LayoutWithSidebar__Content {
		padding: ${({ $isMobile }) => ($isMobile ? '120px' : '0px')};
	}
`;

const HeaderActionsContainer = styled.div`
	display: flex;
	align-items: center;
	gap: ${sizes.spacing(2)};
`;

const MobileActions = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${sizes.spacing(2)};

	svg {
		cursor: pointer;
	}
`;

const MobileSubHeader = styled.div`
	display: flex;
	flex-direction: row;
	background-color: ${colors.common.headerGrey};
	padding: ${sizes.spacing(1)} 0 ${sizes.spacing(1)} ${sizes.spacing(2)};
`;

const HeaderSizeContainer = styled.div`
	margin-bottom: ${({ $marginBottom }) => $marginBottom}px;
`;

const HeaderContainer = ({ children, hideSidebars, id }) => {
	const headerRef = useRef();
	const dispatch = useDispatch();
	const { isMobile } = useReponsive();
	const isShrank = useSelector(state => state.common.isSidebarShrank);
	const isEpcPartner = isInAuthorizedGroups([roleGroups.EPC_PARTNER]);
	const [headerHeight, setHeaderHeight] = useState(0);

	const toggleSideBar = () => {
		dispatch(updateSidebarState());
	};

	const resizeObserver = useMemo(
		() =>
			new ResizeObserver(entries => {
				entries.forEach(entry => {
					setHeaderHeight(entry.contentRect.height);
				});
			}),
		[],
	);

	useEffect(() => {
		const element = headerRef.current;

		if (!element) return;

		resizeObserver.observe(headerRef.current);

		return () => {
			resizeObserver.unobserve(element);
		};
	}, [headerRef, resizeObserver, isMobile]);

	return isMobile ? (
		<>
			<FixedWrapper $isMobile={true} ref={headerRef} id={id}>
				<Container $isMobile={true}>
					<MobileActions>
						{!hideSidebars && <Icon icon={isShrank ? 'menu' : 'close'} size="largest" onClick={toggleSideBar} />}
						<Link to="/">
							<img src={logo} alt="Ecoligo" />
						</Link>
					</MobileActions>

					{!hideSidebars && (
						<HeaderActionsContainer>
							{!isEpcPartner && <NotificationsControl />}
							<UserActions />
						</HeaderActionsContainer>
					)}
				</Container>
				<MobileSubHeader>{children}</MobileSubHeader>
			</FixedWrapper>
			<HeaderSizeContainer $marginBottom={headerHeight} />
		</>
	) : (
		<>
			<FixedWrapper $isMobile={false} $isShrank={isShrank} ref={headerRef} id={id}>
				<Container ref={headerRef} $isMobile={true} id={id}>
					{children}
					<HeaderActionsContainer>
						{!isEpcPartner && <NotificationsControl />}
						<UserActions />
					</HeaderActionsContainer>
				</Container>
			</FixedWrapper>
			<HeaderSizeContainer $marginBottom={headerHeight} />
		</>
	);
};

HeaderContainer.defaultProps = {
	children: null,
	id: null,
	hideSidebars: false,
};

HeaderContainer.propTypes = {
	children: PropTypes.node.isRequired,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	hideSidebars: PropTypes.bool,
};

export default HeaderContainer;
