import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';

import colors from 'Application/theme/colors';

import Loader from 'Common/components/Loader';
import EditableTile from 'Common/components/Tile/EditableTile';
import ButtonLink from 'Common/components/buttons/ButtonLink';
import LoaderWrapper from 'Common/components/LoaderWrapper';

const NumberPill = styled.span`
	background: ${colors.warning.main};
	border-radius: 20px;
	color: ${colors.grey.lightest};
	padding: 2px 12px;
	margin-right: 5px;
	font-size: 14px;
`;

const Text = styled.span`
	color: ${colors.text.primary};
	font-size: 16px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 16px;
	margin-bottom: 32px !important;
`;

const DashboardOffersTile = ({ reviewedOffersCount, isLoading }) => {
	const { t } = useTranslation();

	return (
		<EditableTile title={t('Offers')}>
			{isLoading ? (
				<LoaderWrapper>
					<Loader />
				</LoaderWrapper>
			) : (
				<>
					<div>
						<Text>{t('You have ')}</Text>
						{reviewedOffersCount > 0 ? <NumberPill>{reviewedOffersCount}</NumberPill> : <Text>{t('no ')}</Text>}
						<Text>{t(`${reviewedOffersCount === 1 ? 'Offer' : 'Offers'} from ecoligo right now.`)}</Text>
					</div>
					<ButtonWrapper>
						<ButtonLink label="Review Offers - EPC Partner Dashboard" to="/epc/offers" text={t('Review Offers')} />
					</ButtonWrapper>
				</>
			)}
		</EditableTile>
	);
};

DashboardOffersTile.propTypes = {
	reviewedOffersCount: PropTypes.number.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default DashboardOffersTile;
