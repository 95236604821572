import { toast as originalToast } from 'react-toastify';
import Icon from 'Common/components/icons/Icon';

const iconTypes = {
	[originalToast.TYPE.ERROR]: 'warningRounded',
	[originalToast.TYPE.WARNING]: 'warningRounded',
	[originalToast.TYPE.SUCCESS]: 'infoOutlined',
	[originalToast.TYPE.INFO]: 'infoOutlined',
	[originalToast.TYPE.DEFAULT]: 'infoOutlined',
};

const notify = (content, options) => {
	const hasIcon = Boolean(options.type);

	return originalToast(
		<>
			{hasIcon && <Icon icon={iconTypes[options.type]} />}
			{content}
		</>,
		options,
	);
};

export default notify;
