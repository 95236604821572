import { newStatuses } from 'Campaigns/constants/campaignStatuses';
import { useMemo } from 'react';

const fallbackValues = {
	name: '',
	displayName: '',
	maximumVolume: '',
	minimumVolume: '',
	onHold: '',
	notRealised: false,
	externalFinance: false,
	externalVolume: '',
	provider: '',
	status: '',
};

const useOverviewInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			displayName: data?.finance_details?.displayName || '',
			externalId: data?.id || '',
			name: data?.name || '',
			co2: data?.finance_details?.co2 || '',
			maximumVolume: data?.maximum_volume || '',
			minimumVolume: data?.minimum_volume || '',
			onHold: data?.on_hold,
			notRealised: data?.status === newStatuses.NOT_REALISED,
			externalFinance: data?.external_funding,
			externalVolume: data?.external_funding_volume,
			provider: data?.finance_details?.provider,
			status: data?.status,
		}),
		[data],
	);

export default useOverviewInitialValues;
