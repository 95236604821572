import { useMemo } from 'react';
import { costExceededOptions } from '../constants/costExceededOptions';
import { energyProductionExplanationOptionns } from '../constants/energyProductionExplanationOptionns';
import { m62ChallengeOptions } from '../constants/m62ChallenengeOptions';
import { m63ExtraordinaryEventsOptions } from '../constants/m63ExtraordinaryEventOptions';
import { successesOptions } from '../constants/successeOptions';
const fallbackValues = {
	energyProductionExplanation: null,
	m62Challenges: null,
	successes: null,
	m63ExtraordinaryEvents: null,
	costsExceeded: null,
};

const useReportingFormInitialValues = data => {
	const energyProductionExplanation = useMemo(() => {
		const result = energyProductionExplanationOptionns.filter(
			i => i.value === data.energyProductionExplanation,
		)[0];
		return data.energyProductionExplanation && result ? result : fallbackValues.energyProductionExplanation;
	}, [data?.energyProductionExplanation]);

	const costsExceeded = useMemo(() => {
		const result = costExceededOptions.filter(i => i.value === data.costsExceeded)[0];
		return data.costsExceeded ? result : fallbackValues.costsExceeded;
	}, [data?.costsExceeded]);

	const m62Challenges = useMemo(() => {
		const result = m62ChallengeOptions.filter(i => i.value === data.m62Challenges)[0];
		return data.m62Challenges ? result : fallbackValues.m62Challenges;
	}, [data?.m62Challenges]);

	const m63ExtraordinaryEvents = useMemo(() => {
		const result = m63ExtraordinaryEventsOptions.filter(i => i.value === data.m63ExtraordinaryEvents)[0];
		return data.m63ExtraordinaryEvents ? result : fallbackValues.m63ExtraordinaryEvents;
	}, [data?.m63ExtraordinaryEvents]);

	const successes = useMemo(() => {
		const result = successesOptions.filter(i => i.value === data.successes)[0];
		return data.successes ? result : fallbackValues.successes;
	}, [data?.successes]);

	return useMemo(
		() => ({
			...fallbackValues,
			costsExceeded: data.costsExceeded ? costsExceeded : fallbackValues.costsExceeded,
			m62Challenges: data.m62Challenges ? m62Challenges : fallbackValues.m62Challenges,
			successes: data.successes ? successes : fallbackValues.successes,
			m63ExtraordinaryEvents: data.m63ExtraordinaryEvents
				? m63ExtraordinaryEvents
				: fallbackValues.m63ExtraordinaryEvents,
			energyProductionExplanation: data.energyProductionExplanation
				? energyProductionExplanation
				: fallbackValues.energyProductionExplanation,
		}),
		[data, costsExceeded, m62Challenges, successes, m63ExtraordinaryEvents, energyProductionExplanation],
	);
};
export default useReportingFormInitialValues;
