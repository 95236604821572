import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { newStatuses } from 'Campaigns/constants/campaignStatuses';
import Button from 'Common/components/buttons/Button';
import { useTranslation } from 'react-i18next';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';
import { useState } from 'react';
import updateCampaignStatus from 'Campaigns/api/updateCampaignStatus';
import isBefore from 'date-fns/isBefore';
import { endOfDay, startOfDay, isAfter } from 'date-fns';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const Wrapper = styled.div`
	display: flex;
	align-items: center;
`;

const ChangeStatusButton = ({ campaign, onChange }) => {
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const abortController = useAbortController();

	const handleCloseButtonClick = async e => {
		e.stopPropagation();

		setIsLoading(true);

		try {
			const response = await updateCampaignStatus(abortController.signal, campaign.auto_id, newStatuses.CLOSED);

			onChange(response.data);

			setIsLoading(false);
			setShowConfirmModal(false);
		} catch (error) {
			showToastError(error);
			if (!axios.isCancel(error)) {
				setIsLoading(false);
				setShowConfirmModal(false);
			}
		}
	};

	const handleReopenButtonClick = async e => {
		e.stopPropagation();

		setIsLoading(true);

		try {
			const response = await updateCampaignStatus(abortController.signal, campaign.auto_id, newStatuses.ACTIVE);

			onChange(response.data);

			setIsLoading(false);
			setShowConfirmModal(false);
		} catch (error) {
			showToastError(error);
			if (!axios.isCancel(error)) {
				setIsLoading(false);
				setShowConfirmModal(false);
			}
		}
	};
	const fundingEndDatePassed = isAfter(new Date(), new Date(campaign.funding_end_date));

	const shouldShowReopenButton =
		[newStatuses.CLOSED, newStatuses.FUNDED_PARTLY, newStatuses.FUNDED_INCOMPLETE].includes(campaign.status) &&
		campaign.investments_sum !== undefined &&
		Number(campaign.investments_sum) < Number(campaign.maximum_volume);

	const isReopenButtonEnabled =
		Boolean(!campaign.external_funding) &&
		Boolean(campaign.funding_end_date) &&
		!campaign.on_hold &&
		isBefore(startOfDay(new Date()), endOfDay(new Date(campaign.funding_end_date))) &&
		Number(campaign.investments_sum) < Number(campaign.maximum_volume) &&
		!fundingEndDatePassed;

	const shouldShowCloseButton = campaign.status === newStatuses.ACTIVE;

	const isInAppropriateStatus = ![
		newStatuses.DRAFT,
		newStatuses.READY_TO_LAUNCH,
		newStatuses.FUNDED_COMPLETE,
	].includes(campaign.status);

	const isButtonVisible = isInAppropriateStatus && (shouldShowReopenButton || shouldShowCloseButton);

	const handleModalShowing = e => {
		e.stopPropagation();
		setShowConfirmModal(true);
	};

	if (isButtonVisible) {
		if (shouldShowCloseButton) {
			return (
				<Wrapper>
					<Button
						label="Campaign Details Change Status - Close Button"
						text={t('Close')}
						isLoading={isLoading}
						onClick={handleModalShowing}
					/>
					<QuestionTooltip
						tooltip={t(
							'Close the Campaign to show it as ‘Completed’ on the web site and to stop further investments.',
						)}
					/>
					<ConfirmModal
						isOpen={showConfirmModal}
						onConfirm={handleCloseButtonClick}
						onCancel={() => setShowConfirmModal(false)}
						confirmText="Close"
						isLoading={isLoading}
						heading={t('Close Campaign')}
						text={`
						${t('Closing the Campaign will show it as closed on the website, and the 14-day waiting period will begin.')}
						
						${t('Are you sure you want to close the Campaign?')}`}
					/>
				</Wrapper>
			);
		}

		if (shouldShowReopenButton) {
			const button = (
				<Button
					onClick={handleModalShowing}
					disabled={!isReopenButtonEnabled}
					isLoading={isLoading}
					text={t('Reopen')}
					label="Campaign Details Change Status - Reopen Button"
				/>
			);

			return (
				<Wrapper>
					{isReopenButtonEnabled ? (
						button
					) : (
						<HoverTooltip
							placement="top"
							title={
								campaign.on_hold
									? t('Campaign cannot be reopened when it is On hold.')
									: t('Cannot reopen Campaign because all Tranche Investible dates are in the past')
							}
							arrow
						>
							<div>{button}</div>
						</HoverTooltip>
					)}
					<QuestionTooltip
						tooltip={
							campaign.external_funding
								? t('Campaign cannot be reopened when External finance is applied')
								: t(
										'Reopen the Campaign to show it as ‘Active’ on the website and to allow investments. The Campaign must have a Tranche with valid Investible dates and be set as Investible in portagon',
								  )
						}
					/>
					<ConfirmModal
						isOpen={showConfirmModal}
						onConfirm={handleReopenButtonClick}
						onCancel={() => setShowConfirmModal(false)}
						confirmText="Reopen"
						isLoading={isLoading}
						label="reopen-campaign-confirm"
						heading={t('Reopen Campaign')}
						text={t(`Reopening the Campaign will show it as active on the website. The 14-day waiting period will also be repeated when it is next closed.
						
						Are you sure you want to reopen the Campaign?`)}
					/>
				</Wrapper>
			);
		}
	}

	return null;
};

ChangeStatusButton.defaultProps = {
	onChange: () => {},
};

ChangeStatusButton.propTypes = {
	campaign: PropTypes.shape({
		auto_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		funding_end_date: PropTypes.string,
		status: PropTypes.string,
		investments_sum: PropTypes.string,
		maximum_volume: PropTypes.string,
		external_funding: PropTypes.string,
		on_hold: PropTypes.bool,
	}).isRequired,
	onChange: PropTypes.func,
};

export default ChangeStatusButton;
