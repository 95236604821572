import FormField from 'Common/components/form/FormField';
import { useTranslation } from 'react-i18next';
import FormikTextArea from 'Common/components/form/FormikTextArea';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import PropTypes from 'prop-types';
import { SectionWrapper } from 'Common/components/modals/OverlaySections';
import crudModes from 'Common/constants/crudModes';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';
import formatDate from 'Common/utils/formatDate';
import parseDate from 'Common/utils/parseDate';

const ProposalSection = ({
	values,
	errors,
	touched,
	handleChange,
	setFieldValue,
	setFieldTouched,
	handleBlur,
	mode,
}) => {
	const { t } = useTranslation();

	return mode !== crudModes.VIEW ? (
		<SectionWrapper>
			<FormField data-first-field>
				<FormikSingleDatePicker
					label={t('Validity date')}
					id="offerValidityDate"
					startDateName="offerValidityDate"
					error={errors.offerValidityDate}
					touched={touched.offerValidityDate}
					startDate={parseDate(values.offerValidityDate)}
					setFieldValue={setFieldValue}
					setFieldTouched={setFieldTouched}
					isOverlay
				/>
			</FormField>
			<FormField>
				<FormikTextArea
					label={t('Gensets')}
					id="gensetsInfo"
					name="gensetsInfo"
					error={errors.gensetsInfo}
					touched={touched.gensetsInfo}
					value={values.gensetsInfo}
					onChange={handleChange}
					onBlur={handleBlur}
					tooltip={{
						placement: 'right',
						tooltip: t('List any required info about the gensets included in the offer.'),
					}}
					isOverlay
				/>
			</FormField>

			<FormField>
				<FormikTextArea
					label={t('Assumptions')}
					id="assumptions"
					name="assumptions"
					error={errors.assumptions}
					touched={touched.assumptions}
					value={values.assumptions}
					onChange={handleChange}
					onBlur={handleBlur}
					tooltip={{
						placement: 'right',
						tooltip: t('Assumptions made relating to offer.'),
					}}
					isOverlay
				/>
			</FormField>
			<FormField>
				<FormikTextArea
					label={t('Exclusions')}
					id="exclusions"
					name="exclusions"
					error={errors.exclusions}
					touched={touched.exclusions}
					value={values.exclusions}
					onChange={handleChange}
					onBlur={handleBlur}
					tooltip={{
						placement: 'right',
						tooltip: t('Anything (components etc.) which are not included in this offer.'),
					}}
					isOverlay
				/>
			</FormField>
			<FormField data-last-field>
				<FormikTextArea
					label={t('Variations')}
					id="variations"
					name="variations"
					error={errors.variations}
					touched={touched.variations}
					value={values.variations}
					onChange={handleChange}
					onBlur={handleBlur}
					tooltip={{
						placement: 'right',
						tooltip: t('Potential variations to the offer which can be discussed.'),
					}}
					isOverlay
				/>
			</FormField>
		</SectionWrapper>
	) : (
		<SectionWrapper>
			<KeyValueVisualization
				id="offerValidityDate"
				title={t('Validity date')}
				value={values.offerValidityDate ? formatDate(values.offerValidityDate) : ''}
			/>
			<KeyValueVisualization id="gensetsInfo" title={t('Gensets')} value={values.gensetsInfo} />
			<KeyValueVisualization id="assumptions" title={t('Assumptions')} value={values.assumptions} />
			<KeyValueVisualization id="exclusions" title={t('Exclusions')} value={values.exclusions} />
			<KeyValueVisualization id="variations" title={t('Variations')} value={values.variations} />
		</SectionWrapper>
	);
};

ProposalSection.defaultProps = {
	values: {},
	errors: {},
	touched: {},
	handleChange: () => {},
	setFieldValue: () => {},
	setFieldTouched: () => {},
	handleBlur: () => {},
};
ProposalSection.propTypes = {
	values: PropTypes.shape({
		offerValidityDate: PropTypes.string,
		gensetsInfo: PropTypes.string,
		assumptions: PropTypes.string,
		exclusions: PropTypes.string,
		variations: PropTypes.string,
	}),
	errors: PropTypes.shape({
		offerValidityDate: PropTypes.string,
		gensetsInfo: PropTypes.string,
		assumptions: PropTypes.string,
		exclusions: PropTypes.string,
		variations: PropTypes.string,
	}),
	touched: PropTypes.shape({
		offerValidityDate: PropTypes.bool,
		gensetsInfo: PropTypes.bool,
		assumptions: PropTypes.bool,
		exclusions: PropTypes.bool,
		variations: PropTypes.bool,
	}),
	handleChange: PropTypes.func,
	setFieldValue: PropTypes.func,
	setFieldTouched: PropTypes.func,
	handleBlur: PropTypes.func,
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
};

export default ProposalSection;
