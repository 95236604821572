import { FILTER_NAME_SUFFIXES_REGEX } from '../constants/filterConstants';

export const convertSanitizedDataToSelectedFilters = sanitizedData =>
	Array.from(
		new Set(
			Object.keys(sanitizedData).reduce((acc, originalFilterName) => {
				const filterNameWithoutSuffix = originalFilterName.replace(FILTER_NAME_SUFFIXES_REGEX, '');

				return [...acc, originalFilterName, filterNameWithoutSuffix];
			}, []),
		),
	);
