import styled from 'styled-components/macro';
import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';

import ProjectInfoSummary from './ProjectInfoSummary';
import useProjectStats from './hooks/useProjectStats';
import ProjectInfoSystemSizeEdit from './ProjectInfoSystemSizeEdit';

import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';

import Button from 'Common/components/buttons/Button';

const InfoTile = styled.div`
	border-radius: 20px;
	border: 1px solid ${colors.primary.dark};
	padding: 0 16px;
`;

const Title = styled.div`
	font-size: 18px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
	padding-top: ${sizes.base(3)};
`;

const Text = styled.span`
	font-size: 16px;
	margin: 5px 0;
	flex: 1;
`;

const ProjectInfoTileFormEdit = ({
	project,
	values,
	errors,
	touched,
	namePrefix,
	handleChange,
	setFieldValue,
	handleBlur,
	removeProject,
	campaignId,
}) => {
	const {
		projectName,
		systemSizeKwp,
		otherCampaignsSystemSizeValue,
		otherCampaignsSystemSizePercent,
		currentCampaignSystemSizeValue,
		currentCampaignSystemSizePercent,
	} = useProjectStats(project, values, campaignId);

	return (
		<InfoTile>
			<Title>
				<Text>{projectName}</Text>
				<Button
					small
					secondary
					icon="closeRounded"
					onClick={() => removeProject(project.id)}
					label="Campaign Project Info Edit - Close Button"
				/>
			</Title>
			<Grid container direction="column">
				<Grid item xs={12}>
					<ProjectInfoSummary
						projectType={project?.projectType?.name}
						systemSizeKwp={systemSizeKwp}
						annualCO2Savings={project?.annualCO2Savings}
						otherCampaignsSystemSizeValue={otherCampaignsSystemSizeValue}
						otherCampaignsSystemSizePercent={otherCampaignsSystemSizePercent}
						currentCampaignSystemSize={currentCampaignSystemSizeValue}
						currentCampaignSystemSizePercent={currentCampaignSystemSizePercent}
					/>
				</Grid>
			</Grid>
			<Grid container alignItems="center" direction="column">
				<Grid item xs={4}>
					<ProjectInfoSystemSizeEdit
						handleBlur={handleBlur}
						handleChange={handleChange}
						errors={errors}
						touched={touched}
						values={values}
						namePrefix={namePrefix}
						systemSizeKwp={systemSizeKwp}
						setFieldValue={setFieldValue}
						otherCampaignsSystemSizeValue={otherCampaignsSystemSizeValue}
						currentCampaignSystemSizeValue={currentCampaignSystemSizeValue}
						currentCampaignSystemSizePercent={currentCampaignSystemSizePercent}
					/>
				</Grid>
			</Grid>
		</InfoTile>
	);
};

ProjectInfoTileFormEdit.defaultProps = {
	errors: null,
	touched: null,
};

ProjectInfoTileFormEdit.propTypes = {
	project: PropTypes.shape({
		hiddenProject: PropTypes.bool,
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		projectType: PropTypes.shape({
			name: PropTypes.string,
		}),
		annualCO2Savings: PropTypes.number,
	}).isRequired,
	values: PropTypes.shape({
		financeDetailsProjects: PropTypes.arrayOf(
			PropTypes.shape({
				annualCo2Contribution: PropTypes.number,
			}),
		),
		systemSizeAllocatedValue: PropTypes.number,
	}).isRequired,
	errors: PropTypes.shape({
		financeDetailsProjects: PropTypes.arrayOf(
			PropTypes.shape({
				annualCo2SavingsEditable: PropTypes.bool,
			}),
		),
	}),
	touched: PropTypes.shape({
		financeDetailsProjects: PropTypes.arrayOf(
			PropTypes.shape({
				annualCo2SavingsEditable: PropTypes.bool,
			}),
		),
	}),
	namePrefix: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	handleBlur: PropTypes.func.isRequired,
	removeProject: PropTypes.func.isRequired,
	campaignId: PropTypes.number.isRequired,
};

export default ProjectInfoTileFormEdit;
