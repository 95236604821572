import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import InsuranceTile from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/InsuranceTile/InsuranceTile';
import { PROJECT_DETAILS_PAGE_TAB_TILES } from 'Projects/constants/projectTabForms';
import CommissioningTile from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/CommissioningTile/CommissioningTile';
import PostCompletionTile from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/PostCompletionTile/PostCompletionTile';
import ReportingTile from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/ReportingTile/ReportingTile';

const OperationsTab = ({ project, onEnterEditMode, onExitEditMode, onDirtyForm, handleUpdateProject }) => {
	const handleInsuranceDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.OPERATIONS.INSURANCE, isDirty);
		},
		[onDirtyForm],
	);

	const handleCommissionDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.OPERATIONS.COMMISSIONING, isDirty);
		},
		[onDirtyForm],
	);

	const handlePostCompletionForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.OPERATIONS.POSTCOMPLETION, isDirty);
		},
		[onDirtyForm],
	);

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
				<CommissioningTile
					project={project}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onDirtyForm={handleCommissionDirtyForm}
					onTileSave={handleUpdateProject}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
				<InsuranceTile
					project={project}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onDirtyForm={handleInsuranceDirtyForm}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<PostCompletionTile
					project={project}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onDirtyForm={handlePostCompletionForm}
					onTileSave={handleUpdateProject}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
				<ReportingTile
					project={project}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onDirtyForm={handlePostCompletionForm}
					onTileSave={handleUpdateProject}
				/>
			</Grid>
		</TilesGridWrapper>
	);
};

OperationsTab.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	handleUpdateProject: async () => {},
};

OperationsTab.propTypes = {
	project: PropTypes.shape({}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	handleUpdateProject: PropTypes.func,
};

export default OperationsTab;
