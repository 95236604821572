import getClientContractTypes from '../api/getClientContractTypes';

const loadClientContractTypeOptions = async (signal, query, withEmpty = false) => {
	const response = await getClientContractTypes(signal, query);
	const contractTypes = response.data.content;

	const options = contractTypes
		.filter(contractType => contractType.name && contractType.name !== '-')
		.map(contractType => ({
			value: contractType.id,
			label: contractType.name,
		}));

	if (withEmpty) {
		return [
			{
				value: '',
				label: '',
			},
			...options,
		];
	}

	return options;
};

export default loadClientContractTypeOptions;
