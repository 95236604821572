import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';

import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import { useMemo, useRef, useState } from 'react';
import useDashboardListFilterData from 'Dashboard/hooks/useDashboardListFilterData';
import Filters from 'Common/components/filter/FiltersContainer';

import MyActivitiesTab from 'Dashboard/components/MyActivities/Tabs/MyActivitiesTab/MyActivitiesTab';
import MyProjectsTab from 'Dashboard/components/MyActivities/Tabs/MyProjectsTab/MyProjectsTab';
import MyOnHoldTab from 'Dashboard/components/MyActivities/Tabs/MyOnHoldTab/MyOnHoldTab';
import TabsContainer from 'Common/components/tabs';

const MyActivities = () => {
	const { t } = useTranslation();
	const currentUser = useSelector(state => state.user);
	const filterButtonRef = useRef();
	const filtersData = useDashboardListFilterData();
	const filtersRef = useRef();

	const selectedTab = useSelector(state => state.tabs.myDashboard ?? 0);

	const [showAddedToMyProjects, setShowAddedToMyProjects] = useState(false);

	const tabsConfig = useMemo(
		() => [
			{
				label: 'My Activities',
				PanelComponent: MyActivitiesTab,
				forceRender: true,
				panelProps: {
					setShowAddedToMyProjects,
				},
			},
			{
				label: 'My Projects',
				PanelComponent: MyProjectsTab,
				showRedDot: showAddedToMyProjects,
				forceRender: true,
			},
			{
				label: 'On Hold',
				PanelComponent: MyOnHoldTab,
				forceRender: true,
			},
		],
		[showAddedToMyProjects],
	);

	return (
		<>
			<Helmet>
				<title>{t('My Activities')}</title>
			</Helmet>
			<HeaderContainer isFixed>
				<HeaderTitle>{`${t('Welcome')}, ${currentUser?.firstName}!`}</HeaderTitle>
				<HeaderActions>
					<div ref={filterButtonRef}></div>
				</HeaderActions>
			</HeaderContainer>
			<div ref={filtersRef}>
				<Filters
					key={selectedTab}
					table={'myActivitiesDashboard'}
					buttonRef={filterButtonRef}
					filtersData={filtersData}
				/>
			</div>
			<TabsContainer page="myDashboard" tabsConfig={tabsConfig} />
		</>
	);
};

export default MyActivities;
