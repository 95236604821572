const useStatisticsInitialValues = () => {
	return {
		totalElements: 0,
		content: [
			{
				totalMWpInOperation: 0,
				totalMWpSigned: 0,
				totalMWpMatured: 0,
				totalEurInvested: 0,
				totalProjectsSigned: 0,
				countries: [],
				totalProjectsFunded: 0,
				totalCrowdInvestors: 0,
				totalLifetimeCo2InOperation: 0,
				totalLifetimeCo2Signed: 0,
				totalLifetimeCo2Funded: 0,
				averageCO2SavingsPerEURInvested: 0,
				createdDate: '2022-06-23T10:00:27Z',
				totalCountriesServed: 0,
				totalClientsImpacted: 0,
				totalIndustriesServed: 0,
				totalEpcPartners: 0,
				totalEmployeesImpacted: 0,
				highestKwpFunded: 0,
				lowestKwpFunded: 0,
				totalProjectsInOperation: 0,
				totalProjectsMatured: 0,
				totalClientsImpactedInOperation: 0,
				projectedKWhSigned: 0,
				projectedKWhInOperation: 0,
				activeCrowdInvestors: 0,
				notActiveCrowdInvestors: 0,
				totalMaleCrowdInvestors: 0,
				totalFemaleCrowdInvestors: 0,
			},
		],
	};
};

export default useStatisticsInitialValues;
