import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';

import formatDate from 'Common/utils/formatDate';
import formatCurrency from 'Common/utils/formatCurrency';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { DefaultTable, TableHeadCell, TableBodyCell } from 'Common/components/table/table';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import Tile from 'Common/components/Tile/Tile';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}

	${TableBodyCell} {
		background: transparent;
	}
`;

const EmissionSpvBalanceTable = ({ emissionSpv, isLoading }) => {
	const { t } = useTranslation();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Balance'),
				accessor: 'value',
			},
			{
				Header: t('Date'),
				accessor: 'date',
			},
		],
		[t],
	);

	// Re-structure the SPV balances as table data
	const data = useMemo(
		() =>
			emissionSpv?.balances?.map(balance => ({
				...balance,
				value: balance?.value ? formatCurrency(balance?.value) : '-',
				date: balance?.date ? formatDate(balance?.date, 'd/M/yy') : '-',
			})) ?? [],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[emissionSpv],
	);

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
			},
			useCustomCellStyles,
		);

	return (
		<Tile title="Balances" isLoadingContent={isLoading} heightFitConent isTable>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
				/>
			</TableWrapper>
		</Tile>
	);
};

EmissionSpvBalanceTable.defaultProps = {
	isLoading: false,
};

EmissionSpvBalanceTable.propTypes = {
	emissionSpv: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		balances: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	}).isRequired,
	isLoading: PropTypes.bool,
};

export default EmissionSpvBalanceTable;
