import PropTypes from 'prop-types';
import EntitiesProjectListTable from 'Entities/components/EntitiesDetails/Tabs/EntitiesProjectsTab/EntitiesProjectListTable/EntitiesProjectListTable';

const EntitiesProjectsTab = ({ entity }) => {
	return <EntitiesProjectListTable entity={entity} />;
};

EntitiesProjectsTab.propTypes = {
	entity: PropTypes.shape({}).isRequired,
};

export default EntitiesProjectsTab;
