import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import PartnerTypesForm from 'Preferences/components/Tabs/GeneralTab/PartnerTypes/PartnerTypesForm/PartnerTypesForm';
import crudModes from 'Common/constants/crudModes';
import { PropTypes } from 'prop-types';

const PartnerTypesCreateModal = ({ isOpen, onClose, onFormSubmit }) => {
	const { t } = useTranslation();

	return (
		<ModalForm
			label="partner-types-create"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			heading={t('Create Partner Type')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<PartnerTypesForm
					mode={crudModes.CREATE}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

PartnerTypesCreateModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default PartnerTypesCreateModal;
