import getCountriesByIds from 'Country/api/getCountriesByIds';

const loadSelectedCountries = async (signal, ids) => {
	const response = await getCountriesByIds(signal, ids);
	const countries = response.data;

	return countries
		.filter(country => country.name && country.name !== '-')
		.map(country => ({
			value: country.id,
			label: country.name,
		}));
};

export default loadSelectedCountries;
