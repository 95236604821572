import sizes from 'Application/theme/sizes';
import Button from 'Common/components/buttons/Button';
import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import Icon from 'Common/components/icons/Icon';
import colors from 'Application/theme/colors';
import GenerateAttachmentModal from 'Invoice/components/Modals/GenerateAttachmentModal';
import openUrlInNewTab from 'Common/utils/downloadFile';
import showToastError from 'Common/utils/showToastError';
import downloadInvoice from 'Invoice/api/downloadInvoice';
import { useTranslation } from 'react-i18next';
import useAbortController from 'Common/hooks/useAbortController';

const Wrapper = styled.div`
	padding-right: ${sizes.spacing(1)};
`;

const InvoiceAction = styled(Icon).attrs({ color: colors.primary.dark })`
	cursor: pointer;
`;

const InvoiceActionsContainer = styled.div`
	display: flex;
	gap: ${sizes.spacing(1)};

	${({ $areBigActions }) =>
		$areBigActions &&
		css`
			align-items: center;
			justify-content: center;

			${InvoiceAction} {
				width: 36px;
				height: 36px;
				border-radius: 50%;
				background-color: ${colors.primary.light};
				display: flex;
				justify-content: center;
				align-items: center;
				padding: ${sizes.spacing(1)};
			}
		`}
`;

const InvoiceActions = ({ invoice, countryId, onWrapperClick, onDataChange, $areBigActions }) => {
	const { t } = useTranslation();
	const [showGenerateAttachmentModal, setShowGenerateAttachmentModal] = useState(false);

	const abortController = useAbortController();

	const hasAttachedDocument = useMemo(() => Boolean(invoice?.attachedDocument), [invoice?.attachedDocument]);

	const handleShowPreview = () => {
		openUrlInNewTab(invoice?.attachedDocument?.url);
	};

	const handleDownloadInvoice = async () => {
		try {
			const { data: downloadLink } = await downloadInvoice(
				abortController.signal,
				invoice?.attachedDocument?.name,
			);

			openUrlInNewTab(downloadLink);
		} catch (e) {
			showToastError(e, t('Something went wrong'));
		}
	};

	return (
		<Wrapper onClick={onWrapperClick}>
			{hasAttachedDocument ? (
				<InvoiceActionsContainer $areBigActions={$areBigActions}>
					<InvoiceAction icon="removeRedEyeOutlined" onClick={handleShowPreview} />
					<InvoiceAction icon="download" onClick={handleDownloadInvoice} />
				</InvoiceActionsContainer>
			) : (
				<Button
					icon="add"
					secondary
					onClick={() => setShowGenerateAttachmentModal(true)}
					{...($areBigActions
						? {
								medium: true,
						  }
						: {
								small: true,
						  })}
				/>
			)}
			{showGenerateAttachmentModal && (
				<GenerateAttachmentModal
					invoice={invoice}
					countryId={countryId}
					onSubmit={onDataChange}
					isOpen={showGenerateAttachmentModal}
					onClose={() => setShowGenerateAttachmentModal(null)}
				/>
			)}
		</Wrapper>
	);
};

InvoiceActions.defaultProps = {
	invoice: {},
	onWrapperClick: () => {},
	onAddTemplate: () => {},
	$areBigActions: false,
};

InvoiceActions.propTypes = {
	invoice: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		number: PropTypes.string,
		attachedDocument: PropTypes.shape({
			url: PropTypes.string,
			name: PropTypes.string,
			presignedUrl: PropTypes.string,
		}),
	}),
	countryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onDataChange: PropTypes.func.isRequired,
	onWrapperClick: PropTypes.func,
	$areBigActions: PropTypes.bool,
};

export default InvoiceActions;
