import React, { useEffect, useState } from 'react';
import Filter from './Filter';
import RadioButtonsList from 'Common/components/RadioButtonsList';
import styled from 'styled-components/macro';
import sizes from 'Application/theme/sizes';
import { useTranslation } from 'react-i18next';
import ConditionalGridSection from '../ConditionalGridSection';
import colors from 'Application/theme/colors';
import { FormikNumberRange, Popover } from '../form/FormikNumberRange';
import PropTypes from 'prop-types';

const StageFilterContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	padding-top: ${sizes.spacing(2)};

	${Popover} {
		display: none;
	}
`;
const RadioListContianer = styled.div`
	padding: 0 ${sizes.spacing(4)};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const Title = styled.h3`
	margin: 0;
	margin-bottom: ${sizes.spacing(2)};
`;

const TopSection = styled.div`
	margin-bottom: ${sizes.spacing(1)};
`;

const RadioContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const ClearButton = styled.div`
	border-top: 1px solid ${colors.common.lightGrey};
	padding: ${sizes.spacing(1.5)} 0;
	text-align: center;
	cursor: pointer;
`;

const StageFilter = ({
	name,
	label,
	stageStatus,
	value = '',
	setFilterValue,
	minProps,
	maxProps,
	minName,
	maxName,
	minValue,
	maxValue,
	...props
}) => {
	const { t } = useTranslation();

	const options = [
		{
			value: '',
			label: 'All',
		},
		{
			value: 'IN_PROGRESS',
			label: 'In Progress',
		},
		{
			value: 'COMPLETED',
			label: 'Completed',
		},
		{
			value: 'custom',
			label: 'Custom',
		},
	];

	const [isCustom, setIsCustom] = useState(false);

	useEffect(() => {
		setIsCustom(Number(minValue) > 0 || Boolean(maxValue));
	}, [minValue, maxValue]);

	const handleStatusChange = val => {
		setIsCustom(val === 'custom');
		setFilterValue({ [stageStatus]: val, [`${name}StageCompletedPercentageTo`]: '100' });
	};

	const handleClearOptions = () => {
		setFilterValue({
			[`${name}StageCompletedPercentageFrom`]: '',
			[`${name}StageCompletedPercentageTo`]: '',
			[stageStatus]: '',
		});
	};

	const handleSetPercentages = (values, ...args) => {
		const { [minName]: min, [maxName]: max } = values;

		const newMinValue = Object.hasOwn(values, minName) ? min : minValue || '0';
		const newMaxValue = Object.hasOwn(values, maxName) ? max : maxValue || '0';

		setFilterValue(
			{
				[minName]: newMinValue,
				[maxName]: newMaxValue,
				[stageStatus]: 'custom',
			},
			...args,
		);
	};

	return (
		<Filter
			handleClearOptions={handleClearOptions}
			hasSelected={Boolean(minValue) || (Boolean(maxValue) && maxValue !== 100) || Boolean(value)}
			label={label}
			{...props}
			popupContent={
				<StageFilterContainer>
					<TopSection>
						<RadioListContianer>
							<Title>{label}</Title>
							<RadioButtonsList
								options={options}
								uniqueCode={name}
								selectedValue={isCustom ? 'custom' : value}
								handleChange={handleStatusChange}
							/>
						</RadioListContianer>
						<ConditionalGridSection hasFlag={isCustom}>
							<RadioContainer>
								<FormikNumberRange
									minId={minName}
									minName={minName}
									maxId={maxName}
									maxName={maxName}
									minValue={String(minValue)}
									maxValue={String(maxValue)}
									lowerBound={0}
									upperBound={100}
									setFieldValue={handleSetPercentages}
									minProps={{
										placeholder: '0',
										...minProps,
									}}
									maxProps={{
										placeholder: '100',
										...maxProps,
									}}
									isFilterInput
									hasClearButton={false}
									width={210}
								/>
							</RadioContainer>
						</ConditionalGridSection>
					</TopSection>
					<ClearButton onClick={handleClearOptions}>{t('Clear all')}</ClearButton>
				</StageFilterContainer>
			}
		/>
	);
};

StageFilter.propTypes = {
	name: '',
	label: '',
	value: '',
	minName: '',
	maxName: '',
	minProps: {},
	maxProps: {},
	minValue: '0',
	maxValue: '0',
	stageStatus: '',
	setFilterValue: () => {},
};

StageFilter.defaultProps = {
	value: undefined,
	minValue: undefined,
	maxValue: undefined,
	minProps: {},
	maxProps: {},
};

StageFilter.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string,
	label: PropTypes.string.isRequired,
	minName: PropTypes.string.isRequired,
	maxName: PropTypes.string.isRequired,
	minProps: PropTypes.shape({}),
	maxProps: PropTypes.shape({}),
	stageStatus: PropTypes.string.isRequired,
	setFilterValue: PropTypes.func.isRequired,
	minValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default StageFilter;
