import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';
import { useEffect } from 'react';
import UNITS from 'Common/constants/units';
import { PropTypes } from 'prop-types';
import projectTypes from 'Projects/constants/projectTypes';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: auto;
	padding: 28px;
	padding-top: 16px;
	gap: 16px;
`;

const ReadOnlyInfoWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;

	${KeyValueVisualization} {
		margin-bottom: 10px;
	}
`;

const OptionsWrapper = styled.div``;

const Title = styled.span`
	font-size: 20px;
	font-weight: 600;
	margin-right: 20px;
	margin-bottom: 20px;
	display: flex;
`;

const StyledCheckbox = styled(FormikCheckbox)`
	margin-top: 16px;
	margin-bottom: 16px;

	.MuiCheckbox-root {
		margin-left: 0px;
	}
`;

const Block = styled.div`
	width: 25%;
`;

const StyledFormField = styled(FormField)`
	display: flex;
	align-items: center;
	margin: 0;
`;

const CheckboxWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	${StyledFormField} {
		max-width: 25%;
		@media (max-width: 1300px) {
			max-width: 50%;
		}
	}
`;

const StyledDatePickerFormField = styled(FormField)`
	margin-top: 7px;
	margin-left: 16px;
`;

const Subheader = styled.p`
	text-align: center;
	margin: 0;
	margin-bottom: 8px;
`;

const RfepProjectSection = ({
	project,
	values,
	errors,
	touched,
	handleBlur,
	handleChange,
	setFieldTouched,
	setFieldValue,
}) => {
	const { t } = useTranslation();

	useEffect(() => {
		if (!values.rfepCloseDateActive) {
			setFieldTouched('tenderCloseDate', false);
		}
	}, [values.rfepCloseDateActive, setFieldTouched]);

	return (
		<Wrapper>
			<Subheader>
				The following Project details will be shared with the EPC Partner. <br />
				To make changes, update the Project first and return to the RFEP process.
			</Subheader>
			<ReadOnlyInfoWrapper>
				<Block>
					<Title>{t('Type')}</Title>
					<KeyValueVisualization title={t('Project Type')} value={project?.projectType?.name} />
					<KeyValueVisualization title={t('Energy storage')} value={project?.energyStorage ? 'Yes' : 'No'} />
					<KeyValueVisualization title={t('Gensets')} value={project?.gensets ? 'Yes' : 'No'} />
					{project.projectType?.name === projectTypes.PV && (
						<FormField data-first-field>
							<FormikInput
								label={t('System size')}
								tooltip={{
									placement: 'right',
									tooltip: t(
										'The suggested Project system size.  EPC Partners can adjust the System size in their offer before sending.',
									),
								}}
								id="systemSize"
								name="systemSize"
								error={errors.systemSize}
								touched={touched.systemSize}
								value={values.systemSize}
								onChange={handleChange}
								onBlur={handleBlur}
								isRequired
								unit={UNITS.KWP}
								isOverlay
							/>
						</FormField>
					)}
				</Block>
				<Block>
					<Title>{t('Mounting')}</Title>
					<KeyValueVisualization
						title={t('Mounting type')}
						value={
							project?.mountingTypes.length > 0
								? project?.mountingTypes.map(type => type.displayName).join(', ')
								: ''
						}
					/>
					<KeyValueVisualization title={t('Available space')} unit={UNITS.M2} value={project?.availableSpace} />
					<KeyValueVisualization title={t('Roof type')} value={project?.roofType?.name} />
				</Block>
				<Block>
					<Title>{t('Gensets')}</Title>
					<KeyValueVisualization title={t('Generator size')} unit={UNITS.KVA} value={project?.generatorSize} />
					<KeyValueVisualization title={t('Generator quantity')} value={project?.generatorQuantity} />
				</Block>
				<Block>
					<Title>{t('Connection')}</Title>
					<KeyValueVisualization title={t('Meter quantity')} value={project?.meterQuantity} />
					<KeyValueVisualization title={t('Connection points')} value={project?.connectionPoints} />
				</Block>
			</ReadOnlyInfoWrapper>
			<OptionsWrapper>
				<Title>{t('RFEP options')}</Title>
				<CheckboxWrapper>
					<StyledFormField>
						<StyledCheckbox
							label={t('Request a Genset quote')}
							id="requestGensetQuote"
							name="requestGensetQuote"
							checked={values.requestGensetQuote}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.requestGensetQuote}
						/>
						<QuestionTooltip
							large
							tooltip={<>The EPC Partner will be asked to include a genset in their quote.</>}
						/>
					</StyledFormField>
					<StyledFormField>
						<StyledCheckbox
							label={t('Request Offer validity')}
							id="requestOfferValidity"
							name="requestOfferValidity"
							checked={values.requestOfferValidity}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.requestOfferValidity}
						/>
						<QuestionTooltip
							large
							tooltip={<>The EPC Partner will be asked to specify how long their offer is valid for.</>}
						/>
					</StyledFormField>
					<StyledFormField>
						<StyledCheckbox
							label={t('Set RFEP SaaS signature date')}
							id="rfepCloseDateActive"
							name="rfepCloseDateActive"
							checked={values.rfepCloseDateActive}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.rfepCloseDateActive}
						/>
						<QuestionTooltip
							large
							tooltip={<>EPC Partners must respond to the Tender before the end of this day.</>}
						/>
					</StyledFormField>
					{values.rfepCloseDateActive && (
						<StyledFormField data-last-field>
							<StyledDatePickerFormField>
								<FormikSingleDatePicker
									id="tenderCloseDate"
									name="tenderCloseDate"
									startDateName="tenderCloseDate"
									error={errors.tenderCloseDate}
									touched={touched.tenderCloseDate}
									startDate={values.tenderCloseDate}
									setFieldValue={setFieldValue}
									setFieldTouched={setFieldTouched}
									isOverlay
								/>
							</StyledDatePickerFormField>
						</StyledFormField>
					)}
				</CheckboxWrapper>
				{/* <CheckboxWrapper>
                    <ChecboxDatePickerWrapper>
                        <StyledCheckbox
                            label={t('Set RFEP SaaS signature date')}
                            id="rfepCloseDateActive"
                            name="rfepCloseDateActive"
                            checked={values.rfepCloseDateActive}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            touched={touched.rfepCloseDateActive}
                        />
                        <StyledDatePickerFormField>
                            <FormikSingleDatePicker
                                id="tenderCloseDate"
                                startDateName="tenderCloseDate"
                                error={errors.tenderCloseDate}
                                touched={touched.tenderCloseDate}
                                startDate={values.tenderCloseDate}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                isForm
                            />
                        </StyledDatePickerFormField>
                        <StyledQuestionTooltip
                            large
                            tooltip={
                                <>
                                    EPC Partners must respond to the Tender
                                    before the end of this day.
                                </>
                            }
                        />
                    </ChecboxDatePickerWrapper>
                </CheckboxWrapper> */}
			</OptionsWrapper>
		</Wrapper>
	);
};

RfepProjectSection.defaultProps = {
	project: {},
	values: {},
	errors: {},
	touched: {},
	handleBlur: () => {},
	handleChange: () => {},
	setFieldTouched: () => {},
	setFieldValue: () => {},
};

RfepProjectSection.propTypes = {
	project: PropTypes.shape({
		projectType: PropTypes.shape({ name: PropTypes.string }),
		energyStorage: PropTypes.bool,
		gensets: PropTypes.bool,
		mountingTypes: PropTypes.arrayOf(PropTypes.shape({})),
		availableSpace: PropTypes.number,
		roofType: PropTypes.string,
		generatorSize: PropTypes.number,
		generatorQuantity: PropTypes.number,
		meterQuantity: PropTypes.number,
		connectionPoints: PropTypes.string,
	}),
	values: PropTypes.shape({
		rfepCloseDateActive: PropTypes.bool,
		systemSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		requestGensetQuote: PropTypes.bool,
		tenderCloseDate: PropTypes.instanceOf(Date),
		requestOfferValidity: PropTypes.bool,
	}),
	errors: PropTypes.shape({
		tenderCloseDate: PropTypes.string,
		systemSize: PropTypes.string,
	}),
	touched: PropTypes.shape({
		rfepCloseDateActive: PropTypes.bool,
		systemSize: PropTypes.bool,
		requestGensetQuote: PropTypes.bool,
		tenderCloseDate: PropTypes.bool,
		requestOfferValidity: PropTypes.bool,
	}),
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	setFieldTouched: PropTypes.func,
	setFieldValue: PropTypes.func,
};

export default RfepProjectSection;
