import { store } from 'Application/store';

const isInAuthorizedGroups = authorizedRolesGroups => {
	const userRoleGroups = store.getState().user.roleGroups || [];

	if (!userRoleGroups) {
		return false;
	}

	const intersectingRoleGroups = authorizedRolesGroups.filter(roleGroup => userRoleGroups.includes(roleGroup));

	return intersectingRoleGroups.length > 0;
};

export default isInAuthorizedGroups;
