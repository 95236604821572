import { PropTypes } from 'prop-types';
import Overlay, { OverlayButtons } from 'Common/components/modals/Overlay';
import { DefaultTable } from '../table/table';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import ReactTimeAgo from 'react-time-ago';
import Icon from 'Common/components/icons/Icon';
import { FILE_TYPES } from 'Common/constants/fileTypes';

const DownloadIcon = styled(Icon).attrs({
	icon: 'download',
	// color: colors.text.black,
})`
	cursor: pointer;
	color: ${colors.text.black};
	&:hover {
		color: ${colors.common.lightGreen};
	}
`;

const NameContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
`;

const Name = styled.div``;

const DocumentHistoryOverlay = ({ documents, isOpen, onClose, onDocumentDownload, breadcrumbList }) => {
	const { t } = useTranslation();

	const breadcrumbs = useMemo(
		() => [
			...breadcrumbList,
			{
				label: 'Document history',
			},
		],
		[breadcrumbList],
	);

	const columns = useMemo(
		() => [
			{
				Header: t('Document'),
				accessor: 'name',
				customCellStyles: {
					flex: '1000 0 0',
				},
			},
			{
				Header: t('Uploaded'),
				accessor: 'createdDate',
				maxWidth: 100,
			},
			{
				accessor: 'actions',
				maxWidth: 50,
			},
		],
		[t],
	);

	const downloadFile = useCallback(
		doc => {
			onDocumentDownload(doc);
		},
		[onDocumentDownload],
	);

	const data = useMemo(
		() =>
			documents.map(doc => ({
				name: (
					<NameContainer>
						<Icon icon={FILE_TYPES.DOCX} />
						<Name>{doc.displayName || doc.name}</Name>
					</NameContainer>
				),
				createdDate: <ReactTimeAgo date={doc.createdDate ? new Date(doc.createdDate) : new Date()} />,
				actions: <DownloadIcon onClick={() => downloadFile(doc)} />,
			})),
		[documents, downloadFile],
	);

	const { getTableProps, getTableHeaderProps, getTableBodyProps, prepareRow, headerGroups, rows } =
		useStandardTable(
			{
				data,
				columns,
			},
			useCustomCellStyles,
		);
	return (
		<Overlay label="document-history-overlay" breadcrumbList={breadcrumbs} isOpen={isOpen} onClose={onClose}>
			{(setIsFormDirty, _, onCancel) => (
				<>
					<DefaultTable
						getTableProps={getTableProps}
						getTableHeaderProps={getTableHeaderProps}
						getTableBodyProps={getTableBodyProps}
						prepareRow={prepareRow}
						rows={rows}
						headerGroups={headerGroups}
					/>
					<OverlayButtons onCancel={onCancel} label="Document History List" noSubmitButton />
				</>
			)}
		</Overlay>
	);
};

DocumentHistoryOverlay.defaultProps = {
	documents: [],
	isOpen: false,
	onClose: () => {},
	onDocumentDownload: () => {},
	breadcrumbList: [],
};

DocumentHistoryOverlay.propTypes = {
	documents: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			createdDate: PropTypes.string,
		}),
	),
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onDocumentDownload: PropTypes.func,
	breadcrumbList: PropTypes.arrayOf(
		PropTypes.shape({ label: PropTypes.string.isRequired, href: PropTypes.string }),
	),
};

export default DocumentHistoryOverlay;
