import api from 'Application/api/api';

const getAccountingStandards = (signal, query) =>
	api.get('/accounting-standards', {
		signal,
		params: {
			name: query,
		},
		timeout: 100000,
	});

export default getAccountingStandards;
