import PropTypes from 'prop-types';
import ModalForm from 'Common/components/form/ModalForm';
import { useTranslation } from 'react-i18next';

import GenerateAttachmentForm from 'Invoice/components/Forms/GenerateAttachmentForm';

const GenerateAttachmentModal = ({ invoice, onSubmit, isOpen, onClose, countryId }) => {
	const { t } = useTranslation();

	const onFormSubmit = (...args) => {
		onSubmit(...args);
		onClose();
	};

	return (
		<ModalForm
			label="select-invoice-template"
			heading={t('Generate Invoice')}
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<GenerateAttachmentForm
					invoice={invoice}
					countryId={countryId}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};
GenerateAttachmentModal.defaultProps = {
	isOpen: false,
	invoice: {},
	onClose: () => {},
};
GenerateAttachmentModal.propTypes = {
	countryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	invoice: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	}),
	onSubmit: PropTypes.func.isRequired,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
};

export default GenerateAttachmentModal;
