import api from 'Application/api/api';

const getClientContractDownloadLink = async (signal, fileName, versionId) => {
	const response = await api.get(
		`/client-contracts/download-link?fileName=${fileName}&versionId=${versionId}`,
		{
			signal,
		},
	);

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
	};
};

export default getClientContractDownloadLink;
