import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useOverviewFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				externalFinance: yup.boolean(),
				name: yup.string().required(t('Required')),
				co2: yup.number().integer().typeError(t('CO2 savings must be a number')),
				minimumVolume: yup
					.number()
					.test('decimal-places', 'Must have no more than 2 decimal place', function () {
						if (!this.originalValue) {
							return true;
						}
						return String(this.originalValue).match(/^\d*(\.\d{0,2})?$/);
					})
					.integer()
					.typeError(t('Min finance volume must be a number'))
					.required('Required'),
				maximumVolume: yup
					.number()
					.test('decimal-places', 'Must have no more than 2 decimal place', function () {
						if (!this.originalValue) {
							return true;
						}
						return String(this.originalValue).match(/^\d*(\.\d{0,2})?$/);
					})
					.integer()
					.typeError(t('Max finance volume must be a number'))
					.required('Required')
					.when('minimumVolume', (minimumVolume, schema) =>
						schema.min(minimumVolume, t('Max finance volume must be greater than min finance volume')),
					),
				externalId: yup.number().integer().typeError(t('ID must be a number')),
				externalVolume: yup
					.number()
					.test('decimal-places', 'Must have no more than 2 decimal place', function () {
						if (!this.originalValue) {
							return true;
						}
						return String(this.originalValue).match(/^\d*(\.\d{0,2})?$/);
					})
					.integer()
					.typeError(t('Volume must be a number'))
					.nullable()
					.when('externalFinance', {
						is: true,
						then: yup.number().integer().required(t('Required')),
						otherwise: null,
					}),
				provider: yup.string().when('externalFinance', {
					is: true,
					then: yup.string().required(t('Required')),
					otherwise: null,
				}),
			}),
		[t],
	);
};

export default useOverviewFormValidationSchema;
