import api from 'Application/api/api';

const deleteComment = async (signal, id) => {
	const response = await api.delete(`/step-comments/${id}`, {
		signal,
		timeout: 100000,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: response.data,
	};
};

export default deleteComment;
