import api from 'Application/api/api';

const getProductTypes = signal =>
	api.get('/product-types', {
		params: {
			details: true,
		},
		signal,
	});

export default getProductTypes;
