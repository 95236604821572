import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import { SectionWrapper } from 'Common/components/modals/OverlaySections';
import loadCountryOptions from 'Country/utils/loadCountryOptions';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AddressSection = ({
	values,
	errors,
	touched,
	handleBlur,
	handleChange,
	setFieldValue,
	setFieldTouched,
}) => {
	const { t } = useTranslation();

	return (
		<SectionWrapper $minWidth={300}>
			<FormField data-first-field>
				<FormikSelect
					isAsync
					id="countryModal"
					name="country"
					label={t('Country')}
					value={values.country}
					error={errors.country}
					touched={touched.country}
					setFieldValue={setFieldValue}
					setFieldTouched={setFieldTouched}
					onBlur={handleBlur}
					loadOptions={loadCountryOptions}
					isOverlay
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="apartmentBuilding"
					name="apartmentBuilding"
					label={t('Apartment / Building')}
					value={values.apartmentBuilding}
					error={errors.apartmentBuilding}
					touched={touched.apartmentBuilding}
					onChange={handleChange}
					setFieldTouched={setFieldTouched}
					onBlur={handleBlur}
					isOverlay
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="line1"
					name="line1"
					label={t('Address line 1')}
					value={values.line1}
					error={errors.line1}
					touched={touched.line1}
					onChange={handleChange}
					setFieldTouched={setFieldTouched}
					onBlur={handleBlur}
					isOverlay
				/>
			</FormField>

			<FormField>
				<FormikInput
					id="line2"
					name="line2"
					label={t('Address line 2')}
					value={values.line2}
					error={errors.line2}
					touched={touched.line2}
					onChange={handleChange}
					setFieldTouched={setFieldTouched}
					onBlur={handleBlur}
					isOverlay
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="cityRegion"
					name="cityRegion"
					label={t('City / Region')}
					value={values.cityRegion}
					error={errors.cityRegion}
					touched={touched.cityRegion}
					onChange={handleChange}
					setFieldTouched={setFieldTouched}
					onBlur={handleBlur}
					isOverlay
				/>
			</FormField>
			<FormField data-last-field>
				<FormField>
					<FormikInput
						id="postCodeZip"
						name="postCodeZip"
						label={t('Post Code / Zip')}
						value={values.postCodeZip}
						error={errors.postCodeZip}
						touched={touched.postCodeZip}
						onChange={handleChange}
						setFieldTouched={setFieldTouched}
						onBlur={handleBlur}
						isOverlay
					/>
				</FormField>
			</FormField>
		</SectionWrapper>
	);
};

AddressSection.defaultProps = {
	values: {},
	errors: {},
	touched: {},
	handleBlur: () => {},
	handleChange: () => {},
	setFieldValue: () => {},
	setFieldTouched: () => {},
};

AddressSection.propTypes = {
	values: PropTypes.shape({
		country: PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		}),
		apartmentBuilding: PropTypes.string,
		line1: PropTypes.string,
		line2: PropTypes.string,
		cityRegion: PropTypes.string,
		postCodeZip: PropTypes.string,
	}),
	errors: PropTypes.shape({
		country: PropTypes.string,
		apartmentBuilding: PropTypes.string,
		line1: PropTypes.string,
		line2: PropTypes.string,
		cityRegion: PropTypes.string,
		postCodeZip: PropTypes.string,
	}),
	touched: PropTypes.shape({
		country: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
		apartmentBuilding: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
		line1: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
		line2: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
		cityRegion: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
		postCodeZip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	}),
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	setFieldValue: PropTypes.func,
	setFieldTouched: PropTypes.func,
};

export default AddressSection;
