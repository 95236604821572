import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import TableField from 'Common/components/form/Table/TableField';

const TableCheckbox = props => (
	<TableField>
		<FormikCheckbox {...props} />
	</TableField>
);

export default TableCheckbox;
