import { Navigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import useConfirmationCodeValidity from 'User/hooks/useConfirmationCodeValidity';
import ConfirmAccountForm from 'User/components/ConfirmAccount/ConfirmAccountForm/ConfirmAccountForm';
import { Wrapper, LoadingIcon } from 'User/components/guestScreenComponents';
import H1 from 'Common/components/headers/H1';
import H5 from 'Common/components/headers/H5';
import Link from 'Common/components/Link';
import backgroundImage from 'Common/assets/images/auth-background.jpg';

const ConfirmAccount = () => {
	const { t } = useTranslation();

	const email = useQueryParameter('email');
	const code = useQueryParameter('code');

	const { isValid: isCodeValid, isChecking: isCheckingCodeValidity } = useConfirmationCodeValidity(
		email,
		code,
	);

	if (!email || !code) {
		return <Navigate to="/login" />;
	}

	return (
		<>
			<Helmet>
				<title>{t('Confirm Account')}</title>
			</Helmet>
			<Wrapper backgroundImage={backgroundImage}>
				<H1>{t('Welcome to ecoligo')}</H1>
				{isCheckingCodeValidity ? (
					<>
						<H5>{t('Checking code validity...')}</H5>
						<H5>
							<LoadingIcon />
						</H5>
					</>
				) : isCodeValid ? (
					<>
						<H5>{t('Please enter and confirm your new password below')}</H5>
						<ConfirmAccountForm />
					</>
				) : (
					<>
						<H5>{t('This confirmation link is not valid')}</H5>
						<H5>
							<Link to="/login">go back to login page</Link>
						</H5>
					</>
				)}
			</Wrapper>
		</>
	);
};

export default ConfirmAccount;
