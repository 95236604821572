export const getChartGradient = (chartRef, type, colorFrom, colorTo) => {
	const gradient =
		type === 'radial'
			? chartRef.ctx?.createRadialGradient(0, 0, 0, 0, 0, chartRef.height)
			: chartRef.ctx?.createLinearGradient(0, 0, 0, chartRef.height);
	gradient.addColorStop(0, colorFrom);
	gradient.addColorStop(1, colorTo);

	return gradient;
};
