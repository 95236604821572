import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { DefaultTable, TableBodyCell, TableHeadCell } from 'Common/components/table/table';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import formatNumber from 'Common/utils/formatNumber';
import formatCurrency from 'Common/utils/formatCurrency';
import Link from 'Common/components/Link';
import Icon from 'Common/components/icons/Icon';
import colors from 'Application/theme/colors';
import { useCustomFooterStyle } from 'Common/hooks/table/plugin-hooks/useCustomFooterStyle';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import { PropTypes } from 'prop-types';
import TableCellText from 'Common/components/table/TableCellText';

const Wrapper = styled.div`
	position: relative;
`;

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`}

	${TableHeadCell} {
		background: transparent;
	}

	${TableBodyCell} {
		background: transparent;
	}
`;

const StyledIcon = styled(Icon)`
	margin-left: 10px;
`;

const FooterText = styled.div`
	white-space: break-spaces;
	min-width: ${({ width }) => width}px;
`;

const VolumeWrapper = styled.div`
	padding-left: 5px;
`;

const hasMissingData = project =>
	project.annualCO2Savings === null ||
	project.co2Savings === null ||
	project.predictedAnnualEnergySavings === null;

const formatId = id => `${'0'.repeat(5 - String(id).length)}${id}`;

const ProjectsList = ({
	projects,
	projectsWithDetails,
	totalCampaignKwp,
	totalEpcVolumeContribution,
	isDataBeingEdited,
	shouldHideFooter,
}) => {
	const { t } = useTranslation();

	const totalCampaignAnnualCo2Savings = useMemo(
		() => projectsWithDetails.reduce((acc, project) => acc + Number(project?.annualCO2Savings?.toFixed(1)), 0),
		[projectsWithDetails],
	);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Project'),
				accessor: 'project',
				width: 80,
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => (
						<Link
							color="#00b4d5"
							fontSize="14px"
							to={`/projects/details/${project.projectId}`}
							onClick={e => e.stopPropagation()}
						>
							{formatId(project.externalId)}
						</Link>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ projectId: PropTypes.number, externalId: PropTypes.number }),
						}).isRequired,
					};
					return Cell;
				})(),
				customFooterCellStyles: {
					width: '80px',
				},
				customFooterCellContentStyles: {
					width: '80px',
				},
			},
			{
				Header: t('System size'),
				accessor: 'systemSize',
				width: 90,
				Cell: (() => {
					const Cell = ({ row: { original } }) =>
						!isDataBeingEdited
							? `${formatNumber(original?.systemSizeAllocatedValue, 2)} kWp`
							: `${formatNumber(original?.systemSizeAllocatedValue, 2)} kWp`;
					Cell.propTypes = { row: PropTypes.shape({ original: PropTypes.shape({}) }).isRequired };
					return Cell;
				})(),
				Footer: <FooterText>{totalCampaignKwp ? `${formatNumber(totalCampaignKwp, 2)} kWp` : '-'}</FooterText>,
				customFooterCellStyles: {
					width: '90px',
				},
				customFooterCellContentStyles: {
					fontWeight: 'bold',
					color: colors.text.black,
				},
			},
			{
				Header: t('Annual CO2'),
				accessor: 'annualCO2',
				maxWidth: 90,
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => (
						<TableCellText>
							{project.annualCO2Savings ? `${formatNumber(project.annualCO2Savings, 1)} t` : '-'}
						</TableCellText>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								annualCO2Savings: PropTypes.number,
							}),
						}).isRequired,
					};
					return Cell;
				})(),
				Footer: (
					<FooterText>
						{totalCampaignAnnualCo2Savings ? `${formatNumber(totalCampaignAnnualCo2Savings, 1)} t` : '-'}
					</FooterText>
				),
				customFooterCellStyles: {
					padding: '8px 0',
					maxWidth: '90px',
				},
				customFooterCellContentStyles: {
					fontWeight: 'bold',
					color: colors.text.black,
				},
			},
			{
				Header: t('EPC Volume'),
				accessor: 'epcVolume',
				width: 100,
				Cell: (() => {
					const Cell = ({ row: { original: project } }) => {
						const formatedVolume = formatCurrency(project?.epcVolumeContribution);
						return <VolumeWrapper>{project?.epcVolumeContribution ? formatedVolume : '-'}</VolumeWrapper>;
					};
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ epcVolumeContribution: PropTypes.number }) })
							.isRequired,
					};
					return Cell;
				})(),
				Footer: (
					<FooterText>{totalEpcVolumeContribution ? formatCurrency(totalEpcVolumeContribution) : '-'}</FooterText>
				),
				customFooterCellStyles: {
					padding: '8px 0',
					paddingLeft: '10px',
				},
				customFooterCellContentStyles: {
					fontWeight: 'bold',
					color: colors.text.black,
				},
			},
			{
				Header: t(''),
				accessor: 'missingData',
				width: 30,
				minWidth: 30,
				customCellStyles: {
					padding: 0,
				},
				Cell: (() => {
					const Cell = ({ row: { original: project } }) =>
						hasMissingData(project) ? (
							<HoverTooltip
								placement="right"
								title={t('Cannot calculate statistics for this Project. Check the Project values.')}
								arrow
							>
								<StyledIcon icon="reportProblem" color={colors.warning.main} />
							</HoverTooltip>
						) : (
							''
						);
					Cell.propTypes = { row: PropTypes.shape({ original: PropTypes.shape({}) }).isRequired };
					return Cell;
				})(),
				Footer: '',
			},
		],
		[t, totalCampaignKwp, totalEpcVolumeContribution, totalCampaignAnnualCo2Savings, isDataBeingEdited],
	);

	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			projects.map(project => ({
				// eslint-disable-next-line react/prop-types
				...projectsWithDetails.find(projectWithDetails => projectWithDetails.externalId === project.externalId),
				...project,
			})),
		[projects, projectsWithDetails],
	);

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		getTableFooterProps,
		footerGroups,
		headerGroups,
		rows,
		prepareRow,
	} = useStandardTable(
		{
			data,
			columns,
			getCustomFooterStyles: () => ({
				background: colors.common.tileBackgroundGrey,
				fontWeight: 'bold!important',
				color: colors.text.black,
			}),
			getRowBasedCustomCellStyles: row =>
				row.original.isNew
					? {
							background: 'rgba(25, 159, 59, 0.1)',
					  }
					: {},
		},
		useCustomCellStyles,
		useCustomFooterStyle,
	);

	return (
		<Wrapper>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
					getTableFooterProps={shouldHideFooter ? () => null : getTableFooterProps}
					footerGroups={shouldHideFooter ? {} : footerGroups}
				/>
			</TableWrapper>
		</Wrapper>
	);
};

ProjectsList.defaultProps = {
	isDataBeingEdited: false,
	shouldHideFooter: false,
};

ProjectsList.propTypes = {
	projects: PropTypes.arrayOf(PropTypes.shape({ externalId: PropTypes.number })).isRequired,
	projectsWithDetails: PropTypes.arrayOf(PropTypes.shape({ externalId: PropTypes.number })).isRequired,
	totalCampaignKwp: PropTypes.number.isRequired,
	totalEpcVolumeContribution: PropTypes.number.isRequired,
	isDataBeingEdited: PropTypes.bool,
	shouldHideFooter: PropTypes.bool,
};

export default ProjectsList;
