import api from 'Application/api/api';
import adaptEpcContractRequestData from 'EpcContract/api/adapters/adaptEpcContractRequestData';
import adaptEpcContractResponseData from 'EpcContract/api/adapters/adaptEpcContractResponseData';

const createEpcContract = async (signal, values) => {
	const response = await api.post('/epc-contracts', adaptEpcContractRequestData(values), {
		signal,
		timeout: 100000,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptEpcContractResponseData(response.data),
	};
};

export default createEpcContract;
