import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import Grid from '@mui/material/Grid';

import Button from 'Common/components/buttons/Button';
import Loader from 'Common/components/Loader';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';

import getProjectById from 'Projects/api/getProjectById';
import loadProjectOptions from 'Projects/utils/loadProjectOptions';

import ProjectInfoTile from 'Campaigns/components/ProjectInfoTile/ProjectInfoTile';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';

const ProjectCardsWrapper = styled.div`
	margin-top: 20px;
`;

const StyledFormField = styled(FormField)`
	margin-bottom: 12px !important;
`;

const LoaderContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ProjectsEdit = ({
	handleChange,
	onDirty,
	campaign,
	setFieldValue,
	values,
	dirty,
	errors,
	touched,
	handleBlur,
	saveProjects,
}) => {
	const { t } = useTranslation();

	const [selectedProject, setSelectedProject] = useState(null);

	const [projectOptions, setProjectOptions] = useState([]);
	const [isLoadingProject, setIsLoadingProjects] = useState(false);
	const [isLoadingSelectedProjects, setIsLoadingSelectedProjects] = useState(false);
	const [fullProjects, setFullProjects] = useState([]);

	const abortController = useAbortController();

	const filterOption = ({ label }, searchValue) => label?.toLowerCase()?.includes(searchValue?.toLowerCase());

	const fetchProjectOptions = useCallback(async () => {
		setIsLoadingProjects(true);
		try {
			const response = await loadProjectOptions();
			setProjectOptions(response);
			setIsLoadingProjects(false);
		} catch (e) {
			showToastError(e, t("Can't load the project options"));
		}
	}, [t]);

	useEffect(() => {
		fetchProjectOptions();
	}, [fetchProjectOptions]);

	const setProjectsFromCampaign = useCallback(async () => {
		if (campaign?.finance_details?.projects && campaign?.finance_details?.projects.length > 0) {
			try {
				setIsLoadingSelectedProjects(true);
				const responses = await Promise.all(
					campaign.finance_details.projects.map(e => getProjectById(abortController.signal, e.id, true)),
				);

				setFullProjects(prev => [...prev, ...responses.map(e => e.data)]);
				const campaignId = campaign.finance_details.id;

				setFieldValue(
					'financeDetailsProjects',
					responses.map(e => {
						let allocatedCampaignSize;
						e?.data.financeDetailsProjects.reduce((sum, current) => {
							if (current.financeDetailsId !== campaignId) {
								return sum + current.systemSizeAllocatedValue;
							}
							allocatedCampaignSize = current.systemSizeAllocatedValue;
							return sum;
						}, 0);

						const financeDetailsProjects = [...e.data.financeDetailsProjects].map(projectData => ({
							...projectData,
							annualCo2SavingsEditable: projectData.annualCo2SavingsEditable
								? projectData.annualCo2SavingsEditable
								: 0,
							annualCo2Contribution: projectData.annualCo2Contribution ? projectData.annualCo2Contribution : 0,
						}));

						return {
							...e.data,
							projectId: e.data.id,
							isNew: false,
							financeDetailsProjects,
							financeDetailsId: campaign.auto_id,
							systemSizeAllocatedValue: allocatedCampaignSize,
						};
					}),
				);
				setIsLoadingSelectedProjects(false);
			} catch (e) {
				showToastError(e, t("Can't fetch selected campaign projects data"));
			}
		}
	}, [campaign, setFieldValue, abortController.signal, t]);
	useEffect(() => {
		setProjectsFromCampaign();
	}, [setProjectsFromCampaign]);

	const handleAddProject = async () => {
		try {
			setIsLoadingSelectedProjects(true);
			const projectResponse = await getProjectById(abortController.signal, selectedProject.value, true);
			setFullProjects(prev => [...prev, projectResponse.data]);

			const allocatedSize = projectResponse?.data.financeDetailsProjects.reduce(
				(sum, current) => sum + current.systemSizeAllocatedValue,
				0,
			);

			const systemSizeAllocatedValue = (
				projectResponse?.data.systemSizeKwp - (projectResponse?.data.financeDetailsProjects ? allocatedSize : 0)
			).toFixed(2);

			await saveProjects({
				...values,
				financeDetailsProjects: [
					...values.financeDetailsProjects,
					{
						projectId: selectedProject.value,
						financeDetailsId: campaign?.auto_id,
						financeDetailsProjects: [
							{
								annualCo2Contribution: projectResponse?.data?.annualCO2Savings
									? projectResponse?.data?.annualCO2Savings
									: 0,
								annualCo2SavingsEditable: projectResponse?.data?.annualCO2Savings
									? projectResponse?.data?.annualCO2Savings.toFixed(3)
									: 0,
								epcVolumeContribution: projectResponse?.data?.annualOmPrice?.value
									? projectResponse?.data?.annualOmPrice?.value
									: 0,
								externalId: projectResponse?.data?.externalId ? projectResponse?.data?.externalId : null,
								financeDetailsId: campaign?.finance_details?.id,
								projectId: selectedProject.value,
								systemSizeAllocatedValue: projectResponse?.data.financeDetailsProjects ? allocatedSize : 0,
							},
						],
						systemSizeAllocatedValue,
					},
				],
			});

			notify(t('Project added to campaign'), {
				type: toast.TYPE.SUCCESS,
			});

			setSelectedProject(null);

			// Hide selected project from project select options
			setProjectOptions(prev => {
				const projectOptions = [...prev];

				const selectedProjectIndex = projectOptions.findIndex(option => option.value === selectedProject.value);

				projectOptions[selectedProjectIndex] = {
					...projectOptions[selectedProjectIndex],
					isHidden: true,
				};

				return projectOptions;
			});

			setIsLoadingSelectedProjects(false);
		} catch (e) {
			showToastError(e, t("Can't add a new project to the campaign"));
		}
	};

	const handleRemoveProject = async id => {
		//Remove project from listed projects
		const currentSelectedProjects = [...values.financeDetailsProjects];

		currentSelectedProjects.splice(
			currentSelectedProjects.findIndex(e => e.projectId === id),
			1,
		);

		try {
			setIsLoadingSelectedProjects(true);
			await saveProjects({
				...values,
				financeDetailsProjects: currentSelectedProjects,
			});

			// Unhide project from projects select
			setProjectOptions(prev => {
				const projectOptions = [...prev];

				const selectedProjectIndex = projectOptions.findIndex(option => option.value === id);

				projectOptions[selectedProjectIndex] = {
					...projectOptions[selectedProjectIndex],
					isHidden: false,
				};
				return projectOptions;
			});

			notify(t('Project removed from campaign'), {
				type: toast.TYPE.SUCCESS,
			});
			setIsLoadingSelectedProjects(false);
		} catch (e) {
			showToastError(e, t("Can't remove a project from the campaign"));
		}
	};

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<>
			<Grid container justifyContent="center" alignItems="center">
				<Grid item xs={12}>
					<StyledFormField>
						<FormikSelect
							id="projects"
							name="projects"
							label={t('Add Project')}
							value={selectedProject}
							onChange={setSelectedProject}
							isClearable
							onBlur={handleBlur}
							options={projectOptions.filter(option => !option.isHidden)}
							isLoading={isLoadingProject}
							touched={touched.financeDetailsProjects}
							filterOption={filterOption}
						/>
					</StyledFormField>
				</Grid>
			</Grid>
			<Grid container justifyContent="center" alignItems="center" direction="column">
				<Grid item xs={6}>
					<Button
						onClick={handleAddProject}
						disabled={!selectedProject}
						text={t('Add Project')}
						label="Campaign Details General Project - Add Project Button"
					/>
				</Grid>
			</Grid>

			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<ProjectCardsWrapper>
					{isLoadingSelectedProjects ? (
						<LoaderContainer>
							<Loader />
						</LoaderContainer>
					) : (
						values?.financeDetailsProjects.map((projectFinancialDetails, index) => (
							<ProjectInfoTile
								key={projectFinancialDetails.projectId}
								project={fullProjects.find(project => project.id === projectFinancialDetails.projectId) ?? {}}
								values={projectFinancialDetails}
								errors={errors?.financeDetailsProjects?.[index]}
								touched={touched?.financeDetailsProjects?.[index]}
								namePrefix={`financeDetailsProjects[${index}]`}
								handleChange={handleChange}
								handleBlur={handleBlur}
								setFieldValue={setFieldValue}
								removeProject={handleRemoveProject}
								isInEditMode
								campaignId={campaign?.finance_details?.id}
							/>
						))
					)}
				</ProjectCardsWrapper>
			</Grid>
		</>
	);
};

ProjectsEdit.defaultProps = {
	onDirty: undefined,
	campaign: null,
	values: null,
	dirty: false,
	errors: null,
	touched: null,
};

ProjectsEdit.propTypes = {
	handleChange: PropTypes.func.isRequired,
	onDirty: PropTypes.func,
	campaign: PropTypes.shape({
		finance_details: PropTypes.shape({
			projects: PropTypes.arrayOf(PropTypes.shape({})),
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		auto_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	setFieldValue: PropTypes.func.isRequired,
	values: PropTypes.shape({
		financeDetailsProjects: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	dirty: PropTypes.bool,
	errors: PropTypes.shape({
		financeDetailsProjects: PropTypes.string,
	}),
	touched: PropTypes.shape({
		financeDetailsProjects: PropTypes.bool,
	}),
	handleBlur: PropTypes.func.isRequired,
	saveProjects: PropTypes.func.isRequired,
};

export default ProjectsEdit;
