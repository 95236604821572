import api from 'Application/api/api';

const getUsersWithFullName = (signal, options) => {
	const params = {
		...options,
	};

	return api.get('/users/get-all', {
		params,
		signal,
	});
};

export default getUsersWithFullName;
