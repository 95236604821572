import { createSlice } from '@reduxjs/toolkit';

const initialSidePanelData = {
	isOpen: false,
	type: undefined,
	isLoading: false,
	headerData: {},
	contentData: {},
	dirtyForm: [],
};

export const sidePanelSlice = createSlice({
	name: 'sidePanel',
	initialState: { ...initialSidePanelData },
	reducers: {
		closeSidePanel: () => initialSidePanelData,
		setSidePanelDirtyForm: (state, { payload: data }) => {
			state.dirtyForm = data;
		},
		updateSidePanelData: (state, { payload: data }) => {
			Object.assign(state, data);
		},
		updateSidePanelHeaderData: (state, { payload: data }) => {
			Object.assign(state.headerData, data);
		},
		updateSidePanelContentData: (state, { payload: data }) => {
			Object.assign(state.contentData, data);
		},
		updateSidePanelShowHistory: (state, { payload: data }) => {
			state.contentData.showHistory = data;
		},
	},
});

export const {
	closeSidePanel,
	updateModalIsOpen,
	setSidePanelDirtyForm,
	updateSidePanelData,
	updateSidePanelHeaderData,
	updateSidePanelContentData,
	updateSidePanelShowHistory,
} = sidePanelSlice.actions;

export default sidePanelSlice.reducer;
