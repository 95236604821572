import api from 'Application/api/api';

const getStepByActivity = (signal, activityTemplateId, details = true) => {
	const params = {
		details: details,
	};

	return api.get(`/step-templates/by-activity-template/${activityTemplateId}`, {
		params,
		signal,
	});
};

export default getStepByActivity;
