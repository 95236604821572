import api from 'Application/api/api';

const editBankAccount = async (signal, values) => {
	const response = await api.put('/bank-accounts', values, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: response.data,
	};
};

export default editBankAccount;
