import api from 'Application/api/api';
import adaptClientContractResponseData from './adapters/adaptClientContractResponseData';

const getClientContractById = async (signal, id, details) => {
	const response = await api.get(`/client-contracts/by-id/${id}`, {
		params: {
			details,
		},
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptClientContractResponseData(response.data),
	};
};

export default getClientContractById;
