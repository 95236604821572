import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Radio as MaterialRadio } from '@mui/material';
import Label from 'Common/components/form/Label';
import Mandatory from 'Common/components/form/Mandatory';
import { useRef } from 'react';
import colors from 'Application/theme/colors';
//import ErrorMessage from 'Common/components/form/ErrorMessage';

const Wrapper = styled.div`
	display: flex;
`;

const StyledRadio = styled(MaterialRadio)`
	&.MuiButtonBase-root {
		color: ${colors.grey.dark};

		&:hover {
			background-color: initial;
		}

		&.Mui-focusVisible {
		}

		&.Mui-checked {
			color: ${colors.primary.main};

			&:hover {
				background-color: initial;
			}
		}

		&.Mui-disabled {
			color: ${colors.grey.light};
		}
	}
`;

const StyledLabel = styled(Label)`
	padding: 0;
	cursor: pointer;
`;

const FormikStandaloneRadio = ({
	error,
	label,
	isRequired,
	tooltip,
	name,
	setFieldValue,
	onChange = null,
	...props
}) => {
	const hasError = !!error;
	const hasLabel = label !== null;

	const radioRef = useRef();

	const handleChange = e => {
		setFieldValue(name, e.target.value);
	};

	return (
		<Wrapper>
			{hasLabel ? (
				<StyledLabel
					label={
						<>
							<StyledRadio name={name} ref={radioRef} {...props} />
							{label}
							{isRequired && <Mandatory />}
						</>
					}
					hasError={hasError}
					htmlFor={props.id}
					tooltip={tooltip}
					onClick={() => (onChange ? onChange(prev => !prev) : setFieldValue(name, !props.value))}
				/>
			) : (
				<StyledRadio name={name} onChange={onChange ? onChange : handleChange} ref={radioRef} {...props} />
			)}
		</Wrapper>
	);
};

FormikStandaloneRadio.defaultProps = {
	label: null,
	error: null,
	isRequired: false,
	name: '',
	setFieldValue: () => null,
	tooltip: null,
	value: {},
};

FormikStandaloneRadio.propTypes = {
	label: PropTypes.string,
	error: PropTypes.shape({}),
	isRequired: PropTypes.bool,
	name: PropTypes.string.isRequired,
	setFieldValue: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	value: PropTypes.shape({}),
	tooltip: PropTypes.shape({}),
};

export default FormikStandaloneRadio;
