import { pick } from 'lodash';

const convertFormValuesToRequestData = values => ({
	...pick(values, ['website']),
	displayName: values.displayName.trim(),
	countryId: values.country ? values.country.value : null,
	address: {
		line1: values.line1,
		line2: values.line2,
		apartmentBuilding: values.apartmentBuilding,
		cityRegion: values.cityRegion,
		postCodeZip: values.postCodeZip,
	},
	primaryContact: {
		firstName: values.firstName,
		lastName: values.lastName,
		phone: values.phone,
		email: values.email,
	},
	productTypes: values.productTypes.map(productType => ({
		name: productType.value,
	})),
});

export default convertFormValuesToRequestData;
