import Icon from 'Common/components/icons/Icon';
import { useEffect, useMemo, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import { stages } from 'Projects/constants/stages';
import { setProjectData } from 'Projects/reduxProject';
import getProjectById from 'Projects/api/getProjectById';
import ProgressActivity from 'PMWorkflow/components/Activity/ProgressActivity/ProgressActivity';

import Accordion from 'Common/components/accordion/Accordion';
import useFixedHeader from 'Common/hooks/useFixedHeader';
import useHoverMarker from 'Common/hooks/useHoverMarker';
import AccordionSummary from 'Common/components/accordion/AccordionSummary';

import sizes from 'Application/theme/sizes';
import colors from 'Application/theme/colors';
import Loader from 'Common/components/Loader';
import { useTranslation } from 'react-i18next';
import useReponsive from 'Common/hooks/useResponsive';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const StyledStageContainer = styled.div`
	position: relative;
`;

const StyledStageAccordion = styled(Accordion)`
	border: solid 1.5px transparent;
	margin: 0 !important;

	&.hasMarker {
		border-color: ${colors.primary.main};
	}
`;

const FixedTitle = styled.div`
	position: fixed;
	display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
	flex-direction: row;
	justify-content: space-between;

	transition: all 0.6s ease-in;

	top: ${({ $top }) => $top}px;
	left: ${({ $left }) => $left}px;
	width: calc(100vw - ${({ $left }) => $left}px);
	padding: ${sizes.spacing(2)} ${sizes.spacing(5)};
	background: ${colors.grey.light};
	z-index: 990;
`;

const Title = styled.div`
	color: ${colors.text.greyDark};
	font-size: 16px;
	font-weight: 700;
	display: flex;
	align-items: center;
	text-transform: capitalize;
`;

const ActivitiesContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${sizes.spacing(0.25)};
`;

const PercentageInfo = styled.div`
	${({ isCompleted }) =>
		isCompleted &&
		css`
			color: ${colors.common.darkGreen};
		`}
`;

const CompletedIcon = () => (
	<svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.9982 1.8332L13.8232 0.658203L8.53984 5.94154L9.71484 7.11654L14.9982 1.8332ZM18.5315 0.658203L9.71484 9.47487L6.23151 5.99987L5.05651 7.17487L9.71484 11.8332L19.7148 1.8332L18.5315 0.658203ZM0.339844 7.17487L4.99818 11.8332L6.17318 10.6582L1.52318 5.99987L0.339844 7.17487Z"
			fill="#2AA4BC"
		/>
	</svg>
);

const CompletedIconContainer = styled.span`
	margin-left: ${sizes.spacing(1)};
`;

const StageContainer = ({ stage, offsets, status, ...props }) => {
	const headerRef = useRef();
	const markerContainerRef = useRef();
	const [isExpanded, setIsExpanded] = useState(false);
	const [isVisibleFixedStageTitle, setIsVisibleFixedStageTitle] = useState(false);

	useEffect(() => {
		setIsExpanded(stage.stageStatus === 'IN_PROGRESS');
	}, [stage.stageStatus]);

	useFixedHeader(headerRef, setIsVisibleFixedStageTitle);
	useHoverMarker(markerContainerRef, 'project-progress-marker');

	const iconStyle = {
		fontSize: 20,
		color: colors.text.greyDark,
		marginRight: 8,
	};

	const formattedPercentage = Math.floor(stage.completedPercentage);

	return (
		<StyledStageContainer data-tab-name={stages?.[stage.stage]?.split('_')?.join(' ')?.toLowerCase()}>
			<StyledStageAccordion
				ref={markerContainerRef}
				$isOpen={isExpanded}
				expanded={isExpanded}
				onChange={() => setIsExpanded(prevExpanded => !prevExpanded)}
				background={colors.grey.light}
				data-project-progress-marker
			>
				<AccordionSummary ref={headerRef}>
					<FixedTitle $top={offsets.top} $left={offsets.left} isVisible={isVisibleFixedStageTitle && isExpanded}>
						<Title>
							{isExpanded ? (
								<Icon icon="keyboardArrowDown" style={iconStyle} />
							) : (
								<Icon icon="keyboardArrowRight" style={iconStyle} />
							)}
							{stages?.[stage.stage]?.split('_')?.join(' ')?.toLowerCase()}
						</Title>
						<PercentageInfo isCompleted={stage.completedPercentage === 100}>
							<span>{formattedPercentage}%</span>
							{stage.completedPercentage === 100 && (
								<CompletedIconContainer>
									<CompletedIcon />
								</CompletedIconContainer>
							)}
						</PercentageInfo>
					</FixedTitle>
					<Title>
						{isExpanded ? (
							<Icon icon="keyboardArrowDown" style={iconStyle} />
						) : (
							<Icon icon="keyboardArrowRight" style={iconStyle} />
						)}
						{stages?.[stage.stage]?.split('_')?.join(' ')?.toLowerCase()}
					</Title>
					<PercentageInfo isCompleted={stage.completedPercentage === 100}>
						<span>{formattedPercentage}%</span>
						{stage.completedPercentage === 100 && (
							<CompletedIconContainer>
								<CompletedIcon />
							</CompletedIconContainer>
						)}
					</PercentageInfo>
				</AccordionSummary>
				<ActivitiesContainer>
					{stage?.activities?.map((activity, ind) => (
						<ProgressActivity
							status={status}
							key={activity.id}
							activity={activity}
							$zIndex={stage.activities.length - ind}
							tabName={stages?.[stage.stage]?.split('_')?.join(' ')?.toLowerCase()}
							{...props}
						/>
					))}
				</ActivitiesContainer>
			</StyledStageAccordion>
		</StyledStageContainer>
	);
};

const PositioningDot = styled.div`
	width: 100%;
`;

const LoaderContianer = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 994;
`;

const Wrapper = styled.div`
	position: relative;
	max-height: calc(100vh - ${({ $top }) => $top}px);
	overflow: auto;

	${({ isRefetchingProject }) =>
		isRefetchingProject &&
		css`
			&:before {
				content: '';
				position: fixed;
				left: ${({ $left, $isMobile }) => ($isMobile ? '0' : $left)}px;
				top: ${({ $top, $isMobile }) => ($isMobile ? '0' : $top)}px;
				width: 100%;
				height: 100%;
				background: #c1eff470;
				z-index: 994;
			}
		`}

	${({ $isMobile }) =>
		$isMobile &&
		css`
			max-height: fit-content;
			overflow: hidden;

			${LoaderContianer} {
				position: fixed;
			}

			* {
				font-size: 13px;
			}
		`}
`;

const StagesWrapper = styled.div`
	position: relative;
	padding: ${sizes.spacing(1.5)};
	display: flex;
	flex-direction: column;
	gap: ${sizes.spacing(1.5)};
	overflow: hidden;
	max-height: max-content;
`;

const ProgressTab = ({ project }) => {
	const { t } = useTranslation();
	const { isMobile } = useReponsive();
	const isShrankSidebar = useSelector(state => state.common.isSidebarShrank);
	const dispatch = useDispatch();
	const dotRef = useRef();
	const [isRefetchingProject, setIsRefetchingProject] = useState(false);

	const [offsets, setOffset] = useState({
		top: 0,
		left: 0,
	});

	const abortController = useAbortController();

	const refetchProgress = async newProjectData => {
		if (newProjectData && typeof newProjectData === 'object') {
			dispatch(setProjectData(newProjectData));
			return newProjectData;
		}
		setIsRefetchingProject(true);
		try {
			const response = await getProjectById(abortController.signal, project.id, true);
			dispatch(setProjectData(response.data));
			setIsRefetchingProject(false);
			return response.data;
		} catch (error) {
			showToastError(error, t('Something went wrong when refetching progress tab data'));
			if (!axios.isCancel(error)) {
				setIsRefetchingProject(false);
			}
		}
	};

	const onResize = useCallback(() => {
		if (!dotRef.current) return;

		if (isMobile) {
			const headerContainer = document.getElementById('project-header-container');

			setOffset({
				top: headerContainer.getBoundingClientRect().bottom,
				left: 0,
			});
		} else {
			setOffset({
				top: dotRef.current.getBoundingClientRect().y,
				left: isShrankSidebar ? 80 : 208,
			});
		}
	}, [isMobile, isShrankSidebar]);

	useEffect(() => {
		window.addEventListener('resize', onResize);

		return () => window.removeEventListener('resize', onResize);
	}, [onResize]);

	useEffect(() => {
		if (isShrankSidebar || !isShrankSidebar) {
			onResize();
			setTimeout(() => {
				onResize();
			}, 900);
		}
	}, [isShrankSidebar, onResize]);

	const projectStages = useMemo(
		() =>
			project?.projectStages
				?.reduce((acc, el) => {
					acc.push({
						...el,
						activities: el.activities
							.map(activity => ({
								...activity,
								steps: activity.steps?.filter(step => step.status !== 'NOT_APPLICABLE'),
							}))
							.filter(activity => activity.steps.length > 0)
							.map((act, ind) => ({ ...act, orderNumber: ind + 1 })),
					});
					return acc;
				}, [])
				?.filter(el => el.activities.length > 0),
		[project?.projectStages],
	);

	const customOrderedStages = projectStages
		?.map(stage => {
			let order;
			if (stage.stage === 'SALES') {
				order = 0;
			} else if (stage.stage === 'TECH') {
				order = 1;
			} else if (stage.stage === 'FUNDRAISING') {
				order = 2;
			} else if (stage.stage === 'BUILD') {
				order = 3;
			} else if (stage.stage === 'ASSET_MANAGEMENT') {
				order = 4;
			}
			return {
				...stage,
				order,
			};
		})
		.sort((a, b) => a.order - b.order);

	return (
		<>
			<PositioningDot ref={dotRef}></PositioningDot>
			<Wrapper
				isRefetchingProject={isRefetchingProject}
				$top={offsets.top}
				$left={offsets.left}
				$isMobile={isMobile}
			>
				{isRefetchingProject && (
					<LoaderContianer>
						<Loader />
					</LoaderContianer>
				)}
				<StagesWrapper>
					{customOrderedStages?.map(stage => (
						<StageContainer
							key={`progress-stage-${stage.id}`}
							stage={stage}
							offsets={offsets}
							status={project.condition}
							refetchProgress={refetchProgress}
						/>
					))}
				</StagesWrapper>
			</Wrapper>
		</>
	);
};

StageContainer.propTypes = {
	stage: PropTypes.shape({
		stageStatus: PropTypes.string.isRequired,
		completedPercentage: PropTypes.number.isRequired,
		stage: PropTypes.string.isRequired,
		activities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	}).isRequired,
	offsets: PropTypes.shape({
		top: PropTypes.number,
		left: PropTypes.number,
	}).isRequired,
	status: PropTypes.shape({}).isRequired,
};

ProgressTab.propTypes = {
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		projectStages: PropTypes.arrayOf(PropTypes.shape({})),
		condition: PropTypes.shape({}),
	}).isRequired,
};

export default ProgressTab;
