import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import ClientTile from 'Projects/components/ProjectDetails/Tabs/ClientTab/Tiles/ClientTile/ClientTile';
import { PROJECT_DETAILS_PAGE_TAB_TILES } from 'Projects/constants/projectTabForms';
import ClientDetailsTile from 'Client/components/ClientDetails/Tabs/GeneralTab/Tiles/ClientDetailsTile/ClientDetailsTile';
import AddressTile from 'Client/components/ClientDetails/Tabs/GeneralTab/Tiles/AddressTile/AddressTile';
import DueDiligenceTile from 'Client/components/ClientDetails/Tabs/FinancialTab/Tiles/DueDiligenceTile/DueDiligenceTile';
import ContactsTile from 'Client/components/ClientDetails/Tabs/ContactsTab/ContactsTile';

const ClientTab = ({ project, onEnterEditMode, onExitEditMode, onDirtyForm, handleUpdateProject }) => {
	const handleClientDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.CLIENT.CLIENT, isDirty);
		},
		[onDirtyForm],
	);

	const handleClientDetailsDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.CLIENT.DETAILS, isDirty);
		},
		[onDirtyForm],
	);

	const handleAddressDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.CLIENT.ADDRESS, isDirty);
		},
		[onDirtyForm],
	);

	const handleDueDiligenceDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.CLIENT.DUE_DILIGENCE, isDirty);
		},
		[onDirtyForm],
	);

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
				<ClientTile
					project={project}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onDirtyForm={handleClientDirtyForm}
					onTileSave={handleUpdateProject}
				/>
			</Grid>
			{project.client && (
				<>
					<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
						<ClientDetailsTile
							client={project.client}
							onEnterEditMode={onEnterEditMode}
							onExitEditMode={onExitEditMode}
							// ? Ommiting the data passed to the onSave as it's from the edit request for client and not project
							onSave={() => handleUpdateProject()}
							onDirtyForm={handleClientDetailsDirtyForm}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
						<AddressTile
							client={project.client}
							onEnterEditMode={onEnterEditMode}
							onExitEditMode={onExitEditMode}
							// ? Ommiting the data passed to the onSave as it's from the edit request for client and not project
							onSave={() => handleUpdateProject()}
							onDirtyForm={handleAddressDirtyForm}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
						<DueDiligenceTile
							client={project.client}
							onEnterEditMode={onEnterEditMode}
							onExitEditMode={onExitEditMode}
							// ? Ommiting the data passed to the onSave as it's from the edit request for client and not project
							onSave={() => handleUpdateProject()}
							onDirtyForm={handleDueDiligenceDirtyForm}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<ContactsTile client={project.client} />
					</Grid>
				</>
			)}
		</TilesGridWrapper>
	);
};

ClientTab.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	handleUpdateProject: async () => {},
};

ClientTab.propTypes = {
	project: PropTypes.shape({
		client: PropTypes.shape({}),
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	handleUpdateProject: PropTypes.func,
};

export default ClientTab;
