import { parse, stringify } from 'qs';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import queryParamsDecoder from 'Common/utils/queryParamsDecoder';

const useSetQueryParameter = name => {
	const navigate = useNavigate();

	// noinspection UnnecessaryLocalVariableJS
	const set = useCallback(
		(newValue, shouldReplace) => {
			const query = parse(window.location.search, {
				ignoreQueryPrefix: true,
				arrayLimit: 50,
				decoder: queryParamsDecoder,
			});
			if (newValue === null || newValue === undefined || (Array.isArray(newValue) && newValue.length === 0)) {
				delete query[name];
			} else {
				query[name] = newValue;
			}

			navigate({ search: stringify(query) }, { replace: shouldReplace });
		},
		[navigate, name],
	);

	return set;
};

export default useSetQueryParameter;
