import getTariffType from 'Invoice/api/getTariffType';

const loadTariffTypeOptions = async countryId => {
	const { data: tariffTypes } = await getTariffType(countryId);
	return tariffTypes.map(({ id, name }) => ({
		label: name,
		value: id,
	}));
};

export default loadTariffTypeOptions;
