import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import crudModes from 'Common/constants/crudModes';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';
import formatDateForRequestData from 'Common/utils/formatDateForRequestData';

import editEpcContract from 'EpcContract/api/editEpcContract';
import createEpcContract from 'EpcContract/api/createEpcContract';
import convertFormValuesToRequestData from 'EpcContract/components/EpcContractForm/utils/convertFormValuesToRequestData';
import useEpcContractFormInitialValues from 'EpcContract/components/EpcContractForm/hooks/useEpcContractFormInitialValues';
import useEpcContractFormValidationSchema from 'EpcContract/components/EpcContractForm/hooks/useEpcContractFormValidationSchema';

import getProjectById from 'Projects/api/getProjectById';

import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import UNITS from 'Common/constants/units';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { ModalFormButtons } from 'Common/components/form/ModalForm';
import projectTypes from 'Projects/constants/projectTypes';

const Form = styled.form`
	display: flex;
	flex-wrap: wrap;
	justify-content: stretch;
	flex-direction: column;
`;

const FieldsWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const Section = styled.div`
	width: 100%;
	min-width: 200px;

	div:last-child {
		margin-bottom: 0;
	}
`;

const ContentWrapper = styled.div``;

const StyledFormikInput = styled(FormikInput)`
	position: unset;

	.errorMessage {
		left: initial;
		top: initial;
	}
`;

const EpcContractForm = ({ data, projectId, currencies, systemSize, mode, onSubmit, onCancel, onDirty }) => {
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();

	const initialValues = useEpcContractFormInitialValues(data);
	const validationSchema = useEpcContractFormValidationSchema();

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleChange,
		handleBlur,
		setFieldValue,
		setFieldError,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				if (mode === crudModes.CREATE) {
					await createEpcContract(abortController.signal, {
						projectId,
						...convertFormValuesToRequestData(values),
					});

					notify(t('Contract created successfully.'), {
						type: toast.TYPE.SUCCESS,
					});
				} else if (mode === crudModes.EDIT) {
					await editEpcContract(abortController.signal, {
						...data,
						projectId,
						...convertFormValuesToRequestData(values),
					});

					notify(t('Contract saved successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				}

				const response = await getProjectById(abortController.signal, projectId, true);

				const newSystemSize = response.data.systemSizeKwp;

				if (newSystemSize !== systemSize && response.data.projectType?.name !== projectTypes.PV) {
					notify(t(`Project system size set to ${newSystemSize} kWp`), {
						type: toast.TYPE.SUCCESS,
					});
				}

				const selectedCurrency = values.currency.value;

				const newExchangeRateDate = response.data.currencies.find(
					currency => currency.isoCode === selectedCurrency,
				)?.exchangeRateDate;

				const oldExchangeRateDate = currencies.find(
					currency => currency.isoCode === selectedCurrency,
				)?.exchangeRateDate;

				if (selectedCurrency !== 'EUR' && newExchangeRateDate !== oldExchangeRateDate) {
					if (newExchangeRateDate !== formatDateForRequestData(new Date(), true)) {
						notify(
							t(
								`Project Exchange rate date changed to ${newExchangeRateDate} because some exchange rates were not available for the selected day `,
							),
							{
								type: toast.TYPE.WARNING,
							},
						);
					} else {
						notify(t(`Project Exchange rate date set to ${newExchangeRateDate}`), {
							type: toast.TYPE.SUCCESS,
						});
					}
				}

				await onSubmit();

				// Re-set all fields and mark them as not touched
				resetForm({ values });
				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	const CurrencySign = values.currency
		? () => <>{values.currency.value === 'EUR' ? '€' : values.currency.value}</>
		: null;

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const handleSelectMenuOpen = () => {
		setIsDropdownOpen(true);
	};
	const handleSelectMenuClose = () => {
		setIsDropdownOpen(false);
	};

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form onSubmit={handleSubmit}>
			<ContentWrapper isDropdownOpen={isDropdownOpen}>
				<FieldsWrapper>
					<Section>
						<FormField>
							<FormikInput
								id="epcVolumeExclVat"
								name="epcVolumeExclVat"
								label={t('EPC volume')}
								value={values.epcVolumeExclVat}
								error={errors.epcVolumeExclVat}
								touched={touched.epcVolumeExclVat}
								onChange={handleChange}
								onBlur={handleBlur}
								prefix={CurrencySign}
								isRequired
							/>
						</FormField>
						<FormField>
							<FormikSelect
								isAsync
								id="currency"
								name="currency"
								label={t('Currency')}
								value={values.currency}
								error={errors.currency}
								touched={touched.currency}
								setFieldValue={setFieldValue}
								onBlur={handleBlur}
								loadOptions={loadCurrenciesOptions}
								onMenuOpen={handleSelectMenuOpen}
								onMenuClose={handleSelectMenuClose}
								menuPosition="fixed"
								isRequired
							/>
						</FormField>
						<FormField>
							<FormikInput
								id="annualOmPrice"
								name="annualOmPrice"
								label={t('Annual O&M price')}
								value={values.annualOmPrice}
								error={errors.annualOmPrice}
								touched={touched.annualOmPrice}
								onChange={handleChange}
								onBlur={handleBlur}
								prefix={CurrencySign}
								isRequired
							/>
						</FormField>
						<FormField>
							<StyledFormikInput
								id="annualOmEscalationRate"
								name="annualOmEscalationRate"
								label={t('Annual O&M escalation rate')}
								value={values.annualOmEscalationRate}
								error={errors.annualOmEscalationRate}
								touched={touched.annualOmEscalationRate}
								onChange={handleChange}
								onBlur={handleBlur}
								unit={UNITS.PERCENT_PA}
							/>
						</FormField>
					</Section>
				</FieldsWrapper>
			</ContentWrapper>
			<ModalFormButtons
				onCancel={onCancel}
				isLoading={isLoading}
				label="EPC Contract Form"
				submitButtonText={mode === crudModes.CREATE ? t('Create') : t('Save')}
			/>
		</Form>
	);
};

EpcContractForm.defaultProps = {
	data: {},
};

EpcContractForm.propTypes = {
	currencies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	data: PropTypes.shape({}),
	projectId: PropTypes.number.isRequired,
	mode: PropTypes.string.isRequired,
	systemSize: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
};

export default EpcContractForm;
