import axios from 'axios';
import { store } from 'Application/store';
import { interruptUser } from 'User/reduxUser';

const api = axios.create({
	baseURL: '/api',
	timeout: 15000,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
	paramsSerializer: {
		indexes: null,
	},
});

// Show a re-login dialog when an API request with an expired access token is made
api.interceptors.response.use(
	response => response,
	error => {
		if (error.response && error.response.status === 401 && error.config.url !== '/auth/login') {
			store.dispatch(interruptUser());
		}

		return Promise.reject(error);
	},
);

export default api;
