import { startOfDay } from 'date-fns';
import projectTypes from 'Projects/constants/projectTypes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const useRfepOverlayValidationSchema = () => {
	const { t } = useTranslation();
	const today = startOfDay(new Date());

	return useMemo(
		() =>
			yup.object({
				description: yup.string().required(t('Description is Required')),

				tenderCloseDate: yup
					.date()
					.min(today, "SaaS signature date can't be a date in the past")
					.nullable()
					.when('rfepCloseDateActive', {
						is: true,
						then: yup.date().nullable().required(t('Date is required')),
						otherwise: null,
					}),
				systemSize: yup.mixed().when('projectType', {
					is: projectTypes.PV,
					then: yup
						.number()
						.positive(t('System size must be positive'))
						.test(
							'decimal-places',
							t('System size must have no more than 2 decimal places'),
							value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
						)
						.required(t('System size is Required'))
						.typeError(t('System size must be a number'))
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						)
						.nullable(),
					otherwise: null,
				}),
				invitedPartners: yup.mixed().when('state', {
					is: val => val === 'Closed',
					then: yup.array().required('Required').nullable(),
					otherwise: null,
				}),
			}),
		[t, today],
	);
};

export default useRfepOverlayValidationSchema;
