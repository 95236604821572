import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import logout from 'User/services/logout';
import { useSelector } from 'react-redux';
import AccountModal from './AccountModal/AccountModal';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import colors from 'Application/theme/colors';
import UserAvatar from 'Common/components/usersPreview/UserAvatar';
import showToastError from 'Common/utils/showToastError';
import { push } from 'redux-first-history';
import { useDispatch } from 'react-redux';
import useAbortController from 'Common/hooks/useAbortController';

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 16px;
	color: ${colors.text.primary};
`;

const AvatarContainer = styled.div`
	width: 30px;
	height: 30px;
`;

const UserActions = ({ ...props }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const currentUser = useSelector(state => state.user);

	const [isModalOpened, setIsModalOpened] = useState(false);

	const abortController = useAbortController();

	const handlePrivacyPolicy = () => {
		dispatch(push('/privacy-policy'));
	};

	const handleLogout = async () => {
		try {
			await logout(abortController.signal);
		} catch (err) {
			showToastError(err);
		}
	};

	const handleAccountModalClick = () => {
		setIsModalOpened(true);
	};

	const handleAccountModalClose = () => {
		setIsModalOpened(false);
	};

	return (
		<>
			<Wrapper {...props}>
				<MoreActionsButton
					CustomButton={() => (
						<AvatarContainer>
							<UserAvatar imgUrl={currentUser?.profilePhoto?.url} {...currentUser} />
						</AvatarContainer>
					)}
					label="User Icon More Actions"
				>
					<MenuItem
						onClick={handleAccountModalClick}
						type="button"
						data-action="myAccount"
						label="User Profile - My Account Menu Item"
					>
						{t('My account')}
					</MenuItem>
					<MenuItem onClick={handlePrivacyPolicy} type="button" data-action="privacyPolicy" label="Privacy policy">
						{t('Privacy policy')}
					</MenuItem>
					<MenuItem
						onClick={handleLogout}
						type="button"
						data-action="logout"
						label="User Profile - Logout Menu Item"
					>
						{t('Logout')}
					</MenuItem>
				</MoreActionsButton>
			</Wrapper>
			<AccountModal label="user-actions" isOpen={isModalOpened} onClose={handleAccountModalClose} />
		</>
	);
};

export default UserActions;
