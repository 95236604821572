import { pick } from 'lodash';

const toCurrencyFormat = (amount, currency) =>
	amount
		? {
				currency: currency.value,
				value: String(amount).replace(/\s/g, ''),
		  }
		: undefined;

const convertFormValuesToRequestData = values => ({
	...pick(values, ['annualOmEscalationRate']),
	epcVolumeExclVat: toCurrencyFormat(values.epcVolumeExclVat, values.currency),
	annualOmPrice: toCurrencyFormat(values.annualOmPrice, values.currency),
});

export default convertFormValuesToRequestData;
