import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components/macro';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';
import { updateSidePanelShowHistory } from 'Application/reducers/reduxSidePanel';

import H3 from 'Common/components/headers/H3';
import Breadcrumb from 'Common/components/breadcrumbs/Breadcrumb';
import Breadcrumbs from 'Common/components/breadcrumbs/Breadcrumbs';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import CloseSidepanleBtn from './components/CloseSidepanelBtn';
import useReponsive from 'Common/hooks/useResponsive';
import { css } from 'styled-components/macro';
import sizes from 'Application/theme/sizes';

const Content = styled.div`
	overflow: hidden;
	white-space: nowrap;
`;

const StyledBreadcrumb = styled(Breadcrumb)`
	font-size: 16px;
	font-weight: 700;
	line-height: 19.5px;
`;

const Arrow = styled.div`
	margin: 0 5px;
	font-size: 20px;
	color: ${colors.text.primary};
`;

const StyledLink = styled(Link)`
	font-size: 20px;
	font-weight: 300;
	color: ${colors.text.primary};
	text-decoration: none;
	white-space: nowrap;
	line-height: 24.3px;
	overflow: hidden;
	min-width: 80px;
`;

const StyledH3 = styled(H3)`
	margin-bottom: 0px;
`;

const LeftSide = styled(Grid)`
	height: 29px;
	display: flex;
	align-items: center;
	max-width: 372px;
`;

const RightSide = styled(Grid)`
	height: 29px;
	display: flex;
	align-items: center;
	> * {
		margin-right: 8px;

		&:last-child {
			margin-right: 0px;
		}
	}
`;

const Wrapper = styled(Grid)`
	padding: ${sizes.spacing(2)};
	border-bottom: 1px solid ${colors.common.lightGrey};
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	${({ $isMobile }) =>
		$isMobile &&
		css`
			padding: ${sizes.spacing(1)};
			padding-right: ${sizes.spacing(1)};

			${StyledLink} {
				min-width: 50px;
			}
		`}
`;

const capitalize = word => word.charAt(0).toUpperCase() + word.slice(1);

const SidePanelHeader = ({
	to,
	type,
	title,
	isLoading,
	breadCrumb,
	isBreadcrumb,
	handleOnClose,
	isHistoryShow,
	activeBreadcrumb,
	toActiveBreadcrumb,
}) => {
	const { t } = useTranslation();
	const { isMobile } = useReponsive();
	const dispatch = useDispatch();

	const isStep = type === 'dashboardStep' || type === 'projectStep';

	return (
		<Wrapper $isMobile={isMobile}>
			<LeftSide>
				{isLoading ? (
					<ContentLoader
						width="100%"
						height="20px"
						backgroundColor={colors.common.lightGrey}
						foregroundColor={colors.common.headerGrey}
					>
						<rect x="0" y="0" rx="10" ry="10" width="100" height="20" />
						<rect x="116" y="0" rx="10" ry="10" width="100" height="20" />
					</ContentLoader>
				) : isBreadcrumb ? (
					<Content>
						<Breadcrumbs>
							<StyledLink to={to} active={null}>
								{capitalize(breadCrumb)}
							</StyledLink>
							<Arrow> / </Arrow>
							<StyledLink to={toActiveBreadcrumb}>
								<StyledBreadcrumb active>{t(activeBreadcrumb)}</StyledBreadcrumb>
							</StyledLink>
						</Breadcrumbs>
					</Content>
				) : (
					<StyledH3>{title}</StyledH3>
				)}
			</LeftSide>
			<RightSide>
				{isStep && (
					<MoreActionsButton noBackground dataAction="options" label="Side Panel Header More Actions">
						<MenuItem
							onClick={() => dispatch(updateSidePanelShowHistory(!isHistoryShow))}
							type="button"
							label={`Side Panel Header - ${isHistoryShow ? 'Hide' : 'Show'} History Menu Item`}
						>
							{isHistoryShow ? t('Hide History') : t('Show History')}
						</MenuItem>
					</MoreActionsButton>
				)}
				<CloseSidepanleBtn onClick={handleOnClose} />
			</RightSide>
		</Wrapper>
	);
};

SidePanelHeader.defaultProps = {
	to: '',
	type: '',
	title: '',
	breadCrumb: '',
	isBreadcrumb: false,
	isHistoryShow: false,
	activeBreadcrumb: null,
	toActiveBreadcrumb: null,
};

SidePanelHeader.propTypes = {
	to: PropTypes.string,
	type: PropTypes.string,
	title: PropTypes.string,
	isLoading: PropTypes.bool.isRequired,
	breadCrumb: PropTypes.string,
	isBreadcrumb: PropTypes.bool,
	isHistoryShow: PropTypes.bool,
	activeBreadcrumb: PropTypes.string,
	toActiveBreadcrumb: PropTypes.string,
	handleOnClose: PropTypes.func.isRequired,
};

export default SidePanelHeader;
