import api from 'Application/api/api';

const assignEntityCountry = async (signal, { contractPartyId, countryId }) => {
	const response = await api.post(
		`/contract-parties/assign-country/${contractPartyId}?countryId=${countryId}`,
		{
			signal,
			timeout: 100000,
		},
	);

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: response.data,
	};
};

export default assignEntityCountry;
