import styled from 'styled-components/macro';
import Grid from '@mui/material/Grid';
import { css } from 'styled-components';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

import Link from 'Common/components/Link';
import formatNumber from 'Common/utils/formatNumber';
import StatusPill from 'Common/components/StatusPill';
import MenuIcon from 'Common/components/layout/MenuIcon';
import UsersPreview from 'Common/components/usersPreview/UsersPreview';
import { statusesColors } from 'Projects/constants/statuses';

import { stageOptions } from 'Projects/constants/stages';
import PriorityIcon from 'Common/components/icons/PriorityIcon';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import formatDate from 'Common/utils/formatDate';
import { SIDE_PANEL_CONTENT_TYPES } from 'Common/components/SidePanel/constants/sidePanelContentTypes';
import { useDispatch } from 'react-redux';
import { updateSidePanelData } from 'Application/reducers/reduxSidePanel';
import { PM_WORKFLOW_TILES_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';
import projectTypes from 'Projects/constants/projectTypes';

const Wrapper = styled(Grid)`
	&.MuiGrid-spacing-xs-1 {
		margin: ${props => (props.margin ? props.margin + ' !important' : '0px !important')};
	}

	position: relative;
	z-index: 1;
	padding: ${props => (props.padding ? props.padding : '12px 8px')};
	background: ${props => (props.$backgroundColor ? props.$backgroundColor : colors.common.headerGrey)};
	border-top: ${props => (props.border ? props.border : `1px solid ${colors.common.lightGrey};`)};

	&:hover {
		z-index: 994;
	}
`;

const Block = styled(Grid)`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 16px !important;
`;

const PointerBlock = styled(Block)`
	cursor: pointer;
`;

const KeyValueWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const StageValue = styled.div`
	display: flex;
	align-items: center;
`;

const Label = styled.div`
	font-weight: 400;
	margin-left: 5px;
	font-size: 16px;
	line-height: 24px;
	text-align: left;
	overflow-wrap: break-word;
	color: ${colors.text.black};
	${({ bold }) =>
		bold &&
		css`
			font-weight: 600;
		`}
`;

const StyledLink = styled(Link)`
	text-decoration: none;
	cursor: pointer;
`;

const PointerStageWrapper = styled(Grid)`
	cursor: pointer;
`;

const StageWrapper = styled(Grid)``;

const Stage = ({ stage, showPercentage }) => {
	const openedAt = stage.openedAt && `SD: ${formatDate(stage.openedAt)}`;
	const firstCompletedAt = stage.firstCompletedAt && `ED: ${formatDate(stage.firstCompletedAt)}`;

	const tooltipText =
		openedAt && firstCompletedAt
			? `${openedAt}
		${firstCompletedAt}`
			: !openedAt && !firstCompletedAt
			? ''
			: openedAt || firstCompletedAt;

	return (
		<HoverTooltip title={tooltipText} placement="bottom">
			<StageValue>
				<StatusPill color={colors.secondary.dark}>
					{stageOptions.find(option => option.value === stage?.stage)?.label}{' '}
					{showPercentage
						? stage?.completedPercentage
							? Math.floor(stage?.completedPercentage) + '%'
							: '0%'
						: ''}
				</StatusPill>
			</StageValue>
		</HoverTooltip>
	);
};

const ProjectInfo = ({
	border,
	margin,
	padding,
	spacing,
	backgroundColor,
	data,
	disableUserInfoHover,
	handleUpdateProject,
}) => {
	const dispatch = useDispatch();

	const filteredStages = data.stageIndicators
		?.map(stage => {
			let order;
			if (stage.stage === 'SALES') {
				order = 0;
			} else if (stage.stage === 'TECH') {
				order = 1;
			} else if (stage.stage === 'FUNDRAISING') {
				order = 2;
			} else if (stage.stage === 'BUILD') {
				order = 3;
			} else if (stage.stage === 'ASSET_MANAGEMENT') {
				order = 4;
			}
			return {
				...stage,
				order,
			};
		})
		.sort((a, b) => a.order - b.order);

	const openSidePanelTiles = () => {
		dispatch(
			updateSidePanelData({
				isOpen: true,
				type: SIDE_PANEL_CONTENT_TYPES.SIDE_PANEL_TILES,
				isLoading: false,
				headerData: {
					to: '/projects',
					isBreadcrumb: true,
					breadCrumb: 'Projects',
					activeBreadcrumb: `${data.externalId} - ${data?.client?.name ? data?.client?.name : 'Unknown client'} ${
						data?.description ? ` (${data?.description})` : ''
					}`,
					toActiveBreadcrumb: `/projects/details/${data.id}`,
				},
				contentData: {
					project: data,
					tiles: [PM_WORKFLOW_TILES_KEYS.PROJECT_STATUS, PM_WORKFLOW_TILES_KEYS.PROJECT_RESPONSIBLE_PEOPLE],
					updateEntity: handleUpdateProject,
				},
			}),
		);
	};

	return (
		<Wrapper
			container
			border={border}
			margin={margin}
			padding={padding}
			spacing={spacing ? spacing : 1}
			$backgroundColor={backgroundColor}
		>
			{data.projectType?.name && (
				<Block item id="projectTypeHeader">
					<KeyValueWrapper>
						<MenuIcon icon={String(data?.projectType?.name).toLowerCase()} tooltipText="Dashboard" />
						{data?.energyStorage && data.projectType?.name === projectTypes.PV && (
							<MenuIcon icon="es" tooltipText="Dashboard" />
						)}
						<Label>{data.projectType?.name}</Label>
						{data?.energyStorage && data.projectType?.name === projectTypes.PV && <Label>+ Energy Storage</Label>}
					</KeyValueWrapper>
				</Block>
			)}

			{data?.epcVolumeExclVat?.value && (
				<Block item id="epcVolumeHeader">
					<KeyValueWrapper>
						<Label> {` € ${formatNumber(data?.epcVolumeExclVat?.eurValue, 0)}`}</Label>
					</KeyValueWrapper>
				</Block>
			)}

			{data.systemSizeKwp ? (
				<Block item id="systemSizeHeader">
					<KeyValueWrapper>
						<Label bold>{data.systemSizeKwp !== undefined ? formatNumber(data.systemSizeKwp, 0) : ''}</Label>
						<Label>kWp</Label>
					</KeyValueWrapper>
				</Block>
			) : null}

			{Boolean(data?.averageEPCPrice) && (
				<Block item id="averageEpcPriceHeader">
					<KeyValueWrapper>
						<Label bold>{formatNumber(data?.averageEPCPrice, 0)}</Label>
						<Label>{' €/kWp'}</Label>
					</KeyValueWrapper>
				</Block>
			)}

			{data.country?.name && (
				<Block item id="countryHeader">
					<KeyValueWrapper>
						<MenuIcon icon="location" tooltipText="Dashboard" />
						<Label>{data.country?.name}</Label>
					</KeyValueWrapper>
				</Block>
			)}

			{data.contractType?.name && (
				<Block item id="contractTypeHeader">
					<KeyValueWrapper>
						<MenuIcon icon="contract" tooltipText="Dashboard" />
						<Label>{data.contractType?.name}</Label>
					</KeyValueWrapper>
				</Block>
			)}

			{data?.contractPartyObjectByType?.SaaS?.abbreviation && (
				<Block item id="contractPartyHeader">
					<KeyValueWrapper>
						<MenuIcon icon="entities" tooltipText="Dashboard" />
						<StyledLink to={`/entities/details/${data?.contractPartyObjectByType?.SaaS?.id}`}>
							<Label>{data?.contractPartyObjectByType?.SaaS?.abbreviation}</Label>
						</StyledLink>
					</KeyValueWrapper>
				</Block>
			)}

			{data.epcPartner?.name && (
				<Block item id="epcPartnerHeader">
					<KeyValueWrapper>
						<MenuIcon icon="epc" color={colors.secondary.light} tooltipText="Dashboard" />
						<StyledLink to={`/epc-partners/details/${data.epcPartner.id}`}>
							<Label>{data.epcPartner?.name}</Label>
						</StyledLink>
					</KeyValueWrapper>
				</Block>
			)}

			{data.priority && (
				<PointerBlock item id="priority" onClick={() => openSidePanelTiles()}>
					<PriorityIcon priority={data.priority.priority} />
				</PointerBlock>
			)}

			{data?.responsiblePeopleObjectByRole && (
				<PointerBlock
					item
					id="responsiblePeopleHeader"
					// ? Users Preview has stop propagation on click, so we need to attach click in capturing phace
					onClick={() => openSidePanelTiles()}
				>
					<UsersPreview
						users={data?.responsiblePeopleObjectByRole}
						displayTeam
						disableHover={disableUserInfoHover}
						omitStopPropagation
					/>
				</PointerBlock>
			)}

			<Block item id="stagesHeader">
				<Grid container spacing={1}>
					<PointerStageWrapper item id="statusHeader" onClick={() => openSidePanelTiles()}>
						<StageValue>
							<StatusPill color={statusesColors[data?.condition?.condition]}>
								{data?.condition?.displayName}
							</StatusPill>
						</StageValue>
					</PointerStageWrapper>
					{filteredStages
						?.filter(stage => stage.stageStatus !== 'COMPLETED')
						.map((stage, i) => (
							// eslint-disable-next-line react/no-array-index-key
							<StageWrapper item key={`project-header-${i}`} id={`${stage?.stage.toLowerCase()}Header`}>
								<Stage stage={stage} />
							</StageWrapper>
						))}
				</Grid>
			</Block>
		</Wrapper>
	);
};

ProjectInfo.defaultProps = {
	border: '',
	margin: '',
	padding: '',
	spacing: 0,
	backgroundColor: '',
	disableUserInfoHover: false,
	handleUpdateProject: () => {},
};

ProjectInfo.propTypes = {
	border: PropTypes.string,
	margin: PropTypes.string,
	padding: PropTypes.string,
	spacing: PropTypes.number,
	backgroundColor: PropTypes.string,
	data: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		name: PropTypes.string,
		stageIndicators: PropTypes.arrayOf(PropTypes.shape({})),
		projectType: PropTypes.shape({
			name: PropTypes.string,
		}),
		externalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		client: PropTypes.shape({
			name: PropTypes.string,
		}),
		description: PropTypes.string,
		contractPartyObjectByType: PropTypes.shape({
			SaaS: PropTypes.shape({
				abbreviation: PropTypes.string,
				id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			}),
		}),
		energyStorage: PropTypes.bool,
		epcVolumeExclVat: PropTypes.shape({
			value: PropTypes.number.isRequired,
			eurValue: PropTypes.number.isRequired,
		}),
		systemSizeKwp: PropTypes.number,
		averageEPCPrice: PropTypes.number,
		country: PropTypes.shape({ name: PropTypes.string }),
		contractType: PropTypes.shape({ name: PropTypes.string }),
		epcPartner: PropTypes.shape({
			name: PropTypes.string,
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		priority: PropTypes.shape({ priority: PropTypes.string }),
		responsiblePeopleObjectByRole: PropTypes.shape({}),
		condition: PropTypes.shape({
			condition: PropTypes.string,
			displayName: PropTypes.string,
		}),
	}).isRequired,
	disableUserInfoHover: PropTypes.bool,
	handleUpdateProject: PropTypes.func,
};

Stage.defaultProps = {
	stage: {},
	showPercentage: true,
};

Stage.propTypes = {
	stage: PropTypes.shape({
		completedPercentage: PropTypes.string,
		stage: PropTypes.string,
		openedAt: PropTypes.string,
		firstCompletedAt: PropTypes.string,
	}),
	showPercentage: PropTypes.bool,
};

export default ProjectInfo;
