export const unitSystemSize = {
	KWP: 'KWP',
	AIR_CONDITIONERS: 'AIR_CONDITIONERS',
	AIR_CONDITIONING_AND_17_SOLAR_THERMAL_WATER_HEATERS: 'AIR_CONDITIONING_AND_17_SOLAR_THERMAL_WATER_HEATERS',
	KWP_AND_32_AIR_CONDITIONERS: 'KWP_AND_32_AIR_CONDITIONERS',
	LED_LAMPS: 'LED_LAMPS',
	LITER: 'LITER',
	SOLAR_PANELS_AND_HOT_WATER_TANKS: 'SOLAR_PANELS_AND_HOT_WATER_TANKS',
};

export const unitSystemSizeOptions = [
	{
		label: 'kWp',
		value: unitSystemSize.KWP,
	},
	{
		label: 'Air Conditioners',
		value: unitSystemSize.AIR_CONDITIONERS,
	},
	{
		label: 'Air conditioning and 17 solar thermal water heaters',
		value: unitSystemSize.AIR_CONDITIONING_AND_17_SOLAR_THERMAL_WATER_HEATERS,
	},
	{
		label: 'kWp & 32 air conditioners',
		value: unitSystemSize.KWP_AND_32_AIR_CONDITIONERS,
	},
	{
		label: 'LED Lamps',
		value: unitSystemSize.LED_LAMPS,
	},
	{
		label: 'Liter',
		value: unitSystemSize.LITER,
	},
	{
		label: 'Solar panels and hot water tanks',
		value: unitSystemSize.SOLAR_PANELS_AND_HOT_WATER_TANKS,
	},
];
