import RespondForm from './RespondForm/RespondForm';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import SectionContainer from 'Common/components/modals/SectionContainer';
import Overlay from 'Common/components/modals/Overlay';
import { useMemo } from 'react';

const RespondOverlay = ({ isOpen, onClose, onFormSubmit, inquiry }) => {
	const { t } = useTranslation();

	const breadcrumbList = useMemo(
		() => [
			{
				label: 'Inquiries',
				href: '/inquiries',
			},
			{
				label: inquiry?.clientName || '(No Client)',
				href: `/inquiries/details/${inquiry?.id}`,
			},
			{
				label: t('Inquiry Respond'),
			},
		],
		[t, inquiry],
	);

	return (
		<Overlay
			label="inquiry-respond-overlay"
			breadcrumbList={breadcrumbList}
			isOpen={isOpen}
			onClose={onClose}
			onSave={onFormSubmit}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<SectionContainer name={t('Respond to Inquiry')}>
					<RespondForm onSubmit={handleSubmit} onCancel={onCancel} inquiry={inquiry} onDirty={setIsFormDirty} />
				</SectionContainer>
			)}
		</Overlay>
	);
};

RespondOverlay.defaultProps = {
	inquiry: null,
};

RespondOverlay.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	inquiry: PropTypes.shape({ id: PropTypes.string, clientName: PropTypes.string }),
};

export default RespondOverlay;
