// TODO remove the eslint complexity and fix it
/* eslint-disable complexity */
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import Grid from '@mui/material/Grid';
import FormField from 'Common/components/form/FormField';
import loadCountryOptions from 'Country/utils/loadCountryOptions';
import loadProjectTypeOptions from 'Projects/utils/loadProjectTypeOptions';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import FormikSelect from 'Common/components/form/FormikSelect';
import FormikInput from 'Common/components/form/FormikInput';
import { fullNameProjectTypes } from 'Projects/constants/projectTypes';
import FormikRadio from 'Common/components/form/FormikRadio';
import FormikTextArea from 'Common/components/form/FormikTextArea';
import loadIndustryOptions from 'Industries/utils/loadIndustryOptions';
import IndustrySelectOption from 'Industries/components/IndustrySelectOption';
import IndustrySelectValue from 'Industries/components/IndustrySelectValue';
import Big from 'Common/utils/customBig';
import ConditionalGridSection from 'Common/components/ConditionalGridSection';
import UNITS from 'Common/constants/units';
import { PropTypes } from 'prop-types';

const Title = styled.div`
	font-size: 18px;
	font-weight: 600;
	margin: 0 20px 20px 0;
`;

const TopSection = ({
	values,
	errors,
	touched,
	handleBlur,
	handleChange,
	setFieldValue,
	setFieldTouched,
	isDisabled,
	currencyOptions,
}) => {
	const { t } = useTranslation();

	const CurrencySign = () =>
		useMemo(() => <>{values?.tariffCurrency?.value === 'EUR' ? '€' : values?.tariffCurrency?.value}</>, []);

	const epcVolumeSwitch = useMemo(() => values.epcVolumeSwitch, [values.epcVolumeSwitch]);

	const omPriceSwitch = useMemo(() => values.omPriceSwitch, [values.omPriceSwitch]);

	const handleSystemSizeChange = useCallback(
		e => {
			handleChange(e);
			const systemSizeField = Number(e.target.value);

			if (!omPriceSwitch) {
				if (isNaN(systemSizeField) || systemSizeField === 0) {
					setFieldValue('annualOmPriceKwp', '');
				} else {
					setFieldValue(
						'annualOmPriceKwp',
						values.annualOmPrice
							? Number(Number(Big(values.annualOmPrice).div(Big(systemSizeField)).toString()).toFixed(2))
							: '',
					);
					if (errors['annualOmPriceKwp'] && values.annualOmPrice) {
						setFieldTouched('annualOmPriceKwp');
					}
				}
			}
			if (omPriceSwitch) {
				if (isNaN(systemSizeField) || systemSizeField === 0) {
					setFieldValue('annualOmPrice', '');
				} else {
					setFieldValue(
						'annualOmPrice',
						values.annualOmPriceKwp
							? Number(Big(values.annualOmPriceKwp).times(Big(systemSizeField)).toString()).toFixed(2)
							: '',
					);
					if (errors['annualOmPrice'] && values.annualOmPriceKwp) {
						setFieldTouched('annualOmPrice');
					}
				}
			}
			if (!epcVolumeSwitch) {
				if (isNaN(systemSizeField) || systemSizeField === 0) {
					setFieldValue('epcVolumeExclVatKwp', '');
				} else {
					setFieldValue(
						'epcVolumeExclVatKwp',
						values.epcVolumeExclVat
							? Number(Number(Big(values.epcVolumeExclVat).div(Big(systemSizeField)).toString()).toFixed(2))
							: '',
					);
					if (errors['epcVolumeExclVatKwp'] && values.epcVolumeExclVat) {
						setFieldTouched('epcVolumeExclVatKwp');
					}
				}
			}
			if (epcVolumeSwitch) {
				if (isNaN(systemSizeField) || systemSizeField === 0) {
					setFieldValue('epcVolumeExclVat', '');
				} else {
					setFieldValue(
						'epcVolumeExclVat',
						values.epcVolumeExclVatKwp
							? Number(Big(values.epcVolumeExclVatKwp).times(Big(systemSizeField)).toString()).toFixed(2)
							: '',
					);
					if (errors['epcVolumeExclVat'] && values.epcVolumeExclVatKwp) {
						setFieldTouched('epcVolumeExclVat');
					}
				}
			}
		},
		[
			values.epcVolumeExclVatKwp,
			values.epcVolumeExclVat,
			values.annualOmPriceKwp,
			values.annualOmPrice,
			handleChange,
			omPriceSwitch,
			epcVolumeSwitch,
			setFieldTouched,
			setFieldValue,
			errors,
		],
	);

	const projectType = values.projectType?.label ?? null;

	return (
		<>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
				<Title>{t('Project')}</Title>
				<FormField>
					<FormikSelect
						isAsync
						id="projectType"
						name="projectType"
						label={t('Project type')}
						value={values.projectType}
						error={errors.projectType}
						touched={touched.projectType}
						setFieldValue={setFieldValue}
						onBlur={handleBlur}
						loadOptions={(signal, query) => loadProjectTypeOptions(signal, query, true)}
						isDisabled={isDisabled}
						isRequired
					/>
				</FormField>
				<ConditionalGridSection hasFlag={projectType === fullNameProjectTypes.PV}>
					<FormField>
						<FormikRadio
							id="onGrid"
							name="onGrid"
							value={values.onGrid}
							error={errors.onGrid}
							touched={touched.onGrid}
							onChange={handleChange}
							options={[
								{
									value: 'on-grid',
									label: 'On-grid',
								},
								{
									value: 'off-grid',
									label: 'Off-grid',
								},
							]}
							isRow
							isDisabled={isDisabled}
						/>
					</FormField>
					<FormField>
						<FormikCheckbox
							id="energyStorage"
							name="energyStorage"
							label={t('Energy storage')}
							checked={values.energyStorage}
							error={errors.energyStorage}
							touched={touched.energyStorage}
							onChange={handleChange}
							onBlur={handleBlur}
							isDisabled={isDisabled}
						/>
					</FormField>
					<FormField>
						<FormikCheckbox
							id="gensets"
							name="gensets"
							label={t('Gensets')}
							checked={values.gensets}
							error={errors.gensets}
							touched={touched.gensets}
							onChange={handleChange}
							onBlur={handleBlur}
							isDisabled={isDisabled}
						/>
					</FormField>
				</ConditionalGridSection>
			</Grid>
			<ConditionalGridSection
				hasFlag={projectType === fullNameProjectTypes.PV || isDisabled}
				item
				xs={12}
				sm={12}
				md={6}
				lg={4}
				xl={4}
			>
				<Title>{t('Size')}</Title>
				<ConditionalGridSection hasFlag={projectType === fullNameProjectTypes.PV}>
					<FormField>
						<FormikInput
							id="systemSizeKwp"
							name="systemSizeKwp"
							label={t('System size')}
							value={values.systemSizeKwp}
							error={errors.systemSizeKwp}
							touched={touched.systemSizeKwp}
							onChange={handleSystemSizeChange}
							onBlur={handleBlur}
							isRequired
							isDisabled={isDisabled}
							unit={UNITS.KWP}
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="kwhToKwpRel"
							name="kwhToKwpRel"
							label={t('Yield')}
							value={values.kwhToKwpRel}
							error={errors.kwhToKwpRel}
							touched={touched.kwhToKwpRel}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isDisabled={isDisabled}
							unit={UNITS.KWH_KWP}
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="solarUtilisationRate"
							name="solarUtilisationRate"
							label={t('Solar utilization rate')}
							value={values.solarUtilisationRate}
							error={errors.solarUtilisationRate}
							touched={touched.solarUtilisationRate}
							onChange={handleChange}
							onBlur={handleBlur}
							tooltip={{
								tooltip: t(
									'The % of annual generated energy expected to be consumed.  The remaining % is the expected grid injection, if feed-in applies.',
								),
							}}
							isPercentage
							isRequired={projectType === fullNameProjectTypes.PV}
							isDisabled={isDisabled}
							unit={UNITS.PERCENT}
						/>
					</FormField>
				</ConditionalGridSection>
			</ConditionalGridSection>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
				<Title>{t('Tariff')}</Title>
				<FormField>
					<FormikInput
						id="gridEnergyTariffPerKwh"
						name="gridEnergyTariffPerKwh"
						label={t('Client energy tariff')}
						value={values.gridEnergyTariffPerKwh}
						error={errors.gridEnergyTariffPerKwh}
						touched={touched.gridEnergyTariffPerKwh}
						onChange={handleChange}
						onBlur={handleBlur}
						prefix={CurrencySign}
						tooltip={{
							tooltip: t('per kWh ex. VAT'),
						}}
						isDisabled={isDisabled}
					/>
				</FormField>
				<FormField>
					<FormikSelect
						id="tariffCurrency"
						name="tariffCurrency"
						label={t('Tariff currency')}
						value={values.tariffCurrency}
						error={errors.tariffCurrency}
						touched={touched.tariffCurrency}
						setFieldValue={setFieldValue}
						onBlur={handleBlur}
						options={currencyOptions}
						isDisabled={isDisabled}
						isRequired
					/>
				</FormField>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
				<Title>{t('Client')}</Title>
				<FormField>
					<FormikSelect
						isAsync
						id="country"
						name="country"
						label={t('Country')}
						value={values.country}
						error={errors.country}
						touched={touched.country}
						setFieldValue={setFieldValue}
						onBlur={handleBlur}
						loadOptions={loadCountryOptions}
						isRequired
						isDisabled={isDisabled}
					/>
				</FormField>
				<FormField>
					<FormikInput
						id="clientName"
						name="clientName"
						label={t('Client name')}
						value={values.clientName}
						error={errors.clientName}
						touched={touched.clientName}
						onChange={handleChange}
						onBlur={handleBlur}
						isDisabled={isDisabled}
					/>
				</FormField>
				<FormField>
					<FormikSelect
						isAsync
						id="industry"
						name="industry"
						label={t('Industry')}
						value={values.industry}
						error={errors.industry}
						touched={touched.industry}
						setFieldValue={setFieldValue}
						onBlur={handleBlur}
						loadOptions={loadIndustryOptions}
						isRequired
						isDisabled={isDisabled}
						components={{
							Option: IndustrySelectOption,
							SingleValue: IndustrySelectValue,
						}}
					/>
				</FormField>
				<FormField>
					<FormikSelect
						id="offerCurrency"
						name="offerCurrency"
						label={t('Client currency')}
						value={values.offerCurrency}
						error={errors.offerCurrency}
						touched={touched.offerCurrency}
						setFieldValue={setFieldValue}
						onBlur={handleBlur}
						options={currencyOptions}
						isDisabled={isDisabled}
					/>
				</FormField>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
				<Title>{t('Description')}</Title>

				<FormField>
					<FormikTextArea
						id="description"
						name="description"
						label={t('Description')}
						value={values.description}
						error={errors.description}
						touched={touched.description}
						onChange={handleChange}
						onBlur={handleBlur}
						isDisabled={isDisabled}
						isRequired
						setFieldTouched={setFieldTouched}
					/>
				</FormField>
			</Grid>
		</>
	);
};

TopSection.propTypes = {
	values: PropTypes.shape({
		tariffCurrency: PropTypes.shape({ value: PropTypes.string }),
		epcVolumeSwitch: PropTypes.bool,
		omPriceSwitch: PropTypes.bool,
		annualOmPrice: PropTypes.string,
		annualOmPriceKwp: PropTypes.string.isRequired,
		epcVolumeExclVat: PropTypes.string.isRequired,
		epcVolumeExclVatKwp: PropTypes.string.isRequired,
		projectType: PropTypes.shape({ label: PropTypes.string.isRequired }).isRequired,
		onGrid: PropTypes.string.isRequired,
		energyStorage: PropTypes.bool.isRequired,
		gensets: PropTypes.bool.isRequired,
		systemSizeKwp: PropTypes.string.isRequired,
		kwhToKwpRel: PropTypes.string.isRequired,
		solarUtilisationRate: PropTypes.number,
		country: PropTypes.shape({}),
		gridEnergyTariffPerKwh: PropTypes.string.isRequired,
		clientName: PropTypes.string.isRequired,
		industry: PropTypes.shape({}),
		offerCurrency: PropTypes.shape({}),
		description: PropTypes.string,
	}).isRequired,
	errors: PropTypes.shape({
		tariffCurrency: PropTypes.string,
		epcVolumeSwitch: PropTypes.string,
		omPriceSwitch: PropTypes.string,
		annualOmPrice: PropTypes.string,
		annualOmPriceKwp: PropTypes.string,
		epcVolumeExclVat: PropTypes.string,
		epcVolumeExclVatKwp: PropTypes.string,
		projectType: PropTypes.string,
		onGrid: PropTypes.string,
		energyStorage: PropTypes.string,
		gensets: PropTypes.string,
		systemSizeKwp: PropTypes.string,
		kwhToKwpRel: PropTypes.string,
		solarUtilisationRate: PropTypes.string,
		country: PropTypes.string,
		gridEnergyTariffPerKwh: PropTypes.string,
		clientName: PropTypes.string,
		industry: PropTypes.string,
		offerCurrency: PropTypes.string,
		description: PropTypes.string,
	}).isRequired,
	touched: PropTypes.shape({
		tariffCurrency: PropTypes.bool,
		epcVolumeSwitch: PropTypes.bool,
		omPriceSwitch: PropTypes.bool,
		annualOmPrice: PropTypes.bool,
		annualOmPriceKwp: PropTypes.bool,
		epcVolumeExclVat: PropTypes.bool,
		epcVolumeExclVatKwp: PropTypes.bool,
		projectType: PropTypes.bool,
		onGrid: PropTypes.bool,
		energyStorage: PropTypes.bool,
		gensets: PropTypes.bool,
		systemSizeKwp: PropTypes.bool,
		kwhToKwpRel: PropTypes.bool,
		solarUtilisationRate: PropTypes.bool,
		country: PropTypes.bool,
		gridEnergyTariffPerKwh: PropTypes.bool,
		clientName: PropTypes.bool,
		industry: PropTypes.bool,
		offerCurrency: PropTypes.bool,
		description: PropTypes.bool,
	}).isRequired,
	handleBlur: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool.isRequired,
	currencyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TopSection;
