import { useMemo } from 'react';
import { completedTypes, responsibleTeamOptions } from 'PMWorkflow/constants/options';
import { formatProjectTiles, formatProjectTilesHighLightFields } from 'PMWorkflow/utils/PMWorkflowTilesUtils';

const fallbackValues = {
	title: '',
	weight: {
		value: 5,
		label: '5',
	},
	countryIds: [],
	projectTypeIds: [],
	contractTypeIds: [],
	systemSize: 0,
	lessOrGreater: false,
	completedValue: {},
	responsibleTeam: null,
	canBeSkipped: false,
	expectedDuration: null,
	projectTiles: [],
	projectFields: [],
	automationRules: '',
	forCampaign: false,
};

const useStepsFieldsFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			title: data?.title,
			weight: {
				value: data?.weight,
				label: data?.weight?.toString(),
			},
			projectTypeIds: data?.projectTypes?.map(projectType => ({
				value: projectType.id,
				label: projectType.name,
			})),
			countryIds: data?.countries?.map(country => ({
				value: country.id,
				label: country.name,
			})),
			contractTypeIds: data?.contractTypes?.map(contract => ({
				value: contract.id,
				label: contract.name,
			})),
			calculations: data?.calculations?.map(calculation => ({
				value: calculation.option,
				label: calculation.displayName,
			})),
			systemSize: data?.forSystemSize?.value,
			lessOrGreater: data?.forSystemSize?.value
				? data?.forSystemSize?.operator
					? data?.forSystemSize?.operator
					: 'GREATER_THAN'
				: 'GREATER_THAN',
			completedValue: completedTypes.filter(item => item.value === data?.completedValue)[0],
			canBeSkipped: data?.canBeSkipped,
			responsibleTeam: data?.responsibleTeam
				? {
						label: responsibleTeamOptions.find(option => option.value === data?.responsibleTeam?.name)?.label,
						value: data?.responsibleTeam?.name,
				  }
				: fallbackValues.responsibleTeam,
			expectedDuration: data?.expectedDuration,
			description: data?.description,
			projectTiles: formatProjectTiles(data?.projectTiles),
			projectFields: formatProjectTilesHighLightFields(data?.projectFields),
			automationRules: data?.automatedRule,
			forCampaign: data?.forCampaign,
		}),
		[data],
	);

export default useStepsFieldsFormInitialValues;
