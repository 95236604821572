import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import EditableTile from 'Common/components/Tile/EditableTile';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

import MapLink from 'Common/components/MapLink';
import { types as offerTypes } from 'Projects/constants/offerTypes';

import colors from 'Application/theme/colors';

const Description = styled.div`
	padding: 15px;
	border-radius: 20px;
	color: ${colors.text.black};
	font-size: 16px;
	line-height: 21px;
	white-space: break-spaces;
`;

const MapButtonContainer = styled.div`
	margin-top: 32px;
`;

const GeneralTab = ({ epcOffer }) => {
	const { t } = useTranslation();

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
				<EditableTile title={t('Client')}>
					<KeyValueVisualization title={t('Project ID')} value={epcOffer.externalId} />
					<KeyValueVisualization
						title={t('Client')}
						value={epcOffer.client ?? t('(No client)')}
						valueStyle={!epcOffer.client ? { fontStyle: 'italic' } : {}}
					/>
					<KeyValueVisualization title={t('Industry')} value={epcOffer.industry} />
				</EditableTile>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
				<EditableTile title={t('Location')}>
					<KeyValueVisualization title={t('Country')} value={epcOffer.country} />
					<KeyValueVisualization title={t('City')} value={epcOffer.cityRegion} />
					<MapButtonContainer>
						<MapLink href={epcOffer.projectLocationUrl} />
					</MapButtonContainer>
				</EditableTile>
			</Grid>
			{epcOffer.type === offerTypes.RFEP && (
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<EditableTile title={t('Description')}>
						<Description>{epcOffer.description ?? '-'}</Description>
					</EditableTile>
				</Grid>
			)}
		</TilesGridWrapper>
	);
};

GeneralTab.propTypes = {
	epcOffer: PropTypes.shape({
		externalId: PropTypes.number,
		client: PropTypes.string,
		industry: PropTypes.string,
		country: PropTypes.string,
		cityRegion: PropTypes.string,
		projectLocationUrl: PropTypes.string,
		type: PropTypes.string,
		description: PropTypes.string,
	}).isRequired,
};

export default GeneralTab;
