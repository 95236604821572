import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';

import formatNumber from 'Common/utils/formatNumber';

const ThinText = styled.span`
	font-size: 14px;
	color: ${colors.text.black};
	margin: 10px 0;
	min-width: 36px;
`;

const Text = styled.span`
	font-size: 14px;
	font-weight: 600;
	margin: 10px 0;
	text-transform: none;
`;

export const formatRepaymentScheme = (annuity, bullet, constant) =>
	[
		{ value: annuity, name: 'Annuity' },
		{ value: bullet, name: 'Bullet' },
		{ value: constant, name: 'Constant' },
	]
		.filter(e => e.value !== undefined && e.value > 0)
		.map((e, index, array) => (
			<ThinText key={`${e.name}-${e.value}`}>
				<Text>{`${formatNumber(e.value, 1)}%`}</Text> {`${e.name}${index !== array.length - 1 ? ', ' : ''}`}
			</ThinText>
		));

export const hasMissingData = campaigns =>
	campaigns?.finance_details?.co2SavingsPerEURInvested === null ||
	campaigns?.finance_details?.co2SavingsPerEURInvested === undefined ||
	campaigns?.finance_details?.co2Savings === null ||
	campaigns?.finance_details?.co2Savings === undefined;

export const generateTooltipMessage = (campaigns, t) => {
	const isSynched = Boolean(campaigns?.synched);
	const hasMissingCampaignData = hasMissingData(campaigns);

	if (!isSynched || hasMissingCampaignData) {
		return (
			<>
				{!isSynched && t('Campaign differs to portagon. Check the finance volumes.')}
				{!isSynched && hasMissingCampaignData && (
					<>
						<br />
						<br />
					</>
				)}
				{hasMissingCampaignData &&
					t(
						'Cannot calculate statistics for Campaign or its related Projects. Check the Campaign and Project values.',
					)}
			</>
		);
	}

	return undefined;
};
