import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';

const Layout = styled.div`
	display: flex;
	height: 100vh;
	overflow-y: auto;
`;
const Content = styled.div`
	flex: 1;
`;

const SingleSectionLayout = ({ children }) => (
	<Layout>
		<Content>{children}</Content>
	</Layout>
);

SingleSectionLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default SingleSectionLayout;
