const selectedPrimaryClientOffer = offer => offer.clientOffers.find(clientOffer => clientOffer.primaryOffer);

export const checkProjectHasPrimaryClientOffer = epcOffers =>
	epcOffers.filter(offer => selectedPrimaryClientOffer(offer) !== undefined).length > 0;

export const findProjectPrimaryOffer = epcOffers =>
	epcOffers.reduce((res, offer) => selectedPrimaryClientOffer(offer) || res, undefined);

export const sortClientOfferGroupsByPrimary = groups => {
	let sortedOfferGroups = [...groups];

	if (sortedOfferGroups) {
		sortedOfferGroups = [
			...sortedOfferGroups.map(group => ({
				...group,
				hasPrimaryOffer: group.clientOffers.filter(option => option.primaryOffer).length > 0,
			})),
		];

		return [...sortedOfferGroups.sort((a, b) => b.hasPrimaryOffer - a.hasPrimaryOffer)];
	} else {
		return null;
	}
};

export const hasClientOffers = project => {
	const offersNumber = project?.epcOffers.reduce((prev, epcOffer) => {
		return prev + epcOffer.clientOffers.length;
	}, 0);
	return offersNumber > 0;
};
