import { useRef, useMemo, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { push } from 'redux-first-history';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ButtonLink from 'Common/components/buttons/ButtonLink';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import Filters from 'Common/components/filter/FiltersContainer';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import useUserListFilterData from 'UserManagement/components/UserList/hooks/useUserListFilterData';
import UserListTable from 'UserManagement/components/UserList/UserListTable';
import TabsContainer from 'Common/components/tabs';
import { updateCurrentTab } from 'Application/reducers/reduxTabs';
import UserOverlay from '../UserForm/UserOverlay';
import crudModes from 'Common/constants/crudModes';

const UserList = () => {
	const { t } = useTranslation();
	const [isCreateOpen, setIsCreateOpen] = useState(false);

	const dispatch = useDispatch();
	const location = useLocation();

	const isEpcList = useMemo(() => (location.pathname === '/epc-users' ? 1 : 0), [location.pathname]);

	const canCreateUsers = isAuthorized([roles.ADMIN]);

	const filterButtonRef = useRef();
	const filtersData = useUserListFilterData(isEpcList);

	useEffect(() => {
		dispatch(updateCurrentTab({ tab: 'users', tabIndex: isEpcList ? 1 : 0 }));
	}, [isEpcList, dispatch]);

	const onTabSelect = useCallback(
		selectedTabIndex => {
			if (selectedTabIndex === 0) {
				dispatch(push('/users'));
			} else {
				dispatch(push('/epc-users'));
			}
		},
		[dispatch],
	);

	const handleCreateClose = () => {
		setIsCreateOpen(false);
	};

	const tabsConfig = useMemo(
		() => [
			{
				label: 'Ecoligo',
				PanelComponent: UserListTable,
				panelProps: {
					key: 'ecoligo',
				},
				authorizedRoles: [roles.ADMIN],
				onTabSelect: onTabSelect,
			},
			{
				label: 'EPC',
				PanelComponent: UserListTable,
				panelProps: {
					key: 'epc',
					isEPConly: true,
				},
				onTabSelect: onTabSelect,
			},
		],
		[onTabSelect],
	);

	return (
		<>
			<Helmet>
				<title>{t('Ecoligo Users')}</title>
			</Helmet>

			<HeaderContainer>
				<HeaderTitle>{t('Users')}</HeaderTitle>
				<HeaderActions>
					<div ref={filterButtonRef}></div>
					{canCreateUsers && (
						<ButtonLink
							label="Create User - Users"
							type="submit"
							onClick={() => setIsCreateOpen(true)}
							text={t('Create User')}
							icon="addRounded"
						/>
					)}
				</HeaderActions>
			</HeaderContainer>
			{isEpcList ? (
				<Filters key={0} table="users" buttonRef={filterButtonRef} filtersData={filtersData} />
			) : (
				<Filters key={1} table="epcUsers" buttonRef={filterButtonRef} filtersData={filtersData} />
			)}

			<TabsContainer page={'users'} tabsConfig={tabsConfig} />

			<UserOverlay
				isOpen={isCreateOpen}
				mode={crudModes.CREATE}
				onClose={handleCreateClose}
				onFormSubmit={() => {}}
			/>
		</>
	);
};

export default UserList;
