import AccordionDetails from '@mui/material/AccordionDetails';
import useReponsive from 'Common/hooks/useResponsive';
import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';

const _ActivityDetails = styled(AccordionDetails)`
	&.MuiAccordionDetails-root {
		background: ${props => props.background};
		border-radius: 20px;
		padding: ${({ $isMobile }) => ($isMobile ? '8px' : '0px 16px 8px 23px')};
		margin: 0px;
	}
`;

const ActivityDetails = ({ children, ...props }) => {
	const { isMobile } = useReponsive();

	return (
		<_ActivityDetails $isMobile={isMobile} {...props}>
			{children}
		</_ActivityDetails>
	);
};

ActivityDetails.defaultProps = {
	children: null,
};

ActivityDetails.propTypes = {
	children: PropTypes.node,
};

export default ActivityDetails;
