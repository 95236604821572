import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';

const BreadcrumbTitle = styled.span`
	line-height: 34px;
	font-size: 30px;
	font-weight: 600;
	color: ${colors.text.primary};
	text-transform: uppercase;
	justify-content: space-between;
`;

export default BreadcrumbTitle;
