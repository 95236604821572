import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';

const Mandatory = styled.span`
    display: contents;
    color: ${colors.error.main};
    font-weight: 600;
    font-size: 17px;
    line-height: 17px;
}

    &::after {
        display: contents;
        content: ' *';
`;

export default Mandatory;
