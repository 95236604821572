import { useMemo } from 'react';
import * as yup from 'yup';

const usePartnerDetailsFormValidationSchema = () =>
	useMemo(
		() =>
			yup.object({
				name: yup.string().required('Name is required'),
				partnerType: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.required('Partner Type is required')
					.nullable(),
			}),

		[],
	);

export default usePartnerDetailsFormValidationSchema;
