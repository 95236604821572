import api from 'Application/api/api';

const getPartners = (signal, configuration, filters = {}, searchQuery) => {
	const { page = 1, itemsPerPage = 20, sortBy = 'name', sortDirection = 'ASC' } = configuration;

	const params = {
		...filters,
		...configuration,
		query: searchQuery,
	};

	if (page) {
		params.page = page - 1;
	}

	if (itemsPerPage) {
		params.size = itemsPerPage;
	}

	if (sortBy && sortDirection) {
		params.sort = `${sortBy},${sortDirection}`;
	}

	return api.get('/other-partners/get-pageable', {
		params,
		signal,
	});
};

export default getPartners;
