import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import EditableTile from 'Common/components/Tile/EditableTile';

import { components } from 'react-select';

import usePartnersTileFormInitialValues from 'Entities/components/EntitiesDetails/Tabs/GeneralTab/Tiles/PartnersTile/hooks/usePartnersTileFormInitialValues';
import usePartnersTileFormValidationSchema from 'Entities/components/EntitiesDetails/Tabs/GeneralTab/Tiles/PartnersTile/hooks/usePartnersTileFormValidationSchema';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';
import loadOtherPartnersOptions from 'Partners/utils/loadOtherPartnersOptions';
import assignEntityLawyer from 'Entities/api/assignEntityLawyer';
import assignEntityTaxAdvisor from 'Entities/api/assignEntityTaxAdvisor';
import assignEntityAuditor from 'Entities/api/assignEntityAuditor';
import unassignEntityLawyer from 'Entities/api/unassignEntityLawyer';
import unassignEntityTaxAdvisor from 'Entities/api/unassignEntityTaxAdvisor';
import unassignEntityAuditor from 'Entities/api/unassignEntityAuditor';
import assignEntityAccountant from 'Entities/api/assignEntityAccountant';
import unassignEntityAccountant from 'Entities/api/unassignEntityAccountant';

const ParterSingleValue = props => (
	<>
		<components.SingleValue {...props}>
			{props?.hasValue ? (
				<>
					<span>{props.getValue()[0].label}</span>
					&nbsp;
					<span>
						<b>{props?.getValue()[0]?.partnerTypeName}</b>
					</span>
				</>
			) : (
				''
			)}
		</components.SingleValue>
	</>
);

ParterSingleValue.defaultProps = {
	getValue: () => null,
	hasValue: false,
};

ParterSingleValue.propTypes = {
	getValue: PropTypes.func,
	hasValue: PropTypes.bool,
};

const PartnersTile = ({ entity, onEnterEditMode, onExitEditMode, onDirtyForm, onSave, highlightLabels }) => {
	const { t } = useTranslation();

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = usePartnersTileFormInitialValues(entity);
	const validationSchema = usePartnersTileFormValidationSchema();

	const { errors, touched, values, handleSubmit, dirty, handleBlur, setFieldValue, resetForm } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			setIsSavingChanges(true);

			try {
				if (onDirtyForm) {
					onDirtyForm(false);
				}

				if (values?.lawyer?.value) {
					await assignEntityLawyer(abortController.signal, {
						contractPartyId: entity.id,
						partnerId: values?.lawyer?.value,
					});
				} else {
					await unassignEntityLawyer(abortController.signal, {
						contractPartyId: entity.id,
					});
				}

				if (values?.taxAdvisor?.value) {
					await assignEntityTaxAdvisor(abortController.signal, {
						contractPartyId: entity.id,
						partnerId: values?.taxAdvisor?.value,
					});
				} else {
					await unassignEntityTaxAdvisor(abortController.signal, {
						contractPartyId: entity.id,
					});
				}

				if (values?.accountant?.value) {
					await assignEntityAccountant(abortController.signal, {
						contractPartyId: entity.id,
						partnerId: values?.accountant?.value,
					});
				} else {
					await unassignEntityAccountant(abortController.signal, {
						contractPartyId: entity.id,
					});
				}

				if (values?.auditor?.value) {
					await assignEntityAuditor(abortController.signal, {
						contractPartyId: entity.id,
						partnerId: values?.auditor?.value,
					});
				} else {
					await unassignEntityAuditor(abortController.signal, {
						contractPartyId: entity.id,
					});
				}

				await onSave();
				handleCancel();
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Partners')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
			zIndex={1}
		>
			<FormField>
				<FormikSelect
					isAsync
					id="lawyer"
					name="lawyer"
					label={t('Lawyer')}
					value={values.lawyer}
					error={errors.lawyer}
					touched={touched.lawyer}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={loadOtherPartnersOptions}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('lawyer')}
					components={{
						SingleValue: ParterSingleValue,
					}}
					isClearable
				/>
			</FormField>
			<FormField>
				<FormikSelect
					isAsync
					id="taxAdvisor"
					name="taxAdvisor"
					label={t('Tax advisor')}
					value={values.taxAdvisor}
					error={errors.taxAdvisor}
					touched={touched.taxAdvisor}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={loadOtherPartnersOptions}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('taxAdvisor')}
					components={{
						SingleValue: ParterSingleValue,
					}}
					isClearable
				/>
			</FormField>
			<FormField>
				<FormikSelect
					isAsync
					id="accountant"
					name="accountant"
					label={t('Accountant')}
					value={values.accountant}
					error={errors.accountant}
					touched={touched.accountant}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={loadOtherPartnersOptions}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('accountant')}
					components={{
						SingleValue: ParterSingleValue,
					}}
					isClearable
				/>
			</FormField>
			<FormField>
				<FormikSelect
					isAsync
					id="auditors"
					name="auditor"
					label={t('Auditor')}
					value={values.auditor}
					error={errors.auditor}
					touched={touched.auditor}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={loadOtherPartnersOptions}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('auditor')}
					components={{
						SingleValue: ParterSingleValue,
					}}
					isClearable
				/>
			</FormField>
		</EditableTile>
	);
};

PartnersTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	onSave: async () => {},
	highlightLabels: [],
};

PartnersTile.propTypes = {
	entity: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		taxAdvisor: PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			name: PropTypes.string,
			partnerType: PropTypes.shape({
				id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				name: PropTypes.string,
			}),
		}),
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	onSave: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default PartnersTile;
