import { memo, useEffect, useRef } from 'react';
import { css } from 'styled-components';
import { useState } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import Icon from 'Common/components/icons/Icon';
import Label from 'Common/components/form/Label';
import ErrorMessage from 'Common/components/form/ErrorMessage';

import colors from 'Application/theme/colors';
import { useLabelColor } from './hooks/useLabelColor';
import $inputBorderColor from './utils/$inputBorderColor';
import { useMemo } from 'react';
import useInputPlaceholder from 'Common/components/form/hooks/useInputPlaceholder';

const OuterWrapper = styled.div`
	position: relative;
`;
const disabledStyles = css`
	textarea {
		pointer-events: none;
		cursor: not-allowed;
	}
	svg {
		color: ${colors.text.disabled};
	}
`;

const TextareaWrapper = styled.div`
	position: relative;
	flex: 1;
	overflow: hidden;
	max-width: 100%;

	${({ $isDisabled }) => $isDisabled && disabledStyles}

	${({ $hasClearButton }) =>
		$hasClearButton &&
		css`
			textarea {
				padding-right: 45px;
				margin-right: -25px;
			}
		`}
`;

const StyledTextarea = styled.textarea`
	display: block;
	width: 100%;
	height: 22px;
	min-height: ${props => props.minRows * 22}px;
	max-height: 200px;
	padding: 0px;
	background: transparent;
	border: none;
	border-bottom: 1px solid transparent;
	resize: none;
	color: ${colors.text.black};
	font-weight: 400;
	font-size: 15px;
	line-height: 21px;
	border-color: ${$inputBorderColor};
	overflow: hidden;

	&:hover,
	&:focus {
		overflow: auto;
	}
	&::placeholder {
		color: ${colors.grey.main};
		font-size: 16px;
	}

	&:focus {
		outline: 0 none;
		font-weight: 600;
	}

	&[disabled],
	&[readonly] {
		color: ${colors.text.grey};
		cursor: not-allowed;
	}
`;

const ClearIcon = styled(Icon)`
	position: absolute;
	top: 50%;
	right: 15px;
	color: ${colors.grey.main};
	font-size: 20px !important;
	transform: translateY(-50%);
	cursor: pointer;

	&:hover {
		color: ${colors.grey.dark};
	}
`;

const FormikTextArea = memo(
	({
		// Base props
		id,
		label,
		error,
		touched,
		value: rawValue,

		// Handlers
		onBlur,
		onClick,
		onClear,
		onFocus,
		onChange,
		setFieldTouched,
		onMouseEnter,
		onMouseLeave,

		// State of the input props
		isTile,
		isOverlay,
		isRequired,
		isDisabled,
		isClearable,
		isHighlighted,

		// Additional elements
		unit,
		tooltip,
		isInEditMode,
		placeholder: propPlaceholder,
		minRows,
		...props
	}) => {
		const textareaRef = useRef();
		const hasLabel = label !== null;
		const hasClearButton = isClearable;

		const [hasHover, setHover] = useState(false);
		const [hasFocus, setHasFocus] = useState(false);

		const hasError = !!error && touched && !hasFocus;

		const labelColor = useLabelColor({
			isDisabled,
			hasError,
			isHighlighted,
			isTile,
			isInEditMode,
			hasHover,
			hasFocus,
		});

		const isEditMode = useMemo(
			() => (isTile && isInEditMode) || isOverlay || !isTile,
			[isTile, isOverlay, isInEditMode],
		);

		const value = useMemo(() => {
			return rawValue ?? '';
		}, [rawValue]);

		const placeholder = useInputPlaceholder(isDisabled, unit, propPlaceholder, isTile, isInEditMode);

		const handleClick = (...args) => {
			typeof onClick === 'function' && onClick(...args);
			requestAnimationFrame(() => {
				if (isDisabled) {
					textareaRef?.current?.focus();
				}
			});
		};

		const handleClearIconClick = (...args) => {
			if (isClearable && onClear) {
				onClear(...args);
			}
		};

		const handleOnFocus = (e, ...args) => {
			if (!isTile || isEditMode) {
				setHasFocus(true);
				typeof onFocus === 'function' && onFocus(e, ...args);
			} else {
				e.target.blur();
			}
		};

		const handleOnBlur = (...args) => {
			if (isEditMode) {
				setHasFocus(false);
				typeof setFieldTouched === 'function' && setFieldTouched(props.name, true);
				typeof onBlur === 'function' && onBlur(...args);
			}
		};

		const handleMouseEnter = (...args) => {
			setHover(true);
			typeof onMouseEnter === 'function' && onMouseEnter(...args);
		};

		const handleMouseLeave = (...args) => {
			setHover(false);
			typeof onMouseLeave === 'function' && onMouseLeave(...args);
		};

		useEffect(() => {
			if (textareaRef.current) {
				textareaRef.current.style.height = '0px';
				textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 1}px`; // +1px to account for bottom border and prevent scrollbars
			}
		}, [rawValue, textareaRef]);

		return (
			<OuterWrapper>
				<TextareaWrapper $hasClearButton={hasClearButton} $isDisabled={isDisabled}>
					{hasLabel && (
						<Label
							label={label}
							color={labelColor}
							isRequired={isRequired}
							htmlFor={props.id}
							tooltip={tooltip}
							onClick={handleClick}
						/>
					)}
					<StyledTextarea
						id={id}
						$isOverlay={isOverlay}
						value={value}
						$isTile={isTile}
						ref={textareaRef}
						onChange={onChange}
						$hasError={hasError}
						$hasFocus={hasFocus}
						$isDisabled={isDisabled}
						onBlur={handleOnBlur}
						onClick={handleClick}
						onFocus={handleOnFocus}
						placeholder={placeholder}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						disabled={isDisabled}
						className="textarea"
						minRows={minRows}
					/>
				</TextareaWrapper>
				{hasClearButton && <ClearIcon icon="close" onClick={handleClearIconClick} />}
				{hasError && <ErrorMessage message={error} />}
			</OuterWrapper>
		);
	},
);

FormikTextArea.defaultProps = {
	// Base props
	id: '',
	label: '',
	error: '',
	touched: false,
	value: '',
	name: '',

	// Handlers
	onBlur: () => {},
	onClick: () => {},
	onClear: () => {},
	onFocus: () => {},
	onChange: () => {},
	setFieldTouched: () => {},
	onMouseEnter: () => {},
	onMouseLeave: () => {},

	// State of the input props
	isTile: false,
	isOverlay: false,
	isRequired: false,
	isDisabled: false,
	isClearable: false,
	isHighlighted: false,

	// Additional elements
	unit: '',
	tooltip: '',
	isInEditMode: false,
	placeholder: '',
	minRows: 1,
};

FormikTextArea.propTypes = {
	// Base props
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.string,
	error: PropTypes.string,
	touched: PropTypes.bool,
	value: PropTypes.string,
	name: PropTypes.string,

	// Handlers
	onBlur: PropTypes.func,
	onClick: PropTypes.func,
	onClear: PropTypes.func,
	onFocus: PropTypes.func,
	onChange: PropTypes.func,
	setFieldTouched: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,

	// State of the input props
	isTile: PropTypes.bool,
	isOverlay: PropTypes.bool,
	isRequired: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isClearable: PropTypes.bool,
	isHighlighted: PropTypes.bool,

	// Additional elements
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
	isInEditMode: PropTypes.bool,
	placeholder: PropTypes.string,
	unit: PropTypes.string,
	minRows: PropTypes.number,
};

FormikTextArea.displayName = 'FormikTextArea';

export default FormikTextArea;
