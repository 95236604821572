import styled from 'styled-components/macro';
import { cssTransition, toast, ToastContainer as ReactToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PropTypes } from 'prop-types';

import Icon from 'Common/components/icons/Icon';
import { createGlobalStyle } from 'styled-components';
import colors from 'Application/theme/colors';

const CloseButton = ({ closeToast }) => (
	<Icon icon="closeRounded" className="Toastify__close-button" onClick={closeToast} />
);

CloseButton.defaultProps = {
	closeToast: () => null,
};

CloseButton.propTypes = {
	closeToast: PropTypes.func,
};

const FadeInOut = cssTransition({
	enter: 'fade-in',
	exit: 'fade-out',
});

const KeyFrameStyles = createGlobalStyle`
  @keyframes fade-in-fwd {
    0% {
      transform: translateZ(-80px);
      opacity: 0;
    }
    100% {
      transform: translateZ(0);
      opacity: 1;
    }
  }

  .fade-in {
    animation: fade-in-fwd 0.35s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  @keyframes fade-out-bck {
    0% {
      transform: translateZ(0);
      opacity: 1;
    }
    100% {
      transform: translateZ(-80px);
      opacity: 0;
    }
  }

  .fade-out {
    animation: fade-out-bck 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
`;

const WrappedToastContainer = ({ className, ...props }) => (
	<div className={className}>
		<KeyFrameStyles />
		<ReactToastContainer
			position={toast.POSITION.BOTTOM_RIGHT}
			autoClose={8000}
			transition={FadeInOut}
			closeButton={<CloseButton />}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			pauseOnFocusLoss
			pauseOnHover
			{...props}
		/>
	</div>
);

WrappedToastContainer.propTypes = {
	className: PropTypes.string.isRequired,
};

const ToastContainer = styled(WrappedToastContainer).attrs({})`
	.Toastify__toast-container {
		width: calc(100% - 40px);
		max-width: 450px;
	}

	.Toastify__toast {
		min-height: 90px;
		padding: 12px 62px;
		border-radius: 5px;
		background: ${colors.grey.lightest};
		color: ${colors.text.primary};
	}

	.Toastify__toast--error {
		svg {
			color: ${colors.error.main};
		}
	}

	.Toastify__toast--warning {
		svg {
			color: ${colors.warning.main};
		}
	}

	.Toastify__toast--success {
		svg {
			color: ${colors.success.main};
		}
	}

	.Toastify__toast--info {
		svg {
			color: ${colors.primary.main};
		}
	}

	.Toastify__toast-body {
		svg {
			position: absolute;
			left: 20px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.Toastify__close-button {
		position: absolute;
		top: 25%;
		right: 20px;
		transform: translateY(-50%);
		color: ${colors.text.primary} !important;
	}
`;

export default ToastContainer;
