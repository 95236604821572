const pluginName = 'useStickyColumns';

export const useStickyColumns = hooks => {
	hooks.useInstance.push(useInstance);
	hooks.getCellProps.push(getCellProps);
	hooks.getHeaderProps.push(getHeaderProps);
};

useStickyColumns.pluginName = pluginName;

const useInstance = instance => {
	const { allColumns } = instance;

	if (allColumns.every(column => column.sticky)) {
		throw new Error('React-Table: Cannot make all columns sticky');
	}
};

const getStickyCellProps = (stickyColumn, instance) => {
	const { columns } = instance;

	let stickyOffset = 0;

	const columnIndex = columns.findIndex(column => column.id === stickyColumn.id);

	if (columnIndex < columns.length - 1) {
		const nextColumns = columns.slice(columnIndex + 1);
		const areAllNextColumnsSticky = nextColumns.every(column => column.sticky);

		if (!areAllNextColumnsSticky) {
			throw new Error(
				`React-Table: Cannot have column "${stickyColumn.id}" as sticky, because not all columns after it are sticky`,
			);
		}

		stickyOffset += Math.max(
			nextColumns.reduce((sum, column) => sum + parseInt(column.getHeaderProps().style.minWidth), 0),
			nextColumns.reduce((sum, column) => sum + parseInt(column.getHeaderProps().style.width), 0),
		);
	}

	return {
		style: {
			position: 'sticky',
			right: stickyOffset,
		},
	};
};

const getCellProps = (props, { instance, cell }) => {
	const isSticky = cell.column.sticky ?? false;

	if (isSticky) {
		return [props, getStickyCellProps(cell.column, instance)];
	}

	return [props];
};

const getHeaderProps = (props, { instance, column }) => {
	const isSticky = column.sticky ?? false;

	if (isSticky) {
		return [props, getStickyCellProps(column, instance)];
	}

	return [props];
};
