const convertEntityFormValues = (values, shouldUsePin) => ({
	name: values.companyName,
	abbreviation: values.abbreviation,
	dateOfRegistration: values.dateOfRegistration,
	registrationNumber: values.registrationNumber,
	taxNumber: values.taxNumber,
	vatNumber: values.vatNumber,
	placeOfIncorporation: values.placeOfIncorporation,
	pin: shouldUsePin ? values.pin : '',
});

export default convertEntityFormValues;
