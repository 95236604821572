import Big from 'big.js';

const prepareValue = value => {
	if (typeof value === 'string') {
		return value.trim();
	}
	return value;
};

const customBig = value => Big(prepareValue(value));

export default customBig;
