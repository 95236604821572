import getMyTeamActiveNoneOpenProjects from 'Dashboard/api/getMyTeamActiveNoneOpenProjects';
import DashboardTab from 'Dashboard/components/DashboardTab';
import useTeamProjectsTabStepModifier from 'Dashboard/components/TeamActivities/Tabs/TeamProjectsTab/hooks/useTeamProjectsTabStepModifier';

const TeamProjectsTab = () => (
	<DashboardTab
		helpText="Projects assigned to your team which are waiting on other teams."
		getterFunction={getMyTeamActiveNoneOpenProjects}
		stepModifier={useTeamProjectsTabStepModifier}
	/>
);

export default TeamProjectsTab;
