const locationTextFormatValidator = value => !value || /^-*\d*\.*\d*,*\s*-*\d*\.*\d*$/.test(value);

const locationTextDecimalPlacesValidator = value => {
	if (!value) return true;

	const [latitude, longitude] = value.split(',').map(item => item.trim());

	return (
		String(latitude).match(/^-{0,1}\d*(\.\d{0,15})?$/) && String(longitude).match(/^-{0,1}\d*(\.\d{0,15})?$/)
	);
};

const locationTextLatitudeRangeValidator = value => {
	if (!value) return true;

	const [latitude] = value.split(',').map(item => item.trim());

	return latitude >= -90 && latitude <= 90;
};

const locationTextLongitudeRangeValidator = value => {
	if (!value) return true;

	const [longitude] = value
		.split(',')
		.map(item => item.trim())
		.reverse();

	return longitude >= -180 && longitude <= 180;
};

export {
	locationTextFormatValidator,
	locationTextDecimalPlacesValidator,
	locationTextLatitudeRangeValidator,
	locationTextLongitudeRangeValidator,
};
