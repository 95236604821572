import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Big from 'Common/utils/customBig';

const useInvoiceFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				wht: yup
					.string()
					.nullable()
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'number',
						t('WHT must be a number'),
						value => value === undefined || value === null || /^-?\d*\.?\d*$/.test(value),
					)
					.test(
						'positive',
						t('WHT must be positive'),
						value => value === undefined || value === null || Number(value) >= 0,
					)
					.test(
						'decimal',
						t('WHT can be from 0 to 100'),
						value => value === undefined || value === null || Number(value) <= 100,
					)
					.test(
						'decimal-places',
						t('WHT must have no more than 2 decimal places'),
						value =>
							value === undefined ||
							value === null ||
							(/^-?\d*\.?\d*$/.test(value) &&
								Big(value)
									.toString()
									.match(/^\d*(\.\d{1,2})?$/)),
					),
			}),
		[t],
	);
};

export default useInvoiceFormValidationSchema;
