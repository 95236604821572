import React, { useMemo, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { omit, pick, cloneDeep } from 'lodash';
import { useFormik } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import PropTypes from 'prop-types';

import Loader from 'Common/components/Loader';
import Icon from 'Common/components/icons/Icon';
import Button from 'Common/components/buttons/Button';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import FormikInput from 'Common/components/form/FormikInput';
import Accordion from 'Common/components/accordion/Accordion';
import FormikSelect from 'Common/components/form/FormikSelect';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import AccordionDetails from 'Common/components/accordion/AccordionDetails';
import AccordionSummary from 'Common/components/accordion/AccordionSummary';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';

import createStep from 'PMWorkflow/api/createStep';
import editActivity from 'PMWorkflow/api/editActivity';
import deleteActivity from 'PMWorkflow/api/deleteActivity';
import getActivityById from 'PMWorkflow/api/getActivityById';
import getStepByActivity from 'PMWorkflow/api/getStepByActivity';
import reorderActivities from 'PMWorkflow/api/reorderActivities';
import loadBlockedByOptions from 'PMWorkflow/utils/loadBlockedByOptions';
import parseBlockedByOptions from 'PMWorkflow/utils/parseBlockedByOptions';
import TemplateStep from 'PMWorkflow/components/Step/TemplateStep/TemplateStep';
import moveActivity from 'PMWorkflow/components/Activity/TemplateActivity/utils/moveActivity';
import useActivityFieldsFormInitialValues from 'PMWorkflow/components/Activity/TemplateActivity/hooks/useActivityFieldsFormInitialValues';
import useActivityFieldsFormValidationSchema from 'PMWorkflow/components/Activity/TemplateActivity/hooks/useActivityFieldsFormValidationSchema';
import useReponsive from 'Common/hooks/useResponsive';
import { css } from 'styled-components';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const Tags = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const Tag = styled.div`
	display: flex;
	align-items: center;
	margin: 2px 5px 2px 0;
	padding: 3px 10px;
	color: ${colors.text.white};
	background: ${props => (props.isGrey ? colors.grey.light : colors.primary.dark)};
	border-radius: 25px;

	span {
		overflow: hidden;
		padding: 3px;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: 400;
		font-size: 15px;
		line-height: 24px;
	}
`;

const StyledFormField = styled(Grid)`
	margin-bottom: 16px !important;
`;

const StyledAccordion = styled(Accordion)`
	margin: 0 !important;
`;

const MoveButtons = styled(Grid)`
	padding: 0px !important;
	margin: 5px 0px !important;
	display: flex;
	flex-direction: row;
`;

const StyledGrid = styled(Grid)`
	display: flex;
	flex-direction: row;
	> * {
		margin-right: 4px;

		&:last-child {
			margin-right: 0px;
		}
	}
`;

const StyledTag = styled(Tag)`
	padding: 0px;
	span {
		font-size: 14px;
		padding: 2px 8px;
	}
`;

const Title = styled(Box)`
	color: ${colors.text.greyDark};
	font-size: 14px;
	font-weight: 300;
	display: flex;
	align-items: center;
`;

const Steps = styled(Title)`
	margin-right: 16px !important;
`;

const BoldText = styled(Grid)`
	color: ${colors.text.greyDark};
	font-size: 16px;
	font-weight: 700;
	align-items: center;
	display: grid;
`;

const Name = styled.span`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: inline-block;
`;

const StepTile = styled.div`
	padding: ${({ $isMobile }) => ($isMobile ? '6px' : '10px')};
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${colors.grey.lightest};
`;

const NoStepsText = styled.div`
	color: ${colors.text.black};
	font-size: 14px;
	font-weight: 400;
	margin-right: 8px;
`;

const EmptyDiv = styled.div`
	height: ${props => props.height};
`;

const ButtonsContainer = styled(Grid)`
	${({ $isMobile }) =>
		$isMobile &&
		css`
			display: flex;
			flex-direction: row;

			${Button} {
			}
		`}

	> * {
		margin-right: 16px;

		&:last-child {
			margin-right: 0px;
		}
	}
`;

// eslint-disable-next-line complexity
const TemplateActivity = ({
	stage,
	stages,
	activity,
	countries,
	iconStyle,
	stageIndex,
	stagesLength,
	onDataChange,
	projectTypes,
	contractTypes,
	allActivities,
	setStageState,
	stageActivities,
	setTemplateState,
	setPMWorkflowState,
	setActivitiesByStage,
	updateActivitiesByStage,
	automationFields,
}) => {
	const { t } = useTranslation();
	const { isMobile } = useReponsive();
	const [state, setState] = useState({
		expanded: false,
		isLoading: false,
		isInEditMode: false,
		isSavingChanges: false,
		showConfirmModal: false,
		isMovingAcitivity: false,
		isLoadingActivity: false,
		showStatisticsModal: false,
	});

	const activityIndex = useMemo(
		() => stageActivities.findIndex(act => act.id === activity.id) + 1,
		[stageActivities, activity],
	);

	const initialValues = useActivityFieldsFormInitialValues(activity);
	const validationSchema = useActivityFieldsFormValidationSchema();

	const abortController = useAbortController();

	const moveActivityWithSignal = useMemo(
		() => moveActivity.bind(undefined, abortController.signal),
		[abortController],
	);

	const { errors, touched, values, handleSubmit, handleBlur, setFieldValue, resetForm } = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: async values => {
			setState(prevState => ({ ...prevState, isSavingChanges: true }));
			try {
				const response = await editActivity(abortController.signal, {
					...omit(activity),
					...pick(activity.title),
					title: values.name,
					blockedByActivityTemplateIds: values?.blockedByActivityTemplateIds
						? values?.blockedByActivityTemplateIds?.map(item => item.value)
						: [],
				});

				await setTemplateState();

				await onDataChange();

				setState(prevState => ({
					...prevState,
					isSavingChanges: false,
					isInEditMode: false,
				}));

				setFieldValue(
					'blockedByActivityTemplateIds',
					parseBlockedByOptions(response?.data?.blockedByActivityTemplates),
				);

				const clonedStages = cloneDeep(stages);

				setPMWorkflowState(prevState => ({
					...prevState,
					stages: clonedStages.map(item => {
						if (item.stage === stage.stage) {
							item.activities.map(act => {
								if (act.id === activity.id) {
									const objIndex = item.activities.findIndex(items => items.id === act.id);
									item.activities[objIndex] = {
										...response.data,
										stepTemplates: item.activities[objIndex].stepTemplates,
									};
								}
							});
						}
						return item;
					}),
				}));

				resetForm({ values });
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					resetForm({ values: initialValues });
					handleCancel();
					setState(prevState => ({
						...prevState,
						isSavingChanges: false,
					}));
				}
			}
		},
	});

	const isExpanded = state.expanded === activity.id;

	const ids = [];
	stageActivities.forEach(item => ids.push(item.id));
	const currentIdIndex = ids.findIndex(id => id === activity.id);

	const handleEditButtonClick = () => {
		setState(prevState => ({ ...prevState, isInEditMode: true }));
	};

	const handleActivityClick = () => {
		if (!state.isInEditMode) {
			handleEditButtonClick();
		}
	};

	const handleCancel = () => {
		setState(prevState => ({ ...prevState, isInEditMode: false }));
		resetForm({ values: initialValues });
	};

	const handleExpanded = id => async (e, isExpanded) => {
		try {
			if (isExpanded) {
				setState(prevState => ({ ...prevState, isLoading: true }));
				await setActivityById();
				setState(prevState => ({
					...prevState,
					expanded: id,
					isLoading: false,
				}));
			} else {
				handleCloseActivity();
				setState(prevState => ({
					...prevState,
					expanded: false,
				}));
			}
		} catch (e) {
			showToastError(e);
		}
	};

	const properHandleChange = (name, e) => {
		setFieldValue(name, e.target.value);
	};

	const confirmDeleteActivity = async () => {
		const hasStepInStatistic = activity.stepTemplates.find(step => step.calculations.length > 0);

		if (hasStepInStatistic) {
			return setState(prevState => ({ ...prevState, showConfirmModal: false, showStatisticsModal: true }));
		} else {
			try {
				const response = await deleteActivity(abortController.signal, activity.id);
				if (response.status === 200) {
					const ids = [];

					const filterStageActivities = stageActivities.filter(item => item.id !== activity.id);

					filterStageActivities.map(item => ids.push(item.id));

					await reorderActivities(abortController.signal, ids);

					await setTemplateState();

					await onDataChange();

					const newActivities = stageActivities.filter(item => item.id !== activity.id);
					setPMWorkflowState(prevState => ({
						...prevState,
						stages: [
							...stages.slice(0, stageIndex),
							{ ...stages[stageIndex], activities: newActivities },
							...stages.slice(stageIndex + 1),
						],
					}));

					setState(prevState => ({ ...prevState, showConfirmModal: false }));
				}
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setState(prevState => ({ ...prevState, showConfirmModal: false, showStatisticsModal: true }));
				}
			}
		}
	};

	const handleAddStep = async forCampaign => {
		try {
			setState(prevState => ({ ...prevState, isLoading: true }));
			const values = {
				orderNumber: activity.stepTemplates.length + 1,
				title: 'New Step',
				activityTemplateId: activity.id,
				weight: 5,
				forCampaign: forCampaign,
			};

			const response = await createStep(abortController.signal, {
				...values,
			});

			if (response.status === 201) {
				const clonedStages = cloneDeep(stages);
				await setTemplateState();

				setPMWorkflowState(prevState => ({
					...prevState,
					stages: clonedStages.map(item => {
						if (item.stage === stage.stage) {
							item.activities.map(act => {
								if (act.id === activity.id) {
									act.stepTemplates.push(response.data);
								}
							});
						}
						return item;
					}),
				}));
				setState(prevState => ({ ...prevState, isLoading: false }));
			}
		} catch (error) {
			showToastError(error);
		}
	};

	const setActivityStepTemplates = async () => {
		try {
			const response = await getStepByActivity(abortController.signal, activity.id);

			setPMWorkflowState(prevState => ({
				...prevState,
				stages: prevState.stages.map(item => {
					if (item.stage === stage.stage) {
						item.activities.map(act => {
							if (act.id === activity.id) {
								act.stepTemplates = [...response.data];
							}
						});
					}
					return item;
				}),
			}));
		} catch (error) {
			showToastError(error);
		}
	};

	const updateActivityStepTemplates = async (updatedActivity, updatedStage) => {
		try {
			const clonedStages = cloneDeep(stages);
			const newStage = updatedStage ? updatedStage : stage;
			const response = await getStepByActivity(abortController.signal, updatedActivity.id);

			setPMWorkflowState(prevState => ({
				...prevState,
				stages: clonedStages.map(item => {
					if (item.stage === newStage.stage) {
						item.activities.map(act => {
							if (act.id === updatedActivity.id) {
								act.stepTemplates = [...response.data];
							}
						});
					}
					return item;
				}),
			}));
		} catch (error) {
			showToastError(error);
		}
	};

	const setActivityById = async () => {
		try {
			const clonedStages = cloneDeep(stages);
			const response = await getActivityById(abortController.signal, activity.id);

			setFieldValue(
				'blockedByActivityTemplateIds',
				parseBlockedByOptions(response?.data?.blockedByActivityTemplates),
			);

			setPMWorkflowState(prevState => ({
				...prevState,
				stages: clonedStages.map(item => {
					if (item.stage === stage.stage) {
						item.activities.map(act => {
							if (act.id === activity.id) {
								const objIndex = item.activities.findIndex(items => items.id === act.id);
								item.activities[objIndex] = {
									...response.data,
								};
							}
						});
					}
					return item;
				}),
			}));
		} catch (error) {
			showToastError(error);
		}
	};

	const handleCloseActivity = () => {
		setPMWorkflowState(prevState => ({
			...prevState,
			stages: prevState.stages.map(item => {
				if (item.stage === stage.stage) {
					item.activities.map(act => {
						if (act.id === activity.id) {
							act = activity;
						}
					});
				}

				return item;
			}),
		}));
	};

	return (
		<>
			<ConfirmModal
				noCancel
				isOpen={state.showStatisticsModal}
				onConfirm={() =>
					setState(prevState => ({
						...prevState,
						showStatisticsModal: false,
					}))
				}
				confirmText="Close"
				label="cannot-delete-activity"
				heading={t('Cannot delete Activity')}
				text={t(
					'This activity contains steps that are used in statistics. Please remove them before deleting this activity.',
				)}
			/>
			<ConfirmModal
				isOpen={state.showConfirmModal}
				onConfirm={() => confirmDeleteActivity()}
				onCancel={() =>
					setState(prevState => ({
						...prevState,
						showConfirmModal: false,
					}))
				}
				confirmText="Delete"
				label="delete-activity-confirm"
				heading={t('Delete Activity')}
				text={t(`Are you sure you want to delete Activity ${activity.title}?`)}
			/>
			<StyledAccordion
				expanded={isExpanded}
				onChange={handleExpanded(activity.id)}
				background={colors.grey.lighter}
				border={state.isInEditMode ? `1px solid ${colors.primary.dark}` : '1px solid rgba(255, 255, 255, .4)'}
				data-activity-wrapper={`${activity.title}-${stage.label}`}
			>
				<AccordionSummary>
					<Grid item container xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="space-between">
						<Grid item container xs={12} md={9} xl={10}>
							<Title
								width={{
									xs: '104px',
								}}
								xs={4}
								sm={3}
								md={2}
								lg={2}
								xl={2}
							>
								{state.isLoadingActivity || state.isLoading ? (
									<CircularProgress
										style={{
											color: colors.primary.main,
											marginRight: 13,
										}}
										size={15}
									/>
								) : state.expanded === activity.id ? (
									<Icon icon="keyboardArrowDown" style={iconStyle} />
								) : (
									<Icon icon="keyboardArrowRight" style={iconStyle} />
								)}
								{t('Activity ' + activityIndex)}
							</Title>
							<Grid item container xs={7} sm={9} md={9} lg={9} xl={10} alignItems="center">
								<BoldText item xs={11} sm="auto">
									<Name>{t(`${values.name}`)}</Name>
								</BoldText>
								{isExpanded && (
									<Grid item xs={1} sm="auto" onClick={event => event.stopPropagation()}>
										<MoreActionsButton noBackground label="PMWorkflow Template Activity More Actions">
											<MenuItem
												onClick={() =>
													setState(prevState => ({
														...prevState,
														showConfirmModal: true,
													}))
												}
												label="PMWorkflow Template Activity - Delete Menu Item"
											>
												{t('Delete')}
											</MenuItem>
										</MoreActionsButton>
									</Grid>
								)}
							</Grid>
						</Grid>
						{!isExpanded && (
							<Grid item xs={0} sm={0} md={3} xl={2} display={{ xs: 'none', md: 'flex' }} justifyContent="flex-end">
								<Steps>{t('Steps: ' + activity.stepTemplates.length)}</Steps>
								<StyledGrid item onClick={event => event.stopPropagation()}>
									<Button
										small
										secondary
										icon="keyboardArrowDown"
										onClick={() =>
											moveActivityWithSignal(
												'down',
												ids,
												currentIdIndex,
												activity,
												values,
												stageActivities,
												stages,
												stageIndex,
												setStageState,
												onDataChange,
												t,
												setTemplateState,
												setActivitiesByStage,
												updateActivitiesByStage,
												setState,
											)
										}
										disabled={
											state.isInEditMode ||
											(stageIndex === stagesLength - 1 && currentIdIndex === stageActivities.length - 1)
										}
										isLoading={state.isMovingAcitivity}
										label="PMWorkflow Template Activity - Move Activity Down Button"
									/>
									<Button
										small
										secondary
										icon="keyboardArrowUp"
										onClick={() =>
											moveActivityWithSignal(
												'up',
												ids,
												currentIdIndex,
												activity,
												values,
												stageActivities,
												stages,
												stageIndex,
												setStageState,
												onDataChange,
												t,
												setTemplateState,
												setActivitiesByStage,
												updateActivitiesByStage,
												setState,
											)
										}
										isLoading={state.isMovingAcitivity}
										disabled={state.isInEditMode || (currentIdIndex === 0 && stageIndex === 0)}
										label="PMWorkflow Template Activity - Move Activity Up Button"
									/>
								</StyledGrid>
							</Grid>
						)}
					</Grid>
				</AccordionSummary>
				<AccordionDetails background={colors.grey.lighter}>
					<Grid item container>
						<Grid
							item
							container
							xs={12}
							sm={12}
							md={12}
							lg={3}
							xl={3}
							direction="column"
							justifyContent="space-between"
						>
							<form onSubmit={handleSubmit}>
								<Grid
									item
									container
									xs={12}
									sm={12}
									md={12}
									lg={8}
									xl={6}
									spacing={1}
									direction="row"
									onClick={handleActivityClick}
								>
									<StyledFormField item xs={12} sm={12} md={4} lg={12} xl={12}>
										<FormikInput
											id={`name-${activity.id}`}
											name="name"
											label={t('Name')}
											value={values.name}
											error={errors.name}
											touched={touched.name}
											onChange={properHandleChange.bind(undefined, 'name')}
											onBlur={handleBlur}
											isRequired={state.isInEditMode}
											isInEditMode={state.isInEditMode}
											isTile
										/>
									</StyledFormField>
									<StyledFormField item xs={12} sm={12} md={4} lg={12} xl={12}>
										<FormikSelect
											id={`blockedByActivityTemplateIds-${activity.id}`}
											name="blockedByActivityTemplateIds"
											label={t('Blocked by')}
											value={values.blockedByActivityTemplateIds}
											error={errors.blockedByActivityTemplateIds}
											touched={touched.blockedByActivityTemplateIds}
											setFieldValue={setFieldValue}
											options={loadBlockedByOptions(allActivities, activity.id)}
											isTile
											isInEditMode={state.isInEditMode}
											tooltip={{
												tooltip: t('This Activity cannot be started before the Blocked by activities are complete.'),
											}}
											isMulti
										/>
									</StyledFormField>
									<StyledFormField item xs={12} sm={12} md={4} lg={12} xl={12}>
										<KeyValueVisualization
											title={t('Blocks')}
											value={
												<Tags>
													{activity.blockerActivityTemplates?.map((item, index) => (
														// eslint-disable-next-line react/no-array-index-key
														<StyledTag key={`template-tag-${index}`} isGrey>
															<span>{item.title}</span>
														</StyledTag>
													))}
												</Tags>
											}
											tooltip={
												<QuestionTooltip
													tooltip={t(
														'Auto generated.  To block another Activity, locate it and add this Activity to its "Blocked by" list.',
													)}
												/>
											}
										/>
									</StyledFormField>
									{state.isInEditMode ? (
										<StyledGrid item container direction="row">
											<Button
												small
												secondary
												icon="clear"
												onClick={handleCancel}
												label="PMWorkflow Template Activity - Cancel Button"
											/>
											<Button
												small
												type="submit"
												icon="check"
												isLoading={state.isSavingChanges}
												label="PMWorkflow Template Activity - Submit Button"
											/>
										</StyledGrid>
									) : (
										<Grid item>
											<EmptyDiv height="28px" />
										</Grid>
									)}
								</Grid>
							</form>
							<MoveButtons item container alignItems="flex-end">
								<StyledGrid item>
									<Button
										small
										secondary
										icon="keyboardArrowDown"
										onClick={() =>
											moveActivityWithSignal(
												'down',
												ids,
												currentIdIndex,
												activity,
												values,
												stageActivities,
												stages,
												stageIndex,
												setStageState,
												onDataChange,
												t,
												setTemplateState,
												setActivitiesByStage,
												updateActivitiesByStage,
												setState,
											)
										}
										disabled={
											state.isInEditMode ||
											(stageIndex === stagesLength - 1 && currentIdIndex === stageActivities.length - 1)
										}
										isLoading={state.isMovingAcitivity}
										label="PMWorkflow Template Activity - Move Activity Down Button"
									/>
									<Button
										small
										secondary
										icon="keyboardArrowUp"
										onClick={() =>
											moveActivityWithSignal(
												'up',
												ids,
												currentIdIndex,
												activity,
												values,
												stageActivities,
												stages,
												stageIndex,
												setStageState,
												onDataChange,
												t,
												setTemplateState,
												setActivitiesByStage,
												updateActivitiesByStage,
												setState,
											)
										}
										disabled={state.isInEditMode || (currentIdIndex === 0 && stageIndex === 0)}
										isLoading={state.isMovingAcitivity}
										label="PMWorkflow Template Activity - Move Activity Down Button"
									/>
								</StyledGrid>
							</MoveButtons>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
							{state.isLoading ? (
								<StepTile>
									<Loader />
								</StepTile>
							) : (
								<>
									{activity.stepTemplates.map((step, index) => (
										// eslint-disable-next-line react/no-array-index-key
										<div key={`template-step-${index}`}>
											<TemplateStep
												step={step}
												stage={stage}
												stages={stages}
												activity={activity}
												countries={countries}
												automationFields={automationFields}
												iconStyle={iconStyle}
												stepIndex={index + 1}
												stageIndex={stageIndex}
												projectTypes={projectTypes}
												onDataChange={onDataChange}
												setActivityState={setState}
												stagesLength={stagesLength}
												contractTypes={contractTypes}
												activityIndex={currentIdIndex}
												steps={activity.stepTemplates}
												stageActivities={stageActivities}
												setTemplateState={setTemplateState}
												setPMWorkflowState={setPMWorkflowState}
												setActivityStepTemplates={setActivityStepTemplates}
												updateActivityStepTemplates={updateActivityStepTemplates}
											/>
										</div>
									))}
									<StepTile $isMobile={isMobile}>
										{activity.stepTemplates.length === 0 && <NoStepsText>{t('No steps added.')}</NoStepsText>}
										<ButtonsContainer $isMobile={isMobile}>
											<Button
												onClick={() => handleAddStep(false)}
												text={isMobile ? t('Proj. Step') : t('Project Step')}
												icon="add"
												label={`PMWorkflow Template Activity - ${isMobile ? t('Proj. Step') : t('Project Step')} Button`}
											/>
											<Button
												onClick={() => handleAddStep(true)}
												text={isMobile ? t('Camp. Step') : t('Campaign Step')}
												icon="add"
												label={`PMWorkflow Template Activity - ${isMobile ? t('Camp. Step') : t('Campaign Step')} Button`}
											/>
										</ButtonsContainer>
									</StepTile>
								</>
							)}
						</Grid>
					</Grid>
				</AccordionDetails>
			</StyledAccordion>
		</>
	);
};

TemplateActivity.defaultProps = {
	automationFields: [],
};

TemplateActivity.propTypes = {
	stage: PropTypes.shape({
		stage: PropTypes.string,
		label: PropTypes.string,
	}).isRequired,
	stages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	activity: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		title: PropTypes.string,
		stepTemplates: PropTypes.arrayOf(PropTypes.shape({})),
		blockerActivityTemplates: PropTypes.arrayOf(PropTypes.shape({})),
	}).isRequired,
	countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	iconStyle: PropTypes.shape({}).isRequired,
	stageIndex: PropTypes.number.isRequired,
	projectTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	onDataChange: PropTypes.func.isRequired,
	setStageState: PropTypes.func.isRequired,
	contractTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	stagesLength: PropTypes.number.isRequired,
	allActivities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	stageActivities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	setTemplateState: PropTypes.func.isRequired,
	setPMWorkflowState: PropTypes.func.isRequired,
	setActivitiesByStage: PropTypes.func.isRequired,
	updateActivitiesByStage: PropTypes.func.isRequired,
	automationFields: PropTypes.arrayOf(PropTypes.string),
};

export default TemplateActivity;
