import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { roleGroups } from 'User/constants/roles';
import isInAuthorizedGroups from 'User/utils/isInAuthorizedGroups';
import logout from 'User/services/logout';

const HomepageRedirectRoute = () => {
	const isAuthenticated = useSelector(state => state.user.isAuthenticated);
	const userRoleGroups = useSelector(state => state.user.roleGroups);

	useEffect(() => {
		if (isAuthenticated && !userRoleGroups) {
			// noinspection JSIgnoredPromiseFromCall
			logout();
		}
	}, [isAuthenticated, userRoleGroups]);

	if (isAuthenticated) {
		if (isInAuthorizedGroups([roleGroups.ECOLIGO])) {
			return <Navigate to="/impact" />;
		}

		if (isInAuthorizedGroups([roleGroups.EPC_PARTNER])) {
			return <Navigate to="/epc/dashboard" />;
		}

		if (!userRoleGroups || userRoleGroups.length === 0) {
			return <Navigate to="/404" />;
		}
	}

	return <Navigate to="/login" />;
};

export default HomepageRedirectRoute;
