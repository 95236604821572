import { useMemo } from 'react';
import { pick } from 'lodash';

const fallbackValues = {
	stage: null,
	comment: '',
	onHold: false,
};

const useStatusFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['onHold']),
			status: data?.condition
				? {
						label: data?.condition?.displayName,
						value: data?.condition?.condition,
				  }
				: null,
			closedReason: data?.closedReason
				? {
						label: data?.closedReason?.displayName,
						value: data?.closedReason?.closedReason,
				  }
				: null,
			priority: data?.priority
				? {
						label: data?.priority?.displayName,
						value: data?.priority?.priority,
				  }
				: {
						label: 'Medium',
						value: 'MEDIUM',
				  },
		}),
		[data],
	);

export default useStatusFormInitialValues;
