import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { Checkbox as MaterialCheckbox, FormControlLabel } from '@mui/material';
import Label from 'Common/components/form/Label';
import ErrorMessage from 'Common/components/form/ErrorMessage';
import Icon from 'Common/components/icons/Icon';
import colors from 'Application/theme/colors';
import { useLabelColor } from './hooks/useLabelColor';
import KeyValueVisualization from '../KeyValueVisualization';
import { useTranslation } from 'react-i18next';
import TileDynamicSection from '../Tile/TileDynamicSection';
import { useId } from 'react';
import sizes from 'Application/theme/sizes';

const StyledCheckbox = styled(MaterialCheckbox)`
	&.MuiCheckbox-root {
		width: 22px;
		height: 22px;
		margin-left: 20px;
		border: 2px solid transparent;
		border-radius: ${props => (props.circle ? '20px' : '6px')};
		background-color: ${colors.primary.light};

		&:hover {
			//border-color: ${colors.primary.main};
			background-color: ${colors.primary.main};
			&.Mui-disabled {
				//border-color: ${colors.grey.main};
				background-color: ${colors.grey.light};
			}
		}

		&.Mui-checked {
			//border-color: ${colors.primary.light};
			background-color: ${colors.primary.light};
			&:hover {
				//border-color: ${colors.primary.main};
				background-color: ${colors.primary.main};
			}
		}

		&.Mui-disabled {
			//border-color: ${colors.grey.main};
			background-color: ${colors.grey.light};
		}

		.MuiIconButton-label {
			color: ${colors.primary.dark};

			svg {
				margin-left: 0;
				color: ${colors.primary.dark};
			}
		}

		&.Mui-focusVisible {
			//border-color: ${colors.primary.dark};
		}
	}
`;

const StyledLabel = styled(Label)`
	margin-bottom: 0;
	padding-left: 10px;
	cursor: pointer;
	font-weight: normal;
	${sizes.fontSize.main};
`;

const Wrapper = styled.div`
	display: flex;
	align-items: flex-start;

	.MuiCheckbox-root,
	.MuiFormControlLabel-root {
		margin-left: 0;
	}

	${({ $prependLabel }) =>
		$prependLabel &&
		css`
			${StyledLabel} {
				padding-left: 20px;
			}

			${StyledCheckbox} {
				&.MuiCheckbox-root {
					margin-left: 10px;
				}
			}
		`}

	${({ $isDisabled }) =>
		$isDisabled &&
		css`
			pointer-events: none;
			cursor: not-allowed;
			&:hover {
				.MuiCheckbox-root {
					background-color: ${colors.grey.light};
				}
			}
		`}
`;

const FormikCheckbox = ({
	id,
	label,
	circle,
	error,
	touched,
	className,
	tooltip,
	prependLabel,
	isRequired,
	isDisabled,
	isHighlighted,
	onMouseEnter,
	onMouseLeave,
	isTile,
	isInEditMode,
	checked,
	...props
}) => {
	const hasError = !!error && touched;
	const hasLabel = label !== null;
	const checkboxRef = useRef(null);
	const { t } = useTranslation();

	const listenForEnter = e => {
		if (e.key === 'Enter') {
			checkboxRef.current.click();
		}
	};

	const [hasHover, setHasHover] = useState(false);
	const checkboxId = useId();

	const labelColor = useLabelColor({
		isDisabled,
		hasError,
		isHighlighted,
		isTile: false,
		isInEditMode: false,
		hasHover,
	});

	const handleMouseEnter = (...args) => {
		setHasHover(true);
		typeof onMouseEnter === 'function' && onMouseEnter(...args);
	};

	const handleMouseLeave = (...args) => {
		setHasHover(false);
		typeof onMouseLeave === 'function' && onMouseLeave(...args);
	};

	const checkbox = (
		<FormControlLabel
			control={
				<StyledCheckbox
					id={id}
					color="default"
					icon={<></>}
					checkedIcon={circle ? <Icon icon="fiberManualRecord" /> : <Icon icon="checkRounded" />}
					disableRipple
					ref={checkboxRef}
					onKeyDown={listenForEnter}
					disabled={isDisabled}
					checked={checked}
					{...props}
				/>
			}
			label={
				hasLabel && (
					<StyledLabel
						label={label}
						color={labelColor}
						tooltip={tooltip}
						onClick={() => checkboxRef.current.click()}
						isRequired={isRequired}
					/>
				)
			}
			labelPlacement={prependLabel ? 'start' : 'end'}
		/>
	);

	return (
		<Wrapper
			className={className}
			$prependLabel={prependLabel}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{isTile ? (
				<TileDynamicSection
					isInEditMode={isInEditMode}
					editView={checkbox}
					readonlyView={
						<KeyValueVisualization
							id={id}
							title={label}
							value={checked ? t('Yes') : t('No')}
							hasHoverEffect={!isDisabled}
							isHighlighted={isHighlighted}
						/>
					}
					uniqueId={checkboxId.replace(/[^\w\d]/g, '')}
				/>
			) : (
				checkbox
			)}
			{hasError && <ErrorMessage message={error} />}
		</Wrapper>
	);
};

FormikCheckbox.defaultProps = {
	id: '',
	label: '',
	error: '',
	touched: false,
	className: '',
	tooltip: '',
	prependLabel: false,
	isRequired: false,
	isDisabled: false,
	isHighlighted: false,
	circle: false,
	isTile: false,
	isInEditMode: false,
	onMouseEnter: () => {},
	onMouseLeave: () => {},
	checked: false,
};

FormikCheckbox.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.string,
	error: PropTypes.string,
	touched: PropTypes.bool,
	className: PropTypes.string,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
	prependLabel: PropTypes.bool,
	isRequired: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isHighlighted: PropTypes.bool,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	circle: PropTypes.bool,
	isTile: PropTypes.bool,
	isInEditMode: PropTypes.bool,
	checked: PropTypes.bool,
};

export default FormikCheckbox;
