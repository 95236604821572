import { Grid } from '@mui/material';
import Big from 'big.js';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import NoFalseValueSwitch from 'Common/components/form/NoFalseValueSwitch';
import { SectionWrapper } from 'Common/components/modals/OverlaySections';
import formatNumber from 'Common/utils/formatNumber';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import useResponsive from 'Common/hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import CurrencySign from 'Common/components/CurrencySign';
import crudModes from 'Common/constants/crudModes';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

const CenteredGrid = styled(Grid)`
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: 1200px) {
		justify-content: start;
	}
`;

const SwitchWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 82px;
	margin: 0 24px 0 0;

	@media (min-width: 1280px) {
		justify-content: flex-end;
	}
`;

const VolumeSection = ({
	values,
	errors,
	touched,
	handleBlur,
	handleChange,
	setFieldValue,
	setFieldTouched,
	isPVProject,
	mode,
}) => {
	const { t } = useTranslation();
	const { isSmallscreen, isMediumScreen } = useResponsive();
	const epcVolumeSwitch = values.epcVolumeSwitch;
	const systemSize = Number(values.systemSize);

	const handleEpcVolumeFieldChange = e => {
		handleChange(e);
		const epcVolume = Number(e.target.value);

		if (!epcVolumeSwitch) {
			if (isNaN(systemSize) || systemSize === 0) {
				setFieldValue('epcVolumeExclVatKwp', '', false);
			} else {
				setFieldValue(
					'epcVolumeExclVatKwp',
					epcVolume ? Number(Number(Big(epcVolume).div(Big(systemSize)).toString()).toFixed(2)) : '',
					false,
				);
				if (errors['epcVolumeExclVatKwp'] && epcVolume) {
					setFieldTouched('epcVolumeExclVatKwp');
				}
			}
		}
	};

	const handleEpcVolumeSwitchChange = e => setFieldValue('epcVolumeSwitch', e);

	const handleEpcVolumeKwpFieldChange = e => {
		handleChange(e);
		const epcVolumeKwp = Number(e.target.value);

		if (epcVolumeSwitch) {
			if (isNaN(systemSize) || systemSize === 0) {
				setFieldValue('epcVolumeExclVat', '', false);
			} else {
				setFieldValue(
					'epcVolumeExclVat',
					epcVolumeKwp ? Number(Big(epcVolumeKwp).times(Big(systemSize)).toString()).toFixed(2) : '',
					false,
				);
				if (errors['epcVolumeExclVat'] && epcVolumeKwp) {
					setFieldTouched('epcVolumeExclVat');
				}
			}
		}
	};

	return mode !== crudModes.VIEW ? (
		<SectionWrapper $minWidth="200">
			<Grid container>
				<Grid item xs={12} lg={3}>
					<FormField
						$small={isSmallscreen || isMediumScreen}
						{...(!values.epcVolumeSwitch ? { 'data-first-field': true } : {})}
					>
						<FormikInput
							prefix={() => <CurrencySign currency={values.currency} />}
							label={t('EPC volume')}
							id="epcVolumeExclVat"
							name="epcVolumeExclVat"
							error={errors.epcVolumeExclVat}
							touched={!values.epcVolumeSwitch && touched.epcVolumeExclVat}
							value={values.epcVolumeExclVat}
							onChange={handleEpcVolumeFieldChange}
							onBlur={handleBlur}
							isRequired
							isDisabled={values.epcVolumeSwitch}
							isOverlay
						/>
					</FormField>
				</Grid>
				{isPVProject && (
					<>
						<CenteredGrid item xs={12} lg={2}>
							<SwitchWrapper>
								<NoFalseValueSwitch
									setValue={handleEpcVolumeSwitchChange}
									name="epcVolumeSwitch"
									value={Boolean(values.epcVolumeSwitch)}
								/>
							</SwitchWrapper>
						</CenteredGrid>
						<Grid item xs={12} lg={3}>
							<FormField {...(values.epcVolumeSwitch ? { 'data-first-field': true } : {})}>
								<FormikInput
									prefix={() => <CurrencySign currency={values.currency} />}
									label={t('EPC volume per kWp')}
									id="epcVolumeExclVatKwp"
									name="epcVolumeExclVatKwp"
									error={values.epcVolumeSwitch ? errors.epcVolumeExclVatKwp : null}
									touched={touched.epcVolumeExclVatKwp}
									value={values.epcVolumeExclVatKwp ? formatNumber(values.epcVolumeExclVatKwp, 0) : ''}
									onChange={handleEpcVolumeKwpFieldChange}
									onBlur={handleBlur}
									isRequired
									isDisabled={!values.epcVolumeSwitch}
									isOverlay
								/>
							</FormField>
						</Grid>
					</>
				)}
			</Grid>
			<FormField data-last-field>
				<FormikSelect
					isAsync
					name="currency"
					label={t('Currency')}
					id="currency"
					error={errors.currency}
					touched={touched.currency}
					value={values.currency}
					setFieldValue={setFieldValue}
					loadOptions={loadCurrenciesOptions}
					isRequired
					isOverlay
					onBlur={handleBlur}
				/>
			</FormField>
		</SectionWrapper>
	) : (
		<SectionWrapper $minWidth="200">
			<Grid container>
				<Grid item xs={12} lg={3}>
					<KeyValueVisualization
						id="epcVolumeExclVat"
						title={t('EPC volume')}
						value={values.epcVolumeExclVat}
						prefix={() => <CurrencySign currency={values.currency} />}
					/>
					{isPVProject && (
						<KeyValueVisualization
							id="epcVolumeExclVatKwp"
							title={t('EPC volume per kWp')}
							value={values.epcVolumeExclVatKwp}
							prefix={() => <CurrencySign currency={values.currency} />}
						/>
					)}
					<KeyValueVisualization id="currency" title={t('Currency')} value={values.currency?.label} />
				</Grid>
			</Grid>
		</SectionWrapper>
	);
};

VolumeSection.defaultProps = {
	values: {
		epcVolumeExclVat: '',
		epcVolumeExclVatKwp: '',
	},
	errors: {
		epcVolumeExclVat: '',
		epcVolumeExclVatKwp: '',
	},
	touched: {
		epcVolumeExclVat: false,
		epcVolumeExclVatKwp: false,
	},
	handleBlur: () => {},
	handleChange: () => {},
	setFieldValue: () => {},
	setFieldTouched: () => {},
	isPVProject: false,
};

VolumeSection.propTypes = {
	values: PropTypes.shape({
		epcVolumeExclVat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		epcVolumeExclVatKwp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		currency: PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		}),
		epcVolumeSwitch: PropTypes.bool,
		systemSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	errors: PropTypes.shape({
		epcVolumeExclVat: PropTypes.string,
		epcVolumeExclVatKwp: PropTypes.string,
		currency: PropTypes.string,
	}),
	touched: PropTypes.shape({
		epcVolumeExclVat: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		epcVolumeExclVatKwp: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
		currency: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
	}),
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	setFieldValue: PropTypes.func,
	setFieldTouched: PropTypes.func,
	isPVProject: PropTypes.bool,
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
};

export default VolumeSection;
