import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import useResponsive from 'Common/hooks/useResponsive';

const StyledH5 = styled.h5`
	margin-top: 0;
	margin-bottom: 20px;
	color: ${colors.text.primary};
	font-weight: 600;
	font-size: ${({ $isMobile }) => ($isMobile ? '14px' : '16px')};
	line-height: ${({ $isMobile }) => ($isMobile ? '20px' : '24px')};
	text-align: center;
	text-transform: none;
`;

const H5 = props => {
	const { isMobile } = useResponsive();

	return <StyledH5 {...props} $isMobile={isMobile} />;
};

export default H5;
