import { useCallback, useMemo } from 'react';
import styled from 'styled-components/macro';
import { actions, ensurePluginOrder, flexRender, makePropGetter } from 'react-table';
import TableField from 'Common/components/form/Table/TableField';

const pluginName = 'useEditableRow';

// Actions
actions.turnOnRowEditMode = 'turnOnRowEditMode';
actions.turnOffRowEditMode = 'turnOffRowEditMode';

export const useEditableRow = hooks => {
	hooks.getTurnOnRowEditModeProps = [defaultGetTurnOnRowEditModeProps];
	hooks.getTurnOffRowEditModeProps = [defaultGetTurnOffRowEditModeProps];
	hooks.stateReducers.push(reducer);
	hooks.useInstance.push(useInstance);
	hooks.prepareRow.push(prepareRow);
};

useEditableRow.pluginName = pluginName;

const defaultGetTurnOnRowEditModeProps = (props, { row }) => [
	props,
	{
		onClick: () => {
			row.turnOnRowEditMode();
		},
		style: {
			cursor: 'pointer',
		},
	},
];

const defaultGetTurnOffRowEditModeProps = (props, { row }) => [
	props,
	{
		onClick: () => {
			row.turnOffRowEditMode();
		},
		style: {
			cursor: 'pointer',
		},
	},
];

function reducer(state, action, previousState, instance) {
	if (action.type === actions.init) {
		return {
			rowIdsInEditMode: [],
			...state,
		};
	}

	if (action.type === actions.turnOnRowEditMode) {
		const { id } = action;

		const { rowsById } = instance;
		const isInEditMode = state.rowIdsInEditMode.includes(id);

		if (isInEditMode) {
			return state;
		}

		const newRowIdsInEditMode = [...state.rowIdsInEditMode];

		const row = rowsById[id];

		if (!row.isGrouped) {
			newRowIdsInEditMode.push(id);
		}

		return {
			...state,
			rowIdsInEditMode: newRowIdsInEditMode,
		};
	}

	if (action.type === actions.turnOffRowEditMode) {
		const { id } = action;

		const { rowsById } = instance;
		const isInEditMode = state.rowIdsInEditMode.includes(id);

		if (!isInEditMode) {
			return state;
		}

		const newRowIdsInEditMode = [...state.rowIdsInEditMode];

		const row = rowsById[id];

		if (!row.isGrouped) {
			newRowIdsInEditMode.splice(newRowIdsInEditMode.indexOf(id), 1);
		}

		return {
			...state,
			rowIdsInEditMode: newRowIdsInEditMode,
		};
	}
}

const useInstance = instance => {
	const {
		rows,
		plugins,
		state: { rowIdsInEditMode },
		dispatch,
	} = instance;

	ensurePluginOrder(plugins, ['useSortBy'], 'useEditableRow');

	const flatRowsInEditMode = useMemo(() => {
		const flatRows = [];

		rows.forEach(row => {
			const isInEditMode = rowIdsInEditMode.includes(row.id) || Boolean(row.original.__forCreation);

			row.isInEditMode = isInEditMode;

			if (isInEditMode) {
				flatRows.push(row);
			}
		});

		return flatRows;
	}, [rows, rowIdsInEditMode]);

	const turnOnRowEditMode = useCallback(
		(id, value) => dispatch({ type: actions.turnOnRowEditMode, id, value }),
		[dispatch],
	);

	const turnOffRowEditMode = useCallback(
		(id, value) => dispatch({ type: actions.turnOffRowEditMode, id, value }),
		[dispatch],
	);

	Object.assign(instance, {
		flatRowsInEditMode,
		turnOnRowEditMode,
		turnOffRowEditMode,
	});
};

const OriginalCellTableField = styled(TableField)`
	padding-left: 11px;
	padding-right: 10px;
	border: none;
`;

const prepareRow = (row, { instance }) => {
	const {
		turnOnRowEditMode,
		turnOffRowEditMode,
		getEditableRowInitialValues,
		editableRowFormikOptions,
		getHooks,
		removeRowForCreation,
	} = instance;

	row.turnOnRowEditMode = () => turnOnRowEditMode(row.id);
	row.turnOffRowEditMode = () => {
		if (Boolean(row.original.__forCreation) === true) {
			removeRowForCreation(row.original.id);
		} else {
			turnOffRowEditMode(row.id);
		}
	};
	row.getEditableRowInitialValues = () => getEditableRowInitialValues(row);
	row.editableRowFormikOptions = editableRowFormikOptions;

	row.getTurnOnRowEditModeProps = makePropGetter(getHooks().getTurnOnRowEditModeProps, {
		instance: instance,
		row,
	});

	row.getTurnOffRowEditModeProps = makePropGetter(getHooks().getTurnOffRowEditModeProps, {
		instance: instance,
		row,
	});

	if (row.isInEditMode) {
		row.allCells.forEach(cell => {
			if (!cell.originalRender) {
				cell.originalRender = cell.render;
			}

			cell.render = (type, userProps = {}) => {
				if (type === 'Cell') {
					const column = cell.column;
					const Component = column.EditableField;

					if (typeof Component !== 'undefined') {
						return flexRender(Component, {
							...instance,
							column,
							...userProps,
							row,
							cell,
						});
					}
				}

				return <OriginalCellTableField>{cell.originalRender(type, userProps)}</OriginalCellTableField>;
			};
		});
	}
};
