import api from 'Application/api/api';

const getSaaSBankAccounts = (signal, invoiceId, searchQuery) => {
	const params = {
		query: searchQuery,
	};

	return api.get(`/invoices/get-saas-bank-accounts/${invoiceId}`, {
		params,
		signal,
		timeout: 100000,
	});
};

export default getSaaSBankAccounts;
