import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'Common/components/icons/Icon';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import { PropTypes } from 'prop-types';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import { statusesLabels, statusColorMap, statusHelpTexts } from 'Campaigns/constants/trancheStatuses';

import { formatRepaymentScheme } from 'Campaigns/utils/campaignHelpers';

import formatNumber from 'Common/utils/formatNumber';
import StatusPill from 'Common/components/StatusPill';
import formatCurrency from 'Common/utils/formatCurrency';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import Grid from '@mui/material/Grid';
import { formatInTimeZone } from 'date-fns-tz';
import defaultTimezone from 'Common/constants/defaultTimezone';
import PayoutsTrancheModal from './PayoutsTrancheModal/PayoutsTrancheModal';

const Tags = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const Tag = styled.div`
	display: flex;
	align-items: center;
	margin: 2px 5px 2px 0;
	padding: 3px 10px;
	color: ${colors.text.white};
	background: ${props => (props.isGrey ? colors.grey.light : colors.primary.dark)};
	border-radius: 25px;

	span {
		overflow: hidden;
		padding: 3px;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: 400;
		font-size: 15px;
		line-height: 24px;
	}
`;

const SectionTitle = styled.div`
	font-size: 16px;
	font-weight: 600;
	display: flex;
	align-items: center;
	padding: 16px 0;
	text-transform: none;
	max-height: 50px;
	width: max-content;
`;

const Text = styled.span`
	font-size: 14px;
	line-height: 12px;
	font-weight: 600;
	text-transform: none;
	width: max-content;
`;

const StyledStatusPill = styled(StatusPill)`
	margin-left: 10px;
`;

const StyledIcon = styled(Icon)`
	margin-left: 10px;
`;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	border-top: 1px solid ${colors.grey.light};
`;

const Section = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const ActionsWrapper = styled.div`
	display: flex;
	width: 4%;
	justify-content: flex-end;
	margin-top: 10px;
`;

const TextWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const ButtonActions = styled.div`
	position: absolute;
	right: -40px;
	top: 0;
`;

const SpacingElement = styled.div`
	height: 50px;
`;

const TrancheView = ({ tranch, setTranchIdForEditing, setTrancheForDeletion, copyTranche, emissionSpv }) => {
	const { t } = useTranslation();
	const [isPayoutsModalOpened, setIsPayoutsModalOpened] = useState(false);

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.PM_FUNDRAISING,
		roles.ASSET_MARKETING,
	]);

	const lastInvestmentDate = tranch?.last_investment_date
		? new Date(tranch?.last_investment_date).toLocaleDateString('en-GB')
		: '';

	const investableFromHour = tranch?.investable_time_from
		? formatInTimeZone(new Date(tranch?.investable_time_from), defaultTimezone, 'HH:mm')
		: '';

	const investableFromDate = tranch?.investable_time_from
		? formatInTimeZone(new Date(tranch.investable_time_from), defaultTimezone, 'd/M/yyyy')
		: '';

	const investableUntilHour = tranch?.investable_time_until
		? formatInTimeZone(new Date(tranch?.investable_time_until), defaultTimezone, 'HH:mm')
		: '';

	const investableUntilDate = tranch?.investable_time_until
		? formatInTimeZone(new Date(tranch.investable_time_until), defaultTimezone, 'd/M/yyyy')
		: '';

	const emissionId = tranch?.id;
	const platform = tranch?.tranche_details?.platform?.name;
	const emmisionSPV = emissionSpv?.name;

	return (
		<Wrapper id={tranch?.reference}>
			<Grid container>
				<Grid item xs={12} md={6}>
					<Grid container>
						<Grid item xs={12} md={6}>
							<SectionTitle id="name">
								<Text>{tranch?.reference} </Text>
								<TextWrapper id="status">
									<HoverTooltip placement="top" title={statusHelpTexts[tranch.status]} arrow>
										<StyledStatusPill color={statusColorMap[tranch?.status]}>
											{statusesLabels[tranch.status]}
										</StyledStatusPill>
									</HoverTooltip>
									{!tranch?.synched && (
										<HoverTooltip
											placement="top"
											title={t(
												'Tranche name, max volume, start date differs to portagon, or tranche end date is after the portagon end date.  Check the tranche values.',
											)}
											arrow
										>
											<StyledIcon icon="reportProblem" color={colors.warning.main} />
										</HoverTooltip>
									)}
								</TextWrapper>
							</SectionTitle>

							<KeyValueVisualization
								id="investments"
								title={t('Investments')}
								value={formatCurrency(tranch?.investments_sum)}
								keyStyle={{ marginBottom: '4px' }}
							/>
							<KeyValueVisualization
								id="last-inestments"
								title={t('Last investment')}
								value={lastInvestmentDate}
								keyStyle={{ marginBottom: '4px' }}
							/>
							<KeyValueVisualization
								id="tenor"
								title={t('Tenor')}
								value={tranch?.tenor}
								keyStyle={{ marginBottom: '4px' }}
							/>

							<Grid container spacing={2}>
								<Grid item xs={12} lg={6}>
									<KeyValueVisualization
										id="from"
										title={t('From')}
										value={`${investableFromHour} on ${investableFromDate}`}
										keyStyle={{ marginBottom: '4px' }}
									/>
								</Grid>

								<Grid item xs={12} lg={6}>
									<KeyValueVisualization
										id="from"
										title={t('To')}
										value={`${investableUntilHour} on ${investableUntilDate}`}
										keyStyle={{ marginBottom: '4px' }}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={6}>
							<SpacingElement />
							{emissionId && (
								<KeyValueVisualization
									id="emmissionId"
									title={t('Emission ID')}
									value={emissionId}
									keyStyle={{ marginBottom: '4px' }}
								/>
							)}
							<KeyValueVisualization
								id="platform"
								title={t('Platform')}
								value={platform}
								keyStyle={{ marginBottom: '4px' }}
							/>
							<KeyValueVisualization
								id="emmisionSPV"
								title={t('Emission SPV')}
								value={emmisionSPV}
								keyStyle={{ marginBottom: '4px' }}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} md={2}>
					<SectionTitle>{t('Interest')}</SectionTitle>
					<Section>
						<KeyValueVisualization
							id="interestRate"
							title={t('Interest rate')}
							value={
								tranch?.tranche_details?.interestRate && `${formatNumber(tranch?.tranche_details?.interestRate, 2)} %`
							}
							keyStyle={{ minWidth: '150px' }}
						/>
						<KeyValueVisualization
							id="bonusAmount"
							title={t('Bonus amount')}
							value={
								tranch?.tranche_details?.bonusAmount && `${formatNumber(tranch?.tranche_details?.bonusAmount, 2)} %`
							}
							keyStyle={{ minWidth: '150px' }}
						/>
					</Section>
					<SectionTitle>{t('Tags')}</SectionTitle>
					<KeyValueVisualization
						id="tagName"
						title={''}
						value={
							<Tags>
								{tranch.tranche_details?.tagRefs?.map(tag => (
									<Tag key={tag.tagId}>
										<span>{tag.tagName}</span>
									</Tag>
								))}
							</Tags>
						}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<SectionTitle>{t('Individual investment amount')}</SectionTitle>
					<Section>
						<KeyValueVisualization
							id="minInvestmentAmount"
							title={t('Min')}
							value={formatCurrency(tranch?.tranche_details?.minInvestmentAmount)}
							keyStyle={{ minWidth: '100px' }}
						/>
						<KeyValueVisualization
							id="maxInvestmentAmount"
							title={
								<>
									{t('Max')}
									<QuestionTooltip
										tooltip={t('The example payouts plan from portagon uses the Max investment amount.')}
									/>
								</>
							}
							value={formatCurrency(tranch?.tranche_details?.maxInvestmentAmount)}
							keyStyle={{
								minWidth: '100px',
								alignItems: 'center',
							}}
						/>
						<KeyValueVisualization
							id="divisibleBy"
							title={t('Divisible by')}
							value={formatCurrency(tranch?.tranche_details?.divisibleBy)}
							keyStyle={{ minWidth: '100px' }}
						/>
					</Section>

					<SectionTitle>{t('Repayment scheme')}</SectionTitle>
					<div>
						{formatRepaymentScheme(
							tranch?.tranche_details?.annuity,
							tranch?.tranche_details?.bullet,
							tranch?.tranche_details?.constant,
						)}
					</div>
				</Grid>
				<ButtonActions>
					<ActionsWrapper
						// Keep the actions there, but hidden so that the content is not moved to the right
						style={isAuthorizedToEdit ? undefined : { visibility: 'hidden' }}
					>
						<MoreActionsButton noBackground label="Tranche Details More Actions">
							<MenuItem
								type="button"
								data-action="edit"
								onClick={() => setTranchIdForEditing(tranch?.auto_id)}
								label="Campaigns Details Tranches List - Edit Menu Item"
							>
								Edit
							</MenuItem>
							<MenuItem
								type="button"
								data-action="copy"
								onClick={() => copyTranche(tranch)}
								label="Campaigns Details Tranches List - Copy Menu Item"
							>
								Copy
							</MenuItem>
							{tranch?.payouts && (
								<MenuItem
									type="button"
									data-action="payouts"
									onClick={() => setIsPayoutsModalOpened(true)}
									label="Campaigns Details Tranches List - Show Payouts"
								>
									Payouts
								</MenuItem>
							)}
							<MenuItem
								type="button"
								data-action="delete"
								onClick={() => setTrancheForDeletion(tranch)}
								label="Campaigns Details Tranches List - Delete Menu Item"
							>
								Delete
							</MenuItem>
						</MoreActionsButton>
					</ActionsWrapper>
				</ButtonActions>
			</Grid>
			<PayoutsTrancheModal
				data={tranch}
				isOpen={isPayoutsModalOpened}
				onConfirm={() => setIsPayoutsModalOpened(false)}
			/>
		</Wrapper>
	);
};

TrancheView.propTypes = {
	emissionSpv: PropTypes.shape({
		name: PropTypes.string,
	}).isRequired,
	tranch: PropTypes.shape({
		last_investment_date: PropTypes.string,
		investable_time_from: PropTypes.string.isRequired,
		investable_time_until: PropTypes.string,
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		tranche_details: PropTypes.shape({
			platform: PropTypes.shape({
				name: PropTypes.string,
			}),
			interestRate: PropTypes.number,
			bonusAmount: PropTypes.number,
			tagRefs: PropTypes.arrayOf(PropTypes.shape({})),
			minInvestmentAmount: PropTypes.number,
			maxInvestmentAmount: PropTypes.number,
			divisibleBy: PropTypes.number,
			annuity: PropTypes.number,
			bullet: PropTypes.number,
			constant: PropTypes.bool,
		}).isRequired,
		reference: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
		synched: PropTypes.bool.isRequired,
		investments_sum: PropTypes.string.isRequired,
		tenor: PropTypes.number.isRequired,
		auto_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		payouts: PropTypes.shape({}),
	}).isRequired,
	setTranchIdForEditing: PropTypes.func.isRequired,
	setTrancheForDeletion: PropTypes.func.isRequired,
	copyTranche: PropTypes.func.isRequired,
};

export default TrancheView;
