import getSaaSBankAccounts from 'Invoice/api/getSaaSBankAccounts';

const loadSaaSBankAccountOptions = async (invoiceId, signal, inputValue) => {
	const { data: bankAccounts } = await getSaaSBankAccounts(signal, invoiceId, inputValue);
	return bankAccounts.map(bankAccount => ({
		label: bankAccount.name,
		subtitle: bankAccount.accountNumber,
		value: bankAccount.id,
		currency: bankAccount.currency,
	}));
};

export default loadSaaSBankAccountOptions;
