import getAllStepTemplates from 'PMWorkflow/api/getAllStepTemplates';

const loadImpactStepNames = async (signal, searchQuery) => {
	const steps = await getAllStepTemplates(signal, searchQuery);

	return steps.data
		.map(step => ({
			value: step.id,
			title: step.title,
			status: 'COMPLETED',
		}))
		.sort((a, b) => a.title.localeCompare(b.title));
};

export default loadImpactStepNames;
