import { useMemo } from 'react';
import { isEmpty, pick } from 'lodash';
import { baseLanguages } from '../../constants/baseLanguages';

const fallbackValues = {
	name: '',
	imageId: '',
	localizations: [
		{
			language: { value: 'en', label: 'en', fullName: 'English' },
			name: '',
		},
		{
			language: { value: 'de', label: 'de', fullName: 'German' },
			name: '',
		},

		{
			language: { value: 'es', label: 'es', fullName: 'Spanish' },
			name: '',
		},
		{
			language: { value: 'vi', label: 'vi', fullName: 'Vietnamese' },
			name: '',
		},
	],
};

const useTagFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['name', 'imageId']),
			localizations: !isEmpty(data?.localizations)
				? [
						{
							language: {
								value: 'en',
								label: 'en',
								fullName: 'English',
							},
							name: data?.localizations?.en?.name,
						},
						{
							language: {
								value: 'de',
								label: 'de',
								fullName: 'German',
							},
							name: data?.localizations?.de?.name,
						},
						{
							language: {
								value: 'es',
								label: 'es',
								fullName: 'Spanish',
							},
							name: data?.localizations?.es?.name,
						},
						{
							language: {
								value: 'vi',
								label: 'vi',
								fullName: 'Vietnamese',
							},
							name: data?.localizations?.vi?.name,
						},
						...Object.keys(data?.localizations)
							.filter(e => !baseLanguages.includes(e))
							.map(e => ({
								language: {
									value: e,
									label: e,
									fullName: data?.localizations[e]?.language,
								},
								name: data?.localizations[e]?.name,
							}))
							.sort((a, b) => a.language.value.localeCompare(b.language.value)),
				  ]
				: fallbackValues.localizations,
		}),
		[data],
	);

export default useTagFormInitialValues;
