import crudModes from 'Common/constants/crudModes';
import useAbortController from 'Common/hooks/useAbortController';
import showToastError from 'Common/utils/showToastError';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import { hasClientOffers } from 'Projects/utils/salesHelpers';
import { useEffect, useMemo, useState } from 'react';

const fallbackValues = {
	offerDate: new Date(),
	systemSize: '',
	currency: { value: 'EUR', label: 'EUR Euro' },
	mountingTypes: [],
	epcVolume: '',
	epcVatApplicable: false,
	epcOffer: '',
	epcOfferDisplayName: '',
	epcOfferDocuments: [],
	yield: '',
	p90: false,
	solarPvDegradation: '',
	billingPeriod: 1,
	annualOmPrice: '',
	annualOmEscalationRate: '',
	extraCapexExpected: '',
	extraOpexExpected: '',
	contractType: '',
	contractTerm: '',
	escalationRateInPercent: '',
	ppaTariffType: '',
	solarUtilizationRate: '',
	minimumPurchaseAmount: '',
	minimumWarrantedFunctionality: '',
	discountOnGridTariff: '',
	clientDownpayment: '',
	saasCurrency: { value: 'EUR', label: 'EUR Euro' },
	leaseFee: '',
	ppaTariff: '',
	percentageOnGridTariff: '',
	floorTariff: '',
	ceilingTariff: '',
	expectedClientSavings: '',
	solarEnergyReplacement: '',
	irr: '',
	minimumDscr: '',
	averageDscr: '',
	worstCaseIrr: '',
	primaryOffer: false,
	compareOffer: false,
};

const useClientOfferOverlayValues = (mode, data, epcOffer, project, primaryOfferData) => {
	const [currency, setCurrency] = useState({
		default: fallbackValues.currency,
		saas: fallbackValues.saasCurrency,
	});

	const [document, setDocument] = useState(data?.documents?.slice(-1)[0]);

	const abortController = useAbortController();

	useEffect(() => {
		setDocument(data?.documents?.slice(-1)[0]);
	}, [data?.documents]);

	// Set the default currency to EUR
	useEffect(() => {
		(async () => {
			try {
				if (epcOffer?.currency || epcOffer?.saasCurrency || data?.currency || data?.saasCurrency) {
					const currencies = await loadCurrenciesOptions(abortController.signal);
					const defaultCurrency = currencies.find(
						e => e.value === (data?.currency || epcOffer?.currency || 'EUR'),
					);
					const saasCurrency = currencies.find(
						e => e.value === (data?.saasCurrency || epcOffer?.saasCurrency || 'EUR'),
					);
					setCurrency({ default: defaultCurrency, saas: saasCurrency });
				} else {
					const currencies = await loadCurrenciesOptions(abortController.signal, 'eur', false);
					const euro = currencies.find(e => e.value === 'EUR');
					setCurrency({
						default: euro,
						saas: euro,
					});
				}
			} catch (err) {
				showToastError(err, "Can't fetch currencies");
			}
		})();
	}, [abortController.signal, epcOffer?.currency, epcOffer?.saasCurrency, data?.currency, data?.saasCurrency]);

	const defaultValues = useMemo(
		// eslint-disable-next-line complexity
		() =>
			mode === crudModes.CREATE
				? {
						offerDate: fallbackValues.offerDate,
						systemSize: epcOffer.systemSize || fallbackValues.systemSize,
						mountingTypes:
							project?.mountingTypes?.map(({ mountingType: value, displayName: label }) => ({ value, label })) ||
							fallbackValues.mountingTypes,
						epcVolume: epcOffer?.epcVolumeExclVat?.value || fallbackValues.epcVolume,
						currency: currency.default,
						epcVatApplicable: fallbackValues.epcVatApplicable,
						epcOffer: '',
						epcOfferDisplayName: '',
						epcOfferDocuments: [],
						yield: epcOffer.yield || fallbackValues.yield,
						p90: fallbackValues.p90,
						solarPvDegradation: project.pvDegradationRate
							? project.pvDegradationRate * 100
							: fallbackValues.solarPvDegradation,
						annualOmPrice: epcOffer.annualOmPrice?.value || fallbackValues.annualOmPrice,
						annualOmEscalationRate: epcOffer.annualOmEscalationRate || fallbackValues.annualOmEscalationRate,
						extraCapexExpected: fallbackValues.extraCapexExpected,
						extraOpexExpected: fallbackValues.extraOpexExpected,

						contractType: fallbackValues.contractType,
						contractTerm: fallbackValues.contractTerm,
						escalationRateInPercent: fallbackValues.escalationRateInPercent,
						ppaTariffType: fallbackValues.ppaTariffType,
						saasCurrency: currency.saas,
						solarUtilizationRate: project.solarUtilisationRate || fallbackValues.solarUtilizationRate,
						minimumPurchaseAmount: fallbackValues.minimumPurchaseAmount,
						minimumWarrantedFunctionality: fallbackValues.minimumWarrantedFunctionality,
						discountOnGridTariff: project.discountOnGridTariff || fallbackValues.discountOnGridTariff,
						clientDownpayment: fallbackValues.clientDownpayment,
						billingPeriod: fallbackValues.billingPeriod,
						leaseFee: fallbackValues.leaseFee,

						ppaTariff: fallbackValues.ppaTariff,
						percentageOnGridTariff: fallbackValues.percentageOnGridTariff,
						floorTariff: fallbackValues.floorTariff,
						ceilingTariff: fallbackValues.ceilingTariff,
						expectedClientSavings: fallbackValues.expectedClientSavings,
						solarEnergyReplacement: fallbackValues.solarEnergyReplacement,

						irr: fallbackValues.irr,
						minimumDscr: fallbackValues.minimumDscr,
						averageDscr: fallbackValues.averageDscr,
						worstCaseIrr: fallbackValues.worstCaseIrr,

						primaryOffer: hasClientOffers(project) ? fallbackValues.primaryOffer : true,
						compareOffer: false,
						primaryOfferData: primaryOfferData,
				  }
				: {
						offerDate: data.offerDate || fallbackValues.offerDate,
						systemSize: data.systemSize || fallbackValues.systemSize,
						mountingTypes: data.mountingTypes || fallbackValues.mountingTypes,
						epcVolume: data.epcVolume || fallbackValues.epcVolume,
						currency: currency.default,
						epcVatApplicable: data.epcVatApplicable || fallbackValues.epcVatApplicable,
						epcOffer: '',
						epcOfferDisplayName: document?.displayName || fallbackValues.epcOffer,
						epcOfferDocuments: data.documents || fallbackValues.epcOfferDocuments,
						yield: data.yield || fallbackValues.yield,
						p90: data.p90 || fallbackValues.p90,
						solarPvDegradation: data.solarPvDegradation || fallbackValues.solarPvDegradation,
						annualOmPrice: data.annualOmPrice || fallbackValues.annualOmPrice,
						annualOmEscalationRate: data.annualOmEscalationRate || fallbackValues.annualOmEscalationRate,
						extraCapexExpected: data.extraCapexExpected || fallbackValues.extraCapexExpected,
						extraOpexExpected: data.extraOpexExpected || fallbackValues.extraOpexExpected,

						contractType: data.contractType || fallbackValues.contractType,
						contractTerm: data.contractTerm || fallbackValues.contractTerm,
						escalationRateInPercent: data.escalationRateInPercent || fallbackValues.escalationRateInPercent,
						ppaTariffType: data.ppaTariffType || fallbackValues.ppaTariffType,
						saasCurrency: currency.saas,
						solarUtilizationRate: data.solarUtilizationRate || fallbackValues.solarUtilizationRate,
						minimumPurchaseAmount: data.minimumPurchaseAmount || fallbackValues.minimumPurchaseAmount,
						minimumWarrantedFunctionality:
							data.minimumWarrantedFunctionality || fallbackValues.minimumWarrantedFunctionality,
						discountOnGridTariff: data.discountOnGridTariff || fallbackValues.discountOnGridTariff,
						clientDownpayment: data.clientDownpayment || fallbackValues.clientDownpayment,
						billingPeriod: data.billingPeriod || fallbackValues.billingPeriod,
						leaseFee: data.leaseFee || fallbackValues.leaseFee,

						ppaTariff: data.ppaTariff || fallbackValues.ppaTariff,
						percentageOnGridTariff: data.percentageOnGridTariff || fallbackValues.percentageOnGridTariff,
						floorTariff: data.floorTariff || fallbackValues.floorTariff,
						ceilingTariff: data.ceilingTariff || fallbackValues.ceilingTariff,
						expectedClientSavings: data.expectedClientSavings || fallbackValues.expectedClientSavings,
						solarEnergyReplacement: data.solarEnergyReplacement || fallbackValues.solarEnergyReplacement,

						irr: data.irr || fallbackValues.irr,
						minimumDscr: data.minimumDscr || fallbackValues.minimumDscr,
						averageDscr: data.averageDscr || fallbackValues.averageDscr,
						worstCaseIrr: data.worstCaseIrr || fallbackValues.worstCaseIrr,

						primaryOffer: data.primaryOffer || fallbackValues.primaryOffer,
						compareOffer: false,
						primaryOfferData: primaryOfferData,
				  },
		[mode, data, project, epcOffer, currency, primaryOfferData, document?.displayName],
	);

	const sectionFields = {
		technicalInputs: [
			'offerDate',
			'systemSize',
			'mountingTypes',
			'epcVolume',
			'currency',
			// 'epcOffer',
			'annualOmEscalationRate',
			'yield',
			'solarPvDegradation',
			'solarUtilizationRate',
			'annualOmPrice',
			'extraCapexExpected',
			'extraOpexExpected',
		],
		commercialInputs: [
			'contractType',
			'contractTerm',
			'escalationRateInPercent',
			'ppaTariffType',
			'saasCurrency',
			'minimumPurchaseAmount',
			'minimumWarrantedFunctionality',
			'discountOnGridTariff',
			'clientDownpayment',
			'billingPeriod',
			'leaseFee',
			'ppaTariff',
			'percentageOnGridTariff',
			'floorTariff',
			'ceilingTariff',
		],
		offerOutput: ['expectedClientSavings', 'solarEnergyReplacement'],
		ecoligoResults: ['irr', 'minimumDscr', 'averageDscr', 'worstCaseIrr'],
	};

	return { sectionFields, defaultValues };
};

export default useClientOfferOverlayValues;
