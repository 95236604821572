/* eslint-disable react/prop-types */

import Grid from '@mui/material/Grid';
import TileHeader from 'Common/components/Tile/TileHeader';
import useReponsive from 'Common/hooks/useResponsive';
import ImpactTable from 'Impact/components/ImpactTable/ImpactTable';

import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';

const Wrapper = styled.div`
	background-color: ${colors.common.white};
	border-radius: 20px;
	border: 1px solid transparent;

	&:hover {
		border-color: ${colors.primary.main};
	}
`;

const TitleHeaderWrapper = styled.div`
	padding: 16px 16px 0px 32px;
`;

const ProjectImpactTable = ({ data, rowLabels, columnLabels, cells }) => {
	const { isMobile, isTablet, isBigScreen, isFullHd, is4k } = useReponsive();

	return (
		<Wrapper>
			<Grid item xs={12}>
				<TitleHeaderWrapper>
					<TileHeader title={'impact'} />
				</TitleHeaderWrapper>
			</Grid>
			<ImpactTable
				data={data}
				rowLabels={rowLabels}
				columnLabels={columnLabels}
				isSmallTable={isMobile || isTablet}
				isBigTable={isBigScreen || isFullHd || is4k}
				cells={cells}
			/>
		</Wrapper>
	);
};

export default ProjectImpactTable;
