/* eslint-disable react/prop-types */
import EntitiyDetailsSidePanelComments from 'Entities/components/EntitiesDetails/EntitiyDetailsSidePanelComments';
import DashboardSidePanelProject from 'PMWorkflow/components/Project/DashboardProject/DashboardSidePanelProject';
import DashboardSidePanelStep from 'PMWorkflow/components/Step/DashboardStep/DashboardSidePanelStep';
import ProgressSidePanelStep from 'PMWorkflow/components/Step/ProgressStep/ProgressSidePanelStep';
import ProjectDetailsSidePanelComments from 'Projects/components/Comments/ProjectDetailsSidePanelComments';
import SidePanelNotifications from 'Notifications/components/SidePanelNotifications';

import { SIDE_PANEL_CONTENT_TYPES } from 'Common/components/SidePanel/constants/sidePanelContentTypes';
import SidePanelTiles from 'Common/components/SidePanel/components/SidePanelTiles';

const SIDE_PANEL_COMPONENTS = {
	[SIDE_PANEL_CONTENT_TYPES.NOTIFICATIONS]: ({ contentProps, contentData }) => (
		<SidePanelNotifications {...contentProps} {...contentData} />
	),
	[SIDE_PANEL_CONTENT_TYPES.DASHBOARD_PROJECT]: ({ contentProps, contentData }) => (
		<DashboardSidePanelProject {...contentProps} {...contentData} />
	),
	[SIDE_PANEL_CONTENT_TYPES.DASHBOARD_STEP]: ({ contentProps, contentData }) => (
		<DashboardSidePanelStep {...contentProps} {...contentData} />
	),
	[SIDE_PANEL_CONTENT_TYPES.PROJECT_COMMENTS]: ({ contentProps, contentData }) => (
		<ProjectDetailsSidePanelComments {...contentProps} {...contentData} />
	),
	[SIDE_PANEL_CONTENT_TYPES.PROJECT_STEP]: ({ contentProps, contentData }) => (
		<ProgressSidePanelStep {...contentProps} {...contentData} />
	),
	[SIDE_PANEL_CONTENT_TYPES.ENTITY_COMMENTS]: ({ contentProps, contentData }) => (
		<EntitiyDetailsSidePanelComments {...contentProps} {...contentData} />
	),
	[SIDE_PANEL_CONTENT_TYPES.SIDE_PANEL_TILES]: ({ contentProps, contentData }) => (
		<SidePanelTiles {...contentProps} {...contentData} />
	),
};

export { SIDE_PANEL_COMPONENTS };
