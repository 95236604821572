import api from 'Application/api/api';

const createPostCompletion = async (signal, values) => {
	const response = await api.post('/post-completions/create', values, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
	};
};

export default createPostCompletion;
