import getEntityById from 'Entities/api/getEntityById';

const loadSaaSBankAccountOptionsFromEntity = async (entityId, signal = null) => {
	const {
		data: { bankAccounts },
	} = await getEntityById(signal, entityId, true);

	return bankAccounts.map(bankAccount => ({
		label: bankAccount.name,
		subtitle: bankAccount.accountNumber,
		value: bankAccount.id,
		currency: bankAccount.currency,
	}));
};

export default loadSaaSBankAccountOptionsFromEntity;
