import colors from 'Application/theme/colors';

const $inputBorderColor = ({ $isTile, $hasFocus, $hasError, $isDisabled, $isOverlay }) =>
	$isDisabled
		? 'transparent'
		: $hasError
		? colors.error.main
		: $hasFocus
		? colors.primary.dark
		: $isTile || $isOverlay
		? 'transparent'
		: colors.grey.main;

export default $inputBorderColor;
