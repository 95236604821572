/* eslint-disable no-unused-vars */

import { useState, useEffect } from 'react';
import { datasetOptions, chartTypes } from 'Impact/constants/options';
import { parseStatisticsData } from 'Impact/utils/parseStatisticsData';
import { getChartGradient } from 'Impact/utils/getChartGradient';

import colors from 'Application/theme/colors';

const getDataSetOptions = (index, type, data) => {
	if (type === chartTypes.STACKED_LINE) {
		return index === 0
			? { borderColor: colors.secondary.dark, ...datasetOptions[type] }
			: { borderColor: colors.primary.dark, ...datasetOptions[type] };
	}
	if (type === chartTypes.PIE) {
		return {
			backgroundColor: data.colors,
			borderColor: data.colors,
			hoverBackgroundColor: data.colors,
			...datasetOptions[type],
		};
	} else {
		return datasetOptions[type];
	}
};

const useChartData = (data, chartRef, type, filters, options) => {
	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [],
	});

	useEffect(() => {
		const chart = chartRef.current;

		if (chart && data) {
			const { labels, datasets, values, activeLabels } = parseStatisticsData(
				data,
				type,
				filters,
				options?.autofill,
			);
			const gradient = options?.gradient?.display
				? {
						backgroundColor: getChartGradient(
							chart,
							options.gradient.type,
							options.gradient.from,
							options.gradient.to,
						),
				  }
				: {};

			setChartData({
				labels: labels,
				datasets: datasets.map((dataset, index) => ({
					data: dataset,
					activeLabel: activeLabels ? activeLabels[index] : [],
					values: values,
					...getDataSetOptions(index, type, data),
					...gradient,
				})),
			});
		}
	}, [data, chartRef, type, filters, options]);

	return chartData;
};

export default useChartData;
