import { useCallback, useMemo, useState } from 'react';
import Icon from 'Common/components/icons/Icon';
import Grid from '@mui/material/Grid';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import * as yup from 'yup';

import notify from 'Common/utils/notify';
import Button from 'Common/components/buttons/Button';
import UserAvatar from 'Common/components/usersPreview/UserAvatar';
import StatusPill from 'Common/components/StatusPill';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import TileDynamicSection from 'Common/components/Tile/TileDynamicSection';

import sizes from 'Application/theme/sizes';
import colors from 'Application/theme/colors';

import { authenticateUser } from 'User/reduxUser';
import { roleLabels } from 'User/constants/roles';
import changePassword from 'User/api/changePassword';

import uploadNewPhoto from 'UserManagement/api/uploadNewPhoto';
import deleteUserPhoto from 'UserManagement/api/deleteUserPhoto';

import { css } from 'styled-components';
import useReponsive from 'Common/hooks/useResponsive';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { FILE_TYPES } from 'Common/constants/fileTypes';
import useFileUpload from 'Common/hooks/useFileUpload';

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	> * {
		margin-right: 16px;

		&:last-child {
			margin-right: 0px;
		}
	}
`;

const UserInfoContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	padding-bottom: ${sizes.spacing(10)};
`;

const UserInfoText = styled.div`
	width: fit-content;
	padding-left: ${sizes.spacing(2)};
`;

const StyledForm = styled.form`
	padding: ${sizes.spacing(7)} ${sizes.spacing(6)};

	${({ $isMobile }) =>
		$isMobile &&
		css`
			padding: 8px;

			${UserInfoContainer} {
				flex-direction: column;
				align-items: center;
				gap: 10px;
			}

			${UserInfoText} {
				text-align: center;
			}
		`}
`;

const UserAvatarContainer = styled.div`
	width: 120px;
	height: 120px;
	aspect-ratio: 1;
`;

const UserInfo = styled.div`
	padding-bottom: ${sizes.spacing(1)};
`;

const UserFullName = styled(UserInfo)`
	font-weight: 700;
	padding-bottom: ${sizes.spacing(1.5)};
`;

const UserRoles = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding-top: ${sizes.spacing(0.5)};
	gap: ${sizes.spacing(0.5)};
`;

const IconContainer = styled.div`
	position: absolute;
	right: 5px;
	bottom: 5px;
`;

const StyledFormField = styled(FormField)`
	position: relative;
	margin-bottom: 32px;
`;

const initialValues = {
	oldPassword: '',
	password: '',
	passwordConfirm: '',
};

const ChangePasswordForm = () => {
	const { t } = useTranslation();
	const { isMobile } = useReponsive();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [showChangePassword, setShowChangePassword] = useState(false);

	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

	const abortController = useAbortController();

	const user = useSelector(state => state.user);

	const validationSchema = useMemo(
		() =>
			yup.object({
				oldPassword: yup.string().required(t('Required')),
				password: yup
					.string()
					.required(t('Required'))
					.min(8, t('The password must contain at least 8 characters'))
					.matches(
						/^(?=.*[A-Z])(?=.*[0-9!@#$%^&*()\-+=_~`[\]{}:;'"<>?\\/,.])/,
						t('The password must contain an uppercase letter and either a number or a special character'),
					),
				passwordConfirm: yup
					.string()
					.required(t('Required'))
					.oneOf([yup.ref('password')], t('The passwords must match')),
			}),
		[t],
	);

	const { errors, touched, values, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: true,
		validateOnBlur: false,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				await changePassword(
					abortController.signal,
					user.email,
					values.oldPassword,
					values.password,
					values.passwordConfirm,
				);

				notify(t('Password changed successfully'), {
					type: toast.TYPE.SUCCESS,
				});

				setShowChangePassword(false);
				setIsLoading(false);
				resetForm({ values });
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setIsLoading(false);
					resetForm({ values });
				}
			}
		},
	});

	const handleImageUpload = useCallback(
		async ({ formData }) => {
			try {
				const res = await uploadNewPhoto(abortController.signal, user.email, formData);
				const profilePhoto = res.data.profilePhoto;

				dispatch(authenticateUser({ ...user, profilePhoto }));
			} catch (e) {
				showToastError(e, t("Couldn't upload images"));
			}
		},

		[abortController.signal, user, dispatch, t],
	);

	const {
		handleAddFile: handleAddImage,
		isUploading,
		generateInputProps,
	} = useFileUpload({
		onFileUpload: handleImageUpload,
		fileName: 'Image',
		fileIdentifier: 'file',
		acceptedTypes: [FILE_TYPES.IMAGE],
	});

	const handleRemovePhoto = async () => {
		try {
			await deleteUserPhoto(abortController.signal, user.email);
			dispatch(authenticateUser({ ...user, profilePhoto: {} }));
		} catch (err) {
			showToastError(err);
		}
	};

	return (
		<StyledForm onSubmit={handleSubmit} $isMobile={isMobile}>
			<UserInfoContainer>
				<UserAvatarContainer>
					<UserAvatar
						imgUrl={user?.profilePhoto?.url}
						onUpload={handleAddImage}
						onRemovePhoto={handleRemovePhoto}
						handleChange={handleChange}
						isUploading={isUploading}
						uploadAvailable
					/>
					<input {...generateInputProps()} />
				</UserAvatarContainer>
				<UserInfoText>
					<UserFullName>
						{user.firstName} {user.lastName}
					</UserFullName>
					<UserInfo>{user.email}</UserInfo>
					<UserInfo>{user.phone}</UserInfo>
					<UserRoles>
						{user.roles.map(role => (
							<StatusPill key={role} color={colors.common.darkBlue}>
								{roleLabels[role]}
							</StatusPill>
						))}
					</UserRoles>
				</UserInfoText>
			</UserInfoContainer>

			<TileDynamicSection
				isInEditMode={showChangePassword}
				readonlyView={
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<Button
							text={t('Change Password')}
							onClick={() => setShowChangePassword(true)}
							label="Account Lightbox Form - Change Password Button"
						/>
					</Grid>
				}
				editView={
					<>
						<StyledFormField>
							<FormikInput
								id="oldPassword"
								name="oldPassword"
								inputType={showOldPassword ? 'text' : 'password'}
								label={t('Old Password')}
								value={values.oldPassword}
								error={errors.oldPassword}
								touched={touched.oldPassword}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<IconContainer>
								<Icon
									icon="removeRedEyeOutlined"
									color={showOldPassword ? colors.primary.main : ''}
									onClick={() => setShowOldPassword(prev => !prev)}
								/>
							</IconContainer>
						</StyledFormField>
						<StyledFormField>
							<FormikInput
								id="password"
								name="password"
								inputType={showPassword ? 'text' : 'password'}
								label={t('New Password')}
								value={values.password}
								error={errors.password}
								touched={touched.password}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<IconContainer>
								<Icon
									icon="removeRedEyeOutlined"
									color={showPassword ? colors.primary.main : ''}
									onClick={() => setShowPassword(prev => !prev)}
								/>
							</IconContainer>
						</StyledFormField>
						<StyledFormField>
							<FormikInput
								id="passwordConfirm"
								name="passwordConfirm"
								inputType={showPasswordRepeat ? 'text' : 'password'}
								label={t('Confirm Password')}
								value={values.passwordConfirm}
								error={errors.passwordConfirm}
								touched={touched.passwordConfirm}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<IconContainer>
								<Icon
									icon="removeRedEyeOutlined"
									color={showPasswordRepeat ? colors.primary.main : ''}
									onClick={() => setShowPasswordRepeat(prev => !prev)}
								/>
							</IconContainer>
						</StyledFormField>
						<ButtonWrapper>
							<Button
								secondary
								text={t('Cancel')}
								onClick={() => setShowChangePassword(false)}
								label="Account Lightbox Form - Cancel Button"
							/>
							<Button
								overlay
								type="submit"
								text={t('Save')}
								isLoading={isLoading}
								label="Account Lightbox Form - Save Button"
							/>
						</ButtonWrapper>
					</>
				}
			/>
		</StyledForm>
	);
};

export default ChangePasswordForm;
