import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import ConfirmModal from 'Common/components/modals/ConfirmModal';

import { DefaultTable } from 'Common/components/table/table';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import colors from 'Application/theme/colors';

const Wrapper = styled.div`
	position: relative;
	border-top: 1px solid ${colors.grey.light};
`;

const PayoutsTrancheModal = ({ data, isOpen, onConfirm }) => {
	const { t } = useTranslation();

	const columns = useMemo(
		() => [
			{
				Header: t('Gross Total'),
				accessor: 'gross_total',
			},
			{
				Header: t('State'),
				accessor: 'state',
			},
			{
				Header: t('Interest'),
				accessor: 'interest',
			},
			{
				Header: t('Net total'),
				accessor: 'net_total',
			},

			{
				Header: t('Payout date'),
				accessor: 'payout_date',
			},
			{
				Header: t('Repayment'),
				accessor: 'repayment',
			},
			{
				Header: t('Taxes kest'),
				accessor: 'taxes_kest',
			},
			{
				Header: t('Taxes kist'),
				accessor: 'taxes_kist',
			},
			{
				Header: t('Taxes soli'),
				accessor: 'taxes_soli',
			},
			{
				Header: t('Taxes total'),
				accessor: 'taxes_total',
			},
		],
		[t],
	);
	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable({
			data: data?.payouts?.length > 0 ? data.payouts : [],
			columns,
		});

	return (
		<ConfirmModal
			label="tranche-create"
			isOpen={isOpen}
			onConfirm={onConfirm}
			confirmText="Close"
			noCancel
			heading={t('Payouts')}
		>
			<Wrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
				/>
			</Wrapper>
		</ConfirmModal>
	);
};

PayoutsTrancheModal.defaultProps = {
	isOpen: false,
	data: {},
	onConfirm: () => {},
};

PayoutsTrancheModal.propTypes = {
	isOpen: PropTypes.bool,
	data: PropTypes.shape({ payouts: PropTypes.arrayOf(PropTypes.shape({})) }),
	onConfirm: PropTypes.func,
};

export default PayoutsTrancheModal;
