import { useMemo } from 'react';

const fallbackValues = {
	displayName: '',
	website: '',
	productTypes: [],
	country: null,
	apartmentBuilding: '',
	line1: '',
	line2: '',
	cityRegion: '',
	postCodeZip: '',
};

const useManufacturerOverlayValues = (data, productTypeForNewManufacturer) => {
	const defaultValues = useMemo(
		() => ({
			displayName: data?.displayName || fallbackValues.displayName,
			website: data?.website || fallbackValues.website,
			productTypes: data?.productTypes
				? data?.productTypes?.map(productType => ({
						value: productType.name,
						label: productType.name,
				  }))
				: productTypeForNewManufacturer
				? [
						{
							value: productTypeForNewManufacturer,
							label: productTypeForNewManufacturer,
						},
				  ]
				: fallbackValues.productTypes,
			country: data?.country
				? {
						value: data?.country?.id,
						label: data?.country?.name,
				  }
				: null,
			apartmentBuilding: data?.address?.apartmentBuilding || fallbackValues.apartmentBuilding,
			line1: data?.address?.line1 || fallbackValues.line1,
			line2: data?.address?.line2 || fallbackValues.line2,
			cityRegion: data?.address?.cityRegion || fallbackValues.cityRegion,
			postCodeZip: data?.address?.postCodeZip || fallbackValues.postCodeZip,
		}),
		[
			data?.address?.apartmentBuilding,
			data?.address?.cityRegion,
			data?.address?.line1,
			data?.address?.line2,
			data?.address?.postCodeZip,
			data?.country,
			data?.displayName,
			data?.productTypes,
			data?.website,
			productTypeForNewManufacturer,
		],
	);

	const sectionFields = useMemo(
		() => ({
			details: ['displayName', 'website', 'productTypes'],
			address: ['country', 'apartmentBuilding', 'line1', 'line2', 'cityRegion', 'postCodeZip'],
		}),
		[],
	);

	return {
		defaultValues,
		sectionFields,
	};
};

export default useManufacturerOverlayValues;
