import getAccountingStandards from 'AccountingStandards/api/getAccountingStandards';

const loadAccountingStandardsOptions = async (signal, inputValue) => {
	const { data: accountingStandards } = await getAccountingStandards(signal, inputValue);

	return accountingStandards.map(accountingStandard => ({
		label: accountingStandard.name,
		value: accountingStandard.id,
	}));
};

export default loadAccountingStandardsOptions;
