import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import EditableTile from 'Common/components/Tile/EditableTile';

import editEntity from 'Entities/api/editEntity';
import useEntityFormInitialValues from 'Entities/components/EntitiesDetails/Tabs/GeneralTab/Tiles/EntityDetailsTile/hooks/useEntityFormInitialValues';
import useEntityFormValidationSchema from 'Entities/components/EntitiesDetails/Tabs/GeneralTab/Tiles/EntityDetailsTile/hooks/useEntityFormValidationSchema';
import convertEntityFormValues from 'Entities/components/EntitiesDetails/Tabs/GeneralTab/Tiles/EntityDetailsTile/utils/convertEntityFormValues';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import loadAccountingStandardsOptions from 'Entities/utils/loadAccountingStandardsOptions';
import loadAssetAndRevenueRecognitionOptions from 'Entities/utils/loadAssetAndRevenueRecognitionOptions';
import assignSecretaries from 'Entities/api/assignSecretaries';
import assignAccountingStandards from 'Entities/api/assignAccountingStandards';
import assignAssetAndRevenueRecognition from 'Entities/api/assignAssetAndRevenueRecognition';
import useEcoligoUsersOptions from 'UserManagement/hooks/useEcoligoUsersOptions';
import unassignAccountingStandards from 'Entities/api/unassignAccuntingStandards';
import unassignAssetAndRevenueRecognition from 'Entities/api/unassignAssetAndRevenueRecognition';
import ConditionalGridSection from 'Common/components/ConditionalGridSection';

const EntityDetailsTile = ({
	entity,
	onEnterEditMode,
	onExitEditMode,
	onDirtyForm,
	onSave,
	highlightLabels,
}) => {
	const { t } = useTranslation();

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useEntityFormInitialValues(entity);
	const validationSchema = useEntityFormValidationSchema();

	const isPINVisible = useMemo(() => entity?.country?.isoCode === 'KEN', [entity]);

	const {
		errors,
		touched,
		values,
		handleSubmit: formkHandleSubmit,
		setFieldTouched,
		dirty,
		handleChange,
		handleBlur,
		setFieldValue,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			setIsSavingChanges(true);

			try {
				await editEntity(abortController.signal, {
					...entity,
					...convertEntityFormValues(values, isPINVisible),
				});

				await assignSecretaries(abortController.signal, {
					contractPartyId: entity.id,
					usersEmails: [values?.secretary?.value],
				});

				if (values?.accountingStandard?.value) {
					await assignAccountingStandards(abortController.signal, {
						contractPartyId: entity.id,
						accountingStandardId: values?.accountingStandard?.value,
					});
				} else {
					await unassignAccountingStandards(abortController.signal, {
						contractPartyId: entity.id,
					});
				}

				if (values?.assetAndRevenueRecognition?.value) {
					await assignAssetAndRevenueRecognition(abortController.signal, {
						contractPartyId: entity.id,
						assetAndRevenueRecognitionId: values?.assetAndRevenueRecognition?.value,
					});
				} else {
					await unassignAssetAndRevenueRecognition(abortController.signal, {
						contractPartyId: entity.id,
					});
				}

				if (onDirtyForm) {
					onDirtyForm(false);
				}

				await onSave();
				handleCancel();
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});

	const handleSubmit = (...args) => {
		formkHandleSubmit(...args);
		// ! This is a workaround. For some reason here on submit touched is not set to true to dateOfRegistration
		setFieldTouched('dateOfRegistration', true);
	};

	const { ecoligoUsersOptions: secretaryOptions, isLoadingEcoligoUsersOptions: isLoadingSecretaryOptions } =
		useEcoligoUsersOptions();

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Entity details')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<FormField>
				<FormikInput
					id="companyName"
					name="companyName"
					label={t('Company name')}
					value={values.companyName}
					error={errors.companyName}
					touched={touched.companyName}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('companyName')}
					isRequired
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="abbreviation"
					name="abbreviation"
					label={t('Abbreviation')}
					value={values.abbreviation}
					error={errors.abbreviation}
					touched={touched.abbreviation}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('abbreviation')}
					isRequired
				/>
			</FormField>
			<FormField>
				<FormikSingleDatePicker
					id="dateOfRegistration"
					label={t('Date of registration')}
					startDateName="dateOfRegistration"
					error={errors.dateOfRegistration}
					touched={touched.dateOfRegistration}
					startDate={values.dateOfRegistration}
					setFieldValue={setFieldValue}
					setFieldTouched={setFieldTouched}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('dateOfRegistration')}
					isRequired
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="registrationNumber"
					name="registrationNumber"
					label={t('Registration number')}
					value={values.registrationNumber}
					error={errors.registrationNumber}
					touched={touched.registrationNumber}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('registrationNumber')}
					isRequired
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="taxNumber"
					name="taxNumber"
					label={t('Tax number')}
					value={values.taxNumber}
					error={errors.taxNumber}
					touched={touched.taxNumber}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('taxNumber')}
					isRequired
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="vatNumber"
					name="vatNumber"
					label={t('VAT number')}
					value={values.vatNumber}
					error={errors.vatNumber}
					touched={touched.vatNumber}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('vatNumber')}
					isRequired
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="placeOfIncorporation"
					name="placeOfIncorporation"
					label={t('Place of Incorporation')}
					value={values.placeOfIncorporation}
					error={errors.placeOfIncorporation}
					touched={touched.placeOfIncorporation}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('placeOfIncorporation')}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					id="secretary"
					name="secretary"
					label={t('Secretary')}
					value={values.secretary}
					error={errors.secretary}
					touched={touched.secretary}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					options={secretaryOptions}
					isLoading={isLoadingSecretaryOptions}
					userInfo={values.secretary}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('secretary')}
					isClearable
				/>
			</FormField>
			<FormField>
				<FormikSelect
					isAsync
					id="accountingStandard"
					name="accountingStandard"
					label={t('Accounting standard')}
					value={values.accountingStandard}
					error={errors.accountingStandard}
					touched={touched.accountingStandard}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={loadAccountingStandardsOptions}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('accountingStandard')}
					isClearable
				/>
			</FormField>
			<FormField>
				<FormikSelect
					isAsync
					id="assetAndRevenueRecognition"
					name="assetAndRevenueRecognition"
					label={t('Asset & revenue recognition')}
					value={values.assetAndRevenueRecognition}
					error={errors.assetAndRevenueRecognition}
					touched={touched.assetAndRevenueRecognition}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={loadAssetAndRevenueRecognitionOptions}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('assetAndRevenueRecognition')}
					isClearable
				/>
			</FormField>
			<ConditionalGridSection hasFlag={isPINVisible}>
				<FormField>
					<FormikInput
						id="pin"
						name="pin"
						label={t('PIN')}
						value={values.pin}
						error={errors.pin}
						touched={touched.pin}
						onChange={handleChange}
						onBlur={handleBlur}
						isTile
						isInEditMode={isInEditMode}
						isHighlighted={highlightLabels.includes('pin')}
					/>
				</FormField>
			</ConditionalGridSection>
		</EditableTile>
	);
};

EntityDetailsTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	onSave: async () => {},
	highlightLabels: [],
};

EntityDetailsTile.propTypes = {
	entity: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		country: PropTypes.shape({
			isoCode: PropTypes.string,
		}),
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	onSave: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default EntityDetailsTile;
