import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import colors from 'Application/theme/colors';
import EpcPartnerDashboard from './EpcPartnerDashboard/EpcPartnerDashboard';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';

const Title = styled.div`
	font-size: 26px;
	font-weight: 600;
	color: ${colors.text.primary};
	text-transform: uppercase;
`;

const EpcPartnerPortal = () => {
	const { t } = useTranslation();
	const user = useSelector(state => state.user);

	return (
		<>
			<Helmet>
				<title>{t('EPC Dashboard')}</title>
			</Helmet>
			<HeaderContainer>
				<Title>
					Hello, {user.firstName} {user.lastName}
				</Title>
			</HeaderContainer>
			<EpcPartnerDashboard />
		</>
	);
};

export default EpcPartnerPortal;
