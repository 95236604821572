import api from 'Application/api/api';

const editTranche = async (signal, values) => {
	const response = await api.put('/tranches', values, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
	};
};

export default editTranche;
