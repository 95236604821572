import { useMemo } from 'react';

const useSelectDisplayValue = (value, isMulti) =>
	useMemo(() => {
		const isValueEmptyObject = value && typeof value === 'object' && Object.keys(value).length === 0;
		const isValueEmptyArray = value && Array.isArray(value) && value.length === 0;

		const isValidMultiValue =
			isMulti && Boolean(value) && !isValueEmptyObject && !isValueEmptyArray && value?.length > 0;

		if (isValidMultiValue) {
			return value?.map(el => el.label).join(', ');
		}

		return value?.label ?? '';
	}, [value, isMulti]);

export default useSelectDisplayValue;
