import api from 'Application/api/api';

const uploadInvoiceTemplates = (signal, countryId, files) =>
	api.post(`/invoices/${countryId}/upload-templates`, files, {
		signal,
		timeout: 100000,
		headers: {
			'Content-Type': 'multipart/form-data',
			Accept: '*/*',
		},
	});

export default uploadInvoiceTemplates;
