import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { statusesColors, statusesOptions } from 'Projects/constants/statuses';
import getProjects from 'Projects/api/getProjects';
import getProjectById from 'Projects/api/getProjectById';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import 'rc-pagination/assets/index.css';
import { push } from 'redux-first-history';
import formatCurrency from 'Common/utils/formatCurrency';
import formatNumber from 'Common/utils/formatNumber';
import Link from 'Common/components/Link';
import Big from 'Common/utils/customBig';
import EpcPartnerNameWithTier from 'EpcPartner/components/EpcPartnerNameWithTier';
import { DefaultTable } from 'Common/components/table/table';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import { useSortBy } from 'react-table';
import { useSortingQueryParameter } from 'Common/hooks/table/plugin-hooks/useSortingQueryParameter';
import { useScrollableTableBody } from 'Common/hooks/table/plugin-hooks/useScrollableTableBody';
import { useVirtualizedTableBody } from 'Common/hooks/table/plugin-hooks/useVirtualizedTableBody';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import Button from 'Common/components/buttons/Button';
import ReactTimeAgo from 'react-time-ago';
import { debounce, fill } from 'lodash';
import formatDate from 'Common/utils/formatDate';
import colors from 'Application/theme/colors';
import formatDateForRequestData from 'Common/utils/formatDateForRequestData';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import defaultTimezone from 'Common/constants/defaultTimezone';
import StatusPill from 'Common/components/StatusPill';
import TableCellText from 'Common/components/table/TableCellText';
import { css } from 'styled-components';
import UsersPreview from 'Common/components/usersPreview/UsersPreview';
import sizes from 'Application/theme/sizes';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import { useCustomRowStyle } from 'Common/hooks/table/plugin-hooks/useCustomRowStyle';
import PriorityIcon from 'Common/components/icons/PriorityIcon';
import showToastError from 'Common/utils/showToastError';
import useUniqueAbortSignal from 'Common/hooks/useUniqueAbortSignal';
import prefillTableData from 'Common/utils/prefillTableData';
import { updateSidePanelData } from 'Application/reducers/reduxSidePanel';
import { SIDE_PANEL_CONTENT_TYPES } from 'Common/components/SidePanel/constants/sidePanelContentTypes';
import useAbortController from 'Common/hooks/useAbortController';

const Wrapper = styled.div`
	position: relative;
	pointer-events: ${({ hasOpenFilter }) => (hasOpenFilter ? 'none' : 'all')};
`;

const StagePill = styled(StatusPill)`
	padding: ${sizes.spacing(0.5)} ${sizes.spacing(1)};
`;

const stagePercentageColors = {
	NOT_STARTED: 'transparent',
	IN_PROGRESS: colors.secondary.dark,
	COMPLETED: 'transparent',
};

const Stage = ({ stage }) => {
	const openedAt = stage.openedAt && `SD: ${formatDate(stage.openedAt)}`;
	const firstCompletedAt = stage.firstCompletedAt && `ED: ${formatDate(stage.firstCompletedAt)}`;

	const tooltipText =
		openedAt && firstCompletedAt
			? `${openedAt}
		${firstCompletedAt}`
			: !openedAt && !firstCompletedAt
			? ''
			: openedAt || firstCompletedAt;

	return (
		<HoverTooltip title={tooltipText} placement="bottom">
			<StagePill
				color={stagePercentageColors[stage?.stageStatus]}
				isBlackText={
					stage?.stageStatus === 'NOT_STARTED' || stage?.stageStatus === 'COMPLETED' || !stage?.stageStatus
				}
			>
				{stage?.completedPercentage ? Math.floor(stage?.completedPercentage) : 0}%
			</StagePill>
		</HoverTooltip>
	);
};

const StageCell = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	svg {
		margin-left: ${props => props.hasIcon && '4px'};
	}

	${props =>
		props.hasIcon &&
		css`
			&:before {
				content: '';
				position: absolute;
				top: -22px;
				left: -5px;
				width: 1900px;
				height: 68px;
				z-index: -1;
			}
		`}
`;

const FooterSizeText = styled.div`
	transform: translateX(-30%);
`;

const defaultSortBy = {
	id: 'externalId',
	desc: true,
};

const sortPropertiesMap = {
	condition: 'cond',
	country: 'country.isoCode',
	contractType: 'contractType.name',
	epcPartner: 'epcPartner.name',
	client: 'client.name',
	stage: 'stageOrder',
	duration: 'contractDuration',
	salesChannel: 'salesChannel.name',
	industry: 'client.industry.name',
};

const ProjectListTable = () => {
	const { t } = useTranslation();
	const hasOpenFilter = !!useSelector(state => state?.tables?.projects?.openedFilter);

	const [totalItemsCount, setTotalItemsCount] = useState(25);

	const sidePanelData = useSelector(state => state.sidePanel);
	const isOpenSidepanel = sidePanelData.isOpen && sidePanelData.type === 'projectComments';

	const sortByQueryParam = useQueryParameter('sortBy');
	const sortBy = sortByQueryParam ?? defaultSortBy;
	const filters = useQueryParameter('filters');
	const flags = useQueryParameter('flags');

	const searchQuery = useQueryParameter('searchQuery');

	const dispatch = useDispatch();

	const [totals, setTotals] = useState(null);
	const [projects, setProjects] = useState(() => fill(Array(25), {}));
	const [isLoading, setIsLoading] = useState(true);

	const abortController = useAbortController();
	const getUniqueSignal = useUniqueAbortSignal();

	const statusValue = project => {
		const statusOption = statusesOptions.find(option => option.value === project.condition?.condition)?.label;

		return statusOption;
	};

	const hasLoadedFirstResultsRef = useRef(false);

	const getProjectsBatch = useCallback(
		async (startIndex, stopIndex, filters, sortBy, searchQuery, flags, hasItemsChanges = false) => {
			if (hasItemsChanges) {
				setIsLoading(true);
			}

			const sortById = sortPropertiesMap[sortBy.id] ?? sortBy.id;

			const customFilters = { ...filters };

			if (customFilters.irrTo) {
				customFilters.irrTo = Big(customFilters.irrTo).div(100).toString();
			}
			if (customFilters.irrFrom) {
				customFilters.irrFrom = Big(customFilters.irrFrom).div(100).toString();
			}

			if (customFilters.salesCloseDateFrom) {
				customFilters.salesCloseDateFrom = formatDateForRequestData(
					new Date(customFilters.salesCloseDateFrom),
					'yyyy-MM-dd',
				);
			}
			if (customFilters.salesCloseDateTo) {
				customFilters.salesCloseDateTo = formatDateForRequestData(
					new Date(customFilters.salesCloseDateTo),
					'yyyy-MM-dd',
				);
			}

			if (customFilters.lastModifiedDateFrom) {
				customFilters.lastModifiedDateFrom = zonedTimeToUtc(
					format(new Date(customFilters.lastModifiedDateFrom), "yyyy-MM-dd'T'HH:mm.sss'Z'", {
						timeZone: defaultTimezone,
					}),
					defaultTimezone,
				);
			}
			if (customFilters.lastModifiedDateTo) {
				customFilters.lastModifiedDateTo = zonedTimeToUtc(
					format(new Date(customFilters.lastModifiedDateTo), "yyyy-MM-dd'T'HH:mm.sss'Z'", {
						timeZone: defaultTimezone,
					}),
					defaultTimezone,
				);
			}

			try {
				let response = await getProjects(
					getUniqueSignal('get-projects'),
					{
						offset: startIndex,
						limit: stopIndex - startIndex + 1,
						sortBy: sortById,
						sortDirection: sortBy.desc ? 'DESC' : 'ASC',
					},
					customFilters,
					searchQuery,
				);

				if (hasItemsChanges || !hasLoadedFirstResultsRef.current) {
					const { itemsCount, itemsFilled, error } = prefillTableData(response.data, startIndex, stopIndex, true);
					if (error) {
						showToastError(error);
					} else {
						setProjects(itemsFilled);
						setTotalItemsCount(itemsCount);
						setTotals({
							totalEpcVolumeExclVat: response.data.totalEpcVolumeExclVat,
							totalSystemSize: response.data.totalSystemSize,
						});
					}
				} else {
					setProjects(prevProjects =>
						prevProjects.map((prevProject, index) => {
							if (startIndex <= index && index <= stopIndex) {
								const indexInResultSet = index - startIndex;

								const project = response.data.content[indexInResultSet];

								const stageIndicators = {};
								project?.stageIndicators?.forEach(s => {
									stageIndicators[s.stage] = s;
								});

								return { ...project, stageIndicators };
							}

							return prevProject;
						}),
					);
				}

				hasLoadedFirstResultsRef.current = true;

				setIsLoading(false);
			} catch (error) {
				showToastError(error);
				return error;
			}
		},
		[getUniqueSignal],
	);

	useEffect(() => {
		getProjectsBatch(
			0,
			25,
			filters,
			{
				id: sortBy.id,
				desc: sortBy.desc,
			},
			searchQuery,
			flags,
			true,
		);
	}, [getProjectsBatch, searchQuery, filters, flags, sortBy.desc, sortBy.id]);

	// eslint-disable-next-line
	const handleLoadMoreRows = useCallback(
		debounce(
			(startIndex, stopIndex) => {
				getProjectsBatch(
					startIndex,
					stopIndex,
					filters,
					{
						id: sortBy.id,
						desc: sortBy.desc,
					},
					searchQuery,
					flags,
				);
			},
			350,
			{
				leading: false,
				trailing: true,
			},
		),
		[getProjectsBatch, searchQuery, filters, flags, sortBy.desc, sortBy.id],
	);

	const handleCommentBtnClick = useCallback(
		async (e, project) => {
			e.stopPropagation();
			try {
				if (!isOpenSidepanel) {
					dispatch(
						updateSidePanelData({
							isOpen: true,
							type: SIDE_PANEL_CONTENT_TYPES.PROJECT_COMMENTS,
							isLoading: true,
						}),
					);

					const projectData = await getProjectById(abortController.signal, project.id, true).then(res => res.data);

					dispatch(
						updateSidePanelData({
							isOpen: true,
							type: SIDE_PANEL_CONTENT_TYPES.PROJECT_COMMENTS,
							isLoading: false,
							contentData: { project: projectData, showHistory: false },
							headerData: {
								to: '/projects',
								isBreadcrumb: true,
								breadCrumb: 'Projects',
								activeBreadcrumb: `${projectData.externalId} - ${
									projectData?.client?.name ? projectData?.client?.name : t('Unknown client')
								} ${projectData?.description ? ` (${projectData?.description})` : ''}`,
								toActiveBreadcrumb: `/projects/details/${project.id}`,
							},
						}),
					);
				}
			} catch (err) {
				showToastError(err);
			}
		},
		[abortController.signal, dispatch, isOpenSidepanel, t],
	);

	const formatId = id => (id ? `${'0'.repeat(5 - String(id).length)}${id}` : '-');
	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: '\u00A0',
				accessor: 'priority',
				maxWidth: 37,
				Cell: ({ row: { original: project } }) => <PriorityIcon priority={project?.priority?.priority} />,
			},
			{
				Header: t('Status'),
				accessor: 'condition',
				maxWidth: 120,
				customBodyCellStyles: {
					paddingLeft: '4px',
					overflow: 'visible',
				},
				Cell: ({ row: { original: project } }) => (
					<StageCell>
						<HoverTooltip
							title={statusesOptions.find(option => option.value === project?.condition?.condition)?.label}
						>
							<StatusPill color={statusesColors[project?.condition?.condition]}>{statusValue(project)}</StatusPill>
						</HoverTooltip>
					</StageCell>
				),
				Footer: totalItemsCount,
			},
			{
				Header: t('Sales'),
				maxWidth: 63,
				accessor: 'embeddableProjectStage.salesCompletedPercentage',
				Cell: ({ row: { original: project } }) => {
					const stage = project?.stageIndicators?.SALES;
					return <Stage stage={stage} />;
				},
			},
			{
				Header: t('Tech'),
				maxWidth: 63,
				accessor: 'embeddableProjectStage.techCompletedPercentage',
				Cell: ({ row: { original: project } }) => {
					const stage = project?.stageIndicators?.TECH;
					return <Stage stage={stage} />;
				},
			},
			{
				Header: t('Fund'),
				maxWidth: 63,
				accessor: 'embeddableProjectStage.fundraisingCompletedPercentage',
				Cell: ({ row: { original: project } }) => {
					const stage = project?.stageIndicators?.FUNDRAISING;
					return <Stage stage={stage} />;
				},
			},
			{
				Header: t('Build'),
				maxWidth: 63,
				accessor: 'embeddableProjectStage.buildCompletedPercentage',
				Cell: ({ row: { original: project } }) => {
					const stage = project?.stageIndicators?.BUILD;
					return <Stage stage={stage} />;
				},
			},
			{
				Header: t('Asset'),
				maxWidth: 63,
				accessor: 'embeddableProjectStage.assetManagementCompletedPercentage',
				Cell: ({ row: { original: project } }) => {
					const stage = project?.stageIndicators?.ASSET_MANAGEMENT;
					return <Stage stage={stage} />;
				},
			},
			{
				Header: t('ID'),
				accessor: 'externalId',
				maxWidth: 54,
				highlightSearchTerm: true,
				cellStyle: {
					justifyContent: 'flex-end',
				},
				Cell: ({ row: { original: project } }) => (
					<Link
						color={colors.primary.main}
						fontSize="14px"
						to={`/projects/details/${project.id}`}
						onClick={e => e.stopPropagation()}
					>
						{formatId(project.externalId)}
					</Link>
				),
			},
			{
				Header: t('Country'),
				accessor: 'country',
				maxWidth: 104,
				highlightSearchTerm: true,
				Cell: ({ row: { original: project } }) => (
					<div title={project.country?.isoCode}>
						<TableCellText>{project.country?.isoCode}</TableCellText>
					</div>
				),
			},
			{
				Header: t('Client'),
				accessor: 'client',
				highlightSearchTerm: true,
				customBodyCellContentStyles: {
					color: colors.primary.main,
				},
				Cell: ({ row: { original: project } }) => (
					<Link
						color={colors.primary.main}
						fontSize="14px"
						to={`/clients/details/${project.client?.id}`}
						onClick={e => e.stopPropagation()}
					>
						<TableCellText>{project.client?.name}</TableCellText>
					</Link>
				),
			},
			{
				Header: t('Type'),
				accessor: 'projectType',
				maxWidth: 64,
				highlightSearchTerm: true,
				Cell: ({ row: { original: project } }) => project.projectType?.name ?? '-',
			},
			{
				Header: t('People'),
				width: 90,
				highlightSearchTerm: true,
				Cell: ({ row: { original: project } }) => (
					<UsersPreview users={project?.responsiblePeopleObjectByRole} displayTeam />
				),
				customBodyCellContentStyles: {
					overflow: 'visible',
				},
			},
			{
				Header: t('Size (kWp)'),
				accessor: 'systemSizeKwp',
				maxWidth: 105,
				isNumber: true,
				highlightSearchTerm: true,
				cellStyle: {
					justifyContent: 'flex-end',
				},
				Cell: (() => {
					const Cell = ({ value }) => <TableCellText>{value}</TableCellText>;
					Cell.propTypes = { value: PropTypes.string.isRequired };
					return Cell;
				})(),
				Footer: (
					<FooterSizeText>
						{totals?.totalSystemSize ? `${formatNumber(totals?.totalSystemSize, 0)} kWp` : '-'}
					</FooterSizeText>
				),
			},
			{
				Header: t('EPC volume'),
				accessor: 'epcVolumeExclVat',
				width: 110,
				isCurrency: true,
				highlightSearchTerm: true,
				cellStyle: {
					justifyContent: 'flex-end',
				},
				Cell: ({ row: { original: project } }) => (
					<TableCellText>{project?.epcVolumeExclVat ?? '-'}</TableCellText>
				),
				Footer: totals?.totalEpcVolumeExclVat ? formatCurrency(totals?.totalEpcVolumeExclVat, 0) : '-',
			},
			{
				Header: t('€/kWp'),
				accessor: 'averageEPCPrice',
				width: 110,
				isCurrency: true,
				highlightSearchTerm: true,
				cellStyle: {
					justifyContent: 'flex-end',
				},
				Cell: ({ row: { original: project } }) => (
					<TableCellText>{project?.averageEPCPrice ?? '-'}</TableCellText>
				),
			},
			{
				Header: t('EPC Partner'),
				accessor: 'epcPartner',
				maxWidth: 154,
				highlightSearchTerm: true,
				Cell: ({ row: { original: project } }) =>
					project.epcPartner?.name ? (
						<Link
							color={colors.primary.main}
							fontSize="14px"
							to={`/epc-partners/details/${project?.epcPartner?.id}`}
							onClick={e => e.stopPropagation()}
							style={{
								textDecorationColor: project?.epcPartner?.internalQualificationPassed
									? colors.common.black
									: colors.error.main,
								color: project?.epcPartner?.internalQualificationPassed ? colors.common.black : colors.error.main,
							}}
						>
							<EpcPartnerNameWithTier
								name={project.epcPartner?.name}
								tier={project.epcPartner?.internalQualificationPassed}
								hasPoints={false}
								showIcon={false}
							/>
						</Link>
					) : (
						'-'
					),
			},
			{
				Header: t('Contract'),
				accessor: 'contractType',
				maxWidth: 114,
				highlightSearchTerm: true,
				Cell: ({ row: { original: project } }) => (
					<TableCellText>{project.contractType?.name ?? '-'}</TableCellText>
				),
			},
			{
				Header: t('Duration'),
				accessor: 'duration',
				maxWidth: 114,
				Cell: ({ row: { original: project } }) => (
					<TableCellText>{project.contractDuration ?? '-'}</TableCellText>
				),
			},
			{
				Header: t('SaaS date'),
				accessor: 'clientContract.saasSignatureDate',
				maxWidth: 114,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				Cell: ({ row: { original: project } }) => (
					<TableCellText>
						{project.clientContract?.saasSignatureDate ? project.clientContract?.saasSignatureDate : '-'}
					</TableCellText>
				),
			},
			{
				Header: t('COD'),
				accessor: 'commercialOperationsDate',
				maxWidth: 114,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				Cell: ({ row: { original: project } }) => (
					<TableCellText>
						{project.commercialOperationsDate ? project.commercialOperationsDate : '-'}
					</TableCellText>
				),
			},
			{
				Header: t('IRR'),
				accessor: 'irr',
				maxWidth: 64,
				cellStyle: {
					justifyContent: 'flex-end',
				},
				Cell: ({ row: { original: project } }) => (
					<TableCellText>{project.irr ? Big(project.irr).times(100).toString() : '-'}</TableCellText>
				),
			},
			{
				Header: t('Sales channel'),
				accessor: 'salesChannel',
				width: 130,
				cellStyle: {
					whiteSpace: 'flex-end',
				},
				Cell: ({ row: { original: project } }) => (
					<TableCellText>{project.salesChannel?.name ?? '-'}</TableCellText>
				),
			},
			{
				Header: t('Industry'),
				accessor: 'industry',
				maxWidth: 144,
				cellStyle: {
					whiteSpace: 'flex-end',
				},
				Cell: ({ row: { original: project } }) => (
					<TableCellText>{project.client?.industry?.name ?? '-'}</TableCellText>
				),
			},
			{
				Header: t('Updated'),
				accessor: 'lastModifiedDate',
				maxWidth: 94,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				Cell: ({ row: { original: project } }) =>
					project.lastModifiedDate ? (
						<TableCellText tooltipContent={formatDate(project?.lastModifiedDate, 'EEEE dd/MM/yyyy HH:mm')}>
							<ReactTimeAgo date={new Date(project?.lastModifiedDate)} timeStyle="round" tooltip={false} />
						</TableCellText>
					) : (
						'-'
					),
			},
			{
				Header: '\u00A0',
				accessor: 'comment',
				maxWidth: 37,
				Cell: ({ row: { original: project } }) => (
					<Button
						medium
						onClick={e => {
							handleCommentBtnClick(e, project);
						}}
						icon="forum"
						label="Project Details - Comments Button"
					/>
				),
			},
		],
		[t, totals, totalItemsCount, handleCommentBtnClick],
	);

	const data = useMemo(() => projects, [projects]);

	const itemsHash = useMemo(() => JSON.stringify({ filters, sortBy }), [filters, sortBy]);

	const handleRowClick = useCallback(
		row => {
			if (!window.getSelection().toString()) {
				dispatch(push(`/projects/details/${row.original.id}`));
			}
		},
		[dispatch],
	);

	// Fallback to default row id getter
	const getRowId = useCallback(
		(row, relativeIndex, parent) => (parent ? [parent.id, relativeIndex].join('.') : relativeIndex),
		[],
	);

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		getTableFooterProps,
		getVirtualizedTableBodyProps,
		headerGroups,
		footerGroups,
		rows,
		prepareRow,
	} = useStandardTable(
		{
			data,
			columns,
			defaultSortBy,
			searchTerm: searchQuery,
			onRowClick: handleRowClick,
			virtualization: {
				totalRowsCount: totalItemsCount,
				rowsHash: itemsHash,
				loadMoreRows: handleLoadMoreRows,
			},
			getRowId,
			getCustomRowStyles: () => ({
				cursor: 'default',
			}),
		},
		useClickableTableRow,
		useCustomCellStyles,
		useSortBy,
		useSortingQueryParameter,
		useScrollableTableBody,
		useVirtualizedTableBody,
		useCustomRowStyle,
	);

	return (
		<Wrapper hasOpenFilter={hasOpenFilter}>
			<DefaultTable
				getTableProps={getTableProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				getTableFooterProps={getTableFooterProps}
				getVirtualizedTableBodyProps={getVirtualizedTableBodyProps}
				rows={rows}
				headerGroups={headerGroups}
				footerGroups={footerGroups}
				prepareRow={prepareRow}
				isLoading={isLoading || rows.length !== totalItemsCount}
				showNoResultsFound
			/>
		</Wrapper>
	);
};

Stage.defaultProps = {
	stage: {},
};

Stage.propTypes = {
	stage: PropTypes.shape({
		completedPercentage: PropTypes.string,
		stageStatus: PropTypes.string,
		openedAt: PropTypes.string,
		firstCompletedAt: PropTypes.string,
	}),
};

ProjectListTable.defaultProps = {
	row: {},
};

ProjectListTable.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.func,
	}),
};

export default ProjectListTable;
