import Tile from 'Common/components/Tile/Tile';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import Button from 'Common/components/buttons/Button';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import StatusPill from 'Common/components/StatusPill';
import colors from 'Application/theme/colors';
import KeyValueVisualization, { KeyValueVisualizationWrapper } from 'Common/components/KeyValueVisualization';
import UNITS from 'Common/constants/units';
import crudModes from 'Common/constants/crudModes';
import ClientContractOverlay from '../Overlay/ClientContractOverlay';
import { contractTypes } from '../../constants/contractTypes';
import { PPA_TARIFS } from '../../constants/ppaTarifs';
import ClientContractSignModal from '../Modals/ClientContractSignModal';
import Big from 'big.js';
import formatNumberWithoutFixing from 'Common/utils/formatNumberWithoutFixing';

const StyledTile = styled(Tile)`
	${({ $hasClientContract }) =>
		$hasClientContract &&
		css`
			cursor: pointer;
		`}
`;

const ClientContractFieldsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	${KeyValueVisualizationWrapper} {
		${({ $colNumber }) =>
			$colNumber === 5 ? 'width: 20%; min-width: 200px;' : 'width: 25%; min-width: 250px;'}
	}
`;

const StyledStatusPill = styled(StatusPill)`
	width: fit-content;
`;

// eslint-disable-next-line complexity
const ClientContractTile = ({ project, handleUpdateProject }) => {
	const { t } = useTranslation();
	const [isCreateOverlayOpen, setIsCreateOverlayOpen] = useState(false);
	const [isEditOverlayOpen, setIsEditOverlayOpen] = useState(false);
	const [isSignModalOpen, setIsSignModalOpen] = useState(false);
	const [isViewOverlayOpen, setIsViewOverlayOpen] = useState(false);

	const clientContract = useMemo(() => project?.clientContract || null, [project?.clientContract]);

	const currencySign = price => (price?.value ? (price?.currency === 'EUR' ? '€' : price?.currency) : null);

	const isContractSigned = useMemo(
		() => Boolean(clientContract?.saasSignatureDate),
		[clientContract?.saasSignatureDate],
	);
	const isAllowedToEditHistory = isAuthorized([
		roles.ADMIN,
		roles.HEAD_OF_SALES,
		roles.SALES,
		roles.ASSET_MANAGER,
		roles.HEAD_OF_ASSET_MANAGEMENT,
	]);
	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.MANAGEMENT,
		roles.HEAD_OF_SALES,
		roles.SALES,
		roles.HEAD_OF_TECH,
		roles.FINANCE,
		roles.PM_TECHNICAL,
		roles.ASSET_MANAGER,
		roles.HEAD_OF_ASSET_MANAGEMENT,
	]);

	const hasClientContract = useMemo(() => Boolean(clientContract), [clientContract]);

	const isLease = useMemo(
		() => clientContract?.contractType?.name === contractTypes.Lease,
		[clientContract?.contractType?.name],
	);
	const isPPA = useMemo(
		() => clientContract?.contractType?.name === contractTypes.PPA,
		[clientContract?.contractType?.name],
	);
	const isPpaTarifTypeFixed = useMemo(
		() => clientContract?.ppaTariffType?.ppaTariffType === PPA_TARIFS.FIXED,
		[clientContract?.ppaTariffType?.ppaTariffType],
	);

	const handleCreateBtnClick = () => setIsCreateOverlayOpen(true);

	const handleTileClick = () => {
		if (hasClientContract) {
			if ((isContractSigned && !isAllowedToEditHistory) || !isAuthorizedToEdit) {
				setIsViewOverlayOpen(true);
			} else {
				setIsEditOverlayOpen(true);
			}
		}
	};

	const handleSignBtnClick = e => {
		e.stopPropagation();
		setIsSignModalOpen(true);
	};

	const handleExportClick = e => {
		e.stopPropagation();
		window.open(`/api/projects/${project.id}/export-client-contract`, '_blank');
	};

	return (
		<>
			<StyledTile
				title={t('SaaS contracts')}
				rightComponent={
					hasClientContract ? (
						<>
							<Button label="Export SaaS Contract" onClick={handleExportClick} text={t('Export')} />
							{isAuthorizedToEdit && !isContractSigned && (
								<TileActionButton icon="signContract" onClick={handleSignBtnClick} />
							)}
						</>
					) : (
						isAuthorizedToEdit && <TileActionButton icon="addRounded" onClick={handleCreateBtnClick} />
					)
				}
				$hasClientContract={hasClientContract}
				onClick={handleTileClick}
			>
				{hasClientContract ? (
					<ClientContractFieldsWrapper $colNumber={isPPA && !isPpaTarifTypeFixed ? 5 : 4}>
						<KeyValueVisualization
							title={t('System size')}
							value={
								clientContract?.systemSize
									? formatNumberWithoutFixing(clientContract?.systemSize)
									: clientContract?.systemSize
							}
						/>
						<KeyValueVisualization title={t('Contract Type')} value={clientContract?.contractType?.name} />
						{isLease ? (
							<KeyValueVisualization
								title={t('Lease Fee')}
								prefix={() => currencySign(clientContract?.monthlyLeaseRate)}
								value={
									clientContract?.monthlyLeaseRate?.value
										? formatNumberWithoutFixing(clientContract?.monthlyLeaseRate?.value)
										: clientContract?.monthlyLeaseRate?.value
								}
							/>
						) : isPPA && isPpaTarifTypeFixed ? (
							<KeyValueVisualization
								prefix={() => currencySign(clientContract?.ppaPricePerKwh)}
								title={t('PPA Tariff')}
								value={
									clientContract?.ppaPricePerKwh?.value
										? formatNumberWithoutFixing(clientContract?.ppaPricePerKwh?.value)
										: clientContract?.ppaPricePerKwh?.value
								}
							/>
						) : (
							<>
								<KeyValueVisualization
									prefix={() => currencySign(clientContract?.floorTariff)}
									title={t('Floor Tariff')}
									value={
										clientContract?.floorTariff?.value
											? formatNumberWithoutFixing(clientContract?.floorTariff?.value)
											: clientContract?.floorTariff?.value
									}
								/>
								<KeyValueVisualization
									prefix={() => currencySign(clientContract?.ceilingTariff)}
									title={t('Ceiling Tariff')}
									value={
										clientContract?.ceilingTariff?.value
											? formatNumberWithoutFixing(clientContract?.ceilingTariff?.value)
											: clientContract?.ceilingTariff?.value
									}
								/>
							</>
						)}
						<KeyValueVisualization
							title={t('Projected IRR')}
							value={clientContract?.projectedIrr ? Big(clientContract.projectedIrr).times(100).toString() : ''}
							unit={UNITS.PERCENT}
						/>
					</ClientContractFieldsWrapper>
				) : (
					<StyledStatusPill color={colors.common.brown}>Not created</StyledStatusPill>
				)}
			</StyledTile>

			{isSignModalOpen && (
				<ClientContractSignModal
					isOpen={isSignModalOpen}
					onClose={() => setIsSignModalOpen(false)}
					clientContractId={clientContract?.id}
					contractNumber={clientContract?.contractNumber || project?.externalId}
					onFormSubmit={handleUpdateProject}
				/>
			)}

			{isCreateOverlayOpen && (
				<ClientContractOverlay
					mode={crudModes.CREATE}
					isOpen={isCreateOverlayOpen}
					onClose={() => setIsCreateOverlayOpen(false)}
					onFormSubmit={handleUpdateProject}
					project={project}
				/>
			)}

			{isEditOverlayOpen && (
				<ClientContractOverlay
					mode={crudModes.EDIT}
					isOpen={Boolean(isEditOverlayOpen)}
					onClose={() => setIsEditOverlayOpen(null)}
					onFormSubmit={handleUpdateProject}
					project={project}
					contractId={clientContract?.id}
				/>
			)}

			{isViewOverlayOpen && (
				<ClientContractOverlay
					mode={crudModes.VIEW}
					isOpen={Boolean(isViewOverlayOpen)}
					onClose={() => setIsViewOverlayOpen(null)}
					project={project}
					contractId={clientContract?.id}
				/>
			)}
		</>
	);
};

ClientContractTile.defaultProps = {
	handleUpdateProject: () => {},
	project: {},
};

ClientContractTile.propTypes = {
	project: PropTypes.shape({
		id: PropTypes.number,
		externalId: PropTypes.string,
		clientContract: PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		epcOffers: PropTypes.arrayOf(PropTypes.shape({})),
		systemSizeKwp: PropTypes.number,
		kwhToKwpRel: PropTypes.number,
	}),
	handleUpdateProject: PropTypes.func,
};

export default ClientContractTile;
