const convertAddressTileFormValues = values => ({
	address: {
		apartmentBuilding: values?.apartmentBuilding,
		cityRegion: values?.cityRegion,
		line1: values?.line1,
		line2: values?.line2,
		postCodeZip: values?.postCodeZip,
	},
});

export default convertAddressTileFormValues;
