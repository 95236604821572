import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import sizes from 'Application/theme/sizes';
import colors from 'Application/theme/colors';

import getStepById from 'PMWorkflow/api/getStepById';
import getProjectById from 'Projects/api/getProjectById';
import ActivePill from 'PMWorkflow/components/Step/DashboardStep/Pills/ActivePill';
import DisabledPill from 'PMWorkflow/components/Step/DashboardStep/Pills/DisabledPill';
import RemovingPill from 'PMWorkflow/components/Step/DashboardStep/Pills/RemovingPill';

import Icon from 'Common/components/icons/Icon';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import UsersPreview from 'Common/components/usersPreview/UsersPreview';
import CustomStatusDropdown from 'Common/components/StatusDropdown/StatusDropdown';

import {
	updateSidePanelContentData,
	updateSidePanelData,
	updateSidePanelHeaderData,
} from 'Application/reducers/reduxSidePanel';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { SIDE_PANEL_CONTENT_TYPES } from 'Common/components/SidePanel/constants/sidePanelContentTypes';

const ActivityContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;
`;

const TitleContainer = styled(Grid)`
	display: flex;
	flex-direction: row;
	width: calc(100% - ${({ $hasLongerActions }) => ($hasLongerActions ? '75px' : '30px')});
	align-items: center;
`;

const ActionsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: ${({ $isRemoving }) => ($isRemoving ? 'flex-start' : 'flex-end')};
	align-items: center;
	gap: ${sizes.spacing(2)};
	width: 75px;
`;

const Title = styled(Box)`
	color: ${colors.text.greyDark};
	font-size: 14px;
	font-weight: 300;
	display: flex;
	align-items: center;
	margin-right: 8px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;

const StepTitle = styled(Grid)`
	color: ${colors.text.greyDark};
	font-size: 16px;
	font-weight: 700;
	align-items: center;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;

const Name = styled.span`
	white-space: nowrap;
	display: flex;
	align-items: center;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;

const StyledIcon = styled(Icon)`
	width: 30px !important;
	height: 30px !important;
`;

const StyledNoUserContainer = styled.div`
	display: flex;
	align-items: center;
	padding-right: 4px;
`;

const DashboardStep = ({ step, project, setRemovingSteps, updateProject }) => {
	const [isPaused, setIsPaused] = useState(false);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [status, setStatus] = useState(step.status);
	const sidePanelData = useSelector(state => state.sidePanel);

	const isCurrentStepSidePanel = useMemo(
		() => sidePanelData.isOpen && sidePanelData.contentData?.step?.id === step.id,
		[sidePanelData.contentData?.step?.id, sidePanelData.isOpen, step.id],
	);

	const openStepStatusDropdownId = useSelector(state => state.common.openStepStatusDropdownId);
	const isDropdownVisible = useMemo(
		() => openStepStatusDropdownId === step.id,
		[openStepStatusDropdownId, step.id],
	);

	const abortController = useAbortController();

	useEffect(() => {
		setIsPaused(isCurrentStepSidePanel);
	}, [isCurrentStepSidePanel]);

	const handleSetIsPaused = value => {
		if (!isCurrentStepSidePanel) {
			setIsPaused(value);
		}
	};

	const onStatusUpdate = useCallback(
		async newStatus => {
			try {
				typeof newStatus === 'string' && setStatus(newStatus);
				await updateProject();
			} catch (error) {
				showToastError(error);
			}
		},
		[updateProject],
	);

	const generateProjectData = useCallback(async () => {
		try {
			const projectData = await getProjectById(abortController.signal, project.id, true).then(res => res.data);
			const isProjectOnHold = !(
				projectData?.condition?.condition === 'OPERATION' || projectData?.condition?.condition === 'IN_PROGRESS'
			);

			const projectOnHoldStatus = projectData?.condition?.condition
				.split('_')
				.join(' ')
				.toLowerCase()
				.replace(/(?:^|\s)\S/g, function (a) {
					return a.toUpperCase();
				});

			const projectHeaderData = {
				to: '/projects',
				isBreadcrumb: true,
				breadCrumb: 'Projects',
				activeBreadcrumb: `${projectData.externalId} - ${
					projectData?.client?.name ? projectData?.client?.name : t('Unknown client')
				} ${projectData?.description ? ` (${projectData?.description})` : ''}`,
				toActiveBreadcrumb: `/projects/details/${project.id}`,
			};

			return {
				projectData,
				projectHeaderData,
				isProjectOnHold,
				projectOnHoldStatus,
			};
		} catch (error) {
			showToastError(error);
		}
	}, [project, t, abortController.signal]);

	const handleStepClick = () => {
		if (isCurrentStepSidePanel) return;

		dispatch(
			updateSidePanelData({
				isOpen: true,
				type: SIDE_PANEL_CONTENT_TYPES.DASHBOARD_STEP,
				isLoading: true,
				contentData: {
					project: {
						id: project.id,
					},
					step: {
						id: step.id,
					},
					showHistory: false,
				},
			}),
		);
	};

	const setSidePanelStepData = useCallback(async () => {
		try {
			dispatch(updateSidePanelData({ isSoftLoading: true }));

			const stepData = await getStepById(abortController.signal, step.id).then(res => res.data);
			const { projectData, projectHeaderData, isProjectOnHold, projectOnHoldStatus } =
				await generateProjectData();

			dispatch(updateSidePanelHeaderData(projectHeaderData));

			dispatch(
				updateSidePanelContentData({
					project: projectData,
					step: { ...step, ...stepData },
					onDataChange: onStatusUpdate,
					dropdownProps: {
						isProjectOnHold,
						projectOnHoldStatus,
						activityCanBeCompleted: true,
					},
				}),
			);
			dispatch(
				updateSidePanelData({
					isLoading: false,
					isSoftLoading: false,
				}),
			);
		} catch (error) {
			showToastError(error);
		}
	}, [dispatch, generateProjectData, onStatusUpdate, abortController.signal, step]);

	const handleMouseEnter = () => {
		setIsPaused(isCurrentStepSidePanel || true);
	};

	const handleMouseLeave = () => {
		setIsPaused(isCurrentStepSidePanel || false);
	};

	useEffect(() => {
		if (isCurrentStepSidePanel) {
			setSidePanelStepData();
		}
	}, [isCurrentStepSidePanel, setSidePanelStepData]);

	useEffect(() => {
		setStatus(step.status);
	}, [step.status]);

	const StepContainer = step.isDisabled ? DisabledPill : step.isRemoving ? RemovingPill : ActivePill;

	return (
		<StepContainer
			item
			container
			xs={12}
			key={step.id}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			$isNewlyAdded={step.isNewlyAdded}
			$isMarked={isCurrentStepSidePanel}
			$statusDropdownVisible={isDropdownVisible}
			data-dashboard-section-marker
			onClick={handleStepClick}
			{...(step.isRemoving
				? {
						step: {
							...step,
							status,
						},
						projectId: project.id,
						isPaused,
						setRemovingSteps,
						onStatusUpdate,
						setIsPaused: handleSetIsPaused,
						isMarked: isCurrentStepSidePanel || isPaused,
				  }
				: {})}
		>
			<ActivityContainer className="fade-section">
				<TitleContainer $hasLongerActions={!step.hideResponsiblePerson && step.showActions}>
					<Grid item xs={4} sm={3} md={2}>
						<Name>
							<Title>{step.activityTitle}</Title>
						</Name>
					</Grid>
					<Grid item xs={8} sm={9} md={10}>
						<Name>
							<StepTitle>{step.title}</StepTitle>
						</Name>
					</Grid>
				</TitleContainer>

				<ActionsContainer $isRemoving={step.isRemoving}>
					{!step.hideResponsiblePerson ? (
						step.responsiblePerson ? (
							<UsersPreview
								users={step.responsiblePerson}
								popupProps={{
									$alignment: 'right',
								}}
							/>
						) : (
							<HoverTooltip
								title="Please select the Responsible people for this project."
								placement="left"
								arrowplacement="right"
							>
								<StyledNoUserContainer>
									<StyledIcon icon="noUser" />
								</StyledNoUserContainer>
							</HoverTooltip>
						)
					) : null}
					{!step.isRemoving && (
						<CustomStatusDropdown
							projectId={project.id}
							step={{ ...step, status }}
							onStatusUpdate={onStatusUpdate}
							activityCanBeCompleted={true}
						/>
					)}
				</ActionsContainer>
			</ActivityContainer>
		</StepContainer>
	);
};

DashboardStep.defaultValues = {
	step: {},
	project: {},
	updateProject: () => {},
	setRemovingSteps: () => {},
};

DashboardStep.propTypes = {
	step: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		status: PropTypes.string,
		isDisabled: PropTypes.bool,
		isRemoving: PropTypes.bool,
		isNewlyAdded: PropTypes.bool,
		hideResponsiblePerson: PropTypes.bool,
		showActions: PropTypes.bool,
		title: PropTypes.string,
		activityTitle: PropTypes.string,
		responsiblePerson: PropTypes.shape({}),
	}).isRequired,
	project: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }).isRequired,
	updateProject: PropTypes.func.isRequired,
	setRemovingSteps: PropTypes.func.isRequired,
};

export default DashboardStep;
