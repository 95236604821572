import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import crudModes from 'Common/constants/crudModes';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { Grid } from '@mui/material';
import useEpcPartnerFormInitialValues from './hooks/useEpcPartnerFormInitialValues';
import useEpcPartnerFormValidationSchema from './hooks/useEpcPartnerFormValidationSchema';
import createEpcPartner from 'EpcPartner/api/createEpcPartner';
import convertFormValuesToRequestData from './utils/convertFormValuesToRequestData';
import updateEpcPartner from 'EpcPartner/api/updateEpcPartner';
import { omit } from 'lodash';
import loadCountryOptions from 'Country/utils/loadCountryOptions';
import { OverlayButtons } from 'Common/components/modals/Overlay';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const EpcPartnerForm = ({ data, mode, onSubmit, onCancel, onDirty }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();

	const initialValues = useEpcPartnerFormInitialValues(data);
	const validationSchema = useEpcPartnerFormValidationSchema();

	const newLoad = async () =>
		(await loadCountryOptions()).filter(item => item.value !== 11432 && item.value !== 12035);

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleChange,
		handleBlur,
		setFieldValue,
		setFieldError,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				let response;

				if (mode === crudModes.CREATE) {
					response = await createEpcPartner(abortController.signal, convertFormValuesToRequestData(values));

					notify(t('EPC Partner created successfully.'), {
						type: toast.TYPE.SUCCESS,
					});
				} else if (mode === crudModes.EDIT) {
					response = await updateEpcPartner(abortController.signal, {
						...omit(data, ['tier']),
						...convertFormValuesToRequestData(values),
					});

					notify(t('EPC Partner saved successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				}

				onSubmit(response.data);

				// Re-set all fields and mark them as not touched
				resetForm({ values });
				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form onSubmit={handleSubmit}>
			<Grid container columnSpacing={20}>
				<Grid item xs={12} md={6}>
					<FormField>
						<FormikInput
							id="name"
							name="name"
							label={t('Name')}
							value={values.name}
							error={errors.name}
							touched={touched.name}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="website"
							name="website"
							label={t('Website')}
							value={values.website}
							error={errors.website}
							touched={touched.website}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormField>
						<FormikSelect
							isAsync
							isMulti
							id="countryIds"
							name="countryIds"
							label={t('Countries')}
							value={values.countryIds}
							error={errors.countryIds}
							touched={touched.countryIds}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={newLoad}
							menuPosition="fixed"
							menuPlacement="bottom"
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="apartmentBuilding"
							name="apartmentBuilding"
							label={t('Apartment / Building')}
							value={values.apartmentBuilding}
							error={errors.apartmentBuilding}
							touched={touched.apartmentBuilding}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="line1"
							name="line1"
							label={t('Address line 1')}
							value={values.line1}
							error={errors.line1}
							touched={touched.line1}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="line2"
							name="line2"
							label={t('Address line 2')}
							value={values.line2}
							error={errors.line2}
							touched={touched.line2}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="cityRegion"
							name="cityRegion"
							label={t('City / Region')}
							value={values.cityRegion}
							error={errors.cityRegion}
							touched={touched.cityRegion}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="postCodeZip"
							name="postCodeZip"
							label={t('Post code / ZIP')}
							value={values.postCodeZip}
							error={errors.postCodeZip}
							touched={touched.postCodeZip}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
				</Grid>
			</Grid>
			<OverlayButtons
				onCancel={onCancel}
				isLoading={isLoading}
				label="EPC Partner Form"
				submitButtonText={`${mode === crudModes.CREATE ? 'Create' : 'Save'} EPC Partner`}
			/>
		</Form>
	);
};

EpcPartnerForm.defaultProps = {
	data: null,
};

EpcPartnerForm.propTypes = {
	data: PropTypes.shape({
		localizations: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
	}),
	mode: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
};

export default EpcPartnerForm;
