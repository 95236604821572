import { useMemo } from 'react';

const fallbackValues = {
	epcPartner: null,
};

const useEpcPartnerFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			epcPartner: data.epcPartner
				? {
						value: data.epcPartner.id,
						label: data.epcPartner.name,
						totalPoints: data.epcPartner.totalPoints,
						tier: data.epcPartner.tier,
				  }
				: null,
		}),
		[data],
	);

export default useEpcPartnerFormInitialValues;
