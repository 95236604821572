import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import crudModes from 'Common/constants/crudModes';
import ModalForm from 'Common/components/form/ModalForm';

import TrancheForm from '../TrancheForm/TrancheForm';

import getTrancheById from 'Campaigns/api/getTrancheById';

import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';

const EditTrancheModal = ({ isOpen, onClose, onFormSubmit, trancheId }) => {
	const [data, setData] = useState(null);
	const { t } = useTranslation();

	const abortController = useAbortController();
	useEffect(() => {
		(async () => {
			try {
				const response = await getTrancheById(abortController.signal, trancheId, false);
				setData(response.data);
			} catch (e) {
				showToastError(e, t('An error occured while trying to fetch tranche data'));
			}
		})();
	}, [trancheId, abortController.signal, t]);

	return (
		<ModalForm
			label="tranche-edit"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			isLoadingContent={!data}
			heading={t('Edit Tranche')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<TrancheForm
					mode={crudModes.EDIT}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
					data={data}
				/>
			)}
		</ModalForm>
	);
};

EditTrancheModal.defaultProps = {
	trancheId: 0,
	isOpen: false,
	onClose: () => {},
	onFormSubmit: () => {},
};

EditTrancheModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	trancheId: PropTypes.number,
	onFormSubmit: PropTypes.func,
};

export default EditTrancheModal;
