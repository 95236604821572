export const documentTypes = {
	BOM_BOQ: 'BOM_BOQ',
	PV_LAYOUT: 'PV_LAYOUT',
	PV_SIMULATION_REPORT: 'PV_SIMULATION_REPORT',
	SITE_OBSERVATION_REPORT: 'SITE_OBSERVATION_REPORT',
	SLD_COMMS: 'SLD_COMMS',
	SLD_DC_AC_POWER: 'SLD_DC_AC_POWER',
	TIMELINE_SCHEDULE: 'TIMELINE_SCHEDULE',
	ELECTRICITY_BILL: 'ELECTRICITY_BILL',
};

export const formattedDocumentTypes = {
	[documentTypes.BOM_BOQ]: 'BOM / BOQ',
	[documentTypes.PV_LAYOUT]: 'PV Layout',
	[documentTypes.ELECTRICITY_BILL]: 'Electricity bill',
	[documentTypes.PV_SIMULATION_REPORT]: 'PV simulation report',
	[documentTypes.SITE_OBSERVATION_REPORT]: 'Site observation report',
	[documentTypes.SLD_COMMS]: 'SLD – Comms',
	[documentTypes.SLD_DC_AC_POWER]: 'SLD  - DC & AC power',
	[documentTypes.TIMELINE_SCHEDULE]: 'Timeline / schedule',
};

export const categories = {
	SCHEDULE_AND_OFFER: 'SCHEDULE_AND_OFFER',
	TECHNICAL_DOCUMENTATION: 'TECHNICAL_DOCUMENTATION',
};

export const formattedCategories = {
	[categories.SCHEDULE_AND_OFFER]: 'Schedule & offer',
	[categories.TECHNICAL_DOCUMENTATION]: 'Technical documentation',
};

export const typesByCategory = {
	[documentTypes.BOM_BOQ]: categories.SCHEDULE_AND_OFFER,
	[documentTypes.PV_LAYOUT]: categories.TECHNICAL_DOCUMENTATION,
	[documentTypes.ELECTRICITY_BILL]: categories.TECHNICAL_DOCUMENTATION,
	[documentTypes.PV_SIMULATION_REPORT]: categories.TECHNICAL_DOCUMENTATION,
	[documentTypes.SITE_OBSERVATION_REPORT]: categories.SCHEDULE_AND_OFFER,
	[documentTypes.SLD_COMMS]: categories.TECHNICAL_DOCUMENTATION,
	[documentTypes.SLD_DC_AC_POWER]: categories.TECHNICAL_DOCUMENTATION,
	[documentTypes.TIMELINE_SCHEDULE]: categories.SCHEDULE_AND_OFFER,
};

export const documentTableOptions = [
	{
		documentTitle: documentTypes.TIMELINE_SCHEDULE,
		description: 'Time schedule or project plan, including the construction schedule.',
		group: categories.SCHEDULE_AND_OFFER,
	},
	{
		documentTitle: documentTypes.BOM_BOQ,
		description: 'Bill Of Materials and Bill Of Quantities.',
		group: categories.SCHEDULE_AND_OFFER,
	},
	{
		documentTitle: documentTypes.SITE_OBSERVATION_REPORT,
		description:
			'Observations regarding site conditions.  Highlight areas of attention including poor security, poor existing electrical works, poor roof and structural conditions.  Proof using photos or a simple map is recommended.',
		group: categories.SCHEDULE_AND_OFFER,
	},
	{
		documentTitle: documentTypes.ELECTRICITY_BILL,
		description: 'The client’s electricity bill(s), ideally covering the last 6-12 months.',
		group: categories.TECHNICAL_DOCUMENTATION,
	},
	{
		documentTitle: documentTypes.PV_SIMULATION_REPORT,
		description:
			'Preferably a PVSyst report. Ensure correct model of solar panels and a P90 evaluation is used.',
		group: categories.TECHNICAL_DOCUMENTATION,
	},
	{
		documentTitle: documentTypes.SLD_DC_AC_POWER,
		description:
			'Single line diagram showing interconnectivity of all DC & AC components. Include grid connection points & utility meters.',
		group: categories.TECHNICAL_DOCUMENTATION,
	},
	{
		documentTitle: documentTypes.SLD_COMMS,
		description:
			'Single line diagram showing interconnectivity of all monitoring components. Incclude dataloggers, meters, safety features, and router.',
		group: categories.TECHNICAL_DOCUMENTATION,
	},
	{
		documentTitle: documentTypes.PV_LAYOUT,
		description:
			'Diagram showing the physical layout of the solar panels.  Ensure the number of panels and inverters is correct.',
		group: categories.TECHNICAL_DOCUMENTATION,
	},
];

export const inquiryDocumentOptions = [
	{
		documentTitle: documentTypes.ELECTRICITY_BILL,
		description: 'The client’s electricity bill(s), ideally covering the last 12 months.',
	},
	{
		documentTitle: documentTypes.PV_SIMULATION_REPORT,
		description:
			'Preferably a PVSyst report. Ensure correct model of solar panels and a P90 evaluation is used.',
	},
];
