import styled from 'styled-components/macro';
import React from 'react';
import { css } from 'styled-components';
import { PropTypes } from 'prop-types';

import HoverTooltip from 'Common/components/tooltip/HoverTooltip';

import colors from 'Application/theme/colors';

const TextContainer = styled.div`
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;

	${({ disabled = true }) =>
		disabled &&
		css`
			cursor: not-allowed;
			color: ${colors.text.disabled};
		`}
`;

const TableCellText = ({ children, tooltipContent = children, isDisabled }) => (
	<HoverTooltip arrow arrowplacement="bottom" title={tooltipContent} placement="top">
		<TextContainer disabled={isDisabled}>{children}</TextContainer>
	</HoverTooltip>
);

TableCellText.defaultProps = {
	children: null,
	tooltipContent: null,
	isDisabled: false,
};

TableCellText.propTypes = {
	children: PropTypes.node,
	tooltipContent: PropTypes.node,
	isDisabled: PropTypes.bool,
};

export default TableCellText;
