import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { useTranslation } from 'react-i18next';
import { useSortBy } from 'react-table';
import styled from 'styled-components/macro';

import { DefaultTable } from 'Common/components/table/table';
import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import { useScrollableTableBody } from 'Common/hooks/table/plugin-hooks/useScrollableTableBody';
import { useSortingQueryParameter } from 'Common/hooks/table/plugin-hooks/useSortingQueryParameter';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import showToastError from 'Common/utils/showToastError';
import axios from 'axios';
import getEntities from 'Entities/api/getEntities';
import UsersPreview from 'Common/components/usersPreview/UsersPreview';
import PropTypes from 'prop-types';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import TableCellText from 'Common/components/table/TableCellText';
import Link from 'Common/components/Link';
import useUniqueAbortSignal from 'Common/hooks/useUniqueAbortSignal';
import { parseISO } from 'date-fns';

const Wrapper = styled.div`
	position: relative;
	pointer-events: ${({ hasOpenFilter }) => (hasOpenFilter ? 'none' : 'all')};
`;

const defaultSortBy = {
	id: 'dateOfRegistration',
	desc: true,
};

const EntitiesListTable = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const hasOpenFilter = Boolean(useSelector(state => state?.tables?.entities?.openedFilter));
	const [totalEntitiesCount, setTotalEntitiesCount] = useState(0);

	const sortByQueryParam = useQueryParameter('sortBy');
	const sortBy = sortByQueryParam ?? defaultSortBy;

	const filters = useQueryParameter('filters');

	const searchQuery = useQueryParameter('searchQuery');

	const getUniqueSignal = useUniqueAbortSignal();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Company name'),
				accessor: 'name',
				Cell: (() => {
					const Cell = ({ row: { original: entity } }) => (
						<Link color="#00b4d5" to={`/entities/details/${entity?.id}`} fontSize="14px">
							{entity?.name}
						</Link>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
								name: PropTypes.string,
							}),
						}).isRequired,
					};
					return Cell;
				})(),
				Footer: totalEntitiesCount,
			},
			{
				Header: t('Country'),
				accessor: 'country.isoCode',
			},
			{
				Header: t('Abbreviation'),
				accessor: 'abbreviation',
			},
			{
				Header: t('Date of registration'),
				accessor: 'dateOfRegistration',
				width: 162,
				Cell: ({ row: { original: entity } }) => {
					const manualFormatDate = date => {
						const day = date.getDate();
						const month = date.getMonth() + 1;
						const year = date.getFullYear();
						return `${day}/${month}/${year}`;
					};

					return (
						<TableCellText>
							{entity.dateOfRegistration ? manualFormatDate(parseISO(entity.dateOfRegistration)) : '-'}
						</TableCellText>
					);
				},
			},
			{
				Header: t('Directors'),
				Cell: ({ row: { original: entity } }) => <UsersPreview users={entity?.directors} useArray />,
				customBodyCellContentStyles: {
					overflow: 'visible',
				},
			},
			{
				Header: t('Registration number'),
				accessor: 'registrationNumber',
				width: 174,
			},
			{
				Header: t('Tax number'),
				accessor: 'taxNumber',
			},
			{
				Header: t('VAT number'),
				accessor: 'vatNumber',
			},
		],
		[t, totalEntitiesCount],
	);

	const [entities, setEntities] = useState([]);

	const [isLoading, setIsLoading] = useState(true);

	// Update the state when the sorting / filter / search query changes
	useEffect(() => {
		(async () => {
			setIsLoading(true);

			try {
				const response = await getEntities(
					getUniqueSignal('get-entities'),
					{
						page: 1,
						itemsPerPage: 9999,
						sortBy: sortBy.id,
						sortDirection: sortBy.desc ? 'DESC' : 'ASC',
					},
					filters,
					searchQuery,
				);

				setTotalEntitiesCount(response.data.totalElements);
				setEntities(response.data.content);
				setIsLoading(false);
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setEntities([]);
					setIsLoading(false);
				}
			}
		})();
	}, [sortBy, filters, searchQuery, getUniqueSignal]);

	const data = useMemo(
		() =>
			entities.map(entity => ({
				...entity,
			})),
		[entities],
	);

	const handleRowClick = row => {
		if (!window.getSelection().toString()) {
			dispatch(push(`/entities/details/${row.original.id}`));
		}
	};

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		getTableFooterProps,
		headerGroups,
		footerGroups,
		rows,
		prepareRow,
	} = useStandardTable(
		{
			data,
			columns,
			searchTerm: searchQuery,
			onRowClick: handleRowClick,
			defaultSortBy,
		},
		useCustomCellStyles,
		useClickableTableRow,
		useSortBy,
		useSortingQueryParameter,
		useScrollableTableBody,
	);

	return (
		<Wrapper hasOpenFilter={hasOpenFilter}>
			<DefaultTable
				getTableProps={getTableProps}
				getTableFooterProps={getTableFooterProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				rows={rows}
				headerGroups={headerGroups}
				footerGroups={footerGroups}
				prepareRow={prepareRow}
				isLoading={isLoading}
				showNoResultsFound
			/>
		</Wrapper>
	);
};

EntitiesListTable.defaultProps = {
	row: {},
};

EntitiesListTable.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.func,
	}),
};

export default EntitiesListTable;
