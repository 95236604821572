const disableArgTypes = args =>
	args.reduce(
		(prev, argName) => ({
			...prev,
			[argName]: {
				table: {
					disable: true,
				},
			},
		}),
		{},
	);

export default disableArgTypes;
