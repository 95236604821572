/* eslint-disable complexity */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import Tile from 'Common/components/Tile/Tile';

import FormikInput from 'Common/components/form/FormikInput';
import FormField from 'Common/components/form/FormField';
import { Grid } from '@mui/material';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import UNITS from 'Common/constants/units';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import formatNumber from 'Common/utils/formatNumber';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';
import Icon from 'Common/components/icons/Icon';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import trimDecimalZeros from 'Common/utils/trimDecimalZeros';
import { contractTypes } from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/ClientContract/constants/contractTypes';
import formatNumberWithoutFixing from 'Common/utils/formatNumberWithoutFixing';

const HoverTooltipWrapper = styled.div`
	padding-bottom: 5px;
	padding-left: 5px;
`;

const RelativeFormField = styled(FormField)`
	position: relative;
`;

const SaaSValueContainer = styled.div`
	position: absolute;
	right: 0;
	bottom: ${sizes.spacing(0.5)};
`;

const SaaSValue = styled.div`
	padding-left: ${sizes.spacing(0.7)};
	color: ${colors.primary.dark};
`;

const SaaSValueSufix = ({ value, exchangeRate, currency }) => {
	const saasValue = useMemo(
		() => trimDecimalZeros(formatNumber((value || 0) / (exchangeRate || 1), 2)),
		[value, exchangeRate],
	);

	return value && exchangeRate ? (
		<SaaSValueContainer>
			<SaaSValue>
				{currency?.value === 'EUR' ? UNITS.EUR : currency?.value} {saasValue}
			</SaaSValue>
		</SaaSValueContainer>
	) : null;
};

SaaSValueSufix.defaultProps = {
	exchangeRate: 1,
	value: 0,
};

SaaSValueSufix.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	exchangeRate: PropTypes.number,
	currency: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
};

const FormatedValue = styled.div`
	display: flex;
	align-items: center;
`;

const ValueWithSaaSCurrencySufix = ({ value, exchangeRate, currency }) => {
	const saasValue = trimDecimalZeros(formatNumber((value || 0) / (exchangeRate || 1), 2));
	return value ? (
		<FormatedValue>
			{formatNumberWithoutFixing(value)}
			{exchangeRate ? (
				<SaaSValue>
					{' '}
					/ {currency?.value === 'EUR' ? UNITS.EUR : currency?.value} {saasValue}{' '}
				</SaaSValue>
			) : (
				''
			)}
		</FormatedValue>
	) : (
		'-'
	);
};

ValueWithSaaSCurrencySufix.defaultProps = {
	exchangeRate: 1,
	value: 0,
	currencyPrefix: null,
};

ValueWithSaaSCurrencySufix.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	exchangeRate: PropTypes.number,
	currency: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
};

const InvoiceDetailsTile = ({
	isInvoiceRestricted,
	isLoading,
	isGhana,
	isKenya,
	values,
	errors,
	isLoadingContent,
	contractType,
	touched,
	setFieldValue,
	handleChange,
	handleBlur,
	setFieldTouched,
}) => {
	const { t } = useTranslation();
	const isAuthorizedToEdit = isAuthorized([roles.ADMIN, roles.SALES, roles.FINANCE, roles.MANAGEMENT]);
	const exchangeRate = useMemo(
		() => (values.saasCurrency?.value !== values.paymentCurrency?.value ? values.exchangeRate : 0),
		[values],
	);

	const CurrencySign = values?.paymentCurrency?.value
		? () => (values?.paymentCurrency?.value === 'EUR' ? '€' : values?.paymentCurrency?.value)
		: null;

	const isReadonly = useMemo(
		() => isInvoiceRestricted && isAuthorizedToEdit,
		[isInvoiceRestricted, isAuthorizedToEdit],
	);

	return (
		<Tile
			title={t('Details')}
			isLoading={isLoading}
			isLoadingContent={isLoadingContent}
			isReadonly={isReadonly}
			tooltip={
				isInvoiceRestricted ? (
					<HoverTooltipWrapper>
						<HoverTooltip placement="right" title="Can't edit details for invoices with status Pending or Paid">
							<Icon icon="reportProblem" color={colors.warning.main} />
						</HoverTooltip>
					</HoverTooltipWrapper>
				) : null
			}
			fieldsMaxWidth="250px"
		>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6} md={4}>
					<FormField>
						<FormikInput
							id="number"
							name="number"
							label={t('Number')}
							value={values.number}
							error={errors.number}
							touched={touched.number}
							onChange={handleChange}
							onBlur={handleBlur}
							isDisabled={isReadonly}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSingleDatePicker
							id="issueDate"
							label={t('Issue date')}
							startDateName="issueDate"
							error={errors.issueDate}
							touched={touched.issueDate}
							startDate={values.issueDate}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							isDisabled={isReadonly}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSingleDatePicker
							id="dueDate"
							label={t('Due Date')}
							startDateName="dueDate"
							error={errors.dueDate}
							touched={touched.dueDate}
							startDate={values.dueDate}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							isDisabled={isReadonly}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSingleDatePicker
							id="billingPeriodStart"
							label={t('Billing period start')}
							startDateName="billingPeriodStart"
							error={errors.billingPeriodStart}
							touched={touched.billingPeriodStart}
							startDate={values.billingPeriodStart}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							// isDisabled={isReadonly} Temp fix due to Invoice only generate for last month
							isDisabled={true}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSingleDatePicker
							id="billingPeriodEnd"
							label={t('Billing period end')}
							startDateName="billingPeriodEnd"
							error={errors.billingPeriodEnd}
							touched={touched.billingPeriodEnd}
							startDate={values.billingPeriodEnd}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							// isDisabled={isReadonly} Temp fix due to Invoice only generate for last month
							isDisabled={true}
							isOverlay
						/>
					</FormField>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<FormField>
						<FormikInput
							id="countryVatPercentage"
							name="countryVatPercentage"
							label={t('Country VAT')}
							value={values.countryVatPercentage}
							error={errors.countryVatPercentage}
							touched={touched.countryVatPercentage}
							onChange={handleChange}
							onBlur={handleBlur}
							unit={UNITS.PERCENT}
							isDisabled={isReadonly}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="countryWhtPercentage"
							name="countryWhtPercentage"
							label={t('Country WHT')}
							value={values.countryWhtPercentage}
							error={errors.countryWhtPercentage}
							touched={touched.countryWhtPercentage}
							onChange={handleChange}
							onBlur={handleBlur}
							unit={UNITS.PERCENT}
							isDisabled={isReadonly}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="countryWhtVatPercentage"
							name="countryWhtVatPercentage"
							label={t('Country WHT VAT')}
							value={values.countryWhtVatPercentage}
							error={errors.countryWhtVatPercentage}
							touched={touched.countryWhtVatPercentage}
							onChange={handleChange}
							onBlur={handleBlur}
							unit={UNITS.PERCENT}
							isDisabled={isReadonly}
							isOverlay
						/>
					</FormField>
					{isGhana && (
						<>
							<FormField>
								<FormikInput
									id="countryNHILPercentage"
									name="countryNHILPercentage"
									label={t('Country NHIL')}
									value={values.countryNHILPercentage}
									error={errors.countryNHILPercentage}
									touched={touched.countryNHILPercentage}
									onChange={handleChange}
									onBlur={handleBlur}
									unit={UNITS.PERCENT}
									isDisabled={isReadonly}
									isOverlay
								/>
							</FormField>
							<FormField>
								<FormikInput
									id="countryCovid19LevyPercentage"
									name="countryCovid19LevyPercentage"
									label={t('Country COVID-19 Levy')}
									value={values.countryCovid19LevyPercentage}
									error={errors.countryCovid19LevyPercentage}
									touched={touched.countryCovid19LevyPercentage}
									onChange={handleChange}
									onBlur={handleBlur}
									unit={UNITS.PERCENT}
									isDisabled={isReadonly}
									isOverlay
								/>
							</FormField>
							<FormField>
								<FormikInput
									id="countryGetFundLevyPercentage"
									name="countryGetFundLevyPercentage"
									label={t('Country GETFUND Levy')}
									value={values.countryGetFundLevyPercentage}
									error={errors.countryGetFundLevyPercentage}
									touched={touched.countryGetFundLevyPercentage}
									onChange={handleChange}
									onBlur={handleBlur}
									unit={UNITS.PERCENT}
									isDisabled={isReadonly}
									isOverlay
								/>
							</FormField>
						</>
					)}
					{isKenya && (
						<>
							<KeyValueVisualization
								title={t(
									contractType === contractTypes.PPA
										? 'PPA Fee'
										: contractType === contractTypes.Lease
										? 'Leasing Fee'
										: 'Fee',
								)}
								prefix={CurrencySign}
								disabled
								value={
									<ValueWithSaaSCurrencySufix
										value={contractType === contractTypes.PPA ? values.ppaFee : values.leasingFee}
										exchangeRate={exchangeRate}
										currency={values.saasCurrency}
									/>
								}
							/>
							<KeyValueVisualization
								title={t('Maintenance fee')}
								prefix={CurrencySign}
								disabled
								value={
									<ValueWithSaaSCurrencySufix
										value={values.maintenanceFee}
										exchangeRate={exchangeRate}
										currency={values.saasCurrency}
									/>
								}
							/>
						</>
					)}
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<RelativeFormField>
						<FormikInput
							id="subTotalExcludingTax"
							name="subTotalExcludingTax"
							label={t('Sub total excluding tax')}
							value={values.subTotalExcludingTax}
							error={errors.subTotalExcludingTax}
							touched={touched.subTotalExcludingTax}
							onChange={handleChange}
							onBlur={handleBlur}
							prefix={CurrencySign}
							isDisabled={isReadonly}
							isOverlay
						/>
						<SaaSValueSufix
							value={values.subTotalExcludingTax}
							exchangeRate={exchangeRate}
							currency={values.saasCurrency}
						/>
					</RelativeFormField>
					{isGhana && (
						<>
							<KeyValueVisualization
								title={t('NHIL')}
								prefix={CurrencySign}
								disabled
								value={
									<ValueWithSaaSCurrencySufix
										value={values.nhil}
										exchangeRate={exchangeRate}
										currency={values.saasCurrency}
									/>
								}
							/>
							<KeyValueVisualization
								title={t('COVID-19 Levy')}
								prefix={CurrencySign}
								disabled
								value={
									<ValueWithSaaSCurrencySufix
										value={values.covid19Levy}
										exchangeRate={exchangeRate}
										currency={values.saasCurrency}
									/>
								}
							/>
							<KeyValueVisualization
								title={t('GETFUND Levy')}
								prefix={CurrencySign}
								disabled
								value={
									<ValueWithSaaSCurrencySufix
										value={values.getFundLevy}
										exchangeRate={exchangeRate}
										currency={values.saasCurrency}
									/>
								}
							/>
							<KeyValueVisualization
								title={t('Sub total taxable value')}
								prefix={CurrencySign}
								disabled
								value={
									<ValueWithSaaSCurrencySufix
										value={values.subTotalTaxableValue}
										exchangeRate={exchangeRate}
										currency={values.saasCurrency}
									/>
								}
							/>
						</>
					)}
					<KeyValueVisualization
						title={t('VAT')}
						prefix={CurrencySign}
						disabled
						value={
							<ValueWithSaaSCurrencySufix
								value={values.vat}
								exchangeRate={exchangeRate}
								currency={values.saasCurrency}
							/>
						}
					/>
					<KeyValueVisualization
						title={t('Sub total')}
						prefix={CurrencySign}
						disabled
						value={
							<ValueWithSaaSCurrencySufix
								value={values.subTotal}
								exchangeRate={exchangeRate}
								currency={values.saasCurrency}
							/>
						}
					/>
					<KeyValueVisualization
						title={t('WHT')}
						prefix={CurrencySign}
						disabled
						value={
							<ValueWithSaaSCurrencySufix
								value={values.wht}
								exchangeRate={exchangeRate}
								currency={values.saasCurrency}
							/>
						}
					/>
					<KeyValueVisualization
						title={t('WHT VAT')}
						prefix={CurrencySign}
						disabled
						value={
							<ValueWithSaaSCurrencySufix
								value={values.whtVat}
								exchangeRate={exchangeRate}
								currency={values.saasCurrency}
							/>
						}
					/>
					<KeyValueVisualization
						title={t('Total amount')}
						prefix={CurrencySign}
						disabled
						value={
							<ValueWithSaaSCurrencySufix
								value={values.totalAmount}
								exchangeRate={exchangeRate}
								currency={values.saasCurrency}
							/>
						}
					/>
				</Grid>
			</Grid>
		</Tile>
	);
};

InvoiceDetailsTile.defaultProps = {
	isLoading: false,
	isInvoiceRestricted: false,
	countryIsoCode: '',
	clientIsoCode: '',
};

InvoiceDetailsTile.propTypes = {
	isLoading: PropTypes.bool,
	isGhana: PropTypes.bool.isRequired,
	isKenya: PropTypes.bool.isRequired,
	values: PropTypes.shape({
		exchangeRate: PropTypes.number,
		saasCurrency: PropTypes.shape({
			value: PropTypes.string,
		}),
		paymentCurrency: PropTypes.shape({
			value: PropTypes.string,
		}),
		number: PropTypes.number,
		issueDate: PropTypes.string,
		billingPeriodStart: PropTypes.string,
		billingPeriodEnd: PropTypes.string,
		dueDate: PropTypes.string,
		countryVatPercentage: PropTypes.number,
		countryWhtPercentage: PropTypes.number,
		countryWhtVatPercentage: PropTypes.number,
		countryNHILPercentage: PropTypes.number,
		countryCovid19LevyPercentage: PropTypes.number,
		countryGetFundLevyPercentage: PropTypes.number,
		leasingFee: PropTypes.string,
		ppaFee: PropTypes.string,
		maintenanceFee: PropTypes.string,
		subTotalExcludingTax: PropTypes.string,
		wht: PropTypes.string,
		subTotal: PropTypes.string,
		whtVat: PropTypes.string,
		vat: PropTypes.string,
		nhil: PropTypes.string,
		covid19Levy: PropTypes.string,
		getFundLevy: PropTypes.string,
		subTotalTaxableValue: PropTypes.string,
		totalAmount: PropTypes.string,
	}).isRequired,
	isLoadingContent: PropTypes.bool.isRequired,
	contractType: PropTypes.string.isRequired,
	errors: PropTypes.shape({
		number: PropTypes.number,
		issueDate: PropTypes.string,
		billingPeriodStart: PropTypes.string,
		billingPeriodEnd: PropTypes.string,
		dueDate: PropTypes.string,
		countryVatPercentage: PropTypes.number,
		countryWhtPercentage: PropTypes.number,
		countryWhtVatPercentage: PropTypes.number,
		countryNHILPercentage: PropTypes.number,
		countryCovid19LevyPercentage: PropTypes.number,
		countryGetFundLevyPercentage: PropTypes.number,
		leasingFee: PropTypes.string,
		ppaFee: PropTypes.string,
		maintenanceFee: PropTypes.string,
		subTotalExcludingTax: PropTypes.string,
		wht: PropTypes.string,
		subTotal: PropTypes.string,
		whtVat: PropTypes.string,
		vat: PropTypes.string,
		nhil: PropTypes.string,
		covid19Levy: PropTypes.string,
		getFundLevy: PropTypes.string,
		totalAmount: PropTypes.string,
	}).isRequired,
	touched: PropTypes.shape({
		number: PropTypes.number,
		issueDate: PropTypes.string,
		billingPeriodStart: PropTypes.string,
		billingPeriodEnd: PropTypes.string,
		dueDate: PropTypes.string,
		countryVatPercentage: PropTypes.number,
		countryWhtPercentage: PropTypes.number,
		countryWhtVatPercentage: PropTypes.number,
		countryNHILPercentage: PropTypes.number,
		countryCovid19LevyPercentage: PropTypes.number,
		countryGetFundLevyPercentage: PropTypes.number,
		leasingFee: PropTypes.string,
		ppaFee: PropTypes.string,
		maintenanceFee: PropTypes.string,
		subTotalExcludingTax: PropTypes.string,
		wht: PropTypes.string,
		subTotal: PropTypes.string,
		whtVat: PropTypes.string,
		vat: PropTypes.string,
		nhil: PropTypes.string,
		covid19Levy: PropTypes.string,
		getFundLevy: PropTypes.string,
		totalAmount: PropTypes.string,
	}).isRequired,
	setFieldValue: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleBlur: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	isInvoiceRestricted: PropTypes.bool,
};

export default InvoiceDetailsTile;
