const colors = {
	common: {
		black: '#000000',
		white: '#FFFFFF',
		orange: '#FDAF66',
		darkOrange: '#D88A41',
		yellow: '#FEE300',
		beige: '#F6E6DE',
		lightBeige: '#FAF2EE',
		lightBlue: '#9DDAE0',
		lightGreen: '#4AB2C6',
		darkBlue: '#22AAAA',
		darkerGreen: '#253836',
		darkGreen: '#248BA0',
		green: '#305753',
		blue: '#27BDBD',
		brown: '#933A16',
		grey: '#666666',
		lightGrey: '#E9E9E9',
		headerGrey: '#F7F7F7',
		tabsGrey: '#F7F7F7',
		sidebarGrey: '#F2F2F2',
		borderGrey: '#E5E5E5',
		tileBackgroundGrey: '#F7F7F7',
		warningYellow: '#FFF6E0',
		transparent: '#00000000',
	},
	primary: {
		light: '#C1EFF4',
		main: '#7CCED6',
		dark: '#2AA4BC',
	},
	secondary: {
		lighter: '#E0F7F9',
		light: '#0D2220',
		main: '#194440',
		dark: '#476966',
	},
	error: {
		light: '#FF9D9D',
		main: '#FF3B3B',
		dark: '#BF2C2C',
	},
	warning: {
		light: '#FFE680',
		main: '#FFCC00',
		dark: '#BF9900',
	},
	success: {
		light: '#82E1B7',
		main: '#06C270',
		dark: '#049254',
	},
	grey: {
		lightest: '#F2F2F2',
		lighter: '#F9F9F9',
		light: '#E6E6E6',
		main: '#B3B3B3',
		dark: '#666666',
	},
	text: {
		primaryLight: '#27BDBD',
		primary: '#476966',
		secondary: '#2AA4BC',
		white: '#FFFFFF',
		black: '#0D2220',
		greyDark: '#363737',
		greyLightDark: '#999999',
		grey: '#B3B3B3',
		greyLight: '#CCCCCC',
		disabled: '#E6E6E6',
		highlightDefault: '#BF2C2C',
		highlightHover: '#933A16',
	},
	skeleton: {
		defaultBG: '#F9F9F9',
		defaultFG: '#E6E6E6',
	},
	gradients: {
		radialLight: 'radial-gradient(at 50% bottom, #C1EFF4, #7CCED6);',
		radialDark: 'radial-gradient(at 60% bottom,#00B4D5,#2AA4BC);',
	},
};

export default colors;
