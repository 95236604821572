import { useMemo } from 'react';

const fallbackValues = {
	unknownClient: false,
	responseChoice: '',
	project: null,
	client: null,
	message: '',
	hideClientName: false,
};

const useRespondModalFormInitialValues = () =>
	useMemo(
		() => ({
			...fallbackValues,
		}),
		[],
	);

export default useRespondModalFormInitialValues;
