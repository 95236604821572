import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '@mui/material';
import styled from 'styled-components/macro';

import { closeSidePanel } from 'Application/reducers/reduxSidePanel';
import SidePanelHeader from 'Common/components/SidePanel/SidePanelHeader';
import SidePanelContent from 'Common/components/SidePanel/SidePanelContent';
import {
	NO_HEADER_CONTENT_TYPES,
	NO_PADDING_CONTENT_TYPES,
} from 'Common/components/SidePanel/constants/sidePanelContentTypes';
import { SIDE_PANEL_COMPONENTS } from 'Common/components/SidePanel/constants/sidePanelComponents';
import useReponsive from 'Common/hooks/useResponsive';
import UnsavedChangesModal from 'Common/components/modals/UnsavedChangesModal';
import { css } from 'styled-components';
import { useState } from 'react';

const StyledDrawer = styled(Drawer)`
	inset: auto !important;
	z-index: 997 !important;

	.MuiDrawer-paper {
		position: inherit !important;
		overflow-y: unset !important;
		width: ${({ $isMobile }) => ($isMobile ? '100vw' : '472px')};
		top: ${({ $alignTop }) => ($alignTop ? '0px' : '60px')};
		height: calc(100% - ${({ $alignTop }) => ($alignTop ? '0px' : '60px')});

		${({ $isMobile }) =>
			$isMobile &&
			css`
				* {
					font-size: 13px;
				}
			`}
	}
`;

const LoadingOverlay = styled.div`
	position: fixed;
	background-color: transparent;
	width: 100vw;
	height: 100vh;
	z-index: -1;
	top: 0;
	left: 0;
`;

const SidePanel = () => {
	const { isMobile } = useReponsive();
	const dispatch = useDispatch();
	const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = useState(false);
	const { contentData, headerData, isOpen, type, dirtyForm, isLoading } = useSelector(
		state => state.sidePanel,
	);
	const alignTop = type === 'projectStep' && isMobile;

	const onSidePanelClose = () => {
		if (dirtyForm.length > 0) {
			setIsUnsavedChangesModalOpen(true);
		} else {
			dispatch(closeSidePanel());
		}
	};

	const contentProps = {
		handleOnClose: onSidePanelClose,
	};

	const handleConfirmModal = () => {
		dispatch(closeSidePanel());
	};

	const handleCloseModal = () => {
		setIsUnsavedChangesModalOpen(false);
	};

	return (
		<>
			<StyledDrawer
				$isMobile={isMobile}
				$alignTop={alignTop}
				anchor="right"
				hideBackdrop={true}
				open={isOpen}
				onClose={onSidePanelClose}
				transitionDuration={300}
				disableEnforceFocus={true}
			>
				{!NO_HEADER_CONTENT_TYPES.includes(type) && (
					<SidePanelHeader
						type={type}
						isLoading={isLoading}
						isHistoryShow={contentData.showHistory}
						handleOnClose={onSidePanelClose}
						{...headerData}
					/>
				)}

				<SidePanelContent
					$noPadding={NO_PADDING_CONTENT_TYPES.includes(type)}
					content={SIDE_PANEL_COMPONENTS?.[type]?.({ contentData, contentProps })}
					isLoading={isLoading}
				/>

				{isLoading ? <LoadingOverlay /> : <></>}
			</StyledDrawer>
			<UnsavedChangesModal
				isOpen={isUnsavedChangesModalOpen}
				onConfirm={handleConfirmModal}
				onClose={handleCloseModal}
			/>
		</>
	);
};

export default SidePanel;
