import { useMemo } from 'react';

const fallbackValues = {
	lawyer: null,
	taxAdvisor: null,
	accountant: null,
	auditor: null,
};

const usePartnersTileFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			lawyer: data?.lawyer
				? {
						label: data?.lawyer?.name,
						value: data?.lawyer?.id,
						partnerTypeName: data?.lawyer?.partnerType?.name,
				  }
				: null,
			taxAdvisor: data?.taxAdvisorAndAccountants
				? {
						label: data?.taxAdvisorAndAccountants?.name,
						value: data?.taxAdvisorAndAccountants?.id,
						partnerTypeName: data?.taxAdvisorAndAccountants?.partnerType?.name,
				  }
				: null,
			accountant: data?.accountant
				? {
						label: data?.accountant?.name,
						value: data?.accountant?.id,
						partnerTypeName: data?.accountant?.partnerType?.name,
				  }
				: null,
			auditor: data?.auditor
				? {
						label: data?.auditor?.name,
						value: data?.auditor?.id,
						partnerTypeName: data?.auditor?.partnerType?.name,
				  }
				: null,
		}),
		[data],
	);

export default usePartnersTileFormInitialValues;
