import api from 'Application/api/api';

const passwordReset = (signal, email) =>
	api.post(
		'/auth/password-reset',
		{ username: email },
		{
			signal,
		},
	);

export default passwordReset;
