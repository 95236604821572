import { PropTypes } from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';

const Wrapper = styled.div`
	position: absolute;
	left: 16px;
	top: 100%;
	background-color: ${colors.common.white};
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	padding: 8px;
	margin-top: 5px;
	border-radius: 6px;
	color: ${colors.error.main};
	font-weight: 600;
	${sizes.fontSize.tiny};
	line-height: 14px;
	z-index: 2;

	&:before {
		content: ' ';
		position: absolute;
		left: 15px;
		top: 0;
		width: 10px;
		height: 10px;
		background-color: ${colors.common.white};
		transform: rotate(45deg) translate(-50%);
		z-index: 5;
	}
`;

const ErrorMessage = ({ message, ...props }) => (
	<Wrapper {...props} className={`${props.className} errorMessage`}>
		{message}
	</Wrapper>
);

ErrorMessage.defaultProps = {
	className: '',
};

ErrorMessage.propTypes = {
	message: PropTypes.string.isRequired,
	className: PropTypes.string,
};

export default ErrorMessage;
