import api from 'Application/api/api';
import adaptClientResponseData from 'Client/api/adapters/adaptClientResponseData';

const getClientById = async (signal, id, details) => {
	const response = await api.get(`/clients/by-id/${id}`, {
		params: {
			details,
		},
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptClientResponseData(response.data),
	};
};

export default getClientById;
