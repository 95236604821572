import React, { useEffect, useRef, useState } from 'react';
import Icon from 'Common/components/icons/Icon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import PropTypes from 'prop-types';

import Title from 'Common/components/title/Title';
import Loader from 'Common/components/Loader';
import Button from 'Common/components/buttons/Button';
import FixedTitle from 'Common/components/title/FixedTitle';
import useFixedHeader from 'Common/hooks/useFixedHeader';
import Accordion from 'Common/components/accordion/Accordion';
import AccordionSummary from 'Common/components/accordion/AccordionSummary';
import AccordionDetails from 'Common/components/accordion/AccordionDetails';

import createActivity from 'PMWorkflow/api/createActivity';
import TemplateActivity from 'PMWorkflow/components/Activity/TemplateActivity/TemplateActivity';

import getActivitiesByStage from 'PMWorkflow/api/getActivitiesByStage';
import sizes from 'Application/theme/sizes';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';

const StyledAccordionDetails = styled(AccordionDetails)`
	&.MuiAccordionDetails-root {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 44px;
		padding: 0px;
		margin: 2px;
	}
`;

const NoActivitiesText = styled.div`
	color: ${colors.text.black};
	font-size: 14px;
	font-weight: 400;
	margin-right: 8px;
`;

const ActivitiesContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${sizes.spacing(0.25)};
	padding: ${sizes.spacing(0.25)};
`;

const TemplateStage = ({
	stage,
	stages,
	countries,
	stageIndex,
	stagesLength,
	onDataChange,
	projectTypes,
	contractTypes,
	allActivities,
	setTemplateState,
	setPMWorkflowState,
	automationFields,
}) => {
	const stageRef = useRef();
	const { t } = useTranslation();
	const isShrank = useSelector(state => state.common.isSidebarShrank);
	const [state, setState] = useState({
		expanded: false,
		isLoading: false,
		isLoadingStage: false,
		left: isShrank ? 80 : 207,
		isFixedTitleVisible: false,
	});
	const [isFixedTitleVisible, setIsFixedTitleVisible] = useState(false);

	const abortController = useAbortController();

	const iconStyle = {
		fontSize: 20,
		color: colors.text.greyDark,
		marginRight: 8,
	};

	useFixedHeader(stageRef, setIsFixedTitleVisible);

	const handleExpanded = async () => {
		try {
			if (!state.expanded) {
				await setActivitiesByStage();
				setState(prevState => ({
					...prevState,
					expanded: true,
					isLoadingStage: false,
				}));
			} else {
				setState(prevState => ({
					...prevState,
					expanded: false,
				}));
			}
		} catch (error) {
			showToastError(error);
		}
	};

	const setActivitiesByStage = async () => {
		try {
			setState(prevState => ({ ...prevState, isLoadingStage: true }));
			const response = await getActivitiesByStage(abortController.signal, stage.stage, true);

			setPMWorkflowState(prevState => ({
				...prevState,
				stages: prevState.stages.map(item => {
					if (item.stage === stage.stage) {
						item.activities = response.data;
					}

					return item;
				}),
			}));
		} catch (error) {
			showToastError(error);
		}
	};

	const updateActivitiesByStage = async updatedStage => {
		try {
			const response = await getActivitiesByStage(abortController.signal, updatedStage.stage, true);

			setPMWorkflowState(prevState => ({
				...prevState,
				stages: prevState.stages.map(item => {
					if (item.stage === updatedStage.stage) {
						item.activities = response.data;
					}

					return item;
				}),
			}));
		} catch (error) {
			showToastError(error);
		}
	};

	const handleAddActivity = async event => {
		try {
			setState(prevState => ({ ...prevState, isLoading: true }));
			event.stopPropagation();
			const values = {
				orderNumber: stage.activities.length + 1,
				stage: stage.stage,
				title: 'New Activity',
			};

			const response = await createActivity(abortController.signal, {
				...values,
			});

			if (response.status === 201) {
				await setTemplateState();

				await onDataChange();

				setPMWorkflowState(prevState => ({
					...prevState,
					stages: prevState.stages.map(item => {
						if (item.stage === stage.stage) {
							item.activities.push(response.data);
						}

						return item;
					}),
				}));

				setState(prevState => ({
					...prevState,
					isLoading: false,
				}));
			}
		} catch (error) {
			showToastError(error);
		}
	};

	useEffect(() => {
		if (isFixedTitleVisible) {
			setState(prevState => ({
				...prevState,
				left: isShrank ? 80 : 207,
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isShrank]);

	return (
		<Accordion
			expanded={state.expanded}
			onChange={handleExpanded}
			background={colors.grey.light}
			ref={stageRef}
			data-tab-name={stage.label}
		>
			<AccordionSummary>
				<Grid item container xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="space-between">
					<FixedTitle left={state.left} isVisible={isFixedTitleVisible}>
						{state.isLoadingStage ? (
							<CircularProgress
								style={{
									color: colors.primary.main,
									marginRight: 13,
								}}
								size={15}
							/>
						) : state.expanded ? (
							<Icon icon="keyboardArrowDown" style={iconStyle} />
						) : (
							<Icon icon="keyboardArrowRight" style={iconStyle} />
						)}
						{t(`${stage.label}`)}
					</FixedTitle>
					<Grid item container xs={11} sm={11} md={11} lg={11} xl={11}>
						<Title>
							{state.isLoadingStage ? (
								<CircularProgress
									style={{
										color: colors.primary.main,
										marginRight: 13,
									}}
									size={15}
								/>
							) : state.expanded ? (
								<Icon icon="keyboardArrowDown" style={iconStyle} />
							) : (
								<Icon icon="keyboardArrowRight" style={iconStyle} />
							)}
							{t(`${stage.label}`)}
						</Title>
					</Grid>
					<Grid item container xs={1} sm={1} md={1} lg={1} xl={1} justifyContent="flex-end" alignItems="center">
						<Grid item>
							<Button
								small
								icon="addRounded"
								onClick={event => handleAddActivity(event)}
								label="PMWorkflow Template Stage - Add Activity Only Icon Button"
							/>
						</Grid>
					</Grid>
				</Grid>
			</AccordionSummary>
			{state.isLoading ? (
				<StyledAccordionDetails background={colors.grey.lighter}>
					<Loader />
				</StyledAccordionDetails>
			) : stage.activities.length === 0 ? (
				<StyledAccordionDetails background={colors.grey.lighter}>
					<NoActivitiesText>{t('No activities added.')}</NoActivitiesText>
					<Button
						onClick={event => handleAddActivity(event)}
						text={t('Add activity')}
						label="PMWorkflow Template Stage - Add Activity Button"
					/>
				</StyledAccordionDetails>
			) : (
				<ActivitiesContainer>
					{stage.activities.map(activity => (
						<div key={activity.id}>
							<TemplateActivity
								stage={stage}
								stages={stages}
								activity={activity}
								countries={countries}
								automationFields={automationFields}
								iconStyle={iconStyle}
								stageIndex={stageIndex}
								setStageState={setState}
								projectTypes={projectTypes}
								onDataChange={onDataChange}
								stagesLength={stagesLength}
								contractTypes={contractTypes}
								allActivities={allActivities}
								stageActivities={stage.activities}
								setTemplateState={setTemplateState}
								setPMWorkflowState={setPMWorkflowState}
								setActivitiesByStage={setActivitiesByStage}
								updateActivitiesByStage={updateActivitiesByStage}
							/>
						</div>
					))}
				</ActivitiesContainer>
			)}
		</Accordion>
	);
};

TemplateStage.defaultProps = {
	automationFields: null,
};

TemplateStage.propTypes = {
	stage: PropTypes.shape({
		stage: PropTypes.string.isRequired,
		activities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		label: PropTypes.string.isRequired,
	}).isRequired,
	stages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	stageIndex: PropTypes.number.isRequired,
	onDataChange: PropTypes.func.isRequired,
	projectTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	contractTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	stagesLength: PropTypes.number.isRequired,
	allActivities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	setTemplateState: PropTypes.func.isRequired,
	setPMWorkflowState: PropTypes.func.isRequired,
	automationFields: PropTypes.arrayOf(PropTypes.string),
};

export default TemplateStage;
