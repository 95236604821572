import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { PropTypes } from 'prop-types';

import SidebarLink from 'Common/components/layout/SidebarLink';
import MenuIcon from 'Common/components/layout/MenuIcon';
import SidebarSectionTitle from 'Common/components/layout/SidebarSectionTitle';

const SectionContent = styled.div`
	margin-bottom: 35px;
`;

const animationVariants = {
	expanded: {
		opacity: 1,
		transition: {
			duration: 0.6,
		},
	},
	shrank: {
		opacity: 0,
		transition: {
			duration: 0.6,
		},
	},
};

const EpcSidebarLinks = ({ isShrank }) => {
	const { t } = useTranslation();

	return (
		<>
			<SectionContent>
				<SidebarLink to="/epc/dashboard">
					<MenuIcon icon="dashboard" withTooltip={isShrank} padding={3} tooltipText="Dashboard" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('Dashboard')}
					</motion.span>
				</SidebarLink>
			</SectionContent>
			<SidebarSectionTitle isShrank={isShrank}>{t('Projects')}</SidebarSectionTitle>
			<SectionContent>
				<SidebarLink to="/epc/inquiries">
					<MenuIcon icon="flashOn" withTooltip={isShrank} tooltipText="Inquiries" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('Inquiries')}
					</motion.span>
				</SidebarLink>
				<SidebarLink to="/epc/offers">
					<MenuIcon icon="flare" withTooltip={isShrank} tooltipText="Offers" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('Offers')}
					</motion.span>
				</SidebarLink>
			</SectionContent>
			<SidebarSectionTitle isShrank={isShrank}>{t('Profile')}</SidebarSectionTitle>
			<SectionContent>
				<SidebarLink to="/epc/my-profile">
					<MenuIcon icon="settings" withTooltip={isShrank} tooltipText="My Profile" />
					<motion.span
						variants={animationVariants}
						initial={isShrank ? 'shrank' : 'expanded'}
						animate={isShrank ? 'shrank' : 'expanded'}
					>
						{t('My Profile')}
					</motion.span>
				</SidebarLink>
			</SectionContent>
		</>
	);
};

EpcSidebarLinks.propTypes = {
	isShrank: PropTypes.bool.isRequired,
};

export default EpcSidebarLinks;
