import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useClientFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				client: yup
					.object()
					.nullable()
					.when('unknownClient', {
						is: true,
						then: yup.object().nullable(),
						otherwise: yup.object().required(t('Required, unless "Unknown Client" is checked')).nullable(),
					}),
				industry: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.nullable()
					.required(t('Required')),
			}),
		[t],
	);
};

export default useClientFormValidationSchema;
