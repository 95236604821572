import { pick } from 'lodash';
import formatDateForRequestData from 'Common/utils/formatDateForRequestData';
import projectTypes from 'Projects/constants/projectTypes';

const convertFormValuesToRequestData = values => {
	const hasClient = !values.unknownClient && values.client;
	const responsiblePeopleByRole = {
		ROLE_SALES: values.responsiblePerson.value,
	};

	return {
		...pick(values, ['description', 'locationUrl']),
		stage: 'SALES',
		clientId: hasClient ? values.client.value : null,
		countryId: values.country ? values.country.value : null,
		projectTypeId: values.projectType ? values.projectType.value : null,
		epcPartnerId: values.epcPartner ? values.epcPartner.value : null,
		systemSizeKwp: values.projectType.label === projectTypes.PV ? values.systemSizeKwp : null,
		kwhToKwpRel: values.projectType.label === projectTypes.PV ? values.kwhToKwpRel : null,
		responsiblePeopleByRole,
		projectDeveloperId: values.projectDeveloper ? values.projectDeveloper.value : null,
		gridEmissionFactor: values.country.gridEmissionFactor || null,
		regionalHubId: values.regionalHub ? values.regionalHub.value : null,
		salesChannelId: values.salesChannel ? values.salesChannel.value : null,
		priority: 'MEDIUM',
		initialEngagementDate: values.initialEngagementDate
			? formatDateForRequestData(values.initialEngagementDate, 'yyyy-MM-dd')
			: null,
	};
};

export default convertFormValuesToRequestData;
