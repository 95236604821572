import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import Link from 'Common/components/Link';
import EditableTile from 'Common/components/Tile/EditableTile';
import formatDate from 'Common/utils/formatDate';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

const genderMap = { female: 'Female', male: 'Male' };

const GeneralTab = ({ investor }) => {
	const { t } = useTranslation();

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<EditableTile title={t('Investor details')}>
					<KeyValueVisualization
						id="name"
						title={t('Name')}
						value={`${investor.first_name} ${investor.last_name}`}
					/>

					<KeyValueVisualization
						id="email"
						title={t('E-mail')}
						value={
							<Link
								to={`mailto:${investor.email}`}
								onClick={e => {
									window.location = `mailto:${investor.email}`;
									e.preventDefault();
								}}
							>
								{investor.email}
							</Link>
						}
						hasHoverEffect
					/>

					<KeyValueVisualization
						id="registered"
						title={t('Registered')}
						value={investor.registered && formatDate(investor.registered, 'd/M/yy hh:mm')}
					/>

					<KeyValueVisualization id="type" title={t('Type')} value={investor.type} />

					<KeyValueVisualization
						id="newsletter"
						title={t('Newsletter')}
						value={investor.wants_newsletter ? t('Yes') : t('No')}
					/>
				</EditableTile>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<EditableTile title={t('Demographics')}>
					<KeyValueVisualization
						id="gender"
						title={t('Gender')}
						value={investor.gender ? genderMap[investor.gender] : ''}
					/>

					<KeyValueVisualization
						id="dateOfBirth"
						title={t('Date of birth')}
						value={investor.date_of_birth ? formatDate(investor.date_of_birth, 'd/M/yy') : ''}
					/>

					<KeyValueVisualization
						id="placeOfResidence"
						title={t('Place of residence')}
						value={investor.country_code}
					/>

					<KeyValueVisualization id="nationality" title={t('Nationality')} value={investor.nationality_code} />
				</EditableTile>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<EditableTile title={t('Address')}>
					<KeyValueVisualization id="country" title={t('Country')} value={investor.country_code} />

					<KeyValueVisualization id="cityOrRegion" title={t('City / Region')} value={investor.city} />

					<KeyValueVisualization id="postCodeOrZip" title={t('Post code / ZIP')} value={investor.zip_code} />

					<KeyValueVisualization id="streetOrHouseNo" title={t('Street / House  no.')} value={investor.street} />

					<KeyValueVisualization id="addressLineTwo" title={t('Address line 2')} value={investor.adrressLine2} />
				</EditableTile>
			</Grid>
		</TilesGridWrapper>
	);
};

GeneralTab.propTypes = {
	investor: PropTypes.shape({
		first_name: PropTypes.string,
		last_name: PropTypes.string,
		email: PropTypes.string,
		registered: PropTypes.string,
		type: PropTypes.string,
		wants_newsletter: PropTypes.bool,
		gender: PropTypes.string,
		date_of_birth: PropTypes.string,
		country_code: PropTypes.string,
		nationality_code: PropTypes.string,
		city: PropTypes.string,
		zip_code: PropTypes.string,
		street: PropTypes.string,
		adrressLine2: PropTypes.string,
	}).isRequired,
};

export default GeneralTab;
