import { useCallback, useEffect, useRef } from 'react';

const useUniqueAbortSignal = () => {
	const uniqueSignalsMap = useRef({});

	const getUniqueSignal = useCallback(signalTag => {
		if (uniqueSignalsMap.current[signalTag]) {
			uniqueSignalsMap.current[signalTag].abort();
		}
		uniqueSignalsMap.current[signalTag] = new AbortController();

		return uniqueSignalsMap.current[signalTag].signal;
	}, []);

	const clearSignals = useCallback(() => {
		Object.entries(uniqueSignalsMap.current)
			.map(([key, signal]) => {
				signal.abort();
				return [key, new AbortController()];
			})
			.reduce((acc, [key, signal]) => ({ ...acc, [key]: signal }), {});
	}, []);

	useEffect(() => () => clearSignals(), [clearSignals]);

	return getUniqueSignal;
};
export default useUniqueAbortSignal;
