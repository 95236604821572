import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import crudModes from 'Common/constants/crudModes';
import getSpvProfitById from 'Preferences/api/getSpvProfitById';
import CustomSubmitForm from 'Preferences/components/Tabs/CampaignsTab/SpvProfits/CustomSubmitForm';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PropTypes } from 'prop-types';

const EditModal = ({ isOpen, onClose, idEditing, onFormSubmit }) => {
	const { t } = useTranslation();

	const [spvProfit, setSpvProfit] = useState(null);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				const response = await getSpvProfitById(abortController.signal, idEditing, true);
				setSpvProfit(response.data);
			} catch (error) {
				showToastError(error);
			}
		})();
	}, [idEditing, abortController.signal]);

	const isDataLoading = spvProfit === null;

	return (
		<ModalForm
			label="emission-spv-edit"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			isLoadingContent={isDataLoading}
			heading={t('Edit SPV Profit')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<CustomSubmitForm
					data={spvProfit}
					mode={crudModes.EDIT}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

EditModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	idEditing: PropTypes.string.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default EditModal;
