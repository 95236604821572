import PropTypes from 'prop-types';

import Button from 'Common/components/buttons/Button';

const MapLink = props => (
	<Button
		icon="place"
		text="Google Maps"
		disabled={Boolean(props.href) === false}
		onClick={e => {
			e.stopPropagation();
			window.open(props.href, '_blank');
		}}
		label="Map Link - Google Maps Button"
	/>
);

MapLink.defaultProps = {
	href: '',
};

MapLink.propTypes = {
	href: PropTypes.string,
};

export default MapLink;
