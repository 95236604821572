import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { startOfMonth } from 'date-fns';
import Grid from '@mui/material/Grid';
import colors from 'Application/theme/colors';
import formatDate from 'Common/utils/formatDate';
import Button from 'Common/components/buttons/Button';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import Tile from 'Common/components/Tile/Tile';
import formatDecimal from 'Common/utils/formatDecimal';
import {
	DefaultTable,
	TableHeadCell,
	TableBodyCell,
	TableHeadCellContent,
	TableBodyRow,
} from 'Common/components/table/table';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import getProjectMeteocontrolData from 'Projects/api/getProjectMeteocontrolData';
import MonthSelector from './components/MonthSelector';
import SystemsSelector from './components/SystemsSelector';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';

const TableWrapper = styled.div`
	${TableHeadCell} {
		background: transparent;
	}
	${TableBodyCell} {
		background: transparent;
		height: 44px;
		font-size: 16px;
	}
	${TableHeadCellContent} {
		white-space: normal;
	}
	${TableBodyRow} {
		&:first-child {
			font-weight: 600;
		}
		height: 44px;
	}
	& .MuiTooltip-tooltip {
		background: ${colors.common.white};
		color: ${colors.text.black};
		border-radius: 6px;
		white-space: pre-line;
	}

	& .MuiTooltip-arrow {
		color: ${colors.common.white};
	}
`;

const averageMeasure = (params = []) =>
	Math.round((params.reduce((partialSum, number = 0) => partialSum + number, 0) / params.length) * 100) / 100;

const sumMeasure = (params = []) =>
	Math.round(params.reduce((partialSum, number = 0) => partialSum + number, 0) * 100) / 100;

const co2SavingMeasure = (params = []) =>
	Math.round(params.reduce((partialMul, number = 1) => partialMul * number, 1) * 100) / 100;

const MeasurementsTile = ({ project, meteoSystems }) => {
	const { id: projectId, externalId } = project;
	const clientName = project?.client?.name || '';

	const { t } = useTranslation();
	const abortController = useAbortController();

	const [tableData, setTableData] = useState([]);
	const initialDatesRange = useMemo(() => {
		const date = new Date();
		return {
			startDate: startOfMonth(date),
			endDate: date,
		};
	}, []);
	const [datesRange, setDatesRange] = useState(initialDatesRange);
	const [selectedSystems, setSelectedSystems] = useState(meteoSystems.map(system => system.value));

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Date'),
				exportHeader: t('Date'),
				accessor: 'date',
			},
			{
				Header: () => {
					if (selectedSystems.length > 1) {
						return (
							<HoverTooltip placement="top" title={t('Sum amount of multiple systems')} arrow>
								<div>{t('Σ PV Yield')}</div>
							</HoverTooltip>
						);
					}
					return t('PV Yield');
				},
				exportHeader: selectedSystems.length > 1 ? t('Σ PV Yield') : t('PV Yield'),
				accessor: 'E_Z_EVU',
			},
			{
				Header: () => {
					if (selectedSystems.length > 1) {
						return (
							<HoverTooltip placement="top" title={t('Average amount of multiple systems')} arrow>
								<div>{t('μ Irradiance')}</div>
							</HoverTooltip>
						);
					}
					return t('Irradiance');
				},
				exportHeader: selectedSystems.length > 1 ? t('μ Irradiance') : t('Irradiance'),
				accessor: 'G_M0',
			},
			{
				Header: () => {
					if (selectedSystems.length > 1) {
						return (
							<HoverTooltip placement="top" title={t('Sum amount of multiple systems')} arrow>
								<div>{t('Σ Solar Energy Curtailed')}</div>
							</HoverTooltip>
						);
					}
					return t('Solar Energy Curtailed');
				},
				exportHeader: selectedSystems.length > 1 ? t('Σ Solar Energy Curtailed') : t('Solar Energy Curtailed'),
				accessor: 'PV_CURTAILED',
			},
			{
				Header: () => {
					if (selectedSystems.length > 1) {
						return (
							<HoverTooltip placement="top" title={t('Sum amount of multiple systems')} arrow>
								<div>{t('Σ Grid Consumption')}</div>
							</HoverTooltip>
						);
					}
					return t('Grid Consumption');
				},
				exportHeader: selectedSystems.length > 1 ? t('Σ Grid Consumption') : t('Grid Consumption'),
				accessor: 'E_GRID',
			},
			{
				Header: () => {
					if (selectedSystems.length > 1) {
						return (
							<HoverTooltip placement="top" title={t('Average amount of multiple systems')} arrow>
								<div>{t('μ Performance Ratio')}</div>
							</HoverTooltip>
						);
					}
					return t('Performance Ratio');
				},
				exportHeader: selectedSystems.length > 1 ? t('μ Performance Ratio') : t('Performance Ratio'),
				accessor: 'PR',
				Cell: (() => {
					const Cell = ({ row: { original: measurement } }) => measurement.PR + '%';

					return Cell;
				})(),
			},
			{
				Header: () => {
					if (selectedSystems.length > 1) {
						return (
							<HoverTooltip placement="top" title={t('Average amount of multiple systems')} arrow>
								<div>{t('μ Availability')}</div>
							</HoverTooltip>
						);
					}
					return t('Availability');
				},
				exportHeader: selectedSystems.length > 1 ? t('μ Availability') : t('Availability'),
				accessor: 'VFG',
			},
			{
				Header: () => {
					if (selectedSystems.length > 1) {
						return (
							<HoverTooltip placement="top" title={t('Co2 T')} arrow>
								<div>{t('Σ Co2 T')}</div>
							</HoverTooltip>
						);
					}
					return t('Co2 T');
				},
				exportHeader: selectedSystems.length > 1 ? t('Σ Co2 T') : t('Co2 T'),
				accessor: 'CO2_T',
			},
			{
				Header: () => {
					if (selectedSystems.length > 1) {
						return (
							<HoverTooltip placement="top" title={t('Energy Produced Kwh')} arrow>
								<div>{t('Σ Energy Produced Kwh')}</div>
							</HoverTooltip>
						);
					}
					return t('Energy Produced Kwh');
				},
				exportHeader: selectedSystems.length > 1 ? t('Σ Energy Produced Kwh') : t('Energy Produced Kwh'),
				accessor: 'ENERGY_PRODUCED_KWH',
			},
		],
		[t, selectedSystems.length],
	);

	const setDatesValue = value => {
		setDatesRange(prevDatesRange => ({
			...prevDatesRange,
			...value,
		}));
	};

	useEffect(() => {
		setSelectedSystems(meteoSystems.map(system => system.value));
	}, [meteoSystems]);

	useEffect(() => {
		if (meteoSystems.length === 0 || datesRange.startDate === null || datesRange.endDate === null) {
			return;
		}
		(async () => {
			try {
				const response = await getProjectMeteocontrolData(
					abortController.signal,
					projectId,
					meteoSystems.map(system => system.value),
					datesRange,
				);
				setTableData(response.data);
			} catch (err) {
				showToastError(err, 'Failed loading Meteocontrol data');
			}
		})();
	}, [abortController.signal, projectId, meteoSystems, datesRange]);

	// Re-structure meteo data as table data
	const data = useMemo(() => {
		const filteredTableData = tableData
			.filter(system => selectedSystems.includes(system.systemKey))
			.reduce((accData, currSystem) => {
				currSystem?.basicMeasurments
					?.map(item => ({
						date: formatDate(item.createdAt),
						...item.values,
						...currSystem?.calculations?.find(calc => calc.createdAt === item.createdAt)?.values,
					}))
					.forEach(item => {
						const index = accData.findIndex(accItem => accItem.date === item.date);
						if (index === -1) {
							accData.push({
								...item,
								E_Z_EVU: item.E_Z_EVU || 0,
								G_M0: item.G_M0 || 0,
								PV_CURTAILED: item.PV_CURTAILED || 0,
								E_GRID: item.E_GRID || 0,
								PR: item.PR || 0,
								VFG: item.VFG || 0,
								CO2_T: co2SavingMeasure([item.E_Z_EVU, formatDecimal(project?.country?.gridEmissionFactor)]) || 0,
								ENERGY_PRODUCED_KWH: item.E_Z_EVU || 0,
							});
						} else {
							accData[index] = {
								date: item.date,
								E_Z_EVU: sumMeasure([item.E_Z_EVU, accData[index].E_Z_EVU]),
								G_M0: averageMeasure([item.G_M0, accData[index].G_M0]),
								PV_CURTAILED: sumMeasure([item.PV_CURTAILED, accData[index].PV_CURTAILED]),
								E_GRID: sumMeasure([item.E_GRID, accData[index].E_GRID]),
								PR: averageMeasure([item.PR, accData[index].PR]),
								VFG: averageMeasure([item.VFG, accData[index].VFG]),
								CO2_T: co2SavingMeasure([
									sumMeasure([item.E_Z_EVU, accData[index].E_Z_EVU]),
									formatDecimal(project?.country?.gridEmissionFactor),
								]),
								ENERGY_PRODUCED_KWH: sumMeasure([item.E_Z_EVU, accData[index].E_Z_EVU]),
							};
						}
					});

				return accData;
			}, []);

		const tableFirstRowData = {
			date: filteredTableData.length,
			E_Z_EVU: sumMeasure(filteredTableData.map(item => item.E_Z_EVU)),
			G_M0: averageMeasure(filteredTableData.map(item => item.G_M0)),
			PV_CURTAILED: sumMeasure(filteredTableData.map(item => item.PV_CURTAILED)),
			E_GRID: sumMeasure(filteredTableData.map(item => item.E_GRID)),
			PR: averageMeasure(filteredTableData.map(item => item.PR)),
			VFG: averageMeasure(filteredTableData.map(item => item.VFG)),
			CO2_T: sumMeasure(filteredTableData.map(item => item.CO2_T)),
			ENERGY_PRODUCED_KWH: sumMeasure(filteredTableData.map(item => item.E_Z_EVU)),
		};

		return filteredTableData.length ? [].concat(tableFirstRowData, filteredTableData) : [];
	}, [tableData, selectedSystems, project.country.gridEmissionFactor]);

	const exportedData = useMemo(
		() => [
			[...columns.map(column => column.exportHeader)],
			...data.map(row => [
				row.date,
				row.E_Z_EVU,
				row.G_M0,
				row.PV_CURTAILED,
				row.E_GRID,
				row.PR,
				row.VFG,
				row.CO2_T,
				row.ENERGY_PRODUCED_KWH,
			]),
		],
		[data, columns],
	);

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable({
			data,
			columns,
		});
	return (
		<Tile
			title="Measurements"
			data-section="measurements"
			isTable
			rightComponent={
				<Grid container spacing={1}>
					{data.length !== 0 && (
						<Grid item>
							<Button icon="download" small secondary>
								<CSVLink data={exportedData} filename={`${externalId} - ${clientName} - Measurements.csv`}></CSVLink>
							</Button>
						</Grid>
					)}

					<Grid item>
						<MonthSelector
							setFilterValue={setDatesValue}
							startDateName="startDate"
							endDateName="endDate"
							startDate={datesRange.startDate}
							endDate={datesRange.endDate}
						/>
					</Grid>
					<Grid item>
						<SystemsSelector
							systems={meteoSystems}
							selectedSystems={selectedSystems}
							setSelectedSystems={setSelectedSystems}
							count={selectedSystems.length}
						/>
					</Grid>
				</Grid>
			}
		>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
				/>
			</TableWrapper>
		</Tile>
	);
};

MeasurementsTile.propTypes = {
	project: PropTypes.shape({
		id: PropTypes.number,
		externalId: PropTypes.number,
		client: PropTypes.shape({
			name: PropTypes.string,
		}),
		country: PropTypes.shape({ gridEmissionFactor: PropTypes.number }),
	}),
	meteoSystems: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		}),
	),
};

MeasurementsTile.defaultProps = {
	project: {
		id: '',
		externalId: '',
		client: {
			name: '',
		},
	},
	meteoSystems: [],
};

export default MeasurementsTile;
