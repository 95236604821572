import api from 'Application/api/api';
import getProjectsParamEncoder from 'Projects/api/customParamEncoders/getProjectsParamEncoder';
import defaultDashboardParams from './constants/defaultDashboardParams';

const getMyActiveNoneOpenProjects = (signal, filters, page = 0) =>
	api.get('/projects/my-active-none-open-projects', {
		params: {
			page,
			size: 10,
			...filters,
			...defaultDashboardParams,
		},
		signal,
		timeout: 100000,
		paramsSerializer: {
			encode: getProjectsParamEncoder.bind(undefined, Object.keys(filters)),
			indexes: true,
			dots: true,
		},
	});

export default getMyActiveNoneOpenProjects;
