import colors from 'Application/theme/colors';

export const statuses = {
	OPEN: 'OPEN',
	OFFER_RECEIVED: 'OFFER_RECEIVED',
	DECLINED_BY_ECOLIGO: 'DECLINED_BY_ECOLIGO',
	DECLINED_BY_PARTNER: 'DECLINED_BY_PARTNER',
	SIGNED: 'SIGNED',
	AWARDED: 'AWARDED',
	CLOSED: 'CLOSED',
	OFFER_SENT: 'OFFER_SENT',
};

export const statusOptions = {
	[statuses.OPEN]: 'Open',
	[statuses.OFFER_RECEIVED]: 'Offer received',
	[statuses.DECLINED_BY_ECOLIGO]: 'Declined by ecoligo',
	[statuses.DECLINED_BY_PARTNER]: 'Declined by Partner',
	[statuses.SIGNED]: 'Signed',
	[statuses.AWARDED]: 'Awarded',
	[statuses.CLOSED]: 'Closed',
	[statuses.OFFER_SENT]: 'Offer sent',
};

export const statusColors = {
	[statuses.OFFER_RECEIVED]: colors.primary.main,
	[statuses.OFFER_SENT]: colors.primary.main,
	[statuses.OPEN]: colors.warning.main,
	Primary: colors.primary.dark,
	[statuses.DECLINED_BY_ECOLIGO]: colors.common.brown,
	[statuses.DECLINED_BY_PARTNER]: colors.common.brown,
	[statuses.SIGNED]: colors.secondary.main,
	[statuses.AWARDED]: colors.secondary.main,
	[statuses.CLOSED]: colors.error.main,
};
