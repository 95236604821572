import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useRespondModalFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				responseChoice: yup.string(),
				unknownClient: yup.bool(),
				client: yup.mixed().when(['unknownClient', 'responseChoice'], {
					is: (unknownClient, responseChoice) => !unknownClient && responseChoice === 'newProject',
					then: yup
						.object()
						.shape({ label: yup.string(), value: yup.number() })
						.nullable()
						.required(t('Required')),
					otherwise: null,
				}),
				project: yup.mixed().when('responseChoice', {
					is: val => val === 'existingProject',
					then: yup
						.object()
						.shape({ label: yup.string(), value: yup.number() })
						.nullable()
						.required(t('Required')),
					otherwise: null,
				}),
				message: yup.mixed().when('responseChoice', {
					is: val => val === 'decline',
					then: yup.string().required(t('Message to EPC Partner is required when declining an inquiry')),
					otherwise: null,
				}),
				hideClientName: yup.bool(),
			}),
		[t],
	);
};

export default useRespondModalFormValidationSchema;
