import { useMemo } from 'react';

const fallbackValues = {
	name: '',
	blockedByActivityTemplateIds: [],
	blockerActivityTemplates: [],
};

const useActivityFieldsFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			name: data?.title,
			blockedByActivityTemplateIds: data?.blockedByActivityTemplates?.map(blockedByActivityTemplate => ({
				value: blockedByActivityTemplate.id,
				label: blockedByActivityTemplate.title,
			})),
			blockerActivityTemplates: data.blockerActivityTemplates,
		}),
		[data],
	);
export default useActivityFieldsFormInitialValues;
