import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Modal from 'Common/components/modals/Modal';
import Button from 'Common/components/buttons/Button';
import H1 from '../headers/H1';
import H5 from '../headers/H5';

const ButtonsWrapper = styled.div`
	margin-top: 40px;
	display: flex;
	justify-content: center;

	button {
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}
	}
`;

const StyledModal = styled(Modal)`
	white-space: break-spaces;
`;

const TextWrapper = styled(H5)`
	white-space: pre-line;
	text-align: center;
`;

const UnsuccessfulOperationModal = ({ onCancel, cancelText, label, heading, text, children, ...props }) => {
	const { t } = useTranslation();

	return (
		<StyledModal label={label} onRequestClose={onCancel} {...props}>
			<H1>{heading}</H1>
			{text ? <TextWrapper>{text}</TextWrapper> : null}
			{children}
			<ButtonsWrapper>
				<Button
					text={cancelText ?? t('Close')}
					onClick={onCancel}
					secondary
					label={`Unsuccessful Operation Modal - ${label} - ${cancelText ?? t('Close')} Button`}
				/>
			</ButtonsWrapper>
		</StyledModal>
	);
};

UnsuccessfulOperationModal.defaultProps = {
	onCancel: null,
	cancelText: null,
	label: 'Unsuccessful Operation Modal',
	heading: 'Unsuccessful Operation',
	text: null,
	children: null,
};

UnsuccessfulOperationModal.propTypes = {
	label: PropTypes.string,
	onCancel: PropTypes.func,
	cancelText: PropTypes.string,
	heading: PropTypes.string,
	text: PropTypes.string,
	children: PropTypes.node,
};

export default UnsuccessfulOperationModal;
