export const checkCloseCoordinates = (prevCoordinates, currCoordinate) => {
	const hasSameLongitude = prevCoordinates.some(({ longitude }) => longitude === currCoordinate.longitude);
	const hasSameLatitude = prevCoordinates.some(({ latitude }) => latitude === currCoordinate.latitude);

	return Boolean(hasSameLongitude && hasSameLatitude);
};

export const modifyCoordinates = coord => ({ ...coord, longitude: coord.longitude + 0.0001 });

export const modifyCoordinatesRecursively = (prevCoord, currCoord) =>
	checkCloseCoordinates(prevCoord, currCoord)
		? modifyCoordinatesRecursively(prevCoord, modifyCoordinates(currCoord))
		: currCoord;

export const modifyCloseCoordinates = coordinates =>
	coordinates.reduce(
		(prevCoordinates, currCoordinate) => [
			...prevCoordinates,
			modifyCoordinatesRecursively(prevCoordinates, currCoordinate),
		],
		[],
	);
