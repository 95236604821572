import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import MapLink from 'Common/components/MapLink';
import FormField from 'Common/components/form/FormField';
import EditableTile from 'Common/components/Tile/EditableTile';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import ErrorMessage from 'Common/components/form/ErrorMessage';
import formatDecimal from 'Common/utils/formatDecimal';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import loadCountryOptions from 'Country/utils/loadCountryOptions';

import editProject from 'Projects/api/editProject';
import useLocationFormValidationSchema from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/LocationTile/hooks/useLocationFormValidationSchema';
import useLocationFormInitialValues from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/LocationTile/hooks/useLocationFormInitialValues';
import styled from 'styled-components';
import sizes from 'Application/theme/sizes';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';
import axios from 'axios';

const ButtonWrapper = styled.div`
	width: 220px;
	padding-bottom: ${sizes.spacing(2)};
`;

const StyledFormField = styled(FormField)`
	position: relative;
`;

const StyledErrorMessage = styled(ErrorMessage)`
	position: relative;
	top: -10px;
	left: 0;
`;

const LocationTile = ({
	project,
	onEnterEditMode,
	onExitEditMode,
	onDirtyForm,
	onTileSave,
	highlightLabels,
}) => {
	const { t } = useTranslation();

	const [countries, setCountries] = useState([]);
	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [showMissingGEFWarning, setShowMissingGEFWarning] = useState(false);

	const abortController = useAbortController();

	const initialValues = useLocationFormInitialValues(project);
	const validationSchema = useLocationFormValidationSchema();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.SALES,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.PM_TECHNICAL,
	]);

	const {
		errors,
		touched,
		values,
		handleChange,
		handleBlur,
		handleSubmit,
		dirty,
		setFieldValue,
		resetForm,
		setFieldTouched,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			setIsSavingChanges(true);
			let response;
			try {
				const [latitude, longitude] = values.locationText
					? values.locationText.split(',').map(item => item.trim())
					: [undefined, undefined];

				response = await editProject(abortController.signal, {
					...project,
					...pick(values, ['locationUrl']),
					latitude,
					longitude,
					gridEmissionFactor: values.gridEmissionFactor || null,
					countryId: values.country ? values.country.value : null,
				});

				if (onDirtyForm) {
					onDirtyForm(false);
				}

				await onTileSave(response.data);
				handleCancel();
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});

	const handleCountryChange = country => {
		if (!country.gridEmissionFactor) {
			setShowMissingGEFWarning(true);
		} else {
			setShowMissingGEFWarning(false);
		}
		setFieldValue('gridEmissionFactor', country.gridEmissionFactor || '');
		setFieldValue('country', country);
	};

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	useEffect(() => {
		(async () => {
			try {
				const response = await loadCountryOptions(abortController.signal, undefined, ['gridEmissionFactor']);
				setCountries(response);
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [abortController.signal]);

	useEffect(() => {
		if (!project.country?.gridEmissionFactor) {
			setShowMissingGEFWarning(true);
		}
	}, [project.country]);

	return (
		<EditableTile
			title={t('Location')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<>
				<FormField>
					<FormikSelect
						id="country"
						name="country"
						label={t('Country')}
						value={values.country}
						error={errors.country}
						touched={touched.country}
						onBlur={handleBlur}
						options={countries}
						onChange={handleCountryChange}
						isTile
						isRequired
						isInEditMode={isInEditMode}
						isHighlighted={highlightLabels.includes(
							PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_LOCATION.PROJECT_COUNTRY,
						)}
					/>
				</FormField>
				{showMissingGEFWarning && isInEditMode && (
					<StyledErrorMessage
						message={t('A Grid emission factor for this country does not exist. Please add in Preferences.')}
					/>
				)}
				<StyledFormField>
					<FormikInput
						id="gridEmissionFactor"
						name="gridEmissionFactor"
						label={t('Grid emission factor')}
						value={isInEditMode ? values.gridEmissionFactor : formatDecimal(values.gridEmissionFactor, 3)}
						error={errors.gridEmissionFactor}
						touched={touched.gridEmissionFactor}
						setFieldTouched={setFieldTouched}
						onChange={handleChange}
						onBlur={handleBlur}
						tooltip={{
							tooltip: t('Changing the Country will update the Grid emission factor'),
						}}
						isTile
						isInEditMode={isInEditMode}
						isHighlighted={highlightLabels.includes(
							PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_LOCATION.GRID_EMISSION_FACTOR,
						)}
					/>
				</StyledFormField>
			</>
			{isInEditMode ? (
				<FormField>
					<FormikInput
						id="locationUrl"
						name="locationUrl"
						label={t('Google Maps Link')}
						value={values.locationUrl}
						error={errors.locationUrl}
						touched={touched.locationUrl}
						placeholder={t('Paste here')}
						onChange={handleChange}
						onBlur={handleBlur}
						isTile
						isInEditMode={isInEditMode}
						isHighlighted={highlightLabels.includes(
							PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_LOCATION.LOCATION_URL,
						)}
					/>
				</FormField>
			) : (
				<ButtonWrapper>
					<MapLink
						href={
							project?.locationUrl?.includes('http') || project?.locationUrl?.includes('https')
								? project?.locationUrl
								: project?.locationUrl
								? `http://${project?.locationUrl}`
								: project?.locationUrl
						}
					/>
				</ButtonWrapper>
			)}

			<FormField>
				<FormikInput
					id="locationText"
					name="locationText"
					label={t('Latitude and Longitude')}
					value={values.locationText}
					error={errors.locationText}
					touched={touched.locationText}
					placeholder={t('Paste here')}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_LOCATION.LOCATION_TEXT,
					)}
				/>
			</FormField>
		</EditableTile>
	);
};

LocationTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onSave: undefined,
	onDirtyForm: undefined,
	onTileSave: async () => {},
	highlightLabels: [],
};

LocationTile.propTypes = {
	project: PropTypes.shape({
		country: PropTypes.shape({
			gridEmissionFactor: PropTypes.number,
		}),
		locationUrl: PropTypes.string,
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	onTileSave: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default LocationTile;
