import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import { push } from 'redux-first-history';
import getEpcPartners from 'EpcPartner/api/getEpcPartners';
import EpcPartnerNameWithTier from 'EpcPartner/components/EpcPartnerNameWithTier';
import { DefaultTable } from 'Common/components/table/table';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import { useSortBy } from 'react-table';
import { useSortingQueryParameter } from 'Common/hooks/table/plugin-hooks/useSortingQueryParameter';
import { useScrollableTableBody } from 'Common/hooks/table/plugin-hooks/useScrollableTableBody';
import { useVirtualizedTableBody } from 'Common/hooks/table/plugin-hooks/useVirtualizedTableBody';
import { debounce, fill } from 'lodash';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import Link from 'Common/components/Link';
import colors from 'Application/theme/colors';
import UsersPreview from 'Common/components/usersPreview/UsersPreview';
import showToastError from 'Common/utils/showToastError';
import { PropTypes } from 'prop-types';
import useUniqueAbortSignal from 'Common/hooks/useUniqueAbortSignal';

const Wrapper = styled.div`
	position: relative;
	pointer-events: ${({ hasOpenFilter }) => (hasOpenFilter ? 'none' : 'all')};
`;

const Managers = styled.div`
	display: flex;
`;

const defaultSortBy = {
	id: 'name',
	desc: false,
};

const sortPropertiesMap = {
	cityRegion: 'address.cityRegion',
	primaryContactName: 'firstName',
};

const EpcPartnersListTable = () => {
	const { t } = useTranslation();
	const hasOpenFilter = !!useSelector(state => state?.tables?.epcPartners?.openedFilter);

	const [totalItemsCount, setTotalItemsCount] = useState(25);

	const sortByQueryParam = useQueryParameter('sortBy');
	const sortBy = sortByQueryParam ?? defaultSortBy;
	const filters = useQueryParameter('filters');

	const searchQuery = useQueryParameter('searchQuery');

	const dispatch = useDispatch();

	const [epcPartners, setEpcPartners] = useState(() => fill(Array(25), {}));
	const [isLoading, setIsLoading] = useState(true);

	const getUniqueSignal = useUniqueAbortSignal();

	const hasLoadedFirstResultsRef = useRef(false);

	const getEPCBatch = useCallback(
		async (startIndex, stopIndex, filters, sortBy, searchQuery, hasItemsChanges = false) => {
			if (hasItemsChanges) {
				setIsLoading(true);
			}

			const sortById = sortPropertiesMap[sortBy.id] ?? sortBy.id;

			const customFilters = { ...filters };

			try {
				let response = await getEpcPartners(
					getUniqueSignal('get-epc-partners'),
					{
						offset: startIndex,
						limit: stopIndex - startIndex + 1,
						sortBy: sortById,
						sortDirection: sortBy.desc ? 'DESC' : 'ASC',
					},
					customFilters,
					searchQuery,
				);

				if (hasItemsChanges || !hasLoadedFirstResultsRef.current) {
					const itemsCount = response.data.totalElements ?? 0;

					setEpcPartners(
						fill(Array(itemsCount), {}).map((emptyClient, index) => {
							if (startIndex <= index && index <= stopIndex) {
								const indexInResultSet = index - startIndex;

								return response.data.content[indexInResultSet];
							}

							return emptyClient;
						}),
					);

					setTotalItemsCount(itemsCount);
				} else {
					setEpcPartners(prevClients =>
						prevClients.map((prevClient, index) => {
							if (startIndex <= index && index <= stopIndex) {
								const indexInResultSet = index - startIndex;

								return response.data.content[indexInResultSet];
							}

							return prevClient;
						}),
					);
				}

				hasLoadedFirstResultsRef.current = true;

				if (hasItemsChanges) {
					setIsLoading(false);
				}
			} catch (error) {
				showToastError(error, t("Can't fetch EPC partners"));
			}
		},
		[getUniqueSignal, t],
	);

	useEffect(() => {
		getEPCBatch(
			0,
			25,
			filters,
			{
				id: sortBy.id,
				desc: sortBy.desc,
			},
			searchQuery,
			true,
		);
	}, [getEPCBatch, searchQuery, filters, sortBy.desc, sortBy.id]);

	// eslint-disable-next-line
	const handleLoadMoreRows = useCallback(
		debounce(
			(startIndex, stopIndex) => {
				getEPCBatch(
					startIndex,
					stopIndex,
					filters,
					{
						id: sortBy.id,
						desc: sortBy.desc,
					},
					searchQuery,
				);
			},
			350,
			{
				leading: false,
				trailing: true,
			},
		),
		[getEPCBatch, searchQuery, filters, sortBy.desc, sortBy.id],
	);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Name'),
				accessor: 'name',
				Cell: (() => {
					const Cell = ({ row: { original: partner } }) => (
						<Link
							color={colors.primary.main}
							fontSize="14px"
							to={`/epc-partners/details/${partner?.id}`}
							onClick={e => e.stopPropagation()}
							style={{
								textDecorationColor: partner?.internalQualificationPassed ? colors.common.black : colors.error.main,
								color: partner?.internalQualificationPassed ? colors.common.black : colors.error.main,
							}}
						>
							<EpcPartnerNameWithTier
								name={partner?.name}
								tier={partner?.internalQualificationPassed}
								hasPoints={false}
								isLink={Boolean(partner?.id)}
								showIcon={false}
							/>
						</Link>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
								internalQualificationPassed: PropTypes.bool,
								name: PropTypes.string,
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Countries'),
				accessor: 'countries',
				disableSortBy: true,
				Cell: (() => {
					const Cell = ({ row: { original: partner } }) =>
						partner?.countries ? (
							<div title={partner?.countries.map(c => c.name).join(', ')}>
								{partner?.countries.map(c => c.name).join(', ')}
							</div>
						) : (
							'-'
						);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								countries: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('City / region'),
				accessor: 'cityRegion',
				Cell: (() => {
					const Cell = ({ row: { original: partner } }) => (
						<div title={partner?.address?.cityRegion}>{partner?.address?.cityRegion}</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ address: PropTypes.shape({ cityRegion: PropTypes.string }) }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Total Projects'),
				accessor: 'totalProjects',
				isNumber: true,
				Cell: (() => {
					const Cell = ({ row: { original: partner } }) => (
						<div title={partner?.totalProjects}>{partner?.totalProjects}</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ totalProjects: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Total system size (kWp)'),
				accessor: 'totalSystemSize',
				isNumber: true,
				width: 200,
				Cell: (() => {
					const Cell = ({ row: { original: partner } }) => (
						<div title={partner?.totalSystemSize}>{partner?.totalSystemSize}</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ totalSystemSize: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Managers'),
				accessor: 'managers',
				disableSortBy: true,
				customBodyCellContentStyles: {
					overflow: 'visible',
				},
				Cell: (() => {
					const Cell = ({ row: { original: partner } }) => (
						<Managers>
							<UsersPreview
								users={{
									primaryManager: partner?.primaryManager,
									secondaryManager: partner?.secondaryManager,
								}}
								popupProps={{
									$alignment: 'right',
								}}
							/>
						</Managers>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								primaryManager: PropTypes.shape({}),
								secondaryManager: PropTypes.shape({}),
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Internal qualifications passed'),
				accessor: 'internalQualificationPassed',
				width: 240,
				Cell: (() => {
					const Cell = ({ row: { original: partner } }) => (
						<div title={partner?.internalQualificationPassed}>
							{partner?.internalQualificationPassed ? 'Yes' : 'No'}
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ internalQualificationPassed: PropTypes.bool }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
		],
		[t],
	);

	const data = useMemo(() => epcPartners, [epcPartners]);

	const itemsHash = useMemo(() => JSON.stringify({ filters, sortBy }), [filters, sortBy]);

	const handleRowClick = row => {
		if (!window.getSelection().toString()) {
			dispatch(push(`/epc-partners/details/${row.original.id}`));
		}
	};

	// Fallback to default row id getter
	const getRowId = useCallback(
		(row, relativeIndex, parent) => (parent ? [parent.id, relativeIndex].join('.') : relativeIndex),
		[],
	);

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		getTableFooterProps,
		getVirtualizedTableBodyProps,
		headerGroups,
		footerGroups,
		rows,
		prepareRow,
	} = useStandardTable(
		{
			data,
			columns,
			defaultSortBy,
			searchTerm: searchQuery,
			onRowClick: handleRowClick,
			virtualization: {
				totalRowsCount: totalItemsCount,
				rowsHash: itemsHash,
				loadMoreRows: handleLoadMoreRows,
			},
			getRowId,
		},
		useClickableTableRow,
		useCustomCellStyles,
		useSortBy,
		useSortingQueryParameter,
		useScrollableTableBody,
		useVirtualizedTableBody,
	);

	return (
		<Wrapper hasOpenFilter={hasOpenFilter}>
			<DefaultTable
				getTableProps={getTableProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				getTableFooterProps={getTableFooterProps}
				getVirtualizedTableBodyProps={getVirtualizedTableBodyProps}
				rows={rows}
				headerGroups={headerGroups}
				footerGroups={footerGroups}
				prepareRow={prepareRow}
				isLoading={isLoading || rows.length !== totalItemsCount}
				showNoResultsFound
			/>
		</Wrapper>
	);
};

export default EpcPartnersListTable;
