import styled from 'styled-components/macro';
import Loader from 'Common/components/Loader';

const LoaderContainer = styled.div`
	width: 22px;
	height: 22px;
`;

const SmallLoader = () => (
	<LoaderContainer>
		<Loader />
	</LoaderContainer>
);

export default SmallLoader;
