import { useEffect, useState } from 'react';
import checkConfirmationCodeValidity from 'User/api/checkConfirmationCodeValidity';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const useConfirmationCodeValidity = (email, code) => {
	const [isValid, setIsValid] = useState(false);
	const [isChecking, setIsChecking] = useState(true);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			if (email && code) {
				try {
					const response = await checkConfirmationCodeValidity(abortController.signal, email, code);

					if (response.data && response.data.valid) {
						setIsValid(true);
					} else {
						setIsValid(false);
					}
					setIsChecking(false);
				} catch (error) {
					showAxiosResponseError({
						error,
						toastFields: ['username', 'code'],
						fieldsMapping: {
							username: 'E-mail',
							code: 'Code',
						},
					});
					if (!axios.isCancel(error)) {
						setIsChecking(false);
					}
				}
			}
		})();
	}, [email, code, abortController.signal]);

	return {
		isValid,
		isChecking,
	};
};

export default useConfirmationCodeValidity;
