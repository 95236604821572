import { useMemo } from 'react';

const fallbackValues = {
	companyName: '',
	abbreviation: '',
	registrationNumber: '',
	taxNumber: '',
	vatNumber: '',
	country: null,
	apartmentBuilding: '',
	line1: '',
	line2: '',
	cityRegion: '',
	postCodeZip: '',
	director1: null,
	director2: null,
	director3: null,
	dateOfRegistration: null,
};

const useEntitiesFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			companyName: data?.companyName,
			abbreviation: data?.abbreviation,
			registrationNumber: data?.registrationNumber,
			taxNumber: data?.taxNumber,
			vatNumber: data?.vatNumber,
			country: data?.country,
			apartmentBuilding: data?.apartmentBuilding,
			line1: data?.line1,
			line2: data?.line2,
			cityRegion: data?.cityRegion,
			postCodeZip: data?.postCodeZip,
			director1: data?.director1,
			director2: data?.director2,
			director3: data?.director3,
			dateOfRegistration: data?.dateOfRegistration,
		}),
		[data],
	);

export default useEntitiesFormInitialValues;
