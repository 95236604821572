import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import crudModes from 'Common/constants/crudModes';
import TagForm from 'Preferences/components/Tabs/MarketingTab/Tags/TagForm/TagForm';
import { PropTypes } from 'prop-types';

const TagsCreateModal = ({ isOpen, onClose, onFormSubmit }) => {
	const { t } = useTranslation();

	return (
		<ModalForm
			label="tag-create"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			heading={t('Create Tag')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<TagForm mode={crudModes.CREATE} onSubmit={handleSubmit} onCancel={onCancel} onDirty={setIsFormDirty} />
			)}
		</ModalForm>
	);
};

TagsCreateModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default TagsCreateModal;
