import camelToNormalCase from 'Common/utils/camelToNormalCase';
import uppercaseToNormalCase from 'Common/utils/uppercaseToNormalCase';

const generateSelectControl = (controlName, optionsObject, uppercase = false) => {
	const optionsValues = Object.values(optionsObject);
	const optionsLabels = Object.values(optionsObject);

	const transformFunction = uppercase ? uppercaseToNormalCase : camelToNormalCase;

	const optionsLabelsToValues = optionsValues.reduce(
		(prev, key) => ({
			...prev,
			[key]: transformFunction(key),
		}),
		{},
	);

	return {
		[controlName]: {
			options: optionsValues,
			maps: optionsLabels,
			control: { type: 'select', labels: optionsLabelsToValues },
		},
	};
};

export default generateSelectControl;
