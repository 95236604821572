import { ReactComponent as Logo } from 'Common/assets/images/animated-logo.svg';
import styled from 'styled-components/macro';

const StyledLogo = styled(Logo)`
	max-width: 100%;
	max-height: 100%;

	.outline {
		@keyframes spin {
			0% {
				transform: rotate(0deg);
			}
			25% {
				transform: rotate(-180deg);
			}
			50% {
				transform: rotate(-180deg);
			}
			75% {
				transform: rotate(-360deg);
			}
			100% {
				transform: rotate(-360deg);
			}
		}

		transform-origin: center;
		animation: spin 1.25s linear infinite;
	}

	.point {
		@keyframes fade-in-out {
			0% {
				opacity: 1;
			}
			25% {
				opacity: 0;
			}
			50% {
				opacity: 0;
			}
			75% {
				opacity: 1;
			}
			100% {
				opacity: 1;
			}
		}

		animation: fade-in-out 1.25s linear infinite;
	}
`;

export default StyledLogo;
