export const validateStep = step => {
	const canBeCompleted = step.canBeCompleted;
	const isOpen = step.status === 'OPEN';

	return canBeCompleted && isOpen;
};

const useMyProjectsTabStepModifier = ({ step, projectStage }) => {
	const isDisabled = true;
	const showResponsiblePerson = true;
	const hideActions = true;

	const isValid = validateStep(step);
	const formattedStage =
		projectStage.stage.substr(0, 1).toUpperCase() + projectStage.stage.substr(1).toLowerCase();

	const responsiblePerson = step.responsiblePerson ? { [formattedStage]: step.responsiblePerson } : undefined;

	return { ...step, isValid, isDisabled, responsiblePerson, showResponsiblePerson, hideActions };
};

export default useMyProjectsTabStepModifier;
