import FormField from 'Common/components/form/FormField';
import { useTranslation } from 'react-i18next';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import { components } from 'react-select';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import { PropTypes } from 'prop-types';
import { SectionWrapper } from 'Common/components/modals/OverlaySections';
import { Grid } from '@mui/material';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import UNITS from 'Common/constants/units';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import { mountingTypeOptions } from 'Projects/constants/mountingTypeOptions';
import ComparableFormField from 'Common/components/form/ComparableFormField';
import formatDate from 'Common/utils/formatDate';
import Big from 'big.js';
import crudModes from 'Common/constants/crudModes';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';
import FileUploadField from 'Common/components/form/FileUploadField';
import { FILE_TYPES } from 'Common/constants/fileTypes';
import getClientOfferDownloadLink from '../../../../ClientContract/api/getClientOfferDownloadLink';
import useAbortController from 'Common/hooks/useAbortController';
import showToastError from 'Common/utils/showToastError';

const SelectInput = ({ selectProps: { onPaste }, ...props }) => (
	<components.Input {...props} onPaste={onPaste} />
);

SelectInput.defaultProps = {
	selectProps: {
		onPaste: () => null,
	},
};

SelectInput.propTypes = {
	selectProps: PropTypes.shape({
		onPaste: PropTypes.func,
	}),
};

// eslint-disable-next-line complexity
const TechnicalInputsSection = ({
	errors,
	touched,
	values,
	handleChange,
	handleBlur,
	setFieldValue,
	setFieldTouched,
	mode,
	breadcrumbList,
}) => {
	const { t } = useTranslation();
	const abortController = useAbortController();

	const CurrencySign = values.currency
		? () => <>{values.currency.value === 'EUR' ? '€' : values.currency.value}</>
		: null;

	const handleDocumentDownload = async document => {
		try {
			const response = await getClientOfferDownloadLink(
				abortController.signal,
				document.name,
				document.versionId,
			);
			window.open(response.data, '_blank');
		} catch (error) {
			showToastError(error);
		}
	};

	return mode !== crudModes.VIEW ? (
		<SectionWrapper>
			<Grid container columnSpacing={10}>
				<Grid item xs={12} md={6}>
					<ComparableFormField
						$compareValue={
							values.compareOffer && values.primaryOfferData.offerDate
								? formatDate(values.primaryOfferData.offerDate)
								: ''
						}
						data-first-field
					>
						<FormikSingleDatePicker
							id="offerDate"
							label={t('Offer Date')}
							startDateName="offerDate"
							error={errors?.offerDate}
							touched={touched?.offerDate}
							startDate={values?.offerDate}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							isOverlay
						/>
					</ComparableFormField>
					<ComparableFormField
						$compareValue={
							values.compareOffer && values.primaryOfferData.systemSize
								? `${values.primaryOfferData.systemSize} kWp`
								: ''
						}
					>
						<FormikInput
							label={t('System Size')}
							id="systemSize"
							name="systemSize"
							error={errors?.systemSize}
							touched={touched?.systemSize}
							value={values?.systemSize}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
							unit={UNITS.KWP}
						/>
					</ComparableFormField>
					<FormField>
						<FormikSelect
							id="mountingTypes"
							name="mountingTypes"
							label={t('Mounting type')}
							value={values.mountingTypes}
							error={errors.mountingTypes}
							touched={touched.mountingTypes}
							isClearable
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							options={mountingTypeOptions}
							isMulti
							isOverlay
							menuPosition="fixed"
						/>
					</FormField>
					<ComparableFormField $compareValue={values.compareOffer ? values.primaryOfferData.currency : ''}>
						<FormikSelect
							isAsync
							id="currency"
							name="currency"
							label={t('Currency')}
							value={values.currency}
							error={errors.currency}
							touched={touched.currency}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadCurrenciesOptions}
							isRequired
							isOverlay
						/>
					</ComparableFormField>
					<ComparableFormField
						$compareValue={values.compareOffer ? values.primaryOfferData.epcVolume?.valueWithCurrency : ''}
					>
						<FormikInput
							id="epcVolume"
							name="epcVolume"
							label={t('EPC Volume')}
							value={values.epcVolume}
							error={errors.epcVolume}
							touched={touched.epcVolume}
							onChange={handleChange}
							onBlur={handleBlur}
							prefix={CurrencySign}
							isOverlay
						/>
					</ComparableFormField>
					<FormField>
						<FormikCheckbox
							label={t('EPC VAT Applicable')}
							id="epcVatApplicable"
							name="epcVatApplicable"
							checked={values.epcVatApplicable}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.epcVatApplicable}
						/>
					</FormField>
					<FormField>
						<FileUploadField
							label={t('EPC Offer')}
							value={values.epcOffer}
							name="epcOffer"
							displayValue={values.epcOfferDisplayName}
							fileName="Document"
							acceptedTypes={[FILE_TYPES.DOCX, FILE_TYPES.PDF, FILE_TYPES.ODT]}
							documents={values.epcOfferDocuments}
							onDocumentDownload={handleDocumentDownload}
							breadcrumbList={breadcrumbList}
							setFieldValue={setFieldValue}
						/>
					</FormField>
					<ComparableFormField $compareValue={values.compareOffer ? values.primaryOfferData.yield : ''}>
						<FormikInput
							id="yield"
							name="yield"
							label={t('Yield')}
							value={values.yield}
							error={errors.yield}
							touched={touched.yield}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</ComparableFormField>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormField>
						<FormikCheckbox
							label={t('P90')}
							id="p90"
							name="p90"
							checked={values.p90}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.p90}
						/>
					</FormField>
					<ComparableFormField
						$compareValue={
							values.compareOffer && values.primaryOfferData.solarPvDegradation
								? `${Big(values.primaryOfferData.solarPvDegradation).times(100).toString()} %`
								: ''
						}
					>
						<FormikInput
							id="solarPvDegradation"
							name="solarPvDegradation"
							label={t('Solar PV Degradation')}
							value={values.solarPvDegradation}
							error={errors.solarPvDegradation}
							touched={touched.solarPvDegradation}
							onChange={handleChange}
							onBlur={handleBlur}
							unit={UNITS.PERCENT}
							isOverlay
						/>
					</ComparableFormField>
					<ComparableFormField
						$compareValue={
							values.compareOffer && values.primaryOfferData.solarUtilizationRate
								? `${Big(values.primaryOfferData.solarUtilizationRate).times(100).toString()} %`
								: ''
						}
					>
						<FormikInput
							id="solarUtilizationRate"
							name="solarUtilizationRate"
							label={t('Solar Utilization Rate')}
							value={values.solarUtilizationRate}
							error={errors.solarUtilizationRate}
							touched={touched.solarUtilizationRate}
							onChange={handleChange}
							onBlur={handleBlur}
							unit={UNITS.PERCENT}
							isOverlay
						/>
					</ComparableFormField>
					<ComparableFormField
						$compareValue={values.compareOffer ? values.primaryOfferData.annualOmPrice?.valueWithCurrency : ''}
					>
						<FormikInput
							id="annualOmPrice"
							name="annualOmPrice"
							label={t('Annual O&M Price')}
							value={values.annualOmPrice}
							error={errors.annualOmPrice}
							touched={touched.annualOmPrice}
							onChange={handleChange}
							onBlur={handleBlur}
							prefix={CurrencySign}
							isOverlay
						/>
					</ComparableFormField>
					<ComparableFormField
						$compareValue={
							values.compareOffer && values.primaryOfferData.annualOmEscalationRate
								? `${Big(values.primaryOfferData.annualOmEscalationRate).times(100).toString()} %`
								: ''
						}
					>
						<FormikInput
							id="annualOmEscalationRate"
							name="annualOmEscalationRate"
							label={t('Annual O&M escalation rate')}
							value={values.annualOmEscalationRate}
							error={errors.annualOmEscalationRate}
							touched={touched.annualOmEscalationRate}
							onChange={handleChange}
							onBlur={handleBlur}
							unit={UNITS.PERCENT}
							isOverlay
						/>
					</ComparableFormField>
					<ComparableFormField
						$compareValue={values.compareOffer ? values.primaryOfferData.extraCapexExpected : ''}
					>
						<FormikInput
							id="extraCapexExpected"
							name="extraCapexExpected"
							label={t('Extra CAPEX Expected')}
							value={values.extraCapexExpected}
							error={errors.extraCapexExpected}
							touched={touched.extraCapexExpected}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</ComparableFormField>
					<ComparableFormField
						$compareValue={values.compareOffer ? values.primaryOfferData?.extraOpexExpected : ''}
						data-last-field
					>
						<FormikInput
							id="extraOpexExpected"
							name="extraOpexExpected"
							label={t('Extra OPEX Expected')}
							value={values.extraOpexExpected}
							error={errors.extraOpexExpected}
							touched={touched.extraOpexExpected}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</ComparableFormField>
				</Grid>
			</Grid>
		</SectionWrapper>
	) : (
		<SectionWrapper>
			<Grid container columnSpacing={10}>
				<Grid item xs={12} md={6}>
					<KeyValueVisualization
						id="offerDate"
						title={t('Offer Date')}
						value={values.offerDate ? formatDate(values.offerDate) : ''}
					/>
					<KeyValueVisualization
						id="systemSize"
						title={t('System Size')}
						value={values.systemSize}
						unit={UNITS.KWP}
					/>
					<KeyValueVisualization
						id="mountingTypes"
						title={t('Mounting type')}
						value={values.mountingTypes.map(({ label }) => label).join(', ')}
					/>
					<KeyValueVisualization id="currency" title={t('Currency')} value={values.currency?.label} />
					<KeyValueVisualization
						id="epcVolume"
						title={t('EPC Volume')}
						value={values.epcVolume}
						prefix={CurrencySign}
					/>
					<KeyValueVisualization
						id="epcVatApplicable"
						title={t('EPC VAT Applicable')}
						value={values.epcVatApplicable ? 'Yes' : 'No'}
					/>
					<KeyValueVisualization id="yield" title={t('Yield')} value={values.yield} />
				</Grid>
				<Grid item xs={12} md={6}>
					<KeyValueVisualization id="p90" title={t('P90')} value={values.p90 ? 'Yes' : 'No'} />
					<KeyValueVisualization
						id="solarPvDegradation"
						title={t('Solar PV Degradation')}
						value={values.solarPvDegradation}
						unit={UNITS.PERCENT}
					/>
					<KeyValueVisualization
						id="solarUtilizationRate"
						title={t('Solar Utilization Rate')}
						value={values.solarUtilizationRate}
						unit={UNITS.PERCENT}
					/>
					<KeyValueVisualization
						id="annualOmPrice"
						title={t('Annual O&M Price')}
						value={values.annualOmPrice}
						prefix={CurrencySign}
					/>
					<KeyValueVisualization
						id="annualOmEscalationRate"
						title={t('Annual O&M escalation rate')}
						value={values.annualOmEscalationRate}
						unit={UNITS.PERCENT}
					/>
					<KeyValueVisualization
						id="extraCapexExpected"
						title={t('Extra CAPEX Expected')}
						value={values.extraCapexExpected}
					/>
					<KeyValueVisualization
						id="extraOpexExpected"
						title={t('Extra OPEX Expected')}
						value={values.extraOpexExpected}
					/>
				</Grid>
			</Grid>
		</SectionWrapper>
	);
};

TechnicalInputsSection.defaultProps = {
	selectedPrimaryOption: {},
	breadcrumbList: [],
};

TechnicalInputsSection.propTypes = {
	selectedPrimaryOption: PropTypes.shape({
		name: PropTypes.string,
	}),
	errors: PropTypes.shape({
		currency: PropTypes.string,
		offerDate: PropTypes.string,
		systemSize: PropTypes.string,
		mountingTypes: PropTypes.string,
		epcVolume: PropTypes.string,
		epcVatApplicable: PropTypes.string,
		epcOffer: PropTypes.string,
		yield: PropTypes.string,
		p90: PropTypes.string,
		solarPvDegradation: PropTypes.string,
		solarUtilizationRate: PropTypes.string,
		annualOmPrice: PropTypes.string,
		annualOmEscalationRate: PropTypes.string,
		extraCapexExpected: PropTypes.string,
		extraOpexExpected: PropTypes.string,
	}).isRequired,
	touched: PropTypes.shape({
		currency: PropTypes.shape({ label: PropTypes.bool, value: PropTypes.bool }),
		offerDate: PropTypes.shape({}),
		systemSize: PropTypes.bool,
		mountingTypes: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.shape({}))]),
		epcVolume: PropTypes.bool,
		epcVatApplicable: PropTypes.bool,
		epcOffer: PropTypes.bool,
		yield: PropTypes.bool,
		p90: PropTypes.bool,
		solarPvDegradation: PropTypes.bool,
		solarUtilizationRate: PropTypes.bool,
		annualOmPrice: PropTypes.bool,
		annualOmEscalationRate: PropTypes.bool,
		primaryOffer: PropTypes.bool,
		extraCapexExpected: PropTypes.bool,
		extraOpexExpected: PropTypes.bool,
	}).isRequired,
	values: PropTypes.shape({
		currency: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
		offerDate: PropTypes.instanceOf(Date),
		systemSize: PropTypes.string,
		mountingTypes: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
		epcVolume: PropTypes.string,
		epcVatApplicable: PropTypes.bool,
		epcOffer: PropTypes.shape({
			name: PropTypes.string,
		}),
		epcOfferDisplayName: PropTypes.string,
		epcOfferDocuments: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
			}),
		),
		yield: PropTypes.string,
		p90: PropTypes.bool,
		solarPvDegradation: PropTypes.string,
		solarUtilizationRate: PropTypes.string,
		annualOmPrice: PropTypes.string,
		annualOmEscalationRate: PropTypes.string,
		primaryOffer: PropTypes.bool,
		extraCapexExpected: PropTypes.string,
		extraOpexExpected: PropTypes.string,
		compareOffer: PropTypes.bool,
		primaryOfferData: PropTypes.shape({
			offerDate: PropTypes.string,
			systemSize: PropTypes.string,
			currency: PropTypes.shape({ label: PropTypes.string }),
			epcVolume: PropTypes.string,
			epcVatApplicable: PropTypes.bool,
			p90: PropTypes.bool,
			yield: PropTypes.string,
			solarPvDegradation: PropTypes.string,
			solarUtilizationRate: PropTypes.string,
			annualOmPrice: PropTypes.string,
			annualOmEscalationRate: PropTypes.string,
			extraCapexExpected: PropTypes.string,
			extraOpexExpected: PropTypes.string,
			mountingTypes: PropTypes.arrayOf(PropTypes.shape({})),
		}),
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	handleBlur: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
	breadcrumbList: PropTypes.arrayOf(
		PropTypes.shape({ label: PropTypes.string.isRequired, href: PropTypes.string }),
	),
};

export default TechnicalInputsSection;
