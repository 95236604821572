import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useEntitiesFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				companyName: yup.string().required(t('Company name is required')),
				abbreviation: yup.string().required(t('Abbreviation is required')),
				dateOfRegistration: yup.string().required(t('Date of registration is required')),
				registrationNumber: yup.string().required(t('Registration number is required')),
				taxNumber: yup.string().required(t('Tax number is required')),
				vatNumber: yup.string().required(t('VAT number is required')),
				country: yup
					.object()
					.nullable()
					.test('country', t('Country is required'), value => value?.value),
				director1: yup
					.object()
					.nullable()
					.test('director1', t('At least one director is required'), value => value?.value),
			}),
		[t],
	);
};

export default useEntitiesFormValidationSchema;
