/* eslint-disable react/prop-types */

import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';

import TileHeader from 'Common/components/Tile/TileHeader';
import ImpactChart from 'Impact/components/ImpactChart';
import { graphOptions, chartTypes, filterOptions } from 'Impact/constants/options';

import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';

import { useTranslation } from 'react-i18next';

const TileContainer = styled(Grid)`
	height: 100%;
	background-color: ${colors.common.tileBackgroundGrey};
	border-radius: 20px;
	border: 1px solid transparent;
	&:hover {
		border-color: ${colors.primary.main};
	}
`;

const TitleHeaderWrapper = styled.div`
	padding: 16px 16px 0px 16px;
`;

const GraphWrapper = styled.div`
	width: 100%;
	height: 70%;
	padding: 0 ${sizes.spacing(2)} 0 ${sizes.spacing(2)};
`;

const StyledGridHeader = styled(Grid)`
	height: 40px;
`;

const ImpactEmployeesTile = ({ data }) => {
	const { t } = useTranslation();

	const filters = {
		fields: ['ecoligoEmployees'],
		time: 'Max',
	};

	return (
		<>
			<TileContainer container>
				<StyledGridHeader item xs={12} sm={12} md={12} lg={12} xl={12}>
					<TitleHeaderWrapper>
						<TileHeader title={t('ecoligo employees')} />
					</TitleHeaderWrapper>
				</StyledGridHeader>
				<GraphWrapper>
					<ImpactChart
						data={data}
						type={chartTypes.LINE}
						options={{
							axisLabel: '#',
							...graphOptions[chartTypes.LINE],
							...filterOptions[filters.time],
						}}
						filters={filters}
					/>
				</GraphWrapper>
			</TileContainer>
		</>
	);
};

ImpactEmployeesTile.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ImpactEmployeesTile;
