import api from 'Application/api/api';
import getProjectsParamEncoder from 'Projects/api/customParamEncoders/getProjectsParamEncoder';

const getEntities = (signal, configuration, filters = {}, searchQuery) => {
	const { sortBy = 'lastModifiedDate', sortDirection = 'DESC', offset, limit } = configuration;

	const params = {
		...filters,
		name: searchQuery || filters?.name || '',
	};

	params.offset = offset;
	params.limit = limit;

	if (sortBy && sortDirection) {
		params.sort = `${sortBy},${sortDirection}`;
	}

	return api.get('/contract-parties/get-pageable', {
		params,
		signal,
		timeout: 100000,
		paramsSerializer: {
			encode: getProjectsParamEncoder.bind(undefined, Object.keys(filters)),
			indexes: true,
			dots: true,
		},
	});
};

export default getEntities;
