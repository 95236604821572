import { useMemo } from 'react';

const fallbackValues = {
	meteocontrolKeys: [],
};

const useMeteocontrolFormInitialValues = (data, options) =>
	useMemo(
		() => ({
			meteocontrolKeys:
				data?.meteocontrolKeys && options
					? options.filter(({ value }) => data.meteocontrolKeys.includes(value))
					: fallbackValues.meteocontrolKeys,
		}),
		[data, options],
	);

export default useMeteocontrolFormInitialValues;
