import api from 'Application/api/api';

const getEpcPartnersWithPortalAccess = (signal, name) =>
	api.get('/epcpartners/with-portal-access', {
		params: {
			name,
		},
		signal,
	});

export default getEpcPartnersWithPortalAccess;
