import { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import EditableTile from 'Common/components/Tile/EditableTile';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { DefaultTable, TableHeadCell, TableBodyCell } from 'Common/components/table/table';
import AssetAndRevenueRecognitionDeleteModal from 'AssetAndRevenueRecognition/components/Modals/AssetAndRevenueRecognitionDeleteModal';
import AssetAndRevenueRecognitionCreateModal from 'AssetAndRevenueRecognition/components/Modals/AssetAndRevenueRecognitionCreateModal';
import AssetAndRevenueRecognitionEditModal from 'AssetAndRevenueRecognition/components/Modals/AssetAndRevenueRecognitionEditModal';
import showToastError from 'Common/utils/showToastError';
import getAssetAndRevenueRecognitions from 'AssetAndRevenueRecognition/api/getAssetAndRevenueRecognitions';
import useAbortController from 'Common/hooks/useAbortController';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}
	${TableBodyCell} {
		background: transparent;
	}
`;

const AssetAndRevenueRecognitionList = () => {
	const { t } = useTranslation();

	const [assets, setAssets] = useState([]);
	const [isLoadingContent, setIsLoadingContent] = useState(false);
	const abortController = useAbortController();

	const [assetIdForEditing, setAssetAndRevenueRecognitionIdForEditing] = useState(null);

	const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

	const [assetForDeletion, setAssetAndRevenueRecognitionForDeletion] = useState(null);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Name'),
				accessor: 'name',
			},
			{
				Header: '',
				accessor: 'actionDots',
				maxWidth: 70,
				customCellStyles: {
					justifyContent: 'flex-end',
				},
				customBodyCellContentStyles: {
					padding: '5px',
				},
			},
		],
		[t],
	);

	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			assets.map(asset => ({
				...asset,
				name: asset?.name || '-',
				actionDots: (
					<MoreActionsButton label="Asset & Revenue Recognition More Actions" controlledVisibility>
						<MenuItem
							data-action="edit"
							onClick={() => setAssetAndRevenueRecognitionIdForEditing(asset.id)}
							label="Asset & Revenue Recognition - Edit Menu Item"
						>
							Edit
						</MenuItem>
						<MenuItem
							data-action="delete"
							onClick={() => setAssetAndRevenueRecognitionForDeletion({ id: asset.id, name: asset.name })}
							label="Asset & Revenue Recognition - Delete Menu Item"
						>
							Delete
						</MenuItem>
					</MoreActionsButton>
				),
			})),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t, assets],
	);
	const fetchAssetAndRevenueRecognition = useCallback(async () => {
		try {
			setIsLoadingContent(true);
			const response = await getAssetAndRevenueRecognitions(abortController.signal);
			setIsLoadingContent(false);

			setAssets(response.data);
		} catch (error) {
			showToastError(error);
		}
	}, [abortController.signal]);

	useEffect(() => {
		fetchAssetAndRevenueRecognition();
	}, [fetchAssetAndRevenueRecognition]);

	const handleDataUpdate = () => {
		fetchAssetAndRevenueRecognition();
	};

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
			},
			useCustomCellStyles,
		);

	return (
		<EditableTile
			title={t('Assets & Revenue Recognitions')}
			rightComponent={<TileActionButton data-button="assets" onClick={() => setIsCreateModalOpened(true)} />}
			isTable
			isLoadingContent={isLoadingContent}
		>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
				/>
			</TableWrapper>

			<AssetAndRevenueRecognitionCreateModal
				isOpen={isCreateModalOpened}
				onClose={() => setIsCreateModalOpened(false)}
				onFormSubmit={handleDataUpdate}
			/>

			{assetIdForEditing !== null && (
				<AssetAndRevenueRecognitionEditModal
					isOpen
					onClose={() => setAssetAndRevenueRecognitionIdForEditing(null)}
					onFormSubmit={handleDataUpdate}
					assetId={assetIdForEditing}
				/>
			)}

			<AssetAndRevenueRecognitionDeleteModal
				isOpen={Boolean(assetForDeletion)}
				onFormSubmit={handleDataUpdate}
				assetForDeletion={assetForDeletion}
				onCancel={() => setAssetAndRevenueRecognitionForDeletion(null)}
			/>
		</EditableTile>
	);
};

export default AssetAndRevenueRecognitionList;
