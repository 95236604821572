export const rowLabels = [
	{ label: '€ saved', unit: '€' },
	{ label: 'CO2 emission avoided (t)' },
	{ label: 'energy used (MWh)' },
];

export const columnLabels = [
	{ label: 'To Date (contract)' },
	{ label: 'To Date (lifetime)' },
	{ label: 'Anually average over contract' },
	{ label: 'Anually average over lifetime' },
	{ label: 'Contract' },
	{ label: 'Lifetime' },
];

export const cells = [
	[
		{ accessor: 'contractToDateEnergySavings' },
		{ accessor: 'contractToDateCO2EmissionsAvoided' },
		{ accessor: 'contractToDateEnergyUsed' },
	],
	[
		{ accessor: 'lifetimeToDateEnergySavings' },
		{ accessor: 'lifetimeToDateCO2EmissionsAvoided' },
		{ accessor: 'lifetimeToDateEnergyUsed' },
	],
	[
		{ accessor: 'annuallyAverageEnergySavingsOverContract' },
		{ accessor: 'annuallyAverageCO2EmissionsAvoidedOverContract' },
		{ accessor: 'annuallyAverageEnergyUsedOverContract' },
	],
	[
		{ accessor: 'annuallyAverageEnergySavingsOverLifetime' },
		{ accessor: 'annuallyAverageCO2EmissionsAvoidedOverLifetime' },
		{ accessor: 'annuallyAverageEnergyUsedOverLifetime' },
	],
	[
		{ accessor: 'contractEnergySavings' },
		{ accessor: 'contractCO2EmissionsAvoided' },
		{ accessor: 'contractEnergyUsed' },
	],
	[
		{ accessor: 'lifetimeEnergySavings' },
		{ accessor: 'lifetimeCO2EmissionsAvoided' },
		{ accessor: 'lifetimeEnergyUsed' },
	],
];
