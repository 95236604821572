import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import Big from 'Common/utils/customBig';
import ModalForm, { ModalFormButtons } from 'Common/components/form/ModalForm';

import getMilestones from 'Projects/api/getMilestones';
import PaymentMilestonesList from 'Projects/components/ProjectDetails/Tabs/EPCTab/PaymentMilestonesList/PaymentMilestonesList';

import editEpcContract from 'EpcContract/api/editEpcContract';

import colors from 'Application/theme/colors';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import sizes from 'Application/theme/sizes';

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: stretch;
	flex-direction: column;
	gap: ${sizes.spacing(2)};
`;

const TotalsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	background: ${colors.grey.light};
	padding: 10px 20px;
`;

const TotalText = styled.span`
	${({ isValid }) =>
		isValid
			? css``
			: css`
					color: ${colors.error.main};
					font-weight: 600;
			  `};
`;

const PaymentMilestonesModal = ({ isOpen, onClose, epcContract, projectId, onFormSubmit }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();

	const inputValidationSchema = yup
		.number()
		.test(
			'decimal-places',
			'Maximum 1 decimal place allowed',
			value => value === null || String(value).match(/^\d*(\.\d{1})?$/),
		)
		.typeError(t('Must be a number'))
		.transform((val, originalValue) => (typeof originalValue === 'string' && originalValue === '' ? null : val))
		.min(1, 'Must be 1 or more')
		.max(100, t('Must be less than or equals to 100'))
		.nullable();

	const [milestones, setMilestones] = useState([]);
	const [inputErrors, setInputErrors] = useState({});
	const percentageTotal = milestones.reduce((total, milestone) => {
		if (milestone.isSelected && !isNaN(milestone.percentage)) {
			return Big(total).plus(milestone.percentage);
		}
		return total;
	}, 0);
	const selectedCount = milestones.filter(milestone => milestone.isSelected).length;

	const submit = async () => {
		if (Big(percentageTotal).lt(100) && selectedCount > 0) {
			notify(t('Cannot save Milestones. Make sure all milestones add up to 100%'), {
				type: toast.TYPE.ERROR,
			});
			return;
		}

		if (Object.keys(inputErrors).length > 0) {
			return;
		}

		try {
			setIsLoading(true);
			await editEpcContract(abortController.signal, {
				...epcContract,
				annualOmEscalationRate: epcContract.annualOmEscalationRate ?? 0,
				milestoneValues: milestones
					.filter(milestone => milestone.isSelected)
					.map(milestone => ({
						epcMilestone: {
							id: milestone.id,
							name: milestone.name,
						},
						value: milestone.percentage,
					})),
				projectId,
			});

			notify(t('Milestones saved successfully'), {
				type: toast.TYPE.SUCCESS,
			});

			await onFormSubmit();
			// Re-set all fields and mark them as not touched
			onClose();
			setIsLoading(false);
		} catch (error) {
			showToastError(error);
			if (!axios.isCancel(error)) {
				setIsLoading(false);
			}
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const response = await getMilestones(abortController.signal);

				let allMilestones = response.data
					.map(milestone => ({
						isSelected:
							epcContract.milestoneValues.filter(
								currentMilestone => currentMilestone.epcMilestone.id === milestone.id,
							).length > 0,
						name: milestone.name,
						id: milestone.id,
						percentage:
							epcContract.milestoneValues.filter(
								currentMilestone => currentMilestone.epcMilestone.id === milestone.id,
							)?.[0]?.value * 100,
					}))
					.sort((milestone1, milestone2) => milestone1.id - milestone2.id);

				setMilestones(allMilestones);
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [epcContract, abortController.signal]);

	const handleMilestoneCheckboxClick = (milestoneId, isChecked) => {
		setMilestones(prevMilestones => {
			const newMilestones = [...prevMilestones];

			const index = newMilestones.findIndex(milestone => milestone.id === milestoneId);

			if (index !== -1) {
				newMilestones[index] = {
					...newMilestones[index],
					isSelected: isChecked,
					percentage: newMilestones[index].percentage || 20,
				};
			}
			return newMilestones;
		});
	};
	const handleMilestonePercentageChange = async (milestoneId, percentage) => {
		try {
			const validation = await inputValidationSchema.validate(percentage).catch(e =>
				setInputErrors(prev => ({
					...prev,
					[milestoneId]: { type: e.type, message: e.message },
				})),
			);

			if (validation !== undefined) {
				setInputErrors(prevErrors => {
					const remainingErrors = { ...prevErrors };
					delete remainingErrors[milestoneId];
					return remainingErrors;
				});
			}
			setMilestones(prevMilestones => {
				const newMilestones = [...prevMilestones];

				const index = newMilestones.findIndex(milestone => milestone.id === milestoneId);

				if (index !== -1) {
					newMilestones[index] = {
						...newMilestones[index],
						percentage: isNaN(percentage) ? percentage : Number(percentage),
					};
				}

				return newMilestones;
			});
		} catch (err) {
			showToastError(err);
		}
	};

	return (
		<ModalForm
			label="Payment Milestones Edit"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			submitButtonText="Save"
			heading={t('Edit Payment Milestones')}
		>
			{() => (
				<Container>
					<PaymentMilestonesList
						milestones={milestones}
						onCheckboxClick={handleMilestoneCheckboxClick}
						onPercentageChange={handleMilestonePercentageChange}
						validationError={inputErrors}
					/>
					<TotalsContainer>
						<span>{`${selectedCount} active`}</span>
						<TotalText isValid={Big(percentageTotal).eq(100)}>
							{`${!isNaN(Number(percentageTotal)) ? percentageTotal : '-'} %`}
						</TotalText>
					</TotalsContainer>
					<ModalFormButtons
						onCancel={onClose}
						onConfirm={submit}
						isLoading={isLoading}
						label="Project Details Contract Payment Milestones List"
						submitButtonText={t('Save')}
					/>
				</Container>
			)}
		</ModalForm>
	);
};

PaymentMilestonesModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	epcContract: PropTypes.shape({
		annualOmEscalationRate: PropTypes.number,
		milestoneValues: PropTypes.arrayOf(PropTypes.number),
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	projectId: PropTypes.string.isRequired,
};

export default PaymentMilestonesModal;
