/* eslint-disable react/prop-types */
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';

import formatDecimal from 'Common/utils/formatDecimal';

import Tile from 'Common/components/Tile/Tile';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import {
	DefaultTable,
	TableHeadCell,
	TableBodyCell,
	TableHeadCellContent,
} from 'Common/components/table/table';
import UsersPreview from 'Common/components/usersPreview/UsersPreview';
import { useCustomRowStyle } from 'Common/hooks/table/plugin-hooks/useCustomRowStyle';
import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import Big from 'big.js';
import CountryOverlay from '../Overlay/CountryOverlay';
import crudModes from 'Common/constants/crudModes';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}
	${TableBodyCell} {
		background: transparent;
	}
	${TableHeadCellContent} {
		white-space: normal;
	}
`;

const Managers = styled.div`
	display: flex;
	width: 30px;
	height: 30px;
	aspect-ratio: 1;
`;

const CountriesList = ({ countries, isLoadingContent, handleDataUpdate, handleCountryRowClick }) => {
	const { t } = useTranslation();
	const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Country'),
				accessor: 'country',
			},
			{
				Header: t('Regional hub'),
				accessor: 'regionalHub',
				Cell: ({ row: { original: data } }) => (data?.regionalHub ? data?.regionalHub?.name : ''),
			},
			{
				Header: t('Primary'),
				accessor: 'primaryManagers',
				customBodyCellContentStyles: {
					overflow: 'visible',
				},
			},
			{
				Header: t('Secondary'),
				accessor: 'secondaryManagers',
				customBodyCellContentStyles: {
					overflow: 'visible',
				},
			},
			{
				Header: t('Grid emission factor'),
				accessor: 'gridEmissionFactor',
				minWidth: 160,
				Cell: ({ row: { original: data } }) => formatDecimal(data?.gridEmissionFactor, 3),
			},
			{
				Header: t('Grid tariff escalation rate per year'),
				accessor: 'gridTariffEscalationRatePerYear',
				minWidth: 180,
				Cell: (() => {
					const Cell = ({ row: { original: data } }) =>
						data?.gridTariffEscalationRatePerYear || data?.gridTariffEscalationRatePerYear === 0
							? `${formatDecimal(data?.gridTariffEscalationRatePerYear, 2)} %`
							: null;
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ gridTariffEscalationRatePerYear: PropTypes.number }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('VAT'),
				accessor: 'vat',
				maxWidth: 85,
				Cell: (() => {
					const Cell = ({ row: { original: data } }) =>
						data?.vat ? `${Big(data.vat).times(100).toString()} %` : null;
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ vat: PropTypes.number }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('WHT'),
				accessor: 'wht',
				maxWidth: 85,
				Cell: (() => {
					const Cell = ({ row: { original: data } }) =>
						data?.wht ? `${Big(data.wht).times(100).toString()} %` : null;
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ wht: PropTypes.number }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('WHT for Service Fee Only'),
				accessor: 'whtServiceFeeOnly',
				maxWidth: 160,
				Cell: (() => {
					const Cell = ({ row: { original: data } }) => (data?.whtServiceFeeOnly ? 'Yes' : 'No');
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ whtServiceFeeOnly: PropTypes.bool }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
		],
		[t],
	);

	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			countries.map(country => ({
				...country,
				country: country.name || '-',
				primaryManagers: (
					<Managers>
						{country.primaryManager && (
							<UsersPreview
								users={{
									primaryManager: country.primaryManager,
								}}
							/>
						)}
					</Managers>
				),
				secondaryManagers: (
					<Managers>
						{country.secondaryManager && (
							<UsersPreview
								users={{
									secondaryManager: country.secondaryManager,
								}}
							/>
						)}
					</Managers>
				),
				actions: null,
			})),
		[countries],
	);

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
				onRowClick: handleCountryRowClick,
				getCustomRowStyles: () => ({
					cursor: 'pointer',
				}),
			},
			useClickableTableRow,
			useCustomCellStyles,
			useCustomRowStyle,
		);

	return (
		<Tile
			title="Countries"
			rightComponent={<TileActionButton data-button="countries" onClick={() => setIsCreateModalOpened(true)} />}
			isLoadingContent={isLoadingContent}
			heightFitConent
			isTable
		>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
				/>
			</TableWrapper>

			<CountryOverlay
				isOpen={isCreateModalOpened}
				onFormSubmit={handleDataUpdate}
				onClose={() => setIsCreateModalOpened(false)}
				mode={crudModes.CREATE}
			/>
		</Tile>
	);
};

export default CountriesList;
