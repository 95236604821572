import useAbortController from 'Common/hooks/useAbortController';
import { useCallback } from 'react';
import addComment from 'Projects/api/addComment';
import showToastError from 'Common/utils/showToastError';
import { useTranslation } from 'react-i18next';
import deleteComment from 'Projects/api/deleteComment';
import editComment from 'Projects/api/editComment';
import { useMemo } from 'react';

const useStepCommentsData = (project, step) => {
	const { t } = useTranslation();
	const defaultComments = useMemo(() => step?.comments, [step?.comments]);
	const abortController = useAbortController();

	const onAddComment = useCallback(
		async newCommentText => {
			try {
				const newCommentsResponse = await addComment(abortController.signal, {
					projectId: project.id,
					text: newCommentText,
					stepId: step.id,
				});

				return newCommentsResponse.data;
			} catch (err) {
				showToastError(err, t('A problem occured while trying to send a comment'));
			}
		},
		[abortController.signal, project.id, step.id, t],
	);

	const onEditComment = useCallback(
		async (editedCommentId, editedCommentText) => {
			try {
				const commentChange = {
					commentId: editedCommentId,
					text: editedCommentText,
				};
				await editComment(abortController.signal, commentChange);
			} catch (err) {
				showToastError(err, t('Could not edit comment.'));
			}
		},
		[abortController.signal, t],
	);

	const onDeleteComment = useCallback(
		async deletedCommentId => {
			try {
				await deleteComment(abortController.signal, deletedCommentId);
			} catch (err) {
				showToastError(err, t('Could not delete comment.'));
			}
		},
		[abortController.signal, t],
	);

	return { defaultComments, onAddComment, onEditComment, onDeleteComment };
};

export default useStepCommentsData;
