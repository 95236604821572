export const costExceeded = {
	NO_EXTRA_CODE: 'NO_EXTRA_CODE',
};

export const costExceededOptions = [
	{
		label: 'No extra costs',
		value: costExceeded.NO_EXTRA_CODE,
	},
];
