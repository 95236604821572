import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useCampaignFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				name: yup.string().required(t('Required')),
				systemSize: yup
					.number()
					.test(
						'decimal-places',
						'System size must have no more than 1 decimal place',
						value => value === undefined || String(value).match(/^\d*(\.\d{1})?$/),
					)
					.typeError(t('System size must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? undefined : val,
					)
					.nullable(),
				co2: yup.number().integer().typeError(t('CO2 savings must be a number')),
				minimumVolume: yup
					.number()
					.integer()
					.typeError(t('Min finance volume must be a number'))
					.required('Required'),
				maximumVolume: yup
					.number()
					.integer()
					.typeError(t('Max finance volume must be a number'))
					.required('Required')
					.when('minimumVolume', (minimumVolume, schema) =>
						schema.min(minimumVolume, t('Max finance volume must be greater than min finance volume')),
					),
				externalId: yup.number().integer(t('ID must be an integer')).typeError(t('ID must be a number')),
				financeDetailsProjects: yup
					.array()
					.of(
						yup.object().shape({
							systemSizeAllocatedValue: yup
								.number()
								.typeError(t('Must be a number'))
								.positive(t('Must be positive'))
								.test('decimal-places', 'Must have no more than 2 decimal place', function () {
									return String(this.originalValue).match(/^\d*(\.\d{2})?$/);
								})
								.nullable()
								.required(t('Required')),
						}),
					)
					.min(1, t('At least 1 Project must be assigned')),
			}),
		[t],
	);
};

export default useCampaignFormValidationSchema;
