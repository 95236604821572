import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

import customGAEvent from 'Common/utils/customGAEvent';

const StyledTextButton = styled.button`
	color: ${colors.primary.dark};
	text-decoration: underline;
	font-weight: bold;
	font-size: ${props => props.fontSize || '14px'};
	background: none;
	border: 0 none;
	padding: 0;
	cursor: pointer;
	display: block;
	outline: none;

	&[disabled] {
		cursor: not-allowed;
		color: ${colors.grey.main};
	}
`;

const TextButton = ({ label, onClick, text, icon, type }) => (
	<StyledTextButton
		type={type}
		onClick={(...args) => {
			customGAEvent('button', 'click', label);
			onClick(...args);
		}}
	>
		{icon ? icon : text}
	</StyledTextButton>
);

TextButton.defaultProps = {
	label: null,
	type: 'button',
	onClick: () => {},
	icon: null,
	text: '',
};

TextButton.propTypes = {
	label: PropTypes.string,
	type: PropTypes.string,
	onClick: PropTypes.func,
	text: PropTypes.string,
	icon: PropTypes.node,
};

export default TextButton;
