import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import Loader from 'Common/components/Loader';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from 'Common/components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'Common/components/breadcrumbs/Breadcrumb';
import getInvestorById from 'Investors/api/getInvestorById';
import colors from 'Application/theme/colors';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import isAuthorized from 'User/utils/isAuthorized';
import { roles } from 'User/constants/roles';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import deleteInvestor from 'Investors/api/deleteInvestor';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import axios from 'axios';
import TabsContainer from 'Common/components/tabs';
import GeneralTab from 'Investors/components/InvestorsDetails/Tabs/GeneralTab';
import { INVESTOR_DETAILS_PAGE_TILES_BY_TAB } from 'Investors/constants/investorTabForms';

const StyledBreadcrumbs = styled(Breadcrumbs)`
	flex: 1;
	min-width: 300px;
`;

const ContentWrapper = styled.div`
	background: ${colors.grey.lightest};
`;

const StyleHeaderTitle = styled(Breadcrumbs)`
	text-transform: capitalize;
`;

const InvestorDetails = () => {
	const [investor, setInvestor] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [deletionError, setDeletionError] = useState(null);

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { investorId } = useParams();

	const abortController = useAbortController();

	const handleDeleteInvestorSubmit = async () => {
		try {
			await deleteInvestor(abortController.signal, investorId);
			notify(t('Investor deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});
			dispatch(push('/investors'));
			setShowConfirmModal(false);
		} catch (error) {
			if (!axios.isCancel(error)) {
				setDeletionError(error.response.data);
				setShowConfirmModal(false);
			}
		}
	};

	useEffect(() => {
		setIsLoading(true);

		(async () => {
			try {
				const response = await getInvestorById(abortController.signal, investorId);
				setInvestor(response.data);
				setIsLoading(false);
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setInvestor(null);
					setIsLoading(false);
				}
			}
		})();
	}, [investorId, abortController.signal]);

	const tabsConfig = useMemo(
		() => [
			{
				label: 'General',
				PanelComponent: GeneralTab,
				panelProps: { investor },
				tabForms: INVESTOR_DETAILS_PAGE_TILES_BY_TAB.GENERAL,
			},
		],
		[investor],
	);

	return (
		<div>
			{isLoading || investor === null ? (
				<LoaderWrapper $isFullPage>
					<Loader />
				</LoaderWrapper>
			) : (
				<>
					<HeaderContainer>
						<StyledBreadcrumbs>
							<Breadcrumb to="/investors">{t('Investors')}</Breadcrumb>
							<Breadcrumb active>
								<StyleHeaderTitle>{`${investor.first_name} ${investor.last_name}`}</StyleHeaderTitle>
							</Breadcrumb>
						</StyledBreadcrumbs>
						{isAuthorized([roles.ADMIN, roles.PM_FUNDRAISING, roles.HEAD_OF_FUNDRAISING]) && (
							<HeaderActions>
								<MoreActionsButton label="Investor Details More Actions">
									<MenuItem onClick={() => setShowConfirmModal(true)} label="Investor Details - Delete Menu Item">
										{t('Delete')}
									</MenuItem>
								</MoreActionsButton>
							</HeaderActions>
						)}
					</HeaderContainer>
					<ContentWrapper>
						<TabsContainer page="investor" tabsConfig={tabsConfig} />
						<ConfirmModal
							isOpen={showConfirmModal}
							onConfirm={() => handleDeleteInvestorSubmit()}
							onCancel={() => setShowConfirmModal(false)}
							label="delete-investor"
							heading={t('Delete Investor')}
							text={t(`Are you sure you want to delete investor ${investor.first_name} ${investor.last_name}?`)}
						/>
						<UnsuccessfulOperationModal
							label="cannot-delete-investor"
							isOpen={deletionError !== null}
							onCancel={() => setDeletionError(null)}
							heading={t('Cannot delete Investor')}
							text={deletionError?.violations?.[0]?.message}
						/>
					</ContentWrapper>
				</>
			)}
		</div>
	);
};

export default InvestorDetails;
