import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import isAuthorized from 'User/utils/isAuthorized';
import colors from 'Application/theme/colors';

const SectionTitle = styled.div`
	margin-bottom: 12px;
	color: ${colors.text.primary};
	font-weight: 200;
	font-size: 14px;
	opacity: ${props => (props.isShrank ? 0 : 0.6)};
	transition: opacity 0.6s ease;
	padding-left: 32px;
`;

const SidebarSectionTitle = ({ authorizedRoles, children, ...props }) => {
	if (authorizedRoles && !isAuthorized(authorizedRoles)) {
		return null;
	}

	return <SectionTitle {...props}>{children}</SectionTitle>;
};

SidebarSectionTitle.defaultProps = {
	authorizedRoles: undefined,
};

SidebarSectionTitle.propTypes = {
	authorizedRoles: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.node.isRequired,
};

export default SidebarSectionTitle;
