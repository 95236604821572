import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';

import useTotalsInitialValues from './hooks/useTotalsInitialValues';
import useReponsive from 'Common/hooks/useResponsive';
import getTotals from './api/getTotals';

import ImpactMobile from 'Impact/layouts/ImpactMobile';
import ImpactSmallScreen from 'Impact/layouts/ImpactSmallScreen';
import ImpactBigScreen from 'Impact/layouts/ImpactBigScreen';
import ImpactLargeScreen from 'Impact/layouts/ImpactLargeScreen';

import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';

import getStatistics from 'Impact/api/getStatistics';
import useStatisticsInitialValues from 'Impact/hooks/useStatisticsInitialValues';
import loadImpactStepNames from 'Impact/utils/loadImpactStepNames';

import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import colors from 'Application/theme/colors';

import styled from 'styled-components/macro';
import showToastError from 'Common/utils/showToastError';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import Loader from 'Common/components/Loader';
import getProjectDefaults from 'Preferences/api/getProjectDefaults';
import useAbortController from 'Common/hooks/useAbortController';
import ReactTimeAgo from 'react-time-ago';
import sizes from 'Application/theme/sizes';

const Wrapper = styled.div`
	padding: 20px;
	width: 100%;
	transition: width 0.5s, height 0.5s;

	${({ $isMobile }) =>
		$isMobile &&
		css`
			width: 100vw;
		`}
`;

const LastUpdatedWrapper = styled.div`
	background-color: ${colors.common.headerGrey};
	color: ${colors.text.primary};
	font-size: 14px;
	margin: 0;
	padding-top: ${sizes.spacing(1.5)};
	padding-bottom: ${sizes.spacing(1.5)};
	padding-left: ${sizes.spacing(2)};
`;

const Impact = () => {
	const { t } = useTranslation();
	const { isMobile, isTablet, isSmallscreen, isMediumScreen, isBigScreen, isFullHd, is4k } = useReponsive();

	const statisticsInitialValues = useStatisticsInitialValues();
	const totalsInitialValues = useTotalsInitialValues();

	const abortController = useAbortController();

	const [statistics, setStatistics] = useState(statisticsInitialValues);
	const [totals, setTotals] = useState(totalsInitialValues);
	const [stepTemplates, setStepTemplates] = useState();

	const [isLoading, setIsLoading] = useState(true);

	const currentUser = useSelector(state => state.user);

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				const response = await getStatistics(abortController.signal);
				const stepNames = await loadImpactStepNames(abortController.signal);
				const { data: totalsData } = await getTotals(abortController.signal);
				const {
					data: { allowedPercentageOfRestrictedIndustries },
				} = await getProjectDefaults(abortController.signal);

				setTotals({ ...totalsData, allowedPercentageOfRestrictedIndustries });
				setStepTemplates(stepNames);
				setStatistics(response.data);
				setIsLoading(false);
			} catch (e) {
				showToastError(e);
			}
		})();
	}, [abortController.signal]);

	const crowdPieData = {
		labels: ['active', 'not active'],
		datasets: [totals.totalCrowdInvestors, totals.notActiveCrowdInvestors],
		colors: [colors.primary.main, colors.primary.dark],
	};

	const pieData = {
		labels: ['male', 'female'],
		datasets: [totals.totalMaleCrowdInvestors, totals.totalFemaleCrowdInvestors],
		colors: [colors.primary.main, colors.primary.dark],
	};

	return (
		<>
			<HeaderContainer>
				<HeaderTitle>{`${t('Welcome')}, ${currentUser.firstName}!`}</HeaderTitle>
			</HeaderContainer>
			{totals.createdDate && (
				<LastUpdatedWrapper>
					Data last calculated:{' '}
					<ReactTimeAgo date={new Date(totals.createdDate)} timeStyle="round" tooltip={false} />
				</LastUpdatedWrapper>
			)}
			{isLoading ? (
				<LoaderWrapper $isFullPage>
					<Loader />
				</LoaderWrapper>
			) : (
				<Wrapper $isMobile={isMobile}>
					<Grid container spacing={2}>
						{is4k ? (
							<ImpactLargeScreen
								statistics={statistics}
								crowdPieData={crowdPieData}
								pieData={pieData}
								totals={totals}
								filterValues={stepTemplates}
							/>
						) : isBigScreen || isFullHd ? (
							<ImpactBigScreen
								statistics={statistics}
								crowdPieData={crowdPieData}
								pieData={pieData}
								totals={totals}
								filterValues={stepTemplates}
							/>
						) : isSmallscreen || isMediumScreen ? (
							<ImpactSmallScreen
								statistics={statistics}
								crowdPieData={crowdPieData}
								pieData={pieData}
								totals={totals}
								filterValues={stepTemplates}
							/>
						) : (
							(isTablet || isMobile) && (
								<ImpactMobile
									statistics={statistics}
									crowdPieData={crowdPieData}
									pieData={pieData}
									totals={totals}
									filterValues={stepTemplates}
								/>
							)
						)}
					</Grid>
				</Wrapper>
			)}
		</>
	);
};
export default Impact;
