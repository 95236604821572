import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';

import editSavedEuro from 'Preferences/api/editSavedEuro';
import getSavedEuro from 'Preferences/api/getSavedEuro';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';
import useMarketingFormInitialValues from 'Preferences/components/Tabs/MarketingTab/Marketing/hooks/useMarketingFormInitialValues';
import useMarketingFormValidationSchema from 'Preferences/components/Tabs/MarketingTab/Marketing/hooks/useMarketingFormValidationSchema';
import formatNumber from 'Common/utils/formatNumber';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const MarketingTile = () => {
	const { t } = useTranslation();

	const [savedEuro, setSavedEuro] = useState(null);
	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [isLoadingContent, setIsLoadingContent] = useState(false);

	const abortController = useAbortController();

	const initialValues = useMarketingFormInitialValues(savedEuro);
	const validationSchema = useMarketingFormValidationSchema();

	const { errors, touched, values, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			setIsSavingChanges(true);

			try {
				const response = await editSavedEuro(abortController.signal, {
					euro: Number(values.euro),
				});

				setSavedEuro(response.data);
				handleCancel();
				resetForm({ values });
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					handleCancel();
					resetForm({ values });
				}
			}
		},
	});

	const handleEditButtonClick = () => {
		setIsInEditMode(true);
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
	};

	const handleTileClick = () => {
		if (!isInEditMode) {
			handleEditButtonClick();
		}
	};

	const euroValue = isInEditMode ? values.euro : savedEuro?.euro ? formatNumber(savedEuro?.euro, 0) : '';

	useEffect(() => {
		(async () => {
			try {
				setIsLoadingContent(true);
				const response = await getSavedEuro(abortController.signal);
				setIsLoadingContent(false);

				resetForm({ values: { euro: response.data.euro } });
				setSavedEuro(response.data);
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [resetForm, abortController.signal]);

	return (
		<EditableTile
			title={t('Website statistics')}
			tooltip={
				<QuestionTooltip
					large
					tooltip={
						<>
							Displayed on the ecoligo website but not calculated automatically by pulse. They should be updated
							regularly.
						</>
					}
				/>
			}
			isLoadingContent={isLoadingContent}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<FormField>
				<FormikInput
					id="euro"
					name="euro"
					label={t('EUR saved for businesses over project lifetimes')}
					value={euroValue}
					error={errors.euro}
					touched={touched.euro}
					onChange={handleChange}
					onBlur={handleBlur}
					prefix="€"
					isRequired
					tooltip="Calculate using the Impact Tool, from Projects in the In Construction, In Operation and Matured stages."
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
		</EditableTile>
	);
};

export default MarketingTile;
