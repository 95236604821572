import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { toast } from 'react-toastify';

import getMarketSegments from 'MarketSegment/api/getMarketSegments';
import deleteMarketSegment from 'MarketSegment/api/deleteMarketSegment';

import MarketSegmentsEditModal from 'Preferences/components/Tabs/GeneralTab/MarketSegments/MarketSegmentsEditModal';
import MarketSegmentsCreateModal from 'Preferences/components/Tabs/GeneralTab/MarketSegments/MarketSegmentsCreateModal';

import notify from 'Common/utils/notify';
import EditableTile from 'Common/components/Tile/EditableTile';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { DefaultTable, TableHeadCell, TableBodyCell } from 'Common/components/table/table';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}
	${TableBodyCell} {
		background: transparent;
	}
`;

const MarketSegmentsList = () => {
	const { t } = useTranslation();

	const [marketSegments, setMarketSegments] = useState([]);

	const [marketSegmentIdForEditing, setMarketSegmentIdForEditing] = useState(null);

	const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

	const [marketSegmentIdForDeletion, setMarketSegmentIdForDeletion] = useState(null);

	const [deletionError, setDeletionError] = useState(null);

	const [isLoadingContent, setIsLoadingContent] = useState(false);
	const abortController = useAbortController();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Name'),
				accessor: 'name',
			},
			{
				Header: '',
				accessor: 'actionDots',
				maxWidth: 70,
				customCellStyles: {
					justifyContent: 'flex-end',
				},
				customBodyCellContentStyles: {
					padding: '5px',
				},
			},
		],
		[t],
	);

	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			marketSegments.map(marketSegment => ({
				...marketSegment,
				name: marketSegment?.name || '-',
				actionDots: (
					<MoreActionsButton label="Preference General Market Segment More Actions" controlledVisibility>
						<MenuItem
							data-action="edit"
							onClick={() => setMarketSegmentIdForEditing(marketSegment.id)}
							label="Preference General Market Segment - Edit Menu Item"
						>
							Edit
						</MenuItem>
						<MenuItem
							data-action="delete"
							onClick={() => setMarketSegmentIdForDeletion(marketSegment.id)}
							label="Preference General Market Segment - Delete Menu Item"
						>
							Delete
						</MenuItem>
					</MoreActionsButton>
				),
			})),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[marketSegments],
	);

	useEffect(() => {
		(async () => {
			try {
				setIsLoadingContent(true);
				const response = await getMarketSegments(abortController.signal);
				setIsLoadingContent(false);

				setMarketSegments(response.data.content);
			} catch (error) {
				showToastError(error);
			}
		})();
	}, [abortController.signal]);

	const handleDataUpdate = data => {
		setMarketSegments(data);
	};

	const handleDeleteMarketSegment = async id => {
		try {
			await deleteMarketSegment(abortController.signal, id);

			notify(t('Market segment deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});

			const marketSegmentsResponse = await getMarketSegments(abortController.signal);
			handleDataUpdate(marketSegmentsResponse.data.content);
			setMarketSegmentIdForDeletion(null);
		} catch (error) {
			if (!axios.isCancel(error)) {
				setDeletionError(error.response.data);
				setMarketSegmentIdForDeletion(null);
			}
		}
	};

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
			},
			useCustomCellStyles,
		);

	const marketSegmentForDeletion = marketSegmentIdForDeletion
		? marketSegments.find(marketSegment => marketSegment.id === marketSegmentIdForDeletion)
		: null;

	const hasMarketSegmentForDeletion = !!marketSegmentForDeletion && !!marketSegmentIdForDeletion;

	return (
		<EditableTile
			title={t('Market Segments')}
			rightComponent={
				<TileActionButton data-button="marketSegments" onClick={() => setIsCreateModalOpened(true)} />
			}
			isLoadingContent={isLoadingContent}
			isTable
		>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
				/>
			</TableWrapper>
			<MarketSegmentsCreateModal
				isOpen={isCreateModalOpened}
				onClose={() => setIsCreateModalOpened(false)}
				onFormSubmit={handleDataUpdate}
			/>

			{marketSegmentIdForEditing !== null && (
				<MarketSegmentsEditModal
					isOpen
					onClose={() => setMarketSegmentIdForEditing(null)}
					onFormSubmit={handleDataUpdate}
					marketSegmentId={marketSegmentIdForEditing}
				/>
			)}

			{hasMarketSegmentForDeletion && (
				<ConfirmModal
					isOpen
					onCancel={() => setMarketSegmentIdForDeletion(null)}
					onConfirm={() => handleDeleteMarketSegment(marketSegmentIdForDeletion)}
					label="delete-market-segment-confirm"
					heading={t('Delete Market Segment')}
					text={t(`Are you sure you want to delete Market segment ${marketSegmentForDeletion.name}?`)}
				/>
			)}

			<UnsuccessfulOperationModal
				label="cannot-delete-market-segment"
				isOpen={deletionError !== null}
				onCancel={() => setDeletionError(null)}
				heading={t('Cannot delete Market segment')}
				text={deletionError?.violations?.[0]?.message}
			/>
		</EditableTile>
	);
};

export default MarketSegmentsList;
