import { useCallback, useEffect, useMemo, useState } from 'react';

import crudModes from 'Common/constants/crudModes';

import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import OverlaySections from 'Common/components/modals/OverlaySections';
import getEpcOfferById from 'EpcContract/api/getEpcOfferById';
import createEpcOffer from 'EpcContract/api/createEpcOffer';
import editEpcOffer from 'EpcContract/api/editEpcOffer';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import Big from 'big.js';
import checkExchangeRateDate from 'Projects/utils/checkExchangeRateDate';
import getProjectById from 'Projects/api/getProjectById';
import { EPC_OFFER_LABELS, EPC_OFFER_TYPES } from '../../constants';

import useEstimateOverlayValues from './Estimate/hooks/useEstimateOverlayValues';
import useEstimateOverlaySections from './Estimate/hooks/useEstimateOverlaySections';
import useEstimateOverlayValidationSchema from './Estimate/hooks/useEstimateOverlayValidationSchema';
import convertEstimateToRequestData from './Estimate/utils/convertEstimateToRequestData';

import useInquiryOverlayValues from './Inquiry/hooks/useInquiryOverlayValues';
import useInquiryOverlaySections from './Inquiry/hooks/useInquiryOverlaySections';
import useInquiryOverlayValidationSchema from './Inquiry/hooks/useInquiryOverlayValidationSchema';
import convertInquiryToRequestData from './Inquiry/utils/convertInquiryToRequestData';

import useProposalOverlayValues from './Proposal/hooks/useProposalOverlayValues';
import useProposalOverlaySections from './Proposal/hooks/useProposalOverlaySections';
import useProposalOverlayValidationSchema from './Proposal/hooks/useProposalOverlayValidationSchema';
import convertProposalToRequestData from './Proposal/utils/convertProposalToRequestData';
import projectTypes from 'Projects/constants/projectTypes';
import { useOverlayTitles } from 'Common/components/modals/Overlay';

const epcOfferHooks = {
	[EPC_OFFER_TYPES.ESTIMATE]: {
		useOverlayValues: useEstimateOverlayValues,
		useOverlaySections: useEstimateOverlaySections,
		useOverlayValidationSchema: useEstimateOverlayValidationSchema,
	},
	[EPC_OFFER_TYPES.INQUIRY]: {
		useOverlayValues: useInquiryOverlayValues,
		useOverlaySections: useInquiryOverlaySections,
		useOverlayValidationSchema: useInquiryOverlayValidationSchema,
	},
	[EPC_OFFER_TYPES.PROPOSAL]: {
		useOverlayValues: useProposalOverlayValues,
		useOverlaySections: useProposalOverlaySections,
		useOverlayValidationSchema: useProposalOverlayValidationSchema,
	},
};

const epcRequestDataConverters = {
	[EPC_OFFER_TYPES.ESTIMATE]: convertEstimateToRequestData,
	[EPC_OFFER_TYPES.INQUIRY]: convertInquiryToRequestData,
	[EPC_OFFER_TYPES.PROPOSAL]: convertProposalToRequestData,
};

const EPCOfferOverlay = ({ mode, type, isOpen, onClose, onFormSubmit, project, epcOfferId }) => {
	const [epcOffer, setEpcOffer] = useState(null);
	const [isLoadingContent, setIsLoadingContent] = useState(false);
	const { t } = useTranslation();

	const projectType = useMemo(() => project?.projectType?.name, [project?.projectType?.name]);
	const projectId = useMemo(() => project?.id, [project?.id]);
	const projectSystemSize = useMemo(() => project.systemSizeKwp, [project.systemSizeKwp]);
	const isPVProject = useMemo(() => projectType === projectTypes.PV, [projectType]);
	const projectCurrencies = useMemo(() => project?.currencies, [project?.currencies]);

	const abortController = useAbortController();

	const epcOfferlabel = useMemo(() => EPC_OFFER_LABELS[type], [type]);
	const { name, label, saveBtnLabel, closeBtnLabel } = useOverlayTitles(epcOfferlabel, mode);

	useEffect(() => {
		(async () => {
			if (mode === crudModes.CREATE) return;

			setIsLoadingContent(true);
			try {
				const response = await getEpcOfferById(abortController.signal, epcOfferId, true);

				setEpcOffer(response.data);
				setIsLoadingContent(false);
			} catch (error) {
				showToastError(error, "Couldn't load Estimate");
				if (!axios.isCancel(error)) {
					setIsLoadingContent(false);
				}
			}
		})();
	}, [epcOfferId, abortController.signal, mode]);

	const breadcrumbList = [
		{
			label: t('Sales'),
			link: `/projects/details/${project?.id}`,
		},
		{
			label: epcOfferlabel,
			link: `/projects/details/${project?.id}`,
		},
		{
			label: name,
		},
	];

	const { useOverlayValues, useOverlaySections, useOverlayValidationSchema } = useMemo(
		() => epcOfferHooks[type],
		[type],
	);

	const { sectionFields, defaultValues } = useOverlayValues(project, epcOffer);
	const sections = useOverlaySections(project);
	const validationSchema = useOverlayValidationSchema();

	const convertOfferValues = useMemo(() => epcRequestDataConverters[type], [type]);
	const handleCreateSubmit = useCallback(
		async values => {
			try {
				await createEpcOffer(abortController.signal, {
					...values,
					systemSize: isPVProject ? values.systemSize : null,
					currency: values?.currency?.value,
					yield: values.yield ? values.yield : undefined,
					solarUtilisationRate: values.solarUtilisationRate
						? Big(values.solarUtilisationRate).div(100)
						: undefined,
					epcVolumeExclVat: {
						value: String(values.epcVolumeExclVat).replace(/\s/g, ''),
						currency: values?.currency?.value,
						calculated: !!values.epcVolumeSwitch,
					},
					projectId,
					epcVolumeExclVatKwp: isPVProject
						? {
								value: String(values.epcVolumeExclVatKwp).replace(/\s/g, ''),
								currency: values?.currency?.value,
								calculated: !values.epcVolumeSwitch,
						  }
						: null,
					annualOmPrice: {
						value: Number(String(values.annualOmPrice).replace(/\s/g, '')),
						currency: values?.currency?.value,
						calculated: !!values.omPriceSwitch,
					},
					annualOmPriceKwp: isPVProject
						? {
								value: String(values.annualOmPriceKwp).replace(/\s/g, ''),
								currency: values?.currency?.value,
								calculated: !values.omPriceSwitch,
						  }
						: null,
					annualOmEscalationRate: values.annualOmEscalationRate
						? Big(values.annualOmEscalationRate).div(100)
						: undefined,
					type,

					...convertOfferValues(values, mode),
				});

				const selectedCurrency = values.currency.value;

				if (selectedCurrency !== 'EUR') {
					await checkExchangeRateDate(
						abortController.signal,
						projectId,
						selectedCurrency,
						projectCurrencies.find(currency => currency.isoCode === selectedCurrency)?.exchangeRateDate,
					);
				}

				if (!isPVProject) {
					const projectResponse = await getProjectById(abortController.signal, projectId, true);

					const newSystemSize = projectResponse.data.systemSizeKwp;

					if (newSystemSize !== projectSystemSize) {
						notify(t(`Project system size set to ${newSystemSize} kWp`), {
							type: toast.TYPE.SUCCESS,
						});
					}
				}

				await onFormSubmit();

				notify(t(`${epcOfferlabel} created successfully`), {
					type: toast.TYPE.SUCCESS,
				});
				onClose();
			} catch (error) {
				showToastError(error, `Couldn't create ${epcOfferlabel}`);

				if (!axios.isCancel(error)) {
					setIsLoadingContent(false);
				}
			}
		},
		[
			abortController.signal,
			isPVProject,
			projectId,
			type,
			convertOfferValues,
			mode,
			onFormSubmit,
			t,
			epcOfferlabel,
			onClose,
			projectCurrencies,
			projectSystemSize,
		],
	);

	const handleEditSubmit = useCallback(
		async values => {
			try {
				await editEpcOffer(
					abortController.signal,
					{
						...epcOffer,
						...values,
						systemSize: isPVProject ? values.systemSize : null,
						currency: values?.currency?.value,
						yield: values.yield ? values.yield : undefined,
						solarUtilisationRate: values.solarUtilisationRate
							? Big(values.solarUtilisationRate).div(100)
							: undefined,
						projectId,
						id: epcOfferId,
						epcVolumeExclVat: {
							value: String(values.epcVolumeExclVat).replace(/\s/g, ''),
							currency: values?.currency?.value,
							calculated: !!values?.epcVolumeSwitch,
						},
						epcVolumeExclVatKwp: isPVProject
							? {
									value: String(values.epcVolumeExclVatKwp).replace(/\s/g, ''),
									currency: values?.currency?.value,
									calculated: !values.epcVolumeSwitch,
							  }
							: null,
						annualOmPrice: {
							value: String(values.annualOmPrice).replace(/\s/g, ''),
							currency: values?.currency?.value,
							calculated: !!values.omPriceSwitch,
						},
						annualOmPriceKwp: isPVProject
							? {
									value: String(values.annualOmPriceKwp).replace(/\s/g, ''),
									currency: values?.currency?.value,
									calculated: !values.omPriceSwitch,
							  }
							: null,
						primaryOffer: epcOffer?.primaryOffer,
						annualOmEscalationRate: values.annualOmEscalationRate
							? Big(values.annualOmEscalationRate).div(100)
							: undefined,
						type,

						...convertOfferValues(values, mode),
					},
					false,
				);

				const selectedCurrency = values.currency.value;

				if (selectedCurrency !== 'EUR') {
					await checkExchangeRateDate(
						abortController.signal,
						projectId,
						selectedCurrency,
						projectCurrencies.find(currency => currency.isoCode === selectedCurrency)?.exchangeRateDate,
					);
				}

				if (!isPVProject) {
					const projectResponse = await getProjectById(abortController.signal, projectId, true);

					const newSystemSize = projectResponse.data.systemSizeKwp;

					if (newSystemSize !== projectSystemSize) {
						notify(t(`Project system size set to ${newSystemSize} kWp`), {
							type: toast.TYPE.SUCCESS,
						});
					}
				}

				await onFormSubmit();
				notify(`${epcOfferlabel} saved successfully`, {
					type: toast.TYPE.SUCCESS,
				});
				onClose();
			} catch (error) {
				showToastError(error, `${epcOfferlabel} could not be saved`);
				if (!axios.isCancel(error)) {
					setIsLoadingContent(false);
				}
			}
		},
		[
			abortController.signal,
			epcOffer,
			isPVProject,
			projectId,
			epcOfferId,
			type,
			convertOfferValues,
			mode,
			onFormSubmit,
			epcOfferlabel,
			onClose,
			projectCurrencies,
			projectSystemSize,
			t,
		],
	);

	return (
		<OverlaySections
			label={label}
			breadcrumbList={breadcrumbList}
			isOpen={isOpen}
			onClose={onClose}
			onSaveOverlay={mode === crudModes.CREATE ? handleCreateSubmit : handleEditSubmit}
			isLoadingContent={isLoadingContent}
			sections={sections}
			sectionFields={sectionFields}
			defaultValues={defaultValues}
			validationSchema={validationSchema}
			saveBtnLabel={saveBtnLabel}
			closeBtnLabel={closeBtnLabel}
			mode={mode}
		/>
	);
};

EPCOfferOverlay.defaultProps = {
	mode: crudModes.CREATE,
	type: EPC_OFFER_TYPES.ESTIMATE,
	isOpen: false,
	onClose: () => {},
	onFormSubmit: () => {},
	epcOfferId: null,
	project: {},
};

EPCOfferOverlay.propTypes = {
	mode: PropTypes.oneOf([crudModes.CREATE, crudModes.EDIT]),
	type: PropTypes.oneOf(Object.values(EPC_OFFER_TYPES)),
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onFormSubmit: PropTypes.func,
	epcOfferId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		projectType: PropTypes.shape({
			name: PropTypes.string,
		}),
		systemSizeKwp: PropTypes.number,
		currencies: PropTypes.arrayOf(PropTypes.shape({})),
	}),
};

export default EPCOfferOverlay;
