import api from 'Application/api/api';
import adaptEpcOfferResponseData from 'EpcContract/api/adapters/adaptEpcOfferResponseData';

const getEpcInquiryById = async (signal, id) => {
	const response = await api.get(`/inquiries/partner/${id}`, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}
	return {
		...response,
		data: adaptEpcOfferResponseData(response.data),
	};
};

export default getEpcInquiryById;
