import getProjectDevelopers from 'ProjectDeveloper/api/getProjectDevelopers';

const loadProjectDevelopersOptions = async (signal, query) => {
	const response = await getProjectDevelopers(signal, query);
	const projectDevelopers = response.data.content;

	return projectDevelopers
		.filter(projectDeveloper => projectDeveloper.name && projectDeveloper.name !== '-')
		.map(projectDeveloper => ({
			value: projectDeveloper.id,
			label: projectDeveloper.name,
		}));
};

export default loadProjectDevelopersOptions;
