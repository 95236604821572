import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useEpcContractFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				currency: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.nullable()
					.required(t('Currency is Required')),
				epcVolumeExclVat: yup
					.number()
					.typeError(t('EPC volume must be a number'))
					.positive(t('EPC volume must be positive'))
					.test(
						'decimal-places',
						'EPC volume must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable()
					.required(t('EPC volume is Required')),
				annualOmPrice: yup
					.number()
					.typeError(t('Annual O&M price must be a number'))
					.positive(t('Annual O&M price must be positive'))
					.test(
						'decimal-places',
						'Annual O&M must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable()
					.required(t('Annual O&M price is Required')),
				annualOmEscalationRate: yup
					.number()
					.typeError(t('Annual O&M escalation rate must be a number'))
					.positive(t('Annual O&M escalation rate must be positive'))
					.test(
						'decimal-places',
						'Annual O&M escalation rate must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.min(0, t('Annual O&M escalation rate must be more than or equals to 0'))
					.max(100, t('Annual O&M escalation rate must be less than or equals to 100'))
					.nullable(),
			}),
		[t],
	);
};

export default useEpcContractFormValidationSchema;
