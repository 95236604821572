import styled from 'styled-components/macro';
import React from 'react';
import { PropTypes } from 'prop-types';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';

import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';

const Wrapper = styled.div`
	display: flex;
	min-width: 100px;
	height: 40px;
	justify-content: ${({ $isRow }) => ($isRow ? 'right' : 'center')};
	text-align: ${({ $isRow }) => ($isRow ? 'right' : 'center')};
	font-size: 14px;
	color: ${colors.grey.main};
	font-weight: 700;
	padding: 0 ${sizes.spacing(1.2)};
	white-space: normal;

	&:first-child {
		border-bottom: 1px solid ${colors.common.borderGrey};
		margin-bottom: ${sizes.spacing(1.2)};
	}
`;

const TextContainer = styled.div`
	position: relative;
`;

const StyledTooltip = styled(QuestionTooltip)`
	position: absolute;
	top: 0;
	right: -${sizes.spacing(6.75)};
`;

const ImpactTableHeader = ({ row, isRow }) => (
	<Wrapper $isRow={isRow}>
		<TextContainer>
			{row.label}
			{row?.tooltip && <StyledTooltip tooltip={row.tooltip} />}
		</TextContainer>
	</Wrapper>
);

ImpactTableHeader.defaultProps = {
	row: {
		label: '',
		tooltip: '',
	},
	isRow: false,
};

ImpactTableHeader.propTypes = {
	row: PropTypes.shape({
		label: PropTypes.string,
		tooltip: PropTypes.string,
	}),
	isRow: PropTypes.bool,
};

export default ImpactTableHeader;
