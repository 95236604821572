import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';
import yupUrl from 'Common/utils/yupUrl';

const useProjectDeveloperOverlayValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				name: yup.string().required(t('Required')),
				website: yupUrl(),
				apartmentBuilding: yup.string(),
				line1: yup.string(),
				line2: yup.string(),
				cityRegion: yup.string(),
				postCodeZip: yup.string(),
			}),
		[t],
	);
};

export default useProjectDeveloperOverlayValidationSchema;
