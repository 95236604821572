import FormField from 'Common/components/form/FormField';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import FormikSelect from 'Common/components/form/FormikSelect';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';
import crudModes from 'Common/constants/crudModes';
import formatDate from 'Common/utils/formatDate';
import loadEpcPartnerOptions from 'EpcPartner/utils/loadEpcPartnerOptions';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: fit-content;
`;

const EPCSection = ({
	values,
	errors,
	touched,
	handleChange,
	setFieldValue,
	setFieldTouched,
	handleBlur,
	mode,
}) => {
	const { t } = useTranslation();

	return mode !== crudModes.VIEW ? (
		<Wrapper>
			<FormField data-first-field>
				<FormikSelect
					isAsync
					id="epcPartner"
					name="epcPartner"
					label={t('EPC Partner')}
					error={errors.epcPartner}
					touched={touched.epcPartner}
					value={values.epcPartner}
					setFieldValue={setFieldValue}
					loadOptions={loadEpcPartnerOptions}
					menuPosition="fixed"
					onBlur={handleBlur}
					isRequired
					isOverlay
				/>
			</FormField>
			<FormField>
				<FormikCheckbox
					label={t('Hide client name')}
					id="hideClientName"
					name="hideClientName"
					checked={values.hideClientName}
					onChange={handleChange}
					onBlur={handleBlur}
					touched={touched.hideClientName}
					tooltip={{
						placement: 'right',
						tooltip: t('When selected the EPC Partner won’t see the Client name in their portal.'),
					}}
				/>
			</FormField>

			<FormField data-last-field>
				<FormikSingleDatePicker
					label={t('Initial engagement date')}
					id="initialEngagementDate"
					startDateName="initialEngagementDate"
					error={errors.initialEngagementDate}
					touched={touched.initialEngagementDate}
					startDate={values.initialEngagementDate}
					setFieldValue={setFieldValue}
					setFieldTouched={setFieldTouched}
					isOverlay
				/>
			</FormField>
		</Wrapper>
	) : (
		<Wrapper>
			<KeyValueVisualization id="epcPartner" title={t('EPC Partner')} value={values.epcPartner?.label} />
			<KeyValueVisualization id="hideClientName" title={t('Hide client name')} value={values.hideClientName} />
			<KeyValueVisualization
				id="initialEngagementDate"
				title={t('Initial engagement date')}
				value={values.initialEngagementDate ? formatDate(values.initialEngagementDate) : ''}
			/>
		</Wrapper>
	);
};

EPCSection.defaultProps = {
	values: {},
	errors: {},
	touched: {},
	handleChange: () => {},
	setFieldValue: () => {},
	setFieldTouched: () => {},
	handleBlur: () => {},
	isViewMode: false,
};
EPCSection.propTypes = {
	values: PropTypes.shape({
		epcPartner: PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.string,
		}),
		hideClientName: PropTypes.bool,
		initialEngagementDate: PropTypes.instanceOf(Date),
	}),
	errors: PropTypes.shape({
		epcPartner: PropTypes.string,
		hideClientName: PropTypes.string,
		initialEngagementDate: PropTypes.string,
	}),
	touched: PropTypes.shape({
		epcPartner: PropTypes.bool,
		hideClientName: PropTypes.bool,
		initialEngagementDate: PropTypes.bool,
	}),
	handleChange: PropTypes.func,
	setFieldValue: PropTypes.func,
	setFieldTouched: PropTypes.func,
	handleBlur: PropTypes.func,
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
};

export default EPCSection;
