import { useMemo } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import styled from 'styled-components/macro';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

const TriggerButton = styled.button`
	background: none;
	border: 0 none;
	outline: 0 none;
	padding: 0;
	margin: 0;
	cursor: pointer;
	&:focus {
		outline: 2px solid ${colors.primary.main};
	}
`;

const TriggerDiv = styled.div``;

const Arrow = styled.div`
	width: 1.1rem;

	&::before {
		filter: blur(1px);
	}

	&::after {
	}
`;

const Container = styled.div`
	padding: 20px;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
	border: 0 none;
	border-radius: 10px;
	color: ${colors.text.black};
	font-weight: 500;
	font-size: 14px;
	line-height: 15px;
	max-width: 90%;
	white-space: pre-wrap;

	--tooltipBorder: rgba(134, 134, 134, 0.2);
	--tooltipBackground: ${colors.common.white};

	&[data-popper-placement='top'] {
		${Arrow} {
			&::before,
			&::after {
				border-top-width: 0.6rem;
			}
		}
	}

	&[data-popper-placement='right'] {
		${Arrow} {
			margin-left: -0.9rem;

			&::before,
			&::after {
				border-right-width: 0.6rem;
			}
		}
	}

	&[data-popper-placement='bottom'] {
		${Arrow} {
			margin-top: -0.6rem;

			&::before,
			&::after {
				border-bottom-width: 0.6rem;
			}
		}
	}

	&[data-popper-placement='left'] {
		${Arrow} {
			margin-right: -0.9rem;

			&::before,
			&::after {
				border-left-width: 0.6rem;
			}
		}
	}
`;

const allPlacements = ['bottom', 'right', 'top', 'left'];

const PopperTooltip = ({ tooltip, popperOptions, withPortal, containerStyle, children, ...props }) => {
	const placement = props.placement ?? 'right';

	const fallbackPlacements = useMemo(() => {
		const placements = [...allPlacements];

		placements.splice(placements.indexOf(placement), 1);

		return placements;
	}, [placement]);

	const trigger = props.trigger ?? 'click';

	const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip(
		{
			placement: placement,
			trigger: 'click',
			...props,
		},
		{
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: [0, 10],
					},
				},
				{
					name: 'preventOverflow',
					options: {
						padding: 10,
					},
				},
				{
					name: 'flip',
					options: {
						fallbackPlacements,
					},
				},
			],
			...popperOptions,
		},
	);

	const TriggerComponent = trigger === 'click' ? TriggerButton : TriggerDiv;

	return (
		<>
			<TriggerComponent
				type={trigger === 'click' ? 'button' : undefined}
				ref={setTriggerRef}
				onBlur={e => (visible ? e.target.click() : '')}
				onClick={e => e.stopPropagation()}
				tabIndex="-1"
			>
				{children}
			</TriggerComponent>
			{visible &&
				(withPortal ? (
					ReactDOM.createPortal(
						<Container
							ref={setTooltipRef}
							{...getTooltipProps({
								className: 'tooltip-container',
							})}
						>
							<Arrow
								{...getArrowProps({
									className: 'tooltip-arrow',
								})}
							/>
							{tooltip}
						</Container>,
						document.body,
					)
				) : (
					<Container
						ref={setTooltipRef}
						{...getTooltipProps({
							className: 'tooltip-container',
							style: containerStyle,
						})}
					>
						<Arrow {...getArrowProps({ className: 'tooltip-arrow' })} />
						{tooltip}
					</Container>
				))}
		</>
	);
};

PopperTooltip.defaultProps = {
	tooltip: '',
	children: null,
	placement: null,
	trigger: 'click',
	withPortal: true,
	popperOptions: {},
	containerStyle: {},
};

PopperTooltip.propTypes = {
	tooltip: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
	children: PropTypes.node,
	trigger: PropTypes.string,
	withPortal: PropTypes.bool,
	placement: PropTypes.string,
	popperOptions: PropTypes.shape({}),
	containerStyle: PropTypes.shape({}),
};

export default PopperTooltip;
