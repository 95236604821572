import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getFilterRange from 'Projects/api/getFilterRange';
import { merge } from 'lodash';
import loadClientOptions from 'Client/utils/loadClientOptions';
import getUsersWithFullName from 'UserManagement/api/getUsersWithFullName';
import { roles } from 'User/constants/roles';
import { statusesOptions } from 'Projects/constants/statuses';
import modifyNumberFilterData from 'Common/components/filter/hooks/modifyNumberFilterData';
import loadIndustryOptions from 'Industries/utils/loadIndustryOptions';
import loadSalesChannelOptions from 'Projects/utils/loadSalesChannelOptions';
import loadEpcPartnerOptions from 'EpcPartner/utils/loadEpcPartnerOptions';
import loadProjectTypeOptions from 'Projects/utils/loadProjectTypeOptions';
import loadCountryIsoCodeOptions from 'Country/utils/loadCountryIsoCodeOptions';
import loadStepNames from 'PMWorkflow/utils/loadStepNames';
import generateStageFilterValues from 'Common/components/filter/utils/generateStageFilterValues';
import { projectPriorityOptions } from 'Common/constants/projectPrioritiy';
import showToastError from 'Common/utils/showToastError';
import stepsFilterBeforeUpdateQuery from 'PMWorkflow/utils/stepsFilterBeforeUpdateQuery';
import {
	FILTER_LIFECYCLE_HOOKS,
	FILTER_NAME_SUFFIXES,
	FILTER_TYPES,
} from 'Common/components/filter/constants/filterConstants';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import getContractsFilterRange from 'Impact/api/getStatistics';
import loadClientContractTypeOptions from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/ClientContract/utils/loadClientContractTypeOptions';

const fallbackFilterRanges = {
	stageCompletedPercentageMin: 0,
	stageCompletedPercentageMax: 100,
	systemSizeKwpMin: 0,
	systemSizeKwpMax: 0,
	epcVolumeExclVatMin: 0,
	epcVolumeExclVatMax: 0,
	averageEPCPriceMax: 0,
	averageEPCPriceMin: 0,
	contractDurationMin: 0,
	contractDurationMax: 0,
	irrMin: 0,
	irrMax: 0,
};

const useProjectListFilterData = () => {
	const { t } = useTranslation();

	const loadCustomClientOptions = async (signal, query) => {
		try {
			const options = await loadClientOptions(signal, query);

			const showUnknown = query.length === 0 || 'unknown'.includes(query.toLowerCase());
			if (showUnknown) {
				options.unshift({
					value: -1,
					label: 'Unknown',
				});
			}

			return options;
		} catch (error) {
			showToastError(error);
		}
	};

	const abortController = useAbortController();

	const [filterRanges, setFilterRanges] = useState(fallbackFilterRanges);
	const [responsiblePersonOptions, setResponsiblePersonOptions] = useState([]);

	const stages = [
		{ label: t('Sales'), name: 'sales' },
		{ label: t('Tech'), name: 'tech' },
		{ label: t('Fundraising'), name: 'fundraising' },
		{ label: t('Build'), name: 'build' },
		{ label: t('Asset Management'), name: 'assetManagement' },
	];

	useEffect(() => {
		(async () => {
			try {
				const responses = await Promise.all([
					getFilterRange(abortController.signal),
					getContractsFilterRange(abortController.signal),
				]);

				setFilterRanges(prevFilterRanges => ({
					...prevFilterRanges,
					...merge(...responses.map(response => response.data)),
				}));

				const response = await getUsersWithFullName(abortController.signal, {
					active: true,
					roles: [
						roles.ADMIN,
						roles.FINANCE,
						roles.ASSET_MANAGER,
						roles.ASSET_MARKETING,
						roles.MANAGEMENT,
						roles.PM_FUNDRAISING,
						roles.PM_TECHNICAL,
						roles.SALES,
					],
				});
				if (response.data.length > 0) {
					setResponsiblePersonOptions(
						response.data.map(user => ({
							value: user.email,
							label: user.fullName,
						})),
					);
				}
			} catch (error) {
				if (!axios.isCancel(error)) {
					setFilterRanges(fallbackFilterRanges);
				}
			}
		})();
	}, [abortController.signal]);

	const defaultFilterData = [
		{
			name: 'combinedStepStatusTitles',
			label: t('Steps'),
			type: FILTER_TYPES.STEPS,
			loadOptions: loadStepNames,
			[FILTER_LIFECYCLE_HOOKS.BEFORE_UPDATE_QUERY]: stepsFilterBeforeUpdateQuery,
		},
		{
			name: 'priority',
			label: t('Priority'),
			type: FILTER_TYPES.SELECT,
			options: projectPriorityOptions,
		},
		{
			name: 'condition',
			label: t('Status'),
			type: FILTER_TYPES.SELECT,
			options: statusesOptions,
		},
		...stages.map(stage => ({
			...stage,
			stageStatus: stage.name + FILTER_NAME_SUFFIXES.STAGE_STATUS,
			type: FILTER_TYPES.STAGE,
			minProps: { icon: '%', iconPosition: 'right' },
			maxProps: { icon: '%', iconPosition: 'right' },
			minName: stage.name + FILTER_NAME_SUFFIXES.STAGE_COMPLETED_PERCENTAGE_FROM,
			maxName: stage.name + FILTER_NAME_SUFFIXES.STAGE_COMPLETED_PERCENTAGE_TO,
			lowerBound: filterRanges.stageCompletedPercentageMin,
			upperBound: filterRanges.stageCompletedPercentageMax,
			[FILTER_LIFECYCLE_HOOKS.BEFORE_UPDATE_VALUE]: generateStageFilterValues,
		})),
		{
			name: 'externalId',
			label: t('ID'),
			type: FILTER_TYPES.MULTI_INPUT,
		},
		{
			name: 'countryId',
			label: t('Country'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadCountryIsoCodeOptions,
		},
		{
			name: 'clientId',
			label: t('Client'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadCustomClientOptions,
			handleSearch: (values, setValue) => {
				if (values.includes('-1')) {
					setValue('-1');
				}
			},
		},
		{
			name: 'projectTypeId',
			label: t('Type'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadProjectTypeOptions,
		},
		{
			name: 'responsiblePersonEmail',
			label: t('People'),
			type: FILTER_TYPES.SELECT,
			options: responsiblePersonOptions,
		},
		{
			name: 'systemSizeKwp',
			label: t('Size (kWp)'),
			type: FILTER_TYPES.NUMBER,
			minProps: {
				icon: 'kWp',
				iconPosition: 'right',
			},
			maxProps: {
				icon: 'kWp',
				iconPosition: 'right',
			},
			minName: 'systemSizeKwp' + FILTER_NAME_SUFFIXES.RANGE_FROM,
			maxName: 'systemSizeKwp' + FILTER_NAME_SUFFIXES.RANGE_TO,
			lowerBound: filterRanges.systemSizeKwpMin,
			upperBound: filterRanges.systemSizeKwpMax,
		},
		{
			name: 'epcVolume',
			rangeKey: 'epcVolumeExclVat',
			label: t('EPC volume'),
			type: FILTER_TYPES.NUMBER,
			minProps: { icon: '€' },
			maxProps: { icon: '€' },
			minName: 'epcVolumeExclVat' + FILTER_NAME_SUFFIXES.RANGE_FROM,
			maxName: 'epcVolumeExclVat' + FILTER_NAME_SUFFIXES.RANGE_TO,
			lowerBound: filterRanges.epcVolumeExclVatMin,
			upperBound: filterRanges.epcVolumeExclVatMax,
		},
		{
			name: 'averageEPCPrice',
			rangeKey: 'averageEPCPrice',
			label: t('€/kWp'),
			type: FILTER_TYPES.NUMBER,
			minProps: { icon: '€' },
			maxProps: { icon: '€' },
			minName: 'averageEPCPrice' + FILTER_NAME_SUFFIXES.RANGE_FROM,
			maxName: 'averageEPCPrice' + FILTER_NAME_SUFFIXES.RANGE_TO,
			lowerBound: filterRanges.averageEPCPriceMin,
			upperBound: filterRanges.averageEPCPriceMax,
		},
		{
			name: 'epcPartnerId',
			label: t('EPC Partner'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadEpcPartnerOptions,
		},
		{
			name: 'contractTypeId',
			label: t('Contract'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadClientContractTypeOptions,
		},
		{
			name: 'contractDuration',
			label: t('Duration'),
			type: FILTER_TYPES.NUMBER,
			minName: 'contractDuration' + FILTER_NAME_SUFFIXES.RANGE_FROM,
			maxName: 'contractDuration' + FILTER_NAME_SUFFIXES.RANGE_TO,
			lowerBound: filterRanges.contractDurationMin,
			upperBound: filterRanges.contractDurationMax,
			minProps: {
				icon: 'years',
				iconPosition: 'right',
			},
			maxProps: {
				icon: 'years',
				iconPosition: 'right',
			},
		},
		{
			name: 'saasSignatureDate',
			label: t('SaaS date'),
			type: FILTER_TYPES.DATE,
			startDateName: 'saasSignatureDate' + FILTER_NAME_SUFFIXES.DATE_FROM,
			endDateName: 'saasSignatureDate' + FILTER_NAME_SUFFIXES.DATE_TO,
			isLocalDate: true,
		},
		{
			name: 'commercialOperationsDate',
			label: t('COD'),
			type: FILTER_TYPES.DATE,
			startDateName: 'commercialOperationsDate' + FILTER_NAME_SUFFIXES.DATE_FROM,
			endDateName: 'commercialOperationsDate' + FILTER_NAME_SUFFIXES.DATE_TO,
			isLocalDate: true,
		},
		{
			name: 'irr',
			label: t('IRR'),
			type: FILTER_TYPES.NUMBER,
			minName: 'irr' + FILTER_NAME_SUFFIXES.RANGE_FROM,
			maxName: 'irr' + FILTER_NAME_SUFFIXES.RANGE_TO,
			lowerBound: filterRanges.irrMin,
			upperBound: filterRanges.irrMax,
			minProps: {
				icon: '%',
				iconPosition: 'right',
			},
			maxProps: {
				icon: '%',
				iconPosition: 'right',
			},
		},
		{
			name: 'salesChannelId',
			label: t('Sales channel'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadSalesChannelOptions,
		},
		{
			name: 'industryId',
			label: t('Industry'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadIndustryOptions,
		},
		{
			name: 'lastModifiedDate',
			label: t('Updated'),
			type: FILTER_TYPES.DATE,
			startDateName: 'lastModifiedDate' + FILTER_NAME_SUFFIXES.DATE_FROM,
			endDateName: 'lastModifiedDate' + FILTER_NAME_SUFFIXES.DATE_TO,
		},
	];

	const [filtersData, setFiltersData] = useState(defaultFilterData);

	useEffect(() => {
		const modifyNumberFilterDataBinded = modifyNumberFilterData.bind(undefined, filterRanges);

		setFiltersData(prevFilterData => [...prevFilterData].map(modifyNumberFilterDataBinded));
	}, [filterRanges]);

	useEffect(() => {
		setFiltersData(prevFilterData =>
			[...prevFilterData].map(el =>
				el.name === 'responsiblePersonEmail' ? { ...el, options: responsiblePersonOptions } : el,
			),
		);
	}, [responsiblePersonOptions]);

	return filtersData;
};

export default useProjectListFilterData;
