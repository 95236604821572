import getMyActiveProjects from 'Dashboard/api/getMyActiveProjects';
import DashboardTab from 'Dashboard/components/DashboardTab';
import useMyActivitiesTabStepModifier from 'Dashboard/components/MyActivities/Tabs/MyActivitiesTab/hooks/useMyActivitiesTabStepModifier';
import getMyActiveProjectById from 'Dashboard/api/getMyActiveProjectById';

const MyActivitiesTab = props => (
	<DashboardTab
		helpText="Projects with activities waiting for you to complete the next step."
		getterFunction={getMyActiveProjects}
		singleProjectGetter={getMyActiveProjectById}
		stepModifier={useMyActivitiesTabStepModifier}
		{...props}
	/>
);

export default MyActivitiesTab;
