import { useMemo } from 'react';

const fallbackValues = {
	name: '',
	branchName: '',
	branchCode: '',
	accountNumber: '',
	bankSwift: '',
	currency: { value: 'EUR' },
};

const useBankAccountFormInitialValues = data => {
	return useMemo(
		() => ({
			...fallbackValues,
			currency: data?.currency ? { value: data.currency } : fallbackValues?.currency,
			name: data?.name || '',
			branchName: data?.branchName || '',
			branchCode: data?.branchCode || '',
			accountNumber: data?.accountNumber || '',
			bankSwift: data?.bankSwift || '',
		}),
		[data?.accountNumber, data?.bankSwift, data?.branchCode, data?.branchName, data?.currency, data?.name],
	);
};
export default useBankAccountFormInitialValues;
