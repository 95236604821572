import { useMemo } from 'react';
import { pick } from 'lodash';

const fallbackValues = {
	berlinBarcelonaFlightsPerCO2Tone: '',
	carKmPerCO2Ton: '',
	hotBalloonPerCO2Tone: '',
	yearsOfFootprintPerCO2Tone: '',
};

const useProjectDefaultsFormInitialValues = projectDefaults =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(projectDefaults, [
				'berlinBarcelonaFlightsPerCO2Tone',
				'carKmPerCO2Ton',
				'hotBalloonPerCO2Tone',
				'yearsOfFootprintPerCO2Tone',
			]),
		}),
		[projectDefaults],
	);

export default useProjectDefaultsFormInitialValues;
