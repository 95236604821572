import api from 'Application/api/api';

const resendConfirmationEmail = (signal, email) =>
	api.post(
		'/users/new-confirmation',
		{
			username: email,
		},
		{
			signal,
		},
	);

export default resendConfirmationEmail;
