import { useMemo } from 'react';
import { pick } from 'lodash';
import formatDecimal from 'Common/utils/formatDecimal';
import Big from 'Common/utils/customBig';

const fallbackValues = {
	subjectToVatWht: false,
	taxFree: false,
	wht: '',
};

const useDueDiligenceFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['subjectToVatWht', 'taxFree']),
			wht: data?.wht && formatDecimal(Big(data?.wht), 2),
		}),
		[data],
	);

export default useDueDiligenceFormInitialValues;
