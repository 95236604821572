import api from 'Application/api/api';

const getRegionalHubs = (signal, details, query) =>
	api.get('/regional-hub', {
		params: {
			details,
			query,
		},
		signal,
	});

export default getRegionalHubs;
