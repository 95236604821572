import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

import projectTypes from 'Projects/constants/projectTypes';

import yupUrl from 'Common/utils/yupUrl';

const useProjectCreateFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				client: yup
					.object()
					.nullable()
					.when('unknownClient', {
						is: true,
						then: yup.object().nullable(),
						otherwise: yup
							.object()
							.required(t('Client is required, unless "Unknown Client" is checked'))
							.nullable(),
					}),
				country: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.nullable()
					.required(t('Country is Required')),
				projectType: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.nullable()
					.required(t('Project Type is Required')),
				salesChannel: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.nullable()
					.required(t('Sales channel is Required')),
				epcPartner: yup
					.object()
					.nullable()
					.when('salesChannel', {
						is: value => value?.label === 'EPC Partner',
						then: yup.object().required(t('EPC partner is Required')).nullable(),
						otherwise: yup.object().nullable(),
					}),
				projectDeveloper: yup
					.object()
					.nullable()
					.when('salesChannel', {
						is: value => value?.label === 'Project Developer',
						then: yup.object().required(t('Project Developer is Required')).nullable(),
						otherwise: yup.object().nullable(),
					}),
				systemSizeKwp: yup
					.number()
					.nullable()
					.positive(t('System size must be positive'))
					.typeError(t('System size must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'decimal-places',
						t('System size must have no more than 2 decimal places'),
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					),

				kwhToKwpRel: yup
					.mixed()
					.nullable()
					.when('projectType', {
						is: value => value?.label === projectTypes.PV,
						then: yup
							.number()
							.nullable()
							.positive(t('Yield must be positive'))
							.test(
								'decimal-places',
								t('Yield must have no more than 2 decimal places'),
								value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
							)
							.test(
								'yield-range',
								t('Yield must be between 500 and 3000'),
								value => value === undefined || (value <= 3000 && value >= 500),
							)
							.typeError(t('Yield must be a number'))
							.transform((val, originalValue) =>
								typeof originalValue === 'string' && originalValue === '' ? null : val,
							),

						otherwise: null,
					}),
				locationUrl: yupUrl(),
				initialEngagementDate: yup.date(t('Invalid date')).nullable().required(t('Required')),
			}),
		[t],
	);
};

export default useProjectCreateFormValidationSchema;
