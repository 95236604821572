import { useMemo } from 'react';
import RfepGeneralSection from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/Rfep/Overlay/Sections/RfepGeneralSection';
import RfepProjectSection from '../Sections/RfepProjectSection';
import RfepImagesSection from '../Sections/RfepImagesSection';
import RfepDocumentsSection from '../Sections/RfepDocumentsSection';
import RfepEpcPartnersSection from '../Sections/RfepEpcPartnersSection';

const useRfepOverlaySections = (project, defaultValues) =>
	useMemo(() => {
		return [
			{
				title: 'General',
				section: ({ values, errors, touched, handleChange, handleBlur, setFieldTouched }) => (
					<RfepGeneralSection
						project={project}
						values={values}
						errors={errors}
						touched={touched}
						setFieldTouched={setFieldTouched}
						handleBlur={handleBlur}
						handleChange={handleChange}
					/>
				),
			},
			{
				title: 'Projects',
				section: ({ values, errors, touched, handleChange, handleBlur, setFieldTouched, setFieldValue }) => (
					<RfepProjectSection
						project={project}
						values={values}
						errors={errors}
						touched={touched}
						handleBlur={handleBlur}
						handleChange={handleChange}
						setFieldTouched={setFieldTouched}
						setFieldValue={setFieldValue}
					/>
				),
			},
			{
				title: 'Images',
				section: () => <RfepImagesSection projectId={project.id} images={project.images} />,
			},
			{
				title: 'Documents',
				section: ({ values, handleBlur, handleChange }) => (
					<RfepDocumentsSection values={values} handleBlur={handleBlur} handleChange={handleChange} />
				),
			},
			{
				title: 'Partners',
				section: ({ values, errors, touched, handleBlur, setFieldValue }) => (
					<RfepEpcPartnersSection
						values={values}
						errors={errors}
						touched={touched}
						handleBlur={handleBlur}
						setFieldValue={setFieldValue}
						rfepState={defaultValues.state}
						initialValue={defaultValues.invitedPartners}
					/>
				),
			},
		];
	}, [project, defaultValues]);

export default useRfepOverlaySections;
