import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

import getTags from 'Preferences/api/getTags';
import deleteTag from 'Preferences/api/deleteTag';
import getUniqueLanguages from 'Preferences/api/getUniqueLanguages';
import TagsCreateModal from 'Preferences/components/Tabs/MarketingTab/Tags/TagsCreateModal';
import TagsEditModal from 'Preferences/components/Tabs/MarketingTab/Tags/TagsEditModal';

import notify from 'Common/utils/notify';
import EditableTile from 'Common/components/Tile/EditableTile';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import { useStickyColumns } from 'Common/hooks/table/plugin-hooks/useStickyColumns';
import { DefaultTable, TableBodyCell, TableHeadCell } from 'Common/components/table/table';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}

	${TableBodyCell} {
		background: transparent;
	}
`;

const formatLocalizationObject = localizations => {
	const formatedObject = {};

	for (let language in localizations) {
		formatedObject[language] = localizations[language].name;
	}
	return formatedObject;
};

const TagsList = () => {
	const { t } = useTranslation();

	const [tags, setTags] = useState([]);

	const [tagIdForEditing, setTagIdForEditing] = useState(null);

	const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

	const [tagIdForDeletion, setTagIdForDeletion] = useState(null);

	const [deletionError, setDeletionError] = useState(null);

	const [uniqueLanguages, setUniqueLanguages] = useState([]);

	const [isLoadingContent, setIsLoadingContent] = useState(false);
	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				const response = await getUniqueLanguages(abortController.signal);
				setUniqueLanguages(Object.keys(response.data));
			} catch (error) {
				showToastError(error);
			}
		})();
	}, [tags, abortController.signal]);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Name'),
				accessor: 'name',
			},
			{
				Header: t('Image ID'),
				accessor: 'imageId',
			},

			...uniqueLanguages.map(e => ({
				Header: t(e.toUpperCase()),
				accessor: e,
			})),

			{
				Header: '',
				accessor: 'actions',
				maxWidth: 50,
				customCellStyles: {
					justifyContent: 'flex-end',
				},
				sticky: true,
				customBodyCellContentStyles: {
					padding: '5px',
				},
			},
		],
		[t, uniqueLanguages],
	);

	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			tags.map(tag => ({
				...tag,
				name: tag.name || '-',
				imageId: tag.imageId || '-',
				...formatLocalizationObject(tag.localizations),
				// other: Object.keys(tag.localizations)
				//     .filter(e => !baseLanguages.includes(e))
				//     .join(', '),
				actions: (
					<MoreActionsButton label="Preference Marketing Tags More Actions" controlledVisibility>
						<MenuItem
							onClick={() => setTagIdForEditing(tag.id)}
							type="button"
							data-action="edit"
							label="Preference Marketing Tags - Edit Menu Item"
						>
							{t('Edit')}
						</MenuItem>
						<MenuItem
							onClick={() => setTagIdForDeletion(tag.id)}
							type="button"
							data-action="delete"
							label="Preference Marketing Tags - Delete Menu Item"
						>
							{t('Delete')}
						</MenuItem>
					</MoreActionsButton>
				),
			})),
		[t, tags],
	);

	useEffect(() => {
		(async () => {
			try {
				setIsLoadingContent(true);
				const response = await getTags(abortController.signal);
				setIsLoadingContent(false);

				setTags(response.data);
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [abortController.signal]);

	const handleDataUpdate = data => {
		setTags(data);
	};

	const handleDeleteTag = async id => {
		try {
			await deleteTag(abortController.signal, id);

			notify(t('Tag deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});

			const response = await getTags(abortController.signal);

			handleDataUpdate(response.data);
			setTagIdForDeletion(null);
		} catch (error) {
			setDeletionError(error.response.data);
			if (!axios.isCancel(error)) {
				setTagIdForDeletion(null);
			}
		}
	};

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
			},
			useCustomCellStyles,
			useStickyColumns,
		);

	const tagForDeletion = tagIdForDeletion ? tags.find(tag => tag.id === tagIdForDeletion) : null;

	const hasTagForDeletion = !!tagForDeletion && !!tagIdForDeletion;

	return (
		<EditableTile
			title={t('Campaign & Tranche Tags')}
			tooltip={
				<QuestionTooltip
					large
					tooltip={
						<>
							Tags can be added to Campaign and Tranches to advertise features of the Campaign. The tags must be
							created in the website CMS to work properly.
						</>
					}
				/>
			}
			isLoadingContent={isLoadingContent}
			rightComponent={<TileActionButton data-button="tags" onClick={() => setIsCreateModalOpened(true)} />}
			isTable
		>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
				/>
			</TableWrapper>
			<TagsCreateModal
				isOpen={isCreateModalOpened}
				onClose={() => setIsCreateModalOpened(false)}
				onFormSubmit={handleDataUpdate}
			/>

			{tagIdForEditing !== null && (
				<TagsEditModal
					isOpen
					onClose={() => setTagIdForEditing(null)}
					onFormSubmit={handleDataUpdate}
					tagId={tagIdForEditing}
				/>
			)}

			{hasTagForDeletion && (
				<ConfirmModal
					isOpen
					onCancel={() => setTagIdForDeletion(null)}
					onConfirm={() => handleDeleteTag(tagIdForDeletion)}
					label="delete-tag-confirm"
					heading={t('Delete Tag')}
					text={t(`Are you sure you want to delete Tag ${tagForDeletion.name}?`)}
				/>
			)}

			<UnsuccessfulOperationModal
				label="cannot-delete-tag"
				isOpen={deletionError !== null}
				onCancel={() => setDeletionError(null)}
				heading={t('Cannot delete Tag')}
				text={deletionError?.msg}
			/>
		</EditableTile>
	);
};

export default TagsList;
