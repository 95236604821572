import formatDateForRequestData from 'Common/utils/formatDateForRequestData';
import Big from 'big.js';

const adaptInvoiceRequestData = data => {
	return {
		...data,
		paymentDate: data.paymentDate ? formatDateForRequestData(data.paymentDate) : data.paymentDate,
		issueDate: data.issueDate ? formatDateForRequestData(data.issueDate) : data.issueDate,
		dueDate: data.dueDate ? formatDateForRequestData(data.dueDate) : data.dueDate,
		billingPeriodStart: data.billingPeriodStart
			? formatDateForRequestData(data.billingPeriodStart)
			: data.billingPeriodStart,
		billingPeriodEnd: data.billingPeriodEnd
			? formatDateForRequestData(data.billingPeriodEnd)
			: data.billingPeriodEnd,
		countryVatPercentage: data.countryVatPercentage
			? Big(data.countryVatPercentage).div(100)
			: data.countryVatPercentage,
		countryWhtPercentage: data.countryWhtPercentage
			? Big(data.countryWhtPercentage).div(100)
			: data.countryWhtPercentage,
		countryWhtVatPercentage: data.countryWhtVatPercentage
			? Big(data.countryWhtVatPercentage).div(100)
			: data.countryWhtVatPercentage,
		countryNHILPercentage: data.countryNHILPercentage
			? Big(data.countryNHILPercentage).div(100)
			: data.countryNHILPercentage,
		countryCovid19LevyPercentage: data.countryCovid19LevyPercentage
			? Big(data.countryCovid19LevyPercentage).div(100)
			: data.countryCovid19LevyPercentage,
		countryGetFundLevyPercentage: data.countryGetFundLevyPercentage
			? Big(data.countryGetFundLevyPercentage).div(100)
			: data.countryGetFundLevyPercentage,
	};
};

export default adaptInvoiceRequestData;
