import { useMemo } from 'react';

const formatDate = date => {
	if (!date || !(date instanceof Date)) return '';
	const days = date.getDate();
	const month = date.getMonth();
	const year = date.getFullYear();
	return `${days}/${Number(month) + 1}/${year}`;
};

const useDatepickerDisplayValue = (startDate, endDate, isDual) =>
	useMemo(
		() =>
			startDate || endDate
				? isDual
					? formatDate(startDate) + ' - ' + formatDate(endDate)
					: formatDate(startDate)
				: '',
		[endDate, isDual, startDate],
	);

export default useDatepickerDisplayValue;
