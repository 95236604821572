const pullAllowedPropertiesFromFilterData = (keysByTypes, filterData) =>
	filterData
		.reduce((acc, filterConfig) => {
			const filterKeys = keysByTypes[filterConfig.type];

			const allowedProperties = filterKeys?.map(key => filterConfig[key]) ?? [];

			return [...acc, ...allowedProperties];
		}, [])
		.filter(Boolean);

export default pullAllowedPropertiesFromFilterData;
