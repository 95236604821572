import { useMemo } from 'react';

const fallbackValues = {
	country: null,
	aparmentBuilding: '',
	addressLine1: '',
	addressLine2: '',
	cityRegion: '',
	postCode: '',
};

const useAddressFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			country: data.country
				? {
						value: data.country.id,
						label: data.country.name,
				  }
				: null,
			aparmentBuilding: data.aparmentBuilding ?? '',
			addressLine1: data.addressLine1 ?? '',
			addressLine2: data.addressLine2 ?? '',
			cityRegion: data.cityRegion ?? '',
			postCode: data.postCode ?? '',
		}),
		[data],
	);

export default useAddressFormInitialValues;
