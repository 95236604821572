import api from 'Application/api/api';

const getPartnerByCountryIds = (signal, countryId) =>
	api.get('/other-partners/get-pageable', {
		params: {
			countryId: countryId,
		},
		signal,
	});

export default getPartnerByCountryIds;
