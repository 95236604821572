import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import useResponsive from 'Common/hooks/useResponsive';

const StyledH1 = styled.h1`
	margin-top: 0;
	margin-bottom: 32px;
	color: ${colors.text.primary};
	font-weight: 600;
	font-size: ${({ $isMobile }) => ($isMobile ? '16px' : '30px')};
	line-height: ${({ $isMobile }) => ($isMobile ? '28px' : '44px')};
	text-align: center;
`;

const H1 = props => {
	const { isMobile } = useResponsive();

	return <StyledH1 {...props} $isMobile={isMobile} />;
};

export default H1;
