import styled from 'styled-components/macro';
import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';

import useProjectStats from './hooks/useProjectStats';
import ProjectInfoSummary from './ProjectInfoSummary';
import ProjectInfoSystemSizeView from './ProjectInfoSystemSizeView';

import sizes from 'Application/theme/sizes';
import colors from 'Application/theme/colors';

const InfoTile = styled.div`
	border-radius: 20px;
	border: 1px solid ${colors.primary.dark};
	padding: 0 16px;
	margin-bottom: ${sizes.base(2)};
`;

const Title = styled.div`
	font-size: 18px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5px;
`;

const Text = styled.span`
	font-size: 16px;
	margin: 5px 0;
`;

const ProjectInfoTileView = ({ project, financeDetails, campaignId }) => {
	const {
		projectName,
		otherCampaignsSystemSizeValue,
		otherCampaignsSystemSizePercent,
		currentCampaignSystemSizeValue,
		currentCampaignSystemSizePercent,
	} = useProjectStats(project, financeDetails, campaignId);

	return (
		<InfoTile>
			<Title>
				<Text>{projectName}</Text>
			</Title>
			<Grid container direction="column">
				<Grid item xs={12}>
					<ProjectInfoSummary
						projectType={project?.projectType?.name}
						systemSizeKwp={project?.systemSizeKwp}
						annualCO2Savings={project?.annualCO2Savings}
						otherCampaignsSystemSizeValue={otherCampaignsSystemSizeValue}
						otherCampaignsSystemSizePercent={otherCampaignsSystemSizePercent}
						currentCampaignSystemSize={currentCampaignSystemSizeValue}
						currentCampaignSystemSizePercent={currentCampaignSystemSizePercent}
					/>
				</Grid>
				<Grid item xs={12}>
					<ProjectInfoSystemSizeView
						projectType={project?.projectType?.name}
						systemSizeKwp={currentCampaignSystemSizeValue}
						systemSizePercent={currentCampaignSystemSizePercent}
					/>
				</Grid>
			</Grid>
		</InfoTile>
	);
};

ProjectInfoTileView.defaultProps = {
	financeDetails: { annualCo2Contribution: 0 },
};

ProjectInfoTileView.propTypes = {
	project: PropTypes.shape({
		projectType: PropTypes.shape({
			name: PropTypes.string,
		}).isRequired,
		systemSizeKwp: PropTypes.number.isRequired,
		annualCO2Savings: PropTypes.number.isRequired,
	}).isRequired,
	financeDetails: PropTypes.shape({
		annualCo2Contribution: PropTypes.number,
	}),
	campaignId: PropTypes.number.isRequired,
};

export default ProjectInfoTileView;
