import api from 'Application/api/api';
import adaptClientContractRequestData from './adapters/adaptClientContractRequestData';
import adaptClientContractResponseData from './adapters/adaptClientContractResponseData';

const editClientContract = async (signal, clientContractId, values) => {
	const response = await api.patch(
		`/client-contracts/${clientContractId}`,
		adaptClientContractRequestData(values),
		{
			signal,
			timeout: 100000,
		},
	);

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptClientContractResponseData(response.data),
	};
};

export default editClientContract;
