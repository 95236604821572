import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { merge } from 'lodash';
import getInquiriesFilterRange from 'Inquiries/api/getInquiriesFiltersRange';
import loadIndustryOptions from 'Industries/utils/loadIndustryOptions';
import loadCountryOptions from 'Country/utils/loadCountryOptions';
import loadProjectTypeOptions from 'Projects/utils/loadProjectTypeOptions';
import loadEpcPartnerOptions from 'EpcPartner/utils/loadEpcPartnerOptions';
import modifyNumberFilterData from 'Common/components/filter/hooks/modifyNumberFilterData';
import showToastError from 'Common/utils/showToastError';
import { FILTER_TYPES } from 'Common/components/filter/constants/filterConstants';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import getContractsFilterRange from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/ClientContract/api/getContractsFilterRange';

const fallbackFilterRanges = {
	systemSizeKwpMin: 0,
	systemSizeKwpMax: 0,
	epcVolumeExclVatMin: 0,
	epcVolumeExclVatMax: 0,
};

const useInquiriesFilterData = () => {
	const { t } = useTranslation();

	const [filterRanges, setFilterRanges] = useState(fallbackFilterRanges);

	const defaultFilterData = [
		{
			name: 'state',
			label: t('Status'),
			type: FILTER_TYPES.SELECT,
			options: [
				{
					value: 'NEW',
					label: t('New'),
				},
				{
					value: 'OFFER_CREATED',
					label: t('Offer created'),
				},
				{
					value: 'DECLINED',
					label: t('Declined'),
				},
			],
		},
		{
			name: 'projectId',
			label: t('Project ID'),
			type: FILTER_TYPES.INPUT,
		},
		{
			name: 'epcPartnerId',
			label: t('EPC Partner'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadEpcPartnerOptions,
		},
		{
			name: 'countryId',
			label: t('Country'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadCountryOptions,
		},
		{
			name: 'clientName',
			label: t('Client'),
			type: FILTER_TYPES.INPUT,
		},
		{
			name: 'industryId',
			label: t('Industry'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadIndustryOptions,
		},
		{
			name: 'projectTypeId',
			label: t('Type'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadProjectTypeOptions,
		},
		{
			name: 'systemSizeKwp',
			label: t('System size (kWp)'),
			type: FILTER_TYPES.NUMBER,
			minProps: {
				icon: 'kWp',
				iconPosition: 'right',
			},
			maxProps: {
				icon: 'kWp',
				iconPosition: 'right',
			},
			minName: 'systemSizeKwpFrom',
			maxName: 'systemSizeKwpTo',
			lowerBound: filterRanges.systemSizeKwpMin,
			upperBound: filterRanges.systemSizeKwpMax,
		},
		{
			name: 'epcVolumeExclVat',
			label: t('EPC volume'),
			type: FILTER_TYPES.NUMBER,
			minProps: {
				icon: '€',
			},
			maxProps: {
				icon: '€',
			},
			minName: 'epcVolumeExclVatFrom',
			maxName: 'epcVolumeExclVatTo',
			lowerBound: filterRanges.epcVolumeExclVatMin,
			upperBound: filterRanges.epcVolumeExclVatMax,
		},

		{
			name: 'lastModifiedDate',
			label: t('Updated'),
			type: FILTER_TYPES.DATE,
			startDateName: 'lastModifiedDateFrom',
			endDateName: 'lastModifiedDateTo',
		},
	];

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				const responses = await Promise.all([
					getInquiriesFilterRange(abortController.signal),
					getContractsFilterRange(abortController.signal),
				]);

				setFilterRanges(prevFilterRanges => ({
					...prevFilterRanges,
					...merge(...responses.map(response => response.data)),
				}));
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setFilterRanges(fallbackFilterRanges);
				}
			}
		})();
	}, [abortController.signal]);

	const [filtersData, setFiltersData] = useState(defaultFilterData);

	useEffect(() => {
		const modifyNumberFilterDataBinded = modifyNumberFilterData.bind(undefined, filterRanges);

		setFiltersData(prevFilterData => [...prevFilterData].map(modifyNumberFilterDataBinded));
	}, [filterRanges]);

	return { filtersData };
};

export default useInquiriesFilterData;
