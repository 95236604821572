import getEmissionSpvs from 'Preferences/api/getEmissionSpvs';

const loadEmissionSpvOptions = async signal => {
	const response = await getEmissionSpvs(signal);
	const emissionSpvs = response.data;

	return emissionSpvs.map(emission => ({
		value: emission.id,
		label: emission.name,
	}));
};

export default loadEmissionSpvOptions;
