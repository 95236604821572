import crudModes from 'Common/constants/crudModes';
import formatDateForRequestData from 'Common/utils/formatDateForRequestData';

const convertProposalToRequestData = (values, mode) => {
	const isEdtMode = mode === crudModes.EDIT;
	return {
		epcPartnerId: values?.epcPartner?.value,
		expectedGridInjection: values.expectedGridInjection,
		initialEngagementDate: values.initialEngagementDate
			? formatDateForRequestData(values.initialEngagementDate)
			: null,
		offerValidityDate: values.offerValidityDate ? formatDateForRequestData(values.offerValidityDate) : null,

		...(isEdtMode && {
			// changesMade: changes.join('<br>'),
			signed: values.signed,
		}),
	};
};

export default convertProposalToRequestData;
