import { useEffect, useMemo, useState } from 'react';
import Icon from 'Common/components/icons/Icon';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';

import getRegionalHubs from 'Preferences/api/getRegionalHubs';
import deleteRegionalHub from 'Preferences/api/deleteRegionalHub';
import RegionalHubEditModal from 'Preferences/components/Tabs/CountriesTab/RegionalHub/RegionalHubEditModal';
import RegionalHubCreateModal from 'Preferences/components/Tabs/CountriesTab/RegionalHub/RegionalHubCreateModal';

import notify from 'Common/utils/notify';
import EditableTile from 'Common/components/Tile/EditableTile';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import TableCellTextList from 'Common/components/table/TableCellTextList';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { DefaultTable, TableHeadCell, TableBodyCell } from 'Common/components/table/table';

import colors from 'Application/theme/colors';
import UsersPreview from 'Common/components/usersPreview/UsersPreview';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}
	${TableBodyCell} {
		background: transparent;
	}
`;

const TooltipWrapper = styled.div`
	display: flex;
	align-items: center;
	${({ large }) =>
		large
			? css`
					margin-left: 10px;
			  `
			: css`
					position: relative;
					margin-left: 7px;
			  `};

	button {
		outline: none !important;
	}

	svg {
		display: block !important;
		color: ${colors.primary.main};
		${({ large }) =>
			large
				? css``
				: css`
						font-size: 16px !important;
				  `};
	}
`;

const RegionalHubList = () => {
	const { t } = useTranslation();

	const [regionalHubs, setRegionalHubs] = useState([]);

	const [regionalHubIdForEditing, setRegionalHubIdForEditing] = useState(null);

	const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

	const [regionalHubIdForDeletion, setRegionalHubIdForDeletion] = useState(null);

	const [deletionError, setDeletionError] = useState(null);

	const [isLoadingContent, setIsLoadingContent] = useState(false);
	const abortController = useAbortController();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Name'),
				accessor: 'name',
			},
			{
				Header: (
					<>
						{t('Countries')}
						<TooltipWrapper>
							<HoverTooltip
								placement="right"
								title={t('To add or remove Countries, edit them in the tile above.')}
								arrow
							>
								<Icon icon="helpOutline" />
							</HoverTooltip>
						</TooltipWrapper>
					</>
				),
				accessor: 'countries',
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original: regionalHub } }) => (
						<TableCellTextList listItems={regionalHub.countries}>
							{regionalHub.countries.length ? regionalHub.countries.length : 0}
						</TableCellTextList>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								countries: PropTypes.arrayOf(PropTypes.shape({})),
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: (
					<>
						{t('Users')}
						<TooltipWrapper>
							<HoverTooltip
								placement="right"
								title={t('To add or remove Users, edit them in the admin area.')}
								arrow
							>
								<Icon icon="helpOutline" />
							</HoverTooltip>
						</TooltipWrapper>
					</>
				),
				accessor: 'users',
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original: regionalHub } }) => <UsersPreview users={{ ...regionalHub.members }} />;
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ members: PropTypes.arrayOf(PropTypes.shape({})) }) })
							.isRequired,
					};
					return Cell;
				})(),
				customBodyCellContentStyles: {
					overflow: 'visible',
				},
			},
			{
				Header: '',
				accessor: 'actionDots',
				maxWidth: 70,
				customCellStyles: {
					justifyContent: 'flex-end',
				},
				customBodyCellContentStyles: {
					padding: '5px',
				},
			},
		],
		[t],
	);

	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			regionalHubs.map(regionalHub => ({
				name: regionalHub?.name || '-',
				countries: regionalHub?.countries || [],
				members: regionalHub?.members || [],
				actionDots: (
					<MoreActionsButton label="Preference Countries Regional Hub More Actions" controlledVisibility>
						<MenuItem
							data-action="edit"
							onClick={() => setRegionalHubIdForEditing(regionalHub.id)}
							label="Preference Countries Regional Hub More Actions - Edit Menu Item"
						>
							Edit
						</MenuItem>
						<MenuItem
							data-action="delete"
							onClick={() => setRegionalHubIdForDeletion(regionalHub.id)}
							label="Preference Countries Regional Hub More Actions - Edit Menu Item"
						>
							Delete
						</MenuItem>
					</MoreActionsButton>
				),
			})),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[regionalHubs],
	);

	useEffect(() => {
		(async () => {
			try {
				setIsLoadingContent(true);
				const response = await getRegionalHubs(abortController.signal, true);

				setIsLoadingContent(false);
				setRegionalHubs(response.data);
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [abortController.signal]);

	const handleDataUpdate = data => {
		setRegionalHubs(data);
	};

	const handleDeleteRegionalHub = async id => {
		try {
			await deleteRegionalHub(abortController.signal, id);

			notify(t('Regional hub deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});

			const regionalHubResponse = await getRegionalHubs(abortController.signal, true);
			handleDataUpdate(regionalHubResponse.data);
			setRegionalHubIdForDeletion(null);
		} catch (error) {
			if (!axios.isCancel(error)) {
				setDeletionError(error.response.data);
				setRegionalHubIdForDeletion(null);
			}
		}
	};

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
			},
			useCustomCellStyles,
		);

	const regionalHubForDeletion = regionalHubIdForDeletion
		? regionalHubs.find(regionalHub => regionalHub.id === regionalHubIdForDeletion)
		: null;

	const hasRegionalHubForDeletion = !!regionalHubForDeletion && !!regionalHubIdForDeletion;

	return (
		<EditableTile
			title={t('Regional Hubs')}
			rightComponent={
				<TileActionButton data-button="regionalHubs" onClick={() => setIsCreateModalOpened(true)} />
			}
			isLoadingContent={isLoadingContent}
			isTable
		>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
				/>
			</TableWrapper>
			<RegionalHubCreateModal
				isOpen={isCreateModalOpened}
				onClose={() => setIsCreateModalOpened(false)}
				onFormSubmit={handleDataUpdate}
			/>
			{regionalHubIdForEditing !== null && (
				<RegionalHubEditModal
					isOpen={regionalHubIdForEditing !== null}
					onClose={() => setRegionalHubIdForEditing(null)}
					onFormSubmit={handleDataUpdate}
					regionalHubId={regionalHubIdForEditing}
				/>
			)}

			{hasRegionalHubForDeletion && (
				<ConfirmModal
					isOpen
					onCancel={() => setRegionalHubIdForDeletion(null)}
					onConfirm={() => handleDeleteRegionalHub(regionalHubIdForDeletion)}
					label="delete-regional-hub-confirm"
					heading={t('Delete Regional Hub')}
					text={t(`Are you sure you want to delete Regional hub ${regionalHubForDeletion.name} ?`)}
				/>
			)}

			<UnsuccessfulOperationModal
				label="cannot-delete-regional-hub-confirm"
				isOpen={deletionError !== null}
				onCancel={() => setDeletionError(null)}
				heading={t('Cannot delete Regional hub')}
				text={deletionError?.violations?.[0]?.message}
			/>
		</EditableTile>
	);
};

export default RegionalHubList;
