import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import Big from 'Common/utils/customBig';
import Link from 'Common/components/Link';
import crudModes from 'Common/constants/crudModes';
import FormField from 'Common/components/form/FormField';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import FormikInput from 'Common/components/form/FormikInput';
import { statuses } from 'Campaigns/constants/trancheStatuses';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import FormikTimeSelect from 'Common/components/form/FormikTimeSelect';
import FormikSelect from 'Common/components/form/FormikSelect';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';

import loadEmissionSPVOptions from 'Projects/utils/loadEmissionSPVOptions';
import useTrancheFormInitialValues from './hooks/useTrancheFormInitialValues';
import useTrancheFormValidationSchema from './hooks/useTrancheFormValidationSchema';
import convertFormValuesToRequestData from './utils/convertFormValuesToRequestData';

import editTranche from 'Campaigns/api/editTranche';
import createTranche from 'Campaigns/api/createTranche';
import loadPlatformOptions from 'Campaigns/utils/loadPlatformOptions';

import loadTagOptions from 'Preferences/utils/loadTagOptions';

import colors from 'Application/theme/colors';
import useReponsive from 'Common/hooks/useResponsive';
import UNITS from 'Common/constants/units';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import Icon from 'Common/components/icons/Icon';
import sizes from 'Application/theme/sizes';
import { isLastSundayOfMarch } from 'Campaigns/components/CampaignDetails/Tabs/TranchesTab/Tiles/TranchesListTile/TrancheForm/utils/isSummerTimeChangeDate';
import { ModalFormButtons } from 'Common/components/form/ModalForm';

const SectionTitle = styled.div`
	font-size: 18px;
	font-weight: 600;
	margin: 30px 20px 20px 0;
	display: flex;
`;

const Form = styled.form`
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	${({ $isMobile }) =>
		$isMobile
			? css`
					padding: 4px 8px;

					${FormField} {
						margin: 0 auto;
					}
			  `
			: css`
					padding: 16px 32px;
			  `}
`;

const TotalPercantageContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
	margin-bottom: 20px;
	font-weight: 600;

	${({ isValid }) =>
		!isValid &&
		css`
			color: ${colors.error.main};
		`})}
	
`;

const LinkWrapper = styled.div`
	padding-left: 10px;
`;

const StyledFormField = styled(FormField)`
	max-width: 230px;
	position: relative;
	//min-width: 170px;
`;

const Required = styled.span`
	color: ${colors.error.main};
	margin-left: 4px;
`;

const WarningContainer = styled.div`
	position: absolute;
	right: 0;
	bottom: ${sizes.spacing(0.6)};
`;

const TrancheForm = ({ data, mode, onSubmit, onCancel, onDirty, campaignData }) => {
	const { t } = useTranslation();
	const { isMobile } = useReponsive();
	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();

	const initialValues = useTrancheFormInitialValues(data);
	const validationSchema = useTrancheFormValidationSchema();

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleChange,
		handleBlur,
		setFieldValue,
		setFieldError,
		setFieldTouched,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			setIsLoading(true);
			const areSchemeFieldsFilled = values.bulletCheckbox || values.constantCheckbox || values.annuityCheckbox;
			const areSchemeFieldsNotFilled =
				!values.bulletCheckbox && !values.constantCheckbox && !values.annuityCheckbox;
			if (areSchemeFieldsNotFilled) {
				notify(t('Repayment scheme is required.'), {
					type: toast.TYPE.ERROR,
				});
				setIsLoading(false);
			} else if (!validatePercеntage && areSchemeFieldsFilled) {
				notify(t('Values of annuity, bullet and constant must sum up to 100'), {
					type: toast.TYPE.ERROR,
				});
				setIsLoading(false);
			} else {
				try {
					let response;

					if (mode === crudModes.CREATE) {
						response = await createTranche(abortController.signal, {
							financeId: campaignData.auto_id,
							...convertFormValuesToRequestData(values),
						});

						notify(t('Tranche created successfully.'), {
							type: toast.TYPE.SUCCESS,
						});
					} else if (mode === crudModes.EDIT) {
						response = await editTranche(abortController.signal, {
							...data,
							id: data.auto_id,
							...convertFormValuesToRequestData(values),
						});

						notify(t('Tranche saved successfully'), {
							type: toast.TYPE.SUCCESS,
						});
					}

					// Re-set all fields and mark them as not touched
					resetForm({ values });

					if (onSubmit) {
						onSubmit(response.data);
					}
					setIsLoading(false);
				} catch (error) {
					showAxiosResponseError({
						error,
						setFormikFieldError: setFieldError,
					});

					if (!axios.isCancel(error)) {
						setIsLoading(false);
					}
				}
			}
		},
	});

	const percentageTotal = useMemo(
		() =>
			[
				{
					value: Number(values.annuity),
					isChecked: values.annuityCheckbox,
				},
				{
					value: Number(values.constant),
					isChecked: values.constantCheckbox,
				},
				{
					value: Number(values.bullet),
					isChecked: values.bulletCheckbox,
				},
			].reduce((total, percentage) => {
				if (!isNaN(percentage.value) && percentage.value !== undefined && percentage.isChecked) {
					return Big(total).plus(percentage.value);
				}
				return total;
			}, 0),
		[
			values.annuity,
			values.bullet,
			values.constant,
			values.annuityCheckbox,
			values.constantCheckbox,
			values.bulletCheckbox,
		],
	);

	// ! Special warnings for the daylight saving time edge case where the last Sunday of March doesn't have 2 AM and 3 AM
	const showLastSundayMarchWarningFrom = useMemo(
		() => values.investableFrom && isLastSundayOfMarch(values.investableFrom),
		[values.investableFrom],
	);

	const showLastSundayMarchWarningUntil = useMemo(
		() => values.investableUntil && isLastSundayOfMarch(values.investableUntil),
		[values.investableUntil],
	);

	const validatePercеntage = Big(percentageTotal).eq(100);

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	const isEmmissionIdDisabled =
		(data?.status === statuses.ACTIVE || data?.status === statuses.CLOSED_INVESTED) && data?.synched;

	return (
		<>
			<Form $isMobile={isMobile}>
				<Grid container spacing={4}>
					<Grid item xs={12} sm={12} md={6} lg={5}>
						<SectionTitle>{t('General')}</SectionTitle>
						<Grid container spacing={4}>
							<Grid item xs={12} lg={6}>
								<StyledFormField>
									<FormikInput
										id="reference"
										name="reference"
										label={t('Name')}
										value={values.reference}
										error={errors.reference}
										touched={touched.reference}
										onChange={handleChange}
										onBlur={handleBlur}
										isRequired
										tooltip={{
											tooltip: t('Use the same name in portagon'),
										}}
									/>
								</StyledFormField>

								<StyledFormField>
									<FormikInput
										id="tenor"
										name="tenor"
										label={t('Tenor')}
										value={values.tenor}
										error={errors.tenor}
										touched={touched.tenor}
										onChange={handleChange}
										onBlur={handleBlur}
										isRequired
									/>
								</StyledFormField>

								<StyledFormField>
									<FormikSingleDatePicker
										label={t('Start - End date')}
										id="investableField"
										endId="investableField_endDate"
										startDateName="investableFrom"
										endDateName="investableUntil"
										error={errors.investableField}
										touched={touched.investableField}
										startDate={values.investableFrom}
										endDate={values.investableUntil}
										setFieldValue={setFieldValue}
										setFieldTouched={setFieldTouched}
										isDual
									/>
								</StyledFormField>

								<StyledFormField>
									{!values.investableFrom ? (
										<HoverTooltip
											arrow
											arrowplacement="bottom"
											title={'Select a From and To date before specifying the time'}
											placement="top"
										>
											<div>
												<FormikTimeSelect
													label={t('Start Time')}
													id="investableFromHour"
													name="investableFromHour"
													value={null}
													error={errors.investableFromHour}
													touched={touched.investableFromHour}
													setFieldValue={setFieldValue}
													setFieldTouched={setFieldTouched}
													onBlur={handleBlur}
													isDisabled={!values.investableFrom}
													isMenuFixed
												/>
											</div>
										</HoverTooltip>
									) : (
										<FormikTimeSelect
											label={t('Start Time')}
											id="investableFromHour"
											name="investableFromHour"
											value={values.investableFromHour}
											error={errors.investableFromHour}
											touched={touched.investableFromHour}
											setFieldValue={setFieldValue}
											setFieldTouched={setFieldTouched}
											onBlur={handleBlur}
											isDisabled={!values.investableFrom}
											isMenuFixed
										/>
									)}
									{showLastSundayMarchWarningFrom && (
										<WarningContainer>
											<HoverTooltip
												placement="right"
												title="Due to summertime hour change you can't set the start time to 2 or 3 AM on the last Sunday of March. (It will be changed automatically to 1 AM)"
											>
												<Icon icon="reportProblem" color={colors.warning.main} />
											</HoverTooltip>
										</WarningContainer>
									)}
								</StyledFormField>
								<StyledFormField>
									{!values.investableUntil ? (
										<HoverTooltip
											arrow
											arrowplacement="bottom"
											title={'Select a From and To date before specifying the time'}
											placement="top"
										>
											<div>
												<FormikTimeSelect
													label={t('End Time')}
													id="investableUntilHour"
													name="investableUntilHour"
													value={null}
													error={errors.investableUntilHour}
													touched={touched.investableUntilHour}
													setFieldValue={setFieldValue}
													setFieldTouched={setFieldTouched}
													onBlur={handleBlur}
													isDisabled={!values.investableUntil}
													isMenuFixed
												/>
											</div>
										</HoverTooltip>
									) : (
										<FormikTimeSelect
											label={t('End Time')}
											id="investableUntilHour"
											name="investableUntilHour"
											value={values.investableUntilHour}
											error={errors.investableUntilHour}
											touched={touched.investableUntilHour}
											setFieldValue={setFieldValue}
											setFieldTouched={setFieldTouched}
											onBlur={handleBlur}
											isDisabled={!values.investableUntil}
											isMenuFixed
										/>
									)}
									{showLastSundayMarchWarningUntil && (
										<WarningContainer>
											<HoverTooltip
												placement="right"
												title="Due to summertime hour change you can't set the end time to 2 or 3 AM on the last Sunday of March. (It will be changed automatically to 1 AM)"
											>
												<Icon icon="reportProblem" color={colors.warning.main} />
											</HoverTooltip>
										</WarningContainer>
									)}
								</StyledFormField>
							</Grid>
							<Grid item xs={12} lg={6}>
								<StyledFormField>
									<FormikInput
										id="emissionId"
										name="emissionId"
										label={t('Emission ID')}
										value={values.emissionId}
										error={errors.emissionId}
										touched={touched.emissionId}
										onChange={handleChange}
										onBlur={handleBlur}
										tooltip={{
											tooltip: t('Enter the portagon ID to link the Tranche to a portagon Emission'),
										}}
										isDisabled={isEmmissionIdDisabled}
									/>
								</StyledFormField>

								<StyledFormField>
									<FormikSelect
										id="platform"
										name="platform"
										label={t('Platform')}
										value={values.platform}
										error={errors.platform}
										touched={touched.platform}
										setFieldValue={setFieldValue}
										onBlur={handleBlur}
										loadOptions={loadPlatformOptions}
										menuPosition="fixed"
										isRequired
										isAsync
										tooltip={{
											tooltip: t(
												'If the Tranche is for a co-funding platform, select it here and it will not be displayed on the ecoligo platform.',
											),
										}}
									/>
								</StyledFormField>
								<StyledFormField>
									<FormikSelect
										name="emissionSpv"
										label={t('Emission SPV')}
										id="emissionSpv"
										error={errors.emissionSpv}
										touched={touched.emissionSpv}
										value={values.emissionSpv}
										setFieldValue={setFieldValue}
										loadOptions={loadEmissionSPVOptions}
										menuPosition="fixed"
										isClearable
										isRequired
										isAsync
										onBlur={handleBlur}
									/>
								</StyledFormField>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={3}>
						<SectionTitle>{t('Interest')}</SectionTitle>
						<StyledFormField>
							<FormikInput
								id="interestRate"
								name="interestRate"
								label={t('Interest rate')}
								value={values.interestRate}
								error={errors.interestRate}
								touched={touched.interestRate}
								onChange={handleChange}
								onBlur={handleBlur}
								tooltip={{
									tooltip: t('The full interest rate for this Tranche, including any bonus.'),
								}}
								isRequired
								unit={UNITS.PERCENT}
							/>
						</StyledFormField>
						<StyledFormField>
							<FormikInput
								id="bonusAmount"
								name="bonusAmount"
								label={t('Bonus amount')}
								value={values.bonusAmount}
								error={errors.bonusAmount}
								touched={touched.bonusAmount}
								onChange={handleChange}
								onBlur={handleBlur}
								tooltip={{
									tooltip: t(
										'If the interest rate includes a bonus, enter the bonus amount for display on the website.',
									),
								}}
								unit={UNITS.PERCENT}
							/>
						</StyledFormField>
						<SectionTitle>{t('Tags')}</SectionTitle>

						<StyledFormField>
							<FormikSelect
								name="tags"
								id="tags"
								error={errors.tags}
								touched={touched.tags}
								value={values.tags}
								setFieldValue={setFieldValue}
								loadOptions={loadTagOptions}
								label={t('Tags')}
								menuPosition="fixed"
								isClearable
								isMulti
								isAsync
								onBlur={handleBlur}
								tooltip={{
									tooltip: t('To re-order tags, remove them and add in the required order.'),
								}}
							/>
						</StyledFormField>
						<LinkWrapper>
							<Link color="#00b4d5" to="/preferences?tab=marketing" target="_blank">
								<span>{t('Manage tags')}</span>
							</Link>
						</LinkWrapper>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={4}>
						<SectionTitle>
							{t('Individual investment amount')}
							<QuestionTooltip
								tooltip={t('The size of each individual investment, and the amount increments must be divisible by.')}
							/>
						</SectionTitle>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<StyledFormField>
									<FormikInput
										id="minInvestmentAmount"
										name="minInvestmentAmount"
										label={t('Min')}
										value={values.minInvestmentAmount}
										error={errors.minInvestmentAmount}
										touched={touched.minInvestmentAmount}
										onChange={handleChange}
										onBlur={handleBlur}
										prefix="€"
										isRequired
									/>
								</StyledFormField>
							</Grid>
							<Grid item xs={12}>
								<StyledFormField>
									<FormikInput
										id="maxInvestmentAmount"
										name="maxInvestmentAmount"
										label={t('Max')}
										value={values.maxInvestmentAmount}
										error={errors.maxInvestmentAmount}
										touched={touched.maxInvestmentAmount}
										onChange={handleChange}
										onBlur={handleBlur}
										prefix="€"
										tooltip={{
											tooltip: t('The example payouts plan from portagon uses the Max investment amount.'),
										}}
										isRequired
									/>
								</StyledFormField>
							</Grid>
							<Grid item xs={12}>
								<StyledFormField>
									<FormikInput
										id="divisibleBy"
										name="divisibleBy"
										label={t('Divisible by')}
										value={values.divisibleBy}
										error={errors.divisibleBy}
										touched={touched.divisibleBy}
										onChange={handleChange}
										onBlur={handleBlur}
										prefix="€"
										isRequired
									/>
								</StyledFormField>
							</Grid>
						</Grid>
						<SectionTitle>
							{t('Repayment scheme')}
							<Required>*</Required>
						</SectionTitle>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<div>
									<StyledFormField>
										<FormikCheckbox
											label={t('Annuity')}
											id="annuityCheckbox"
											name="annuityCheckbox"
											checked={values.annuityCheckbox}
											onChange={handleChange}
											onBlur={handleBlur}
											touched={touched.annuityCheckbox}
										/>
									</StyledFormField>
									{values.annuityCheckbox && (
										<StyledFormField>
											<FormikInput
												id="annuity"
												name="annuity"
												error={errors.annuity}
												touched={touched.annuity}
												value={values.annuity}
												onChange={handleChange}
												onBlur={handleBlur}
												unit={UNITS.PERCENT}
											/>
										</StyledFormField>
									)}
								</div>
							</Grid>
							<Grid item xs={12}>
								<div>
									<StyledFormField>
										<FormikCheckbox
											label={t('Bullet')}
											id="bulletCheckbox"
											name="bulletCheckbox"
											checked={values.bulletCheckbox}
											onChange={handleChange}
											onBlur={handleBlur}
											touched={touched.bulletCheckbox}
										/>
									</StyledFormField>
									{values.bulletCheckbox && (
										<StyledFormField>
											<FormikInput
												id="bullet"
												name="bullet"
												error={errors.bullet}
												touched={touched.bullet}
												value={values.bullet}
												onChange={handleChange}
												onBlur={handleBlur}
												unit={UNITS.PERCENT}
											/>
										</StyledFormField>
									)}
								</div>
							</Grid>
							<Grid item xs={12}>
								<div>
									<StyledFormField>
										<FormikCheckbox
											label={t('Constant')}
											id="constantCheckbox"
											name="constantCheckbox"
											checked={values.constantCheckbox}
											onChange={handleChange}
											onBlur={handleBlur}
											touched={touched.constantCheckbox}
										/>
									</StyledFormField>
									{values.constantCheckbox && (
										<StyledFormField>
											<FormikInput
												id="constant"
												name="constant"
												error={errors.constant}
												touched={touched.constant}
												value={values.constant}
												onChange={handleChange}
												onBlur={handleBlur}
												unit={UNITS.PERCENT}
											/>
										</StyledFormField>
									)}
								</div>
							</Grid>
						</Grid>
						{(values.bulletCheckbox || values.constantCheckbox || values.annuityCheckbox) && (
							<TotalPercantageContainer isValid={validatePercеntage}>
								{`${!isNaN(Number(percentageTotal)) ? percentageTotal : '-'} %`}
							</TotalPercantageContainer>
						)}
					</Grid>
				</Grid>
			</Form>
			<ModalFormButtons
				onCancel={onCancel}
				onConfirm={handleSubmit}
				isLoading={isLoading}
				label="Campaign Details Tranches List Form"
				submitButtonText={mode === crudModes.CREATE ? t('Create') : t('Save')}
			/>
		</>
	);
};

TrancheForm.defaultProps = {
	data: null,
};

TrancheForm.propTypes = {
	data: PropTypes.shape({
		auto_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		status: PropTypes.string.isRequired,
		synched: PropTypes.bool.isRequired,
	}),
	mode: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
	campaignData: PropTypes.shape({ auto_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })
		.isRequired,
};

export default TrancheForm;
