import { createSlice } from '@reduxjs/toolkit';

const initialNotificationsData = {
	newNotificationsEntry: [],
};

export const notificationsSlice = createSlice({
	name: 'notifications',
	initialState: { ...initialNotificationsData },
	reducers: {
		addNewNotificationEntry: state => {
			state.newNotificationsEntry = [...state.newNotificationsEntry, state.newNotificationsEntry.length + 1];
		},
		clearNewNotificationsEntries: state => {
			state.newNotificationsEntry = [];
		},
	},
});

export const { addNewNotificationEntry, clearNewNotificationsEntries } = notificationsSlice.actions;

export default notificationsSlice.reducer;
