import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useMarketingFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				euro: yup
					.number()
					.typeError(t('Must be a number'))
					.integer('Must be an integer')
					.required(t('Required'))
					.positive('Must be positive'),
			}),
		[t],
	);
};

export default useMarketingFormValidationSchema;
