import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import PartnerDetailsTile from 'Partners/components/PartnerDetails/Tabs/DetailsTab/Tiles/PartnerDetailsTile/PartnerDetailsTile';
import AddressTile from 'Partners/components/PartnerDetails/Tabs/DetailsTab/Tiles/AddressTile/AddressTile';
import { PARTNER_DETAILS_PAGE_TAB_TILES } from 'Partners/constants/partnerTabForms';

const DetailsTab = ({ partner, onEnterEditMode, onExitEditMode, onDataChange, onDirtyForm }) => {
	const handlePartnerDetailsDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PARTNER_DETAILS_PAGE_TAB_TILES.GENERAL.DETAILS, isDirty);
		},
		[onDirtyForm],
	);

	const handleAddressDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PARTNER_DETAILS_PAGE_TAB_TILES.GENERAL.ADDRESS, isDirty);
		},
		[onDirtyForm],
	);

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<PartnerDetailsTile
					partner={partner}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={onDataChange}
					onDirtyForm={handlePartnerDetailsDirtyForm}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<AddressTile
					partner={partner}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={onDataChange}
					onDirtyForm={handleAddressDirtyForm}
				/>
			</Grid>
		</TilesGridWrapper>
	);
};

DetailsTab.defaultProps = {
	onEnterEditMode: () => {},
	onExitEditMode: () => {},
	onDirtyForm: () => {},
};

DetailsTab.propTypes = {
	partner: PropTypes.shape({}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDataChange: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
};

export default DetailsTab;
