import styled from 'styled-components/macro';

const HeaderActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex: 1;

	> * {
		margin-right: 8px;

		&:last-child {
			margin-right: 16px;
		}
	}
`;

export default HeaderActions;
