import { eachWeekendOfMonth, isSameDay } from 'date-fns';

const isLastSundayOfMonth = date => {
	const lastSundayOfMonth = eachWeekendOfMonth(date).pop();

	return isSameDay(lastSundayOfMonth, date);
};

const isLastSundayOfMarch = date => date.getMonth() === 2 && isLastSundayOfMonth(date);
const isLastSundayOfOctober = date => date.getMonth() === 9 && isLastSundayOfMonth(date);

export { isLastSundayOfMarch, isLastSundayOfOctober };
