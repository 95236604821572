import { useEffect, useState, useRef } from 'react';
import { push } from 'redux-first-history';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UnsignedClientListTable from 'Client/components/ClientList/Tabs/UnsignedClientList/UnsignedClientListTable';
import useSmartResize from 'Common/hooks/table/useSmartResize';

const UnsignedClientList = () => {
	const storedTableState = useSelector(state => state.tables.unsignedClients);
	const [shouldTableRender, setShouldTableRender] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();
	const filtersRef = useRef();

	useEffect(() => {
		if (!shouldTableRender) {
			if (location.search === '' && storedTableState && storedTableState.url) {
				dispatch(push(`/unsignedClients${storedTableState.url}`));
			}
			setShouldTableRender(true);
		}
	}, [dispatch, storedTableState, location.search, shouldTableRender]);

	// eslint-disable-next-line
	const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
	useSmartResize(filtersRef, setForceUpdateCounter);

	return <>{(shouldTableRender || !storedTableState?.url) && <UnsignedClientListTable />}</>;
};

export default UnsignedClientList;
