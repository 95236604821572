import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSortBy } from 'react-table';
import { push } from 'redux-first-history';
import { debounce, fill } from 'lodash';
import styled from 'styled-components/macro';
import ReactTimeAgo from 'react-time-ago';
import { PropTypes } from 'prop-types';

import getEpcPartnerOffers from 'EpcPartner/api/getEpcPartnerOffers';
import { formatPortalStatus } from 'EpcPartner/utils/formatPortalStatus';

import { statusColors, statusOptions } from 'Projects/constants/offerStatuses';

import formatDate from 'Common/utils/formatDate';
import formatNumber from 'Common/utils/formatNumber';
import StatusPill from 'Common/components/StatusPill';
import { DefaultTable } from 'Common/components/table/table';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import { useScrollableTableBody } from 'Common/hooks/table/plugin-hooks/useScrollableTableBody';
import { useVirtualizedTableBody } from 'Common/hooks/table/plugin-hooks/useVirtualizedTableBody';
import { useSortingQueryParameter } from 'Common/hooks/table/plugin-hooks/useSortingQueryParameter';
import TableCellText from 'Common/components/table/TableCellText';
import showToastError from 'Common/utils/showToastError';
import useUniqueAbortSignal from 'Common/hooks/useUniqueAbortSignal';

const Wrapper = styled.div`
	position: relative;
`;

const defaultSortBy = {
	id: 'id',
	desc: false,
};

const sortPropertiesMap = {
	externalId: 'id',
};

const checkSystemSize = (offerSystemSize, tenderSystemSize) => {
	if (offerSystemSize) {
		return formatNumber(offerSystemSize, 0);
	} else if (tenderSystemSize) {
		return formatNumber(tenderSystemSize, 0);
	} else {
		return '-';
	}
};

const statusTitles = {
	OFFER_RECEIVED: 'The EPC Partner has provided an Offer to complete this Project.',
	DECLINED_BY_ECOLIGO: 'ecoligo have chosen not to proceed with your Offer',
	DECLINED_BY_PARTNER: 'You have chosen not to proceed with this Project',
	CLOSED: 'Project opportunity no longer available',
	OFFER_SENT: 'Offer has been sent to ecoligo for review',
	OPEN: 'A new Project opportunity from ecoligo',
	AWARDED: 'Congratulations!  You have been awarded this Project',
};

const EpcOffersListTable = () => {
	const { t } = useTranslation();

	const [totalItemsCount, setTotalItemsCount] = useState(25);

	const searchQuery = useQueryParameter('searchQuery');
	const sortByQueryParam = useQueryParameter('sortBy');
	const sortBy = sortByQueryParam ?? defaultSortBy;

	const dispatch = useDispatch();

	const [epcOffers, setEpcOffers] = useState(() => fill(Array(25), {}));
	const [isLoading, setIsLoading] = useState(true);

	const getUniqueSignal = useUniqueAbortSignal();

	const hasLoadedFirstResultsRef = useRef(false);

	const getEPCBatch = useCallback(
		async (startIndex, stopIndex, sortBy, searchQuery, hasItemsChanges = false) => {
			if (hasItemsChanges) {
				setIsLoading(true);
			}

			const sortById = sortPropertiesMap[sortBy.id] ?? sortBy.id;

			try {
				let response = await getEpcPartnerOffers(
					getUniqueSignal('get-epc-offers'),
					{
						offset: startIndex,
						limit: stopIndex - startIndex + 1,
						sortBy: sortById,
						sortDirection: sortBy.desc ? 'DESC' : 'ASC',
					},
					searchQuery,
				);

				if (hasItemsChanges || !hasLoadedFirstResultsRef.current) {
					const itemsCount = response.data.totalElements ?? 0;

					setEpcOffers(
						fill(Array(itemsCount), {}).map((emptyClient, index) => {
							if (startIndex <= index && index <= stopIndex) {
								const indexInResultSet = index - startIndex;

								return response.data.content[indexInResultSet];
							}

							return emptyClient;
						}),
					);

					setTotalItemsCount(itemsCount);
				} else {
					setEpcOffers(prevClients =>
						prevClients.map((prevClient, index) => {
							if (startIndex <= index && index <= stopIndex) {
								const indexInResultSet = index - startIndex;

								return response.data.content[indexInResultSet];
							}

							return prevClient;
						}),
					);
				}

				hasLoadedFirstResultsRef.current = true;

				if (hasItemsChanges) {
					setIsLoading(false);
				}
			} catch (error) {
				showToastError(error, t("Can't fetch EPC offers"));
			}
		},
		[getUniqueSignal, t],
	);

	useEffect(() => {
		getEPCBatch(
			0,
			25,
			{
				id: sortBy.id,
				desc: sortBy.desc,
			},
			searchQuery,
			true,
		);
	}, [getEPCBatch, searchQuery, sortBy.desc, sortBy.id]);

	// eslint-disable-next-line
	const handleLoadMoreRows = useCallback(
		debounce(
			(startIndex, stopIndex) => {
				getEPCBatch(
					startIndex,
					stopIndex,
					{
						id: sortBy.id,
						desc: sortBy.desc,
					},
					searchQuery,
				);
			},
			350,
			{
				leading: false,
				trailing: true,
			},
		),
		[getEPCBatch, searchQuery, sortBy.desc, sortBy.id],
	);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Status'),
				accessor: 'status',
				minWidth: 170,
				maxWidth: 170,
				customBodyCellStyles: {
					paddingLeft: '4px',
				},
				customCellContentStyles: {
					paddingRight: '8px',
				},
				Cell: (() => {
					const Cell = ({ row: { original: offer } }) =>
						offer?.status ? (
							<HoverTooltip placement="top" title={statusTitles[formatPortalStatus(offer?.status)] || ''} arrow>
								<StatusPill color={statusColors[offer?.status]}>
									{statusOptions[formatPortalStatus(offer?.status)]}
								</StatusPill>
							</HoverTooltip>
						) : (
							'-'
						);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ status: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('SaaS date'),
				accessor: 'tenderCloseDate',
				disableSortBy: true,
				Cell: ({ row: { original: offer } }) =>
					offer?.tenderCloseDate ? formatDate(offer?.tenderCloseDate, 'd/M/yy') : '-',
			},
			{
				Header: t('ID'),
				accessor: 'externalId',
				Cell: ({ row: { original: offer } }) => (offer.externalId ? offer.externalId : '-'),
			},
			{
				Header: t('Country'),
				accessor: 'country',
				disableSortBy: true,
				Cell: ({ row: { original: offer } }) => (offer.country ? offer.country : '-'),
			},
			{
				Header: t('Client'),
				accessor: 'client',
				Cell: ({ row: { original: offer } }) => (offer.client ? offer.client : '-'),
			},
			{
				Header: t('Type'),
				accessor: 'type',
				Cell: ({ row: { original: offer } }) => (offer.type ? offer.type : '-'),
			},
			{
				Header: t('System size (kWp)'),
				accessor: 'systemSize',
				Cell: ({ row: { original: offer } }) =>
					offer?.systemSize || offer?.tenderSystemSize
						? checkSystemSize(offer?.systemSize, offer?.tenderSystemSize)
						: '-',
			},
			{
				Header: t('EPC Volume'),
				accessor: 'epcVolumeExclVat',
				Cell: ({ row: { original: offer } }) =>
					offer?.epcVolumeExclVat?.value
						? `${offer?.epcVolumeExclVat?.currency} ${formatNumber(offer?.epcVolumeExclVat?.value)}`
						: '-',
			},
			{
				Header: t('Updated'),
				accessor: 'lastModifiedDate',
				Cell: (() => {
					const Cell = ({ row: { original: offer } }) =>
						offer?.lastModifiedDate ? (
							<TableCellText tooltipContent={formatDate(offer?.lastModifiedDate, 'EEEE dd/MM/yyyy HH:mm')}>
								<ReactTimeAgo date={new Date(offer?.lastModifiedDate)} timeStyle="round" tooltip={false} />
							</TableCellText>
						) : (
							'-'
						);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ lastModifiedDate: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
			},
		],
		[t],
	);

	const data = useMemo(() => epcOffers, [epcOffers]);

	const itemsHash = useMemo(() => JSON.stringify({ sortBy }), [sortBy]);

	const handleRowClick = row => {
		if (!window.getSelection().toString()) {
			dispatch(push(`/epc/offers/${row.original.id}`));
		}
	};

	// Fallback to default row id getter
	const getRowId = useCallback(
		(row, relativeIndex, parent) => (parent ? [parent.id, relativeIndex].join('.') : relativeIndex),
		[],
	);

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		getTableFooterProps,
		getVirtualizedTableBodyProps,
		headerGroups,
		footerGroups,
		rows,
		prepareRow,
	} = useStandardTable(
		{
			data,
			columns,
			defaultSortBy,
			searchTerm: searchQuery,
			onRowClick: handleRowClick,
			virtualization: {
				totalRowsCount: totalItemsCount,
				rowsHash: itemsHash,
				loadMoreRows: handleLoadMoreRows,
			},
			getRowId,
		},
		useClickableTableRow,
		useCustomCellStyles,
		useSortBy,
		useSortingQueryParameter,
		useScrollableTableBody,
		useVirtualizedTableBody,
	);

	return (
		<Wrapper>
			<DefaultTable
				getTableProps={getTableProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				getTableFooterProps={getTableFooterProps}
				getVirtualizedTableBodyProps={getVirtualizedTableBodyProps}
				rows={rows}
				headerGroups={headerGroups}
				footerGroups={footerGroups}
				prepareRow={prepareRow}
				isLoading={isLoading || rows.length !== totalItemsCount}
				showNoResultsFound
			/>
		</Wrapper>
	);
};

export default EpcOffersListTable;
