import { useState, useEffect, useMemo, useCallback } from 'react';
import Filter from 'Common/components/filter/Filter';
import { useTranslation } from 'react-i18next';
import FilterDropdown from 'Common/components/filter/FilterDropdown';
import { debounce } from 'lodash';
import useSearchOptions from 'Common/components/filter/hooks/useSearchOptions';
import showToastError from 'Common/utils/showToastError';
import PropTypes from 'prop-types';
import transformDropdownOption from 'Common/components/filter/utils/transformDropdownOption';
import useAbortController from 'Common/hooks/useAbortController';

const AsyncSelectFilter = ({
	name,
	values,
	loadOptions,
	setFilterValue,
	opened,
	handleSearch,
	stepFilterProps,
	...props
}) => {
	const [options, setOptions] = useState([]);
	const [displayOptions, setDisplayOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();

	const abortController = useAbortController();

	const handleSetFieldValue = val => {
		if (val && typeof val === 'object') {
			const isExisitngValue = values.find(el => String(el.value) === String(val.value));
			if (!isExisitngValue) {
				const transformedOption = transformDropdownOption(val);
				setFilterValue({ [name]: [...values, transformedOption] });
			} else {
				const newFilteredValues = values.filter(el => String(el.value) !== String(val.value));
				setFilterValue({ [name]: [...newFilteredValues] });
			}
		} else {
			const newValue = String(val);
			if (values.includes(newValue)) {
				const oldValueIndex = values.indexOf(newValue);
				setFilterValue({
					[name]: [...values.slice(0, oldValueIndex), ...values.slice(oldValueIndex + 1)],
				});
			} else {
				setFilterValue({ [name]: [...values, newValue] });
			}
		}
	};

	const { searchValue, setSearchValue, filteredOptions } = useSearchOptions(options, false);

	const handleSetSearchValue = value => {
		typeof handleSearch === 'function' && handleSearch(values, handleSetFieldValue);
		setSearchValue(value);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedLoadOptions = useCallback(
		debounce(async (inputValue, resolve) => {
			try {
				const loadedOptions = await loadOptions(abortController.signal, inputValue);
				resolve(loadedOptions);
			} catch (e) {
				showToastError(e, t(`Can't load option for filter ${inputValue}`));
			}
		}, 800),
		[t],
	);

	const promiseOptions = useCallback(
		inputValue =>
			new Promise(resolve => {
				debouncedLoadOptions(inputValue, resolve);
			}),
		[debouncedLoadOptions],
	);

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				setOptions(await promiseOptions(searchValue));
				setIsLoading(false);
			} catch (e) {
				showToastError(e);
			}
		})();
	}, [searchValue, promiseOptions]);

	useEffect(() => {
		setDisplayOptions(prevDisplayOptions => {
			const isObjectValues = values?.every(value => Boolean(value) && typeof value === 'object');
			if (isObjectValues) {
				return values.sort((a, b) => a.label.localeCompare(b.label)).filter(el => !!el);
			}

			const prevLength = prevDisplayOptions?.length;
			const valLength = values?.length;
			if (valLength === prevLength) {
				return prevDisplayOptions;
			}
			if (valLength < prevLength) {
				return prevDisplayOptions?.filter(option => values?.includes(String(option.value)));
			}
			if (valLength - prevLength === 1) {
				const newOptionsValue = values?.find(
					option => !prevDisplayOptions?.find(prevOption => String(prevOption.value) === option),
				);
				const newOption = options?.find(el => String(el.value) === newOptionsValue);

				return [...prevDisplayOptions, newOption]
					.sort((a, b) => a.label.localeCompare(b.label))
					.filter(el => !!el);
			}
			return options?.filter(option => values?.includes(String(option.value)));
		});
	}, [options, values]);

	const selectedOptionsCount = useMemo(() => values?.length, [values]);

	const handleClearOptions = e => {
		e.preventDefault();
		setFilterValue({ [name]: [] });
	};

	return (
		<Filter
			count={selectedOptionsCount}
			handleClearOptions={handleClearOptions}
			opened={opened}
			{...props}
			popupContent={
				<FilterDropdown
					isLoading={isLoading}
					selectedOptions={values}
					options={filteredOptions}
					handleOptionClick={handleSetFieldValue}
					handleBottomBtnClick={handleClearOptions}
					bottomBtnLabel={t('Clear all')}
					searchValue={searchValue}
					setSearchValue={handleSetSearchValue}
					displayOptions={displayOptions}
					hasFocus={opened}
					stepFilterProps={stepFilterProps}
				/>
			}
		/>
	);
};

AsyncSelectFilter.defaultProps = {
	values: [],
	opened: false,
	handleSearch: undefined,
	stepFilterProps: undefined,
	loadOptions: () => [],
};

AsyncSelectFilter.propTypes = {
	name: PropTypes.string.isRequired,
	values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
	loadOptions: PropTypes.func,
	setFilterValue: PropTypes.func.isRequired,
	opened: PropTypes.bool,
	handleSearch: PropTypes.func,
	stepFilterProps: PropTypes.shape({}),
};

export default AsyncSelectFilter;
