import Big from 'Common/utils/customBig';
import formatDateForRequestData from 'Common/utils/formatDateForRequestData';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import defaultTimezone from 'Common/constants/defaultTimezone';

const getCustomFilters = filters => {
	const customFilters = { ...filters };

	if (customFilters.irrTo) {
		customFilters.irrTo = Big(customFilters.irrTo).div(100).toString();
	}
	if (customFilters.irrFrom) {
		customFilters.irrFrom = Big(customFilters.irrFrom).div(100).toString();
	}

	if (customFilters.salesCloseDateFrom) {
		customFilters.salesCloseDateFrom = formatDateForRequestData(
			new Date(customFilters.salesCloseDateFrom),
			'yyyy-MM-dd',
		);
	}
	if (customFilters.salesCloseDateTo) {
		customFilters.salesCloseDateTo = formatDateForRequestData(
			new Date(customFilters.salesCloseDateTo),
			'yyyy-MM-dd',
		);
	}

	if (customFilters.lastModifiedDateFrom) {
		customFilters.lastModifiedDateFrom = zonedTimeToUtc(
			format(new Date(customFilters.lastModifiedDateFrom), "yyyy-MM-dd'T'HH:mm.sss'Z'", {
				timeZone: defaultTimezone,
			}),
			defaultTimezone,
		);
	}
	if (customFilters.lastModifiedDateTo) {
		customFilters.lastModifiedDateTo = zonedTimeToUtc(
			format(new Date(customFilters.lastModifiedDateTo), "yyyy-MM-dd'T'HH:mm.sss'Z'", {
				timeZone: defaultTimezone,
			}),
			defaultTimezone,
		);
	}

	return customFilters;
};

export default getCustomFilters;
