import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import { css } from 'styled-components';

const EditingIndicator = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: -5px;
	right: 1px;
	width: 10px;
	height: 10px;
	border-radius: 10px;
	background-color: ${colors.error.main};
	color: ${colors.grey.main};
	transform: translateX(100%);

	transition: all 0.2s ease-out;
	opacity: 1 !important;

	${({ $isHidden }) =>
		$isHidden &&
		css`
			opacity: 0 !important;
			top: -20px;
		`}

	svg {
		font-size: 8px;
	}
`;

export default EditingIndicator;
