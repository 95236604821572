/* eslint-disable no-unused-vars */

import colors from 'Application/theme/colors';

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const offersTableFields = {
	rowLabels: [
		{ label: 'Offers', unit: '€' },
		{ label: 'Signed', unit: 't' },
		{
			label: 'Worst Case',
			tooltip:
				'The case where all restricted offers get signed, and all non-restricted offers do not get signed.',
		},
	],
	columnLabels: [
		{ label: 'Total in MWp' },
		{ label: 'Total in MWp' },
		{ label: '# of Projects' },
		{ label: 'Share' },
		{ label: 'Allowed Left' },
	],
	cells: [
		[
			{
				accessor: 'totalMWpOffers',
				styles: {
					isBold: true,
				},
			},
			{
				accessor: 'totalMWpSigned',
				styles: {
					isBold: true,
				},
			},
			{
				accessor: 'worstCaseTotalMWpSigned',
				styles: {
					isBold: true,
				},
			},
		],
		[
			{
				accessor: 'totalMWpRestrictedOffers',
				styles: {
					isBold: true,
					textColor: colors.common.darkGreen,
				},
			},
			{
				accessor: 'totalMwpRestrictedSigned',
				styles: {
					isBold: true,
					textColor: colors.common.darkGreen,
				},
			},
			{
				accessor: 'worstCaseTotalMWpRestrictedSigned',
				styles: {
					isBold: true,
					textColor: colors.common.darkGreen,
				},
			},
		],
		[
			{
				accessor: 'totalNumberOfOfferedRestrictedProjects',
				styles: {
					isBold: true,
					textColor: colors.common.darkGreen,
				},
			},
			{
				accessor: 'totalNumberOfSignedRestrictedProjects',
				styles: {
					isBold: true,
					textColor: colors.common.darkGreen,
				},
			},
			{
				accessor: 'worstCaseTotalNumberOfSignedRestrictedProjects',
				styles: {
					isBold: true,
					textColor: colors.common.darkGreen,
				},
			},
		],
		[
			{
				accessor: 'shareRestrictedOffers',
				unit: '%',
				styles: {
					isBold: true,
					textColor: colors.common.darkGreen,
				},
			},
			{
				accessor: 'shareSignedOffers',
				unit: '%',
				styles: {
					isBold: true,
					textColor: colors.common.darkGreen,
				},
			},
			{
				accessor: 'worstCaseShareSignedOffers',
				unit: '%',
				styles: {
					isBold: true,
					textColor: colors.common.darkGreen,
				},
			},
		],
		[
			{
				accessor: 'remainingRestrictedOffersAllowed',
				unit: '%',
				styles: {
					isBold: true,
				},
			},
			{
				accessor: 'remainingSignedOffersAllowed',
				unit: '%',
				styles: {
					isBold: true,
				},
			},
			{
				accessor: 'worstCaseRemainingSignedOffersAllowed',
				unit: '%',
				styles: {
					isBold: true,
				},
			},
		],
	],
};

export const ageGroups = {
	betweenEightenAndTwentyNine: '18-29',
	betweenThirtyAndThirtyNine: '30-39',
	betweenFortyAndFortyNine: '40-49',
	betweenFiftyAndFiftyNine: '50-59',
	betweenSixtyAndSixtyNine: '60-69',
	overSeventy: '70+',
};

export const sizes = {
	info: {
		small: '160px;',
		big: '180px;',
	},
	tiles: {
		small: '340px;',
		medium: '360px;',
		large: '520px;',
	},
};

export const projectFields = {
	'# of Projects': ['totalProjectsSigned', 'totalProjectsInOperation', 'totalProjectsMatured'],
	MWp: ['totalMWpSigned', 'totalMWpInOperation', 'totalMWpMatured'],
	'# of Clients': ['totalClientsImpacted', 'totalClientsImpactedInOperation'],
	'kWh Energy': ['projectedKWhSigned', 'projectedKWhInOperation'],
};

export const labelsFields = {
	registeredCrowdInvestors: 'Total',
	totalCrowdInvestors: 'Active',
	notActiveCrowdInvestors: 'Not Active',
};

export const filtersTimeValue = {
	'1M': 1,
	'6M': 6,
	YTD: new Date().getMonth() + 1,
	'1Y': 12,
};

export const filterOptions = {
	'1M': {
		x: {
			ticks: {},
		},
	},
	'6M': {
		x: {
			ticks: {
				maxTicksLimit: 6,
			},
		},
	},
	YTD: {
		x: {
			ticks: {
				maxTicksLimit: new Date().getMonth(),
			},
		},
	},
	'1Y': {
		x: {
			ticks: {
				maxTicksLimit: 13,
			},
		},
	},
	Max: {
		x: {
			ticks: {
				maxTicksLimit: 6,
			},
		},
	},
};

const defaultOptions = {
	maintainAspectRatio: false,
	font: { family: 'Montserrat, sans-serif' },
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: false,
		},
		tooltip: {
			displayColors: false,
			padding: 10,
			titleAlign: 'center',
			bodyAlign: 'center',
			titleFont: { family: 'Montserrat, sans-serif', weight: 500, size: 14 },
			bodyFont: { family: 'Montserrat, sans-serif', weight: 500, size: 14 },
			caretSize: 10,
			callbacks: {
				title: function (title) {
					const item = title[0];
					return item.dataset.values[item.dataIndex];
				},
				label: function (label) {
					const activeLabel = label.dataset.activeLabel || '';
					return activeLabel + ' ' + label.formattedValue;
				},
			},
		},
	},
};

const tooltipExact = {
	hover: {
		mode: 'dataset',
		intersect: false,
	},
};

const tooltipClosest = {
	interaction: {
		displayColors: false,
		mode: 'nearest',
		intersect: false,
	},

	...tooltipExact,
};

const tooltipPercentage = {
	legend: {
		display: false,
	},
	title: {
		display: false,
	},
	tooltip: {
		displayColors: false,
		padding: 10,
		titleAlign: 'center',
		bodyAlign: 'center',
		titleFont: { family: 'Montserrat, sans-serif', weight: 500, size: 14 },
		bodyFont: { family: 'Montserrat, sans-serif', weight: 500, size: 14 },
		caretSize: 10,
		callbacks: {
			title: function (title) {
				const item = title[0];
				return item.dataset.values[item.dataIndex];
			},
			label: function (label) {
				return label.formattedValue + ' %';
			},
		},
	},
};

const scalesDashed = {
	scales: {
		y: {
			ticks: {
				color: '#666666',
				font: {
					size: 12.5,
					weight: 600,
				},
			},
			grid: {
				display: true,
				drawBorder: false,
				drawOnChartArea: true,
				drawTicks: true,
				borderDash: [6, 4],
			},
		},
		x: {
			position: 'bottom',
			ticks: {
				font: {
					size: 12.5,
					weight: 600,
				},
			},
			grid: {
				display: false,
			},
		},
	},
};

const scalesDisabled = {
	scales: {
		y: {
			display: false,
		},
		x: {
			display: false,
		},
	},
};

export const chartTypes = {
	BAR: 'BAR',
	DOUGHNUT: 'DOUGHNUT',
	PIE: 'PIE',
	LINE: 'LINE',
	STACKED_LINE: 'STACKED_LINE',
};

export const graphOptions = {
	[chartTypes.BAR]: {
		...tooltipExact,
		gradient: {
			display: true,
			type: 'radial',
			from: 'rgba(42, 164, 188)',
			to: 'rgba(0, 180, 213, 0.9)',
		},
		maxBarThickness: 32,
		scales: {
			y: {
				ticks: {
					font: {
						size: 12.5,
					},
					stepSize: 25,
					callback: function (label) {
						return label + ' %';
					},
				},
				grid: {
					display: true,
					drawBorder: false,
					drawOnChartArea: true,
					drawTicks: true,
					borderDash: [6, 4],
				},
			},
			x: {
				ticks: {
					font: {
						size: 12.5,
					},
				},
				grid: {
					display: false,
				},
			},
		},
		layout: {
			padding: {
				left: 16,
				bottom: 24,
			},
		},
		maintainAspectRatio: false,
		font: { family: 'Montserrat, sans-serif' },
		plugins: tooltipPercentage,
	},
	[chartTypes.DOUGHNUT]: {
		...tooltipExact,
		...scalesDisabled,
		gradient: {
			display: false,
		},
		maintainAspectRatio: false,
		font: { family: 'Montserrat, sans-serif' },
		plugins: tooltipPercentage,
	},
	[chartTypes.PIE]: {
		...tooltipExact,
		...scalesDisabled,
		gradient: {
			display: false,
		},
		maintainAspectRatio: false,
		font: { family: 'Montserrat, sans-serif' },
		plugins: tooltipPercentage,
	},
	[chartTypes.LINE]: {
		...defaultOptions,
		...tooltipClosest,
		...scalesDashed,
		autofill: true,
		gradient: {
			display: true,
			type: 'linear',
			from: 'rgba(42,164,188,0.5)',
			to: 'rgba(42,164,188,0)',
		},
		maintainAspectRatio: false,
		layout: {
			padding: {
				top: 25,
				bottom: 0,
			},
		},
	},
	[chartTypes.STACKED_LINE]: {
		...defaultOptions,
		...tooltipClosest,
		...scalesDashed,
		autofill: true,
		gradient: {
			display: true,
			type: 'linear',
			from: 'rgba(42,164,188,0.5)',
			to: 'rgba(42,164,188,0)',
		},
		maintainAspectRatio: false,
		layout: {
			padding: {
				top: 25,
			},
		},
	},
};

const lineDatasetOptions = {
	fill: true,
	borderWidth: 1,
	pointBackgroundColor: colors.common.transparent,
	pointBorderColor: colors.common.transparent,
	hoverBorderWidth: 3,
	hoverBorderColor: colors.common.white,
	pointHoverBorderColor: colors.common.transparent,
	pointHitDetectionRadius: 1,
};

const pieDatasetOptions = {
	backgroundColor: [colors.primary.main, colors.primary.dark],
	borderColor: [colors.primary.main, colors.primary.dark],
	hoverBackgroundColor: [colors.primary.main, colors.primary.dark],
};

export const datasetOptions = {
	[chartTypes.BAR]: {
		fill: true,
		tension: 0.5,
		backgroundColor: colors.primary.dark,
		hoverBackgroundColor: colors.primary.dark,
		borderRadius: 14,
		borderSkipped: false,
	},
	[chartTypes.DOUGHNUT]: {
		borderWidth: 1,
		cutout: '70%',
		...pieDatasetOptions,
	},
	[chartTypes.PIE]: {},
	[chartTypes.LINE]: {
		tension: 0.2,
		borderColor: colors.primary.dark,

		...lineDatasetOptions,
	},
	[chartTypes.STACKED_LINE]: {
		tension: 0.5,

		...lineDatasetOptions,
	},
};
