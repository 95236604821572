export const successes = {
	OPERATIONS_ARE_GOING_ACCORDING_TO_PLAN: 'OPERATIONS_ARE_GOING_ACCORDING_TO_PLAN',
	THE_CHALLENGES_ALREADY_DESCRIBED_HAVE_NOW_BEEN_RESOLVED:
		'THE_CHALLENGES_ALREADY_DESCRIBED_HAVE_NOW_BEEN_RESOLVED',
	THERE_WERE_NO_MAJOR_ACHIEVEMENTS: 'THERE_WERE_NO_MAJOR_ACHIEVEMENTS',
};

export const successesOptions = [
	{
		label: 'Operations are going according to plan',
		value: successes.OPERATIONS_ARE_GOING_ACCORDING_TO_PLAN,
	},
	{
		label: 'The challenges already described have now been resolved',
		value: successes.THE_CHALLENGES_ALREADY_DESCRIBED_HAVE_NOW_BEEN_RESOLVED,
	},
	{
		label: 'There were no major achievements',
		value: successes.THERE_WERE_NO_MAJOR_ACHIEVEMENTS,
	},
];
