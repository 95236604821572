import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Link from 'Common/components/Link';
import { useFormik } from 'formik';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import Button from 'Common/components/buttons/Button';
import useForgottenPasswordFormValidationSchema from 'User/components/ForgottenPassword/ForgottenPasswordForm/hooks/useForgottenPasswordFormValidationSchema';
import useForgottenPasswordFormInitialValues from 'User/components/ForgottenPassword/ForgottenPasswordForm/hooks/useForgottenPasswordFormInitialValues';
import passwordReset from 'User/api/passwordReset';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';

const ButtonWrapper = styled.div`
	display: flex;
	height: 100px;
	align-items: flex-end;
	justify-content: flex-end;
	padding-top: 182px;
`;

const ForgottenPasswordForm = ({ onSuccess }) => {
	const { t } = useTranslation();

	const abortController = useAbortController();

	const initialValues = useForgottenPasswordFormInitialValues({});
	const validationSchema = useForgottenPasswordFormValidationSchema();

	const { errors, touched, values, handleSubmit, handleChange, handleBlur } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			// Because of security concerns, regardless of whether an error
			// actually happened, the user gets notified that an e-mail
			// for confirmation was sent. That is why the "catch" is empty.
			try {
				await passwordReset(abortController.signal, values.email);
				onSuccess();
			} catch (error) {
				showToastError(error);
			}
		},
	});

	return (
		<form onSubmit={handleSubmit}>
			<FormField>
				<FormikInput
					id="email"
					name="email"
					label={t('E-mail')}
					value={values.email}
					error={errors.email}
					touched={touched.email}
					onChange={handleChange}
					onBlur={handleBlur}
					placeholder="Email"
				/>
			</FormField>
			<Link to="/login">{t('back to login')}</Link>
			<ButtonWrapper>
				<Button
					type="submit"
					text={t('Reset password')}
					label="Forgotten Password Form - Reset Password Button"
				/>
			</ButtonWrapper>
		</form>
	);
};

ForgottenPasswordForm.defaultProps = {
	onSuccess: () => {},
};

ForgottenPasswordForm.propTypes = {
	onSuccess: PropTypes.func,
};

export default ForgottenPasswordForm;
