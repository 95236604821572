import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import crudModes from 'Common/constants/crudModes';
import ModalForm from 'Common/components/form/ModalForm';

import getRegionalHubById from 'Preferences/api/getRegionalHubById';
import RegionalHubForm from 'Preferences/components/Tabs/CountriesTab/RegionalHub/RegionalHubForm/RegionalHubForm';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';

const RegionalHubEditModal = ({ isOpen, onClose, regionalHubId, onFormSubmit }) => {
	const { t } = useTranslation();

	const [regionalHub, setRegionalHub] = useState(null);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				const response = await getRegionalHubById(abortController.signal, regionalHubId, true);

				setRegionalHub(response.data);
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [regionalHubId, abortController.signal]);

	const isDataLoading = regionalHub === null;

	return (
		<ModalForm
			label="regional-hub-edit"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			isLoadingContent={isDataLoading}
			heading={t('Edit Regional Hub')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<RegionalHubForm
					data={regionalHub}
					mode={crudModes.EDIT}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

RegionalHubEditModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	regionalHubId: PropTypes.string.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default RegionalHubEditModal;
