import api from 'Application/api/api';

const getTrancheById = async (signal, id, details) => {
	const response = await api.get(`/tranches/by-id/${id}`, {
		params: { details },
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
	};
};

export default getTrancheById;
