import colors from 'Application/theme/colors';
import { useMemo } from 'react';

const useKeyValueVisualizationState = (rawValue, rawPlaceholder, isDisabled, unit) => {
	const hasUnit = Boolean(unit);

	const valueColor = useMemo(() => {
		if (isDisabled) {
			return colors.text.greyLight;
		}

		if (!rawValue) {
			return colors.text.greyLight;
		}

		return colors.text.black;
	}, [rawValue, isDisabled]);

	const displayedValue = useMemo(() => {
		if (rawValue || rawValue === 0) {
			if (hasUnit) {
				return `${rawValue} ${unit}`;
			}
			return rawValue;
		}

		if (rawPlaceholder) {
			return rawPlaceholder;
		}

		return '-';
	}, [hasUnit, rawPlaceholder, rawValue, unit]);

	return { displayedValue, valueColor };
};

export default useKeyValueVisualizationState;
