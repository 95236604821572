import api from 'Application/api/api';

const reorderSteps = async (signal, ids) => {
	const response = await api.patch('/step-templates/reorder', ids, {
		signal,
	});

	return response;
};

export default reorderSteps;
