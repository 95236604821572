const capitalizeFirstLetters = string => {
	const spaceIndex = string.indexOf(' ');

	const capitalizedStr =
		string[0].toUpperCase() + string.slice(1, spaceIndex > 0 ? spaceIndex : string.length);

	return `${capitalizedStr}${
		spaceIndex === -1 ? '' : ' ' + capitalizeFirstLetters(string.slice(spaceIndex + 1))
	}`;
};

export default capitalizeFirstLetters;
