import { pick } from 'lodash';

import { roles } from 'User/constants/roles';
import { getRoleGroupByRole } from 'User/constants/roles';

const convertFormValuesToRequestData = values => ({
	...pick(values, ['firstName', 'lastName', 'email', 'phone', 'active', 'profilePhoto']),
	fullName: `${values.firstName} ${values.lastName}`,
	title: values.title ? values.title.value : null,
	roles: values.roles.map(role => ({
		name: role.value,
		roleGroup: getRoleGroupByRole(role.value),
	})),
	regionalHub:
		values.roles.filter(item => item.value === roles.SALES).length > 0
			? values.regionalHub
				? values.regionalHub.length > 0
					? values.regionalHub
					: {}
				: null
			: null,
	regionalHubId:
		values.roles.filter(item => item.value === roles.SALES).length > 0
			? values.regionalHub
				? values.regionalHub.value
				: null
			: null,
});

export default convertFormValuesToRequestData;
