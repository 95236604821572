import api from 'Application/api/api';

const getSalesChannels = (signal, query) =>
	api.get('/sales-channels/get-pageable', {
		params: {
			query,
		},
		signal,
	});

export default getSalesChannels;
