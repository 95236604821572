import { useMemo } from 'react';

const fallbackValues = {
	rate: '',
};

const useExchangeRateFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			rate: data?.rate || '',
		}),
		[data],
	);

export default useExchangeRateFormInitialValues;
