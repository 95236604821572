import api from 'Application/api/api';

const assignDriveFolder = async (signal, projectId, folderName) => {
	const response = await api.patch('/projects/assign-drive-folder/', {
		folderName,
		projectId,
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: response.data,
	};
};

export default assignDriveFolder;
