import { useMemo } from 'react';

const fallbackValues = {
	director1: null,
	director2: null,
	director3: null,
};

const useDirectorsTileFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			director1: data?.directors?.[0]
				? {
						...data?.directors?.[0],
						value: data?.directors?.[0]?.email,
						label: data?.directors?.[0]?.fullName,
				  }
				: null,
			director2: data?.directors?.[1]
				? {
						...data?.directors?.[1],
						value: data?.directors?.[1]?.email,
						label: data?.directors?.[1]?.fullName,
				  }
				: null,
			director3: data?.directors?.[2]
				? {
						...data?.directors?.[2],
						value: data?.directors?.[2]?.email,
						label: data?.directors?.[2]?.fullName,
				  }
				: null,
			notes: data?.notes,
		}),
		[data],
	);

export default useDirectorsTileFormInitialValues;
