import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';
import useAbortController from 'Common/hooks/useAbortController';
import showToastError from 'Common/utils/showToastError';
import editProject from 'Projects/api/editProject';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useReportingFormInitialValues from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/ReportingTile/hooks/useReportingFormInitialValues';
import { m62ChallengeOptions } from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/ReportingTile/constants/m62ChallenengeOptions';
import { successesOptions } from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/ReportingTile/constants/successeOptions';
import { m63ExtraordinaryEventsOptions } from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/ReportingTile/constants/m63ExtraordinaryEventOptions';
import { costExceededOptions } from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/ReportingTile/constants/costExceededOptions';
import { energyProductionExplanationOptionns } from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/ReportingTile/constants/energyProductionExplanationOptionns';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';

const ReportingTile = ({ project, onDirtyForm, onExitEditMode, onEnterEditMode, highlightLabels }) => {
	const { t } = useTranslation();
	const abortController = useAbortController();
	const [isInEditMode, setIsInEditMode] = useState(false);
	const initialValues = useReportingFormInitialValues(project);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		setFieldValue,
		setFieldTouched,
		handleBlur,
		resetForm,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: async values => {
			setIsSavingChanges(true);
			try {
				setIsSavingChanges(true);
				await editProject(abortController.signal, {
					...project,
					energyProductionExplanation: values?.energyProductionExplanation?.value,
					costsExceeded: values?.costsExceeded?.value,
					m62Challenges: values?.m62Challenges?.value,
					successes: values?.successes?.value,
					m63ExtraordinaryEvents: values?.m63ExtraordinaryEvents?.value,
				});
				onDirtyForm(false);
				handleCancel();
				setIsSavingChanges(false);
				setIsInEditMode(false);
				resetForm({ values });
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}
		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		onDirtyForm(dirty);
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Reporting')}
			isInEditMode={isInEditMode}
			isLoading={isSavingChanges}
			onCancel={handleCancel}
			onSubmit={handleSubmit}
			onClick={handleTileClick}
		>
			<FormField>
				<FormikSelect
					id="energyProductionExplanation"
					name="energyProductionExplanation"
					label={t('Energy production explanation')}
					value={values.energyProductionExplanation}
					error={errors.energyProductionExplanation}
					touched={touched.energyProductionExplanation}
					setFieldTouched={setFieldTouched}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					options={energyProductionExplanationOptionns}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_REPORTING.ENERG_PRODUCTION_EXPLANATION,
					)}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					id="costsExceeded"
					name="costsExceeded"
					label={t('Costs exceeded')}
					value={values.costsExceeded}
					error={errors.costsExceeded}
					touched={touched.costsExceeded}
					setFieldTouched={setFieldTouched}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					options={costExceededOptions}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_REPORTING.COST_EXCEEDED,
					)}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					id="m62Challenges"
					name="m62Challenges"
					label={t('M62 challenges')}
					value={values.m62Challenges}
					error={errors.m62Challenges}
					touched={touched.m62Challenges}
					setFieldTouched={setFieldTouched}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					options={m62ChallengeOptions}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_REPORTING.M62_CHALLENGES,
					)}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					id="successes"
					name="successes"
					label={t('Successes')}
					value={values.successes}
					error={errors.successes}
					touched={touched.successes}
					setFieldTouched={setFieldTouched}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					options={successesOptions}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_REPORTING.SUCCESSES,
					)}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					id="m63ExtraordinaryEvents"
					name="m63ExtraordinaryEvents"
					label={t('M_63 extraordinary events')}
					value={values.m63ExtraordinaryEvents}
					error={errors.m63ExtraordinaryEvents}
					touched={touched.m63ExtraordinaryEvents}
					setFieldTouched={setFieldTouched}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					options={m63ExtraordinaryEventsOptions}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_REPORTING.M63_EXTRAORDINARY_EVENTS,
					)}
				/>
			</FormField>
		</EditableTile>
	);
};

ReportingTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	highlightLabels: [],
};

ReportingTile.propTypes = {
	project: PropTypes.shape({
		projectType: PropTypes.shape({ name: PropTypes.string }),
		id: PropTypes.number,
		contractPartyObjectByType: PropTypes.shape({ SaaS: PropTypes.shape({ id: PropTypes.number }) }).isRequired,
		country: PropTypes.shape({ id: PropTypes.number }),
		projectSettings: PropTypes.shape({
			id: PropTypes.number,
			invoiceTemplate: PropTypes.shape({ fileName: PropTypes.string, id: PropTypes.number }),
			bankAccount: PropTypes.shape({ name: PropTypes.string, id: PropTypes.number }),
			saasCurrency: PropTypes.string,
			paymentCurrency: PropTypes.string,
			takeOrPay: PropTypes.bool,
		}),
	}).isRequired,
	onDirtyForm: PropTypes.func,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default ReportingTile;
