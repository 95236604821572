import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import crudModes from 'Common/constants/crudModes';
import PlatformsForm from './PlatformsForm/PlatformsForm';
import getPlatformById from 'Preferences/api/getPlatformById';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PropTypes } from 'prop-types';

const PlatformsEditModal = ({ isOpen, onClose, platformId, onFormSubmit }) => {
	const { t } = useTranslation();

	const [marketSegment, setContractData] = useState(null);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				const response = await getPlatformById(abortController.signal, platformId);

				setContractData(response.data);
			} catch (error) {
				showToastError(error);
			}
		})();
	}, [platformId, abortController.signal]);

	const isDataLoading = marketSegment === null;

	return (
		<ModalForm
			label="platform-edit"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			isLoadingContent={isDataLoading}
			heading={t('Edit Platform')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<PlatformsForm
					data={marketSegment}
					mode={crudModes.EDIT}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

PlatformsEditModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	platformId: PropTypes.string.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default PlatformsEditModal;
