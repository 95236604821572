import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import isAuthorized from 'User/utils/isAuthorized';

import isInAuthorizedGroups from 'User/utils/isInAuthorizedGroups';

import { setIsListPage } from 'Application/reducers/reduxCommon';
import { closeSidePanel } from 'Application/reducers/reduxSidePanel';
import LayoutWithSidebar from 'Common/components/layout/LayoutWithSidebar';

const RouteWithLayout = ({
	isListPage,
	layout: Layout,
	authorizedRoles,
	component: Component,
	authorizedRoleGroups,
	requiresAuthentication,
}) => {
	const isSidePanelOpen = useSelector(state => state.sidePanel.isOpen);
	const isAuthenticated = useSelector(state => state.user.isAuthenticated);

	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		if (isSidePanelOpen) {
			dispatch(closeSidePanel());
		}
		dispatch(setIsListPage(isListPage));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, location, isListPage]);

	// Redirect authenticated and unauthenticated users to their corresponding
	// homepage, based on the current route's authentication policy.
	if (requiresAuthentication && !isAuthenticated) {
		return <Navigate to="/" />;
	}

	// Redirect authenticated users to their homepage,
	// based on the current route's role-based and role-group-based authorization policy.
	if (requiresAuthentication && isAuthenticated) {
		const isAuthorizedByRole = !authorizedRoles || (authorizedRoles && isAuthorized(authorizedRoles));

		const isAuthorizedByRoleGroup =
			!authorizedRoleGroups || (authorizedRoleGroups && isInAuthorizedGroups(authorizedRoleGroups));

		if (!isAuthorizedByRole || !isAuthorizedByRoleGroup) {
			return <Navigate to="/" />;
		}
	}

	return (
		<Layout removeOverfow={isListPage || isSidePanelOpen}>
			<Component />
		</Layout>
	);
};

RouteWithLayout.defaultProps = {
	component: null,
	isListPage: false,
	authorizedRoleGroups: [],
	layout: LayoutWithSidebar,
	authorizedRoles: undefined,
	requiresAuthentication: true,
};

RouteWithLayout.propTypes = {
	component: PropTypes.func,
	isListPage: PropTypes.bool,
	layout: PropTypes.elementType,
	requiresAuthentication: PropTypes.bool,
	authorizedRoles: PropTypes.arrayOf(PropTypes.string),
	authorizedRoleGroups: PropTypes.arrayOf(PropTypes.string),
};

export default RouteWithLayout;
