import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import ContactsTile from './ContactsTile';

const ContactsTab = ({ client }) => {
	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<ContactsTile client={client} />
			</Grid>
		</TilesGridWrapper>
	);
};
ContactsTab.defaultProps = {};

ContactsTab.propTypes = {
	client: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		projects: PropTypes.arrayOf(
			PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
		),
	}).isRequired,
};

export default ContactsTab;
