import { useMemo } from 'react';
import { pick } from 'lodash';
import { utcToZonedTime } from 'date-fns-tz';
import { getTimeValue } from 'Campaigns/components/CampaignForm/utils/getTimeValue';
import defaultTimezone from 'Common/constants/defaultTimezone';

const fallbackValues = {
	reference: '',
	emissionId: '',
	tenor: '',
	investableFrom: null,
	investableFromHour: 10,
	investableUntil: null,
	investableUntilHour: 10,
	platform: {
		value: 1,
		label: 'Ecoligo',
	},
	emissionSpv: null,
	interestRate: '',
	bonusAmount: '',
	minInvestmentAmount: '100',
	maxInvestmentAmount: '',
	divisibleBy: '50',
	constantCheckbox: false,
	bulletCheckbox: false,
	annuityCheckbox: false,
	annuity: '',
	bullet: '',
	constant: '',
	tags: [],
};

const generateInvestableHour = date => (date ? getTimeValue(date) : null);

const useTrancheFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data?.tranche_details, [
				'interestRate',
				'bonusAmount',
				'minInvestmentAmount',
				'maxInvestmentAmount',
				'divisibleBy',
				'annuity',
				'bullet',
				'constant',
			]),
			...pick(data, ['reference', 'emissionId', 'tenor']),
			emissionId: data?.id,
			investableFrom: data?.investable_time_from
				? utcToZonedTime(new Date(data?.investable_time_from), defaultTimezone)
				: null,
			investableFromHour: generateInvestableHour(data?.investable_time_from),
			investableUntil: data?.investable_time_until
				? utcToZonedTime(new Date(data?.investable_time_until), defaultTimezone)
				: null,
			investableUntilHour: generateInvestableHour(data?.investable_time_until),
			platform: data?.tranche_details?.platform
				? {
						value: data?.tranche_details?.platform.id,
						label: data?.tranche_details?.platform.name,
				  }
				: {
						value: 1,
						label: 'Ecoligo',
				  },
			emissionSpv: data?.tranche_details?.emissionSpv
				? {
						value: data?.tranche_details?.emissionSpv.id,
						label: data?.tranche_details?.emissionSpv.name,
				  }
				: null,
			constantCheckbox: Boolean(data?.tranche_details?.constantEnabled),
			bulletCheckbox: Boolean(data?.tranche_details?.bulletEnabled),
			annuityCheckbox: Boolean(data?.tranche_details?.annuityEnabled),
			tags:
				data?.tranche_details?.tagRefs &&
				data?.tranche_details?.tagRefs.map(e => ({
					value: e.tagId,
					label: e.tagName,
				})),
		}),
		[data],
	);

export default useTrancheFormInitialValues;
