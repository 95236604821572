import adaptEpcContractRequestData from 'EpcContract/api/adapters/adaptEpcContractRequestData';
import adaptEpcOfferRequestData from 'EpcContract/api/adapters/adaptEpcOfferRequestData';
import adaptOmContractRequestData from 'OmContract/api/adapters/adaptOmContractRequestData';
import Big from 'Common/utils/customBig';
import projectTypes from 'Projects/constants/projectTypes';
import adaptClientContractRequestData from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/ClientContract/api/adapters/adaptClientContractRequestData';

const adaptProjectRequestData = data => ({
	...data,
	clientContracts: data.clientContracts
		? data.clientContracts.map(contract => adaptClientContractRequestData(contract))
		: data.clientContracts,
	epcContracts: data.epcContracts
		? data.epcContracts.map(contract => adaptEpcContractRequestData(contract))
		: data.epcContracts,
	omContracts: data.omContracts
		? data.omContracts.map(contract => adaptOmContractRequestData(contract))
		: data.omContracts,
	epcOffers: data.epcOffers ? data.epcOffers.map(offer => adaptEpcOfferRequestData(offer)) : data.epcOffers,
	irr: data.irr ? Number(Big(data.irr).div(100).toString()) : data.irr,
	solarUtilisationRate:
		data.solarUtilisationRate && Number(Big(data.solarUtilisationRate).div(100).toString()),
	contractPartyByType: data.contractPartyByType
		? data.contractPartyByType
		: data.contractPartyObjectByType
		? Object.fromEntries(Object.entries(data.contractPartyObjectByType).map(([key, val]) => [key, val.id]))
		: undefined,
	manufacturerByProductType: data.manufacturerByProductType
		? data.manufacturerByProductType
		: data.manufacturerObjectByProductType
		? Object.fromEntries(
				Object.entries(data.manufacturerObjectByProductType).map(([key, val]) => [key, val.name]),
		  )
		: undefined,
	predictedAnnualEnergySavings:
		data.predictedAnnualEnergySavings !== '' &&
		data.predictedAnnualEnergySavings !== null &&
		data.predictedAnnualEnergySavings !== undefined &&
		data?.projectType?.name !== projectTypes.PV
			? Number(data.predictedAnnualEnergySavings)
			: null,
});

export default adaptProjectRequestData;
