/* eslint-disable no-unused-vars */

import { chartTypes } from 'Impact/constants/options';
import { addDays, endOfMonth, differenceInDays, subMonths, getDaysInMonth } from 'date-fns';
import { ageGroups, filtersTimeValue, labelsFields, months } from 'Impact/constants/options';
import formatNumberWithoutFixing from 'Common/utils/formatNumberWithoutFixing';
import { pick, isEmpty } from 'lodash';

const stringToDate = dateString => {
	const [day, month, year] = dateString.split('/');
	return new Date(year, month - 1, day);
};

const dateToString = date => {
	return [date.getDate(), date.getMonth() + 1, date.getFullYear()].join('/');
};

const getMonthName = date => {
	return months[date.getMonth()];
};

const hasFields = (item, fields) => {
	let hasAllFields = true;
	for (let i = 0; i < fields.length; i++) {
		if (!item[fields[i]]) {
			hasAllFields = false;
		}
	}
	return hasAllFields;
};

const getDataByYear = (data, fields) => {
	let startYear = new Date(data[data.length - 1].createdDate).getFullYear();
	const byYear = [];
	let isInitial = true;
	for (let i = 0; i < data.length; i++) {
		const year = new Date(data[i].createdDate).getFullYear();
		if (hasFields(data[i], fields) && (year < startYear || isInitial)) {
			byYear.push(data[i]);
			startYear = year;
			if (isInitial) {
				isInitial = false;
			}
		}
	}
	return byYear.reverse();
};

const fillData = data => {
	let dataFilled = [...data];
	const valueIndexes = [];
	for (let j = 0; j < dataFilled.length; j++) {
		if (dataFilled[j]?.value || dataFilled[j]?.value === 0) {
			valueIndexes.push(j);
		}
	}
	for (let i = 0; i < dataFilled.length; i++) {
		for (let k = 0; k < valueIndexes.length; k++) {
			let index = valueIndexes[k];
			let indexLeft = index - i;
			let indexRight = index + i;
			if (indexLeft > -1 && !dataFilled[indexLeft].value && dataFilled[indexLeft].value !== 0) {
				dataFilled[indexLeft].value = dataFilled[index].value;
			}
			if (
				indexRight < dataFilled.length &&
				!dataFilled[indexRight].value &&
				dataFilled[indexRight].value !== 0
			) {
				dataFilled[indexRight].value = dataFilled[index].value;
			}
		}
	}

	return dataFilled;
};

const fillPeriod = (start, end, data, ticks) => {
	let currentDate = start;
	const interval = Math.floor(differenceInDays(end, start) / ticks) || 1;
	const result = [];
	while (currentDate < end) {
		let findElement = data.filter(item => {
			return dateToString(item.date) === dateToString(currentDate);
		})[0];

		let value = findElement?.value;
		if (!value) {
			let closestDateElement = data.reduce((prev, curr) => {
				const a = Math.abs(curr.date.getTime() - currentDate.getTime());
				const b = Math.abs(prev.date.getTime() - currentDate.getTime());
				return a - b < 0 ? curr : prev;
			});
			value = closestDateElement.value;
		}

		result.push({ date: currentDate, value: value });
		currentDate = addDays(currentDate, interval);
	}

	return result;
};

const applyFieldFilters = (data, field) => {
	const picked = data?.map(item => pick(item, ['createdDate', field]));
	const mapped = picked?.map(item => {
		const fieldValue = item[field] ? item[field] : null;
		const keyvalue = { date: new Date(item.createdDate), value: fieldValue };
		return keyvalue;
	});

	return fillData(mapped);
};

const applyCountryFilters = (data, field, countryName) => {
	const mapped = data?.map(item => {
		const countryValues = item?.countries.filter(country => country.countryName === countryName)[0];
		let fieldValue =
			countryValues && (countryValues[field] || countryValues[field] === 0) ? countryValues[field] : null;
		const keyvalue = { date: new Date(item.createdDate), value: fieldValue };
		return keyvalue;
	});

	return fillData(mapped);
};

const getFilteredMultilineLabels = (data, fieldFilter, timeFilter, countryFilter) => {
	let dataFilled = countryFilter
		? applyCountryFilters(data, fieldFilter, countryFilter)
		: applyFieldFilters(data, fieldFilter);

	const isMonth = timeFilter === '1M';
	const result =
		timeFilter === 'Max'
			? getTicksYears(dataFilled, 48)
			: getTicksMonths(dataFilled, isMonth ? getDaysInMonth(new Date()) : 4, timeFilter);

	return {
		labels: result.map(item =>
			timeFilter === 'Max'
				? item.date.getFullYear()
				: isMonth
				? dateToString(item.date)
				: getMonthName(item.date),
		),
		values: result.map(item => dateToString(item.date)),
		dataset: result.map(item => item.value),
	};
};

const getTicksYears = (data, ticks) => {
	let result = [];
	const startYear = 2017;
	const endYear = new Date().getFullYear();

	for (let periodIndex = startYear; periodIndex <= endYear; periodIndex++) {
		const filled = fillPeriod(
			stringToDate(`1/1/${periodIndex}`),
			periodIndex === endYear ? new Date() : stringToDate(`31/12/${periodIndex}`),
			data,
			ticks,
		);
		result = result.concat(filled);
	}
	return result;
};

const getTicksMonths = (data, ticks, timeFilter) => {
	let result = [];
	let flag = false;
	const endDate = new Date();
	let yearIndex = endDate.getFullYear();

	for (let periodIndex = 0; periodIndex < filtersTimeValue[timeFilter]; periodIndex++) {
		let monthDelta = endDate.getMonth() - periodIndex;
		if (monthDelta < 0 && !flag) {
			yearIndex--;
			flag = true;
		}
		if (timeFilter === 'YTD') {
			monthDelta++;
		}
		const monthIndex = monthDelta < 0 ? 12 - Math.abs(monthDelta) : monthDelta;
		const filled = fillPeriod(
			timeFilter === '1M' ? subMonths(new Date(), 1) : stringToDate(`1/${monthIndex}/${yearIndex}`),
			periodIndex === 0 ? new Date() : endOfMonth(stringToDate(`1/${monthIndex}/${yearIndex}`)),
			data,
			ticks,
		);
		result = result.concat(filled.reverse());
	}
	return result.reverse();
};

export const parseStatisticsData = (data, type, filters) => {
	if (type === chartTypes.LINE) {
		let dataFilled = applyFieldFilters(data, filters.fields[0]);
		const result = getTicksYears(dataFilled, 48);

		return {
			labels: result.map(item => item.date.getFullYear()),
			values: result.map(item => dateToString(item.date)),
			datasets: [result.map(item => item.value)],
		};
	}

	if (type === chartTypes.STACKED_LINE) {
		let totals = {
			labels: [],
			datasets: [],
			values: [],
		};

		let totalsDatasets = [];

		for (let i = 0; i < filters?.fields?.length; i++) {
			const { labels, values, dataset } = getFilteredMultilineLabels(data, filters.fields[i], filters.time);
			totalsDatasets = totalsDatasets.concat([dataset]);
			totals = { labels, values };
		}

		for (let j = 0; j < filters?.country?.length; j++) {
			const { dataset } = getFilteredMultilineLabels(data, filters.fields[0], filters.time, filters.country[j]);
			totalsDatasets = totalsDatasets.concat([dataset]);
		}

		totals.datasets = totalsDatasets;

		if (filters.country?.length > 0) {
			totals.activeLabels = ['Total'];
			totals.activeLabels = totals.activeLabels.concat(filters.country);
		} else if (filters.fields?.length > 0) {
			totals.activeLabels = filters.fields.map(field => labelsFields[field]);
		}

		return totals;
	}

	if (type === chartTypes.BAR) {
		const totals = !isEmpty(data) ? Object.values(data).reduce((total, value) => total + value) : 0;

		return {
			labels: Object.keys(data).map(item => (ageGroups[item] ? ageGroups[item] : item)),
			datasets: [Object.values(data).map(item => Math.round((item / totals) * 100))],
			values: Object.values(data).map(item => item),
		};
	}

	if (type === chartTypes.DOUGHNUT || type === chartTypes.PIE) {
		const total = data.datasets.reduce((total, value) => total + value);

		return {
			labels: data.labels,
			datasets: [data.datasets.map(item => Math.round((item / total) * 100))],
			values: data.datasets.map(item => item),
		};
	}
};

export const parseTableData = data => {
	const byYear = getDataByYear(data, ['createdDate']);
	const result = byYear.map((item, index, array) => {
		return {
			year: new Date(item.createdDate).getFullYear(),
			invested: formatNumberWithoutFixing(item.totalEurInvested),
			growth:
				index === 0
					? '-'
					: Math.round((array[index].totalEurInvested / array[index - 1].totalEurInvested) * 100) + ' %',
		};
	});

	return result.reverse();
};

export const getAvailableCountries = data => {
	const countriesMap = {};
	for (let i = 0; i <= data.length; i++) {
		const countries = data[i]?.countries || [];
		for (let k = 0; k <= countries.length; k++) {
			const country = countries[k];
			const countryName = country?.countryName || '';
			if (countryName) {
				countriesMap[countryName] = 1;
			}
		}
	}
	return Object.keys(countriesMap).map(e => {
		return {
			label: e,
			value: e,
		};
	});
};
