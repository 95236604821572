import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { rgba } from 'polished';
import colors from 'Application/theme/colors';
import { css } from 'styled-components';

const Link = styled(RouterLink)`
	color: ${props => props.color || colors.text.primary};
	font-size: ${props => props.fontSize || '16px'};
	text-decoration-color: ${props => (props.color ? rgba(`${props.color}`, 0.7) : rgba('#363737', 0.7))};

	${({ disabled }) =>
		disabled &&
		css`
			cursor: not-allowed;
			color: ${colors.text.disabled};
			text-decoration: none;
		`};
`;

export default Link;
