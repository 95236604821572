import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Radio as MaterialRadio, RadioGroup as MaterialRadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Label from 'Common/components/form/Label';
import ErrorMessage from 'Common/components/form/ErrorMessage';
import colors from 'Application/theme/colors';
import { useLabelColor } from './hooks/useLabelColor';
import { useState } from 'react';
import sizes from 'Application/theme/sizes';

const StyledRadio = styled(MaterialRadio)`
	&.MuiButtonBase-root {
		color: ${colors.primary.light};
		width: 24px;
		height: 24px;
		padding: 0;
		margin-right: 8px;

		&:hover {
			.MuiSvgIcon-root:nth-child(1) {
				background: ${colors.primary.main};
				color: ${colors.primary.main};
			}
		}
		&.Mui-checked {
			color: transparent;

			&:hover {
				background-color: initial;
				color: transparent;
			}
		}

		&.Mui-focusVisible {
			color: ${colors.primary.dark};
		}
		.MuiSvgIcon-root {
			width: 24px;
			height: 24px;
		}
		.MuiSvgIcon-root:nth-child(1) {
			background: ${colors.primary.light};
			border-radius: 25px;
		}
		.MuiSvgIcon-root:nth-child(2) {
			color: ${colors.primary.main};
		}

		&.Mui-disabled {
			* {
				color: ${colors.grey.light} !important;
				.MuiSvgIcon-root:nth-child(1) {
					background: ${colors.grey.light} !important ;
				}
				.MuiSvgIcon-root:nth-child(2) {
					fill: ${colors.grey.main}!important;
				}
			}
		}
	}
`;

const StyledRadioGroup = styled(MaterialRadioGroup)`
	gap: ${sizes.spacing(1)};

	.MuiFormControlLabel-label {
		color: ${colors.common.black};
		font-weight: 400;
		${sizes.fontSize.main};
		font-family: inherit !important;
	}

	.MuiFormControlLabel-root {
		margin-right: 16px;
		margin-left: 0px;

		&:hover {
			.MuiButtonBase-root {
				background-color: initial;
				//color: ${colors.primary.main};
			}
		}
	}
`;

const StyledFormControlLabel = styled(FormControlLabel)`
	.MuiFormControlLabel-label {
		color: ${colors.common.black};
	}

	&:hover {
		.MuiFormControlLabel-label {
			color: ${colors.primary.dark};
		}
	}

	&:focus {
		outline: none;
		.MuiButtonBase-root {
			background-color: initial;
			color: ${colors.primary.main};
		}
	}

	.Mui-disabled {
		color: ${colors.grey.main}!important;
	}
`;

const FormikRadio = ({
	options,
	error,
	label,
	isRequired,
	tooltip,
	name,
	isRow,
	touched,
	isInEditMode,
	isDisabled,
	isTile,
	isHighlighted,
	...props
}) => {
	const hasError = !!error && touched;
	const hasLabel = !!label;

	const listenForEnter = e => {
		if (e.key === 'Enter') {
			e.preventDefault();
			e.target.click();
		}
	};

	const [hasHover, setHasHover] = useState(false);

	const labelColor = useLabelColor({ isHighlighted, isDisabled, hasError, isTile, isInEditMode, hasHover });

	const generateTabIndex = option => (props.value === option ? '-1' : '0');

	const handleMouseEnter = () => {
		setHasHover(true);
	};

	const handleMouseLeave = () => {
		setHasHover(false);
	};

	return (
		<FormControl component="fieldset" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			{hasLabel && (
				<Label
					label={label}
					color={labelColor}
					isRequired={isRequired}
					hasError={hasError}
					htmlFor={props.id}
					tooltip={tooltip}
					isInEditMode={isInEditMode}
				/>
			)}
			<StyledRadioGroup row={isRow} {...props} name={name}>
				{options.map(option => (
					<StyledFormControlLabel
						key={option.value}
						value={option.value}
						tabIndex={generateTabIndex(option.value)}
						onKeyDown={listenForEnter}
						control={<StyledRadio tabIndex="-1" key={option.value} disableRipple />}
						label={option.label}
						disabled={isDisabled || option.disabled}
					/>
				))}
			</StyledRadioGroup>
			{hasError && <ErrorMessage message={error} />}
		</FormControl>
	);
};

FormikRadio.defaultProps = {
	options: [],
	error: '',
	label: '',
	isRequired: false,
	tooltip: '',
	name: '',
	isRow: false,
	touched: false,
	isInEditMode: false,
	isDisabled: false,
	isTile: false,
	isHighlighted: false,
};

FormikRadio.propTypes = {
	options: PropTypes.arrayOf(PropTypes.shape({})),
	error: PropTypes.string,
	label: PropTypes.string,
	isRequired: PropTypes.bool,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
	name: PropTypes.string,
	isRow: PropTypes.bool,
	touched: PropTypes.bool,
	isInEditMode: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isTile: PropTypes.bool,
	isHighlighted: PropTypes.bool,
	value: PropTypes.string.isRequired,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default FormikRadio;
