import api from 'Application/api/api';

const updateProjectInsurance = async (signal, values, insuranceId) => {
	const response = await api.put(`/insurances/update/${insuranceId}`, values, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
	};
};

export default updateProjectInsurance;
