/* eslint-disable complexity */
import ReactDOM from 'react-dom';
import { useEffect, useState, useRef, useMemo } from 'react';
import ReactSelect, { components } from 'react-select';
import ReactSelectAsync from 'react-select/async';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { css } from 'styled-components';

import colors from 'Application/theme/colors';

import SmallLoader from 'Common/components/SmallLoader';
import Label from 'Common/components/form/Label';
import ErrorMessage from 'Common/components/form/ErrorMessage';
import useReactSelectCustomStyle from 'Common/components/form/hooks/useReactSelectCustomStyle';
import UsersPreview from 'Common/components/usersPreview/UsersPreview';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import { useLabelColor } from './hooks/useLabelColor';
import showToastError from 'Common/utils/showToastError';
import useSelectPlaceholder from 'Common/components/form/hooks/useSelectPlaceholder';
import useSelectDisplayValue from 'Common/components/form/hooks/useSelectDisplayValue';
import useAbortController from 'Common/hooks/useAbortController';
import useResponsive from 'Common/hooks/useResponsive';
import sizes from 'Application/theme/sizes';
import Icon from '../icons/Icon';
import FormikInput from './FormikInput';

const Wrapper = styled.div`
	position: relative;
	max-width: 100%;
`;

const SelectWrapper = styled.div`
	display: flex;
	align-items: center;

	.react-select__value-container {
		gap: ${sizes.spacing(0.25)};
	}
`;

const ReactSelectWrapper = styled.div`
	width: ${props => (props.$hasAvatar ? '92%' : '100%')};
`;

// ? Add width 8% ?
const AvatarContainer = styled.div`
	width: 30px;
	height: 30px;
	margin-right: 8px;
`;

const Tag = styled.div`
	display: inline-block;
	align-items: center;
	margin: 2px 5px 2px 0;
	padding: 3px 10px;
	color: ${colors.text.white};
	background: ${colors.primary.dark};
	border-radius: 25px;

	span {
		overflow: hidden;
		padding: 3px;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: 400;
		${sizes.fontSize.small};
		line-height: 24px;
	}
`;

const TagsWrapper = styled.div`
	width: 100%;
`;

const CreateLink = styled.div`
	padding: 10px 20px;
	border-bottom: 1px solid ${colors.grey.main};
	font-weight: 700;
	font-size: 15px;
	width: 100%;
	text-align: left;
	cursor: pointer;
`;

const DropdownIndicator = props => (
	<components.DropdownIndicator {...props}>
		<SmallLoader />
	</components.DropdownIndicator>
);

const MenuList = props => (
	<components.MenuList {...props}>
		<CreateLink
			onTouchEnd={() => {
				props.selectProps.onCreateClick();
			}}
			onClick={() => {
				props.selectProps.onCreateClick();
			}}
		>
			{props.selectProps.createLinkTitle}
		</CreateLink>
		{props.children}
	</components.MenuList>
);

const OverlayElement = styled.div`
	position: fixed;
	display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1002;

	${({ $isMobile }) =>
		$isMobile &&
		css`
			background-color: rgba(0, 0, 0, 0.5);
		`};
`;

const Popup = styled.div`
	${({ $isOpen }) =>
		$isOpen
			? css`
					opacity: 1;
			  `
			: css`
					opacity: 0;
					visibility: hidden;
					pointer-events: none;
			  `};
`;

const MultiValueContainer = styled.div`
	display: flex;
	align-items: center;
	margin-right: ${sizes.base(0.5)};
	padding: ${sizes.spacing(0.25)} ${sizes.spacing(1)};
	border-radius: 25px;
	color: ${colors.common.white};
	background: ${colors.primary.dark};
`;

const MultiValueLabel = styled.div`
	padding: 3px 3px 3px 6px;
	color: ${colors.common.white};
	font-weight: 400;
	${sizes.fontSize.main};
	line-height: ${sizes.lineHeight.small};
	cursor: 'default';
`;

const MultiValueRemove = styled(Icon).attrs({
	icon: 'close',
})`
	display: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'block')};
	padding: ${sizes.spacing(0.15)};
	width: ${sizes.base(5.5)};
	height: ${sizes.base(5.5)};
	border-radius: 11px;
	cursor: pointer;

	:hover {
		background-color: ${colors.grey.main};
		color: ${colors.primary.dark};
	}
`;

const MenuItemWithSubtitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const MenuItemSubtitle = styled.span`
	font-weight: 400;
`;

const MultiValueComponent = ({ data, isDisabled, removeProps: { onClick } }) => {
	const handleClick = e => {
		e.preventDefault();
		e.stopPropagation();
		typeof onClick === 'function' && onClick();
	};
	return (
		<MultiValueContainer>
			<MultiValueLabel>{data.label}</MultiValueLabel>
			<MultiValueRemove $isDisabled={isDisabled} onTouchEnd={handleClick} onMouseDown={handleClick} />
		</MultiValueContainer>
	);
};

MultiValueComponent.defaultProps = {
	data: {},
	removeProps: {},
	isDisabled: false,
};

MultiValueComponent.propTypes = {
	data: PropTypes.shape({
		label: PropTypes.string,
	}),
	removeProps: PropTypes.shape({
		onClick: PropTypes.func,
	}),
	isDisabled: PropTypes.bool,
};

const SingleValueComponent = ({ children, data, ...props }) => {
	return <components.SingleValue {...props}>{data.subtitle ? data.label : children}</components.SingleValue>;
};

SingleValueComponent.propTypes = {
	data: PropTypes.shape({
		label: PropTypes.string,
		subtitle: PropTypes.string,
	}).isRequired,
	children: PropTypes.node.isRequired,
};

const FormikSelect = ({
	// Base props
	id,
	name,
	label,
	error,
	value,
	touched,
	isMulti,
	tooltip,
	isRequired,
	isClearable,
	// isClearable: propIsClearable,
	customStyles,
	placeholder: propPlaceholder,
	multiPlaceholder,
	renderErrorMessage,
	menuPlacement,

	// State props
	isTile,
	isOverlay,
	isLoading,
	isDisabled,
	isInEditMode,
	isHighlighted,
	inputPrefix,

	// Handlers
	onBlur,
	onClick,
	onFocus,
	onPaste,
	onSearch,
	onChange,
	onMouseLeave,
	onMouseEnter,
	setFieldValue,
	onCreateClick,
	setFieldTouched,

	//Normal Select props
	options,

	// Async Select props
	isAsync,
	loadOptions,

	// Additional elements
	userInfo,
	createLinkTitle,
	formatOptionLabel,
	components: propComponents,
}) => {
	const { isMobile } = useResponsive();
	const initialStyles = useReactSelectCustomStyle(isDisabled);
	const [curriedCustomStyle, setCurriedCustomStyle] = useState(initialStyles);
	const maxLength = 26;

	const hasError = !!error && ((typeof touched === 'boolean' && touched) || typeof touched === 'object');
	const [hasHover, setHasHover] = useState(false);
	const [hasFocus, setHasFocus] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const selectRef = useRef();
	const popupRef = useRef();

	const labelColor = useLabelColor({
		isDisabled,
		hasError,
		isHighlighted,
		isTile,
		isInEditMode,
		hasHover,
		hasFocus,
	});

	const displayValue = useSelectDisplayValue(value, isMulti);
	const abortController = useAbortController();

	const isReadMode = useMemo(() => !isInEditMode && isTile, [isInEditMode, isTile]);
	const hasLabel = Boolean(label);

	// ? remove comment after release
	// const isClearable = useMemo(() => !isRequired && propIsClearable, [isRequired, propIsClearable]);

	const placeholder = useSelectPlaceholder(isMulti, isTile, isInEditMode, propPlaceholder, multiPlaceholder);

	// Async Select
	// ! If any loading problems occur check this function
	// ! debounce has unknown dependencies
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedLoadOptions = useCallback(
		debounce(async (inputValue, resolve) => {
			try {
				const loadedOptions = await loadOptions(abortController.signal, inputValue);
				resolve(loadedOptions);
			} catch (e) {
				showToastError(e);
			}
		}, 350),
		[],
	);

	const promiseOptions = inputValue =>
		new Promise(resolve => {
			debouncedLoadOptions(inputValue, resolve);
		});

	const handleOnClick = (...args) => {
		requestAnimationFrame(() => {
			selectRef?.current?.focus();
		});
		typeof onClick === 'function' && onClick(...args);
	};

	const handleChange = option => {
		setHasHover(false);
		setIsOpen(false);
		if (typeof setFieldValue === 'function') {
			setFieldValue(name, option);
		}
		if (typeof onChange === 'function') {
			onChange(option);
		}
	};

	const handleBlur = (...args) => {
		setHasFocus(false);
		if (setFieldTouched) {
			setFieldTouched(name, true);
		}

		typeof onBlur === 'function' && onBlur(...args);
	};

	const handleFocus = (...args) => {
		setHasFocus(true);
		typeof onFocus === 'function' && onFocus(...args);
	};

	const handleMouseEnter = (...args) => {
		setHasHover(true);
		typeof onMouseEnter === 'function' && onMouseEnter(...args);
	};

	const handleMouseLeave = (...args) => {
		setHasHover(false);
		typeof onMouseLeave === 'function' && onMouseLeave(...args);
	};

	const components = useMemo(() => {
		const composedComponents = {
			...propComponents,
			LoadingIndicator: SmallLoader,
			MultiValue: MultiValueComponent,
			SingleValue: SingleValueComponent,
		};

		if (isLoading) {
			composedComponents.DropdownIndicator = DropdownIndicator;
		}

		if (createLinkTitle) {
			composedComponents.MenuList = MenuList;
		}

		return composedComponents;
	}, [isLoading, createLinkTitle, propComponents]);

	const SelectElement = isAsync ? ReactSelectAsync : ReactSelect;
	const specificProps = isAsync
		? {
				displayValue,
				defaultOptions: true,
				loadOptions: promiseOptions,
		  }
		: {
				...(onSearch ? { onInputChange: onSearch } : {}),
				displayValue,
				options: options,
		  };

	useEffect(() => {
		if (!isDisabled) {
			setCurriedCustomStyle(prevStyles => ({
				...prevStyles,
				control: (provided, state) => ({
					...prevStyles.control(provided, state),
					borderColor: state.isFocused
						? colors.primary.dark + ' !important'
						: hasError
						? colors.error.main + ' !important'
						: isTile || isOverlay
						? 'transparent !important'
						: colors.grey.main + ' !important',
				}),
				clearIndicator: (provided, state) => ({
					...prevStyles.clearIndicator(provided, state),
					color:
						hasHover && !state.isFocused ? colors.grey.main : state.isFocused ? colors.primary.main : 'transparent',
					':hover': {
						color: colors.grey.dark,
					},
				}),
				indicatorSeparator: (provided, state) => ({
					...prevStyles.indicatorSeparator(provided, state),
					backgroundColor:
						hasHover && !state.isFocused ? colors.grey.main : state.isFocused ? colors.primary.main : 'transparent',
				}),
				dropdownIndicator: (provided, state) => ({
					...prevStyles.dropdownIndicator(provided, state),
					':hover': {
						color: colors.grey.main,
					},
					color:
						hasHover && !state.isFocused ? colors.grey.main : state.isFocused ? colors.primary.main : 'transparent',
				}),
				placeholder: provided => ({
					...provided,
					color: colors.grey.main,
				}),
			}));
		}
	}, [hasHover, hasError, isDisabled, isOverlay, isTile]);

	useEffect(() => {
		if (renderErrorMessage && !isDisabled) {
			setCurriedCustomStyle(prevStyles => ({
				...prevStyles,
				control: (provided, state) => ({
					...prevStyles.control(provided, state),
				}),
			}));
		}
	}, [hasError, renderErrorMessage, isDisabled]);

	useEffect(() => {
		setCurriedCustomStyle(prevStyles => ({
			...prevStyles,
			control: (provided, state) => ({
				...prevStyles.control(provided, state),
				fontSize: sizes.fontSize.main,
			}),
			multiValueLabel: (provided, state) => ({
				...prevStyles.multiValueLabel(provided, state),
				fontSize: sizes.fontSize.main,
			}),
			option: (provided, state) => ({
				...prevStyles.option(provided, state),
				fontSize: sizes.fontSize.main,
			}),
		}));

		if (isMobile) {
			setCurriedCustomStyle(prevStyles => ({
				...prevStyles,
				menuPortal: (provided, state) => ({
					...prevStyles.menuPortal(provided, state),
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, calc(-50% - 110px))',
				}),
				option: (provided, state) => ({
					...prevStyles.option(provided, state),
					background: 'none',
				}),
			}));
		} else {
			setCurriedCustomStyle(prevStyles => ({
				...prevStyles,
				menuPortal: (provided, state) => ({
					...prevStyles.menuPortal(provided, state),
					left: provided.left,
					top: provided.top,
					transform: provided.transform,
				}),
			}));
		}
	}, [isMobile]);

	const onResize = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const openDropdown = () => {
		if (isDisabled) return;
		setIsOpen(true);
	};

	useEffect(() => {
		window.addEventListener('resize', onResize);

		return () => window.removeEventListener('resize', onResize);
	}, [onResize]);

	useEffect(() => {
		setCurriedCustomStyle(prevStyles => ({
			...prevStyles,
			...customStyles,
		}));
	}, [customStyles]);

	const selectElement = (
		<ReactSelectWrapper onMouseDown={openDropdown} onTouchEnd={openDropdown} $hasAvatar={userInfo}>
			<SelectElement
				classNamePrefix="react-select"
				inputId={id}
				value={value}
				ref={selectRef}
				isMulti={isMulti}
				onBlur={handleBlur}
				onFocus={handleFocus}
				components={components}
				onChange={handleChange}
				styles={curriedCustomStyle}
				onClick={handleOnClick}
				onCreateClick={onCreateClick}
				createLinkTitle={createLinkTitle}
				isDisabled={isDisabled}
				{...specificProps}
				placeholder={placeholder}
				formatOptionLabel={
					formatOptionLabel
						? formatOptionLabel
						: option =>
								option.subtitle ? (
									<MenuItemWithSubtitleWrapper>
										<span>{option.label}</span>
										<MenuItemSubtitle>{option.subtitle}</MenuItemSubtitle>
									</MenuItemWithSubtitleWrapper>
								) : (
									option.label
								)
				}
				isClearable={isClearable}
				onPaste={onPaste}
				menuPlacement={menuPlacement}
				menuPortalTarget={popupRef.current}
				menuPosition={'absolute'}
				menuIsOpen={isOpen}
			/>
		</ReactSelectWrapper>
	);

	return (
		<Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} data-select>
			{hasLabel && (
				<Label
					label={label}
					htmlFor={id}
					isRequired={isTile ? isInEditMode && isRequired : isRequired}
					color={labelColor}
					tooltip={tooltip}
				/>
			)}

			<SelectWrapper>
				{userInfo && (
					<AvatarContainer>
						<UsersPreview
							popupProps={{
								$alignment: 'left',
							}}
							users={{
								user: userInfo,
							}}
						/>
					</AvatarContainer>
				)}
				{isReadMode || isDisabled ? (
					value?.length > 0 ? (
						<TagsWrapper>
							{value?.map(tag => (
								<Tag key={tag.value}>
									<span>{tag.label.length > maxLength ? tag.label.substring(0, maxLength) + '...' : tag.label}</span>
								</Tag>
							))}
						</TagsWrapper>
					) : (
						<FormikInput
							id={isDisabled ? '' : id}
							isTile
							isInEditMode={isInEditMode}
							value={displayValue}
							prefix={inputPrefix}
							onClick={handleOnClick}
							isDisabled={isDisabled}
							placeholder={placeholder}
						/>
					)
				) : (
					selectElement
				)}
			</SelectWrapper>

			{hasError && <ErrorMessage message={error} />}
			{ReactDOM.createPortal(
				<>
					<OverlayElement $isMobile={isMobile} $isOpen={isOpen} onClick={() => setIsOpen(false)} />
					<Popup ref={popupRef} $isOpen={isOpen} onClick={() => setIsOpen(false)} />
				</>,
				document.body,
			)}
		</Wrapper>
	);
};

MenuList.defaultProps = {
	children: null,
	selectProps: {},
};

MenuList.propTypes = {
	children: PropTypes.node.isRequired,
	selectProps: PropTypes.shape({
		onCreateClick: PropTypes.func.isRequired,
		createLinkTitle: PropTypes.string.isRequired,
	}).isRequired,
};

FormikSelect.defaultProps = {
	// Base props
	id: '',
	name: '',
	label: '',
	error: '',
	value: '',
	touched: false,
	isMulti: false,
	tooltip: '',
	isRequired: false,
	placeholder: '',
	customStyles: {},
	multiPlaceholder: '',
	renderErrorMessage: false,
	isClearable: false,
	menuPlacement: 'auto',

	// State props
	isTile: false,
	isOverlay: false,
	isLoading: false,
	isDisabled: false,
	isInEditMode: false,
	isHighlighted: false,

	// Handlers
	onBlur: () => {},
	onClick: () => {},
	onFocus: () => {},
	onPaste: () => {},
	onChange: () => {},
	onSearch: undefined,
	onMouseLeave: () => {},
	onMouseEnter: () => {},
	setFieldValue: () => {},
	onCreateClick: () => {},
	setFieldTouched: () => {},

	//Normal Select props
	options: [],

	// Async Select props
	isAsync: false,
	loadOptions: () => {},

	// Additional elements
	userInfo: undefined,
	inputPrefix: () => null,
	createLinkTitle: '',
	// ! Do not set empty function as default value
	formatOptionLabel: undefined,
	components: {},
};

FormikSelect.propTypes = {
	// Base props
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
	touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({}), PropTypes.array]),
	isMulti: PropTypes.bool,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
	isRequired: PropTypes.bool,
	placeholder: PropTypes.string,
	customStyles: PropTypes.shape({}),
	multiPlaceholder: PropTypes.string,
	renderErrorMessage: PropTypes.bool,
	isClearable: PropTypes.bool,
	menuPlacement: PropTypes.string,

	// State props
	isTile: PropTypes.bool,
	isOverlay: PropTypes.bool,
	isLoading: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isInEditMode: PropTypes.bool,
	isHighlighted: PropTypes.bool,

	// Handlers
	onBlur: PropTypes.func,
	onClick: PropTypes.func,
	onFocus: PropTypes.func,
	onPaste: PropTypes.func,
	onChange: PropTypes.func,
	onSearch: PropTypes.func,
	onMouseLeave: PropTypes.func,
	onMouseEnter: PropTypes.func,
	setFieldValue: PropTypes.func,
	onCreateClick: PropTypes.func,
	setFieldTouched: PropTypes.func,

	//Normal Select props
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.string,
		}),
	),

	// Async Select props
	isAsync: PropTypes.bool,
	loadOptions: PropTypes.func,

	// Additional elements
	userInfo: PropTypes.shape({}),
	inputPrefix: PropTypes.func,
	createLinkTitle: PropTypes.string,
	formatOptionLabel: PropTypes.func,
	components: PropTypes.shape({}),
};

export default FormikSelect;
