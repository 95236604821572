import api from 'Application/api/api';

const unassignAccountingStandards = async (signal, { contractPartyId }) => {
	const response = await api.post(`/contract-parties/unassign-accounting-standard/${contractPartyId}`, {
		signal,
		timeout: 100000,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: response.data,
	};
};

export default unassignAccountingStandards;
