import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

import updateEpcPartner from 'EpcPartner/api/updateEpcPartner';
import EpcPartnerNameWithTier from 'EpcPartner/components/EpcPartnerNameWithTier';
import useEpcPartnerDetailsFormInitialValues from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/EpcPartnerDetailsTile/hooks/useEpcPartnerDetailsFormInitialValues';
import useEpcPartnerDetailsFormValidationSchema from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/EpcPartnerDetailsTile/hooks/useEpcPartnerDetailsFormValidationSchema';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';
import axios from 'axios';

const EpcPartnerDetailsTile = ({
	epcPartner,
	onEnterEditMode,
	onExitEditMode,
	onSave,
	onDirtyForm,
	highlightLabels,
}) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.SALES,
		roles.PM_TECHNICAL,
		roles.ASSET_MANAGER,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useEpcPartnerDetailsFormInitialValues(epcPartner);
	const validationSchema = useEpcPartnerDetailsFormValidationSchema();

	const { errors, touched, values, handleSubmit, dirty, handleChange, handleBlur, resetForm } = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			setIsSavingChanges(true);

			try {
				const response = await updateEpcPartner(abortController.signal, {
					...omit(epcPartner, ['tier']),
					...values,
					inviteToPortal: values.portalAccess,
				});

				if (onDirtyForm) {
					onDirtyForm(false);
				}

				onSave(response.data);
				handleCancel();
				setIsSavingChanges(false);

				// Re-set all fields and mark them as not touched
				resetForm({ values });
			} catch (error) {
				showToastError(error, t("Can't update EPC partner details"));
				if (!axios.isCancel(error)) {
					setIsSavingChanges(false);
				}
			}
		},
	});

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('EPC partner details')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			{isInEditMode ? (
				<>
					<FormField>
						<FormikInput
							id="name"
							name="name"
							label={t('Name')}
							value={values.name}
							error={errors.name}
							touched={touched.name}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isTile
							isInEditMode={isInEditMode}
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.EPC_PARTNER_DETAILS.EPC_PARTNER_NAME,
							)}
						/>
					</FormField>

					<FormField>
						<FormikInput
							id="website"
							name="website"
							label={t('Website')}
							value={values.website}
							error={errors.website}
							touched={touched.website}
							onChange={handleChange}
							onBlur={handleBlur}
							isTile
							isInEditMode={isInEditMode}
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.EPC_PARTNER_DETAILS.EPC_PARTNER_WEBSITE,
							)}
						/>
					</FormField>
				</>
			) : (
				<>
					<KeyValueVisualization
						title={t('Name')}
						value={<EpcPartnerNameWithTier name={epcPartner.name} tier={epcPartner.tier} hasPoints={false} />}
						hasHoverEffect
						isHighlighted={highlightLabels.includes(
							PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.EPC_PARTNER_DETAILS.EPC_PARTNER_NAME,
						)}
					/>
					<KeyValueVisualization
						title={t('Website')}
						value={
							<a
								href={/https?:\/\//.test(epcPartner?.website) ? values.website : `http://${values.website}`}
								target="_blank"
								rel="noreferrer noreferrer"
							>
								{values.website}
							</a>
						}
						hasHoverEffect
						isHighlighted={highlightLabels.includes(
							PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.EPC_PARTNER_DETAILS.EPC_PARTNER_WEBSITE,
						)}
					/>
				</>
			)}
		</EditableTile>
	);
};

EpcPartnerDetailsTile.defaultProps = {
	epcPartner: {},
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	highlightLabels: [],
};

EpcPartnerDetailsTile.propTypes = {
	epcPartner: PropTypes.shape({
		name: PropTypes.string,
		tier: PropTypes.string,
		website: PropTypes.string,
	}),
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onSave: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default EpcPartnerDetailsTile;
