import { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import EditableTile from 'Common/components/Tile/EditableTile';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { DefaultTable, TableHeadCell, TableBodyCell } from 'Common/components/table/table';
import BankAccountDeleteModal from 'BankAccount/components/Modals/BankAccountDeleteModal';
import BankAccountCreateModal from 'BankAccount/components/Modals/BankAccountCreateModal';
import BankAccountEditModal from 'BankAccount/components/Modals/BankAccountEditModal';
import showToastError from 'Common/utils/showToastError';
import getEntityById from 'Entities/api/getEntityById';
import useAbortController from 'Common/hooks/useAbortController';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}
	${TableBodyCell} {
		background: transparent;
	}
`;

const BankAccountList = ({ entity }) => {
	const { t } = useTranslation();

	const [bankAccounts, setBankAccounts] = useState(entity?.bankAccounts);
	const [isLoadingContent, setIsLoadingContent] = useState(false);
	const abortController = useAbortController();

	const [bankAccountIdForEditing, setbankAccountIdForEditing] = useState(null);

	const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

	const [bankAccountForDeletion, setbankAccountForDeletion] = useState(null);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Name'),
				accessor: 'name',
			},
			{
				Header: t('Branch name'),
				accessor: 'branchName',
			},
			{
				Header: t('Branch code'),
				accessor: 'branchCode',
			},
			{
				Header: t('Account number'),
				accessor: 'accountNumber',
			},
			{
				Header: t('Bank SWIFT'),
				accessor: 'bankSwift',
			},
			{
				Header: t('Currency'),
				accessor: 'currency',
			},
			{
				Header: '',
				accessor: 'actionDots',
				maxWidth: 70,
				customCellStyles: {
					justifyContent: 'flex-end',
				},
				customBodyCellContentStyles: {
					padding: '5px',
				},
			},
		],
		[t],
	);

	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			bankAccounts.map(bankAccount => ({
				...bankAccount,
				name: bankAccount?.name || '-',
				actionDots: (
					<MoreActionsButton label="Bank accounts More Actions" controlledVisibility>
						<MenuItem
							data-action="edit"
							onClick={() => setbankAccountIdForEditing(bankAccount.id)}
							label="Bank accounts - Edit Menu Item"
						>
							Edit
						</MenuItem>
						<MenuItem
							data-action="delete"
							onClick={() => setbankAccountForDeletion({ id: bankAccount.id, name: bankAccount.name })}
							label="Bank accounts - Delete Menu Item"
						>
							Delete
						</MenuItem>
					</MoreActionsButton>
				),
			})),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t, bankAccounts],
	);
	const fetchBankAccounts = useCallback(async () => {
		try {
			setIsLoadingContent(true);
			const response = await getEntityById(abortController.signal, entity?.id, true);
			setIsLoadingContent(false);

			setBankAccounts(response.data?.bankAccounts);
		} catch (error) {
			showToastError(error);
		}
	}, [abortController.signal, entity]);

	useEffect(() => {
		fetchBankAccounts();
	}, [fetchBankAccounts]);

	const handleDataUpdate = () => {
		fetchBankAccounts();
	};

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
			},
			useCustomCellStyles,
		);

	return (
		<EditableTile
			title={t('Bank Accounts')}
			rightComponent={
				<TileActionButton data-button="bankAccounts" onClick={() => setIsCreateModalOpened(true)} />
			}
			isTable
			isLoadingContent={isLoadingContent}
		>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
				/>
			</TableWrapper>

			<BankAccountCreateModal
				entityId={entity?.id}
				isOpen={isCreateModalOpened}
				onClose={() => setIsCreateModalOpened(false)}
				onFormSubmit={handleDataUpdate}
			/>

			{bankAccountIdForEditing !== null && (
				<BankAccountEditModal
					isOpen
					onClose={() => setbankAccountIdForEditing(null)}
					onFormSubmit={handleDataUpdate}
					bankAccountId={bankAccountIdForEditing}
				/>
			)}

			{bankAccountForDeletion && (
				<BankAccountDeleteModal
					isOpen
					onFormSubmit={handleDataUpdate}
					bankAccountForDeletion={bankAccountForDeletion}
					onCancel={() => setbankAccountForDeletion(null)}
				/>
			)}
		</EditableTile>
	);
};

BankAccountList.defaultProps = {
	entity: null,
};

BankAccountList.propTypes = {
	entity: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		bankAccounts: PropTypes.arrayOf(
			PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
		),
	}),
};

export default BankAccountList;
