import { useMemo } from 'react';

const fallbackValues = {
	name: '',
	displayName: '',
	maximumVolume: '',
	minimumVolume: '',
	systemSize: 0,
	externalId: '',
	financeDetailsProjects: [],
};

const useCampaignFormInitialValues = () =>
	useMemo(
		() => ({
			...fallbackValues,
		}),
		[],
	);

export default useCampaignFormInitialValues;
