import { useMemo } from 'react';
import { pick } from 'lodash';
import { roleOptions } from 'User/constants/roles';
import { titleOptions } from 'UserManagement/constants/titles';

const fallbackValues = {
	firstName: '',
	lastName: '',
	email: '',
	title: null,
	phone: '',
	active: true,
	roles: [],
	regionalHub: [],
	profilePhoto: null,
};

const useUserFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['firstName', 'lastName', 'email', 'phone', 'active']),
			title: data?.title ? titleOptions?.find(option => option.value === data.title) : null,
			roles: data?.roles ? data?.roles.map(role => roleOptions.find(option => option.value === role.name)) : [],
			regionalHub: data?.regionalHub
				? {
						value: data?.regionalHub.id,
						label: data?.regionalHub.name,
				  }
				: [],
			profilePhoto: data?.profilePhoto ? data?.profilePhoto : null,
		}),
		[data],
	);

export default useUserFormInitialValues;
