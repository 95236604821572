import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { pick } from 'lodash';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import getUsersWithFullName from 'UserManagement/api/getUsersWithFullName';

import editProject from 'Projects/api/editProject';
import getProjectById from 'Projects/api/getProjectById';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';
import axios from 'axios';

const rolesMap = {
	assetManager: 'ROLE_ASSET_MANAGER',
	marketing: 'ROLE_ASSET_MARKETING',
	fundraising: 'ROLE_PM_FUNDRAISING',
	tech: 'ROLE_PM_TECHNICAL',
	sales: 'ROLE_SALES',
	finance: 'ROLE_FINANCE',
};

const ResponsiblePeopleTile = ({
	project,
	onEnterEditMode,
	onExitEditMode,
	onDirtyForm,
	onTileSave,
	heightFitConent,
	highlightLabels,
}) => {
	const { t } = useTranslation();
	const responsiblePersons = project?.responsiblePeopleObjectByRole;
	const initialValues = {
		tech: responsiblePersons?.Tech
			? {
					...responsiblePersons?.Tech,
					value: responsiblePersons?.Tech?.email,
					label: responsiblePersons?.Tech?.fullName,
					imgUrl: responsiblePersons?.Tech?.profilePhoto?.url,
			  }
			: null,
		sales: responsiblePersons?.Sales
			? {
					...responsiblePersons?.Sales,
					value: responsiblePersons?.Sales?.email,
					label: responsiblePersons?.Sales?.fullName,
					imgUrl: responsiblePersons?.Sales?.profilePhoto?.url,
			  }
			: null,
		fundraising: responsiblePersons?.Fundraising
			? {
					...responsiblePersons?.Fundraising,
					value: responsiblePersons?.Fundraising?.email,
					label: responsiblePersons?.Fundraising?.fullName,
					imgUrl: responsiblePersons?.Fundraising?.profilePhoto?.url,
			  }
			: null,
		marketing: responsiblePersons?.Marketing
			? {
					...responsiblePersons?.Marketing,
					value: responsiblePersons?.Marketing?.email,
					label: responsiblePersons?.Marketing?.fullName,
					imgUrl: responsiblePersons?.Marketing?.profilePhoto?.url,
			  }
			: null,
		finance: responsiblePersons?.Finance
			? {
					...responsiblePersons?.Finance,
					value: responsiblePersons?.Finance?.email,
					label: responsiblePersons?.Finance?.fullName,
					imgUrl: responsiblePersons?.Finance?.profilePhoto?.url,
			  }
			: null,
		assetManager: responsiblePersons?.['Asset Management']
			? {
					...responsiblePersons?.['Asset Management'],
					value: responsiblePersons?.['Asset Management']?.email,
					label: responsiblePersons?.['Asset Management']?.fullName,
					imgUrl: responsiblePersons?.['Asset Management']?.profilePhoto?.url,
			  }
			: null,
	};

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.SALES,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.ASSET_MANAGER,
		roles.PM_FUNDRAISING,
		roles.PM_TECHNICAL,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [isLoadingUsers, setIsLoadingUsers] = useState(false);
	const [responsiblePersonOptions, setResponsiblePersonOptions] = useState({});

	const abortController = useAbortController();

	const validationSchema = useMemo(
		() =>
			yup.object({
				sales: yup.object().nullable().required(t('Sales person is required')),
			}),
		[t],
	);

	const { errors, touched, values, handleBlur, handleSubmit, dirty, setFieldValue, resetForm } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				setIsSavingChanges(true);
				const responsiblePeopleByRole = {};
				Object.entries({
					...pick(values, ['tech', 'sales', 'fundraising', 'marketing', 'assetManager', 'finance']),
				})
					.filter(item => item[1]?.value)
					.forEach(([role, responsiblePerson]) => {
						responsiblePeopleByRole[rolesMap[role]] = responsiblePerson?.value;
					});

				await editProject(abortController.signal, {
					...project,
					systemSizeKwp: project.systemSizeKwp ? project.systemSizeKwp : '',
					responsiblePeopleByRole,
				});

				const response = await getProjectById(abortController.signal, project.id, true);

				if (onDirtyForm) {
					onDirtyForm(false);
				}

				await onTileSave(response.data);
				handleCancel();
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});

	useEffect(() => {
		(async () => {
			try {
				setIsLoadingUsers(true);

				let sales = await getUsersWithFullName(abortController.signal, {
					active: true,
					roles: [roles.SALES, roles.HEAD_OF_SALES],
				});
				sales = sales.data.map(user => ({
					...user,
					value: user.email,
					label: user.fullName,
				}));

				let fundraising = await getUsersWithFullName(abortController.signal, {
					active: true,
					roles: [roles.PM_FUNDRAISING, roles.HEAD_OF_FUNDRAISING],
				});
				fundraising = fundraising.data.map(user => ({
					...user,
					value: user.email,
					label: user.fullName,
				}));

				let marketing = await getUsersWithFullName(abortController.signal, {
					active: true,
					roles: [roles.ASSET_MARKETING, roles.HEAD_OF_MARKETING],
				});
				marketing = marketing.data.map(user => ({
					...user,
					value: user.email,
					label: user.fullName,
				}));

				let assetManager = await getUsersWithFullName(abortController.signal, {
					active: true,
					roles: [roles.ASSET_MANAGER, roles.HEAD_OF_ASSET_MANAGEMENT],
				});
				assetManager = assetManager.data.map(user => ({
					...user,
					value: user.email,
					label: user.fullName,
				}));

				let tech = await getUsersWithFullName(abortController.signal, {
					active: true,
					roles: [roles.PM_TECHNICAL, roles.HEAD_OF_TECH],
				});
				tech = tech.data.map(user => ({
					...user,
					value: user.email,
					label: user.fullName,
				}));

				let finance = await getUsersWithFullName(abortController.signal, {
					active: true,
					roles: [roles.FINANCE, roles.HEAD_OF_FINANCE],
				});
				finance = finance.data.map(user => ({
					...user,
					value: user.email,
					label: user.fullName,
				}));

				setResponsiblePersonOptions({
					sales,
					fundraising,
					marketing,
					assetManager,
					tech,
					finance,
				});
				setIsLoadingUsers(false);
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [abortController.signal]);

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Responsible people')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
			showRightComponent={values.onHold && !isInEditMode}
			heightFitConent={heightFitConent}
		>
			<FormField>
				<FormikSelect
					id="sales"
					name="sales"
					label={t('Sales')}
					value={values.sales}
					error={errors.sales}
					touched={touched.sales}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					options={responsiblePersonOptions.sales}
					disabled={isLoadingUsers}
					isLoading={isLoadingUsers}
					isInEditMode={isInEditMode}
					userInfo={values.sales}
					isRequired
					isTile
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_RESPONSIBLE_PEOPLE.SALES,
					)}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					id="tech"
					name="tech"
					label={t('Tech')}
					value={values.tech}
					error={errors.tech}
					touched={touched.tech}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					options={responsiblePersonOptions.tech}
					disabled={isLoadingUsers}
					isLoading={isLoadingUsers}
					isInEditMode={isInEditMode}
					userInfo={values.tech}
					isClearable
					isTile
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_RESPONSIBLE_PEOPLE.TECH,
					)}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					id="fundraising"
					name="fundraising"
					label={t('Fundraising')}
					value={values.fundraising}
					error={errors.fundraising}
					touched={touched.fundraising}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					options={responsiblePersonOptions.fundraising}
					disabled={isLoadingUsers}
					isLoading={isLoadingUsers}
					isInEditMode={isInEditMode}
					userInfo={values.fundraising}
					isClearable
					isTile
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_RESPONSIBLE_PEOPLE.FUNDRAISING,
					)}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					id="marketing"
					name="marketing"
					label={t('Marketing')}
					value={values.marketing}
					error={errors.marketing}
					touched={touched.marketing}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					options={responsiblePersonOptions.marketing}
					disabled={isLoadingUsers}
					isLoading={isLoadingUsers}
					isInEditMode={isInEditMode}
					userInfo={values.marketing}
					isClearable
					isTile
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_RESPONSIBLE_PEOPLE.MARKETING,
					)}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					id="assetManager"
					name="assetManager"
					label={t('Asset Management')}
					value={values.assetManager}
					error={errors.assetManager}
					touched={touched.assetManager}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					options={responsiblePersonOptions.assetManager}
					disabled={isLoadingUsers}
					isLoading={isLoadingUsers}
					isInEditMode={isInEditMode}
					userInfo={values.assetManager}
					isClearable
					isTile
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_RESPONSIBLE_PEOPLE.ASSET_MANAGER,
					)}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					id="finance"
					name="finance"
					label={t('Finance')}
					value={values.finance}
					error={errors.finance}
					touched={touched.finance}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					options={responsiblePersonOptions.finance}
					disabled={isLoadingUsers}
					isLoading={isLoadingUsers}
					isInEditMode={isInEditMode}
					userInfo={values.finance}
					isClearable
					isTile
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_RESPONSIBLE_PEOPLE.FINANCE,
					)}
				/>
			</FormField>
		</EditableTile>
	);
};

ResponsiblePeopleTile.defaultProps = {
	onDirtyForm: undefined,
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onTileSave: async () => {},
	heightFitConent: undefined,
	project: {},
	highlightLabels: [],
};

ResponsiblePeopleTile.propTypes = {
	onDirtyForm: PropTypes.func,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onTileSave: PropTypes.func,
	heightFitConent: PropTypes.bool,
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		systemSizeKwp: PropTypes.number,
		responsiblePeopleObjectByRole: PropTypes.shape({}),
	}),
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default ResponsiblePeopleTile;
