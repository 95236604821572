import { useCallback, useEffect, useMemo, useState } from 'react';

import crudModes from 'Common/constants/crudModes';

import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import OverlaySections from 'Common/components/modals/OverlaySections';
import getProjectDeveloperById from 'ProjectDeveloper/api/getProjectDeveloperById';
import useProjectDeveloperOverlayValidationSchema from './hooks/useProjectDeveloperOverlayValidationSchema';
import useProjectDeveloperOverlaySections from './hooks/useProjectDeveloperOverlaySections';
import useProjectDeveloperOverlayValues from './hooks/useProjectDeveloperOverlayValues';
import updateProjectDeveloper from 'ProjectDeveloper/api/updateProjectDeveloper';
import convertProjectDeveloperValuesToRequestData from './utils/convertFormValuesToRequestData';
import createProjectDeveloper from 'ProjectDeveloper/api/createProjectDeveloper';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useOverlayTitles } from 'Common/components/modals/Overlay';

const ProjectDeveloperOverlay = ({
	mode,
	isOpen,
	onClose,
	onFormSubmit,
	projectDeveloperId,
	breadcrumbList,
}) => {
	const [projectDeveloper, setProjectDeveloper] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();
	const { label, saveBtnLabel, closeBtnLabel } = useOverlayTitles(t('Project Developer'), mode);

	const abortController = useAbortController();

	const isEditMode = useMemo(() => mode === crudModes.EDIT, [mode]);

	useEffect(() => {
		(async () => {
			if (!isEditMode) return;

			setIsLoading(true);
			try {
				const response = await getProjectDeveloperById(abortController.signal, projectDeveloperId, true);

				setProjectDeveloper(response.data);
				setIsLoading(false);
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		})();
	}, [abortController.signal, isEditMode, projectDeveloperId]);

	const { defaultValues, sectionFields } = useProjectDeveloperOverlayValues(projectDeveloper);
	const sections = useProjectDeveloperOverlaySections();
	const validationSchema = useProjectDeveloperOverlayValidationSchema();

	const handleCreateSubmit = useCallback(
		async values => {
			try {
				const response = await createProjectDeveloper(
					abortController.signal,
					convertProjectDeveloperValuesToRequestData(values),
				);

				await onFormSubmit(response.data);

				onClose();

				notify(t('Project developer created successfully.'), {
					type: toast.TYPE.SUCCESS,
				});
			} catch (error) {
				showToastError(error, "Couldn't create Project Developer");

				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
		[abortController.signal, onClose, onFormSubmit, t],
	);

	const handleEditSubmit = useCallback(
		async values => {
			try {
				const response = await updateProjectDeveloper(abortController.signal, {
					...projectDeveloper,
					...convertProjectDeveloperValuesToRequestData(values),
				});

				await onFormSubmit(response.data);

				onClose();

				notify(t('Project developer saved successfully'), {
					type: toast.TYPE.SUCCESS,
				});
			} catch (error) {
				showToastError(error, 'Project Developer could not be saved');
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
		[abortController.signal, onClose, onFormSubmit, projectDeveloper, t],
	);

	return (
		<OverlaySections
			label={label}
			breadcrumbList={breadcrumbList}
			isOpen={isOpen}
			onClose={onClose}
			onSaveOverlay={mode === crudModes.CREATE ? handleCreateSubmit : handleEditSubmit}
			isLoadingContent={isLoading}
			sections={sections}
			defaultValues={defaultValues}
			sectionFields={sectionFields}
			validationSchema={validationSchema}
			saveBtnLabel={saveBtnLabel}
			closeBtnLabel={closeBtnLabel}
		/>
	);
};

ProjectDeveloperOverlay.defaultProps = {
	mode: crudModes.CREATE,
	isOpen: false,
	projectDeveloperId: null,
	onClose: () => {},
	onFormSubmit: () => {},
	breadcrumbList: [],
};

ProjectDeveloperOverlay.propTypes = {
	mode: PropTypes.oneOf([crudModes.CREATE, crudModes.EDIT]),
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	breadcrumbList: PropTypes.arrayOf(PropTypes.shape({})),
	onFormSubmit: PropTypes.func,
	projectDeveloperId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ProjectDeveloperOverlay;
