import getMarketSegments from 'MarketSegment/api/getMarketSegments';

const loadMarketSegmentOptions = async (signal, query) => {
	const response = await getMarketSegments(signal, query);
	const marketSegments = response.data.content;

	return marketSegments.map(marketSegment => ({
		value: marketSegment.id,
		label: marketSegment.name,
	}));
};

export default loadMarketSegmentOptions;
