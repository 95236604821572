import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

const StyledTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))`
	& .MuiTooltip-tooltip {
		background: ${colors.common.white};
		color: ${colors.text.black};
		border-radius: 6px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
		padding: 16px;
		font-size: 16px;
		font-weight: 400;
		white-space: break-spaces;
	}
`;

const HoverTooltip = ({ listItems, children }) => (
	<StyledTooltip title={listItems.join('\n')}>{children}</StyledTooltip>
);

HoverTooltip.defaultProps = {
	listItems: [],
	children: null,
};

HoverTooltip.propTypes = {
	children: PropTypes.node,
	listItems: PropTypes.arrayOf(PropTypes.shape({})),
};

export default HoverTooltip;
