import { useMemo } from 'react';
import parseISO from 'date-fns/parseISO';
import { unitSystemSizeOptions } from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/CommissioningTile/constants/unitSystemSizeOptions';

const fallbackValues = {
	commissioningAcceptanceDate: null,
	commercialOperationsDate: null,
	unitSystemSize: null,
};

const useCommissioningFormInitialValues = data => {
	const unitSystemSize = useMemo(() => {
		const result = unitSystemSizeOptions.filter(i => i.value === data.unitSystemSize);
		return data.unitSystemSize ? result : fallbackValues.unitSystemSize;
	}, [data?.unitSystemSize]);

	return useMemo(
		() => ({
			...fallbackValues,
			commissioningAcceptanceDate: data.commissioningAcceptanceDate
				? parseISO(data.commissioningAcceptanceDate)
				: null,
			commercialOperationsDate: data.commercialOperationsDate ? parseISO(data.commercialOperationsDate) : null,
			projectedCommercialOperationsDate: data.projectedCommercialOperationsDate
				? parseISO(data.projectedCommercialOperationsDate)
				: null,
			unitSystemSize: data.unitSystemSize ? unitSystemSize : fallbackValues.unitSystemSize,
		}),
		[data, unitSystemSize],
	);
};

export default useCommissioningFormInitialValues;
