import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import crudModes from 'Common/constants/crudModes';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import editBankAccount from 'BankAccount/api/editBankAccount';
import createBankAccount from 'BankAccount/api/createBankAccount';
import assignBankAccount from 'BankAccount/api/assignBankAccount';
import useBankAccountFormValidationSchema from 'BankAccount/components/Form/hooks/useBankAccountFormValidationSchema';
import useBankAccountFormInitialValues from 'BankAccount/components/Form/hooks/useBankAccountFormInitialValues';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import FormikSelect from 'Common/components/form/FormikSelect';
import { ModalFormButtons } from 'Common/components/form/ModalForm';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const FieldsWrapper = styled.div`
	width: 100%;
`;

const BankAccountForm = ({ data, entityId, mode, onSubmit, onCancel, onDirty }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();

	const initialValues = useBankAccountFormInitialValues(data);
	const validationSchema = useBankAccountFormValidationSchema();

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		setFieldTouched,
		setFieldValue,
		handleChange,
		handleBlur,
		setFieldError,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				if (mode === crudModes.CREATE) {
					const response = await createBankAccount(abortController.signal, {
						...values,
						currency: values.currency.value,
					});
					await assignBankAccount(abortController.signal, {
						bankAccountsIds: [response?.data?.id],
						contractPartyId: entityId,
					});

					onSubmit();

					notify(t('Bank Account created successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				} else if (mode === crudModes.EDIT) {
					await editBankAccount(abortController.signal, {
						...data,
						...values,
						currency: values.currency.value,
					});

					onSubmit();

					notify(t('Bank Account saved successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				}

				resetForm({ values });
				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form onSubmit={handleSubmit}>
			<FieldsWrapper>
				<FormField>
					<FormikInput
						id="name"
						name="name"
						label={t('Bank name')}
						value={values.name}
						error={errors.name}
						touched={touched.name}
						onChange={handleChange}
						setFieldTouched={setFieldTouched}
						onBlur={handleBlur}
						isRequired
					/>
				</FormField>
				<FormField>
					<FormikSelect
						isAsync
						id="currency"
						name="currency"
						label={t('Currency')}
						value={values.currency}
						error={errors.currency}
						touched={touched.currency}
						setFieldTouched={setFieldTouched}
						setFieldValue={setFieldValue}
						onBlur={handleBlur}
						loadOptions={loadCurrenciesOptions}
					/>
					<option>Currently: {initialValues.currency.value}</option>
				</FormField>
				<FormField>
					<FormikInput
						id="branchName"
						name="branchName"
						label={t('Branch name')}
						value={values.branchName}
						error={errors.branchName}
						touched={touched.branchName}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
				</FormField>
				<FormField>
					<FormikInput
						id="branchCode"
						name="branchCode"
						label={t('Branch Code')}
						value={values.branchCode}
						error={errors.branchCode}
						touched={touched.branchCode}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
				</FormField>
				<FormField>
					<FormikInput
						id="accountNumber"
						name="accountNumber"
						label={t('Account Number')}
						value={values.accountNumber}
						error={errors.accountNumber}
						touched={touched.accountNumber}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
				</FormField>
				<FormField>
					<FormikInput
						id="bankSwift"
						name="bankSwift"
						label={t('Bank SWIFT')}
						value={values.bankSwift}
						error={errors.bankSwift}
						touched={touched.bankSwift}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
				</FormField>
			</FieldsWrapper>
			<ModalFormButtons
				onCancel={onCancel}
				isLoading={isLoading}
				label="Bank Account Form"
				submitButtonText={mode === crudModes.CREATE ? t('Create') : t('Save')}
			/>
		</Form>
	);
};

BankAccountForm.defaultProps = {
	data: null,
	entityId: 0,
};

BankAccountForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	entityId: PropTypes.number,
	mode: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
};

export default BankAccountForm;
