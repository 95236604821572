import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useInsuranceFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				formDocumentName: yup.string().required('File is required'),
				insuredSum: yup
					.number()
					.typeError(t('Insured number must be a number'))
					.min(0, t('Insured number must be positive'))
					.required('Insured number is required')
					.test(
						'decimal-places',
						'Insured number must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				insuredCurrency: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.nullable()
					.test('required', t('Currency is Required'), value => Boolean(value.value) || value.value === 0),
				premium: yup
					.number()
					.typeError(t('Premium must be a number'))
					.min(0, t('Premium must be positive'))
					.required('Premium is required')
					.test(
						'decimal-places',
						'Premium must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				premiumPercentage: yup
					.number()
					.typeError(t('Premium percentage be a number'))
					.min(0, t('Premium percentage must be positive'))
					.required('Premium percentage is required')
					.test(
						'decimal-places',
						'Premium percentage must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				providerName: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.nullable()
					.test('required', t('Provider name is Required'), value => Boolean(value.value) || value.value === 0),
				policyCost: yup
					.number()
					.typeError(t('Policy cost must be a number'))
					.min(0, t('Policy cost must be positive'))
					.required('Policy cost is required')
					.test(
						'decimal-places',
						'Policy cost must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				policyCurrency: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.nullable()
					.test('required', t('Currency is Required'), value => Boolean(value.value) || value.value === 0),
				policyStartDate: yup
					.date()
					.typeError(t('Policy Start Date is required'))
					.required(t('Policy Start Date is required')),
				policyEndDate: yup
					.date()
					.typeError(t('Policy End Date is required'))
					.min(yup.ref('policyStartDate'), "Policy start date can't be before policy end date")
					.required(t('Policy End Date is required')),
				insuredEntity: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.nullable()
					.test('required', t('Insured entity is Required'), value => Boolean(value.value) || value.value === 0),
			}),
		[t],
	);
};

export default useInsuranceFormValidationSchema;
