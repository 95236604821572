import colors from 'Application/theme/colors';
import styled, { css } from 'styled-components/macro';

const SendButton = styled.div`
	${({ $disabled }) => css`
		position: absolute;
		right: 6px;
		top: calc(50% + 11px);
		transform: translateY(-50%);
		color: ${colors.text.grey};
		cursor: ${$disabled ? 'not-allowed' : 'pointer'};

		svg {
			width: 20px;
			color: ${$disabled ? colors.grey.main : colors.primary.dark};
		}
	`}
`;

export default SendButton;
