const openUrlInNewTab = url => {
	const link = document.createElement('a');
	link.setAttribute('target', '_blank');
	link.href = url;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export default openUrlInNewTab;
