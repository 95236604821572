import PropTypes from 'prop-types';
import crudModes from 'Common/constants/crudModes';
import ModalForm from 'Common/components/form/ModalForm';
import { useTranslation } from 'react-i18next';
import BankAccountForm from '../Form/BankAccountForm';

const BankAccountCreateModal = ({ isOpen, onClose, onFormSubmit, entityId }) => {
	const { t } = useTranslation();

	return (
		<ModalForm
			label="bank-account-create"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			heading={t('Create Bank Account')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<BankAccountForm
					entityId={entityId}
					mode={crudModes.CREATE}
					onCancel={onCancel}
					onSubmit={handleSubmit}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};
BankAccountCreateModal.defaultProps = {
	isOpen: false,
	onClose: () => {},
	onFormSubmit: () => {},
	entityId: 0,
};
BankAccountCreateModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onFormSubmit: PropTypes.func,
	entityId: PropTypes.number,
};

export default BankAccountCreateModal;
