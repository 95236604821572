import { useMemo } from 'react';
import Big from 'Common/utils/customBig';

const optionsCount = 3;

const initialValuesForOption = {
	contractType: '',
	duration: '',
	currency: null,
};

const fallbackValues = {
	clientName: '',
	marketSegment: null,
	country: null,
	projectType: null,
	systemSizeKwp: '',
	kwhToKwpRel: '',
	expectedGridInjection: '',
	gridEnergyTariffPerKwh: '',
	epcVolumeExclVat: '',
	epcVolumeExclVatKwp: '',
	annualOmPrice: '',
	annualOmPriceKwp: '',
	annualOmEscalationRate: '',
	tariffCurrency: null,
	offerCurrency: null,
	epcOfferCurrency: null,
	gensets: false,
	energyStorage: false,
	onGrid: false,
	options: [...Array(optionsCount).keys()].map(() => initialValuesForOption),
	description: '',
};

const useEpcInquiryFormInitialValues = (data, isCreate) =>
	useMemo(
		() => ({
			...fallbackValues,
			...data,
			description: data.description,
			onGrid: isCreate ? 'on-grid' : data.onGrid ? 'on-grid' : 'off-grid',
			industry: data?.industry
				? {
						value: data.industry.id,
						label: data.industry.name,
						marketSegment: data.industry.marketSegment,
				  }
				: null,
			country: data?.country
				? {
						value: data.country.id,
						label: data.country.name,
				  }
				: null,
			projectType: data?.projectType
				? {
						value: data.projectType.id,
						label: data.projectType.fullName
							? `${data.projectType.fullName} (${data.projectType.name})`
							: data.projectType.name,
				  }
				: {
						label: 'Solar photovoltaic (PV)',
						value: 8518,
				  },
			tariffCurrency: data?.gridEnergyTariffPerKwh
				? {
						value: data.gridEnergyTariffPerKwh.currency,
						label: data.gridEnergyTariffPerKwh.currency,
				  }
				: null,
			offerCurrency:
				data?.clientOffers && data?.clientOffers.length > 0
					? {
							value: data?.clientOffers?.[0]?.currency,
							label: data?.clientOffers?.[0]?.currency,
					  }
					: null,
			epcOfferCurrency: data?.currency
				? {
						value: data.currency,
						label: data.currency,
				  }
				: null,
			options:
				data?.clientOffers?.length > 0
					? data?.clientOffers.map(i => ({
							...i,
							contractType: i?.contractType
								? {
										value: i.contractType.id,
										label: i.contractType.name,
								  }
								: '',

							escalationRateInPercent:
								i?.escalationRateInPercent && Number(Big(i?.escalationRateInPercent).times(100).toString()),
							clientUpfrontPayment: i?.clientUpfrontPayment?.value ?? '',
					  }))
					: [...Array(optionsCount).keys()].map(() => initialValuesForOption),
			annualOmPrice: data?.annualOmPrice?.value ?? '',
			annualOmPriceKwp: data?.annualOmPriceKwp?.value ?? '',
			epcVolumeExclVat: data?.epcVolumeExclVat?.value ?? '',
			epcVolumeExclVatKwp: data?.epcVolumeExclVatKwp?.value ?? '',
			gridEnergyTariffPerKwh: data?.gridEnergyTariffPerKwh?.value ?? '',
			solarUtilisationRate: data?.solarUtilisationRate && Big(data?.solarUtilisationRate).times(100),
		}),
		[data, isCreate],
	);

export default useEpcInquiryFormInitialValues;
