import { useCallback } from 'react';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

import ProjectInfo from 'Projects/components/shared/ProjectInfo';
import SidePanelTiles from 'Common/components/SidePanel/components/SidePanelTiles';
import SidePanelHistory from 'Common/components/SidePanel/components/SidePanelHistory';
import SidePanelStepInfo from 'Common/components/SidePanel/components/SidePanelStepInfo';
import SidebarLineSeparator from 'Common/components/SidePanel/components/SidePanelLineSeparator';

import CommentList from 'Common/components/Comments/CommentList';
import { useSelector } from 'react-redux';
import useStepCommentsData from 'PMWorkflow/components/Step/hooks/useStepCommentsData';

const DashboardSidePanelStep = ({ project, showHistory, step, onDataChange, dropdownProps, ...rest }) => {
	const isSoftLoading = useSelector(state => state.sidePanel.isSoftLoading);

	const handleUpdateEntity = useCallback(
		newProjectData => {
			if (newProjectData) {
				onDataChange(newProjectData);
				return newProjectData;
			}
			return onDataChange();
		},
		[onDataChange],
	);

	const modifiedDropdownProps = { ...dropdownProps, projectId: project.id, isSoftLoading: isSoftLoading };

	const { defaultComments, onAddComment, onEditComment, onDeleteComment } = useStepCommentsData(project, step);

	return (
		<>
			<ProjectInfo
				border="none"
				padding="0px"
				margin="0px 0px 16px 0px"
				backgroundColor={colors.common.white}
				data={project}
				disableUserInfoHover
			/>
			<SidebarLineSeparator />
			<SidePanelStepInfo
				step={step}
				onStatusUpdate={handleUpdateEntity}
				dropdownProps={modifiedDropdownProps}
				{...rest}
			/>
			<SidebarLineSeparator />

			<SidePanelHistory step={step} showHistory={showHistory} />
			{showHistory && <SidebarLineSeparator />}

			{step?.projectTiles?.length > 0 && (
				<>
					<SidePanelTiles
						project={project}
						tiles={step.projectTiles}
						fields={step.projectFields}
						updateEntity={handleUpdateEntity}
					/>
					<SidebarLineSeparator />
				</>
			)}

			<CommentList
				defaultComments={defaultComments}
				onAddComment={onAddComment}
				onEditComment={onEditComment}
				onDeleteComment={onDeleteComment}
			/>
		</>
	);
};

DashboardSidePanelStep.defaultProps = {
	step: {},
	project: {},
	dropdownProps: {},
	showHistory: false,
	onDataChange: () => {},
};

DashboardSidePanelStep.propTypes = {
	step: PropTypes.shape({
		projectTiles: PropTypes.arrayOf(PropTypes.shape({})),
		projectFields: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	showHistory: PropTypes.bool.isRequired,
	onDataChange: PropTypes.func.isRequired,
	dropdownProps: PropTypes.shape({}),
};

export default DashboardSidePanelStep;
