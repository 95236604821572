import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import PropTypes from 'prop-types';
import ContactsList from './ContactsList/ContactsList';
import AssignToProjectModal from 'Contacts/components/AssignToProjectForm/AssignToProjectModal';
import ExpellContactModal from 'Contacts/components/ExpellContactModal/ExpellContactModal';
import DeleteContactModal from 'Contacts/components/DeleteContactModal/DeleteContactModal';
import useContactsData from 'Client/components/ClientDetails/Tabs/ContactsTab/hooks/useContactsData';

const ContactsTile = ({ client }) => {
	const dispatch = useDispatch();

	const {
		contacts,
		contactIdForAssigning,
		contactIdForExpelling,
		contactForDeletion,

		invoicingProjectsContacts,
		generalInvocingContactId,

		updateContactsData,
		handleUpdateContact,

		handleAssignProject,
		handleAssignInvoicingContact,
		handleAssignInvoicingContactToAllProjects,
		handleExpellContact,
		handleDeleteContact,

		closeExpellModal,
		closeAssignModal,
		closeDeleteModal,
	} = useContactsData(client);

	const handleUserAccountEdit = email => dispatch(push(`/users/edit/${email}`));

	const isAssignModalOpen = Boolean(contactIdForAssigning);
	const isExpellModalOpen = Boolean(contactIdForExpelling);
	const isDeleteModalOpen = Boolean(contactForDeletion?.id);

	return (
		<>
			<ContactsList
				contacts={contacts}
				clientId={client.id}
				generalInvocingContactId={generalInvocingContactId}
				invoicingProjectsContacts={invoicingProjectsContacts}
				onSubmit={handleUpdateContact}
				onAssignProject={handleAssignProject}
				onAssignInvoicingContact={handleAssignInvoicingContact}
				onAssignGeneralInvoicingContact={handleAssignInvoicingContactToAllProjects}
				onUserAccountEdit={handleUserAccountEdit}
				onExpellContact={handleExpellContact}
				onContactDeletion={handleDeleteContact}
			/>
			{isAssignModalOpen && (
				<AssignToProjectModal
					isOpen={isAssignModalOpen}
					onClose={closeAssignModal}
					contactId={String(contactIdForAssigning)}
					onFormSubmit={updateContactsData}
					clientId={String(client.id)}
				/>
			)}
			{isExpellModalOpen && (
				<ExpellContactModal
					isOpen={isExpellModalOpen}
					onClose={closeExpellModal}
					contactId={String(contactIdForExpelling)}
					onFormSubmit={updateContactsData}
				/>
			)}
			{isDeleteModalOpen && (
				<DeleteContactModal
					isOpen={isDeleteModalOpen}
					onClose={closeDeleteModal}
					onFormSubmit={updateContactsData}
					contact={contactForDeletion}
				/>
			)}
		</>
	);
};
ContactsTile.defaultProps = {};

ContactsTile.propTypes = {
	client: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		projects: PropTypes.arrayOf(
			PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
		),
	}).isRequired,
};

export default ContactsTile;
