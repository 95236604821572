import { useCallback, useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { push } from 'redux-first-history';
import { useTranslation } from 'react-i18next';
import { stringify } from 'qs';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PartnersListTable from 'Partners/components/PartnersList/PartnersListTable';
import ButtonLink from 'Common/components/buttons/ButtonLink';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import Filters from 'Common/components/filter/FiltersContainer';
import usePartnerListFilterData from 'Partners/components/PartnersList/hooks/usePartnerListFilterData';
import useSmartResize from 'Common/hooks/table/useSmartResize';
import PartnerCreateOverlay from 'Partners/components/PartnerForm/PartnerCreateOverlay';

const sortPropertiesMap = {
	country: 'country.name',
	cityRegion: 'address.cityRegion',
	primaryContactName: 'firstName',
};

const PartnersList = () => {
	const { t } = useTranslation();

	const storedTableState = useSelector(state => state.tables.partners);
	const [shouldTableRender, setShouldTableRender] = useState(false);
	const dispatch = useDispatch();

	const sortByQueryParam = useQueryParameter('sortBy');
	const filters = useQueryParameter('filters');

	const location = useLocation();

	useEffect(() => {
		if (!shouldTableRender) {
			if (location.search === '' && storedTableState && storedTableState.url) {
				dispatch(push(`/partners${storedTableState.url}`));
			}
			setShouldTableRender(true);
		}
	}, [dispatch, storedTableState, location.search, shouldTableRender]);

	const openExport = useCallback(
		exportWithOptions => {
			const sortById =
				sortByQueryParam && sortPropertiesMap[sortByQueryParam.id]
					? sortPropertiesMap[sortByQueryParam.id]
					: sortByQueryParam?.id;

			if (exportWithOptions) {
				window.open(
					`api/other-partners/export-table-view?${
						sortByQueryParam
							? 'sort=' + sortById + '%2C' + (sortByQueryParam.desc ? 'DESC' : 'ASC')
							: 'sort=name%2CASC'
					}&${stringify(filters)}&size=9999&`,

					'_blank',
				);
			} else {
				window.open(
					`api/other-partners/export-table-view?&size=9999&${
						sortByQueryParam
							? 'sort=' + sortById + '%2C' + (sortByQueryParam.desc ? 'DESC' : 'ASC')
							: 'sort=name%2CASC'
					}`,
					'_blank',
				);
			}
		},
		[sortByQueryParam, filters],
	);

	const filterButtonRef = useRef();

	const filtersData = usePartnerListFilterData();

	const filtersRef = useRef();

	const [isCreateOpen, setIsCreateOpen] = useState(false);

	const handleCreateClose = () => {
		setIsCreateOpen(false);
	};

	// eslint-disable-next-line
	const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
	useSmartResize(filtersRef, setForceUpdateCounter);

	return (
		<>
			<Helmet>
				<title>{t('Partners')}</title>
			</Helmet>
			<HeaderContainer>
				<HeaderTitle>{t('Partners')}</HeaderTitle>
				<HeaderActions>
					<div ref={filterButtonRef}></div>

					{isAuthorized([
						roles.ADMIN,
						roles.FINANCE,
						roles.MANAGEMENT,
						roles.SALES,
						roles.PM_TECHNICAL,
						roles.ASSET_MANAGER,
					]) && (
						<ButtonLink
							label="Create Partner - Partners"
							onClick={() => setIsCreateOpen(true)}
							text={t('Create partner')}
							icon="addRounded"
						/>
					)}
					<MoreActionsButton label=" artner More Actions">
						{filters && (
							<MenuItem
								onClick={() => openExport(true)}
								type="button"
								data-action="exportFiltered"
								label="Partner - Export filtered to CSV Menu Item"
							>
								{t('Export filtered to CSV')}
							</MenuItem>
						)}
						<MenuItem
							onClick={() => openExport(false)}
							type="button"
							data-action="exportAll"
							label="Partner - Export all to CSV Menu Item"
						>
							{t('Export all to CSV')}
						</MenuItem>
					</MoreActionsButton>
				</HeaderActions>
			</HeaderContainer>
			<div ref={filtersRef}>
				<Filters table="partners" buttonRef={filterButtonRef} filtersData={filtersData} />
			</div>

			{(shouldTableRender || !storedTableState?.url) && <PartnersListTable />}

			<PartnerCreateOverlay
				isOpen={isCreateOpen}
				onClose={handleCreateClose}
				onFormSubmit={data => dispatch(push(`/partners/details/${data.id}`))}
			/>
		</>
	);
};

export default PartnersList;
