import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import styled from 'styled-components';

const FilterPill = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: ${({ hasSelected, isOpened }) =>
		isOpened ? colors.common.blue : hasSelected ? colors.primary.dark : colors.primary.light};
	color: ${({ hasSelected, isOpened }) =>
		hasSelected || isOpened ? colors.common.white : colors.primary.dark};
	border-radius: ${sizes.base(8)};
	padding: ${sizes.spacing(0.5)} ${sizes.spacing(1.5)};
	cursor: pointer;
	font-weight: bold;
	${sizes.fontSize.small};
`;

export default FilterPill;
