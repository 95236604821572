import api from 'Application/api/api';

const updateLifetimeInBatch = async (signal, values) => {
	const response = await api.put('/project-types/batch/update-lifetime', values, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
	};
};

export default updateLifetimeInBatch;
