import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import { push } from 'redux-first-history';
import { useSortBy } from 'react-table';
import { debounce, fill } from 'lodash';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import 'rc-pagination/assets/index.css';

import getTranches from 'Campaigns/api/getTranches';
import { statusesLabels, statusColorMap, statusHelpTexts } from 'Campaigns/constants/trancheStatuses';
import { formatRepaymentScheme } from 'Campaigns/utils/campaignHelpers';

import colors from 'Application/theme/colors';
import Icon from 'Common/components/icons/Icon';

import { DefaultTable } from 'Common/components/table/table';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import { useSortingQueryParameter } from 'Common/hooks/table/plugin-hooks/useSortingQueryParameter';
import { useScrollableTableBody } from 'Common/hooks/table/plugin-hooks/useScrollableTableBody';
import { useVirtualizedTableBody } from 'Common/hooks/table/plugin-hooks/useVirtualizedTableBody';
import Link from 'Common/components/Link';
import formatDate from 'Common/utils/formatDate';
import formatNumber from 'Common/utils/formatNumber';
import StatusPill from 'Common/components/StatusPill';
import PillWrapper from 'Common/components/PillWrapper';
import formatCurrency from 'Common/utils/formatCurrency';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import defaultTimezone from 'Common/constants/defaultTimezone';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import showToastError from 'Common/utils/showToastError';
import useUniqueAbortSignal from 'Common/hooks/useUniqueAbortSignal';
import prefillTableData from 'Common/utils/prefillTableData';

const Wrapper = styled.div`
	position: relative;
	pointer-events: ${({ hasOpenFilter }) => (hasOpenFilter ? 'none' : 'all')};
`;

const defaultSortBy = {
	id: 'externalId',
	desc: true,
};

const sortPropertiesMap = {
	investable_time_from: 'investableFrom',
	investable_time_until: 'investableUntil',
	interestRate: 'trancheDetails.interestRate',
	name: 'trancheDetails.emissionSpv.name',
};

const TranchesListTable = () => {
	const { t } = useTranslation();
	const hasOpenFilter = !!useSelector(state => state.tables?.tranches?.openedFilter);

	const [totalItemsCount, setTotalItemsCount] = useState(25);

	const sortByQueryParam = useQueryParameter('sortBy');
	const sortBy = sortByQueryParam ?? defaultSortBy;
	const filters = useQueryParameter('filters');

	const searchQuery = useQueryParameter('searchQuery');

	const dispatch = useDispatch();

	const [tranches, setTranches] = useState(() => fill(Array(25), {}));
	const [isLoading, setIsLoading] = useState(true);

	const getUniqueSignal = useUniqueAbortSignal();

	const hasLoadedFirstResultsRef = useRef(false);

	const setTranchesBatch = useCallback(
		async (startIndex, stopIndex, filters, sortBy, searchQuery, hasItemsChanges = false) => {
			if (hasItemsChanges) {
				setIsLoading(true);
			}

			const sortById = sortPropertiesMap[sortBy.id] ?? defaultSortBy.id;

			const customFilters = { ...filters };

			if (customFilters.investableFrom) {
				customFilters.investableFrom = zonedTimeToUtc(
					format(new Date(customFilters.investableFrom), "yyyy-MM-dd'T'HH:mm.sss'Z'", {
						timeZone: defaultTimezone,
					}),
					defaultTimezone,
				);
			}
			if (customFilters.investableUntil) {
				customFilters.investableUntil = zonedTimeToUtc(
					format(new Date(customFilters.investableUntil), "yyyy-MM-dd'T'HH:mm.sss'Z'", {
						timeZone: defaultTimezone,
					}),
					defaultTimezone,
				);
			}

			try {
				let response = await getTranches(
					getUniqueSignal('get-tranches'),
					{
						offset: startIndex,
						limit: stopIndex - startIndex + 1,
						sortBy: sortById,
						sortDirection: sortBy.desc ? 'DESC' : 'ASC',
					},
					customFilters,
					searchQuery,
				);
				if (hasItemsChanges || !hasLoadedFirstResultsRef.current) {
					const { itemsCount, itemsFilled, error } = prefillTableData(response.data, startIndex, stopIndex);
					if (error) {
						showToastError(error);
					} else {
						setTranches(itemsFilled);
						setTotalItemsCount(itemsCount);
					}
				} else {
					setTranches(prevTranches =>
						prevTranches.map((prevTranche, index) => {
							if (startIndex <= index && index <= stopIndex) {
								const indexInResultSet = index - startIndex;

								return response.data.content[indexInResultSet];
							}

							return prevTranche;
						}),
					);
				}

				hasLoadedFirstResultsRef.current = true;

				if (hasItemsChanges) {
					setIsLoading(false);
				}
			} catch (error) {
				showToastError(error, t("Can't fetch tranches"));
			}
		},
		[getUniqueSignal, t],
	);

	useEffect(() => {
		setTranchesBatch(
			0,
			25,
			filters,
			{
				id: sortBy.id,
				desc: sortBy.desc,
			},
			searchQuery,
			true,
		);
	}, [setTranchesBatch, searchQuery, filters, sortBy.desc, sortBy.id]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleLoadMoreRows = useCallback(
		debounce(
			(startIndex, stopIndex) => {
				setTranchesBatch(
					startIndex,
					stopIndex,
					filters,
					{
						id: sortBy.id,
						desc: sortBy.desc,
					},
					searchQuery,
				);
			},
			350,
			{
				leading: false,
				trailing: true,
			},
		),
		[setTranchesBatch, searchQuery, filters, sortBy.desc, sortBy.id],
	);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Status'),
				accessor: 'status',
				minWidth: 205,
				maxWidth: 210,
				disableSortBy: true,
				highlightSearchTerm: true,
				customBodyCellStyles: {
					paddingLeft: '4px',
				},

				Cell: (() => {
					const Cell = ({ row: { original: tranch } }) =>
						tranch.status ? (
							<PillWrapper>
								<HoverTooltip placement="right" title={statusHelpTexts[tranch.status] || ''} arrow>
									<StatusPill color={statusColorMap[tranch.status]}>{statusesLabels[tranch.status]}</StatusPill>
								</HoverTooltip>
								{!tranch?.synched && (
									<HoverTooltip
										placement="right"
										title={t('Tranche differs to portagon. Check the tenor and investable dates.')}
										arrow
									>
										<Icon icon="reportProblem" color={colors.warning.main} />
									</HoverTooltip>
								)}
							</PillWrapper>
						) : (
							'-'
						);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ status: PropTypes.string, synched: PropTypes.bool }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Name'),
				accessor: 'reference',
				maxWidth: 104,
				highlightSearchTerm: true,
				Cell: ({ row: { original: tranch } }) => (tranch.reference ? tranch.reference : '-'),
			},
			{
				Header: t('Investments'),
				accessor: 'investments_sum',
				disableSortBy: true,
				maxWidth: 114,
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original: campaign } }) => (
						<div title={campaign?.investments_sum}>
							{campaign?.investments_sum ? formatCurrency(campaign?.investments_sum) : '-'}
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								investments_sum: PropTypes.number,
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Campaign'),
				accessor: 'finance_name',
				maxWidth: 254,
				highlightSearchTerm: true,
				customCellContentStyles: {
					paddingLeft: '4px',
				},
				customBodyCellContentStyles: {
					paddingRight: '8px',
				},
				Cell: (() => {
					const Cell = ({ row: { original: tranch } }) =>
						tranch.finance_name ? (
							<Link
								color="#00b4d5"
								fontSize="14px"
								onClick={e => {
									e.stopPropagation();
								}}
								to={`/campaigns/details/${tranch?.finance_id}`}
							>
								{tranch.finance_name}
							</Link>
						) : (
							'-'
						);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								finance_name: PropTypes.string,
								finance_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Investible from'),
				accessor: 'investable_time_from',
				disableSortBy: true,
				width: 130,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				Cell: ({ row: { original: tranch } }) =>
					tranch.investable_time_from ? formatDate(tranch.investable_time_from) : '-',
			},
			{
				Header: t('Investible to'),
				accessor: 'investable_time_until',
				disableSortBy: true,
				maxWidth: 114,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				Cell: ({ row: { original: tranch } }) =>
					tranch.investable_time_until ? formatDate(tranch.investable_time_until) : '-',
			},
			{
				Header: t('Last investment'),
				accessor: 'last_investment_date',
				disableSortBy: true,
				width: 140,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				Cell: ({ row: { original: tranch } }) =>
					tranch.last_investment_date ? formatDate(tranch.last_investment_date) : '-',
			},
			{
				Header: t('Tenor'),
				accessor: 'tenor',
				maxWidth: 64,
				highlightSearchTerm: true,
				Cell: ({ row: { original: tranch } }) => (tranch.tenor ? formatNumber(tranch.tenor, 1) : '-'),
			},
			{
				Header: t('Interest'),
				accessor: 'interestRate',
				width: 80,
				highlightSearchTerm: true,
				Cell: ({ row: { original: tranch } }) =>
					tranch.tranche_details?.interestRate ? `${tranch.tranche_details?.interestRate}%` : '-',
			},
			{
				Header: t('Repayment scheme'),
				accessor: 'repayment',
				maxWidth: 224,
				disableSortBy: true,
				highlightSearchTerm: true,
				Cell: ({ row: { original: tranch } }) =>
					tranch?.tranche_details?.annuity || tranch?.tranche_details?.bullet || tranch?.tranche_details?.constant
						? formatRepaymentScheme(
								tranch?.tranche_details?.annuity,
								tranch?.tranche_details?.bullet,
								tranch?.tranche_details?.constant,
						  )
						: '-',
			},
			{
				Header: t('ID'),
				accessor: 'externalId',
				maxWidth: 54,
				highlightSearchTerm: true,
				Cell: ({ row: { original: tranch } }) => (tranch.id ? tranch.id : '-'),
			},
		],
		[t],
	);

	const data = useMemo(() => tranches, [tranches]);

	const itemsHash = useMemo(() => JSON.stringify({ filters, sortBy }), [filters, sortBy]);

	const handleRowClick = useCallback(
		row => {
			if (!window.getSelection().toString()) {
				dispatch(push(`/campaigns/details/${row?.original.finance_id}`));
			}
		},
		[dispatch],
	);

	// Fallback to default row id getter
	const getRowId = useCallback(
		(row, relativeIndex, parent) => (parent ? [parent.id, relativeIndex].join('.') : relativeIndex),
		[],
	);

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		getVirtualizedTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useStandardTable(
		{
			data,
			columns,
			defaultSortBy,
			searchTerm: searchQuery,
			onRowClick: handleRowClick,
			virtualization: {
				totalRowsCount: totalItemsCount,
				rowsHash: itemsHash,
				loadMoreRows: handleLoadMoreRows,
			},
			getRowId,
		},
		useClickableTableRow,
		useCustomCellStyles,
		useSortBy,
		useSortingQueryParameter,
		useScrollableTableBody,
		useVirtualizedTableBody,
	);

	return (
		<Wrapper hasOpenFilter={hasOpenFilter}>
			<DefaultTable
				getTableProps={getTableProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				getVirtualizedTableBodyProps={getVirtualizedTableBodyProps}
				rows={rows}
				headerGroups={headerGroups}
				prepareRow={prepareRow}
				isLoading={isLoading || rows.length !== totalItemsCount}
				showNoResultsFound
			/>
		</Wrapper>
	);
};

export default TranchesListTable;
