import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'Common/components/icons/Icon';
import styled from 'styled-components/macro';
import Button from 'Common/components/buttons/Button';
import Thumbnail from 'Common/components/Thumbnail';
import getProjectImages from 'Projects/api/getProjectImages';
import uploadImages from 'Projects/api/uploadImages';
import deleteImages from 'Projects/api/deleteImages';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import ImageModal from 'Projects/components/ProjectDetails/Tabs/ImagesTab/ImageModal';
import Loader from 'Common/components/Loader';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import Prompt from 'Common/components/Prompt';
import isAuthorized from 'User/utils/isAuthorized';
import { roles } from 'User/constants/roles';
import colors from 'Application/theme/colors';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { FILE_TYPES } from 'Common/constants/fileTypes';
import useFileUpload from 'Common/hooks/useFileUpload';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const Toolbar = styled.div`
	display: flex;
	box-shadow: 0 2px 3px 2px #f7f9fa;
	padding: 10px 20px;

	> * {
		margin-right: 16px;

		&:last-child {
			margin-right: 0;
		}
	}
`;

const Gallery = styled.div`
	position: relative;
	padding: 20px;
	width: 100%;

	&:focus {
		outline: none;
	}
`;

const ThumbnailContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	${({ isDraggedOver }) =>
		isDraggedOver &&
		css`
			opacity: 0.5;
		`}
`;

const UploadFileOverlay = styled.div`
	position: absolute;
	height: 48vh;
	width: 98%;
	border: 2px dashed ${colors.text.black};
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 10px;
	top: 20px;
`;

const EmptyGalleryText = styled.div`
	position: absolute;
	height: 48vh;
	width: 98%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 10px;
	top: 20px;
	font-weight: 600;
	color: ${colors.text.black};
`;

const ImagesTab = ({ project }) => {
	const { t } = useTranslation();
	const [images, setImages] = useState([]);
	const [areImagesLoading, setAreImagesLoading] = useState(true);
	const [selectedImages, setSelectedImages] = useState([]);
	const [openedImageInModal, setOpenedImageInModal] = useState(null);
	const [isDeleting, setIsDeleting] = useState(false);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const isAuthorizedToCreate = isAuthorized([
		roles.ADMIN,
		roles.SALES,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.PM_TECHNICAL,
	]);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				setAreImagesLoading(true);

				const response = await getProjectImages(abortController.signal, project?.id);

				setImages(response.data);

				setAreImagesLoading(false);
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [abortController.signal, project?.id]);

	const handleImageSelection = image => {
		if (selectedImages.includes(image)) {
			setSelectedImages(prevImages => {
				const newImages = [...prevImages];

				const index = newImages.indexOf(image);

				if (index !== -1) {
					newImages.splice(index, 1);
				}

				return newImages;
			});
		} else {
			setSelectedImages(prevImages => [...prevImages, image]);
		}
	};

	const handleImageDeletion = async () => {
		setIsDeleting(true);

		try {
			await deleteImages(abortController.signal, selectedImages, project?.id);
			const response = await getProjectImages(abortController.signal, project?.id);
			setImages(response.data);
			setIsDeleting(false);
			setShowDeleteConfirmation(false);
			notify(t(`${selectedImages.length > 1 ? 'Images' : 'Image'} deleted successfully`), {
				type: toast.TYPE.SUCCESS,
			});

			setSelectedImages([]);
		} catch (error) {
			showToastError(error);
		}
	};

	const handleImageUpload = useCallback(
		async ({ formData, acceptedCount, allCount }) => {
			try {
				await uploadImages(abortController.signal, formData, project?.id);

				const response = await getProjectImages(abortController.signal, project?.id);

				notify(`Success! ${acceptedCount} of ${allCount} image(s) uploaded`, {
					type: acceptedCount === allCount ? toast.TYPE.SUCCESS : toast.TYPE.WARNING,
				});

				setImages(response.data);
			} catch (e) {
				showToastError(e, t("Couldn't upload images"));
			}
		},

		[project?.id, t, abortController.signal],
	);

	const {
		handleAddFile: handleAddImage,
		isUploading,
		generateRootProps,
		generateInputProps,
		isDragActive,
	} = useFileUpload({
		onFileUpload: handleImageUpload,
		fileName: 'Image',
		fileIdentifier: 'files',
		acceptedTypes: [FILE_TYPES.IMAGE],
		multiple: true,
	});

	const handleImageUpdate = updatedImage => {
		const updatedImageIndex = images.findIndex(image => image.id.name === updatedImage.id.name);

		let newArr = [...images];

		newArr.splice(updatedImageIndex, 1, updatedImage);

		setImages(newArr);
	};

	return (
		<Wrapper>
			<Prompt message="" when={isUploading} />

			<Toolbar>
				{selectedImages.length === 0 && (
					<Button
						text={t('Upload')}
						icon="cloudUploadRounded"
						onClick={handleAddImage}
						disabled={isUploading || !isAuthorizedToCreate}
						label="Project Details Images Tab - Upload Button"
					/>
				)}

				{selectedImages.length > 0 && (
					<Button
						secondary
						icon="delete"
						text={t('Delete')}
						onClick={() => setShowDeleteConfirmation(true)}
						disabled={!isAuthorizedToCreate}
						label="Project Details Images Tab - Delete Button"
					/>
				)}

				{selectedImages.length > 0 && (
					<Button
						overlay
						text={`${selectedImages.length} selected`}
						icon="clear"
						onClick={() => setSelectedImages([])}
						label="Project Details Images Tab - Clear Button"
					/>
				)}
			</Toolbar>

			<Gallery
				isDragActive={!isAuthorizedToCreate ? false : isDragActive}
				{...generateRootProps({
					className: 'dropzone',
					onDrop: event => !isAuthorizedToCreate && event.stopPropagation(),
				})}
				onClick={e => e.stopPropagation()}
			>
				{images.length === 0 && !areImagesLoading && !isUploading && !isDragActive && isAuthorizedToCreate && (
					<EmptyGalleryText>
						<span>{t('There are no images yet')}</span>

						<p>{t("Drag 'n' drop your images here or click Upload to add images")}</p>
					</EmptyGalleryText>
				)}

				{areImagesLoading && <Loader />}

				<ThumbnailContainer isDraggedOver={isDragActive}>
					<>
						{images.map(image => (
							<Thumbnail
								key={image.id.name}
								image={image}
								isSelected={selectedImages.includes(image.id.name)}
								setSelected={img => handleImageSelection(img)}
								forceShowIndicator={selectedImages.length > 0}
								onImageClick={setOpenedImageInModal}
							/>
						))}

						{(isUploading || isDeleting) && <Loader />}
					</>
				</ThumbnailContainer>

				<input {...generateInputProps()} disabled={!isAuthorizedToCreate} />

				{isDragActive && (
					<UploadFileOverlay>
						<Icon icon="cloudUploadRounded" color={colors.text.white} size="huge" className="bigIcon" />

						<p>{t('Drop your images here to upload')}</p>
					</UploadFileOverlay>
				)}
			</Gallery>

			{openedImageInModal && (
				<ImageModal
					isOpen={openedImageInModal !== null}
					onClose={() => setOpenedImageInModal(null)}
					openedImage={openedImageInModal}
					images={images}
					projectId={project?.id}
					onImageUpdate={handleImageUpdate}
					label="project-detials-image-update"
				/>
			)}

			<ConfirmModal
				isOpen={showDeleteConfirmation}
				onConfirm={() => handleImageDeletion()}
				onCancel={() => setShowDeleteConfirmation(false)}
				label="project-detials-image-delete"
				heading=""
				text={t(
					`Are you sure you want to delete ${
						selectedImages.length > 1 ? `${selectedImages.length} Images` : `Image ${selectedImages[0]}`
					}?`,
				)}
			/>
		</Wrapper>
	);
};

ImagesTab.defaultProps = {
	project: {
		images: [],
	},
};

ImagesTab.propTypes = {
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		images: PropTypes.arrayOf(PropTypes.shape({})),
	}),
};

export default ImagesTab;
