import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import Button from 'Common/components/buttons/Button';
import Breadcrumb from 'Common/components/breadcrumbs/Breadcrumb';
import Breadcrumbs from 'Common/components/breadcrumbs/Breadcrumbs';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import BreadcrumbTitle from 'Common/components/breadcrumbs/BreadcrumbTitle';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';

import loadCountryOptions from 'Country/utils/loadCountryOptions';
import loadProjectTypeOptions from 'Projects/utils/loadProjectTypeOptions';

import setStages from 'PMWorkflow/utils/setStages';
import TemplateStage from 'PMWorkflow/components/Stage/TemplateStage/TemplateStage';
import getActivities from 'PMWorkflow/api/getActivities';
import getTemplateState from 'PMWorkflow/api/getTemplateState';
import getAutomationFields from 'PMWorkflow/api/getAutomationFields';
import changeTemplateState from 'PMWorkflow/api/changeTemplateState';
import useReponsive from 'Common/hooks/useResponsive';
import { css } from 'styled-components';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { useSelector } from 'react-redux';
import loadClientContractTypeOptions from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/ClientContract/utils/loadClientContractTypeOptions';

const StyledBreadcrumbs = styled(Breadcrumbs)`
	flex: 1;
	line-height: 1.8em;
	max-width: calc(100vw - ${({ $isSideBarShrank }) => ($isSideBarShrank ? '340px' : '460px')});
	transition: max-width linear 0.3s;
`;

const Wrapper = styled.div`
	margin-bottom: 12px;

	${({ $isMobile }) =>
		$isMobile &&
		css`
			* {
				font-size: 13px;
			}
		`}
`;

const PMWorkflowTab = () => {
	const { t } = useTranslation();
	const { isMobile, isTablet } = useReponsive();
	const isSidebarShrank = useSelector(state => state.common.isSidebarShrank);
	const [state, setState] = useState({
		countries: [],
		isLoading: true,
		projectTypes: [],
		contractTypes: [],
		allActivities: [],
		templateState: null,
		stages: setStages(),
		showConfirmModal: false,
	});

	const abortController = useAbortController();

	const comingFromLocation = useQueryParameter('comingFrom');

	const handleStageDataChange = async () => {
		try {
			const activitiesResponse = await getActivities(abortController.signal);

			setState(prevState => ({
				...prevState,
				allActivities: activitiesResponse.data,
			}));
		} catch (e) {
			showToastError(e);
		}
	};

	const confirmChangeStatus = async () => {
		try {
			const values = {
				templateState: 'APPROVED',
			};

			const response = await changeTemplateState(abortController.signal, {
				...values,
			});

			setState(prevState => ({
				...prevState,
				showConfirmModal: false,
				templateState: response.data.templateState,
			}));
		} catch (e) {
			showToastError(e);
		}
	};

	const setTemplateState = async () => {
		try {
			const state = await getTemplateState(abortController.signal);

			setState(prevState => ({
				...prevState,
				templateState: state.data.templateState,
			}));
		} catch (e) {
			showToastError(e);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const state = await getTemplateState(abortController.signal);
				const activitiesResponse = await getActivities(abortController.signal);
				const projectTypesResponse = await loadProjectTypeOptions(abortController.signal);
				const contractTypesResponse = await loadClientContractTypeOptions(
					abortController.signal,
					undefined,
					false,
				);
				const countriesResponse = await loadCountryOptions(abortController.signal, undefined, []);
				const automationFieldsResponse = await getAutomationFields(abortController.signal);

				setState(prevState => ({
					...prevState,
					isLoading: false,
					countries: countriesResponse,
					projectTypes: projectTypesResponse,
					contractTypes: contractTypesResponse,
					allActivities: activitiesResponse.data,
					templateState: state.data.templateState,
					automationFields: automationFieldsResponse.data,
				}));
			} catch (e) {
				showToastError(e);
			}
		})();
	}, [abortController.signal]);

	return (
		<>
			<ConfirmModal
				isOpen={state.showConfirmModal}
				onConfirm={() => confirmChangeStatus()}
				onCancel={() =>
					setState(prevState => ({
						...prevState,
						showConfirmModal: false,
					}))
				}
				confirmText="Apply"
				label="apply-changes-confirm"
				heading={t('Apply changes')}
				text={t('Are you sure you want to apply the changes to all Projects?')}
			/>
			<HeaderContainer>
				<StyledBreadcrumbs $isSideBarShrank={isSidebarShrank}>
					<Breadcrumb to={`/${comingFromLocation || 'preferences'}`}>
						{t(comingFromLocation) || (isTablet ? t('Pref.') : t('Preferences'))}
					</Breadcrumb>
					<Breadcrumb active>
						<BreadcrumbTitle>{t('PM Workflow')}</BreadcrumbTitle>
					</Breadcrumb>
				</StyledBreadcrumbs>
				<HeaderActions>
					<Button
						icon="check"
						disabled={state.templateState !== 'DRAFT'}
						onClick={() =>
							setState(prevState => ({
								...prevState,
								showConfirmModal: true,
							}))
						}
						text={isMobile || isTablet ? t('Apply') : t('Apply update')}
						label="PMWorkflow - Apply Update Button"
						dataAction="apply"
					/>
				</HeaderActions>
			</HeaderContainer>
			<TilesGridWrapper>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					{state.stages.map((key, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<Wrapper key={`template-stage-${key}-${index}`} $isMobile={isMobile}>
							<TemplateStage
								stage={key}
								stages={state.stages}
								stageIndex={key.index}
								countries={state.countries}
								automationFields={state.automationFields}
								setPMWorkflowState={setState}
								stageActivities={key.activities}
								projectTypes={state.projectTypes}
								stagesLength={state.stages.length}
								contractTypes={state.contractTypes}
								setTemplateState={setTemplateState}
								allActivities={state.allActivities}
								onDataChange={handleStageDataChange}
							/>
						</Wrapper>
					))}
				</Grid>
			</TilesGridWrapper>
		</>
	);
};

export default PMWorkflowTab;
