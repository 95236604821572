import { getHours, getMinutes } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import defaultTimezone from 'Common/constants/defaultTimezone';

export const getTimeValue = date => {
	const hours = getHours(utcToZonedTime(new Date(date), defaultTimezone));
	const minutes = getMinutes(utcToZonedTime(new Date(date), defaultTimezone));

	if (minutes === 59) {
		return 24;
	}

	return hours;
};
