import colors from 'Application/theme/colors';

export const stages = {
	MATURED: 'MATURED',
	ASSET_MANAGEMENT: 'ASSET_MANAGEMENT',
	BUILD: 'BUILD',
	FUNDRAISING: 'FUNDRAISING',
	SALES: 'SALES',
	TECH: 'TECH',
};

export const stagesLabels = ['SALES', 'TECH', 'FUNDRAISING', 'BUILD', 'ASSET_MANAGEMENT'];

export const stageColors = {
	[stages.ASSET_MANAGEMENT]: colors.primary.dark,
	[stages.BUILD]: colors.primary.main,
	[stages.FUNDRAISING]: colors.primary.main,
	[stages.SALES]: colors.common.yellow,
	[stages.TECH]: colors.secondary.dark,
};

export const stageOptions = [
	{
		label: 'Asset Management',
		value: stages.ASSET_MANAGEMENT,
	},
	{
		label: 'Build',
		value: stages.BUILD,
	},
	{
		label: 'Fundraising',
		value: stages.FUNDRAISING,
	},
	{
		label: 'Sales',
		value: stages.SALES,
	},
	{
		label: 'Tech',
		value: stages.TECH,
	},
];
