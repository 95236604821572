import getEmissionSPV from 'Projects/api/getEmissionSPV';

const loadEmissionSPVOptions = async (signal, query) => {
	const response = await getEmissionSPV(signal, query);
	const emissionSPV = response.data;

	return emissionSPV
		.filter(emission => emission.name && emission.name !== '-')
		.map(emission => ({
			value: emission.id,
			label: emission.name,
		}));
};

export default loadEmissionSPVOptions;
