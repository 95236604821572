import api from 'Application/api/api';

const declineEpcOffer = async (signal, id) => {
	const response = await api.patch(`/epc-offers/${id}/decline`, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: response.data,
	};
};

export default declineEpcOffer;
