import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import crudModes from 'Common/constants/crudModes';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import { getRecentYearOptions } from 'Common/utils/getRecentYears';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import editClient from 'Client/api/editClient';
import createClient from 'Client/api/createClient';
import useClientFormInitialValues from 'Client/components/ClientForm/hooks/useClientFormInitialValues';
import useClientFormValidationSchema from 'Client/components/ClientForm/hooks/useClientFormValidationSchema';
import convertFormValuesToRequestData from 'Client/components/ClientForm/utils/convertFormValuesToRequestData';
import loadCountryOptions from 'Country/utils/loadCountryOptions';

import loadIndustryOptions from 'Industries/utils/loadIndustryOptions';
import IndustrySelectOption from 'Industries/components/IndustrySelectOption';
import IndustrySelectValue from 'Industries/components/IndustrySelectValue';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import { OverlayButtons } from 'Common/components/modals/Overlay';

const Form = styled.form`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
`;

const ClientForm = ({ onSubmit, onCancel, onDirty, data, mode }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();

	const initialValues = useClientFormInitialValues(data);
	const validationSchema = useClientFormValidationSchema();

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleChange,
		handleBlur,
		setFieldValue,
		setFieldError,
		resetForm,
		setFieldTouched,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				let response;

				if (mode === crudModes.CREATE) {
					response = await createClient(abortController.signal, convertFormValuesToRequestData(values));

					notify(t('Client created successfully.'), {
						type: toast.TYPE.SUCCESS,
					});
				} else if (mode === crudModes.EDIT) {
					response = await editClient(abortController.signal, {
						...data,
						...convertFormValuesToRequestData(values),
					});

					notify(t('Client saved successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				}

				// Re-set all fields and mark them as not touched
				resetForm({ values });

				if (onSubmit) {
					onSubmit(response.data);
				}

				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	const isAuthorizedToEditExcludedIndustry = isAuthorized([
		roles.ADMIN,
		roles.HEAD_OF_ASSET_MANAGEMENT,
		roles.HEAD_OF_MARKETING,
		roles.HEAD_OF_FUNDRAISING,
		roles.HEAD_OF_TECH,
		roles.HEAD_OF_SALES,
		roles.HEAD_OF_ESG,
	]);
	const isIndustryFieldDisabled =
		(mode === crudModes.EDIT || mode === crudModes.CREATE) &&
		values?.industry?.excluded &&
		!isAuthorizedToEditExcludedIndustry;

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form onSubmit={handleSubmit}>
			<Grid container columnSpacing={20}>
				<Grid item xs={12} md={6}>
					<FormField>
						<FormikInput
							id="name"
							name="name"
							label={t('Name')}
							value={values.name}
							error={errors.name}
							touched={touched.name}
							onChange={handleChange}
							onBlur={handleBlur}
							setFieldTouched={setFieldTouched}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="registrationNumber"
							name="registrationNumber"
							label={t('Registration number')}
							value={values.registrationNumber}
							error={errors.registrationNumber}
							touched={touched.registrationNumber}
							onChange={handleChange}
							onBlur={handleBlur}
							setFieldTouched={setFieldTouched}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="website"
							name="website"
							label={t('Website')}
							value={values.website}
							error={errors.website}
							touched={touched.website}
							onChange={handleChange}
							onBlur={handleBlur}
							setFieldTouched={setFieldTouched}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSelect
							isAsync
							id="industry"
							name="industry"
							label={t('Industry')}
							value={values.industry}
							error={errors.industry}
							touched={touched.industry}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadIndustryOptions}
							menuPosition="fixed"
							isRequired
							components={{
								Option: IndustrySelectOption,
								SingleValue: IndustrySelectValue,
							}}
							menuPlacement="top"
							setFieldTouched={setFieldTouched}
							isDisabled={isIndustryFieldDisabled}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSelect
							id="foundationYear"
							name="foundationYear"
							label={t('Client Foundation Year')}
							value={values.foundationYear}
							error={errors.foundationYear}
							touched={touched.foundationYear}
							setFieldValue={setFieldValue}
							options={getRecentYearOptions()}
							setFieldTouched={setFieldTouched}
							menuPosition="fixed"
							menuPlacement="top"
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="employees"
							name="employees"
							label={t('Employees')}
							value={values.employees}
							error={errors.employees}
							touched={touched.employees}
							onChange={handleChange}
							onBlur={handleBlur}
							setFieldTouched={setFieldTouched}
							isOverlay
						/>
					</FormField>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormField>
						<FormikSelect
							isAsync
							id={parent === 'modal' ? 'countryModal' : 'country'}
							name="country"
							label={t('Country')}
							value={values.country}
							error={errors.country}
							touched={touched.country}
							isClearable
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadCountryOptions}
							menuPosition="fixed"
							setFieldTouched={setFieldTouched}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="apartmentBuilding"
							name="apartmentBuilding"
							label={t('Apartment / Building')}
							value={values.apartmentBuilding}
							error={errors.apartmentBuilding}
							touched={touched.apartmentBuilding}
							onChange={handleChange}
							onBlur={handleBlur}
							setFieldTouched={setFieldTouched}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="line1"
							name="line1"
							label={t('Address line 1')}
							value={values.line1}
							error={errors.line1}
							touched={touched.line1}
							onChange={handleChange}
							onBlur={handleBlur}
							setFieldTouched={setFieldTouched}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="line2"
							name="line2"
							label={t('Address line 2')}
							value={values.line2}
							error={errors.line2}
							touched={touched.line2}
							onChange={handleChange}
							onBlur={handleBlur}
							setFieldTouched={setFieldTouched}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="cityRegion"
							name="cityRegion"
							label={t('City / Region')}
							value={values.cityRegion}
							error={errors.cityRegion}
							touched={touched.cityRegion}
							onChange={handleChange}
							onBlur={handleBlur}
							setFieldTouched={setFieldTouched}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="postCodeZip"
							name="postCodeZip"
							label={t('Post code / ZIP')}
							value={values.postCodeZip}
							error={errors.postCodeZip}
							touched={touched.postCodeZip}
							onChange={handleChange}
							onBlur={handleBlur}
							setFieldTouched={setFieldTouched}
							isOverlay
						/>
					</FormField>
				</Grid>
			</Grid>
			<OverlayButtons
				onCancel={onCancel}
				isLoading={isLoading}
				label="Client Form"
				submitButtonText={`${mode === crudModes.CREATE ? 'Create' : 'Edit'} Client`}
			/>
		</Form>
	);
};

ClientForm.defaultProps = {
	data: null,
};

ClientForm.propTypes = {
	data: PropTypes.shape({
		localizations: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
	}),
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
	mode: PropTypes.string.isRequired,
};

export default ClientForm;
