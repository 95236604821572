import colors from 'Application/theme/colors';

export const statusColorMap = {
	DRAFT: colors.common.orange,
	READY_TO_LAUNCH: colors.secondary.dark,
	ACTIVE: colors.primary.main,
	CLOSED: colors.common.brown,
	FUNDED_INCOMPLETE: colors.common.brown,
	FUNDED_PARTLY: colors.primary.dark,
	FUNDED_COMPLETE: colors.primary.dark,
	NOT_REALISED: colors.common.brown,
};

export const statuses = {
	DRAFT: 'Draft',
	PUBLISHED: 'Published',
};

export const newStatuses = {
	DRAFT: 'DRAFT',
	READY_TO_LAUNCH: 'READY_TO_LAUNCH',
	ACTIVE: 'ACTIVE',
	CLOSED: 'CLOSED',
	FUNDED_INCOMPLETE: 'FUNDED_INCOMPLETE',
	FUNDED_PARTLY: 'FUNDED_PARTLY',
	FUNDED_COMPLETE: 'FUNDED_COMPLETE',
	NOT_REALISED: 'NOT_REALISED',
};

export const newStatusesLabels = {
	[newStatuses.DRAFT]: 'Draft',
	[newStatuses.READY_TO_LAUNCH]: 'Ready to launch',
	[newStatuses.ACTIVE]: 'Active',
	[newStatuses.CLOSED]: 'Closed',
	[newStatuses.FUNDED_INCOMPLETE]: 'Funded incomplete',
	[newStatuses.FUNDED_PARTLY]: 'Funded partly',
	[newStatuses.FUNDED_COMPLETE]: 'Funded complete',
	[newStatuses.NOT_REALISED]: 'Not realised',
};

export const statusOptions = [
	{
		value: 'DRAFT',
		label: 'Draft',
	},
	{
		value: 'READY_TO_LAUNCH',
		label: 'Ready to launch',
	},
	{
		value: 'ACTIVE',
		label: 'Active',
	},
	{
		value: 'CLOSED',
		label: 'Closed',
	},
	{
		value: 'FUNDED_INCOMPLETE',
		label: 'Funded incomplete',
	},
	{
		value: 'FUNDED_PARTLY',
		label: 'Funded partly',
	},
	{
		value: 'FUNDED_COMPLETE',
		label: 'Funded complete',
	},
];

export const statusDescriptions = {
	DRAFT: 'Validations are not met or Campaign not synced with portagon.',
	READY_TO_LAUNCH: ' Synced with portagon and ready for investment.',
	ACTIVE: ' In progress',
	CLOSED: 'Max finance volume reached and within the 14 day waiting period.',
	FUNDED_INCOMPLETE: '14 day waiting period expired and Min finance volume was not reached.',
	FUNDED_PARTLY: '14 day waiting period expired and Min finance volume was reached.',
	FUNDED_COMPLETE: '14 day waiting period expired and Max finance volume was reached.',
	NOT_REALISED: 'Project not completed and funds returned to investors.',
};
