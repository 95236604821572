import api from 'Application/api/api';

const getManufacturerByName = (signal, name) =>
	api.get(`/manufacturers/by-name/${name}`, {
		params: {
			details: true,
		},
		signal,
	});

export default getManufacturerByName;
