import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';

import colors from 'Application/theme/colors';
import useReponsive from 'Common/hooks/useResponsive';
import { css } from 'styled-components';

const _HeaderTitle = styled.div`
	font-size: 30px;
	font-weight: 600;
	color: ${colors.text.primary};
	text-transform: uppercase;
	line-height: 44px;
	justify-content: space-between;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	${({ $isMobile }) =>
		$isMobile &&
		css`
			font-size: 16px;
			width: fit-content;
		`}
`;

const HeaderTitle = ({ children }) => {
	const { isMobile } = useReponsive();

	return <_HeaderTitle $isMobile={isMobile}>{children}</_HeaderTitle>;
};

HeaderTitle.propTypes = {
	children: PropTypes.node.isRequired,
};

export default HeaderTitle;
