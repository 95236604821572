import colors from 'Application/theme/colors';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import BasePill from './BasePill';
import sizes from 'Application/theme/sizes';
import CustomStatusDropdown from 'Common/components/StatusDropdown/StatusDropdown';
import BaseProgressBar from 'Common/components/form/BaseProgressBar';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	position: relative;
	z-index: ${({ $statusDropdownVisible }) => ($statusDropdownVisible ? 3 : 1)};
`;

const RemovingPillContainer = styled(BasePill)`
	position: relative;
	background-color: ${colors.secondary.lighter};
	cursor: pointer;
	margin-bottom: 0px !important;
	z-index: 1;
`;

const InnerWrapper = styled.div`
	position: relative;
	overflow: hidden;
	border-radius: 20px;
	margin-bottom: ${({ $isRemoved }) => ($isRemoved ? '0px' : '2px')};
	transform-origin: top;

	@keyframes remove {
		0% {
			transform: scaleY(1) translateX(0);
			margin-bottom: 2px !important;
		}
		99% {
			transform: scaleY(0) translateX(110%);
			margin-bottom: 2px !important;
		}
		100% {
			transform: scaleY(0);
			min-height: 0;
			max-height: 0;
			height: 0;
		}
	}

	${({ $isRemoved }) =>
		$isRemoved &&
		css`
			animation: remove 0.5s linear forwards;
			border: none !important;

			.fade-section {
				@keyframes fadeInner {
					from {
						opacity: 0;
					}
					to {
						opacity: 1;
					}
				}
				animation: ${({ $isNewlyAdded }) => ($isNewlyAdded ? 'fadeInner 0.2s linear forwards' : 'none')};
			}
		`}
`;

const ProgressContainer = styled.div`
	height: 2px;
	min-width: calc(100% + 45px);
	transform: translateX(-25px);
	position: absolute;
	bottom: 0;
	z-index: 2;
`;

const RemovingPillStatusDropdown = styled.div`
	position: absolute;
	top: 50%;
	right: ${sizes.spacing(2.15)};
	opacity: 1;
	transform: translateY(-50%);
	transition: opacity 0.2s ease-in-out;
	z-index: 2;

	${({ $isRemoved }) =>
		$isRemoved &&
		css`
			opacity: 0;
		`}
`;

const removingTimeMs = 8000;

const RemovingPill = ({
	step,
	onClick,
	isMarked,
	isPaused,
	children,
	projectId,
	setIsPaused,
	setRemovingSteps,
	onStatusUpdate,
	$statusDropdownVisible,
	onMouseEnter,
	onMouseLeave,
	...containerProps
}) => {
	const [progress, setProgress] = useState(100);
	const [isRemoved, setIsRemoved] = useState(false);
	const [endTime, setEndTime] = useState(Date.now() + removingTimeMs);
	const [timeLeft, setTimeLeft] = useState(removingTimeMs);

	const loop = useCallback(() => {
		const now = Date.now();

		const endLoop = () => {
			setIsRemoved(false);
			setIsRemoved(true);
			setIsPaused(false);
			setEndTime(0);
		};

		if (!isRemoved) {
			if (now < endTime) {
				const calculatedProgress = Math.floor(((endTime - now) / removingTimeMs) * 100);
				const newProgress = Math.max(0, calculatedProgress);

				if (newProgress === 0) {
					endLoop();
				}

				setProgress(prevProgress =>
					prevProgress === newProgress ? Math.max(0, prevProgress - 0.01) : newProgress,
				);
			} else {
				endLoop();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isRemoved, endTime, setIsPaused, progress]);

	useEffect(() => {
		setRemovingSteps(prevSteps => {
			const newStepsSet = new Set(prevSteps);

			if (isRemoved) {
				newStepsSet.delete(step.id);
			} else {
				newStepsSet.add(step.id);
			}

			return Array.from(newStepsSet);
		});
	}, [isRemoved, setRemovingSteps, step.id]);

	useEffect(() => {
		if (isPaused) {
			const n = endTime - Date.now();
			setTimeLeft(n);
		} else {
			setEndTime(Date.now() + timeLeft);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPaused]);

	useEffect(() => {
		let animationId;
		if (!isPaused) {
			animationId = requestAnimationFrame(loop);
		}
		return () => {
			cancelAnimationFrame(animationId);
		};
	}, [isPaused, loop]);

	return (
		<Wrapper
			onClick={onClick}
			$statusDropdownVisible={$statusDropdownVisible}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<InnerWrapper $isRemoved={isRemoved}>
				<RemovingPillContainer $isMarked={isMarked} {...containerProps}>
					{children}
				</RemovingPillContainer>
				<ProgressContainer>
					<BaseProgressBar value={progress} />
				</ProgressContainer>
			</InnerWrapper>
			<RemovingPillStatusDropdown $isRemoved={isRemoved}>
				<CustomStatusDropdown
					step={step}
					activityCanBeCompleted={true}
					onStatusUpdate={onStatusUpdate}
					projectId={projectId}
				/>
			</RemovingPillStatusDropdown>
		</Wrapper>
	);
};

RemovingPill.defaultProps = {
	step: {},
	onClick: () => {},
	isMarked: false,
	isPaused: false,
	children: null,
	setIsPaused: () => {},
	setRemovingSteps: () => {},
	onStatusUpdate: () => {},
	$statusDropdownVisible: false,
	onMouseEnter: () => {},
	onMouseLeave: () => {},
};
RemovingPill.propTypes = {
	step: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
	onClick: PropTypes.func,
	isMarked: PropTypes.bool,
	isPaused: PropTypes.bool,
	children: PropTypes.node,
	projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	setIsPaused: PropTypes.func,
	setRemovingSteps: PropTypes.func,
	onStatusUpdate: PropTypes.func,
	$statusDropdownVisible: PropTypes.bool,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
};

export default RemovingPill;
