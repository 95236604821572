import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import MarketSegmentsForm from 'Preferences/components/Tabs/GeneralTab/MarketSegments/MarketSegmentsForm/MarketSegmentsForm';
import crudModes from 'Common/constants/crudModes';
import getMarketSegmentById from 'MarketSegment/api/getMarketSegmentById';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PropTypes } from 'prop-types';

const MarketSegmentsEditModal = ({ isOpen, onClose, marketSegmentId, onFormSubmit }) => {
	const { t } = useTranslation();

	const [marketSegment, setMarketSegment] = useState(null);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				const response = await getMarketSegmentById(abortController.signal, marketSegmentId);

				setMarketSegment(response.data);
			} catch (error) {
				showToastError(error);
			}
		})();
	}, [marketSegmentId, abortController.signal]);

	const isDataLoading = marketSegment === null;

	return (
		<ModalForm
			label="market-segment-edit"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			isLoadingContent={isDataLoading}
			heading={t('Edit Market segment')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<MarketSegmentsForm
					data={marketSegment}
					mode={crudModes.EDIT}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

MarketSegmentsEditModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	marketSegmentId: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default MarketSegmentsEditModal;
