import api from 'Application/api/api';

const getEntityByCountryIds = (signal, countryId) =>
	api.get('/contract-parties/get-pageable', {
		params: {
			countriesIds: countryId,
		},
		signal,
	});

export default getEntityByCountryIds;
