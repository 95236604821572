import colors from 'Application/theme/colors';
import Modal from 'Common/components/modals/Modal';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import H1 from '../headers/H1';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import Loader from 'Common/components/Loader';
import Button from '../buttons/Button';
import sizes from 'Application/theme/sizes';
import { useTranslation } from 'react-i18next';
import Icon from '../icons/Icon';
import PopperTooltip from '../tooltip/PopperTooltip';
import Prompt from '../Prompt';

const ModalFormWrapper = styled(Modal)`
	${({ $width, $height, $maxWidth }) => css`
		.ReactModal__Content {
			max-width: 1200px;
			width: ${$width};
			height: ${$height};
			${$maxWidth ? `max-width: ${$maxWidth};` : ''}
			overflow-y: hidden;
			padding: 0;
			display: flex;
			flex-direction: column;
		}
	`}

	form {
		justify-content: center;
	}
`;

const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: auto;
	position: sticky;
	top: 0;
	background: ${colors.grey.lightest};
	padding-top: ${sizes.spacing(4)};
	padding-bottom: ${sizes.spacing(6)};
	padding-left: ${sizes.spacing(12)};
	padding-right: ${sizes.spacing(12)};
	z-index: 2;

	h1 {
		margin: 0;
	}
`;

const HeadingWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: center;
`;

const ContentWrapper = styled.div`
	overflow-y: auto;
	padding-left: ${sizes.spacing(12)};
	padding-right: ${sizes.spacing(12)};
	padding-bottom: ${sizes.spacing(2)};
	margin-bottom: ${sizes.spacing(12)};
	flex: 1;
`;

const FooterWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 0 ${sizes.spacing(4)} 0;
	bottom: 0;
	justify-content: center;
	position: absolute;
	flex: 1;
	align-self: center;
	flex-grow: 1;

	> * {
		margin-right: ${sizes.spacing(2)};

		&:last-child {
			margin-right: 0;
		}
	}
`;

const TooltipWrapper = styled.div`
	margin-left: 10px;
`;

const HelpText = styled.p`
	${sizes.fontSize.main};
	padding-left: 15px;
	margin-bottom: 10px;
	white-space: pre-line;
	text-align: center;
`;

export const ModalFormButtons = ({
	onCancel,
	onConfirm,
	label,
	submitButtonText,
	isLoading,
	isDisabled,
	transparent,
}) => {
	const { t } = useTranslation();

	return (
		<FooterWrapper $transparent={transparent}>
			<Button secondary text={t('Cancel')} onClick={onCancel} label={`${label} - Cancel Button`} />
			<Button
				overlay
				type="submit"
				onClick={onConfirm}
				disabled={isDisabled}
				text={submitButtonText}
				isLoading={isLoading}
				label={`${label} - ${submitButtonText} Button`}
			/>
		</FooterWrapper>
	);
};

ModalFormButtons.defaultProps = {
	onConfirm: undefined,
	submitButtonText: 'Confirm',
	isLoading: false,
	isDisabled: false,
	transparent: false,
};

ModalFormButtons.propTypes = {
	onCancel: PropTypes.func.isRequired,
	onConfirm: PropTypes.func,
	label: PropTypes.string.isRequired,
	submitButtonText: PropTypes.string,
	isLoading: PropTypes.bool,
	isDisabled: PropTypes.bool,
	transparent: PropTypes.bool,
};

export const ModalForm = ({
	label,
	isOpen,
	onClose,
	onFormSubmit,
	isLoadingContent,
	children,
	heading,
	helpText,
	tooltipText,
	width,
	height,
	maxWidth,
}) => {
	const [isFormDirty, setIsFormDirty] = useState(false);

	const onCancel = () => {
		if (isFormDirty) {
			const result = window.confirm('You have unsaved changes');
			if (result) {
				onClose();
			}
		} else {
			onClose();
		}
	};

	const handleSubmit = data => {
		onFormSubmit(data);
		onClose();
	};

	return (
		<ModalFormWrapper label={label} isOpen={isOpen} $width={width} $height={height} $maxWidth={maxWidth}>
			<Prompt message="" when={isFormDirty} />

			{heading && (
				<HeaderWrapper>
					<HeadingWrapper>
						<H1>{heading}</H1>
						{tooltipText ? (
							<TooltipWrapper>
								<PopperTooltip tooltip="You can paste into this table from Excel.">
									<Icon icon="helpOutline" size="small" color={colors.primary.main} />
								</PopperTooltip>
							</TooltipWrapper>
						) : null}
					</HeadingWrapper>
					{helpText && <HelpText>{helpText}</HelpText>}
				</HeaderWrapper>
			)}
			<ContentWrapper>
				{isLoadingContent ? (
					<LoaderWrapper>
						<Loader />
					</LoaderWrapper>
				) : (
					children(setIsFormDirty, handleSubmit, onCancel)
				)}
			</ContentWrapper>
		</ModalFormWrapper>
	);
};

ModalForm.defaultProps = {
	isLoadingContent: false,
	heading: null,
	helpText: null,
	tooltipText: null,
	width: 'auto',
	height: 'auto',
	maxWidth: undefined,
};

ModalForm.propTypes = {
	label: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	isLoadingContent: PropTypes.bool,
	children: PropTypes.func.isRequired,
	heading: PropTypes.string,
	helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	tooltipText: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
	maxWidth: PropTypes.string,
};

export default ModalForm;
