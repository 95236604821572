import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getFilterRange from 'Projects/api/getFilterRange';
import { merge } from 'lodash';
import loadClientOptions from 'Client/utils/loadClientOptions';
import getUsersWithFullName from 'UserManagement/api/getUsersWithFullName';
import { roles } from 'User/constants/roles';
import { statusesOptions } from 'Projects/constants/statuses';
import modifyNumberFilterData from 'Common/components/filter/hooks/modifyNumberFilterData';
import loadCountryOptions from 'Country/utils/loadCountryOptions';
import loadStepNames from 'PMWorkflow/utils/loadStepNames';
import generateStageFilterValues from 'Common/components/filter/utils/generateStageFilterValues';
import { projectPriorityOptions } from 'Common/constants/projectPrioritiy';
import showToastError from 'Common/utils/showToastError';
import stepsFilterBeforeUpdateQuery from 'PMWorkflow/utils/stepsFilterBeforeUpdateQuery';
import { FILTER_LIFECYCLE_HOOKS, FILTER_TYPES } from 'Common/components/filter/constants/filterConstants';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import getContractsFilterRange from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/ClientContract/api/getContractsFilterRange';

const fallbackFilterRanges = {
	stageCompletedPercentageMin: 0,
	stageCompletedPercentageMax: 100,
};

const useDashboardListFilterData = () => {
	const { t } = useTranslation();

	const loadCustomClientOptions = async (signal, query) => {
		try {
			const options = await loadClientOptions(signal, query);

			const showUnknown = query.length === 0 || 'unknown'.includes(query.toLowerCase());
			if (showUnknown) {
				options.unshift({
					value: -1,
					label: 'Unknown',
				});
			}

			return options;
		} catch (e) {
			showToastError(e, t("Can't load client options"));
		}
	};
	const abortController = useAbortController();

	const [filterRanges, setFilterRanges] = useState(fallbackFilterRanges);
	const [responsiblePersonOptions, setResponsiblePersonOptions] = useState([]);

	const stages = [
		{ label: t('Sales'), name: 'sales' },
		{ label: t('Tech'), name: 'tech' },
		{ label: t('Fundraising'), name: 'fundraising' },
		{ label: t('Build'), name: 'build' },
		{ label: t('Asset Management'), name: 'assetManagement' },
	];

	useEffect(() => {
		(async () => {
			try {
				const responses = await Promise.all([
					getFilterRange(abortController.signal),
					getContractsFilterRange(abortController.signal),
				]);

				setFilterRanges(prevFilterRanges => ({
					...prevFilterRanges,
					...merge(...responses.map(response => response.data)),
				}));

				const response = await getUsersWithFullName(abortController.signal, {
					active: true,
					roles: [
						roles.ADMIN,
						roles.FINANCE,
						roles.ASSET_MANAGER,
						roles.ASSET_MARKETING,
						roles.MANAGEMENT,
						roles.PM_FUNDRAISING,
						roles.PM_TECHNICAL,
						roles.SALES,
					],
				});
				if (response.data.length > 0) {
					setResponsiblePersonOptions(
						response.data.map(user => ({
							value: user.email,
							label: user.fullName,
						})),
					);
				}
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setFilterRanges(fallbackFilterRanges);
				}
			}
		})();
	}, [abortController.signal]);

	const defaultFilterData = [
		{
			name: 'combinedStepStatusTitles',
			label: t('Steps'),
			type: FILTER_TYPES.STEPS,
			loadOptions: loadStepNames,
			[FILTER_LIFECYCLE_HOOKS.BEFORE_UPDATE_QUERY]: stepsFilterBeforeUpdateQuery,
		},
		{
			name: 'priority',
			label: t('Priority'),
			type: FILTER_TYPES.SELECT,
			options: projectPriorityOptions,
		},
		{
			name: 'condition',
			label: t('Status'),
			type: FILTER_TYPES.SELECT,
			options: statusesOptions,
		},
		...stages.map(stage => ({
			...stage,
			stageStatus: stage.name + 'StageStatus',
			type: FILTER_TYPES.STAGE,
			value: '',
			minProps: { icon: '%', iconPosition: 'right' },
			maxProps: { icon: '%', iconPosition: 'right' },
			minName: stage.name + 'StageCompletedPercentageFrom',
			maxName: stage.name + 'StageCompletedPercentageTo',
			lowerBound: filterRanges.stageCompletedPercentageMin,
			upperBound: filterRanges.stageCompletedPercentageMax,
			[FILTER_LIFECYCLE_HOOKS.BEFORE_UPDATE_VALUE]: generateStageFilterValues,
		})),
		{
			name: 'countryId',
			label: t('Country'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadCountryOptions,
		},
		{
			name: 'clientId',
			label: t('Client'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadCustomClientOptions,
			handleSearch: (values, setValue) => {
				if (values.includes('-1')) {
					setValue('-1');
				}
			},
		},
		{
			name: 'responsiblePersonEmail',
			label: t('People'),
			type: FILTER_TYPES.SELECT,
			options: responsiblePersonOptions,
		},
	];

	const [filtersData, setFiltersData] = useState(defaultFilterData);

	useEffect(() => {
		const modifyNumberFilterDataBinded = modifyNumberFilterData.bind(undefined, filterRanges);

		setFiltersData(prevFilterData => [...prevFilterData].map(modifyNumberFilterDataBinded));
	}, [filterRanges]);

	useEffect(() => {
		setFiltersData(prevFilterData =>
			[...prevFilterData].map(el =>
				el.name === 'responsiblePersonEmail' ? { ...el, options: responsiblePersonOptions } : el,
			),
		);
	}, [responsiblePersonOptions]);

	return filtersData;
};

export default useDashboardListFilterData;
