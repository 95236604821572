import api from 'Application/api/api';
import adaptClientContractRequestData from './adapters/adaptClientContractRequestData';
import adaptClientContractResponseData from './adapters/adaptClientContractResponseData';

const createClientContract = async (signal, values) => {
	const response = await api.post('/client-contracts', adaptClientContractRequestData(values), {
		signal,
		timeout: 100000,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptClientContractResponseData(response.data),
	};
};

export default createClientContract;
