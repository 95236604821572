import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import { push } from 'redux-first-history';
import { useSortBy } from 'react-table';
import { debounce, fill } from 'lodash';
import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';

import 'rc-pagination/assets/index.css';

import colors from 'Application/theme/colors';

import OnHoldIcon from 'Projects/components/OnHoldIcon';

import Link from 'Common/components/Link';
import Icon from 'Common/components/icons/Icon';
import formatDate from 'Common/utils/formatDate';
import formatNumber from 'Common/utils/formatNumber';
import StatusPill from 'Common/components/StatusPill';
import { DefaultTable } from 'Common/components/table/table';
import PillWrapper from 'Common/components/PillWrapper';
import formatCurrency from 'Common/utils/formatCurrency';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import TableCellText from 'Common/components/table/TableCellText';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import defaultTimezone from 'Common/constants/defaultTimezone';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import { useScrollableTableBody } from 'Common/hooks/table/plugin-hooks/useScrollableTableBody';
import { useVirtualizedTableBody } from 'Common/hooks/table/plugin-hooks/useVirtualizedTableBody';
import { useSortingQueryParameter } from 'Common/hooks/table/plugin-hooks/useSortingQueryParameter';

import getCampaigns from 'Campaigns/api/getCampaigns';
import {
	newStatusesLabels,
	statusColorMap,
	statusDescriptions,
	newStatuses,
} from 'Campaigns/constants/campaignStatuses';
import { hasMissingData, generateTooltipMessage } from 'Campaigns/utils/campaignHelpers';
import showToastError from 'Common/utils/showToastError';
import axios from 'axios';
import useUniqueAbortSignal from 'Common/hooks/useUniqueAbortSignal';
import prefillTableData from 'Common/utils/prefillTableData';

const Wrapper = styled.div`
	position: relative;
	pointer-events: ${({ hasOpenFilter }) => (hasOpenFilter ? 'none' : 'all')};
`;

const FooterSizeText = styled.div`
	transform: translateX(-30%);
`;

const StyledOnHoldIcon = styled(OnHoldIcon)`
	width: 20px;
	height: 20px;
`;

const defaultSortBy = {
	id: 'id',
	desc: true,
};

const sortPropertiesMap = {
	systemSize: 'financeDetails.systemSize',
	investments_sum: 'investmentsSum',
	id: 'externalId',
	maximum_volume: 'maximumVolume',
};

const CampaignsListTable = () => {
	const { t } = useTranslation();
	const hasOpenFilter = !!useSelector(state => state?.tables?.campaigns?.openedFilter);

	const [totalItemsCount, setTotalItemsCount] = useState(25);
	const [totals, setTotals] = useState(null);

	const sortByQueryParam = useQueryParameter('sortBy');
	const sortBy = sortByQueryParam ?? defaultSortBy;
	const filters = useQueryParameter('filters');

	const searchQuery = useQueryParameter('searchQuery');

	const dispatch = useDispatch();

	const [campaigns, setCampaigns] = useState(() => fill(Array(25), {}));
	const [isLoading, setIsLoading] = useState(true);

	const getUniqueSignal = useUniqueAbortSignal();

	const hasLoadedFirstResultsRef = useRef(false);

	const getCampaignsBatch = useCallback(
		async (startIndex, stopIndex, filters, sortBy, searchQuery, hasItemsChanges = false) => {
			if (hasItemsChanges) {
				setIsLoading(true);
			}

			const sortById = sortPropertiesMap[sortBy.id] ?? sortBy.id;

			const customFilters = { ...filters };

			if (customFilters.lastModifiedDateFrom) {
				customFilters.lastModifiedDateFrom = zonedTimeToUtc(
					format(new Date(customFilters.lastModifiedDateFrom), "yyyy-MM-dd'T'HH:mm.sss'Z'", {
						timeZone: defaultTimezone,
					}),
					defaultTimezone,
				);
			}
			if (customFilters.lastModifiedDateTo) {
				customFilters.lastModifiedDateTo = zonedTimeToUtc(
					format(new Date(customFilters.lastModifiedDateTo), "yyyy-MM-dd'T'HH:mm.sss'Z'", {
						timeZone: defaultTimezone,
					}),
					defaultTimezone,
				);
			}

			try {
				let response = await getCampaigns(
					getUniqueSignal('get-campaigns'),
					{
						offset: startIndex,
						limit: stopIndex - startIndex + 1,
						sortBy: sortById,
						sortDirection: sortBy.desc ? 'DESC' : 'ASC',
					},
					customFilters,
					searchQuery,
				);
				if (hasItemsChanges || !hasLoadedFirstResultsRef.current) {
					const { itemsCount, itemsFilled, error } = prefillTableData(response.data, startIndex, stopIndex);
					if (error) {
						showToastError(error);
					} else {
						setCampaigns(itemsFilled);
						setTotalItemsCount(itemsCount);
						setTotals({
							totalInvestmentsSum: response.data.totalInvestmentsSum,
							totalMaximumVolume: response.data.totalMaximumVolume,
							totalSystemSize: response.data.totalSystemSize,
						});
					}
				} else {
					setCampaigns(prevCampaigns =>
						prevCampaigns.map((prevCampaign, index) => {
							if (startIndex <= index && index <= stopIndex) {
								const indexInResultSet = index - startIndex;

								return response.data.content[indexInResultSet];
							}

							return prevCampaign;
						}),
					);
				}

				hasLoadedFirstResultsRef.current = true;

				if (hasItemsChanges) {
					setIsLoading(false);
				}
			} catch (error) {
				showToastError(error, t("Can't fetch campaigns"));
				if (!axios.isCancel(error)) {
					setCampaigns([]);
					setTotalItemsCount(0);
				}
			}
		},
		[getUniqueSignal, t],
	);
	useEffect(() => {
		getCampaignsBatch(
			0,
			25,
			filters,
			{
				id: sortBy.id,
				desc: sortBy.desc,
			},
			searchQuery,
			true,
		);
	}, [getCampaignsBatch, searchQuery, filters, sortBy.desc, sortBy.id]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleLoadMoreRows = useCallback(
		debounce(
			(startIndex, stopIndex) => {
				getCampaignsBatch(
					startIndex,
					stopIndex,
					filters,
					{
						id: sortBy.id,
						desc: sortBy.desc,
					},
					searchQuery,
				);
			},
			350,
			{
				leading: false,
				trailing: true,
			},
		),
		[getCampaignsBatch, searchQuery, filters, sortBy.desc, sortBy.id],
	);
	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Status'),
				accessor: 'status',
				minWidth: 220,
				highlightSearchTerm: true,
				customBodyCellStyles: {
					paddingLeft: '4px',
				},
				Cell: (() => {
					const Cell = ({ row: { original: campaign } }) =>
						campaign.status ? (
							<PillWrapper>
								<HoverTooltip placement="right" title={statusDescriptions[campaign.status] || ''} arrow>
									<StatusPill color={statusColorMap[campaign.status]}>{newStatusesLabels[campaign.status]}</StatusPill>
								</HoverTooltip>
								{campaign.on_hold && (
									<HoverTooltip placement="right" title={t('On hold and not listed on the ecoligo website.')} arrow>
										<StyledOnHoldIcon />
									</HoverTooltip>
								)}
								{campaign.status !== newStatuses.FUNDED_COMPLETE &&
									campaign.status !== newStatuses.FUNDED_PARTLY &&
									(!campaign?.synched || hasMissingData(campaign)) && (
										<HoverTooltip placement="right" title={generateTooltipMessage(campaign, t)} arrow>
											<Icon icon="reportProblem" color={colors.warning.main} />
										</HoverTooltip>
									)}
							</PillWrapper>
						) : (
							'-'
						);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								status: PropTypes.oneOf(Object.values(newStatuses)),
								on_hold: PropTypes.bool,
								synched: PropTypes.bool,
							}),
						}).isRequired,
					};
					return Cell;
				})(),
				Footer: totalItemsCount,
			},
			{
				Header: t('Projects ID'),
				accessor: 'projectId',
				maxWidth: 104,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				disableSortBy: true,
				Cell: ({ row: { original: campaign } }) =>
					campaign?.finance_details?.projects?.length > 0
						? campaign?.finance_details?.projects?.map((project, index) => (
								<Fragment key={`campaign-${project.id}`}>
									<Link
										color="#00b4d5"
										fontSize="14px"
										onClick={e => {
											e.stopPropagation();
										}}
										to={`/projects/details/${project?.id}`}
									>
										{project?.externalId}
									</Link>
									{campaign?.finance_details?.projects?.length - 1 === index ? ' ' : ', '}
								</Fragment>
						  ))
						: '-',
			},
			{
				Header: t('Name'),
				accessor: 'name',
				maxWidth: 210,
				highlightSearchTerm: true,
				customCellContentStyles: {
					paddingLeft: '4px',
				},
				customBodyCellContentStyles: {
					paddingRight: '8px',
				},
				Cell: (() => {
					const Cell = ({ row: { original: campaign } }) =>
						campaign.name ? (
							<Link
								color="#00b4d5"
								fontSize="14px"
								onClick={e => {
									e.stopPropagation();
								}}
								to={`/campaigns/details/${campaign?.auto_id}`}
							>
								<TableCellText>{campaign.name}</TableCellText>
							</Link>
						) : (
							'-'
						);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								name: PropTypes.string,
								auto_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Finance vol.'),
				accessor: 'maximum_volume',
				highlightSearchTerm: true,
				Cell: ({ row: { original: campaigns } }) =>
					campaigns?.maximum_volume ? formatCurrency(campaigns?.maximum_volume) : '-',
				Footer: totals?.totalMaximumVolume ? formatCurrency(totals?.totalMaximumVolume, 0) : '-',
			},
			{
				Header: t('Investments'),
				accessor: 'investments_sum',
				maxWidth: 114,
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original: campaign } }) => (
						<div title={campaign?.investments_sum}>
							{campaign?.investments_sum ? formatCurrency(campaign?.investments_sum) : '-'}
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ investments_sum: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
				Footer: totals?.totalInvestmentsSum ? formatCurrency(totals?.totalInvestmentsSum, 0) : '-',
			},
			{
				Header: t('Start date'),
				accessor: 'funding_start_date',
				maxWidth: 114,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				disableSortBy: true,
				Cell: ({ row: { original: campaign } }) =>
					campaign.funding_start_date ? formatDate(campaign.funding_start_date) : '-',
			},
			{
				Header: t('End date'),
				accessor: 'funding_end_date',
				maxWidth: 114,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				disableSortBy: true,
				Cell: ({ row: { original: campaign } }) =>
					campaign.funding_end_date ? formatDate(campaign.funding_end_date) : '-',
			},
			{
				Header: t('Last investment'),
				accessor: 'last_investment_date',
				maxWidth: 134,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				disableSortBy: true,
				Cell: ({ row: { original: campaigns } }) =>
					campaigns.last_investment_date ? formatDate(campaigns.last_investment_date) : '-',
			},
			{
				Header: t('Funded in'),
				accessor: 'duration',
				maxWidth: 104,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				disableSortBy: true,
				Cell: ({ row: { original: campaign } }) => (campaign?.duration ? `${campaign?.duration} days` : '-'),
			},
			{
				Header: t('Country'),
				accessor: 'financeDetails.projects.project.country',
				maxWidth: 104,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original: campaigns } }) => (
						<div title={campaigns?.finance_details?.country}>{campaigns?.finance_details?.country}</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ finance_details: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t(' Emission SPV'),
				accessor: 'emissionSPV.name',
				maxWidth: 120,
				highlightSearchTerm: true,
				cellStyle: {
					justifyContent: 'flex-end',
				},
				Cell: (() => {
					const Cell = ({ row: { original: campaigns } }) => (
						<div title={campaigns?.emissionSpv?.name}>{campaigns?.emissionSpv?.name}</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ emissionSpv: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Projects'),
				accessor: 'projectsId',
				maxWidth: 104,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				disableSortBy: true,
				Cell: ({ row: { original: campaign } }) =>
					campaign?.finance_details?.projects?.length > 0
						? campaign?.finance_details?.projects?.map((project, index) => (
								<Fragment key={`campaign-${project.id}`}>
									<Link
										color="#00b4d5"
										fontSize="14px"
										onClick={e => {
											e.stopPropagation();
										}}
										to={`/projects/details/${project?.id}`}
									>
										{project?.externalId}
									</Link>
									{campaign?.finance_details?.projects?.length - 1 === index ? ' ' : ', '}
								</Fragment>
						  ))
						: '-',
			},
			{
				Header: t('Size (kWp)'),
				accessor: 'systemSize',
				maxWidth: 105,
				isNumber: true,
				highlightSearchTerm: true,
				cellStyle: {
					justifyContent: 'flex-end',
				},
				Cell: ({ row: { original: campaigns } }) =>
					campaigns?.finance_details?.systemSize ? formatNumber(campaigns?.finance_details?.systemSize, 0) : '-',
				Footer: (
					<FooterSizeText>
						{totals?.totalSystemSize ? `${formatNumber(totals?.totalSystemSize, 0)} kWp` : '-'}
					</FooterSizeText>
				),
			},
			{
				Header: t('CO2 tonnes'),
				accessor: 'co2',
				maxWidth: 110,
				disableSortBy: true,
				Cell: ({ row: { original: campaigns } }) =>
					campaigns?.finance_details?.co2Savings ? formatNumber(campaigns?.finance_details?.co2Savings, 0) : '-',
			},
			{
				Header: t('ID'),
				accessor: 'id',
				maxWidth: 54,
				highlightSearchTerm: true,
				cellStyle: {
					justifyContent: 'flex-end',
				},
				Cell: ({ row: { original: campaigns } }) => campaigns?.id ?? '-',
			},
		],
		[t, totalItemsCount, totals?.totalInvestmentsSum, totals?.totalMaximumVolume, totals?.totalSystemSize],
	);

	const data = useMemo(() => campaigns, [campaigns]);

	const itemsHash = useMemo(() => JSON.stringify({ filters, sortBy }), [filters, sortBy]);

	const handleRowClick = useCallback(
		row => {
			if (!window.getSelection().toString()) {
				dispatch(push(`/campaigns/details/${row.original.auto_id}`));
			}
		},
		[dispatch],
	);

	// Fallback to default row id getter
	const getRowId = useCallback(
		(row, relativeIndex, parent) => (parent ? [parent.id, relativeIndex].join('.') : relativeIndex),
		[],
	);

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		getVirtualizedTableBodyProps,
		headerGroups,
		footerGroups,
		rows,
		prepareRow,
	} = useStandardTable(
		{
			data,
			columns,
			defaultSortBy,
			searchTerm: searchQuery,
			onRowClick: handleRowClick,
			virtualization: {
				totalRowsCount: totalItemsCount,
				rowsHash: itemsHash,
				loadMoreRows: handleLoadMoreRows,
			},
			getRowId,
		},
		useClickableTableRow,
		useCustomCellStyles,
		useSortBy,
		useSortingQueryParameter,
		useScrollableTableBody,
		useVirtualizedTableBody,
	);

	return (
		<Wrapper hasOpenFilter={hasOpenFilter}>
			<DefaultTable
				getTableProps={getTableProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				getVirtualizedTableBodyProps={getVirtualizedTableBodyProps}
				rows={rows}
				headerGroups={headerGroups}
				footerGroups={footerGroups}
				prepareRow={prepareRow}
				isLoading={isLoading || rows.length !== totalItemsCount}
				showNoResultsFound
			/>
		</Wrapper>
	);
};

export default CampaignsListTable;
