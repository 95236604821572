import React from 'react';
import SomethingWentWrong from './SomethingWentWrong';
import { PropTypes } from 'prop-types';
import customGAException from 'Common/utils/customGAException';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, error };
	}

	componentDidCatch(error, errorInfo) {
		// Send error to Google Analytics
		customGAException(error + errorInfo.componentStack, true);

		this.props.setTrackPathChange(true);
	}

	render() {
		if (this.state.hasError) {
			return <SomethingWentWrong />;
		}

		return this.props.children;
	}
}

ErrorBoundary.defaultProps = {
	setTrackPathChange: () => null,
};

ErrorBoundary.propTypes = {
	children: PropTypes.node.isRequired,
	setTrackPathChange: PropTypes.func,
};

export default ErrorBoundary;
