import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';
import Button from 'Common/components/buttons/Button';
import loadInvitedEpcPartners from 'EpcPartner/utils/loadInvitedEpcPartners';
import H5 from 'Common/components/headers/H5';
import { PropTypes } from 'prop-types';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 32px;
`;

const StyledFormField = styled(FormField)`
	padding: 0 10px;
	margin-bottom: 10px;
`;

const TextWrapper = styled.div`
	width: 100%;
	font-size: 16px;
	padding: 00px 10px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 40px;
`;

const StyledSelect = styled(FormikSelect)`
	margin-top: 16px;
	margin-bottom: 16px;
`;

const RfepEpcPartnersSection = ({ values, errors, touched, handleBlur, setFieldValue, rfepState }) => {
	const { t } = useTranslation();
	const handleSendRfep = e => {
		e.preventDefault();
		setFieldValue('sendTender', true);
		e.target.closest('form').requestSubmit();
	};

	return (
		<Wrapper>
			<TextWrapper>
				<H5> Select the EPC Partners to send the RFEP to.</H5>
			</TextWrapper>
			<StyledFormField>
				<StyledSelect
					isAsync
					id="invitedPartners"
					name="invitedPartners"
					error={errors.invitedPartners}
					touched={touched.invitedPartners}
					value={values.invitedPartners}
					setFieldValue={setFieldValue}
					loadOptions={loadInvitedEpcPartners}
					menuPosition="fixed"
					onBlur={handleBlur}
					isMulti
					isClearable={rfepState !== 'Open'}
					isOverlay
				/>
			</StyledFormField>
			<TextWrapper>{`${values?.invitedPartners?.length || 0} EPC Partner(s) selected`}</TextWrapper>
			{(rfepState === undefined || rfepState === 'Draft') && (
				<ButtonWrapper>
					<Button
						icon="send"
						text={t('Send RFEP')}
						disabled={
							values?.invitedPartners === null ||
							values?.invitedPartners === undefined ||
							values?.invitedPartners?.length === 0
						}
						onClick={handleSendRfep}
						label="Projects Details Offers RFEP EPC Partners - Send RFEP Button"
					/>
				</ButtonWrapper>
			)}
		</Wrapper>
	);
};

RfepEpcPartnersSection.defaultProps = {
	values: {},
	errors: {},
	touched: {},
	handleBlur: () => {},
	setFieldValue: () => {},
	rfepState: undefined,
};

RfepEpcPartnersSection.propTypes = {
	values: PropTypes.shape({
		invitedPartners: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	errors: PropTypes.shape({
		invitedPartners: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	touched: PropTypes.shape({
		invitedPartners: PropTypes.bool,
	}),
	handleBlur: PropTypes.func,
	setFieldValue: PropTypes.func,
	rfepState: PropTypes.string,
};

export default RfepEpcPartnersSection;
