import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import React from 'react';
import styled from 'styled-components/macro';
import NoFalseValueSwitch from '../form/NoFalseValueSwitch';
import Icon from '../icons/Icon';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${sizes.spacing(1)};
	padding-left: ${sizes.spacing(1)};
`;

const StatusDropdownTrigger = styled.div`
	width: 25px;
	height: 25px;
`;

const StatusIcon = styled.div`
	width: 100%;
	height: 100%;
	border: solid 2.5px ${props => (props.color ? props.color : colors.common.blue)};
	border-radius: 50%;

	svg {
		width: 100%;
		height: 100%;
		color: ${colors.common.blue};
	}
`;

const StepFilterCheckbox = ({ stepData: { key, showCompleted }, onStepStatusChange }) => {
	const handleSetValue = value => {
		onStepStatusChange(key, value);
	};

	return (
		<Wrapper>
			<StatusDropdownTrigger>
				<StatusIcon>{showCompleted && <Icon icon="check" />}</StatusIcon>
			</StatusDropdownTrigger>
			<NoFalseValueSwitch setValue={handleSetValue} value={showCompleted} />
		</Wrapper>
	);
};

StepFilterCheckbox.propTypes = {
	stepData: PropTypes.shape({
		key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		showCompleted: PropTypes.bool.isRequired,
	}).isRequired,
	onStepStatusChange: PropTypes.func.isRequired,
};

export default StepFilterCheckbox;
