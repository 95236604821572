import { css } from 'styled-components';

import colors from 'Application/theme/colors';

const baseButtonStyles = css`
	// Active
	border-radius: 32px;
	cursor: pointer;
	border: 0 none;
	font-size: 16px;
	font-weight: 500;
	max-height: 32px;
	padding: ${({ $textLength }) => ($textLength > 3 ? '4px 32px' : '4px 48px')};
	color: ${colors.common.white};
	background: ${colors.primary.dark};
	white-space: nowrap;
	box-shadow: none;
	text-transform: none;

	// Hover
	&:hover {
		background: ${colors.common.lightGreen};
		box-shadow: none;
	}

	// Pressed
	&:active {
		background: ${colors.common.darkGreen};
		box-shadow: none;
	}

	// Disabled
	&.Mui-disabled {
		color: ${colors.text.white};
		background: ${colors.grey.light};
		pointer-events: none;
		cursor: not-allowed;
	}

	// Focused
	&:focus-visible {
		box-shadow: 0px 2px 12px ${colors.primary.dark};
		outline: none;
	}

	${({ $secondary }) =>
		$secondary &&
		css`
			// Active
			color: ${colors.primary.dark};
			background: ${colors.primary.light};

			// Hover
			&:hover {
				background: ${colors.secondary.lighter};
			}

			// Pressed
			&:active {
				background: ${colors.primary.main};
			}

			// Disabled
			&:disabled {
				color: ${colors.text.grey};
				background: ${colors.grey.light};
			}
		`}

	${({ $icon }) =>
		$icon
			? css`
					padding: ${({ $textLength }) => ($textLength > 4 ? '4px 32px 4px 16px' : '4px 42px 4px 26px')};
					${({ $isLoading }) =>
						!$isLoading &&
						css`
							svg {
								margin-right: 6px;
							}
						`}
			  `
			: css`
					${({ $isLoading }) =>
						$isLoading &&
						css`
							padding: ${({ $textLength }) => ($textLength > 0 ? '12px' : '4px')};
							min-width: 120px;
						`}
			  `}

	${({ $overlay }) =>
		$overlay &&
		css`
			box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
			&:disabled {
				box-shadow: none;
			}
		`}

	${({ $medium }) =>
		$medium &&
		css`
			min-width: 36px;
			min-height: 36px;
			aspect-ratio: 1;
			padding: 0px;
			color: ${colors.primary.dark};

			// Active
			background: ${colors.primary.light};

			// Hover
			&:hover {
				color: ${colors.common.white};
				background: ${colors.common.lightGreen};
			}

			// Pressed
			&:active {
				background: ${colors.common.darkGreen};
			}

			// Disabled
			&:disabled {
				color: ${colors.grey.main};
			}

			${({ $icon }) =>
				$icon &&
				css`
					svg {
						margin-right: 0px;
					}
				`}

			${({ $active }) =>
				$active &&
				css`
					color: ${colors.common.white};
					background: ${colors.primary.dark};
				`}
		`}
	
	${({ $small }) =>
		$small &&
		css`
			width: 28px;
			height: 28px;
			aspect-ratio: 1;
			min-width: 28px;
			padding: 0px;
			svg {
				width: 18px;
				height: 18px;
				margin: 0 auto;
			}

			${({ $icon }) =>
				$icon &&
				css`
					.MuiSvgIcon-root {
						margin: 0px;
					}
				`}
		`}

	${({ $noBackground }) =>
		$noBackground &&
		css`
			color: ${colors.common.black};
			background: transparent;
		`}
`;

export default baseButtonStyles;
