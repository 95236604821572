import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import FormikTextArea from 'Common/components/form/FormikTextArea';
import FormField from 'Common/components/form/FormField';
import MapLink from 'Common/components/MapLink';
import Mandatory from 'Common/components/form/Mandatory';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';
import { PropTypes } from 'prop-types';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 32px;
`;

const InfoWrapper = styled.div`
	display: flex;
	margin-bottom: 16px;
`;

const Title = styled.span`
	font-size: 20px;
	font-weight: 600;
	margin-right: 20px;
	margin-bottom: 20px;
	display: flex;
`;

const StyledCheckbox = styled(FormikCheckbox)`
	margin-top: 16px;
	margin-bottom: 16px;

	.MuiCheckbox-root {
		margin-left: 0px;
	}
`;

const Block = styled.div`
	margin-right: 20px;
	width: 40%;
`;

const StyledFormField = styled(FormField)`
	padding: 0 10px;
	//min-height: 40%;

	> div {
		height: 100%;
	}
`;

const MapButtonContainer = styled.div`
	margin-top: 16px;
`;

const RfepGeneralSection = ({
	project,
	values,
	errors,
	touched,
	handleBlur,
	handleChange,
	setFieldTouched,
}) => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<InfoWrapper>
				<Block>
					<Title>{t('Client')}</Title>
					<KeyValueVisualization title={t('Project ID')} value={project?.externalId} />
					<KeyValueVisualization title={t('Client')} value={project?.client?.name} />

					<KeyValueVisualization title={t('Industry')} value={project?.client?.industry?.name} />
				</Block>
				<Block>
					<Title>{t('Location')}</Title>
					<KeyValueVisualization title={t('Country')} value={project?.country?.name} />
					<KeyValueVisualization title={t('City')} value={project?.client?.address?.cityRegion} />
					<MapButtonContainer>
						<MapLink href={project?.locationUrl} />
					</MapButtonContainer>
				</Block>
			</InfoWrapper>
			<StyledCheckbox
				label={t('Hide client name')}
				id="hideClientName"
				name="hideClientName"
				checked={values.hideClientName}
				onChange={handleChange}
				onBlur={handleBlur}
				touched={touched.hideClientName}
			/>
			<Title>
				{t('Description')}
				<Mandatory />
				<QuestionTooltip
					large
					tooltip={<>Describe the Project so EPC Partners understand what they are bidding on.</>}
				/>
			</Title>
			<StyledFormField data-last-field>
				<FormikTextArea
					id="description"
					name="description"
					error={errors.description}
					touched={touched.description}
					setFieldTouched={setFieldTouched}
					value={values.description}
					onChange={handleChange}
					onBlur={handleBlur}
					isOverlay
				/>
			</StyledFormField>
		</Wrapper>
	);
};

RfepGeneralSection.defaultProps = {
	project: {},
	values: {},
	errors: {},
	touched: {},
	handleBlur: () => {},
	handleChange: () => {},
	setFieldTouched: () => {},
};

RfepGeneralSection.propTypes = {
	project: PropTypes.shape({
		externalId: PropTypes.number,
		client: PropTypes.shape({
			name: PropTypes.string,
			industry: PropTypes.shape({ name: PropTypes.string }),
			address: PropTypes.shape({ cityRegion: PropTypes.string }),
		}),
		country: PropTypes.shape({
			name: PropTypes.string,
		}),
		locationUrl: PropTypes.string,
	}),
	values: PropTypes.shape({
		hideClientName: PropTypes.bool,
		description: PropTypes.string,
	}),
	errors: PropTypes.shape({
		description: PropTypes.string,
	}),
	touched: PropTypes.shape({
		hideClientName: PropTypes.bool,
		description: PropTypes.string,
	}),
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	setFieldTouched: PropTypes.func,
};

export default RfepGeneralSection;
