import { useMemo } from 'react';
import { pick } from 'lodash';

import crudModes from 'Common/constants/crudModes';

const fallbackValues = {
	name: '',
	marketSegment: null,
	usable: true,
	restricted: false,
};

const useIndustriesFormInitialValues = (data, mode) =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['name', 'restricted']),
			marketSegment: data?.marketSegment
				? {
						label: data?.marketSegment?.name,
						value: data?.marketSegment?.id,
				  }
				: null,
			usable: mode === crudModes.CREATE ? true : data?.usable ? data?.usable : false,
			excluded: data?.excluded,
		}),
		[data, mode],
	);

export default useIndustriesFormInitialValues;
