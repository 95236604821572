import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { push } from 'redux-first-history';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { stringify } from 'qs';

import Filters from 'Common/components/filter/FiltersContainer';

import { updateCurrentTab } from 'Application/reducers/reduxTabs';
import ButtonLink from 'Common/components/buttons/ButtonLink';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import crudModes from 'Common/constants/crudModes';

import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';

import useClientListFilterData from 'Client/components/ClientList/hooks/useClientListFilterData';

import SignedClientList from 'Client/components/ClientList/Tabs/SignedClientList/SignedClientList';
import UnsignedClientList from 'Client/components/ClientList/Tabs/UnsignedClientList/UnsignedClientList';
import TabsContainer from 'Common/components/tabs';
import { useLocation } from 'react-router';
import ClientOverlay from './ClientForm/ClientOverlay';

const FiltersWrapper = styled.div`
	background: ${colors.common.headerGrey};
`;

const sortPropertiesMap = {
	country: 'country.name',
	marketSegment: 'marketSegment.name',
	cityRegion: 'address.cityRegion',
	primaryContact: 'firstName',
};

const Clients = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const clientButtonRef = useRef();
	const unsignedClientButtonRef = useRef();
	const filtersData = useClientListFilterData();
	const sortByQueryParam = useQueryParameter('sortBy');
	const filters = useQueryParameter('filters');
	const location = useLocation();

	const isSignedTab = useMemo(() => location.pathname.includes('/clients'), [location.pathname]);

	useEffect(() => {
		dispatch(updateCurrentTab({ tab: 'clients', tabIndex: isSignedTab ? 0 : 1 }));
	}, [isSignedTab, dispatch]);

	const onTabSelect = useCallback(
		selectedTabIndex => {
			if (selectedTabIndex === 0) {
				dispatch(push('/clients'));
			} else {
				dispatch(push('/unsignedClients'));
			}
		},
		[dispatch],
	);

	const modifyClientFilters = filters => {
		const projectType = filters?.projectType;

		if (!projectType) return filters;

		if (projectType === 'inOperationProjects') {
			return {
				...filters,
				inOperationProjects: true,
			};
		}

		if (projectType === 'signedProjects') {
			return {
				...filters,
				signedProjects: true,
			};
		}

		return filters;
	};

	const openExport = useCallback(
		exportWithOptions => {
			const defaultFilters = {
				signedProjects: isSignedTab,
				notSignedProjects: !isSignedTab,
			};
			const exportFilters = modifyClientFilters({
				...filters,
				...defaultFilters,
			});

			const sortById =
				sortByQueryParam && sortPropertiesMap[sortByQueryParam.id]
					? sortPropertiesMap[sortByQueryParam.id]
					: sortByQueryParam?.id;

			if (exportWithOptions) {
				window.open(
					`api/clients/export-table-view?${
						sortByQueryParam
							? 'sort=' + sortById + '%2C' + (sortByQueryParam.desc ? 'DESC' : 'ASC')
							: 'sort=name%2CASC'
					}&${stringify(exportFilters)}&size=9999&`,

					'_blank',
				);
			} else {
				window.open(
					`api/clients/export-table-view?&size=9999&${
						sortByQueryParam
							? 'sort=' + sortById + '%2C' + (sortByQueryParam.desc ? 'DESC' : 'ASC')
							: 'sort=name%2CASC'
					}&${stringify(defaultFilters)}`,
					'_blank',
				);
			}
		},
		[sortByQueryParam, filters, isSignedTab],
	);

	const tabsConfig = useMemo(
		() => [
			{
				label: 'Signed',
				PanelComponent: SignedClientList,
				onTabSelect,
			},
			{
				label: 'Not Signed',
				PanelComponent: UnsignedClientList,
				onTabSelect,
			},
		],
		[onTabSelect],
	);

	const [isCreateOpen, setIsCreateOpen] = useState();

	const handleCreateClose = () => {
		setIsCreateOpen(false);
	};

	return (
		<>
			<Helmet>
				<title>{t('Ecoligo Clients')}</title>
			</Helmet>

			<HeaderContainer>
				<HeaderTitle>{t('Clients')}</HeaderTitle>
				<HeaderActions>
					{isSignedTab ? <div ref={clientButtonRef}></div> : <div ref={unsignedClientButtonRef}></div>}
					{isAuthorized([roles.ADMIN, roles.SALES, roles.FINANCE, roles.MANAGEMENT]) && (
						<ButtonLink
							label="Create Client - Clients"
							onClick={() => setIsCreateOpen(true)}
							text={t('Create Client')}
							icon="addRounded"
						/>
					)}
					<MoreActionsButton label="Clients More Actions">
						{filters && (
							<MenuItem
								onClick={() => openExport(true)}
								type="button"
								data-action="exportFiltered"
								label="Clients - Export filtered to CSV Menu Item"
							>
								{t('Export filtered to CSV')}
							</MenuItem>
						)}
						<MenuItem
							onClick={() => openExport(false)}
							type="button"
							data-action="exportAll"
							label="Clients - Export all to CSV Menu Item"
						>
							{t('Export all to CSV')}
						</MenuItem>
					</MoreActionsButton>
				</HeaderActions>
			</HeaderContainer>

			<FiltersWrapper>
				{isSignedTab ? (
					<Filters key="1" table="clients" buttonRef={clientButtonRef} filtersData={filtersData} />
				) : (
					<Filters key="2" table="unsignedClients" buttonRef={unsignedClientButtonRef} filtersData={filtersData} />
				)}
			</FiltersWrapper>
			<TabsContainer page="clients" tabsConfig={tabsConfig} />

			{isCreateOpen && (
				<ClientOverlay
					isOpen={isCreateOpen}
					onClose={handleCreateClose}
					mode={crudModes.CREATE}
					onFormSubmit={data => dispatch(push(`/clients/details/${data.id}`))}
				/>
			)}
		</>
	);
};

export default Clients;
