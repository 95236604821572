import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { omit, pick } from 'lodash';
import styled from 'styled-components/macro';
import ReactTimeAgo from 'react-time-ago';
import PropTypes from 'prop-types';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import TileDynamicSection from 'Common/components/Tile/TileDynamicSection';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import editCampaign from 'Campaigns/api/editCampaign';
import { newStatuses } from 'Campaigns/constants/campaignStatuses';
import useStatusFormInitialValues from 'Campaigns/components/CampaignDetails/Tabs/GeneralTab/Tiles/StatusTile/hooks/useStatusFormInitialValues';
import useStatusFormValidationSchema from 'Campaigns/components/CampaignDetails/Tabs/GeneralTab/Tiles/StatusTile/hooks/useStatusFormValidationSchema';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const SectionTitle = styled.div`
	font-size: 18px;
	font-weight: 600;
	margin: 30px 20px 20px 0;
	display: flex;
	align-items: center;
`;

const StyledFormikCheckbox = styled(FormikCheckbox)`
	.MuiCheckbox-root {
		margin-left: 10px !important;
	}
`;

const ValidationCheckboxKeyValue = styled(KeyValueVisualization)`
	> div {
		// KeyContainer
		&:first-child {
			flex: 0 1 calc(100% - 80px);
			width: 100%;

			// Key
			> span {
				//width: 200px;
			}
		}

		// ValueContainer
		&:last-child {
			width: 80px;
			min-width: initial;

			// Value
			> div {
				width: 80px;
			}
		}
	}
`;

const StatusTile = ({ campaign, onEnterEditMode, onExitEditMode, onDirtyForm, onSave }) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.HEAD_OF_ESG,
		roles.PM_FUNDRAISING,
		roles.HEAD_OF_FUNDRAISING,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useStatusFormInitialValues(campaign);
	const validationSchema = useStatusFormValidationSchema();

	const { touched, values, handleSubmit, dirty, errors, handleChange, handleBlur, resetForm } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			setIsSavingChanges(true);
			let response;

			try {
				response = await editCampaign(abortController.signal, {
					...omit(campaign, ['finance_details']),
					...pick(campaign.finance_details, [
						'co2',
						'systemSize',
						'status',
						'frontendReviewPassed',
						'documentsAvailableForDownload',
						'vibApproved',
						'trancheSetupCorrectly',
						'projectPassedPortagonPlausibility',
						'displayName',
					]),
					...values,
					id: campaign.auto_id,
					minimumVolume: campaign.minimum_volume,
					maximumVolume: campaign.maximum_volume,
				});

				if (onDirtyForm) {
					onDirtyForm(false);
				}

				onSave(response.data);
				handleCancel();
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (error) {
				showToastError(error, t('An error occured while trying to save Status Tile'));

				if (!axios.isCancel(error)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	const isExternalIdDisabled =
		campaign.status === newStatuses.ACTIVE ||
		campaign.status === newStatuses.FUNDED_COMPLETE ||
		campaign.status === newStatuses.FUNDED_PARTLY;

	return (
		<EditableTile
			title={t('portagon status')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<KeyValueVisualization
				id="updated"
				title={t('Updated')}
				value={
					<>
						Updated&nbsp;
						{campaign.last_portagon_sync ? (
							<ReactTimeAgo
								date={campaign.last_portagon_sync ? new Date(campaign.last_portagon_sync) : new Date()}
								timeStyle="round"
							/>
						) : (
							'never'
						)}
					</>
				}
				hasHoverEffect
			/>
			<FormField>
				<FormikInput
					id="externalId"
					name="externalId"
					label={t('portagon ID')}
					value={values.externalId}
					error={errors.externalId}
					touched={touched.externalId}
					onChange={handleChange}
					onBlur={handleBlur}
					isDisabled={isExternalIdDisabled}
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>

			<SectionTitle>{t('Validations')}</SectionTitle>

			<TileDynamicSection
				isInEditMode={isInEditMode}
				editView={
					<>
						<FormField>
							<StyledFormikCheckbox
								label={t('Tranche is setup correctly in portagon backend')}
								id="trancheSetupCorrectly"
								name="trancheSetupCorrectly"
								checked={values.trancheSetupCorrectly}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={touched.trancheSetupCorrectly}
							/>
						</FormField>
						<FormField>
							<StyledFormikCheckbox
								label={t('VIB approved')}
								id="vibApproved"
								name="vibApproved"
								checked={values.vibApproved}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={touched.vibApproved}
							/>
						</FormField>
						<FormField>
							<StyledFormikCheckbox
								label={t('Documents available for download for the investors in the Frontend')}
								id="documentsAvailableForDownload"
								name="documentsAvailableForDownload"
								checked={values.documentsAvailableForDownload}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={touched.documentsAvailableForDownload}
							/>
						</FormField>
						<FormField>
							<StyledFormikCheckbox
								label={t('Frontend review passed')}
								id="frontendReviewPassed"
								name="frontendReviewPassed"
								checked={values.frontendReviewPassed}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={touched.frontendReviewPassed}
							/>
						</FormField>
						<FormField>
							<StyledFormikCheckbox
								label={t('Project passed portagon plausibility check')}
								id="projectPassedPortagonPlausibility"
								name="projectPassedPortagonPlausibility"
								checked={values.projectPassedPortagonPlausibility}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={touched.projectPassedPortagonPlausibility}
							/>
						</FormField>
					</>
				}
				readonlyView={
					<>
						<ValidationCheckboxKeyValue
							id="trancheSetupCorrectly"
							title={t('Tranche is setup correctly in portagon backend')}
							value={campaign.finance_details?.trancheSetupCorrectly ? 'Yes' : 'No'}
							hasHoverEffect
						/>
						<ValidationCheckboxKeyValue
							id="vibApproved"
							title={t('VIB approved')}
							value={campaign.finance_details?.vibApproved ? 'Yes' : 'No'}
							hasHoverEffect
						/>
						<ValidationCheckboxKeyValue
							id="documentsAvailableForDownload"
							title={t('Documents available for download for the investors in the Frontend')}
							value={campaign.finance_details?.documentsAvailableForDownload ? 'Yes' : 'No'}
							hasHoverEffect
						/>
						<ValidationCheckboxKeyValue
							id="frontendReviewPassed"
							title={t('Frontend review passed')}
							value={campaign.finance_details?.frontendReviewPassed ? 'Yes' : 'No'}
							hasHoverEffect
						/>
						<ValidationCheckboxKeyValue
							id="projectPassedPortagonPlausibility"
							title={t('Project passed portagon plausibility check')}
							value={campaign.finance_details?.projectPassedPortagonPlausibility ? 'Yes' : 'No'}
							hasHoverEffect
						/>
					</>
				}
				uniqueId="status"
			/>
		</EditableTile>
	);
};

StatusTile.defaultProps = {
	campaign: {},
	onSave: () => {},
	onDirtyForm: () => {},
	onExitEditMode: () => {},
	onEnterEditMode: () => {},
};

StatusTile.propTypes = {
	onSave: PropTypes.func,
	campaign: PropTypes.shape({
		finance_details: PropTypes.shape({
			trancheSetupCorrectly: PropTypes.bool,
			vibApproved: PropTypes.bool,
			documentsAvailableForDownload: PropTypes.bool,
			frontendReviewPassed: PropTypes.bool,
			projectPassedPortagonPlausibility: PropTypes.bool,
		}),
		auto_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		minimum_volume: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		maximum_volume: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		status: PropTypes.string,
		last_portagon_sync: PropTypes.string,
	}),
	onDirtyForm: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onEnterEditMode: PropTypes.func,
};

export default StatusTile;
