import colors from 'Application/theme/colors';

export const projectPriorityOptions = [
	{
		value: 'LOW',
		label: 'Low',
	},
	{
		value: 'MEDIUM',
		label: 'Medium',
	},
	{
		value: 'HIGH',
		label: 'High',
	},
];

export const projectPriorities = {
	LOW: 'LOW',
	MEDIUM: 'MEDIUM',
	HIGH: 'HIGH',
};

export const projectPriorityIcons = {
	LOW: {
		icon: 'lowPriority',
		color: colors.primary.dark,
	},
	MEDIUM: {
		icon: 'mediumPriority',
		color: colors.common.orange,
	},
	HIGH: {
		icon: 'highPriority',
		color: colors.common.brown,
	},
};
