import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import loadCountryOptions from 'Country/utils/loadCountryOptions';
import FormikSelect from 'Common/components/form/FormikSelect';

import updateEpcPartner from 'EpcPartner/api/updateEpcPartner';
import useAddressFormValidationSchema from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/AddressTile/hooks/useAddressFormValidationSchema';
import useAddressFormInitialValues from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/AddressTile/hooks/useAddressFormInitialValues';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const AddressTile = ({ epcPartner, onEnterEditMode, onExitEditMode, onSave, onDirtyForm }) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([roles.EPC_PARTNER]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useAddressFormInitialValues(epcPartner);
	const validationSchema = useAddressFormValidationSchema();

	const newLoad = async () =>
		(await loadCountryOptions()).filter(item => item.value !== 11432 && item.value !== 12035);

	const { errors, touched, values, handleSubmit, dirty, handleChange, handleBlur, setFieldValue, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: async values => {
				setIsSavingChanges(true);

				let response;

				try {
					response = await updateEpcPartner(abortController.signal, {
						...omit(epcPartner, ['tier', 'countryIds']),
						countryIds: values.countryIds ? values.countryIds.map(country => country.value) : [],
						address: omit(values, ['country', 'countries']),
					});

					setIsSavingChanges(false);

					if (onDirtyForm) {
						onDirtyForm(false);
					}

					onSave(response.data);
					handleCancel();
					resetForm({ values });
				} catch (e) {
					showToastError(e, t("Can't update EPC partner"));
					if (!axios.isCancel(e)) {
						setIsSavingChanges(false);
					}
				}
			},
		});

	const apartmentBuildingValue = isInEditMode
		? values.apartmentBuilding
		: epcPartner.address?.apartmentBuilding
		? epcPartner.address?.apartmentBuilding
		: '';

	const line1Value = isInEditMode ? values.line1 : epcPartner.address?.line1 ? epcPartner.address?.line1 : '';

	const line2Value = isInEditMode ? values.line2 : epcPartner.address?.line2 ? epcPartner.address?.line2 : '';

	const cityRegionValue = isInEditMode
		? values.cityRegion
		: epcPartner.address?.cityRegion
		? epcPartner.address?.cityRegion
		: '';

	const postCodeZipValue = isInEditMode
		? values.postCodeZip
		: epcPartner.address?.postCodeZip
		? epcPartner.address?.postCodeZip
		: '';

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleOnDivClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Address')}
			onClick={handleOnDivClick}
			isInEditMode={isInEditMode}
			isLoading={isSavingChanges}
			onCancel={handleCancel}
			onSubmit={handleSubmit}
		>
			<FormField>
				<FormikSelect
					isAsync
					isMulti
					id="countryIds"
					name="countryIds"
					label={t('Countries')}
					value={values.countryIds}
					error={errors.countryIds}
					touched={touched.countryIds}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={newLoad}
					isRequired
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="apartmentBuilding"
					name="apartmentBuilding"
					label={t('Apartment / Building')}
					value={apartmentBuildingValue}
					error={errors.apartmentBuilding}
					touched={touched.apartmentBuilding}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="line1"
					name="line1"
					label={t('Address line 1')}
					value={line1Value}
					error={errors.line1}
					touched={touched.line1}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="line2"
					name="line2"
					label={t('Address line 2')}
					value={line2Value}
					error={errors.line2}
					touched={touched.line2}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="cityRegion"
					name="cityRegion"
					label={t('City / Region')}
					value={cityRegionValue}
					error={errors.cityRegion}
					touched={touched.cityRegion}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="postCodeZip"
					name="postCodeZip"
					label={t('Post code / ZIP')}
					value={postCodeZipValue}
					error={errors.postCodeZip}
					touched={touched.postCodeZip}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
		</EditableTile>
	);
};

AddressTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
};

AddressTile.propTypes = {
	epcPartner: PropTypes.shape({
		address: PropTypes.shape({
			apartmentBuilding: PropTypes.string,
			line1: PropTypes.string,
			line2: PropTypes.string,
			cityRegion: PropTypes.string,
			postCodeZip: PropTypes.string,
		}),
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onSave: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
};

export default AddressTile;
