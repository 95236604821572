import { useMemo } from 'react';
import * as yup from 'yup';
import yupUrl from 'Common/utils/yupUrl';
import { useTranslation } from 'react-i18next';
import {
	locationTextFormatValidator,
	locationTextDecimalPlacesValidator,
	locationTextLatitudeRangeValidator,
	locationTextLongitudeRangeValidator,
} from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/LocationTile/utils/locationTextValidators';

const useLocationFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				locationUrl: yupUrl(),
				locationText: yup
					.string()
					.nullable()
					.test(
						'location-format',
						t('Must contain two valid numbers, separated by a comma'),
						locationTextFormatValidator,
					)
					.test(
						'decimal places',
						t('Numbers must have no more than 15 decimal places'),
						locationTextDecimalPlacesValidator,
					)
					.test(
						'range-latitude',
						t('First number(latitude) must be between -90 and 90'),
						locationTextLatitudeRangeValidator,
					)
					.test(
						'range-longitude',
						t('Second number(longitude) must be between -180 and 180'),
						locationTextLongitudeRangeValidator,
					),
				//handing as string due to js 16 decimal limit
				gridEmissionFactor: yup
					.string()
					.nullable()
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'number',
						t('Must be a valid number'),
						value => value === undefined || value === null || /^-?\d*\.?\d*$/.test(value),
					)
					.test(
						'positive',
						t('Must be positive'),
						value => value === undefined || value === null || Number(value) >= 0,
					)
					.test(
						'decimal',
						t('Grid emission factor can be from 0 to 1'),
						value => value === undefined || value === null || Number(value) <= 1,
					)
					.test(
						'decimal-places',
						t('Must have no more than 20 decimal places'),
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,20})?$/),
					),
			}),
		[t],
	);
};

export default useLocationFormValidationSchema;
