import getEntityByCountryIds from 'Projects/api/getEntityByCountryIds';

const loadEntityOptions = async (signal, query) => {
	const response = await getEntityByCountryIds(signal, query);
	const entities = response.data.content;
	return entities
		.filter(entity => entity.name && entity.name !== '-')
		.map(entity => ({
			value: entity.id,
			label: entity.name,
			subtitle: `${entity.abbreviation}, ${entity.country.name}`,
		}));
};

export default loadEntityOptions;
