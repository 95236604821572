import api from 'Application/api/api';

const getMarketSegments = (signal, query) =>
	api.get('/market-segments/get-pageable', {
		params: {
			query,
			sort: 'name,ASC',
			size: 999,
		},
		signal,
	});

export default getMarketSegments;
