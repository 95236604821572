import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useExpellContactValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				comment: yup.string().required(t('Required')),
			}),
		[t],
	);
};

export default useExpellContactValidationSchema;
