import api from 'Application/api/api';

const getProjectsPartials = (signal, query) =>
	api.get('/projects/get-partial', {
		params: {
			query,
			size: 9999,
		},
		signal,
	});

export default getProjectsPartials;
