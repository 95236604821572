import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useCanBeCompletedByUser = step => {
	const user = useSelector(state => state.user);
	const [userCanCompleteStep, setUserCanCompleteStep] = useState(false);

	useEffect(() => {
		if (user?.roles?.includes('ROLE_ADMIN')) {
			setUserCanCompleteStep(true);
		} else {
			if (!step?.responsibleTeam?.name.includes('HEAD')) {
				setUserCanCompleteStep(true);
			} else if (
				step?.responsibleTeam?.name.includes('HEAD') &&
				user?.roles?.includes(step?.responsibleTeam?.name)
			) {
				setUserCanCompleteStep(true);
			}
		}
	}, [user?.roles, step]);

	return userCanCompleteStep;
};

export default useCanBeCompletedByUser;
