const serializeQueryStepData = querySteps => {
	if (!querySteps) return [];

	return querySteps.map(({ value, title, status }) => ({
		key: Number(value),
		value: Number(value),
		label: title,
		title: title,
		showCompleted: status === 'COMPLETED',
	}));
};

const formatLocalStepData = localSteps => {
	if (!localSteps) return [];

	return localSteps.map(step => ({
		value: step.key,
		title: step.title,
		status: step.showCompleted ? 'COMPLETED' : 'OPEN',
	}));
};

export { serializeQueryStepData, formatLocalStepData };
