import api from 'Application/api/api';

const getInvoices = (signal, configuration, filters = {}, searchQuery) => {
	const { sortBy = 'dateModified', sortDirection = 'DESC' } = configuration;

	const params = {
		...filters,
		...configuration,
		query: searchQuery,
	};

	if (sortBy && sortDirection) {
		params.sort = `${sortBy},${sortDirection}`;
	}

	return api.get('/invoices/get-pageable', {
		params,
		signal,
		timeout: 100000,
	});
};

export default getInvoices;
