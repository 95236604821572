import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import Tile from 'Common/components/Tile/Tile';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { DefaultTable, TableHeadCell, TableBodyCell } from 'Common/components/table/table';
import PropTypes from 'prop-types';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import Icon from 'Common/components/icons/Icon';
import colors from 'Application/theme/colors';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import Loader from 'Common/components/Loader';
import openUrlInNewTab from 'Common/utils/downloadFile';
import deleteTemplate from 'Invoice/api/deleteTemplate';
import TableCellText from 'Common/components/table/TableCellText';
import ReactTimeAgo from 'react-time-ago';
import formatDate from 'Common/utils/formatDate';
import DeleteInvoiceTemplateViolationModal from 'Invoice/components/Modals/DeleteInvoiceTemplateViolationModal';
import useFileUpload from 'Common/hooks/useFileUpload';
import uploadInvoiceTemplates from 'Invoice/api/uploadInvoiceTemplates';
import { FILE_TYPES } from 'Common/constants/fileTypes';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}
	${TableBodyCell} {
		background: transparent;
	}
`;

const UploadFileOverlay = styled.div``;

const InvoiceTemplatesList = ({ countryId, isLoadingContent, invoiceTemplates, onDataChange }) => {
	const { t } = useTranslation();

	const abortController = useAbortController();
	const [deleteTemplateViolations, setDeleteTemplateViolations] = useState();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Name'),
				accessor: 'fileName',
				customCellStyles: {
					flex: '1000 0 0',
				},
			},
			{
				Header: t('Updated'),
				accessor: 'createdDate',
				maxWidth: 70,
				Cell: (() => {
					const Cell = ({ row: { original: invoiceTempalte } }) =>
						invoiceTempalte?.createdDate ? (
							<TableCellText tooltipContent={formatDate(invoiceTempalte?.createdDate, 'EEEE dd/MM/yyyy HH:mm')}>
								<ReactTimeAgo date={new Date(invoiceTempalte?.createdDate)} timeStyle="round" tooltip={false} />
							</TableCellText>
						) : (
							'-'
						);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ createdDate: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: '',
				accessor: 'actionDots',
				maxWidth: 70,
				customCellStyles: {
					justifyContent: 'flex-end',
				},
				customBodyCellContentStyles: {
					padding: '5px',
				},
			},
		],
		[t],
	);

	const handleDownloadTemplate = useCallback(invoiceTemplate => {
		openUrlInNewTab(invoiceTemplate.url);
	}, []);

	const handleDeleteTemplate = useCallback(
		async invoiceTemplate => {
			try {
				await deleteTemplate(abortController.signal, invoiceTemplate.id);
				notify(t('Invoice template deleted successfully'), {
					type: toast.TYPE.SUCCESS,
				});
				onDataChange();
			} catch (error) {
				const hasViolations = error?.response && error?.response?.data?.violations;
				if (hasViolations) {
					setDeleteTemplateViolations(error?.response?.data?.violations?.[0]?.message);
				} else {
					showToastError(error);
				}
			}
		},
		[abortController.signal, onDataChange, t],
	);

	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			invoiceTemplates.map(invoiceTemplate => ({
				...invoiceTemplate,
				name: invoiceTemplate?.name || '-',
				createdDate: invoiceTemplate?.createdDate || undefined,
				actionDots: (
					<MoreActionsButton label="Invoice Templates More Actions" controlledVisibility>
						<MenuItem
							data-action="download"
							onClick={() => handleDownloadTemplate(invoiceTemplate)}
							label="Invoice Templates - Download Menu Item"
						>
							Download
						</MenuItem>
						<MenuItem
							data-action="delete"
							onClick={() => handleDeleteTemplate(invoiceTemplate)}
							label="Invoice Templates - Delete Menu Item"
						>
							Delete
						</MenuItem>
					</MoreActionsButton>
				),
			})),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t, invoiceTemplates],
	);

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
			},
			useCustomCellStyles,
		);

	const handleFileUpload = useCallback(
		async ({ formData, allCount, acceptedCount }) => {
			try {
				await uploadInvoiceTemplates(abortController.signal, countryId, formData);

				onDataChange();

				notify(`Success! ${acceptedCount} of ${allCount} template(s) uploaded`, {
					type: acceptedCount === allCount ? toast.TYPE.SUCCESS : toast.TYPE.WARNING,
				});
			} catch (error) {
				showToastError(error, t('Error uploading images. Please try again'));
			}
		},
		[abortController.signal, countryId, onDataChange, t],
	);

	const {
		handleAddFile: handleAddInvoiceTemplate,
		isUploading,
		generateInputProps,
		isDragActive,
	} = useFileUpload({
		onFileUpload: handleFileUpload,
		fileName: 'Invoice Template',
		fileIdentifier: 'templates',
		acceptedTypes: [FILE_TYPES.DOCX],
	});

	return (
		<>
			<Tile
				title={t('Invoice Templates')}
				rightComponent={
					<>
						<TileActionButton
							data-button="invoiceTemplate"
							icon="cloudUploadRounded"
							onClick={handleAddInvoiceTemplate}
							label="Preferences Country list - Upload Button"
						/>
					</>
				}
				heightFitConent
				isLoadingContent={isLoadingContent}
				isTable
			>
				{isDragActive && (
					<UploadFileOverlay>
						<Icon icon="cloudUploadRounded" color={colors.text.white} size="huge" className="bigIcon" />
						<p>{t('Drop your images here to upload')}</p>
					</UploadFileOverlay>
				)}

				{isUploading ? (
					<LoaderWrapper>
						<Loader />
					</LoaderWrapper>
				) : (
					<TableWrapper>
						<input {...generateInputProps()} />
						<DefaultTable
							getTableProps={getTableProps}
							getTableHeaderProps={getTableHeaderProps}
							getTableBodyProps={getTableBodyProps}
							rows={rows}
							headerGroups={headerGroups}
							prepareRow={prepareRow}
						/>
					</TableWrapper>
				)}
			</Tile>
			<DeleteInvoiceTemplateViolationModal
				isOpen={deleteTemplateViolations}
				violation={deleteTemplateViolations}
				onClose={() => setDeleteTemplateViolations(null)}
			/>
		</>
	);
};

InvoiceTemplatesList.defaultProps = {
	countryId: undefined,
	invoiceTemplates: [],
	isLoadingContent: false,
};

InvoiceTemplatesList.propTypes = {
	isLoadingContent: PropTypes.bool,
	countryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	invoiceTemplates: PropTypes.arrayOf(PropTypes.shape({})),
	onDataChange: PropTypes.func.isRequired,
};

export default InvoiceTemplatesList;
