export const tierTooltips = {
	Bronze: (
		<>
			<b>Bronze&nbsp;&nbsp;&nbsp;&nbsp;</b>
			{'Internal qualification passed.'}
		</>
	),
	Silver: (
		<>
			<b>Silver&nbsp;&nbsp;&nbsp;&nbsp;</b>
			{`Internal qualification passed, 1+ project in operation
            and 5+ points.`}
		</>
	),
	Gold: (
		<>
			<b>Gold&nbsp;&nbsp;&nbsp;&nbsp;</b>
			{'External qualification passed and 15+ points.'}
		</>
	),
	Platinum: (
		<>
			<b>Silver&nbsp;&nbsp;&nbsp;&nbsp;</b>
			{'External qualification passed and 30+ points.'}
		</>
	),
	Diamond: (
		<>
			<b>Silver&nbsp;&nbsp;&nbsp;&nbsp;</b>
			{'Platinum status for 1+ years and 50+ points.'}
		</>
	),
};
