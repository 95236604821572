import { useMediaQuery } from '@mui/material';

const useResponsive = () => {
	const isTouchDevice = useMediaQuery('(hover: none)');
	const isMobile = useMediaQuery('(max-width:600px)');
	const isTablet = useMediaQuery('(min-width: 600px) and (max-width:960px)');
	const isSmallscreen = useMediaQuery('(min-width: 960px) and (max-width:1280px)');
	const isMediumScreen = useMediaQuery('(min-width: 1280px) and (max-width:1440px)');
	const isBigScreen = useMediaQuery('(min-width: 1440px) and (max-width:1920px)');
	const isFullHd = useMediaQuery('(min-width: 1920px) and (max-width:2560px)');
	const is4k = useMediaQuery('(min-width: 2560px)');

	const useCustom = (minWidth, maxWidth) =>
		useMediaQuery(`(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`);

	const isMobileUp = useMediaQuery('(min-width:600px)');
	const isTabletUp = useMediaQuery('(min-width: 600px)');
	const isSmallscreenUp = useMediaQuery('(min-width: 960px)');
	const isMediumScreenUp = useMediaQuery('(min-width: 1280px)');
	const isBigScreenUp = useMediaQuery('(min-width: 1440px)');
	const isFullHdUp = useMediaQuery('(min-width: 1920px)');
	const is4kUp = useMediaQuery('(min-width: 2560px)');

	const useCustomUp = minWidth => useMediaQuery(`(min-width: ${minWidth}px)`);

	const isMobileDown = useMediaQuery('(max-width:600px)');
	const isTabletDown = useMediaQuery('(max-width: 960px)');
	const isSmallscreenDown = useMediaQuery('(max-width: 1280px)');
	const isMediumScreenDown = useMediaQuery('(max-width: 1440px)');
	const isBigScreenDown = useMediaQuery('(max-width: 1920px)');
	const isFullHdDown = useMediaQuery('(max-width: 2560px)');
	const is4kDown = useMediaQuery('(max-width: 3840px)');

	const useCustomDown = maxWidth => useMediaQuery(`(max-width: ${maxWidth}px)`);

	return {
		isTouchDevice,
		isMobile,
		isTablet,
		isSmallscreen,
		isMediumScreen,
		isBigScreen,
		isFullHd,
		is4k,
		useCustom,

		isMobileUp,
		isTabletUp,
		isSmallscreenUp,
		isMediumScreenUp,
		isBigScreenUp,
		isFullHdUp,
		is4kUp,
		useCustomUp,

		isMobileDown,
		isTabletDown,
		isSmallscreenDown,
		isMediumScreenDown,
		isBigScreenDown,
		isFullHdDown,
		is4kDown,
		useCustomDown,
	};
};

export default useResponsive;
