import { useEffect, useState } from 'react';
import ModalForm from 'Common/components/form/ModalForm';
import EpcContractForm from 'EpcContract/components/EpcContractForm/EpcContractForm';
import crudModes from 'Common/constants/crudModes';
import getEpcContractById from 'EpcContract/api/getEpcContractById';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import showToastError from 'Common/utils/showToastError';
import { useTranslation } from 'react-i18next';
import useAbortController from 'Common/hooks/useAbortController';
import { PropTypes } from 'prop-types';

const EpcContractEditModal = ({ isOpen, onClose, contractId, project, onFormSubmit }) => {
	const [contractData, setContractData] = useState(null);
	const [currency, setCurrency] = useState(null);

	const { t } = useTranslation();

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				const response = await getEpcContractById(abortController.signal, contractId);

				// Fetch the currency from the annualOmPrice,
				// as it's a required field, and it always has a currency.
				const currencyOptions = await loadCurrenciesOptions(
					abortController.signal,
					response.data.epcVolumeExclVat.currency,
					false,
				);

				setCurrency(currencyOptions.find(e => e.value === response.data.epcVolumeExclVat.currency));
				setContractData(response.data);
			} catch (e) {
				showToastError(e, t("Can't update contract data"));
			}
		})();
	}, [contractId, abortController.signal, t]);

	const isDataLoaded = contractData !== null && currency !== null;

	return (
		<ModalForm
			label="epc-contracat-edit"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			isLoadingContent={!isDataLoaded}
			heading={t('Edit EPC Contract')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<EpcContractForm
					data={contractData}
					projectId={project.id}
					currencies={project.currencies}
					systemSize={String(project.systemSizeKwp)}
					mode={crudModes.EDIT}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

EpcContractEditModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	contractId: PropTypes.number.isRequired,
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		currencies: PropTypes.arrayOf(PropTypes.shape({})),
		systemSizeKwp: PropTypes.number,
	}).isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default EpcContractEditModal;
