import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Prompt from 'Common/components/Prompt';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import crudModes from 'Common/constants/crudModes';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import FormikSelect from 'Common/components/form/FormikSelect';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import editIndustry from 'Industries/api/editIndustry';
import getIndustries from 'Industries/api/getIndustries';
import createIndustry from 'Industries/api/createIndustry';
import loadMarketSegmentOptions from 'MarketSegment/utils/loadMarketSegmentOptions';
import useIndustriesFormInitialValues from './hooks/useIndustriesFormInitialValues';
import useIndustriesFormValidationSchema from './hooks/useIndustriesFormValidationSchema';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { ModalFormButtons } from 'Common/components/form/ModalForm';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const FieldsWrapper = styled.div`
	width: 100%;
`;

const IndustriesForm = ({ data, mode, onSubmit, onCancel, onDirty }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();

	const initialValues = useIndustriesFormInitialValues(data, mode);
	const validationSchema = useIndustriesFormValidationSchema();

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleChange,
		handleBlur,
		setFieldError,
		resetForm,
		setFieldValue,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				if (mode === crudModes.CREATE) {
					await createIndustry(abortController.signal, {
						...values,
						marketSegmentId: values.marketSegment.value,
					});

					notify(t('Industry created successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				} else if (mode === crudModes.EDIT) {
					await editIndustry(abortController.signal, {
						...data,
						...values,
						marketSegmentId: values.marketSegment.value,
					});

					notify(t('Industry saved successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				}

				const response = await getIndustries(abortController.signal);
				onSubmit(response.data.content);

				// Re-set all fields and mark them as not touched
				resetForm({ values });
				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<>
			<Prompt message="" when={dirty} />
			<Form onSubmit={handleSubmit}>
				<FieldsWrapper>
					<FormField>
						<FormikInput
							id="name"
							name="name"
							label={t('Name')}
							value={values.name}
							error={errors.name}
							touched={touched.name}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
						/>
					</FormField>
				</FieldsWrapper>
				<FieldsWrapper>
					<FormField>
						<FormikSelect
							isAsync
							id="marketSegment"
							name="marketSegment"
							label={t('Market segment')}
							value={values.marketSegment}
							error={errors.marketSegment}
							touched={touched.marketSegment}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadMarketSegmentOptions}
							isRequired
							menuPosition="fixed"
							menuPlacement="bottom"
						/>
					</FormField>
				</FieldsWrapper>
				<FieldsWrapper>
					<FormField>
						<FormikCheckbox
							id="usable"
							name="usable"
							label={t('Usable')}
							checked={values.usable}
							error={errors.usable}
							touched={touched.usable}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</FormField>
				</FieldsWrapper>
				<FieldsWrapper>
					<FormField>
						<FormikCheckbox
							id="restricted"
							name="restricted"
							label={t('Restricted')}
							checked={values.restricted}
							error={errors.restricted}
							touched={touched.restricted}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</FormField>
				</FieldsWrapper>
				<FieldsWrapper>
					<FormField>
						<FormikCheckbox
							id="excluded"
							name="excluded"
							label={t('Excluded')}
							checked={values.excluded}
							error={errors.excluded}
							touched={touched.excluded}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					</FormField>
				</FieldsWrapper>
				<ModalFormButtons
					onCancel={onCancel}
					isLoading={isLoading}
					label="Preferences Industries Form"
					submitButtonText={mode === crudModes.CREATE ? t('Create') : t('Save')}
				/>
			</Form>
		</>
	);
};

IndustriesForm.defaultProps = {
	data: null,
};

IndustriesForm.propTypes = {
	data: PropTypes.shape({}),
	mode: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
};

export default IndustriesForm;
