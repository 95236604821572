import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import getEpcPartnerInquires from 'EpcPartner/api/getEpcPartnerInquires';
import formatCurrency from 'Common/utils/formatCurrency';
import { push } from 'redux-first-history';
import ReactTimeAgo from 'react-time-ago';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import { useSortBy } from 'react-table';
import { useSortingQueryParameter } from 'Common/hooks/table/plugin-hooks/useSortingQueryParameter';
import { useScrollableTableBody } from 'Common/hooks/table/plugin-hooks/useScrollableTableBody';
import { DefaultTable } from 'Common/components/table/table';
import Link from 'Common/components/Link';
import colors from 'Application/theme/colors';
import formatDate from 'Common/utils/formatDate';
import StatusPill from 'Common/components/StatusPill';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import TableCellText from 'Common/components/table/TableCellText';
import showToastError from 'Common/utils/showToastError';
import axios from 'axios';
import { PropTypes } from 'prop-types';
import useUniqueAbortSignal from 'Common/hooks/useUniqueAbortSignal';
import projectTypes from 'Projects/constants/projectTypes';

const statusMap = {
	NEW: colors.success.main,
	OFFER_CREATED: colors.primary.main,
	DECLINED: colors.error.main,
};

const statusOptions = {
	NEW: 'Inquiry sent',
	OFFER_CREATED: 'Offer created',
	DECLINED: 'Declined',
};

const statusHoverTextOptions = {
	NEW: 'Inquiry has been sent to ecoligo for review',
	OFFER_CREATED:
		'Ecoligo has accepted your Inquiry. Click the Offer number to view the associated Offer. Ecoligo will contact you shortly.',
	DECLINED: 'Ecoligo have chosen not to proceed with your Inquiry.',
};

const Wrapper = styled.div`
	position: relative;
`;

const defaultSortBy = {
	id: 'lastModifiedDate',
	desc: true,
};

const sortPropertiesMap = {
	marketSegment: 'marketSegment.name',
	projectType: 'projectType.name',
};

const EpcInquiresListTable = () => {
	const { t } = useTranslation();

	const sortByQueryParam = useQueryParameter('sortBy');
	const sortBy = sortByQueryParam ?? defaultSortBy;

	const dispatch = useDispatch();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Status'),
				accessor: 'state',
				customBodyCellStyles: {
					paddingLeft: '4px',
				},
			},
			{
				Header: t('Offer'),
				accessor: 'epcOfferId',
				maxWidth: 100,
			},
			{
				Header: t('Country'),
				accessor: 'country',
			},
			{
				Header: t('Client'),
				accessor: 'clientName',
			},
			{
				Header: t('Industry'),
				accessor: 'industry',
			},

			{
				Header: t('Project type'),
				accessor: 'projectType',
			},
			{
				Header: t('System size (kWp)'),
				accessor: 'systemSizeKwp',
			},
			{
				Header: t('EPC price'),
				accessor: 'epcVolumeExclVat',
			},
			{
				Header: t('Updated'),
				accessor: 'lastModifiedDate',
				Cell: (() => {
					const Cell = ({ row: { original: inquiry } }) =>
						inquiry?.lastModifiedDate && !isNaN(new Date(inquiry?.lastModifiedDate)) ? (
							<TableCellText tooltipContent={formatDate(inquiry?.lastModifiedDate, 'EEEE dd/MM/yyyy HH:mm')}>
								<ReactTimeAgo date={new Date(inquiry?.lastModifiedDate)} timeStyle="round" tooltip={false} />
							</TableCellText>
						) : (
							'-'
						);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ lastModifiedDate: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
			},
		],
		[t],
	);

	const [inquiries, setInquiries] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const getUniqueSignal = useUniqueAbortSignal();

	useEffect(() => {
		(async () => {
			setIsLoading(true);

			const sortById = sortPropertiesMap[sortBy.id] ?? sortBy.id;

			try {
				const response = await getEpcPartnerInquires(getUniqueSignal('get-epc-inquiries'), {
					sortBy: sortById,
					sortDirection: sortBy.desc ? 'DESC' : 'ASC',
				});
				setInquiries(response.data.inquiries);
				setIsLoading(false);
			} catch (error) {
				showToastError(error, t("Can't fetch EPC partner inquiries"));
				if (!axios.isCancel(error)) {
					setInquiries([]);
					setIsLoading(false);
				}
			}
		})();
	}, [sortBy, getUniqueSignal, t]);

	// Re-structure the EPC Partners as table data
	const data = useMemo(
		() =>
			inquiries.map(inquiry => ({
				...inquiry,
				country: inquiry?.country?.name || '-',
				epcOfferId: (
					<Link
						color="#00b4d5"
						fontSize="14px"
						to={`/epc/offers/${inquiry?.epcOfferId}`}
						onClick={e => e.stopPropagation()}
					>
						{inquiry?.epcOfferId}
					</Link>
				),
				epcVolumeExclVat: inquiry?.epcVolumeExclVat
					? formatCurrency(inquiry.epcVolumeExclVat.value, 0, inquiry.epcVolumeExclVat.currency)
					: '-',
				clientName: inquiry?.clientName || '-',
				state: (
					<HoverTooltip placement="right" title={t(statusHoverTextOptions?.[inquiry?.state])} arrow>
						<StatusPill color={statusMap[inquiry?.state]}>{statusOptions?.[inquiry?.state]}</StatusPill>
					</HoverTooltip>
				),
				industry: inquiry?.industry?.name || '-',
				projectType: inquiry?.projectType?.fullName
					? `${inquiry.projectType?.fullName} (${inquiry.projectType?.name})`
					: '-',
				systemSizeKwp: inquiry?.projectType?.name === projectTypes.PV ? inquiry.systemSizeKwp : '-',
			})),
		[inquiries, t],
	);

	const handleRowClick = row => {
		if (!window.getSelection().toString()) {
			dispatch(push(`/epc/inquiries/${row.original.id}`));
		}
	};

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
				onRowClick: handleRowClick,
				defaultSortBy,
			},
			useClickableTableRow,
			useCustomCellStyles,
			useSortBy,
			useSortingQueryParameter,
			useScrollableTableBody,
		);

	return (
		<Wrapper>
			<DefaultTable
				getTableProps={getTableProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				rows={rows}
				headerGroups={headerGroups}
				prepareRow={prepareRow}
				isLoading={isLoading}
				showNoResultsFound
			/>
		</Wrapper>
	);
};

export default EpcInquiresListTable;
