import { FILTER_TYPES, FILTER_DEFAULTS } from 'Common/components/filter/constants/filterConstants';
import { serializeQueryStepData } from 'PMWorkflow/utils/convertStepFilterData';

const filterSerializers = {
	[FILTER_TYPES.NUMBER]: (q, f) => ({
		[f.minName]: q[f.minName] || f.defaultMinValue || FILTER_DEFAULTS[FILTER_TYPES.NUMBER].minValue,
		[f.maxName]: q[f.maxName] || f.defaultMaxValue || FILTER_DEFAULTS[FILTER_TYPES.NUMBER].maxValue,
	}),
	[FILTER_TYPES.DATE]: (q, f) => ({
		[f.startDateName]:
			q[f.startDateName] || f.defaultStartDateValue || FILTER_DEFAULTS[FILTER_TYPES.DATE].startDateValue,
		[f.endDateName]:
			q[f.endDateName] || f.defaultEndDateValue || FILTER_DEFAULTS[FILTER_TYPES.DATE].endDateValue,
	}),
	[FILTER_TYPES.STAGE]: (q, f) => ({
		[f.stageStatus]:
			q[f.stageStatus] || f.defaultStageStatus || FILTER_DEFAULTS[FILTER_TYPES.STAGE].stageStatus,
		[f.minName]: q[f.minName] || f.defaultMinValue || FILTER_DEFAULTS[FILTER_TYPES.STAGE].minValue,
		[f.maxName]: q[f.maxName] || f.defaultMaxValue || FILTER_DEFAULTS[FILTER_TYPES.STAGE].maxValue,
	}),
	[FILTER_TYPES.STEPS]: (q, f) => ({
		[f.name]: serializeQueryStepData(q[f.name]) || FILTER_DEFAULTS[FILTER_TYPES.STEPS],
	}),
	defaultSerializer: (q, f) => ({
		[f.name]: q[f.name] || f.defaultValue || FILTER_DEFAULTS[f.type],
	}),
};

const filterValueSerializer = (query, filter) => {
	const { type } = filter;
	const filterSerializer = filterSerializers[type] || filterSerializers.defaultSerializer;
	const serializedValues = filterSerializer(query, filter);
	return serializedValues;
};

export default filterValueSerializer;
