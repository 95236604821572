// TODO remove the eslint complexity and fix it
/* eslint-disable complexity */
import { pick } from 'lodash';
import projectTypes from 'Projects/constants/projectTypes';
import manufacturedProductTypes from 'Manufacturer/constants/manufacturedProductTypes';
import getProjectDefaults from 'Preferences/api/getProjectDefaults';
import Big from 'Common/utils/customBig';

const removeEmptyProps = data =>
	Object.fromEntries(Object.entries(data).filter(([, val]) => val !== '' && val !== null));

const cleanManufacturers = (projectType, energyStorage, values) => {
	let formattedValues = {};

	if (projectType === projectTypes.PV) {
		formattedValues = {
			[manufacturedProductTypes.SOLAR_MODULES]: values[manufacturedProductTypes.SOLAR_MODULES]?.value || '',

			[manufacturedProductTypes.INVERTERS]: values[manufacturedProductTypes.INVERTERS]?.value || '',

			[manufacturedProductTypes.MOUNTINGS]: values[manufacturedProductTypes.MOUNTINGS]?.value || '',

			[manufacturedProductTypes.BATTERIES]: values[manufacturedProductTypes.BATTERIES]?.value || '',

			[manufacturedProductTypes.LED_LUMINAIRES]: '',
			[manufacturedProductTypes.AC_UNITS]: '',
			[manufacturedProductTypes.SOLAR_HOT_WATER]: '',
		};

		if (!energyStorage) {
			formattedValues = {
				...formattedValues,
				[manufacturedProductTypes.BATTERIES]: '',
			};
		}
	} else if (projectType === projectTypes.EE_AC) {
		formattedValues = {
			[manufacturedProductTypes.LED_LUMINAIRES]: '',
			[manufacturedProductTypes.AC_UNITS]: values[manufacturedProductTypes.AC_UNITS]?.value || '',
			[manufacturedProductTypes.SOLAR_MODULES]: '',
			[manufacturedProductTypes.BATTERIES]: '',
			[manufacturedProductTypes.INVERTERS]: '',
			[manufacturedProductTypes.MOUNTINGS]: '',
			[manufacturedProductTypes.SOLAR_HOT_WATER]: '',
		};

		if (!energyStorage) {
			formattedValues = {
				...formattedValues,
				[manufacturedProductTypes.BATTERIES]: '',
			};
		}
	} else if (projectType === projectTypes.EE_LED) {
		formattedValues = {
			[manufacturedProductTypes.LED_LUMINAIRES]: values[manufacturedProductTypes.LED_LUMINAIRES]?.value || '',
			[manufacturedProductTypes.AC_UNITS]: '',
			[manufacturedProductTypes.SOLAR_MODULES]: '',
			[manufacturedProductTypes.BATTERIES]: '',
			[manufacturedProductTypes.INVERTERS]: '',
			[manufacturedProductTypes.MOUNTINGS]: '',
			[manufacturedProductTypes.SOLAR_HOT_WATER]: '',
		};
	} else if (projectType === projectTypes.EE_SHW) {
		formattedValues = {
			[manufacturedProductTypes.LED_LUMINAIRES]: '',
			[manufacturedProductTypes.AC_UNITS]: '',
			[manufacturedProductTypes.SOLAR_MODULES]: '',
			[manufacturedProductTypes.BATTERIES]: '',
			[manufacturedProductTypes.INVERTERS]: '',
			[manufacturedProductTypes.MOUNTINGS]: '',
			[manufacturedProductTypes.SOLAR_HOT_WATER]: values[manufacturedProductTypes.SOLAR_HOT_WATER]?.value || '',
		};

		if (!energyStorage) {
			formattedValues = {
				...formattedValues,
				[manufacturedProductTypes.BATTERIES]: '',
			};
		}
	} else {
		formattedValues = {
			[manufacturedProductTypes.LED_LUMINAIRES]: values[manufacturedProductTypes.LED_LUMINAIRES]?.value || '',
			[manufacturedProductTypes.AC_UNITS]: values[manufacturedProductTypes.AC_UNITS]?.value || '',
			[manufacturedProductTypes.SOLAR_MODULES]: values[manufacturedProductTypes.SOLAR_MODULES]?.value || '',
			[manufacturedProductTypes.BATTERIES]: values[manufacturedProductTypes.BATTERIES]?.value || '',
			[manufacturedProductTypes.INVERTERS]: values[manufacturedProductTypes.INVERTERS]?.value || '',
			[manufacturedProductTypes.MOUNTINGS]: values[manufacturedProductTypes.MOUNTINGS]?.value || '',
		};
		if (!energyStorage) {
			formattedValues = {
				...formattedValues,
				[manufacturedProductTypes.BATTERIES]: '',
			};
		}
	}
	return formattedValues;
};

const convertFormValuesToRequestData = async (signal, values) => {
	const projectType = values.projectType ? values.projectType.label : null;

	const isOnGrid = values.grid && projectType === projectTypes.PV && values.grid === 'on-grid';

	const hasGensets = !!values.gensets && projectType === projectTypes.PV;

	const hasRoofMounting =
		values.mountingTypes && projectType === projectTypes.PV && values.mountingTypes.length > 0
			? values.mountingTypes.filter(option => ['Roof', 'Carpark'].includes(option.label)).length > 0
			: false;

	const defaultDegradationRate = await getProjectDefaults(signal).then(res => res.data.pvDegradationRate);
	return {
		...pick(values, ['gensets', 'availableSpace']),
		pvDegradationRate:
			projectType === projectTypes.PV && !isNaN(Number(values.pvDegradationRate))
				? Big(values.pvDegradationRate).div(100)
				: defaultDegradationRate,
		predictedAnnualEnergySavings:
			values.predictedAnnualEnergySavings !== '' &&
			values.predictedAnnualEnergySavings !== null &&
			values.predictedAnnualEnergySavings !== undefined &&
			projectType !== projectTypes.PV
				? Number(values.predictedAnnualEnergySavings)
				: null,
		mountingTypes: [],
		onGrid: isOnGrid,
		gensets: hasGensets,
		projectTypeId: values.projectType ? values.projectType.value : null,
		projectType: {
			name: values.projectType.label,
			id: values.projectType.value,
		},
		systemSizeKwp: values.systemSizeKwp,

		meterQuantity: isOnGrid && projectType === projectTypes.PV ? values.meterQuantity : null,

		connectionPoints: isOnGrid && projectType === projectTypes.PV ? values.connectionPoints : null,

		generatorSize: hasGensets && projectType === projectTypes.PV ? values.generatorSize : null,

		generatorQuantity: hasGensets && projectType === projectTypes.PV ? values.generatorQuantity : null,

		roofType: hasRoofMounting
			? {
					id: values?.roofType?.value,
					name: values?.roofType?.label,
			  }
			: null,

		roofTypeId: hasRoofMounting ? values?.roofType?.value : null,

		energyStorage: projectType === projectTypes.PV ? values.energyStorage : false,

		kwhToKwpRel: values.kwhToKwpRel && projectType === projectTypes.PV ? values.kwhToKwpRel : '',

		solarUtilisationRate: projectType === projectTypes.PV ? values.solarUtilisationRate : null,

		batteryCapacityInKwh:
			values.energyStorage && projectType === projectTypes.PV ? values.batteryCapacityInKwh : '',
		description: values.description ? values.description : '',
		batterySizeInKw: values.energyStorage && projectType === projectTypes.PV ? values.batterySizeInKw : '',
		availableSpace: projectType === projectTypes.PV ? values.availableSpace : '',
		gridEnergyTariffPerKwh:
			values.gridEnergyTariffPerKwh !== null && values.gridEnergyTariffPerKwh !== '' && values.currency
				? {
						currency: values.currency.value,
						value: values.gridEnergyTariffPerKwh,
				  }
				: null,

		manufacturerByProductType: {
			...removeEmptyProps(cleanManufacturers(projectType, values.energyStorage, values)),
		},
	};
};

export default convertFormValuesToRequestData;
