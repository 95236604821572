import api from 'Application/api/api';
import adaptProjectResponseData from 'Projects/api/adapters/adaptProjectResponseData';

const unassignClientFromProject = async (signal, projectId) => {
	const response = await api.post(`/projects/unassign-client/${projectId}`, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptProjectResponseData(response.data),
	};
};

export default unassignClientFromProject;
