import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import crudModes from 'Common/constants/crudModes';
import CustomSubmitForm from 'Preferences/components/Tabs/CampaignsTab/SpvBalances/CustomSubmitForm';
import { PropTypes } from 'prop-types';

const CreateModal = ({ isOpen, onClose, onFormSubmit }) => {
	const { t } = useTranslation();

	return (
		<ModalForm
			label="spv-balance-create"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			heading={t('Create SPV Balance')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<CustomSubmitForm
					mode={crudModes.CREATE}
					onCancel={onCancel}
					onSubmit={handleSubmit}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

CreateModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default CreateModal;
