const CLIENT_DETAILS_PAGE_TAB_TILES = {
	GENERAL: {
		DETAILS: 'details',
		ADDRESS: 'address',
	},
	FINANCIAL: {
		DUE_DILIGENCE: 'dueDiligence',
	},
};

const CLIENT_DETAILS_PAGE_TILES_BY_TAB = {
	GENERAL: Object.values(CLIENT_DETAILS_PAGE_TAB_TILES.GENERAL),
	FINANCIAL: Object.values(CLIENT_DETAILS_PAGE_TAB_TILES.FINANCIAL),
};

export { CLIENT_DETAILS_PAGE_TAB_TILES, CLIENT_DETAILS_PAGE_TILES_BY_TAB };
