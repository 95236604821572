import api from 'Application/api/api';

const updateUser = (signal, values) =>
	api.put(
		'/users',
		{
			...values,
			profilePhoto: values.profilePhoto || undefined,
		},
		{
			signal,
		},
	);

export default updateUser;
