import api from 'Application/api/api';

const getPresignedUrlForView = async (signal, fileName, versionId) => {
	const response = await api.get(`/buckets/view-link?fileName=${fileName}&versionId=${versionId}`, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
	};
};

export default getPresignedUrlForView;
