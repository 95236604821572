import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import crudModes from 'Common/constants/crudModes';
import IndustriesForm from './IndustriesForm/IndustriesForm';
import { PropTypes } from 'prop-types';

const IndustriesCreateModal = ({ isOpen, onClose, onFormSubmit }) => {
	const { t } = useTranslation();

	return (
		<ModalForm
			label="industry-create"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			heading={t('Create Industry')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<IndustriesForm
					mode={crudModes.CREATE}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

IndustriesCreateModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default IndustriesCreateModal;
