import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Overlay, { useOverlayTitles } from 'Common/components/modals/Overlay';
import SectionContainer from 'Common/components/modals/SectionContainer';
import EpcPartnerForm from './EpcPartnerForm';
import { useMemo } from 'react';

const EpcPartnerOverlay = ({ data, mode, onFormSubmit, isOpen, onClose, breadcrumbList }) => {
	const { t } = useTranslation();
	const { name, label } = useOverlayTitles(t('EPC Partner'), mode);

	const epcPartnerBreadcrumbList = useMemo(
		() =>
			breadcrumbList || [
				{
					label: 'EPC Partners',
					href: '/epc-partners',
				},
				{
					label: name,
				},
			],
		[name, breadcrumbList],
	);

	return (
		<Overlay
			label={label}
			breadcrumbList={epcPartnerBreadcrumbList}
			isOpen={isOpen}
			onClose={onClose}
			onSave={onFormSubmit}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<SectionContainer name="EPC Partner">
					<EpcPartnerForm
						data={data}
						mode={mode}
						onSubmit={handleSubmit}
						onCancel={onCancel}
						onDirty={setIsFormDirty}
					/>
				</SectionContainer>
			)}
		</Overlay>
	);
};

EpcPartnerOverlay.defaultProps = {
	data: null,
	onDirty: () => null,
	breadcrumbList: undefined,
};

EpcPartnerOverlay.propTypes = {
	data: PropTypes.shape({}),
	mode: PropTypes.string.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	breadcrumbList: PropTypes.arrayOf(
		PropTypes.shape({ label: PropTypes.string.isRequired, href: PropTypes.string }),
	),
};

export default EpcPartnerOverlay;
