import PropTypes from 'prop-types';
import crudModes from 'Common/constants/crudModes';
import ModalForm from 'Common/components/form/ModalForm';
import UtilityRateForm from 'Client/components/Form/UtilityRateForm';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line
const UtilityRateModal = ({ countryId, utilityRateForEditing, isOpen, onClose, onFormSubmit }) => {
	const { t } = useTranslation();
	const mode = utilityRateForEditing ? crudModes.EDIT : crudModes.CREATE;
	return (
		<ModalForm
			label="utility-rate-create"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			heading={t('Create Grid Utility Rate')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<UtilityRateForm
					countryId={countryId}
					utilityRateForEditing={utilityRateForEditing}
					mode={mode}
					onCancel={onCancel}
					onSubmit={handleSubmit}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};
UtilityRateModal.defaultProps = {
	isOpen: false,
	onClose: () => {},
	onFormSubmit: () => {},
	utilityRateForEditing: '',
};
UtilityRateModal.propTypes = {
	client: PropTypes.shape({
		gridUtilityRates: PropTypes.arrayOf({}),
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}).isRequired,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onFormSubmit: PropTypes.func,
	utilityRateForEditing: PropTypes.string,
};

export default UtilityRateModal;
