import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import styled from 'styled-components/macro';

import getPlatforms from 'Preferences/api/getPlatforms';
import deletePlatform from 'Preferences/api/deletePlatform';

import PlatformsCreateModal from './PlatformsCreateModal';
import PlatformsEditModal from './PlatformsEditModal';

import EditableTile from 'Common/components/Tile/EditableTile';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { DefaultTable, TableHeadCell, TableBodyCell } from 'Common/components/table/table';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}
	${TableBodyCell} {
		background: transparent;
	}
`;

const PlatformsList = () => {
	const { t } = useTranslation();

	const [platforms, setPlatforms] = useState([]);

	const [platformIdForEditing, setPlatformIdForEditing] = useState(null);

	const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

	const [platformIdForDeletion, setPlatformIdForDeletion] = useState(null);

	const [deletionError, setDeletionError] = useState(null);

	const [isLoadingContent, setIsLoadingContent] = useState(false);
	const abortController = useAbortController();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Name'),
				accessor: 'name',
			},
			{
				Header: '',
				accessor: 'actionDots',
				maxWidth: 70,
				customCellStyles: {
					justifyContent: 'flex-end',
				},
				customBodyCellContentStyles: {
					padding: '5px',
				},
			},
		],
		[t],
	);

	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			platforms.map(platform => ({
				...platform,
				name: platform?.name || '-',
				actionDots: (
					<MoreActionsButton label="Preference Campaigns Platforms More Actions" controlledVisibility>
						<MenuItem
							data-action="edit"
							onClick={() => setPlatformIdForEditing(platform.id)}
							label="Preference Campaigns Platforms - Edit Menu Item"
						>
							Edit
						</MenuItem>
						<MenuItem
							data-action="delete"
							onClick={() => setPlatformIdForDeletion(platform.id)}
							label="Preference Campaigns Platforms - Delete Menu Item"
						>
							Delete
						</MenuItem>
					</MoreActionsButton>
				),
			})),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[platforms],
	);

	useEffect(() => {
		(async () => {
			try {
				setIsLoadingContent(true);
				const response = await getPlatforms(abortController.signal);

				setIsLoadingContent(false);
				setPlatforms(response.data);
			} catch (error) {
				showToastError(error);

				if (!axios.isCancel(error)) {
					setIsLoadingContent(false);
				}
			}
		})();
	}, [abortController.signal]);

	const handleDataUpdate = data => {
		setPlatforms(data);
	};

	const handleDeletePlatform = async id => {
		try {
			await deletePlatform(abortController.signal, id);

			notify(t('Platform deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});

			const platformsResponse = await getPlatforms(abortController.signal);
			handleDataUpdate(platformsResponse.data);
			setPlatformIdForDeletion(null);
		} catch (error) {
			if (!axios.isCancel(error)) {
				setDeletionError(error.response.data);
				setPlatformIdForDeletion(null);
			}
		}
	};

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
			},
			useCustomCellStyles,
		);

	const platformForDeletion = platformIdForDeletion
		? platforms.find(platform => platform.id === platformIdForDeletion)
		: null;

	const hasPlatformForDeletion = !!platformForDeletion && !!platformIdForDeletion;

	return (
		<EditableTile
			title={t('Cofunding platforms')}
			rightComponent={
				<TileActionButton data-button="cofundingPlatforms" onClick={() => setIsCreateModalOpened(true)} />
			}
			isLoadingContent={isLoadingContent}
			isTable
		>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
				/>
			</TableWrapper>
			<PlatformsCreateModal
				isOpen={isCreateModalOpened}
				onClose={() => setIsCreateModalOpened(false)}
				onFormSubmit={handleDataUpdate}
			/>

			{platformIdForEditing !== null && (
				<PlatformsEditModal
					isOpen
					onClose={() => setPlatformIdForEditing(null)}
					onFormSubmit={handleDataUpdate}
					platformId={platformIdForEditing}
				/>
			)}

			{hasPlatformForDeletion && (
				<ConfirmModal
					isOpen
					onCancel={() => setPlatformIdForDeletion(null)}
					onConfirm={() => handleDeletePlatform(platformIdForDeletion)}
					label="delete-platform-confirm"
					heading={t('Delete Platform')}
					text={t(`Are you sure you want to delete Platform ${platformForDeletion.name}?`)}
				/>
			)}

			<UnsuccessfulOperationModal
				label="cannot-delete-platform-confirm"
				isOpen={deletionError !== null}
				onCancel={() => setDeletionError(null)}
				heading={t('Cannot delete platform')}
				text={deletionError?.msg}
			/>
		</EditableTile>
	);
};

export default PlatformsList;
