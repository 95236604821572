import { useMemo } from 'react';

const fallbackValues = {
	epc: null,
	saas: null,
	transmittedLoad: null,
};

const useProjectEntitiesFormInitialValues = data =>
	useMemo(() => {
		const initialValues = { ...fallbackValues };

		if (!data.contractPartyObjectByType) {
			return initialValues;
		}

		return {
			...initialValues,
			epc: data.contractPartyObjectByType.EPC
				? {
						value: data.contractPartyObjectByType.EPC.id,
						label: data.contractPartyObjectByType.EPC.name,
						abbreviation: data.contractPartyObjectByType.EPC.abbreviation,
				  }
				: null,
			saas: data.contractPartyObjectByType.SaaS
				? {
						value: data.contractPartyObjectByType.SaaS.id,
						label: data.contractPartyObjectByType.SaaS.name,
				  }
				: null,
			transmittedLoad: data.contractPartyObjectByType['Transmitted Load']
				? {
						value: data.contractPartyObjectByType['Transmitted Load'].id,
						label: data.contractPartyObjectByType['Transmitted Load'].name,
				  }
				: null,
		};
	}, [data]);

export default useProjectEntitiesFormInitialValues;
