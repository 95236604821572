import formatDateForRequestData from 'Common/utils/formatDateForRequestData';
import { PaymentGuaranteeTypes } from 'Projects/constants/paymentGuaranteeOptions';

// eslint-disable-next-line complexity
const convertClientContractValuesToRequestData = (values, project) => ({
	systemSize: values.systemSize,
	mountingTypes: values?.mountingTypes?.map(mountingType => ({ mountingType: mountingType.value })) || [],
	epcPartnerId: values?.epcPartner ? values.epcPartner?.value : null,
	epcVolume: values.epcVolume
		? {
				value: values.epcVolume,
				currency: values?.currency?.value,
		  }
		: null,
	currency: values.currency ? values.currency.value : null,
	yield: values.yield,
	p90: values.p90,
	solarPvDegradation: values.solarPvDegradation ? values.solarPvDegradation : null,
	annualOmPrice: values.annualOmPrice
		? {
				value: values.annualOmPrice,
				currency: values.currency.value,
		  }
		: null,
	annualOmEscalationRate: values.annualOmEscalationRate ? values.annualOmEscalationRate : null,
	extraCapexExpected: values.extraCapexExpected,
	extraOpexExpected: values.extraOpexExpected,
	paymentGuaranteeType: values.paymentGuaranteeType?.value
		? {
				paymentGuaranteeType: values.paymentGuaranteeType?.value,
				displayName: values.paymentGuaranteeType?.label,
		  }
		: null,
	paymentGuaranteeAmount: values.paymentGuaranteeAmount
		? { value: values.paymentGuaranteeAmount, currency: values.currency.value }
		: null,
	paymentGuaranteeDetails:
		values?.paymentGuaranteeType && values?.paymentGuaranteeType?.value === PaymentGuaranteeTypes.OTHER
			? values.paymentGuaranteeDetails
			: null,

	contractTypeId: values.contractType ? values.contractType?.value : null,
	saasCurrency: values.saasCurrency ? values.saasCurrency?.value : null,
	monthlyLeaseRate: values.monthlyLeaseRate
		? {
				value: values.monthlyLeaseRate,
				currency: values.saasCurrency.value,
		  }
		: null,
	ppaTariffType: values.ppaTariffType
		? {
				ppaTariffType: values.ppaTariffType.value,
				displayName: values.ppaTariffType.label,
		  }
		: null,
	ppaPricePerKwh: values.ppaPricePerKwh
		? {
				value: values.ppaPricePerKwh,
				currency: values.saasCurrency.value,
		  }
		: null,
	percentageOnGridTariff: values.percentageOnGridTariff ? values.percentageOnGridTariff : null,
	floorTariff: values.floorTariff
		? {
				value: values.floorTariff,
				currency: values.saasCurrency.value,
		  }
		: null,
	ceilingTariff: values.ceilingTariff
		? {
				value: values.ceilingTariff,
				currency: values.saasCurrency.value,
		  }
		: null,
	escalationRateInPercent: values.escalationRateInPercent ? values.escalationRateInPercent : null,
	contractDuration: values.contractDuration,
	clientUpfrontPayment: values.clientUpfrontPayment
		? {
				value: values.clientUpfrontPayment,
				currency: values.saasCurrency.value,
		  }
		: null,
	solarUtilizationRate: values.solarUtilizationRate ? values.solarUtilizationRate : null,
	minimumPurchaseAmount: values.minimumPurchaseAmount ? values.minimumPurchaseAmount : null,
	discountOnGridTariff: values.discountOnGridTariff ? values.discountOnGridTariff : null,
	billingPeriod: values.billingPeriod,

	contractPartyId: values.contractParty ? values.contractParty.value : null,
	saasVersion: values.saasVersion,
	longstop: values.longstop ? formatDateForRequestData(values.longstop) : undefined,
	handoverPrice: values.handoverPrice
		? {
				value: values.handoverPrice,
				currency: values.saasCurrency.value,
		  }
		: null,
	bankAccountId: values.bankAccount ? values.bankAccount.value : null,
	easementFee: values.easementFee
		? {
				value: values.easementFee,
				currency: values.saasCurrency.value,
		  }
		: null,
	minimumWarrantedFunctionality: values.minimumWarrantedFunctionality
		? values.minimumWarrantedFunctionality
		: null,

	projectedIrr: values.projectedIrr ? values.projectedIrr : null,
	worstCaseIrr: values.worstCaseIrr ? values.worstCaseIrr : null,
	saasSignatureDate: values.saasSignatureDate ? formatDateForRequestData(values.saasSignatureDate) : undefined,
	contractNumber: values.contractNumber,
	minimumDscr: values.minimumDscr,
	averageDscr: values.averageDscr,

	projectId: project.id,
});

export default convertClientContractValuesToRequestData;
