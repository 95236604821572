import { useMemo } from 'react';
import parseISO from 'date-fns/parseISO';

const fallbackValues = {
	initialEngagementDate: null,
	salesChannel: null,
};

const useEngagementFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			salesChannel: data.salesChannel
				? {
						value: data.salesChannel.id,
						label: data.salesChannel.name,
				  }
				: null,
			initialEngagementDate: data.initialEngagementDate ? parseISO(data.initialEngagementDate) : null,
		}),
		[data],
	);

export default useEngagementFormInitialValues;
