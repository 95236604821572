/* eslint-disable react/prop-types */
import Grid from '@mui/material/Grid';
import Icon from 'Common/components/icons/Icon';
import formatNumberWithoutFixing from 'Common/utils/formatNumberWithoutFixing';
import { getFiltersSerializer } from 'Impact/utils/getFiltersEncodedUrl';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { stringify } from 'qs';

import styled from 'styled-components/macro';
import { css } from 'styled-components';
import colors from 'Application/theme/colors';

const IconContainer = styled.div`
	width: 43px !important;
	height: 43px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: flex-start;
	flex: 0 0 auto;
	position: relative;
	z-index: 10;
`;

const BackgroundWrapper = styled.div`
	width: 43px !important;
	height: 43px;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 14px;
	z-index: 5;
	background: ${({ $gradient }) => $gradient};
`;

const ShadowWrapper = styled.div`
	content: '';
	position: absolute;
	box-shadow: 0px 0px 15px 10px rgba(42, 164, 188, 0.25);
	bottom: 0px;
	left: 25%;
	right: 25%;

	width: 50%;
	height: 50%;
	-moz-border-radius: 100%;
	border-radius: 100%;
	z-index: 1;
`;

const IconWrapper = styled.div`
	z-index: 10;
`;

const InfoTextWrapper = styled.div`
	font-size: ${props => props.fontSize || '30px'};
	line-height: ${props => props.lineHeight || '35px'};
	font-weight: ${props => props.fontWeight || 600};
	color: ${props => props.color || colors.common.black};
	overflow: visible;
	white-space: nowrap;
`;

const InfoTextSmall = styled.div`
	line-height: ${props => props.lineHeight || '25px'};
	font-weight: ${props => props.fontWeight || 500};
	color: ${props => props.color || colors.common.black};
	font-size: 16px;
	transform: translateY(-8px);
	overflow: visible;
	white-space: nowrap;
`;

const TextWrapper = styled.div`
	display: inline-block;
	padding-left: 6px;
`;

const UnitWrapper = styled.div`
	display: inline-block;
	padding: 0px 0px 6px 5px;
`;

const StyledGrid = styled(Grid)`
	padding-left: 4px;
`;

const ItemContainer = styled.div`
	${({ $fullWidth }) => css`
		width: ${$fullWidth ? 'inherit' : '190px'};
		height: 60px;
		display: flex;
		flex-direction: row;
	`}

	${({ $isLink }) =>
		$isLink &&
		css`
			&:hover * {
				color: ${colors.primary.dark};
				cursor: pointer;
			}
		`}
`;

const InfoText = ({ item, gradient, omitValueFormat, fullWidth }) => {
	const dispatch = useDispatch();

	const redirectWithFilter = filterRedirect => {
		if (filterRedirect) {
			const searchQuery = stringify(filterRedirect, {
				arrayFormat: 'indices',
				allowDots: true,
				encoder: getFiltersSerializer.bind(null, Object.keys(filterRedirect)),
			});
			dispatch(push(`/projects?${encodeURI(searchQuery)}`));
		}
	};

	return (
		<ItemContainer
			$isLink={item?.filterRedirect}
			$fullWidth={fullWidth}
			onClick={() => redirectWithFilter(item?.filterRedirect)}
		>
			{item?.icon && (
				<IconContainer>
					<IconWrapper>
						<Icon icon={item.icon} color={colors.common.white} />
					</IconWrapper>
					<BackgroundWrapper $gradient={item?.gradient || gradient}></BackgroundWrapper>
					<ShadowWrapper></ShadowWrapper>
				</IconContainer>
			)}
			<StyledGrid container justifyContent="flex-start" alignItems="flex-start">
				<Grid container justifyContent="flex-start" alignItems="flex-end">
					{item.prefix && (
						<TextWrapper>
							<InfoTextWrapper>{item.prefix}</InfoTextWrapper>
						</TextWrapper>
					)}
					<TextWrapper>
						<InfoTextWrapper>
							{omitValueFormat ? item.value : formatNumberWithoutFixing(item.value)}
						</InfoTextWrapper>
					</TextWrapper>
					<UnitWrapper>
						<InfoTextWrapper fontSize={'16px'} fontWeight={700} lineHeight={'10px'}>
							{item.unit}
						</InfoTextWrapper>
					</UnitWrapper>
				</Grid>
				<Grid container justifyContent="flex-start" alignItems="flex-start">
					<TextWrapper>
						<InfoTextSmall color={colors.common.grey} fontWeight={500}>
							{item.text}
						</InfoTextSmall>
					</TextWrapper>
				</Grid>
			</StyledGrid>
		</ItemContainer>
	);
};

export default InfoText;
