import axios from 'axios';
import { toast } from 'react-toastify';
import notify from './notify';
import i18n from 'Application/i18n';
import customGAException from 'Common/utils/customGAException';

/**
 * Displays error toast
 * @param {*} error - error catched
 * @param {*} errorMessage - custom error message (optional)
 */
const showToastError = (error, errorMessage) => {
	const { message, toastId } = (() => {
		let toastId, message;

		// Don't return error message if status code is 401 and data error is 'Unauthorized' - when session is expired
		// If data error is 'Unauthenticated' or undefined the error should be displayed, e.g. when user enter wrong email/password
		if (
			axios.isCancel(error) ||
			(error?.response?.status === 401 && error?.response?.data.error === 'Unauthorized')
		) {
			return {};
		}

		// Send error to Google Analytics
		customGAException(error.stack, false);

		if (!navigator.onLine) {
			message = i18n.t("There's no internet connection. Please try again later.");
			toastId = 'no-internet-connection';
		} else if (error.code === 'ECONNABORTED' && /^timeout of \d+ms exceeded$/.test(error.message)) {
			message = i18n.t('Request timed out. Please check your internet connection.');
			toastId = 'slow-internet-connection';
		} else {
			// When the backend returns an error message it is preferred to the custom FE message
			// If error "code" field is 'Unspecified', don't show the error message - it's no good to the user
			message = (error.response?.data?.code !== 'Unspecified' && error.response?.data?.msg) || errorMessage;
		}

		// Fallback message
		if (!message) {
			message = i18n.t('Something went wrong');
			toastId = 'something-went-wrong';
		}

		return { message, toastId };
	})();

	message &&
		notify(message, {
			type: toast.TYPE.ERROR,
			toastId, // Prevent duplicate toast messages when there's no internet connection or custom error message
		});
};

export default showToastError;
