import { parseISO } from 'date-fns';
import { useMemo } from 'react';

const fallbackValues = {
	pin: '',
	companyName: '',
	abbreviation: '',
	dateOfRegistration: null,
	registrationNumber: '',
	taxNumber: '',
	vatNumber: '',
	secretary: null,
	accountingStandard: null,
	assetAndRevenueRecognition: null,
	placeOfIncorporation: '',
};

const useEntityFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			companyName: data?.name,
			abbreviation: data?.abbreviation,
			dateOfRegistration: data.dateOfRegistration ? parseISO(data.dateOfRegistration) : null,
			registrationNumber: data?.registrationNumber,
			taxNumber: data?.taxNumber,
			vatNumber: data?.vatNumber,
			pin: data?.pin,
			placeOfIncorporation: data?.placeOfIncorporation,
			secretary: data?.secretaries?.[0]
				? {
						...data?.secretaries?.[0],
						value: data?.secretaries?.[0]?.email,
						label: data?.secretaries?.[0]?.fullName,
				  }
				: null,
			accountingStandard: data?.accountingStandard
				? {
						value: data?.accountingStandard?.id,
						label: data?.accountingStandard?.name,
				  }
				: null,
			assetAndRevenueRecognition: data?.assetAndRevenueRecognition
				? {
						value: data?.assetAndRevenueRecognition?.id,
						label: data?.assetAndRevenueRecognition?.name,
				  }
				: null,
		}),
		[data],
	);

export default useEntityFormInitialValues;
