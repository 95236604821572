import Button from 'Common/components/buttons/Button';
import PropTypes from 'prop-types';

const TileActionButton = ({ icon, ...rest }) => (
	<Button {...rest} small icon={icon} label="Tile Right - Create Button" />
);

TileActionButton.defaultProps = {
	icon: 'addRounded',
};
TileActionButton.propTypes = {
	icon: PropTypes.string,
};

export default TileActionButton;
