import api from 'Application/api/api';

const archieveNotification = async (signal, id) =>
	await api.put(
		'/notifications/set-archived',
		{
			id,
			archived: true,
		},
		{
			signal,
		},
	);

export default archieveNotification;
