import { css } from 'styled-components';

const sizes = {
	spacing: factor => 8 * (factor ? factor : 1) + 'px',
	base: factor => 4 * (factor ? factor : 1) + 'px',
	lineHeight: {
		title: '44px',
		big: '24px',
		main: '20px',
		small: '17px',
	},
	fontSize: {
		big: css`
			font-size: 18px;
			@media (max-width: 600px) {
				font-size: 14px;
			}
		`,
		main: css`
			font-size: 16px;
			@media (max-width: 600px) {
				font-size: 12px;
			}
		`,
		small: css`
			font-size: 14px;
			@media (max-width: 600px) {
				font-size: 12px;
			}
		`,
		tiny: css`
			font-size: 12px;
			@media (max-width: 600px) {
				font-size: 10px;
			}
		`,
	},
};

export default sizes;
