import { useMemo } from 'react';
import { pick } from 'lodash';

const fallbackValues = {
	trancheSetupCorrectly: false,
	vibApproved: false,
	documentsAvailableForDownload: false,
	frontendReviewPassed: false,
	projectPassedPortagonPlausibility: false,
	externalId: '',
};

const useStatusFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data.finance_details, [
				'frontendReviewPassed',
				'documentsAvailableForDownload',
				'vibApproved',
				'trancheSetupCorrectly',
				'projectPassedPortagonPlausibility',
			]),
			externalId: data.id,
		}),
		[data],
	);

export default useStatusFormInitialValues;
