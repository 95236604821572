import { Tooltip as MaterialTooltip } from '@mui/material';
import { css } from 'styled-components';
import styled from 'styled-components';

import colors from 'Application/theme/colors';

import { PropTypes } from 'prop-types';

const arrowPlacements = {
	start: css`
		left: 20px !important;
	`,
	end: css`
		right: 20px !important;
	`,
};

const StyledTooltip = styled(({ className, ...props }) => (
	<MaterialTooltip {...props} classes={{ popper: className }} />
))`
	& .MuiTooltip-tooltip {
		background: ${colors.text.black};
		color: ${colors.common.white};
		border-radius: 6px;
		white-space: pre-line;
	}

	& .MuiTooltip-arrow {
		color: ${colors.text.black};

		${({ arrowplacement }) => arrowPlacements[arrowplacement]}
	}
`;

const HoverTooltip = ({ title, arrow, placement, children, arrowplacement, ...props }) => (
	<StyledTooltip
		arrow={!!arrow}
		arrowplacement={arrowplacement}
		title={title || ''}
		placement={placement}
		{...props}
	>
		<div>{children}</div>
	</StyledTooltip>
);

HoverTooltip.defaultProps = {
	title: '',
	arrow: true,
	placement: 'right',
	arrowplacement: undefined,
};

HoverTooltip.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	arrow: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	placement: PropTypes.oneOf([
		'bottom-end',
		'bottom-start',
		'bottom',
		'left-end',
		'left-start',
		'left',
		'right-end',
		'right-start',
		'right',
		'top-end',
		'top-start',
		'top',
	]),
	children: PropTypes.node.isRequired,
	arrowplacement: PropTypes.string,
};

export default HoverTooltip;
