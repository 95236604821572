import { useMemo } from 'react';
import parseISO from 'date-fns/parseISO';

const fallbackValues = {
	value: '',
	date: '',
	emissionSPV: '',
};

const useCustomSubmitFormInitialValues = data => {
	return useMemo(
		() => ({
			...fallbackValues,
			value: data?.value || fallbackValues.value,
			date: data?.date ? parseISO(data?.date) : fallbackValues.date,
			emissionSPV: data?.emissionSPV
				? {
						value: data?.emissionSPV.id,
						label: data?.emissionSPV.name,
				  }
				: null,
		}),
		[data],
	);
};

export default useCustomSubmitFormInitialValues;
