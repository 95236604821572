import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useEngagementFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				initialEngagementDate: yup.date(t('Invalid date')).required(t('Required')).nullable(),
			}),
		[t],
	);
};

export default useEngagementFormValidationSchema;
