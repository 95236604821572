import { useState } from 'react';
import styled from 'styled-components/macro';
import Thumbnail from 'Common/components/Thumbnail';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import ImageModal from 'Projects/components/ProjectDetails/Tabs/ImagesTab/ImageModal';
import colors from 'Application/theme/colors';
import { PropTypes } from 'prop-types';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const Gallery = styled.div`
	position: relative;
	padding: 20px;
	width: 100%;

	&:focus {
		outline: none;
	}
`;

const ThumbnailContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	${({ isDraggedOver }) =>
		isDraggedOver &&
		css`
			opacity: 0.5;
		`}
`;

const EmptyGalleryText = styled.div`
	position: absolute;
	height: 48vh;
	width: 98%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 10px;
	top: 20px;
	font-weight: 600;
	color: ${colors.text.white};
`;

const ImagesTab = ({ projectId, images }) => {
	const { t } = useTranslation();
	const [openedImageInModal, setOpenedImageInModal] = useState(null);

	return (
		<Wrapper>
			<Gallery onClick={e => e.stopPropagation()}>
				{!images || images?.length === 0 ? (
					<EmptyGalleryText>
						<span>{t('There are no images yet')}</span>
					</EmptyGalleryText>
				) : (
					<ThumbnailContainer>
						<>
							{images.map(image => (
								<Thumbnail
									key={image.id.name}
									image={image}
									onImageClick={setOpenedImageInModal}
									isSelectable={false}
								/>
							))}
						</>
					</ThumbnailContainer>
				)}
			</Gallery>

			{openedImageInModal && (
				<ImageModal
					isOpen={openedImageInModal !== null}
					onClose={() => setOpenedImageInModal(null)}
					openedImage={openedImageInModal}
					images={images}
					projectId={projectId}
					readOnly
					label="epc-partner-image-update"
				/>
			)}
		</Wrapper>
	);
};

ImagesTab.defaultProps = {
	projectId: null,
	images: [],
};

ImagesTab.propTypes = {
	projectId: PropTypes.number,
	images: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ImagesTab;
