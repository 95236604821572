// import useAbortController from 'Common/hooks/useAbortController';
import parseISO from 'date-fns/parseISO';
import { useEffect, useMemo, useState } from 'react';

const fallbackValues = {
	projectId: '',
	pctDate: '',
	pctRequiredPR: '',
	pctPRCalculationResult: '',
	pctForm: '',
	pctFormName: '',
	pctFormDisplayDocuments: [],
	pcdAmount: '',
	pcdCurrency: { label: 'EUR Euro', value: 'EUR' },
	pcdInvoiceForm: '',
	pcdInvoiceFormName: '',
	pcdInvoiceFormDisplayDocuments: [],
	pcdPayslipForm: '',
	pcdPayslipFormName: '',
	pcdPayslipFormDisplayDocuments: [],
	pcdComment: '',
	ms5PayslipForm: '',
	ms5PayslipFormName: '',
	ms5PayslipFormDisplayDocuments: [],
};

const usePostCompletionFormInitialValues = data => {
	const [pcdAmountValue, setPcdAmountValue] = useState(fallbackValues.pcdAmount);
	useEffect(() => {
		setPcdAmountValue(data?.postCompletion?.pcdAmount);
	}, [data?.postCompletion?.pcdAmount]);

	const [pctFormDocumentData, setPctFormDocumentData] = useState(
		data?.postCompletion?.pctCountersigneds?.slice(-1)[0],
	);
	useEffect(() => {
		setPctFormDocumentData(data?.postCompletion?.pctCountersigneds?.slice(-1)[0]);
	}, [data?.postCompletion?.pctCountersigneds]);
	const [pcdInvoiceFormDocumentData, setPcdInvoiceFormDocumentData] = useState(
		data?.postCompletion?.pcdInvoices?.slice(-1)[0],
	);
	useEffect(() => {
		setPcdInvoiceFormDocumentData(data?.postCompletion?.pcdInvoices?.slice(-1)[0]);
	}, [data?.postCompletion?.pcdInvoices]);

	const [pcdPayslipFormDocumentData, setPcdPayslipFormDocumentData] = useState(
		data?.postCompletion?.pcdPayslips?.slice(-1)[0],
	);
	useEffect(() => {
		setPcdPayslipFormDocumentData(data?.postCompletion?.pcdPayslips?.slice(-1)[0]);
	}, [data?.postCompletion?.pcdPayslips]);

	const [ms5PayslipFormDocumentData, setMs5PayslipFormDocumentData] = useState(
		data?.postCompletion?.ms5Payslips?.slice(-1)[0],
	);
	useEffect(() => {
		setMs5PayslipFormDocumentData(data?.postCompletion?.ms5Payslips?.slice(-1)[0]);
	}, [data?.postCompletion?.ms5Payslips]);

	return useMemo(
		() => ({
			projectId: data?.id ? data?.id : fallbackValues?.projectId,
			pctPassed: data?.postCompletion?.pctPassed,
			pctDate: data?.postCompletion
				? data?.postCompletion?.pctDate
					? parseISO(data?.postCompletion?.pctDate)
					: fallbackValues.pctDate
				: fallbackValues.pctDate,
			pctRequiredPR: data?.postCompletion ? data?.postCompletion?.pctRequiredPR : fallbackValues.pctRequiredPR,
			pctPRCalculationResult: data?.postCompletion
				? data?.postCompletion?.pctPRCalculationResult
				: fallbackValues.pctPRCalculationResult,
			pctForm: '',
			pctFormName: pctFormDocumentData?.displayName || fallbackValues.pctFormName,
			pctFormDisplayDocuments:
				data?.postCompletion?.pctCountersigneds || fallbackValues?.pctFormDisplayDocuments,

			pcdPaid: data?.postCompletion?.pcdPaid,
			pcdAmount: pcdAmountValue || fallbackValues.pcdAmount,
			pcdCurrency: fallbackValues?.pcdCurrency,
			pcdInvoiceForm: '',
			pcdInvoiceFormName: pcdInvoiceFormDocumentData?.displayName || fallbackValues.pcdInvoiceFormName,
			pcdInvoiceFormDisplayDocuments:
				data?.postCompletion?.pcdInvoices || fallbackValues?.pcdInvoiceFormDisplayDocuments,
			pcdPayslipForm: '',
			pcdPayslipFormName: pcdPayslipFormDocumentData?.displayName || fallbackValues.pcdPayslipFormName,
			pcdPayslipFormDisplayDocuments:
				data?.postCompletion?.pcdPayslips || fallbackValues?.pcdPayslipFormDisplayDocuments,

			ms5Paid: data?.postCompletion?.ms5Paid,
			ms5PayslipForm: '',
			ms5PayslipFormName: ms5PayslipFormDocumentData?.displayName || fallbackValues.ms5PayslipFormName,
			ms5PayslipFormDisplayDocuments:
				data?.postCompletion?.ms5Payslips || fallbackValues?.ms5PayslipFormDisplayDocuments,
			pcdComment: data?.postCompletion?.pcdComment || fallbackValues?.pcdComment,
		}),
		[
			data,
			ms5PayslipFormDocumentData?.displayName,
			pcdInvoiceFormDocumentData?.displayName,
			pcdPayslipFormDocumentData?.displayName,
			pctFormDocumentData?.displayName,
			pcdAmountValue,
		],
	);
};

export default usePostCompletionFormInitialValues;
