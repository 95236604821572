import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Overlay, { useOverlayTitles } from 'Common/components/modals/Overlay';
import SectionContainer from 'Common/components/modals/SectionContainer';
import UserForm from './UserForm';
import useAbortController from 'Common/hooks/useAbortController';
import crudModes from 'Common/constants/crudModes';
import getUserByEmail from 'UserManagement/api/getUserByEmail';
import showToastError from 'Common/utils/showToastError';
import { useTranslation } from 'react-i18next';

const UserOverlay = ({ isOpen, onClose, onFormSubmit, mode, email }) => {
	const { t } = useTranslation();
	const { name, label } = useOverlayTitles(t('User'), mode);

	const [userData, setUserData] = useState(null);

	const abortController = useAbortController();

	useEffect(() => {
		if (mode === crudModes.EDIT && isOpen && email) {
			(async () => {
				try {
					const response = await getUserByEmail(abortController.signal, email);

					setUserData(response.data);
				} catch (err) {
					showToastError(err);
				}
			})();
		}
	}, [mode, email, abortController.signal, isOpen]);

	const isDataLoading = mode === crudModes.EDIT && userData === null;

	const breadcrumbList = useMemo(
		() => [
			{
				label: 'Users',
				href: '/users',
			},
			{
				label: name,
			},
		],
		[name],
	);

	return (
		<Overlay
			label={label}
			breadcrumbList={breadcrumbList}
			isOpen={isOpen}
			onClose={onClose}
			onSave={onFormSubmit}
			isLoadingContent={isDataLoading}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<SectionContainer name="User">
					<UserForm
						mode={mode}
						data={userData}
						onSubmit={handleSubmit}
						onCancel={onCancel}
						onDirty={setIsFormDirty}
					/>
				</SectionContainer>
			)}
		</Overlay>
	);
};

UserOverlay.defaultProps = {
	email: null,
};

UserOverlay.propTypes = {
	onFormSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	mode: PropTypes.string.isRequired,
	email: PropTypes.string,
};

export default UserOverlay;
