import api from 'Application/api/api';

const getAssetAndRevenueRecognitions = (signal, searchQuery) => {
	return api.get('/assets-and-revenue-recognitions', {
		params: {
			query: searchQuery,
		},
		signal,
		timeout: 100000,
	});
};

export default getAssetAndRevenueRecognitions;
