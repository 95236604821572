import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useInputPlaceholder = (isDisabled, unit, propPlaceholder, isTile, isInEditMode) => {
	const { t } = useTranslation();
	const hasUnit = Boolean(unit);

	const placeholder = useMemo(() => {
		if (isDisabled) {
			return '-';
		}
		if (isInEditMode || !isTile) {
			if (propPlaceholder) {
				return propPlaceholder;
			}
			if (hasUnit) {
				return unit;
			}
			return t('Edit here');
		}
		return '-';
	}, [isDisabled, isInEditMode, isTile, hasUnit, propPlaceholder, t, unit]);

	return placeholder;
};

export default useInputPlaceholder;
