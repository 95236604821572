import useQueryParameter from 'Common/hooks/useQueryParameter';
import { useMemo } from 'react';

const useInitialSortByFromQueryParameter = defaultSortBy => {
	const sortByQueryParam = useQueryParameter('sortBy');

	// noinspection UnnecessaryLocalVariableJS
	const initialSortBy = useMemo(
		() => [sortByQueryParam ? { ...sortByQueryParam } : { ...defaultSortBy }],
		// eslint-disable-next-line
		[],
	);

	return initialSortBy;
};

export default useInitialSortByFromQueryParameter;
