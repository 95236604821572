import { useMemo } from 'react';
import { pick } from 'lodash';

const fallbackValues = {
	name: '',
	website: '',
	countryIds: [],
	apartmentBuilding: '',
	line1: '',
	line2: '',
	cityRegion: '',
	postCodeZip: '',
	firstName: '',
	lastName: '',
	phone: '',
	email: '',
	ext: '',
	comment: '',
	position: '',
};

const useEpcPartnerFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, [
				'name',
				'website',
				'firstName',
				'lastName',
				'phone',
				'email',
				'comment',
				'ext',
				'position',
			]),
			countryIds: data?.countries?.map(country => ({
				value: country.id,
				label: country.name,
			})),
			apartmentBuilding: data?.address?.apartmentBuilding ?? '',
			line1: data?.address?.line1 ?? '',
			line2: data?.address?.line2 ?? '',
			cityRegion: data?.address?.cityRegion ?? '',
			postCodeZip: data?.address?.postCodeZip ?? '',
		}),
		[data],
	);

export default useEpcPartnerFormInitialValues;
