import api from 'Application/api/api';

const getClientContractTypes = (signal, query) =>
	api.get('/contract-types/get-pageable', {
		params: {
			query,
		},
		signal,
	});

export default getClientContractTypes;
