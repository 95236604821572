import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useEmissionSpvFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				name: yup.string().required(t('Name is Required')),
				abbreviation: yup
					.string()
					.nullable()
					.required(t('Abbreviation is Required'))
					.max(6, t('Abbreviation cannot be more than 6 characters')),
			}),
		[t],
	);
};

export default useEmissionSpvFormValidationSchema;
