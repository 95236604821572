import Grid from '@mui/material/Grid';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import styled from 'styled-components/macro';

import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';

import IndustriesList from 'Preferences/components/Tabs/IndustriesTab/IndustriesList/IndustriesList';
import RestrictedIndustriesTile from 'Preferences/components/Tabs/IndustriesTab/RestrictedIndustriesTile/RestrictedIndustriesTile';

const GridWrapper = styled(Grid)`
	position: relative;
	z-index: ${({ $zIndex }) => ($zIndex ? $zIndex : 0)};
`;

const IndustriesTab = () => (
	<TilesGridWrapper>
		{isAuthorized([roles.ADMIN, roles.FINANCE, roles.MANAGEMENT]) && (
			<GridWrapper item xs={12} sm={12} md={12} lg={12} xl={12} $zIndex="1">
				<RestrictedIndustriesTile />
			</GridWrapper>
		)}
		<GridWrapper data-form="countries" item xs={12} sm={12} md={12} lg={12} xl={12}>
			<IndustriesList />
		</GridWrapper>
	</TilesGridWrapper>
);

export default IndustriesTab;
