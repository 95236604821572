import PropTypes from 'prop-types';

import styled from 'styled-components';
import useResponsive from 'Common/hooks/useResponsive';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import H1 from 'Common/components/headers/H1';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from 'Common/components/buttons/Button';
import showToastError from 'Common/utils/showToastError';
import Modal from 'Common/components/modals/Modal';
import useAbortController from 'Common/hooks/useAbortController';
import { useState } from 'react';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import editClientContract from '../../api/editClientContract';
import FormikInput from 'Common/components/form/FormikInput';
import sizes from 'Application/theme/sizes';
import formatDateForRequestData from 'Common/utils/formatDateForRequestData';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const FieldsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;
`;

const StyledFormField = styled.div`
	padding-bottom: ${sizes.spacing(1)};
`;

const FooterWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	margin-top: 40px;
	bottom: 0;
	justify-content: center;

	> * {
		margin-right: 16px;

		&:last-child {
			margin-right: 0px;
		}
	}
`;

const ClientContractSignModal = ({ isOpen, onFormSubmit, clientContractId, onClose, contractNumber }) => {
	const { t } = useTranslation();
	const { isMobile } = useResponsive();
	const [isSubmitting, setIsSubmiting] = useState(false);

	const abortController = useAbortController();

	const {
		values,
		errors,
		dirty,
		touched,
		setFieldValue,
		setFieldTouched,
		handleSubmit,
		handleBlur,
		handleChange,
	} = useFormik({
		initialValues: {
			saasSignatureDate: new Date(),
			contractNumber: contractNumber || null,
		},
		validationSchema: yup.object().shape({
			saasSignatureDate: yup.date(t('Invalid date')).nullable().required(t('SaaS Signature Date is required')),
			contractNumber: yup.string().nullable(),
		}),
		onSubmit: async () => {
			try {
				setIsSubmiting(true);

				await editClientContract(abortController.signal, clientContractId, {
					saasSignatureDate: values.saasSignatureDate
						? formatDateForRequestData(values.saasSignatureDate)
						: undefined,
					contractNumber: values.contractNumber,
				});
				await onFormSubmit();
				notify(t('SaaS Contract signed successfully'), {
					type: toast.TYPE.SUCCESS,
				});
				onClose();
			} catch (e) {
				showToastError(e, t('Something went wrong when signing contract'));
			}
		},
	});

	const onCancel = () => {
		if (dirty) {
			const result = window.confirm('You have unsaved changes');
			if (result) {
				onClose();
			}
		} else {
			onClose();
		}
	};

	return (
		<Modal label="saas-contract-sign" isOpen={isOpen} $isMobile={isMobile}>
			<H1>Sign SaaS Contract</H1>
			<Form onSubmit={handleSubmit}>
				<FieldsWrapper>
					<StyledFormField>
						<FormikSingleDatePicker
							id="saasSignatureDate"
							label={t('SaaS signature date')}
							startDateName="saasSignatureDate"
							error={errors.saasSignatureDate}
							touched={touched.saasSignatureDate}
							startDate={values.saasSignatureDate}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							isRequired
						/>
					</StyledFormField>
					<StyledFormField>
						<FormikInput
							id="contractNumber"
							name="contractNumber"
							label={t('Contract number')}
							value={values.contractNumber}
							error={errors.contractNumber}
							touched={touched.contractNumber}
							onChange={handleChange}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
						/>
					</StyledFormField>
				</FieldsWrapper>
				<FooterWrapper>
					<Button secondary text={t('Cancel')} onClick={onCancel} label="Contact Number Form - Cancel Button" />
					<Button
						overlay
						type="submit"
						text={t('Sign Contract')}
						label={'Contact Number Form - Save Button'}
						isLoading={isSubmitting}
					/>
				</FooterWrapper>
			</Form>
		</Modal>
	);
};

ClientContractSignModal.defaultProps = {
	project: {},
	isOpen: false,
	onClose: () => {},
	clientContractId: null,
	onFormSubmit: () => {},
	contractNumber: '',
};

ClientContractSignModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	clientContractId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	project: PropTypes.shape({}),
	onFormSubmit: PropTypes.func,
	contractNumber: PropTypes.string,
};

export default ClientContractSignModal;
