import useAbortController from 'Common/hooks/useAbortController';
import showToastError from 'Common/utils/showToastError';
import createEntityComment from 'Entities/api/createEntityComment';
import deleteEntityComment from 'Entities/api/deleteEntityComment';
import editEntityComment from 'Entities/api/editEntityComment';
import getEntityById from 'Entities/api/getEntityById';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useEntityDetailsCommentsData = (entity, handleChange) => {
	const { t } = useTranslation();
	const defaultComments = useMemo(() => entity?.comments, [entity?.comments]);

	const abortController = useAbortController();

	const onAddComment = useCallback(
		async newCommentText => {
			try {
				await createEntityComment(abortController.signal, {
					contractPartyId: entity.id,
					text: newCommentText,
				});

				const {
					data: { comments: newEntityComments },
				} = await getEntityById(abortController.signal, entity.id);

				handleChange();

				return newEntityComments;
			} catch (err) {
				showToastError(err, t('A problem occured while trying to send a comment'));
			}
		},
		[abortController.signal, handleChange, entity.id, t],
	);

	const onEditComment = useCallback(
		async (editedCommentId, editedCommentText) => {
			try {
				const commentChange = {
					commentId: editedCommentId,
					contractPartyId: entity.id,
					text: editedCommentText,
				};
				await editEntityComment(abortController.signal, commentChange);

				handleChange();
			} catch (err) {
				showToastError(err, t('Could not edit comment.'));
			}
		},
		[abortController.signal, handleChange, entity.id, t],
	);

	const onDeleteComment = useCallback(
		async deletedCommentId => {
			try {
				await deleteEntityComment(abortController.signal, deletedCommentId);
				handleChange();
			} catch (err) {
				showToastError(err, t('Could not delete comment.'));
			}
		},
		[abortController.signal, handleChange, t],
	);

	return { defaultComments, onAddComment, onEditComment, onDeleteComment };
};

export default useEntityDetailsCommentsData;
