import useAbortController from 'Common/hooks/useAbortController';
import showToastError from 'Common/utils/showToastError';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ecoligoRoleOptions } from 'User/constants/roles';
import getUsersWithFullName from 'UserManagement/api/getUsersWithFullName';

const useEcoligoUsersOptions = excludedUserEmails => {
	const { t } = useTranslation();
	const [ecoligoUsersOptions, setEcoligoUsersOptions] = useState([]);
	const [isLoadingEcoligoUsersOptions, setIsLoadingEcoligoUsersOptions] = useState(false);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				setIsLoadingEcoligoUsersOptions(true);
				const response = await getUsersWithFullName(abortController.signal, {
					active: true,
					roles: ecoligoRoleOptions.map(option => option.value),
				});
				setEcoligoUsersOptions(
					response.data.map(user => ({
						...user,
						value: user.email,
						label: user.fullName,
					})),
				);
				setIsLoadingEcoligoUsersOptions(false);
			} catch (e) {
				showToastError(e, t("Couldn't load ecligo users options"));
			}
		})();
	}, [abortController.signal, t]);

	const filteredEcoligoUsersOptions = useMemo(
		() =>
			ecoligoUsersOptions.filter(ecoligoUser =>
				excludedUserEmails ? !excludedUserEmails.includes(ecoligoUser.email) : true,
			),
		[ecoligoUsersOptions, excludedUserEmails],
	);

	return { ecoligoUsersOptions: filteredEcoligoUsersOptions, isLoadingEcoligoUsersOptions };
};

export default useEcoligoUsersOptions;
