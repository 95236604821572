import { cloneElement, Children, useEffect, useRef, useState } from 'react';
import { ClickAwayListener, Grow, MenuList, Paper as MaterialPaper, Popper } from '@mui/material';
import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';

import Button from 'Common/components/buttons/Button';
import customGAEvent from 'Common/utils/customGAEvent';
import showToastError from 'Common/utils/showToastError';
import colors from 'Application/theme/colors';
import useResponsive from 'Common/hooks/useResponsive';

const Wrapper = styled.div`
	> div {
		z-index: 1;
	}
	${({ $noBackground, $isOpen, $controlledVisibility, $isTouchDevice }) =>
		$noBackground || $controlledVisibility
			? css`
					transition: visibility 0.3s, opacity 0.3s;
					.MuiButton-root {
						background: none;

						.MuiTouchRipple-root {
							display: none;
						}
						&:hover {
							background: none;
							color: ${colors.common.lightGreen};
						}
					}
					${$isOpen
						? css`
								.MuiButton-root {
									color: ${colors.common.lightGreen};
								}
						  `
						: ''}
					${$controlledVisibility && !$isTouchDevice && !$isOpen
						? css`
								visibility: hidden;
								opacity: 0;
						  `
						: ''}
			  `
			: ''}
`;

const Paper = styled(MaterialPaper)`
	border-radius: 6px;
	overflow: hidden;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15) !important;
`;

const MoreActionsButton = ({
	label,
	children,
	className,
	dataAction,
	popperProps,
	CustomButton,
	noBackground,
	controlledVisibility,
	...props
}) => {
	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);
	const { isTouchDevice } = useResponsive();

	const handleToggle = () => {
		customGAEvent('button', 'click', label);
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = e => {
		if (anchorRef.current && anchorRef.current.contains(e.target)) {
			return;
		}

		setOpen(false);
	};

	const handleListKeyDown = e => {
		if (e.key === 'Tab') {
			e.preventDefault();
			setOpen(false);
		}
	};

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = useRef(open);
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const wrapperClassname =
		(className ? `${className} ` : '') +
		(controlledVisibility ? 'controlledVisibility' : '') +
		' more-actions-button';

	return (
		<Wrapper
			className={wrapperClassname}
			data-action={dataAction}
			$noBackground={noBackground}
			$isOpen={open}
			$controlledVisibility={controlledVisibility}
			$isTouchDevice={isTouchDevice}
		>
			{CustomButton ? (
				<div
					ref={anchorRef}
					aria-label="more"
					aria-haspopup="true"
					aria-hidden="true"
					onClick={props.disabled ? null : handleToggle}
					{...props}
				>
					<CustomButton />
				</div>
			) : (
				<Button
					medium
					ariaLabel="more"
					ariaHaspopup="true"
					buttonRef={anchorRef}
					icon="moreVertRounded"
					noBackground={noBackground}
					onClick={props.disabled ? null : handleToggle}
					label="More Actions Button"
					dataAction={null}
				/>
			)}
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				placement="bottom-end"
				style={{
					zIndex: 9999,
				}}
				{...popperProps}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom-end' ? 'right top' : 'right bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList disablePadding autoFocusItem={open} onKeyDown={handleListKeyDown}>
									{Children.map(children, child => {
										if (!child) {
											return null;
										}

										// Always use the onClick handler, even for links,
										// so that the user could see the menu closing.
										const handleClick = async e => {
											handleClose(e);

											if (child.props.onClick) {
												try {
													await child.props.onClick(e);
												} catch (error) {
													showToastError(error);
												}
											}
										};

										return cloneElement(child, {
											onClick: handleClick,
										});
									})}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Wrapper>
	);
};

MoreActionsButton.defaultProps = {
	label: null,
	children: null,
	className: null,
	popperProps: null,
	CustomButton: null,
	noBackground: false,
	controlledVisibility: false,
	dataAction: null,
	disabled: false,
};

MoreActionsButton.propTypes = {
	label: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	CustomButton: PropTypes.func,
	noBackground: PropTypes.bool,
	controlledVisibility: PropTypes.bool,
	popperProps: PropTypes.shape({}),
	dataAction: PropTypes.string,
	disabled: PropTypes.bool,
};

export default MoreActionsButton;
