import { useMemo } from 'react';
import TechnicalInputsSection from '../Sections/TechnicalInputsSection';
import CommercialInputsSection from '../Sections/CommercialInputsSection';
import OfferOutputSection from '../Sections/OfferOutput';
import EcoligoResultsSection from '../Sections/EcoligoResultsSection';

const useClientOfferOverlaySections = (epcOfferId, breadcrumbList) => {
	return useMemo(() => {
		return [
			{
				title: 'Technical Inputs',
				section: props => (
					<TechnicalInputsSection {...props} epcOfferId={epcOfferId} breadcrumbList={breadcrumbList} />
				),
			},
			{
				title: 'Commercial Inputs',
				section: props => <CommercialInputsSection {...props} />,
			},
			{
				title: 'Offer Output',
				section: props => <OfferOutputSection {...props} />,
			},
			{
				title: 'Ecoligo Results',
				section: props => <EcoligoResultsSection {...props} />,
			},
		];
	}, [epcOfferId, breadcrumbList]);
};

export default useClientOfferOverlaySections;
