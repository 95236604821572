import DocumentsList from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/EPCOfferTile/DocumentsList/DocumentsList';
import { inquiryDocumentOptions } from 'Projects/constants/documentTableOptions';
import { PropTypes } from 'prop-types';

const DocumentationSection = ({
	setDocsForUpload,
	documents,
	docsForUpload,
	setDocsForDelete,
	docsForDelete,
	inquiryId,
	handleDeleteDoc,
	onDataChange,
	isDisabled,
}) => (
	<DocumentsList
		documentDefs={inquiryDocumentOptions}
		offerId={inquiryId}
		documents={documents}
		docsForUpload={docsForUpload}
		setDocsForUpload={setDocsForUpload}
		setDocsForDelete={setDocsForDelete}
		docsForDelete={docsForDelete}
		handleDeleteDoc={handleDeleteDoc}
		onDataChange={onDataChange}
		//onDataChange={updateDocuments}
		//uploadDocsApiCall={uploadDocsFromAdmin}
		isReadOnly={isDisabled}
		showHeader={false}
		idPropName="inquiryId"
	/>
);

DocumentationSection.defaultProps = {
	docsForUpload: null,
	inquiryId: null,
	onDataChange: () => {},
};

DocumentationSection.propTypes = {
	setDocsForUpload: PropTypes.func.isRequired,
	setDocsForDelete: PropTypes.func.isRequired,
	documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	docsForDelete: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	docsForUpload: PropTypes.arrayOf(PropTypes.shape({})),
	inquiryId: PropTypes.number,
	handleDeleteDoc: PropTypes.func.isRequired,
	onDataChange: PropTypes.func,
	isDisabled: PropTypes.bool.isRequired,
};

export default DocumentationSection;
