const EPC_OFFER_TYPES = {
	ESTIMATE: 'ESTIMATE',
	INQUIRY: 'INQUIRY',
	PROPOSAL: 'PROPOSAL',
};

const EPC_OFFER_LABELS = {
	[EPC_OFFER_TYPES.ESTIMATE]: 'Estimate',
	[EPC_OFFER_TYPES.INQUIRY]: 'Inquiry',
	[EPC_OFFER_TYPES.PROPOSAL]: 'Proposal',
};

export { EPC_OFFER_TYPES, EPC_OFFER_LABELS };
