import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import sizes from 'Application/theme/sizes';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';
import { useFormik } from 'formik';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';
import Button from 'Common/components/buttons/Button';
import * as yup from 'yup';
import loadInvoiceTemplateOptions from 'Invoice/utils/loadInvoiceTemplateOptions';
import useAbortController from 'Common/hooks/useAbortController';
import loadSaaSBankAccountOptions from 'Invoice/utils/loadSaaSBankAccountOptions';
import assignTemplateAndBankAccount from 'Invoice/api/assignTemplateAndBankAccount';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { INVOICE_STATUS_OPTIONS } from 'Invoice/constants/invoiceContstants';
import generateInvoice from 'Invoice/api/generateInvoice';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const FieldsWrapper = styled.div`
	width: 100%;
`;

const FooterWrapper = styled.div`
	padding-top: ${sizes.spacing(2)};

	> * {
		margin-right: ${sizes.spacing(2)};

		&:last-child {
			margin-right: 0;
		}
	}
`;
const fallbackValues = {
	status: INVOICE_STATUS_OPTIONS[0],
	invoiceTemplate: null,
	exchangeRate: '',
	bankAccount: null,
	currency: { value: 'EUR' },
};

const useSelectInvoiceInitialValues = invoice =>
	useMemo(
		() => ({
			invoiceTemplate: invoice?.invoiceTemplate
				? { value: invoice?.invoiceTemplate.id, label: invoice?.invoiceTemplate.fileName }
				: fallbackValues.invoiceTemplate,
			bankAccount: invoice?.bankAccount
				? { value: invoice?.bankAccount.id, label: invoice?.bankAccount.name }
				: fallbackValues.bankAccount,
		}),
		[invoice?.bankAccount, invoice?.invoiceTemplate],
	);

const useSelectInvoiceValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				invoiceTemplate: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.required(t('Invoice Template is required'))
					.nullable(),
				bankAccount: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.required(t('Bank Account is required'))
					.nullable(),
			}),
		[t],
	);
};

const GenerateAttachmentForm = ({ invoice, onSubmit, onCancel, onDirty, countryId }) => {
	const { t } = useTranslation();

	const initialValues = useSelectInvoiceInitialValues(invoice);
	const validationSchema = useSelectInvoiceValidationSchema();

	const abortController = useAbortController();

	const loadInvoiceTemplateOptionsBinded = useMemo(
		() => loadInvoiceTemplateOptions.bind(null, [countryId]),
		[countryId],
	);

	const loadSaaSBankAccountOptionsBinded = useMemo(
		() => loadSaaSBankAccountOptions.bind(null, [invoice.id]),
		[invoice.id],
	);

	const {
		errors,
		touched,
		values,
		dirty,
		handleSubmit,
		setFieldTouched,
		setFieldValue,
		handleBlur,
		setFieldError,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				await assignTemplateAndBankAccount(abortController.signal, invoice.id, {
					templateId: values.invoiceTemplate.value,
					bankAccountId: values.bankAccount.value,
				});

				notify(t('Invoice template and bank account added successfully'), {
					type: toast.TYPE.SUCCESS,
				});

				await generateInvoice(abortController.signal, invoice.id);

				notify(t('Invoice generated successfully'), {
					type: toast.TYPE.SUCCESS,
				});

				await onSubmit();
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
			}
		},
	});

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form onSubmit={handleSubmit}>
			<FieldsWrapper>
				<FormField>
					<FormikSelect
						isAsync
						id="invoiceTemplate"
						name="invoiceTemplate"
						label={t('Invoice Template')}
						value={values.invoiceTemplate}
						error={errors.invoiceTemplate}
						touched={touched.invoiceTemplate}
						setFieldTouched={setFieldTouched}
						setFieldValue={setFieldValue}
						onBlur={handleBlur}
						loadOptions={loadInvoiceTemplateOptionsBinded}
						menuPlacement="bottom"
						isRequired
					/>
				</FormField>
				<FormField>
					<FormikSelect
						isAsync
						id="bankAccount"
						name="bankAccount"
						label={t('Bank Account')}
						value={values.bankAccount}
						error={errors.bankAccount}
						touched={touched.bankAccount}
						setFieldTouched={setFieldTouched}
						setFieldValue={setFieldValue}
						onBlur={handleBlur}
						loadOptions={loadSaaSBankAccountOptionsBinded}
						isRequired
					/>
				</FormField>
			</FieldsWrapper>
			<FooterWrapper>
				<Button secondary text={t('Cancel')} onClick={onCancel} label="Invoice Template Form - Cancel Button" />
				<Button overlay type="submit" text={t('Generate')} label={'Invoice Template Form - Generate Button'} />
			</FooterWrapper>
		</Form>
	);
};
GenerateAttachmentForm.defaultProps = {
	invoice: {},
	onCancel: () => {},
	onDirty: () => {},
};
GenerateAttachmentForm.propTypes = {
	countryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	invoice: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	}),
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	onDirty: PropTypes.func,
};

export default GenerateAttachmentForm;
