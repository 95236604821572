import { css } from 'styled-components';
import styled from 'styled-components/macro';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import colors from 'Application/theme/colors';

import defaultBackground from 'Common/assets/images/pulse-background.png';
import useResponsive from 'Common/hooks/useResponsive';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

const ReactModalAdapter = ({ className, modalClassName, label, ...props }) => {
	return (
		<ReactModal
			className={modalClassName}
			portalClassName={className}
			onAfterOpen={() => ReactGA.modalview(label)}
			{...props}
		/>
	);
};

const StyledModal = styled(ReactModalAdapter).attrs({
	modalClassName: 'Modal',
})`
	.ReactModal__Overlay {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 40px 20px;
		z-index: 1000;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		background-color: rgba(54, 55, 55, 0.4) !important;

		&--after-open {
			opacity: 1;
		}

		&--before-close {
			opacity: 0;
		}

		body.is-blurred & {
			background-color: transparent !important;
		}

		body.is-blurred &:before {
			content: '';
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: ${colors.primary.main};
			background: linear-gradient(-45deg, ${colors.primary.main} 0%, ${colors.primary.main} 100%);

			opacity: 0.8;
		}

		${({ $hasEcoligoBackground }) =>
			$hasEcoligoBackground &&
			css`
				background: url(${defaultBackground});
				background-size: cover;

				body.is-blurred &:before {
					opacity: 0;
				}
			`}
	}

	.ReactModal__Content {
		position: relative;
		width: 100%;
		max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '680px')};
		padding: ${({ padding, $isMobile }) => (padding ? padding : $isMobile ? '32px' : '32px 96px')};
		background: ${colors.grey.lightest};
		border-radius: 20px;
		max-height: 100%;
		overflow-y: auto;
		font-size: 16px;
		&:focus {
			outline: 0 none;
		}
	}
`;

ReactModalAdapter.defaultProps = {
	className: '',
	label: 'Modal',
	modalClassName: '',
};

ReactModalAdapter.propTypes = {
	label: PropTypes.string,
	className: PropTypes.string,
	modalClassName: PropTypes.string,
};

const Modal = props => {
	const { isMobile } = useResponsive();

	return <StyledModal {...props} $isMobile={isMobile} />;
};

export default Modal;
