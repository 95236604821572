import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import BankAccountList from 'BankAccount/components/List/BankAccountsList';

const BankAccountsTab = ({ entity, onDataChange }) => {
	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<BankAccountList entity={entity} onDataChange={onDataChange} />
			</Grid>
		</TilesGridWrapper>
	);
};

BankAccountsTab.propTypes = {
	entity: PropTypes.shape({}).isRequired,
	onDataChange: PropTypes.func.isRequired,
};

export default BankAccountsTab;
