import { useState, useEffect } from 'react';
import { roles } from 'User/constants/roles';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';
import isAuthorized from 'User/utils/isAuthorized';

import Link from 'Common/components/Link';
import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';
import TileDynamicSection from 'Common/components/Tile/TileDynamicSection';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

import loadEpcPartnerOptions from 'EpcPartner/utils/loadEpcPartnerOptions';
import EpcPartnerNameWithTier from 'EpcPartner/components/EpcPartnerNameWithTier';

import assignEpcPartnerToProject from 'Projects/api/assignEpcPartnerToProject';
import unassignEpcPartnerFromProject from 'Projects/api/unassignEpcPartnerFromProject';
import useEpcPartnerFormInitialValues from 'Projects/components/ProjectDetails/Tabs/EPCTab/Tiles/EpcPartnerTile/hooks/useEpcPartnerFormInitialValues';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';
import axios from 'axios';
import EpcPartnerOverlay from 'EpcPartner/components/EpcPartnerForm/EpcPartnerOverlay';
import crudModes from 'Common/constants/crudModes';

const EpcPartnerTile = ({
	project,
	onEnterEditMode,
	onExitEditMode,
	onDirtyForm,
	shouldDisableField,
	onTileSave,
	highlightLabels,
}) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.PM_TECHNICAL,
		roles.SALES,
		roles.MANAGEMENT,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [showCreateEpcPartnerOverlay, setShowCreateEpcPartnerOverlay] = useState(false);

	const abortController = useAbortController();

	const initialValues = useEpcPartnerFormInitialValues(project);

	const { errors, touched, values, handleSubmit, dirty, handleBlur, setFieldValue, resetForm } = useFormik({
		initialValues,
		onSubmit: async values => {
			setIsSavingChanges(true);

			try {
				if (values.epcPartner) {
					await assignEpcPartnerToProject(abortController.signal, values.epcPartner.label, project.id);
				} else {
					await unassignEpcPartnerFromProject(abortController.signal, project.id);
				}

				onDirtyForm(false);

				await onTileSave();
				handleCancel();
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});

	const handleEpcPartnerFormSubmit = epcPartner => {
		setFieldValue('epcPartner', {
			value: epcPartner.id,
			label: epcPartner.name,
		});
		requestAnimationFrame(() => {
			handleSubmit();
		});
	};

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	const customDisplayValue = values.epcPartner?.label ? (
		<Link color={colors.primary.main} to={`/epc-partners/details/${project.epcPartner?.id}`}>
			{values.epcPartner?.label}
		</Link>
	) : (
		''
	);

	return (
		<EditableTile
			title={t('EPC Partner')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<TileDynamicSection
				uniqueId="Client"
				isInEditMode={isInEditMode}
				readonlyView={
					<KeyValueVisualization
						title={t('EPC Partner')}
						value={customDisplayValue}
						hasHoverEffect
						isHighlighted={highlightLabels.includes(
							PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_EPC_PARTNER.EPC_PARTNER,
						)}
					/>
				}
				editView={
					<FormField>
						<FormikSelect
							isAsync
							id="epcPartner"
							name="epcPartner"
							label={t('EPC Partner')}
							value={values.epcPartner}
							error={errors.epcPartner}
							touched={touched.epcPartner}
							isClearable
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadEpcPartnerOptions}
							createLinkTitle="Create EPC Partner"
							onCreateClick={() => setShowCreateEpcPartnerOverlay(true)}
							isDisabled={shouldDisableField}
							isTile
							isInEditMode={isInEditMode}
							isMenuFixed
							customDisplayValue={customDisplayValue}
							getOptionLabel={option => (
								<EpcPartnerNameWithTier
									tier={option.tier}
									name={option.label}
									points={option.totalPoints}
									showIcon={false}
								/>
							)}
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_EPC_PARTNER.EPC_PARTNER,
							)}
						/>
					</FormField>
				}
			/>
			{showCreateEpcPartnerOverlay && (
				<EpcPartnerOverlay
					mode={crudModes.CREATE}
					isOpen={showCreateEpcPartnerOverlay}
					onClose={() => setShowCreateEpcPartnerOverlay(false)}
					onFormSubmit={handleEpcPartnerFormSubmit}
				/>
			)}
		</EditableTile>
	);
};

EpcPartnerTile.defaultProps = {
	onEnterEditMode: () => null,
	onExitEditMode: () => null,
	onDirtyForm: () => null,
	onTileSave: async () => {},
	highlightLabels: [],
	shouldDisableField: false,
};

EpcPartnerTile.propTypes = {
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		epcPartner: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	shouldDisableField: PropTypes.bool,
	onTileSave: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default EpcPartnerTile;
