/* eslint-disable react/prop-types */

import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';

import TileHeader from 'Common/components/Tile/TileHeader';
import ImpactChart from 'Impact/components/ImpactChart';
import { graphOptions, chartTypes, filterOptions } from 'Impact/constants/options';

import GraphControl from 'Impact/components/GraphControl';
import ImpactInvestorsTable from 'Impact/tiles/ImpactVolumeTile/ImpactInvestorsTable';

import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';

import { useTranslation } from 'react-i18next';

const TileContainer = styled(Grid)`
	width: 100%;
	height: 100%;
	background-color: ${colors.common.tileBackgroundGrey};
	border-radius: 20px;
	border: 1px solid transparent;
	&:hover {
		border-color: ${colors.primary.main};
	}
`;

const TitleHeaderWrapper = styled.div`
	padding: 16px 16px 0px 16px;
`;

const GraphWrapper = styled.div`
	width: 100%;
	height: 230px;
	padding: ${sizes.spacing(2)} ${sizes.spacing(2)} 0 ${sizes.spacing(2)};
`;

const StyledGridHeader = styled(Grid)`
	height: 40px;
`;

const ControlWrapper = styled.div`
	padding: 0 ${sizes.spacing(1)} ${sizes.spacing(1.5)} 0;
`;

const ImpactCrowdLineTile = ({ data }) => {
	const { t } = useTranslation();

	const [showLineChart, setShowLineChart] = useState(true);

	const [labelIndex, setLabelIndex] = useState(0);
	const labels = ['Growth Chart', 'Table'];
	const filters = {
		fields: ['totalEurInvested'],
		time: 'Max',
	};

	const onControlSelect = index => {
		const idx = index < 0 ? labels.length - 1 : index === labels.length ? 0 : index;
		setShowLineChart(!idx);
		setLabelIndex(idx);
	};

	return (
		<>
			<TileContainer container>
				<StyledGridHeader item xs={12} sm={12} md={12} lg={12} xl={12}>
					<TitleHeaderWrapper>
						<TileHeader title={t('volume invested')} />
					</TitleHeaderWrapper>
				</StyledGridHeader>
				{showLineChart ? (
					<GraphWrapper>
						<ImpactChart
							data={data}
							options={{
								axisLabel: '€',
								...graphOptions[chartTypes.LINE],
								...filterOptions[filters.time],
							}}
							filters={filters}
							type={chartTypes.LINE}
						/>
					</GraphWrapper>
				) : (
					<ImpactInvestorsTable data={data} />
				)}
				<Grid container justifyContent="flex-end" alignItems="flex-end">
					<ControlWrapper>
						<GraphControl labels={labels} selectedIndex={labelIndex} onControlSelect={onControlSelect} />
					</ControlWrapper>
				</Grid>
			</TileContainer>
		</>
	);
};

ImpactCrowdLineTile.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ImpactCrowdLineTile;
