import { useMemo, useRef } from 'react';
import { chartTypes } from 'Impact/constants/options';
import { axisLabelPlugin, dougnutPlugin, activeLabelPlugin } from 'Impact/utils/plugins';
import useChartData from 'Impact/hooks/useChartData';
import { PropTypes } from 'prop-types';

import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
	Filler,
	ArcElement,
} from 'chart.js';

import { Line, Bar, Doughnut, Pie } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	ArcElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
	Filler,
);

const GraphWrapper = styled.div`
	width: 100%;
	height: 100%;
`;

const ChartPlaceholder = styled.div`
	width: 100px;
	height: 100px;
	border-radius: 50%;
	border: 2px dashed ${colors.common.lightBlue};
`;

const ImpactChart = ({ data, type, filters, options }) => {
	const chartRef = useRef(null);

	const chartData = useChartData(data, chartRef, type, filters, options);

	const isEmpty = data.datasets && data.datasets.every(e => e === null || e === 0);
	const totalValue = useMemo(
		() =>
			type === chartTypes.DOUGHNUT && chartData?.datasets[0]?.values
				? chartData.datasets[0].values.reduce((res, val) => res + val, 0)
				: '',
		[type, chartData.datasets],
	);

	const charts = {
		[chartTypes.BAR]: <Bar ref={chartRef} data={chartData} options={options} />,
		[chartTypes.DOUGHNUT]: (
			<Doughnut ref={chartRef} data={chartData} options={{ ...options, totalValue }} plugins={dougnutPlugin()} />
		),
		[chartTypes.PIE]: isEmpty ? (
			<>
				<ChartPlaceholder />
			</>
		) : (
			<Pie ref={chartRef} data={chartData} options={options} />
		),
		[chartTypes.LINE]: (
			<Line ref={chartRef} data={chartData} options={options} plugins={axisLabelPlugin(options?.axisLabel)} />
		),
		[chartTypes.STACKED_LINE]: (
			<Line ref={chartRef} data={chartData} options={options} plugins={activeLabelPlugin()} />
		),
	};

	return (
		<>
			<GraphWrapper>{charts[type]}</GraphWrapper>
		</>
	);
};

ImpactChart.defaultProps = {
	filters: {},
	options: {},
};

ImpactChart.propTypes = {
	type: PropTypes.string.isRequired,
	filters: PropTypes.shape({
		time: PropTypes.string,
	}),
	data: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.shape({})),
		PropTypes.shape({ datasets: PropTypes.arrayOf(PropTypes.number) }),
	]).isRequired,
	options: PropTypes.shape({
		label: PropTypes.string,
		axisLabel: PropTypes.string,
	}),
};

export default ImpactChart;
