import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useCallback } from 'react';

import crudModes from 'Common/constants/crudModes';
import countryCodes from 'Common/constants/countryCodes';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import removeTrailingZeros from 'Common/utils/removeTrailingZeros';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import assignCountryManagers from 'Preferences/api/assignCountryManagers';
import useCountryFormInitialValues from 'Country/components/Form/hooks/useCountryFormInitialValues';
import useCountryFormValidationSchema from 'Country/components/Form/hooks/useCountryFormValidationSchema';

import { roles } from 'User/constants/roles';

import loadCountryOptions from 'Country/utils/loadCountryOptions';

import getUsersWithFullName from 'UserManagement/api/getUsersWithFullName';
import loadRegionalHubOptions from 'UserManagement/utils/loadRegionalHubOptions';
import showToastError from 'Common/utils/showToastError';
import Big from 'Common/utils/customBig';
import UNITS from 'Common/constants/units';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { OverlayButtons } from 'Common/components/modals/Overlay';
import Tile from 'Common/components/Tile/Tile';
import { Grid } from '@mui/material';
import InvoiceTemplatesList from 'Invoice/components/TemplatesList/InvoiceTemplatesList';
import sizes from 'Application/theme/sizes';
import UtilityList from 'Client/components/UtilityList/UtilityList';
import getTariffType from 'Invoice/api/getTariffType';
import getCountryExchangeRates from 'Invoice/api/getCountryExchangeRates';
import CountryExchangeRate from 'Client/components/CountryForm/CountryExchangeRate';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding-bottom: ${sizes.spacing(2)};
`;

const FieldsWrapper = styled.div`
	width: 100%;
`;

const CountryForm = ({
	countryId,
	data,
	mode,
	onSubmit,
	onCancel,
	onDirty,
	assignedCountries,
	handleUpdate,
	saveBtnLabel,
}) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const initialValues = useCountryFormInitialValues(data);
	const validationSchema = useCountryFormValidationSchema();

	const [managerOptions, setManagerOptions] = useState([]);
	const [countryOptions, setCountryOptions] = useState([]);
	const [selectLoader, setSelectLoader] = useState(false);

	const fetchUtilityRates = useCallback(async () => {
		if (!countryId) return;
		try {
			const response = await getTariffType(countryId);
			setUtilityRates(response?.data);
		} catch (err) {
			showToastError(err);
		}
	}, [countryId]);

	const fetchCountryExchangeRates = useCallback(async () => {
		if (!data.isoCode) return;
		try {
			const response = await getCountryExchangeRates(data.isoCode);
			setCountryExchangeRates(response?.data);
		} catch (err) {
			showToastError(err);
		}
	}, [data.isoCode]);

	useEffect(() => {
		fetchUtilityRates();
	}, [fetchUtilityRates]);

	useEffect(() => {
		fetchCountryExchangeRates();
	}, [fetchCountryExchangeRates]);

	const [utilityRates, setUtilityRates] = useState();
	const [countryExchangeRates, setCountryExchangeRates] = useState();

	const abortController = useAbortController();
	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleBlur,
		setFieldError,
		setFieldValue,
		resetForm,
		handleChange,
	} = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				const response = await assignCountryManagers(abortController.signal, {
					...values,
					countryId: values.country.value,
					primaryManagerEmail: values.primaryManager.value,
					secondaryManagerEmail: values.secondaryManager ? values.secondaryManager.value : null,
					regionalHubId: values.regionalHub.value,
					vat: values?.vat && Big(values.vat).div(100),
					vatWht: values?.vatWht && Big(values.vatWht).div(100),
					wht: values?.wht && Big(values.wht).div(100),
					covid19Levy: values?.covid19Levy && Big(values.covid19Levy).div(100),
					nhil: values?.nhil && Big(values.nhil).div(100),
					getFundLevy: values?.getFundLevy && Big(values.getFundLevy).div(100),
					whtServiceFeeOnly: values?.whtServiceFeeOnly,
				});

				onSubmit(response.data);

				// Re-set all fields and mark them as not touched
				resetForm({ values });
				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	useEffect(() => {
		(async () => {
			setSelectLoader(true);
			try {
				const response = await getUsersWithFullName(abortController.signal, {
					active: true,
					roles: [
						roles.ADMIN,
						roles.FINANCE,
						roles.ASSET_MANAGER,
						roles.ASSET_MARKETING,
						roles.MANAGEMENT,
						roles.PM_FUNDRAISING,
						roles.PM_TECHNICAL,
						roles.SALES,
					],
				});
				if (response.data.length > 0) {
					setManagerOptions(
						response.data
							.filter(
								user =>
									user.email !== values?.secondaryManager?.value && user.email !== values?.primaryManager?.value,
							)
							.map(user => ({
								value: user.email,
								label: user.fullName,
							})),
					);
				}
				setSelectLoader(false);
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setSelectLoader(false);
				}
			}
		})();
	}, [values.primaryManager, values.secondaryManager, abortController.signal, t]);

	useEffect(() => {
		(async () => {
			try {
				const response = await loadCountryOptions(abortController.signal, undefined, undefined);
				setCountryOptions(response.filter(country => !assignedCountries?.includes(country.value)));
			} catch (error) {
				showToastError(error);
			}
		})();
	}, [assignedCountries, abortController.signal, t]);

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Tile isOverlay title={t('General')}>
						<FieldsWrapper>
							{mode === crudModes.CREATE && (
								<FormField>
									<FormikSelect
										id="country"
										name="country"
										label={t('Country')}
										value={values.country}
										error={errors.country}
										touched={touched.country}
										setFieldValue={setFieldValue}
										onBlur={handleBlur}
										options={countryOptions}
										isRequired
										isOverlay
									/>
								</FormField>
							)}
							<FormField>
								<FormikSelect
									isAsync
									id="regionalHub"
									name="regionalHub"
									label={t('Regional Hub')}
									value={values.regionalHub}
									error={errors.regionalHub}
									touched={touched.regionalHub}
									setFieldValue={setFieldValue}
									onBlur={handleBlur}
									loadOptions={loadRegionalHubOptions}
									isRequired
									isClearable
									isOverlay
								/>
							</FormField>
							<FormField>
								<FormikSelect
									id="primaryManager"
									name="primaryManager"
									label={t('Primary Manager')}
									value={values.primaryManager}
									error={errors.primaryManager}
									touched={touched.primaryManager}
									setFieldValue={setFieldValue}
									onBlur={handleBlur}
									options={managerOptions}
									isRequired
									isClearable
									menuPosition="fixed"
									isLoading={selectLoader}
									isOverlay
								/>
							</FormField>
							<FormField>
								<FormikSelect
									id="secondaryManager"
									name="secondaryManager"
									label={t('Secondary Manager')}
									value={values.secondaryManager}
									error={errors.secondaryManager}
									touched={touched.secondaryManager}
									setFieldValue={setFieldValue}
									onBlur={handleBlur}
									options={managerOptions}
									isClearable
									menuPosition="fixed"
									isDisabled={!values.primaryManager}
									isLoading={selectLoader}
									isOverlay
								/>
							</FormField>
							<FormField>
								<FormikInput
									id="gridEmissionFactor"
									name="gridEmissionFactor"
									label={t('Grid Emission Factor')}
									value={
										mode === crudModes.CREATE
											? removeTrailingZeros(values.gridEmissionFactor)
											: values.gridEmissionFactor
									}
									error={errors.gridEmissionFactor}
									touched={touched.gridEmissionFactor}
									onBlur={handleBlur}
									onChange={handleChange}
									isOverlay
								/>
							</FormField>
							<FormField>
								<FormikInput
									id="gridTariffEscalationRatePerYear"
									name="gridTariffEscalationRatePerYear"
									label={t('Grid Tariff Escalation Rate per year')}
									value={values.gridTariffEscalationRatePerYear}
									error={errors.gridTariffEscalationRatePerYear}
									touched={touched.gridTariffEscalationRatePerYear}
									onBlur={handleBlur}
									unit={UNITS.PERCENT_PER_YEAR}
									onChange={handleChange}
									isOverlay
								/>
							</FormField>
						</FieldsWrapper>
					</Tile>
				</Grid>
				<Grid item xs={12} md={6}>
					<Tile isOverlay title={t('Taxes')}>
						<FieldsWrapper>
							<FormField>
								<FormikInput
									id="vat"
									name="vat"
									label={t('VAT')}
									value={values.vat}
									error={errors.vat}
									touched={touched.vat}
									onBlur={handleBlur}
									unit={UNITS.PERCENT}
									onChange={handleChange}
									isOverlay
								/>
							</FormField>
							<FormField>
								<FormikInput
									id="wht"
									name="wht"
									label={t('WHT')}
									value={values.wht}
									error={errors.wht}
									touched={touched.wht}
									onBlur={handleBlur}
									unit={UNITS.PERCENT}
									onChange={handleChange}
									isOverlay
								/>
							</FormField>
							<FormField>
								<FormikInput
									id="vatWht"
									name="vatWht"
									label={t('VAT WHT')}
									value={values.vatWht}
									error={errors.vatWht}
									touched={touched.vatWht}
									onBlur={handleBlur}
									unit={UNITS.PERCENT}
									onChange={handleChange}
									isOverlay
								/>
							</FormField>
							{data?.isoCode === countryCodes.GHANA && (
								<>
									<FormField>
										<FormikInput
											id="nhil"
											name="nhil"
											label={t('NHIL')}
											value={values.nhil}
											error={errors.nhil}
											touched={touched.nhil}
											onBlur={handleBlur}
											unit={UNITS.PERCENT}
											onChange={handleChange}
											isOverlay
										/>
									</FormField>
									<FormField>
										<FormikInput
											id="covid19Levy"
											name="covid19Levy"
											label={t('COVID-19 Levy')}
											value={values.covid19Levy}
											error={errors.covid19Levy}
											touched={touched.covid19Levy}
											onBlur={handleBlur}
											unit={UNITS.PERCENT}
											onChange={handleChange}
											isOverlay
										/>
									</FormField>
									<FormField>
										<FormikInput
											id="getFundLevy"
											name="getFundLevy"
											label={t('GETFUND Levy')}
											value={values.getFundLevy}
											error={errors.getFundLevy}
											touched={touched.getFundLevy}
											onBlur={handleBlur}
											unit={UNITS.PERCENT}
											onChange={handleChange}
											isOverlay
										/>
									</FormField>
								</>
							)}
							<FormField>
								<FormikCheckbox
									label={t('WHT for Service Fee Only')}
									id="whtServiceFeeOnly"
									name="whtServiceFeeOnly"
									checked={values.whtServiceFeeOnly}
									onChange={handleChange}
									onBlur={handleBlur}
									touched={touched.whtServiceFeeOnly}
								/>
							</FormField>
						</FieldsWrapper>
					</Tile>
				</Grid>
				{mode !== crudModes.CREATE && (
					<Grid item xs={12}>
						<InvoiceTemplatesList
							countryId={countryId}
							invoiceTemplates={data?.invoiceTemplates}
							onDataChange={handleUpdate}
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<UtilityList utilityRates={utilityRates} countryId={countryId} onDataChange={fetchUtilityRates} />
				</Grid>
				<Grid item xs={12}>
					<CountryExchangeRate
						countryExchangeRates={countryExchangeRates}
						countryIsoCode={data.isoCode}
						onDataChange={fetchCountryExchangeRates}
					/>
				</Grid>
			</Grid>
			<OverlayButtons
				onCancel={onCancel}
				isLoading={isLoading}
				label="Country Form"
				submitButtonText={saveBtnLabel}
			/>
		</Form>
	);
};
CountryForm.defaultProps = {
	data: {},
	countryId: '',
};

CountryForm.propTypes = {
	data: PropTypes.shape({
		isoCode: PropTypes.string,
		invoiceTemplates: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	mode: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	handleUpdate: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
	assignedCountries: PropTypes.arrayOf(PropTypes.number).isRequired,
	countryId: PropTypes.string,
	saveBtnLabel: PropTypes.string.isRequired,
};

export default CountryForm;
