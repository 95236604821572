import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import FormikTextArea from 'Common/components/form/FormikTextArea';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import UsersPreview from 'Common/components/usersPreview/UsersPreview';
import formatDate from 'Common/utils/formatDate';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import SendButton from './SendButton';
import showToastError from 'Common/utils/showToastError';
import axios from 'axios';
import PropTypes from 'prop-types';
import Icon from 'Common/components/icons/Icon';

const defaultStyles = css`
	margin-bottom: ${sizes.spacing(4)};
`;

const newCommentStyles = css`
	@keyframes fadeIn {
		from {
			opacity: 0;
			transform: translateY(-30px);
		}

		to {
			opacity: 1;
			transform: translateY(0);
		}
	}

	animation: fadeIn 0.5s ease-in-out forwards;
	margin-bottom: ${sizes.spacing(4)};
`;

const deletedCommentStyles = css`
	@keyframes fadeOut {
		from {
			opacity: 1;
			max-height: 60px;
			transform: translateY(0);
			margin-bottom: ${sizes.spacing(4)};
		}

		to {
			opacity: 0;
			max-height: 0px;
			transform: translateY(30px);
			margin-bottom: 0px;
		}
	}

	pointer-events: none;
	animation: fadeOut 0.5s ease-in-out forwards;
`;

const stepDeletedCommentStyles = css`
	[data-text-element] {
		color: ${colors.text.grey} !important;
	}
`;

const CommentContainer = styled.div`
	${({ $isNew, $isDeleted, $stepDeleted }) => {
		if ($stepDeleted) {
			return stepDeletedCommentStyles;
		}
		if ($isNew) {
			return newCommentStyles;
		}
		if ($isDeleted) {
			return deletedCommentStyles;
		}
		return defaultStyles;
	}};
	&:hover {
		.more-actions-button.controlledVisibility {
			visibility: visible;
			opacity: 1;
		}
	}
`;

const CommentHeader = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	align-items: center;
	gap: ${sizes.spacing(2)};
	margin-bottom: ${sizes.spacing(1)};
`;

const CommentUserAvatarContainer = styled.div``;

const CommentAuthor = styled.div`
	font-weight: 700;

	color: ${({ $isCreator }) => ($isCreator ? colors.common.darkGreen : colors.common.black)};
`;

const CommentDate = styled.div``;

const EditText = styled.div`
	color: ${colors.primary.main};
	font-size: 12px;
`;

const StyledMoreActionsButton = styled(MoreActionsButton)`
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);

	transition: opacity 0.2s linear;

	&:hover {
		opacity: 1;
	}
`;

const DeletedText = styled.span`
	color: ${colors.error.main};
	font-weight: 600;
	font-size: 12px;
`;

const CommentContent = styled.div``;
const EditCommentContainer = styled.div`
	position: relative;
	.textarea {
		width: calc(100% + 10.5px);
		padding-right: ${sizes.spacing(4)};
		overflow: auto;
	}
`;
const StyledSendButton = styled(SendButton)`
	top: calc(50%);
`;
const CommentText = styled.div`
	font-size: 16px;
	font-weight: 400;
	padding-right: 40px;
	${({ $hideOverflow }) =>
		$hideOverflow
			? 'overflow: hidden; text-overflow: ellipsis;'
			: 'overflow-wrap: break-word; white-space: pre-wrap;'};
`;

const Comment = ({
	comment: { id, text, user, createdDate, isNew, isDeleted, subHeader, stepDeleted },
	onEditComment,
	onDeleteComment,
	hideActions,
	hideOverflow,
}) => {
	const formatedDate = formatDate(createdDate, 'dd/MM/yy');
	const isEdited = false;
	const currentUser = useSelector(state => state.user);
	const isCreator = user.email === currentUser.email;
	const { t } = useTranslation();

	const [editedComment, setEditedComment] = useState(text);
	const [commentText, setCommentText] = useState(text);
	const [isInEditMode, setIsInEditMode] = useState(false);

	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);

	const handleTextAreaChange = e => {
		setEditedComment(e.target.value);
	};

	const handleEditComment = async () => {
		if (editedComment.trim() === '') {
			return;
		}

		try {
			await onEditComment(id, editedComment);
			setCommentText(editedComment);
			setIsInEditMode(false);
		} catch (err) {
			showToastError(err, t('Could not edit comment.'));
		}
	};

	useEffect(() => {
		setCommentText(text);
	}, [text]);

	const handleDeleteComment = async () => {
		try {
			await onDeleteComment(id);
			setIsDeleteModalShown(false);
		} catch (err) {
			showToastError(err, t('Could not delete comment.'));
			if (!axios.isCancel(err)) {
				setIsDeleteModalShown(false);
			}
		}
	};

	return (
		<>
			<CommentContainer $isNew={isNew} $isDeleted={isDeleted} $stepDeleted={stepDeleted}>
				<CommentHeader>
					<CommentUserAvatarContainer>
						<UsersPreview popupProps={{ $alignment: 'left' }} users={{ users: user }} />
					</CommentUserAvatarContainer>
					<CommentAuthor $isCreator={isCreator} data-text-element>
						{user.fullName}
					</CommentAuthor>
					<CommentDate data-text-element>{formatedDate}</CommentDate>
					{stepDeleted && <DeletedText>{t('deleted')}</DeletedText>}
					{isEdited && <EditText data-text-element>{t('edited')}</EditText>}
					{isCreator && !hideActions && !stepDeleted && (
						<StyledMoreActionsButton label="Projects Comments More Actions" controlledVisibility>
							<MenuItem
								type="button"
								data-action="edit"
								onClick={() => setIsInEditMode(prev => !prev)}
								label="Projects Comments - Edit Menu Item"
							>
								Edit
							</MenuItem>

							<MenuItem
								type="button"
								data-action="delete"
								onClick={() => setIsDeleteModalShown(true)}
								label="Projects Comments - Delete Menu Item"
							>
								Delete
							</MenuItem>
						</StyledMoreActionsButton>
					)}
				</CommentHeader>
				{Boolean(subHeader) && subHeader}
				<CommentContent>
					{isInEditMode ? (
						<EditCommentContainer>
							<FormikTextArea name="comment" value={editedComment} onChange={handleTextAreaChange} />
							<StyledSendButton $disabled={editedComment.trim() === ''} onClick={handleEditComment}>
								<Icon icon="send" />
							</StyledSendButton>
						</EditCommentContainer>
					) : (
						<CommentText data-text-element $hideOverflow={hideOverflow}>
							{commentText}
						</CommentText>
					)}
				</CommentContent>
			</CommentContainer>
			<ConfirmModal
				isOpen={isDeleteModalShown}
				onConfirm={() => handleDeleteComment()}
				onCancel={() => setIsDeleteModalShown(false)}
				confirmText="Delete"
				label="delete-comment"
				heading={t('Delete Comment')}
				text={t('Are you sure you want to delete this comment?')}
			/>
		</>
	);
};

Comment.propTypes = {
	comment: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		text: PropTypes.string.isRequired,
		user: PropTypes.shape({
			email: PropTypes.string.isRequired,
			fullName: PropTypes.string.isRequired,
		}).isRequired,
		createdDate: PropTypes.string.isRequired,
		isNew: PropTypes.bool,
		isDeleted: PropTypes.bool,
		stepDeleted: PropTypes.bool,
		subHeader: PropTypes.node,
	}).isRequired,
	onEditComment: PropTypes.func,
	onDeleteComment: PropTypes.func,
	hideActions: PropTypes.bool,
	hideOverflow: PropTypes.bool,
};

Comment.defaultProps = {
	onEditComment: () => {},
	onDeleteComment: () => {},
	hideActions: false,
	hideOverflow: false,
};

export default Comment;
