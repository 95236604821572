import getMyActiveNoneOpenProjects from 'Dashboard/api/getMyActiveNoneOpenProjects';
import DashboardTab from 'Dashboard/components/DashboardTab';
import useMyProjectsTabStepModifier from 'Dashboard/components/MyActivities/Tabs/MyProjectsTab/hooks/useMyProjectsTabStepModifier';

const MyProjectsTab = () => (
	<DashboardTab
		helpText="Projects assigned to you which are waiting on other teams."
		getterFunction={getMyActiveNoneOpenProjects}
		stepModifier={useMyProjectsTabStepModifier}
	/>
);

export default MyProjectsTab;
