import styled from 'styled-components/macro';
import TableField from 'Common/components/form/Table/TableField';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import { PropTypes } from 'prop-types';

const StyledDatePicker = styled(FormikSingleDatePicker)`
	width: 100%;
	height: 100%;
`;

const TableSingleDatePicker = ({
	error,
	touched,
	startDate,
	startDateName,
	endDate,
	endDateName,
	setFieldValue,
	setFieldTouched,
	isDual,
	id,
}) => (
	<TableField>
		<StyledDatePicker
			startDateName={startDateName}
			startDate={startDate}
			error={error}
			touched={touched}
			setFieldValue={setFieldValue}
			setFieldTouched={setFieldTouched}
			endDateName={endDateName}
			endDate={endDate}
			isDual={isDual}
			isForm
			id={id}
		/>
	</TableField>
);

TableSingleDatePicker.propTypes = {
	error: PropTypes.string.isRequired,
	touched: PropTypes.bool.isRequired,
	startDate: PropTypes.string.isRequired,
	startDateName: PropTypes.string.isRequired,
	endDate: PropTypes.string.isRequired,
	endDateName: PropTypes.string.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	isDual: PropTypes.bool.isRequired,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default TableSingleDatePicker;
