import getRegionalHubs from 'Preferences/api/getRegionalHubs';

const loadRegionalHubOptions = async (signal, query) => {
	const response = await getRegionalHubs(signal, false, query);
	const regionalHubs = response.data;

	return regionalHubs.map(regionalHub => ({
		value: regionalHub.id,
		label: regionalHub.name,
	}));
};

export default loadRegionalHubOptions;
