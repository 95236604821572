import { useMemo } from 'react';
import * as yup from 'yup';

const useQualificationsFormValidationSchema = () =>
	useMemo(
		() =>
			yup.object({
				internalQualificationPassed: yup.bool(),
				internalPassDate: yup.mixed().when('internalQualificationPassed', {
					is: true,
					then: yup.mixed().required('Required'),

					otherwise: null,
				}),
				externalQualificationPassed: yup.bool(),
				externalPassDate: yup.mixed().when('externalQualificationPassed', {
					is: true,
					then: yup.mixed().required('Required'),

					otherwise: null,
				}),
			}),
		[],
	);

export default useQualificationsFormValidationSchema;
