import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { push } from 'redux-first-history';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import Filters from 'Common/components/filter/FiltersContainer';
import InvestorsListTable from 'Investors/components/InvestorsList/InvestorsListTable';
import useSmartResize from 'Common/hooks/table/useSmartResize';
import useInvestorsListFilterData from 'Investors/components/InvestorsList/hooks/useInvestorsListFilterData';

const InvestorsList = () => {
	const { t } = useTranslation();
	const storedTableState = useSelector(state => state.tables.investors);
	const [shouldTableRender, setShouldTableRender] = useState(false);
	const dispatch = useDispatch();

	const location = useLocation();

	useEffect(() => {
		if (!shouldTableRender) {
			if (location.search === '' && storedTableState && storedTableState.url) {
				dispatch(push(`/investors${storedTableState.url}`));
			}
			setShouldTableRender(true);
		}
	}, [dispatch, storedTableState, location.search, shouldTableRender]);

	const filterButtonRef = useRef();
	const filtersData = useInvestorsListFilterData();

	const filtersRef = useRef();

	// eslint-disable-next-line
	const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
	useSmartResize(filtersRef, setForceUpdateCounter);

	return (
		<>
			<Helmet>
				<title>{t('Investors')}</title>
			</Helmet>
			<HeaderContainer>
				<HeaderTitle>{t('Investors')}</HeaderTitle>
				<HeaderActions>
					<div ref={filterButtonRef}></div>
				</HeaderActions>
			</HeaderContainer>
			<div ref={filtersRef}>
				<Filters table="investors" buttonRef={filterButtonRef} filtersData={filtersData} />
			</div>

			{(shouldTableRender || !storedTableState?.url) && <InvestorsListTable />}
		</>
	);
};

export default InvestorsList;
