import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import crudModes from 'Common/constants/crudModes';
import EpcInquiryForm from 'EpcPartner/components/EpcPartnerPortal/EpcInquiryCreate/EpcInquiryCreateForm/EpcInquiryForm';

const EpcInquiryCreate = () => {
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<title>{t('Create Inquiry')}</title>
			</Helmet>

			<EpcInquiryForm mode={crudModes.CREATE} formParent={'epcPortal'} />
		</>
	);
};

export default EpcInquiryCreate;
