import { createRef, useCallback, useLayoutEffect, useRef } from 'react';
import { useWindowHeight } from '@react-hook/window-size';
import { actions } from 'react-table';
import useUserOnPage from 'Common/hooks/useUserOnPage';

// Actions
actions.setBodyHeight = 'setBodyHeight';

// Reducer
function reducer(state, action) {
	if (action.type === actions.init) {
		return {
			...state,
			tableRef: createRef(),
			headerRef: createRef(),
			footerRef: createRef(),
		};
	}

	if (action.type === actions.setBodyHeight) {
		const { bodyHeight } = action;

		return {
			...state,
			bodyHeight,
		};
	}
}

const pluginName = 'useScrollableTableBody';

export const useScrollableTableBody = hooks => {
	hooks.stateReducers.push(reducer);
	hooks.useInstance.push(useInstance);

	hooks.getTableProps.push(getTableProps);
	hooks.getTableBodyProps.push(getTableBodyProps);

	hooks.getTableHeaderProps.push(getTableHeaderProps);
	hooks.getHeaderGroupProps.push(getHeaderGroupProps);

	hooks.getTableFooterProps.push(getTableFooterProps);
	hooks.getFooterGroupProps.push(getFooterGroupProps);
};

useScrollableTableBody.pluginName = pluginName;

const intervalBetweenChecks = 1000;

const useInstance = instance => {
	const { dispatch } = instance;

	const userOnPage = useUserOnPage();

	const timeoutId = useRef();
	const savedBodyHeight = useRef(0);
	const windowHeight = useWindowHeight();

	const loop = useCallback(() => {
		if (!userOnPage) {
			return;
		}

		const tableMarker = document.getElementById('table-body-marker-dot');
		const pageMarker = document.getElementById('page-top-marker-dot');
		const tableTop = tableMarker.getBoundingClientRect().top;
		const pageTop = pageMarker.getBoundingClientRect().top;
		const top = Math.max(pageTop, tableTop) - Math.min(pageTop, tableTop);
		const footer = document.getElementById('table-footer');
		const footerHeight = footer ? footer.getBoundingClientRect().height : 0;
		const bodyHeight = windowHeight - top - footerHeight;

		if (bodyHeight !== savedBodyHeight.current) {
			dispatch({
				type: actions.setBodyHeight,
				bodyHeight,
			});
			savedBodyHeight.current = bodyHeight;
		}

		timeoutId.current = setTimeout(loop, intervalBetweenChecks);
	}, [dispatch, userOnPage, windowHeight]);

	useLayoutEffect(() => {
		loop();

		return () => {
			clearTimeout(timeoutId.current);
		};
	}, [loop]);
};

const getTableProps = (props, { instance }) => [
	props,
	{
		ref: instance.state.tableRef,
	},
];

const getTableBodyProps = (props, { instance }) => [
	props,
	{
		style: {
			height: instance.state.bodyHeight,
		},
	},
];

const getTableHeaderProps = (props, { instance }) => [
	props,
	{
		ref: instance.state.headerRef,
		style: {
			position: 'sticky',
			top: 0,
		},
	},
];

const getHeaderGroupProps = props => [
	props,
	{
		style: {
			width: 'calc(100% - 10px)',
			minWidth: 'calc(100% - 10px)',
		},
	},
];

const getTableFooterProps = (props, { instance }) => [
	props,
	{
		ref: instance.state.footerRef,
		style: {
			position: 'sticky',
			bottom: 0,
		},
	},
];

const getFooterGroupProps = props => [
	props,
	{
		style: {
			width: 'calc(100% - 10px)',
			minWidth: 'calc(100% - 10px)',
		},
	},
];
