import getProjectsPartials from 'Projects/api/getProjectsPartials';

const loadProjectOptions = async () => {
	const response = await getProjectsPartials();

	const projects = response.data.content;
	return projects.map(project => ({
		value: project.value,
		label: project.label,
	}));
};

export default loadProjectOptions;
