import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Grid from '@mui/material/Grid';
import { css } from 'styled-components';
import { PropTypes } from 'prop-types';

const DynamicSectionContainer = styled(Grid)`
	display: flex;
	flex-direction: ${({ $hasFlag }) => ($hasFlag ? 'column' : 'column-reverse')} !important;
	z-index: 1;
`;

const Section = styled.div`
	z-index: ${({ $hasFlag }) => ($hasFlag ? '1' : '-1')};
	${({ hasLoaded }) =>
		hasLoaded
			? css`
					animation: ${({ $hasFlag, type }) => ($hasFlag ? 'fadeIn' + type : 'fadeOut' + type)}
						${({ hasMounted }) => (hasMounted ? '0.6s' : '0.0s')} linear;
					animation-fill-mode: forwards;
			  `
			: css`
					height: auto;
			  `}

	@keyframes ${({ type }) => 'fadeIn' + type} {
		0% {
			opacity: 0.1;
			height: ${({ otherSectionHeight }) => otherSectionHeight}px;
		}
		50% {
			opacity: 0.5;
			height: ${({ currentSectionHeight }) => currentSectionHeight}px;
		}
		100% {
			opacity: 1;
			height: auto;
		}
	}

	@keyframes ${({ type }) => 'fadeOut' + type} {
		0% {
			opacity: 0.1;
			height: 0px;
		}
		50% {
			opacity: 0;
			height: 0px;
		}
		100% {
			opacity: 0;
			height: 0px;
			transform: scale(0.3);
		}
	}
`;

const TileDynamicSection = ({
	asyncCondition,
	isAsync,
	isInEditMode,
	editView,
	readonlyView,
	gridOptions,
	uniqueId,
}) => {
	const readonlyViewRef = useRef();
	const editViewRef = useRef();

	const [readonlyHeight, setReadonlyHeight] = useState(0);
	const [editHeight, setEditHeight] = useState(0);

	const [hasLoaded, setHasLoaded] = useState(false);
	const [hasMounted, setHasMounted] = useState(false);

	useLayoutEffect(() => {
		const isReady = isAsync ? asyncCondition : true;
		if (editViewRef.current && readonlyViewRef.current && isReady) {
			setHasLoaded(false);
			requestAnimationFrame(() => {
				const readHeight = readonlyViewRef.current?.getBoundingClientRect().height;
				setReadonlyHeight(readHeight);

				const editHeight = editViewRef.current?.getBoundingClientRect().height;
				setEditHeight(editHeight);
				setHasLoaded(true);
			});
		}
	}, [editViewRef, readonlyViewRef, asyncCondition, isAsync, isInEditMode]);

	useEffect(() => {
		if (hasLoaded) {
			setTimeout(() => {
				setHasMounted(true);
			}, 600);
		}
	}, [hasLoaded]);

	return (
		<DynamicSectionContainer $hasFlag={isInEditMode} {...gridOptions}>
			<Section
				hasLoaded={hasLoaded}
				$hasFlag={!isInEditMode}
				currentSectionHeight={readonlyHeight}
				otherSectionHeight={editHeight}
				ref={readonlyViewRef}
				type={'Read' + uniqueId}
				hasMounted={hasMounted}
			>
				{readonlyView}
			</Section>
			<Section
				hasLoaded={hasLoaded}
				$hasFlag={isInEditMode}
				currentSectionHeight={editHeight}
				otherSectionHeight={readonlyHeight}
				ref={editViewRef}
				type={'Edit' + uniqueId}
				hasMounted={hasMounted}
			>
				{editView}
			</Section>
		</DynamicSectionContainer>
	);
};

TileDynamicSection.defaultProps = {
	asyncCondition: '',
	isAsync: false,
	editView: null,
	readonlyView: null,
	gridOptions: [],
	uniqueId: null,
};

TileDynamicSection.propTypes = {
	asyncCondition: PropTypes.string,
	isAsync: PropTypes.bool,
	isInEditMode: PropTypes.bool.isRequired,
	editView: PropTypes.shape({}),
	readonlyView: PropTypes.shape({}),
	gridOptions: PropTypes.arrayOf(PropTypes.shape({})),
	uniqueId: PropTypes.string,
};

export default TileDynamicSection;
