import useAbortController from 'Common/hooks/useAbortController';
import { useCallback } from 'react';
import addComment from 'Projects/api/addComment';
import showToastError from 'Common/utils/showToastError';
import { useTranslation } from 'react-i18next';
import deleteComment from 'Projects/api/deleteComment';
import editComment from 'Projects/api/editComment';
import addSubHeaderToComment from 'Projects/components/Comments/utils/addSubHeaderToComment';
import { useMemo } from 'react';

const useProjectDetailsCommentsData = project => {
	const { t } = useTranslation();
	const defaultComments = useMemo(() => project?.stepComments.map(addSubHeaderToComment), [project]);
	const abortController = useAbortController();

	const onAddComment = useCallback(
		async newCommentText => {
			try {
				const newCommentsResponse = await addComment(abortController.signal, {
					projectId: project.id,
					text: newCommentText,
				});

				const newProjectComments = newCommentsResponse.data.map(addSubHeaderToComment);

				return newProjectComments;
			} catch (err) {
				showToastError(err, t('A problem occured while trying to send a comment'));
			}
		},
		[abortController.signal, project.id, t],
	);

	const onEditComment = useCallback(
		async (editedCommentId, editedCommentText) => {
			try {
				const commentChange = {
					commentId: editedCommentId,
					text: editedCommentText,
				};
				await editComment(abortController.signal, commentChange);
			} catch (err) {
				showToastError(err, t('Could not edit comment.'));
			}
		},
		[abortController.signal, t],
	);

	const onDeleteComment = useCallback(
		async deletedCommentId => {
			try {
				await deleteComment(abortController.signal, deletedCommentId);
			} catch (err) {
				showToastError(err, t('Could not delete comment.'));
			}
		},
		[abortController.signal, t],
	);

	return { defaultComments, onAddComment, onEditComment, onDeleteComment };
};

export default useProjectDetailsCommentsData;
