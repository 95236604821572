import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

import formatNumber from 'Common/utils/formatNumber';
import MultiStepProgressBar from 'Common/components/MultiStepProgressBar';

const SystemInfoSummary = styled.div`
	display: flex;
	justify-content: space-between;
`;

const AllocatedStatsContainer = styled.div`
	padding-top: 10px;
	padding-bottom: 15px;
`;
const AlllocatedStat = styled.div`
	&:before {
		content: '';
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		margin-right: 7px;
		background-color: ${props => props.color};
	}
`;

const ThinText = styled.span`
	font-size: 14px;
	line-height: 25px;
	color: ${colors.text.primary};
`;

const ProjectInfoSummary = ({
	projectType,
	systemSizeKwp,
	annualCO2Savings,
	otherCampaignsSystemSizeValue,
	otherCampaignsSystemSizePercent,
	currentCampaignSystemSize,
	currentCampaignSystemSizePercent,
}) => {
	const { t } = useTranslation();
	return (
		<>
			<SystemInfoSummary>
				<div>
					<ThinText>{projectType}</ThinText>
					&nbsp;
					<ThinText>{systemSizeKwp ?? 0} kWp</ThinText>
					&nbsp;
				</div>
				<ThinText>{annualCO2Savings ? formatNumber(annualCO2Savings, 1) : 0} t Annual CO2</ThinText>
			</SystemInfoSummary>

			<MultiStepProgressBar
				steps={[
					{
						value: otherCampaignsSystemSizeValue,
						color: colors.primary.dark,
					},
					{
						value: currentCampaignSystemSize,
						color: colors.common.orange,
					},
				]}
				totalSteps={systemSizeKwp}
				isStacked
				nextStep={currentCampaignSystemSize}
			/>

			<AllocatedStatsContainer>
				<AlllocatedStat color={colors.primary.dark}>
					<ThinText>
						{otherCampaignsSystemSizePercent ? formatNumber(otherCampaignsSystemSizePercent, 0) : 0}%{' '}
						{t('Other campaigns')}
					</ThinText>
				</AlllocatedStat>
				<AlllocatedStat color={colors.common.orange}>
					<ThinText>
						{currentCampaignSystemSizePercent ? formatNumber(currentCampaignSystemSizePercent, 0) : 0}%{' '}
						{t('This campaigns')}
					</ThinText>
				</AlllocatedStat>
			</AllocatedStatsContainer>
		</>
	);
};

ProjectInfoSummary.defaultProps = {
	projectType: '',
	systemSizeKwp: 0,
	annualCO2Savings: 0,
	currentCampaignSystemSize: 0,
	otherCampaignsSystemSizeValue: null,
	otherCampaignsSystemSizePercent: 0,
	currentCampaignSystemSizePercent: 0,
};

ProjectInfoSummary.propTypes = {
	projectType: PropTypes.string,
	systemSizeKwp: PropTypes.number,
	annualCO2Savings: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	currentCampaignSystemSize: PropTypes.number,
	otherCampaignsSystemSizeValue: PropTypes.shape({}),
	otherCampaignsSystemSizePercent: PropTypes.number,
	currentCampaignSystemSizePercent: PropTypes.number,
};

export default ProjectInfoSummary;
