import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import PropTypes from 'prop-types';

import formatNumber from 'Common/utils/formatNumber';

const ThinText = styled.span`
	font-size: 14px;
	color: ${colors.text.black};
`;

const BigText = styled.span`
	font-size: 24px;
	color: ${colors.common.orange};
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const InfoWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
`;

const ProjectInfoSystemSizeView = ({ systemSizeKwp, systemSizePercent }) => (
	<Wrapper>
		<InfoWrapper>
			<BigText>{formatNumber(systemSizeKwp, 2)} kWp</BigText>
		</InfoWrapper>
		<InfoWrapper>
			<ThinText>{systemSizePercent ? systemSizePercent.toFixed(0) : 0}%</ThinText>
		</InfoWrapper>
	</Wrapper>
);

ProjectInfoSystemSizeView.defaultProps = {
	systemSizeKwp: 0,
	systemSizePercent: 0,
};

ProjectInfoSystemSizeView.propTypes = {
	systemSizeKwp: PropTypes.number,
	systemSizePercent: PropTypes.number,
};

export default ProjectInfoSystemSizeView;
