/* eslint-disable complexity */
import Big from 'big.js';
import { parseISO } from 'date-fns';
import projectTypes from 'Projects/constants/projectTypes';
import { useMemo } from 'react';

const fallbackValues = {
	epcPartner: null,
	initialEngagementDate: undefined,
	hideClientName: false,
	systemSize: '',
	yield: '',
	solarUtilisationRate: '',
	expectedGridInjection: '',
	epcVolumeExclVat: undefined,
	currency: {
		value: 'EUR',
	},
	annualOmPrice: undefined,
	annualOmEscalationRate: '',
	epcVolumeSwitch: false,
	epcVolumeExclVatKwp: undefined,
	omPriceSwitch: false,
	annualOmPriceKwp: undefined,
	offerValidityDate: '',
	assumptions: '',
	exclusions: '',
	variations: '',
	gensetsInfo: '',
};

const useInquiryOverlayValues = (project, epcOffer) => {
	const defaultValues = useMemo(
		() => ({
			epcPartner: epcOffer?.epcPartner
				? {
						label: epcOffer?.epcPartner?.name,
						value: epcOffer?.epcPartner?.id,
				  }
				: fallbackValues.epcPartner,
			initialEngagementDate: epcOffer?.initialEngagementDate
				? parseISO(epcOffer?.initialEngagementDate)
				: fallbackValues.initialEngagementDate,
			hideClientName: epcOffer?.hideClientName || fallbackValues.hideClientName,
			systemSize: epcOffer?.systemSize || fallbackValues.systemSize,
			yield: epcOffer?.yield || fallbackValues.yield,
			solarUtilisationRate: epcOffer?.solarUtilisationRate
				? Big(epcOffer?.solarUtilisationRate).times(100).toString()
				: fallbackValues.solarUtilisationRate,
			epcVolumeExclVat: epcOffer?.epcVolumeExclVat?.value || fallbackValues.epcVolumeExclVat,
			epcVolumeExclVatKwp: epcOffer?.epcVolumeExclVatKwp?.value || fallbackValues.epcVolumeExclVatKwp,
			annualOmPrice: epcOffer?.annualOmPrice?.value || fallbackValues.annualOmPrice,
			annualOmPriceKwp: epcOffer?.annualOmPriceKwp?.value || fallbackValues.annualOmPriceKwp,
			annualOmEscalationRate: epcOffer?.annualOmEscalationRate
				? Big(epcOffer?.annualOmEscalationRate).times(100).toString()
				: fallbackValues.annualOmEscalationRate,
			epcVolumeSwitch: !!epcOffer?.epcVolumeExclVat?.calculated || fallbackValues.epcVolumeSwitch,
			omPriceSwitch: !!epcOffer?.annualOmPrice?.calculated || fallbackValues.omPriceSwitch,

			offerValidityDate: epcOffer?.offerValidityDate
				? parseISO(epcOffer?.offerValidityDate)
				: fallbackValues.offerValidityDate,
			gensetsInfo: epcOffer?.gensetsInfo || fallbackValues.gensetsInfo,
			assumptions: epcOffer?.assumptions || fallbackValues.assumptions,
			exclusions: epcOffer?.exclusions || fallbackValues.exclusions,
			variations: epcOffer?.variations || fallbackValues.variations,
			currency: epcOffer?.currency ? { value: epcOffer.currency } : fallbackValues.currency,
			isPVProject: project?.projectType?.name === projectTypes.PV,
		}),
		[project, epcOffer],
	);

	const sectionFields = useMemo(
		() => ({
			general: ['epcPartner', 'initialEngagementDate', 'hideClientName'],
			size: ['systemSize', 'yield', 'solarUtilisationRate'],
			volume: ['epcVolumeExclVat', 'epcVolumeExclVatKwp', 'currency', 'epcVolumeSwitch'],
			om: ['annualOmPrice', 'annualOmPriceKwp', 'annualOmEscalationRate', 'omPriceSwitch'],
			proposal: ['offerValidityDate', 'gensetsInfo', 'assumptions', 'exclusions', 'variations'],
		}),
		[],
	);

	return {
		defaultValues,
		sectionFields,
	};
};

export default useInquiryOverlayValues;
