import api from 'Application/api/api';

const getRoofTypes = (signal, query) =>
	api.get('/roof-type', {
		params: {
			query,
		},
		signal,
	});

export default getRoofTypes;
