import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useConfirmPasswordResetFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				password: yup
					.string()
					.required(t('Required'))
					.min(8, t('The password must contain at least 8 characters'))
					.matches(
						/^(?=.*[A-Z])(?=.*[0-9!@#$%^&*()\-+=_~`[\]{}:;'"<>?\\/,.])/,
						t('The password must contain an uppercase letter and either a number or a special character'),
					),
				passwordConfirm: yup
					.string()
					.required(t('Required'))
					.oneOf([yup.ref('password')], t('The passwords must match')),
			}),
		[t],
	);
};

export default useConfirmPasswordResetFormValidationSchema;
