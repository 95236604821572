import api from 'Application/api/api';

const deleteEntity = async (signal, id) => {
	const response = await api.delete(`/contract-parties/${id}`, {
		signal,
		timeout: 100000,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: response.data,
	};
};

export default deleteEntity;
