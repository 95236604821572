import getTags from 'Preferences/api/getTags';

const loadTagOptions = async (signal, query) => {
	const response = await getTags(signal);
	const tags = response.data;

	return tags
		.filter(tag => (query ? tag.name.toLowerCase().includes(query.toLowerCase()) : true))
		.map(tag => ({
			value: tag.id,
			label: tag.name,
		}));
};

export default loadTagOptions;
