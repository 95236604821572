const EPC_PARTNER_DETAILS_PAGE_TAB_TILES = {
	GENERAL: {
		DETAILS: 'details',
		ADDRESS: 'address',
		QUALIFICATIONS: 'qualifications',
		MANAGERS: 'managers',
	},
};

const EPC_PARTNER_DETAILS_PAGE_TILES_BY_TAB = {
	GENERAL: Object.values(EPC_PARTNER_DETAILS_PAGE_TAB_TILES.GENERAL),
};

const EPC_OFFER_DETAILS_PAGE_TAB_TILES = {
	GENERAL: {
		CLIENT: 'client',
		LOCATION: 'location',
	},
	PROJECT: {
		TYPE: 'type',
		MOUNTING: 'mounting',
		GENSETS: 'gensets',
		CONNECTION: 'connection',
	},
	OFFER: {
		OFFER: 'offer',
	},
};

const EPC_OFFER_DETAILS_PAGE_TILES_BY_TAB = {
	GENERAL: Object.values(EPC_OFFER_DETAILS_PAGE_TAB_TILES.GENERAL),
	PROJECT: Object.values(EPC_OFFER_DETAILS_PAGE_TAB_TILES.PROJECT),
	OFFER: Object.values(EPC_OFFER_DETAILS_PAGE_TAB_TILES.OFFER),
};

const EPC_PARTNER_PROFILE_DETAILS_PAGE_TAB_TILES = {
	GENERAL: {
		DETAILS: 'details',
		ADDRESS: 'address',
	},
};

const EPC_PARTNER_PROFILE_DETAILS_PAGE_TILES_BY_TAB = {
	GENERAL: Object.values(EPC_PARTNER_PROFILE_DETAILS_PAGE_TAB_TILES.GENERAL),
};

export {
	EPC_PARTNER_DETAILS_PAGE_TAB_TILES,
	EPC_PARTNER_DETAILS_PAGE_TILES_BY_TAB,
	EPC_OFFER_DETAILS_PAGE_TAB_TILES,
	EPC_OFFER_DETAILS_PAGE_TILES_BY_TAB,
	EPC_PARTNER_PROFILE_DETAILS_PAGE_TAB_TILES,
	EPC_PARTNER_PROFILE_DETAILS_PAGE_TILES_BY_TAB,
};
