import styled from 'styled-components/macro';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import colors from 'Application/theme/colors';

const Wrapper = styled.div`
	padding: 20px;

	.rc-slider-rail {
		height: 8px;
		background-color: ${colors.grey.main};
	}
	.rc-slider-track {
		background-color: ${colors.primary.main};
		height: 8px;
	}
	.rc-slider-step {
		height: 8px;
	}
	.rc-slider-handle {
		border: solid 2px ${colors.primary.main};
		margin-top: -6px;
		height: 20px;
		width: 20px;
	}
`;

const SliderRange = props => (
	<Wrapper>
		<Slider range {...props} tipProps={{ zIndex: 3 }} />
	</Wrapper>
);

export default SliderRange;
