import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

import customGAEvent from 'Common/utils/customGAEvent';

const Button = styled.button`
	display: flex;
	justify-content: flex-start;
	width: 100%;
	padding: 10px 30px 10px 20px;
	background: ${colors.common.white};
	transition: background 150ms cubic-bezier(0.4, 0, 0.2, 1);
	border: 0 none;
	outline: 0 none;
	cursor: pointer;

	${({ disabled }) =>
		!disabled &&
		css`
			&:hover {
				color: ${colors.text.white};
				background: ${colors.common.darkBlue};
			}
		`}
`;

const MenuItem = ({ innerRef, onClick, label, dataAction, ...props }) => (
	<Button
		innerRef={innerRef}
		onClick={(...args) => {
			customGAEvent('button', 'click', label);
			onClick(...args);
		}}
		data-action={dataAction}
		{...props}
	/>
);

MenuItem.defaultProps = {
	label: null,
	innerRef: null,
	dataAction: null,
	onClick: () => {},
};

MenuItem.propTypes = {
	onClick: PropTypes.func,
	label: PropTypes.string,
	innerRef: PropTypes.string,
	dataAction: PropTypes.string,
};

export default MenuItem;
