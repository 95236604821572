import api from 'Application/api/api';

const reassignIndustry = async (signal, values) => {
	const response = await api.post('/clients/re-assign-industry', null, {
		params: { ...values },
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
	};
};

export default reassignIndustry;
