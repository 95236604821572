import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import SalesChannelForm from 'Preferences/components/Tabs/GeneralTab/SalesChannel/SalesChannelForm/SalesChannelForm';
import crudModes from 'Common/constants/crudModes';
import { PropTypes } from 'prop-types';

const SalesChannelCreateModal = ({ entityId, isOpen, onClose, onFormSubmit }) => {
	const { t } = useTranslation();

	return (
		<ModalForm
			label="sales-channel-create"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			heading={t('Create Sales channel')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<SalesChannelForm
					mode={crudModes.CREATE}
					entityId={entityId}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

SalesChannelCreateModal.propTypes = {
	entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default SalesChannelCreateModal;
