import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import styled from 'styled-components/macro';

const SubHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: ${sizes.spacing(1)};
	gap: ${sizes.spacing(2)};
`;

const CommentOrigin = styled.div`
	font-size: 14px;
	color: ${colors.text.grey};
`;

const ActivityName = styled.span`
	font-weight: 700;
`;

const addSubHeaderToComment = comment => ({
	...comment,
	subHeader:
		comment?.activityTitle && comment?.stepTitle ? (
			<SubHeader>
				<CommentOrigin data-text-element>
					{comment?.activityTitle} / <ActivityName>{comment?.stepTitle}</ActivityName>
				</CommentOrigin>
			</SubHeader>
		) : null,
});

export default addSubHeaderToComment;
