import api from 'Application/api/api';

const getCurrencies = (signal, query) =>
	api.get('/countries/currencies', {
		params: {
			query,
		},
		signal,
	});

export default getCurrencies;
