import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const usePostCompletionFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				pctRequiredPR: yup
					.number()
					.typeError(t('Required PR must be a number'))
					.min(0, t('Required PR must be positive'))
					.nullable(),
				pctPRCalculationResult: yup
					.number()
					.typeError(t('Required PR must be a number'))
					.min(0, t('Required PR must be positive'))
					.nullable(),
				pcdAmount: yup
					.number()
					.typeError(t('Required PR must be a number'))
					.min(0, t('Required PR must be positive'))
					.nullable(),
			}),
		[t],
	);
};

export default usePostCompletionFormValidationSchema;
