import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';
import projectTypes from 'Projects/constants/projectTypes';

const useProjectDetailsFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				systemSizeKwp: yup
					.number()
					.typeError(t('System size must be a number'))
					.positive(t('System size must be positive'))
					.test(
						'decimal-places',
						'System size must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable(),
				pvDegradationRate: yup.mixed().when('projectType', {
					is: value => value?.label === projectTypes.PV,
					then: yup
						.number()
						.nullable()
						.required(t('PV degradation rate is required'))
						.positive(t('Must be positive'))
						.max(100, t('Must be less than or equal to 100'))
						.typeError(t('Must be a number'))
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						)
						.test(
							'decimal-places',
							t('Must have no more than 1 decimal places'),
							value => value === undefined || String(value).match(/^\d*(\.\d{1})?$/),
						),
					otherwise: null,
				}),
				predictedAnnualEnergySavings: yup
					.number()
					.nullable()
					.positive(t('Must be positive'))
					.typeError(t('Must be a number'))
					.test(
						'decimal-places',
						t('Must have no more than 6 decimal places'),
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,6})?$/),
					),
				meterQuantity: yup
					.number()
					.typeError(t('Meter quantity must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.integer(t('Meter quantity must be a whole number'))
					.positive(t('Meter quantity must be positive'))
					.max(100, t('Meter quantity must be less than or equal to 100'))
					.nullable(),

				generatorSize: yup
					.number()
					.typeError(t('Generator size (kVA) must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.integer(t('Generator size (kVA) must be a whole number'))
					.positive(t('Generator size (kVA) must be positive'))
					.nullable(),

				generatorQuantity: yup
					.number()
					.typeError(t('Generator quantity must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.integer(t('Generator quantity must be a whole number'))
					.positive(t('Generator quantity must be positive'))
					.nullable(),

				kwhToKwpRel: yup.mixed().when('projectType', {
					is: val => val?.label === projectTypes.PV,
					then: yup
						.number()
						.positive(t('Yield (kWh/kWp) must be positive'))
						.test(
							'decimal-places',
							t('Yield (kWh/kWp) must have no more than 2 decimal places'),
							value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
						)
						.test(
							'yield-range',
							t('Yield must be between 500 and 3000'),
							value => !value || (value <= 3000 && value >= 500),
						)
						.typeError(t('Yield (kWh/kWp) must be a number'))
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						)
						.nullable(),
					otherwise: null,
				}),
				roofType: yup.mixed().when(['mountingTypes', 'projectType'], {
					is: (mountingTypes, projectType) =>
						projectType.label &&
						projectType.label === projectTypes.PV &&
						mountingTypes &&
						mountingTypes.filter(e => e?.label === 'Roof' || e?.label === 'Carpark').length > 0,
					then: yup.mixed().required('Roof type is Required'),
					otherwise: null,
				}),
				mountingTypes: yup.mixed().when(['projectType'], {
					is: projectType => projectType?.label === projectTypes.PV,
					then: yup.array().min(1, t('Mounting type is Required')).required(t('Mounting type is Required')),
					otherwise: null,
				}),
				solarUtilisationRate: yup.mixed().when('projectType', {
					is: value => value?.label === projectTypes.PV,
					then: yup
						.number()
						.typeError(t('Solar utilization rate must be a number'))
						.positive(t('Solar utilization rate must be positive'))
						.test(
							'decimal-places',
							t('Solar utilization rate must have no more than 2 decimal places'),
							value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
						)
						.typeError(t('Solar utilization rate must be a number'))
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						)
						.max(100, t('Solar utilization rate must be less than or equals to 100'))
						.nullable()
						.required(t('Solar utilization rate is Required')),
					otherwise: null,
				}),

				gridEnergyTariffPerKwh: yup
					.number()
					.nullable()
					.typeError(t('Grid energy tariff must be a number'))
					.positive(t('Grid energy tariff must be positive'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.test(
						'decimal-places',
						t('Grid energy tariff must have no more than 3 decimal places'),
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,3})?$/),
					),

				projectType: yup
					.object({
						label: yup.string(),
						value: yup.number(),
					})
					.nullable()
					.test({
						message: t('Project type is Required'),
						test: value => value?.label !== '' && value?.label !== undefined,
					}),

				batterySizeInKw: yup
					.mixed()
					.nullable()
					.when(['projectType', 'energyStorage'], {
						is: (projectType, energyStorage) => projectType?.label === 'ES' || energyStorage,
						then: yup
							.number()
							.typeError(t('Battery size must be a number'))
							.transform((val, originalValue) =>
								typeof originalValue === 'string' && originalValue === '' ? null : val,
							)
							.integer(t('Battery size must be a whole number'))
							.positive(t('Battery size must be positive'))
							.nullable(),
						otherwise: null,
					}),

				batteryCapacityInKwh: yup
					.mixed()
					.nullable()
					.when(['projectType', 'energyStorage'], {
						is: (projectType, energyStorage) => projectType?.label === 'ES' || energyStorage,
						then: yup
							.number()
							.typeError(t('Battery capacity must be a number'))
							.transform((val, originalValue) =>
								typeof originalValue === 'string' && originalValue === '' ? null : val,
							)
							.integer(t('Battery capacity must be a whole number'))
							.positive(t('Battery capacity must be positive'))
							.nullable(),
						otherwise: null,
					}),

				availableSpace: yup
					.number()
					.typeError(t('Available space must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.integer(t('Available space must be a whole number'))
					.positive(t('Available space must be positive'))
					.nullable(),
			}),
		[t],
	);
};

export default useProjectDetailsFormValidationSchema;
