import api from 'Application/api/api';

const expellContact = (signal, contactId, data) =>
	api.patch(
		'/contacts/mark-as-expelled',
		{ ...data, contactId },
		{
			signal,
		},
	);

export default expellContact;
