import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import OnHoldIcon from 'Projects/components/OnHoldIcon';

import colors from 'Application/theme/colors';

import {
	newStatusesLabels,
	statusColorMap,
	statusDescriptions,
	newStatuses,
} from 'Campaigns/constants/campaignStatuses';
import { hasMissingData, generateTooltipMessage } from 'Campaigns/utils/campaignHelpers';
import { PropTypes } from 'prop-types';

import Link from 'Common/components/Link';
import Icon from 'Common/components/icons/Icon';
import formatDate from 'Common/utils/formatDate';
import formatNumber from 'Common/utils/formatNumber';
import StatusPill from 'Common/components/StatusPill';
import PillWrapper from 'Common/components/PillWrapper';
import formatCurrency from 'Common/utils/formatCurrency';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { DefaultTable, TableHeadCell, TableBodyCell } from 'Common/components/table/table';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}

	${TableBodyCell} {
		background: transparent;
	}
`;

const StyledOnHoldIcon = styled(OnHoldIcon)`
	width: 20px;
	height: 20px;
`;

const CampaignList = ({ project }) => {
	const { t } = useTranslation();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Status'),
				accessor: 'status',
				minWidth: 220,
				highlightSearchTerm: true,
				customBodyCellStyles: {
					paddingLeft: '4px',
				},
				Cell: (() => {
					const Cell = ({ row: { original: campaign } }) =>
						campaign.status ? (
							<PillWrapper>
								<HoverTooltip placement="right" title={statusDescriptions[campaign.status] || ''} arrow>
									<StatusPill color={statusColorMap[campaign.status]}>{newStatusesLabels[campaign.status]}</StatusPill>
								</HoverTooltip>
								{campaign.on_hold && (
									<HoverTooltip placement="right" title={t('On hold and not listed on the ecoligo website.')} arrow>
										<StyledOnHoldIcon />
									</HoverTooltip>
								)}
								{campaign.status !== newStatuses.FUNDED_COMPLETE &&
									campaign.status !== newStatuses.FUNDED_PARTLY &&
									(!campaign?.synched || hasMissingData(campaign)) && (
										<HoverTooltip placement="right" title={generateTooltipMessage(campaign, t)} arrow>
											<Icon icon="reportProblem" color={colors.warning.main} />
										</HoverTooltip>
									)}
							</PillWrapper>
						) : (
							'-'
						);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								status: PropTypes.string,
								on_hold: PropTypes.bool,
								investments_sum: PropTypes.string,
								synched: PropTypes.bool,
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Name'),
				accessor: 'name',
				maxWidth: 250,
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original: campaign } }) =>
						campaign.name ? (
							<Link
								color="#00b4d5"
								fontSize="14px"
								onClick={e => {
									e.stopPropagation();
								}}
								to={`/campaigns/details/${campaign?.auto_id}`}
							>
								{campaign.name}
							</Link>
						) : (
							'-'
						);
					Cell.propTypes = PropTypes.shape({}).isRequired;
					return Cell;
				})(),
			},
			{
				Header: t('% allocated'),
				accessor: 'allocated',
				maxWidth: 100,
				highlightSearchTerm: true,
				Cell: ({ row: { original: campaign } }) =>
					campaign.systemSizeAllocatedPercentage ? campaign.systemSizeAllocatedPercentage : '-',
			},
			{
				Header: t('Finance vol.'),
				accessor: 'maximum_volume',
				maxWidth: 90,
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original: campaigns } }) =>
						campaigns?.maximum_volume ? formatCurrency(campaigns?.maximum_volume) : '-';
					Cell.propTypes = { row: PropTypes.shape({ original: PropTypes.shape({}) }).isRequired };
					return Cell;
				})(),
			},
			{
				Header: t('Investments'),
				accessor: 'investments_sum',
				maxWidth: 100,
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original: campaign } }) => (
						<div title={campaign?.investments_sum}>
							{campaign?.investments_sum ? formatCurrency(campaign?.investments_sum) : '-'}
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ investments_sum: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Start date'),
				accessor: 'funding_start_date',
				maxWidth: 80,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				Cell: ({ row: { original: campaign } }) =>
					campaign.funding_start_date ? formatDate(campaign.funding_start_date) : '-',
			},
			{
				Header: t('End date'),
				accessor: 'funding_end_date',
				maxWidth: 80,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				disableSortBy: true,
				Cell: ({ row: { original: campaign } }) =>
					campaign.funding_end_date ? formatDate(campaign.funding_end_date) : '-',
			},
			{
				Header: t('Last investment'),
				accessor: 'last_investment_date',
				maxWidth: 130,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				Cell: (() => {
					const Cell = ({ row: { original: campaigns } }) =>
						campaigns.last_investment_date ? formatDate(campaigns.last_investment_date) : '-';
					Cell.propTypes = { row: PropTypes.shape({ original: PropTypes.shape({}) }).isRequired };
					return Cell;
				})(),
			},
			{
				Header: t('Funded in'),
				accessor: 'duration',
				maxWidth: 90,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				Cell: ({ row: { original: campaign } }) => (campaign?.duration ? `${campaign?.duration} days` : '-'),
			},
			{
				Header: t('Country'),
				accessor: 'country',
				maxWidth: 80,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				Cell: ({ row: { original: campaign } }) =>
					campaign?.finance_details?.country ? campaign?.finance_details?.country : '-',
			},
			{
				Header: t('Projects'),
				accessor: 'projectId',
				maxWidth: 80,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				Cell: ({ row: { original: campaign } }) =>
					campaign?.finance_details?.projectIds.length > 0
						? campaign?.finance_details?.financeDetailsProjects.map((item, index) => (
								<Fragment key={`financeDetails-${item.projectId}-${item.externalId}-${item.financeDetailsId}`}>
									<Link
										color="#00b4d5"
										fontSize="14px"
										onClick={e => {
											e.stopPropagation();
										}}
										to={`/projects/details/${item.projectId}`}
									>
										{item.projectId}
									</Link>
									{campaign?.finance_details?.financeDetailsProjects.length - 1 === index ? ' ' : ', '}
								</Fragment>
						  ))
						: '-',
			},
			{
				Header: t('System size'),
				accessor: 'systemSize',
				maxWidth: 100,
				highlightSearchTerm: true,
				Cell: ({ row: { original: campaigns } }) =>
					campaigns?.finance_details?.systemSize ? formatNumber(campaigns?.finance_details?.systemSize, 0) : '-',
			},
			{
				Header: t('Annual CO2'),
				accessor: 'co2',
				maxWidth: 100,
				Cell: ({ row: { original: campaigns } }) =>
					campaigns?.finance_details?.totalCampaignAnnualCo2Savings
						? formatNumber(campaigns?.finance_details?.totalCampaignAnnualCo2Savings, 0)
						: '-',
			},
			{
				Header: t('ID'),
				accessor: 'id',
				maxWidth: 30,
				highlightSearchTerm: true,
				cellStyle: {
					justifyContent: 'flex-end',
				},
				Cell: ({ row: { original: campaigns } }) => campaigns?.id ?? '-',
			},
		],
		[t],
	);

	const allocated = financeDetails => {
		const systemSizeAllocatedPercentage =
			project.financeDetailsProjects.find(x => x.financeDetailsId === financeDetails.id)
				.systemSizeAllocatedPercentage * 100;

		return `${formatNumber(systemSizeAllocatedPercentage, 0)}%` || '-';
	};

	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			project?.finances?.map(campaign => ({
				...campaign,
				baseInterestRate: campaign?.baseInterestRate ? `${campaign.baseInterestRate} %` : '-',
				bonusInterestRate: campaign?.bonusInterestRate ? `${campaign.bonusInterestRate} %` : '-',
				financeVolumeInclVat: campaign.financeVolumeInclVat ? formatCurrency(campaign.financeVolumeInclVat) : '-',
				soldLoansBonusRate: campaign?.soldLoansBonusRate?.eurValue
					? formatCurrency(campaign.soldLoansBonusRate.eurValue)
					: '-',
				soldLoansBaseRate: campaign?.soldLoansBaseRate?.eurValue
					? formatCurrency(campaign.soldLoansBaseRate.eurValue)
					: '-',
				fundingEndDate: campaign?.fundingEndDate ? formatDate(campaign?.fundingEndDate, 'd/M/yy') : '-',
				fundingStartDate: campaign?.fundingStartDate ? formatDate(campaign?.fundingStartDate, 'd/M/yy') : '-',
				emissionSPV: campaign?.emissionSPV?.name,
				systemSizeAllocatedPercentage: allocated(campaign?.finance_details),
			})) ?? [],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[project],
	);

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
			},
			useCustomCellStyles,
		);

	return (
		<TableWrapper>
			<DefaultTable
				getTableProps={getTableProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				rows={rows}
				headerGroups={headerGroups}
				prepareRow={prepareRow}
			/>
		</TableWrapper>
	);
};

CampaignList.propTypes = {
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		maximum_volume: PropTypes.number,
		last_investment_date: PropTypes.shape({}),
		finance_details: PropTypes.shape({
			systemSize: PropTypes.number.isRequired,
			totalCampaignAnnualCo2Savings: PropTypes.number.isRequired,
		}),
		financeDetailsProjects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		finances: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	}).isRequired,
};

export default CampaignList;
