import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ConfirmModal from 'Common/components/modals/ConfirmModal';

const UnsavedChangesModal = ({ isOpen, onClose, onConfirm, isSavingChanges }) => {
	const { t } = useTranslation();

	return (
		<ConfirmModal
			label="you-have-unsaved-changes-confirm"
			isOpen={isOpen}
			onConfirm={onConfirm}
			onCancel={onClose}
			isLoading={isSavingChanges}
			heading={t('You have unsaved changes')}
			text={
				<>
					{t('Are you sure you want to leave this page?')}
					<br />
					{t('Any changes you have made on this page will be lost.')}
				</>
			}
		/>
	);
};

UnsavedChangesModal.defaultProps = {
	isSavingChanges: false,
};

UnsavedChangesModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	isSavingChanges: PropTypes.bool,
};

export default UnsavedChangesModal;
