/* eslint-disable react/prop-types */
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import EditableTile from 'Common/components/Tile/EditableTile';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { DefaultTable, TableHeadCell, TableBodyCell } from 'Common/components/table/table';
import PropTypes from 'prop-types';
import InvoiceOverlay from 'Invoice/components/Overlays/InvoiceOverlay';
import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import PillWrapper from 'Common/components/PillWrapper';
import StatusPill from 'Common/components/StatusPill';
import {
	INVOICE_STATUSES,
	INVOICE_STATUSES_LABELS,
	INVOICE_STATUS_COLORS,
} from 'Invoice/constants/invoiceContstants';
import InvoiceActions from 'Invoice/components/Actions/InvoiceActions';
import { useCustomRowStyle } from 'Common/hooks/table/plugin-hooks/useCustomRowStyle';
import formatNumber from 'Common/utils/formatNumber';
import UNITS from 'Common/constants/units';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import isAuthorized from 'User/utils/isAuthorized';
import { roles } from 'User/constants/roles';
import crudModes from 'Common/constants/crudModes';
import { isSameYear } from 'date-fns';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import getProjectById from 'Projects/api/getProjectById';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}
	${TableBodyCell} {
		background: transparent;
	}
`;

// ? Just replaces '-' in dates with '/' . A little overkill, but it's straightforward.
const formatDatesFromInvoice = (invoice, dateKeys) =>
	dateKeys.reduce(
		(acc, key) => ({
			...acc,
			[key]: invoice[key] ? invoice[key].replaceAll('-', '/') : '',
		}),
		{},
	);

const InvoiceList = ({ project, onDataChange }) => {
	const { t } = useTranslation();
	const abortController = useAbortController();
	const [isProjectLoading, setIsProjectLoading] = useState(false);
	const [contractType, setContractType] = useState();

	const country = useMemo(() => project?.country, [project?.country]);
	const client = useMemo(() => project?.client, [project?.client]);
	const invoices = useMemo(() => project?.invoices ?? [], [project.invoices]);
	const isAuthorizedToCreate = useMemo(() => isAuthorized([roles.ADMIN, roles.FINANCE]), []);

	const numberOfInvoicesCurrentYear = useMemo(
		() => invoices.filter(invoice => isSameYear(new Date(), new Date(invoice.createdDate))).length,
		[invoices],
	);

	const [invoiceIdForEditing, setInvoiceIdForEditing] = useState();
	const [isCreateOverlayOpen, setIsCreateOverlayOpen] = useState(false);
	const editOverlayData = useMemo(
		() => ({
			projectId: project.id,
			projectExternalId: project.externalId,
			country,
			client,
			entityId: project?.contractPartyObjectByType?.SaaS?.id,
			invoiceId: invoiceIdForEditing,
		}),
		[
			project.id,
			project.externalId,
			project?.contractPartyObjectByType?.SaaS?.id,
			country,
			client,
			invoiceIdForEditing,
		],
	);

	const createOverlayData = useMemo(() => {
		const invoiceNumberThisYear = numberOfInvoicesCurrentYear + 1;

		const newInvoiceNumber = `${new Date().getFullYear()}-${country?.isoCode}-${
			project.externalId
		}-${invoiceNumberThisYear}`;

		return {
			projectId: project.id,
			projectExternalId: project.externalId,
			projectSettings: project.projectSettings,
			country,
			client,
			newInvoiceNumber,
			entityId: project?.contractPartyObjectByType?.SaaS?.id,
		};
	}, [
		country,
		numberOfInvoicesCurrentYear,
		project?.contractPartyObjectByType?.SaaS?.id,
		project.projectSettings,
		project.externalId,
		client,
		project.id,
	]);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Status'),
				customBodyCellStyles: {
					height: '42px',
				},
				Cell: (() => {
					const Cell = ({ row: { original: invoice } }) =>
						invoice?.status?.status === INVOICE_STATUSES.PAID ? (
							<PillWrapper>
								<StatusPill color={INVOICE_STATUS_COLORS.OVERDUE[invoice?.overdueStatus?.status]}>
									{INVOICE_STATUSES_LABELS.OVERDUE[invoice?.overdueStatus?.status]}
								</StatusPill>
							</PillWrapper>
						) : (
							<PillWrapper>
								<StatusPill color={INVOICE_STATUS_COLORS[invoice?.status?.status]}>
									{INVOICE_STATUSES_LABELS[invoice?.status?.status]}
								</StatusPill>
							</PillWrapper>
						);

					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								status: PropTypes.shape({ status: PropTypes.string, displayName: PropTypes.string }),
								invoiceTemplate: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Number'),
				accessor: 'number',
				customBodyCellStyles: {
					height: '42px',
				},
			},
			{
				Header: t('Issue date'),
				accessor: 'issueDate',
				customBodyCellStyles: {
					height: '42px',
				},
			},
			{
				Header: t('Due date'),
				accessor: 'dueDate',
				customBodyCellStyles: {
					height: '42px',
				},
			},
			{
				Header: t('Payment date'),
				accessor: 'paymentDate',
				customBodyCellStyles: {
					height: '42px',
				},
			},
			{
				Header: t('Billing period start'),
				accessor: 'billingPeriodStart',
				customBodyCellStyles: {
					height: '42px',
				},
			},
			{
				Header: t('Billing period end'),
				accessor: 'billingPeriodEnd',
				customBodyCellStyles: {
					height: '42px',
				},
			},
			{
				Header: t('Total amount'),
				accessor: 'totalAmount',
				customBodyCellStyles: {
					height: '42px',
				},
			},
			{
				Header: '',
				accessor: 'actionDots',
				maxWidth: 100,
				customCellStyles: {
					justifyContent: 'flex-end',
				},
				customBodyCellStyles: {
					height: '42px',
				},
			},
		],
		[t],
	);

	const handleDataChange = data => onDataChange(data);

	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			invoices?.map(invoice => ({
				...invoice,
				...formatDatesFromInvoice(invoice, [
					'issueDate',
					'dueDate',
					'billingPeriodStart',
					'billingPeriodEnd',
					'paymentDate',
				]),
				totalAmount: invoice.totalAmount
					? `${invoice.saasCurrency === 'EUR' ? UNITS.EUR : invoice.saasCurrency} ${formatNumber(
							invoice.totalAmount,
							0,
					  )}`
					: '-',
				actionDots: (
					<InvoiceActions
						invoice={invoice}
						onDataChange={handleDataChange}
						countryId={country.id}
						onWrapperClick={e => e.stopPropagation()}
					/>
				),
			})),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t, invoices],
	);

	const handleRowClick = row => {
		if (!window.getSelection().toString()) {
			setInvoiceIdForEditing(row.original.id);
		}
	};

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
				onRowClick: handleRowClick,
				getCustomRowStyles: () => ({
					height: 'auto',
				}),
			},
			useCustomRowStyle,
			useCustomCellStyles,
			useClickableTableRow,
		);

	useEffect(() => {
		(async () => {
			if (project?.id) {
				try {
					setIsProjectLoading(true);
					const response = await getProjectById(abortController.signal, project?.id, true);
					setContractType(response?.data?.contractType?.name);
					setIsProjectLoading(false);
				} catch (e) {
					setIsProjectLoading(false);
					showToastError(e, t('An error occured while trying to fetch invoice data'));
				}
			}
		})();
	}, [project, abortController.signal, t]);

	return (
		<EditableTile
			title={t('Invoice')}
			isTable
			rightComponent={
				isAuthorizedToCreate ? (
					<HoverTooltip title={!isProjectLoading && !contractType ? t('Create a SaaS contract first') : ''}>
						<TileActionButton
							data-button="create-invoice"
							disabled={!contractType}
							onClick={() => setIsCreateOverlayOpen(true)}
						/>
					</HoverTooltip>
				) : null
			}
		>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
				/>
			</TableWrapper>

			{invoiceIdForEditing && (
				<InvoiceOverlay
					mode={crudModes.EDIT}
					data={editOverlayData}
					isOpen={Boolean(invoiceIdForEditing)}
					onClose={() => setInvoiceIdForEditing(null)}
					onFormSubmit={handleDataChange}
					onInvoiceDelete={handleDataChange}
				/>
			)}

			{isCreateOverlayOpen && (
				<InvoiceOverlay
					mode={crudModes.CREATE}
					data={createOverlayData}
					isOpen={Boolean(isCreateOverlayOpen)}
					onClose={() => setIsCreateOverlayOpen(false)}
					onFormSubmit={handleDataChange}
					onInvoiceDelete={handleDataChange}
				/>
			)}
		</EditableTile>
	);
};

InvoiceList.propTypes = {
	onDataChange: PropTypes.func.isRequired,
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		country: PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		invoices: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				number: PropTypes.string,
				issueDate: PropTypes.string,
				dueDate: PropTypes.string,
				billingPeriodStart: PropTypes.string,
				billingPeriodEnd: PropTypes.string,
				totalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				status: PropTypes.shape({ status: PropTypes.string, displayName: PropTypes.string }),
				invoiceTemplate: PropTypes.shape({
					id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
				}),
			}),
		),
	}).isRequired,
};

export default InvoiceList;
