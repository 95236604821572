import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

import EditableTile from 'Common/components/Tile/EditableTile';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { DefaultTable, TableBodyCell, TableHeadCell } from 'Common/components/table/table';
import AccountingStandardsDeleteModal from 'AccountingStandards/components/Modals/AccountingStandardsDeleteModal';
import AccountingStandardsCreateModal from 'AccountingStandards/components/Modals/AccountingStandardsCreateModal';
import AccountingStandardsEditModal from 'AccountingStandards/components/Modals/AccountingStandardsEditModal';
import showToastError from 'Common/utils/showToastError';
import getAccountingStandards from 'AccountingStandards/api/getAccountingStandards';
import useAbortController from 'Common/hooks/useAbortController';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}
	${TableBodyCell} {
		background: transparent;
	}
`;

const AccountingStandardsList = () => {
	const { t } = useTranslation();

	const [accountingStandards, setAccountingStandards] = useState([]);

	const [isLoadingContent, setIsLoadingContent] = useState(false);

	const [accountingStandardIdForEditing, setAccountingStandardsIdForEditing] = useState(null);

	const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

	const [accountingStandardForDeletion, setAccountingStandardsForDeletion] = useState(null);

	const abortController = useAbortController();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Name'),
				accessor: 'name',
			},
			{
				Header: '',
				accessor: 'actionDots',
				maxWidth: 70,
				customCellStyles: {
					justifyContent: 'flex-end',
				},
				customBodyCellContentStyles: {
					padding: '5px',
				},
			},
		],
		[t],
	);

	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			accountingStandards.map(accountingStandard => ({
				...accountingStandard,
				name: accountingStandard?.name || '-',
				actionDots: (
					<MoreActionsButton label="Accounting standards More Actions" controlledVisibility>
						<MenuItem
							data-action="edit"
							onClick={() => setAccountingStandardsIdForEditing(accountingStandard.id)}
							label="Accounting standards - Edit Menu Item"
						>
							Edit
						</MenuItem>
						<MenuItem
							data-action="delete"
							onClick={() =>
								setAccountingStandardsForDeletion({ id: accountingStandard.id, name: accountingStandard.name })
							}
							label="Accounting standards - Delete Menu Item"
						>
							Delete
						</MenuItem>
					</MoreActionsButton>
				),
			})),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t, accountingStandards],
	);

	const fetchAccountingStandards = useCallback(async () => {
		try {
			setIsLoadingContent(true);
			const response = await getAccountingStandards(abortController.signal);
			setIsLoadingContent(false);

			setAccountingStandards(response.data);
			return response.data;
		} catch (error) {
			showToastError(error);
		}
	}, [abortController.signal]);

	useEffect(() => {
		fetchAccountingStandards();
	}, [fetchAccountingStandards]);

	const handleDataUpdate = () => {
		fetchAccountingStandards();
	};

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
			},
			useCustomCellStyles,
		);

	return (
		<EditableTile
			title={t('Accounting Standards')}
			rightComponent={
				<TileActionButton data-button="accountingStandards" onClick={() => setIsCreateModalOpened(true)} />
			}
			isTable
			isLoadingContent={isLoadingContent}
		>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
				/>
			</TableWrapper>

			<AccountingStandardsCreateModal
				isOpen={isCreateModalOpened}
				onClose={() => setIsCreateModalOpened(false)}
				onFormSubmit={handleDataUpdate}
			/>

			{accountingStandardIdForEditing !== null && (
				<AccountingStandardsEditModal
					isOpen
					onClose={() => setAccountingStandardsIdForEditing(null)}
					onFormSubmit={handleDataUpdate}
					accountingStandardId={accountingStandardIdForEditing}
				/>
			)}
			<AccountingStandardsDeleteModal
				isOpen={Boolean(accountingStandardForDeletion)}
				onFormSubmit={handleDataUpdate}
				accountingStandardForDeletion={accountingStandardForDeletion}
				onCancel={() => setAccountingStandardsForDeletion(null)}
			/>
		</EditableTile>
	);
};

export default AccountingStandardsList;
