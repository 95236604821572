import api from 'Application/api/api';
import adaptClientOfferResponseData from './adapters/adaptClientOfferResponseData';

const createClientOffer = async (signal, values) => {
	const response = await api.post('/client-offers', values, {
		signal,
		timeout: 100000,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptClientOfferResponseData(response.data),
	};
};

export default createClientOffer;
