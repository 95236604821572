import useAbortController from 'Common/hooks/useAbortController';
import showToastError from 'Common/utils/showToastError';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import loadTariffTypeOptions from 'Invoice/utils/loadTariffTypeOptions';
import { useEffect, useMemo, useState } from 'react';

const fallbackValues = {
	paymentCurrency: { value: 'EUR' },
	saasCurrency: { value: 'EUR' },
	template: null,
	bankAccount: null,
	takeOrPay: false,
	tariffType: null,
};

const useSettingsTileFormInitialValues = (projectSettings, country) => {
	const saasCurrencyValue = projectSettings?.saasCurrency
		? projectSettings.saasCurrency
		: fallbackValues.saasCurrency.value;
	const paymentCurrencyValue = projectSettings?.paymentCurrency
		? projectSettings.paymentCurrency
		: fallbackValues.paymentCurrency.value;

	const tariffTypeValue = projectSettings?.tariffTypeId
		? projectSettings.tariffTypeId
		: fallbackValues.tariffType;

	const [saasCurrencyOption, setSaasCurrencyOption] = useState({
		value: saasCurrencyValue,
		label: saasCurrencyValue,
	});

	const [paymentCurrencyOption, setPaymentCurrencyOption] = useState({
		value: paymentCurrencyValue,
		label: paymentCurrencyValue,
	});

	const [tariffTypeOption, setTariffTypeOption] = useState({
		label: '',
		value: undefined,
	});

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				const saasCurrencies = await loadCurrenciesOptions(abortController.signal);
				setSaasCurrencyOption(saasCurrencies.find(e => e.value === saasCurrencyValue));
				setPaymentCurrencyOption(saasCurrencies.find(e => e.value === paymentCurrencyValue));
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [saasCurrencyValue, paymentCurrencyValue, abortController.signal]);

	useEffect(() => {
		(async () => {
			try {
				const tariffTypes = await loadTariffTypeOptions(country.id);
				const tariffTypeOption = tariffTypes.find(e => e.value === tariffTypeValue);
				setTariffTypeOption(tariffTypeOption);
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [country.id, tariffTypeValue]);

	return useMemo(
		() => ({
			template: projectSettings?.invoiceTemplate
				? {
						label: projectSettings.invoiceTemplate.fileName,
						value: projectSettings.invoiceTemplate.id,
				  }
				: fallbackValues.template,
			saasCurrency: saasCurrencyOption,
			paymentCurrency: paymentCurrencyOption,
			bankAccount: projectSettings?.bankAccount
				? {
						label: projectSettings.bankAccount.name,
						value: projectSettings.bankAccount.id,
				  }
				: fallbackValues.bankAccount,
			takeOrPay: projectSettings?.takeOrPay ? projectSettings.takeOrPay : fallbackValues.takeOrPay,
			tariffType: tariffTypeOption,
		}),
		[projectSettings, saasCurrencyOption, paymentCurrencyOption, tariffTypeOption],
	);
};

export default useSettingsTileFormInitialValues;
