import { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import getClientContactsById from 'Client/api/getClientContactsById';
import assignInvoicingContact from 'Client/api/assignInvoicingContact';
import assignContactToProject from 'Contacts/api/assignContactToProject';

const generateInvoicingProjectsContacts = contacts =>
	contacts.reduce((previousProjects, contact) => {
		const reducedInvoicingProjects =
			contact?.projectContacts
				?.filter(p => p.invoicingContact)
				?.map(p => [p.projectId, p.contactId])
				?.reduce(
					(prevProjects, [prId, ctId]) => ({
						...prevProjects,
						[prId]: [...(prevProjects?.[prId] ?? []), ctId],
					}),
					{},
				) ?? [];

		return { ...previousProjects, ...reducedInvoicingProjects };
	}, {});

const generateGeneralInvoicingContactId = (invoicingContacts, clientProjectsIds) => {
	const invocingContactIds = invoicingContacts;
	const haveAllProjectsInvoicing = invocingContactIds.length === clientProjectsIds.length;
	const isSameUser = Array.from(new Set(invocingContactIds)).length === 1;

	return (haveAllProjectsInvoicing && isSameUser && invocingContactIds[0]) || 0;
};

const generateInvoicingContacts = invoicingProjectsContacts =>
	Object.values(invoicingProjectsContacts).reduce((prev, curr) => [...prev, ...curr], []);

const checkHasWronglySetInvoicingContacts = invoicingProjectsContacts =>
	Object.values(invoicingProjectsContacts).some(contacts => contacts.length > 1);

const useContactsData = client => {
	const abortController = useAbortController();
	const { t } = useTranslation();

	const [contacts, setContacts] = useState([]);
	const [shouldUpdate, setShouldUpdate] = useState(true);
	// ? We need to store the fetched client id so we can refetch the contacts when the client changes
	const fetchedClientId = useRef();
	const [contactIdForAssigning, setContactIdForAssigning] = useState();
	const [contactIdForExpelling, setContactIdForExpelling] = useState();
	const [contactForDeletion, setContactForDeletion] = useState({});

	const clientProjectsIds = useMemo(() => client.projects.map(p => p.id), [client.projects]);

	const invoicingProjectsContacts = useMemo(() => generateInvoicingProjectsContacts(contacts), [contacts]);

	const invoicingContacts = useMemo(
		() => generateInvoicingContacts(invoicingProjectsContacts),
		[invoicingProjectsContacts],
	);

	const generalInvocingContactId = useMemo(
		() => generateGeneralInvoicingContactId(invoicingContacts, clientProjectsIds),
		[invoicingContacts, clientProjectsIds],
	);
	const updateContactsData = () => setShouldUpdate(true);

	// const hasWronglySetInvoicingContacts = useMemo(
	// 	() => checkHasWronglySetInvoicingContacts(invoicingProjectsContacts),
	// 	[invoicingProjectsContacts],
	// );

	// useEffect(() => {
	// 	if (hasWronglySetInvoicingContacts) {
	// 		notify(t('There are projects with more than one invoicing contact assigned.'), {
	// 			type: toast.TYPE.WARNING,
	// 		});
	// 	}
	// }, [hasWronglySetInvoicingContacts, t]);

	useEffect(() => {
		if (shouldUpdate || fetchedClientId.current !== client.id) {
			(async () => {
				try {
					const response = await getClientContactsById(abortController.signal, client.id);
					setContacts(response.data);
					fetchedClientId.current = client.id;
					setShouldUpdate(false);
				} catch (e) {
					showToastError(e, t("Can't fetch SaaS contracts"));
				}
			})();
		}
	}, [client.id, shouldUpdate, abortController.signal, t]);

	const handleUpdateContact = (data, isCreate) => {
		setContacts(prev => {
			let currentContacts = [...prev];
			if (isCreate) {
				return [...currentContacts, data];
			} else {
				return currentContacts.map(contact => {
					if (contact.id === data.id) {
						return {
							...contact,
							...omit(data, ['projectExternalId', 'projectId']),
						};
					} else {
						return contact;
					}
				});
			}
		});
	};

	const handleAssignProject = contactId => setContactIdForAssigning(contactId);
	const closeAssignModal = () => setContactIdForAssigning(null);

	const handleAssignInvoicingContact = async contact => {
		const { firstName, lastName, contactId, isInvoicingContact, projectId, projectExternalId } = contact;
		try {
			await assignInvoicingContact(abortController.signal, {
				contactId,
				invoicingContact: !isInvoicingContact,
				projectId,
			});
			updateContactsData();
			const actionText = isInvoicingContact ? 'unassigned' : 'assigned';

			notify(
				t(`Contact ${firstName} ${lastName} ${actionText} as invoicing contact for project ${projectExternalId}`),
				{
					type: toast.TYPE.SUCCESS,
				},
			);
		} catch (e) {
			showToastError(e, t("Can't set invoicing contact"));
		}
	};

	const handleExpellContact = contactId => setContactIdForExpelling(contactId);
	const closeExpellModal = () => setContactIdForExpelling(null);

	const handleDeleteContact = contactData => setContactForDeletion(contactData);
	const closeDeleteModal = () => setContactForDeletion({});

	const removeAllInvocingContacts = () =>
		Promise.all(
			Object.entries(invoicingProjectsContacts).map(([prId, cIdArr]) =>
				cIdArr.map(cId =>
					assignInvoicingContact(abortController.signal, {
						contactId: cId,
						invoicingContact: false,
						projectId: prId,
					}),
				),
			),
		);

	const assignContactToAllProjects = contactId =>
		assignContactToProject(abortController.signal, {
			contactId,
			projectIds: clientProjectsIds,
		});

	const assignContactInvoicingToAllProjects = contactId =>
		Promise.all(
			clientProjectsIds.map(prId =>
				assignInvoicingContact(abortController.signal, {
					contactId,
					invoicingContact: true,
					projectId: prId,
				}),
			),
		);

	const handleAssignInvoicingContactToAllProjects = async contact => {
		const { firstName, lastName, contactId, isInvoicingContact, projectContacts } = contact;
		try {
			const hasProjectsWithInvoicing = invoicingContacts.length > 0;

			if (hasProjectsWithInvoicing) {
				await removeAllInvocingContacts();
			}

			const isUserAssignedToAllProjects = clientProjectsIds?.length === projectContacts?.length;
			if (!isUserAssignedToAllProjects) {
				await assignContactToAllProjects(contactId);
			}

			await assignContactInvoicingToAllProjects(contactId);

			setShouldUpdate(true);
			const actionText = isInvoicingContact ? 'unassigned' : 'assigned';

			notify(t(`Contact ${firstName} ${lastName} ${actionText} as invoicing contact to all client projects`), {
				type: toast.TYPE.SUCCESS,
			});
		} catch (e) {
			showToastError(e, t("Can't set invoicing contact to all projects"));
		}
	};

	return {
		contacts,
		contactIdForAssigning,
		contactIdForExpelling,
		contactForDeletion,

		invoicingProjectsContacts,
		generalInvocingContactId,

		updateContactsData,
		handleUpdateContact,

		handleAssignProject,
		handleAssignInvoicingContact,
		handleAssignInvoicingContactToAllProjects,
		handleExpellContact,
		handleDeleteContact,

		closeExpellModal,
		closeAssignModal,
		closeDeleteModal,
	};
};

export default useContactsData;

export {
	generateInvoicingProjectsContacts,
	generateGeneralInvoicingContactId,
	generateInvoicingContacts,
	checkHasWronglySetInvoicingContacts,
};
