import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { pick } from 'lodash';
import PropTypes from 'prop-types';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import loadCountryOptions from 'Country/utils/loadCountryOptions';
import FormikSelect from 'Common/components/form/FormikSelect';

import updatePartner from 'Partners/api/updatePartner';
import useAddressFormInitialValues from 'Partners/components/PartnerDetails/Tabs/DetailsTab/Tiles/AddressTile/hooks/useAddressFormInitialValues';
import useAddressFormValidationSchema from 'Partners/components/PartnerDetails/Tabs/DetailsTab/Tiles/AddressTile/hooks/useAddressFormValidationSchema';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const AddressTile = ({ partner, onEnterEditMode, onExitEditMode, onSave, onDirtyForm }) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.SALES,
		roles.PM_TECHNICAL,
		roles.ASSET_MANAGER,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useAddressFormInitialValues(partner);
	const validationSchema = useAddressFormValidationSchema();

	const { errors, touched, values, handleSubmit, dirty, handleChange, handleBlur, setFieldValue, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: async values => {
				setIsSavingChanges(true);

				let response;

				try {
					response = await updatePartner(abortController.signal, {
						...partner,
						countryId: values.country ? values.country.value : null,
						...pick(values, ['addressLine1', 'addressLine2', 'cityRegion', 'aparmentBuilding', 'postCode']),
					});

					if (onDirtyForm) {
						onDirtyForm(false);
					}

					onSave(response.data);
					handleCancel();
					setIsSavingChanges(false);
					resetForm({ values });
				} catch (e) {
					showToastError(e, t("Can't update Partner address info"));
					if (!axios.isCancel(e)) {
						handleCancel();
						setIsSavingChanges(false);
					}
				}
			},
		});

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Address')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<FormField>
				<FormikSelect
					isAsync
					id="country"
					name="country"
					label={t('Country')}
					value={values.country}
					error={errors.country}
					touched={touched.country}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={loadCountryOptions}
					isTile
					isInEditMode={isInEditMode}
					isRequired
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="aparmentBuilding"
					name="aparmentBuilding"
					label={t('Apartment / Building')}
					value={values.aparmentBuilding}
					error={errors.aparmentBuilding}
					touched={touched.aparmentBuilding}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="addressLine1"
					name="addressLine1"
					label={t('Address line 1')}
					value={values.addressLine1}
					error={errors.addressLine1}
					touched={touched.addressLine1}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="addressLine2"
					name="addressLine2"
					label={t('Address line 2')}
					value={values.addressLine2}
					error={errors.addressLine2}
					touched={touched.addressLine2}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="cityRegion"
					name="cityRegion"
					label={t('City / Region')}
					value={values.cityRegion}
					error={errors.cityRegion}
					touched={touched.cityRegion}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="postCode"
					name="postCode"
					label={t('Post code / ZIP')}
					value={values.postCode}
					error={errors.postCode}
					touched={touched.postCode}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
		</EditableTile>
	);
};

AddressTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
};

AddressTile.propTypes = {
	partner: PropTypes.shape({}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onSave: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
};

export default AddressTile;
