import { useTranslation } from 'react-i18next';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import useAbortController from 'Common/hooks/useAbortController';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import showToastError from 'Common/utils/showToastError';
import PropTypes from 'prop-types';
import deleteUtilityRate from 'Client/api/deleteUtilityRate';

const UtilityRateDeleteModal = ({ isOpen, onClose, onFormSubmit, utilityRateForDeletion }) => {
	const { t } = useTranslation();
	const abortController = useAbortController();

	const handleDelete = async () => {
		try {
			await deleteUtilityRate(abortController.signal, utilityRateForDeletion);
			onFormSubmit();
			onClose();

			notify(t('Utility Rate deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});
		} catch (error) {
			showToastError(error, t("Can't delete Utility Rate"));
		}
	};

	return (
		<ConfirmModal
			isOpen={isOpen}
			onCancel={onClose}
			onConfirm={handleDelete}
			confirmText="Delete"
			label="delete-utility-rate-confirm"
			heading={t('Delete Utility Rate')}
			text={t('Are you sure you want to delete Utility Rate ?')}
		/>
	);
};

UtilityRateDeleteModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	utilityRateForDeletion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default UtilityRateDeleteModal;
