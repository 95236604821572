import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Loader from 'Common/components/Loader';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';

import getContactByEmail from 'Contacts/api/getContactByEmail';

import LoaderWrapper from 'Common/components/LoaderWrapper';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import TabsContainer from 'Common/components/tabs';
import { EPC_PARTNER_PROFILE_DETAILS_PAGE_TILES_BY_TAB } from 'EpcPartner/constants/epcPartnerTabForms';
import GeneralTab from 'EpcPartner/components/EpcPartnerPortal/EpcPartnerProfile/Tabs/GeneralTab/GeneralTab';

const EpcPartnerProfile = () => {
	const [epcPartner, setEpcPartner] = useState(null);
	const [isLoadingEpcPartner, setIsLoadingEpcPartner] = useState(true);

	const { t } = useTranslation();

	const epcPartnerEmail = useSelector(state => state.user.email);

	const abortController = useAbortController();

	useEffect(() => {
		setIsLoadingEpcPartner(true);

		(async () => {
			try {
				const response = await getContactByEmail(abortController.signal, epcPartnerEmail);
				setEpcPartner(response.data.epcPartner);
				setIsLoadingEpcPartner(false);
			} catch (error) {
				showToastError(error, t("Can't fetch partner details"));
				if (!axios.isCancel(error)) {
					setEpcPartner(null);
					setIsLoadingEpcPartner(false);
				}
			}
		})();
	}, [epcPartnerEmail, abortController.signal, t]);

	const handleEpcPartnerDataChange = useCallback(changes => {
		setEpcPartner(prevEpcPartner => ({
			...prevEpcPartner,
			...changes,
		}));
	}, []);

	const tabsConfig = useMemo(
		() => [
			{
				label: 'General',
				PanelComponent: GeneralTab,
				panelProps: { epcPartner, onDataChange: handleEpcPartnerDataChange },
				tabForms: EPC_PARTNER_PROFILE_DETAILS_PAGE_TILES_BY_TAB.GENERAL,
			},
		],
		[epcPartner, handleEpcPartnerDataChange],
	);

	return (
		<div>
			{isLoadingEpcPartner ? (
				<LoaderWrapper $isFullPage>
					<Loader />
				</LoaderWrapper>
			) : (
				epcPartner !== null && (
					<>
						<Helmet>
							<title>{t('My Profile')}</title>
						</Helmet>
						<HeaderContainer>
							<HeaderTitle>{t('My Profile')}</HeaderTitle>
						</HeaderContainer>
						<TabsContainer page="epcPartner" tabsConfig={tabsConfig} />
					</>
				)
			)}
		</div>
	);
};

export default EpcPartnerProfile;
