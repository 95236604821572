import { useDropzone } from 'react-dropzone';

import { useCallback, useEffect, useMemo, useState } from 'react';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import axios from 'axios';
import { FILE_TYPES_LABELS, FILE_TYPES_MIME_TYPES, FILE_TYPES_EXTENSIONS } from 'Common/constants/fileTypes';

const MAX_FILE_SIZE_MB = 10;

const alertUnsavedChanges = event => {
	event.preventDefault();
	event.returnValue = '';
};

const useAcceptedFileTypes = acceptedTypes => {
	const validateFileType = file =>
		acceptedTypes.some(acceptedType => FILE_TYPES_MIME_TYPES[acceptedType].includes(file.type));

	const stringifiedTypes = useMemo(
		() => [...acceptedTypes].map(type => FILE_TYPES_LABELS[type]).join(', '),
		[acceptedTypes],
	);

	return { validateFileType, stringifiedTypes };
};

const useFileUpload = ({
	onFileUpload,
	fileName,
	fileIdentifier,
	multiple = false,
	acceptedTypes = [],
	maxSizeInMb = MAX_FILE_SIZE_MB,
}) => {
	const [isUploading, setIsUploading] = useState(false);

	const { validateFileType, stringifiedTypes } = useAcceptedFileTypes(acceptedTypes);

	const onDrop = useCallback(
		(acceptedFiles, fileRejections) => {
			if (fileRejections && fileRejections.length > 0) {
				fileRejections.forEach(({ file }) => {
					//              MB                 KB     Bytes
					if (file.size > maxSizeInMb * 1024 * 1024) {
						notify(`${fileName} ${file.name} not uploaded because it exceeds ${MAX_FILE_SIZE_MB}MB.`, {
							type: toast.TYPE.ERROR,
						});
					} else {
						notify(`${fileName} ${file.name} not uploaded because it doesn't match the accepted file types.`, {
							type: toast.TYPE.ERROR,
						});
					}
				});
			}

			const allCount = acceptedFiles.length + fileRejections.length;
			const acceptedCount = acceptedFiles.length;

			(async () => {
				if (acceptedCount === 0) {
					notify('Error uploading files. Please try again', {
						type: toast.TYPE.ERROR,
					});
				} else {
					const formData = new FormData();
					let isWrongFileType = false;

					acceptedFiles.forEach(file => {
						if (validateFileType(file)) {
							formData.append(fileIdentifier, file);
							return;
						}
						isWrongFileType = true;
						notify(`${fileName} ${file.name} not uploaded because it is not ${stringifiedTypes}.`, {
							type: toast.TYPE.ERROR,
						});
					});

					if (isWrongFileType) return;

					try {
						await onFileUpload({ formData, acceptedCount, allCount, files: acceptedFiles });

						setIsUploading(false);
					} catch (error) {
						if (!axios.isCancel(error)) {
							setIsUploading(false);
						}
					}
				}
			})();
		},
		[maxSizeInMb, fileIdentifier, fileName, onFileUpload, stringifiedTypes, validateFileType],
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		//       MB                 KB     Bytes
		maxSize: maxSizeInMb * 1024 * 1024,
		multiple,
	});

	const handleAddFile = e => {
		e.preventDefault();
		getRootProps().onClick(e);
	};

	const generateInputProps = (...args) => {
		const inputProps = getInputProps(...args);

		return {
			...inputProps,
			accept: acceptedTypes
				.map(fileType => FILE_TYPES_EXTENSIONS[fileType].map(ext => `.${ext}`).join(', '))
				.join(', '),
		};
	};

	const generateRootProps = (...args) => {
		const rootProps = getRootProps(...args);

		return {
			...rootProps,
			onClick: () => {},
		};
	};

	useEffect(() => {
		if (isUploading) {
			window.addEventListener('beforeunload', alertUnsavedChanges);
		} else {
			window.removeEventListener('beforeunload', alertUnsavedChanges);
		}

		return () => {
			window.removeEventListener('beforeunload', alertUnsavedChanges);
		};
	}, [isUploading]);

	return {
		onDrop,
		isUploading,
		handleAddFile,
		getRootProps,
		generateInputProps,
		generateRootProps,
		isDragActive,
	};
};

export default useFileUpload;
