import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import FormField from 'Common/components/form/FormField';
import TileHeader from 'Common/components/Tile/TileHeader';
import { forwardRef } from 'react';
import useReponsive from 'Common/hooks/useResponsive';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import Loader from 'Common/components/Loader';
import Tile from './Tile';

const Form = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;

	${FormField} {
		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const EditableTile = forwardRef(
	({
		dataSection,
		children,
		tooltip,
		title,
		isInEditMode,
		onCancel,
		onSubmit,
		isLoading,
		isLoadingContent,
		rightComponent,
		isTable,
		isReadonly,
		heightFitConent,
		onClick,
		// ...props
	}) =>
		// _ref,
		{
			const { isMobile } = useReponsive();
			const handleSubmit = (...args) => {
				onSubmit(...args);
				document.activeElement.blur();
			};

			return (
				<Tile
					data-section={dataSection}
					data-tile-wrapper={title}
					tooltip={tooltip}
					isLoading={isLoading}
					rightComponent={rightComponent}
					isTable={isTable}
					isReadonly={isReadonly}
					heightFitConent={heightFitConent}
					onClick={onClick}
					isActive={isInEditMode}
					$isReadonly={isReadonly}
				>
					{isLoadingContent ? (
						<>
							<TileHeader
								title={title}
								tooltip={tooltip}
								isInEditMode={isInEditMode}
								onCancel={onCancel}
								isLoading={isLoading}
								rightComponent={rightComponent}
								isTableTile={isTable}
								$isMobile={isMobile}
							/>
							<LoaderWrapper>
								<Loader />
							</LoaderWrapper>
						</>
					) : onSubmit ? (
						<Form onSubmit={handleSubmit}>
							<TileHeader
								title={title}
								tooltip={tooltip}
								isInEditMode={isInEditMode}
								onCancel={onCancel}
								isLoading={isLoading}
								rightComponent={rightComponent}
								isTableTile={isTable}
								$isMobile={isMobile}
							/>
							{children}
						</Form>
					) : (
						<>
							<TileHeader
								title={title}
								tooltip={tooltip}
								isInEditMode={isInEditMode}
								onCancel={onCancel}
								isLoading={isLoading}
								rightComponent={rightComponent}
								isTableTile={isTable}
								$isMobile={isMobile}
							/>
							{children}
						</>
					)}
				</Tile>
			);
		},
);

EditableTile.displayName = 'Tile';

EditableTile.defaultProps = {
	isInEditMode: false,
	isLoading: false,
	isLoadingContent: false,
	isReadonly: false,
	dataSection: '',
	children: undefined,
	tooltip: null,
	title: undefined,
	onCancel: undefined,
	onSubmit: undefined,
	rightComponent: undefined,
	isTable: false,
	heightFitConent: false,
	onClick: undefined,
};

EditableTile.propTypes = {
	dataSection: PropTypes.string,
	children: PropTypes.node,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	title: PropTypes.string,
	isInEditMode: PropTypes.bool,
	onCancel: PropTypes.func,
	onSubmit: PropTypes.func,
	isLoading: PropTypes.bool,
	isLoadingContent: PropTypes.bool,
	rightComponent: PropTypes.node,
	isTable: PropTypes.bool,
	isReadonly: PropTypes.bool,
	heightFitConent: PropTypes.bool,
	onClick: PropTypes.func,
};

export default EditableTile;
