import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import FormikRadio from 'Common/components/form/FormikRadio';
import FormikTextArea from 'Common/components/form/FormikTextArea';
import FileUploadField from 'Common/components/form/FileUploadField';
import FormikSelect from 'Common/components/form/FormikSelect';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import { FILE_TYPES } from 'Common/constants/fileTypes';
import convertFormPostCompletionValuesToRequestData from 'Projects/utils/convertFormPostCompletionValuesToRequestData';
import uploadPostCompletionDocuments from 'Projects/api/uploadPostCompletionDocuments';
import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import notify from 'Common/utils/notify';
import EditableTile from 'Common/components/Tile/EditableTile';
import FormikInput from 'Common/components/form/FormikInput';
import createPostCompletion from 'Projects/api/createPostCompletion';
import updatePostCompletion from 'Projects/api/updatePostCompletion';
import getPostCompletionDownloadLink from 'Projects/api/getPostCompletionDownloadLink';
import editProject from 'Projects/api/editProject';
import usePostCompletionFormValidationSchema from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/PostCompletionTile/hooks/usePostCompletionFormValidationSchema';
import usePostCompletionFormInitialValues from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/PostCompletionTile/hooks/usePostCompletionFormInitialValues';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import ConditionalGridSection from 'Common/components/ConditionalGridSection';

const Title = styled.div`
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 16px;
	color: ${colors.text.greyDark};
`;

const StyledGridContainer = styled(Grid)`
	height: auto;
`;

const StyledGrid = styled(Grid)`
	padding-right: 32px;
	height: fit-content;
	position: relative;
`;

const StyledFormField = styled.div`
	max-width: 400px;
	margin-bottom: 16px !important;
`;

const StyledCheckField = styled.div`
	margin-bottom: 16px !important;
`;

const StyledCommentField = styled(FormikTextArea)`
	width: 100%;
`;

const PostCompletionTile = ({
	project,
	onEnterEditMode,
	onExitEditMode,
	onDirtyForm,
	onTileSave,
	highlightLabels,
	breadcrumbList,
}) => {
	const { t } = useTranslation();

	const isAuthorizedToEditExcluded = isAuthorized([
		roles.ADMIN,
		roles.ASSET_MANAGER,
		roles.HEAD_OF_ASSET_MANAGEMENT,
	]);
	const isAuthorizedtoEdit = isAuthorized([
		roles.FINANCE,
		roles.ADMIN,
		roles.ASSET_MANAGER,
		roles.HEAD_OF_ASSET_MANAGEMENT,
		roles.HEAD_OF_FINANCE,
	]);

	const isAuthorizedFinanceRole = useMemo(() => !isAuthorizedToEditExcluded, [isAuthorizedToEditExcluded]);
	const isAuthorizedithoutdFinanceRole = useMemo(() => !isAuthorizedtoEdit, [isAuthorizedtoEdit]);

	const isIndustryFieldDisabled = useMemo(() => !isAuthorizedToEditExcluded, [isAuthorizedToEditExcluded]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = usePostCompletionFormInitialValues(project);
	const validationSchema = usePostCompletionFormValidationSchema();

	const {
		errors,
		touched,
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		handleSubmit,
		setFieldTouched,
		dirty,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			setIsSavingChanges(true);
			try {
				if (!project.postCompletion) {
					let response = await createPostCompletion(
						abortController.signal,
						convertFormPostCompletionValuesToRequestData(values),
					);
					if (values.pctForm) {
						const formData = new FormData();
						formData.append('document', values.pctForm);
						await uploadPostCompletionDocuments(
							abortController.signal,
							formData,
							response.data.id,
							'PCT_COUNTERSIGNED',
						);
					}
					if (values.pcdInvoiceForm) {
						const formData = new FormData();
						formData.append('document', values.pcdInvoiceForm);
						await uploadPostCompletionDocuments(abortController.signal, formData, response.data.id, 'PCD_INVOICE');
					}
					if (values.pcdPayslipForm) {
						const formData = new FormData();
						formData.append('document', values.pcdPayslipForm);
						await uploadPostCompletionDocuments(abortController.signal, formData, response.data.id, 'PCD_PAYSLIP');
					}
					if (values.ms5PayslipForm) {
						const formData = new FormData();
						formData.append('document', values.ms5PayslipForm);
						await uploadPostCompletionDocuments(abortController.signal, formData, response.data.id, 'MS5_PAYSLIP');
					}
					await editProject(abortController.signal, {
						...project,
						postCompletion: response.data,
					});
					notify(t('Project Post Completion created successfully.'), {
						type: toast.TYPE.SUCCESS,
					});
				} else {
					const response = await updatePostCompletion(
						abortController.signal,
						convertFormPostCompletionValuesToRequestData(values),
						project.postCompletion.id,
					);
					if (values.pctForm) {
						const formData = new FormData();
						formData.append('document', values.pctForm);
						await uploadPostCompletionDocuments(
							abortController.signal,
							formData,
							response.data.id,
							'PCT_COUNTERSIGNED',
						);
					}
					if (values.pcdInvoiceForm) {
						const formData = new FormData();
						formData.append('document', values.pcdInvoiceForm);
						await uploadPostCompletionDocuments(abortController.signal, formData, response.data.id, 'PCD_INVOICE');
					}
					if (values.pcdPayslipForm) {
						const formData = new FormData();
						formData.append('document', values.pcdPayslipForm);
						await uploadPostCompletionDocuments(abortController.signal, formData, response.data.id, 'PCD_PAYSLIP');
					}
					if (values.ms5PayslipForm) {
						const formData = new FormData();
						formData.append('document', values.ms5PayslipForm);
						await uploadPostCompletionDocuments(abortController.signal, formData, response.data.id, 'MS5_PAYSLIP');
					}
					await editProject(abortController.signal, {
						...project,
						postCompletion: response.data,
					});
					notify(t('Project Post Completion updated successfully.'), {
						type: toast.TYPE.SUCCESS,
					});
				}
				if (onDirtyForm) {
					onDirtyForm(false);
				}
				await onTileSave();
				handleCancel();
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					handleCancel();
					setIsSavingChanges(false);
					resetForm({ values });
				}
			}
		},
	});

	const [isPCTShow, setIsPCTShow] = useState();
	useEffect(() => {
		if (values.pctPassed) {
			setIsPCTShow(values.pctPassed.toString());
		}
	}, [values.pctPassed]);

	const [isPCDShow, setisPCDShow] = useState();
	useEffect(() => {
		if (values.pcdPaid) {
			setisPCDShow(values.pcdPaid.toString());
		}
	}, [values.pcdPaid]);

	const [isMS5Show, setIsMS5Show] = useState();
	useEffect(() => {
		if (values.ms5Paid) {
			setIsMS5Show(values.ms5Paid.toString());
		}
	}, [values.ms5Paid]);

	useEffect(() => {
		if (project?.postCompletion) {
			setFieldValue('pcdAmount', project?.postCompletion?.pcdAmount?.value);
		} else {
			setFieldValue('pcdAmount', '');
		}
	}, [project?.postCompletion, setFieldValue]);

	const currencySign = values.pcdCurrency
		? () => (values.pcdCurrency.value === 'EUR' ? '€' : values.pcdCurrency.value)
		: null;

	const handleDocumentDownload = async document => {
		try {
			const response = await getPostCompletionDownloadLink(
				abortController.signal,
				document.name,
				document.versionId,
			);
			window.open(response.data, '_blank');
		} catch (error) {
			showToastError(error);
		}
	};

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedtoEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('POST-COMPLETION')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<Title>{t('PCT (Post-Completion Test)')}</Title>
			<StyledCheckField>
				<FormikRadio
					id="pctPassed"
					name="pctPassed"
					label={t('PCD check: Has PCD been paid?')}
					value={values.pctPassed}
					error={errors.pctPassed}
					touched={touched.pctPassed}
					onChange={handleChange}
					options={[
						{
							value: true,
							label: 'Yes',
						},
						{
							value: false,
							label: 'No',
						},
					]}
					isRow
					isTile
					isRequired
					isDisabled={isIndustryFieldDisabled}
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_POST_COMPLETION.POST_COMPLETION,
					)}
				/>
			</StyledCheckField>
			<StyledGridContainer container>
				<StyledGrid item xs={12} lg={6} xl={3}>
					<StyledFormField>
						<ConditionalGridSection hasFlag={isPCTShow === 'true'}>
							<FormikSingleDatePicker
								id="pctDate"
								label={t('Date of PCT completion')}
								startDateName="pctDate"
								error={errors.pctDate}
								touched={touched.pctDate}
								startDate={values.pctDate}
								setFieldValue={setFieldValue}
								setFieldTouched={setFieldTouched}
								isOverlay
								isDisabled={isIndustryFieldDisabled}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_POST_COMPLETION.POST_COMPLETION,
								)}
							/>
						</ConditionalGridSection>
					</StyledFormField>
				</StyledGrid>
				<StyledGrid item xs={12} lg={6} xl={3}>
					<StyledFormField>
						<ConditionalGridSection hasFlag={isPCTShow === 'true'}>
							<FormikInput
								id="pctRequiredPR"
								name="pctRequiredPR"
								label={t('Required PR')}
								value={values.pctRequiredPR}
								error={errors.pctRequiredPR}
								touched={touched.pctRequiredPR}
								setFieldValue={setFieldValue}
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder={'0.00%'}
								isTile
								isInEditMode={isInEditMode}
								isDisabled={isIndustryFieldDisabled}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_POST_COMPLETION.POST_COMPLETION,
								)}
							/>
						</ConditionalGridSection>
					</StyledFormField>
				</StyledGrid>
				<StyledGrid item xs={12} lg={6} xl={3}>
					<ConditionalGridSection hasFlag={isPCTShow === 'true'}>
						<StyledFormField>
							<FormikInput
								id="pctPRCalculationResult"
								name="pctPRCalculationResult"
								label={t('PR calculation result')}
								value={values.pctPRCalculationResult}
								error={errors.pctPRCalculationResult}
								touched={touched.pctPRCalculationResult}
								setFieldValue={setFieldValue}
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder={'0.00%'}
								isTile
								isInEditMode={isInEditMode}
								isDisabled={isIndustryFieldDisabled}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_POST_COMPLETION.POST_COMPLETION,
								)}
							/>
						</StyledFormField>
					</ConditionalGridSection>
				</StyledGrid>
				<StyledGrid item xs={12} lg={6} xl={3}>
					<ConditionalGridSection hasFlag={isPCTShow === 'true'}>
						<StyledFormField>
							<FileUploadField
								label={t('Countersigned PCT')}
								value={values.pctForm}
								name="pctForm"
								displayValue={values.pctFormName}
								fileName="Document"
								acceptedTypes={[FILE_TYPES.PDF]}
								documents={project?.postCompletion?.pctCountersigneds}
								onDocumentDownload={handleDocumentDownload}
								breadcrumbList={breadcrumbList}
								setFieldValue={setFieldValue}
								isTile
								isDisabled={isIndustryFieldDisabled}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_POST_COMPLETION.POST_COMPLETION,
								)}
							/>
						</StyledFormField>
					</ConditionalGridSection>
				</StyledGrid>
			</StyledGridContainer>

			<Title>{t('PCD (Post-Completion Damages)')}</Title>
			<StyledCheckField>
				<FormikRadio
					id="pcdPaid"
					name="pcdPaid"
					label={t('PCT check: Has the damages been paid?')}
					value={values.pcdPaid}
					error={errors.pcdPaid}
					touched={touched.pcdPaid}
					onChange={handleChange}
					options={[
						{
							value: true,
							label: 'Yes',
						},
						{
							value: false,
							label: 'No',
						},
					]}
					isRow
					isTile
					isRequired
					isDisabled={isIndustryFieldDisabled}
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_POST_COMPLETION.POST_COMPLETION,
					)}
				/>
			</StyledCheckField>
			<StyledGridContainer container>
				<StyledGrid item xs={12} lg={6} xl={3}>
					<ConditionalGridSection hasFlag={isPCDShow === 'true'}>
						<StyledFormField>
							<FormikInput
								id="pcdAmount"
								name="pcdAmount"
								label={t('PCD amount')}
								value={values.pcdAmount}
								error={errors.pcdAmount}
								touched={touched.pcdAmount}
								onChange={handleChange}
								onBlur={handleBlur}
								prefix={currencySign}
								isTile
								isInEditMode={isInEditMode}
								setFieldValue={setFieldValue}
								isDisabled={isAuthorizedFinanceRole}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_POST_COMPLETION.POST_COMPLETION,
								)}
							/>
						</StyledFormField>
					</ConditionalGridSection>
				</StyledGrid>
				<StyledGrid item xs={12} lg={6} xl={3}>
					<ConditionalGridSection hasFlag={isPCDShow === 'true'}>
						<StyledFormField>
							<FormikSelect
								isAsync
								id="pcdCurrency"
								name="pcdCurrency"
								label={t('Currency')}
								value={values.pcdCurrency}
								error={errors.pcdCurrency}
								touched={touched.pcdCurrency}
								setFieldValue={setFieldValue}
								onBlur={handleBlur}
								loadOptions={loadCurrenciesOptions}
								isOverlay
								isTile
								isInEditMode={isInEditMode}
								isDisabled={isAuthorizedFinanceRole}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_POST_COMPLETION.POST_COMPLETION,
								)}
							/>
						</StyledFormField>
					</ConditionalGridSection>
				</StyledGrid>
				<StyledGrid item xs={12} lg={6} xl={3}>
					<StyledFormField>
						<ConditionalGridSection hasFlag={isPCDShow === 'true'}>
							<FileUploadField
								label={t('PCD invoice')}
								value={values.pcdInvoiceForm}
								name="pcdInvoiceForm"
								displayValue={values.pcdInvoiceFormName}
								fileName="Document"
								acceptedTypes={[FILE_TYPES.PDF]}
								documents={project?.postCompletion?.pcdInvoices}
								onDocumentDownload={handleDocumentDownload}
								breadcrumbList={breadcrumbList}
								setFieldValue={setFieldValue}
								isTile
								isDisabled={isIndustryFieldDisabled}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_POST_COMPLETION.POST_COMPLETION,
								)}
							/>
						</ConditionalGridSection>
					</StyledFormField>
				</StyledGrid>
				<StyledGrid item xs={12} lg={6} xl={3}>
					<StyledFormField>
						<ConditionalGridSection hasFlag={isPCDShow === 'true'}>
							<FileUploadField
								label={t('PCD Payslip')}
								value={values.pcdPayslipForm}
								name="pcdPayslipForm"
								displayValue={values.pcdPayslipFormName}
								fileName="Document"
								acceptedTypes={[FILE_TYPES.PDF]}
								documents={project?.postCompletion?.pcdPayslips}
								onDocumentDownload={handleDocumentDownload}
								breadcrumbList={breadcrumbList}
								setFieldValue={setFieldValue}
								isTile
								isDisabled={isIndustryFieldDisabled}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_POST_COMPLETION.POST_COMPLETION,
								)}
							/>
						</ConditionalGridSection>
					</StyledFormField>
				</StyledGrid>
			</StyledGridContainer>

			<ConditionalGridSection hasFlag={isPCDShow === 'true'}>
				<StyledCommentField
					id="pcdComment"
					name="pcdComment"
					label={t('Comment')}
					value={values.pcdComment}
					error={errors.pcdComment}
					touched={touched.pcdComment}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					minRows={4}
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_POST_COMPLETION.POST_COMPLETION,
					)}
				/>
			</ConditionalGridSection>

			<Title>{t('MSS (Milestone Payment)')}</Title>
			<StyledGridContainer container>
				<StyledGrid item xs={12} lg={6} xl={3}>
					<StyledFormField>
						<FormikRadio
							id="ms5Paid"
							name="ms5Paid"
							label={t('MS5 check: Has MS5 been paid?')}
							value={values.ms5Paid}
							error={errors.ms5Paid}
							touched={touched.ms5Paid}
							onChange={handleChange}
							options={[
								{
									value: true,
									label: 'Yes',
								},
								{
									value: false,
									label: 'No',
								},
							]}
							isRow
							isTile
							isRequired
							isInEditMode={isInEditMode}
							isDisabled={isAuthorizedithoutdFinanceRole}
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_POST_COMPLETION.POST_COMPLETION,
							)}
						/>
					</StyledFormField>
				</StyledGrid>
				<StyledGrid item xs={12} lg={6} xl={3}>
					<StyledFormField>
						<ConditionalGridSection hasFlag={isMS5Show === 'true'}>
							<FileUploadField
								label={t('MS5 partner payslip')}
								value={values.ms5PayslipForm}
								name="ms5PayslipForm"
								displayValue={values.ms5PayslipFormName}
								fileName="Document"
								isTile
								acceptedTypes={[FILE_TYPES.PDF]}
								documents={project?.postCompletion?.ms5Payslips}
								onDocumentDownload={handleDocumentDownload}
								breadcrumbList={breadcrumbList}
								setFieldValue={setFieldValue}
								isDisabled={isAuthorizedithoutdFinanceRole}
								isHighlighted={highlightLabels.includes(
									PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_POST_COMPLETION.POST_COMPLETION,
								)}
							/>
						</ConditionalGridSection>
					</StyledFormField>
				</StyledGrid>
			</StyledGridContainer>
		</EditableTile>
	);
};

PostCompletionTile.defaultProps = {
	project: {},
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	onTileSave: async () => {},
	highlightLabels: [],
	setFieldValue: () => {},
	breadcrumbList: [],
	setFieldTouched: () => {},
	values: {},
	errors: {},
	touched: {},
};

PostCompletionTile.propTypes = {
	values: PropTypes.shape({
		highlightLabels: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	errors: PropTypes.shape({
		paymentVerification: PropTypes.string,
		signInsurancePolicyName: PropTypes.string,
		insurancepayslipName: PropTypes.string,
		insurancepayslip: PropTypes.string,
		signInsurancePolicyDocuments: PropTypes.string,
		breadcrumbList: PropTypes.string,
	}),
	touched: PropTypes.shape({
		paymentVerification: PropTypes.bool,
		signInsurancePolicyName: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
		insurancepayslipName: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
		insurancepayslip: PropTypes.bool,
		signInsurancePolicyDocuments: PropTypes.bool,
		breadcrumbList: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
	}),
	onDirtyForm: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onEnterEditMode: PropTypes.func,
	onTileSave: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
	breadcrumbList: PropTypes.arrayOf(PropTypes.string),
	project: PropTypes.shape({
		postCompletion: PropTypes.arrayOf(),
	}),
};

export default PostCompletionTile;
