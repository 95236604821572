import { useCallback } from 'react';

import CommentList from 'Common/components/Comments/CommentList';
import SidePanelTiles from 'Common/components/SidePanel/components/SidePanelTiles';
import SidePanelHistory from 'Common/components/SidePanel/components/SidePanelHistory';
import SidePanelStepInfo from 'Common/components/SidePanel/components/SidePanelStepInfo';
import SidebarLineSeparator from 'Common/components/SidePanel/components/SidePanelLineSeparator';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useStepCommentsData from 'PMWorkflow/components/Step/hooks/useStepCommentsData';

const ProgressSidePanelStep = ({ step, showHistory, project, onDataChange, dropdownProps, ...rest }) => {
	const isSoftLoading = useSelector(state => state.sidePanel.isSoftLoading);

	const handleUpdateEntity = useCallback(
		newProjectData => {
			if (newProjectData) {
				onDataChange(newProjectData);
				return newProjectData;
			}
			return onDataChange();
		},
		[onDataChange],
	);

	const modifiedDropdownProps = { ...dropdownProps, projectId: project.id, isSoftLoading: isSoftLoading };

	const { defaultComments, onAddComment, onEditComment, onDeleteComment } = useStepCommentsData(project, step);

	return (
		<>
			<SidePanelStepInfo
				step={step}
				dropdownProps={modifiedDropdownProps}
				// ! Intentionally removing status
				// eslint-disable-next-line no-unused-vars
				onStatusUpdate={status => handleUpdateEntity()}
				{...rest}
			/>
			<SidebarLineSeparator />

			<SidePanelHistory step={step} showHistory={showHistory} />
			{showHistory && <SidebarLineSeparator />}

			{step?.projectTiles?.length > 0 && (
				<>
					<SidePanelTiles
						project={project}
						tiles={step.projectTiles}
						fields={step.projectFields}
						updateEntity={handleUpdateEntity}
					/>
					<SidebarLineSeparator />
				</>
			)}

			<CommentList
				defaultComments={defaultComments}
				onAddComment={onAddComment}
				onEditComment={onEditComment}
				onDeleteComment={onDeleteComment}
			/>
		</>
	);
};

ProgressSidePanelStep.defaultValues = {
	showHistory: false,
	step: {},
	project: {},
	dropdownProps: {},
	onDataChange: () => {},
};

ProgressSidePanelStep.defaultProps = {
	step: null,
	project: null,
	showHistory: false,
	onDataChange: () => null,
	dropdownProps: null,
};

ProgressSidePanelStep.propTypes = {
	step: PropTypes.shape({
		projectTiles: PropTypes.arrayOf(PropTypes.shape({})),
		projectFields: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	}),
	showHistory: PropTypes.bool,
	onDataChange: PropTypes.func,
	dropdownProps: PropTypes.shape({}),
};

export default ProgressSidePanelStep;
