import { useCallback, useEffect, useMemo, useState } from 'react';

import getRfepById from 'Rfep/api/getRfepById';

import crudModes from 'Common/constants/crudModes';

import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import OverlaySections from 'Common/components/modals/OverlaySections';
import useRfepOverlayValidationSchema from './hooks/useRfepOverlayValidationSchema';
import useRfepOverlaySections from './hooks/useRfepOverlaySections';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import editRfep from 'Rfep/api/editRfep';
import createRfep from 'Rfep/api/createRfep';
import reopenRfep from 'Rfep/api/reopenRfep';
import { documentTypes, typesByCategory } from 'Projects/constants/documentTableOptions';
import formatDateForRequestData from 'Common/utils/formatDateForRequestData';
import useRfepOverlayValues from './hooks/useRfepOverlayValues';
import projectTypes from 'Projects/constants/projectTypes';
import { useOverlayTitles } from 'Common/components/modals/Overlay';

const RfepOverlay = ({ mode, isOpen, onClose, onFormSubmit, project }) => {
	const [rfep, setRfep] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();
	const { name, label, saveBtnLabel, closeBtnLabel } = useOverlayTitles(t('RFEP'), mode);

	const abortController = useAbortController();

	const isEditMode = useMemo(() => mode === crudModes.EDIT, [mode]);
	const isCreateMode = useMemo(() => mode === crudModes.CREATE, [mode]);

	useEffect(() => {
		(async () => {
			if (!isEditMode) return;
			if (!isOpen) return;

			setIsLoading(true);
			try {
				const response = await getRfepById(abortController.signal, project.tender.id, true);
				setRfep({
					...response.data,
					invitedPartners: response.data?.invitedPartners.map(partner => ({
						value: partner.id,
						label: partner.name,
					})),
					tenderCloseDate: response.data?.tenderCloseDate ? new Date(response.data?.tenderCloseDate) : null,
				});
				setIsLoading(false);
			} catch (error) {
				showToastError(error, 'Error while fetching RFEP');
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		})();
	}, [project, abortController.signal, isEditMode, isOpen]);

	const breadcrumbList = useMemo(
		() => [
			{
				label: t('Sales'),
				link: `/projects/details/${project?.id}`,
			},
			{
				label: t('RFEP'),
				link: `/projects/details/${project?.id}`,
			},
			{
				label: name,
			},
		],
		[project.id, name, t],
	);

	const { sectionFields, defaultValues } = useRfepOverlayValues(project, rfep, isCreateMode);
	const sections = useRfepOverlaySections(project, defaultValues);
	const validationSchema = useRfepOverlayValidationSchema();

	const handleCreateSubmit = useCallback(
		async values => {
			try {
				const createResponse = await createRfep(
					abortController.signal,
					{
						...values,
						systemSize: project.projectType?.name === projectTypes.PV ? values.systemSize : null,
						locationUrl: project.locationUrl,
						sendTender: values.sendTender
							? {
									invitedPartners: values.invitedPartners.map(partner => partner.value),
							  }
							: undefined,
						tenderCloseDate:
							values.tenderCloseDate && values.rfepCloseDateActive
								? formatDateForRequestData(values.tenderCloseDate)
								: null,
						invitedPartners: values?.invitedEpcPartners?.map(partner => partner.value) || [],
						documentDefs:
							Object.keys(documentTypes)
								?.filter(type => Boolean(values[`${type}_request`]?.length))
								?.map(type => ({
									documentTitle: type,
									categoryName: typesByCategory[type],
									request: Boolean(values[`${type}_request`]),
									mandatory:
										values[`${type}_mandatory`] !== undefined ? Boolean(values[`${type}_mandatory`].length) : true,
								})) || [],
					},
					project.id,
				);

				await onFormSubmit(createResponse.data);

				onClose();

				notify(t('RFEP created successfully'), {
					type: toast.TYPE.SUCCESS,
				});
			} catch (error) {
				showToastError(error, "Couldn't create RFEP");

				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
		[
			abortController.signal,
			onClose,
			onFormSubmit,
			project.id,
			project.locationUrl,
			project.projectType?.name,
			t,
		],
	);

	const handleEditSubmit = useCallback(
		async values => {
			try {
				let editResponse;
				if (defaultValues.state === 'Closed') {
					editResponse = await reopenRfep(abortController.signal, {
						...values,
						systemSize: project.projectType?.name === projectTypes.PV ? values.systemSize : null,
						sendTender: {
							invitedPartners: values?.invitedPartners && values?.invitedPartners.map(partner => partner.value),
						},
						tenderCloseDate:
							values.tenderCloseDate && values.rfepCloseDateActive
								? formatDateForRequestData(values.tenderCloseDate)
								: null,
						id: project.tender.id,
						documentDefs:
							Object.keys(documentTypes)
								?.filter(type => Boolean(values[`${type}_request`]?.length))
								?.map(type => ({
									documentTitle: type,
									categoryName: typesByCategory[type],
									request: Boolean(values[`${type}_request`]),
									mandatory:
										values[`${type}_mandatory`] !== undefined ? Boolean(values[`${type}_mandatory`].length) : true,
								})) || [],
					});

					await onFormSubmit(editResponse.data);

					notify(t('RFEP reopened successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				} else {
					editResponse = await editRfep(abortController.signal, {
						...values,
						systemSize: project.projectType?.name === projectTypes.PV ? values.systemSize : null,
						sendTender: values.sendTender
							? {
									invitedPartners: values?.invitedPartners && values?.invitedPartners.map(partner => partner.value),
							  }
							: undefined,
						tenderCloseDate:
							values.tenderCloseDate && values.rfepCloseDateActive
								? formatDateForRequestData(values.tenderCloseDate)
								: null,
						id: project.tender.id,
						documentDefs: Object.keys(documentTypes)
							.filter(type => Boolean(values[`${type}_request`]?.length))
							.map(type => ({
								documentTitle: type,
								categoryName: typesByCategory[type],
								request: Boolean(values[`${type}_request`]),
								mandatory:
									values[`${type}_mandatory`] !== undefined ? Boolean(values[`${type}_mandatory`].length) : true,
							})),
					});

					await onFormSubmit(editResponse.data);

					notify(t('RFEP saved successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				}
				onClose();
			} catch (error) {
				showToastError(error, 'RFEP could not be saved');
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
		[
			abortController?.signal,
			defaultValues?.state,
			onClose,
			onFormSubmit,
			project?.projectType?.name,
			project?.tender?.id,
			t,
		],
	);

	return (
		<OverlaySections
			label={label}
			breadcrumbList={breadcrumbList}
			isOpen={isOpen}
			onClose={onClose}
			onSaveOverlay={mode === crudModes.CREATE ? handleCreateSubmit : handleEditSubmit}
			isLoadingContent={isLoading}
			sections={sections}
			defaultValues={defaultValues}
			sectionFields={sectionFields}
			validationSchema={validationSchema}
			saveBtnLabel={saveBtnLabel}
			closeBtnLabel={closeBtnLabel}
		/>
	);
};

RfepOverlay.defaultProps = {
	mode: crudModes.CREATE,
	isOpen: false,
	onClose: () => {},
	onFormSubmit: () => {},
	project: {},
};

RfepOverlay.propTypes = {
	mode: PropTypes.oneOf([crudModes.CREATE, crudModes.EDIT]),
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onFormSubmit: PropTypes.func,
	project: PropTypes.shape({
		tender: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
		systemSizeKwp: PropTypes.number,
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		projectType: PropTypes.shape({ name: PropTypes.string }),
		locationUrl: PropTypes.string,
	}),
};

export default RfepOverlay;
