import { useTranslation } from 'react-i18next';
import Overlay, { useOverlayTitles } from 'Common/components/modals/Overlay';
import SectionContainer from 'Common/components/modals/SectionContainer';
import ProjectCreateForm from './ProjectCreateForm';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import crudModes from 'Common/constants/crudModes';

const ProjectCreateOverlay = ({ isOpen, onClose, onFormSubmit }) => {
	const { t } = useTranslation();
	const { name, label } = useOverlayTitles(t('Project'), crudModes.CREATE);

	const breadcrumbList = useMemo(
		() => [
			{
				label: 'Projects',
				href: '/projects',
			},
			{
				label: name,
			},
		],
		[name],
	);

	return (
		<Overlay
			label={label}
			breadcrumbList={breadcrumbList}
			isOpen={isOpen}
			onClose={onClose}
			onSave={onFormSubmit}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<SectionContainer name="Project">
					<ProjectCreateForm onSubmit={handleSubmit} onCancel={onCancel} onDirty={setIsFormDirty} />
				</SectionContainer>
			)}
		</Overlay>
	);
};

ProjectCreateOverlay.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default ProjectCreateOverlay;
