import { createGlobalStyle } from 'styled-components';
import colors from 'Application/theme/colors';

const GlobalStyles = createGlobalStyle`
  html {
    overflow: hidden;
    color: ${colors.text.primary};
    font-size: 18px;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-button:start:decrement,
  ::-webkit-scrollbar-button:end:increment {
    display: none;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: ${colors.grey.light};
    -webkit-border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.grey.main};
    -webkit-border-radius: 5px;
  }

  ::-ms-clear {
    display: none;
  }

  ::-ms-reveal {
    display: none;
  }

  button::-moz-focus-inner {
    border: 0;
  }

  * {
    font-family: 'Montserrat', sans-serif!important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    &[data-placement^='top'] {
      .react-datepicker__triangle {
        border-top-color: #fff;
      }

      .react-datepicker__triangle::before {
        border-top-color: ${colors.grey.main};
      }
    }

    &[data-placement^='bottom'] {
      .react-datepicker__triangle {
        border-bottom-color: #fff;
      }

      .react-datepicker__triangle::before {
        border-bottom-color: ${colors.grey.main};
      }
    }

    .react-datepicker {
      border: 1px solid ${colors.grey.main};
      border-radius: 25px;
      z-index: 3;
    }
  }

  .react-datepicker {
    &__header {
      border: none;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      background: initial;
      width: 100%;
    }

    &__month-container {
      width: 100%;
    }

    &__day-names {
      border-top: 1px solid ${colors.grey.main};
      border-bottom: 1px solid ${colors.grey.main};
      color: ${colors.text.primary};
      font-weight: 600;
    }

    &__day,
    &__day-name {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin-left: 0;
      margin-right: 0;
    }

    &__day {
      &:focus {
        outline: 0 none;
      }

      &:hover {
        border-radius: 50%;
        background: #f0f0f0;
        color: #000;
      }

      &--outside-month {
        background-color: transparent;
        color: ${colors.grey.main};
        pointer-events: none;
      }

      &--selected,
      &--in-range {
        border-radius: 50%;
        background-color: ${colors.primary.main};
        color: ${colors.grey.main};
      }

      &--in-range {
        border-radius: 0;
      }

      &--range-start {
        border-radius: 50% 0 0 50%;

        &:hover {
          border-radius: 50% 0 0 50%;
        }
      }

      &--range-end {
        border-radius: 0 50% 50% 0;

        &:hover {
          border-radius: 0 50% 50% 0;
        }
      }

      &--range-start.react-datepicker__day--range-end:not(.react-datepicker__day--in-selecting-range) {
        border-radius: 50%;

        &:hover {
          border-radius: 50%;
        }
      }

      &--in-selecting-range {
        border-radius: 0;
        background-color: ${colors.primary.main};
        color: ${colors.grey.main};
      }

      &--selecting-range-start {
        border-radius: 50% 0 0 50%;

        &:hover {
          border-radius: 50% 0 0 50%;
        }
      }

      &--selecting-range-end {
        border-radius: 0 50% 50% 0;

        &:hover {
          border-radius: 0 50% 50% 0;
        }
      }

      &--selecting-range-start.react-datepicker__day--selecting-range-end {
        border-radius: 50%;

        &:hover {
          border-radius: 50%;
        }
      }
    }

    &__month {
      &--selecting-range {
        .react-datepicker__day {
          &--selected,
          &--in-range,
          &--range-start,
          &--range-end {
            &:not(.react-datepicker__day--in-selecting-range) {
              background: transparent;
              color: #000;
            }
          }
        }
      }
    }
  }
`;

export default GlobalStyles;
