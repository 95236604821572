import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';

import Icon from 'Common/components/icons/Icon';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';

import { tierTooltips } from 'EpcPartner/constants/tiers';

import colors from 'Application/theme/colors';

const Wrapper = styled.div`
	display: flex;
`;

const IconWrapper = styled.div`
	height: 23px;
	width: 18px !important;

	display: flex;
	align-items: center;
	svg {
		width: 18px !important;
		height: 18px !important;
	}

	& > div {
		height: 18px;
	}
`;

const NameWrapper = styled.div`
	display: flex;
	margin-left: ${({ showIcon }) => (showIcon ? '4px' : 0)};
	min-height: 23px;
	align-items: center;

	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;

	${({ isLink }) =>
		isLink &&
		css`
			/* white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; */
		`};

	${({ isUnqualified }) =>
		isUnqualified &&
		css`
			color: ${({ isLink }) => isLink && colors.error.main};
		`};
`;

const EpcPartnerNameWithTier = ({ tier, name, points, isLink, showIcon, hasPoints }) => {
	const isQualified = Boolean(tier);

	return (
		<Wrapper>
			{isQualified && showIcon && (
				<IconWrapper>
					<HoverTooltip placement="top" title={tierTooltips[tier]} arrow>
						<Icon type={tier} />
					</HoverTooltip>
				</IconWrapper>
			)}
			<HoverTooltip
				placement="top"
				title={hasPoints ? `${name} (${points ? Math.floor(points) : 0})` : name}
				arrow
			>
				<NameWrapper isUnqualified={!isQualified} isLink={isLink} showIcon={showIcon}>
					{name}
				</NameWrapper>
			</HoverTooltip>
		</Wrapper>
	);
};

EpcPartnerNameWithTier.defaultProps = {
	points: null,
	tier: false,
	isLink: false,
	showIcon: true,
	hasPoints: true,
};

EpcPartnerNameWithTier.propTypes = {
	tier: PropTypes.bool,
	name: PropTypes.string.isRequired,
	points: PropTypes.arrayOf(PropTypes.shape({})),
	isLink: PropTypes.bool,
	showIcon: PropTypes.bool,
	hasPoints: PropTypes.bool,
};

export default EpcPartnerNameWithTier;
