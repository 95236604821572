// TODO remove the eslint complexity and fix it
/* eslint-disable complexity */
import { useMemo } from 'react';
import { useCallback } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import Big from 'Common/utils/customBig';
import { PropTypes } from 'prop-types';

import formatNumber from 'Common/utils/formatNumber';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import FormikSelect from 'Common/components/form/FormikSelect';
import NoFalseValueSwitch from 'Common/components/form/NoFalseValueSwitch';
import UNITS from 'Common/constants/units';
import useReponsive from 'Common/hooks/useResponsive';
import projectTypes from 'Projects/constants/projectTypes';

const FieldsRow = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	min-height: 82px;
`;

const StyledFormField = styled(FormField)`
	width: ${({ $fullWidth, $small }) => ($fullWidth ? '100%' : $small ? '150px' : '200px')};
	margin-bottom: 20px !important;
`;

const Title = styled.div`
	font-size: 20px;
	font-weight: 600;
	margin-right: 20px;
	margin-bottom: 20px;
	//padding: 0 10px;
`;

const SwitchWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 82px;
	margin: 0 24px;

	@media (min-width: 1280px) {
		justify-content: flex-end;
	}
`;

const BiddingForm = ({
	errors,
	touched,
	values,
	handleChange,
	handleBlur,
	setFieldValue,
	showYieldField = true,
	showSolarUtilisationRateField = true,
	setFieldTouched,
	projectType,
	context,
	contextLabel,
	isTile,
	isInEditMode,
}) => {
	const { t } = useTranslation();
	const { isSmallscreen, isMediumScreen } = useReponsive();

	const CurrencySign = () =>
		useMemo(() => <>{values?.currency?.value === 'EUR' ? '€' : values?.currency?.value}</>, []);

	const systemSize = useMemo(() => Number(values.systemSize), [values.systemSize]);

	const epcVolumeSwitch = useMemo(() => values.epcVolumeSwitch, [values.epcVolumeSwitch]);

	const omPriceSwitch = useMemo(() => values.omPriceSwitch, [values.omPriceSwitch]);

	const handleEpcVolumeKwpFieldChange = useCallback(
		e => {
			handleChange(e);
			const epcVolumeKwp = Number(e.target.value);

			if (epcVolumeSwitch) {
				if (isNaN(systemSize) || systemSize === 0) {
					setFieldValue('epcVolumeExclVat', '', false);
				} else {
					setFieldValue(
						'epcVolumeExclVat',
						epcVolumeKwp ? Number(Big(epcVolumeKwp).times(Big(systemSize)).toString()).toFixed(2) : '',
						false,
					);
					if (errors['epcVolumeExclVat'] && epcVolumeKwp) {
						setFieldTouched('epcVolumeExclVat');
					}
				}
			}
		},
		[handleChange, epcVolumeSwitch, systemSize, setFieldTouched, setFieldValue, errors],
	);

	const handleEpcVolumeFieldChange = useCallback(
		e => {
			handleChange(e);
			const epcVolume = Number(e.target.value);

			if (!epcVolumeSwitch) {
				if (isNaN(systemSize) || systemSize === 0) {
					setFieldValue('epcVolumeExclVatKwp', '', false);
				} else {
					setFieldValue(
						'epcVolumeExclVatKwp',
						epcVolume ? Number(Number(Big(epcVolume).div(Big(systemSize)).toString()).toFixed(2)) : '',
						false,
					);
					if (errors['epcVolumeExclVatKwp'] && epcVolume) {
						setFieldTouched('epcVolumeExclVatKwp');
					}
				}
			}
		},
		[handleChange, epcVolumeSwitch, systemSize, setFieldTouched, setFieldValue, errors],
	);

	const handleOmPriceKwpFieldChange = useCallback(
		e => {
			handleChange(e);
			const annualOmPriceKwp = Number(e.target.value);

			if (omPriceSwitch) {
				if (isNaN(systemSize) || systemSize === 0) {
					setFieldValue('annualOmPrice', '', false);
				} else {
					setFieldValue(
						'annualOmPrice',
						annualOmPriceKwp ? Number(Big(annualOmPriceKwp).times(Big(systemSize)).toString()).toFixed(2) : '',
						false,
					);
					if (errors['annualOmPrice'] && annualOmPriceKwp) {
						setFieldTouched('annualOmPrice');
					}
				}
			}
		},
		[handleChange, omPriceSwitch, systemSize, setFieldTouched, setFieldValue, errors],
	);

	const handleOmPriceFieldChange = useCallback(
		e => {
			handleChange(e);
			const annualOmPrice = Number(e.target.value);
			if (!omPriceSwitch) {
				if (isNaN(systemSize) || systemSize === 0) {
					setFieldValue('annualOmPriceKwp', '', false);
				} else {
					setFieldValue(
						'annualOmPriceKwp',
						annualOmPrice ? Number(Number(Big(annualOmPrice).div(Big(systemSize)).toString()).toFixed(2)) : '',
						false,
					);
					if (errors['annualOmPriceKwp'] && annualOmPrice) {
						setFieldTouched('annualOmPriceKwp');
					}
				}
			}
		},
		[handleChange, omPriceSwitch, systemSize, setFieldTouched, setFieldValue, errors],
	);

	const handleSystemSizeChange = useCallback(
		e => {
			handleChange(e);
			const systemSizeField = Number(e.target.value);

			if (!omPriceSwitch) {
				if (isNaN(systemSizeField) || systemSizeField === 0) {
					setFieldValue('annualOmPriceKwp', '', false);
				} else {
					setFieldValue(
						'annualOmPriceKwp',
						values.annualOmPrice
							? Number(Number(Big(values.annualOmPrice).div(Big(systemSizeField)).toString()).toFixed(2))
							: '',
						false,
					);
					if (errors['annualOmPriceKwp'] && values.annualOmPrice) {
						setFieldTouched('annualOmPriceKwp');
					}
				}
			}
			if (omPriceSwitch) {
				if (isNaN(systemSizeField) || systemSizeField === 0) {
					setFieldValue('annualOmPrice', '', false);
				} else {
					setFieldValue(
						'annualOmPrice',
						values.annualOmPriceKwp
							? Number(Big(values.annualOmPriceKwp).times(Big(systemSizeField)).toString()).toFixed(2)
							: '',
						false,
					);
					if (errors['annualOmPrice'] && values.annualOmPriceKwp) {
						setFieldTouched('annualOmPrice');
					}
				}
			}
			if (!epcVolumeSwitch) {
				if (isNaN(systemSizeField) || systemSizeField === 0) {
					setFieldValue('epcVolumeExclVatKwp', '', false);
				} else {
					setFieldValue(
						'epcVolumeExclVatKwp',
						values.epcVolumeExclVat
							? Number(Number(Big(values.epcVolumeExclVat).div(Big(systemSizeField)).toString()).toFixed(2))
							: '',
						false,
					);
					if (errors['epcVolumeExclVatKwp'] && values.epcVolumeExclVat) {
						setFieldTouched('epcVolumeExclVatKwp');
					}
				}
			}
			if (epcVolumeSwitch) {
				if (isNaN(systemSizeField) || systemSizeField === 0) {
					setFieldValue('epcVolumeExclVat', '', false);
				} else {
					setFieldValue(
						'epcVolumeExclVat',
						values.epcVolumeExclVatKwp
							? Number(Big(values.epcVolumeExclVatKwp).times(Big(systemSizeField)).toString()).toFixed(2)
							: '',
						false,
					);
					if (errors['epcVolumeExclVat'] && values.epcVolumeExclVatKwp) {
						setFieldTouched('epcVolumeExclVat');
					}
				}
			}
		},
		[
			values.epcVolumeExclVatKwp,
			values.epcVolumeExclVat,
			values.annualOmPriceKwp,
			values.annualOmPrice,
			handleChange,
			omPriceSwitch,
			epcVolumeSwitch,
			setFieldTouched,
			setFieldValue,
			errors,
		],
	);

	const handleEpcVolumeSwitchChange = e => setFieldValue('epcVolumeSwitch', e);

	const handleOmPriceSwitchChange = e => setFieldValue('omPriceSwitch', e);

	const showSystemSize = projectType === projectTypes.PV || context !== 'EPC_PORTAL_OFFER';
	const showSizeSection = showSystemSize || showYieldField || showSolarUtilisationRateField;

	return (
		<>
			{showSizeSection && (
				<>
					<Title>{t('Size')}</Title>
					<FieldsRow>
						{showSystemSize && (
							<StyledFormField $fullWidth={projectType !== projectTypes.PV}>
								<FormikInput
									label={t('System size')}
									id="systemSize"
									name="systemSize"
									error={errors.systemSize}
									touched={touched.systemSize}
									value={values.systemSize}
									onChange={handleSystemSizeChange}
									onBlur={handleBlur}
									isRequired
									isDisabled={projectType !== projectTypes.PV}
									tooltip={
										projectType !== projectTypes.PV
											? {
													tooltip: t(`System size is calculated from the EPC Volume when the ${contextLabel} is saved.`),
											  }
											: ''
									}
									isTile={isTile}
									isInEditMode={isInEditMode}
									unit={UNITS.KWP}
								/>
							</StyledFormField>
						)}
						{showYieldField && (
							<StyledFormField $fullWidth={projectType !== projectTypes.PV}>
								<FormikInput
									label={t('Yield')}
									id="yield"
									name="yield"
									error={errors.yield}
									touched={touched.yield}
									value={values.yield}
									onChange={handleChange}
									onBlur={handleBlur}
									isRequired
									isTile={isTile}
									isInEditMode={isInEditMode}
									unit={UNITS.KWH_KWP}
								/>
							</StyledFormField>
						)}
					</FieldsRow>

					{showSolarUtilisationRateField && (
						<FieldsRow>
							<StyledFormField $fullWidth={projectType !== projectTypes.PV}>
								<FormikInput
									id="solarUtilisationRate"
									name="solarUtilisationRate"
									label={t('Solar utilization rate')}
									value={values.solarUtilisationRate}
									error={errors.solarUtilisationRate}
									touched={touched.solarUtilisationRate}
									onChange={handleChange}
									onBlur={handleBlur}
									tooltip={{
										tooltip: t(
											'The % of annual generated energy expected to be consumed.  The remaining % is the expected grid injection, if feed-in applies.',
										),
									}}
									isTile={isTile}
									isInEditMode={isInEditMode}
									unit={UNITS.PERCENT}
									isRequired
								/>
							</StyledFormField>
						</FieldsRow>
					)}
				</>
			)}

			<Title>{t('EPC volume')}</Title>
			<FieldsRow>
				<Grid container>
					<Grid item xs={12} lg={4}>
						<StyledFormField $fullWidth={projectType !== projectTypes.PV} $small={isSmallscreen || isMediumScreen}>
							<FormikInput
								prefix={CurrencySign}
								label={t('EPC volume')}
								id="epcVolumeExclVat"
								name="epcVolumeExclVat"
								error={errors.epcVolumeExclVat}
								touched={!values.epcVolumeSwitch && touched.epcVolumeExclVat}
								value={values.epcVolumeExclVat}
								onChange={handleEpcVolumeFieldChange}
								onBlur={handleBlur}
								isRequired
								isTile={isTile}
								isDisabled={values.epcVolumeSwitch}
								isInEditMode={isInEditMode}
							/>
						</StyledFormField>
					</Grid>
					{projectType === projectTypes.PV && (
						<>
							<Grid item xs={12} lg={4}>
								{isInEditMode && (
									<SwitchWrapper>
										<NoFalseValueSwitch
											setValue={handleEpcVolumeSwitchChange}
											name="epcVolumeSwitch"
											value={Boolean(values.epcVolumeSwitch)}
										/>
									</SwitchWrapper>
								)}
							</Grid>
							<Grid item xs={12} lg={4}>
								<StyledFormField>
									<FormikInput
										prefix={CurrencySign}
										label={t('EPC volume per kWp')}
										id="epcVolumeExclVatKwp"
										name="epcVolumeExclVatKwp"
										error={values.epcVolumeSwitch ? errors.epcVolumeExclVatKwp : null}
										touched={touched.epcVolumeExclVatKwp}
										value={
											isInEditMode
												? values.epcVolumeExclVatKwp
												: values.epcVolumeExclVatKwp
												? formatNumber(values.epcVolumeExclVatKwp, 0)
												: ''
										}
										onChange={handleEpcVolumeKwpFieldChange}
										onBlur={handleBlur}
										isRequired
										isTile={isTile}
										isDisabled={!values.epcVolumeSwitch}
										isInEditMode={isInEditMode}
									/>
								</StyledFormField>
							</Grid>
						</>
					)}
				</Grid>
			</FieldsRow>
			<FieldsRow>
				<StyledFormField $fullWidth={projectType !== projectTypes.PV}>
					<FormikSelect
						isAsync
						name="currency"
						label={t('Currency')}
						id="currency"
						error={errors.currency}
						touched={touched.currency}
						value={values.currency}
						setFieldValue={setFieldValue}
						loadOptions={loadCurrenciesOptions}
						isTile={isTile}
						isRequired
						onBlur={handleBlur}
						isInEditMode={isInEditMode}
					/>
				</StyledFormField>
			</FieldsRow>
			<Title>{t('O&M')}</Title>
			<FieldsRow>
				<Grid container>
					<Grid item xs={12} lg={4}>
						<StyledFormField $fullWidth={projectType !== projectTypes.PV} $small={isSmallscreen || isMediumScreen}>
							<FormikInput
								prefix={CurrencySign}
								label={t('Annual O&M price')}
								id="annualOmPrice"
								name="annualOmPrice"
								error={errors.annualOmPrice}
								touched={!values.omPriceSwitch && touched.annualOmPrice}
								value={values.annualOmPrice}
								onChange={handleOmPriceFieldChange}
								onBlur={handleBlur}
								isRequired
								isTile={isTile}
								isDisabled={Boolean(values.omPriceSwitch)}
								isInEditMode={isInEditMode}
							/>
						</StyledFormField>
					</Grid>
					{projectType === projectTypes.PV && (
						<>
							<Grid item xs={12} lg={4}>
								{isInEditMode && (
									<SwitchWrapper>
										<NoFalseValueSwitch
											setValue={handleOmPriceSwitchChange}
											name="omPriceSwitch"
											value={Boolean(values.omPriceSwitch)}
										/>
									</SwitchWrapper>
								)}
							</Grid>
							<Grid item xs={12} lg={4}>
								<StyledFormField>
									<FormikInput
										prefix={CurrencySign}
										label={t('Annual O&M per kWp')}
										id="annualOmPriceKwp"
										name="annualOmPriceKwp"
										error={errors.annualOmPriceKwp}
										touched={Boolean(values.omPriceSwitch && touched.annualOmPriceKwp)}
										value={values.annualOmPriceKwp}
										onChange={handleOmPriceKwpFieldChange}
										onBlur={handleBlur}
										isRequired
										isTile={isTile}
										isDisabled={!values.omPriceSwitch}
										isInEditMode={isInEditMode}
									/>
								</StyledFormField>
							</Grid>
						</>
					)}
				</Grid>
			</FieldsRow>
			<FieldsRow>
				<StyledFormField $fullWidth={projectType !== projectTypes.PV}>
					<FormikInput
						label={t('Annual O&M escalation rate')}
						id="annualOmEscalationRate"
						name="annualOmEscalationRate"
						error={errors.annualOmEscalationRate}
						touched={touched.annualOmEscalationRate}
						value={values.annualOmEscalationRate}
						onChange={handleChange}
						onBlur={handleBlur}
						isRequired
						isTile={isTile}
						unit="%"
						isInEditMode={isInEditMode}
					/>
				</StyledFormField>
			</FieldsRow>
		</>
	);
};

BiddingForm.defaultProps = {
	isTile: false,
	contextLabel: '',
};

BiddingForm.propTypes = {
	errors: PropTypes.shape({
		currency: PropTypes.string,
		systemSize: PropTypes.string,
		epcVolumeSwitch: PropTypes.string,
		omPriceSwitch: PropTypes.string,
		epcVolumeExclVat: PropTypes.string,
		epcVolumeExclVatKwp: PropTypes.string,
		annualOmPrice: PropTypes.string,
		annualOmPriceKwp: PropTypes.string,
		yield: PropTypes.string,
		solarUtilisationRate: PropTypes.string,
		annualOmEscalationRate: PropTypes.string,
	}).isRequired,
	touched: PropTypes.shape({
		currency: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
		systemSize: PropTypes.bool,
		epcVolumeSwitch: PropTypes.bool,
		omPriceSwitch: PropTypes.bool,
		epcVolumeExclVat: PropTypes.bool,
		epcVolumeExclVatKwp: PropTypes.bool,
		annualOmPrice: PropTypes.bool,
		annualOmPriceKwp: PropTypes.bool,
		yield: PropTypes.bool,
		solarUtilisationRate: PropTypes.bool,
		annualOmEscalationRate: PropTypes.bool,
	}).isRequired,
	values: PropTypes.shape({
		currency: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ value: PropTypes.string.isRequired })]),
		systemSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		epcVolumeSwitch: PropTypes.bool,
		omPriceSwitch: PropTypes.bool,
		epcVolumeExclVat: PropTypes.number,
		epcVolumeExclVatKwp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		annualOmPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		annualOmPriceKwp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		yield: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		solarUtilisationRate: PropTypes.string,
		annualOmEscalationRate: PropTypes.number,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	handleBlur: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	showYieldField: PropTypes.bool.isRequired,
	showSolarUtilisationRateField: PropTypes.bool.isRequired,
	projectType: PropTypes.string.isRequired,
	context: PropTypes.string.isRequired,
	contextLabel: PropTypes.string,
	isTile: PropTypes.bool,
	isInEditMode: PropTypes.bool.isRequired,
};

export default BiddingForm;
