import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import ContactsList from './ContactsList/ContactsList';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import deletePartnerContact from 'Partners/api/deletePartnerContact';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';

const ContactsTab = ({ partner }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [contacts, setContacts] = useState(partner.contacts);
	const [contactForDeletion, setContactForDeletion] = useState(null);

	const abortController = useAbortController();

	const handleListDeleteContact = contact => {
		setContactForDeletion(contact);
	};

	const handleContactDelete = async contact => {
		try {
			await deletePartnerContact(abortController.signal, contact.id);
			setContactForDeletion(null);
			setContacts(prev => prev.filter(e => e.id !== contact.id));
			notify(t(`Contact ${contact.firstName} ${contact.lastName} deleted successfully`), {
				type: toast.TYPE.SUCCESS,
			});
		} catch (error) {
			showToastError(error, t("Can't delete contact"));
		}
	};

	const handleContactUpdate = (data, isCreate) => {
		setContacts(prev => {
			let currentContacts = [...prev];
			if (isCreate) {
				return [...currentContacts, data];
			} else {
				return currentContacts.map(contact => {
					if (contact.id === data.id) {
						return data;
					} else {
						return contact;
					}
				});
			}
		});
	};

	const handleOpenUserEdit = email => dispatch(push(`/users/edit/${email}`));

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<ContactsList
					contacts={contacts}
					partnerId={partner.id}
					onSubmit={handleContactUpdate}
					deleteContact={handleListDeleteContact}
					openUserEdit={handleOpenUserEdit}
				/>
				<ConfirmModal
					isOpen={Boolean(contactForDeletion)}
					onCancel={() => setContactForDeletion(null)}
					onConfirm={() => handleContactDelete(contactForDeletion)}
					confirmText="Delete"
					label="delete-contact-confirm"
					heading={t('Delete contact')}
					text={t(
						`Are you sure you want to delete Contact ${contactForDeletion?.firstName} ${contactForDeletion?.lastName}?`,
					)}
				/>
			</Grid>
		</TilesGridWrapper>
	);
};

ContactsTab.propTypes = {
	partner: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		contacts: PropTypes.arrayOf(PropTypes.shape({})),
	}).isRequired,
};

export default ContactsTab;
