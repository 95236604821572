import PropTypes from 'prop-types';
import crudModes from 'Common/constants/crudModes';
import ModalForm from 'Common/components/form/ModalForm';
import AccountingStandardsForm from 'AccountingStandards/components/Form/AccountingStandardsForm';
import { useTranslation } from 'react-i18next';

const AccountingStandardsCreateModal = ({ isOpen, onClose, onFormSubmit }) => {
	const { t } = useTranslation();

	return (
		<ModalForm
			label="accounting-standards-create"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			heading={t('Create Accounting Standard')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<AccountingStandardsForm
					mode={crudModes.CREATE}
					onCancel={onCancel}
					onSubmit={handleSubmit}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};
AccountingStandardsCreateModal.defaultProps = {
	isOpen: false,
	onClose: () => {},
	onFormSubmit: () => {},
};
AccountingStandardsCreateModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onFormSubmit: PropTypes.func,
};

export default AccountingStandardsCreateModal;
