import api from 'Application/api/api';
import adaptClientRequestData from 'Client/api/adapters/adaptClientRequestData';
import adaptClientResponseData from 'Client/api/adapters/adaptClientResponseData';

const createClient = async (signal, values) => {
	const response = await api.post('/clients', adaptClientRequestData(values), {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptClientResponseData(response.data),
	};
};

export default createClient;
