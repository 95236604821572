import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useStatusFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				status: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.nullable()
					.required(t('Required')),

				closedReason: yup
					.object()
					.nullable()
					.shape({ label: yup.string(), value: yup.string() })
					.test('closedReason', 'Closed Reason is Required', function () {
						if (this.parent?.status?.value === 'CLOSED') {
							return !!this.from[0]?.value;
						}
						return true;
					}),
			}),
		[t],
	);
};

export default useStatusFormValidationSchema;
