import api from 'Application/api/api';

const getIndustries = (signal, usable = null, query) =>
	api.get('/industries/get-pageable', {
		params: query
			? {
					sort: 'name,ASC',
					size: 999,
					usable: usable,
					query,
			  }
			: {
					sort: 'name,ASC',
					size: 999,
					usable: usable,
			  },
		signal,
	});

export default getIndustries;
