import api from 'Application/api/api';
import adaptProjectResponseData from 'Projects/api/adapters/adaptProjectResponseData';
import adaptProjectRequestData from 'Projects/api/adapters/adaptProjectRequestData';

const editProject = async (signal, values) => {
	const response = await api.put('/projects', adaptProjectRequestData(values), {
		signal,
		timeout: 60000,
	});

	if (!response || !response.data) {
		return response;
	}
	return {
		...response,
		data: adaptProjectResponseData(response.data),
	};
};

export default editProject;
