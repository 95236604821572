import { useEffect, useMemo, useState } from 'react';
import manufacturedProductTypes from 'Manufacturer/constants/manufacturedProductTypes';
import { pick } from 'lodash';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import Big from 'Common/utils/customBig';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import projectTypes from 'Projects/constants/projectTypes';

const fallbackValues = {
	projectType: null,
	roofType: null,
	energyStorage: false,
	gensets: false,
	generatorSize: '',
	generatorQuantity: '',
	grid: 'on-grid',
	mountingTypes: [],
	[manufacturedProductTypes.SOLAR_MODULES]: null,
	[manufacturedProductTypes.INVERTERS]: null,
	[manufacturedProductTypes.BATTERIES]: null,
	[manufacturedProductTypes.MOUNTINGS]: null,
	[manufacturedProductTypes.AC_UNITS]: null,
	[manufacturedProductTypes.LED_LUMINAIRES]: null,
	[manufacturedProductTypes.SOLAR_HOT_WATER]: null,
	systemSizeKwp: '',
	kwhToKwpRel: '',
	solarUtilisationRate: '',
	availableSpace: '',
	meterQuantity: '',
	connectionPoints: '',
	batterySizeInKw: '',
	batteryCapacityInKwh: '',
	gridEnergyTariffPerKwh: '',
	currency: 'EUR',
	description: '',
};

const getManufacturerOptionByProductType = (manufacturers, productType) =>
	manufacturers?.[productType]?.displayName
		? {
				value: manufacturers[productType].name,
				label: manufacturers[productType].displayName,
		  }
		: null;

const useProjectDetailsFormInitialValues = (data, isInEditMode, projectDefaults) => {
	const currencyValue = data.gridEnergyTariffPerKwh
		? data.gridEnergyTariffPerKwh.currency
		: fallbackValues.currency;
	const [currencyOption, setCurrencyOption] = useState({
		value: currencyValue,
		label: 'EUR Euro',
	});

	const abortController = useAbortController();

	// Set the default currency to EUR (if no currency was set)
	useEffect(() => {
		(async () => {
			try {
				const currencies = await loadCurrenciesOptions(abortController.signal, currencyValue, false);

				setCurrencyOption(currencies.find(e => e.value === currencyValue));
			} catch (err) {
				showToastError(err);
			}
		})();
	}, [currencyValue, abortController.signal]);

	return useMemo(() => {
		const manufacturers = data.manufacturerObjectByProductType;

		return {
			...fallbackValues,
			...pick(data, [
				'systemSizeKwp',
				'kwhToKwpRel',
				'availableSpace',
				'meterQuantity',
				'connectionPoints',
				'batterySizeInKw',
				'batteryCapacityInKwh',
				'predictedAnnualEnergySavings',
			]),
			generatorSize: data.generatorSize ? data.generatorSize : '',
			generatorQuantity: data.generatorQuantity ? data.generatorQuantity : '',
			pvDegradationRate: data.pvDegradationRate
				? Big(data.pvDegradationRate)?.times(100)
				: projectDefaults.pvDegradationRate * 100,
			solarUtilisationRate: data.solarUtilisationRate,

			projectType: data.projectType
				? {
						value: data.projectType.id,
						label: data.projectType.name,
				  }
				: null,

			roofType: data.roofType
				? {
						value: data.roofType.id,
						label: data.roofType.name,
				  }
				: null,

			energyStorage: !!data.energyStorage,
			gensets: !!data.gensets,
			grid: data.projectType?.name === projectTypes.PV ? (data.onGrid ? 'on-grid' : 'off-grid') : 'on-grid',

			gridEnergyTariffPerKwh: data.gridEnergyTariffPerKwh ? data.gridEnergyTariffPerKwh.value : null,
			gridEnergyTariffPerKwhEur:
				data.gridEnergyTariffPerKwh && !data.gridEnergyTariffPerKwh.euro && data.gridEnergyTariffPerKwh.eurValue
					? data.gridEnergyTariffPerKwh.eurValue
					: null,

			mountingTypes:
				data.mountingTypes && data.mountingTypes.length > 0
					? data.mountingTypes.map(mountingType => ({
							value: mountingType.mountingType,
							label: mountingType.displayName,
					  }))
					: [],

			[manufacturedProductTypes.SOLAR_MODULES]: getManufacturerOptionByProductType(
				manufacturers,
				manufacturedProductTypes.SOLAR_MODULES,
			),

			[manufacturedProductTypes.INVERTERS]: getManufacturerOptionByProductType(
				manufacturers,
				manufacturedProductTypes.INVERTERS,
			),

			[manufacturedProductTypes.BATTERIES]: getManufacturerOptionByProductType(
				manufacturers,
				manufacturedProductTypes.BATTERIES,
			),

			[manufacturedProductTypes.MOUNTINGS]: getManufacturerOptionByProductType(
				manufacturers,
				manufacturedProductTypes.MOUNTINGS,
			),

			[manufacturedProductTypes.AC_UNITS]: getManufacturerOptionByProductType(
				manufacturers,
				manufacturedProductTypes.AC_UNITS,
			),

			[manufacturedProductTypes.LED_LUMINAIRES]: getManufacturerOptionByProductType(
				manufacturers,
				manufacturedProductTypes.LED_LUMINAIRES,
			),

			[manufacturedProductTypes.SOLAR_HOT_WATER]: getManufacturerOptionByProductType(
				manufacturers,
				manufacturedProductTypes.SOLAR_HOT_WATER,
			),

			currency: currencyOption,
			description: data?.description ? data?.description : fallbackValues.description,
		};
		// eslint-disable-next-line
	}, [currencyOption, data, isInEditMode]);
};

export default useProjectDetailsFormInitialValues;
