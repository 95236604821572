import api from 'Application/api/api';

const getPartnerTypes = (signal, query) =>
	api.get('/partner-types', {
		params: {
			query,
		},
		signal,
	});

export default getPartnerTypes;
