import PropTypes from 'prop-types';
import crudModes from 'Common/constants/crudModes';
import ModalForm from 'Common/components/form/ModalForm';
import AssetAndRevenueRecognitionForm from 'AssetAndRevenueRecognition/components/Form/AssetAndRevenueRecognitionForm';
import { useTranslation } from 'react-i18next';

const AssetAndRevenueRecognitionCreateModal = ({ isOpen, onClose, onFormSubmit }) => {
	const { t } = useTranslation();

	return (
		<ModalForm
			label="asset-and-revenue-recognition-create"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			heading={t('Create Asset & Revenue Recognition')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<AssetAndRevenueRecognitionForm
					mode={crudModes.CREATE}
					onCancel={onCancel}
					onSubmit={handleSubmit}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};
AssetAndRevenueRecognitionCreateModal.defaultProps = {
	isOpen: false,
	onClose: () => {},
	onFormSubmit: () => {},
};
AssetAndRevenueRecognitionCreateModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onFormSubmit: PropTypes.func,
};

export default AssetAndRevenueRecognitionCreateModal;
