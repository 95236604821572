const UNITS = {
	KWP: 'kWp',
	KWH: 'kWh',
	KVA: 'kVa',
	KWH_KWP: 'kWh/kWp',
	PER_KWP: 'per kWp',
	KWP_PER_YEAR: 'kWp/kWp per year',
	M2: 'm2',
	KW: 'kw',
	COD: 'COD',
	exVAT: 'ex. VAT',
	PERCENT_PA: 'in % p.a.',
	MWH: 'MWh',
	PERCENT: '%',
	PERCENT_PER_YEAR: '% per year',
	EUR: '€',
};

export default UNITS;
