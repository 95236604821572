import { useMemo } from 'react';

const useInputValue = (rawValue, unit, hasFocus, isTile, isInEditMode) => {
	const hasUnit = Boolean(unit);
	const isReadMode = useMemo(() => isTile && !isInEditMode, [isTile, isInEditMode]);

	const value = useMemo(() => {
		if (rawValue || rawValue === 0) {
			if (hasFocus) {
				return rawValue;
			}
			if (hasUnit && (isReadMode || !isTile)) {
				return `${rawValue} ${unit}`;
			}
			return rawValue;
		}
		if (hasFocus) {
			return '';
		}
		return '';
	}, [rawValue, hasFocus, hasUnit, isReadMode, isTile, unit]);

	return value;
};

export default useInputValue;
