import projectTypes from 'Projects/constants/projectTypes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import OMSection from '../../Sections/OMSection';
import SizeSection from '../../Sections/SizeSection';
import VolumeSection from '../../Sections/VolumeSection';

const useEstimateOverlaySections = project => {
	const { t } = useTranslation();

	return useMemo(() => {
		const isPVProject = project?.projectType?.name === projectTypes.PV;

		return [
			{
				title: 'Size',
				section: props => <SizeSection {...props} isPVProject={isPVProject} />,
			},
			{
				title: t('EPC volume'),
				section: props => <VolumeSection {...props} isPVProject={isPVProject} />,
			},
			{
				title: t('O&M'),
				section: props => <OMSection {...props} isPVProject={isPVProject} />,
			},
		];
	}, [project?.projectType?.name, t]);
};

export default useEstimateOverlaySections;
