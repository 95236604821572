import api from 'Application/api/api';

const getCountryById = (signal, id, details = false) =>
	api.get('/countries/by-id', {
		timeout: 100000,
		params: {
			id,
			details,
		},
		signal,
	});

export default getCountryById;
