import { useEffect } from 'react';

const useFixedHeader = (ref, setFixedHeader) => {
	useEffect(() => {
		let observer;
		const element = ref.current;
		if (element) {
			observer = new IntersectionObserver(
				entries => {
					entries.forEach(entry => {
						if (entry.isIntersecting) {
							setFixedHeader(false);
						} else if (!entry.isIntersecting && entry.boundingClientRect.y < 400) {
							setFixedHeader(true);
						}
					});
				},
				{
					threshold: [1, 0.1],
				},
			);
			observer.observe(element);
		}

		return () => {
			if (observer) {
				observer.disconnect();
			}
		};
	}, [ref, setFixedHeader]);
};

export default useFixedHeader;
