import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import notify from 'Common/utils/notify';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import loadCountryOptions from 'Country/utils/loadCountryOptions';
import loadPartnerTypeOptions from 'Partners/components/PartnerForm/utils/loadPartnerTypeOptions';
import createPartner from 'Partners/api/createPartner';
import usePartnerFormValidationSchema from 'Partners/components/PartnerForm/hooks/usePartnerFormValidationSchema';
import usePartnerFormInitialValues from 'Partners/components/PartnerForm/hooks/usePartnerFormInitialValues';
import convertFormValuesToRequestData from 'Partners/components/PartnerForm/utils/convertFormValuesToRequestData';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { OverlayButtons } from 'Common/components/modals/Overlay';

const Form = styled.form`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
`;

const PartnerCreateForm = ({ onSubmit, onCancel, onDirty }) => {
	const { t } = useTranslation();
	const { isMobile } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();
	const initialValues = usePartnerFormInitialValues();
	const validationSchema = usePartnerFormValidationSchema();

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleChange,
		handleBlur,
		setFieldValue,
		setFieldError,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				let response;
				response = await createPartner(abortController.signal, convertFormValuesToRequestData(values));

				notify(t('Partner created successfully.'), {
					type: toast.TYPE.SUCCESS,
				});

				// Re-set all fields and mark them as not touched
				resetForm({ values });

				if (onSubmit) {
					onSubmit(response.data);
				}
				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form $isMobile={isMobile} onSubmit={handleSubmit}>
			<Grid container columnSpacing={20}>
				<Grid item xs={12} md={6}>
					<FormField>
						<FormikInput
							id="name"
							name="name"
							label={t('Name')}
							value={values.name}
							error={errors.name}
							touched={touched.name}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSelect
							id="partnerType"
							name="partnerType"
							label={t('Partner Type')}
							value={values.partnerType}
							error={errors.partnerType}
							touched={touched.partnerType}
							isClearable
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadPartnerTypeOptions}
							isAsync
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="description"
							name="description"
							label={t('Description')}
							value={values.description}
							error={errors.description}
							touched={touched.description}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormField>
						<FormikSelect
							isAsync
							id={parent === 'modal' ? 'countryModal' : 'country'}
							name="country"
							label={t('Country')}
							value={values.country}
							error={errors.country}
							touched={touched.country}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadCountryOptions}
							menuPosition="fixed"
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="aparmentBuilding"
							name="aparmentBuilding"
							label={t('Apartment / Building')}
							value={values.aparmentBuilding}
							error={errors.aparmentBuilding}
							touched={touched.aparmentBuilding}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="addressLine1"
							name="addressLine1"
							label={t('Address line 1')}
							value={values.addressLine1}
							error={errors.addressLine1}
							touched={touched.addressLine1}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="addressLine2"
							name="addressLine2"
							label={t('Address line 2')}
							value={values.addressLine2}
							error={errors.addressLine2}
							touched={touched.addressLine2}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="cityRegion"
							name="cityRegion"
							label={t('City / Region')}
							value={values.cityRegion}
							error={errors.cityRegion}
							touched={touched.cityRegion}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="postCode"
							name="postCode"
							label={t('Post code / ZIP')}
							value={values.postCode}
							error={errors.postCode}
							touched={touched.postCode}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
				</Grid>
			</Grid>

			<OverlayButtons
				onCancel={onCancel}
				isLoading={isLoading}
				label="Partner Create Form"
				submitButtonText={'Create Partner'}
			/>
		</Form>
	);
};

PartnerCreateForm.defaultProps = {
	data: null,
};

PartnerCreateForm.propTypes = {
	data: PropTypes.shape({
		localizations: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
	}),
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
};

export default PartnerCreateForm;
