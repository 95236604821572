import styled from 'styled-components';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

import Icon from 'Common/components/icons/Icon';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';

const TextWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: ${colors.text.primary};
`;

const OuterElement = styled.div`
	width: 100%;
	height: 13px;
	background-color: ${colors.grey.lightest};
	border-radius: 25px;
	border: 1px solid ${colors.grey.main};
	display: flex;

	& > :last-child {
		border-top-right-radius: 25px;
		border-bottom-right-radius: 25px;
	}
	& > :first-child {
		border-top-left-radius: 25px;
		border-bottom-left-radius: 25px;
	}
`;

const StepElement = styled.div`
	height: 100%;
	background-color: ${props => props.color};
`;

const Text = styled.div`
	font-weight: 600;
	font-size: 16px;
`;

const StyledWarningIcon = styled(Icon)`
	margin-left: 4px;
	margin-bottom: -4px;
`;

const MultiStepProgressBar = ({ steps, totalSteps, stepText, totalText, warning, warningText }) => (
	<>
		<TextWrapper>
			<Text>
				{stepText}
				{warning && (
					<HoverTooltip placement="right" title={warningText}>
						<StyledWarningIcon icon="reportProblem" />
					</HoverTooltip>
				)}
			</Text>
			<Text>{totalText}</Text>
		</TextWrapper>

		<OuterElement>
			{steps.map((step, index) => {
				const ratio = ((step.value / totalSteps) * 100).toFixed(4);
				if (step.value > 0) {
					return (
						<StepElement
							// eslint-disable-next-line react/no-array-index-key
							key={`${step.color}-${index}`}
							style={{
								width: `${ratio <= 100 ? ratio : 100}%`,
							}}
							color={step.color}
						/>
					);
				}
				return null;
			})}
		</OuterElement>
	</>
);

MultiStepProgressBar.defaultProps = {
	steps: [],
	stepText: '',
	totalText: '',
	totalSteps: 1,
	warning: false,
	warningText: '',
};

MultiStepProgressBar.propTypes = {
	steps: PropTypes.arrayOf(PropTypes.shape({})),
	warning: PropTypes.bool,
	stepText: PropTypes.string,
	totalText: PropTypes.string,
	totalSteps: PropTypes.number,
	warningText: PropTypes.string,
};

export default MultiStepProgressBar;
