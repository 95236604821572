import api from 'Application/api/api';

const editEpcInquiry = (signal, values) =>
	api.put('/inquiries/partner', values, {
		signal,
		timeout: 100000,
		headers: {
			'Content-Type': 'multipart/form-data',
			Accept: '*/*',
		},
	});

export default editEpcInquiry;
