import api from 'Application/api/api';

const deleteImages = (signal, imageIds, projectId) =>
	api.delete(
		'/images/delete',

		{ data: { imageIds, projectId } },

		{
			signal,
		},
	);

export default deleteImages;
