import adaptOmContractResponseData from 'OmContract/api/adapters/adaptOmContractResponseData';
import adaptEpcContractResponseData from 'EpcContract/api/adapters/adaptEpcContractResponseData';
import adaptEpcOfferResponseData from 'EpcContract/api/adapters/adaptEpcOfferResponseData';
import Big from 'Common/utils/customBig';
import adaptProjectImageResponseData from 'Projects/api/adapters/adaptProjectImageResponseData';
import adaptClientContractResponseData from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/ClientContract/api/adapters/adaptClientContractResponseData';

const adaptProjectResponseData = data => ({
	...data,
	clientContracts: data.clientContracts
		? data.clientContracts.map(contract => adaptClientContractResponseData(contract))
		: data.clientContracts,
	epcContracts: data.epcContracts
		? data.epcContracts.map(contract => adaptEpcContractResponseData(contract))
		: data.epcContracts,
	omContracts: data.omContracts
		? data.omContracts.map(contract => adaptOmContractResponseData(contract))
		: data.omContracts,
	images: data.images ? data.images.map(image => adaptProjectImageResponseData(image)) : data.images,
	epcOffers: data.epcOffers ? data.epcOffers.map(offer => adaptEpcOfferResponseData(offer)) : data.epcOffers,
	irr: data.irr ? Number(Big(data.irr).times(100).toString()) : data.irr,
	solarUtilisationRate: data.solarUtilisationRate
		? Number(Big(data.solarUtilisationRate).times(100).toString())
		: data.solarUtilisationRate,
});

export default adaptProjectResponseData;
