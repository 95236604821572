import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import notify from 'Common/utils/notify';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import createUtilityRate from 'Client/api/createUtilityRate';
import useUtilityRateFormValidationSchema from 'Client/components/Form/hooks/useUtilityRateFormValidationSchema';
import useUtilityRateFormInitialValues from 'Client/components/Form/hooks/useUtilityRateFormInitialValues';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { ModalFormButtons } from 'Common/components/form/ModalForm';
import sizes from 'Application/theme/sizes';
import loadTariffTypeOptions from 'Invoice/utils/loadTariffTypeOptions';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const FieldsWrapper = styled.div`
	width: 100%;
	padding: ${sizes.spacing(0.5)} 0 ${sizes.spacing(0.5)} 0;
`;

const UtilityRateForm = ({ onSubmit, countryId, onCancel }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const abortController = useAbortController();
	const initialValues = useUtilityRateFormInitialValues();
	const validationSchema = useUtilityRateFormValidationSchema();
	const loadTariffTypeOptionsBinded = useMemo(
		() => loadTariffTypeOptions.bind(null, [countryId]),
		[countryId],
	);

	const {
		errors,
		touched,
		values,
		handleSubmit,
		setFieldValue,
		handleChange,
		handleBlur,
		setFieldError,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				await createUtilityRate(abortController.signal, {
					name: values.name.label,
					rate: values.rate,
					countryId: countryId,
				});
				notify(t('Utility Rate created successfully'), {
					type: toast.TYPE.SUCCESS,
				});
				onSubmit();
				resetForm({ values });
				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	return (
		<Form onSubmit={handleSubmit}>
			<FieldsWrapper>
				<FormField>
					<FormikSelect
						isAsync
						id="name"
						name="name"
						label={t('Tariff Type Name')}
						value={values.name}
						error={errors.name}
						touched={touched.name}
						setFieldValue={setFieldValue}
						onBlur={handleBlur}
						loadOptions={loadTariffTypeOptionsBinded}
						isClearable
					/>
				</FormField>
				<FormField>
					<FormikInput
						id="rate"
						name="rate"
						label={t('Rate')}
						value={values.rate}
						error={errors.rate}
						touched={touched.rate}
						onChange={handleChange}
						onBlur={handleBlur}
						isRequired
					/>
				</FormField>
			</FieldsWrapper>
			<ModalFormButtons
				onCancel={onCancel}
				isLoading={isLoading}
				// label="Utility Rate Form"
				submitButtonText={'Create'}
			/>
		</Form>
	);
};

UtilityRateForm.defaultProps = {
	countryId: undefined,
	onDirty: () => {},
	onCancel: () => {},
	onSubmit: () => {},
};

UtilityRateForm.propTypes = {
	countryId: PropTypes.number,
	onCancel: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default UtilityRateForm;
