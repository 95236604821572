import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useAddressTileFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				country: yup
					.object()
					.nullable()
					.test('country', t('Country is required'), value => value?.value),
			}),
		[t],
	);
};

export default useAddressTileFormValidationSchema;
