import { statuses } from 'Projects/constants/offerStatuses';

export const formatPortalStatus = status => {
	if (status === statuses.OFFER_RECEIVED) {
		return statuses.OFFER_SENT;
	}
	if (status === statuses.SIGNED) {
		return statuses.AWARDED;
	}
	return status;
};
