import PropTypes from 'prop-types';
import ClientProjectsListTable from 'Client/components/ClientDetails/Tabs/ClientProjectsTab/ClientProjectsList/ClientProjectsListTable';

const ClientProjectsTab = ({ client }) => {
	return <ClientProjectsListTable client={client} />;
};

ClientProjectsTab.propTypes = {
	client: PropTypes.shape({}).isRequired,
};

export default ClientProjectsTab;
