import styled from 'styled-components/macro';
import Comment from './Comment';
import PropTypes from 'prop-types';
import sizes from 'Application/theme/sizes';
import { useTranslation } from 'react-i18next';
import colors from 'Application/theme/colors';
import { useDynamicComments } from './hooks/useDynamicComments';
import { useState } from 'react';
import FormikTextArea from 'Common/components/form/FormikTextArea';
import SendButton from './SendButton';
import Icon from 'Common/components/icons/Icon';
import showToastError from 'Common/utils/showToastError';

const FieldWrapper = styled.div`
	position: relative;
	.textarea {
		width: calc(100% + 10.5px);
		padding-right: ${sizes.spacing(4)};
		overflow: auto;
	}
`;

const CommentListContainer = styled.div`
	padding-top: ${sizes.spacing(4)};
	display: flex;
	flex-direction: column;
	gap: ${sizes.spacing(2)};
	z-index: 0;
`;

const NoComments = styled.div`
	padding-top: ${sizes.spacing(8)};
	margin: 0 auto;
	color: ${colors.text.grey};
`;

const CommentList = ({
	defaultComments,
	hideCreateSection,
	hideActions,
	onAddComment,
	onEditComment,
	onDeleteComment: propOnDeleteComment,
}) => {
	const [newComment, setNewComment] = useState('');
	const [comments, setComments] = useState(defaultComments);

	const handleTextAreaChange = e => {
		setNewComment(e.target.value);
	};

	const handleSendComment = async () => {
		if (newComment.trim() === '') {
			return;
		}

		try {
			const newComments = await onAddComment(newComment);

			setNewComment('');
			setComments(newComments);
		} catch (err) {
			showToastError(err, t('A problem occured while trying to send a comment'));
		}
	};
	const { t } = useTranslation();

	const { dynamicComments, handleCommentDelete } = useDynamicComments(comments);

	const onDeleteComment = commentId => {
		handleCommentDelete(commentId);

		return propOnDeleteComment(commentId);
	};

	return (
		<>
			{!hideCreateSection && (
				<FieldWrapper>
					<FormikTextArea label="Comment" name="comment" value={newComment} onChange={handleTextAreaChange} />
					<SendButton $disabled={newComment.trim() === ''} onClick={handleSendComment}>
						<Icon icon="send" />
					</SendButton>
				</FieldWrapper>
			)}
			<CommentListContainer>
				{dynamicComments.length > 0 ? (
					dynamicComments?.map(comment => (
						<div key={comment.id}>
							<Comment
								comment={comment}
								handleCommentDelete={handleCommentDelete}
								hideActions={hideActions}
								onEditComment={onEditComment}
								onDeleteComment={onDeleteComment}
							/>
						</div>
					))
				) : (
					<NoComments>{t('No comments yet...')}</NoComments>
				)}
			</CommentListContainer>
		</>
	);
};

CommentList.defaultProps = {
	project: null,
	step: null,
	hideCreateSection: false,
	hideActions: false,
	defaultComments: [],
};

CommentList.propTypes = {
	defaultComments: PropTypes.arrayOf(PropTypes.shape({})),
	onAddComment: PropTypes.func.isRequired,
	onEditComment: PropTypes.func.isRequired,
	onDeleteComment: PropTypes.func.isRequired,
	project: PropTypes.shape({
		stepComments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	}),
	step: PropTypes.shape({
		comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	}),
	hideCreateSection: PropTypes.bool,
	hideActions: PropTypes.bool,
};

export default CommentList;
