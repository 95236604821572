import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

import updateEpcPartner from 'EpcPartner/api/updateEpcPartner';
import useEpcPartnerDetailsFormInitialValues from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/EpcPartnerDetailsTile/hooks/useEpcPartnerDetailsFormInitialValues';
import useEpcPartnerDetailsFormValidationSchema from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/EpcPartnerDetailsTile/hooks/useEpcPartnerDetailsFormValidationSchema';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const EpcPartnerDetailsTile = ({ epcPartner, onEnterEditMode, onExitEditMode, onSave, onDirtyForm }) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([roles.EPC_PARTNER]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useEpcPartnerDetailsFormInitialValues(epcPartner);
	const validationSchema = useEpcPartnerDetailsFormValidationSchema();

	const { errors, touched, values, handleSubmit, dirty, handleChange, handleBlur, resetForm } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			setIsSavingChanges(true);

			try {
				const response = await updateEpcPartner(abortController.signal, {
					...omit(epcPartner, ['tier']),
					...values,
					inviteToPortal: values.portalAccess,
				});

				setIsSavingChanges(false);

				if (onDirtyForm) {
					onDirtyForm(false);
				}

				onSave(response.data);
				handleCancel();

				// Re-set all fields and mark them as not touched
				resetForm({ values });
			} catch (error) {
				showToastError(error, t("Can't update EPC partner"));
				if (!axios.isCancel(error)) {
					setIsSavingChanges(false);
				}
			}
		},
	});

	const nameValue = isInEditMode ? values.name : epcPartner.name ? epcPartner.name : '';

	const websiteValue = isInEditMode ? values.website : epcPartner.website ? epcPartner.website : '';

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleOnDivClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('EPC partner details')}
			onClick={handleOnDivClick}
			isInEditMode={isInEditMode}
			isLoading={isSavingChanges}
			onCancel={handleCancel}
			onSubmit={handleSubmit}
		>
			<FormField>
				<FormikInput
					id="name"
					name="name"
					label={t('Name')}
					value={nameValue}
					error={errors.name}
					touched={touched.name}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isRequired
				/>
			</FormField>
			<FormField>
				{isInEditMode ? (
					<FormikInput
						id="website"
						name="website"
						label={t('Website')}
						value={websiteValue}
						error={errors.website}
						touched={touched.website}
						onChange={handleChange}
						onBlur={handleBlur}
						isTile
						isInEditMode={isInEditMode}
					/>
				) : (
					<KeyValueVisualization
						title={t('Website')}
						value={
							websiteValue === '-' ? (
								''
							) : (
								<a
									href={/https?:\/\//.test(websiteValue) ? websiteValue : `http://${websiteValue}`}
									target="_blank"
									rel="noreferrer noreferrer"
								>
									{websiteValue}
								</a>
							)
						}
						hasHoverEffect
					/>
				)}
			</FormField>
		</EditableTile>
	);
};

EpcPartnerDetailsTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
};

EpcPartnerDetailsTile.propTypes = {
	epcPartner: PropTypes.shape({
		name: PropTypes.string,
		website: PropTypes.string,
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onSave: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
};

export default EpcPartnerDetailsTile;
