import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';

import sizes from 'Application/theme/sizes';
import colors from 'Application/theme/colors';

import Button from 'Common/components/buttons/Button';
import { css } from 'styled-components';

const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: ${sizes.spacing(2)};
	min-height: 26px;
	padding: ${props => props.isTableTile && '0 16px'};
`;

const Title = styled.div`
	flex: 1;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	color: ${colors.secondary.dark};
`;

const TitleWrapper = styled.div`
	display: flex;
	width: 100%;

	${({ $isMobile }) =>
		$isMobile &&
		css`
			${Title} {
				font-size: 14px;
			}
		`}
`;

const ButtonsWrapper = styled.div`
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${colors.common.white};
	> * {
		margin-right: 4px;

		&:last-child {
			margin-right: 0px;
		}
	}
`;

const Line = styled.div`
	height: 1px;
	width: 32px;
	background: ${colors.secondary.dark};
`;

const TileHeader = ({
	title,
	$isMobile,
	tooltip,
	isInEditMode,
	onCancel,
	isLoading,
	rightComponent,
	isTableTile,
}) => (
	<HeaderWrapper isTableTile={isTableTile}>
		<TitleWrapper $isMobile={$isMobile}>
			{tooltip ? (
				<Title>
					{title}
					{tooltip}
				</Title>
			) : (
				<Title>{title}</Title>
			)}
			<ButtonsWrapper>
				{isInEditMode && (
					<>
						<Button
							small
							secondary
							icon="clear"
							disabled={isLoading}
							onClick={onCancel}
							label="Tile Header Edit Mode - Close Button"
						/>
						<Button
							small
							type="submit"
							icon="check"
							isLoading={isLoading}
							label="Tile Header Edit Mode - Submit Button"
						/>
					</>
				)}
				{rightComponent !== undefined && rightComponent}
			</ButtonsWrapper>
		</TitleWrapper>
		<Line />
	</HeaderWrapper>
);

TileHeader.defaultProps = {
	$isMobile: false,
	tooltip: '',
	isInEditMode: false,
	onCancel: () => null,
	isLoading: false,
	rightComponent: null,
	isTableTile: false,
};

TileHeader.propTypes = {
	title: PropTypes.string.isRequired,
	$isMobile: PropTypes.bool,
	tooltip: PropTypes.node,
	isInEditMode: PropTypes.bool,
	onCancel: PropTypes.func,
	isLoading: PropTypes.bool,
	rightComponent: PropTypes.node,
	isTableTile: PropTypes.bool,
};

export default TileHeader;
