import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const usePartnersTileFormValidationSchema = () => {
	const { t } = useTranslation();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(() => yup.object({}), [t]);
};

export default usePartnersTileFormValidationSchema;
