import api from 'Application/api/api';

const autoAssignDriveFolder = async (signal, id) => {
	const response = await api.patch(`/projects/auto-assign-drive-folder/${id}`, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: response.data,
	};
};

export default autoAssignDriveFolder;
