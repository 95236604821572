import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

import getSalesChannels from 'Preferences/api/getSalesChannels';
import deleteSalesChannel from 'Preferences/api/deleteSalesChannel';
import SalesChannelCreateModal from 'Preferences/components/Tabs/GeneralTab/SalesChannel/SalesChannelCreateModal';
import SalesChannelEditModal from 'Preferences/components/Tabs/GeneralTab/SalesChannel/SalesChannelEditModal';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import notify from 'Common/utils/notify';
import EditableTile from 'Common/components/Tile/EditableTile';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { DefaultTable, TableHeadCell, TableBodyCell } from 'Common/components/table/table';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell} {
		background: transparent;
	}
	${TableBodyCell} {
		background: transparent;
	}
`;

const SalesChannelList = () => {
	const { t } = useTranslation();

	const [salesChannels, setSalesChannels] = useState([]);

	const [salesChannelIdForEditing, setSalesChannelIdForEditing] = useState(null);

	const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

	const [salesChannelIdForDeletion, setSalesChannelIdForDeletion] = useState(null);

	const [deletionError, setDeletionError] = useState(null);

	const [isLoadingContent, setIsLoadingContent] = useState(false);
	const abortController = useAbortController();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Name'),
				accessor: 'name',
			},
			{
				Header: '',
				accessor: 'actionDots',
				maxWidth: 70,
				customCellStyles: {
					justifyContent: 'flex-end',
				},
				customBodyCellContentStyles: {
					padding: '5px',
				},
			},
		],
		[t],
	);

	// Re-structure the projects as table data
	const data = useMemo(
		() =>
			salesChannels.map(salesChannel => ({
				...salesChannel,
				name: salesChannel?.name || '-',
				actionDots: (
					<MoreActionsButton label="Preference General Sales Chanel More Actions" controlledVisibility>
						<MenuItem
							data-action="edit"
							onClick={() => setSalesChannelIdForEditing(salesChannel.id)}
							disabled={!isAuthorized([roles.ADMIN, roles.FINANCE, roles.MANAGEMENT, roles.SALES])}
							label="Preference General Sales Chanel - Edit Menu Item"
						>
							Edit
						</MenuItem>
						<MenuItem
							data-action="delete"
							onClick={() => setSalesChannelIdForDeletion(salesChannel.id)}
							label="Preference General Sales Chanel - Delete Menu Item"
						>
							Delete
						</MenuItem>
					</MoreActionsButton>
				),
			})),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t, salesChannels],
	);

	useEffect(() => {
		(async () => {
			try {
				setIsLoadingContent(true);
				const response = await getSalesChannels(abortController.signal);
				setIsLoadingContent(false);

				setSalesChannels(response.data.content);
			} catch (error) {
				showToastError(error);
			}
		})();
	}, [abortController.signal]);

	const handleDataUpdate = data => {
		setSalesChannels(data);
	};

	const handleDeleteSalesChannel = async id => {
		try {
			await deleteSalesChannel(abortController.signal, id);

			notify(t('Sales channel deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});

			const salesChannelResponse = await getSalesChannels(abortController.signal);
			handleDataUpdate(salesChannelResponse.data.content);
			setSalesChannelIdForDeletion(null);
		} catch (error) {
			if (!axios.isCancel(error)) {
				setDeletionError(error.response.data);
				setSalesChannelIdForDeletion(null);
			}
		}
	};

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
			},
			useCustomCellStyles,
		);

	const salesChannelForDeletion = salesChannelIdForDeletion
		? salesChannels.find(salesChannel => salesChannel.id === salesChannelIdForDeletion)
		: null;

	const hasSalesChannelForDeletion = !!salesChannelForDeletion && !!salesChannelIdForDeletion;

	return (
		<EditableTile
			title={t('Sales channels')}
			rightComponent={
				<TileActionButton data-button="salesChannels" onClick={() => setIsCreateModalOpened(true)} />
			}
			isTable
			isLoadingContent={isLoadingContent}
		>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
				/>
			</TableWrapper>
			<SalesChannelCreateModal
				isOpen={isCreateModalOpened}
				onClose={() => setIsCreateModalOpened(false)}
				onFormSubmit={handleDataUpdate}
			/>

			{salesChannelIdForEditing !== null && (
				<SalesChannelEditModal
					isOpen
					onClose={() => setSalesChannelIdForEditing(null)}
					onFormSubmit={handleDataUpdate}
					salesChannelId={salesChannelIdForEditing}
				/>
			)}

			{hasSalesChannelForDeletion && (
				<ConfirmModal
					isOpen
					onCancel={() => setSalesChannelIdForDeletion(null)}
					onConfirm={() => handleDeleteSalesChannel(salesChannelIdForDeletion)}
					label="delete-sales-channel-confirm"
					heading={t('Delete Sales Channel')}
					text={t(`Are you sure you want to delete Sales channel ${salesChannelForDeletion.name} ?`)}
				/>
			)}

			<UnsuccessfulOperationModal
				label="cannot-delete-sales-channel"
				isOpen={deletionError !== null}
				onCancel={() => setDeletionError(null)}
				heading={t('Cannot delete Sales Channel')}
				text={deletionError?.violations?.[0]?.message}
			/>
		</EditableTile>
	);
};

export default SalesChannelList;
