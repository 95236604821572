import { createSlice } from '@reduxjs/toolkit';

const initialProjectData = {};

export const projectSlice = createSlice({
	name: 'project',
	initialState: { ...initialProjectData },
	reducers: {
		clearProjectData: () => initialProjectData,
		updateCurrentProject: (state, { payload: project }) => {
			Object.assign(state, project);
		},
		setProjectData: (state, { payload: project }) => project,
	},
});

export const { clearProjectData, updateCurrentProject, setProjectData } = projectSlice.actions;

export default projectSlice.reducer;
