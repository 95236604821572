import { set, add } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import defaultTimezone from 'Common/constants/defaultTimezone';
import {
	isLastSundayOfMarch,
	isLastSundayOfOctober,
} from 'Campaigns/components/CampaignDetails/Tabs/TranchesTab/Tiles/TranchesListTile/TrancheForm/utils/isSummerTimeChangeDate';

// ! We need a little hour conversion because of the daylight saving time.
// ! The hours are missing only if the date is the last sunday of march or october.
const convertDateAndHour = (date, hour) =>
	date
		? zonedTimeToUtc(
				add(
					set(new Date(date), {
						hours: 0,
						minutes: 0,
					}),
					hour === 24
						? {
								hours: isLastSundayOfMarch(date) ? 22 : isLastSundayOfOctober(date) ? 24 : 23,
								minutes: 59,
						  }
						: {
								hours:
									isLastSundayOfOctober(date) && hour > 2
										? hour + 1
										: isLastSundayOfMarch(date) && hour > 2
										? hour - 1
										: hour,
						  },
				),
				defaultTimezone,
		  )
		: null;

export default convertDateAndHour;
