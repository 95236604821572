import getIndustriesByIds from 'Industries/api/getIndustriesByIds';

const loadSelectedIndustries = async (signal, ids) => {
	const response = await getIndustriesByIds(signal, ids);
	const industries = response.data;

	return industries
		.filter(industry => industry.name && industry.name !== '-')
		.map(industry => ({
			value: industry.id,
			label: industry.name,
		}));
};

export default loadSelectedIndustries;
