import { useCallback, useEffect, useMemo, useState } from 'react';
import getEpcPartnerById from 'EpcPartner/api/getEpcPartnerById';
import deleteEpcPartner from 'EpcPartner/api/deleteEpcPartner';
import { useParams } from 'react-router';
import Loader from 'Common/components/Loader';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from 'Common/components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'Common/components/breadcrumbs/Breadcrumb';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import isAuthorized from 'User/utils/isAuthorized';
import { roles } from 'User/constants/roles';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import colors from 'Application/theme/colors';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

import GeneralTab from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/GeneralTab';
import ContactsTab from 'EpcPartner/components/EpcPartnerDetails/Tabs/ContactsTab/ContactsTab';
import EPCPartnerProjectList from 'EpcPartner/components/EpcPartnerDetails/Tabs/EPCPartnerProjectList/EPCPartnerProjectList';

import { EPC_PARTNER_DETAILS_PAGE_TILES_BY_TAB } from 'EpcPartner/constants/epcPartnerTabForms';
import TabsContainer from 'Common/components/tabs';

const StyledBreadcrumbs = styled(Breadcrumbs)`
	min-width: 300px;
`;

const ContentWrapper = styled.div`
	background: ${colors.grey.lightest};
`;

const StyledHeaderTitle = styled(HeaderTitle)`
	line-height: 34px;
	text-transform: capitalize;
`;

const EpcPartnerDetails = () => {
	const [epcPartner, setEpcPartner] = useState(null);
	const [isLoadingEpcPartner, setIsLoadingEpcPartner] = useState(true);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [deletionError, setDeletionError] = useState(null);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { epcPartnerId } = useParams();

	const abortController = useAbortController();

	useEffect(() => {
		setIsLoadingEpcPartner(true);

		(async () => {
			try {
				const response = await getEpcPartnerById(abortController.signal, epcPartnerId, true);
				setEpcPartner(response.data);
				setIsLoadingEpcPartner(false);
			} catch (error) {
				showToastError(error, t("Can't get EPC partner data"));
				if (!axios.isCancel(error)) {
					setEpcPartner(null);
					setIsLoadingEpcPartner(false);
				}
			}
		})();
	}, [epcPartnerId, abortController.signal, t]);

	const handleEpcPartnerDataChange = useCallback(changes => {
		setEpcPartner(prevEpcPartner => ({
			...prevEpcPartner,
			...changes,
		}));
	}, []);

	const handleDeleteEpcPartnerSubmit = async () => {
		try {
			await deleteEpcPartner(abortController.signal, epcPartnerId);
			notify(t('EPC Partner deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});
			dispatch(push('/epc-partners'));
			setShowConfirmModal(false);
		} catch (error) {
			if (!axios.isCancel(error)) {
				setDeletionError(error.response.data);
				setShowConfirmModal(false);
			}
		}
	};

	const tabsConfig = useMemo(
		() => [
			{
				label: 'General',
				PanelComponent: GeneralTab,
				panelProps: { epcPartner, onDataChange: handleEpcPartnerDataChange },
				tabForms: EPC_PARTNER_DETAILS_PAGE_TILES_BY_TAB.GENERAL,
				forceRender: true,
			},
			{
				label: 'Contacts',
				PanelComponent: ContactsTab,
				panelProps: { epcPartner },
				forceRender: true,
			},
			{
				label: 'Projects',
				PanelComponent: EPCPartnerProjectList,
				panelProps: { epcPartner },
			},
		],
		[epcPartner, handleEpcPartnerDataChange],
	);

	return (
		<div>
			{isLoadingEpcPartner || epcPartner === null ? (
				<LoaderWrapper $isFullPage>
					<Loader />
				</LoaderWrapper>
			) : (
				<>
					<HeaderContainer>
						<StyledBreadcrumbs>
							<Breadcrumb to="/epc-partners">{t('EPC Partners')}</Breadcrumb>
							<Breadcrumb active>
								<StyledHeaderTitle>{epcPartner.name}</StyledHeaderTitle>
							</Breadcrumb>
						</StyledBreadcrumbs>
						{isAuthorized([
							roles.ADMIN,
							roles.FINANCE,
							roles.MANAGEMENT,
							roles.SALES,
							roles.PM_TECHNICAL,
							roles.ASSET_MANAGER,
						]) && (
							<HeaderActions>
								<MoreActionsButton label="EPC Partner Details More Actions">
									<MenuItem onClick={() => setShowConfirmModal(true)} label="EPC Partner Details - Delete Menu Item">
										{t('Delete')}
									</MenuItem>
								</MoreActionsButton>
							</HeaderActions>
						)}
					</HeaderContainer>
					<ContentWrapper>
						<TabsContainer page="epcPartner" tabsConfig={tabsConfig} />
						<ConfirmModal
							isOpen={showConfirmModal}
							onConfirm={() => handleDeleteEpcPartnerSubmit()}
							onCancel={() => setShowConfirmModal(false)}
							label="delete-epc-partner"
							heading={t('Delete EPC Partner')}
							text={t(`Are you sure you want to delete EPC Partner ${epcPartner.name}?`)}
						/>
						<UnsuccessfulOperationModal
							label="cannot-delete-epc-partner"
							isOpen={deletionError !== null}
							onCancel={() => setDeletionError(null)}
							heading={t('Cannot delete EPC Partner')}
							text={deletionError?.violations?.[0]?.message}
						/>
					</ContentWrapper>
				</>
			)}
		</div>
	);
};

export default EpcPartnerDetails;
