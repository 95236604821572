import { useMemo } from 'react';

const fallbackValues = {
	unknownClient: true,
	client: null,
};

const useClientFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			industry: data.client?.industry
				? {
						value: data.client?.industry?.id,
						label: data.client?.industry?.name,
						marketSegment: data.client?.industry?.marketSegment,
						restricted: data.client?.industry?.restricted,
						excluded: data.client?.industry?.excluded,
				  }
				: {},
			client: data.client
				? {
						label: data.client.name,
						value: data.client.id,
				  }
				: null,
			unknownClient: !data.client,
		}),
		[data],
	);

export default useClientFormInitialValues;
