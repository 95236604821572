import useQueryParameter from 'Common/hooks/useQueryParameter';
import useSetQueryParameter from 'Common/hooks/useSetQueryParameter';
import { useEffect } from 'react';
import { ensurePluginOrder } from 'react-table';

const pluginName = 'useSortingQueryParameter';

export const useSortingQueryParameter = hooks => {
	hooks.useInstance.push(useInstance);
};

useSortingQueryParameter.pluginName = pluginName;

const useInstance = instance => {
	const {
		defaultSortBy,
		state: { sortBy },
		toggleSortBy,
		plugins,
	} = instance;

	ensurePluginOrder(plugins, ['useSortBy'], 'useSortingQueryParameter');

	const sortByQueryParam = useQueryParameter('sortBy');
	const setSortByQueryParam = useSetQueryParameter('sortBy');

	// What is the table currently sorted by
	const tableSortBy = sortBy[0];
	const tableSortById = tableSortBy?.id;
	const tableSortByDesc = tableSortBy?.desc;

	// Update the query parameter according to the current sorting in the table
	useEffect(() => {
		if (tableSortById) {
			if (tableSortById === defaultSortBy.id && tableSortByDesc === defaultSortBy.desc) {
				setSortByQueryParam(null);
			} else {
				setSortByQueryParam({
					id: tableSortById,
					desc: tableSortByDesc,
				});
			}
		}
	}, [tableSortById, tableSortByDesc, defaultSortBy.id, defaultSortBy.desc, setSortByQueryParam]);

	const sortByQueryParamId = sortByQueryParam?.id;
	const sortByQueryParamDesc = sortByQueryParam?.desc;

	// Update the table if the query parameter has been changed externally
	useEffect(() => {
		if (sortByQueryParamId) {
			toggleSortBy(sortByQueryParamId, sortByQueryParamDesc, false);
		}
	}, [sortByQueryParamId, sortByQueryParamDesc, toggleSortBy]);
};
