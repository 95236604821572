import React from 'react';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import Grid from '@mui/material/Grid';
import ShareholdersList from './ShareholdersList';
import PropTypes from 'prop-types';
import CapitalTile from 'Entities/components/EntitiesDetails/Tabs/CapitalTab/CapitalTile/CapitalTile';
import { useCallback } from 'react';
import { ENTITY_DETAILS_PAGE_TAB_TILES } from 'Entities/constants/entityTabForms';

const CapitalTab = ({ entity, onEnterEditMode, onExitEditMode, onDataChange, onDirtyForm }) => {
	const handleGeneralTabSave = data => {
		onDataChange(data);
		onExitEditMode();
	};

	const handleCapitalDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(ENTITY_DETAILS_PAGE_TAB_TILES.CAPITAL.CAPITAL, isDirty);
		},
		[onDirtyForm],
	);

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
				<CapitalTile
					entity={entity}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={handleGeneralTabSave}
					onDirtyForm={handleCapitalDirtyForm}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
				<ShareholdersList entity={entity} onDataChange={onDataChange} />
			</Grid>
		</TilesGridWrapper>
	);
};

CapitalTab.propTypes = {
	entity: PropTypes.shape({}).isRequired,
	onEnterEditMode: PropTypes.func.isRequired,
	onExitEditMode: PropTypes.func.isRequired,
	onDataChange: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func.isRequired,
};

export default CapitalTab;
