import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import format from 'date-fns/format';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import PropTypes from 'prop-types';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';

import editClient from 'Client/api/editClient';
import useDueDiligenceFormInitialValues from 'Client/components/ClientDetails/Tabs/FinancialTab/Tiles/DueDiligenceTile/hooks/useDueDiligenceFormInitialValues';
import useDueDiligenceFormValidationSchema from 'Client/components/ClientDetails/Tabs/FinancialTab/Tiles/DueDiligenceTile/hooks/useDueDiligenceFormValidationSchema';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';

const StyledFormikInput = styled(FormikInput)`
	color: ${props => props.color};
`;

const FieldContainer = styled.div`
	padding: 10px 0;
`;

const getScoringColor = scoring => (scoring >= 0 ? colors.text.primary : colors.error.main);

const DueDiligenceTile = ({
	client,
	onEnterEditMode,
	onExitEditMode,
	onSave,
	onDirtyForm,
	highlightLabels,
}) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.SALES,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.PM_TECHNICAL,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useDueDiligenceFormInitialValues(client);
	const validationSchema = useDueDiligenceFormValidationSchema();
	const [isDisabledDate, setIsDisabledDate] = useState(true);

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleChange,
		handleBlur,
		setFieldValue,
		setFieldTouched,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			setIsSavingChanges(true);
			let response;

			try {
				response = await editClient(abortController.signal, {
					...client,
					...values,
					financialDDOPassedDate: values.financialDDOPassedDate
						? format(new Date(values.financialDDOPassedDate), 'yyyy-MM-dd')
						: null,
				});

				if (onDirtyForm) {
					onDirtyForm(false);
				}

				onSave(response.data);
				handleCancel();
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});

	useEffect(() => {
		setIsDisabledDate(!values.financialDDOPassedDate);
	}, [values.financialDDOPassedDate]);

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	const scoringProfitabilityNetIncomeProfitMarginValue = isInEditMode
		? values.scoringProfitabilityNetIncomeProfitMargin
		: values.scoringProfitabilityNetIncomeProfitMargin
		? `${values.scoringProfitabilityNetIncomeProfitMargin} %`
		: '';

	return (
		<EditableTile
			title={t('Due diligence')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<FieldContainer>
				<FormikCheckbox
					id="threeAuditedAnnualReportsExisting"
					name="threeAuditedAnnualReportsExisting"
					label={t('Annual reports')}
					checked={values.threeAuditedAnnualReportsExisting}
					error={errors.threeAuditedAnnualReportsExisting}
					touched={touched.threeAuditedAnnualReportsExisting}
					onChange={handleChange}
					onBlur={handleBlur}
					state="editable"
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DUE_DILIGENCE.ANNUAL_REPORT,
					)}
				/>
			</FieldContainer>

			<FormField>
				<FormikSingleDatePicker
					id="financialDDOPassedDate"
					startDateName="financialDDOPassedDate"
					label={t('Financial DD passed date')}
					startDate={values.financialDDOPassedDate}
					error={errors.financialDDOPassedDate}
					touched={touched.financialDDOPassedDate}
					setFieldValue={setFieldValue}
					setFieldTouched={setFieldTouched}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DUE_DILIGENCE.FINANCIAL_PASSED_DATE,
					)}
				/>
			</FormField>
			<FormField>
				<FormikCheckbox
					id="fddPassed"
					name="fddPassed"
					label={t('FDD passed')}
					checked={values.fddPassed}
					error={errors.fddPassed}
					touched={touched.fddPassed}
					onChange={handleChange}
					onBlur={handleBlur}
					state="editable"
					isTile
					isInEditMode={isInEditMode}
					isDisabled={isDisabledDate}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DUE_DILIGENCE.FDD_PASSED,
					)}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="scoringProfitabilityNetIncomeProfitMargin"
					name="scoringProfitabilityNetIncomeProfitMargin"
					label={t('Profitability scoring')}
					value={scoringProfitabilityNetIncomeProfitMarginValue}
					error={errors.scoringProfitabilityNetIncomeProfitMargin}
					touched={touched.scoringProfitabilityNetIncomeProfitMargin}
					onChange={handleChange}
					onBlur={handleBlur}
					color={getScoringColor(values.scoringProfitabilityNetIncomeProfitMargin)}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DUE_DILIGENCE.PROFITABILITY_SCORING,
					)}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="scoringLiquidityQuickRatio"
					name="scoringLiquidityQuickRatio"
					label={t('Liquidity scoring')}
					value={values.scoringLiquidityQuickRatio}
					error={errors.scoringLiquidityQuickRatio}
					touched={touched.scoringLiquidityQuickRatio}
					onChange={handleChange}
					onBlur={handleBlur}
					color={getScoringColor(values.scoringLiquidityQuickRatio)}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DUE_DILIGENCE.LIQUIDITY_SCORING,
					)}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="scoringSolvencyAssetCoverageRatio"
					name="scoringSolvencyAssetCoverageRatio"
					label={t('Solvency scoring')}
					value={values.scoringSolvencyAssetCoverageRatio}
					error={errors.scoringSolvencyAssetCoverageRatio}
					touched={touched.scoringSolvencyAssetCoverageRatio}
					onChange={handleChange}
					onBlur={handleBlur}
					color={getScoringColor(values.scoringSolvencyAssetCoverageRatio)}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DUE_DILIGENCE.SOLVENCY_SCORING,
					)}
				/>
			</FormField>
			<FormField>
				<StyledFormikInput
					id="scoringSolvencyAssetCoverageRatioExShareholderLoans"
					name="scoringSolvencyAssetCoverageRatioExShareholderLoans"
					label={t('Solvency scoring w/o SH loans')}
					value={values.scoringSolvencyAssetCoverageRatioExShareholderLoans}
					error={errors.scoringSolvencyAssetCoverageRatioExShareholderLoans}
					touched={touched.scoringSolvencyAssetCoverageRatioExShareholderLoans}
					onChange={handleChange}
					onBlur={handleBlur}
					color={getScoringColor(values.scoringSolvencyAssetCoverageRatioExShareholderLoans)}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DUE_DILIGENCE.SOLVENCY_SCORING_LOANS,
					)}
				/>
			</FormField>
		</EditableTile>
	);
};

DueDiligenceTile.defaultProps = {
	client: {},
	onEnterEditMode: () => null,
	onExitEditMode: () => null,
	onDirtyForm: () => null,
	onSave: () => null,
	highlightLabels: [],
};

DueDiligenceTile.propTypes = {
	client: PropTypes.shape({
		threeAuditedAnnualReportsExisting: PropTypes.bool,
		fddPassed: PropTypes.bool,
	}),
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	onSave: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default DueDiligenceTile;
