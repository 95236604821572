import { useMemo } from 'react';
import Big from 'Common/utils/customBig';

const formatProjectId = id => (id.length < 5 ? '0'.repeat(5 - id.length) + id : id);

const useProjectStats = (project, financeDetails, campaignId) =>
	useMemo(() => {
		const projectName =
			project &&
			`${formatProjectId(String(project.externalId))}${project.client ? ' - ' + project.client?.name : ''} ${
				project?.description ? '(' + project.description + ')' : ''
			}`;

		// Big js doesn't allow division by 0
		const systemSizeKwp = project?.systemSizeKwp ?? 1;

		const otherCampaignsSystemSizeValue = project?.financeDetailsProjects
			? project?.financeDetailsProjects.reduce(
					(sum, current) =>
						current?.financeDetailsId !== campaignId || !campaignId
							? sum.plus(current?.systemSizeAllocatedValue)
							: sum,
					Big(0),
			  )
			: Big(0);

		const otherCampaignsSystemSizePercent = otherCampaignsSystemSizeValue.div(systemSizeKwp).times(100);

		let systemSizeAllocatedValueFixed = parseFloat(
			financeDetails?.systemSizeAllocatedValue?.toString().replace(/,/g, ''),
		).toFixed(2);

		const currentCampaignSystemSizeValue = !isNaN(systemSizeAllocatedValueFixed)
			? Big(systemSizeAllocatedValueFixed)
			: Big(0);

		const currentCampaignSystemSizePercent = currentCampaignSystemSizeValue.div(systemSizeKwp).times(100);

		return {
			projectName,
			systemSizeKwp,
			otherCampaignsSystemSizeValue,
			otherCampaignsSystemSizePercent: Number(otherCampaignsSystemSizePercent),
			currentCampaignSystemSizeValue: Number(currentCampaignSystemSizeValue),
			currentCampaignSystemSizePercent: Number(currentCampaignSystemSizePercent),
		};
	}, [project, financeDetails, campaignId]);

export default useProjectStats;
