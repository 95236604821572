import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import Label from 'Common/components/form/Label';
import FormikInput from './FormikInput';
import FormikDatePicker from './FormikDatePicker';
import { useEffect, useRef, useState } from 'react';
import colors from 'Application/theme/colors';
import { css } from 'styled-components';
import { ClickAwayListener } from '@mui/material';
import { useMemo } from 'react';
import { useLabelColor } from 'Common/components/form/hooks/useLabelColor';
import useDatepickerDisplayValue from 'Common/components/form/hooks/useDatepickerDisplayValue';

const Wrapper = styled.div`
	border: ${({ $isInEditMode, $isTable }) => $isInEditMode && $isTable && `solid 2px ${colors.primary.main};`};

	${({ $isTable }) =>
		$isTable &&
		css`
			width: 100%;
			height: 100%;
			padding: 3px;
			display: flex;
			text-align: center;
			align-items: center;
		`}

	${({ $isOpen }) =>
		$isOpen &&
		css`
			input {
				border-color: ${colors.primary.dark};
			}
		`}
	
	${({ $isDisabled }) =>
		$isDisabled &&
		css`
			input {
				cursor: not-allowed;
				border-color: ${colors.primary.dark};
			}
			label {
				cursor: not-allowed;
			}
		`}
`;

const FieldContainer = styled.div`
	position: relative;
`;

const Popup = styled.form`
	position: fixed;
	min-height: 550px;
	top: ${({ $top }) => $top}px;
	left: ${({ $left }) => ($left > 10 ? $left : 10)}px;

	${({ isOpen, $isDisabled, $isReadMode }) =>
		isOpen && !$isDisabled && !$isReadMode
			? css`
					opacity: 1;
					z-index: 2147483647;
			  `
			: css`
					opacity: 0;
					visibility: hidden;
					pointer-events: none;
					z-index: -2147483647;
			  `};

	${({ $isTablet }) =>
		$isTablet &&
		css`
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		`}
`;

const OverlayElement = styled.div`
	position: fixed;
	display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2147483646;
`;

const FormikSingleDatePicker = ({
	id,
	name,
	label,
	endId,
	error,
	isDual,
	tooltip,
	touched,
	endDate,
	startDate,
	endDateName,
	startDateName,

	isTile,
	onOpen,
	isTable,
	isOverlay,
	isRequired,
	isDisabled,
	isInEditMode,
	isHighlighted,

	onBlur,
	onFocus,
	onMouseEnter,
	onMouseLeave,
	setFieldValue,
	setFieldTouched,

	...props
}) => {
	const popupRef = useRef();
	const inputRef = useRef();
	const wrapperRef = useRef();
	const startDateInputRef = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const [hasHover, setHasHover] = useState(false);
	const [hasFocus, setHasFocus] = useState(false);

	const hasError = !!error && touched;

	const isReadMode = useMemo(() => isTile && !isInEditMode, [isTile, isInEditMode]);
	const labelColor = useLabelColor({
		isDisabled,
		hasError,
		isHighlighted,
		isTile,
		isInEditMode,
		hasHover,
		hasFocus: hasFocus || isOpen,
	});

	const displayValue = useDatepickerDisplayValue(startDate, endDate, isDual);

	const [left, setLeft] = useState(0);
	const [top, setTop] = useState(0);
	const isTablet = window.innerWidth < 1000;

	const togglePopup = () => {
		setFieldTouched && setFieldTouched(startDateName);
		setIsOpen(prevOpen => {
			typeof onOpen === 'function' && onOpen(!prevOpen);
			return !prevOpen;
		});
	};

	const handleOnMouseEnter = (...args) => {
		setHasHover(true);
		typeof onMouseEnter === 'function' && onMouseEnter(...args);
	};

	const handleOnMouseLeave = (...args) => {
		setHasHover(false);
		typeof onMouseLeave === 'function' && onMouseLeave(...args);
	};

	const handleBlur = (...args) => {
		setHasFocus(false);
		if (setFieldTouched) {
			setFieldTouched(name, true);
		}

		typeof onBlur === 'function' && onBlur(...args);
	};

	const handleFocus = (...args) => {
		setHasFocus(true);
		typeof onFocus === 'function' && onFocus(...args);
	};

	useEffect(() => {
		if (isInEditMode) {
			typeof props.setOpen === 'function' && props.setOpen(isOpen);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	useEffect(() => {
		if (wrapperRef.current && popupRef.current) {
			const { x: inputLeftOffset, y: inputTopOffset } = wrapperRef.current.getBoundingClientRect();

			const inputWidth = inputRef.current?.offsetWidth;

			const { width: popupWidth, height: popupHeight } = popupRef.current.getBoundingClientRect();
			const windowWidth = window.innerWidth;
			const windowHeight = window.innerHeight;

			const left =
				inputLeftOffset + popupWidth + inputWidth > windowWidth
					? inputLeftOffset - popupWidth
					: inputLeftOffset + inputWidth;

			const top = inputTopOffset + popupHeight > windowHeight ? windowHeight - popupHeight - 20 : inputTopOffset;

			setLeft(left);
			setTop(top);
		}
	}, [setLeft, setTop, wrapperRef, popupRef, isOpen]);

	useEffect(() => {
		if (isOpen) {
			requestAnimationFrame(() => {
				startDateInputRef?.current?.focus();
			});
		}
	}, [isOpen, startDateInputRef]);

	return (
		<ClickAwayListener onClickAway={() => setIsOpen(false)}>
			<Wrapper
				ref={wrapperRef}
				$isInEditMode={isInEditMode}
				$isTable={isTable}
				$isOpen={isOpen}
				$isDisabled={isDisabled}
				hasError={hasError}
				$isHighlighted={isHighlighted}
				onMouseEnter={handleOnMouseEnter}
				onMouseLeave={handleOnMouseLeave}
			>
				<Label
					label={label}
					color={labelColor}
					isRequired={isTile ? isInEditMode && isRequired : isRequired}
					htmlFor={`${id}_static`}
					tooltip={tooltip}
				/>
				<FieldContainer>
					<FormikInput
						value={displayValue}
						id={`${id}_static`}
						name={name}
						onClick={togglePopup}
						isDisabled={isDisabled}
						isRequired={isRequired}
						isOverlay={isOverlay}
						error={error}
						touched={touched}
						ref={inputRef}
						isTile={isTile}
						isInEditMode={isInEditMode}
						onBlur={handleBlur}
						onFocus={handleFocus}
						onChange={() => {}}
					/>
					{ReactDOM.createPortal(
						<>
							<OverlayElement isOpen={isOpen} onClick={() => setIsOpen(false)} />
							<Popup
								onSubmit={e => {
									e.preventDefault();
									e.stopPropagation();
									setIsOpen(false);
								}}
								ref={popupRef}
								isOpen={isOpen}
								$left={left}
								$top={top}
								isTablet={isTablet}
								$isReadMode={isReadMode}
								$isDisabled={isDisabled}
							>
								{isOpen && (
									<FormikDatePicker
										startDateId={startDateName}
										endDateId={endDateName}
										startDateName={startDateName}
										endDateName={endDateName}
										startDateValue={startDate ?? ''}
										endDateValue={endDate ?? ''}
										setFieldValue={setFieldValue}
										closePopup={() => setIsOpen(false)}
										isDual={isDual}
										isTable={isTable}
										startDateInputRef={startDateInputRef}
										isForm
										id={id}
										endId={endId}
									/>
								)}
							</Popup>
						</>,
						document.body,
					)}
				</FieldContainer>
			</Wrapper>
		</ClickAwayListener>
	);
};

FormikSingleDatePicker.defaultProps = {
	id: '',
	name: '',
	label: '',
	endId: '',
	error: '',
	isDual: false,
	tooltip: '',
	touched: null,
	endDate: null,
	startDate: null,
	endDateName: '',
	startDateName: '',

	isTile: false,
	isOverlay: false,
	onOpen: () => {},
	isTable: false,
	isRequired: false,
	isDisabled: false,
	isInEditMode: false,
	isHighlighted: false,

	onBlur: () => {},
	onFocus: () => {},
	onMouseEnter: () => {},
	onMouseLeave: () => {},
	setFieldValue: () => {},
	setFieldTouched: () => {},
	setOpen: () => {},
};

FormikSingleDatePicker.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string,
	label: PropTypes.string,
	endId: PropTypes.string,
	error: PropTypes.string,
	isDual: PropTypes.bool,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
	touched: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
	endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
	startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
	endDateName: PropTypes.string,
	startDateName: PropTypes.string,

	isTile: PropTypes.bool,
	isOverlay: PropTypes.bool,
	onOpen: PropTypes.func,
	isTable: PropTypes.bool,
	isRequired: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isInEditMode: PropTypes.bool,
	isHighlighted: PropTypes.bool,

	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	setFieldValue: PropTypes.func,
	setFieldTouched: PropTypes.func,
	setOpen: PropTypes.func,
};

export default FormikSingleDatePicker;
