import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import Button from 'Common/components/buttons/Button';

import useQueryParameter from 'Common/hooks/useQueryParameter';
import useConfirmationCodeValidity from 'User/hooks/useConfirmationCodeValidity';
import ConfirmPasswordResetForm from 'User/components/ConfirmPasswordReset/ConfirmPasswordResetForm/ConfirmPasswordResetForm';
import { LoadingIcon, Wrapper } from 'User/components/guestScreenComponents';
import H5 from 'Common/components/headers/H5';
import H6 from 'Common/components/headers/H6';
import backgroundImage from 'Common/assets/images/auth-background.jpg';

const StyledH5 = styled(H5)`
	display: flex;
	text-transform: uppercase;
	margin-bottom: 8px;
	font-weight: 700;
`;
const StyledH6 = styled(H6)`
	font-weight: 400;
	text-align: start;
	color: ${colors.secondary.light};
	margin-bottom: 35px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	height: 100px;
	align-items: flex-end;
	justify-content: flex-end;
	padding-top: 183px;
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const Line = styled.div`
	height: 1px;
	width: 32px;
	margin-bottom: 32px;
	background: ${colors.secondary.dark};
`;

const TextWrapper = styled.div`
	padding-bottom: 30px;
`;

const ConfirmPasswordReset = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const email = useQueryParameter('email');
	const code = useQueryParameter('code');

	const { isValid: isCodeValid, isChecking: isCheckingCodeValidity } = useConfirmationCodeValidity(
		email,
		code,
	);

	return (
		<>
			<Helmet>
				<title>{t('Confirm Password Reset')}</title>
			</Helmet>
			<Wrapper backgroundImage={backgroundImage}>
				<TitleWrapper>
					<StyledH5>{t('Enter and confirm your new password')}</StyledH5>
					<Line />
				</TitleWrapper>
				{isCheckingCodeValidity ? (
					<>
						<StyledH6>{t('Checking code validity...')}</StyledH6>
						<H5>
							<LoadingIcon />
						</H5>
					</>
				) : isCodeValid ? (
					<ConfirmPasswordResetForm />
				) : (
					<>
						<TextWrapper>
							<StyledH6>{t('This password reset link is not valid')}</StyledH6>
						</TextWrapper>
						<ButtonWrapper>
							<Button
								text={t('Back to log in')}
								onClick={() => dispatch(push('/login'))}
								label="Confirm Password Resset Form - Back to log in Button"
							/>
						</ButtonWrapper>
					</>
				)}
			</Wrapper>
		</>
	);
};

export default ConfirmPasswordReset;
