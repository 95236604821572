import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getFilterRange from 'Client/api/getFilterRange';
import { merge } from 'lodash';
import loadCountryOptions from 'Country/utils/loadCountryOptions';
import loadIndustryOptions from 'Industries/utils/loadIndustryOptions';
import modifyNumberFilterData from 'Common/components/filter/hooks/modifyNumberFilterData';
import loadSelectedCountries from 'Country/utils/loadSelectedCountries';
import loadSelectedIndustries from 'Industries/utils/loadSelectedIndustries';
import showToastError from 'Common/utils/showToastError';
import { FILTER_TYPES, FILTER_NAME_SUFFIXES } from 'Common/components/filter/constants/filterConstants';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import getContractsFilterRange from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/ClientContract/api/getContractsFilterRange';

const fallbackFilterRanges = {
	totalProjectsMax: 0,
	totalProjectsMin: 0,
	totalSystemSizeMax: 0,
	totalSystemSizeMin: 0,
};

const useClientListFilterData = () => {
	const { t } = useTranslation();

	const [filterRanges, setFilterRanges] = useState(fallbackFilterRanges);

	const abortController = useAbortController();

	const defaultFilterData = [
		{
			name: 'name',
			label: t('Name'),
			type: FILTER_TYPES.INPUT,
		},
		{
			name: 'projectType',
			label: t('Projects'),
			type: FILTER_TYPES.SWITCH,
			options: [
				{
					value: '',
					label: 'All',
				},
				{
					value: 'inOperationProjects',
					label: 'In operation',
				},
				{
					value: 'signedProjects',
					label: 'Signed',
				},
			],
			hideLabelValue: true,
		},
		{
			name: 'industryId',
			label: t('Industry'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadIndustryOptions,
			loadOptionOrOptionsByQueryParam: loadSelectedIndustries,
		},
		{
			name: 'financialDDOPassedDate',
			label: t('Financial DD passed date'),
			type: FILTER_TYPES.DATE,
			startDateName: 'financialDDOPassedDate' + FILTER_NAME_SUFFIXES.DATE_FROM,
			endDateName: 'financialDDOPassedDate' + FILTER_NAME_SUFFIXES.DATE_TO,
			isLocalDate: true,
		},
		{
			name: 'fddPassed',
			label: t('FDD Passed'),
			type: FILTER_TYPES.SWITCH,
			options: [
				{
					value: 'Yes',
					label: 'Yes',
				},
				{
					value: 'No',
					label: 'No',
				},
			],
			hideLabelValue: true,
		},
		{
			name: 'countryId',
			label: t('Country'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadCountryOptions,
			loadOptionOrOptionsByQueryParam: loadSelectedCountries,
		},
		{
			name: 'city',
			label: t('City / Region'),
			type: FILTER_TYPES.INPUT,
		},
		{
			name: 'totalProjects',
			label: t('Total Projects'),
			type: FILTER_TYPES.NUMBER,
			minName: 'totalProjectsFrom',
			maxName: 'totalProjectsTo',
			lowerBound: fallbackFilterRanges.totalProjectsMin,
			upperBound: fallbackFilterRanges.totalProjectsMax,
		},
		{
			name: 'totalSystemSize',
			label: t('Total system size(kWp)'),
			type: FILTER_TYPES.NUMBER,
			minProps: { icon: 'kWp', iconPosition: 'right' },
			maxProps: { icon: 'kWp', iconPosition: 'right' },
			minName: 'totalSystemSizeFrom',
			maxName: 'totalSystemSizeTo',
			lowerBound: filterRanges.totalSystemSizeMax,
			upperBound: filterRanges.totalSystemSizeMax,
		},
	];

	useEffect(() => {
		(async () => {
			try {
				const responses = await Promise.all([
					getFilterRange(abortController.signal),
					getContractsFilterRange(abortController.signal),
				]);

				setFilterRanges(prevFilterRanges => ({
					...prevFilterRanges,
					...merge(...responses.map(response => response.data)),
				}));
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setFilterRanges(fallbackFilterRanges);
				}
			}
		})();
	}, [abortController.signal]);

	const [filtersData, setFiltersData] = useState(defaultFilterData);

	useEffect(() => {
		const modifyNumberFilterDataBinded = modifyNumberFilterData.bind(undefined, filterRanges);

		setFiltersData(prevFilterData => [...prevFilterData].map(modifyNumberFilterDataBinded));
	}, [filterRanges]);

	return filtersData;
};

export default useClientListFilterData;
