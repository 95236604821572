import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import RfepTile from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/Rfep/RfepTile';

import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';

import EPCOfferTile from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/EPCOfferTile/EPCOfferTile';
import ClientOfferTile from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/ClientOfferTile/ClientOfferTile';
import { useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import H3 from 'Common/components/headers/H3';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import ClientContractTile from './Tiles/ClientContract/components/Tile/ClientContractTile';

const HistoryTitle = styled(H3)`
	position: relative;
	padding-left: ${sizes.spacing(2)};
	padding-top: ${sizes.spacing(2)};
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	color: ${colors.secondary.dark};
	margin-bottom: ${sizes.spacing(1)};

	&::before {
		content: '';
		position: absolute;
		bottom: -${sizes.spacing(1)};
		left: ${sizes.spacing(2)};
		width: 32px;
		height: 1px;
		background: ${colors.secondary.dark};
	}
`;

const HistoryContainer = styled(TilesGridWrapper)`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-right: 0;

	${({ $isAllowedToEditHistory }) =>
		!$isAllowedToEditHistory &&
		css`
			pointer-events: none;
			opacity: 0.6;
		`}
`;

const SalesTab = ({ project, handleUpdateProject }) => {
	const isContractSigned = useMemo(
		() => Boolean(project?.clientContract?.saasSignatureDate),
		[project?.clientContract?.saasSignatureDate],
	);

	const isAllowedToEditHistory = isAuthorized([roles.ADMIN, roles.HEAD_OF_SALES]);

	return isContractSigned ? (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<ClientContractTile project={project} handleUpdateProject={handleUpdateProject} />
			</Grid>
			<HistoryTitle>History</HistoryTitle>
			<HistoryContainer $isAllowedToEditHistory={isAllowedToEditHistory}>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<RfepTile project={project} onTileSave={handleUpdateProject} />
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<EPCOfferTile project={project} handleUpdateProject={handleUpdateProject} />
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<ClientOfferTile project={project} handleUpdateProject={handleUpdateProject} />
				</Grid>
			</HistoryContainer>
		</TilesGridWrapper>
	) : (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<RfepTile project={project} onTileSave={handleUpdateProject} />
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<EPCOfferTile project={project} handleUpdateProject={handleUpdateProject} />
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<ClientOfferTile project={project} handleUpdateProject={handleUpdateProject} />
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<ClientContractTile project={project} handleUpdateProject={handleUpdateProject} />
			</Grid>
		</TilesGridWrapper>
	);
};

SalesTab.defaultProps = {
	project: {},
	handleUpdateProject: async () => {},
};

SalesTab.propTypes = {
	project: PropTypes.shape({
		epcOffers: PropTypes.arrayOf(PropTypes.shape({})),
		hasPrimaryClientOffer: PropTypes.bool,
		tender: PropTypes.shape({ state: PropTypes.string }),
		clientContract: PropTypes.shape({
			saasSignatureDate: PropTypes.string,
		}),
	}),
	handleUpdateProject: PropTypes.func,
};

export default SalesTab;
