// TODO remove the eslint complexity and fix it
import { forwardRef, useEffect, useRef, useState } from 'react';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import useReponsive from 'Common/hooks/useResponsive';
import Icon from 'Common/components/icons/Icon';
import Label from 'Common/components/form/Label';
import useCombinedRefs from 'Common/hooks/useCombinedRefs';
import ErrorMessage from 'Common/components/form/ErrorMessage';

import sizes from 'Application/theme/sizes';
import colors from 'Application/theme/colors';
import { useLabelColor } from './hooks/useLabelColor';
import { useMemo } from 'react';
import $inputBorderColor from 'Common/components/form/utils/$inputBorderColor';
import useInputPlaceholder from 'Common/components/form/hooks/useInputPlaceholder';
import useInputValue from 'Common/components/form/hooks/useInputValue';
import Big from 'big.js';

const PrefixContainer = styled.div`
	display: flex;
	align-items: center;
	margin-right: 4px;
	${sizes.fontSize.big};

	${({ $isDisabled }) =>
		$isDisabled &&
		css`
			color: ${colors.text.greyLight};
		`}
`;

const baseInputStyles = css`
	input {
		display: block;
		width: 100%;
		min-height: ${sizes.base(7)};
		border: none;
		border-bottom: 1px solid transparent;
		color: ${colors.text.black};
		font-weight: 400;
		${sizes.fontSize.main};
		line-height: ${sizes.lineHeight.small};
		background-color: transparent;
		border-color: ${$inputBorderColor};
		cursor: pointer;

		&:focus {
			outline: 0 none;
		}

		&[disabled] {
			color: ${colors.text.greyLight};
			${sizes.fontSize.main};
			margin-top: 2px;
			border-color: transparent;
			&::placeholder {
				color: ${colors.text.greyLight};
				${sizes.fontSize.main};
			}
		}

		&::placeholder {
			color: ${colors.text.grey};
		}
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}

	input:disabled {
		-webkit-text-fill-color: ${colors.text.greyLight};
		-webkit-opacity: 1;
		opacity: 1;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:focus {
		transition: background-color 600000s 0s, color 600000s 0s;
	}
`;

const commonFocusStyles = css`
	input {
		color: ${colors.text.black};
		font-weight: 600;
	}
`;

const disabledStyles = css`
	input {
		cursor: not-allowed;
		color: ${colors.text.greyLight};
	}
	label {
		cursor: not-allowed;
		color: ${colors.text.greyLight};
	}
`;

const InputWrapper = styled.div`
	position: relative;
	flex: 1;
	max-width: '100%';

	${baseInputStyles}

	${({ $hasFocus }) => $hasFocus && commonFocusStyles}
	${({ $isDisabled }) => $isDisabled && disabledStyles}
`;

const InputRowContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const ClearIcon = styled(Icon)`
	position: absolute;
	top: 50%;
	right: ${sizes.spacing(1)};
	color: ${colors.text.primary};
	width: ${sizes.base(5)} !important;
	height: ${sizes.base(5)} !important;
	transform: translateY(-50%);
	cursor: pointer;

	&:hover {
		color: ${colors.grey.dark};
	}
`;

const FormikInput = forwardRef(
	(
		{
			// Base props
			id,
			name,
			label,
			error,
			touched,
			inputType,
			hideErrors,
			placeholder: propPlaceholder,
			value: rawValue,
			defaultValue,

			// Handlers
			onBlur,
			onClear,
			onFocus,
			onClick,
			setFieldTouched,
			onMouseEnter,
			onMouseLeave,

			// State of the input props
			isTile,
			isOverlay,
			isDisabled,
			isRequired,
			isClearable,
			isInEditMode,
			isHighlighted,

			// Additional elements
			unit,
			tooltip,
			prefix: Prefix,

			// The rest, should be passed to the input and not to use anything from props in the component
			...props
		},
		_ref,
	) => {
		const { isMobile } = useReponsive();
		const showLabel = Boolean(label);
		const hasPrefix = Boolean(Prefix);
		const hasError = Boolean(error) && touched;
		const showError = hasError && !hideErrors && touched;

		const isReadMode = useMemo(() => isTile && !isInEditMode, [isTile, isInEditMode]);

		const [hasFocus, setHasFocus] = useState(false);
		const [hasHover, setHasHover] = useState(false);

		const innerRef = useRef(_ref);
		const combinedRef = useCombinedRefs(_ref, innerRef);

		const labelColor = useLabelColor({
			isDisabled,
			hasError,
			isHighlighted,
			isTile,
			isInEditMode,
			hasHover,
			hasFocus,
		});

		const value = useInputValue(rawValue, unit, hasFocus, isTile, isInEditMode);

		const placeholder = useInputPlaceholder(isDisabled, unit, propPlaceholder, isTile, isInEditMode);

		const handleClearIconClick = e => {
			if (isClearable && onClear) {
				onClear(e);
			}
		};

		const handleOnClick = (e, ...args) => {
			requestAnimationFrame(() => {
				e.target.focus();
			});
			typeof onClick === 'function' && onClick(e, args);
		};

		const handleOnKeyDown = e => {
			if (isReadMode || isDisabled) {
				e.preventDefault();
			}
		};

		const handleOnFocus = (e, ...args) => {
			if (!isReadMode) {
				setHasFocus(true);
				typeof onFocus === 'function' && onFocus(e, ...args);
			} else {
				e.target.blur();
			}
		};

		const handleOnBlur = (...args) => {
			if (!isReadMode) {
				setHasFocus(false);
				typeof setFieldTouched === 'function' && setFieldTouched(name, true);
				typeof onBlur === 'function' && onBlur(...args);
			}
		};

		const handleOnMouseEnter = (...args) => {
			setHasHover(true);
			typeof onMouseEnter === 'function' && onMouseEnter(...args);
		};

		const handleOnMouseLeave = (...args) => {
			setHasHover(false);
			typeof onMouseLeave === 'function' && onMouseLeave(...args);
		};

		useEffect(() => {
			if (isTile && hasFocus && !isInEditMode && combinedRef.current) {
				combinedRef?.current.blur();
			}
		}, [isTile, isInEditMode, hasFocus, combinedRef]);

		return (
			<InputWrapper
				$isTile={isTile}
				$isDisabled={isDisabled}
				$isInEditMode={isInEditMode}
				$hasFocus={hasFocus}
				$hasError={hasError}
				$isMobile={isMobile}
				$isOverlay={isOverlay}
				onMouseEnter={handleOnMouseEnter}
				onMouseLeave={handleOnMouseLeave}
			>
				{showLabel && (
					<Label
						label={label}
						color={labelColor}
						isRequired={isTile ? isInEditMode && isRequired : isRequired}
						htmlFor={id}
						tooltip={tooltip}
					/>
				)}
				<InputRowContainer>
					{hasPrefix && (
						<PrefixContainer $isDisabled={isDisabled}>
							{typeof Prefix === 'string' ? Prefix : <Prefix />}
						</PrefixContainer>
					)}
					<input
						id={id}
						value={value}
						defaultValue={defaultValue}
						type={inputType}
						ref={combinedRef}
						onBlur={handleOnBlur}
						onFocus={handleOnFocus}
						onClick={handleOnClick}
						placeholder={placeholder}
						onKeyDown={handleOnKeyDown}
						{...props}
						readOnly={isReadMode}
						disabled={isDisabled}
						tabIndex={isDisabled ? -1 : 0}
					/>
				</InputRowContainer>

				{isClearable && value.length > 0 && <ClearIcon icon="close" onClick={handleClearIconClick} />}
				{showError && <ErrorMessage message={error} />}
			</InputWrapper>
		);
	},
);

FormikInput.defaultProps = {
	// Base props
	id: '',
	name: '',
	label: '',
	error: '',
	touched: false,
	inputType: 'text',
	hideErrors: false,
	placeholder: '',
	value: '',
	defaultValue: undefined,

	// Handlers
	onBlur: () => {},
	onClear: () => {},
	onFocus: () => {},
	onClick: () => {},
	setFieldTouched: () => {},
	onMouseEnter: () => {},
	onMouseLeave: () => {},

	// State of the input props
	isTile: false,
	isOverlay: false,
	isDisabled: false,
	isRequired: false,
	isClearable: false,
	isInEditMode: false,
	isHighlighted: false,

	// Additional elements
	unit: '',
	tooltip: '',
	prefix: () => null,
};

FormikInput.propTypes = {
	// Base props
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string,
	label: PropTypes.string,
	error: PropTypes.string,
	touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
	inputType: PropTypes.string,
	hideErrors: PropTypes.bool,
	placeholder: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.instanceOf(Big),
		PropTypes.instanceOf(null),
	]),
	defaultValue: PropTypes.string,

	// Handlers
	onBlur: PropTypes.func,
	onClear: PropTypes.func,
	onFocus: PropTypes.func,
	onClick: PropTypes.func,
	setFieldTouched: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,

	// State of the input props
	isTile: PropTypes.bool,
	isOverlay: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isRequired: PropTypes.bool,
	isClearable: PropTypes.bool,
	isInEditMode: PropTypes.bool,
	isHighlighted: PropTypes.bool,

	// Additional elements
	unit: PropTypes.string,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
	prefix: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

FormikInput.displayName = 'FormikInput';

export default FormikInput;
