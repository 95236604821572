import api from 'Application/api/api';

const uploadDocsForInquiry = (signal, file) =>
	api.post('/inquiries/partner/upload-docs', file, {
		signal,
		timeout: 100000,
		headers: {
			'Content-Type': 'multipart/form-data',
			Accept: '*/*',
		},
	});

export default uploadDocsForInquiry;
