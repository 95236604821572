const formatNumber = (number, decimalPlaces = 2) => {
	const isValidNumber = !isNaN(parseFloat(number));
	const minDecimalPlaces = Math.min(decimalPlaces, 20); // 20 is the maximum number of decimal places supported by toLocaleString

	if (isValidNumber) {
		return Number(number).toLocaleString('en-EN', {
			minimumFractionDigits: minDecimalPlaces,
			maximumFractionDigits: minDecimalPlaces,
		});
	}

	return '';
};

export default formatNumber;
