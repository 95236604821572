import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

import ProjectInfo from 'Projects/components/shared/ProjectInfo';
import { setSidePanelDirtyForm } from 'Application/reducers/reduxSidePanel';

import CommentList from 'Common/components/Comments/CommentList';

import styled from 'styled-components/macro';
import sizes from 'Application/theme/sizes';
import useProjectDetailsCommentsData from 'PMWorkflow/components/Project/hooks/useProjectCommentsData';
import ResponsiblePeopleTile from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/ResponsiblePeopleTile/ResponsiblePeopleTile';

const alertUnsavedChanges = event => {
	event.preventDefault();
	event.returnValue = '';
};

const CommentListContainer = styled.div`
	padding-top: ${sizes.spacing(4)};
	margin-bottom: ${({ $hasComment }) => ($hasComment ? sizes.spacing(6) : 0)};
`;

const Comment = styled.div``;
const CommentLabel = styled.div`
	color: ${colors.text.grey};
	font-size: 14px;
	font-weight: 700;
`;
const CommentContent = styled.div`
	font-size: 16px;
	font-weight: 400;
`;

const DashboardSidePanelProject = ({ project, updateProject }) => {
	const dispatch = useDispatch();
	const [dirtyForms, setDirtyForms] = useState([]);
	const [editModeEnabledTabs, setEditModeEnabledTabs] = useState([]);

	useEffect(() => {
		if (editModeEnabledTabs.length > 0) {
			window.addEventListener('beforeunload', alertUnsavedChanges);
		} else {
			window.removeEventListener('beforeunload', alertUnsavedChanges);
		}

		return () => window.removeEventListener('beforeunload', alertUnsavedChanges);
	}, [editModeEnabledTabs]);

	const handleEnableEditMode = tab => {
		setEditModeEnabledTabs(preEditModeEnabledTabs => [...preEditModeEnabledTabs, tab]);
	};

	const handleDisableEditMode = tab => {
		setEditModeEnabledTabs(preEditModeEnabledTabs => {
			const newTabs = [...preEditModeEnabledTabs];

			const index = newTabs.indexOf(tab);

			if (index !== -1) {
				newTabs.splice(index, 1);
			}

			return newTabs;
		});
	};

	const handleDirtyForms = useCallback(
		(form, isDirty) => {
			if (isDirty) {
				setDirtyForms(prev => [...prev, form]);
			} else {
				setDirtyForms(prev => {
					const current = [...prev];
					const formIndex = current.indexOf(form);
					if (formIndex > -1) {
						current.splice(formIndex, 1);
					}
					return current;
				});
			}
		},
		[setDirtyForms],
	);

	const handleResponsiblePeopleDirtyForm = useCallback(
		isDirty => {
			handleDirtyForms('responsiblePeople', isDirty);
		},
		[handleDirtyForms],
	);

	useEffect(() => {
		dispatch(setSidePanelDirtyForm(dirtyForms));
	}, [dirtyForms, dispatch]);

	const { defaultComments, onAddComment, onEditComment, onDeleteComment } =
		useProjectDetailsCommentsData(project);

	return (
		<>
			<ProjectInfo
				border="none"
				padding="0px"
				margin="0px 0px 16px 0px"
				backgroundColor={colors.common.white}
				data={project}
				disableUserInfoHover
			/>
			<ResponsiblePeopleTile
				project={project}
				onEnterEditMode={() => handleEnableEditMode(0)}
				onExitEditMode={() => handleDisableEditMode(0)}
				onDirtyForm={handleResponsiblePeopleDirtyForm}
				onTileSave={updateProject}
				heightFitConent
			/>
			<CommentListContainer $hasComment={Boolean(project?.comment)}>
				<CommentList
					defaultComments={defaultComments}
					onAddComment={onAddComment}
					onEditComment={onEditComment}
					onDeleteComment={onDeleteComment}
				/>
			</CommentListContainer>
			{Boolean(project?.comment) && (
				<Comment>
					<CommentLabel>Comment</CommentLabel>
					<CommentContent>{project?.comment}</CommentContent>
				</Comment>
			)}
		</>
	);
};

DashboardSidePanelProject.defaultProps = {
	project: {},
	updateProject: () => {},
};

DashboardSidePanelProject.propTypes = {
	project: PropTypes.shape({
		comment: PropTypes.string,
	}),
	updateProject: PropTypes.func,
};

export default DashboardSidePanelProject;
