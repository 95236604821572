import api from 'Application/api/api';

const getSpvBalanceById = (signal, id, details) => {
	const params = {
		details: details,
	};

	return api.get(`/emissionspv-balance/by-id/${id}`, {
		params,
		signal,
	});
};

export default getSpvBalanceById;
