import { useState, useRef, useEffect, useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import Row from 'Common/components/layout/Row';
import sizes from 'Application/theme/sizes';
import colors from 'Application/theme/colors';
import Icon from 'Common/components/icons/Icon';
import FormikInput from 'Common/components/form/FormikInput';
import { useCalendarData } from 'Common/components/form/hooks/useDatepickerCalendarData';
import {
	isAfter,
	startOfDay,
	startOfWeek,
	startOfMonth,
	startOfQuarter,
	startOfYear,
	endOfWeek,
	endOfYear,
	endOfMonth,
	endOfQuarter,
	sub,
	set,
	isSameDay,
	isBefore,
} from 'date-fns';
import { ClickAwayListener, useMediaQuery } from '@mui/material';
import useReponsive from 'Common/hooks/useResponsive';

const PickerContainer = styled(Row)`
	box-shadow: 0px 0px ${sizes.base(2.5)} rgba(0, 0, 0, 0.2);
	background-color: ${colors.common.white};
	border-radius: ${sizes.base(1.5)};
`;
const PickerMain = styled.div`
	width: ${({ isSmallDesktop, isForm }) => (isSmallDesktop || isForm ? sizes.base(90) : sizes.base(115))};

	border-right: solid 1px ${colors.grey.light};
`;
const PickerInputs = styled(Row)`
	justify-content: space-between;
	gap: 5%;
	padding: ${sizes.spacing(1)} ${sizes.spacing(2)};
	padding-top: ${sizes.spacing(2)};
`;
const StyledFormikInput = styled(FormikInput)`
	${({ $isSelecting }) =>
		$isSelecting &&
		css`
			border-color: ${colors.primary.main} !important;
		`}
`;

const Calendar = styled.div``;

const CalendarControls = styled(Row)`
	justify-content: space-between;
	width: 100%;
	border-top: solid 1px ${colors.grey.light};
	padding: ${sizes.spacing(2)};
`;
const CalendarPickerRow = styled(Row)`
	position: relative;
`;

const CalendarPickerPrevBtn = styled(Icon)`
	cursor: pointer;
`;

const CalendarPickerLabel = styled.div`
	cursor: pointer;
	line-height: 1.6;
	text-align: center;
	margin: 0 ${sizes.spacing(0.5)};
	font-weight: 700;
	text-transform: uppercase;
`;
const CalendarPickerNextBtn = styled(Icon)`
	cursor: pointer;
`;

const CalendarPickerDropdown = styled.div`
	position: absolute;
	width: 100%;
	left: 0;
	top: 130%;
	background-color: ${colors.common.white};
	box-shadow: 0px 0px ${sizes.base(2.5)} rgba(0, 0, 0, 0.2);
	max-height: 200px;
	overflow-y: auto;
	border-radius: ${sizes.base(3)};
	transform-origin: top;
	transition: transform 0.3s ease-in-out;
	transform: ${({ isOpen }) => (isOpen ? 'scaleY(1)' : 'scaleY(0)')};
`;
const CalendarPickerDropdownOption = styled.div`
	padding: ${sizes.spacing(1.2)} 0;
	text-align: center;
	&:hover {
		background-color: ${colors.grey.light};
	}
	${({ isSelected }) =>
		isSelected &&
		css`
			background-color: ${colors.primary.main};
			color: ${colors.common.white};
		`}
`;

const CalendarWeekDays = styled(Row)`
	padding-top: ${sizes.spacing(2)};
	padding-bottom: ${sizes.spacing(1.5)};
	padding: ${sizes.spacing(2)};
	border-top: solid 1px ${colors.grey.light};
`;

const CalendarWeekDay = styled.div`
	width: calc(100% / 7);
	font-weight: 600;
	line-height: ${sizes.base(6)};
	text-align: center;
`;

const CalendarDaysGrid = styled.div`
	padding-top: ${sizes.spacing(2)};
	padding-bottom: ${sizes.spacing(2)};
	border-top: solid 1px ${colors.grey.light};
`;
const CalendarDaysRow = styled(Row)`
	padding: 0 ${sizes.spacing(2)};
	padding-top: ${sizes.spacing(0.5)};
`;
const CalendarDay = styled.div`
	min-width: ${({ isForm, isSmallDesktop }) => (isForm || isSmallDesktop ? sizes.base(11.5) : sizes.base(15))};
	line-height: ${({ isForm, isSmallDesktop }) => (isForm || isSmallDesktop ? '1.7' : '2')};
	font-weight: 400;
	text-align: center;
	cursor: pointer;
	color: ${({ isOtherMonth }) => (isOtherMonth ? `${colors.text.greyLight}` : `${colors.common.black}`)};
	padding: ${({ isForm, isSmallDesktop }) =>
		isForm || isSmallDesktop ? sizes.spacing(1) : sizes.spacing(1.5)};
	aspect-ratio: 1;
	flex: 1;
	${({ isInRange }) =>
		isInRange &&
		css`
			color: ${colors.common.white};
			background-color: ${colors.primary.main};
		`}
	${({ isStart }) =>
		isStart &&
		css`
			border-top-left-radius: 50%;
			border-bottom-left-radius: 50%;
			color: ${colors.common.white};
			background-color: ${colors.primary.main};
		`}
		${({ isStartHover }) =>
		isStartHover &&
		css`
			border-top-left-radius: 50%;
			border-bottom-left-radius: 50%;
			background-color: ${({ isStart }) => !isStart && `${colors.grey.light}`};
		`}
		${({ isEnd }) =>
		isEnd &&
		css`
			border-top-right-radius: 50%;
			border-bottom-right-radius: 50%;
			color: ${colors.common.white};
			background-color: ${colors.primary.main};
		`}
		${({ isEndHover }) =>
		isEndHover &&
		css`
			border-top-right-radius: 50%;
			border-bottom-right-radius: 50%;
			background-color: ${({ isEnd }) => !isEnd && `${colors.grey.light}`};
		`}
		${({ isCurrentDayToday }) =>
		isCurrentDayToday &&
		css`
			color: ${colors.common.darkBlue};
		`};
`;

const ClearBtn = styled.div`
	line-height: ${sizes.base(5.5)};
	padding: ${sizes.spacing(1.5)};
	border-top: solid 1px ${colors.grey.light};
	cursor: pointer;
	text-align: center;
	color: ${colors.text.primaryLight};
`;

const PickerSidebar = styled.div`
	padding: ${sizes.spacing(2)};
`;
const PickerSidebarItem = styled.div`
	position: relative;
	padding: ${sizes.spacing(0.8)} ${sizes.spacing(2)};
	border-radius: ${sizes.base(8)};
	color: ${({ isSelected }) => (isSelected ? `${colors.common.white}` : `${colors.common.black}`)};
	margin-bottom: ${sizes.spacing(1)};
	font-weight: ${({ isSelected }) => (isSelected ? '700' : '500')};
	width: max-content;
	cursor: pointer;
	background-color: ${({ isSelected }) =>
		isSelected ? `${colors.text.primaryLight} !important` : 'transparent'};

	&:hover {
		background-color: ${colors.grey.light};
	}

	${({ isSelected }) =>
		isSelected &&
		css`
			padding-right: ${sizes.spacing(4.5)};
		`}
`;
const PickerSidebarItemIcon = styled(Icon)`
	display: ${({ $isVisible }) => !$isVisible && 'none !important'};
	cursor: pointer;
	position: absolute;
	right: ${sizes.spacing(1)};
	top: 50%;
	transform: translateY(-50%) scale(0.9);
`;

const innitialSidebarDates = [
	{
		label: 'This week',
		start: startOfWeek(new Date()),
		end: startOfDay(endOfWeek(new Date())),
	},
	{
		label: 'This month',
		start: startOfMonth(new Date()),
		end: startOfDay(endOfMonth(new Date())),
	},
	{
		label: 'This quarter',
		start: startOfQuarter(new Date()),
		end: startOfDay(endOfQuarter(new Date())),
	},
	{
		label: 'This year',
		start: startOfYear(new Date()),
		end: startOfDay(endOfYear(new Date())),
	},
	{
		label: 'Last month',
		start: startOfMonth(sub(new Date(), { months: 1 })),
		end: startOfDay(endOfMonth(sub(new Date(), { months: 1 }))),
	},
	{
		label: 'Last quarter',
		start: startOfQuarter(sub(new Date(), { months: 3 })),
		end: startOfDay(endOfQuarter(sub(new Date(), { months: 3 }))),
	},
	{
		label: 'Last year',
		start: startOfYear(
			set(new Date(), {
				year: new Date().getFullYear() - 1,
			}),
		),
		end: startOfDay(
			endOfYear(
				set(new Date(), {
					year: new Date().getFullYear() - 1,
				}),
			),
		),
	},
];

const RawDataLoader = () => (
	<ContentLoader width="60%" height={10} backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
		<rect x="0" y="0" rx="5" ry="5" width="100%" height="10" />
	</ContentLoader>
);
const DataLoader = memo(RawDataLoader);

const loaderData = Array.from({ length: 6 }, () => Array.from({ length: 7 }, (_, j) => j));

const FormikDatePicker = ({
	startDateValue,
	endDateValue,
	startDateName,
	endDateName,
	setFieldValue,
	isDual,
	isForm,
	closePopup,
	startDateInputRef,
	id,
	endId,
	onClearAll,
}) => {
	const { t } = useTranslation();
	const { isMobile } = useReponsive();

	const isSmallDesktop = useMediaQuery('(max-width:1300px)');
	const isTablet = useMediaQuery('(max-width:1024px)');

	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();

	const [startDateHover, setStartDateHover] = useState();
	const [endDateHover, setEndDateHover] = useState();

	const [startDateInput, setStartDateInput] = useState();
	const [endDateInput, setEndDateInput] = useState();

	const [dateType, setDateTypeInternal] = useState('start');
	const setDateType = useCallback(
		val => {
			setDateTypeInternal(isDual ? val : 'start');
		},
		[isDual],
	);

	const [sidebarDates, setSidebarDates] = useState(innitialSidebarDates);

	const { daysOfTheWeek, currentMonth, currentYear, calendarData, changeDate, months, isLoadingCalendarData } =
		useCalendarData(startDate, endDate, dateType, startDateHover, endDateHover, isDual);

	const currentMonthNum = useMemo(() => months.indexOf(currentMonth), [currentMonth, months]);

	const years = useMemo(() => Array.from(new Array(41), (_, i) => i + 2010), []);

	const handleInputFocus = type => {
		setDateType(type);
	};

	const handleInputChange = (type, value) => {
		const setterFuncInput = type === 'start' ? setStartDateInput : setEndDateInput;
		const setterFuncDate = setFieldValue.bind(undefined, type === 'start' ? startDateName : endDateName);

		const dateRegex = /^(0?[1-9]|[1-2][0-9]|3[0-1])(\/|.|-)(0?[1-9]|1[0-2])(\/|\.|-)\d{4}$/;

		const inputRegex = /[0-9]|\/|\.|-/;

		if ((value && value?.[value?.length - 1].match(inputRegex)) || value.length === 0) {
			setterFuncInput(value);
		}

		if (value.length === 0) {
			setterFuncDate(undefined);
		} else if (value.match(dateRegex)) {
			const separatorsRegex = /(\/|\.|-)/;
			const [day, month, year] = value.split(separatorsRegex).filter(el => !el.match(separatorsRegex));
			const newDate = new Date(year, month - 1, day);

			if (type === 'end' && isBefore(newDate, startDate)) {
				setFieldValue(startDateName, null);
			} else if (type === 'start' && isAfter(newDate, endDate)) {
				setFieldValue(endDateName, null);
			}

			setterFuncDate(newDate);
			setSidebarDates(prevDates => prevDates.map(el => ({ ...el, selected: false })));
		}
	};

	const handleStartChange = handleInputChange.bind(undefined, 'start');
	const handleEndChange = handleInputChange.bind(undefined, 'end');
	const handleStartFocus = handleInputFocus.bind(undefined, 'start');
	const handleEndFocus = handleInputFocus.bind(undefined, 'end');

	const handleInputClear = (e, type) => {
		e.stopPropagation();
		if (type === 'start') {
			setFieldValue(startDateName, null);
		} else {
			setFieldValue(endDateName, null);
		}
	};

	const [dropdowns, setDropdowns] = useState({ month: false, year: false });
	const selectedMonthRef = useRef();
	const selectedYearRef = useRef();

	const toggleDropdown = type => {
		setDropdowns(prevDropdownState => ({
			...prevDropdownState,
			[type]: !prevDropdownState[type],
		}));
		setTimeout(() => {
			const selectedRef = type === 'month' ? selectedMonthRef : selectedYearRef;
			selectedRef.current?.scrollIntoView({ block: 'center' });
		}, 100);
	};

	const monthTriggerClick = e => {
		e.stopPropagation();
		toggleDropdown('month');
	};

	const yearTriggerClick = e => {
		e.stopPropagation();
		toggleDropdown('year');
	};

	const handleDateClick = ({ month, year, day }) => {
		const newDate = new Date(year, month, day);
		if (dateType === 'start') {
			setFieldValue(startDateName, newDate);
			if (endDate && isAfter(newDate, endDate)) {
				setFieldValue(endDateName, null);
			}
		} else if (dateType === 'end') {
			setFieldValue(endDateName, newDate);
			if (startDate && isAfter(startDate, newDate)) {
				setFieldValue(startDateName, null);
				setDateType('start');
			}
		}
		setSidebarDates(prevDates => prevDates.map(el => ({ ...el, selected: false })));
		isForm && !isDual && closePopup();
	};

	const changeInputValue = (type, value) => {
		const setterFuncInput = type === 'start' ? setStartDateInput : setEndDateInput;

		if (!value) {
			setterFuncInput('');
		} else if (value instanceof Date) {
			const days = value.getDate();
			const month = value.getMonth();
			const year = value.getFullYear();
			setterFuncInput(`${days}/${Number(month) + 1}/${year}`);
		}
	};

	useEffect(() => {
		if (startDateValue && !endDateValue) {
			setDateType('end');
		} else if (!startDateValue && endDateValue) {
			setDateType('start');
		} else if (!startDateValue && !endDateValue) {
			setDateType('start');
		}
	}, [startDateValue, endDateValue, setDateType]);

	useEffect(() => {
		if (startDate && !startDateValue && isSameDay(startDate, startDateValue)) {
			return;
		}
		setStartDate(startDateValue);
		changeInputValue('start', startDateValue);

		changeDate('month', startDateValue ? startDateValue?.getMonth() : currentMonthNum);
		changeDate('year', startDateValue ? startDateValue?.getFullYear() : currentYear);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDateValue]);

	useEffect(() => {
		if (endDate && !endDateValue && isSameDay(endDate, endDateValue)) {
			return;
		}
		setEndDate(endDateValue);
		changeInputValue('end', endDateValue);

		changeDate('month', endDateValue ? endDateValue?.getMonth() : currentMonthNum);
		changeDate('year', endDateValue ? endDateValue?.getFullYear() : currentYear);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endDateValue]);

	useEffect(() => {
		if (!startDate || !endDate) {
			setSidebarDates(prevDates => prevDates.map(el => ({ ...el, selected: false })));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate, setSidebarDates]);

	const handleMouseLeave = () => {
		setStartDateHover(undefined);
		setEndDateHover(undefined);
	};

	const handleDateMouseEnter = ({ day, year, month }) => {
		const setterFunc = dateType === 'start' ? setStartDateHover : setEndDateHover;
		const oppositeSetterFunc = dateType === 'start' ? setEndDateHover : setStartDateHover;

		const newDate = new Date(year, month, day);
		setterFunc(newDate);
		oppositeSetterFunc(undefined);
	};

	const handleClearAll = () => {
		if (onClearAll) {
			onClearAll();
		} else {
			setFieldValue(startDateName, null);
			setFieldValue(endDateName, null);
		}
		setDateType('start');
	};

	const handleSidebarClick = (start, end, index) => {
		const isSelected = sidebarDates[index].selected;
		setFieldValue(startDateName, !isSelected ? start : null);
		setFieldValue(endDateName, !isSelected ? end : null);

		if (!isSelected) {
			setSidebarDates(prevDates => [
				...prevDates.slice(0, index).map(el => ({ ...el, selected: false })),
				{
					...prevDates[index],
					selected: true,
				},
				...prevDates.slice(index + 1).map(el => ({ ...el, selected: false })),
			]);
		} else {
			setDateType('start');
		}
	};

	const handleKeyPress = e => {
		if (e.code === 'Enter') {
			e.preventDefault();
			e.stopPropagation();
			closePopup();
		}
	};

	return (
		<PickerContainer isTablet={isTablet}>
			<PickerMain isForm={isForm} isSmallDesktop={isSmallDesktop}>
				<PickerInputs>
					<StyledFormikInput
						$isSelecting={dateType === 'start'}
						value={startDateInput}
						onFocus={handleStartFocus}
						onChange={e => handleStartChange(e.target.value)}
						onClear={e => handleInputClear(e, 'start')}
						ref={startDateInputRef}
						isClearable
						id={id}
						onKeyDown={handleKeyPress}
					/>
					{isDual && (
						<StyledFormikInput
							$isSelecting={dateType === 'end'}
							value={endDateInput}
							onFocus={handleEndFocus}
							onChange={e => handleEndChange(e.target.value)}
							onClear={e => handleInputClear(e, 'end')}
							isClearable
							id={endId}
							onKeyDown={handleKeyPress}
						/>
					)}
				</PickerInputs>
				<Calendar>
					<CalendarControls>
						<CalendarPickerRow>
							<CalendarPickerPrevBtn icon="arrowBackIos" onClick={() => changeDate('month', currentMonthNum - 1)} />
							<CalendarPickerLabel onClick={monthTriggerClick}>{currentMonth}</CalendarPickerLabel>
							<CalendarPickerNextBtn
								icon="arrowForwardIos"
								onClick={() => changeDate('month', currentMonthNum + 1)}
							/>

							<ClickAwayListener onClickAway={() => dropdowns.month && toggleDropdown('month')}>
								<CalendarPickerDropdown isOpen={dropdowns['month']}>
									{months?.map((month, i) => (
										<CalendarPickerDropdownOption
											// eslint-disable-next-line react/no-array-index-key
											key={`month-${i}`}
											isSelected={currentMonth === month}
											ref={currentMonth === month ? selectedMonthRef : undefined}
											data-month={i + 1 < 10 ? `0${i + 1}` : i + 1}
											onClick={() => {
												changeDate('month', i);
												toggleDropdown('month');
											}}
										>
											{month}
										</CalendarPickerDropdownOption>
									))}
								</CalendarPickerDropdown>
							</ClickAwayListener>
						</CalendarPickerRow>
						<CalendarPickerRow>
							<CalendarPickerPrevBtn icon="arrowBackIos" onClick={() => changeDate('year', currentYear - 1)} />
							<CalendarPickerLabel onClick={yearTriggerClick}>{currentYear}</CalendarPickerLabel>
							<CalendarPickerNextBtn icon="arrowForwardIos" onClick={() => changeDate('year', currentYear + 1)} />
							<ClickAwayListener onClickAway={() => dropdowns.year && toggleDropdown('year')}>
								<CalendarPickerDropdown isOpen={dropdowns['year']}>
									{years?.map((year, i) => (
										<CalendarPickerDropdownOption
											// eslint-disable-next-line react/no-array-index-key
											key={`year-${i}`}
											isSelected={currentYear === year}
											ref={currentYear === year ? selectedYearRef : undefined}
											onClick={() => {
												changeDate('year', year);
												toggleDropdown('year');
											}}
											data-year={year}
										>
											{year}
										</CalendarPickerDropdownOption>
									))}
								</CalendarPickerDropdown>
							</ClickAwayListener>
						</CalendarPickerRow>
					</CalendarControls>

					<CalendarWeekDays>
						{daysOfTheWeek.map((day, ind) => (
							// eslint-disable-next-line react/no-array-index-key
							<CalendarWeekDay key={`day-${ind}`}>{day}</CalendarWeekDay>
						))}
					</CalendarWeekDays>

					<CalendarDaysGrid onMouseLeave={handleMouseLeave}>
						{isLoadingCalendarData
							? loaderData.map((week, i) => (
									// eslint-disable-next-line react/no-array-index-key
									<CalendarDaysRow key={`week-${i}`}>
										{week.map(day => (
											<CalendarDay key={day}>
												<DataLoader />
											</CalendarDay>
										))}
									</CalendarDaysRow>
							  ))
							: calendarData.map((week, i) => (
									// eslint-disable-next-line react/no-array-index-key
									<CalendarDaysRow key={`week-${i}`}>
										{week.map(day => (
											<CalendarDay
												{...day}
												isSmallDesktop={isSmallDesktop}
												key={day.day}
												isForm={isForm}
												data-date={`${day.day < 10 ? '0' + day.day : day.day}/${
													day.month < 10 ? '0' + day.month : day.month
												}/${day.year}`}
												isOtherMonth={day.otherMonth}
												onClick={() => handleDateClick(day)}
												onMouseEnter={() => handleDateMouseEnter(day)}
											>
												{day.day}
											</CalendarDay>
										))}
									</CalendarDaysRow>
							  ))}
					</CalendarDaysGrid>
				</Calendar>
				<ClearBtn onClick={handleClearAll}>{t('Clear all')}</ClearBtn>
			</PickerMain>
			{isDual && !isForm && !isMobile && (
				<PickerSidebar>
					{sidebarDates.map(({ label, start, end, selected }, i) => (
						// eslint-disable-next-line react/no-array-index-key
						<PickerSidebarItem key={i} isSelected={selected} onClick={() => handleSidebarClick(start, end, i)}>
							{label}
							<PickerSidebarItemIcon icon="close" $isVisible={selected} />
						</PickerSidebarItem>
					))}
				</PickerSidebar>
			)}
		</PickerContainer>
	);
};

FormikDatePicker.defaultProps = {
	startDateValue: null,
	endDateValue: null,
	startDateName: '',
	endDateName: '',
	setFieldValue: () => {},
	isDual: false,
	isForm: false,
	closePopup: () => {},
	startDateInputRef: {},
	id: '',
	endId: '',
	onClearAll: () => {},
};

FormikDatePicker.propTypes = {
	startDateValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
	endDateValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
	startDateName: PropTypes.string,
	endDateName: PropTypes.string,
	setFieldValue: PropTypes.func,
	isDual: PropTypes.bool,
	isForm: PropTypes.bool,
	closePopup: PropTypes.func,
	startDateInputRef: PropTypes.shape({}),
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	endId: PropTypes.string,
	onClearAll: PropTypes.func,
};

export default FormikDatePicker;
