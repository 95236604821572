import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useReponsive from 'Common/hooks/useResponsive';

import SingleSectionLayout from 'Common/components/layout/SingleSectionLayout';

import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';

const Wrapper = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: flex-start;
	padding: 50px 0 0 20px;

	h1 {
		font-weight: 500;
		font-size: 40px;
	}

	h2 {
		font-weight: 500;
		font-size: 30px;
	}

	p {
		font-size: 20px;
		width: 60%;
	}
`;

const PrivacyPolicy = () => {
	const { t } = useTranslation();

	const isAuthenticated = useSelector(state => state.user.isAuthenticated);
	const { isMobile } = useReponsive();

	const content = (
		<>
			<HeaderContainer hideSidebars={!isAuthenticated && isMobile}>
				<HeaderTitle />
			</HeaderContainer>

			<Wrapper>
				<h1>{t('Privacy Policy')}</h1>
				<p>{t('Last updated August 01, 2022')}</p>
				<p>
					{t(
						'This privacy notice for ecoligo GmbH ("Company", "we", "us", or "our"), describes how and why we might collect, store, use, and/or process your information when you use our services (“Services”), when you visit our website at ',
					)}
					<a href="https://pulse.ecoligo.com">{t('https://pulse.ecoligo.com')}</a>
				</p>
				<p>
					{t('If you have any questions or concerns, please contact us at ')}
					<a href="admin@ecoligo.com">{t('admin@ecoligo.com')}</a>
				</p>
				<h1>{t('TABLE OF CONTENTS')}</h1>
				<p>{t('1. WHAT INFORMATION DO WE COLLECT?')}</p>
				<p>{t('2. HOW DO WE PROCESS YOUR INFORMATION?')}</p>
				<p>{t('3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?')}</p>
				<p>{t('4. HOW LONG DO WE KEEP YOUR INFORMATION?')}</p>
				<p>{t('5. HOW DO WE KEEP YOUR INFORMATION SAFE?')}</p>
				<p>{t('6. DO WE COLLECT INFORMATION FROM MINORS?')}</p>
				<p>{t('7. WHAT ARE YOUR PRIVACY RIGHTS?')}</p>
				<p>{t('8. CONTROLS FOR DO-NOT-TRACK FEATURES')}</p>
				<p>{t('9. DO WE MAKE UPDATES TO THIS NOTICE?')}</p>
				<p>{t('10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?')}</p>
				<p>{t('11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?')}</p>
				<h1>{t('1. WHAT INFORMATION DO WE COLLECT?')}</h1>
				<p>
					<b>{t('Personal Information Provided by You.')}</b>
					{t(
						'The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and features you use. The personal information we collect may include the following:',
					)}
				</p>
				<ul>
					<li>{t('Names')}</li>
					<li>{t('Phone numbers')}</li>
					<li>{t('Job titles')}</li>
					<li>{t('Email addresses')}</li>
				</ul>
				<p>
					<b>{t('Information automatically collected.')}</b>
					<p>
						{t(
							'We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.The information we collect includes Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).',
						)}
					</p>
				</p>
				<h1>{t('2. HOW DO WE PROCESS YOUR INFORMATION?')}</h1>
				<p>
					{t(
						'We process your personal information to identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them.',
					)}
				</p>

				<h1>{t('3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?')}</h1>
				<p>{t('We do not share your personal information with third parties.')}</p>

				<h1>{t('4. HOW LONG DO WE KEEP YOUR INFORMATION?')}</h1>
				<p>
					{t(
						'We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than 2 years. When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example,because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible',
					)}
				</p>

				<h1>{t('5. HOW DO WE KEEP YOUR INFORMATION SAFE?')}</h1>
				<p>
					{t(
						'We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.',
					)}
				</p>

				<h1>{t('6. DO WE COLLECT INFORMATION FROM MINORS?')}</h1>
				<p>
					{t(
						'We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependents use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at admin@ecoligo.com.',
					)}
				</p>

				<h1>{t('7. WHAT ARE YOUR PRIVACY RIGHTS?')}</h1>
				<p>
					{t(
						'If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: ',
					)}
					<a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html">
						{t('https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html')}
					</a>
				</p>
				<p>
					{t(
						'If you are located in Switzerland, the contact details for the data protection authorities are available here: ',
					)}
					<a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
						{t('https://www.edoeb.admin.ch/edoeb/en/home.html')}
					</a>{' '}
				</p>
				<p>
					{t(
						'Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOUCONTACT US ABOUT THIS NOTICE?" below.',
					)}
				</p>
				<p>
					{t(
						'However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.',
					)}
				</p>
				<p>
					{t('If you have questions or comments about your privacy rights, you may email us at admin@ecoligo.com')}
				</p>

				<h1>{t('8. CONTROLS FOR DO-NOT-TRACK FEATURES')}</h1>
				<p>
					{t(
						'Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.',
					)}
				</p>

				<h1>{t('9. DO WE MAKE UPDATES TO THIS NOTICE?')}</h1>
				<p>
					{t(
						'We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.',
					)}
				</p>

				<h1>{t('10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?')}</h1>
				<p>
					{t(
						'If you have questions or comments about this notice, you may contact our Data Protection Officer, Martin Baart, by email at martin.baart@ecoligo.com, by phone at +49 175 667 33 26, or by post to:ecoligo GmbH, Invalidenstraße 112, 10115 Berlin, Germany',
					)}
				</p>

				<h1>{t('11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?')}</h1>
				<p>
					{t(
						'Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please submit a request vie email to martin.baart@ecoligo.com.',
					)}
				</p>
			</Wrapper>
		</>
	);

	return <SingleSectionLayout>{content}</SingleSectionLayout>;
};

export default PrivacyPolicy;
