import { css } from 'styled-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import Icon from 'Common/components/icons/Icon';
import customGAEvent from 'Common/utils/customGAEvent';
import baseButtonStyles from 'Common/components/buttons/baseButtonStyles';
import useReponsive from 'Common/hooks/useResponsive';
import { useMemo } from 'react';

const Wrapper = styled.div`
	display: inline-flex;

	${({ disabled }) =>
		disabled &&
		css`
			cursor: not-allowed;
		`}
`;

const StyledButtonLink = styled(Link)`
	${baseButtonStyles}
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 24px;
	white-space: nowrap;
	text-decoration: none;
`;

const ButtonLink = ({
	to,
	text,
	icon,
	href,
	label,
	medium,
	active,
	target,
	onClick,
	overlay,
	disabled,
	isLoading,
	secondary,
	isExternal,
	marginRight,
	noBackground,
}) => {
	const { isMobile } = useReponsive();
	const isCancelBtn = useMemo(() => secondary && isMobile, [isMobile, secondary]);

	return (
		<Wrapper disabled={disabled}>
			<StyledButtonLink
				to={to}
				target={target}
				$icon={icon}
				$isMobile={isMobile}
				$active={active}
				$textLength={text.length}
				$overlay={overlay}
				$medium={medium || isMobile}
				$secondary={secondary}
				$marginRight={marginRight}
				$noBackground={noBackground}
				disabled={isLoading ? true : disabled ? true : false}
				onClick={
					isExternal
						? e => {
								customGAEvent('buttonLink', 'click', label);
								window.location = href;
								e.preventDefault();
						  }
						: e => onClick(e)
				}
			>
				{!icon && isCancelBtn && <Icon icon="close" />}
				{icon && <Icon icon={icon} />}
				{text && !isMobile && text}
			</StyledButtonLink>
		</Wrapper>
	);
};

ButtonLink.defaultProps = {
	to: null,
	href: null,
	icon: null,
	text: null,
	label: null,
	target: null,
	medium: false,
	active: false,
	overlay: false,
	disabled: false,
	isLoading: false,
	isExternal: false,
	onClick: () => {},
	noBackground: false,
	secondary: undefined,
	marginRight: undefined,
};

ButtonLink.propTypes = {
	target: PropTypes.shape({}),
	active: PropTypes.bool,
	medium: PropTypes.bool,
	icon: PropTypes.string,
	href: PropTypes.string,
	label: PropTypes.string,
	overlay: PropTypes.bool,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	secondary: PropTypes.bool,
	isLoading: PropTypes.bool,
	isExternal: PropTypes.bool,
	marginRight: PropTypes.bool,
	noBackground: PropTypes.bool,
	to: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

export default ButtonLink;
