import Filter from 'Common/components/filter/Filter';
import RadioButtonsList from '../RadioButtonsList';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const RadioButtonsListContainer = styled.div`
	padding: ${sizes.spacing(2)};
`;

const BottomBtn = styled.div`
	line-height: 22px;
	padding: ${sizes.spacing(1.5)} 0;
	color: ${({ $isActive }) => ($isActive ? colors.text.primaryLight : colors.text.grey)};
	cursor: pointer;
	${sizes.fontSize.main};
	text-align: center;
	position: relative;
	border-top: 1px solid ${colors.grey.light};
`;

const RadioFilter = ({ value, name, setFilterValue, label, options, hideLabelValue, ...props }) => {
	const { t } = useTranslation();

	const handleChange = value => {
		setFilterValue({ [name]: value });
	};
	const filterLabel = `${label} ${value && !hideLabelValue ? `(${value})` : ''}`;

	return (
		<Filter
			label={filterLabel}
			hasSelected={value !== ''}
			handleClearOptions={() => handleChange('')}
			{...props}
			popupContent={
				<Wrapper>
					<RadioButtonsListContainer>
						<RadioButtonsList options={options} selectedValue={value} handleChange={handleChange} />
					</RadioButtonsListContainer>
					<BottomBtn $isActive={Boolean(value)} onClick={() => handleChange('')}>
						{t('Reset to default')}
					</BottomBtn>
				</Wrapper>
			}
		/>
	);
};

RadioFilter.defaultProps = {
	value: '',
	name: '',
	setFilterValue: () => {},
	label: '',
	options: [],
	hideLabelValue: false,
};

RadioFilter.propTypes = {
	value: PropTypes.string,
	name: PropTypes.string,
	setFilterValue: PropTypes.func,
	label: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.shape({})),
	hideLabelValue: PropTypes.bool,
};

export default RadioFilter;
