// TODO remove the eslint complexity and fix it
/* eslint-disable complexity */
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

import { types } from 'Projects/constants/offerTypes';
import { updateCurrentProject } from 'Projects/reduxProject';
import { rfepStates, stateColors } from 'Projects/constants/rfepStates';
import closeRfep from 'Rfep/api/closeRfep';
import deleteRfep from 'Rfep/api/deleteRfep';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import colors from 'Application/theme/colors';

import notify from 'Common/utils/notify';
import EditableTile from 'Common/components/Tile/EditableTile';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import PillWrapper from 'Common/components/PillWrapper';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import StatusPill from 'Common/components/StatusPill';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import RfepOverlay from './Overlay/RfepOverlay';
import crudModes from 'Common/constants/crudModes';

const stateTitles = {
	[rfepStates.DRAFT]: 'The RFEP has not been sent to EPC Partners.',
	[rfepStates.OPEN]: 'The RFEP has been sent and EPC Partners can respond with a proposal.',
	[rfepStates.Awarded]: 'A recipient of the RFEP signed a contract and was awarded the Project.',
	[rfepStates.CLOSED]: 'The RFEP was closed / expired and no recipients were awarded the Project.',
};

const StyledConfirmModal = styled(ConfirmModal)`
	.ReactModal__Content {
		padding: 40px !important;
		text-align: center;
	}

	.ReactModal__Content > div {
		display: flex;
		justify-content: center;
	}
`;

const TooltipHeading = styled.p`
	font-weight: 600;
`;

const StatePill = styled.div`
	background: ${props => stateColors[props.color]};
	border-radius: 25px;
	color: ${colors.grey.lightest};
	padding: 5px 16px;
	font-size: 16px;
`;

const RfepTooltipText = () => (
	<>
		<TooltipHeading>Create & send RFEP</TooltipHeading>
		<p>
			Send a request for an EPC proposal to selected EPC Partners. <br />
			They will be notified by email and will respond using pulse.
		</p>
		<p>EPC Partners must be invited to pulse before they can be included in an RFEP.</p>
	</>
);

const RfepTile = ({ project, onTileSave }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isCreateRfepModalOpened, setIsCreateRfepModalOpened] = useState(false);

	const [isEditRfepModalOpened, setIsEditRfepModalOpened] = useState(false);
	const [rfepIdForDeletion, setRfepIdForDeletion] = useState(null);
	const [rfepIdForClosing, setRfepIdForClosing] = useState(null);
	const [showRfepSentConfirm, setShowRfepSentConfirm] = useState(false);

	const abortController = useAbortController();

	const isAuthorizedToEditAndCreate = isAuthorized([
		roles.ADMIN,
		roles.SALES,
		roles.FINANCE,
		roles.MANAGEMENT,
	]);

	const isSalesChannelEpcPartner = project.salesChannel?.name === 'EPC Partner';

	const hasClient = !!project.client;

	const missingPermission = !isAuthorizedToEditAndCreate;

	const isCreationBlockedByOtherContracts =
		project?.clientContracts?.length > 0 || project?.epcContracts?.length > 0;

	const hasEstimates = project.epcOffers?.some(offer => offer.type === types.ESTIMATE);

	const canCreateRfep =
		!isSalesChannelEpcPartner &&
		hasClient &&
		!isCreationBlockedByOtherContracts &&
		isAuthorizedToEditAndCreate &&
		hasEstimates;

	// const canEditRfep =
	// 	isAuthorizedToEditAndCreate &&
	// 	!hasSignedEpcOffer &&
	// 	!isSalesChannelEpcPartner &&
	// 	project?.tender?.state !== rfepStates.CLOSED;

	const createButtonTooltipText = useMemo(() => {
		if (!canCreateRfep) {
			if (isSalesChannelEpcPartner) {
				return 'Cannot create RFEP when Sales channel is EPC Partner';
			} else if (!hasClient) {
				return 'Cannot create RFEP when Project does not have a Client';
			} else if (isCreationBlockedByOtherContracts) {
				return 'Cannot create RFEP when Project has client/epc contracts';
			} else if (missingPermission) {
				return 'You do not have the required permission for this action';
			} else if (!hasEstimates) {
				return 'Cannot create RFEP when there are no Estimates for the Project';
			}
			return '';
		}
		return '';
	}, [
		canCreateRfep,
		hasClient,
		hasEstimates,
		isCreationBlockedByOtherContracts,
		isSalesChannelEpcPartner,
		missingPermission,
	]);

	const handleCreateButtonClicked = () => {
		setIsCreateRfepModalOpened(true);
	};

	const handleEditButtonClicked = () => {
		setIsEditRfepModalOpened(true);
	};

	const handleRfepClose = async id => {
		try {
			await closeRfep(abortController.signal, id);

			await onTileSave();
			notify(t('RFEP closed successfully'), {
				type: toast.TYPE.SUCCESS,
			});
			setRfepIdForClosing(null);
		} catch (e) {
			showToastError(e);
		}
	};

	// const handleRfepReopen = async id => {
	//     try {
	//         await reopenRfep(abortController.signal, id);

	//         await onTileSave();
	//         notify(t('RFEP reopened successfully'), {
	//             type: toast.TYPE.SUCCESS,
	//         });
	//     } catch (e) {
	//         showToastError(e);
	//     }
	// };

	const handleRfepDelete = async id => {
		try {
			await deleteRfep(abortController.signal, id);
			await onTileSave();
			dispatch(updateCurrentProject({ ...project, tender: undefined }));
			notify(t('RFEP deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});
			setRfepIdForDeletion(null);
		} catch (e) {
			showToastError(e);
		}
	};

	const handleRfepSubmit = async response => {
		try {
			await onTileSave();
			if (response?.invitedPartners?.length > 0) {
				setShowRfepSentConfirm(true);
			}
		} catch (err) {
			showToastError(err);
		}
	};

	return (
		<EditableTile
			title={t('RFEP')}
			tooltip={<QuestionTooltip large tooltip={<RfepTooltipText />} />}
			rightComponent={
				project?.tender ? (
					<MoreActionsButton noBackground label="Projects Details Offers RFEP More Actions">
						<MenuItem
							disabled={!canCreateRfep}
							onClick={handleEditButtonClicked}
							type="button"
							data-action="edit"
							label="Projects Details Offers RFEP - Edit Menu Item"
						>
							{t('Edit')}
						</MenuItem>
						<MenuItem
							disabled={project?.tender?.state !== rfepStates.OPEN || !isAuthorizedToEditAndCreate}
							onClick={() => setRfepIdForClosing(project.tender.id)}
							type="button"
							data-action="close"
							label="Projects Details Offers RFEP - Close Menu Item"
						>
							{t('Close')}
						</MenuItem>
						<MenuItem
							disabled={project?.tender?.state !== rfepStates.CLOSED || !isAuthorizedToEditAndCreate}
							onClick={handleEditButtonClicked}
							type="button"
							data-action="reopen"
							label="Projects Details Offers RFEP - Reopen Menu Item"
						>
							{t('Reopen')}
						</MenuItem>
						<MenuItem
							disabled={project?.tender?.state !== rfepStates.DRAFT || !isAuthorizedToEditAndCreate}
							onClick={() => setRfepIdForDeletion(project.tender.id)}
							type="button"
							data-action="delete"
							label="Projects Details Offers RFEP - Delete Menu Item"
						>
							{t('Delete')}
						</MenuItem>
					</MoreActionsButton>
				) : (
					<HoverTooltip placement="left" title={createButtonTooltipText} arrow>
						<TileActionButton
							disabled={!canCreateRfep}
							data-button="rfep-create"
							onClick={handleCreateButtonClicked}
						/>
					</HoverTooltip>
				)
			}
		>
			<PillWrapper>
				{project.tender ? (
					<HoverTooltip placement="top" title={t(stateTitles[project.tender.state]) || ''} arrow>
						<StatePill color={project.tender.state}>{project.tender.state}</StatePill>
					</HoverTooltip>
				) : (
					<StatusPill color={colors.common.brown}>Not started</StatusPill>
				)}
			</PillWrapper>

			<RfepOverlay
				mode={crudModes.CREATE}
				key="create"
				isOpen={isCreateRfepModalOpened}
				onClose={() => setIsCreateRfepModalOpened(false)}
				project={project}
				onFormSubmit={handleRfepSubmit}
			/>
			<RfepOverlay
				mode={crudModes.EDIT}
				key="edit"
				isOpen={isEditRfepModalOpened}
				onClose={() => setIsEditRfepModalOpened(false)}
				project={project}
				onFormSubmit={handleRfepSubmit}
			/>
			<StyledConfirmModal
				isOpen={rfepIdForDeletion !== null}
				onCancel={() => setRfepIdForDeletion(null)}
				onConfirm={() => handleRfepDelete(rfepIdForDeletion)}
				label="delete-rfep-confirm"
				heading={t('Delete RFEP')}
				text={t('Are you sure you want to delete this RFEP?')}
			/>
			<StyledConfirmModal
				isOpen={rfepIdForClosing !== null}
				onCancel={() => setRfepIdForClosing(null)}
				onConfirm={() => handleRfepClose(rfepIdForClosing)}
				label="close-rfep-confirm"
				heading={t('Close RFEP')}
				text={
					<>
						{t(
							'If you close the RFEP, EPC Partners who have not yet responded will not be able to send their Offer.  These EPC Partners will be notified the RFEP is closed. ',
						)}

						{t('Are you sure you want to close this RFEP?')}
					</>
				}
			/>
			<UnsuccessfulOperationModal
				label="cannot-sent-rfep"
				isOpen={showRfepSentConfirm}
				onCancel={() => setShowRfepSentConfirm(false)}
				heading={t('RFEP Sent!')}
				text={t(
					"EPC Partners will receive an email notification and can send a proposal in their portal. Received proposals will show in the offer table as 'Offer received'.",
				)}
			/>
		</EditableTile>
	);
};

RfepTile.defaultProps = {
	project: {},
	onTileSave: async () => {},
};

RfepTile.propTypes = {
	project: PropTypes.shape({
		epcOffers: PropTypes.arrayOf(PropTypes.shape({})),
		salesChannel: PropTypes.shape({ name: PropTypes.string }),
		client: PropTypes.shape({}),
		clientContracts: PropTypes.arrayOf(PropTypes.shape({})),
		epcContracts: PropTypes.arrayOf(PropTypes.shape({})),
		tender: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	onTileSave: PropTypes.func,
};

export default RfepTile;
