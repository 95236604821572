export const energyProductionExplanation = {
	KWH_LEASE_NO_EFFECT_ON_INCOME: 'KWH_LEASE_NO_EFFECT_ON_INCOME',
	KWH_PPA: 'kWh_PPA',
	KWH_NOT_IN_OPERATION_NO_EFFECT_ON_INCOME: 'KWH_NOT_IN_OPERATION_NO_EFFECT_ON_INCOME',
	NO_YIELD_NOT_IN_OPERATION: 'NO_YIELD_NOT_IN_OPERATION',
	THERE_WERE_NO_MAJOR_CHALLENGES: 'THERE_WERE_NO_MAJOR_CHALLENGES',
};

export const energyProductionExplanationOptionns = [
	{
		label: 'kWh, lease, no effect on income',
		value: energyProductionExplanation.KWH_LEASE_NO_EFFECT_ON_INCOME,
	},
	{
		label: 'kWh, PPA',
		value: energyProductionExplanation.KWH_PPA,
	},
	{
		label: 'kWh, not in operation, no effect on income',
		value: energyProductionExplanation.KWH_NOT_IN_OPERATION_NO_EFFECT_ON_INCOME,
	},
	{
		label: 'No yield, not in operation',
		value: energyProductionExplanation.NO_YIELD_NOT_IN_OPERATION,
	},
	{
		label: 'There were no major challenges',
		value: energyProductionExplanation.THERE_WERE_NO_MAJOR_CHALLENGES,
	},
];
