import api from 'Application/api/api';

const getShareholderById = async (signal, shareholderId, details = true) => {
	const response = await api.get(`/shareholders/by-id/${shareholderId}`, {
		params: {
			details,
		},
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return response;
};

export default getShareholderById;
