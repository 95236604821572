import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';

import editStep from 'PMWorkflow/api/editStep';
import reorderSteps from 'PMWorkflow/api/reorderSteps';
import showToastError from 'Common/utils/showToastError';

const moveStep = async (
	signal,
	action,
	ids,
	values,
	currentIdIndex,
	step,
	steps,
	activity,
	activities,
	stages,
	stageIndex,
	setActivityState,
	onDataChange,
	t,
	setTemplateState,
	setActivityStepTemplates,
	updateActivityStepTemplates,
	setState,
) => {
	try {
		setState(prevState => ({ ...prevState, isStepMoving: true }));
		setActivityState(prevState => ({ ...prevState, isLoadingActivity: true }));
		let swapIndex, newIds;

		if (action === 'up') {
			if (currentIdIndex === 0) {
				return activities.map(
					(key, index) =>
						key.id === activity.id &&
						moveStepInOtherActivity(
							signal,
							activities[index - 1] ? activities[index - 1] : 'up',
							step,
							stages,
							stageIndex,
							values,
							setActivityState,
							onDataChange,
							t,
							setTemplateState,
							setActivityStepTemplates,
							updateActivityStepTemplates,
							setState,
						),
				);
			}

			swapIndex = currentIdIndex - 1;

			newIds = ids
				.slice(0, swapIndex)
				.concat(
					ids[currentIdIndex],
					ids.slice(currentIdIndex, currentIdIndex),
					ids[swapIndex],
					ids.slice(currentIdIndex + 1),
				);
		} else if (action === 'down') {
			if (currentIdIndex === steps.length - 1) {
				return activities.map(
					(key, index) =>
						key.id === activity.id &&
						moveStepInOtherActivity(
							signal,
							activities[index + 1] ? activities[index + 1] : 'down',
							step,
							stages,
							stageIndex,
							values,
							setActivityState,
							onDataChange,
							t,
							setTemplateState,
							setActivityStepTemplates,
							updateActivityStepTemplates,
							setState,
						),
				);
			}

			swapIndex = currentIdIndex + 1;

			newIds = ids
				.slice(0, currentIdIndex)
				.concat(ids[swapIndex], ids.slice(swapIndex, swapIndex), ids[currentIdIndex], ids.slice(swapIndex + 1));
		}

		await reorderSteps(signal, newIds);

		await setTemplateState();

		await onDataChange();

		await setActivityStepTemplates();

		setState(prevState => ({ ...prevState, isStepMoving: false }));
		setActivityState(prevState => ({ ...prevState, isLoadingActivity: false }));
	} catch (error) {
		showToastError(error);
	}
};

const moveStepInOtherActivity = async (
	signal,
	next,
	step,
	stages,
	stageIndex,
	values,
	setActivityState,
	onDataChange,
	t,
	setTemplateState,
	setActivityStepTemplates,
	updateActivityStepTemplates,
	setState,
) => {
	try {
		if (next === 'down') {
			return findNextStageWithActivity(
				signal,
				'down',
				step,
				stages,
				values,
				stageIndex,
				setActivityState,
				onDataChange,
				t,
				setTemplateState,
				setActivityStepTemplates,
				updateActivityStepTemplates,
				setState,
			);
		} else if (next === 'up') {
			return findNextStageWithActivity(
				signal,
				'up',
				step,
				stages,
				values,
				stageIndex,
				setActivityState,
				onDataChange,
				t,
				setTemplateState,
				setActivityStepTemplates,
				updateActivityStepTemplates,
				setState,
			);
		} else {
			const changes = {
				id: step.id,
				orderNumber: next.stepTemplates.length + 1,
				title: step.title,
				activityTemplateId: next.id,
				weight: step.weight,
				canBeSkipped: values?.canBeSkipped,
				description: values?.description,
				expectedDuration: values?.expectedDuration,
				completedValue: values?.completedValue?.value,
				countryIds: values?.countryIds?.map(item => item.value),
				projectTypeIds: values?.projectTypeIds?.map(item => item.value),
				contractTypeIds: values?.contractTypeIds?.map(item => item.value),
				calculations: values?.calculations.map(item => {
					return { option: item.value, displayName: item.label };
				}),
				responsibleTeam: {
					name: values?.responsibleTeam?.value,
					roleGroup: values?.responsibleTeam?.value,
				},
				forSystemSize: {
					value: values?.systemSize,
					operator: values?.lessOrGreater ? values?.lessOrGreater : null,
				},
			};

			await editStep(signal, {
				...changes,
			});

			await setTemplateState();

			await onDataChange();

			await setActivityStepTemplates();

			await updateActivityStepTemplates(next);

			setState(prevState => ({ ...prevState, isStepMoving: false }));
			setActivityState(prevState => ({ ...prevState, isLoadingActivity: false }));

			return notify(t(`Step ${values.title} has been moved to ${next.title}`), {
				type: toast.TYPE.SUCCESS,
			});
		}
	} catch (error) {
		showToastError(error);
	}
};

const findNextStageWithActivity = async (
	signal,
	direction,
	step,
	stages,
	values,
	stageIndex,
	setActivityState,
	onDataChange,
	t,
	setTemplateState,
	setActivityStepTemplates,
	updateActivityStepTemplates,
	setState,
) => {
	try {
		let next = null;
		let nextStage = null;
		if (direction === 'down') {
			for (let i = stageIndex + 1; i < stages.length; i++) {
				if (stages[i].activities.length > 0) {
					next = stages[i].activities[0];
					nextStage = stages[i];
					break;
				} else if ((i === stages.length - 1) & (stages[i].activities.length === 0)) {
					notify(t('Please expand the activity that you want to move the step.'), {
						type: toast.TYPE.INFO,
					});
				}
			}
		} else {
			for (let i = stageIndex - 1; i >= 0; i--) {
				if (stages[i].activities.length > 0) {
					next = stages[i].activities[stages[i].activities.length - 1];
					nextStage = stages[i];
					break;
				} else if ((i === 0) & (stages[i].activities.length === 0)) {
					notify(t('Please expand the activity that you want to move the step.'), {
						type: toast.TYPE.INFO,
					});
				}
			}
		}

		if (next !== null) {
			const changes = {
				id: step.id,
				orderNumber: next.stepTemplates.length + 1,
				title: step.title,
				activityTemplateId: next.id,
				weight: step.weight,
				canBeSkipped: values?.canBeSkipped,
				description: values?.description,
				expectedDuration: values?.expectedDuration,
				completedValue: values?.completedValue?.value,
				countryIds: values?.countryIds?.map(item => item.value),
				projectTypeIds: values?.projectTypeIds?.map(item => item.value),
				contractTypeIds: values?.contractTypeIds?.map(item => item.value),
				calculations: values?.calculations.map(item => {
					return { option: item.value, displayName: item.label };
				}),
				responsibleTeam: {
					name: values?.responsibleTeam?.value,
					roleGroup: values?.responsibleTeam?.value,
				},
				forSystemSize: {
					value: values?.systemSize,
					operator: values?.lessOrGreater ? values?.lessOrGreater : null,
				},
			};

			await editStep(signal, {
				...changes,
			});

			await setTemplateState();

			await onDataChange();

			await setActivityStepTemplates();

			await updateActivityStepTemplates(next, nextStage);

			setState(prevState => ({ ...prevState, isStepMoving: false }));
			setActivityState(prevState => ({ ...prevState, isLoadingActivity: false }));

			return notify(t(`Step ${values.title} has been moved to ${next.title}`), {
				type: toast.TYPE.SUCCESS,
			});
		}

		setState(prevState => ({ ...prevState, isStepMoving: false }));
		setActivityState(prevState => ({ ...prevState, isLoadingActivity: false }));
	} catch (error) {
		showToastError(error);
	}
};

export default moveStep;
