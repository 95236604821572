import { parseISO } from 'date-fns';

const parseDate = date => {
	if (date) {
		const isDate = date instanceof Date;
		if (isDate) {
			return date;
		}

		const isIsoDate = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/.test(date);
		if (isIsoDate) {
			return parseISO(date);
		}

		const isValidDate = !Number.isNaN(Date.parse(date));

		return isValidDate ? new Date(date) : undefined;
	}
	return undefined;
};

export default parseDate;
