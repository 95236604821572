import { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'Common/components/modals/Modal';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import Loader from 'Common/components/Loader';
import H1 from 'Common/components/headers/H1';
import InfoTile from 'Impact/tiles/ImpactInfoTile';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import showToastError from 'Common/utils/showToastError';
import getProjectDefaults from 'Preferences/api/getProjectDefaults';
import getTotals from 'Impact/api/getTotals';
import useAbortController from 'Common/hooks/useAbortController';
import getProjectById from 'Projects/api/getProjectById';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import formatNumber from 'Common/utils/formatNumber';
import Button from 'Common/components/buttons/Button';

const StyledModal = styled(Modal)`
	.ReactModal__Content {
		@media (min-width: 1000px) {
			min-width: 840px;
		}
	}
`;

const InfoTilesContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${sizes.spacing(2)};
`;

const ButtonsWrapper = styled.div`
	margin-top: 40px;
	display: flex;
	justify-content: center;

	> * {
		margin-right: 16px;

		&:last-child {
			margin-right: 0px;
		}
	}
`;

const RestrictedIndustriesWarningModal = ({ projectId, isOpen, isConfirming, onCancel, onConfirm }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [restrictedIndustriesData, setRestrictedIndustriesData] = useState({
		totalMWpOffers: 0,
		totalMWpRestrictedOffers: 0,
		allowedPercentageOfRestrictedIndustries: 0,
	});

	const abortController = useAbortController();

	const location = useLocation();

	const [fetchedProject, setFetchedProject] = useState({});
	const reduxProject = useSelector(state => state.currentProject);
	const isProjectDetailsPage = useMemo(
		() => location.pathname.includes('/projects/details'),
		[location.pathname],
	);

	const project = useMemo(
		() => (isProjectDetailsPage ? reduxProject : fetchedProject),
		[isProjectDetailsPage, reduxProject, fetchedProject],
	);

	const projectRestrictedIndustriesData = useMemo(() => {
		const adddedMWP = Number(formatNumber(project.systemSizeKwp / 1000, 2));

		const addedPercent = Number(
			formatNumber((project.systemSizeKwp / 1000 / restrictedIndustriesData.totalMWpOffers) * 100, 2),
		);

		return {
			adddedMWP,
			addedPercent,
		};
	}, [project, restrictedIndustriesData.totalMWpOffers]);

	const fetchProjectData = useCallback(async () => {
		if (!isProjectDetailsPage) {
			const { data } = await getProjectById(abortController.signal, projectId);
			setFetchedProject(data);
		}
	}, [isProjectDetailsPage, abortController.signal, projectId]);

	useEffect(() => {
		fetchProjectData();
	}, [fetchProjectData]);

	const fetchRestrictedIndustriesData = useCallback(async () => {
		try {
			setIsLoading(true);

			const {
				data: { allowedPercentageOfRestrictedIndustries },
			} = await getProjectDefaults(abortController.signal);
			const {
				data: { totalMWpOffers, totalMWpRestrictedOffers },
			} = await getTotals(abortController.signal);

			setRestrictedIndustriesData({
				totalMWpOffers,
				totalMWpRestrictedOffers,
				allowedPercentageOfRestrictedIndustries,
			});

			setIsLoading(false);
		} catch (e) {
			showToastError(e);
		}
	}, [abortController.signal]);

	useEffect(() => {
		fetchRestrictedIndustriesData();
	}, [fetchRestrictedIndustriesData]);

	const restrictedOffersData = useMemo(
		() => [
			{
				value: restrictedIndustriesData.totalMWpRestrictedOffers,
				unit: 'MWp',
				text: 'current',
				icon: 'assignment',
			},
			{
				value: projectRestrictedIndustriesData.adddedMWP,
				unit: 'MWp',
				text: 'added',
				prefix: '+',
			},
			{
				value: formatNumber(
					Number(restrictedIndustriesData.totalMWpRestrictedOffers) +
						Number(projectRestrictedIndustriesData.adddedMWP),
					2,
				),
				unit: 'MWp',
				icon: 'assignment',
				text: 'new',
			},
		],
		[restrictedIndustriesData.totalMWpRestrictedOffers, projectRestrictedIndustriesData.adddedMWP],
	);

	const mwpPercentRestrictedIndustriesData = useMemo(
		() => [
			{
				value: restrictedIndustriesData.totalMWpRestrictedOffers,
				unit: '%',
				icon: 'warehouse',
				text: 'current',
			},
			{
				value: projectRestrictedIndustriesData.addedPercent,
				unit: '%',
				text: 'added',
				prefix: '+',
			},
			{
				value: formatNumber(
					Number(restrictedIndustriesData.totalMWpRestrictedOffers) +
						Number(projectRestrictedIndustriesData.addedPercent),
					2,
				),
				unit: '%',
				icon: 'warehouse',
				text: 'new',
			},
		],
		[projectRestrictedIndustriesData.addedPercent, restrictedIndustriesData.totalMWpRestrictedOffers],
	);

	const allowedPercentRestrictedIndustriesData = useMemo(
		() => [
			{
				value: restrictedIndustriesData.allowedPercentageOfRestrictedIndustries,
				unit: '%',
				icon: 'warehouse',
			},
		],
		[restrictedIndustriesData.allowedPercentageOfRestrictedIndustries],
	);

	return (
		<StyledModal isOpen={isOpen} label={t('Restricted industries')} onConfirm={onConfirm} onCancel={onCancel}>
			<H1>{t('Restricted industries')}</H1>

			{isLoading ? (
				<LoaderWrapper>
					<Loader />
				</LoaderWrapper>
			) : (
				<InfoTilesContainer>
					<InfoTile
						title={t('MWP of restricted offers')}
						gradient={colors.gradients.radialDark}
						data={restrictedOffersData}
						$isLightbox
					/>
					<InfoTile
						title={t('MWP % restricted industries')}
						gradient={colors.gradients.radialLight}
						data={mwpPercentRestrictedIndustriesData}
						$isLightbox
					/>
					<InfoTile
						title={t('Allowed % restricted industries')}
						gradient={colors.gradients.radialLight}
						data={allowedPercentRestrictedIndustriesData}
						$isLightbox
					/>
				</InfoTilesContainer>
			)}

			<ButtonsWrapper>
				<Button
					secondary
					text={t('Cancel')}
					onClick={onCancel}
					label={`Restricted Industries Modal ${t('Cancel')} Button`}
				/>
				<Button
					overlay
					text={t('Confirm')}
					onClick={onConfirm}
					isLoading={isConfirming}
					className="confirmBtn"
					label={`Restricted Industries Modal ${t('Confirm')} Button`}
				/>
			</ButtonsWrapper>
		</StyledModal>
	);
};

RestrictedIndustriesWarningModal.propTypes = {
	projectId: PropTypes.string.isRequired,
	isConfirming: PropTypes.bool.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
};

export default RestrictedIndustriesWarningModal;
