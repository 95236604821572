import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

import logo from 'Common/assets/images/logo.svg';
import backgroundImage from 'Common/assets/images/auth-background.jpg';
import backgroundImageMobile from 'Common/assets/images/auth-background-mobile.jpg';
import { css } from 'styled-components';
import useReponsive from 'Common/hooks/useResponsive';

const StyledWrapper = styled.div`
	background: ${colors.grey.lightest};
	height: 100%;
`;

const LogoContainer = styled.div`
	text-align: center;
	padding: 48px 0;

	${({ $isMobile }) =>
		$isMobile &&
		css`
			padding: 12px 0px;
		`}

	img {
		display: inline-block;
		width: 200px;
	}
`;

const ContentWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: calc(100vh - 165px);
	background: url('${({ backgroundImage }) => backgroundImage}');
	background-size: cover;
	border-radius: 20px;
	margin: 0px 32px 32px 32px;
	padding: 200px;

	${({ $isMobile }) =>
		$isMobile &&
		css`
			margin: 8px;
			padding: 80px 8px;
			height: fit-content;
			height: calc(100vh - 100px);
		`}
`;

const Content = styled.div`
	width: 100%;
	max-width: 480px;
	min-height: 397px;
	padding: 32px;
	background: ${colors.common.white};
	border-radius: 20px;
`;

export const LoadingIcon = styled(CircularProgress)`
	color: ${colors.primary.main}!important;
`;

const Wrapper = ({ children }) => {
	const { isMobile } = useReponsive();

	return (
		<StyledWrapper>
			<LogoContainer $isMobile={isMobile}>
				<img src={logo} alt="Ecoligo" />
			</LogoContainer>
			<ContentWrapper $isMobile={isMobile} backgroundImage={isMobile ? backgroundImageMobile : backgroundImage}>
				<Content>{children}</Content>
			</ContentWrapper>
		</StyledWrapper>
	);
};

Wrapper.defaultProps = {
	children: null,
};

Wrapper.propTypes = {
	children: PropTypes.node,
};

export { Wrapper };
