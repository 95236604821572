export const closedReasons = {
	LOST_TO_COMPETITION: 'LOST_TO_COMPETITION',
	STRAIGHT_PURCHASE: 'STRAIGHT_PURCHASE',
	CLIENT_NOT_PURSUING_PROJECT_FURTHER: 'CLIENT_NOT_PURSUING_PROJECT_FURTHER',
	DISQUALIFIED_BY_ECOLIGO: 'DISQUALIFIED_BY_ECOLIGO',
};

export const closedReasonsOptions = [
	{
		label: 'Lost to Competition',
		value: closedReasons.LOST_TO_COMPETITION,
	},
	{
		label: 'Straight Purchase',
		value: closedReasons.STRAIGHT_PURCHASE,
	},
	{
		label: 'Client Not Pursuing Project further',
		value: closedReasons.CLIENT_NOT_PURSUING_PROJECT_FURTHER,
	},
	{
		label: 'Disqualified by ecoligo',
		value: closedReasons.DISQUALIFIED_BY_ECOLIGO,
	},
];
