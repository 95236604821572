import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';
import { Normalize } from 'styled-normalize';
import ReactGA from 'react-ga';

import App from 'Application/components/App';
import { history, persistor, store } from 'Application/store';

import logout from 'User/services/logout';
import ErrorBoundary from './ErrorBoundary';

const Root = () => {
	const [isGAInitialized, setIsGAInitialized] = useState(false);
	const currentVersion = String(process.env.REACT_APP_VERSION);
	const storedVersion = localStorage.getItem('lastUpdatedVersion');

	useEffect(() => {
		// !! Universal Analytics properties will stop collecting data starting 1 July 2023. It’s recommended that you create a Google Analytics 4 property instead.
		// !! react-ga is not compatible Google Analytics 4 for now. After they update react-ga to GA4, we need to updated.
		// !! react-ga4 is not collectiong data and cant be used for now.
		// !! react-4-ga is little bit different so for now we using this.
		ReactGA.initialize(process.env.REACT_APP_GA_UA);
		setIsGAInitialized(true);
	}, []);

	if (storedVersion !== null) {
		const currentMinorVersion = currentVersion?.split('.')?.slice(0, 2)?.join('.');
		const storedMinorVersion = storedVersion?.split('.')?.slice(0, 2)?.join('.');
		if (storedMinorVersion !== currentMinorVersion) {
			localStorage.clear();
			localStorage.setItem('lastUpdatedVersion', currentVersion);
			logout();
		}
	} else {
		localStorage.setItem('lastUpdatedVersion', currentVersion);
	}

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ErrorBoundary>
					<Router history={history}>
						<HelmetProvider>
							<Normalize />
							<App isGAInitialized={isGAInitialized} />
						</HelmetProvider>
					</Router>
				</ErrorBoundary>
			</PersistGate>
		</Provider>
	);
};

export default Root;
