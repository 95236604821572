import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { PropTypes } from 'prop-types';

import {
	categories,
	documentTableOptions,
	formattedCategories,
	formattedDocumentTypes,
} from 'Projects/constants/documentTableOptions';
import DocumentUploadWithApiCall from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/EPCOfferTile/DocumentsList/DocumentUploadWithApiCall';
import DocumentUpload from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/EPCOfferTile/DocumentsList/DocumentUpload';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import {
	DefaultTable,
	TableBodyCell,
	TableBodyGroupCell,
	TableHeadCell,
} from 'Common/components/table/table';
import colors from 'Application/theme/colors';
import { useCustomRowStyle } from 'Common/hooks/table/plugin-hooks/useCustomRowStyle';

const Wrapper = styled.div`
	position: relative;
	width: 100%;
`;

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell}:not(${TableBodyGroupCell}) {
		background: transparent;
	}

	${TableBodyCell} {
		height: auto;
		background: transparent;
	}
`;

const StyledImportBoxWithApiCall = styled(DocumentUploadWithApiCall)`
	min-height: 100%;
`;

const StyledImportBox = styled(DocumentUpload)`
	min-height: 100%;
`;

const Mandatory = styled.span`
	margin-left: 2px;
	color: ${colors.error.main};
	font-size: 17px;
	line-height: 17px;
`;

const SecondRow = styled.p`
	display: inline-block;
	margin-bottom: 8px;
`;

const FirstRow = styled.p`
	margin: 0px;
	margin-top: 4px;
	font-weight: bold;
`;

const DocumentsList = ({
	documentDefs,
	documents,
	offerId,
	onDataChange,
	uploadDocsApiCall,
	setDocsForUpload,
	docsForUpload,
	handleDeleteDoc,
	isReadOnly,
	idPropName,
	showHeader,
}) => {
	const { t } = useTranslation();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Document'),
				accessor: 'document',
				minWidth: 700,
			},
			{
				Header: t('Upload'),
				accessor: 'upload',
				minWidth: 300,
			},
		],
		[t],
	);

	const data = useMemo(
		() =>
			documentDefs.map(documentDefinition => ({
				bodyGroupIds: [documentDefinition.group],
				document: (
					<>
						<FirstRow>
							{formattedDocumentTypes[documentDefinition.documentTitle]}
							{documentDefinition.mandatory && <Mandatory title="Required" />}
						</FirstRow>
						<SecondRow>
							{documentTableOptions.find(doc => doc.documentTitle === documentDefinition.documentTitle)?.description}
						</SecondRow>
					</>
				),
				upload: uploadDocsApiCall ? (
					<StyledImportBoxWithApiCall
						slot={documentDefinition.documentTitle}
						offerId={offerId}
						uploadedDocs={documents.filter(doc => doc.slot === documentDefinition.documentTitle)}
						onDataChange={onDataChange}
						uploadDocsApiCall={uploadDocsApiCall}
						isReadOnly={isReadOnly}
						idPropName={idPropName}
					/>
				) : (
					<StyledImportBox
						slot={documentDefinition.documentTitle}
						offerId={offerId}
						uploadedDocs={documents.filter(doc => doc.slot === documentDefinition.documentTitle)}
						onDataChange={onDataChange}
						setDocsForUpload={setDocsForUpload}
						docsForUpload={docsForUpload}
						isReadOnly={isReadOnly}
						handleDeleteDoc={handleDeleteDoc}
						idPropName={idPropName}
					/>
				),
			})),
		[
			documentDefs,
			documents,
			offerId,
			onDataChange,
			uploadDocsApiCall,
			isReadOnly,
			setDocsForUpload,
			docsForUpload,
			idPropName,
			handleDeleteDoc,
		],
	);

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
				getCustomRowStyles: () => ({
					height: 'auto',
				}),
			},
			useCustomRowStyle,
		);

	return (
		<Wrapper>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					prepareRow={prepareRow}
					headerGroups={showHeader ? headerGroups : []}
					bodyGroups={[
						{
							id: categories.SCHEDULE_AND_OFFER,
							name: formattedCategories[categories.SCHEDULE_AND_OFFER],
						},
						{
							id: categories.TECHNICAL_DOCUMENTATION,
							name: formattedCategories[categories.TECHNICAL_DOCUMENTATION],
						},
					]}
				/>
			</TableWrapper>
		</Wrapper>
	);
};

DocumentsList.defaultProps = {
	offerId: null,
	setDocsForUpload: () => null,
	handleDeleteDoc: () => null,
	showHeader: true,
	docsForUpload: [],
	onDataChange: () => null,
	uploadDocsApiCall: () => null,
};

DocumentsList.propTypes = {
	documentDefs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	offerId: PropTypes.number,
	onDataChange: PropTypes.func,
	uploadDocsApiCall: PropTypes.func,
	setDocsForUpload: PropTypes.func,
	docsForUpload: PropTypes.arrayOf(PropTypes.shape({})),
	handleDeleteDoc: PropTypes.func,
	isReadOnly: PropTypes.bool.isRequired,
	idPropName: PropTypes.string.isRequired,
	showHeader: PropTypes.bool,
};

export default DocumentsList;
