import colors from 'Application/theme/colors';

export const rfepStates = {
	DRAFT: 'Draft',
	OPEN: 'Open',
	CLOSED: 'Closed',
	Awarded: 'Awarded',
};

export const stateColors = {
	[rfepStates.DRAFT]: colors.common.orange,
	[rfepStates.OPEN]: colors.secondary.dark,
	[rfepStates.CLOSED]: colors.warning.main,
	[rfepStates.Awarded]: colors.primary.dark,
};
