const CAMPAIGN_DETAILS_PAGE_TAB_TILES = {
	GENERAL: {
		OVERVIEW: 'overview',
		PROJECTS: 'projects',
		STATUS: 'status',
		MARKETING: 'marketing',
	},
};

const CAMPAIGN_DETAILS_PAGE_TILES_BY_TAB = {
	GENERAL: Object.values(CAMPAIGN_DETAILS_PAGE_TAB_TILES.GENERAL),
};

export { CAMPAIGN_DETAILS_PAGE_TAB_TILES, CAMPAIGN_DETAILS_PAGE_TILES_BY_TAB };
