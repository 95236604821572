import api from 'Application/api/api';

const addComment = async (signal, values) => {
	const endpoint = values.stepId ? 'steps' : 'projects';
	const response = await api.post(`/${endpoint}/add-comment`, values, {
		signal,
		timeout: 100000,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: response.data,
	};
};

export default addComment;
