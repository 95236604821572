import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import EntityDetailsTile from 'Entities/components/EntitiesDetails/Tabs/GeneralTab/Tiles/EntityDetailsTile/EntityDetailsTile';
import AddressTile from 'Entities/components/EntitiesDetails/Tabs/GeneralTab/Tiles/AddressTile/AddressTile';
import DirectorsTile from 'Entities/components/EntitiesDetails/Tabs/GeneralTab/Tiles/DirectorsTile/DirectorsTile';
import PartnersTile from 'Entities/components/EntitiesDetails/Tabs/GeneralTab/Tiles/PartnersTile/PartnersTile';
import { ENTITY_DETAILS_PAGE_TAB_TILES } from 'Entities/constants/entityTabForms';
import CommentsTile from './Tiles/CommentsTile/CommentsTile';

const GeneralTab = ({ entity, onEnterEditMode, onExitEditMode, onDataChange, onDirtyForm }) => {
	const handleGeneralTabSave = data => {
		onDataChange(data);
		onExitEditMode();
	};

	const handleDetailsTileDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(ENTITY_DETAILS_PAGE_TAB_TILES.GENERAL.DETAILS, isDirty);
		},
		[onDirtyForm],
	);

	const handleAddressTileDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(ENTITY_DETAILS_PAGE_TAB_TILES.GENERAL.ADDRESS, isDirty);
		},
		[onDirtyForm],
	);

	const handleDirectorsTileDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(ENTITY_DETAILS_PAGE_TAB_TILES.GENERAL.DIRECTORS, isDirty);
		},
		[onDirtyForm],
	);

	const handlePartnersTileDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(ENTITY_DETAILS_PAGE_TAB_TILES.GENERAL.PARTNERS, isDirty);
		},
		[onDirtyForm],
	);
	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<EntityDetailsTile
					entity={entity}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={handleGeneralTabSave}
					onDirtyForm={handleDetailsTileDirtyForm}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<AddressTile
					entity={entity}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={handleGeneralTabSave}
					onDirtyForm={handleAddressTileDirtyForm}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<DirectorsTile
					entity={entity}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={handleGeneralTabSave}
					onDirtyForm={handleDirectorsTileDirtyForm}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<PartnersTile
					entity={entity}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={handleGeneralTabSave}
					onDirtyForm={handlePartnersTileDirtyForm}
				/>
			</Grid>
			{entity?.comments?.length > 0 && (
				<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
					<CommentsTile
						entity={entity}
						onEnterEditMode={onEnterEditMode}
						onExitEditMode={onExitEditMode}
						onSave={handleGeneralTabSave}
						onDirtyForm={handlePartnersTileDirtyForm}
					/>
				</Grid>
			)}
		</TilesGridWrapper>
	);
};

GeneralTab.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
};

GeneralTab.propTypes = {
	entity: PropTypes.shape([]).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDataChange: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
};

export default GeneralTab;
