import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Prompt from 'Common/components/Prompt';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import Big from 'Common/utils/customBig';
import * as yup from 'yup';
import { PropTypes } from 'prop-types';

import makeBiddingOnOffer from 'EpcPartner/api/makeBiddingOnOffer';
import BiddingForm from 'EpcPartner/components/EpcPartnerPortal/EpcOfferDetails/Tabs/OffersTab/OffersFormTile/BiddingForm';
import ProposalForm from 'EpcPartner/components/EpcPartnerPortal/EpcOfferDetails/Tabs/OffersTab/OffersFormTile/ProposalForm';

import colors from 'Application/theme/colors';

import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';

import EditableTile from 'Common/components/Tile/EditableTile';
import YellowMessage from 'Common/components/YellowMessage';
import formatDateForRequestData from 'Common/utils/formatDateForRequestData';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import projectTypes from 'Projects/constants/projectTypes';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`;

const BiddingBlock = styled.div`
	min-width: 600px;
`;

const ProposalBlock = styled.div`
	min-width: 300px;
`;

const OffersFormTile = ({
	defaultValues,
	onSubmit,
	epcOffer,
	onEnterEditMode,
	onExitEditMode,
	onDirtyForm,
}) => {
	const { t } = useTranslation();
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [isInEditMode, setIsInEditMode] = useState(false);
	const [currencyOption, setCurrencyOption] = useState(null);
	const abortController = useAbortController();

	const currentUser = useSelector(state => state.user);
	// // Set the default currency to EUR
	useEffect(() => {
		(async () => {
			try {
				let currencies;
				if (defaultValues?.currency) {
					currencies = await loadCurrenciesOptions(abortController.signal, defaultValues.currency, false);
					setCurrencyOption(currencies.find(e => e.value === defaultValues.currency));
				} else {
					currencies = await loadCurrenciesOptions(abortController.signal, 'eur', false);
					setCurrencyOption(currencies.find(e => e.value === 'EUR'));
				}
			} catch (e) {
				showToastError(e, t("Can't set default currency"));
			}
		})();
	}, [defaultValues.currency, abortController.signal, t]);

	const initialValues = useMemo(
		() => ({
			...defaultValues,
			currency: currencyOption,
		}),
		[defaultValues, currencyOption],
	);

	const validationSchema = useMemo(
		() =>
			yup.object({
				systemSize: yup.mixed().when('projectType', {
					is: projectTypes.PV,
					then: yup
						.number()
						.positive(t('Must be positive'))
						.test(
							'decimal-places',
							t('Must have no more than 2 decimal places'),
							value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
						)
						.typeError(t('Must be a number'))
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						)
						.nullable()
						.required(t('Required')),
					otherwise: null,
				}),
				yield: yup.mixed().when('projectType', {
					is: projectTypes.PV,
					then: yup
						.number()
						.required(t('Yield is Required'))
						.positive(t('Yield must be positive'))
						.test(
							'decimal-places',
							t('Yield must have no more than 2 decimal places'),
							value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
						)
						.test(
							'yield-range',
							t('Yield must be between 500 and 3000'),
							value => value === undefined || (value <= 3000 && value >= 500),
						)
						.typeError(t('Yield must be a number'))
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						)
						.nullable(),

					otherwise: null,
				}),
				epcVolumeExclVat: yup
					.number()
					.positive(t('EPC volume must be positive'))
					.typeError(t('EPC volume must be a number'))
					.test(
						'decimal-places',
						'EPC volume must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable()
					.required(t('EPC volume is Required')),
				epcVolumeExclVatKwp: yup.mixed().when('projectType', {
					is: projectTypes.PV,
					then: yup
						.number()
						.typeError(t('Must be a number'))
						.positive(t('Must be positive, check System size.'))
						.test(
							'decimal-places',
							'Must have no more than 2 decimal places',
							value => value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
						)
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						)
						.nullable()
						.required(t('Required')),
					otherwise: null,
				}),
				currency: yup
					.object()
					.shape({ label: yup.string(), value: yup.string() })
					.nullable()
					.required(t('Required')),
				annualOmPrice: yup
					.number()
					.typeError(t('Must be a number'))
					.positive(t('Must be positive, check System size.'))
					.nullable()
					.required(t('Required'))
					.test('decimal-places', 'Must have no more than 2 decimal places', value =>
						String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					),
				annualOmPriceKwp: yup.mixed().when('projectType', {
					is: projectTypes.PV,
					then: yup
						.number()
						.typeError(t('Must be a number'))
						.positive(t('Must be positive, check System size.'))
						.nullable()
						.required(t('Required'))
						.test('decimal-places', 'Must have no more than 2 decimal places', value =>
							String(value).match(/^\d*(\.\d{1,2})?$/),
						)
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						),
					otherwise: null,
				}),
				annualOmEscalationRate: yup
					.number()
					.typeError(t('Must be a number'))
					.min(0, t('Must be more than or equal to 0'))
					.max(100, t('Must be less than or equals to 100'))
					.test(
						'decimal-places',
						'Must have no more than 2 decimal places',
						value => value === undefined || value === null || String(value).match(/^\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.nullable()
					.required(t('Required')),
				solarUtilisationRate: yup.mixed().when('projectType', {
					is: value => value === projectTypes.PV,
					then: yup
						.number()
						.positive(t('Solar utilization rate must be positive'))
						.test(
							'decimal-places',
							t('Solar utilization rate must have no more than 2 decimal places'),
							value => value === null || value === undefined || String(value).match(/^\d*(\.\d{1,2})?$/),
						)
						.typeError(t('Solar utilization rate must be a number'))
						.transform((val, originalValue) =>
							typeof originalValue === 'string' && originalValue === '' ? null : val,
						)
						.max(100, 'Solar utilization rate must be less than or equals to 100')
						.nullable()
						.required(t('Solar utilization rate is Required')),
					otherwise: null,
				}),
				gensetsInfo: yup.mixed().when('requestGensetQuote', {
					is: true,
					then: yup.string().required(t('Gensets is Required')).nullable(),
					otherwise: null,
				}),
				offerValidityDate: yup.mixed().when('requestOfferValidity', {
					is: true,
					then: yup.date().nullable().required(t('Validity date is Required')),
					otherwise: null,
				}),
			}),
		[t],
	);

	const {
		errors,
		touched,
		values,
		handleChange,
		handleBlur,
		handleSubmit,
		dirty,
		setFieldValue,
		resetForm,
		validateField,
		setFieldError,
		setFieldTouched,
	} = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			setIsSavingChanges(true);
			let response;
			try {
				response = await makeBiddingOnOffer(abortController.signal, {
					...epcOffer,
					...values,
					systemSize: defaultValues.projectType === projectTypes.PV ? values.systemSize : null,
					currency: values?.currency?.value,
					epcVolumeExclVat: {
						value: String(values.epcVolumeExclVat).replace(/\s/g, ''),
						currency: values?.currency?.value,
						calculated: !!values.epcVolumeSwitch,
					},
					epcVolumeExclVatKwp:
						defaultValues.projectType === projectTypes.PV
							? {
									value: String(values.epcVolumeExclVatKwp).replace(/\s/g, ''),
									currency: values?.currency?.value,
									calculated: !values.epcVolumeSwitch,
							  }
							: null,
					annualOmPrice: {
						value: String(values.annualOmPrice).replace(/\s/g, ''),
						currency: values?.currency?.value,
						calculated: !!values.omPriceSwitch,
					},
					annualOmPriceKwp:
						defaultValues.projectType === projectTypes.PV
							? {
									value: String(values.annualOmPriceKwp).replace(/\s/g, ''),
									currency: values?.currency?.value,
									calculated: !values.omPriceSwitch,
							  }
							: null,
					annualOmEscalationRate: Number(Big(values?.annualOmEscalationRate).div(100).toString()),
					solarUtilisationRate:
						defaultValues.projectType === projectTypes.PV
							? Number(Big(values?.solarUtilisationRate).div(100).toString())
							: null,
					yield: defaultValues.projectType === projectTypes.PV ? values.yield : undefined,
					validityDate: values?.offerValidityDate ? formatDateForRequestData(values?.offerValidityDate) : null,
					offerId: epcOffer.id,
				});
				onSubmit({
					...response.data,
					annualOmEscalationRate:
						response.data?.annualOmEscalationRate &&
						Number(Big(response.data?.annualOmEscalationRate).times(100).toString()),
				});
				setIsSavingChanges(false);

				setIsInEditMode(false);
				onDirtyForm('details', false);
				resetForm({ values });
				handleCancel();
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setIsSavingChanges(false);
				}
			}
		},
	});

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode) {
			handleEditButtonClick();
		}
	};

	const isReadOnly = epcOffer.status !== 'OPEN';

	return (
		<>
			<EditableTile
				title={t('Offfers')}
				isLoading={isSavingChanges}
				isInEditMode={isInEditMode}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
				onClick={isReadOnly ? null : handleTileClick}
			>
				{Boolean(defaultValues.reasonForChange) && Boolean(defaultValues.userResponded) && (
					<YellowMessage
						title="Message from ecoligo"
						firstName={defaultValues.userResponded.firstName}
						lastName={defaultValues.userResponded.lastName}
						message={'Reason for change \r\n' + defaultValues.reasonForChange}
						secondMessage={'Changes made \r\n' + defaultValues.changesMade.split('<br>').join('\r\n')}
						date={new Date(defaultValues.dateResponded)}
						initialsColor={
							defaultValues.userResponded.email !== currentUser.email ? colors.primary.main : colors.primary.dark
						}
						color={colors.common.warningYellow}
					/>
				)}
				<Prompt message="" when={dirty} />
				<Form>
					<BiddingBlock>
						<BiddingForm
							errors={errors}
							values={values}
							touched={touched}
							handleChange={handleChange}
							handleBlur={handleBlur}
							initialValues={initialValues}
							setFieldValue={setFieldValue}
							projectType={defaultValues.projectType}
							showYieldField={defaultValues.projectType === projectTypes.PV}
							showSolarUtilisationRateField={defaultValues.projectType === projectTypes.PV}
							validateField={validateField}
							setFieldError={setFieldError}
							setFieldTouched={setFieldTouched}
							context="EPC_PORTAL_OFFER"
							isTile
							isInEditMode={isInEditMode}
						/>
					</BiddingBlock>
					<ProposalBlock>
						<ProposalForm
							errors={errors}
							values={values}
							touched={touched}
							handleChange={handleChange}
							handleBlur={handleBlur}
							initialValues={initialValues}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							showGensetsField={epcOffer.requestGensetQuote}
							showValidityDateField={epcOffer.requestOfferValidity}
							isValidityDateRequired={epcOffer.requestOfferValidity}
							isGensetsRequierd={epcOffer.requestGensetQuote}
							isTile
							isInEditMode={isInEditMode}
						/>
					</ProposalBlock>
				</Form>
			</EditableTile>
		</>
	);
};

OffersFormTile.defaultProps = {
	onEnterEditMode: () => {},
	onExitEditMode: () => {},
};

OffersFormTile.propTypes = {
	defaultValues: PropTypes.shape({
		userResponded: PropTypes.shape({
			firstName: PropTypes.string,
			lastName: PropTypes.string,
			email: PropTypes.string.isRequired,
		}),
		currency: PropTypes.string.isRequired,
		projectType: PropTypes.string.isRequired,
		reasonForChange: PropTypes.string,
		changesMade: PropTypes.arrayOf(PropTypes.shape({})),
		dateResponded: PropTypes.string,
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
	epcOffer: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		status: PropTypes.string.isRequired,
		requestGensetQuote: PropTypes.bool.isRequired,
		requestOfferValidity: PropTypes.bool.isRequired,
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func.isRequired,
};

export default OffersFormTile;
