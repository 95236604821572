import showToastError from 'Common/utils/showToastError';
import getInvoiceTemplates from 'Invoice/api/getInvoiceTemplates';

const loadInvoiceTemplateOptions = async (countriesIds, signal, fileName) => {
	try {
		const { data: invoiceTemplates } = await getInvoiceTemplates(signal, countriesIds, fileName);

		return invoiceTemplates.map(({ id, fileName }) => ({
			label: fileName,
			value: id,
		}));
	} catch (error) {
		showToastError(error);
	}
	return [];
};

export default loadInvoiceTemplateOptions;
