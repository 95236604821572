import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from 'Application/theme/colors';

const TextWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	color: ${colors.text.primary};
	margin-bottom: 5px !important;
`;

const OuterElement = styled.div`
	width: 100%;
	height: 13px;
	background-color: ${colors.grey.lightest};
	border-radius: 25px;
	border: 1px solid ${colors.grey.main};
	display: flex;
`;

const InnerElement = styled.div`
	height: 100%;
	background-color: ${colors.primary.dark};
	border-radius: 25px;
`;

const Text = styled.div`
	font-weight: 600;
	font-size: 16px;
`;

const ProgressBar = ({ step, totalSteps, stepText, totalText }) => {
	const ratio = ((step / totalSteps) * 100).toFixed(4);

	return (
		<>
			<TextWrapper>
				<Text>{stepText}</Text>
				<Text>{totalText}</Text>
			</TextWrapper>

			<OuterElement>
				<InnerElement style={{ width: `${ratio <= 100 ? ratio : 100}%` }} />
			</OuterElement>
		</>
	);
};

ProgressBar.defaultProps = {
	step: 0,
	totalSteps: 1,
};

ProgressBar.propTypes = {
	step: PropTypes.number,
	totalSteps: PropTypes.number,
	stepText: PropTypes.string.isRequired,
	totalText: PropTypes.string.isRequired,
};

export default ProgressBar;
