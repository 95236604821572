import styled from 'styled-components/macro';
import { css } from 'styled-components';

const LoaderWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 200px;

	${({ $isFullPage }) =>
		$isFullPage &&
		css`
			position: fixed;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		`}
`;

export default LoaderWrapper;
