import { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import { useCallback } from 'react';
import { PROJECT_DETAILS_PAGE_TAB_TILES } from 'Projects/constants/projectTabForms';
import MeteocontrolTile from './Tiles/MeteocontrolTile/MeteocontrolTile';
import MeasurementsTile from './Tiles/MeasurementsTile/MeasurementsTile';

const MeteocontrolTab = ({ project, onEnterEditMode, onExitEditMode, onDirtyForm, handleUpdateProject }) => {
	const [meteoSystems, setMeteoSystems] = useState([]);

	const handleMeteocontrolDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(PROJECT_DETAILS_PAGE_TAB_TILES.METEOCONTROL.METEOCONTROL, isDirty);
		},
		[onDirtyForm],
	);

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={9} md={6} lg={4}>
				<MeteocontrolTile
					project={project}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onDirtyForm={handleMeteocontrolDirtyForm}
					onTileSave={handleUpdateProject}
					setMeteotabSystems={setMeteoSystems}
					meteoSystems={meteoSystems}
				/>
			</Grid>
			<Grid item xs={12}>
				<MeasurementsTile project={project} meteoSystems={meteoSystems} />
			</Grid>
		</TilesGridWrapper>
	);
};

MeteocontrolTab.defaultProps = {
	project: {
		id: '',
		meteocontrolKeys: [],
	},
	onEnterEditMode: null,
	onExitEditMode: null,
	onDirtyForm: null,
	handleUpdateProject: null,
};

MeteocontrolTab.propTypes = {
	project: PropTypes.shape({
		id: PropTypes.number,
		meteocontrolKeys: PropTypes.arrayOf(PropTypes.string),
	}),
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	handleUpdateProject: PropTypes.func,
};

export default MeteocontrolTab;
