import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';
import { isInteger, isNumber } from 'lodash';

const useCapitalTileFormValidationSchema = () => {
	const { t } = useTranslation();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(
		() =>
			yup.object({
				shareCapital: yup
					.number()
					.typeError(t('Share Capital must be a number'))
					.min(0, t('Number of shares must be positive'))
					.required(t('Share Capital is required')),
				numberOfShares: yup
					.number()
					.typeError(t('Number of shares must be a number'))
					.test(
						'isInteger',
						t('Number of shares must be an integer'),
						value => !isNumber(value) || isInteger(value),
					)
					.min(0, t('Number of shares must be positive')),
			}),
		[t],
	);
};

export default useCapitalTileFormValidationSchema;
