import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components/macro';
import Icon from 'Common/components/icons/Icon';
import { useTranslation } from 'react-i18next';
import Loader from 'Common/components/Loader';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import colors from 'Application/theme/colors';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { PropTypes } from 'prop-types';

const docIconMapping = {
	jpg: <Icon icon="insertDriveFile" />,
	jpeg: <Icon icon="insertDriveFile" />,
	png: <Icon icon="insertDriveFile" />,
	bmp: <Icon icon="insertDriveFile" />,
	gif: <Icon icon="insertDriveFile" />,
	pdf: <Icon icon="pictureAsPdf" />,
	docx: <Icon icon="insertDriveFile" />,
	xlsx: <Icon icon="insertDriveFile" />,
	csv: <Icon icon="insertDriveFile" />,
	kmz: <Icon icon="insertDriveFile" />,
};

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	outline: none;
	justify-content: center;
	align-items: ${props => props.isDragActive && 'center'};
	border: ${props => props.isDragActive && '2px dashed #999999'};
	flex-direction: column;
	border-radius: 10px;
	color: ${colors.text.primary};
	padding: ${props => (props.isDragActive ? '0' : '2px')};
	p {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	button {
		margin-top: 20px;
	}
`;

const UploadTextWrapper = styled.div`
	display: flex;
	align-items: center;
	color: ${colors.text.primary};
	padding: 10px 0;
	cursor: pointer;
`;

const UploadIcon = styled(Icon)`
	margin-right: 10px;
`;

const DocsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	opacity: ${props => props.isDragActive && '0'};
`;

const DocEntryWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 3px 0;
	.nameWrapper,
	.actionsWrapper {
		display: flex;
		align-items: center;
		margin-right: 30px;
		& svg {
			margin-right: 6px;
			cursor: pointer;
		}
		& :last-child {
			margin-right: 0;
		}
	}
`;

const extensionsRegex = /(?:\.([^.]+))?$/;

const DocumentUploadWithApiCall = ({
	slot,
	offerId,
	uploadedDocs,
	onDataChange,
	uploadDocsApiCall,
	idPropName,
	isReadOnly,
	...props
}) => {
	const { t } = useTranslation();

	const [isImporting, setIsImporting] = useState(false);

	const [docOfferForDeletion, setDocForDeletion] = useState(null);

	const abortController = useAbortController();

	const onDrop = useCallback(
		acceptedFiles => {
			(async () => {
				if (acceptedFiles.length > 0) {
					setIsImporting(true);

					const formData = new FormData();
					formData.append(slot, acceptedFiles[0]);

					const uploadData = new Blob(
						[
							JSON.stringify({
								[idPropName]: offerId,
								docsToDelete: [],
							}),
						],
						{
							type: 'application/json',
						},
					);

					formData.append('uploadDocs', uploadData);

					try {
						const response = await uploadDocsApiCall(abortController.signal, formData);
						onDataChange({
							documents: response.data.documents,
						});
						setIsImporting(false);
					} catch (error) {
						showToastError(error);
						if (!axios.isCancel(error)) {
							setIsImporting(false);
						}
					}
				} else {
					notify(t('Invalid file format'), {
						type: toast.TYPE.ERROR,
					});
				}
			})();
		},
		[slot, offerId, onDataChange, uploadDocsApiCall, t, idPropName, abortController.signal],
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: '.jpeg, .jpg, .gif, .png, .xlsx, .pdf, .kmz, .bmp, .docx, .csv',
	});

	const handleDeleteDocument = async docId => {
		const formData = new FormData();

		const uploadData = new Blob(
			[
				JSON.stringify({
					[idPropName]: offerId,
					docsToDelete: [docId],
				}),
			],
			{
				type: 'application/json',
			},
		);

		formData.append('uploadDocs', uploadData);

		try {
			const response = await uploadDocsApiCall(abortController.signal, formData);
			onDataChange({ documents: response.data.documents });
			setDocForDeletion(null);
		} catch (error) {
			showToastError(error);
		}
	};

	if (isImporting) {
		return (
			<Wrapper {...props}>
				<Loader />
			</Wrapper>
		);
	}

	const handleDeleteButtonClick = (event, id, name) => {
		event.stopPropagation();
		setDocForDeletion({ id, name });
	};

	const handleDownloadDoc = (event, url) => {
		event.stopPropagation();
		window.open(url, '_blank');
	};

	return (
		<>
			<Wrapper
				{...getRootProps({
					onDrop: event => isReadOnly && event.stopPropagation(),
				})}
				{...props}
				isDragActive={isReadOnly ? false : isDragActive}
			>
				<input {...getInputProps()} disabled={isReadOnly} />

				<DocsWrapper isDragActive={isDragActive} onClick={e => e.stopPropagation()}>
					{uploadedDocs.length > 0 &&
						uploadedDocs.map(e => (
							<DocEntryWrapper key={e}>
								<div className="nameWrapper">
									{docIconMapping[e.name.match(extensionsRegex)[1]]}
									<span>{e.name}</span>
								</div>
								<div className="actionsWrapper">
									<Icon icon="cloudDownload" onClick={event => handleDownloadDoc(event, e.url)} />
									{!isReadOnly && (
										<Icon icon="delete" onClick={event => handleDeleteButtonClick(event, e.id, e.name)} />
									)}
								</div>
							</DocEntryWrapper>
						))}
				</DocsWrapper>
				{!isReadOnly && (
					<UploadTextWrapper isDragActive={isDragActive}>
						<UploadIcon icon="cloudUploadRounded" />

						<span>{!isDragActive ? 'Upload' : t('Drop your document here')}</span>
					</UploadTextWrapper>
				)}
			</Wrapper>
			{docOfferForDeletion && (
				<ConfirmModal
					isOpen={docOfferForDeletion !== null}
					onCancel={() => setDocForDeletion(null)}
					onConfirm={() => handleDeleteDocument(docOfferForDeletion.id)}
					label="delete-document-upload-with-api-call"
					heading={t('Delete Document')}
					text={t(`Are you sure you want to delete ${docOfferForDeletion.name}?`)}
				/>
			)}
		</>
	);
};

DocumentUploadWithApiCall.defaultProps = {
	offerId: null,
};

DocumentUploadWithApiCall.propTypes = {
	slot: PropTypes.string.isRequired,
	offerId: PropTypes.number,
	uploadedDocs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	onDataChange: PropTypes.func.isRequired,
	uploadDocsApiCall: PropTypes.func.isRequired,
	idPropName: PropTypes.string.isRequired,
	isReadOnly: PropTypes.bool.isRequired,
};

export default DocumentUploadWithApiCall;
