import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import Button from 'Common/components/buttons/Button';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import api from 'Application/api/api';
import showToastPending from 'Common/utils/showToastPending';

const Wrapper = styled.div`
	position: relative;
`;

const NightlyMaintenanceDescription = styled.p`
	margin: 0;
	margin-bottom: 20px;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	max-width: fit-content;
`;

const NightlyMaintenance = () => {
	const { t } = useTranslation();
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [cronJob, setCronJob] = useState({});

	const openModal = cronData => {
		setIsOpenModal(true);
		setCronJob(cronData);
	};

	const closeModal = () => {
		setIsOpenModal(false);
	};

	const handlePopupConfirm = () => {
		const abortController = new AbortController();

		const { endpoints, label } = cronJob;
		const requests = Promise.all(
			endpoints.map(endpoint => api.get(endpoint, { timeout: 0, signal: abortController.signal })),
		);

		showToastPending(
			requests,
			() => abortController.abort(),
			t(`Cron job ${label} pending`),
			t(`Cron job ${label} successful`),
			t(`Cron job ${label} failed`),
		);
		closeModal();
	};

	const commonDescription = 'Are you sure you want to run this job? It may take a while.';
	const cronBtnsData = [
		// {
		//     label: 'CrowdDesk Sync',
		//     endpoints: [],
		//     description:
		//         'The CrowdDesk data download is very resource intensive for both CrowdDesk and pulse, and may take up to 30 minutes to complete.  It should only be run if absolutely necessary. Are you sure you want to run this job?.',
		// },
		{
			label: 'Statistics & CO2',
			endpoints: ['/finances/calculate-totals'],
			description: commonDescription,
		},
		{
			label: 'RFEP Tender Expiry',
			endpoints: ['/tenders/close-expired-tenders'],
			description: commonDescription,
		},
		{
			label: 'EPC Tiers & Points',
			endpoints: ['/epcpartners/update-tiers-and-total-points'],
			description: commonDescription,
		},
		{
			label: 'Project Statistics',
			endpoints: [
				'/olap/client-employee-dimension',
				'/olap/finance-investment-dimension',
				'/olap/project-dimension',
				'/olap/step-calculation-dimension',
			],
			description: commonDescription,
		},
		{
			label: 'Meteocontrol',
			endpoints: ['/meteocontrol/run-job'],
			description: commonDescription,
		},
		{
			label: 'Invoices',
			endpoints: ['/invoices/trigger-automated-creation'],
			description: commonDescription,
		},
		{
			label: 'Payouts',
			endpoints: ['/finances/calc-returnOn-invested'],
			description: commonDescription,
		},
	];

	return (
		<Wrapper>
			<NightlyMaintenanceDescription>{t('Run the nightly CRON jobs on demand')}</NightlyMaintenanceDescription>
			<ButtonsWrapper>
				{cronBtnsData.map(cron => (
					<Button
						text={t(cron.label)}
						onClick={() => openModal(cron)}
						key={`cron-${cron.label}`}
						label={`Preferences - Run the nightly CRON jobs on Demand - ${t(cron.label)} Button`}
					/>
				))}
			</ButtonsWrapper>
			<ConfirmModal
				label="run-the-nightly-cron-jobs-on-demand"
				isOpen={isOpenModal}
				onCancel={closeModal}
				onConfirm={() => handlePopupConfirm()}
				heading={t(`Run ${t(cronJob.label)}?`)}
				text={cronJob.description}
			/>
		</Wrapper>
	);
};

export default NightlyMaintenance;
