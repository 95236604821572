import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import OverviewTile from 'Campaigns/components/CampaignDetails/Tabs/GeneralTab/Tiles/OverviewTile/OverviewTile';
import StatusTile from 'Campaigns/components/CampaignDetails/Tabs/GeneralTab/Tiles/StatusTile/StatusTile';
import MarketingTile from 'Campaigns/components/CampaignDetails/Tabs/GeneralTab/Tiles/MarketingTile/MarketingTile';
import ProjectsTile from 'Campaigns/components/CampaignDetails/Tabs/GeneralTab/Tiles/ProjectsTile/ProjectsTile';
import { CAMPAIGN_DETAILS_PAGE_TAB_TILES } from 'Campaigns/constants/campaignTabForms';

const GeneralTab = ({ campaign, onEnterEditMode, onExitEditMode, onDataChange, onDirtyForm }) => {
	const handleStatusChange = data => {
		onDataChange({
			status: data.status,
			tranches: data.tranches,
		});
	};

	const handleOverviewDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(CAMPAIGN_DETAILS_PAGE_TAB_TILES.GENERAL.OVERVIEW, isDirty);
		},
		[onDirtyForm],
	);

	const handleProjectsDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(CAMPAIGN_DETAILS_PAGE_TAB_TILES.GENERAL.PROJECTS, isDirty);
		},
		[onDirtyForm],
	);

	const handleStatusDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(CAMPAIGN_DETAILS_PAGE_TAB_TILES.GENERAL.STATUS, isDirty);
		},
		[onDirtyForm],
	);

	const handleMarketingDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(CAMPAIGN_DETAILS_PAGE_TAB_TILES.GENERAL.MARKETING, isDirty);
		},
		[onDirtyForm],
	);

	return (
		<TilesGridWrapper>
			<Grid data-section="campaigns" item xs={12} sm={12} md={6} lg={4} xl={3}>
				<OverviewTile
					campaign={campaign}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={onDataChange}
					onStatusChange={handleStatusChange}
					onDirtyForm={handleOverviewDirtyForm}
				/>
			</Grid>
			<Grid data-section="projects" item xs={12} sm={12} md={6} lg={4} xl={3}>
				<ProjectsTile
					campaign={campaign}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={onDataChange}
					onDirtyForm={handleProjectsDirtyForm}
				/>
			</Grid>
			<Grid data-section="portagonStatus" item xs={12} sm={12} md={6} lg={4} xl={3}>
				<StatusTile
					campaign={campaign}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={onDataChange}
					onDirtyForm={handleStatusDirtyForm}
				/>
			</Grid>
			<Grid data-section="marketing" item xs={12} sm={12} md={6} lg={4} xl={3}>
				<MarketingTile
					campaign={campaign}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={onDataChange}
					onDirtyForm={handleMarketingDirtyForm}
				/>
			</Grid>
		</TilesGridWrapper>
	);
};

GeneralTab.defaultProps = {
	onEnterEditMode: () => null,
	onExitEditMode: () => null,
	onDirtyForm: () => null,
};

GeneralTab.propTypes = {
	campaign: PropTypes.shape({}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDataChange: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
};

export default GeneralTab;
