import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ContentLoader from 'react-content-loader';
import Grid from '@mui/material/Grid';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import ProjectInfo from 'Projects/components/shared/ProjectInfo';

import colors from 'Application/theme/colors';
import { css } from 'styled-components';
import getProjectById from 'Projects/api/getProjectById';
import showToastError from 'Common/utils/showToastError';
import { useTranslation } from 'react-i18next';
import useAbortController from 'Common/hooks/useAbortController';

const Wrapper = styled(Grid)`
	display: flex;
	position: absolute;
	${({ $position }) =>
		$position === 'bottom'
			? css`
					top: 99%;
			  `
			: css`
					bottom: 99%;
			  `};
	left: -20px;
	padding: 16px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	border-radius: 20px;
	background-color: ${colors.common.white};
	z-index: 1001;
	max-width: 776px;
	width: fit-content;

	${({ $isMapInfo }) =>
		$isMapInfo &&
		css`
			position: relative;
			top: 0;
			left: 0;
			box-shadow: none;
			border-radius: 0;
			padding: 0;
		`};

	${({ $isInTable }) =>
		$isInTable &&
		css`
			left: 60px;
		`}
	${({ $isInTable, $position }) =>
		$isInTable && $position === 'bottom'
			? css`
					top: auto;
					margin-top: 38.5px;
			  `
			: css`
					bottom: auto;
					margin-bottom: 38.5px;
			  `};
`;

const ProjectInfoPopup = ({ projectId, disableUserInfoHover, ...props }) => {
	const popupRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [projectInfo, setProjectInfo] = useState({});
	const [position, setPosition] = useState('bottom');
	const { t } = useTranslation();

	const abortController = useAbortController();

	const observerCallback = useCallback(entries => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				if (0.9 > entry.intersectionRatio && entry.intersectionRatio > 0) {
					setPosition('top');
				}
			}
		});
	}, []);

	const observer = useMemo(() => new IntersectionObserver(observerCallback), [observerCallback]);

	useEffect(() => {
		setIsLoading(true);
		const timeout = setTimeout(async () => {
			try {
				const response = await getProjectById(abortController.signal, projectId, true);
				setProjectInfo(response.data);
				setIsLoading(false);
			} catch (e) {
				showToastError(e, t("Can't fetch project data"));
			}
		}, 700);

		return () => {
			clearTimeout(timeout);
		};
	}, [projectId, abortController.signal, t]);

	useEffect(() => {
		if (!popupRef.current) return;

		observer.observe(popupRef.current);

		return () => {
			observer.disconnect();
		};
	}, [observer, popupRef]);

	return (
		<Wrapper ref={popupRef} $position={position} {...props}>
			{isLoading ? (
				<>
					<ContentLoader
						width="540px"
						height="70px"
						backgroundColor={colors.common.lightGrey}
						foregroundColor={colors.common.headerGrey}
					>
						<rect x="6" y="12" rx="10" ry="10" width="76" height="20" />
						<rect x="98" y="12" rx="10" ry="10" width="72" height="20" />
						<rect x="186" y="12" rx="10" ry="10" width="76" height="20" />
						<rect x="278" y="12" rx="10" ry="10" width="76" height="20" />
						<rect x="370" y="12" rx="10" ry="10" width="76" height="20" />
						<circle cx="478" cy="20" r="18" />
						<circle cx="500" cy="20" r="18" />
						<circle cx="520" cy="20" r="18" />

						<rect x="6" y="48" rx="10" ry="10" width="101" height="20" />
						<rect x="123" y="48" rx="10" ry="10" width="62" height="20" />
						<rect x="201" y="48" rx="10" ry="10" width="62" height="20" />
						<rect x="279" y="48" rx="10" ry="10" width="62" height="20" />
						<rect x="357" y="48" rx="10" ry="10" width="62" height="20" />
						<rect x="435" y="48" rx="10" ry="10" width="101" height="20" />
					</ContentLoader>
				</>
			) : (
				<ProjectInfo
					spacing={0}
					border="none"
					padding="0px"
					backgroundColor={colors.common.white}
					data={projectInfo}
					disableUserInfoHover={disableUserInfoHover}
				/>
			)}
		</Wrapper>
	);
};

ProjectInfoPopup.defaultProps = {
	disableUserInfoHover: false,
};

ProjectInfoPopup.propTypes = {
	projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	disableUserInfoHover: PropTypes.bool,
};

export default ProjectInfoPopup;
