import * as yup from 'yup';

const yupUrl = (message = 'Enter a correct url') =>
	yup
		.string()
		.matches(
			/^((https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}))?$/,
			{
				excludeEmptyString: true,
				message,
			},
		);

export default yupUrl;
