import { useEffect, useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSortBy } from 'react-table';

import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';

import { roleGroups, roleOptions, rolesByRoleGroup } from 'User/constants/roles';

import getUsers from 'UserManagement/api/getUsers';

import { DefaultTable } from 'Common/components/table/table';
import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import { useScrollableTableBody } from 'Common/hooks/table/plugin-hooks/useScrollableTableBody';
import { useSortingQueryParameter } from 'Common/hooks/table/plugin-hooks/useSortingQueryParameter';
import Link from 'Common/components/Link';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import showToastError from 'Common/utils/showToastError';
import PropTypes from 'prop-types';
import axios from 'axios';
import useUniqueAbortSignal from 'Common/hooks/useUniqueAbortSignal';
import UserOverlay from '../UserForm/UserOverlay';
import crudModes from 'Common/constants/crudModes';
import getUserByEmail from 'UserManagement/api/getUserByEmail';
import useAbortController from 'Common/hooks/useAbortController';

const Wrapper = styled.div`
	position: relative;
	pointer-events: ${({ hasOpenFilter }) => (hasOpenFilter ? 'none' : 'all')};

	@keyframes highlight {
		0% {
			background-color: ${colors.common.lightBeige};
		}
		100% {
			background-color: ${colors.common.white};
		}
	}
`;

const defaultSortBy = {
	id: 'firstName',
	desc: false,
};

const generateAppliedUserFilters = ({ active, roles, ...filters } = {}, isEPConly = false) => {
	let activeValue = ['1'];

	if (active === 'inactive') {
		activeValue = ['0'];
	} else if (active === 'all') {
		activeValue = [];
	}

	return {
		active: activeValue,
		roles: isEPConly ? ['ROLE_EPC_PARTNER'] : roles || rolesByRoleGroup[roleGroups.ECOLIGO],
		...filters,
	};
};

const UserListTable = ({ isEPConly }) => {
	const { t } = useTranslation();

	const filterTable = useMemo(() => (isEPConly ? 'epcUsers' : 'users'), [isEPConly]);
	const hasOpenFilter = !!useSelector(state => state.tables?.[filterTable]?.openedFilter);
	const [totalEcoligoUsers, setTotalEcoligoUsers] = useState(0);
	const [totalEpcPartners, setTotalEpcPartners] = useState(0);
	const [userToEdit, setUserToEdit] = useState(false);
	const abortController = useAbortController();

	const sortByQueryParam = useQueryParameter('sortBy');
	const sortBy = sortByQueryParam ?? defaultSortBy;

	const filters = useQueryParameter('filters');

	const searchQuery = useQueryParameter('searchQuery');

	const getUniqueSignal = useUniqueAbortSignal();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('First name'),
				accessor: 'firstName',
				highlightSearchTerm: true,
				Footer: () => (
					<span>
						{t('Total number of active ecoligo users:')} {totalEcoligoUsers}
					</span>
				),
			},
			{
				Header: t('Last name'),
				accessor: 'lastName',
				highlightSearchTerm: true,
			},
			{
				Header: t('Gender'),
				accessor: 'title',
				highlightSearchTerm: true,
			},
			{
				Header: t('E-mail'),
				accessor: 'email',
				minWidth: 270,
				highlightSearchTerm: true,
				Footer: () => (
					<span>
						{t('Total number of active EPC partner users:')} {totalEpcPartners}
					</span>
				),
			},
			{
				Header: t('Phone'),
				accessor: 'phone',
				highlightSearchTerm: true,
			},
			{
				Header: t('Roles'),
				accessor: 'roles',
				disableSortBy: true,
			},
			{
				Header: t('Regional hub'),
				accessor: 'regionalHub',
				disableSortBy: true,
				Cell: (() => {
					const Cell = ({ row: { original } }) => (
						<div title={original?.regionalHub?.name}>
							{original?.regionalHub?.name ? original?.regionalHub?.name : ''}
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ regionalHub: PropTypes.shape({ name: PropTypes.string }) }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Status'),
				accessor: 'active',
			},
		],
		[t, totalEcoligoUsers, totalEpcPartners],
	);

	const [users, setUsers] = useState([]);

	const [isLoading, setIsLoading] = useState(true);

	const fetchUsers = useCallback(async () => {
		setIsLoading(true);

		const appliedFilters = generateAppliedUserFilters(filters, isEPConly);

		try {
			const response = await getUsers(
				getUniqueSignal('get-users'),
				{
					page: 1,
					itemsPerPage: 9999,
					sortBy: sortBy.id,
					sortDirection: sortBy.desc ? 'DESC' : 'ASC',
				},
				appliedFilters,
				searchQuery,
			);

			setTotalEcoligoUsers(response.data.totalNumberOfActiveEcoligoUsers);
			setTotalEpcPartners(response.data.totalNumberOfActiveEPCPartnerUsers);
			setUsers(response.data.content);
			setIsLoading(false);
		} catch (error) {
			showToastError(error);
			if (!axios.isCancel(error)) {
				setUsers([]);
				setIsLoading(false);
			}
		}
	}, [filters, isEPConly, sortBy, searchQuery, getUniqueSignal]);

	// Update the users in the state when the sorting / filter / search query changes
	useEffect(() => {
		fetchUsers();
	}, [fetchUsers]);

	// Re-structure the users as table data
	const data = useMemo(
		() =>
			users.map(user => ({
				...user,
				firstName: (
					<Link color="#00b4d5" onClick={e => e.preventDefault()}>
						{user?.firstName}
					</Link>
				),
				rawEmail: user.email,
				active: user.active ? t('Active') : t('Inactive'),
				roles: user.roles
					.map(role => roleOptions.find(roleOption => roleOption.value === role.name)?.label || role.name)
					.join(', '),
			})),
		[users, t],
	);

	const handleUserSubmit = useCallback(async () => {
		try {
			const hasAppliedFilters = Boolean(filters) && Object.keys(filters).length > 0;
			if (hasAppliedFilters) {
				fetchUsers();
			} else {
				setUsers(prevUsers => {
					const updatedUsers = prevUsers.map(user => {
						if (user.email === userToEdit) {
							return {
								...user,
								isLoading: true,
								isHighlighted: true,
							};
						}
						return { ...user, isHighlighted: false };
					});
					return updatedUsers;
				});
				const { data: updatedUser } = await getUserByEmail(abortController.signal, userToEdit);
				setUsers(prevUsers => {
					const updatedUsers = prevUsers.map(user => {
						if (user.email === userToEdit) {
							return { ...updatedUser, isHighlighted: true };
						}
						return { ...user, isHighlighted: false };
					});
					return updatedUsers;
				});
			}
		} catch (e) {
			showToastError(e, "Can't get updated invoice");
		}
	}, [filters, fetchUsers, abortController.signal, userToEdit]);

	const handleRowClick = row => {
		if (!window.getSelection().toString()) {
			setUserToEdit(row.original.rawEmail);
		}
	};

	const handleEditClose = () => {
		setUserToEdit(null);
	};

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		getTableFooterProps,
		headerGroups,
		footerGroups,
		rows,
		prepareRow,
	} = useStandardTable(
		{
			data,
			columns,
			searchTerm: searchQuery,
			onRowClick: handleRowClick,
			defaultSortBy,
			getCustomRowStyles: row =>
				row.original.isHighlighted
					? row.original.isLoading
						? {
								backgroundColor: colors.common.lightBeige,
						  }
						: {
								animation: 'highlight 5s ease-in-out forwards',
						  }
					: {},
		},
		useClickableTableRow,
		useSortBy,
		useSortingQueryParameter,
		useScrollableTableBody,
	);

	return (
		<Wrapper hasOpenFilter={hasOpenFilter}>
			<DefaultTable
				getTableProps={getTableProps}
				getTableFooterProps={getTableFooterProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				rows={rows}
				headerGroups={headerGroups}
				footerGroups={footerGroups}
				prepareRow={prepareRow}
				isLoading={isLoading}
				showNoResultsFound
			/>

			<UserOverlay
				isOpen={Boolean(userToEdit)}
				email={userToEdit}
				mode={crudModes.EDIT}
				onClose={handleEditClose}
				onFormSubmit={handleUserSubmit}
			/>
		</Wrapper>
	);
};

UserListTable.defaultProps = {
	isEPConly: false,
};

UserListTable.propTypes = {
	isEPConly: PropTypes.bool,
};

export default UserListTable;
