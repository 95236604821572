import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Tile from 'Common/components/Tile/Tile';
import showToastError from 'Common/utils/showToastError';
import Comment from 'Common/components/Comments/Comment';
import { useDispatch } from 'react-redux';
import { updateSidePanelData } from 'Application/reducers/reduxSidePanel';
import { SIDE_PANEL_CONTENT_TYPES } from 'Common/components/SidePanel/constants/sidePanelContentTypes';
import { useSelector } from 'react-redux';

const CommentsTile = ({ entity, onSave }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const sidePanelData = useSelector(state => state.sidePanel);
	const isOpenSidepanel = sidePanelData.isOpen && sidePanelData.type === 'entityComments';

	const handleTileClick = () => {
		try {
			if (!isOpenSidepanel) {
				dispatch(
					updateSidePanelData({
						isOpen: true,
						type: SIDE_PANEL_CONTENT_TYPES.ENTITY_COMMENTS,
						isLoading: true,
					}),
				);
				dispatch(
					updateSidePanelData({
						isOpen: true,
						isLoading: false,
						contentData: { entity, showHistory: false, handleChange: onSave },
						headerData: {
							to: '/entities',
							isBreadcrumb: true,
							breadCrumb: 'Entities',
							activeBreadcrumb: entity.name,
							toActiveBreadcrumb: `/entities/details/${entity.id}`,
						},
					}),
				);
			}
		} catch (err) {
			showToastError(err);
		}
	};
	const entityComments = useMemo(
		() =>
			entity?.comments
				.slice()
				.sort(comment => comment.createdDate)
				.slice(0, 3),
		[entity],
	);

	return (
		<Tile title={t('Latest Comments')} onClick={handleTileClick} zIndex={1}>
			{entityComments.map(comment => (
				<Comment key={comment.id} comment={comment} hideActions hideOverflow />
			))}
		</Tile>
	);
};

CommentsTile.propTypes = {
	entity: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		name: PropTypes.string,
		pin: PropTypes.string,
		comments: PropTypes.arrayOf(PropTypes.shape({})),
	}).isRequired,
	onSave: PropTypes.func,
};

CommentsTile.defaultProps = {
	onSave: () => {},
};

export default CommentsTile;
