import getClientOptions from 'Client/api/getClientOptions';
import showToastError from 'Common/utils/showToastError';
import i18n from 'Application/i18n';

const loadClientOptions = async (signal, query) => {
	try {
		const response = await getClientOptions(signal, query);
		const clients = response.data.content;

		return clients.map(client => ({
			value: client.id,
			label: client.name,
		}));
	} catch (error) {
		showToastError(error, i18n.t("Can't fetch client options"));
		return [];
	}
};

export default loadClientOptions;
