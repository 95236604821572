import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FILTER_TYPES } from 'Common/components/filter/constants/filterConstants';
import { INVOICE_STATUS_OPTIONS, INVOICE_OVERDUE_STATUS_OPTIONS } from 'Invoice/constants/invoiceContstants';
import loadEntitiesAbbreviationsOptions from 'Invoice/utils/loadEntitiesAbbreviationsOptions';
import modifyNumberFilterData from 'Common/components/filter/hooks/modifyNumberFilterData';
import { merge } from 'lodash';
import useAbortController from 'Common/hooks/useAbortController';
import getAmountFilterRanges from 'Invoice/api/getAmountFilterRanges';
import axios from 'axios';
import loadClientOptions from 'Client/utils/loadClientOptions';
import showToastError from 'Common/utils/showToastError';
import loadCountryOptions from 'Country/utils/loadCountryOptions';

const fallbackFilterRanges = {
	totalAmountMin: 0,
	totalAmountMax: 100,
};

const useInvoiceFilterData = () => {
	const { t } = useTranslation();
	const [filterRanges, setFilterRanges] = useState(fallbackFilterRanges);

	const abortController = useAbortController();

	const loadCustomClientOptions = async (signal, query) => {
		try {
			const options = await loadClientOptions(signal, query);

			const showUnknown = query.length === 0 || 'unknown'.includes(query.toLowerCase());
			if (showUnknown) {
				options.unshift({
					value: -1,
					label: 'Unknown',
				});
			}

			return options;
		} catch (error) {
			showToastError(error);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const responses = await Promise.all([getAmountFilterRanges(abortController.signal)]);

				setFilterRanges(prevFilterRanges => ({
					...prevFilterRanges,
					...merge(...responses.map(response => response.data)),
				}));
			} catch (error) {
				if (!axios.isCancel(error)) {
					setFilterRanges(fallbackFilterRanges);
				}
			}
		})();
	}, [abortController.signal]);

	const defaultFilterData = [
		{
			name: 'status',
			label: t('Status'),
			type: FILTER_TYPES.SELECT,
			options: INVOICE_STATUS_OPTIONS,
		},
		{
			name: 'overdueStatus',
			label: t('Overdue Status'),
			type: FILTER_TYPES.SELECT,
			options: INVOICE_OVERDUE_STATUS_OPTIONS,
		},
		{
			name: 'number',
			label: t('Number'),
			type: FILTER_TYPES.INPUT,
		},
		{
			name: 'projectExternalId',
			label: t('Project ID'),
			type: FILTER_TYPES.MULTI_INPUT,
		},
		{
			name: 'clientId',
			label: t('Client'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadCustomClientOptions,
			handleSearch: (values, setValue) => {
				if (values.includes('-1')) {
					setValue('-1');
				}
			},
		},
		{
			name: 'countryId',
			label: t('Country'),
			loadOptions: loadCountryOptions,
			type: FILTER_TYPES.ASYNC_SELECT,
		},
		{
			name: 'accountNumber',
			label: t('Account Number'),
			type: FILTER_TYPES.INPUT,
		},
		{
			name: 'issueDate',
			label: t('Issue Date'),
			type: FILTER_TYPES.DATE,
			startDateName: 'issueDateFrom',
			endDateName: 'issueDateTo',
			isLocalDate: true,
		},
		{
			name: 'dueDate',
			label: t('Due Date'),
			type: FILTER_TYPES.DATE,
			startDateName: 'dueDateFrom',
			endDateName: 'dueDateTo',
			isLocalDate: true,
		},
		{
			name: 'billingPeriodStart',
			label: t('Billing Period Start'),
			type: FILTER_TYPES.DATE,
			startDateName: 'billingPeriodStartFrom',
			endDateName: 'billingPeriodStartTo',
			isLocalDate: true,
		},
		{
			name: 'billingPeriodEnd',
			label: t('Billing Period End'),
			type: FILTER_TYPES.DATE,
			startDateName: 'billingPeriodEndFrom',
			endDateName: 'billingPeriodEndTo',
			isLocalDate: true,
		},
		{
			name: 'amount',
			label: t('Amount'),
			type: FILTER_TYPES.NUMBER,
			minName: 'totalAmountFrom',
			maxName: 'totalAmountTo',
			rangeKey: 'totalAmount',
			lowerBound: filterRanges.totalAmountMin,
			upperBound: filterRanges.totalAmountMax,
		},
		{
			name: 'contractPartyId',
			label: t('Entity'),
			type: FILTER_TYPES.ASYNC_SELECT,
			loadOptions: loadEntitiesAbbreviationsOptions,
		},
		{
			name: 'paymentDate',
			label: t('Payment Date'),
			type: FILTER_TYPES.DATE,
			startDateName: 'paymentDateFrom',
			endDateName: 'paymentDateTo',
			isLocalDate: true,
		},
	];

	const [filtersData, setFiltersData] = useState(defaultFilterData);

	useEffect(() => {
		const modifyNumberFilterDataBinded = modifyNumberFilterData.bind(undefined, filterRanges);

		setFiltersData(prevFilterData => [...prevFilterData].map(modifyNumberFilterDataBinded));
	}, [filterRanges]);

	return { filtersData };
};

export default useInvoiceFilterData;
