import styled from 'styled-components/macro';
import React from 'react';
import { PropTypes } from 'prop-types';

import HoverTooltipList from 'Common/components/tooltip/HoverTooltipList';

const TextContainer = styled.div`
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;

const TableCellTextList = ({ listItems, children }) =>
	listItems.length > 0 ? (
		<HoverTooltipList listItems={listItems}>
			<TextContainer>{children}</TextContainer>
		</HoverTooltipList>
	) : (
		<TextContainer>{children}</TextContainer>
	);

TableCellTextList.propTypes = {
	listItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	children: PropTypes.node.isRequired,
};

export default TableCellTextList;
