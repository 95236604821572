import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useTrancheFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				annuity: yup
					.number()
					.min(0, t('Annuity must 0 or more'))
					.test(
						'decimal-places',
						'Annuity must have no more than 1 decimal place',
						value => value === undefined || String(value).match(/^\d*(\.\d{1})?$/),
					)
					.typeError(t('Annuity must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? undefined : val,
					)
					.nullable()
					.max(100, 'Annuity must be less than or equals to 100')
					.when('annuityCheckbox', {
						is: true,
						then: yup.number().required('Repayment scheme is Required'),

						otherwise: null,
					}),
				bullet: yup
					.number()
					.min(0, t('Bullet must 0 or more'))
					.test(
						'decimal-places',
						'Bullet must have no more than 1 decimal place',
						value => value === undefined || String(value).match(/^\d*(\.\d{1})?$/),
					)
					.typeError(t('Bullet must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? undefined : val,
					)
					.nullable()
					.max(100, 'Bullet must be less than or equals to 100')
					.when('bulletCheckbox', {
						is: true,
						then: yup.number().required('Bullet is Required'),

						otherwise: null,
					}),
				constant: yup
					.number()
					.min(0, t('Constant must 0 or more'))
					.test(
						'decimal-places',
						'Constant must have no more than 1 decimal place',
						value => value === undefined || String(value).match(/^\d*(\.\d{1})?$/),
					)
					.typeError(t('Constant must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? undefined : val,
					)
					.nullable()
					.max(100, 'Constant must be less than or equals to 100')
					.when('constantCheckbox', {
						is: true,
						then: yup.number().required('Constant is Required'),

						otherwise: null,
					}),
				tenor: yup
					.number()
					.min(0, t('Tenor must 0 or more'))
					.max(100, 'Tenor must be less than or equals to 100')
					.test(
						'decimal-places',
						'Tenor must have no more than 1 decimal place',
						value => value === undefined || String(value).match(/^\d*(\.\d{1})?$/),
					)
					.typeError(t('Tenor must be a number'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? undefined : val,
					)
					.nullable()
					.required(t('Required')),
				emissionId: yup.number().typeError(t('Must be a number')).nullable().integer(t('Must be an integer')),
				constantCheckbox: yup.boolean(),
				annuityCheckbox: yup.boolean(),
				reference: yup.string().required(t('Required')),
				bulletCheckbox: yup.boolean(),
				platform: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.nullable()
					.required(t('Required')),
				interestRate: yup
					.number()
					.typeError(t('Interest rate must be a number'))
					.nullable()
					.positive(t('Interest rate must be positive'))
					.max(100, 'Interest Rate must be less than or equals to 100.')
					.required(t('Required')),
				bonusAmount: yup
					.number()
					.typeError(t('Bonus amount must be a number'))
					.nullable()
					.positive(t('Bonus amount must be positive')),
				minInvestmentAmount: yup
					.number()
					.integer()
					.typeError(t('Min finance volume must be a number'))
					.nullable()
					.positive(t('Min investment amount must be positive'))
					.required(t('Required')),
				maxInvestmentAmount: yup
					.number()
					.integer()
					.typeError(t('Max finance volume must be a number'))
					.when('minInvestmentAmount', (minInvestmentAmount, schema) =>
						schema.min(minInvestmentAmount, t('Max volume must be greater than Min volume.')),
					)
					.required('Required'),
				divisibleBy: yup
					.number()
					.integer(t('Divisible by must be an integer'))
					.typeError(t('Divisible by must be a number'))
					.nullable()
					.positive(t('Divisible by must be positive'))
					.required(t('Required')),
				emissionSpv: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.nullable()
					.required(t('Required')),
			}),
		[t],
	);
};

export default useTrancheFormValidationSchema;
