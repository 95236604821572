import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import useQueryParameter from 'Common/hooks/useQueryParameter';

import 'rc-pagination/assets/index.css';
import Link from 'Common/components/Link';
import { push } from 'redux-first-history';
import getInvestors from 'Investors/api/getInvestors';
import { DefaultTable } from 'Common/components/table/table';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import { useSortBy } from 'react-table';
import { useSortingQueryParameter } from 'Common/hooks/table/plugin-hooks/useSortingQueryParameter';
import { useScrollableTableBody } from 'Common/hooks/table/plugin-hooks/useScrollableTableBody';
import { useVirtualizedTableBody } from 'Common/hooks/table/plugin-hooks/useVirtualizedTableBody';
import { debounce, fill } from 'lodash';
import formatDate from 'Common/utils/formatDate';
import showToastError from 'Common/utils/showToastError';
import { PropTypes } from 'prop-types';
import useUniqueAbortSignal from 'Common/hooks/useUniqueAbortSignal';
import prefillTableData from 'Common/utils/prefillTableData';

const Wrapper = styled.div`
	position: relative;
	pointer-events: ${({ hasOpenFilter }) => (hasOpenFilter ? 'none' : 'all')};
`;

const defaultSortBy = {
	id: 'createdAt',
	desc: true,
};

const sortPropertiesMap = {
	country: 'country.name',
	epcPartner: 'epcPartner.name',
	type: 'projectType.name',
	marketSegment: 'marketSegment.name',
};

const InvestorsListTable = () => {
	const { t } = useTranslation();
	const hasOpenFilter = !!useSelector(state => state?.tables?.investors?.openedFilter);

	const [totalItemsCount, setTotalItemsCount] = useState(25);

	const sortByQueryParam = useQueryParameter('sortBy');
	const sortBy = sortByQueryParam ?? defaultSortBy;
	const filters = useQueryParameter('filters');

	const searchQuery = useQueryParameter('searchQuery');

	const dispatch = useDispatch();

	const [investors, setInvestors] = useState(() => fill(Array(25), {}));
	const [isLoading, setIsLoading] = useState(true);

	const getUniqueSignal = useUniqueAbortSignal();

	const hasLoadedFirstResultsRef = useRef(false);

	const getInvestorsBatch = useCallback(
		async (startIndex, stopIndex, filters, sortBy, searchQuery, hasItemsChanges = false) => {
			if (hasItemsChanges) {
				setIsLoading(true);
			}

			const sortById = sortPropertiesMap[sortBy.id] ?? sortBy.id;

			const customFilters = { ...filters };

			try {
				let response = await getInvestors(
					getUniqueSignal('get-investors'),
					{
						offset: startIndex,
						limit: stopIndex - startIndex + 1,
						sortBy: sortById,
						sortDirection: sortBy.desc ? 'DESC' : 'ASC',
					},
					customFilters,
					searchQuery,
				);

				if (hasItemsChanges || !hasLoadedFirstResultsRef.current) {
					const { itemsCount, itemsFilled, error } = prefillTableData(response.data, startIndex, stopIndex);
					if (error) {
						showToastError(error);
					} else {
						setInvestors(itemsFilled);
						setTotalItemsCount(itemsCount);
					}
				} else {
					setInvestors(prevInvestors =>
						prevInvestors.map((prevInvestor, index) => {
							if (startIndex <= index && index <= stopIndex) {
								const indexInResultSet = index - startIndex;

								return response.data.content[indexInResultSet];
							}

							return prevInvestor;
						}),
					);
				}

				hasLoadedFirstResultsRef.current = true;

				if (hasItemsChanges) {
					setIsLoading(false);
				}
			} catch (error) {
				showToastError(error);
			}
		},
		[getUniqueSignal],
	);

	useEffect(() => {
		getInvestorsBatch(
			0,
			25,
			filters,
			{
				id: sortBy.id,
				desc: sortBy.desc,
			},
			searchQuery,
			true,
		);
	}, [getInvestorsBatch, searchQuery, filters, sortBy.desc, sortBy.id]);

	// eslint-disable-next-line
	const handleLoadMoreRows = useCallback(
		debounce(
			(startIndex, stopIndex) => {
				getInvestorsBatch(
					startIndex,
					stopIndex,
					filters,
					{
						id: sortBy.id,
						desc: sortBy.desc,
					},
					searchQuery,
				);
			},
			350,
			{
				leading: false,
				trailing: true,
			},
		),
		[getInvestorsBatch, searchQuery, filters, sortBy.desc, sortBy.id],
	);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('First name'),
				accessor: 'firstName',
				highlightSearchTerm: true,
				cellContentStyle: {
					display: 'flex',
				},
				Cell: (() => {
					const Cell = ({ row: { original: investor } }) => (
						<Link
							color="#00b4d5"
							fontSize="14px"
							to={`/investors/details/${investor.auto_id}`}
							onClick={e => e.stopPropagation()}
						>
							{investor.first_name}
						</Link>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								auto_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
								first_name: PropTypes.string,
							}),
						}).isRequired,
					};
					return Cell;
				})(),
				Footer: totalItemsCount,
			},
			{
				Header: t('Last name'),
				accessor: 'lastName',
				highlightSearchTerm: true,
				cellStyle: {
					justifyContent: 'flex-end',
				},
				Cell: ({ row: { original: investor } }) => investor.last_name,
			},
			{
				Header: t('E-mail'),
				accessor: 'email',
				highlightSearchTerm: true,
			},
			{
				Header: t('Registered'),
				accessor: 'createdAt',
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				Cell: ({ row: { original: investor } }) =>
					investor.registered ? formatDate(investor.registered, 'd/M/yyyy HH:mm') : '-',
			},
		],
		[t, totalItemsCount],
	);

	const data = useMemo(() => investors, [investors]);

	const itemsHash = useMemo(() => JSON.stringify({ filters, sortBy }), [filters, sortBy]);

	const handleRowClick = useCallback(
		row => {
			if (!window.getSelection().toString()) {
				dispatch(push(`/investors/details/${row.original.auto_id}`));
			}
		},
		[dispatch],
	);

	// Fallback to default row id getter
	const getRowId = useCallback(
		(row, relativeIndex, parent) => (parent ? [parent.id, relativeIndex].join('.') : relativeIndex),
		[],
	);

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		getTableFooterProps,
		getVirtualizedTableBodyProps,
		headerGroups,
		footerGroups,
		rows,
		prepareRow,
	} = useStandardTable(
		{
			data,
			columns,
			defaultSortBy,
			searchTerm: searchQuery,
			onRowClick: handleRowClick,
			virtualization: {
				totalRowsCount: totalItemsCount,
				rowsHash: itemsHash,
				loadMoreRows: handleLoadMoreRows,
			},
			getRowId,
		},
		useClickableTableRow,
		useSortBy,
		useSortingQueryParameter,
		useScrollableTableBody,
		useVirtualizedTableBody,
	);

	return (
		<Wrapper hasOpenFilter={hasOpenFilter}>
			<DefaultTable
				getTableProps={getTableProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				getTableFooterProps={getTableFooterProps}
				getVirtualizedTableBodyProps={getVirtualizedTableBodyProps}
				rows={rows}
				headerGroups={headerGroups}
				footerGroups={footerGroups}
				prepareRow={prepareRow}
				isLoading={isLoading || rows.length !== totalItemsCount}
				showNoResultsFound
			/>
		</Wrapper>
	);
};

export default InvestorsListTable;
