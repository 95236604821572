import { pick } from 'lodash';
import removeTrailingZeros from 'Common/utils/removeTrailingZeros';
import convertDateAndHour from 'Campaigns/components/CampaignDetails/Tabs/TranchesTab/Tiles/TranchesListTile/TrancheForm/utils/convertDateAndHour';

const convertFormValuesToRequestData = values => ({
	...pick(values, ['reference', 'bonusAmount', 'bonusRate', 'interestRate', 'tenor']),
	minInvestmentAmount: removeTrailingZeros(values.minInvestmentAmount),
	maxInvestmentAmount: removeTrailingZeros(values.maxInvestmentAmount),
	divisibleBy: removeTrailingZeros(values.divisibleBy),
	externalId: values.emissionId,
	setTags: values.tags ? { tagIds: values.tags.map(tag => tag.value) } : { tagIds: [] },
	investableFrom: convertDateAndHour(values.investableFrom, values.investableFromHour),
	investableUntil: convertDateAndHour(values.investableUntil, values.investableUntilHour),
	platformId: values.platform ? values.platform.value : null,
	emissionSpvId: values.emissionSpv ? values.emissionSpv.value : null,
	annuityEnabled: values.annuityCheckbox,
	bulletEnabled: values.bulletCheckbox,
	constantEnabled: values.constantCheckbox,
	bullet: values.bulletCheckbox ? values.bullet : null,
	annuity: values.annuityCheckbox ? values.annuity : null,
	constant: values.constantCheckbox ? values.constant : null,
});

export default convertFormValuesToRequestData;
