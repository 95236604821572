import { createSlice } from '@reduxjs/toolkit';

const initialUserData = {
	isAuthenticated: false,
	isInterrupted: false,
	email: undefined,
	firstName: undefined,
	lastName: undefined,
	title: undefined,
	roles: [],
	roleGroups: [],
	profilePhoto: {},
};

export const userSlice = createSlice({
	name: 'user',
	initialState: { ...initialUserData },
	reducers: {
		interruptUser: state => {
			state.isInterrupted = true;
		},
		authenticateUser: (
			state,
			{ payload: { email, firstName, lastName, title, phone, roles, roleGroups, profilePhoto } },
		) => {
			state.isAuthenticated = true;
			state.isInterrupted = false;
			state.email = email;
			state.firstName = firstName;
			state.lastName = lastName;
			state.title = title;
			state.roles = roles;
			state.phone = phone;
			state.roleGroups = roleGroups;
			state.profilePhoto = profilePhoto;
		},
		clearUserData: () => initialUserData,
		updateCurrentUser: (state, { payload: userData }) => {
			Object.assign(state, userData);
		},
	},
});

export const { interruptUser, authenticateUser, clearUserData, updateCurrentUser } = userSlice.actions;

export default userSlice.reducer;
