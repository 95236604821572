import api from 'Application/api/api';

const confirmAccount = (signal, email, password, passwordConfirm, code) =>
	api.post(
		'/auth/confirm-registration',
		{
			username: email,
			password,
			passwordConfirm,
			code,
		},
		{
			signal,
		},
	);

export default confirmAccount;
