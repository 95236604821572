import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import FormField from 'Common/components/form/FormField';

import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { ModalFormButtons } from 'Common/components/form/ModalForm';
import FormikTextArea from 'Common/components/form/FormikTextArea';
import expellContact from 'Contacts/api/expellContact';
import showToastError from 'Common/utils/showToastError';
import useExpellContactInitialValues from './hooks/useExpellContactFormInitialValues';
import useExpellContactValidationSchema from './hooks/useExpellContactValidationSchema';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const FieldsWrapper = styled.div`
	width: 100%;
`;

const ExpellContactForm = ({
	contactId,
	onDirty,
	setDeletionError,
	onSubmit: onFormSubmit,
	onCancel: onClose,
}) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const initialValues = useExpellContactInitialValues({});
	const validationSchema = useExpellContactValidationSchema();

	const abortController = useAbortController();

	const { errors, touched, values, dirty, handleChange, handleBlur, handleSubmit } = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				await expellContact(abortController.signal, contactId, values);
				onFormSubmit();
				notify(t('Contact expelled successfully'), {
					type: toast.TYPE.SUCCESS,
				});
				setIsLoading(false);
				onClose();
			} catch (error) {
				showToastError(error, 'Cannot expel contact');

				if (!axios.isCancel(error)) {
					setIsLoading(false);
					setDeletionError(error.response.data);
					onClose();
				}
			}
			onClose();
		},
	});

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form onSubmit={handleSubmit}>
			<FieldsWrapper>
				<FormField>
					<FormikTextArea
						id="comment"
						name="comment"
						label={t('Comment')}
						value={values.comment}
						error={errors.comment}
						touched={touched.comment}
						onChange={handleChange}
						onBlur={handleBlur}
						isRequired
					/>
				</FormField>
			</FieldsWrapper>
			<ModalFormButtons
				onCancel={onClose}
				onConfirm={handleSubmit}
				isLoading={isLoading}
				label="Contact Mark as Expelled"
				submitButtonText={t('Save')}
			/>
		</Form>
	);
};

ExpellContactForm.defaultProps = {
	data: null,
};

ExpellContactForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	setDeletionError: PropTypes.func.isRequired,
	contactId: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
};

export default ExpellContactForm;
