import { useMemo } from 'react';
import ComercialDetailsSection from '../Sections/ComercialDetailsSection';
import EcoligoEconomicResultsSection from '../Sections/EcoligoEconomicResultsSection';
import OtherContractualTermsSection from '../Sections/OtherContractualTermsSection';
import ProjectDetailsSection from '../Sections/ProjectDetailsSection';

const useClientContractOverlaySections = (countryId, mode, breadcrumbList) =>
	useMemo(
		() => [
			{
				title: 'Project Details',
				section: props => <ProjectDetailsSection {...props} mode={mode} breadcrumbList={breadcrumbList} />,
			},
			{
				title: 'Commercial Details',
				section: props => <ComercialDetailsSection {...props} mode={mode} />,
			},
			{
				title: 'Other contractual terms',
				section: props => <OtherContractualTermsSection {...props} mode={mode} countryId={countryId} />,
			},
			{
				title: 'Ecoligo Economic Results',
				section: props => <EcoligoEconomicResultsSection {...props} mode={mode} />,
			},
		],
		[mode, breadcrumbList, countryId],
	);

export default useClientContractOverlaySections;
