// TODO remove the eslint complexity and fix it
/* eslint-disable complexity */
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import declineEpcOffer from 'EpcContract/api/declineEpcOffer';
import reopenEpcOffer from 'EpcContract/api/reopenEpcOffer';
import deleteEpcOffer from 'EpcContract/api/deleteEpcOffer';
import setEpcOfferAsPrimary from 'EpcContract/api/setEpcOfferAsPrimary';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import { types } from 'Projects/constants/offerTypes';
import { statuses } from 'Projects/constants/offerStatuses';
import isAuthorized from 'User/utils/isAuthorized';
import { roles } from 'User/constants/roles';
import { useDispatch } from 'react-redux';
import { updateCurrentProject } from 'Projects/reduxProject';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PropTypes } from 'prop-types';
import crudModes from 'Common/constants/crudModes';
import EPCOfferOverlay from '../Overlays/EPCOffer/EPCOfferOverlay';
import { EPC_OFFER_TYPES } from '../constants';
import projectTypes from 'Projects/constants/projectTypes';

const EPCOfferListActions = ({
	offer,
	project,
	epcOffers,
	reloadProject,
	isPrimary,
	canDecline,
	projectType,
	projectSystemSize,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [epcOfferIdForEditingWithClientOffers, setEpcOfferIdForEditingWithClientOffers] = useState(null);

	const [epcOfferIdForEditing, setEpcOfferIdForEditing] = useState({
		ESTIMATE: null,
		INQUIRY: null,
		PROPOSAL: null,
	});

	const abortController = useAbortController();

	const isAuthorizedToEdit = isAuthorized([roles.ADMIN, roles.SALES, roles.FINANCE, roles.MANAGEMENT]);

	const [offerForDeletion, setOfferForDeletion] = useState(null);
	const [offerForDecline, setOfferForDecline] = useState(null);
	const [offerForReopen, setOfferForReopen] = useState(null);

	const handleOfferStatusChange = changedOffer => {
		const offers = [...epcOffers];

		const index = offers.findIndex(offer => offer.id === changedOffer.id);

		if (index !== -1) {
			if (changedOffer.primaryOffer) {
				const currentPrimaryIndex = offers.findIndex(offer => offer.primaryOffer);

				if (currentPrimaryIndex !== -1) {
					offers[currentPrimaryIndex] = {
						...offers[currentPrimaryIndex],
						primaryOffer: false,
					};
				}
			}

			offers[index] = {
				...offers[index],
				status: changedOffer.status,
				primaryOffer: changedOffer.primaryOffer,
			};
		}

		dispatch(
			updateCurrentProject({
				epcOffers: offers,
			}),
		);
	};

	const handleSetOfferAsPrimary = useCallback(
		async id => {
			try {
				await setEpcOfferAsPrimary(abortController.signal, id);

				const projectResponse = await reloadProject();

				if (projectType !== projectTypes.PV) {
					const newSystemSize = projectResponse.systemSizeKwp;

					if (newSystemSize !== projectSystemSize) {
						notify(t(`Project system size set to ${newSystemSize} kWp`), {
							type: toast.TYPE.SUCCESS,
						});
					}
				}

				notify(t('Offer set as primary successfully'), {
					type: toast.TYPE.SUCCESS,
				});
			} catch (error) {
				showToastError(error);
			}
		},
		[projectSystemSize, projectType, reloadProject, t, abortController.signal],
	);

	const handleDeclineOffer = async id => {
		try {
			let response = await declineEpcOffer(abortController.signal, id);
			notify(t('Offer declined successfully'), {
				type: toast.TYPE.SUCCESS,
			});
			handleOfferStatusChange(response.data);

			setOfferForDecline(null);
		} catch (error) {
			showToastError(error);
		}
	};
	const handleReopenOffer = async id => {
		try {
			let response = await reopenEpcOffer(abortController.signal, id);
			handleOfferStatusChange(response.data);

			notify(t('Offer reopened successfully'), {
				type: toast.TYPE.SUCCESS,
			});

			setOfferForReopen(null);
		} catch (error) {
			showToastError(error);
		}
	};

	const handleDeleteEpcOffer = async id => {
		try {
			await deleteEpcOffer(abortController.signal, id);
			notify(t('EPC offer deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});

			await reloadProject();
			setOfferForDeletion(null);
		} catch (error) {
			showToastError(error);
		}
	};

	const canOnlyViewOffer =
		offer?.status === statuses.DECLINED_BY_ECOLIGO ||
		(offer.status === statuses.OFFER_RECEIVED && offer.type === 'RFEP') ||
		!isAuthorizedToEdit;

	return (
		<>
			<MoreActionsButton label="Project Details Offers List More Actions" controlledVisibility>
				<MenuItem
					disabled={
						offer.status === statuses.DECLINED_BY_PARTNER ||
						offer.status === statuses.CLOSED ||
						offer.status === statuses.OPEN
					}
					onClick={() =>
						canOnlyViewOffer
							? setEpcOfferIdForEditing(prev => ({
									...prev,
									[offer.type === 'RFEP' ? 'PROPOSAL' : offer.type]: offer.id,
							  }))
							: offer?.clientOfferGroups?.length > 0 || offer.signed
							? setEpcOfferIdForEditingWithClientOffers({
									type: offer.type === 'RFEP' ? 'PROPOSAL' : offer.type,
									id: offer.id,
							  })
							: setEpcOfferIdForEditing(prev => ({
									...prev,
									[offer.type === 'RFEP' ? 'PROPOSAL' : offer.type]: offer.id,
							  }))
					}
					type="button"
					data-action="openOffer"
					label={`Project Details Offers List - ${canOnlyViewOffer ? 'View' : 'Edit'} Menu Item`}
				>
					{canOnlyViewOffer ? t('View') : t('Edit')}
				</MenuItem>
				{!canOnlyViewOffer && (
					<>
						<MenuItem
							disabled={
								offer.status === statuses.DECLINED_BY_ECOLIGO ||
								offer.status === statuses.CLOSED ||
								offer.status === statuses.DECLINED_BY_PARTNER ||
								offer.status === statuses.OPEN ||
								isPrimary ||
								!isAuthorizedToEdit
							}
							onClick={() => handleSetOfferAsPrimary(offer.id)}
							type="button"
							data-action="setAsPrimary"
							label="Project Details Offers List - Set as Primary Menu Item"
						>
							{t('Set as Primary')}
						</MenuItem>

						{(offer.status === statuses.DECLINED_BY_ECOLIGO ||
							offer.status === statuses.DECLINED_BY_PARTNER ||
							offer.status === statuses.OFFER_RECEIVED ||
							offer.status === statuses.CLOSED) &&
							offer.type === 'RFEP' && (
								<MenuItem
									disabled={!isAuthorizedToEdit}
									onClick={() => setOfferForReopen(offer.id)}
									type="button"
									data-action="reopen"
									label="Project Details Offers List - Reopen Menu Item"
								>
									{t('Reopen')}
								</MenuItem>
							)}
						<MenuItem
							disabled={
								!canDecline ||
								offer.type === types.ESTIMATE ||
								offer.status === statuses.CLOSED ||
								offer.status === statuses.DECLINED_BY_PARTNER ||
								offer.status === statuses.DECLINED_BY_ECOLIGO ||
								!isAuthorizedToEdit
							}
							onClick={() => setOfferForDecline(offer)}
							type="button"
							data-action="decline"
						>
							{t('Decline')}
						</MenuItem>
						<MenuItem
							disabled={!isAuthorizedToEdit || offer.status === statuses.CLOSED}
							onClick={() => setOfferForDeletion(offer.id)}
							type="button"
							data-action="delete"
						>
							{t('Delete')}
						</MenuItem>
					</>
				)}
			</MoreActionsButton>

			{epcOfferIdForEditing.ESTIMATE && (
				<EPCOfferOverlay
					type={EPC_OFFER_TYPES.ESTIMATE}
					mode={canOnlyViewOffer ? crudModes.VIEW : crudModes.EDIT}
					isOpen={epcOfferIdForEditing.ESTIMATE !== null}
					onClose={() =>
						setEpcOfferIdForEditing(prev => ({
							...prev,
							ESTIMATE: null,
						}))
					}
					onFormSubmit={reloadProject}
					project={project}
					epcOfferId={epcOfferIdForEditing.ESTIMATE}
				/>
			)}
			{epcOfferIdForEditing.INQUIRY && (
				<EPCOfferOverlay
					type={EPC_OFFER_TYPES.INQUIRY}
					mode={canOnlyViewOffer ? crudModes.VIEW : crudModes.EDIT}
					isOpen={epcOfferIdForEditing.INQUIRY !== null}
					onClose={() =>
						setEpcOfferIdForEditing(prev => ({
							...prev,
							INQUIRY: null,
						}))
					}
					onFormSubmit={reloadProject}
					project={project}
					epcOfferId={epcOfferIdForEditing.INQUIRY}
				/>
			)}
			{epcOfferIdForEditing.PROPOSAL && (
				<EPCOfferOverlay
					type={EPC_OFFER_TYPES.PROPOSAL}
					mode={canOnlyViewOffer ? crudModes.VIEW : crudModes.EDIT}
					isOpen={epcOfferIdForEditing.PROPOSAL !== null}
					onClose={() =>
						setEpcOfferIdForEditing(prev => ({
							...prev,
							PROPOSAL: null,
						}))
					}
					onFormSubmit={reloadProject}
					project={project}
					epcOfferId={epcOfferIdForEditing.PROPOSAL}
				/>
			)}

			<ConfirmModal
				isOpen={epcOfferIdForEditingWithClientOffers !== null}
				onCancel={() => setEpcOfferIdForEditingWithClientOffers(null)}
				onConfirm={() => {
					setEpcOfferIdForEditing(prev => ({
						...prev,
						[epcOfferIdForEditingWithClientOffers.type]: epcOfferIdForEditingWithClientOffers.id,
					}));

					setEpcOfferIdForEditingWithClientOffers(null);
				}}
				confirmText={'Edit'}
				label="edit-epc-offer"
				heading={t('Edit EPC offer')}
				text={t('If you change the values in this offer, it may invalidate the associated Client offer(s).')}
			/>

			<ConfirmModal
				isOpen={offerForDeletion !== null}
				onCancel={() => setOfferForDeletion(null)}
				onConfirm={() => handleDeleteEpcOffer(offerForDeletion)}
				label="delete-epc-offer"
				heading={t('Delete EPC offer')}
				text={t('Are you sure you want to delete this offer?')}
			/>
			<ConfirmModal
				isOpen={offerForReopen !== null}
				onCancel={() => setOfferForReopen(null)}
				onConfirm={() => handleReopenOffer(offerForReopen)}
				confirmText={t('Reopen')}
				label="reopen-epc-offer"
				heading={t('Reopen EPC offer')}
				text={`${t(
					'The EPC Partner will be notified and will need to resend the offer again using their portal.',
				)}
				
				${t('Are you sure you want to reopen this offer?')}`}
			/>
			{offerForDecline && (
				<ConfirmModal
					isOpen={offerForDecline !== null}
					onCancel={() => setOfferForDecline(null)}
					onConfirm={() => handleDeclineOffer(offerForDecline.id)}
					label="decline-epc-offer"
					heading={t('Decline EPC offer')}
					text={`${
						offerForDecline.type === 'RFEP' &&
						t('The EPC Partner will be notified that their offer has been declined.')
					}
					
					${t('Are you sure you want to decline this offer?')}`}
				/>
			)}
		</>
	);
};

EPCOfferListActions.defaultProps = {
	offer: {},
	epcOffers: [],
	reloadProject: () => {},
	projectId: null,
	project: {},
	isPrimary: false,
	isSigned: false,
	canDecline: false,
	projectType: '',
	projectSystemSize: 0,
};

EPCOfferListActions.propTypes = {
	offer: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		primaryOffer: PropTypes.bool,
		type: PropTypes.string,
		clientOfferGroups: PropTypes.arrayOf(PropTypes.shape({})),
		status: PropTypes.string,
		signed: PropTypes.bool,
	}),
	epcOffers: PropTypes.arrayOf(PropTypes.shape({})),
	reloadProject: PropTypes.func,
	project: PropTypes.shape({}),
	isPrimary: PropTypes.bool,
	canDecline: PropTypes.bool,
	projectType: PropTypes.string,
	projectSystemSize: PropTypes.number,
};

export default EPCOfferListActions;
