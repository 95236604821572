import useAbortController from 'Common/hooks/useAbortController';
import showToastError from 'Common/utils/showToastError';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InvoiceGeneralTile from 'Invoice/components/Tiles/GeneralTile/InvoiceGeneralTile';
import InvoiceDetailsTile from 'Invoice/components/Tiles/DetailsTile/InvoiceDetailsTile';
import styled from 'styled-components/macro';
import sizes from 'Application/theme/sizes';
import InvoiceActions from 'Invoice/components/Actions/InvoiceActions';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import InfoText from 'Impact/components/InfoText';
import colors from 'Application/theme/colors';
import {
	INVOICE_STATUSES,
	INVOICE_STATUSES_LABELS,
	RESTRICTED_INVOICE_STATUSES,
} from 'Invoice/constants/invoiceContstants';
import useInvoiceFormValidationSchema from '../Forms/hooks/useInvoiceFormValidationSchema';
import useInvoiceInitialValues from '../Forms/hooks/useInvoiceFormInitialValues';
import axios from 'axios';
import { useFormik } from 'formik';
import patchInvoice from 'Invoice/api/patchInvoice';
import crudModes from 'Common/constants/crudModes';
import createInvoice from 'Invoice/api/createInvoice';
import convertInvoiceValuesToRequestData from '../Forms/utils/convertInvoiceValuesToRequestData';
import _, { pick } from 'lodash';
import calculateInvoiceFields from 'Invoice/utils/calculateInvoiceFields';
import { addDays, isAfter, isBefore, isSameDay } from 'date-fns';
import { OverlayButtons } from 'Common/components/modals/Overlay';
import countryCodes from 'Common/constants/countryCodes';
import getProjectById from 'Projects/api/getProjectById';

const Form = styled.form`
	display: flex;
	flex-direction: column;
`;

const TilesContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${sizes.spacing(2)};
`;

const OverlayTopSection = styled.div`
	display: flex;
	justify-content: space-between;
	padding: ${sizes.spacing(2)};
	padding-bottom: 0;
`;

const InvoiceForm = ({
	mode,
	onClose,
	onDirty,
	onSubmit,
	data,
	isSavingChanges,
	invoice,
	setIsInvoiceRestricted,
	setIsSavingChanges,
}) => {
	const { t } = useTranslation();
	const [contractType, setContractType] = useState();
	const abortController = useAbortController();
	const [isProjectLoading, setIsProjectLoading] = useState(false);
	const [countryId, setCountryId] = useState();

	const isEditMode = useMemo(() => mode === crudModes.EDIT, [mode]);
	const isCreateMode = useMemo(() => mode === crudModes.CREATE, [mode]);

	const initData = useMemo(
		() => ({
			...invoice,
			...data,
			mode,
		}),
		[data, invoice, mode],
	);

	const initialValues = useInvoiceInitialValues(initData);
	const validationSchema = useInvoiceFormValidationSchema(isCreateMode);

	const isGhana = useMemo(
		() =>
			initData?.country?.isoCode === countryCodes.GHANA &&
			initData?.client?.country?.isoCode === countryCodes.GHANA,
		[initData?.country?.isoCode, initData?.client?.country?.isoCode],
	);
	const isKenya = useMemo(
		() =>
			initData?.country?.isoCode === countryCodes.KENYA &&
			initData?.client?.country?.isoCode === countryCodes.KENYA,
		[initData?.country?.isoCode, initData?.client?.country?.isoCode],
	);

	const handleCancel = () => {
		resetForm({ values: initialValues });
		if (onDirty) {
			onDirty(false);
		}
	};

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleBlur,
		setFieldValue,
		handleChange,
		setFieldTouched,
		resetForm,
		setValues,
	} = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			let response;
			setIsSavingChanges(true);

			try {
				if (isCreateMode) {
					response = await createInvoice(abortController.signal, {
						...values,
						saasCurrency: values.saasCurrency.value ?? 'EUR',
						paymentCurrency: values.paymentCurrency.value ?? 'EUR',
						dueDate: values.dueDate,
						issueDate: values.issueDate,
						projectExternalId: data.projectExternalId,
						status: { status: values.status.value },
						templateId: values?.invoiceTemplate?.value,
						bankAccountId: values?.bankAccount?.value,
						paymentDate: values.status.value === INVOICE_STATUSES.PAID ? values.paymentDate : null,
						eurBankAccountId: values?.eurBankAccount?.value,
					});

					if (onDirty) {
						onDirty(false);
					}

					notify(t('Invoice created successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				} else {
					const requestValues = convertInvoiceValuesToRequestData(values);
					response = await patchInvoice(abortController.signal, invoice.id, requestValues);

					if (onDirty) {
						onDirty(false);
					}

					notify(t('Invoice data updated successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				}

				await onSubmit(response.data);
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});

	useEffect(() => {
		(async () => {
			if (initData?.projectId) {
				try {
					setIsProjectLoading(true);
					const response = await getProjectById(abortController.signal, initData?.projectId, true);
					setContractType(response?.data?.contractType?.name);
					setIsProjectLoading(false);
					setCountryId(response?.data?.country?.id);
				} catch (e) {
					setIsProjectLoading(false);
					showToastError(e, t('An error occured while trying to fetch invoice data'));
				}
			}
		})();
	}, [initData?.projectId, abortController.signal, t]);

	useEffect(() => {
		const calculatedValues = dirty
			? calculateInvoiceFields(
					values.subTotalExcludingTax,
					values.countryVatPercentage,
					values.countryWhtPercentage,
					values.countryWhtVatPercentage,
					values.countryNHILPercentage,
					values.countryCovid19LevyPercentage,
					values.countryGetFundLevyPercentage,
					values.taxFree,
					isGhana,
					isKenya,
					contractType,
			  )
			: _.pick(
					calculateInvoiceFields(
						values.subTotalExcludingTax,
						values.countryVatPercentage,
						values.countryWhtPercentage,
						values.countryWhtVatPercentage,
						values.countryNHILPercentage,
						values.countryCovid19LevyPercentage,
						values.countryGetFundLevyPercentage,
						values.taxFree,
						isGhana,
						isKenya,
						contractType,
					),
					['subTotalTaxableValue'],
			  );
		setValues({ ...values, ...calculatedValues });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		values.subTotalExcludingTax,
		values.countryVatPercentage,
		values.countryWhtPercentage,
		values.countryWhtVatPercentage,
		values.countryNHILPercentage,
		values.countryCovid19LevyPercentage,
		values.countryGetFundLevyPercentage,
		setValues,
	]);

	const prefIsInvoiceRestricted = useRef();
	const isInvoiceRestricted = useMemo(
		() => RESTRICTED_INVOICE_STATUSES.includes(values?.status?.value),
		[values?.status?.value],
	);
	useEffect(() => {
		setIsInvoiceRestricted(isInvoiceRestricted);
	}, [isInvoiceRestricted, setIsInvoiceRestricted]);

	const displayedStatus = useMemo(() => {
		if (values?.status?.value === INVOICE_STATUSES.PAID) {
			if (values?.paymentDate) {
				const isOnTime =
					isBefore(values.paymentDate, values.dueDate) || isSameDay(values.paymentDate, values.dueDate);
				if (isOnTime) {
					return INVOICE_STATUSES_LABELS.OVERDUE.ON_TIME;
				}

				const isOverdueLessThan15Days =
					(isAfter(values.paymentDate, values.dueDate) &&
						isBefore(values.paymentDate, addDays(values.dueDate, 15))) ||
					isSameDay(values.paymentDate, addDays(values.dueDate, 15));

				if (isOverdueLessThan15Days) {
					return INVOICE_STATUSES_LABELS.OVERDUE.OVERDUE_LESS_THAN_15_DAYS;
				}

				const isOverdueMoreThan15Days =
					isAfter(values.paymentDate, addDays(values.dueDate, 15)) &&
					isBefore(values.paymentDate, addDays(values.dueDate, 30));

				if (isOverdueMoreThan15Days) {
					return INVOICE_STATUSES_LABELS.OVERDUE.OVERDUE_MORE_THAN_15_DAYS;
				}

				const isOverdueMoreThan30Days = isAfter(values.paymentDate, addDays(values.dueDate, 30));

				if (isOverdueMoreThan30Days) {
					return INVOICE_STATUSES_LABELS.OVERDUE.OVERDUE_MORE_THAN_30_DAYS;
				}
			}
			return values?.status?.label;
		}

		return values?.status?.label;
	}, [values.dueDate, values.paymentDate, values?.status?.label, values?.status?.value]);

	useEffect(() => {
		if (isInvoiceRestricted && prefIsInvoiceRestricted.current !== isInvoiceRestricted) {
			// ? When user selects restricted status, we need to reset the fields from the Details Tile
			setValues({
				...initialValues,
				...pick(values, [
					'countryVatPercentage',
					'countryWhtPercentage',
					'countryWhtVatPercentage',
					'status',
					'saasCurrency',
					'paymentCurrency',
					'exchangeRate',
					'invoiceTemplate',
					'bankAccount',
					'vat',
					'wht',
					'whtVat',
					'subTotalExcludingTax',
					'subTotal',
					'totalAmount',
					'nhil',
					'covid19Levy',
					'getFundLevy',
					'countryNHILPercentage',
					'countryCovid19LevyPercentage',
					'countryGetFundLevyPercentage',
				]),
			});
			// ? To prevent memory leak as we update values and we also depend on them
			// ? We want to change them only when we change to restricted status
			prefIsInvoiceRestricted.current = isInvoiceRestricted;
		}
	}, [initialValues, isInvoiceRestricted, setValues, values]);

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form onSubmit={handleSubmit}>
			{isEditMode && (
				<OverlayTopSection>
					<InfoText
						item={{
							icon: 'euro',
							value: values?.totalAmount || 1,
							unit: 'EUR',
							text: 'Total Amount',
							gradient: colors.primary.dark,
						}}
						omitValueFormat
						fullWidth
					/>
					<InvoiceActions
						invoice={invoice}
						onDataChange={onSubmit}
						countryId={countryId}
						onWrapperClick={e => e.stopPropagation()}
						$areBigActions
					/>
				</OverlayTopSection>
			)}

			<TilesContainer>
				<InvoiceGeneralTile
					invoice={invoice}
					isEditMode={isEditMode}
					entityId={data.entityId}
					countryId={countryId}
					isLoading={isSavingChanges}
					values={values}
					errors={errors}
					touched={touched}
					setFieldValue={setFieldValue}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldTouched={setFieldTouched}
					displayedStatus={displayedStatus}
				/>
				<InvoiceDetailsTile
					isInvoiceRestricted={isInvoiceRestricted}
					isLoading={isSavingChanges}
					isLoadingContent={isProjectLoading}
					contractType={contractType}
					isGhana={isGhana}
					isKenya={isKenya}
					values={values}
					errors={errors}
					touched={touched}
					setFieldValue={setFieldValue}
					handleChange={handleChange}
					handleBlur={handleBlur}
					setFieldTouched={setFieldTouched}
				/>
			</TilesContainer>
			<OverlayButtons onCancel={onClose} submitButtonText={isEditMode ? 'Save Changes' : 'Create Invoice'} />
		</Form>
	);
};

InvoiceForm.defaultProps = {
	data: {},
	invoice: {},
	isSavingChanges: false,
	onClose: () => {},
	onDirty: () => {},
	onSubmit: () => {},
	setIsInvoiceRestricted: () => {},
	setIsSavingChanges: () => {},
};

InvoiceForm.propTypes = {
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
	data: PropTypes.shape({
		//Create specific
		newInvoiceNumber: PropTypes.string,
		projectExternalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		//Edit specific
		invoiceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		//Both
		entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		country: PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			isoCode: PropTypes.string,
		}),
	}),
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	onDirty: PropTypes.func,
	invoice: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		invoiceNumber: PropTypes.string,
		invoiceDate: PropTypes.string,
		dueDate: PropTypes.string,
		paymentDate: PropTypes.string,
		status: PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.string,
		}),
		currency: PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.string,
		}),
		exchangeRate: PropTypes.number,
		invoiceTemplate: PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.string,
		}),
		bankAccount: PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.string,
		}),
		country: PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			isoCode: PropTypes.string,
		}),
		totalAmount: PropTypes.number,
	}),
	setIsInvoiceRestricted: PropTypes.func,
	isSavingChanges: PropTypes.bool,
	setIsSavingChanges: PropTypes.func,
};

export default InvoiceForm;
