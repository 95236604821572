import { useMemo, useState } from 'react';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import Icon from 'Common/components/icons/Icon';
import ReactTimeAgo from 'react-time-ago';
import archieveNotification from 'Notifications/api/archieveNotification';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import { updateCurrentTab } from 'Application/reducers/reduxTabs';
import { updateSidePanelData } from 'Application/reducers/reduxSidePanel';
import getStepById from 'PMWorkflow/api/getStepById';
import showToastError from 'Common/utils/showToastError';
import PropTypes from 'prop-types';
import useAbortController from 'Common/hooks/useAbortController';
import { SIDE_PANEL_CONTENT_TYPES } from 'Common/components/SidePanel/constants/sidePanelContentTypes';

const ArchiveBtn = styled.div`
	display: none;
	position: absolute;
	right: ${sizes.spacing(1.5)};
	top: ${sizes.spacing(1.5)};
	width: fit-content;
	z-index: 1;

	&:hover {
		color: ${colors.primary.dark};
	}
`;

const defaultNotificationStyles = css`
	background-color: ${colors.common.tabsGrey};

	&:hover {
		border: 1px solid ${colors.primary.dark};
		${ArchiveBtn} {
			display: block;
		}
	}
`;
const archievedNotificationStyles = css`
	background-color: ${colors.grey.light};
`;

const removedNotificationStyles = key => css`
	@keyframes ${`fadeOut${key}`} {
		0% {
			opacity: 1;
			transform: translateY(0);
			max-height: 600px;
			margin-bottom: ${sizes.spacing(2)};
			padding: ${sizes.spacing(2)};
		}

		30% {
			opacity: 0.3;
		}

		70% {
			opacity: 0;
			transform: translateY(50%);
			max-height: 0px;
			margin-bottom: ${sizes.spacing(1)};
			padding: ${sizes.spacing(1)};
		}

		100% {
			opacity: 0;
			transform: translateY(100%);
			max-height: 0px;
			margin-bottom: 0;
			padding: 0;
		}
	}

	transition: all 0.1s ease-in-out;
	animation: ${`fadeOut${key}`} 0.5s ease-in-out forwards;
`;

const newlyAddedNotificationStyles = key => css`
	@keyframes ${`fadeIn${key}`} {
		0% {
			opacity: 0;
			transform: translateX(100%);
			max-height: 0px;
			margin-bottom: 0;
		}
		100% {
			opacity: 1;
			transform: translateX(0);
			max-height: 100%;
			margin-bottom: ${sizes.spacing(2)};
		}
	}

	animation: ${`fadeIn${key}`} 1.3s ease-in-out forwards;
`;

const NotificationWrapper = styled.div`
	padding: ${sizes.spacing(2)};
	border-radius: 10px;
	border: 1px solid transparent;
	position: relative;
	margin-bottom: ${sizes.spacing(2)};
	cursor: pointer;
	max-height: 130px;
	z-index: 0;

	${({ $isArchive }) => ($isArchive ? archievedNotificationStyles : defaultNotificationStyles)}

	${({ $isRemoved, $id }) => $isRemoved && removedNotificationStyles($id)}

	${({ $isNewlyAdded, $id }) => $isNewlyAdded && newlyAddedNotificationStyles($id)}
`;

const NotificationTitle = styled.div`
	position: relative;
	color: ${colors.secondary.dark};
	font-weight: 700;
	font-size: 16px;
	margin-bottom: 20px;

	&:after {
		content: '';
		position: absolute;
		width: 32px;
		height: 1px;
		background-color: ${colors.secondary.dark};
		bottom: -7px;
		left: 0;
	}
`;

const NotificationContent = styled.div``;
const NotificationContentText = styled.div`
	font-size: 16px;
`;

const NotificationTimeAgo = styled(ReactTimeAgo)`
	font-size: 12px;
	color: ${colors.text.grey};
`;

const Notification = ({ notification, isArchive }) => {
	const dispatch = useDispatch();
	const [isRemoved, setIsRemoved] = useState(false);

	const abortController = useAbortController();

	const {
		id,
		projectId,
		stepId,
		projectExternalId,
		clientName,
		description,
		createdDate,
		type,
		isNewlyAdded,
	} = notification;
	const title =
		type === 'PROJECT_DELETED'
			? 'Deleted project'
			: `${projectExternalId} - ${clientName ? clientName : 'Unknown client'}`;

	const descriptionText = type === 'PROJECT_DELETED' ? 'Project ' + description : description;

	const isGeneralTabNotification = useMemo(
		() =>
			type === 'ASSIGNED_AS_RESPONSIBLE_PERSON' ||
			type === 'REMOVED_AS_RESPONSIBLE_PERSON' ||
			type === 'PROJECT_STATUS_CHANGE',
		[type],
	);

	const isInvoiceTabNotification = useMemo(() => type === 'INVOICE_CREATED', [type]);

	const archiveStep = async e => {
		e.stopPropagation();
		try {
			await archieveNotification(abortController.signal, id);
			setIsRemoved(true);
		} catch (error) {
			showToastError(error);
		}
	};

	const handleOnClick = async () => {
		if (type === 'PROJECT_DELETED') return;

		dispatch(push(`/projects/details/${projectId}`));

		const tabIndex = isGeneralTabNotification ? 0 : isInvoiceTabNotification ? 5 : 1;

		dispatch(
			updateCurrentTab({
				tab: 'projects',
				tabIndex,
			}),
		);

		const stepNotificationTypes = ['STEP_UNBLOCKED', 'STEP_STATUS_CHANGE', 'STEP_COMMENT'];
		if (stepNotificationTypes.includes(type)) {
			try {
				const step = await getStepById(abortController.signal, stepId);
				const activityId = step.activityId;

				setTimeout(() => {
					dispatch(
						updateSidePanelData({
							isOpen: true,
							type: SIDE_PANEL_CONTENT_TYPES.PROJECT_STEP,
							isLoading: true,
							contentData: {
								project: {
									id: projectId,
								},
								step: {
									id: stepId,
								},
								showHistory: false,
								activityId: activityId,
							},
						}),
					);
				}, 500);
			} catch (error) {
				showToastError(error);
			}
		}
	};

	const dateCreatedMs = new Date(createdDate);

	return (
		<NotificationWrapper
			$isArchive={isArchive}
			$isRemoved={isRemoved}
			$isNewlyAdded={isNewlyAdded}
			$id={id}
			onClick={handleOnClick}
		>
			<ArchiveBtn onClick={archiveStep}>
				<Icon icon="close"></Icon>
			</ArchiveBtn>

			<NotificationTitle>{title}</NotificationTitle>

			<NotificationContent>
				<NotificationContentText>{descriptionText}</NotificationContentText>
			</NotificationContent>
			<NotificationTimeAgo date={dateCreatedMs} timeStyle="round" />
		</NotificationWrapper>
	);
};

Notification.defaultProps = {
	isArchive: false,
};

Notification.propTypes = {
	notification: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		projectId: PropTypes.number,
		stepId: PropTypes.number,
		projectExternalId: PropTypes.number,
		clientName: PropTypes.string,
		description: PropTypes.string,
		createdDate: PropTypes.string,
		type: PropTypes.string,
		isNewlyAdded: PropTypes.bool,
	}).isRequired,
	isArchive: PropTypes.bool,
};

export default Notification;
