import api from 'Application/api/api';

const createManufacturer = (signal, values) =>
	api.post(
		'/manufacturers',

		values,
		{
			signal,
		},
	);

export default createManufacturer;
