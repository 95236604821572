import getProductTypes from 'Projects/api/getProductTypes';

const loadProductTypeOptions = async (signal, query) => {
	const response = await getProductTypes(signal);
	const productTypes = response.data;

	return productTypes
		.filter(productType => productType.name && productType.name !== '-')
		.map(productType => ({
			value: productType.name,
			label: productType.name,
		}))
		.filter(option => option.label && option.label.toLowerCase().includes(query.toLowerCase()));
};

export default loadProductTypeOptions;
