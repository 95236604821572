// TODO remove the eslint complexity and fix it
/* eslint-disable complexity */
import { useState, useEffect, useMemo, useCallback } from 'react';
import {
	startOfMonth,
	getISODay,
	set,
	add,
	sub,
	isAfter,
	isSameDay,
	isBefore,
	getDate,
	isSameMonth,
} from 'date-fns';
import CustomDebounce from 'Common/utils/CustomDebounce';

const daysOfTheWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const isBetween = (date, startDate, endDate) => {
	if (isAfter(startDate, endDate)) {
		throw new Error('startDate must be before endDate');
	}
	return isAfter(date, startDate) && isBefore(date, endDate);
};

export const generateSingleDateDayData = (today, start, startHover) => {
	const isStart = isSameDay(today, start);
	const isStartHover = isSameDay(today, startHover);

	return {
		isStart,
		isStartHover,
		isEnd: isStart,
		isEndHover: isStartHover,
	};
};

export const generateDualDateDayData = (today, start, end, startHover, endHover) => {
	const isTodayStart = isSameDay(today, start);
	const isTodayBeforeStart = isBefore(today, start);
	const isTodayAfterStart = isAfter(today, start);

	const isTodayEnd = isSameDay(today, end);
	const isTodayBeforeEnd = isBefore(today, end);
	const isTodayAfterEnd = isAfter(today, end);

	const isTodayStartHover = isSameDay(today, startHover);
	// const isTodayBeforeStartHover = isBefore(today, startHover);
	const isTodayAfterStartHover = isAfter(today, startHover);

	const isTodayEndHover = isSameDay(today, endHover);
	const isTodayBeforeEndHover = isBefore(today, endHover);
	// const isTodayAfterEndHover = isAfter(today, endHover);

	let isStart = false;
	let isStartHover = false;
	let isInRange = false;
	let isEnd = false;
	let isEndHover = false;

	if (!start && !end) {
		if (startHover) {
			if (isTodayStartHover) {
				isStartHover = true;
				isEndHover = true;
			}
		} else if (endHover) {
			if (isTodayEndHover) {
				isStartHover = true;
				isEndHover = true;
			}
		}
	} else if (start && end) {
		if (startHover) {
			if (isTodayStart && isTodayEnd) {
				if (isTodayStartHover) {
					isStart = true;
					isEnd = true;
				} else if (isTodayAfterStartHover) {
					isEnd = true;
				}
			} else if (isTodayStart && isTodayStartHover) {
				if (isTodayBeforeEnd) {
					isStartHover = true;
				}
			} else if (isTodayStartHover && isTodayEnd) {
				if (isTodayAfterStart) {
					isStart = true;
					isEnd = true;
				}
			} else if (isTodayStartHover) {
				isStartHover = true;
				if (isTodayAfterEnd) {
					isEndHover = true;
				}
			} else if (isTodayEnd) {
				if (isTodayAfterStartHover) {
					isEnd = true;
				}
			} else if (isTodayStart) {
				if (isTodayBeforeEnd) {
					if (isTodayAfterStartHover) {
						isInRange = true;
					}
				}
			} else {
				if (isTodayAfterStart) {
					if (isTodayBeforeEnd) {
						if (isTodayAfterStartHover) {
							isInRange = true;
						}
					}
				} else if (isTodayBeforeStart) {
					if (isTodayAfterStartHover) {
						isInRange = true;
					}
				}
			}
		} else if (endHover) {
			if (isTodayStart && isTodayEnd && isTodayEndHover) {
				isStart = true;
				isEnd = true;
			} else if (isTodayStart && isTodayEnd) {
				if (isTodayBeforeEndHover) {
					isStart = true;
				}
			} else if (isTodayEnd && isTodayEndHover) {
				if (isTodayAfterStart) {
					isEndHover = true;
				}
			} else if (isTodayStart && isTodayEndHover) {
				if (isTodayBeforeEnd) {
					isStart = true;
					isEnd = true;
				}
			} else if (isTodayStart) {
				if (isTodayBeforeEnd) {
					if (isTodayBeforeEndHover) {
						isStart = true;
					}
				}
			} else if (isTodayEndHover) {
				if (isTodayAfterStart) {
					isEndHover = true;
				} else if (isTodayBeforeStart) {
					isStartHover = true;
					isEndHover = true;
				}
			} else if (isTodayEnd) {
				if (isTodayBeforeEndHover) {
					isInRange = true;
				}
			} else {
				if (isTodayAfterStart && isTodayBeforeEndHover) {
					isInRange = true;
				}
			}
		} else {
			isStart = isTodayStart;
			isEnd = isTodayEnd;
			isInRange = isTodayAfterStart && isTodayBeforeEnd;
		}
	} else if (start && !end) {
		if (startHover) {
			if (isTodayStart && isTodayStartHover) {
				isStart = true;
				isEnd = true;
			} else if (isTodayStartHover) {
				isStart = true;
				isEnd = true;
			}
		} else if (endHover) {
			if (isTodayStart && isTodayEndHover) {
				isStart = true;
				isEnd = true;
			} else if (isTodayStart) {
				if (isTodayBeforeEndHover) {
					isStart = true;
				}
			} else if (isTodayEndHover) {
				if (isTodayAfterStart) {
					isEndHover = true;
				} else if (isTodayBeforeStart) {
					isStartHover = true;
					isEndHover = true;
				}
			} else {
				if (isTodayAfterStart && isTodayBeforeEndHover) {
					isInRange = true;
				}
			}
		} else {
			isStart = isTodayStart;
			isEnd = isStart;
		}
	} else if (!start && end) {
		if (startHover) {
			if (isTodayEnd && isTodayStartHover) {
				isStart = true;
				isEnd = true;
			} else if (isTodayEnd) {
				if (isTodayAfterStartHover) {
					isEnd = true;
				}
			} else if (isTodayStartHover) {
				if (isTodayBeforeEnd) {
					isStartHover = true;
				} else if (isTodayAfterEnd) {
					isStartHover = true;
					isEndHover = true;
				}
			} else {
				if (isTodayBeforeEnd && isTodayAfterStartHover) {
					isInRange = true;
				}
			}
		} else if (endHover) {
			if (isTodayEnd && isTodayEndHover) {
				isStart = true;
				isEnd = true;
			} else if (isTodayEndHover) {
				isStartHover = true;
				isEndHover = true;
			}
		} else {
			isEnd = isTodayEnd;
			isStart = isEnd;
		}
	}

	return {
		isStart,
		isStartHover,
		isInRange,
		isEnd,
		isEndHover,
	};
};

export const checkCurrentDate = (
	currentDay,
	today,
	start,
	end,
	{ isStart, isStartHover, isInRange, isEnd, isEndHover },
) => {
	return (
		!start &&
		!end &&
		!isStart &&
		!isStartHover &&
		!isInRange &&
		!isEnd &&
		!isEndHover &&
		isSameDay(currentDay, today)
	);
};

// ? Old and imperfect implementation
// export const generateDualDateDayData = (today, start, end, startHover, endHover) => {
// 	const isEndHoverBeforeStart = isBefore(endHover, start);
// 	const isStartHoverAfterEnd = isAfter(startHover, end);
// 	const isStartHoverBeforeEnd = isBefore(startHover, end);

// 	const isStart =
// 		((start ? isSameDay(today, start) : isSameDay(today, end)) &&
// 			!isEndHoverBeforeStart &&
// 			!isStartHoverAfterEnd &&
// 			!isStartHoverBeforeEnd &&
// 			!isSameDay(startHover, end)) ||
// 		(isSameDay(startHover, end) && isSameDay(today, startHover));

// 	const isStartHover =
// 		((isSameDay(today, startHover) || isSameDay(today, endHover)) && !start) ||
// 		(isEndHoverBeforeStart && isSameDay(today, endHover)) ||
// 		(isStartHoverBeforeEnd && isSameDay(today, startHover)) ||
// 		(isStartHoverAfterEnd && isSameDay(today, startHover));

// 	const isInRange =
// 		(isBetween(today, start, end) && !endHover && !startHover) ||
// 		isBetween(today, start, endHover) ||
// 		isBetween(today, startHover, end);

// 	const isEnd =
// 		(end ? isSameDay(today, end) : isStart) &&
// 		!isStartHoverAfterEnd &&
// 		(endHover ? isSameDay(today, endHover) : true);

// 	const isEndHover = endHover ? isSameDay(today, endHover) : isStartHover && (isStartHoverAfterEnd || !end);

// 	return {
// 		isStart,
// 		isStartHover,
// 		isInRange,
// 		isEnd,
// 		isEndHover,
// 	};
// };

export const generateCalendarData = (
	date,
	startDate,
	endDate,
	startHoverDate,
	innitialEndHoverDate,
	isDual,
) => {
	const nowDate = new Date();
	const endHoverDate = isDual ? innitialEndHoverDate : startHoverDate;
	const newDate = [];

	const year = date.getFullYear();

	let currentDay = sub(startOfMonth(date), {
		days: getISODay(startOfMonth(date)) - 1,
	});

	for (let i = 0; i < 6; i++) {
		newDate.push([]);
		for (let dateIndex = 0; dateIndex < 7; dateIndex++) {
			let dayObj = {
				day: getDate(currentDay),
				year,
				month: currentDay.getMonth(),
				otherMonth: !isSameMonth(currentDay, date),
			};

			const generatedData = isDual
				? generateDualDateDayData(currentDay, startDate, endDate, startHoverDate, endHoverDate, nowDate)
				: generateSingleDateDayData(currentDay, startDate, startHoverDate, nowDate);

			dayObj = {
				...dayObj,
				...generatedData,
				isCurrentDayToday: checkCurrentDate(currentDay, nowDate, startDate, endDate, generatedData),
			};

			newDate[i].push(dayObj);
			currentDay = add(currentDay, { days: 1 });
		}
	}
	return newDate;
};

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const useCalendarData = (
	propStartDate,
	propEndDate,
	dateType,
	startHoverDate,
	endHoverDate,
	isDual,
) => {
	const startDate = useMemo(
		() => (!(propStartDate instanceof Date) ? undefined : set(propStartDate, { hours: 0, minutes: 0 })),
		[propStartDate],
	);
	const endDate = useMemo(
		() => (!(propEndDate instanceof Date) ? undefined : set(propEndDate, { hours: 0, minutes: 0 })),
		[propEndDate],
	);

	const [currentDate, setCurrentDate] = useState(new Date());
	const [calendarData, setCalendarData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const currentYear = currentDate.getFullYear();
	const currentMonth = months[currentDate.getMonth()];

	const changeDate = (field, value) => {
		setCurrentDate(prevDate => set(prevDate, field === 'month' ? { month: value } : { year: value }));
	};

	useEffect(() => {
		const focusedDate = dateType === 'start' ? startDate : endDate;

		setCurrentDate(focusedDate ? focusedDate : startDate ? startDate : endDate ? endDate : new Date());
	}, [startDate, endDate, dateType]);

	const handleUpdateCalendarData = useCallback(() => {
		setCalendarData(
			generateCalendarData(currentDate, startDate, endDate, startHoverDate, endHoverDate, isDual),
			setIsLoading(false),
		);
	}, [currentDate, setCalendarData, startDate, endDate, startHoverDate, endHoverDate, isDual]);

	const debouncerInstance = useMemo(() => new CustomDebounce(), []);

	useEffect(() => {
		debouncerInstance.debounce(() => {
			handleUpdateCalendarData();
		}, 100);
	}, [handleUpdateCalendarData, debouncerInstance]);

	return {
		calendarData,
		daysOfTheWeek,
		currentMonth,
		currentYear,
		changeDate,
		months,
		isLoadingCalendarData: isLoading,
	};
};
