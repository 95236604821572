import Big from 'big.js';
import formatDateForRequestData from 'Common/utils/formatDateForRequestData';
import { contractTypes } from '../../../../ClientContract/constants/contractTypes';
import { ppaTariffTypes } from '../../../../ClientContract/constants/ppaTariffTypes';

// eslint-disable-next-line complexity
const convertFormValuesToRequestData = (data, epcOfferId) => {
	const isPpa = data.contractType?.label === contractTypes.PPA;
	const isLease = data.contractType?.label === contractTypes.Lease;
	const isFloating = data.ppaTariffType?.label === ppaTariffTypes.FLOATING;
	const isFixed = data.ppaTariffType?.label === ppaTariffTypes.FIXED;

	return {
		annualOmPrice: data.annualOmPrice
			? {
					value: data.annualOmPrice,
					currency: data.currency.value,
			  }
			: undefined,
		averageDscr: data.averageDscr,
		billingPeriod: data.billingPeriod,
		ceilingTariff:
			data.ceilingTariff && isPpa && isFloating
				? {
						value: data.ceilingTariff,
						currency: data.saasCurrency.value,
				  }
				: undefined,
		clientUpfrontPayment: data.clientDownpayment
			? {
					currency: data.currency.value,
					value: data.clientDownpayment,
			  }
			: undefined,
		contractTypeName: data.contractType.label,
		currency: data.currency.value,
		duration: data.contractTerm,
		epcOfferId: epcOfferId,
		epcVat: data.epcVatApplicable,
		epcVolume: data.epcVolume
			? {
					value: data.epcVolume,
					currency: data.currency.value,
			  }
			: undefined,
		escalationRateInPercent: data.escalationRateInPercent
			? Number(Big(data.escalationRateInPercent).div(100).toString())
			: undefined,
		expectedClientSavings: data.expectedClientSavings
			? {
					value: data.expectedClientSavings,
					currency: data.saasCurrency.value,
			  }
			: undefined,
		extraCapexExpected: data.extraCapexExpected,
		extraOpexExpected: data.extraOpexExpected,
		floorTariff:
			data.floorTariff && isPpa && isFloating
				? {
						value: data.floorTariff,
						currency: data.saasCurrency.value,
				  }
				: undefined,
		irr: data.irr ? Number(Big(data.irr).div(100).toString()) : undefined,
		minimumDscr: data.minimumDscr,
		minimumPurchaseAmount: data.minimumPurchaseAmount
			? Number(Big(data.minimumPurchaseAmount).div(100).toString())
			: undefined,
		minimumWarrantedFunctionality: data.minimumWarrantedFunctionality
			? Number(Big(data.minimumWarrantedFunctionality).div(100).toString())
			: undefined,
		discountOnGridTariff: data.discountOnGridTariff
			? Number(Big(data.discountOnGridTariff).div(100).toString())
			: undefined,
		monthlyLeaseRate:
			data.leaseFee && isLease
				? {
						value: data.leaseFee,
						currency: data.saasCurrency.value,
				  }
				: undefined,
		mountingTypes: data.mountingTypes
			? data.mountingTypes.map(mountingType => ({ mountingType: mountingType.value }))
			: undefined,
		offerDate: data.offerDate ? formatDateForRequestData(data.offerDate) : undefined,
		annualOmEscalationRate: data.annualOmEscalationRate
			? Number(Big(data.annualOmEscalationRate).div(100).toString())
			: undefined,
		p90: data.p90,
		percentageOnGridTariff:
			data.percentageOnGridTariff && isPpa && isFloating
				? Number(Big(data.percentageOnGridTariff).div(100).toString())
				: undefined,
		ppaPricePerKwh:
			data.ppaTariff && isPpa && isFixed
				? {
						currency: data.saasCurrency.value,
						value: data.ppaTariff,
				  }
				: undefined,
		ppaTariffType: data.ppaTariffType
			? {
					displayName: data.ppaTariffType.label,
					ppaTariffType: data.ppaTariffType.value,
			  }
			: undefined,
		primaryOffer: data.primaryOffer,
		saasCurrency: data.saasCurrency.value,
		solarEnergyReplacement: data.solarEnergyReplacement,
		solarPvDegradation: data.solarPvDegradation
			? Number(Big(data.solarPvDegradation).div(100).toString())
			: undefined,
		solarUtilizationRate: data.solarUtilizationRate
			? Number(Big(data.solarUtilizationRate).div(100).toString())
			: undefined,
		systemSize: data.systemSize,
		worstCaseIrr: data.worstCaseIrr ? Number(Big(data.worstCaseIrr).div(100).toString()) : undefined,
		yield: data.yield,
	};
};

export default convertFormValuesToRequestData;
