import { useMemo } from 'react';
import * as yup from 'yup';
import yupUrl from 'Common/utils/yupUrl';

const useEpcPartnerDetailsFormValidationSchema = () =>
	useMemo(
		() =>
			yup.object({
				website: yupUrl(),
				name: yup.string().required('Required'),
			}),
		[],
	);

export default useEpcPartnerDetailsFormValidationSchema;
