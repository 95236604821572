import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'Common/components/Link';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';

import Dialog from 'Common/components/Dialog';

const DialogText = styled.div`
	a {
		text-decoration: none;
	}
`;

const CookieConsentDialog = () => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const { pathname } = useLocation();
	const isBlurred = isOpen && pathname !== '/privacy-policy';

	const setStoredConsent = () => localStorage.setItem('cookieConsent', true);

	const onAccept = () => {
		setStoredConsent();
		setIsOpen(false);
	};

	useEffect(() => {
		const storedConsent = localStorage.getItem('cookieConsent');
		if (!storedConsent) {
			setIsOpen(true);
		}
	}, []);

	return (
		<Dialog
			isOpen={isOpen}
			fullWidth={true}
			isBlurred={isBlurred}
			leftButtonAction={onAccept}
			title={t('Cookie Consent')}
			leftButtonText={t('Accept')}
			message={
				<DialogText>
					{t(
						'We use cookies on our website to give the most relevant experience. You can learn about our Privacy policy',
					)}
					<Link to="/privacy-policy"> {t('here')}</Link>
				</DialogText>
			}
		/>
	);
};

export default CookieConsentDialog;
