import api from 'Application/api/api';

const getInvestorById = async (signal, id) => {
	const response = await api.get(`/investors/by-id/${id}`, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}
	return {
		...response,
	};
};

export default getInvestorById;
