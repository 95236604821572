import { useCallback, useEffect, useMemo, useRef, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSortBy } from 'react-table';
import { debounce, fill } from 'lodash';
import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';

import 'rc-pagination/assets/index.css';
import colors from 'Application/theme/colors';

import { Helmet } from 'react-helmet-async';
import Link from 'Common/components/Link';
import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import Filters from 'Common/components/filter/FiltersContainer';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import MenuItem from 'Common/components/buttons/MenuItemButton';

import formatDate from 'Common/utils/formatDate';
import { DefaultTable } from 'Common/components/table/table';
import formatCurrency from 'Common/utils/formatCurrency';
import TableCellText from 'Common/components/table/TableCellText';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import { useScrollableTableBody } from 'Common/hooks/table/plugin-hooks/useScrollableTableBody';
import { useVirtualizedTableBody } from 'Common/hooks/table/plugin-hooks/useVirtualizedTableBody';
import { useSortingQueryParameter } from 'Common/hooks/table/plugin-hooks/useSortingQueryParameter';
import {
	INVOICE_STATUSES,
	INVOICE_STATUSES_LABELS,
	INVOICE_STATUS_COLORS,
} from 'Invoice/constants/invoiceContstants';
import prefillTableData from 'Common/utils/prefillTableData';

import InvoiceOverlay from 'Invoice/components/Overlays/InvoiceOverlay';
import useInvoiceFilterData from 'Invoice/hooks/useInvoiceFilterData';

import PillWrapper from 'Common/components/PillWrapper';
import StatusPill from 'Common/components/StatusPill';

import getInvoices from 'Invoice/api/getInvoices';
import showToastError from 'Common/utils/showToastError';
import useUniqueAbortSignal from 'Common/hooks/useUniqueAbortSignal';
import crudModes from 'Common/constants/crudModes';
import { useSelector } from 'react-redux';
import getInvoiceById from 'Invoice/api/getInvoiceById';
import useAbortController from 'Common/hooks/useAbortController';
import { useCustomRowStyle } from 'Common/hooks/table/plugin-hooks/useCustomRowStyle';
import { stringify } from 'qs';

const Wrapper = styled.div`
	position: relative;
	pointer-events: ${({ hasOpenFilter }) => (hasOpenFilter ? 'none' : 'all')};

	@keyframes highlight {
		0% {
			background-color: ${colors.common.lightBeige};
		}
		100% {
			background-color: ${colors.common.white};
		}
	}
`;

const defaultSortBy = {
	id: 'id',
	desc: true,
};

const InvoicesList = () => {
	const { t } = useTranslation();
	const hasOpenFilter = useSelector(state => state?.tables?.invoices?.openedFilter);

	const [totalItemsCount, setTotalItemsCount] = useState(25);

	const sortByQueryParam = useQueryParameter('sortBy');
	const sortBy = sortByQueryParam ?? defaultSortBy;
	const filters = useQueryParameter('filters');

	const searchQuery = useQueryParameter('searchQuery');

	const [invoices, setInvoices] = useState(() => fill(Array(25), {}));
	const [isLoading, setIsLoading] = useState(true);
	const [selectedInvoice, setSelectedInvoice] = useState();

	const getUniqueSignal = useUniqueAbortSignal();

	const hasLoadedFirstResultsRef = useRef(false);

	const abortController = useAbortController();

	const getInvoicesBatch = useCallback(
		async (startIndex, stopIndex, filters, sortBy, searchQuery, hasItemsChanges = false) => {
			try {
				let response = await getInvoices(
					getUniqueSignal('get-invoices'),
					{
						offset: startIndex,
						limit: stopIndex - startIndex + 1,
						sortBy: sortBy.id,
						sortDirection: sortBy.desc ? 'DESC' : 'ASC',
					},
					filters,
					searchQuery,
				);

				if (hasItemsChanges || !hasLoadedFirstResultsRef.current) {
					const { itemsCount, itemsFilled, error } = prefillTableData(response.data, startIndex, stopIndex);
					if (error) {
						showToastError(error);
					} else {
						setInvoices(itemsFilled);
						setTotalItemsCount(itemsCount);
					}
				} else {
					setInvoices(prevInvoices =>
						prevInvoices.map((prevInvoice, index) => {
							if (startIndex <= index && index <= stopIndex) {
								const indexInResultSet = index - startIndex;
								return response.data.content[indexInResultSet] || {};
							}
							return prevInvoice;
						}),
					);
				}
				hasLoadedFirstResultsRef.current = true;
				if (hasItemsChanges) {
					setIsLoading(false);
				}
			} catch (error) {
				showToastError(error, "Can't fetch Invoices");
			}
		},
		[getUniqueSignal],
	);

	useEffect(() => {
		getInvoicesBatch(
			0,
			25,
			filters,
			{
				id: sortBy.id,
				desc: sortBy.desc,
			},
			searchQuery,
			true,
		);
	}, [getInvoicesBatch, searchQuery, filters, sortBy.desc, sortBy.id]);

	const handleLoadMoreRows = useMemo(
		() =>
			debounce(
				(startIndex, stopIndex) => {
					getInvoicesBatch(
						startIndex,
						stopIndex,
						filters,
						{
							id: sortBy.id,
							desc: sortBy.desc,
						},
						searchQuery,
					);
				},
				350,
				{
					leading: false,
					trailing: true,
				},
			),
		[getInvoicesBatch, searchQuery, filters, sortBy.desc, sortBy.id],
	);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Status'),
				accessor: 'status',
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original: invoice } }) =>
						invoice?.status?.status === INVOICE_STATUSES.PAID ? (
							<PillWrapper>
								<StatusPill color={INVOICE_STATUS_COLORS.OVERDUE[invoice?.overdueStatus?.status]}>
									{INVOICE_STATUSES_LABELS.OVERDUE[invoice?.overdueStatus?.status]}
								</StatusPill>
							</PillWrapper>
						) : (
							<PillWrapper>
								<StatusPill color={INVOICE_STATUS_COLORS[invoice?.status?.status]}>
									{INVOICE_STATUSES_LABELS[invoice?.status?.status]}
								</StatusPill>
							</PillWrapper>
						);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								overdueStatus: PropTypes.shape({ status: PropTypes.string }),
								status: PropTypes.shape({ status: PropTypes.string, displayName: PropTypes.string }),
								invoiceTemplate: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Number'),
				accessor: 'number',
				highlightSearchTerm: true,
				Cell: ({ row: { original: invoice } }) => (invoice?.number ? invoice?.number : '-'),
			},
			{
				Header: t('Project ID'),
				accessor: 'project.externalId',
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original: invoice } }) => (
						<Link
							color={colors.primary.main}
							fontSize="14px"
							to={`/projects/details/${invoice?.projectId}?tab=invoices`}
							onClick={e => e.stopPropagation()}
						>
							<TableCellText>{invoice?.projectExternalId}</TableCellText>
						</Link>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
								projectExternalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Client'),
				accessor: 'project.client.name',
				highlightSearchTerm: true,
				disableSortBy: true,
				customBodyCellContentStyles: {
					color: colors.primary.main,
				},
				Cell: (() => {
					const Cell = ({ row: { original: invoice } }) =>
						invoice.clientId && (
							<Link
								color={colors.primary.main}
								fontSize="14px"
								to={`/clients/details/${invoice.clientId}`}
								onClick={e => e.stopPropagation()}
							>
								<TableCellText>{invoice.clientName}</TableCellText>
							</Link>
						);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
								clientName: PropTypes.string,
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Entity'),
				disableSortBy: true,
				Cell: (() => {
					const Cell = ({ row: { original: invoice } }) => (
						<TableCellText>{invoice?.contractPartyName ? invoice?.contractPartyName : '-'}</TableCellText>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								contractPartyName: PropTypes.string,
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Country'),
				accessor: 'project.country',
				maxWidth: 104,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				highlightSearchTerm: true,
				Cell: (() => {
					const Cell = ({ row: { original: invoice } }) => <div title={invoice?.country}>{invoice?.country}</div>;
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ country: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Account Number'),
				accessor: 'bankAccount.accountNumber',
				highlightSearchTerm: true,
				disableSortBy: true,
				Cell: ({ row: { original: invoice } }) =>
					invoice?.bankAccount?.accountNumber ? invoice?.bankAccount?.accountNumber : '-',
			},
			{
				Header: t('Issue Date'),
				accessor: 'issueDate',
				highlightSearchTerm: true,
				Cell: ({ row: { original: invoice } }) => (invoice?.issueDate ? formatDate(invoice.issueDate) : '-'),
			},
			{
				Header: t('Due Date'),
				accessor: 'dueDate',
				highlightSearchTerm: true,
				Cell: ({ row: { original: invoice } }) => (invoice?.dueDate ? formatDate(invoice.dueDate) : '-'),
			},
			{
				Header: t('Payment date'),
				accessor: 'paymentDate',
				highlightSearchTerm: true,
				Cell: ({ row: { original: invoice } }) => (invoice?.paymentDate ? formatDate(invoice.paymentDate) : '-'),
			},
			{
				Header: t('Billing Period Start'),
				accessor: 'billingPeriodStart',
				highlightSearchTerm: true,
				width: 162,
				Cell: ({ row: { original: invoice } }) =>
					invoice?.billingPeriodStart ? formatDate(invoice.billingPeriodStart) : '-',
			},
			{
				Header: t('Billing Period End'),
				accessor: 'billingPeriodEnd',
				highlightSearchTerm: true,
				width: 162,
				Cell: ({ row: { original: invoice } }) =>
					invoice?.billingPeriodEnd ? formatDate(invoice.billingPeriodEnd) : '-',
			},
			{
				Header: t('Amount'),
				accessor: 'totalAmount',
				highlightSearchTerm: true,
				Cell: ({ row: { original: invoice } }) =>
					invoice?.totalAmount ? formatCurrency(invoice.totalAmount, 2, 'EUR') : '-',
			},
		],
		[t],
	);

	const data = useMemo(() => invoices, [invoices]);

	const itemsHash = useMemo(() => JSON.stringify({ filters, sortBy }), [filters, sortBy]);

	const handleRowClick = row => {
		if (!window.getSelection().toString()) {
			setSelectedInvoice(row.original);
		}
	};

	const getRowId = useCallback(
		(row, relativeIndex, parent) => (parent ? [parent.id, relativeIndex].join('.') : relativeIndex),
		[],
	);

	const refetchInvoices = useCallback(() => {
		getInvoicesBatch(
			0,
			25,
			filters,
			{
				id: sortBy.id,
				desc: sortBy.desc,
			},
			searchQuery,
			true,
		);
	}, [getInvoicesBatch, filters, sortBy.id, sortBy.desc, searchQuery]);

	const handleInvoiceSubmit = useCallback(
		async id => {
			const hasAppliedFilters = Boolean(filters) && Object.keys(filters).length > 0;
			if (hasAppliedFilters) {
				refetchInvoices();
			} else {
				try {
					setInvoices(prevInvoices => {
						const updatedInvoices = prevInvoices
							.map(invoice => {
								if (invoice.id === id) {
									return {
										...invoice,
										isLoading: true,
										isHighlighted: true,
									};
								}
								return { ...invoice, isHighlighted: false };
							})
							.filter(Boolean);
						return updatedInvoices;
					});

					const { data: updatedInvoice } = await getInvoiceById(abortController.signal, id);

					setInvoices(prevInvoices => {
						const updatedInvoices = prevInvoices.map(invoice => {
							if (invoice.id === id) {
								return { ...updatedInvoice, isHighlighted: true };
							}
							return { ...invoice, isHighlighted: false };
						});
						return updatedInvoices;
					});
				} catch (e) {
					showToastError(e, "Can't get updated invoice");
				}
			}
		},
		[abortController.signal, filters, refetchInvoices],
	);

	const handleInvoiceDelete = useCallback(async () => {
		refetchInvoices();
	}, [refetchInvoices]);

	const openExport = useCallback(
		exportWithOptions => {
			const sortById = sortByQueryParam?.id;
			let exportURL;

			if (exportWithOptions) {
				const formatedFilers = {
					...filters,
				};

				exportURL = `api/invoices/export-table-view?${
					sortByQueryParam
						? 'sort=' + sortById + '%2C' + (sortByQueryParam.desc ? 'DESC' : 'ASC')
						: 'sort=id%2CDESC'
				}&${stringify(formatedFilers)}&size=9999`;
			} else {
				exportURL = `api/invoices/export-table-view?&size=9999&${
					sortByQueryParam
						? 'sort=' + sortById + '%2C' + (sortByQueryParam.desc ? 'DESC' : 'ASC')
						: 'sort=id%2CDESC'
				}`;
			}

			window.open(exportURL, '_blank');
		},
		[sortByQueryParam, filters],
	);

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		getVirtualizedTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useStandardTable(
		{
			data,
			columns,
			defaultSortBy,
			searchTerm: searchQuery,
			onRowClick: handleRowClick,
			virtualization: {
				totalRowsCount: totalItemsCount,
				rowsHash: itemsHash,
				loadMoreRows: handleLoadMoreRows,
			},
			getCustomRowStyles: row =>
				row.original.isHighlighted
					? row.original.isLoading
						? {
								backgroundColor: colors.common.lightBeige,
						  }
						: {
								animation: 'highlight 5s ease-in-out forwards',
						  }
					: {},
			getRowId,
		},
		useClickableTableRow,
		useCustomCellStyles,
		useSortBy,
		useSortingQueryParameter,
		useScrollableTableBody,
		useVirtualizedTableBody,
		useCustomRowStyle,
	);

	const filterButtonRef = useRef();
	const { filtersData } = useInvoiceFilterData();
	return (
		<>
			<Helmet>
				<title>{t('Invoices')}</title>
			</Helmet>
			<HeaderContainer>
				<HeaderTitle>{t('Invoices')}</HeaderTitle>
				<HeaderActions>
					<div ref={filterButtonRef}></div>
					<MoreActionsButton label="Invoices More Actions">
						{filters && (
							<MenuItem
								onClick={() => openExport(true)}
								type="button"
								data-action="exportFiltered"
								label="Invoices - Export filtered to CSV Menu Item"
							>
								{t('Export filtered to CSV')}
							</MenuItem>
						)}
						<MenuItem
							onClick={() => openExport(false)}
							type="button"
							data-action="exportAll"
							label="Invoices - Export all to CSV Menu Item"
						>
							{t('Export all to CSV')}
						</MenuItem>
					</MoreActionsButton>
				</HeaderActions>
			</HeaderContainer>
			<Filters table="invoices" buttonRef={filterButtonRef} filtersData={filtersData} />
			<Wrapper hasOpenFilter={hasOpenFilter}>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					getVirtualizedTableBodyProps={getVirtualizedTableBodyProps}
					rows={rows}
					headerGroups={headerGroups}
					prepareRow={prepareRow}
					isLoading={isLoading || rows.length !== totalItemsCount}
					showNoResultsFound
				/>

				{selectedInvoice && (
					<InvoiceOverlay
						mode={crudModes.EDIT}
						data={{
							invoiceId: selectedInvoice.id,
							projectId: selectedInvoice.projectId,
							entityId: selectedInvoice.contractPartyId,
						}}
						onFormSubmit={() => handleInvoiceSubmit(selectedInvoice.id)}
						onInvoiceDelete={handleInvoiceDelete}
						isOpen={Boolean(selectedInvoice)}
						onClose={() => setSelectedInvoice(null)}
					/>
				)}
			</Wrapper>
		</>
	);
};

export default InvoicesList;
