const useProjectImpactInitialValues = () => ({
	sumTotalSigned: 0,
	countTotalSigned: 0,
	clientCount: 0,
	sumTotalInOperation: 0,
	countTotalInOperation: 0,
	clientCountInOperation: 0,
	numberOfEmployees: 0,
	volumeInvested: 0,
	averageEpcPrice: 0,
	statusChartInOperation: 0,
	statusChartNotSigned: 0,
	statusChartFunded: 0,
	statusChartSigned: 0,
	industriesChartTotal: 0,
	femaleShare: 0,
	projectStatistics: {
		contractToDateEnergySavings: 0,
		lifetimeToDateEnergySavings: 0,
		annuallyAverageEnergySavingsOverContract: 0,
		annuallyAverageEnergySavingsOverLifetime: 0,
		contractEnergySavings: 0,
		lifetimeEnergySavings: 0,
		contractToDateCO2EmissionsAvoided: 0,
		lifetimeToDateCO2EmissionsAvoided: 0,
		contractCO2EmissionsAvoided: 0,
		lifetimeCO2EmissionsAvoided: 0,
		contractToDateEnergyUsed: 0,
		lifetimeToDateEnergyUsed: 0,
		usableEnergyYield: 0,
		contractEnergyUsed: 0,
		lifetimeEnergyUsed: 0,
		systemSizeTotal: 0,
	},
	industriesChartRestricted: 0,
	industriesChartNotRestricted: 0,
	companiesChartTotal: 0,
	salesChannelBarChart: [],
	companiesChartLarge: 0,
	companiesChartMedium: 0,
	companiesChartSmall: 0,
});

export default useProjectImpactInitialValues;
