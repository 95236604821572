import Grid from '@mui/material/Grid';
import formatNumber from 'Common/utils/formatNumber';
import { PropTypes } from 'prop-types';
import TableHeader from 'Impact/components/ImpactTable/ImpactTableHeader';

import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';

const Wrapper = styled.div`
	width: ${({ $isMobile }) => ($isMobile ? '100%' : `calc(100% - ${sizes.spacing(1.5)})`)};
	height: 100%;
`;

const TableRowText = styled.td`
	height: 40px;
	text-align: center;
	font-size: 14px;
	color: ${({ $textColor }) => $textColor || colors.text.black};
	font-weight: ${({ $isBold }) => $isBold && 700};
	white-space: nowrap;
`;

const TableColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 0px;
`;

const TableWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	padding: ${({ $isMobile }) => ($isMobile ? '4px' : '0px 0px 8px 16px')};
`;

const ImpactTable = ({ data, rowLabels, columnLabels, cells, isSmallTable, isBigTable }) => {
	const getFormattedRowCols = (rowLabels, columnLabels, isSmallTable, isBigTable) => {
		let columns = columnLabels.slice();
		let rows = rowLabels.slice();
		if (isSmallTable || isBigTable) {
			columns.unshift({});
		} else {
			rows.unshift({});
		}
		return { columns, rows };
	};

	const formatTableRow = (data, unit, colIndex) => {
		const isCompact = !isSmallTable && !isBigTable;
		const isRoundable = isCompact ? (colIndex > 2 ? 1 : 0) : colIndex > 3 ? 1 : 0;
		const result = data ? (isRoundable ? formatNumber(Number(data).toFixed(1), 1) : formatNumber(data, 0)) : 0;
		if (unit === '€') {
			return `${unit} ${result}`;
		} else {
			return unit ? `${result} ${unit}` : `${result}`;
		}
	};

	const { rows, columns } = getFormattedRowCols(rowLabels, columnLabels, isSmallTable, isBigTable);

	return (
		<Wrapper $isMobile={isSmallTable}>
			<Grid item xs={12}>
				{isSmallTable && (
					<>
						{rows?.map((row, rowIndex) => (
							<TableWrapper key={`table-wrapper-${row.value}`}>
								<TableColumn>
									{columns.map(col => (
										<TableHeader key={`table-header-${col.label}`} row={col} />
									))}
								</TableColumn>
								<TableColumn>
									<TableHeader row={row} />
									{columns.map((col, colIndex) => {
										if (colIndex > 0) {
											const { accessor, styles } = cells[colIndex - 1][rowIndex];
											return (
												<TableRowText
													$textColor={styles?.textColor}
													$isBold={styles?.isBold}
													key={`table-text-${col.label}`}
													row={col}
												>
													{formatTableRow(data[accessor], row.unit)}
												</TableRowText>
											);
										}
									})}
								</TableColumn>
							</TableWrapper>
						))}
					</>
				)}
				{isBigTable && (
					<TableWrapper>
						{columns.map((col, colIndex) => (
							<TableColumn key={`table-column-${col.value}`}>
								<TableHeader row={col} />
								{rows.map((row, rowIndex) => {
									if (colIndex === 0) {
										return <TableHeader row={row} key={`table-header-${row.label}`} />;
									} else {
										const { accessor, unit, styles } = cells[colIndex - 1][rowIndex];
										return (
											<TableRowText
												$textColor={styles?.textColor}
												$isBold={styles?.isBold}
												key={`table-text-${row.label}`}
											>
												{formatTableRow(data[accessor], unit, colIndex)}
											</TableRowText>
										);
									}
								})}
							</TableColumn>
						))}
					</TableWrapper>
				)}
				{!isSmallTable && !isBigTable && (
					<TableWrapper>
						{rows.map((row, rowIndex) => (
							<TableColumn key={`table-column-${row.value}`}>
								<TableHeader row={row} />
								{columns.map((col, colIndex) => {
									if (rowIndex === 0) {
										return <TableHeader row={col} key={`table-header-${col.label}`} isRow />;
									} else {
										const { accessor, unit, styles } = cells[colIndex][rowIndex - 1];
										return (
											<TableRowText
												$textColor={styles?.textColor}
												$isBold={styles?.isBold}
												key={`table-text-${col.label}`}
											>
												{formatTableRow(data[accessor], unit, colIndex)}
											</TableRowText>
										);
									}
								})}
							</TableColumn>
						))}
					</TableWrapper>
				)}
			</Grid>
		</Wrapper>
	);
};

ImpactTable.defaultProps = {
	data: null,
	rowLabels: [],
	columnLabels: [],
	cells: [],
	isSmallTable: false,
	isBigTable: false,
};

ImpactTable.propTypes = {
	data: PropTypes.shape({
		contractToDateCO2EmissionsAvoided: PropTypes.number.isRequired,
		contractToDateEnergySavings: PropTypes.number.isRequired,
		contractEnergyUsed: PropTypes.number.isRequired,
		contractCO2EmissionsAvoided: PropTypes.number.isRequired,
		contractToDateEnergyUsed: PropTypes.number.isRequired,
		contractEnergySavings: PropTypes.number.isRequired,
		lifetimeCO2EmissionsAvoided: PropTypes.number.isRequired,
		lifetimeEnergySavings: PropTypes.number.isRequired,
		lifetimeEnergyUsed: PropTypes.number.isRequired,
		lifetimeToDateCO2EmissionsAvoided: PropTypes.number.isRequired,
		lifetimeToDateEnergySavings: PropTypes.number.isRequired,
		lifetimeToDateEnergyUsed: PropTypes.number.isRequired,
		annuallyAverageCO2EmissionsAvoidedOverContract: PropTypes.number,
		annuallyAverageCO2EmissionsAvoidedOverLifetime: PropTypes.number.isRequired,
		annuallyAverageEnergySavingsOverContract: PropTypes.number.isRequired,
		annuallyAverageEnergySavingsOverLifetime: PropTypes.number.isRequired,
		annuallyAverageEnergyUsedOverContract: PropTypes.number.isRequired,
		annuallyAverageEnergyUsedOverLifetime: PropTypes.number.isRequired,
	}).isRequired,
	rowLabels: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			unit: PropTypes.string,
		}),
	),
	columnLabels: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
		}),
	),
	cells: PropTypes.arrayOf(PropTypes.string),
	isSmallTable: PropTypes.bool,
	isBigTable: PropTypes.bool,
};

export default ImpactTable;
