import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import DashboardInquiresTile from './Tiles/DashboardInquiresTile';
import getEpcPartnerInquires from 'EpcPartner/api/getEpcPartnerInquires';
import DashboardOffersTile from './Tiles/DashboardOffersTile';
import getEpcPartnerOffers from 'EpcPartner/api/getEpcPartnerOffers';
import showToastError from 'Common/utils/showToastError';
import { useTranslation } from 'react-i18next';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const EpcPartnerDashboard = () => {
	const [inquiries, setInquiries] = useState([]);
	const [offers, setOffers] = useState([]);
	const [isLoadingOffers, setIsLoadingOffers] = useState(true);
	const [isLoadingInquiries, setIsLoadingInquiries] = useState(true);

	const { t } = useTranslation();

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			setIsLoadingInquiries(true);

			try {
				const response = await getEpcPartnerInquires(abortController.signal, {
					sortBy: 'lastModifiedDate',
					sortDirection: 'DESC',
				});
				setInquiries(response.data.inquiries);
				setIsLoadingInquiries(false);
			} catch (error) {
				showToastError(error, t("Can't fetch EPC partner inquiries"));
				if (!axios.isCancel(error)) {
					setInquiries([]);
					setIsLoadingInquiries(false);
				}
			}
		})();
	}, [abortController.signal, t]);

	useEffect(() => {
		(async () => {
			setIsLoadingOffers(true);

			try {
				const response = await getEpcPartnerOffers(abortController.signal, {});
				setOffers(response.data.content);
				setIsLoadingOffers(false);
			} catch (error) {
				showToastError(error, t("Can't fetch EPC partner offers"));
				if (!axios.isCancel(error)) {
					setOffers([]);
					setIsLoadingOffers(false);
				}
			}
		})();
	}, [abortController.signal, t]);

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
				<DashboardInquiresTile
					reviewedInquiriesCount={inquiries.filter(inquiry => inquiry.state === 'NEW').length}
					isLoading={isLoadingInquiries}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
				<DashboardOffersTile
					reviewedOffersCount={offers.filter(inquiry => inquiry.status === 'OPEN').length}
					isLoading={isLoadingOffers}
				/>
			</Grid>
		</TilesGridWrapper>
	);
};

export default EpcPartnerDashboard;
