import { useState } from 'react';
import Modal from 'Common/components/modals/Modal';
import Button from 'Common/components/buttons/Button';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';
import H1 from 'Common/components/headers/H1';
import SharePointIcon from 'Common/components/icons/SharepointIcon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import autoAssignDriveFolder from 'Projects/api/autoAssignDriveFolder';
import assignDriveFolder from 'Projects/api/assignDriveFolder';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import FormikInput from 'Common/components/form/FormikInput';
import Loader from 'Common/components/Loader';
import { useDispatch } from 'react-redux';
import { updateCurrentProject } from 'Projects/reduxProject';
import getDriveFolderUrl from 'Projects/api/getDriveFolderUrl';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import PropTypes from 'prop-types';

const StyledModal = styled(Modal)`
	.ReactModal__Content {
		max-width: 550px;
		padding: 16px;
	}
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StatusWrapper = styled.div`
	display: flex;
	align-items: center;
	svg {
		width: 32px;
		height: 32px;
	}
`;

const ContentWrapperButtons = styled.div`
	display: flex;
	margin-top: 20px;
	> button:first-child {
		margin-right: 10px;
	}
`;

const ModalButtonWrapper = styled.div`
	margin-top: 40px;
	display: flex;
	justify-content: center;
`;

const LinkMenuWrapper = styled.div`
	input {
		max-width: 70%;
	}
`;

const FolderName = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	text-decoration: underline;
	cursor: pointer;
	color: #551a8b;
`;

const SharePointLoader = styled(Loader)`
	width: 20px !important;
	height: 20px !important;
`;

const Row = styled.div`
	> * {
		margin-right: 16px;

		&:last-child {
			margin-right: 0;
		}
	}
`;

const SharePointSettingsModal = ({ label, isOpen, onClose, project }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [showFolderLinkMenu, setShowFolderLinkMenu] = useState(false);
	const [folderInputValue, setFolderInputValue] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isSharePointUrlLoading, setIsSharePointUrlLoading] = useState(false);
	const [folderName, setFolderName] = useState(project.driveFolderName);

	const abortController = useAbortController();

	const handleAutoAssignFolder = async () => {
		setIsLoading(true);
		try {
			let response = await autoAssignDriveFolder(abortController.signal, project.id);
			notify(t('Folder linked successfully.'), {
				type: toast.TYPE.SUCCESS,
			});
			dispatch(
				updateCurrentProject({
					driveFolderId: response.data.driveFolderId,
					driveFolderName: response.data.driveFolderName,
					driveFolderUrl: response.data.driveFolderUrl,
				}),
			);
			setIsLoading(false);
		} catch (error) {
			showToastError(error);
			if (!axios.isCancel(error)) {
				setIsLoading(false);
			}
		}
	};

	const handleAssignFolder = async () => {
		setIsLoading(true);
		try {
			let response = await assignDriveFolder(abortController.signal, project.id, folderInputValue);
			notify(t('Folder linked successfully.'), {
				type: toast.TYPE.SUCCESS,
			});
			dispatch(
				updateCurrentProject({
					driveFolderId: response.data.driveFolderId,
					driveFolderName: response.data.driveFolderName,
					driveFolderUrl: response.data.driveFolderUrl,
				}),
			);
			setShowFolderLinkMenu(false);
			setIsLoading(false);
		} catch (error) {
			showToastError(error);
			if (!axios.isCancel(error)) {
				setShowFolderLinkMenu(false);
				setIsLoading(false);
			}
		}
	};

	const handleUnassignFolder = async () => {
		setIsLoading(true);
		try {
			await assignDriveFolder(abortController.signal, project.id);
			notify(t('Folder unlinked successfully.'), {
				type: toast.TYPE.SUCCESS,
			});
			dispatch(
				updateCurrentProject({
					driveFolderId: null,
					driveFolderName: null,
					driveFolderUrl: null,
				}),
			);
			setIsLoading(false);
		} catch (error) {
			showToastError(error);
			if (!axios.isCancel(error)) {
				setIsLoading(false);
			}
		}
	};

	const handleSharePointLinkClick = async () => {
		if (!isSharePointUrlLoading) {
			setIsSharePointUrlLoading(true);
			try {
				const { data } = await getDriveFolderUrl(abortController.signal, project.id);
				setFolderName(data.name);
				window.open(data.webUrl, '_blank');
				setIsSharePointUrlLoading(false);
			} catch (error) {
				if (Boolean(project.driveFolderUrl) === true) {
					showToastError(error, t('Folder not found'));
				}
				if (!axios.isCancel(error)) {
					setIsSharePointUrlLoading(false);
				}
			}
		}
	};

	return (
		<StyledModal label={label} isOpen={isOpen} onRequestClose={onClose}>
			<H1>{t('SharePoint folder')}</H1>
			<ContentWrapper>
				<KeyValueVisualization
					title={t('Status')}
					value={
						<StatusWrapper>
							<SharePointIcon isActive={Boolean(project.driveFolderUrl)} />
							{Boolean(project.driveFolderUrl) === true ? 'Linked' : 'Not Linked'}
						</StatusWrapper>
					}
				/>
				<KeyValueVisualization
					title={t('Folder')}
					value={
						Boolean(project.driveFolderUrl) === true ? (
							isSharePointUrlLoading ? (
								<SharePointLoader />
							) : (
								<FolderName onClick={() => handleSharePointLinkClick()}>{folderName}</FolderName>
							)
						) : (
							''
						)
					}
				/>
				{showFolderLinkMenu && (
					<LinkMenuWrapper>
						<FormikInput
							label={t('Folder name')}
							value={folderInputValue}
							onChange={e => setFolderInputValue(e.target.value)}
							tooltip={{
								tooltip: t(
									'Type the folder name as seen in SharePoint or OneDrive, and pulse will link it to the Project.',
								),
							}}
						/>
					</LinkMenuWrapper>
				)}
				<ContentWrapperButtons>
					{Boolean(project.driveFolderUrl) === true ? (
						<Button
							text={t('Unlink')}
							onClick={() => handleUnassignFolder()}
							isLoading={isLoading}
							label="Project Details SharePoint Settings - Unlink Button"
						/>
					) : (
						<>
							{showFolderLinkMenu ? (
								<Row>
									<Button
										secondary
										text={t('Cancel')}
										onClick={() => setShowFolderLinkMenu(false)}
										label="Project Details SharePoint Settings - Cancel Button"
									/>
									<Button
										overlay
										text={t('Link')}
										onClick={() => handleAssignFolder()}
										isLoading={isLoading}
										label="Project Details SharePoint Settings - Link Button"
									/>
								</Row>
							) : (
								<Row>
									<Button
										secondary
										text={t('Auto link')}
										onClick={() => handleAutoAssignFolder()}
										isLoading={isLoading}
										label="Project Details SharePoint Settings - Auto Link Button"
									/>
									<Button
										overlay
										text={t('Select folder')}
										onClick={() => setShowFolderLinkMenu(true)}
										label="Project Details SharePoint Settings - Select Folder Link Button"
									/>
								</Row>
							)}
						</>
					)}
				</ContentWrapperButtons>
			</ContentWrapper>
			<ModalButtonWrapper>
				<Button text={t('Close')} secondary onClick={onClose} />
			</ModalButtonWrapper>
		</StyledModal>
	);
};

SharePointSettingsModal.propTypes = {
	label: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		driveFolderName: PropTypes.string,
		driveFolderUrl: PropTypes.string,
	}).isRequired,
};

export default SharePointSettingsModal;
