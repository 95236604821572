import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmModal from 'Common/components/modals/ConfirmModal';
import { hideUnsavedChangesModal } from 'Application/reducers/reduxCommon';

const UnsavedChangesModalRedux = () => {
	const dispatch = useDispatch();

	const isOpened = useSelector(state => state.common.isUnsavedChangesModalOpened);

	const callback = useSelector(state => state.common.unsavedChangesCallback);

	const { t } = useTranslation();

	const closeModal = () => {
		dispatch(hideUnsavedChangesModal());
	};

	const handleConfirm = () => {
		closeModal();
		callback(true);
	};

	const handleCancel = () => {
		closeModal();
		callback(false);
	};

	return (
		<ConfirmModal
			label="you-have-unsaved-changes-redux-confirm"
			isOpen={isOpened}
			onConfirm={handleConfirm}
			onCancel={handleCancel}
			heading={t('You have unsaved changes')}
			text={`${t('Are you sure you want to leave this page?')}
				
				${t('Any changes you have made on this page will be lost.')}`}
		/>
	);
};

export default UnsavedChangesModalRedux;
