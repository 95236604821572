const convertProjectDeveloperValuesToRequestData = values => ({
	name: values.name.trim(),
	website: values.website.trim(),
	countryId: values.country ? values.country.value : null,
	address: {
		line1: values.line1,
		line2: values.line2,
		apartmentBuilding: values.apartmentBuilding,
		cityRegion: values.cityRegion,
		postCodeZip: values.postCodeZip,
	},
});

export default convertProjectDeveloperValuesToRequestData;
