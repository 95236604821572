import api from 'Application/api/api';

const getActivitiesByStage = (signal, stage, details) => {
	const params = {
		details: details,
	};

	return api.get(`/activity-templates/by-stage/${stage}`, {
		params,
		signal,
	});
};

export default getActivitiesByStage;
