import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const usePartnerFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				name: yup.string().required(t('Name is required')),
				partnerType: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.required(t('Partner Type is required'))
					.nullable(),
				description: yup.string(),
				country: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.required(t('Country is required'))
					.nullable(),
				aparmentBuilding: yup.string(),
				addressLine1: yup.string(),
				addressLine2: yup.string(),
				cityRegion: yup.string(),
				postCode: yup.string(),
			}),
		[t],
	);
};

export default usePartnerFormValidationSchema;
