import Big from 'big.js';
import { mountingTypeOptions } from 'Projects/constants/mountingTypeOptions';

// eslint-disable-next-line complexity
const adaptClientOfferResponseData = data => ({
	...data,
	annualOmPrice: data.annualOmPrice?.value && String(data.annualOmPrice.value),
	averageDscr: data.averageDscr && String(data.averageDscr),
	ceilingTariff: data.ceilingTariff?.value,
	clientDownpayment: data.clientUpfrontPayment?.value,
	currency: data.currency,
	contractType: data.contractType
		? { label: data.contractType.name, value: Number(data.contractType.id) }
		: data.contractType,
	contractTerm: data.duration && String(data.duration),
	epcVatApplicable: data.epcVat,
	epcVolume: data.epcVolume?.value && String(data.epcVolume.value),
	expectedClientSavings: data.expectedClientSavings?.value,
	floorTariff: data.floorTariff?.value,
	leaseFee: data.monthlyLeaseRate?.value,
	minimumDscr: data.minimumDscr && String(data.minimumDscr),
	mountingTypes: data.mountingTypes
		? data.mountingTypes.map(({ mountingType }) =>
				mountingTypeOptions.find(({ value }) => value === mountingType),
		  )
		: [],
	offerDate: data.offerDate ? new Date(data.offerDate) : data.offerDate,
	ppaTariff: data.ppaPricePerKwh?.value,
	ppaTariffType: data.ppaTariffType
		? { label: data.ppaTariffType.displayName, value: data.ppaTariffType.ppaTariffType }
		: data.ppaTariffType,
	saasCurrency: data.saasCurrency,
	systemSize: data.systemSize && String(data.systemSize),
	escalationRateInPercent: data.escalationRateInPercent
		? Big(data.escalationRateInPercent).times(100).toString()
		: undefined,
	yield: data.yield && String(data.yield),
	solarPvDegradation: data.solarPvDegradation ? Big(data.solarPvDegradation).times(100).toString() : undefined,
	annualOmEscalationRate: data.annualOmEscalationRate
		? Big(data.annualOmEscalationRate).times(100).toString()
		: undefined,
	extraCapexExpected: data.extraCapexExpected && String(data.extraCapexExpected),
	extraOpexExpected: data.extraOpexExpected && String(data.extraOpexExpected),
	percentageOnGridTariff: data.percentageOnGridTariff
		? Big(data.percentageOnGridTariff).times(100).toString()
		: undefined,
	discountOnGridTariff: data.discountOnGridTariff
		? Big(data.discountOnGridTariff).times(100).toString()
		: undefined,
	solarUtilizationRate: data.solarUtilizationRate
		? Big(data.solarUtilizationRate).times(100).toString()
		: undefined,
	irr: data.irr ? Big(data.irr).times(100).toString() : undefined,
	worstCaseIrr: data.worstCaseIrr ? Big(data.worstCaseIrr).times(100).toString() : undefined,
	minimumPurchaseAmount: data.minimumPurchaseAmount
		? Big(data.minimumPurchaseAmount).times(100).toString()
		: undefined,
	minimumWarrantedFunctionality: data.minimumWarrantedFunctionality
		? Big(data.minimumWarrantedFunctionality).times(100).toString()
		: undefined,
});

export default adaptClientOfferResponseData;
