import { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import assignCampaigns from 'Projects/api/assignCampaigns';
import CampaignList from 'Projects/components/ProjectDetails/Tabs/CampaignsTab/CampaignList';

import notify from 'Common/utils/notify';
import EditableTile from 'Common/components/Tile/EditableTile';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const CampaignsTab = ({ project, handleUpdateProject }) => {
	const { t } = useTranslation();
	const [campaignForUnlink, setCampaignForUnlink] = useState(null);

	const abortController = useAbortController();

	const handleCampaignUnlink = async campaignId => {
		const campaignsLeft = project?.campaigns.filter(campaign => campaign.id !== campaignId);
		try {
			let response = await assignCampaigns(abortController.signal, {
				campaignIds: [...campaignsLeft.map(campaign => campaign.id)],
				projectId: project?.id,
			});
			notify(t('Campaign unassigned successfully'), {
				type: toast.TYPE.SUCCESS,
			});
			handleUpdateProject({ campaigns: response.data.campaigns });
			setCampaignForUnlink(null);
		} catch (e) {
			showToastError(e);
			if (!axios.isCancel(e)) {
				setCampaignForUnlink(null);
			}
		}
	};

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<EditableTile title={t('Campaigns')}>
					<CampaignList project={project} />
				</EditableTile>
			</Grid>

			<ConfirmModal
				isOpen={campaignForUnlink !== null}
				onCancel={() => setCampaignForUnlink(null)}
				onConfirm={() => handleCampaignUnlink(campaignForUnlink?.id)}
				label="campaigns-unlink"
				heading=""
				text={`${t('Are you sure you want to unlink Campaign ')} ${campaignForUnlink?.externalId} ${
					campaignForUnlink?.name
				}?`}
			/>
		</TilesGridWrapper>
	);
};

CampaignsTab.defaultProps = {
	project: {},
	handleUpdateProject: async () => {},
};

CampaignsTab.propTypes = {
	project: PropTypes.shape({
		campaigns: PropTypes.arrayOf(PropTypes.shape({})),
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}).isRequired,
	handleUpdateProject: PropTypes.func,
};

export default CampaignsTab;
