import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import EditableTile from 'Common/components/Tile/EditableTile';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import NightlyMaintenance from 'Preferences/components/Tabs/AdvancedTab/NightlyMaintenance/NightlyMaintenanceView';

const AdvancedTab = () => {
	const { t } = useTranslation();

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
				<EditableTile title={t('Nightly Maintenance')}>
					<NightlyMaintenance />
				</EditableTile>
			</Grid>
		</TilesGridWrapper>
	);
};

export default AdvancedTab;
