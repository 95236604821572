import api from 'Application/api/api';

const getRfepById = (signal, id, details) =>
	api.get(`tenders/${id}`, {
		params: {
			details,
		},
		signal,
	});

export default getRfepById;
