import { useMemo } from 'react';
import useProductTypes from 'Projects/hooks/useProductTypes';
import manufacturedProductTypes from 'Manufacturer/constants/manufacturedProductTypes';

const filterByManufacturer = (types, manufacturer) =>
	types?.filter(type => type.name === manufacturer)?.[0]?.manufacturers ?? [];

const transformTypes = productTypes =>
	productTypes.map(productType => ({
		value: productType.name,
		label: productType.displayName,
	}));

const sortTypes = productTypes => productTypes.sort((a, b) => a.label.localeCompare(b.label));

const useProductTypesOptions = () => {
	const productTypes = useProductTypes();

	const productTypesOptionsByManufacturer = useMemo(
		() =>
			Object.values(manufacturedProductTypes).reduce(
				(prev, manufacturer) => ({
					...prev,
					[manufacturer]: sortTypes(transformTypes(filterByManufacturer(productTypes, manufacturer))),
				}),
				{},
			),
		[productTypes],
	);

	return productTypesOptionsByManufacturer;
};

export default useProductTypesOptions;
