import styled from 'styled-components/macro';
import FormField from 'Common/components/form/FormField';
import { useTranslation } from 'react-i18next';
import FormikTextArea from 'Common/components/form/FormikTextArea';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import { PropTypes } from 'prop-types';

const Title = styled.div`
	font-size: 20px;
	font-weight: 600;
	margin-right: 20px;
	margin-bottom: 20px;
`;

const ProposalForm = ({
	errors,
	touched,
	values,
	handleChange,
	handleBlur,
	setFieldValue,
	setFieldTouched,
	showGensetsField,
	isGensetsRequierd,
	showValidityDateField,
	isValidityDateRequired,
	isTile,
	isInEditMode,
}) => {
	const { t } = useTranslation();
	return (
		<>
			<Title>{t('Proposal')}</Title>
			{showValidityDateField && (
				<FormField>
					<FormikSingleDatePicker
						label={t('Validity date')}
						id="offerValidityDate"
						startDateName="offerValidityDate"
						error={errors.offerValidityDate}
						touched={touched.offerValidityDate}
						startDate={values.offerValidityDate !== '' ? values.offerValidityDate : null}
						setFieldValue={setFieldValue}
						setFieldTouched={setFieldTouched}
						isTile={isTile}
						isInEditMode={isInEditMode}
						isRequired={isValidityDateRequired}
					/>
				</FormField>
			)}
			{showGensetsField && (
				<FormField>
					<FormikTextArea
						label={t('Gensets')}
						id="gensetsInfo"
						name="gensetsInfo"
						error={errors.gensetsInfo}
						touched={touched.gensetsInfo}
						value={values.gensetsInfo}
						onChange={handleChange}
						onBlur={handleBlur}
						tooltip={{
							placement: 'right',
							tooltip: t('List any required info about the gensets included in the offer.'),
						}}
						isTile={isTile}
						isInEditMode={isInEditMode}
						isRequired={isGensetsRequierd}
					/>
				</FormField>
			)}

			<FormField>
				<FormikTextArea
					label={t('Assumptions')}
					id="assumptions"
					name="assumptions"
					error={errors.assumptions}
					touched={touched.assumptions}
					value={values.assumptions}
					onChange={handleChange}
					onBlur={handleBlur}
					tooltip={{
						placement: 'right',
						tooltip: t('Assumptions made relating to offer.'),
					}}
					isTile={isTile}
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikTextArea
					label={t('Exclusions')}
					id="exclusions"
					name="exclusions"
					error={errors.exclusions}
					touched={touched.exclusions}
					value={values.exclusions}
					onChange={handleChange}
					onBlur={handleBlur}
					tooltip={{
						placement: 'right',
						tooltip: t('Anything (components etc.) which are not included in this offer.'),
					}}
					isTile={isTile}
					isInEditMode={isInEditMode}
				/>
			</FormField>
			<FormField>
				<FormikTextArea
					label={t('Variations')}
					id="variations"
					name="variations"
					error={errors.variations}
					touched={touched.variations}
					value={values.variations}
					onChange={handleChange}
					onBlur={handleBlur}
					tooltip={{
						placement: 'right',
						tooltip: t('Potential variations to the offer which can be discussed.'),
					}}
					isTile={isTile}
					isInEditMode={isInEditMode}
				/>
			</FormField>
		</>
	);
};

ProposalForm.defaultProps = {
	showGensetsField: true,
	showValidityDateField: true,
	isTile: false,
	isInEditMode: false,
};

ProposalForm.propTypes = {
	errors: PropTypes.shape({
		offerValidityDate: PropTypes.string,
		gensetsInfo: PropTypes.string,
		assumptions: PropTypes.string,
		exclusions: PropTypes.string,
		variations: PropTypes.string,
	}).isRequired,
	touched: PropTypes.shape({
		offerValidityDate: PropTypes.bool,
		gensetsInfo: PropTypes.bool,
		assumptions: PropTypes.bool,
		exclusions: PropTypes.bool,
		variations: PropTypes.bool,
	}).isRequired,
	values: PropTypes.shape({
		offerValidityDate: PropTypes.instanceOf(Date),
		gensetsInfo: PropTypes.string,
		assumptions: PropTypes.string,
		exclusions: PropTypes.string,
		variations: PropTypes.string,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	handleBlur: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	showGensetsField: PropTypes.bool,
	isGensetsRequierd: PropTypes.bool.isRequired,
	showValidityDateField: PropTypes.bool,
	isValidityDateRequired: PropTypes.bool.isRequired,
	isTile: PropTypes.bool,
	isInEditMode: PropTypes.bool,
};

export default ProposalForm;
