import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';
import yupUrl from 'Common/utils/yupUrl';

const useEpcPartnerFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				name: yup.string().required(t('Required')),
				website: yupUrl(),
				country: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.required(t('Required'))
					.nullable(),
				apartmentBuilding: yup.string(),
				line1: yup.string(),
				line2: yup.string(),
				cityRegion: yup.string(),
				postCodeZip: yup.string(),
				firstName: yup.string().when('inviteToPortal', {
					is: true,
					then: yup.string().required(t('Required')),
					otherwise: null,
				}),
				lastName: yup.string().when('inviteToPortal', {
					is: true,
					then: yup.string().required(t('Required')),
					otherwise: null,
				}),
				phone: yup.string(),
				email: yup.string().when('inviteToPortal', {
					is: true,
					then: yup.string().email().required(t('Required')),
					otherwise: yup.string().email(),
				}),
			}),
		[t],
	);
};

export default useEpcPartnerFormValidationSchema;
