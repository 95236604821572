import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { sortBy } from 'lodash';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import Loader from 'Common/components/Loader';
import EditableTile from 'Common/components/Tile/EditableTile';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import getProjectById from 'Projects/api/getProjectById';

import assignCampaignProjects from 'Campaigns/api/assignCampaignProjects';

import ProjectInfoTileView from 'Campaigns/components/ProjectInfoTile/ProjectInfoTileView';
import ProjectsEdit from 'Campaigns/components/CampaignDetails/Tabs/GeneralTab/Tiles/ProjectsTile/ProjectsEdit';
import useProjectsFormInitialValues from 'Campaigns/components/CampaignDetails/Tabs/GeneralTab/Tiles/ProjectsTile/hooks/useProjectsFormInitialValues';
import convertFormValuesToRequestData from 'Campaigns/components/CampaignDetails/Tabs/GeneralTab/Tiles/ProjectsTile/utils/convertFormValuesToRequestData';
import useProjectsFormValidationSchema from 'Campaigns/components/CampaignDetails/Tabs/GeneralTab/Tiles/ProjectsTile/hooks/useProjectsFormValidationSchema';

import ProjectsList from './ProjectsList';
import TileDynamicSection from 'Common/components/Tile/TileDynamicSection';

import { css } from 'styled-components';
import useReponsive from 'Common/hooks/useResponsive';
import showToastError from 'Common/utils/showToastError';
import mergeCo2Values from 'Campaigns/components/CampaignDetails/Tabs/GeneralTab/Tiles/ProjectsTile/utils/mergeCo2Values';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const CenteredContent = styled.div`
	display: flex;
	justify-content: center;
`;

const ProjectListWrapper = styled.div`
	margin-top: 10px;
	margin-bottom: 10px;
`;

const Wrapper = styled.div`
	${({ $isMobile }) =>
		$isMobile &&
		css`
			* {
				font-size: 13px;
			}
		`}
`;

const ProjectsTile = ({ campaign, onEnterEditMode, onExitEditMode, onSave, onDirtyForm }) => {
	const { t } = useTranslation();
	const { isMobile } = useReponsive();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.PM_FUNDRAISING,
		roles.ASSET_MARKETING,
		roles.HEAD_OF_ESG,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [projects, setProjects] = useState([]);
	const [isLoadingSelectedProjects, setIsLoadingSelectedProjects] = useState(false);

	const abortController = useAbortController();

	const initialValues = useProjectsFormInitialValues(campaign);
	const validationSchema = useProjectsFormValidationSchema();

	const {
		errors,
		touched,
		values,
		dirty,
		handleSubmit,
		handleChange,
		handleBlur,
		setFieldValue,
		setFieldError,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			setIsSavingChanges(true);
			try {
				const response = await saveProjects(values);

				notify(t('Campaign updated successfully.'), {
					type: toast.TYPE.SUCCESS,
				});

				// Re-set all fields and mark them as not touched

				onSave(response.data);
				setIsSavingChanges(false);
				setIsInEditMode(false);
				onExitEditMode();
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});

				if (!axios.isCancel(error)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});

	const refetchProjects = useCallback(async () => {
		try {
			if (campaign?.finance_details?.projects && campaign?.finance_details?.projects.length > 0) {
				setIsLoadingSelectedProjects(true);
				const responses = await Promise.all(
					campaign.finance_details.projects.map(e => getProjectById(abortController.signal, e.id, true)),
				);
				setProjects(
					sortBy(
						responses.map(response => response.data),
						['externalId'],
					),
				);
				setIsLoadingSelectedProjects(false);
			} else {
				setProjects([]);
			}
		} catch (e) {
			showToastError(e, t('An error occured while fetching selected campaign projects data'));

			if (!axios.isCancel(e)) {
				setProjects([]);
			}
		}
	}, [campaign, abortController.signal, t]);

	const saveProjects = useCallback(
		async values => {
			const { assignProjectsToFinanceDto } = {
				...convertFormValuesToRequestData(values, campaign?.finance_details?.id),
			};

			const assignCampaignResponse = await assignCampaignProjects(abortController.signal, {
				financeDetailsProjects: assignProjectsToFinanceDto?.financeDetailsProjects,
				financeId: campaign.auto_id,
			});

			const mergedFinanceDetails = mergeCo2Values(
				values.financeDetailsProjects,
				assignCampaignResponse.data.finance_details.financeDetailsProjects,
			);

			setFieldValue('financeDetailsProjects', mergedFinanceDetails);
			await refetchProjects();
			return assignCampaignResponse;
		},
		[campaign, refetchProjects, setFieldValue, abortController.signal],
	);

	const handleCancel = useCallback(() => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });

		if (onExitEditMode) {
			onExitEditMode();
		}
	}, [resetForm, initialValues, onExitEditMode]);

	const handleTileClick = useCallback(() => {
		if (!isInEditMode && isAuthorizedToEdit) {
			setIsInEditMode(true);

			if (typeof onEnterEditMode === 'function') {
				onEnterEditMode();
			}
		}
	}, [onEnterEditMode, isAuthorizedToEdit, isInEditMode]);

	useEffect(() => {
		if (!isInEditMode) {
			onDirtyForm(false);
		} else {
			onDirtyForm(dirty);
		}
	}, [dirty, isInEditMode, onDirtyForm]);

	useEffect(() => {
		refetchProjects();
	}, [refetchProjects]);

	return (
		<EditableTile
			title={t('Projects')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<Wrapper $isMobile={isMobile}>
				<TileDynamicSection
					isInEditMode={isInEditMode}
					uniqueId="projects"
					editView={
						<ProjectsEdit
							dirty={dirty}
							values={values}
							errors={errors}
							touched={touched}
							campaign={campaign}
							onDirty={onDirtyForm}
							setFieldValue={setFieldValue}
							handleBlur={handleBlur}
							saveProjects={saveProjects}
							handleChange={handleChange}
						/>
					}
					readonlyView={
						<>
							{isLoadingSelectedProjects ? (
								<CenteredContent>
									<Loader />
								</CenteredContent>
							) : (
								projects.map(project => (
									<ProjectInfoTileView
										key={project.id}
										project={project}
										financeDetails={campaign.finance_details.financeDetailsProjects.find(
											e => e.projectId === project.id,
										)}
										campaignId={campaign.finance_details.id}
									/>
								))
							)}

							<ProjectListWrapper>
								{campaign?.finance_details?.projects && (
									<ProjectsList
										projects={sortBy(campaign?.finance_details?.financeDetailsProjects, ['externalId'])}
										projectsWithDetails={projects}
										totalCampaignAnnualCo2Savings={campaign?.finance_details?.totalCampaignAnnualCo2Savings}
										totalCampaignKwp={campaign?.finance_details?.totalCampaignKwp}
										totalEpcVolumeContribution={campaign?.finance_details?.totalEpcVolumeContribution}
										campaignId={campaign?.finance_details?.id}
									/>
								)}
							</ProjectListWrapper>
						</>
					}
				/>
			</Wrapper>
		</EditableTile>
	);
};

ProjectsTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
};

ProjectsTile.propTypes = {
	campaign: PropTypes.shape({
		finance_details: PropTypes.shape({
			projects: PropTypes.arrayOf(PropTypes.shape({})),
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			financeDetailsProjects: PropTypes.arrayOf(PropTypes.shape({})),
			totalCampaignKwp: PropTypes.number,
			totalEpcVolumeContribution: PropTypes.number,
			totalCampaignAnnualCo2Savings: PropTypes.number,
		}),
		auto_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		minimum_volume: PropTypes.string,
		maximum_volume: PropTypes.string,
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onSave: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
};

export default ProjectsTile;
