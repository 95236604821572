import Grid from '@mui/material/Grid';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import styled from 'styled-components/macro';
import getCountriesWithManagers from 'Preferences/api/getCountriesWithManagers';

import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';

import RegionalHubList from 'Preferences/components/Tabs/CountriesTab/RegionalHub/RegionalHubList';
import CountriesList from 'Country/components/List/CountriesList';
import DefaultManagersTile from 'Preferences/components/Tabs/CountriesTab/Tiles/DefaultManagersTile';
import CountryOverlay from 'Country/components/Overlay/CountryOverlay';
import { useState, useCallback, useEffect } from 'react';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import crudModes from 'Common/constants/crudModes';

const GridWrapper = styled(Grid)`
	position: relative;
	z-index: ${({ $zIndex }) => ($zIndex ? $zIndex : 0)};
`;

const CountriesTab = () => {
	const [selectedCountry, setSelectedCountry] = useState();
	const [countries, setCountries] = useState([]);
	const [isLoadingCountriesContent, setIsLoadingCountriesContent] = useState(false);

	const abortController = useAbortController();

	const fetchCountries = useCallback(async () => {
		try {
			setIsLoadingCountriesContent(true);
			const { data: countries } = await getCountriesWithManagers(abortController.signal);

			setIsLoadingCountriesContent(false);
			setCountries(countries);
		} catch (err) {
			showToastError(err);
		}
	}, [abortController.signal]);

	useEffect(() => {
		fetchCountries();
	}, [fetchCountries]);

	const handleDataUpdate = useCallback(() => {
		fetchCountries();
	}, [fetchCountries]);

	const handleCountryRowClick = useCallback(row => {
		if (!window.getSelection().toString()) {
			setSelectedCountry({ id: row.original.id, name: row.original.name });
		}
	}, []);
	const handleOverlayClose = useCallback(() => {
		handleDataUpdate();
		setSelectedCountry(null);
	}, [handleDataUpdate]);

	return (
		<TilesGridWrapper>
			<CountryOverlay
				countryId={selectedCountry?.id}
				countryName={selectedCountry?.name}
				isOpen={Boolean(selectedCountry)}
				onFormSubmit={handleDataUpdate}
				onClose={handleOverlayClose}
				mode={crudModes.EDIT}
			/>

			{isAuthorized([roles.ADMIN, roles.FINANCE, roles.MANAGEMENT]) && (
				<GridWrapper item xs={12} sm={12} md={12} lg={12} xl={12} $zIndex="1">
					<DefaultManagersTile />
				</GridWrapper>
			)}
			<GridWrapper data-form="countries" item xs={12} sm={12} md={12} lg={12} xl={12}>
				<CountriesList
					countries={countries}
					handleDataUpdate={handleDataUpdate}
					handleCountryRowClick={handleCountryRowClick}
					isLoadingContent={isLoadingCountriesContent}
				/>
			</GridWrapper>
			{isAuthorized([roles.ADMIN, roles.FINANCE, roles.MANAGEMENT]) && (
				<Grid data-form="regionalHubs" item xs={12} sm={12} md={12} lg={12} xl={12}>
					<RegionalHubList />
				</Grid>
			)}
		</TilesGridWrapper>
	);
};

export default CountriesTab;
