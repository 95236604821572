import { useEffect, useState } from 'react';
import getProductTypes from 'Projects/api/getProductTypes';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';

const useProductTypes = () => {
	const [productTypes, setProductTypes] = useState([]);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				const response = await getProductTypes(abortController.signal);

				setProductTypes(response.data);
			} catch (error) {
				showToastError(error);
			}
		})();
	}, [abortController.signal]);

	return productTypes;
};

export default useProductTypes;
