import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import getAdminEpcInquiryById from 'Inquiries/api/getAdminEpcInquiryById';
import EpcInquiryForm from 'EpcPartner/components/EpcPartnerPortal/EpcInquiryCreate/EpcInquiryCreateForm/EpcInquiryForm';
import { useParams } from 'react-router';
import crudModes from 'Common/constants/crudModes';
import RespondOverlay from 'Inquiries/components/InquiryDetails/RespondOverlay/RespondOverlay';
import showToastError from 'Common/utils/showToastError';
import LoaderWrapper from 'Common/components/LoaderWrapper';
import Loader from 'Common/components/Loader';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const EpcInquiryEdit = () => {
	const { t } = useTranslation();
	const { inquiryId } = useParams();

	const [inquiry, setInquiry] = useState(null);
	const [isRepondModalOpened, setIsRespondModalOpened] = useState(false);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			try {
				const inquiryResponse = await getAdminEpcInquiryById(abortController.signal, inquiryId);

				setInquiry({
					...inquiryResponse.data,
					currency: inquiryResponse.data?.annualOmPrice?.currency ?? null,
				});
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					setInquiry(null);
				}
			}
		})();
	}, [inquiryId, abortController.signal]);

	const handleDataChange = changes => {
		setInquiry(prevEpcOffer => ({
			...prevEpcOffer,
			...changes,
		}));
	};

	return (
		<>
			<Helmet>
				<title>{t('EPC Inquiry')}</title>
			</Helmet>

			{inquiry !== null ? (
				<EpcInquiryForm
					data={inquiry}
					mode={crudModes.EDIT}
					onDataChange={handleDataChange}
					formParent={'admin'}
					openRespondModal={setIsRespondModalOpened}
					isDisabled
				/>
			) : (
				<LoaderWrapper $isFullPage>
					<Loader />
				</LoaderWrapper>
			)}

			<RespondOverlay
				isOpen={isRepondModalOpened}
				onClose={() => setIsRespondModalOpened(false)}
				onFormSubmit={handleDataChange}
				inquiry={inquiry}
			/>
		</>
	);
};

export default EpcInquiryEdit;
