// TODO remove the eslint complexity and fix it
/* eslint-disable complexity */
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { pick, isEqual, cloneDeep } from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import Button from 'Common/components/buttons/Button';
import Mandatory from 'Common/components/form/Mandatory';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import FormikRadio from 'Common/components/form/FormikRadio';
import FormikInput from 'Common/components/form/FormikInput';
import Accordion from 'Common/components/accordion/Accordion';
import FormikSelect from 'Common/components/form/FormikSelect';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import FormikTextArea from 'Common/components/form/FormikTextArea';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import AccordionSummary from 'Common/components/accordion/AccordionSummary';
import AccordionDetails from 'Common/components/accordion/AccordionDetails';
import ConditionalGridSection from 'Common/components/ConditionalGridSection';
import Label from 'Common/components/form/Label';

import editStep from 'PMWorkflow/api/editStep';
import deleteStep from 'PMWorkflow/api/deleteStep';
import getStepTemplateById from 'PMWorkflow/api/getStepTemplateById';
import getUnusedStatisticsOptions from 'PMWorkflow/api/getUnusedStatisticsOptions';
import validateAutimationRules from 'PMWorkflow/api/validateAutomationRules';
import moveStep from 'PMWorkflow/components/Step/TemplateStep/utils/moveStep';
import useStepFieldsFormInitialValues from 'PMWorkflow/components/Step/TemplateStep/hooks/useStepsFieldsFormInitialValues';
import useStepsFieldsFormValidationSchema from 'PMWorkflow/components/Step/TemplateStep/hooks/useStepsFieldsFormValidationSchema';
import { weightOptions, completedTypes, responsibleTeamOptions } from 'PMWorkflow/constants/options';

import { PM_WORKFLOW_TILES_OPTIONS } from 'PMWorkflow/constants/PMWorkflowTiles';
import useReponsive from 'Common/hooks/useResponsive';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import {
	filterHighlightedFieldsByTiles,
	getTileHighLightFieldsOptions,
} from 'PMWorkflow/utils/PMWorkflowTilesUtils';

const Wrapper = styled.div`
	margin-bottom: 12px;
`;

const StepTitle = styled.div`
	position: relative;
	margin-right: 16px;

	${Mandatory} {
		&:after {
			right: -13px;
			top: -5px;
			display: block;
			position: absolute;
		}
	}
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	color: ${colors.common.white};
	> * {
		margin-right: 4px;

		&:last-child {
			margin-right: 0px;
		}
	}
`;

const EmptyDiv = styled.div`
	width: ${props => props.size};
`;

const MoveButtons = styled(Row)`
	margin-left: 16px;
	> * {
		margin-right: 4px;

		&:last-child {
			margin-right: 0px;
		}
	}
`;

const BottomGrid = styled(Grid)`
	padding: 8px 0px;
`;

const Text = styled.div`
	color: ${colors.text.greyDark};
	font-size: 14px;
	font-weight: 300;
	display: flex;
	align-items: center;
	margin-right: 2px;
`;

const BoldText = styled.div`
	color: ${colors.text.greyDark};
	font-size: 16px;
	font-weight: 700;
	align-items: center;
	display: grid;
	margin-left: 15px;
`;

const Name = styled.span`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: inline-block;
`;

const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	& label {
		padding-right: 16px !important;
	}
	.MuiCheckbox-root {
		margin-left: 0px !important;
	}
`;

const ButtonsWrapper = styled.div`
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const FormSelectField = styled.div`
	width: 65px;
	height: 32px;
`;

const StyledText = styled(Text)`
	margin-bottom: 8px;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
	border-top: 1px solid ${colors.common.borderGrey};
	border-top-left-radius: 0px !important;
	border-top-right-radius: 0px !important;
	padding: ${({ $isMobile }) => ($isMobile ? 'inherit' : '8px 16px 8px 32px !important')};
`;

const StyledFormikSelect = styled(FormikSelect)`
	max-width: 291px;
`;

const ProjectType = styled(Text)`
	margin-right: 24px;
`;

const StyledLabel = styled(Label)`
	${sizes.fontSize.main};
	color: ${colors.primary.dark} !important;
	font-weight: 500;
	cursor: pointer;
	text-decoration: underline;
	position: absolute;
	top: 10px;
	left: 250px;
`;

const TemplateFormField = styled(Grid)`
	position: relative;
	max-width: 290px;
`;

const StepTitleContainer = styled.div`
	max-width: 260px;
`;

const TemplateStep = ({
	step,
	stage,
	steps,
	stages,
	activity,
	countries,
	iconStyle,
	stepIndex,
	stageIndex,
	projectTypes,
	onDataChange,
	stagesLength,
	contractTypes,
	activityIndex,
	stageActivities,
	setActivityState,
	setTemplateState,
	automationFields,
	setPMWorkflowState,
	setActivityStepTemplates,
	updateActivityStepTemplates,
}) => {
	const { t } = useTranslation();
	const { isMobile } = useReponsive();
	const [state, setState] = useState({
		expanded: false,
		isNewStep: false,
		isLoading: false,
		isStepMoving: false,
		isInEditMode: false,
		applicableValues: {},
		isSavingChanges: false,
		showConfirmModal: false,
		showRequireCompletionModal: false,
		showAutomationFieldsModal: false,
		requireCompletionMessage: [],
		highlightFieldOptions: [],
		statisticsOptions: [],
	});

	const abortController = useAbortController();

	const initialValues = useStepFieldsFormInitialValues(step);
	const validationSchema = useStepsFieldsFormValidationSchema();

	const messages = {
		newStepNoApplicable: [
			`This new Step is set to ‘Require completion’ and must be completed for all Projects, including those which have already completed the ${activity.title} Activity.  This will create a lot of open Steps.`,
			`To reduce the number of open Steps, use the Applicable to filters so the Step does not apply to all Projects, or change Step to ‘Set as complete‘ so it will be ignored by existing Projects which have already completed the ${activity.title} Activity.`,
		],
		newStep: [
			`This new Step is set to ‘Require completion‘ and must be completed for all Projects that match the Applicable to filter, including those which have already completed the ${activity.title} Activity.  This may create a lot of open Steps.`,
			`To reduce the number of open Steps, ensure the Applicable to filter is as restrictive as possible, or change Step to ‘Set as complete‘ so it will be ignored by existing Projects which have already completed the ${activity.title} Activity.`,
		],
		existingStep: [
			'The Applicable to filter was changed or removed. If the Step now applies to more Projects, it must be completed for those Projects.  This may create a lot of open Steps.',
			`Ensure the Applicable to filter is set correctly and is as restrictive as possible, or change Step to ‘Set as complete‘ so it will be ignored by any Projects which now match the filter but have already completed the ${activity.title} Activity. Projects which already the Step already applied to, or have not yet completed the ${activity.title} Activity are not affected.`,
		],
	};

	const moveStepWithSignal = useMemo(
		() => moveStep.bind(undefined, abortController.signal),
		[abortController],
	);

	const { values, errors, touched, handleSubmit, handleBlur, setFieldValue, resetForm } = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: async values => {
			try {
				setState(prevState => ({ ...prevState, isSavingChanges: true }));

				let hasApplicable =
					values.projectTypeIds.length > 0 ||
					values.contractTypeIds.length > 0 ||
					values.countryIds.length > 0 ||
					values.systemSize > 0;
				let applicableChanged = !isEqual(getApplicableValues(values), state.applicableValues);

				if (values?.completedValue?.value === 'REQUIRE_COMPLETION') {
					let message = '';
					if (state.isNewStep) {
						message = hasApplicable ? messages.newStep : messages.newStepNoApplicable;
						setState(prevState => ({
							...prevState,
							showRequireCompletionModal: true,
							requireCompletionMessage: message,
						}));
					} else if (applicableChanged) {
						message = messages.existingStep;
						setState(prevState => ({
							...prevState,
							showRequireCompletionModal: true,
							requireCompletionMessage: message,
						}));
					} else {
						await confirmSaveStep();
					}
				} else {
					await confirmSaveStep();
				}
			} catch (error) {
				showToastError(error);
			}
		},
	});

	const ids = [];
	const isExpanded = state.expanded === step.id;

	steps.forEach(item => ids.push(item.id));
	const currentIdIndex = ids.findIndex(id => id === step.id);

	const handleExpanded = id => async (e, isExpanded) => {
		try {
			if (isExpanded) {
				setState(prevState => ({ ...prevState, isLoading: true }));
				await setStepById();
				setState(prevState => ({
					...prevState,
					expanded: id,
					isLoading: false,
				}));
			} else {
				handleCancel();
				handleCloseStep();
				setState(prevState => ({
					...prevState,
					expanded: false,
				}));
			}
		} catch (error) {
			showToastError(error);
		}
	};

	const handleEditButtonClick = () => {
		setState(prevState => ({ ...prevState, isInEditMode: true }));
	};

	const handleStepClick = () => {
		if (!state.isInEditMode) {
			handleEditButtonClick();
		}
	};

	const handleCancel = () => {
		setState(prevState => ({ ...prevState, isInEditMode: false }));
		resetForm({ values: initialValues });
	};

	const confirmDeleteStep = async () => {
		const hasStepInStatistic = values?.calculations?.length > 0;

		if (hasStepInStatistic) {
			return setState(prevState => ({ ...prevState, showConfirmModal: false, showDeleteModal: true }));
		} else {
			try {
				const response = await deleteStep(abortController.signal, step.id);
				if (response.status === 200) {
					await setTemplateState();

					await onDataChange();

					setPMWorkflowState(prevState => {
						const stages = prevState.stages;
						const activities = prevState.stages[stageIndex].activities;
						const prevSteps = activities[activityIndex].stepTemplates;
						const newSteps = prevSteps.filter(item => item.id !== step.id);

						return {
							...prevState,
							stages: [
								...stages.slice(0, stageIndex),
								{
									...stages[stageIndex],
									activities: [
										...activities.slice(0, activityIndex),
										{ ...activities[activityIndex], stepTemplates: newSteps },
										...activities.slice(activityIndex + 1),
									],
								},
								...stages.slice(stageIndex + 1),
							],
						};
					});

					setState(prevState => ({ ...prevState, showConfirmModal: false }));
				}
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setState(prevState => ({ ...prevState, showConfirmModal: false, showDeleteModal: true }));
				}
			}
		}
	};

	const confirmSaveStep = async () => {
		try {
			const hasAutomations = values.automationRules && values.automationRules.length > 0;
			if (hasAutomations) {
				try {
					await validateAutimationRules(abortController.signal, values.automationRules.trim());
				} catch (e) {
					showToastError(e);
					if (!axios.isCancel(e)) {
						setState(prevState => ({
							...prevState,
							isInEditMode: true,
							isSavingChanges: false,
						}));
					}
					return;
				}
			}

			await editStep(abortController.signal, {
				...pick(values, ['title', 'canBeSkipped', 'description', 'expectedDuration']),
				id: step.id,
				completedValue: values?.completedValue?.value,
				orderNumber: step?.orderNumber,
				weight: values?.weight?.value,
				countryIds: values?.countryIds?.map(item => item.value),
				projectTypeIds: values?.projectTypeIds?.map(item => item.value),
				contractTypeIds: values?.contractTypeIds?.map(item => item.value),
				calculations: values?.calculations.map(item => {
					return { option: item.value, displayName: item.label };
				}),
				responsibleTeam: {
					name: values?.responsibleTeam?.value,
					roleGroup: values?.responsibleTeam?.value,
				},
				forSystemSize: {
					value: values.systemSize,
					operator: values?.lessOrGreater ? values?.lessOrGreater : null,
				},
				projectTiles: values?.projectTiles?.map(item => item.value),
				projectFields: values?.projectFields?.map(item => item.value),
				automatedRule: values?.automationRules?.trim(),
				automationEnabled: hasAutomations,
			});

			await onDataChange();

			await setStepById();

			await setTemplateState();

			handleCancel();

			setState(prevState => ({
				...prevState,
				isSavingChanges: false,
				showRequireCompletionModal: false,
				isNewStep: false,
			}));

			resetForm({ values });
		} catch (error) {
			showToastError(error);
			if (!axios.isCancel(error)) {
				resetForm({ values: initialValues });
				handleCancel();
				setState(prevState => ({
					...prevState,
					isSavingChanges: false,
				}));
			}
		}
	};

	const setStepById = async () => {
		try {
			const clonedStages = cloneDeep(stages);
			const response = await getStepTemplateById(abortController.signal, step.id);

			setPMWorkflowState(prevState => ({
				...prevState,
				stages: clonedStages.map(item => {
					if (item.stage === stage.stage) {
						item.activities.map(act => {
							if (act.id === activity.id) {
								const objIndex = act.stepTemplates.findIndex(template => template.id === step.id);
								act.stepTemplates[objIndex] = {
									...response.data,
								};
							}
						});
					}
					return item;
				}),
			}));
		} catch (error) {
			showToastError(error);
		}
	};

	const handleCloseStep = () => {
		const clonedStages = cloneDeep(stages);

		setPMWorkflowState(prevState => ({
			...prevState,
			stages: clonedStages.map(item => {
				if (item.stage === stage.stage) {
					item.activities.map(act => {
						if (act.id === activity.id) {
							const objIndex = act.stepTemplates.findIndex(template => template.id === step.id);
							act.stepTemplates[objIndex] = step;
						}
					});
				}
				return item;
			}),
		}));
	};

	const properHandleChange = (name, e) => {
		const el = e.target;
		const isCheckbox = el.type === 'checkbox';
		const value = isCheckbox ? el.checked : el.value;
		setFieldValue(name, value);
	};

	useEffect(() => {
		if (state.isInEditMode) {
			setState(prevState => ({
				...prevState,
				isNewStep: values.title === 'New Step',
				applicableValues: getApplicableValues(values),
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.isInEditMode]);

	useEffect(() => {
		if (values.projectTiles.length > 0) {
			const newFieldOptions = getTileHighLightFieldsOptions(values.projectTiles);
			setState(prevState => ({
				...prevState,
				highlightFieldOptions: newFieldOptions,
			}));

			const filteredValues = filterHighlightedFieldsByTiles(values.projectTiles, values.projectFields);
			setFieldValue('projectFields', filteredValues);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values.projectTiles]);

	useEffect(() => {
		const setStatisticsOptions = async () => {
			try {
				const res = await getUnusedStatisticsOptions();
				const statisticsOptions = [];
				for (let i = 0; i < res.data.length; i++) {
					statisticsOptions.push({
						value: res.data[i].option,
						label: res.data[i].displayName,
					});
				}
				setState(prevState => ({
					...prevState,
					statisticsOptions,
				}));
			} catch (e) {
				showToastError(e);
			}
		};
		setStatisticsOptions();
	}, []);

	const getApplicableValues = pickValues => ({
		...pick(pickValues, ['projectTypeIds', 'contractTypeIds', 'countryIds', 'systemSize']),
	});

	const automationRulesTooltip = t(
		"Define the automation logic, using the following operators:  < , <= , > , >= , == , != , && (for AND) , || (for OR) \n\nExample rule: \nsystemSizeKwp >= 1000 AND country != null AND (country.name == 'Kenya' OR country.name == 'Vietnam'). \n\nWhen using relations, always first check if the relation != null (like in the example for country above). \n\nFor list with available fields, click the 'rules’ link",
	);

	return (
		<Wrapper>
			<ConfirmModal
				isOpen={state.showConfirmModal}
				onConfirm={() => confirmDeleteStep()}
				onCancel={() =>
					setState(prevState => ({
						...prevState,
						showConfirmModal: false,
					}))
				}
				confirmText="Delete"
				label="delete-step-confirm"
				heading={t('Delete Step')}
				text={t(`Are you sure you want to delete Step ${step.title}?`)}
			/>
			<ConfirmModal
				isOpen={state.showRequireCompletionModal}
				onConfirm={() => confirmSaveStep()}
				onCancel={() =>
					setState(prevState => ({
						...prevState,
						showRequireCompletionModal: false,
						isInEditMode: true,
						isLoading: false,
						isSavingChanges: false,
					}))
				}
				confirmText="Save"
				cancelText="Keep editing"
				label="warning-about-creating-open-steps"
				heading={t('Warning about creating open Steps')}
				text={`${state?.requireCompletionMessage?.map(message => message).join('\n\n')}
				
				${t('Do you want save and continue, or keep editing?')}`}
			/>
			<ConfirmModal
				noCancel
				isOpen={state.showDeleteModal}
				onConfirm={() =>
					setState(prevState => ({
						...prevState,
						showDeleteModal: false,
						isInEditMode: true,
						isLoading: false,
						isSavingChanges: false,
					}))
				}
				confirmText="Close"
				label="cannot-delete-steps"
				heading={t('Cannot delete Step')}
				text={t(
					'This step is used to calculate statistics.  Assign the statistics tag to another step and try again.',
				)}
			/>
			<ConfirmModal
				isOpen={state.showAutomationFieldsModal}
				onConfirm={() =>
					setState(prevState => ({
						...prevState,
						showAutomationFieldsModal: false,
					}))
				}
				noCancel
				confirmText="Close"
				heading=""
				text={`${automationFields?.map(field => field).join('\n\n')}`}
			/>
			<form onSubmit={handleSubmit}>
				<Accordion
					expanded={isExpanded}
					onChange={handleExpanded(step.id)}
					background={colors.grey.lightest}
					border={state.isInEditMode ? `1px solid ${colors.primary.dark}` : '1px solid rgba(255, 255, 255, .4)'}
					data-step-wrapper={step.title + '-' + activity.title}
				>
					<AccordionSummary>
						<Grid container justifyContent="space-between">
							<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
								<Grid container>
									<Text
										width={{
											xs: '104px',
										}}
										xs={4}
										sm={3}
										md={2}
										lg={2}
										xl={2}
									>
										{state.isLoading ? (
											<CircularProgress
												style={{
													color: colors.primary.main,
													marginRight: 13,
												}}
												size={15}
											/>
										) : state.expanded === step.id ? (
											<KeyboardArrowDown style={iconStyle} />
										) : (
											<KeyboardArrowRight style={iconStyle} />
										)}
										<StepTitle>
											{t('Step ' + stepIndex)}
											{state.isInEditMode && <Mandatory />}
										</StepTitle>
									</Text>
									<Grid item container xs={8} sm={9} md={10} lg={10} xl={10}>
										{state.isInEditMode ? (
											<StepTitleContainer>
												<FormikInput
													id={`title-${step.id}`}
													name="title"
													value={values.title}
													error={errors.title}
													touched={touched.title}
													onChange={properHandleChange.bind(undefined, 'title')}
													onBlur={handleBlur}
													isRequired={state.isInEditMode}
													isTile={!state.isInEditMode}
													isInEditMode={state.isInEditMode}
													onClick={e => state.isInEditMode && e.stopPropagation()}
												/>
											</StepTitleContainer>
										) : (
											<BoldText>
												<Name>{t(`${values.title}`)}</Name>
											</BoldText>
										)}
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={false} sm={false} md={4} lg={4} xl={4} display={{ xs: 'none', md: 'flex' }}>
								<Grid container>
									{isExpanded && (
										<ProjectType>{values?.forCampaign ? t('Campaign Step') : t('Project Step')}</ProjectType>
									)}
									<ButtonsWrapper>
										<Text
											onClick={e => {
												setState(prevState => ({
													...prevState,
													isInEditMode: true,
												}));
												isExpanded && e.stopPropagation();
											}}
										>
											{t('Weight:')}
										</Text>
										{state.isInEditMode ? (
											<FormSelectField onClick={e => e.stopPropagation()}>
												<FormikSelect
													id={`weight-${step.id}`}
													name="weight"
													value={values.weight}
													error={errors.weight}
													touched={touched.weight}
													setFieldValue={setFieldValue}
													options={weightOptions}
													isTile={!state.isInEditMode}
													isRequired={state.isInEditMode}
													isInEditMode={state.isInEditMode}
												/>
											</FormSelectField>
										) : (
											<Text>{values.weight.value}</Text>
										)}
										{isExpanded ? (
											<div aria-hidden="true" onClick={e => e.stopPropagation()}>
												<MoreActionsButton
													noBackground
													label="PMWorkflow Template Step More Actions"
													dataAction={`options-${values.title}`}
												>
													<MenuItem
														onClick={() =>
															setState(prevState => ({
																...prevState,
																showConfirmModal: true,
															}))
														}
														label="PMWorkflow Template Step - Delete Menu Item"
														dataAction={`delete-${values.title}`}
													>
														{t('Delete')}
													</MenuItem>
												</MoreActionsButton>
											</div>
										) : (
											<EmptyDiv size="20px" />
										)}
										{!isExpanded ? (
											<MoveButtons onClick={event => event.stopPropagation()}>
												<Button
													small
													secondary
													icon="keyboardArrowDown"
													isLoading={state.isStepMoving}
													disabled={
														state.isInEditMode ||
														(stageIndex === stagesLength - 1 &&
															currentIdIndex === activity.stepTemplates.length - 1 &&
															activityIndex === stageActivities.length - 1)
													}
													onClick={() =>
														moveStepWithSignal(
															'down',
															ids,
															values,
															currentIdIndex,
															step,
															steps,
															activity,
															stageActivities,
															stages,
															stageIndex,
															setActivityState,
															onDataChange,
															t,
															setTemplateState,
															setActivityStepTemplates,
															updateActivityStepTemplates,
															setState,
														)
													}
													label="PMWorkflow Template Step - Move Step Down Button"
												/>
												<Button
													small
													secondary
													icon="keyboardArrowUp"
													disabled={
														state.isInEditMode || (stageIndex === 0 && activityIndex === 0 && currentIdIndex === 0)
													}
													isLoading={state.isStepMoving}
													onClick={() =>
														moveStepWithSignal(
															'up',
															ids,
															values,
															currentIdIndex,
															step,
															steps,
															activity,
															stageActivities,
															stages,
															stageIndex,
															setActivityState,
															onDataChange,
															t,
															setTemplateState,
															setActivityStepTemplates,
															updateActivityStepTemplates,
															setState,
														)
													}
													label="PMWorkflow Template Step - Move Step Up Button"
												/>
											</MoveButtons>
										) : state.isInEditMode ? (
											<Row aria-hidden="true" onClick={e => e.stopPropagation()}>
												<Button
													small
													secondary
													icon="clear"
													onClick={handleCancel}
													disabled={state.isSavingChanges}
													label="PMWorkflow Template Step - Cancel Button"
												/>
												<Button
													small
													type="submit"
													icon="check"
													dataAction={`saveStep-${values.title}`}
													disabled={state.isSavingChanges}
													isLoading={state.isSavingChanges}
													label="PMWorkflow Template Step - Submit Button"
												/>
											</Row>
										) : (
											<EmptyDiv size="66px" />
										)}
									</ButtonsWrapper>
								</Grid>
							</Grid>
						</Grid>
					</AccordionSummary>
					<StyledAccordionDetails background={colors.grey.lightest} onClick={handleStepClick} $isMobile={isMobile}>
						{isExpanded ? (
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container direction="column">
									<Grid item>
										<StyledText>{t('Applicable to')}</StyledText>
									</Grid>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
											<Grid container direction="column" spacing={2}>
												<TemplateFormField item>
													<StyledFormikSelect
														id={`projectTypeIds-${step.id}`}
														name="projectTypeIds"
														label={t('Projects')}
														value={values.projectTypeIds}
														error={errors.projectTypeIds}
														touched={touched.projectTypeIds}
														setFieldValue={setFieldValue}
														options={projectTypes}
														isTile={!state.isInEditMode}
														isInEditMode={state.isInEditMode}
														isMulti
														multiPlaceholder="All"
													/>
												</TemplateFormField>
												<TemplateFormField item>
													<StyledFormikSelect
														id={`countryIds-${step.id}`}
														name="countryIds"
														label={t('Countries')}
														value={values.countryIds}
														error={errors.countryIds}
														touched={touched.countryIds}
														setFieldValue={setFieldValue}
														onFocus={() => handleStepClick()}
														options={countries}
														isTile={!state.isInEditMode}
														isInEditMode={state.isInEditMode}
														isMulti
														multiPlaceholder="All"
													/>
												</TemplateFormField>
												<TemplateFormField item>
													<StyledFormikSelect
														id={`contractTypeIds-${step.id}`}
														name="contractTypeIds"
														label={t('Contracts')}
														value={values.contractTypeIds}
														error={errors.contractTypeIds}
														touched={touched.contractTypeIds}
														setFieldValue={setFieldValue}
														onFocus={() => handleStepClick()}
														options={contractTypes}
														isTile={!state.isInEditMode}
														isInEditMode={state.isInEditMode}
														isMulti
														multiPlaceholder="All"
													/>
												</TemplateFormField>
												<TemplateFormField item>
													<FormikInput
														id={`systemSize-${step.id}`}
														name="systemSize"
														label={t('System size')}
														placeholder={t('All')}
														value={values.systemSize || ''}
														error={errors.systemSize}
														touched={touched.systemSize}
														onChange={properHandleChange.bind(undefined, 'systemSize')}
														onBlur={handleBlur}
														isTile={!state.isInEditMode}
														isInEditMode={state.isInEditMode}
													/>
												</TemplateFormField>
												<TemplateFormField item>
													{values.systemSize && (
														<FormikRadio
															id={`lessOrGreater-${step.id}`}
															name="lessOrGreater"
															value={values.lessOrGreater}
															error={errors.lessOrGreater}
															touched={touched.lessOrGreater}
															onChange={properHandleChange.bind(undefined, 'lessOrGreater')}
															options={[
																{
																	value: 'LESS_THEN',
																	label: 'or less',
																	disabled: !state.isInEditMode,
																},
																{
																	value: 'GREATER_THAN',
																	label: 'or greater',
																	disabled: !state.isInEditMode,
																},
															]}
															isRow
															isTile={!state.isInEditMode}
															isInEditMode={state.isInEditMode}
														/>
													)}
												</TemplateFormField>
											</Grid>
										</Grid>
										<Grid item container direction="column" xs={12} sm={12} md={6} lg={4} xl={4} spacing={2}>
											<TemplateFormField item>
												<StyledFormikSelect
													id={`completedValue-${step.id}`}
													name="completedValue"
													label={t('When parent activity is already completed')}
													value={values.completedValue}
													error={errors.completedValue}
													touched={touched.completedValue}
													setFieldValue={setFieldValue}
													onFocus={() => handleStepClick()}
													onBlur={handleBlur}
													options={completedTypes}
													isRequired={state.isInEditMode}
													isTile={!state.isInEditMode}
													isInEditMode={state.isInEditMode}
													tooltip={{
														tooltip: t(
															'Determines if existing Projects must retrospectively complete this Step, even if they have already completed the parent Activity.  It applies when creating new Steps or editing the Applicable to filter so a Step applies to more Projects.  Use ‘Set as complete’ for unimportant Steps that do not need to be completed for Projects that have already completed the parent Activity.  E.g. adding a new Step to a due diligence Activity and it is not necessary for Projects which have already completed their due diligence.    Use ‘Require completion‘ for important Steps that must be completed for all Projects retrospectively.  E.g. a new compliance Step that all new and existing Projects must complete.  Existing Projects may have the related Stage and Activity re-opened so the Step can be completed.  If a Project has not yet completed the parent Activity, this option has no effect and the Step must always be completed',
														),
													}}
													maxWidth="290px"
												/>
											</TemplateFormField>
											<TemplateFormField item>
												<CheckboxContainer>
													<FormikCheckbox
														label={t('Can be skipped')}
														id={`canBeSkipped-${step.id}`}
														name="canBeSkipped"
														checked={values.canBeSkipped}
														onChange={properHandleChange.bind(undefined, 'canBeSkipped')}
														onBlur={handleBlur}
														touched={touched.canBeSkipped}
														disabled={!state.isInEditMode}
														tooltip={{
															tooltip: t(
																'Allow users to Skip this step instead of completing it.  pulse will show the Step was not completed',
															),
														}}
													/>
												</CheckboxContainer>
											</TemplateFormField>
											<TemplateFormField item>
												<StyledFormikSelect
													id={`responsibleTeam-${step.id}`}
													name="responsibleTeam"
													label={t('Responsibe Team')}
													value={values.responsibleTeam}
													error={errors.responsibleTeam}
													touched={touched.responsibleTeam}
													setFieldValue={setFieldValue}
													onFocus={() => handleStepClick()}
													options={responsibleTeamOptions}
													isTile={!state.isInEditMode}
													isRequired={state.isInEditMode}
													isInEditMode={state.isInEditMode}
												/>
											</TemplateFormField>
											<TemplateFormField item>
												<StyledFormikSelect
													id={`projectTiles-${step.id}`}
													name="projectTiles"
													label={t('Tiles to use')}
													value={values.projectTiles}
													error={errors.projectTiles}
													touched={touched.projectTiles}
													setFieldValue={setFieldValue}
													onFocus={() => handleStepClick()}
													options={PM_WORKFLOW_TILES_OPTIONS}
													isTile={!state.isInEditMode}
													isMulti
													isInEditMode={state.isInEditMode}
													multiPlaceholder="All"
												/>
											</TemplateFormField>
											<TemplateFormField item>
												<ConditionalGridSection item hasFlag={values.projectTiles.length > 0} zindex={1}>
													<StyledFormikSelect
														id={`projectFields-${step.id}`}
														name="projectFields"
														label={t('Highlight fields')}
														value={values.projectFields}
														error={errors.projectFields}
														touched={touched.projectFields}
														setFieldValue={setFieldValue}
														onFocus={() => handleStepClick()}
														options={state.highlightFieldOptions}
														isTile={!state.isInEditMode}
														isMulti
														isInEditMode={state.isInEditMode}
														multiPlaceholder="Edit Here"
													/>
												</ConditionalGridSection>
											</TemplateFormField>
											<TemplateFormField item>
												<FormikInput
													id={`expectedDuration-${step.id}`}
													name="expectedDuration"
													label={t('Expected Duration')}
													value={values.expectedDuration}
													error={errors.expectedDuration}
													touched={touched.expectedDuration}
													onChange={properHandleChange.bind(undefined, 'expectedDuration')}
													onBlur={handleBlur}
													isTile={!state.isInEditMode}
													isInEditMode={state.isInEditMode}
													tooltip={{
														tooltip: t(
															'The number of days it is expected the step will need to be completed.  If the step takes longer the Project may be considered off-track',
														),
													}}
												/>
											</TemplateFormField>
											<TemplateFormField item>
												<StyledFormikSelect
													id={`calculations-${step.id}`}
													name="calculations"
													label={t('Use for statistics')}
													value={values.calculations}
													error={errors.calculations}
													touched={touched.calculations}
													setFieldValue={setFieldValue}
													onFocus={() => handleStepClick()}
													options={state.statisticsOptions}
													isTile={!state.isInEditMode}
													isInEditMode={state.isInEditMode}
													isMulti
													multiPlaceholder="All"
													tooltip={{
														tooltip: t(
															'When this step is completed, the project will count towards the selected statistics.  Each statistic can only be assigned to single step.  It is recommended to assign statistics only to steps which apply to all Projects, and therefore no Applicable to filters should be set.',
														),
													}}
												/>
											</TemplateFormField>
											<TemplateFormField item>
												<FormikInput
													id={`description-${step.id}`}
													name="description"
													label={t('Description')}
													value={values.description}
													error={errors.description}
													touched={touched.description}
													onChange={properHandleChange.bind(undefined, 'description')}
													onBlur={handleBlur}
													isTile={!state.isInEditMode}
													isInEditMode={state.isInEditMode}
												/>
											</TemplateFormField>
											<TemplateFormField item>
												<FormikTextArea
													id={`automationRules-${step.id}`}
													name="automationRules"
													label={t('Automation rules')}
													value={values.automationRules}
													error={errors.automationRules}
													touched={touched.automationRules}
													onChange={properHandleChange.bind(undefined, 'automationRules')}
													onBlur={handleBlur}
													isTile={!state.isInEditMode}
													isInEditMode={state.isInEditMode}
													tooltip={{
														tooltip: automationRulesTooltip,
													}}
												/>
												<StyledLabel
													label={'rules'}
													onClick={() =>
														setState(prevState => ({
															...prevState,
															showAutomationFieldsModal: true,
														}))
													}
												/>
											</TemplateFormField>
										</Grid>
									</Grid>
									<BottomGrid item container justifyContent="flex-end">
										<Row aria-hidden="true" onClick={event => event.stopPropagation()}>
											<Button
												small
												secondary
												icon="keyboardArrowDown"
												isLoading={state.isStepMoving}
												onClick={() =>
													moveStepWithSignal(
														'down',
														ids,
														values,
														currentIdIndex,
														step,
														steps,
														activity,
														stageActivities,
														stages,
														stageIndex,
														setActivityState,
														onDataChange,
														t,
														setTemplateState,
														setActivityStepTemplates,
														updateActivityStepTemplates,
														setState,
													)
												}
												disabled={
													state.isInEditMode ||
													(stageIndex === stagesLength - 1 && currentIdIndex === activity.stepTemplates.length - 1)
												}
												label="PMWorkflow Template Step - Move Step Down Button"
											/>
											<Button
												small
												secondary
												icon="keyboardArrowUp"
												isLoading={state.isStepMoving}
												disabled={state.isInEditMode || (stageIndex === 0 && activityIndex === 0 && currentIdIndex === 0)}
												onClick={() =>
													moveStepWithSignal(
														'up',
														ids,
														values,
														currentIdIndex,
														step,
														steps,
														activity,
														stageActivities,
														stages,
														stageIndex,
														setActivityState,
														onDataChange,
														t,
														setTemplateState,
														setActivityStepTemplates,
														updateActivityStepTemplates,
														setState,
													)
												}
												label="PMWorkflow Template Step - Move Step Up Button"
											/>
										</Row>
									</BottomGrid>
								</Grid>
							</Grid>
						) : null}
					</StyledAccordionDetails>
				</Accordion>
			</form>
		</Wrapper>
	);
};

TemplateStep.defaultProps = {
	step: {},
	steps: [],
	stage: {},
	stages: [],
	stepIndex: 0,
	activity: {},
	stageIndex: 0,
	countries: [],
	iconStyle: {},
	stagesLength: 0,
	activityIndex: 0,
	projectTypes: [],
	contractTypes: [],
	stageActivities: [],
	automationFields: [],
	onDataChange: () => {},
	setActivityState: () => {},
	setTemplateState: () => {},
	setPMWorkflowState: () => {},
	setActivityStepTemplates: () => {},
	updateActivityStepTemplates: () => {},
};

TemplateStep.propTypes = {
	step: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		orderNumber: PropTypes.number,
		title: PropTypes.string,
	}),
	steps: PropTypes.arrayOf(PropTypes.shape({})),
	stage: PropTypes.shape({
		stage: PropTypes.string,
	}),
	stages: PropTypes.arrayOf(PropTypes.shape({})),
	activity: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		title: PropTypes.string,
		stepTemplates: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	countries: PropTypes.arrayOf(PropTypes.shape({})),
	iconStyle: PropTypes.shape({}),
	stepIndex: PropTypes.number,
	stageIndex: PropTypes.number,
	onDataChange: PropTypes.func,
	projectTypes: PropTypes.arrayOf(PropTypes.shape({})),
	contractTypes: PropTypes.arrayOf(PropTypes.shape({})),
	stagesLength: PropTypes.number,
	activityIndex: PropTypes.number,
	stageActivities: PropTypes.arrayOf(PropTypes.shape({})),
	setActivityState: PropTypes.func,
	setTemplateState: PropTypes.func,
	automationFields: PropTypes.arrayOf(PropTypes.string),
	setPMWorkflowState: PropTypes.func,
	setActivityStepTemplates: PropTypes.func,
	updateActivityStepTemplates: PropTypes.func,
};

export default TemplateStep;
