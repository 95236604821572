import Overlay, { useOverlayTitles } from 'Common/components/modals/Overlay';
import useAbortController from 'Common/hooks/useAbortController';
import showToastError from 'Common/utils/showToastError';
import getInvoiceById from 'Invoice/api/getInvoiceById';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import sizes from 'Application/theme/sizes';
import { useMemo } from 'react';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import removeAttachedDocument from 'Invoice/api/removeAttachedDocument';
import { useCallback } from 'react';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import deleteInvoice from 'Invoice/api/deleteInvoice';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import crudModes from 'Common/constants/crudModes';
import InvoiceForm from 'Invoice/components/Forms/InvoiceForm';
import useResponsive from 'Common/hooks/useResponsive';

const StyledMoreActionsButton = styled(MoreActionsButton)`
	position: absolute;
	right: ${({ $isMobile }) => ($isMobile ? sizes.spacing(3) : sizes.spacing(5))};
	top: -${sizes.spacing(1)};
`;

const InvoiceOverlay = ({ mode, isOpen, onClose, onFormSubmit, onInvoiceDelete, data }) => {
	const { t } = useTranslation();
	const { name, label } = useOverlayTitles(t('Invoice'), crudModes.CREATE);
	const isEditMode = useMemo(() => mode === crudModes.EDIT, [mode]);
	const isCreateMode = useMemo(() => mode === crudModes.CREATE, [mode]);
	const [isLoadingContent, setIsLoadingContent] = useState(false);
	const [invoice, setInvoice] = useState({});
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [isInvoiceRestricted, setIsInvoiceRestricted] = useState(false);
	const { isMobile } = useResponsive();

	const abortController = useAbortController();

	const breadcrumbList = useMemo(
		() => [
			{
				label: 'Invoices',
				href: `/projects/details/${data.projectId}`,
			},
			{
				label: isEditMode ? String(data.invoiceId) : name,
			},
		],
		[isEditMode, data.invoiceId, data.projectId, name],
	);

	const fetchInvoice = useCallback(
		async initialFetch => {
			try {
				if (isCreateMode) return;
				if (initialFetch) setIsLoadingContent(true);

				const { data: invoice } = await getInvoiceById(abortController.signal, data.invoiceId);
				setInvoice(invoice);

				if (initialFetch) setIsLoadingContent(false);
			} catch (e) {
				showToastError(e, t('Something went wrong'));
			}
		},
		[abortController.signal, data.invoiceId, isCreateMode, t],
	);

	useEffect(() => {
		fetchInvoice(true);
	}, [fetchInvoice]);

	const handleDataChange = useCallback(() => {
		onFormSubmit();
		fetchInvoice(false);
	}, [fetchInvoice, onFormSubmit]);

	const handleDeleteAttachedDocument = useCallback(async () => {
		try {
			await removeAttachedDocument(abortController.signal, data.invoiceId);

			notify(t('Attached document removed successfully'), {
				type: toast.TYPE.SUCCESS,
			});

			handleDataChange();
		} catch (e) {
			showToastError(e, t('Something went wrong while removing invoice attached document'));
		}
	}, [abortController.signal, data.invoiceId, handleDataChange, t]);

	const handleDeleteInvoice = useCallback(async () => {
		try {
			await deleteInvoice(abortController.signal, data.invoiceId);

			notify(t('Invoice deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});

			onInvoiceDelete();
			onClose();
		} catch (e) {
			showToastError(e, t('Something went wrong while deleting invoice'));
		}
	}, [abortController.signal, data.invoiceId, t, onClose, onInvoiceDelete]);

	// Cannot edit value for invoices in status ‘Pending’ or ‘Paid’.
	const InvoiceOverlayActions = useMemo(
		() => (
			<StyledMoreActionsButton $isMobile={isMobile}>
				<MenuItem onClick={handleDeleteAttachedDocument} disabled={!invoice.attachedDocument}>
					Delete Attached Document
				</MenuItem>
				<MenuItem onClick={handleDeleteInvoice} disabled={isInvoiceRestricted}>
					Delete Invoice
				</MenuItem>
			</StyledMoreActionsButton>
		),
		[handleDeleteAttachedDocument, handleDeleteInvoice, isInvoiceRestricted, invoice, isMobile],
	);

	return (
		<Overlay
			label={label}
			breadcrumbList={breadcrumbList}
			isOpen={isOpen}
			onClose={onClose}
			onSave={handleDataChange}
			isSubmitting={isSavingChanges}
			headerContent={isEditMode && InvoiceOverlayActions}
			isLoadingContent={isLoadingContent}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<InvoiceForm
					invoice={invoice}
					onClose={onCancel}
					onDirty={setIsFormDirty}
					data={data}
					mode={mode}
					onSubmit={handleSubmit}
					setIsInvoiceRestricted={setIsInvoiceRestricted}
					isSavingChanges={isSavingChanges}
					setIsSavingChanges={setIsSavingChanges}
				/>
			)}
		</Overlay>
	);
};

InvoiceOverlay.defaultProps = {
	data: {},
	isOpen: false,
	onClose: () => {},
	onFormSubmit: () => {},
	onInvoiceDelete: () => {},
};

InvoiceOverlay.propTypes = {
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
	onInvoiceDelete: PropTypes.func,
	data: PropTypes.shape({
		//Create specific
		newInvoiceNumber: PropTypes.string,
		projectExternalId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		//Edit specific
		invoiceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

		//Both
		entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		country: PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
	}),
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onFormSubmit: PropTypes.func,
};

export default InvoiceOverlay;
