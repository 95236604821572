import api from 'Application/api/api';

const getProjectImageDownloadUrl = (signal, projectId, imageName) =>
	api.get('/images/download-link', {
		params: {
			projectId,
			imageName,
		},
		signal,
	});

export default getProjectImageDownloadUrl;
