import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';
import Mandatory from './Mandatory';
import sizes from 'Application/theme/sizes';

const Wrapper = styled.label`
	color: ${({ $color }) => $color};
	${sizes.fontSize.main};
	font-weight: 700;
	line-height: 22px;
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
`;

const Label = ({ label, color, tooltip: propTooltip, isRequired, htmlFor, ...props }) => {
	const tooltip = typeof propTooltip === 'string' ? { tooltip: propTooltip } : propTooltip;

	return (
		<Wrapper $color={color} htmlFor={htmlFor} {...props}>
			{label}
			{isRequired && <Mandatory />}
			{!!propTooltip && <QuestionTooltip {...tooltip} />}
		</Wrapper>
	);
};

Label.defaultProps = {
	label: '',
	color: 'inherit',
	tooltip: '',
	isRequired: false,
	htmlFor: '',
};

Label.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({}).isRequired]),
	color: PropTypes.string,
	tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ tooltip: PropTypes.string })]),
	isRequired: PropTypes.bool,
	htmlFor: PropTypes.string,
};

export default Label;
