import api from 'Application/api/api';

const getContactByEmail = (signal, email, details) =>
	api.get(`/contacts/by-email/${email}`, {
		params: {
			details,
		},
		signal,
	});

export default getContactByEmail;
