import { Grid } from '@mui/material';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';
import { SectionWrapper } from 'Common/components/modals/OverlaySections';
import crudModes from 'Common/constants/crudModes';
import UNITS from 'Common/constants/units';
import formatDate from 'Common/utils/formatDate';
import parseDate from 'Common/utils/parseDate';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const EcoligoEconomicResultsSection = ({
	mode,
	values,
	errors,
	touched,
	handleBlur,
	handleChange,
	setFieldValue,
	setFieldTouched,
}) => {
	const { t } = useTranslation();
	const isViewMode = mode === crudModes.VIEW;

	return !isViewMode ? (
		<SectionWrapper>
			<Grid container columnSpacing={10}>
				<Grid item xs={12} md={6}>
					<FormField data-first-field>
						<FormikInput
							unit={UNITS.PERCENT}
							id="projectedIrr"
							name="projectedIrr"
							label={t('Projected IRR')}
							value={values.projectedIrr}
							error={errors.projectedIrr}
							touched={touched.projectedIrr}
							onChange={handleChange}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							label={t('Minimum DSCR')}
							id="minimumDscr"
							name="minimumDscr"
							error={errors?.minimumDscr}
							touched={touched?.minimumDscr}
							value={values?.minimumDscr}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							label={t('Average DSCR')}
							id="averageDscr"
							name="averageDscr"
							error={errors?.averageDscr}
							touched={touched?.averageDscr}
							value={values?.averageDscr}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							unit={UNITS.PERCENT}
							id="worstCaseIrr"
							name="worstCaseIrr"
							label={t('Worst case IRR')}
							value={values.worstCaseIrr}
							error={errors.worstCaseIrr}
							touched={touched.worstCaseIrr}
							onChange={handleChange}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormField>
						<FormikSingleDatePicker
							id="saasSignatureDate"
							label={t('SaaS signature date')}
							startDateName="saasSignatureDate"
							error={errors.saasSignatureDate}
							touched={touched.saasSignatureDate}
							startDate={parseDate(values.saasSignatureDate)}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField data-last-field>
						<FormikInput
							id="contractNumber"
							name="contractNumber"
							label={t('Contract number')}
							value={values.contractNumber}
							error={errors.contractNumber}
							touched={touched.contractNumber}
							onChange={handleChange}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
				</Grid>
			</Grid>
		</SectionWrapper>
	) : (
		<SectionWrapper>
			<Grid container columnSpacing={10}>
				<Grid item xs={12} md={6}>
					<KeyValueVisualization
						id="projectedIrr"
						title={t('Projected IRR')}
						value={values.projectedIrr}
						unit={UNITS.PERCENT}
					/>
					<KeyValueVisualization id="minimumDscr" title={t('Minimum DSCR')} value={values.minimumDscr} />
					<KeyValueVisualization id="averageDscr" title={t('Average DSCR')} value={values.averageDscr} />
					<KeyValueVisualization
						id="worstCaseIrr"
						title={t('Worst case IRR')}
						value={values.worstCaseIrr}
						unit={UNITS.PERCENT}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<KeyValueVisualization
						id="saasSignatureDate"
						title={t('SaaS signature date')}
						value={values.saasSignatureDate ? formatDate(values.saasSignatureDate) : ''}
					/>
					<KeyValueVisualization id="contractNumber" title={t('Contract number')} value={values.contractNumber} />
				</Grid>
			</Grid>
		</SectionWrapper>
	);
};

EcoligoEconomicResultsSection.defaultProps = {
	mode: crudModes.CREATE,
	values: {},
	errors: {},
	touched: {},
	handleBlur: () => {},
	handleChange: () => {},
	setFieldValue: () => {},
	setFieldTouched: () => {},
};

EcoligoEconomicResultsSection.propTypes = {
	mode: PropTypes.oneOf([crudModes.CREATE, crudModes.EDIT, crudModes.VIEW]),
	values: PropTypes.shape({
		projectedIrr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		worstCaseIrr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		saasSignatureDate: PropTypes.instanceOf(Date),
		contractNumber: PropTypes.string,
		minimumDscr: PropTypes.string,
		averageDscr: PropTypes.string,
	}),
	errors: PropTypes.shape({
		projectedIrr: PropTypes.string,
		worstCaseIrr: PropTypes.string,
		saasSignatureDate: PropTypes.string,
		contractNumber: PropTypes.string,
		minimumDscr: PropTypes.string,
		averageDscr: PropTypes.string,
	}),
	touched: PropTypes.shape({
		projectedIrr: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
		worstCaseIrr: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
		saasSignatureDate: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
		contractNumber: PropTypes.bool,
		minimumDscr: PropTypes.bool,
		averageDscr: PropTypes.bool,
	}),
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	setFieldValue: PropTypes.func,
	setFieldTouched: PropTypes.func,
};

export default EcoligoEconomicResultsSection;
