import { useMemo } from 'react';
import * as yup from 'yup';

const useMarketingFormValidationSchema = () =>
	useMemo(
		() =>
			yup.object({
				tags: yup
					.array()
					.of(
						yup.object().shape({
							value: yup.number(),
							label: yup.string(),
						}),
					)
					.nullable(),
			}),
		[],
	);

export default useMarketingFormValidationSchema;
