import { FILTER_TYPES } from 'Common/components/filter/constants/filterConstants';

const generateStageFilterValues = (newValues, _, filter) => {
	if (filter.type !== FILTER_TYPES.STAGE) return newValues;

	const modifiedValues = { ...newValues };

	if (newValues[filter.stageStatus] === 'custom') {
		const from = newValues[filter.minName];
		const to = newValues[filter.maxName];

		modifiedValues[filter.stageStatus] = '';

		if (!from) {
			modifiedValues[filter.minName] = '0';
		}
		if (!to) {
			modifiedValues[filter.maxName] = '100';
		}
	} else {
		modifiedValues[filter.minName] = '';
		modifiedValues[filter.maxName] = '';
	}

	return modifiedValues;
};

export default generateStageFilterValues;
