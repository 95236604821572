import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

import CommentList from 'Common/components/Comments/CommentList';
import styled from 'styled-components/macro';
import sizing from 'Application/theme/sizes';
import useEntityDetailsCommentsData from 'Entities/components/EntitiesDetails/hooks/useEntityDetailsCommentsData';

const Comment = styled.div`
	padding-top: ${sizing.spacing(6)};
`;
const CommentLabel = styled.div`
	color: ${colors.text.grey};
	font-size: 14px;
	font-weight: 700;
`;
const CommentContent = styled.div`
	font-size: 16px;
	font-weight: 400;
`;

const EntitiyDetailsSidePanelComments = ({ entity, handleChange }) => {
	const { defaultComments, onAddComment, onEditComment, onDeleteComment } = useEntityDetailsCommentsData(
		entity,
		handleChange,
	);

	return (
		<>
			<CommentList
				defaultComments={defaultComments}
				onAddComment={onAddComment}
				onEditComment={onEditComment}
				onDeleteComment={onDeleteComment}
			/>

			{Boolean(entity?.comment) && (
				<Comment>
					<CommentLabel>Comment</CommentLabel>
					<CommentContent>{entity?.comment}</CommentContent>
				</Comment>
			)}
		</>
	);
};

EntitiyDetailsSidePanelComments.defaultProps = {
	entity: {},
	handleChange: () => {},
};

EntitiyDetailsSidePanelComments.propTypes = {
	entity: PropTypes.shape({
		comment: PropTypes.string,
	}),
	handleChange: PropTypes.func,
};

export default EntitiyDetailsSidePanelComments;
