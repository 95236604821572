// Filters out duplicate hooks from the list of hooks
// Filters by reference
const removeDuplicateHooks = hooks =>
	hooks.reduce((prev, hook) => {
		const isDuplicate = prev.some(prevHook => prevHook === hook);

		if (!isDuplicate) {
			return [...prev, hook];
		}
		return prev;
	}, []);

export default removeDuplicateHooks;
