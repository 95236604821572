import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';
import FormField from 'Common/components/form/FormField';
import { useFormik } from 'formik';
import FormikSelect from 'Common/components/form/FormikSelect';
import FormikTextArea from 'Common/components/form/FormikTextArea';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import FormikStandaloneRadio from 'Common/components/form/FormikStandaloneRadio';
import loadClientOptions from 'Client/utils/loadClientOptions';
import useRespondModalFormInitialValues from './hooks/useRespondModalFormInitialValues';
import useRespondModalFormValidationSchema from './hooks/useRespondModalFormValidationSchema';
import respondForExistingProject from 'Inquiries/api/respondForExistingProject';
import respondForNewProject from 'Inquiries/api/respondForNewProject';
import loadProjectOptions from 'Projects/utils/loadProjectOptions';
import respondDecline from 'Inquiries/api/respondDecline';
import TextButton from 'Common/components/buttons/TextButton';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import colors from 'Application/theme/colors';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { PropTypes } from 'prop-types';
import ClientOverlay from 'Client/components/ClientForm/ClientOverlay';
import crudModes from 'Common/constants/crudModes';
import { OverlayButtons } from 'Common/components/modals/Overlay';
import { useLocation } from 'react-router';

const Form = styled.form`
	width: 100%;
	max-width: 720px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const SeparatingLine = styled.div`
	width: 100%;
	height: 1px;
	background: ${colors.grey.main};
	margin-bottom: 20px;
`;

const ContentWrapper = styled.div``;

const MessageWrapper = styled.div`
	padding: 0 120px;
	width: 100%;
`;

const StyledFormikStandaloneRadio = styled(FormikStandaloneRadio)`
	padding-left: 0 !important;
`;

const RadioText = styled.div`
	padding-left: 20px;

	& > .text {
		font-size: 15px;
		margin: 10px 0;
	}
`;

const EditLink = styled(TextButton)`
	padding: 0 20px;
`;

const RespondForm = ({ onSubmit, onCancel, inquiry, onDirty }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const { pathname } = useLocation();

	const abortController = useAbortController();

	const initialValues = useRespondModalFormInitialValues();
	const validationSchema = useRespondModalFormValidationSchema();

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleChange,
		handleBlur,
		setFieldValue,
		setFieldError,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			setIsLoading(true);
			let response;

			try {
				if (values.responseChoice === 'newProject') {
					response = await respondForNewProject(abortController.signal, {
						clientId: values.unknownClient ? null : values.client.value,
						inquiryId: inquiry.id,
						message: values.message,
						hideClientName: values.hideClientName,
					});
				} else if (values.responseChoice === 'existingProject') {
					response = await respondForExistingProject(abortController.signal, {
						projectId: values.project.value,
						inquiryId: inquiry.id,
						message: values.message,
						hideClientName: values.hideClientName,
					});
				} else if (values.responseChoice === 'decline') {
					response = await respondDecline(abortController.signal, {
						inquiryId: inquiry.id,
						message: values.message,
						hideClientName: values.hideClientName,
					});
				} else {
					notify(t('Select an option first'), {
						type: toast.TYPE.ERROR,
					});
					setIsLoading(false);
					return;
				}

				notify(t('Inquiry response sent successfully'), {
					type: toast.TYPE.SUCCESS,
				});

				onSubmit(response?.data);

				// Re-set all fields and mark them as not touched
				resetForm({ values });
				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	useEffect(() => {
		if (values.unknownClient || values.responseChoice !== 'newProject') {
			setFieldValue('client', null);
		}
		if (values.responseChoice !== 'existingProject') {
			setFieldValue('project', null);
		}
	}, [values, setFieldValue]);

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	const [clientFormSubmitCount, setClientFormSubmitCount] = useState(0);

	const handleClientFormSubmit = client => {
		setClientFormSubmitCount(prevCount => prevCount + 1);

		setFieldValue('client', {
			value: client.id,
			label: client.name,
		});
	};

	const isUnknownClient = values.unknownClient;

	const [isCreateClientOverlayOpened, setIsCreateClientOverlayOpened] = useState(false);

	const [isEditClientOverlayOpened, setIsEditClientOverlayOpened] = useState(false);

	const hasClient = !!values.client;

	const createClientBreadcrumbList = useMemo(
		() => [
			{ label: 'Inquiries', href: '/inquiries', forceRefresh: true },
			{
				label: inquiry.clientName || '(No Client)',
				href: `/inquiries/details/${inquiry.id}`,
				forceRefresh: true,
			},
			{ label: 'Inquiry Respond', href: pathname },
			{ label: 'Create Client' },
		],
		[pathname, inquiry],
	);

	const editClientBreadcrumbList = useMemo(
		() => [
			{ label: 'Inquiries', href: '/inquiries', forceRefresh: true },
			{
				label: inquiry.clientName || '(No Client)',
				href: `/inquiries/details/${inquiry.id}`,
				forceRefresh: true,
			},
			{ label: 'Inquiry Respond', href: pathname },
			{ label: 'Edit Client' },
		],
		[pathname, inquiry],
	);

	return (
		<Form onSubmit={handleSubmit}>
			<ContentWrapper>
				<FormField>
					<FormikStandaloneRadio
						checked={values.responseChoice === 'newProject'}
						onChange={() => setFieldValue('responseChoice', 'newProject')}
						name={'newProject'}
						label={t('Create a new Project')}
					/>
					<RadioText>
						<div className="text">
							Create a new Project, and save an EPC inquiry and Client offer within the Project. Select this option
							when the inquiry is for a new Project not known to ecoligo.
						</div>
						{values.responseChoice === 'newProject' && (
							<>
								<FormField>
									<FormikCheckbox
										id="unknownClient"
										name="unknownClient"
										label={t('Unknown Client')}
										checked={values.unknownClient}
										error={errors.unknownClient}
										touched={touched.unknownClient}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</FormField>
								<FormField>
									<FormikSelect
										isAsync
										id="client"
										name="client"
										label={t('Client')}
										value={values.client}
										error={errors.client}
										touched={touched.client}
										isClearable
										setFieldValue={setFieldValue}
										onBlur={handleBlur}
										loadOptions={loadClientOptions}
										isDisabled={isUnknownClient}
										createLinkTitle="Create Client"
										onCreateClick={() => setIsCreateClientOverlayOpened(true)}
										shouldDefaultOptionsUpdate={clientFormSubmitCount}
										isRequired={!isUnknownClient}
									/>
									{hasClient && (
										<EditLink
											type="button"
											text={t('Edit Client')}
											onClick={() => setIsEditClientOverlayOpened(true)}
											label="Inquiries Inquiry Details Respond Midal Edint Client Button"
										/>
									)}
								</FormField>
							</>
						)}
					</RadioText>
				</FormField>
				<FormField>
					<FormikStandaloneRadio
						checked={values.responseChoice === 'existingProject'}
						onChange={() => setFieldValue('responseChoice', 'existingProject')}
						name={'existingProject'}
						label={t('Create an EPC inquiry within an existing Project ')}
					/>
					<RadioText>
						<div className="text">
							Create an EPC inquiry and Client offer within an existing ecoligo Project. Select this option when the
							inquiry is for a Project which ecoligo is already working on.
						</div>
						{values.responseChoice === 'existingProject' && (
							<FormField>
								<FormikSelect
									isAsync
									id="project"
									name="project"
									label={t('Project')}
									value={values.project}
									error={errors.project}
									touched={touched.project}
									setFieldValue={setFieldValue}
									onBlur={handleBlur}
									loadOptions={loadProjectOptions}
									menuPosition="fixed"
									isRequired
								/>
							</FormField>
						)}
					</RadioText>
				</FormField>
				<FormField>
					<FormikStandaloneRadio
						checked={values.responseChoice === 'decline'}
						onChange={() => setFieldValue('responseChoice', 'decline')}
						name={'decline'}
						label={t('Decline the inquiry')}
					/>
					<RadioText>
						<div className="text">
							The EPC Partner will be informed their inquiry was declined. The inquiry is still visible to both the
							EPC and ecoligo.
						</div>
					</RadioText>
				</FormField>
			</ContentWrapper>
			<SeparatingLine />
			<MessageWrapper>
				<FormField>
					<FormikTextArea
						id="message"
						name="message"
						label={t('Message to EPC Partner')}
						value={values.message}
						error={errors.message}
						touched={touched.message}
						onChange={handleChange}
						onBlur={handleBlur}
						tooltip={{
							tooltip:
								'Message is sent in the notification email to the EPC Partner and is shown in pulse when viewing the inquiry.',
						}}
						isDisabled={!values.responseChoice}
						isRequired={values.responseChoice === 'decline'}
					/>
				</FormField>
				<FormField>
					<StyledFormikStandaloneRadio
						checked={values.hideClientName}
						onChange={() => setFieldValue('hideClientName', !values.hideClientName)}
						name={'hideClientName'}
						label={t('Hide Client Name')}
					/>
				</FormField>
			</MessageWrapper>
			<OverlayButtons
				onCancel={onCancel}
				isLoading={isLoading}
				isDisabled={values.responseChoice === ''}
				label="Industries Details Respond Modal Form"
				submitButtonText={t('Respond')}
			/>
			<ClientOverlay
				isOpen={isCreateClientOverlayOpened}
				mode={crudModes.CREATE}
				onClose={() => {
					setIsCreateClientOverlayOpened(false);
				}}
				breadcrumbList={createClientBreadcrumbList}
				onFormSubmit={data => {
					handleClientFormSubmit(data);
					setIsCreateClientOverlayOpened(false);
				}}
				data={{
					name: inquiry?.clientName,
					industry: inquiry.industry,
					country: inquiry.country,
				}}
			/>

			{isEditClientOverlayOpened && (
				<ClientOverlay
					isOpen={isEditClientOverlayOpened}
					mode={crudModes.EDIT}
					onClose={() => setIsEditClientOverlayOpened(false)}
					breadcrumbList={editClientBreadcrumbList}
					clientId={values.client.value}
					onFormSuPbmit={data => {
						handleClientFormSubmit(data);
						setIsEditClientOverlayOpened(false);
					}}
				/>
			)}
		</Form>
	);
};

RespondForm.defaultProps = {
	inquiry: null,
};

RespondForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
	inquiry: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		clientName: PropTypes.string.isRequired,
		industry: PropTypes.shape({}).isRequired,
		country: PropTypes.shape({}).isRequired,
	}),
};

export default RespondForm;
