import DueDiligenceTile from 'Client/components/ClientDetails/Tabs/FinancialTab/Tiles/DueDiligenceTile/DueDiligenceTile';
import ClientAddressTile from 'Client/components/ClientDetails/Tabs/GeneralTab/Tiles/AddressTile/AddressTile';
import ClientDetailsTile from 'Client/components/ClientDetails/Tabs/GeneralTab/Tiles/ClientDetailsTile/ClientDetailsTile';
import ClientTile from 'Projects/components/ProjectDetails/Tabs/ClientTab/Tiles/ClientTile/ClientTile';
import EpcPartnerTile from 'Projects/components/ProjectDetails/Tabs/EPCTab/Tiles/EpcPartnerTile/EpcPartnerTile';
import RfepTile from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/Rfep/RfepTile';
import EngagementTile from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/EngagementTile/EngagementTile';
import LocationTile from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/LocationTile/LocationTile';
import ProjectDetailsTile from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/ProjectDetailsTile/ProjectDetailsTile';
import ProjectEntitiesTile from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/ProjectEntitiesTile/ProjectEntitiesTile';
import ResponsiblePeopleTile from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/ResponsiblePeopleTile/ResponsiblePeopleTile';
import StatusTile from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/StatusTile/StatusTile';
import EPCAddressTile from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/AddressTile/AddressTile';
import EpcQualificationsTile from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/QualificationsTile/QualificationsTile';
import AccountManagersTile from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/AccountManagersTile/AccountManagersTile';
import InsuranceTile from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/InsuranceTile/InsuranceTile';
import CommissioningTile from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/CommissioningTile/CommissioningTile';
import MeteocontrolTile from 'Projects/components/ProjectDetails/Tabs/MeteocontrolTab/Tiles/MeteocontrolTile/MeteocontrolTile';
import EpcPartnerDetailsTile from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/EpcPartnerDetailsTile/EpcPartnerDetailsTile';
import { PM_WORKFLOW_TILES_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';

export const PM_WORKFLOW_TILES_COMPONENTS = {
	[PM_WORKFLOW_TILES_KEYS.PROJECT_CLIENT]: ClientTile,
	[PM_WORKFLOW_TILES_KEYS.CLIENT_DETAILS]: ClientDetailsTile,
	[PM_WORKFLOW_TILES_KEYS.CLIENT_ADDRESS]: ClientAddressTile,
	[PM_WORKFLOW_TILES_KEYS.CLIENT_DUE_DILIGENCE]: DueDiligenceTile,
	[PM_WORKFLOW_TILES_KEYS.PROJECT_DETAILS]: ProjectDetailsTile,
	[PM_WORKFLOW_TILES_KEYS.PROJECT_ENGAGEMENT]: EngagementTile,
	[PM_WORKFLOW_TILES_KEYS.PROJECT_RESPONSIBLE_PEOPLE]: ResponsiblePeopleTile,
	[PM_WORKFLOW_TILES_KEYS.PROJECT_STATUS]: StatusTile,
	[PM_WORKFLOW_TILES_KEYS.PROJECT_LOCATION]: LocationTile,
	[PM_WORKFLOW_TILES_KEYS.PROJECT_ENTITIES]: ProjectEntitiesTile,
	[PM_WORKFLOW_TILES_KEYS.PROJECT_RFEP]: RfepTile,
	[PM_WORKFLOW_TILES_KEYS.PROJECT_EPC_PARTNER]: EpcPartnerTile,
	[PM_WORKFLOW_TILES_KEYS.EPC_PARTNER_DETAILS]: EpcPartnerDetailsTile,
	[PM_WORKFLOW_TILES_KEYS.EPC_PARTNER_ADDRESS]: EPCAddressTile,
	[PM_WORKFLOW_TILES_KEYS.EPC_PARTNER_QUALIFICATIONS]: EpcQualificationsTile,
	[PM_WORKFLOW_TILES_KEYS.EPC_PARTNER_ACCOUNT_MANAGERS]: AccountManagersTile,
	[PM_WORKFLOW_TILES_KEYS.PROJECT_INSURANCE]: InsuranceTile,
	[PM_WORKFLOW_TILES_KEYS.PROJECT_COMMISSIONING]: CommissioningTile,
	[PM_WORKFLOW_TILES_KEYS.PROJECT_METEOCONTROL]: MeteocontrolTile,
};
