import PropTypes from 'prop-types';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import { useTranslation } from 'react-i18next';
import deleteShareholder from 'Shareholders/api/deleteShareholder';
import useAbortController from 'Common/hooks/useAbortController';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useState } from 'react';
import UnsuccessfulOperationModal from 'Common/components/modals/UnsuccessfulOperationModal';
import axios from 'axios';

const ShareholderDeleteModal = ({ shareholderForDeletion, isOpen, onCancel, onFormSubmit }) => {
	const { t } = useTranslation();
	const [deletionError, setDeletionError] = useState();

	const abortController = useAbortController();

	const handleDeleteShareholder = async id => {
		try {
			await deleteShareholder(abortController.signal, id);

			notify(t('Shareholder deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});

			onFormSubmit();
			onCancel();
		} catch (error) {
			if (!axios.isCancel(error)) {
				setDeletionError(error?.response?.data);
				onCancel();
			}
		}
	};
	return (
		<>
			<ConfirmModal
				isOpen={isOpen}
				onCancel={onCancel}
				onConfirm={() => handleDeleteShareholder(shareholderForDeletion?.id)}
				label="delete-shareholder-confirm"
				heading={t('Delete Shareholder')}
				text={t(`Are you sure you want to delete Shareholder ${shareholderForDeletion?.name} ?`)}
			/>
			<UnsuccessfulOperationModal
				label="cannot-delete-shareholder"
				isOpen={Boolean(deletionError)}
				onCancel={() => setDeletionError(null)}
				heading={t('Cannot delete Shareholder')}
				text={deletionError?.violations?.[0]?.message}
			/>
		</>
	);
};

ShareholderDeleteModal.defaultProps = {
	shareholderForDeletion: null,
};

ShareholderDeleteModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	shareholderForDeletion: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		name: PropTypes.string,
	}),
};

export default ShareholderDeleteModal;
