import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Prompt from 'Common/components/Prompt';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import { PropTypes } from 'prop-types';

import notify from 'Common/utils/notify';
import crudModes from 'Common/constants/crudModes';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import editMarketSegment from 'MarketSegment/api/editMarketSegment';
import getMarketSegments from 'MarketSegment/api/getMarketSegments';
import createMarketSegments from 'MarketSegment/api/createMarketSegments';

import useMarketSegmentFormValidationSchema from 'Preferences/components/Tabs/GeneralTab/MarketSegments/MarketSegmentsForm/hooks/useMarketSegmentFormValidationSchema';
import useMarketSegmentFormInitialValues from 'Preferences/components/Tabs/GeneralTab/MarketSegments/MarketSegmentsForm/hooks/useMarketSegmentFormInitialValues';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { ModalFormButtons } from 'Common/components/form/ModalForm';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const FieldsWrapper = styled.div`
	width: 100%;
`;

const MarketSegmentsForm = ({ data, mode, onSubmit, onCancel, onDirty }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();

	const initialValues = useMarketSegmentFormInitialValues(data);
	const validationSchema = useMarketSegmentFormValidationSchema();

	const { errors, touched, values, handleSubmit, dirty, handleChange, handleBlur, setFieldError, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: async values => {
				try {
					setIsLoading(true);
					if (mode === crudModes.CREATE) {
						await createMarketSegments(abortController.signal, values);

						notify(t('Market segment created successfully'), {
							type: toast.TYPE.SUCCESS,
						});
					} else if (mode === crudModes.EDIT) {
						await editMarketSegment(abortController.signal, {
							...data,
							...values,
						});

						notify(t('Market segment saved successfully'), {
							type: toast.TYPE.SUCCESS,
						});
					}

					const response = await getMarketSegments(abortController.signal);
					onSubmit(response.data.content);

					// Re-set all fields and mark them as not touched
					resetForm({ values });
					setIsLoading(false);
				} catch (error) {
					showAxiosResponseError({
						error,
						setFormikFieldError: setFieldError,
					});
					if (!axios.isCancel(error)) {
						setIsLoading(false);
					}
				}
			},
		});

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<>
			<Prompt message="" when={dirty} />
			<Form onSubmit={handleSubmit}>
				<FieldsWrapper>
					<FormField>
						<FormikInput
							id="name"
							name="name"
							label={t('Name')}
							value={values.name}
							error={errors.name}
							touched={touched.name}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isInEditMode={mode === crudModes.CREATE}
						/>
					</FormField>
				</FieldsWrapper>
				<ModalFormButtons
					onCancel={onCancel}
					isLoading={isLoading}
					label="Preferences Market Segments Form"
					submitButtonText={mode === crudModes.CREATE ? t('Create') : t('Save')}
				/>
			</Form>
		</>
	);
};

MarketSegmentsForm.defaultProps = {
	data: null,
};

MarketSegmentsForm.propTypes = {
	data: PropTypes.shape({}),
	mode: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
};

export default MarketSegmentsForm;
