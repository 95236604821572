import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import { push } from 'redux-first-history';
import getClients from 'Client/api/getClients';
import 'rc-pagination/assets/index.css';
import Link from 'Common/components/Link';
import { DefaultTable } from 'Common/components/table/table';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useSortBy } from 'react-table';
import { useSortingQueryParameter } from 'Common/hooks/table/plugin-hooks/useSortingQueryParameter';
import { useScrollableTableBody } from 'Common/hooks/table/plugin-hooks/useScrollableTableBody';
import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import { useVirtualizedTableBody } from 'Common/hooks/table/plugin-hooks/useVirtualizedTableBody';
import { debounce, fill } from 'lodash';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import TableCellText from 'Common/components/table/TableCellText';
import showToastError from 'Common/utils/showToastError';
import PropTypes from 'prop-types';
import useUniqueAbortSignal from 'Common/hooks/useUniqueAbortSignal';
import prefillTableData from 'Common/utils/prefillTableData';

const Wrapper = styled.div`
	position: relative;
	pointer-events: ${({ hasOpenFilter }) => (hasOpenFilter ? 'none' : 'all')};
`;

const defaultSortBy = {
	id: 'name',
	desc: false,
};

const sortPropertiesMap = {
	country: 'country.name',
	marketSegment: 'marketSegment.name',
	cityRegion: 'address.cityRegion',
	primaryContact: 'firstName',
};

const modifyClientFilters = filters => {
	const projectType = filters?.projectType;

	if (!projectType) return filters;

	if (projectType === 'inOperationProjects') {
		return {
			...filters,
			inOperationProjects: true,
		};
	}

	if (projectType === 'signedProjects') {
		return {
			...filters,
			signedProjects: true,
		};
	}

	return filters;
};

const isSigned = true;
const ClientListTable = () => {
	const { t } = useTranslation();
	const hasOpenFilter = !!useSelector(state => state?.tables?.clients?.openedFilter);

	const [totalItemsCount, setTotalItemsCount] = useState(25);

	const sortByQueryParam = useQueryParameter('sortBy');
	const sortBy = sortByQueryParam ?? defaultSortBy;
	const filters = useQueryParameter('filters');

	const searchQuery = useQueryParameter('searchQuery');

	const dispatch = useDispatch();

	const [clients, setClients] = useState(() => fill(Array(25), {}));
	const [isLoading, setIsLoading] = useState(true);

	const getUniqueSignal = useUniqueAbortSignal();

	const hasLoadedFirstResultsRef = useRef(false);

	const getClientsBatch = useCallback(
		async (startIndex, stopIndex, filters, sortBy, searchQuery, hasItemsChanges = false) => {
			if (hasItemsChanges) {
				setIsLoading(true);
			}

			const sortById = sortPropertiesMap[sortBy.id] ?? sortBy.id;

			const customFilters = modifyClientFilters({ ...filters });

			try {
				let response = await getClients(
					getUniqueSignal('get-signed-clients'),
					{
						offset: startIndex,
						limit: stopIndex - startIndex + 1,
						sortBy: sortById,
						notSignedProjects: !isSigned,
						sortDirection: sortBy.desc ? 'DESC' : 'ASC',
						signedProjects: isSigned,
					},
					customFilters,
					searchQuery,
					true,
				);

				if (hasItemsChanges || !hasLoadedFirstResultsRef.current) {
					const { itemsCount, itemsFilled, error } = prefillTableData(response.data, startIndex, stopIndex);
					if (error) {
						showToastError(error);
					} else {
						setClients(itemsFilled);
						setTotalItemsCount(itemsCount);
					}
				} else {
					setClients(prevClients =>
						prevClients.map((prevClient, index) => {
							if (startIndex <= index && index <= stopIndex) {
								const indexInResultSet = index - startIndex;

								return response.data.content[indexInResultSet];
							}

							return prevClient;
						}),
					);
				}

				hasLoadedFirstResultsRef.current = true;

				if (hasItemsChanges) {
					setIsLoading(false);
				}
			} catch (error) {
				showToastError(error, t("Can't fetch clients"));
			}
		},
		[getUniqueSignal, t],
	);

	useEffect(() => {
		const customFilters = modifyClientFilters({ ...filters });
		getClientsBatch(
			0,
			25,
			customFilters,
			{
				id: sortBy.id,
				desc: sortBy.desc,
			},
			searchQuery,
			true,
		);
	}, [getClientsBatch, searchQuery, filters, sortBy.desc, sortBy.id]);

	// eslint-disable-next-line
	const handleLoadMoreRows = useCallback(
		debounce(
			(startIndex, stopIndex) => {
				getClientsBatch(
					startIndex,
					stopIndex,
					filters,
					{
						id: sortBy.id,
						desc: sortBy.desc,
					},
					searchQuery,
				);
			},
			350,
			{
				leading: false,
				trailing: true,
			},
		),
		[getClientsBatch, searchQuery, filters, sortBy.desc, sortBy.id],
	);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Name'),
				accessor: 'name',
				Footer: totalItemsCount,
				Cell: (() => {
					const Cell = ({ row: { original: client } }) => (
						<Link color="#00b4d5" to={`/clients/details/${client?.id}`} fontSize="14px">
							{client?.name}
						</Link>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
								name: PropTypes.string,
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Industry'),
				accessor: 'industry',
				Cell: (() => {
					const Cell = ({ row: { original: client } }) => (
						<div title={client.industry?.name}>
							<TableCellText>{client.industry?.name}</TableCellText>
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ industry: PropTypes.shape({ name: PropTypes.string }) }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Country'),
				accessor: 'country',
				Cell: (() => {
					const Cell = ({ row: { original: client } }) => (
						<div title={client.country?.isoCode}>
							<TableCellText>{client.country?.name}</TableCellText>
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								country: PropTypes.shape({ isoCode: PropTypes.string, name: PropTypes.string }),
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('City / Region'),
				accessor: 'cityRegion',
				Cell: (() => {
					const Cell = ({ row: { original: client } }) => (
						<div title={client.address?.cityRegion}>
							<TableCellText>{client.address?.cityRegion}</TableCellText>
						</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ address: PropTypes.shape({ cityRegion: PropTypes.string }) }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Financial DD passed date'),
				accessor: 'financialDDOPassedDate',
				maxWidth: 170,
				cellStyle: {
					whiteSpace: 'pre-wrap',
				},
				Cell: ({ row: { original: client } }) => {
					return (
						<TableCellText>{client.financialDDOPassedDate ? client.financialDDOPassedDate : '-'}</TableCellText>
					);
				},
			},
			{
				Header: t('FDD Passed'),
				accessor: 'fddPassed',
				maxWidth: 90,
				Cell: (() => {
					const Cell = ({ row: { original: client } }) => (
						<div title={client?.fddPassed}>{client?.fddPassed ? 'Yes' : 'No'}</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ fddPassed: PropTypes.bool }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Total Projects'),
				accessor: 'totalProjects',
				isNumber: true,
			},
			{
				Header: t('Total system size (kWp)'),
				accessor: 'totalSystemSize',
				isNumber: true,
				width: 200,
			},
		],
		[t, totalItemsCount],
	);

	const data = useMemo(() => clients, [clients]);

	const itemsHash = useMemo(() => JSON.stringify({ filters, sortBy }), [filters, sortBy]);

	const handleRowClick = row => {
		if (!window.getSelection().toString()) {
			dispatch(push(`/clients/details/${row.original.id}`));
		}
	};

	// Fallback to default row id getter
	const getRowId = useCallback(
		(row, relativeIndex, parent) => (parent ? [parent.id, relativeIndex].join('.') : relativeIndex),
		[],
	);

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		getTableFooterProps,
		getVirtualizedTableBodyProps,
		headerGroups,
		footerGroups,
		rows,
		prepareRow,
	} = useStandardTable(
		{
			data,
			columns,
			defaultSortBy,
			searchTerm: searchQuery,
			onRowClick: handleRowClick,
			virtualization: {
				totalRowsCount: totalItemsCount,
				rowsHash: itemsHash,
				loadMoreRows: handleLoadMoreRows,
			},
			getRowId,
		},
		useClickableTableRow,
		useCustomCellStyles,
		useSortBy,
		useSortingQueryParameter,
		useScrollableTableBody,
		useVirtualizedTableBody,
	);

	return (
		<Wrapper hasOpenFilter={hasOpenFilter}>
			<DefaultTable
				getTableProps={getTableProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				getTableFooterProps={getTableFooterProps}
				getVirtualizedTableBodyProps={getVirtualizedTableBodyProps}
				rows={rows}
				headerGroups={headerGroups}
				footerGroups={footerGroups}
				prepareRow={prepareRow}
				isLoading={isLoading || rows.length !== totalItemsCount}
				showNoResultsFound
			/>
		</Wrapper>
	);
};

ClientListTable.defaultProps = {
	row: {},
};

ClientListTable.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.func,
	}),
};

export default ClientListTable;
