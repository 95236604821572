import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import UNITS from 'Common/constants/units';
import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import editClient from 'Client/api/editClient';

import useTaxesFormInitialValues from 'Client/components/ClientDetails/Tabs/FinancialTab/Tiles/TaxesTile/hooks/useTaxesFormInitialValues';
import useTaxesFormValidationSchema from 'Client/components/ClientDetails/Tabs/FinancialTab/Tiles/TaxesTile/hooks/useTaxesFormValidationSchema';
import EditableTile from 'Common/components/Tile/EditableTile';
import FormikInput from 'Common/components/form/FormikInput';
import FormField from 'Common/components/form/FormField';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';

const FieldContainer = styled.div`
	padding: 10px 0;
`;

const TaxesTile = ({ client, onEnterEditMode, onExitEditMode, onSave, onDirtyForm, highlightLabels }) => {
	const { t } = useTranslation();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.SALES,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.PM_TECHNICAL,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useTaxesFormInitialValues(client);
	const validationSchema = useTaxesFormValidationSchema();

	const { errors, touched, values, handleSubmit, dirty, handleChange, handleBlur, resetForm } = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			setIsSavingChanges(true);
			let response;

			try {
				response = await editClient(abortController.signal, {
					...client,
					...values,
				});

				if (onDirtyForm) {
					onDirtyForm(false);
				}

				onSave(response.data);
				handleCancel();
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Taxes')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<FieldContainer>
				<FormikCheckbox
					label={t('Subject to VAT WHT')}
					id="subjectToVatWht"
					name="subjectToVatWht"
					checked={values.subjectToVatWht}
					error={errors.subjectToVatWht}
					onChange={handleChange}
					onBlur={handleBlur}
					touched={touched.subjectToVatWht}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.CLIENT_DETAILS.SUBJECT_TO_VAT,
					)}
				/>
			</FieldContainer>
			<FieldContainer>
				<FormikCheckbox
					label={t('Tax Free')}
					id="taxFree"
					name="taxFree"
					checked={values.taxFree}
					error={errors.taxFree}
					onChange={handleChange}
					onBlur={handleBlur}
					touched={touched.taxFree}
					isTile
					isInEditMode={isInEditMode}
				/>
			</FieldContainer>
			{client?.country?.isoCode === 'KEN' && (
				<FormField>
					<FormikInput
						id="wht"
						name="wht"
						label={t('WHT')}
						value={values.wht}
						error={errors.wht}
						touched={touched.wht}
						onBlur={handleBlur}
						unit={UNITS.PERCENT}
						onChange={handleChange}
						isInEditMode={isInEditMode}
						isTile
					/>
				</FormField>
			)}
		</EditableTile>
	);
};

TaxesTile.defaultProps = {
	client: {},
	onEnterEditMode: () => null,
	onExitEditMode: () => null,
	onDirtyForm: () => null,
	onSave: () => null,
	highlightLabels: [],
};

TaxesTile.propTypes = {
	client: PropTypes.shape({
		country: PropTypes.shape({
			isoCode: PropTypes.string,
		}),
	}),
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	onSave: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default TaxesTile;
