import TextButton from 'Common/components/buttons/TextButton';
import { toast } from 'react-toastify';
import notify from './notify';
import showToastError from './showToastError';

/**
 * Displays error toast
 * @param {*} error - error catched
 * @param {*} errorMessage - custom error message (optional)
 */
const showToastPending = (promise, onCancel, pendingMessage, successMessage, errorMessage) => {
	const id = toast.loading(() => (
		<>
			{pendingMessage}
			<TextButton label="Cancel" onClick={onCancel} text="Cancel" />
		</>
	));
	(async () => {
		try {
			await promise;

			toast.dismiss(id);
			notify(successMessage, {
				type: toast.TYPE.SUCCESS,
			});
		} catch (err) {
			toast.dismiss(id);
			showToastError(err, errorMessage);
		}
	})();
};

export default showToastPending;
