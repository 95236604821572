import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

import CrowdLineTile from 'Impact/tiles/ImpactVolumeTile/ImpactVolumeTile';
import InfoTile from 'Impact/tiles/ImpactInfoTile';
import ProjectsTile from 'Impact/tiles/ImpactProjectsTile/ImpactProjectsTile';
import ImpactEmployeesTile from 'Impact/tiles/ImpactEmployeesTile';
import DemographicTile from 'Impact/tiles/ImpactDemographicTile/ImpactDemographicTile';
import ImpactOffersTile from 'Impact/tiles/ImpactOffersTile';

import Map from 'Common/components/Map/Map';

import { sizes } from 'Impact/constants/options';

import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import { useTranslation } from 'react-i18next';
import { statuses } from 'Projects/constants/statuses';

const StyledGrid = styled(Grid)`
	height: ${props => props.height || '100%;'} !important;
`;

const ImpactBigScreens = ({ statistics, crowdPieData, pieData, totals, filterValues }) => {
	const { t } = useTranslation();

	const firstArr = filterValues.find(item => {
		return item?.title.toLowerCase() === 'upload work completion certificate ';
	});
	const sencondArr = filterValues.find(item => {
		return item?.title.toLowerCase() === 'disburse milestone upon delivery to epc';
	});
	const firstArrOpen = { ...firstArr, status: 'OPEN' };
	const combinedArr = [firstArrOpen, sencondArr];

	return (
		<>
			<StyledGrid item xs={12}>
				<StyledGrid container spacing={2}>
					<StyledGrid item xs={6}>
						<InfoTile
							title={t('mwp of projects')}
							gradient={colors.gradients.radialDark}
							data={[
								{
									value: totals?.totalMWpSigned,
									unit: 'MWp',
									text: t('signed'),
									icon: 'signed',
									filterRedirect: {
										condition: [statuses.IN_PROGRESS, statuses.ON_HOLD, statuses.OPERATION, statuses.MATURE],
										combinedStepStatusTitles: [
											filterValues.find(item => item.title.toLowerCase() === 'sign client contract'),
										],
									},
								},
								{
									value: totals?.totalMWpInConstruction,
									unit: 'MWp',
									text: t('in Construction'),
									icon: 'building',
									filterRedirect: {
										condition: [statuses.IN_PROGRESS],
										combinedStepStatusTitles: [...combinedArr],
									},
								},
								{
									value: totals?.totalMWpInOperation,
									unit: 'MWp',
									text: t('in operation'),
									icon: 'inOperation',
									filterRedirect: {
										condition: [statuses.OPERATION],
									},
								},
								{
									value: totals?.totalMWpMatured,
									unit: 'MWp',
									text: t('matured'),
									icon: 'leaf',
									filterRedirect: {
										condition: [statuses.MATURE],
									},
								},
							]}
						/>
					</StyledGrid>
					<StyledGrid item xs={6}>
						<InfoTile
							title={t('# of projects')}
							gradient={colors.gradients.radialLight}
							data={[
								{
									value: totals.totalProjectsSigned,
									text: t('signed'),
									icon: 'signed',
									filterRedirect: {
										condition: [statuses.IN_PROGRESS, statuses.ON_HOLD, statuses.OPERATION, statuses.MATURE],
										combinedStepStatusTitles: [
											filterValues.find(item => {
												return item.title.toLowerCase() === 'sign client contract';
											}),
										],
									},
								},
								{
									value: totals.numberOfProjectsInConstruction,
									text: t('in Construction'),
									icon: 'building',
									filterRedirect: {
										condition: [statuses.IN_PROGRESS],
										combinedStepStatusTitles: [...combinedArr],
									},
								},
								{
									value: totals.totalProjectsInOperation,
									text: t('in operation'),
									icon: 'inOperation',
									filterRedirect: {
										condition: [statuses.OPERATION],
									},
								},
								{
									value: totals.totalProjectsMatured,
									text: t('matured'),
									icon: 'leaf',
									filterRedirect: {
										condition: [statuses.MATURE],
									},
								},
							]}
						/>
					</StyledGrid>
				</StyledGrid>
			</StyledGrid>

			<StyledGrid item xs={12}>
				<StyledGrid container spacing={2}>
					<StyledGrid item xs={8}>
						<StyledGrid container spacing={2}>
							<StyledGrid item xs={12} height={'550px;'}>
								<Map />
							</StyledGrid>
							<StyledGrid item xs={12} height={sizes.tiles.large}>
								<ProjectsTile data={statistics?.content} />
							</StyledGrid>
							<StyledGrid item xs={6} height={'345px;'}>
								<CrowdLineTile data={statistics?.content} />
							</StyledGrid>
							<StyledGrid item xs={6} height={'345px;'}>
								<ImpactEmployeesTile data={statistics?.content} />
							</StyledGrid>
							<StyledGrid item xs={6}>
								<InfoTile
									title={t('# of employees impacted')}
									gradient={colors.gradients.radialDark}
									data={[
										{
											value: totals.totalEmployeesImpacted?.toFixed(0),
											text: t('impacted'),
											icon: 'employees',
										},
										{
											value: totals.totalFemaleEmployeesImpacted?.toFixed(0),
											text: t('impacted'),
											icon: 'female',
										},
									]}
								/>
							</StyledGrid>
							<StyledGrid item xs={6}>
								<InfoTile
									title={t('# of clients')}
									gradient={colors.gradients.radialLight}
									data={[
										{
											value: totals.totalClientsImpacted,
											text: t('signed'),
											icon: 'signed',
										},
										{
											value: totals.totalClientsImpactedInOperation,
											text: t('in operation'),
											icon: 'leaf',
										},
									]}
								/>
							</StyledGrid>
							<StyledGrid item xs={12}>
								<ImpactOffersTile data={totals} />
							</StyledGrid>
						</StyledGrid>
					</StyledGrid>
					<StyledGrid item xs={4}>
						<StyledGrid container spacing={2}>
							<StyledGrid item xs={12}>
								<InfoTile
									title={t('volume invested')}
									gradient={colors.gradients.radialDark}
									data={[
										{
											value: totals.totalEurInvested,
											text: t('invested'),
											unit: 'EUR',
											icon: 'invested',
										},
									]}
								/>
							</StyledGrid>
							<StyledGrid item xs={12}>
								<DemographicTile
									data={totals.investorAgeGroupsDto}
									pieData={pieData}
									crowdPieData={crowdPieData}
									lineData={statistics?.content}
								/>
							</StyledGrid>
						</StyledGrid>
					</StyledGrid>
				</StyledGrid>
			</StyledGrid>
		</>
	);
};

ImpactBigScreens.defaultProps = {
	statistics: {},
	crowdPieData: {},
	pieData: {},
	totals: {},
	filterValues: [],
};

ImpactBigScreens.propTypes = {
	statistics: PropTypes.shape({
		content: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	crowdPieData: PropTypes.shape({}),
	pieData: PropTypes.shape({}),
	filterValues: PropTypes.arrayOf(PropTypes.shape({})),
	totals: PropTypes.shape({
		totalMWpSigned: PropTypes.number,
		totalMWpInConstruction: PropTypes.number,
		totalMWpInOperation: PropTypes.number,
		totalMWpMatured: PropTypes.number,
		totalProjectsSigned: PropTypes.number,
		numberOfProjectsInConstruction: PropTypes.number,
		totalProjectsInOperation: PropTypes.number,
		totalProjectsMatured: PropTypes.number,
		totalEmployeesImpacted: PropTypes.number,
		totalFemaleEmployeesImpacted: PropTypes.number,
		totalClientsImpacted: PropTypes.number,
		totalClientsImpactedInOperation: PropTypes.number,
		totalMWpOffers: PropTypes.number,
		totalMWpRestrictedOffers: PropTypes.number,
		percentageMWpOfRestrictedIndustries: PropTypes.number,
		allowedPercentageOfRestrictedIndustries: PropTypes.number,
		totalEurInvested: PropTypes.number,
		investorAgeGroupsDto: PropTypes.shape({}),
	}),
};

export default ImpactBigScreens;
