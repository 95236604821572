import api from 'Application/api/api';

const getAmountFilterRanges = async signal => {
	const response = await api.get('/invoices/filters-range', {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return response;
};

export default getAmountFilterRanges;
