import { store } from 'Application/store';

/**
 *
 * @param {*} authorizedRoles
 * @param {*} noOtherRoles No additional roles should be authorized
 * @returns
 */
const isAuthorized = (authorizedRoles, noOtherRoles = false) => {
	const userRoles = store.getState().user.roles || [];

	if (!userRoles) {
		return false;
	}

	const intersectingRoles = authorizedRoles.filter(role => userRoles.includes(role));

	return noOtherRoles
		? intersectingRoles.length === authorizedRoles.length // Check if any additional roles are authorized
		: intersectingRoles.length > 0;
};

export default isAuthorized;
