const pluginName = 'useClickableTableRow';

export const useClickableTableRow = hooks => {
	hooks.getRowProps.push(getRowProps);
};

useClickableTableRow.pluginName = pluginName;

const getRowProps = (props, { instance, row }) => {
	const isClickable = row.original.isClickable ?? true;

	if (!isClickable) {
		return [props];
	}

	return [
		props,
		{
			onClick: () => instance.onRowClick(row),
			style: {
				cursor: 'pointer',
			},
		},
	];
};
