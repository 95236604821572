const pluginName = 'useCustomCellStyles';

export const useCustomCellStyles = hooks => {
	hooks.getHeaderProps.push(getHeaderProps);
	hooks.getHeaderCellContentProps.push(getHeaderCellContentProps);

	hooks.getCellProps.push(getCellProps);
	hooks.getCellContentProps.push(getCellContentProps);
	hooks.getFooterProps.push(getFooterProps);
	hooks.getFooterCellContentProps.push(getFooterCellContentProps);
};

useCustomCellStyles.pluginName = pluginName;

const getHeaderProps = (props, { column }) => [
	props,
	{
		style: {
			...column.customCellStyles,
			...column.customHeaderCellStyles,
		},
	},
];

const getHeaderCellContentProps = (props, { column }) => [
	props,
	{
		style: {
			...column.customCellContentStyles,
			...column.customHeaderCellContentStyles,
		},
	},
];

const getCellProps = (props, { cell, instance }) => [
	props,
	{
		style: {
			...(instance.getRowBasedCustomCellStyles ? instance.getRowBasedCustomCellStyles(cell.row) : {}),
			...cell.column.customCellStyles,
			...cell.column.customBodyCellStyles,
		},
	},
];
const getCellContentProps = (props, { cell }) => [
	props,
	{
		style: {
			...cell.column.customCellContentStyles,
			...cell.column.customBodyCellContentStyles,
		},
	},
];

const getFooterProps = (props, { column }) => [
	props,
	{
		style: {
			...column.customFooterCellStyles,
		},
	},
];

const getFooterCellContentProps = (props, { column }) => [
	props,
	{
		style: {
			...column.customFooterCellContentStyles,
		},
	},
];
