import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';
import yupUrl from 'Common/utils/yupUrl';

const useClientFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				name: yup.string().required(t('Required')),
				website: yupUrl(),
				email: yup.string().email(),
				employees: yup
					.number()
					.typeError(t('Employees must be a number'))
					.positive(t('Employees must be positive'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.integer(t('Employees must be a whole number'))
					.nullable(),
				industry: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.nullable()
					.required(t('Required')),
			}),
		[t],
	);
};

export default useClientFormValidationSchema;
