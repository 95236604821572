import api from 'Application/api/api';

const getClientOfferDownloadLink = async (signal, fileName, versionId) => {
	const response = await api.get(`/client-offers/download-link?fileName=${fileName}&versionId=${versionId}`, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
	};
};

export default getClientOfferDownloadLink;
