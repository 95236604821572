import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import getDriveFaults from 'Integrations/api/getDriveFaults';
import Link from 'Common/components/Link';
import { DefaultTable } from 'Common/components/table/table';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useSortBy } from 'react-table';
import { useSortingQueryParameter } from 'Common/hooks/table/plugin-hooks/useSortingQueryParameter';
import { useScrollableTableBody } from 'Common/hooks/table/plugin-hooks/useScrollableTableBody';
import colors from 'Application/theme/colors';
import StatusPill from 'Common/components/StatusPill';
import formatDate from 'Common/utils/formatDate';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const Wrapper = styled.div`
	position: relative;
`;

const defaultSortBy = {
	id: 'createdDate',
	desc: false,
};

const SharepointFoldersListTable = () => {
	const { t } = useTranslation();

	const sortByQueryParam = useQueryParameter('sortBy');
	const sortBy = sortByQueryParam ?? defaultSortBy;

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Status'),
				accessor: 'status',
				width: 200,
				customBodyCellStyles: {
					paddingLeft: '4px',
				},
			},
			{
				Header: t('Date'),
				accessor: 'createdDate',
				width: 200,
			},
			{
				Header: t('Project'),
				accessor: 'projectExternalId',
				width: 140,
			},
			{
				Header: t('Name'),
				accessor: 'name',
			},

			{
				Header: t('Result'),
				accessor: 'result',
			},
		],
		[t],
	);

	const [driveFaults, setDriveFaults] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const abortController = useAbortController();

	// Update the EPC Partners data in the state when the sorting / filter / search query changes
	useEffect(() => {
		(async () => {
			setIsLoading(true);

			const sortById = sortBy.id;

			try {
				const response = await getDriveFaults(abortController.signal, {
					sortBy: sortById,
					sortDirection: sortBy.desc ? 'DESC' : 'ASC',
				});
				setDriveFaults(response.data.content);
				setIsLoading(false);
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setDriveFaults([]);
					setIsLoading(false);
				}
			}
		})();
	}, [sortBy, abortController.signal]);

	// Re-structure the EPC Partners as table data
	const data = useMemo(
		() =>
			driveFaults.map(integration => ({
				...integration,
				projectExternalId:
					integration.projectId && integration.projectExternalId ? (
						<Link color="#00b4d5" to={`/projects/details/${integration.projectId}`} fontSize="14px">
							{integration.projectExternalId}
						</Link>
					) : (
						integration.projectExternalId
					),
				resource: integration.resource || '-',
				name: integration.name || '-',
				status: (
					<StatusPill color={integration.status === 'FAILED' ? colors.error.main : colors.warning.main}>
						{integration.status}
					</StatusPill>
				),
				result: integration.result || '-',
				createdDate: formatDate(integration.createdDate, 'd/LL/yy hh:mm'),
			})),
		[driveFaults],
	);

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
				defaultSortBy,
			},
			useCustomCellStyles,
			useSortBy,
			useSortingQueryParameter,
			useScrollableTableBody,
		);

	return (
		<Wrapper>
			<DefaultTable
				getTableProps={getTableProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				rows={rows}
				headerGroups={headerGroups}
				prepareRow={prepareRow}
				isLoading={isLoading}
				showNoResultsFound
			/>
		</Wrapper>
	);
};

export default SharepointFoldersListTable;
