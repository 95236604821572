import { isEmpty, omit } from 'lodash';

const convertLocalFilterDataToQuery = (localFilterData, newValues, allowedProperties) =>
	Object.entries(newValues)
		.filter(([key]) => allowedProperties.includes(key))
		.reduce(
			(acc, [name, value]) => {
				if (!value || value?.length === 0 || (typeof value === 'object' && isEmpty(value))) {
					return {
						...omit(acc, [name]),
					};
				} else {
					return {
						...acc,
						[name]: value,
					};
				}
			},
			{ ...localFilterData },
		);

export default convertLocalFilterDataToQuery;
