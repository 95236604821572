import { useMemo } from 'react';
import { pick } from 'lodash';

const fallbackValues = {
	password: '',
	passwordConfirm: '',
};

const useConfirmAccountFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['password', 'passwordConfirm']),
		}),
		[data],
	);

export default useConfirmAccountFormInitialValues;
