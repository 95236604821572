import { createSlice } from '@reduxjs/toolkit';

const initialTipData = {
	modals: [],
};

export const tipSlice = createSlice({
	name: 'tip',
	initialState: { ...initialTipData },
	reducers: {
		stopShowingTip: (state, { payload: { tipName } }) => {
			state.modals = [...state.modals, tipName];
		},
	},
});

export const { stopShowingTip } = tipSlice.actions;

export default tipSlice.reducer;
