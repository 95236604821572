const FILE_TYPES = {
	IMAGE: 'IMAGE',
	DOCX: 'DOCX',
	PDF: 'PDF',
	XLSX: 'XLSX',
	CSV: 'CSV',
	ODT: 'ODT',
};

const FILE_TYPES_LABELS = {
	[FILE_TYPES.IMAGE]: 'Image (.jpg, .png, .gif)',
	[FILE_TYPES.DOCX]: 'Word Document (.docx)',
	[FILE_TYPES.PDF]: 'PDF Document (.pdf)',
	[FILE_TYPES.XLSX]: 'Excel Document (.xlsx)',
	[FILE_TYPES.CSV]: 'CSV File (.csv)',
	[FILE_TYPES.ODT]: 'Open Office Document (.odt)',
};

const FILE_TYPES_MIME_TYPES = {
	[FILE_TYPES.IMAGE]: ['image/jpeg', 'image/png', 'image/gif'],
	[FILE_TYPES.DOCX]: [
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/msword',
	],
	[FILE_TYPES.PDF]: ['application/pdf'],
	[FILE_TYPES.XLSX]: [
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/vnd.ms-excel',
	],
	[FILE_TYPES.CSV]: ['text/csv'],
	[FILE_TYPES.ODT]: ['application/vnd.oasis.opendocument.text'],
};

const FILE_TYPES_EXTENSIONS = {
	[FILE_TYPES.IMAGE]: ['jpg', 'jpeg', 'png', 'gif'],
	[FILE_TYPES.DOCX]: ['docx', 'doc'],
	[FILE_TYPES.PDF]: ['pdf'],
	[FILE_TYPES.XLSX]: ['xlsx', 'xls'],
	[FILE_TYPES.CSV]: ['csv'],
	[FILE_TYPES.ODT]: ['odt'],
};

const FILE_ICONS = {
	[FILE_TYPES.IMAGE]: 'image',
	[FILE_TYPES.DOCX]: 'word',
	[FILE_TYPES.PDF]: 'pdf',
	[FILE_TYPES.XLSX]: 'excel',
	[FILE_TYPES.CSV]: 'csv',
	[FILE_TYPES.ODT]: 'word',
};

export { FILE_TYPES, FILE_TYPES_LABELS, FILE_TYPES_MIME_TYPES, FILE_TYPES_EXTENSIONS, FILE_ICONS };
