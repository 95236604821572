import PropTypes from 'prop-types';
import { useMemo } from 'react';

import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';

const Wrapper = styled.div`
	padding-top: ${sizes.spacing(0.5)};
	width: 95%;
	transition: width 0.3s, height 0.3s;
	min-width: 120px;
`;

const LegendContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	padding-top: 10px;
	justify-content: center;
	transform: translateX(-16px);

	&:first-child {
		padding-top: 0px;
	}
`;

const LegendItemContainer = styled.div`
	padding: 0px 4px;
	display: inline;
	width: ${props => props.width || '100%;'} !important;
`;

const LegendIcon = styled.div`
	width: 12px;
	height: 4px;
	background-color: ${props => props.color || colors.common.lightBlue};
	border-radius: 10px;
	display: flex;
	align-items: center;
`;

const LegendText = styled.div`
	color: ${props => props.color || colors.text.primary};
	font-size: 12px;
	font-weight: 600;
	display: flex;
	align-items: flex-end;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const LegendTextWrapper = styled.div`
	padding-right: 12px;
`;

const IconWrapper = styled.div`
	padding-top: 4px;
`;

const GraphLegend = ({ data }) => {
	const total = data.datasets.reduce((total, value) => total + value);

	const legendItems = useMemo(
		() =>
			data?.datasets.map((e, index) => {
				const percentageFormatted = Number(total) ? Math.round((e / total) * 100) : 0;
				return (
					// eslint-disable-next-line react/no-array-index-key
					<LegendContainer container key={`legend-${e}-${index}`}>
						<LegendItemContainer width={'20px;'}>
							<IconWrapper>
								<LegendIcon color={data.colors[index]} />
							</IconWrapper>
						</LegendItemContainer>
						<LegendItemContainer width={'100px;'}>
							<LegendText color={colors.text.greyDark}>{data.labels[index]}</LegendText>
						</LegendItemContainer>
						<LegendItemContainer width={'50px;'}>
							<LegendTextWrapper>
								<LegendText color={colors.text.greyDark}>{e}</LegendText>
							</LegendTextWrapper>
						</LegendItemContainer>
						<LegendItemContainer width={'20px;'}>
							<LegendText color={colors.text.greyLightDark}>
								{percentageFormatted === 0 ? '<1%' : `${percentageFormatted}%`}
							</LegendText>
						</LegendItemContainer>
					</LegendContainer>
				);
			}),
		[data, total],
	);

	return (
		<>
			<Wrapper>{legendItems}</Wrapper>
		</>
	);
};

GraphLegend.defaultProps = {
	data: {
		datasets: [],
		labels: [],
		colors: [],
	},
};

GraphLegend.propTypes = {
	data: PropTypes.shape({
		datasets: PropTypes.arrayOf(PropTypes.number),
		labels: PropTypes.arrayOf(PropTypes.string),
		colors: PropTypes.arrayOf(PropTypes.string),
	}),
};

export default GraphLegend;
