import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import ShareholderForm from 'Shareholders/components/ShareholderForm/ShareholderForm';
import crudModes from 'Common/constants/crudModes';
import { PropTypes } from 'prop-types';

const ShareholderCreateModal = ({ isOpen, availableEntityShares, entityId, onClose, onFormSubmit }) => {
	const { t } = useTranslation();

	return (
		<ModalForm
			label="Entities Shareholder Form"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			submitButtonText="Create"
			heading={t('Create Shareholder')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<ShareholderForm
					mode={crudModes.CREATE}
					entityId={entityId}
					availableEntityShares={availableEntityShares}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

ShareholderCreateModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	availableEntityShares: PropTypes.number.isRequired,
	entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default ShareholderCreateModal;
