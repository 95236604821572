import React from 'react';

import Icons, { iconOptions } from 'Common/components/icons/Icon';
import generateSelectControl from 'Application/stories/helpers/generateSelectControl';
import disableArgTypes from 'Application/stories/helpers/disableArgTypes';
import CenteredContent from 'Application/stories/decorators/CenteredContent';

export default {
	title: 'Common/Icons',
	component: Icons,
	decorators: [CenteredContent],
	argTypes: {
		...disableArgTypes(['size', 'padding', 'style']),
		...generateSelectControl('icon', iconOptions),
	},
};

const IconTemplate = args => <Icons {...args} />;

export const Icon = IconTemplate.bind({});
Icon.args = {
	icon: 'check',
	size: 'huge',
	color: 'black',
};

Icon.parameters = {
	design: {
		type: 'figma',
		url: 'https://www.figma.com/file/hCmKAn76GVRrP6Oiwm0Nut/ecoligo---Style-Guide-V1?node-id=1562%3A57653',
	},
};
