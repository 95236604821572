import { createSlice } from '@reduxjs/toolkit';
import { pullAll } from 'lodash';

const initialTableData = {
	projects: {
		url: '',
		query: {},
		selectedFilters: [],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
	clients: {
		url: '',
		query: {},
		selectedFilters: [],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
	unsignedClients: {
		url: '',
		query: {},
		selectedFilters: [],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
	partners: {
		url: '',
		query: {},
		selectedFilters: [],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
	epcPartners: {
		url: '',
		query: {},
		selectedFilters: [],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
	users: {
		url: '',
		query: {},
		selectedFilters: [],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
	epcUsers: {
		url: '',
		query: {},
		selectedFilters: [],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
	inquiries: {
		url: '',
		query: {},
		selectedFilters: [],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
	investors: {
		url: '',
		query: {},
		selectedFilters: [],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
	campaigns: {
		url: '',
		query: {},
		selectedFilters: [],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
	tranches: {
		url: '',
		query: {},
		selectedFilters: [],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
	myActivitiesDashboard: {
		url: '',
		query: {},
		selectedFilters: [],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
	teamActivitiesDashboard: {
		url: '',
		query: {},
		selectedFilters: [],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
	impactDashboard: {
		url: '',
		query: {},
		selectedFilters: ['country'],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
	entities: {
		url: '',
		query: {},
		selectedFilters: [],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
	test: {
		url: '',
		query: {},
		selectedFilters: [],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
	invoices: {
		query: {},
		selectedFilters: [],
		openedFilter: '',
		filtersCollapsed: false,
		omitedFilters: [],
	},
};

const tablesSlice = createSlice({
	name: 'tables',
	initialState: {
		...initialTableData,
	},
	reducers: {
		setTablesToIntital: () => initialTableData,
		clearTableData: (state, { payload: table }) => {
			state[table] = { ...initialTableData[table] };
		},
		updateCurrentTableData: (state, { payload: { table, tableData } }) => {
			state[table] = { ...state[table], ...tableData };
		},
		updateSelectedFilters: (state, { payload: { table, newSelectedFilters } }) => {
			state[table] = {
				...state[table],
				selectedFilters: [
					...pullAll(state[table]?.selectedFilters ?? [], newSelectedFilters),
					...newSelectedFilters,
				],
			};
		},
	},
});

export const { setTablesToIntital, clearTableData, updateCurrentTableData, updateSelectedFilters } =
	tablesSlice.actions;

export default tablesSlice.reducer;
