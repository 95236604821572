import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useTagFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				name: yup.string().required(t('Name is Required')),
				localizations: yup.array().of(
					yup.object().shape({
						language: yup
							.object()
							.shape({
								value: yup.string(),
								label: yup.string(),
								fullName: yup.string(),
							})
							.nullable()
							.required('Required'),
						name: yup.string(),
					}),
				),
			}),
		[t],
	);
};

export default useTagFormValidationSchema;
