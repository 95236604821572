/* eslint-disable react/prop-types */

import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';

import GraphLegend from 'Impact/components/GraphLegend';
import ImpactChart from 'Impact/components/ImpactChart';
import { graphOptions } from 'Impact/constants/options';

import styled from 'styled-components/macro';
import sizes from 'Application/theme/sizes';

const GraphWrapper = styled.div`
	margin-top: ${sizes.spacing(1.5)};
	display: flex;
	align-items: center;
	justify-content: center;
	width: 150px;
	height: 150px;
`;

const StyledGrid = styled(Grid)`
	padding: ${sizes.spacing(2)} 0 0 ${sizes.spacing(3.75)};
`;

const GraphWithLegend = ({ pieData, type }) => {
	return (
		<>
			<Grid container justifyContent="center" alignItems="center">
				<GraphWrapper>
					<ImpactChart data={pieData} type={type} options={graphOptions[type]} />
				</GraphWrapper>
			</Grid>
			<StyledGrid container justifyContent="center" alignItems="center">
				<GraphLegend data={pieData} />
			</StyledGrid>
		</>
	);
};

GraphWithLegend.propTypes = {
	pieData: PropTypes.shape({}).isRequired,
	type: PropTypes.string.isRequired,
};

export default GraphWithLegend;
