import api from 'Application/api/api';
import adaptProjectResponseData from 'Projects/api/adapters/adaptProjectResponseData';
import adaptProjectRequestData from 'Projects/api/adapters/adaptProjectRequestData';

const createProject = async (signal, values) => {
	const response = await api.post('/projects', adaptProjectRequestData(values), {
		signal,
		timeout: 100000,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptProjectResponseData(response.data),
	};
};

export default createProject;
