import { useTranslation } from 'react-i18next';
import loadCountryOptions from 'Country/utils/loadCountryOptions';
import loadPartnerTypeOptions from 'Partners/components/PartnerForm/utils/loadPartnerTypeOptions';
import { FILTER_TYPES } from 'Common/components/filter/constants/filterConstants';
import { useMemo } from 'react';

const usePartnerListFilterData = () => {
	const { t } = useTranslation();

	const generateFiltersData = useMemo(
		() => [
			{
				name: 'name',
				label: t('Name'),
				type: FILTER_TYPES.INPUT,
			},
			{
				name: 'countryId',
				label: t('Country'),
				loadOptions: loadCountryOptions,
				type: FILTER_TYPES.ASYNC_SELECT,
			},
			{
				name: 'partnerTypeId',
				label: t('Partner Type'),
				loadOptions: loadPartnerTypeOptions,
				type: FILTER_TYPES.ASYNC_SELECT,
			},
		],
		[t],
	);

	return generateFiltersData;
};

export default usePartnerListFilterData;
