import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalForm from 'Common/components/form/ModalForm';
import crudModes from 'Common/constants/crudModes';
import getShareholderById from 'Shareholders/api/getShareholderById';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PropTypes } from 'prop-types';
import ShareholderForm from 'Shareholders/components/ShareholderForm/ShareholderForm';

const ShareholdersEditModal = ({ isOpen, onClose, availableEntityShares, shareholderId, onFormSubmit }) => {
	const { t } = useTranslation();

	const [shareholder, setShareholder] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();

	const reCalculatedAvailableEntityShares = useMemo(
		() => availableEntityShares + (shareholder?.numberOfShares ?? 0),
		[availableEntityShares, shareholder?.numberOfShares],
	);

	useEffect(() => {
		(async () => {
			try {
				setIsLoading(true);
				const response = await getShareholderById(abortController.signal, shareholderId);

				setShareholder(response.data);
				setIsLoading(false);
			} catch (error) {
				showToastError(error);
			}
		})();
	}, [shareholderId, abortController.signal]);

	return (
		<ModalForm
			label="Entities Shareholder Form"
			isOpen={isOpen}
			onClose={onClose}
			onFormSubmit={onFormSubmit}
			isLoadingContent={isLoading}
			heading={t('Edit Shareholder')}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<ShareholderForm
					data={shareholder}
					mode={crudModes.EDIT}
					availableEntityShares={reCalculatedAvailableEntityShares}
					onSubmit={handleSubmit}
					onCancel={onCancel}
					onDirty={setIsFormDirty}
				/>
			)}
		</ModalForm>
	);
};

ShareholdersEditModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	availableEntityShares: PropTypes.number.isRequired,
	shareholderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onFormSubmit: PropTypes.func.isRequired,
};

export default ShareholdersEditModal;
