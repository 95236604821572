import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import H3 from 'Common/components/headers/H3';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import Notification from './Notification';
import SkeletonNotification from './SkeletonNotification';
import getAllNotifications from 'Notifications/api/getAllNotifications';
import CloseSidepanleBtn from 'Common/components/SidePanel/components/CloseSidepanelBtn';
import archieveAllNotifications from 'Notifications/api/archieveAllNotifications';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';

const Wrapper = styled.div`
	height: 100%;
	position: relative;
`;

const NotificationsHeader = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	padding: ${sizes.spacing(1)} ${sizes.spacing(2)} ${sizes.spacing(0.5)} ${sizes.spacing(2)};
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	z-index: 1;
	background-color: ${colors.common.white};
`;

const NotificationActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const NotificationsTitle = styled(H3)`
	margin: 0;
`;

const NotificationsList = styled.div`
	display: flex;
	flex-direction: column;
	max-height: 100%;
	width: calc(100% + 10px);
	overflow-y: auto;
	overflow-x: hidden;
	padding: ${sizes.spacing(2)};
	padding-top: ${sizes.spacing(6)};
`;

const SidePanelNotifications = ({ newNotification, handleOnClose }) => {
	const { t } = useTranslation();
	const [notifications, setNotifications] = useState([]);
	const [isArchive, setIsArchive] = useState(false);
	const [page, setPage] = useState(1);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [totalElements, setTotalElements] = useState(20);
	const userEmail = useSelector(state => state.user.email);

	const abortController = useAbortController();

	const notificationsContainer = useRef();

	const skeletonNotifications = useMemo(
		() =>
			Array.from({ length: Math.min(totalElements - notifications.length, 5) }, (_, ind) => ({
				type: 'skeleton',
				key: `skeleton-${ind}`,
			})),
		[totalElements, notifications],
	);

	const toggleArchive = () => {
		setNotifications([]);
		setTotalElements(5);
		setPage(1);
		setIsArchive(prev => !prev);
	};

	const archieveAll = async () => {
		setNotifications([]);
		try {
			await archieveAllNotifications(abortController.signal, userEmail);
			setNotifications([]);
			setTotalElements(0);
		} catch (error) {
			showToastError(error);
		}
	};

	const fetchNotifications = useCallback(
		async pageOffset => {
			setIsLoadingMore(true);
			try {
				const { content: notifications, totalElements } = await getAllNotifications(
					abortController.signal,
					userEmail,
					pageOffset,
					isArchive,
				).then(res => res.data);

				setNotifications(prevNotifications => [...prevNotifications, ...notifications]);
				setTotalElements(totalElements);
				setIsLoadingMore(false);
			} catch (error) {
				showToastError(error);
			}
		},
		[userEmail, isArchive, abortController.signal],
	);

	const handleScroll = useCallback(
		e => {
			const firstSkeleton = document.getElementById('first-skeleton');

			const scrolled = e?.target?.scrollTop + window.innerHeight;
			if (firstSkeleton && scrolled > firstSkeleton?.offsetTop && !isLoadingMore) {
				setIsLoadingMore(true);
				setPage(prevOffset => prevOffset + 1);
			}
		},
		[isLoadingMore],
	);

	useEffect(() => {
		const element = notificationsContainer.current;
		element.addEventListener('scroll', handleScroll);

		return () => {
			element.removeEventListener('scroll', handleScroll);
		};
	}, [notificationsContainer, handleScroll]);

	useEffect(() => {
		fetchNotifications(page);
	}, [fetchNotifications, page]);

	useEffect(() => {
		if (newNotification && Object.keys(newNotification).length > 0) {
			setNotifications(prev => [
				{ ...newNotification, isNewlyAdded: true },
				...prev.slice(0, prev.length).map(n => ({ ...n, isNewlyAdded: false })),
			]);
		}
	}, [newNotification]);

	return (
		<Wrapper>
			<NotificationsHeader>
				<NotificationsTitle>{isArchive ? t('Archive') : t('Notifications')}</NotificationsTitle>
				<NotificationActions>
					<MoreActionsButton noBackground label="Side Panel Notifications More Actions">
						<MenuItem
							type="button"
							data-action="showArchieve"
							onClick={toggleArchive}
							label={`Side Panel Notifications - Show ${isArchive ? 'Notifications' : 'Archive'} Menu Item`}
						>
							{isArchive ? t('Show Notifications') : t('Show Archive')}
						</MenuItem>
						{!isArchive && (
							<MenuItem
								type="button"
								data-action="archieveAll"
								onClick={archieveAll}
								label="Side Panel Notifications - Archive All Menu Item"
							>
								{t('Archive All')}
							</MenuItem>
						)}
					</MoreActionsButton>
					<CloseSidepanleBtn onClick={handleOnClose} />
				</NotificationActions>
			</NotificationsHeader>
			<NotificationsList ref={notificationsContainer}>
				{notifications.map(notification => (
					<div key={notification.id}>
						<Notification notification={notification} isArchive={isArchive} />
					</div>
				))}
				{skeletonNotifications.length > 0 && (
					<div id="first-skeleton">
						<SkeletonNotification />
					</div>
				)}
				{skeletonNotifications.slice(1).map((_, ind) => (
					// eslint-disable-next-line react/no-array-index-key
					<div key={ind}>
						<SkeletonNotification />
					</div>
				))}
			</NotificationsList>
		</Wrapper>
	);
};

SidePanelNotifications.defaultProps = {
	newNotification: {},
	handleOnClose: () => {},
};

SidePanelNotifications.propTypes = {
	newNotification: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]),
	handleOnClose: PropTypes.func,
};

export default SidePanelNotifications;
