export const getFiltersSerializer = (keys, parsedParam) => {
	const type = keys?.find(key => typeof parsedParam === 'string' && parsedParam.includes(key))
		? 'key'
		: 'value';

	const queryKeyTitles = ['condition', 'combinedStepStatusTitles', 'buildStageStatus'];
	const filterQueryTitle = queryKeyTitles.find(keyName => String(parsedParam).includes(keyName));

	if (type === 'key' && filterQueryTitle) {
		let keyParams = `filters${parsedParam
			.replace(filterQueryTitle, `[${filterQueryTitle}]`)
			.replace(/\.\d+$/g, '')}`;
		if (filterQueryTitle === 'combinedStepStatusTitles') {
			return `${keyParams.replace('.', '[')}]`;
		}
		return keyParams;
	}

	return parsedParam;
};
