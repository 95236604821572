import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import getProjectById from 'Projects/api/getProjectById';
import loadProjectOptions from 'Projects/utils/loadProjectOptions';

import notify from 'Common/utils/notify';
import Button from 'Common/components/buttons/Button';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import createCampaign from 'Campaigns/api/createCampaign';
import ProjectInfoTile from 'Campaigns/components/ProjectInfoTile/ProjectInfoTile';
import useCampaignFormInitialValues from 'Campaigns/components/CampaignForm/hooks/useCampaignFormInitialValues';
import convertFormValuesToRequestData from 'Campaigns/components/CampaignForm/utils/convertFormValuesToRequestData';
import useCampaignFormValidationSchema from 'Campaigns/components/CampaignForm/hooks/useCampaignFormValidationSchema';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import useResponsive from 'Common/hooks/useResponsive';
import { OverlayButtons } from 'Common/components/modals/Overlay';

const Form = styled.form`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
`;

const CampaignCreateForm = ({ onSubmit, onCancel, onDirty }) => {
	const { t } = useTranslation();
	const { isMobile } = useResponsive();
	const [projects, setProjects] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [hiddenProjects, setHiddenProjects] = useState([]);
	const [selectedProject, setSelectedProject] = useState(null);
	const [isLoadingProject, setIsLoadingProjects] = useState(true);

	const abortController = useAbortController();

	const initialValues = useCampaignFormInitialValues();
	const validationSchema = useCampaignFormValidationSchema();

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleChange,
		handleBlur,
		setFieldValue,
		setFieldError,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				let response;
				response = await createCampaign(abortController.signal, convertFormValuesToRequestData(values));

				notify(t('Campaign created successfully.'), {
					type: toast.TYPE.SUCCESS,
				});

				// Re-set all fields and mark them as not touched
				resetForm({ values });

				onSubmit(response.data);

				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});

				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	const handleAddProject = async () => {
		try {
			const response = await getProjectById(abortController.signal, selectedProject.value, true);

			setHiddenProjects(prev => [...prev, response.data]);
			const allocatedSize = response?.data.financeDetailsProjects.reduce(
				(sum, current) => sum + current.systemSizeAllocatedValue,
				0,
			);

			const financeDetailsProjects = [...response.data.financeDetailsProjects].map(projectData => ({
				...projectData,
				annualCo2SavingsEditable: projectData?.annual_co2savings_editable
					? projectData?.annual_co2savings_editable
					: 0,
				annualCo2Contribution: projectData?.annualCo2Contribution ? projectData?.annualCo2Contribution : 0,
			}));

			setFieldValue('financeDetailsProjects', [
				...values.financeDetailsProjects,
				{
					projectId: selectedProject.value,
					financeDetailsProjects,
					systemSizeAllocatedValue: (
						response?.data.systemSizeKwp - (response?.data.financeDetailsProjects ? allocatedSize : 0)
					).toFixed(2),
				},
			]);

			setSelectedProject(null);

			// Hide selected project from project select options
			setProjects(prev => {
				const projectOptions = [...prev];

				const selectedProjectIndex = projectOptions.findIndex(option => option.value === selectedProject.value);

				projectOptions[selectedProjectIndex] = {
					...projectOptions[selectedProjectIndex],
					isHidden: true,
				};

				return projectOptions;
			});
		} catch (e) {
			showToastError(e, t('An error occured while adding a new project'));
		}
	};

	const handleRemoveProject = id => {
		//Remove project from listed projects
		const currentSelectedProjects = [...values.financeDetailsProjects];

		currentSelectedProjects.splice(
			currentSelectedProjects.findIndex(e => e.projectId === id),
			1,
		);

		setFieldValue('financeDetailsProjects', currentSelectedProjects);

		// Unhide project from projects select
		setProjects(prev => {
			const projectOptions = [...prev];

			const selectedProjectIndex = projectOptions.findIndex(option => option.value === id);

			projectOptions[selectedProjectIndex] = {
				...projectOptions[selectedProjectIndex],
				isHidden: false,
			};
			return projectOptions;
		});
	};

	useEffect(() => {
		(async () => {
			try {
				setIsLoadingProjects(true);
				const response = await loadProjectOptions();
				setProjects(response);
				setIsLoadingProjects(false);
			} catch (e) {
				showToastError(e, t("Can't load project options"));
			}
		})();
	}, [abortController.signal, t]);

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form $isMobile={isMobile} onSubmit={handleSubmit}>
			<Grid container columnSpacing={{ xs: 0, md: 10 }}>
				<Grid item xs={12} md={6} lg={4}>
					<FormField>
						<FormikInput
							id="externalId"
							name="externalId"
							label={t('portagon ID')}
							value={values.externalId}
							error={errors.externalId}
							touched={touched.externalId}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="name"
							name="name"
							label={t('Name')}
							value={values.name}
							error={errors.name}
							touched={touched.name}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							tooltip={{
								tooltip: t('Use the same name in portagon to link the Campaign automatically.'),
							}}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="minimumVolume"
							name="minimumVolume"
							label={t('Min finance volume')}
							value={values.minimumVolume}
							error={errors.minimumVolume}
							touched={touched.minimumVolume}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="maximumVolume"
							name="maximumVolume"
							label={t('Max finance volume')}
							value={values.maximumVolume}
							error={errors.maximumVolume}
							touched={touched.maximumVolume}
							onChange={handleChange}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSelect
							id="projects"
							name="projects"
							label={t('Add Project')}
							value={selectedProject}
							onChange={setSelectedProject}
							isClearable
							onBlur={handleBlur}
							options={projects.filter(option => !option.isHidden)}
							isRequired
							isLoading={isLoadingProject}
							touched={touched.financeDetailsProjects}
							error={typeof errors.financeDetailsProjects === 'string' ? errors.financeDetailsProjects : ''}
							isOverlay
							menuPlacement="top"
						/>
					</FormField>
					<FormField>
						<Button
							text={t('Add Project')}
							onClick={handleAddProject}
							disabled={!selectedProject}
							label="Campaign Form - Add Project"
						/>
					</FormField>
				</Grid>
				<Grid container item xs={12} md={6} lg={8} columnSpacing={1}>
					{values.financeDetailsProjects.map((projectFinancialDetails, index) => (
						<Grid key={projectFinancialDetails.projectId} item xs={12} lg={6}>
							<ProjectInfoTile
								project={hiddenProjects.find(project => project.id === projectFinancialDetails.projectId) ?? {}}
								values={projectFinancialDetails}
								errors={errors?.financeDetailsProjects?.[index]}
								touched={touched?.financeDetailsProjects?.[index]}
								namePrefix={`financeDetailsProjects[${index}]`}
								handleChange={handleChange}
								handleBlur={handleBlur}
								setFieldValue={setFieldValue}
								removeProject={handleRemoveProject}
								isOverlay
							/>
						</Grid>
					))}
				</Grid>
			</Grid>

			<OverlayButtons
				onCancel={onCancel}
				isLoading={isLoading}
				label="Campaign Create Form"
				submitButtonText={'Create Campaign'}
			/>
		</Form>
	);
};

CampaignCreateForm.defaultProps = {
	data: null,
};

CampaignCreateForm.propTypes = {
	data: PropTypes.shape({
		localizations: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
	}),
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
};

export default CampaignCreateForm;
