import { Grid } from '@mui/material';
import Big from 'big.js';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import NoFalseValueSwitch from 'Common/components/form/NoFalseValueSwitch';
import { SectionWrapper } from 'Common/components/modals/OverlaySections';
import UNITS from 'Common/constants/units';
import useResponsive from 'Common/hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import crudModes from 'Common/constants/crudModes';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

const CenteredGrid = styled(Grid)`
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: 1200px) {
		justify-content: start;
	}
`;

const SwitchWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 82px;
	margin: 0 24px 0 0;

	@media (min-width: 1280px) {
		justify-content: center;
	}
`;

const OMSection = ({
	values,
	errors,
	touched,
	handleBlur,
	handleChange,
	setFieldValue,
	setFieldTouched,
	isPVProject,
	mode,
}) => {
	const { t } = useTranslation();
	const { isSmallscreen, isMediumScreen } = useResponsive();
	const omPriceSwitch = values.omPriceSwitch;
	const systemSize = Number(values.systemSize);

	const CurrencySign = () => <>{values?.currency?.value === 'EUR' ? '€' : values?.currency?.value}</>;

	const handleOmPriceFieldChange = e => {
		handleChange(e);
		const annualOmPrice = Number(e.target.value);
		if (!omPriceSwitch) {
			if (isNaN(systemSize) || systemSize === 0) {
				setFieldValue('annualOmPriceKwp', '', false);
			} else {
				setFieldValue(
					'annualOmPriceKwp',
					annualOmPrice ? Number(Number(Big(annualOmPrice).div(Big(systemSize)).toString()).toFixed(2)) : '',
					false,
				);
				if (errors['annualOmPriceKwp'] && annualOmPrice) {
					setFieldTouched('annualOmPriceKwp');
				}
			}
		}
	};

	const handleOmPriceSwitchChange = e => setFieldValue('omPriceSwitch', e);

	const handleOmPriceKwpFieldChange = e => {
		handleChange(e);
		const annualOmPriceKwp = Number(e.target.value);

		if (omPriceSwitch) {
			if (isNaN(systemSize) || systemSize === 0) {
				setFieldValue('annualOmPrice', '', false);
			} else {
				setFieldValue(
					'annualOmPrice',
					annualOmPriceKwp ? Number(Big(annualOmPriceKwp).times(Big(systemSize)).toString()).toFixed(2) : '',
					false,
				);
				if (errors['annualOmPrice'] && annualOmPriceKwp) {
					setFieldTouched('annualOmPrice');
				}
			}
		}
	};

	return mode !== crudModes.VIEW ? (
		<SectionWrapper>
			<Grid container>
				<Grid item xs={12} lg={3}>
					<FormField
						$small={isSmallscreen || isMediumScreen}
						{...(!values.omPriceSwitch ? { 'data-first-field': true } : {})}
					>
						<FormikInput
							prefix={CurrencySign}
							label={t('Annual O&M price')}
							id="annualOmPrice"
							name="annualOmPrice"
							error={errors.annualOmPrice}
							touched={!values.omPriceSwitch && touched.annualOmPrice}
							value={values.annualOmPrice}
							onChange={handleOmPriceFieldChange}
							onBlur={handleBlur}
							isRequired
							isDisabled={Boolean(values.omPriceSwitch)}
							isOverlay
						/>
					</FormField>
				</Grid>
				{isPVProject && (
					<>
						<CenteredGrid item xs={12} lg={2}>
							<SwitchWrapper>
								<NoFalseValueSwitch
									setValue={handleOmPriceSwitchChange}
									name="omPriceSwitch"
									value={Boolean(values.omPriceSwitch)}
								/>
							</SwitchWrapper>
						</CenteredGrid>
						<Grid item xs={12} lg={3}>
							<FormField {...(values.omPriceSwitch ? { 'data-first-field': true } : {})}>
								<FormikInput
									prefix={CurrencySign}
									label={t('Annual O&M per kWp')}
									id="annualOmPriceKwp"
									name="annualOmPriceKwp"
									value={values.annualOmPriceKwp}
									error={errors.annualOmPriceKwp}
									touched={Boolean(values.omPriceSwitch && touched.annualOmPriceKwp)}
									onChange={handleOmPriceKwpFieldChange}
									onBlur={handleBlur}
									isRequired
									isDisabled={!values.omPriceSwitch}
									isOverlay
								/>
							</FormField>
						</Grid>
					</>
				)}
			</Grid>
			<FormField data-last-field>
				<FormikInput
					label={t('Annual O&M escalation rate')}
					id="annualOmEscalationRate"
					name="annualOmEscalationRate"
					error={errors.annualOmEscalationRate}
					touched={touched.annualOmEscalationRate}
					value={values.annualOmEscalationRate}
					onChange={handleChange}
					onBlur={handleBlur}
					isRequired
					unit={UNITS.PERCENT}
					isOverlay
				/>
			</FormField>
		</SectionWrapper>
	) : (
		<SectionWrapper>
			<Grid container>
				<Grid item xs={12} lg={3}>
					<KeyValueVisualization
						id="annualOmPrice"
						title={t('Annual O&M price')}
						value={values.annualOmPrice}
						prefix={CurrencySign}
					/>
				</Grid>
				{isPVProject && (
					<KeyValueVisualization
						id="annualOmPriceKwp"
						title={t('Annual O&M per kWp')}
						value={values.annualOmPriceKwp}
						prefix={CurrencySign}
					/>
				)}
			</Grid>
			<KeyValueVisualization
				id="annualOmEscalationRate"
				title={t('Annual O&M escalation rate')}
				value={values.annualOmEscalationRate}
				unit={UNITS.PERCENT}
			/>
		</SectionWrapper>
	);
};

OMSection.defaultProps = {
	values: {
		omPriceSwitch: false,
		systemSize: '',
		annualOmPrice: '',
		annualOmPriceKwp: '',
		annualOmEscalationRate: '',
	},
	errors: {
		annualOmPrice: '',
		annualOmPriceKwp: '',
		annualOmEscalationRate: '',
	},
	touched: {
		annualOmPrice: false,
		annualOmPriceKwp: false,
		annualOmEscalationRate: false,
	},
	handleBlur: () => {},
	handleChange: () => {},
	setFieldValue: () => {},
	setFieldTouched: () => {},
	isPVProject: false,
};
OMSection.propTypes = {
	values: PropTypes.shape({
		omPriceSwitch: PropTypes.bool,
		systemSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		annualOmPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		annualOmPriceKwp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		annualOmEscalationRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		currency: PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		}),
	}),
	errors: PropTypes.shape({
		annualOmPrice: PropTypes.string,
		annualOmPriceKwp: PropTypes.string,
		annualOmEscalationRate: PropTypes.string,
	}),
	touched: PropTypes.shape({
		annualOmPrice: PropTypes.bool,
		annualOmPriceKwp: PropTypes.bool,
		annualOmEscalationRate: PropTypes.bool,
	}),
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	setFieldValue: PropTypes.func,
	setFieldTouched: PropTypes.func,
	isPVProject: PropTypes.bool,
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
};

export default OMSection;
