import api from 'Application/api/api';
import adaptEpcOfferRequestData from 'EpcContract/api/adapters/adaptEpcOfferRequestData';
import adaptEpcOfferResponseData from 'EpcContract/api/adapters/adaptEpcOfferResponseData';

const editEpcOffer = async (signal, values, sendNotification = false) => {
	const response = await api.put('/epc-offers', adaptEpcOfferRequestData(values), {
		params: {
			sendNotification,
		},
		signal,
		timeout: 100000,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptEpcOfferResponseData(response.data),
	};
};

export default editEpcOffer;
