import api from 'Application/api/api';

const editEntityComment = async (signal, values) => {
	const response = await api.put('/contract-party-comments', values, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: response.data,
	};
};

export default editEntityComment;
