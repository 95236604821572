import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import format from 'date-fns/format';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import EditableTile from 'Common/components/Tile/EditableTile';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useEditableRow } from 'Common/hooks/table/plugin-hooks/useEditableRow';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { useCustomRowProps } from 'Common/hooks/table/plugin-hooks/useCustomRowProps';
import TableField from 'Common/components/form/Table/TableField';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import TableSingleDatePicker from 'Common/components/form/Table/TableSingleDatePicker';
import notify from 'Common/utils/notify';
import formatNumber from 'Common/utils/formatNumber';
import { DefaultTable, TableBodyCell, TableHeadCell } from 'Common/components/table/table';

import changeExchangeRateDate from 'Projects/api/changeExchangeRateDate';
import TableInput from 'Common/components/form/Table/TableInput';
import { useCustomRowStyle } from 'Common/hooks/table/plugin-hooks/useCustomRowStyle';
import colors from 'Application/theme/colors';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';

const Wrapper = styled.div`
	position: relative;
	width: 100%;
`;

const TableWrapper = styled.div`
	${TableHeadCell} {
		background: transparent;
	}

	${TableBodyCell} {
		background: transparent;
	}
`;

const CurrenciesTile = ({ project, onTileSave }) => {
	const { t } = useTranslation();

	const abortController = useAbortController();

	const columns = useMemo(
		() => [
			{
				Header: t('Currency'),
				accessor: 'isoCode',
				width: 100,
				customBodyCellStyles: {
					fontWeight: 600,
					height: '53px',
				},
				Cell: (() => {
					const Cell = ({ row: { original } }) =>
						original.isoCode ? <div data-isocode={'isoCode'}>{original.isoCode}</div> : '-';
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ isoCode: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Date'),
				accessor: 'exchangeRateDate',
				width: 80,
				sticky: true,
				customBodyCellStyles: {
					fontWeight: 600,
					height: '53px',
				},

				Cell: (() => {
					const Cell = ({ row: { original } }) =>
						original.exchangeRateDate ? (
							<div data-exchangeratedate={'exchangeRateDate'}>
								{format(new Date(original.exchangeRateDate), 'd/M/yy')}
							</div>
						) : (
							'-'
						);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ exchangeRateDate: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
				EditableField: (() => {
					const EditableField = ({
						formik: { values, errors, touched, setFieldValue, setFieldTouched },
						row: { original },
					}) => (
						<TableSingleDatePicker
							id={`${original.isoCode}_exchangeRateDate`}
							startDate={values.exchangeRateDate}
							startDateName="exchangeRateDate"
							error={errors.exchangeRateDate}
							touched={touched.exchangeRateDate}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
						/>
					);
					EditableField.propTypes = {
						formik: PropTypes.shape({
							values: PropTypes.shape({
								exchangeRateDate: PropTypes.string,
							}),
							errors: PropTypes.shape({
								exchangeRateDate: PropTypes.string,
							}),
							touched: PropTypes.shape({
								exchangeRateDate: PropTypes.string,
							}),
							setFieldTouched: PropTypes.func,
							setFieldValue: PropTypes.func,
						}).isRequired,
						row: PropTypes.shape({ original: PropTypes.shape({ isoCode: PropTypes.string }) }).isRequired,
					};
					return EditableField;
				})(),
			},
			{
				Header: t('Rate'),
				accessor: 'exchangeRate',
				width: 100,
				customBodyCellStyles: {
					height: '53px',
				},
				Cell: (() => {
					const Cell = ({ row: { original: currency } }) =>
						currency.exchangeRate ? (
							<div data-exchangerate={'exchangeRate'}>{formatNumber(currency.exchangeRate, 9)}</div>
						) : (
							'-'
						);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ exchangeRate: PropTypes.number }) }).isRequired,
					};
					return Cell;
				})(),
				EditableField: (() => {
					const EditableField = ({ formik: { values, errors, touched, handleChange, handleBlur } }) => (
						<TableInput
							name="comment"
							value={values.comment}
							error={errors.comment}
							touched={touched.comment}
							onChange={handleChange}
							onBlur={handleBlur}
							disabled={true}
						/>
					);
					EditableField.propTypes = {
						formik: PropTypes.shape({
							values: PropTypes.shape({
								comment: PropTypes.string,
							}),
							errors: PropTypes.shape({
								comment: PropTypes.string,
							}),
							touched: PropTypes.shape({
								comment: PropTypes.string,
							}),
							handleChange: PropTypes.func,
							handleBlur: PropTypes.func,
						}).isRequired,
					};
					return EditableField;
				})(),
			},
			{
				Header: '',
				accessor: 'actions',
				width: 30,
				customCellStyles: {
					background: `${colors.common.tileBackgroundGrey}!important`,
				},
				customHeaderCellStyles: {
					background: 'transparent',
				},
				customBodyCellStyles: {
					height: '53px',
				},
				EditableField: TableField,
				Cell: (() => {
					const Cell = ({ row }) => (
						<MoreActionsButton
							data-isocode={row.original.isoCode}
							label="Projects Details Currencies More Actions"
							controlledVisibility
						>
							<MenuItem
								type="button"
								data-action="edit"
								{...row.getTurnOnRowEditModeProps()}
								label="Projects Details Currencies - Edit Menu Item"
							>
								{t('Edit')}
							</MenuItem>
						</MoreActionsButton>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							getTurnOnRowEditModeProps: PropTypes.func,
							original: PropTypes.shape({ isoCode: PropTypes.string }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
		],
		[t],
	);

	// Memoized project currencies
	const data = useMemo(() => project.currencies, [project.currencies]);

	const getEditableRowInitialValues = useCallback(
		row => ({
			exchangeRateDate: row.original.exchangeRateDate ? new Date(row.original.exchangeRateDate) : null,
		}),
		[],
	);

	const editableRowFormikOptions = useMemo(
		() => ({
			validationSchema: yup.object({
				exchangeRateDate: yup.date().required(t('Required')).nullable(),
			}),

			onSubmit: async (row, values) => {
				try {
					const response = await changeExchangeRateDate(
						abortController.signal,
						format(new Date(values.exchangeRateDate), 'yyyy-MM-dd'),
						row.original.id,
						project.id,
					);

					const newExchangeRateDate = format(
						new Date(response.data.currencies.find(currency => currency.id === row.original.id).exchangeRateDate),
						'yyyy-MM-dd',
					);

					const submittedExchangeRateDate = format(new Date(values.exchangeRateDate), 'yyyy-MM-dd');

					if (newExchangeRateDate !== submittedExchangeRateDate) {
						notify(
							t(
								`Project Exchange rate date changed to ${newExchangeRateDate} because some exchange rates were not available for the selected day `,
							),
							{
								type: toast.TYPE.WARNING,
							},
						);
					} else {
						notify(t(`Project Exchange rate date set to ${newExchangeRateDate}`), {
							type: toast.TYPE.SUCCESS,
						});
					}

					await onTileSave(response.data);
				} catch (error) {
					showToastError(error);
					return 'error';
				}
			},
		}),
		[onTileSave, project.id, t, abortController.signal],
	);

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
				getEditableRowInitialValues,
				editableRowFormikOptions,
				newRowData: {
					exchangeRateDate: null,
				},
				getCustomRowProps: row => ({
					'data-isocode': row.original.isoCode,
				}),
				getCustomRowStyles: () => ({
					height: 'auto',
				}),
			},
			useCustomRowProps,
			useCustomRowStyle,
			useCustomCellStyles,
			useEditableRow,
		);

	return (
		<EditableTile title={t('Currencies')} isTable>
			<Wrapper>
				<TableWrapper>
					<DefaultTable
						getTableProps={getTableProps}
						getTableHeaderProps={getTableHeaderProps}
						getTableBodyProps={getTableBodyProps}
						rows={rows}
						headerGroups={headerGroups}
						prepareRow={prepareRow}
						editableRowFormikOptions={editableRowFormikOptions}
					/>
				</TableWrapper>
			</Wrapper>
		</EditableTile>
	);
};

CurrenciesTile.defaultProps = {
	project: {},
	onTileSave: async () => {},
};

CurrenciesTile.propTypes = {
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		currencies: PropTypes.arrayOf(PropTypes.shape({})),
	}).isRequired,
	onTileSave: PropTypes.func,
};

export default CurrenciesTile;
