export const m62Challenges = {
	THERE_WERE_NO_MAJOR_CHALLENGES: 'THERE_WERE_NO_MAJOR_CHALLENGES',
};

export const m62ChallengeOptions = [
	{
		label: 'There were no major challenges',
		value: m62Challenges.THERE_WERE_NO_MAJOR_CHALLENGES,
	},
];
