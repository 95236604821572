const convertToNullableValues = (values, omittedFields) =>
	Object.keys(values).reduce((acc, key) => {
		if (omittedFields.includes(key)) {
			return {
				...acc,
				[key]: values[key],
			};
		}

		return {
			...acc,
			[key]: values[key] || null,
		};
	}, {});

export default convertToNullableValues;
