import api from 'Application/api/api';
import adaptFiltersRangeResponseData from 'Projects/api/adapters/adaptFiltersRangeResponseData';

const getFilterRange = async signal => {
	const response = await api.get('/projects/filters-range', {
		signal,
	});

	return {
		...response,
		data: adaptFiltersRangeResponseData(response.data),
	};
};

export default getFilterRange;
