import { useMemo } from 'react';
import { pick } from 'lodash';
import parseISO from 'date-fns/parseISO';

const fallbackValues = {
	internalQualificationPassed: false,
	externalQualificationPassed: false,
};

const useQualificationsFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['internalQualificationPassed', 'externalQualificationPassed']),
			internalPassDate: data.internalPassDate ? parseISO(data.internalPassDate) : undefined,
			externalPassDate: data.externalPassDate ? parseISO(data.externalPassDate) : undefined,
		}),
		[data],
	);

export default useQualificationsFormInitialValues;
