// TODO remove the eslint complexity and fix it
/* eslint-disable complexity */
import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Label from 'Common/components/form/Label';
import ErrorMessage from 'Common/components/form/ErrorMessage';
import FormikInput from 'Common/components/form/FormikInput';
import SliderRange from 'Common/components/form/SliderRange';
import formatNumber from 'Common/utils/formatNumber';
import { useOnClickOutside } from 'crooks';
import Mandatory from 'Common/components/form/Mandatory';
import colors from 'Application/theme/colors';
import { useTranslation } from 'react-i18next';
import useReponsive from 'Common/hooks/useResponsive';
import CustomDebounce from 'Common/utils/CustomDebounce';

const Wrapper = styled.div`
	width: ${({ $isMobile, $width }) => ($width ? $width + 'px' : $isMobile ? '300px' : '420px')};
`;

const FieldsWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;

	> div {
		&:first-child,
		&:last-child {
			flex: 1;

			input + .icon {
				display: none;
			}
		}
	}

	input {
		& ~ svg {
			left: unset !important;
			right: 10px !important;
		}
	}
`;

const TopSide = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
`;

const Popover = styled.div`
	position: absolute;
	bottom: 0;
	left: 50%;
	z-index: 3;
	background: ${colors.common.white};
	border-radius: 5px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
	transform: translate(-50%, calc(100% + 15px));

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -100%);
		width: 0;
		height: 0;
		border-left: 13px solid transparent;
		border-right: 13px solid transparent;
		border-bottom: 13px solid #fff;
	}
`;

const ClearBtn = styled.div`
	padding: 5px;
	text-align: center;
	position: relative;
	color: ${colors.primary.main};
	cursor: pointer;

	&:before {
		content: '';
		position: absolute;
		left: -16px;
		top: -5px;
		width: calc(100% + 32px);
		height: 1px;
		background-color: ${colors.grey.light};
	}
`;

const FormikNumberRange = ({
	label,
	minId,
	minRef,
	maxId,
	maxRef,
	minName,
	maxName,
	minValue,
	maxValue,
	minError,
	maxError,
	minTouched,
	maxTouched,
	lowerBound,
	upperBound,
	setFieldValue,
	minProps,
	maxProps,
	isRequired,
	isFilterInput,
	hasClearButton = true,
	width,
}) => {
	const { t } = useTranslation();
	const errors = [];
	const { isMobile } = useReponsive();

	if (minError) {
		errors.push(minError);
	}

	if (maxError) {
		errors.push(maxError);
	}

	const areFieldsTouched =
		(typeof minTouched === 'boolean' && minTouched) ||
		typeof minTouched === 'object' ||
		(typeof maxTouched === 'boolean' && maxTouched) ||
		typeof maxTouched === 'object';

	const hasError = errors.length > 0 && areFieldsTouched;
	const hasLabel = label !== null;

	const [isSliderOpened, setIsSliderOpened] = useState(false);

	const [values, setValues] = useState({
		min: minValue ?? '',
		max: maxValue ?? '',
		lastChanged: null,
	});

	useEffect(() => {
		setValues({
			min: minValue ?? '',
			max: maxValue ?? '',
			lastChanged: null,
		});
	}, [minValue, maxValue]);

	const debouncerInstance = useMemo(() => new CustomDebounce(), []);

	const syncValues = useCallback(
		({ min, max, lastChanged }) => {
			let newMin = min;
			let newMax = max;

			if (min !== '' && Number(min) <= lowerBound) {
				newMin = '';
			} else if (min !== '' && Number(min) > upperBound) {
				newMin = upperBound.toString();
			}

			if (max !== '' && Number(max) >= upperBound) {
				newMax = '';
			} else if (max !== '' && Number(max) < lowerBound) {
				newMax = lowerBound.toString();
			}

			if (min !== '' && max !== '' && Number(min) > Number(max)) {
				if (lastChanged === 'min') {
					newMax = '';
				} else if (lastChanged === 'max') {
					newMin = '';
				}
			}

			if (newMin !== min || newMax !== max) {
				setValues({
					min: newMin,
					max: newMax,
					lastChanged: null,
				});
			}

			if (isFilterInput) {
				setFieldValue({
					[minName]: newMin,
					[maxName]: newMax,
				});
			} else {
				setFieldValue(minName, newMin);
				setFieldValue(maxName, newMax);
			}
		},
		[lowerBound, maxName, minName, setFieldValue, isFilterInput, upperBound],
	);

	const debouncedSyncValues = useCallback(
		valuesForSync =>
			debouncerInstance.debounce(() => {
				syncValues(valuesForSync);
			}, 800),
		[debouncerInstance, syncValues],
	);

	const handleChangeMinValue = e => {
		const value = e.target.value ? e.target.value.replace(/,/g, '') : '';

		setValues(prevValues => {
			const newValues = {
				...prevValues,
				min: value,
				lastChanged: 'min',
			};

			if (value > lowerBound) {
				debouncedSyncValues(newValues);
			}

			return newValues;
		});
	};

	const handleChangeMaxValue = e => {
		const value = e.target.value ? e.target.value.replace(/,/g, '') : '';

		setValues(prevValues => {
			const newValues = {
				...prevValues,
				max: value,
				lastChanged: 'max',
			};

			debouncedSyncValues(newValues);

			return newValues;
		});
	};

	const handleClearMinValue = () => {
		setValues(prevValues => ({
			...prevValues,
			min: '',
			lastChanged: 'min',
		}));
		setFieldValue({ [minName]: '' });
		setIsSliderOpened(true);
	};

	const handleClearMaxValue = () => {
		setValues(prevValues => ({
			...prevValues,
			max: '',
			lastChanged: 'max',
		}));
		setFieldValue({ [maxName]: '' });
		setIsSliderOpened(true);
	};

	const handleOutsideClick = () => {
		setIsSliderOpened(false);
	};

	const wrapperRef = useOnClickOutside(handleOutsideClick, !isSliderOpened);

	const handleSliderChange = ([min, max]) => {
		const newValues = {
			min: min !== lowerBound ? min.toString() : '',
			max: max !== upperBound ? max.toString() : '',
			lastChanged: null,
		};
		setValues(newValues);
		debouncedSyncValues(newValues);
	};

	const isMinInValidRange =
		values.min === '' || (Number(values.min) >= lowerBound && Number(values.min) <= upperBound);

	const isMaxInValidRange =
		values.max === '' || (Number(values.max) >= lowerBound && Number(values.max) <= upperBound);

	const areMinAndMaxValuesAligned =
		values.min === '' || values.max === '' || Number(values.min) <= Number(values.max);

	const isInValidRange = isMinInValidRange && isMaxInValidRange && areMinAndMaxValuesAligned;

	return (
		<Wrapper $width={width} $isMobile={isMobile}>
			{hasLabel && (
				<Label
					label={
						<>
							{label}
							{isRequired && <Mandatory />}
						</>
					}
					hasError={hasError}
				/>
			)}

			<FieldsWrapper ref={wrapperRef}>
				<TopSide>
					<FormikInput
						id={minId}
						name={minName}
						value={values.min !== '' ? values.min : ''}
						error={minError}
						touched={minTouched}
						onChange={handleChangeMinValue}
						isClearable={values.min !== ''}
						onClear={handleClearMinValue}
						autoComplete="off"
						placeholder={formatNumber(parseInt(minProps.placeholder.replace(/[^0-9 | ^.]/g, '')), 0)}
						ref={minRef}
						type="number"
					/>
					<FormikInput
						id={maxId}
						name={maxName}
						value={values.max !== '' ? values.max : ''}
						error={maxError}
						touched={maxTouched}
						onChange={handleChangeMaxValue}
						isClearable={values.max !== ''}
						onClear={handleClearMaxValue}
						autoComplete="off"
						placeholder={formatNumber(parseInt(maxProps.placeholder.replace(/[^0-9 | ^.]/g, '')), 0)}
						ref={maxRef}
						type="number"
					/>
				</TopSide>
				<div>
					{isSliderOpened && isInValidRange && (
						<Popover>
							<SliderRange
								min={lowerBound}
								max={upperBound}
								value={[values.min ? Number(values.min) : lowerBound, values.max ? Number(values.max) : upperBound]}
								onChange={handleSliderChange}
							/>
						</Popover>
					)}

					{isFilterInput && (
						<SliderRange
							min={lowerBound}
							max={upperBound}
							value={[values.min ? Number(values.min) : lowerBound, values.max ? Number(values.max) : upperBound]}
							onChange={handleSliderChange}
						/>
					)}
					{hasClearButton && (
						<ClearBtn
							onClick={() => {
								setFieldValue({ [minName]: '', [maxName]: '' });
							}}
						>
							{t('Clear all')}
						</ClearBtn>
					)}
				</div>
			</FieldsWrapper>

			{hasError && <ErrorMessage message={errors.join('. ')} />}
		</Wrapper>
	);
};

FormikNumberRange.defaultProps = {
	label: null,
	minError: null,
	maxError: null,
	minTouched: false,
	maxTouched: false,
	minProps: {},
	maxProps: {},
	isRequired: false,
	minRef: null,
	maxRef: null,
	minValue: undefined,
	maxValue: undefined,
	width: 0,
	hasClearButton: false,
	isFilterInput: false,
};

FormikNumberRange.propTypes = {
	label: PropTypes.string,
	minId: PropTypes.string.isRequired,
	maxId: PropTypes.string.isRequired,
	minName: PropTypes.string.isRequired,
	maxName: PropTypes.string.isRequired,
	minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	minError: PropTypes.string,
	maxError: PropTypes.string,
	minTouched: PropTypes.bool,
	maxTouched: PropTypes.bool,
	lowerBound: PropTypes.number.isRequired,
	upperBound: PropTypes.number.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	minProps: PropTypes.shape({
		placeholder: PropTypes.string,
	}),
	maxProps: PropTypes.shape({
		placeholder: PropTypes.string,
	}),
	isRequired: PropTypes.bool,
	minRef: PropTypes.shape({}),
	maxRef: PropTypes.shape({}),
	isFilterInput: PropTypes.bool,
	hasClearButton: PropTypes.bool,
	width: PropTypes.number,
};

export { FormikNumberRange, Popover };
