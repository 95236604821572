import api from 'Application/api/api';

const getUserAssignments = async (signal, email) => {
	const response = await api.get('/users/get-assignments', {
		params: { email },
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
	};
};

export default getUserAssignments;
