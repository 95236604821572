import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';
import yupUrl from 'Common/utils/yupUrl';

const useClientDetailsFormValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				name: yup.string().required(t('Required')),
				website: yupUrl(),
				employees: yup
					.number()
					.typeError(t('Employees must be a number'))
					.positive(t('Employees must be positive'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.integer(t('Employees must be a whole number'))
					.nullable(),
				industry: yup
					.object()
					.shape({ label: yup.string(), value: yup.number() })
					.nullable()
					.required(t('Required')),
				percentageOfFemaleShare: yup
					.number()
					.typeError(t('Female share must be a number'))
					.min(0, t('Female share must be more than or equals to 0'))
					.max(100, t('Female share must be less than or equals to 100'))
					.nullable()
					.test(
						'decimal-places',
						'Must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^-?\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					),
				managers: yup
					.number()
					.typeError(t('Managers must be a number'))
					.positive(t('Managers must be positive'))
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					)
					.integer(t('Managers must be a whole number'))
					.nullable(),
				percentageOfFemaleManagers: yup
					.number()
					.typeError(t('Female share must be a number'))
					.min(0, t('Percentage off female managers must be greater than or equal to 0'))
					.nullable()
					.test(
						'decimal-places',
						'Must have no more than 2 decimal places',
						value => value === undefined || String(value).match(/^-?\d*(\.\d{1,2})?$/),
					)
					.transform((val, originalValue) =>
						typeof originalValue === 'string' && originalValue === '' ? null : val,
					),
			}),
		[t],
	);
};

export default useClientDetailsFormValidationSchema;
