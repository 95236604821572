import api from 'Application/api/api';

const editProjectSettings = async (signal, values) => {
	const response = await api.put('/project-settings', values, {
		signal,
		timeout: 10000,
	});

	if (!response || !response.data) {
		return response;
	}
	return {
		...response,
		data: response.data,
	};
};

export default editProjectSettings;
