import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { PropTypes } from 'prop-types';

import Modal from 'Common/components/modals/Modal';
import Button from 'Common/components/buttons/Button';
import showToastError from 'Common/utils/showToastError';
import { useState } from 'react';
import axios from 'axios';
import H1 from '../headers/H1';
import H5 from '../headers/H5';

const ButtonsWrapper = styled.div`
	margin-top: 40px;
	display: flex;
	justify-content: center;

	.ReactModal__Content {
		padding: 40px !important;
		text-align: center;
	}

	.ReactModal__Content > div {
		display: flex;
		justify-content: center;
	}

	> * {
		margin-right: 16px;

		&:last-child {
			margin-right: 0px;
		}
	}

	${({ $noCancel }) =>
		$noCancel &&
		css`
			> * {
				margin-right: 0px;
			}
		`}
`;

const TextWrapper = styled(H5)`
	white-space: pre-line;
	text-align: center;
`;

const ConfirmModal = ({
	label,
	heading,
	text: text,
	children,
	noCancel,
	onCancel,
	isLoading,
	onConfirm,
	cancelText,
	confirmText,
	...props
}) => {
	const { t } = useTranslation();
	const [isCanceling, setIsCanceling] = useState(false);
	const [isConfirming, setIsConfirming] = useState(false);

	const handleOnCancel = async (...args) => {
		try {
			setIsCanceling(true);
			await onCancel(...args);
			setIsCanceling(false);
		} catch (e) {
			showToastError(e, t('Something went wrong when canceling the action'));
		}
	};

	const handleOnConfirm = async (...args) => {
		try {
			setIsConfirming(true);
			await onConfirm(...args);
			setIsConfirming(false);
		} catch (e) {
			showToastError(e, t('Something went wrong when confirming the action'));
			if (!axios.isCancel(e)) {
				setIsConfirming(false);
			}
		}
	};

	return (
		<Modal label={label} onRequestClose={onCancel} {...props}>
			<H1>{heading}</H1>
			{text && <TextWrapper>{text}</TextWrapper>}
			{children}

			<ButtonsWrapper $cancel={noCancel}>
				{!noCancel && (
					<Button
						secondary={Boolean(onConfirm)}
						text={cancelText ?? t('Cancel')}
						onClick={handleOnCancel}
						isLoading={isCanceling}
						label={`Confirm Modal - ${label} - ${cancelText ?? t('Cancel')} Button`}
					/>
				)}
				{onConfirm && (
					<Button
						overlay
						text={confirmText ?? t('Confirm')}
						onClick={handleOnConfirm}
						isLoading={isLoading || isConfirming}
						className="confirmBtn"
						label={`Confirm Modal - ${label} - ${confirmText ?? t('Confirm')} Button`}
					/>
				)}
			</ButtonsWrapper>
		</Modal>
	);
};

ConfirmModal.defaultProps = {
	label: 'Confirm Modal',
	children: null,
	text: '',
	noCancel: false,
	isLoading: false,
	confirmText: undefined,
	cancelText: undefined,
	onCancel: () => {},
	heading: '',
	onConfirm: () => {},
};

ConfirmModal.propTypes = {
	label: PropTypes.string,
	children: PropTypes.node,
	heading: PropTypes.string.isRequired,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onCancel: PropTypes.func,
	noCancel: PropTypes.bool,
	onConfirm: PropTypes.func,
	isLoading: PropTypes.bool,
	confirmText: PropTypes.node,
	cancelText: PropTypes.string,
};

export default ConfirmModal;
