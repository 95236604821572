import { useMemo } from 'react';
import { pick } from 'lodash';

const fallbackValues = {
	name: '',
};

const usePlatformsFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['name']),
		}),
		[data],
	);

export default usePlatformsFormInitialValues;
