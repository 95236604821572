import { useMemo } from 'react';
import { pick } from 'lodash';
import parseISO from 'date-fns/parseISO';

const fallbackValues = {
	threeAuditedAnnualReportsExisting: '',
	fddPassed: '',
	scoringProfitabilityNetIncomeProfitMargin: '',
	scoringLiquidityQuickRatio: '',
	scoringSolvencyAssetCoverageRatio: '',
	scoringSolvencyAssetCoverageRatioExShareholderLoans: '',
	financialDDOPassedDate: null,
};

const useDueDiligenceFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, [
				'threeAuditedAnnualReportsExisting',
				'fddPassed',
				'scoringProfitabilityNetIncomeProfitMargin',
				'scoringLiquidityQuickRatio',
				'scoringSolvencyAssetCoverageRatio',
				'scoringSolvencyAssetCoverageRatioExShareholderLoans',
			]),
			financialDDOPassedDate: data?.financialDDOPassedDate ? parseISO(data?.financialDDOPassedDate) : null,
		}),
		[data],
	);

export default useDueDiligenceFormInitialValues;
