import { useEffect, useRef } from 'react';

const useAbortController = () => {
	const abortController = useRef(new AbortController());

	useEffect(() => () => abortController.current.abort(), [abortController]);

	return {
		signal: abortController.current.signal,
	};
};

export default useAbortController;
