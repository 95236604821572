import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import Link from 'Common/components/Link';
import Title from 'Common/components/title/Title';
import PriorityIcon from 'Common/components/icons/PriorityIcon';

import DashboardStep from 'PMWorkflow/components/Step/DashboardStep/DashboardStep';
import { useGeneratedSteps } from 'PMWorkflow/components/Project/DashboardProject/hooks/useGeneratedSteps';

import ProjectInfoPopup from 'Dashboard/components/ProjectInfoPopup/ProjectInfoPopup';

import colors from 'Application/theme/colors';
import getProjectById from 'Projects/api/getProjectById';
import {
	updateSidePanelContentData,
	updateSidePanelData,
	updateSidePanelHeaderData,
} from 'Application/reducers/reduxSidePanel';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { SIDE_PANEL_CONTENT_TYPES } from 'Common/components/SidePanel/constants/sidePanelContentTypes';

const Wrapper = styled(Grid)`
	position: relative;
	background-color: ${colors.grey.light};
	margin-bottom: 10px;
	border-radius: 20px;
	padding: 2px;
	padding-bottom: 0;
	transform-origin: top;
	transform-style: preserve-3d;
	border: solid 1.5px transparent;
	cursor: pointer;
	z-index: 1;

	&:hover {
		z-index: 2;
	}

	@keyframes remove {
		0% {
			transform: scaleY(1) translateX(0);
			margin-bottom: 10px !important;
			height: 46px;
			opacity: 1;
		}

		60% {
			opacity: 0;
		}

		99% {
			transform: scaleY(0) translateX(110%);
			margin-bottom: 10px !important;
			height: 0px;
			opacity: 0;
		}

		100% {
			transform: scaleY(0);
			min-height: 0;
			max-height: 0;
			height: 0px;
			opacity: 0;
		}
	}

	${({ $isSelected }) =>
		$isSelected &&
		css`
			border-color: ${colors.primary.dark};
		`}

	&.hasMarker {
		border-color: ${colors.primary.main};
	}

	${({ $isRemoved }) =>
		$isRemoved &&
		css`
			margin-bottom: 0 !important;
			animation: remove 0.5s linear forwards;
			animation-delay: 0.5s;
		`}
`;

const CompletedPercentage = styled.div`
	font-size: 16px;
	font-weight: 500;
	line-height: 19.5px;
	align-items: flex-end;
`;

const InfoSection = styled.div`
	padding: 12px 16px;
`;

const RelativeGrid = styled(Grid)`
	position: relative;
`;

const StyledLink = styled(Link)`
	position: relative;
	text-decoration: none;
`;

const StyledTitle = styled(Title)`
	&:hover {
		color: ${colors.text.secondary};
		cursor: pointer;
	}
	color: ${({ isActive }) => (isActive ? colors.text.secondary : colors.text.greyDark)};
`;

const WrapperPriority = styled(Grid)`
	margin-right: 12px;
`;

const DashboardProject = ({ zIndex, project, stepModifier, updateProject, setShowAddedToMyProjects }) => {
	const { t } = useTranslation();
	const [isPopupShown, setPopupShown] = useState(false);
	const [removingSteps, setRemovingSteps] = useState([]);
	const dispatch = useDispatch();
	const sidePanelData = useSelector(state => state.sidePanel);

	const isProjectOpenInSidePanel = useMemo(
		() =>
			sidePanelData.isOpen &&
			sidePanelData.type === 'dashboardProject' &&
			sidePanelData?.contentData?.project?.id === project?.id,
		[project?.id, sidePanelData?.contentData?.project?.id, sidePanelData.isOpen, sidePanelData.type],
	);

	const currentUser = useSelector(state => state.user);

	const { visibleSteps, allSteps } = useGeneratedSteps(stepModifier, project, currentUser);

	const abortController = useAbortController();

	const handleMouseOver = () => {
		setPopupShown(true);
	};

	const handleMouseLeave = () => {
		setPopupShown(false);
	};

	const handleInfoSectionClick = async () => {
		if (isProjectOpenInSidePanel) return;

		dispatch(
			updateSidePanelData({
				isOpen: true,
				type: SIDE_PANEL_CONTENT_TYPES.DASHBOARD_PROJECT,
				isLoading: true,
				contentData: {
					project: {
						id: project.id,
					},
					showHistory: false,
				},
			}),
		);
	};

	const setSidePanelProject = useCallback(async () => {
		try {
			dispatch(
				updateSidePanelData({
					isSoftLoading: true,
				}),
			);
			const projectResponse = await getProjectById(abortController.signal, project.id, true);

			dispatch(
				updateSidePanelHeaderData({
					to: '/projects',
					isBreadcrumb: true,
					breadCrumb: 'Projects',
					activeBreadcrumb: `${project.externalId} - ${
						project?.client?.name ? project?.client?.name : t('Unknown client')
					} ${project?.description ? ` (${project?.description})` : ''}`,
					toActiveBreadcrumb: `/projects/details/${project.id}`,
				}),
			);
			dispatch(
				updateSidePanelContentData({
					project: { ...projectResponse.data },
				}),
			);
			dispatch(
				updateSidePanelData({
					isLoading: false,
					isSoftLoading: false,
				}),
			);
		} catch (error) {
			showToastError(error);
		}
	}, [
		dispatch,
		project?.client?.name,
		project?.description,
		project.externalId,
		project.id,
		abortController.signal,
		t,
	]);

	useEffect(() => {
		if (isProjectOpenInSidePanel) {
			setSidePanelProject();
		}
	}, [setSidePanelProject, isProjectOpenInSidePanel]);

	const isRemovedProject = useMemo(
		() => visibleSteps?.length === 0 && removingSteps?.length === 0,
		[visibleSteps, removingSteps],
	);

	const isProjectMoved = useMemo(
		() =>
			allSteps?.length > 0 &&
			visibleSteps?.length === 0 &&
			removingSteps?.length === 0 &&
			project.completedPercentage < 100,
		[allSteps?.length, project.completedPercentage, removingSteps?.length, visibleSteps?.length],
	);

	useEffect(() => {
		let timeout1;
		let timeout2;
		if (isProjectMoved) {
			timeout1 = setTimeout(() => {
				setShowAddedToMyProjects(true);

				timeout2 = setTimeout(() => {
					setShowAddedToMyProjects(false);
				}, 1000);
			}, 1500);
		}

		return () => {
			clearTimeout(timeout1);
			clearTimeout(timeout2);
		};
	}, [isProjectMoved, setShowAddedToMyProjects]);
	const bindedUpdateProject = useMemo(
		() => updateProject.bind(undefined, project.id),
		[project.id, updateProject],
	);

	return allSteps?.length > 0 ? (
		<Wrapper
			$isRemoved={isRemovedProject}
			$isSelected={isProjectOpenInSidePanel}
			$zIndex={zIndex}
			data-dashboard-section-marker
		>
			<InfoSection onClick={() => handleInfoSectionClick(project)}>
				<Grid item container xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="space-between">
					<RelativeGrid item container xs={11} sm={11} md={11} lg={11} xl={11}>
						<WrapperPriority>
							<PriorityIcon priority={project.priority.priority} />
						</WrapperPriority>
						<div onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave}>
							{isPopupShown && <ProjectInfoPopup disableUserInfoHover projectId={project.id} />}
							<StyledLink to={`/projects/details/${project.id}`} onClick={e => e.stopPropagation()}>
								<StyledTitle isActive={isProjectOpenInSidePanel}>
									{project?.client
										? t(`${project?.externalId} - ${project?.client?.name}`)
										: t(`${project?.externalId} - Unknown Client`)}
								</StyledTitle>
							</StyledLink>
						</div>
					</RelativeGrid>
					<Grid item container xs={1} sm={1} md={1} lg={1} xl={1} justifyContent="flex-end" alignItems="center">
						<Grid item>
							<CompletedPercentage>{`${Math.floor(project?.completedPercentage)}%`}</CompletedPercentage>
						</Grid>
					</Grid>
				</Grid>
			</InfoSection>
			{allSteps.map(step => (
				<DashboardStep
					key={step.id}
					step={step}
					project={project}
					setRemovingSteps={setRemovingSteps}
					updateProject={bindedUpdateProject}
				/>
			))}
		</Wrapper>
	) : null;
};

DashboardProject.defaultProps = {
	zIndex: 1,
	setShowAddedToMyProjects: undefined,
};

DashboardProject.propTypes = {
	zIndex: PropTypes.number,
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		externalId: PropTypes.number,
		client: PropTypes.shape({
			name: PropTypes.string,
		}),
		description: PropTypes.string,
		completedPercentage: PropTypes.number,
		priority: PropTypes.shape({ priority: PropTypes.string }),
	}).isRequired,
	updateProject: PropTypes.func.isRequired,
	stepModifier: PropTypes.func.isRequired,
	setShowAddedToMyProjects: PropTypes.func,
};

export default DashboardProject;
