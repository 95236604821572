import { useEffect, useMemo, useRef } from 'react';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import styled, { css } from 'styled-components/macro';
import FormikInput from 'Common/components/form/FormikInput';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import FilterDropdownLoader from 'Common/components/filter/FilterDropdownLoader';
import StepFilterCheckbox from './StepFilterCheckbox';
import useReponsive from 'Common/hooks/useResponsive';
import PropTypes from 'prop-types';

const SidebarContainer = styled.div`
	padding: ${sizes.spacing(0.8)} ${sizes.spacing(1)};
	border-left: solid 1px ${colors.grey.light};
	display: flex;
	flex-direction: column;
	gap: ${sizes.spacing(0.4)};
	min-width: max-content;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: ${sizes.base(105)};
`;

const DropdownContainer = styled.div`
	display: flex;
	flex-direction: row;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	overflow: hidden;
	background-color: ${colors.common.white};
	z-index: 5;

	${({ $showMobileStepSidebar }) =>
		$showMobileStepSidebar &&
		css`
			flex-direction: column;
			max-height: 600px;

			${SidebarContainer} {
				max-height: 250px;
				border-top: solid 1px ${colors.grey.light};
				padding: ${sizes.spacing(2.2)} ${sizes.spacing(1)};
			}
		`};
`;

const MainContainer = styled.div`
	width: 100%;
	max-height: ${sizes.base(105)};
`;

const SearchFieldContainer = styled.div`
	display: flex;
	max-width: 300px;
	padding: ${sizes.spacing(1)} ${sizes.spacing(1.5)};
	padding-top: ${sizes.spacing(1.8)};
`;

const OptionsContainer = styled.div`
	max-height: ${sizes.base(82)};
	width: ${sizes.base(75)};
	overflow-y: auto;
`;

const BottomBtn = styled.div`
	border-top: 1px solid ${colors.grey.light};
	line-height: 22px;
	padding: ${sizes.spacing(1.2)} 0;
	color: ${colors.text.primaryLight};
	cursor: pointer;
	${sizes.fontSize.main};
	text-align: center;
`;

const SidebarOption = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${sizes.spacing(1)};
`;

const SidebarOptionPill = styled.div`
	position: relative;
	width: max-content;
	padding: ${sizes.spacing(0.5)} ${sizes.spacing(1.5)};
	padding-right: ${sizes.spacing(3.5)};
	border-radius: ${sizes.base(8)};
	background-color: ${colors.common.blue};
	color: white;
	font-weight: 700;
	${sizes.fontSize.main};
	cursor: pointer;

	&:after {
		content: '+';
		position: absolute;
		right: ${sizes.spacing(1.6)};
		top: 55%;
		transform: rotate(45deg) translate(-50%, -50%);
		font-size: ${sizes.base(8)};
		font-weight: 500;
	}

	&.colored {
		color: red;
	}
`;

const LabelCharacter = styled.div`
	color: ${({ isColored }) => (isColored ? `${colors.primary.dark}` : 'inherit')};
`;

const LabelCharactersContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	max-width: 300px;
`;

const ShortText = styled.div`
	text-overflow: ellipsis;
	max-width: 230px;
	white-space: nowrap;
	overflow: hidden;
`;

const DropdownOption = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: ${sizes.spacing(0.5)} ${sizes.spacing(2)};
	font-weight: 500;
	min-height: ${sizes.base(8)};
	color: ${colors.common.black};
	line-height: 24px;
	cursor: pointer;
	${sizes.fontSize.main};
	max-width: 300px;

	&:hover {
		background-color: ${colors.common.blue};
		color: ${colors.common.white};
		${LabelCharacter} {
			color: ${colors.common.white};
		}
	}
	&:after {
		content: '';
		display: block;
		min-width: ${sizes.base(6)};
		height: ${sizes.base(6)};
		border-radius: ${sizes.base(1.5)};
		background-color: ${colors.primary.light};
		transform: translateX(${sizes.spacing(1.1)});
	}

	&:before {
		content: ${({ isSelected }) => (isSelected ? "''" : 'none')};
		position: absolute;
		width: ${sizes.base(2.3)};
		height: ${sizes.base(3.8)};
		top: ${sizes.spacing(0.8)};
		right: ${sizes.spacing(1.8)};
		border-bottom: solid 2px ${colors.primary.dark};
		border-right: solid 2px ${colors.primary.dark};
		transform: rotate(45deg);
		z-index: 2;
	}
`;

// const SpecialOption = styled(DropdownOption)`
// 	position: sticky;
// 	top: 0;
// 	background-color: ${colors.common.white};
// 	border-bottom: solid 1px ${colors.grey.light};
// 	z-index: 1;
// `;

const LabelArray = ({ labelArr }) => (
	<LabelCharactersContainer>
		{labelArr.map(({ value, colored }, i) => (
			// eslint-disable-next-line react/no-array-index-key
			<LabelCharacter isColored={colored} key={`label-char-${i}`}>
				{value}
			</LabelCharacter>
		))}
	</LabelCharactersContainer>
);

LabelArray.propTypes = {
	labelArr: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			colored: PropTypes.bool,
		}),
	).isRequired,
};

const renderLabel = label => {
	return Array.isArray(label) ? (
		label.length > 25 ? (
			<HoverTooltip arrow="bottom" title={label.map(el => el.hiddenValue).join('')} placement="top">
				<LabelArray labelArr={label} />
			</HoverTooltip>
		) : (
			<LabelArray labelArr={label} />
		)
	) : label.length > 20 ? (
		<HoverTooltip arrow="bottom" title={label} placement="top">
			<ShortText>{label}</ShortText>
		</HoverTooltip>
	) : (
		<span>{label}</span>
	);
};

const FilterDropdown = ({
	options,
	hasFocus,
	isLoading,
	searchValue,
	bottomBtnLabel,
	displayOptions,
	setSearchValue,
	stepFilterProps,
	selectedOptions,
	handleOptionClick,
	handleBottomBtnClick,
	disableSearch,
	disableClearAll,
}) => {
	const { isMobile } = useReponsive();
	const isStepFilter = Boolean(stepFilterProps);
	const inputRef = useRef();
	const handleSearch = e => {
		setSearchValue(e.target.value);
	};

	const stringifiedOptions = useMemo(
		() => Array.isArray(selectedOptions) && [...selectedOptions]?.map(el => String(el)),
		[selectedOptions],
	);

	const isUsingObjects = isStepFilter;

	const isSelected = option =>
		option.selected ||
		(Array.isArray(stringifiedOptions) && stringifiedOptions?.includes(String(option.value))) ||
		(isUsingObjects && Boolean(selectedOptions.find(el => String(el.value) === String(option.value))));

	useEffect(() => {
		if (hasFocus && inputRef.current) {
			inputRef.current.focus();
		}
	}, [hasFocus, inputRef]);

	const showMobileStepSidebar = isMobile && isStepFilter;
	const showSidebar = !isMobile || showMobileStepSidebar;

	return (
		<DropdownContainer $showMobileStepSidebar={showMobileStepSidebar} onClick={e => e.preventDefault()}>
			<MainContainer>
				{!disableSearch && (
					<SearchFieldContainer>
						<FormikInput
							placeholder="Search"
							value={searchValue}
							onChange={handleSearch}
							ref={inputRef}
							isClearable
							onClear={() => setSearchValue('')}
						/>
					</SearchFieldContainer>
				)}

				{isLoading && <FilterDropdownLoader />}

				{!isLoading && (
					<OptionsContainer>
						{options.map(el => (
							<DropdownOption
								key={String(el.key ?? el.name ?? el.value)}
								isSelected={isSelected(el)}
								onClick={() => handleOptionClick(isStepFilter ? el : el.name ?? el.value)}
							>
								{el.CustomOptionWrapper ? (
									<el.CustomOptionWrapper>{renderLabel(el.label)}</el.CustomOptionWrapper>
								) : (
									<>{renderLabel(el.label)}</>
								)}
							</DropdownOption>
						))}
					</OptionsContainer>
				)}

				{(!isStepFilter || !isMobile) && !disableClearAll && (
					<BottomBtn onClick={handleBottomBtnClick}>{bottomBtnLabel}</BottomBtn>
				)}
			</MainContainer>

			{displayOptions?.length > 0 && showSidebar && (
				<SidebarContainer>
					{displayOptions.map(el => (
						<SidebarOption key={String(el.key ?? el.name ?? el.value)}>
							{isStepFilter && (
								<StepFilterCheckbox stepData={el} onStepStatusChange={stepFilterProps.onStepStatusChange} />
							)}
							<SidebarOptionPill onClick={() => handleOptionClick(isStepFilter ? el : el.name ?? el.value)}>
								{el?.label?.length > 20 ? (
									<HoverTooltip arrow="bottom" title={el?.label} placement="top">
										<span>{el?.label?.substring(0, 21)}...</span>
									</HoverTooltip>
								) : (
									<span>{el?.label}</span>
								)}
							</SidebarOptionPill>
						</SidebarOption>
					))}
				</SidebarContainer>
			)}
			{showMobileStepSidebar && <BottomBtn onClick={handleBottomBtnClick}>{bottomBtnLabel}</BottomBtn>}
		</DropdownContainer>
	);
};

FilterDropdown.defaultProps = {
	bottomBtnLabel: '',
	displayOptions: [],
	hasFocus: false,
	isLoading: false,
	searchValue: '',
	setSearchValue: () => {},
	stepFilterProps: null,
	options: [],
	selectedOptions: [],
	handleBottomBtnClick: () => {},
	handleOptionClick: () => {},
	disableSearch: false,
	disableClearAll: false,
};

FilterDropdown.propTypes = {
	bottomBtnLabel: PropTypes.string,
	displayOptions: PropTypes.arrayOf(PropTypes.shape({})),
	handleBottomBtnClick: PropTypes.func,
	handleOptionClick: PropTypes.func,
	hasFocus: PropTypes.bool,
	isLoading: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.shape({})),
	searchValue: PropTypes.string,
	selectedOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])),
	setSearchValue: PropTypes.func,
	stepFilterProps: PropTypes.shape({
		onStepStatusChange: PropTypes.func,
	}),
	disableSearch: PropTypes.bool,
	disableClearAll: PropTypes.bool,
};

export default FilterDropdown;
