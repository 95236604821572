import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import FileUploadField from 'Common/components/form/FileUploadField';
import FormikSelect from 'Common/components/form/FormikSelect';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import { FILE_TYPES } from 'Common/constants/fileTypes';
import loadPartnerOptions from 'Projects/utils/loadPartnerOptions';
import loadEntityOptions from 'Projects/utils/loadEntityOptions';
import convertFormValuesToRequestData from 'Projects/utils/convertFormValuesToRequestData';
import uploadClientContractDocuments from 'Projects/api/uploadInsuranceDocuments';
import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import notify from 'Common/utils/notify';
import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import sizes from 'Application/theme/sizes';
import createProjectInsurance from 'Projects/api/createProjectInsurance';
import updateProjectInsurance from 'Projects/api/updateProjectInsurance';
import { insuranceValidityStatusOptions } from 'Projects/constants/insuranceValidityStatuses';
import getInsuranceDownloadLink from 'Projects/api/getInsuranceDownloadLink';
import editProject from 'Projects/api/editProject';
import useInsuranceFormValidationSchema from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/InsuranceTile/hooks/useInsuranceFormValidationSchema';
import useInsuranceFormInitialValues from 'Projects/components/ProjectDetails/Tabs/OperationsTab/Tiles/InsuranceTile/hooks/useInsuranceFormInitialValues';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import formatNumber from 'Common/utils/formatNumber';
import axios from 'axios';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import Label from 'Common/components/form/Label';

const Title = styled.div`
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 10px;
	color: ${colors.text.greyDark};
`;

const StyledGridContainer = styled(Grid)`
	height: auto;
`;

const StyledGrid = styled(Grid)`
	padding-right: 32px;
	height: fit-content;
	position: relative;
`;

const StyledFormField = styled.div`
	max-width: 400px;
	margin-bottom: 16px !important;
`;

const BlockSectionsTitle = styled.div`
	color: ${colors.text.grey};
	font-size: 14px;
	line-height: 17px;
	font-weight: 700;
`;

const BlocksContainer = styled.div`
	margin-bottom: ${sizes.spacing(2)};
	display: flex;
	flex-direction: column;
	gap: ${sizes.spacing(0.5)};
	padding-top: ${sizes.spacing(0.5)};
`;

const BlockerPill = styled.div`
	padding: ${sizes.spacing(0.5)} ${sizes.spacing(1.5)};
	background-color: ${({ isBlocker, isCompleted }) =>
		isBlocker || isCompleted ? colors.grey.light : colors.common.blue};
	width: fit-content;
	border-radius: 32px;
	font-size: 14px;
	color: ${colors.common.white};
`;
const isValid = val => !Number.isNaN(Number(val)) || Boolean(val);

const InsuranceTile = ({
	project,
	onEnterEditMode,
	onExitEditMode,
	onDirtyForm,
	onTileSave,
	highlightLabels,
	breadcrumbList,
}) => {
	const { t } = useTranslation();

	const isAuthorizedToEditExcluded = isAuthorized([
		roles.ADMIN,
		roles.ASSET_MANAGER,
		roles.HEAD_OF_ASSET_MANAGEMENT,
	]);
	const isAuthorizedtoEdit = isAuthorized([
		roles.FINANCE,
		roles.ADMIN,
		roles.ASSET_MANAGER,
		roles.HEAD_OF_ASSET_MANAGEMENT,
		roles.HEAD_OF_FINANCE,
	]);

	const isAuthorizedFinanceRole = useMemo(() => !isAuthorizedToEditExcluded, [isAuthorizedToEditExcluded]);
	const isAuthorizedithoutdFinanceRole = useMemo(() => !isAuthorizedtoEdit, [isAuthorizedtoEdit]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useInsuranceFormInitialValues(project);
	const validationSchema = useInsuranceFormValidationSchema();

	const {
		errors,
		touched,
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		handleSubmit,
		setFieldTouched,
		dirty,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			setIsSavingChanges(true);
			try {
				if (!project.insurance) {
					let response = await createProjectInsurance(
						abortController.signal,
						convertFormValuesToRequestData(values),
					);
					await editProject(abortController.signal, {
						...project,
						insurance: response.data,
					});
					if (values.formDocument) {
						const formData = new FormData();
						formData.append('document', values.formDocument);
						await uploadClientContractDocuments(abortController.signal, formData, response.data.id, 'FORM');
					}
					if (values.signedPolicyDocument) {
						const formData = new FormData();
						formData.append('document', values.signedPolicyDocument);
						await uploadClientContractDocuments(
							abortController.signal,
							formData,
							response.data.id,
							'SIGNED_POLICY',
						);
					}
					if (values.payslipDocument) {
						const formData = new FormData();
						formData.append('document', values.payslipDocument);
						await uploadClientContractDocuments(abortController.signal, formData, response.data.id, 'PAYSLIP');
					}
					notify(t('Project Insurance created successfully.'), {
						type: toast.TYPE.SUCCESS,
					});
				} else {
					const response = await updateProjectInsurance(
						abortController.signal,
						convertFormValuesToRequestData(values),
						project.insurance.id,
					);
					if (values.formDocument) {
						const formData = new FormData();
						formData.append('document', values.formDocument);
						await uploadClientContractDocuments(abortController.signal, formData, response.data.id, 'FORM');
					}
					if (values.signedPolicyDocument) {
						const formData = new FormData();
						formData.append('document', values.signedPolicyDocument);
						await uploadClientContractDocuments(
							abortController.signal,
							formData,
							response.data.id,
							'SIGNED_POLICY',
						);
					}
					if (values.payslipDocument) {
						const formData = new FormData();
						formData.append('document', values.payslipDocument);
						await uploadClientContractDocuments(abortController.signal, formData, response.data.id, 'PAYSLIP');
					}
					notify(t('Project Insurance updated successfully.'), {
						type: toast.TYPE.SUCCESS,
					});
					await editProject(abortController.signal, {
						...project,
						insurance: response.data,
					});
				}

				if (onDirtyForm) {
					onDirtyForm(false);
				}
				await onTileSave();
				handleCancel();
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					handleCancel();
					setIsSavingChanges(false);
					resetForm({ values });
				}
			}
		},
	});

	useEffect(() => {
		(async () => {
			try {
				if (project?.insurance) {
					const currencies = await loadCurrenciesOptions(abortController.signal, false);

					const defaultCurrency = currencies.find(
						e => e?.value === (project?.insurance?.insuredCurrency || 'EUR'),
					);
					const policyCurrency = currencies.find(e => e?.value === (project?.insurance?.policyCurrency || 'EUR'));
					setFieldValue('insuredCurrency', defaultCurrency);
					setFieldValue('policyCurrency', policyCurrency);
				} else {
					if (project?.epcContract) {
						const currencies = await loadCurrenciesOptions(abortController.signal, false);
						const defaultCurrency = currencies.find(
							e => e?.value === (project?.epcContract?.annualOmPrice?.currency || 'EUR'),
						);
						setFieldValue('insuredCurrency', defaultCurrency);
					}
				}
			} catch (err) {
				showToastError(err, "Can't fetch currencies");
			}
		})();
	}, [
		abortController.signal,
		project?.insuredCurrency,
		project?.insurance,
		project?.policyCurrency,
		project?.epcContract,
		setFieldValue,
	]);

	useEffect(() => {
		if (project?.insurance) {
			setFieldValue('insuredSum', project?.insurance?.insuredSum?.value);
		} else if (project?.epcContract) {
			setFieldValue('insuredSum', project?.epcContract?.annualOmPrice?.value);
		} else {
			setFieldValue('insuredSum', '');
		}
	}, [project?.insurance, project?.epcContract, setFieldValue]);

	useEffect(() => {
		if (values?.formDocument) {
			setFieldValue('formDocumentName', values?.formDocument.name);
		}
	}, [values?.formDocument, setFieldValue]);

	const handleDocumentDownload = async document => {
		try {
			const response = await getInsuranceDownloadLink(abortController.signal, document.name, document.versionId);
			window.open(response.data, '_blank');
		} catch (error) {
			showToastError(error);
		}
	};

	const currencySign = values.insuredCurrency
		? () => (values.insuredCurrency.value === 'EUR' ? '€' : values.insuredCurrency.value)
		: null;

	const policyCurrencySign = values.policyCurrency
		? () => (values.policyCurrency.value === 'EUR' ? '€' : values.policyCurrency.value)
		: null;

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedtoEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Insurance')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<Title>{t('Insurance sum and premium')}</Title>
			<StyledGridContainer container>
				<StyledGrid item xs={12} lg={6} xl={3}>
					<StyledFormField>
						<FormikInput
							isTile
							id="insuredSum"
							name="insuredSum"
							label={t('Insured sum')}
							value={values.insuredSum}
							error={errors.insuredSum}
							touched={touched.insuredSum}
							onChange={handleChange}
							onBlur={handleBlur}
							prefix={currencySign}
							isInEditMode={isInEditMode}
							setFieldValue={setFieldValue}
							isRequired
							isDisabled={isAuthorizedFinanceRole}
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_INSURANCE.INSURANCE,
							)}
						/>
					</StyledFormField>
				</StyledGrid>
				<StyledGrid item xs={12} lg={6} xl={3}>
					<StyledFormField>
						<FormikSelect
							isAsync
							isTile
							id="insuredCurrency"
							name="insuredCurrency"
							label={t('Currency')}
							value={values.insuredCurrency}
							error={errors.insuredCurrency}
							touched={touched.insuredCurrency}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadCurrenciesOptions}
							isOverlay
							isRequired
							isInEditMode={isInEditMode}
							isDisabled={isAuthorizedFinanceRole}
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_INSURANCE.INSURANCE,
							)}
						/>
					</StyledFormField>
				</StyledGrid>
				<StyledGrid item xs={12} lg={6} xl={3}>
					<StyledFormField>
						<FormikInput
							isTile
							id="premium"
							name="premium"
							label={t('Premium')}
							value={
								isValid(values.premium) ? (isInEditMode ? values.premium : formatNumber(values.premium, 2)) : ''
							}
							error={errors.premium}
							touched={touched.premium}
							onChange={handleChange}
							onBlur={handleBlur}
							prefix={currencySign}
							isInEditMode={isInEditMode}
							isRequired
							isDisabled={isAuthorizedFinanceRole}
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_INSURANCE.INSURANCE,
							)}
						/>
					</StyledFormField>
				</StyledGrid>
				<StyledGrid item xs={12} lg={6} xl={3}>
					<StyledFormField>
						<FormikInput
							isTile
							id="premiumPercentage"
							name="premiumPercentage"
							label={t('Premium Percentage')}
							value={values.premiumPercentage}
							error={errors.premiumPercentage}
							touched={touched.premiumPercentage}
							setFieldValue={setFieldValue}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder={'0.00%'}
							isRequired
							isInEditMode={isInEditMode}
							isDisabled={isAuthorizedFinanceRole}
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_INSURANCE.INSURANCE,
							)}
						/>
					</StyledFormField>
				</StyledGrid>
			</StyledGridContainer>
			<StyledFormField>
				<FileUploadField
					isTile
					label={t('Insurance form')}
					value={values.formDocument}
					name="formDocument"
					displayValue={values.formDocumentName}
					fileName="Document"
					acceptedTypes={[FILE_TYPES.PDF]}
					error={errors.formDocumentName}
					touched={touched.formDocument}
					documents={project?.insurance?.formDocuments}
					onDocumentDownload={handleDocumentDownload}
					breadcrumbList={breadcrumbList}
					setFieldValue={setFieldValue}
					onChange={handleChange}
					onBlur={handleBlur}
					isRequired
					isInEditMode={isInEditMode}
					isDisabled={isAuthorizedFinanceRole}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_INSURANCE.INSURANCE,
					)}
				/>
			</StyledFormField>
			<Title>{t('Insurance provider')}</Title>
			<StyledGridContainer container>
				<StyledGrid item xs={12} lg={6} xl={4}>
					<StyledFormField>
						<FormikSelect
							isTile
							isAsync
							id="providerName"
							name="providerName"
							label={t('Provider name')}
							value={values.providerName}
							error={errors.providerName}
							touched={touched.providerName}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={() => loadPartnerOptions(abortController.signal, project.country.id)}
							isInEditMode={isInEditMode}
							isRequired
							isDisabled={isAuthorizedFinanceRole}
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_INSURANCE.INSURANCE,
							)}
						/>
					</StyledFormField>
				</StyledGrid>
				<StyledGrid item xs={12} lg={6} xl={4}>
					<StyledFormField>
						<FormikInput
							isTile
							id="policyCost"
							name="policyCost"
							label={t('Policy cost')}
							value={
								isValid(values.policyCost)
									? isInEditMode
										? values.policyCost
										: formatNumber(values.policyCost, 2)
									: ''
							}
							isDisabled={isAuthorizedFinanceRole}
							error={errors.policyCost}
							touched={touched.policyCost}
							onChange={handleChange}
							onBlur={handleBlur}
							prefix={policyCurrencySign}
							isInEditMode={isInEditMode}
							isRequired
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_INSURANCE.INSURANCE,
							)}
						/>
					</StyledFormField>
				</StyledGrid>
				<StyledGrid item xs={12} lg={6} xl={4}>
					<StyledFormField>
						<FormikSelect
							isTile
							isAsync
							id="policyCurrency"
							name="policyCurrency"
							label={t('Currency')}
							value={values.policyCurrency}
							error={errors.policyCurrency}
							touched={touched.policyCurrency}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadCurrenciesOptions}
							isOverlay
							isInEditMode={isInEditMode}
							isRequired
							isDisabled={isAuthorizedFinanceRole}
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_INSURANCE.INSURANCE,
							)}
						/>
					</StyledFormField>
				</StyledGrid>
				<StyledGrid item xs={12} lg={6} xl={4}>
					<StyledFormField>
						<FormikSelect
							isTile
							isAsync
							id="insuredEntity"
							name="insuredEntity"
							label={t('Insured entity')}
							value={values.insuredEntity}
							error={errors.insuredEntity}
							touched={touched.insuredEntity}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={() => loadEntityOptions(abortController.signal, project.country.id)}
							isInEditMode={isInEditMode}
							isRequired
							isDisabled={isAuthorizedFinanceRole}
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_INSURANCE.INSURANCE,
							)}
						/>
					</StyledFormField>
				</StyledGrid>
				<StyledGrid item xs={12} lg={6} xl={4}>
					<StyledFormField>
						<FormikSingleDatePicker
							isTile
							id="policyStartDate"
							label={t('Policy start date')}
							startDateName="policyStartDate"
							error={errors.policyStartDate}
							touched={touched.policyStartDate}
							startDate={values.policyStartDate}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							isRequired
							isOverlay
							isInEditMode={isInEditMode}
							isDisabled={isAuthorizedFinanceRole}
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_INSURANCE.INSURANCE,
							)}
						/>
					</StyledFormField>
				</StyledGrid>
				<StyledGrid item xs={12} lg={6} xl={4}>
					<StyledFormField>
						<FormikSingleDatePicker
							isTile
							id="policyEndDate"
							label={t('Policy end date')}
							startDateName="policyEndDate"
							error={errors.policyEndDate}
							touched={touched.policyEndDate}
							startDate={values.policyEndDate}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							isRequired
							isOverlay
							isInEditMode={isInEditMode}
							isDisabled={isAuthorizedFinanceRole}
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_INSURANCE.INSURANCE,
							)}
						/>
					</StyledFormField>
				</StyledGrid>
			</StyledGridContainer>
			<StyledFormField>
				<FileUploadField
					isTile
					label={t('Signed insurance policy')}
					value={values.signedPolicyDocument}
					name="signedPolicyDocument"
					displayValue={values.signedPolicyDocumentName}
					fileName="Document"
					acceptedTypes={[FILE_TYPES.PDF]}
					documents={project?.insurance?.signedPolicyDocuments}
					onDocumentDownload={setFieldValue}
					breadcrumbList={breadcrumbList}
					setFieldValue={setFieldValue}
					isDisabled={isAuthorizedFinanceRole}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_INSURANCE.INSURANCE,
					)}
				/>
			</StyledFormField>
			<FormField>
				<Title>{t('Insurance payment')}</Title>
				<Grid container>
					<Grid item lg={12} xl={6}>
						<Label label={'Insurance premium payment verification'} color={colors.grey.main} />
						<StyledFormField>
							<FormikCheckbox
								label={t('The insurance premium has been paid')}
								id="paymentVerification"
								name="paymentVerification"
								checked={values.paymentVerification}
								setFieldValue={setFieldValue}
								onChange={handleChange}
								onBlur={handleBlur}
								isDisabled={isAuthorizedithoutdFinanceRole}
								touched={touched.paymentVerification}
							/>
						</StyledFormField>
					</Grid>

					<Grid item lg={12} xl={6}>
						<StyledFormField>
							<BlockSectionsTitle>{t('Insurance validity')}</BlockSectionsTitle>
							<BlocksContainer>
								<BlockerPill
									id="validityStatus"
									name="validityStatus"
									isBlocker
									touched={touched.validityStatus}
									setFieldValue={setFieldValue}
								>
									{insuranceValidityStatusOptions.find(str => str.value === values.validityStatus).label}
								</BlockerPill>
							</BlocksContainer>
						</StyledFormField>
					</Grid>
				</Grid>
				<StyledFormField>
					<FileUploadField
						isTile
						label={t('Insurance payslip')}
						value={values.payslipDocument}
						name="payslipDocument"
						displayValue={values.payslipDocumentName}
						fileName="Document"
						acceptedTypes={[FILE_TYPES.PDF]}
						documents={project?.insurance?.payslipDocuments}
						onDocumentDownload={handleDocumentDownload}
						breadcrumbList={breadcrumbList}
						setFieldValue={setFieldValue}
						isDisabled={isAuthorizedithoutdFinanceRole}
					/>
				</StyledFormField>
			</FormField>
		</EditableTile>
	);
};

InsuranceTile.defaultProps = {
	project: {},
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	onTileSave: async () => {},
	highlightLabels: [],
	setFieldValue: () => {},
	breadcrumbList: [],
	setFieldTouched: () => {},
	values: {},
	errors: {},
	touched: {},
};

InsuranceTile.propTypes = {
	values: PropTypes.shape({
		insuredCurrency: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
		policyCurrency: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
		paymentVerification: PropTypes.bool,
		highlightLabels: PropTypes.arrayOf(PropTypes.shape({})),
		formDocumentName: PropTypes.string,
		signedPolicyDocumentName: PropTypes.string,
		payslipDocumentName: PropTypes.string,
		formDocumentDisplayDocuments: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
			}),
		),
		signedPolicyDocumentDisplayDocuments: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
			}),
		),
		payslipDocumentDisplayDocuments: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
			}),
		),
		breadcrumbList: PropTypes.arrayOf(
			PropTypes.shape({ label: PropTypes.string.isRequired, href: PropTypes.string }),
		),
	}),
	errors: PropTypes.shape({
		paymentVerification: PropTypes.string,
		insuredCurrency: PropTypes.string,
		policyCurrency: PropTypes.string,
		breadcrumbList: PropTypes.string,
	}),
	touched: PropTypes.shape({
		paymentVerification: PropTypes.bool,
		insuredCurrency: PropTypes.shape({ label: PropTypes.bool, value: PropTypes.bool }),
		policyCurrency: PropTypes.shape({ label: PropTypes.bool, value: PropTypes.bool }),
		breadcrumbList: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
	}),
	onDirtyForm: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onEnterEditMode: PropTypes.func,
	onTileSave: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
	breadcrumbList: PropTypes.arrayOf(PropTypes.string),
	project: PropTypes.shape({
		insurance: PropTypes.arrayOf(),
		country: PropTypes.arrayOf(PropTypes.shape({})),
		insuredCurrency: PropTypes.arrayOf(PropTypes.shape({})),
		policyCurrency: PropTypes.arrayOf(PropTypes.shape({})),
		epcContract: PropTypes.arrayOf(),
	}),
};

export default InsuranceTile;
