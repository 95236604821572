import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import { orderBy, pick, uniqBy } from 'lodash';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import styled from 'styled-components/macro';

import editContact from 'Contacts/api/editContact';
import createContact from 'Contacts/api/createContact';

import { userStates } from 'EpcPartner/constants/userStates';

import notify from 'Common/utils/notify';
import EditableTile from 'Common/components/Tile/EditableTile';
import ExternalLink from 'Common/components/ExternalLink';
import TableInput from 'Common/components/form/Table/TableInput';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import TableCheckbox from 'Common/components/form/Table/TableCheckbox';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import { useEditableRow } from 'Common/hooks/table/plugin-hooks/useEditableRow';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import { useStickyColumns } from 'Common/hooks/table/plugin-hooks/useStickyColumns';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import {
	DefaultTable,
	TableBodyGroupCell,
	TableBody,
	TableHeadCell,
	TableBodyCell,
} from 'Common/components/table/table';
import colors from 'Application/theme/colors';
import { useCustomRowStyle } from 'Common/hooks/table/plugin-hooks/useCustomRowStyle';
import showToastError from 'Common/utils/showToastError';
import ProjectInfoPopup from 'Dashboard/components/ProjectInfoPopup/ProjectInfoPopup';
import { PropTypes } from 'prop-types';
import useAbortController from 'Common/hooks/useAbortController';
import Icon from 'Common/components/icons/Icon';
import sizes from 'Application/theme/sizes';
import isAssignInvoicingContactOptionDisabled from 'Client/components/ClientDetails/Tabs/ContactsTab/ContactsList/utils/isAssignInvoicingContactOptionDisabled';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	@media (min-width: 1330px) {
		${TableBody} {
			overflow: hidden !important;
		}
	}

	${TableHeadCell}:not(${TableBodyGroupCell}) {
		background: transparent;
	}
	${TableBodyCell} {
		background: transparent;
	}
`;

const FirstNameField = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${sizes.spacing(1)};
	align-items: center;
`;

const ProjectNameContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${sizes.spacing(1)};
`;

const ContactsList = ({
	contacts,
	clientId,
	onSubmit,
	invoicingProjectsContacts,
	onAssignProject,
	onAssignInvoicingContact,
	generalInvocingContactId,
	onAssignGeneralInvoicingContact,
	onContactDeletion,
	onUserAccountEdit,
	onExpellContact,
}) => {
	const { t } = useTranslation();

	const abortController = useAbortController();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('First name'),
				accessor: 'firstName',
				customCellContentStyles: {
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
					display: 'block',
				},
				customBodyCellStyles: {
					height: '53px',
				},
				Cell: (() => {
					const Cell = ({ row }) => (
						<FirstNameField>
							{row.original.firstName}
							{row.original.isInvoicingContact && <Icon icon="invoicingIcon" color={colors.primary.dark} />}
						</FirstNameField>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								firstName: PropTypes.string,
								isInvoicingContact: PropTypes.bool,
							}),
							getTurnOnRowEditModeProps: PropTypes.func,
						}).isRequired,
					};
					return Cell;
				})(),
				EditableField: (() => {
					const EditableField = ({ formik: { values, errors, touched, handleChange, handleBlur } }) => (
						<TableInput
							id="firstName"
							name="firstName"
							value={values.firstName}
							error={errors.firstName}
							touched={touched.firstName}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					);
					EditableField.propTypes = {
						formik: PropTypes.shape({
							values: PropTypes.shape({
								firstName: PropTypes.string,
							}),
							errors: PropTypes.shape({
								firstName: PropTypes.string,
							}),
							touched: PropTypes.shape({
								firstName: PropTypes.bool,
							}),
							handleChange: PropTypes.func,
							handleBlur: PropTypes.func,
						}).isRequired,
					};
					return EditableField;
				})(),
			},
			{
				Header: t('Last name'),
				accessor: 'lastName',
				customCellContentStyles: {
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
					display: 'block',
				},
				customBodyCellStyles: {
					height: '53px',
				},
				EditableField: (() => {
					const EditableField = ({ formik: { values, errors, touched, handleChange, handleBlur } }) => (
						<TableInput
							id="lastName"
							name="lastName"
							value={values.lastName}
							error={errors.lastName}
							touched={touched.lastName}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					);
					EditableField.propTypes = {
						formik: PropTypes.shape({
							values: PropTypes.shape({
								lastName: PropTypes.string,
							}),
							errors: PropTypes.shape({
								lastName: PropTypes.string,
							}),
							touched: PropTypes.shape({
								lastName: PropTypes.bool,
							}),
							handleChange: PropTypes.func,
							handleBlur: PropTypes.func,
						}).isRequired,
					};
					return EditableField;
				})(),
			},
			{
				Header: t('Position'),
				accessor: 'position',
				customCellContentStyles: {
					textOverflow: 'ellipsis',
					display: 'block',
				},
				customBodyCellStyles: {
					height: '53px',
				},
				EditableField: (() => {
					const EditableField = ({ formik: { values, errors, touched, handleChange, handleBlur } }) => (
						<TableInput
							id="position"
							name="position"
							value={values.position}
							error={errors.position}
							touched={touched.position}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					);
					EditableField.propTypes = {
						formik: PropTypes.shape({
							values: PropTypes.shape({
								position: PropTypes.string,
							}),
							errors: PropTypes.shape({
								position: PropTypes.string,
							}),
							touched: PropTypes.shape({
								position: PropTypes.bool,
							}),
							handleChange: PropTypes.func,
							handleBlur: PropTypes.func,
						}).isRequired,
					};
					return EditableField;
				})(),
			},
			{
				Header: t('E-mail'),
				accessor: 'email',
				customCellContentStyles: {
					textOverflow: 'ellipsis',
					display: 'block',
				},
				customBodyCellStyles: {
					height: '53px',
				},
				EditableField: (() => {
					const EditableField = ({ formik: { values, errors, touched, handleChange, handleBlur } }) => (
						<TableInput
							id="email"
							name="email"
							value={values.email}
							error={errors.email}
							touched={touched.email}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					);
					EditableField.propTypes = {
						formik: PropTypes.shape({
							values: PropTypes.shape({
								email: PropTypes.string,
							}),
							errors: PropTypes.shape({
								email: PropTypes.string,
							}),
							touched: PropTypes.shape({
								email: PropTypes.bool,
							}),
							handleChange: PropTypes.func,
							handleBlur: PropTypes.func,
						}).isRequired,
					};
					return EditableField;
				})(),
				Cell: (() => {
					const Cell = ({ row }) => (
						<ExternalLink location={`mailto:${row.original.email}`}>{row.original.email}</ExternalLink>
					);
					Cell.propTypes = {
						row: PropTypes.shape({ original: PropTypes.shape({ email: PropTypes.string }) }).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Phone'),
				accessor: 'phone',
				customCellContentStyles: {
					textOverflow: 'ellipsis',
					display: 'block',
				},
				customBodyCellStyles: {
					height: '53px',
				},
				EditableField: (() => {
					const EditableField = ({ formik: { values, errors, touched, handleChange, handleBlur } }) => (
						<TableInput
							id="phone"
							name="phone"
							value={values.phone}
							error={errors.phone}
							touched={touched.phone}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					);
					EditableField.propTypes = {
						formik: PropTypes.shape({
							values: PropTypes.shape({
								phone: PropTypes.string,
							}),
							errors: PropTypes.shape({
								phone: PropTypes.string,
							}),
							touched: PropTypes.shape({
								phone: PropTypes.bool,
							}),
							handleChange: PropTypes.func,
							handleBlur: PropTypes.func,
						}).isRequired,
					};
					return EditableField;
				})(),
				Cell: (() => {
					const Cell = ({ row }) => (
						<ExternalLink location={`tel:${row.original.phone}`}>{row.original.phoneAndExt}</ExternalLink>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ phone: PropTypes.string, phoneAndExt: PropTypes.string }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Comment'),
				accessor: 'comment',
				customCellContentStyles: {
					textOverflow: 'ellipsis',
					display: 'block',
				},
				customBodyCellStyles: {
					height: '53px',
				},
				EditableField: (() => {
					const EditableField = ({ formik: { values, errors, touched, handleChange, handleBlur } }) => (
						<TableInput
							id="comment"
							name="comment"
							value={values.comment}
							error={errors.comment}
							touched={touched.comment}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
					);
					EditableField.propTypes = {
						formik: PropTypes.shape({
							values: PropTypes.shape({
								comment: PropTypes.string,
							}),
							errors: PropTypes.shape({
								comment: PropTypes.string,
							}),
							touched: PropTypes.shape({
								comment: PropTypes.bool,
							}),
							handleChange: PropTypes.func,
							handleBlur: PropTypes.func,
						}).isRequired,
					};
					return EditableField;
				})(),
			},
			{
				Header: t('Portal status'),
				accessor: 'invited',
				customBodyCellStyles: {
					height: '53px',
				},
				EditableField: (() => {
					const EditableField = ({ formik: { values, touched, handleChange, handleBlur } }) => (
						<TableCheckbox
							id="inviteToPortal"
							name="inviteToPortal"
							label="Invite"
							checked={values.inviteToPortal}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.inviteToPortal}
							// isDisabled // DISABLED UNTIL CLIENT CONTACT ROLES ARE ADDED
						/>
					);
					EditableField.propTypes = {
						formik: PropTypes.shape({
							values: PropTypes.shape({
								inviteToPortal: PropTypes.bool,
							}),
							touched: PropTypes.shape({
								inviteToPortal: PropTypes.bool,
							}),
							handleChange: PropTypes.func,
							handleBlur: PropTypes.func,
						}).isRequired,
					};
					return EditableField;
				})(),
			},
			{
				Header: '',
				accessor: 'actions',
				maxWidth: 30,
				sticky: true,
				customCellStyles: {
					background: `${colors.common.tileBackgroundGrey}!important`,
				},
				customHeaderCellStyles: {
					background: 'transparent',
				},
				customBodyCellStyles: {
					height: '53px',
				},
				EditableField: <></>,
				Cell: (() => {
					const Cell = ({ row }) =>
						!row.original.markAsExpelled ? (
							<MoreActionsButton label="Clients Contacts More Actions" controlledVisibility>
								<MenuItem
									type="button"
									data-action="edit"
									{...row.getTurnOnRowEditModeProps()}
									label="Clients Contacts List - Edit Menu Item"
								>
									Edit
								</MenuItem>
								<MenuItem
									type="button"
									data-action="assignToProject"
									onClick={() => onAssignProject(row.original.contactId)}
									label="Clients Contacts List - Assign to Project Menu Item"
								>
									Assign to Project
								</MenuItem>
								<MenuItem
									type="button"
									data-action="onAssignInvoicingContact"
									onClick={() => onAssignInvoicingContact(row.original)}
									label="Clients Contacts List - Assign as Invoicing contact"
									disabled={isAssignInvoicingContactOptionDisabled(
										row.original,
										Object.keys(invoicingProjectsContacts),
									)}
								>
									{row.original.isInvoicingContact ? 'Unassign Invoicing contact' : 'Assign as Invoicing contact'}
								</MenuItem>
								<MenuItem
									type="button"
									data-action="onAssignInvoicingContactToAllProjects"
									onClick={() => onAssignGeneralInvoicingContact(row.original)}
									label="Clients Contacts List - Assign as Invoicing contact"
									disabled={generalInvocingContactId === row.original.contactId}
								>
									Assign as Invoicing to all projects
								</MenuItem>
								<MenuItem
									type="button"
									data-action="editUserAccount"
									disabled={row.original.invited !== 'Invited'}
									onClick={() => onUserAccountEdit(row.original.email)}
									label="Clients Contacts List - Edit User Account Menu Item"
								>
									Edit User account
								</MenuItem>
								<MenuItem
									type="button"
									data-action="expelUserAccount"
									onClick={() => onExpellContact(row.original.contactId)}
									label="Clients Contacts List - Mark as Expelled Menu Item"
								>
									Mark as expelled
								</MenuItem>
								<MenuItem
									type="button"
									data-action="delete"
									onClick={() =>
										onContactDeletion({
											firstName: row.original.firstName,
											lastName: row.original.lastName,
											id: row.original.contactId,
										})
									}
									label="Clients Contacts List - Delete Menu Item"
								>
									Delete
								</MenuItem>
							</MoreActionsButton>
						) : (
							<MoreActionsButton label="Clients Contacts More Actions" controlledVisibility>
								<MenuItem
									type="button"
									data-action="delete"
									onClick={() =>
										onContactDeletion({
											firstName: row.original.firstName,
											lastName: row.original.lastName,
											id: row.original.contactId,
										})
									}
									label="Clients Contacts List - Delete Menu Item"
								>
									Delete
								</MenuItem>
							</MoreActionsButton>
						);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								firstName: PropTypes.string,
								lastName: PropTypes.string,
								contactId: PropTypes.string,
								email: PropTypes.string,
								invited: PropTypes.string,
								markAsExpelled: PropTypes.bool,
								projectId: PropTypes.number,
								isInvoicingContact: PropTypes.bool,
							}),
							getTurnOnRowEditModeProps: PropTypes.func,
						}).isRequired,
					};
					return Cell;
				})(),
			},
		],
		[
			t,
			invoicingProjectsContacts,
			generalInvocingContactId,
			onAssignProject,
			onAssignInvoicingContact,
			onAssignGeneralInvoicingContact,
			onUserAccountEdit,
			onExpellContact,
			onContactDeletion,
		],
	);

	const data = useMemo(
		() =>
			orderBy(contacts, ['firstName'], ['asc']).map(contact => ({
				...contact,
				id: `${contact.id}_${contact.projectId}`,
				phoneAndExt: `${contact.phone}${contact.ext ? ',' + contact.ext : ''}`,
				invited:
					contact.userState === userStates.ACTIVE
						? 'Invited'
						: contact.markAsExpelled
						? 'Expelled'
						: 'Not invited',
				contactId: String(contact.id),
				$rowDisabled: contact.markAsExpelled,
				bodyGroupIds: [contact.projectExternalId || 'general'],
				isInvoicingContact: contact.projectContacts?.find(el => el.contactId === contact.id)?.invoicingContact,
			})),
		[contacts],
	);

	const categories = useMemo(
		() =>
			uniqBy(
				[
					{ id: 'general', name: 'General' },
					...(
						data?.map(item => {
							const hasInvocingContact = Object.keys(invoicingProjectsContacts).includes(String(item.projectId));

							return {
								id: item.projectExternalId,
								name: (
									<ProjectNameContainer>
										{String(item.projectExternalId).padStart(5, '0')}
										{!hasInvocingContact && (
											<HoverTooltip placement="right" title="Missing contact for invoicing">
												<Icon icon="reportProblem" color={colors.warning.main} />
											</HoverTooltip>
										)}
									</ProjectNameContainer>
								),
								linkTo: `/projects/details/${item.projectId}`,
								Popup: () => <ProjectInfoPopup projectId={item.projectId} $isInTable disableUserInfoHover />,
							};
						}) || []
					).filter(item => !isNaN(item.id)),
				],
				'id',
			),
		[data, invoicingProjectsContacts],
	);

	const getEditableRowInitialValues = useCallback(
		row =>
			pick(row.original, [
				'firstName',
				'lastName',
				'email',
				'phone',
				'ext',
				'contactId',
				'position',
				'inviteToPortal',
				'comment',
			]),
		[],
	);

	const editableRowFormikOptions = useMemo(
		() => ({
			validationSchema: yup.object({
				firstName: yup.string().required(t('Required')),
				lastName: yup.string().required(t('Required')),
				inviteToPortal: yup.bool(),
				email: yup.string().when('inviteToPortal', {
					is: true,
					then: yup.string().email('Wrong email format').required(t('Required')),
					otherwise: yup.string().email('Wrong email format'),
				}),
			}),
			onSubmit: async (row, values) => {
				try {
					let response;
					if (row.isNew) {
						response = await createContact(abortController.signal, {
							...values,
							email: values.email || null,
							clientId: clientId,
							markAsExpelled: false,
						});
						notify(t('Contact created successfully'), {
							type: toast.TYPE.SUCCESS,
						});
					} else {
						response = await editContact(abortController.signal, {
							...values,
							email: values.email || null,
							id: values.contactId,
							type: 'CLIENT',
							markAsExpelled: row.original.markAsExpelled,
						});
						notify(t('Contact saved successfully'), {
							type: toast.TYPE.SUCCESS,
						});
					}

					onSubmit(response.data, row.isNew);
				} catch (error) {
					showToastError(error, row.isNew ? t("Can't create contact") : t("Can't edit contact"));
					return 'error';
				}
			},
		}),
		[t, clientId, onSubmit, abortController.signal],
	);

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		addRowForCreation,
	} = useStandardTable(
		{
			data,
			columns,
			getEditableRowInitialValues,
			editableRowFormikOptions,
			newRowData: {
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				phoneExt: '',
				position: '',
				comment: '',
				inviteToPortal: false,
			},
			getCustomRowStyles: () => ({
				height: 'auto',
			}),
		},
		useCustomCellStyles,
		useStickyColumns,
		useEditableRow,
		useCustomRowStyle,
	);

	return (
		<EditableTile
			title={t('Contacts')}
			rightComponent={<TileActionButton onClick={addRowForCreation} />}
			isTable
		>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					prepareRow={prepareRow}
					headerGroups={headerGroups}
					editableRowFormikOptions={editableRowFormikOptions}
					bodyGroups={categories}
				/>
			</TableWrapper>
		</EditableTile>
	);
};

ContactsList.propTypes = {
	contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	clientId: PropTypes.number.isRequired,
	onSubmit: PropTypes.func.isRequired,
	invoicingProjectsContacts: PropTypes.shape({ [PropTypes.number]: PropTypes.number }).isRequired,
	generalInvocingContactId: PropTypes.number.isRequired,
	onAssignGeneralInvoicingContact: PropTypes.func.isRequired,
	onAssignProject: PropTypes.func.isRequired,
	onAssignInvoicingContact: PropTypes.func.isRequired,
	onExpellContact: PropTypes.func.isRequired,
	onContactDeletion: PropTypes.func.isRequired,
	onUserAccountEdit: PropTypes.func.isRequired,
};

export default ContactsList;
