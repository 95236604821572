import ContentLoader from 'react-content-loader';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

import { css } from 'styled-components';

const Content = styled.div`
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;

	${({ $noPadding }) =>
		!$noPadding &&
		css`
			padding: 12px 16px;
		`}
`;

const SidePanelContent = ({ content, isLoading, ...props }) => (
	<Content {...props}>
		{isLoading ? (
			<>
				<ContentLoader
					width="100%"
					height="100px"
					backgroundColor={colors.common.lightGrey}
					foregroundColor={colors.common.headerGrey}
				>
					<circle cx="20" cy="20" r="20" />
					<rect x="56" y="10" rx="10" ry="10" width="100" height="20" />
					<rect x="172" y="10" rx="10" ry="10" width="62" height="20" />
					<rect x="0" y="50" rx="10" ry="10" width="435" height="20" />
				</ContentLoader>
				<ContentLoader
					width="100%"
					height="100px"
					backgroundColor={colors.common.lightGrey}
					foregroundColor={colors.common.headerGrey}
					backgroundOpacity={0.8}
					foregroundOpacity={0.8}
				>
					<circle cx="20" cy="20" r="20" />
					<rect x="56" y="10" rx="10" ry="10" width="100" height="20" />
					<rect x="172" y="10" rx="10" ry="10" width="62" height="20" />
					<rect x="0" y="50" rx="10" ry="10" width="435" height="20" />
				</ContentLoader>
				<ContentLoader
					width="100%"
					height="100px"
					backgroundColor={colors.common.lightGrey}
					foregroundColor={colors.common.headerGrey}
					backgroundOpacity={0.6}
					foregroundOpacity={0.6}
				>
					<circle cx="20" cy="20" r="20" />
					<rect x="56" y="10" rx="10" ry="10" width="100" height="20" />
					<rect x="172" y="10" rx="10" ry="10" width="62" height="20" />
					<rect x="0" y="50" rx="10" ry="10" width="435" height="20" />
				</ContentLoader>
				<ContentLoader
					width="100%"
					height="100px"
					backgroundColor={colors.common.lightGrey}
					foregroundColor={colors.common.headerGrey}
					backgroundOpacity={0.4}
					foregroundOpacity={0.4}
				>
					<circle cx="20" cy="20" r="20" />
					<rect x="56" y="10" rx="10" ry="10" width="100" height="20" />
					<rect x="172" y="10" rx="10" ry="10" width="62" height="20" />
					<rect x="0" y="50" rx="10" ry="10" width="435" height="20" />
				</ContentLoader>
				<ContentLoader
					width="100%"
					height="100px"
					backgroundColor={colors.common.lightGrey}
					foregroundColor={colors.common.headerGrey}
					backgroundOpacity={0.2}
					foregroundOpacity={0.2}
				>
					<circle cx="20" cy="20" r="20" />
					<rect x="56" y="10" rx="10" ry="10" width="100" height="20" />
					<rect x="172" y="10" rx="10" ry="10" width="62" height="20" />
					<rect x="0" y="50" rx="10" ry="10" width="435" height="20" />
				</ContentLoader>
			</>
		) : (
			content
		)}
	</Content>
);

SidePanelContent.defaultProps = {
	content: null,
};

SidePanelContent.propTypes = {
	content: PropTypes.node,
	isLoading: PropTypes.bool.isRequired,
};

export default SidePanelContent;
