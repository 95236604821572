const sanitizeAndPullDirtyFromQuery = (allowedProperties, filters) => {
	const filterProps = Object.keys(filters);

	const { dirtyProperties, cleanProperties } = filterProps.reduce(
		(acc, filterProp) => {
			const isDirty = !allowedProperties.includes(filterProp);
			const selectedArray = isDirty ? 'dirtyProperties' : 'cleanProperties';

			return {
				...acc,
				[selectedArray]: [...acc[selectedArray], filterProp],
			};
		},
		{ dirtyProperties: [], cleanProperties: [] },
	);

	const sanitizedQueryData = cleanProperties.reduce((acc, key) => ({ ...acc, [key]: filters[key] }), {});

	return { dirtyProperties, sanitizedQueryData };
};

export default sanitizeAndPullDirtyFromQuery;
