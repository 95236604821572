import { useMemo } from 'react';
import { pick } from 'lodash';

const fallbackValues = {
	locationUrl: '',
	country: null,
	gridEmissionFactor: '',
};

const useLocationFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['locationUrl']),
			country: data.country
				? {
						value: data.country.id,
						label: data.country.name,
				  }
				: null,
			gridEmissionFactor: data.gridEmissionFactor ? data.gridEmissionFactor : data?.country?.gridEmissionFactor,
			locationText: data.latitude && data.longitude ? `${data.latitude}, ${data.longitude}` : '',
		}),
		[data],
	);

export default useLocationFormInitialValues;
