// TODO remove the eslint complexity and fix it
/* eslint-disable complexity */
import { CircularProgress, Button as MaterialButton, Badge } from '@mui/material';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

import Icon from 'Common/components/icons/Icon';
import customGAEvent from 'Common/utils/customGAEvent';
import baseButtonStyles from 'Common/components/buttons/baseButtonStyles';

const StyledButton = styled(MaterialButton)`
	&.MuiButton-root {
		${baseButtonStyles}
	}
`;

const Button = ({
	type,
	text,
	icon,
	count,
	small,
	label,
	medium,
	active,
	overlay,
	onClick,
	disabled,
	ariaLabel,
	isLoading,
	buttonRef,
	secondary: isCancelBtn,
	dataAction,
	ariaHidden,
	showDotBadge,
	ariaHaspopup,
	noBackground,
}) => {
	return (
		<Badge
			badgeContent={count}
			color="error"
			overlap="circular"
			{...(showDotBadge
				? {
						variant: 'dot',
						invisible: false,
				  }
				: {})}
		>
			<StyledButton
				type={type}
				$count={count}
				ref={buttonRef}
				onClick={(...args) => {
					customGAEvent('button', 'click', label);
					typeof onClick === 'function' && onClick(...args);
				}}
				$icon={icon}
				aria-label={ariaLabel}
				$small={small}
				data-action={dataAction}
				aria-hidden={ariaHidden}
				$medium={medium}
				$active={active}
				$overlay={overlay}
				aria-haspopup={ariaHaspopup}
				$isLoading={isLoading}
				$secondary={isCancelBtn}
				$noBackground={noBackground}
				disabled={isLoading ? true : disabled ? true : false}
				$textLength={small || medium ? 0 : text ? text.length : 0}
			>
				{icon ? (
					isLoading ? (
						<CircularProgress
							size={small || medium ? 18 : 15}
							style={{ color: colors.primary.main, marginRight: small || medium ? 0 : 12 }}
						/>
					) : (
						<Icon icon={icon} />
					)
				) : null}
				{text && isLoading && !icon ? (
					<CircularProgress style={{ color: colors.primary.main }} size={small || medium ? 18 : 15} />
				) : (
					text
				)}
			</StyledButton>
		</Badge>
	);
};

Button.defaultProps = {
	count: 0,
	text: null,
	icon: null,
	small: false,
	medium: false,
	active: false,
	onClick: null,
	type: 'button',
	dataAction: '',
	overlay: false,
	label: 'Button',
	disabled: false,
	buttonRef: null,
	ariaLabel: null,
	isLoading: false,
	secondary: false,
	ariaHidden: null,
	ariaHaspopup: null,
	showDotBadge: false,
	noBackground: false,
};

Button.propTypes = {
	small: PropTypes.bool,
	active: PropTypes.bool,
	medium: PropTypes.bool,
	type: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
	icon: PropTypes.string,
	count: PropTypes.number,
	label: PropTypes.string,
	overlay: PropTypes.bool,
	onClick: PropTypes.func,
	buttonRef: PropTypes.shape({}),
	disabled: PropTypes.bool,
	isLoading: PropTypes.bool,
	secondary: PropTypes.bool,
	ariaLabel: PropTypes.string,
	dataAction: PropTypes.string,
	ariaHidden: PropTypes.string,
	noBackground: PropTypes.bool,
	showDotBadge: PropTypes.bool,
	ariaHaspopup: PropTypes.string,
};

export default Button;
