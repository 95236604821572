import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import PropTypes from 'prop-types';

import { Popper, Grow, Paper as MaterialPaper, ClickAwayListener } from '@mui/material';
import StatusPill from 'Common/components/StatusPill';
import FilterDropdown from 'Common/components/filter/FilterDropdown';

const Wrapper = styled.div`
	display: flex;
`;

const SystemsButton = styled.div`
	cursor: pointer;
`;

const Paper = styled(MaterialPaper)`
	border-radius: 6px;
	overflow: hidden;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15) !important;
`;

const SystemsSelector = ({ systems, setSelectedSystems, selectedSystems, count }) => {
	const { t } = useTranslation();
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleSetFieldValue = newValue => {
		setSelectedSystems(systems => {
			if (systems.includes(newValue)) {
				return systems.filter(system => system !== newValue);
			}
			return [...systems, newValue];
		});
	};

	const handleClose = e => {
		if (anchorRef.current && anchorRef.current.contains(e.target)) {
			return;
		}
		setOpen(false);
	};

	return (
		<Wrapper>
			<SystemsButton ref={anchorRef} onClick={handleToggle}>
				<StatusPill color={colors.primary.dark}>
					{count && count > 0 ? `${t('Systems')} (${count})` : t('Systems')}
				</StatusPill>
			</SystemsButton>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				placement="bottom-end"
				style={{
					zIndex: 9999,
				}}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom-end' ? 'right top' : 'right bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<div>
									<FilterDropdown
										selectedOptions={selectedSystems}
										options={systems}
										handleOptionClick={handleSetFieldValue}
										hasFocus={open}
										disableSearch
										disableClearAll
									/>
								</div>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Wrapper>
	);
};

SystemsSelector.defaultProps = {
	systems: [],
	setSelectedSystems: () => {},
	selectedSystems: [],
	count: 0,
};

SystemsSelector.propTypes = {
	systems: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		}),
	),
	setSelectedSystems: PropTypes.func,
	selectedSystems: PropTypes.arrayOf(PropTypes.string),
	count: PropTypes.number,
};

export default SystemsSelector;
