import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import loadCountryOptions from 'Country/utils/loadCountryOptions';
import { FILTER_TYPES } from 'Common/components/filter/constants/filterConstants';
import useEcoligoUsersOptions from 'UserManagement/hooks/useEcoligoUsersOptions';

const useEntitiesFilterData = () => {
	const { t } = useTranslation();

	const { ecoligoUsersOptions: directorsOptions } = useEcoligoUsersOptions();

	const filtersData = useMemo(
		() => [
			{
				name: 'name',
				label: t('Company name'),
				type: FILTER_TYPES.INPUT,
			},
			{
				name: 'countriesIds',
				label: t('Country'),
				type: FILTER_TYPES.ASYNC_SELECT,
				loadOptions: loadCountryOptions,
			},
			{
				name: 'abbreviation',
				label: t('Abbreviation'),
				type: FILTER_TYPES.INPUT,
			},
			{
				name: 'dateOfRegistration',
				label: t('Date of registration'),
				type: FILTER_TYPES.DATE,
				startDateName: 'dateOfRegistrationFrom',
				endDateName: 'dateOfRegistrationTo',
			},
			{
				name: 'directorsEmails',
				label: t('Directors'),
				type: FILTER_TYPES.SELECT,
				options: directorsOptions,
			},
			{
				name: 'registrationNumber',
				label: t('Registration number'),
				type: FILTER_TYPES.INPUT,
			},
			{
				name: 'taxNumber',
				label: t('Tax number'),
				type: FILTER_TYPES.INPUT,
			},
			{
				name: 'vatNumber',
				label: t('VAT number'),
				type: FILTER_TYPES.INPUT,
			},
		],
		[t, directorsOptions],
	);

	return { filtersData };
};

export default useEntitiesFilterData;
