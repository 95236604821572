import api from 'Application/api/api';

const downloadInvoice = async (signal, fileName) => {
	const response = await api.get('/invoices/download-link', {
		params: {
			fileName,
		},
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return response;
};

export default downloadInvoice;
