import PropTypes from 'prop-types';

import Link from './Link';

const ExternalLink = ({ location, children, isDisabled }) => (
	<Link
		to="#"
		disabled={isDisabled}
		color="#00b4d5"
		onClick={e => {
			if (!isDisabled) {
				window.location = location;
			}
			e.preventDefault();
		}}
	>
		{children}
	</Link>
);

ExternalLink.defaultProps = {
	location: '',
	children: null,
	isDisabled: false,
};

ExternalLink.propTypes = {
	children: PropTypes.node,
	location: PropTypes.string,
	isDisabled: PropTypes.bool,
};

export default ExternalLink;
