import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ContactsList from 'EpcPartner/components/EpcPartnerDetails/Tabs/ContactsTab/Tiles/ContactsTile/ContactsList/ContactsList';
import { omit } from 'lodash';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import deleteContact from 'Contacts/api/deleteContacts';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import showToastError from 'Common/utils/showToastError';
import getEpcPartnerContactsById from 'EpcPartner/api/getEpcPartnerContactsById';
import AssignToProjectModal from 'Contacts/components/AssignToProjectForm/AssignToProjectModal';
import useAbortController from 'Common/hooks/useAbortController';

const ContactsTile = ({ epcPartner }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [contacts, setContacts] = useState([]);
	const [contactIdForAssigning, setContactIdForAssigning] = useState(null);
	const [contactForDeletion, setContactForDeletion] = useState(null);
	const [shouldUpdate, setShouldUpdate] = useState(true);
	// ? We need to store the fetched epc partner id so we can refetch the contacts when the epc partner changes
	const epcPartnerId = useRef();

	const abortController = useAbortController();

	useEffect(() => {
		if ((shouldUpdate && epcPartner.id) || epcPartner.id !== epcPartnerId.current) {
			(async () => {
				try {
					const response = await getEpcPartnerContactsById(abortController.signal, epcPartner.id);
					setContacts(response.data);
					epcPartnerId.current = epcPartner.id;
					setShouldUpdate(false);
				} catch (e) {
					showToastError(e, t("Can't fetch SaaS contracts"));
				}
			})();
		}
	}, [epcPartner, shouldUpdate, abortController.signal, t]);

	const handleListDeleteContact = contact => {
		setContactForDeletion(contact);
	};

	const handleContactDelete = async contact => {
		try {
			await deleteContact(abortController.signal, contact.id);
			setContacts(prev => prev.filter(e => e.id !== contact.id));
			setContactForDeletion(null);
			notify(t(`Contact ${contact.firstName} ${contact.lastName} deleted successfully`), {
				type: toast.TYPE.SUCCESS,
			});
		} catch (error) {
			showToastError(error, t("Can't delete contact"));
		}
	};

	const handleContactUpdate = (data, isCreate) => {
		setContacts(prev => {
			let currentContacts = [...prev];
			if (isCreate) {
				return [...currentContacts, data];
			} else {
				return currentContacts.map(contact => {
					if (contact.id === data.id) {
						return {
							...contact,
							...omit(data, ['projectExternalId', 'projectId']),
						};
					} else {
						return contact;
					}
				});
			}
		});
	};

	const handleContactExpell = data => {
		setContacts(prev => [...prev.filter(e => e.id !== data.id), data]);
	};

	const handleOpenUserEdit = email => dispatch(push(`/users/edit/${email}`));

	const handleAssignSubmit = () => setShouldUpdate(true);

	return (
		<>
			<ContactsList
				contacts={contacts}
				epcPartnerId={epcPartner.id}
				onSubmit={handleContactUpdate}
				openAssignModal={setContactIdForAssigning}
				deleteContact={handleListDeleteContact}
				openUserEdit={handleOpenUserEdit}
				updateContacts={handleContactExpell}
			/>
			{contactIdForAssigning && (
				<AssignToProjectModal
					isOpen={Boolean(contactIdForAssigning)}
					onClose={() => setContactIdForAssigning(null)}
					contactId={String(contactIdForAssigning)}
					onFormSubmit={handleAssignSubmit}
					epcPartnerId={String(epcPartner.id)}
				/>
			)}
			<ConfirmModal
				isOpen={Boolean(contactForDeletion)}
				onCancel={() => setContactForDeletion(null)}
				onConfirm={() => handleContactDelete(contactForDeletion)}
				confirmText="Delete"
				label="delete-contact-confirm"
				heading={t('Delete contact')}
				text={t(
					`Are you sure you want to delete Contact ${contactForDeletion?.firstName} ${contactForDeletion?.lastName}?`,
				)}
			/>
		</>
	);
};

ContactsTile.defaultProps = {
	epcPartner: {},
};

ContactsTile.propTypes = {
	epcPartner: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
};

export default ContactsTile;
