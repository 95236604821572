import { useEffect, useMemo, useState } from 'react';
import useEpcInquiryFormInitialValues from 'EpcPartner/components/EpcPartnerPortal/EpcInquiryCreate/EpcInquiryCreateForm/hooks/useEpcInquiryFormInitialValues';
import useEpcInquiryCreateFormValidationSchema from 'EpcPartner/components/EpcPartnerPortal/EpcInquiryCreate/EpcInquiryCreateForm/hooks/useEpcInquiryCreateFormValidationSchema';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import createEpcInquiry from 'EpcPartner/api/createEpcInquiry';
import editEpcInquiry from 'EpcPartner/api/editEpcInquiry';
import uploadDocsForInquiry from 'EpcPartner/api/uploadDocsForInquiry';

import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import { push } from 'redux-first-history';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';
import convertFormValuesToRequestData from 'EpcPartner/components/EpcPartnerPortal/EpcInquiryCreate/EpcInquiryCreateForm/utils/convertFormValuesToRequestData';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import Grid from '@mui/material/Grid';
import Icon from 'Common/components/icons/Icon';
import Prompt from 'Common/components/Prompt';
import OfferSection from 'EpcPartner/components/EpcPartnerPortal/EpcInquiryCreate/EpcInquiryCreateForm/sections/OfferSection';
import TopSection from 'EpcPartner/components/EpcPartnerPortal/EpcInquiryCreate/EpcInquiryCreateForm/sections/TopSection';
import EpcOfferSection from 'EpcPartner/components/EpcPartnerPortal/EpcInquiryCreate/EpcInquiryCreateForm/sections/EpcOfferSection';
import DocumentationSection from 'EpcPartner/components/EpcPartnerPortal/EpcInquiryCreate/EpcInquiryCreateForm/sections/DocumentationSection';
import crudModes from 'Common/constants/crudModes';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import H1 from 'Common/components/headers/H1';
import H5 from 'Common/components/headers/H5';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';
import YellowMessage from 'Common/components/YellowMessage';
import getContactByEmail from 'Contacts/api/getContactByEmail';
import { fullNameProjectTypes } from 'Projects/constants/projectTypes';
import colors from 'Application/theme/colors';
import deleteEpcInquiry from 'EpcPartner/api/deleteEpcInquiry';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { PropTypes } from 'prop-types';
import loadClientContractTypeOptions from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/ClientContract/utils/loadClientContractTypeOptions';
import EpcInquiryFormHeader from './EpcInquiryFormHeader';

const FormWrapper = styled.div`
	overflow: auto;
`;

const Form = styled.form`
	width: 99%;
	max-width: 1300px;
`;

const Title = styled.div`
	font-size: 18px;
	font-weight: 600;
	margin: 0 20px 20px 0;
	display: flex;
`;

const PaddedGrid = styled(Grid)`
	padding: 16px;
`;

const StyledDeleteModal = styled(ConfirmModal)`
	.confirmBtn {
		background-color: ${colors.error.main};
		&:active,
		&:hover {
			background-color: ${colors.error.light};
		}
		&:focus:not(:active) {
			background-color: ${colors.error.light};
			box-shadow: 0 0 8px 0 ${colors.error.light};
		}
	}
`;

const EpcInquiryForm = ({
	data,
	mode,
	onDataChange,
	isDisabled: propIsDisabled,
	formParent,
	openRespondModal,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const initialValues = useEpcInquiryFormInitialValues(data, mode === crudModes.CREATE);

	const validationSchema = useEpcInquiryCreateFormValidationSchema();

	const [isCreatedByTheUser, setIsCreatedByTheUser] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const currentUser = useSelector(state => state.user);
	const [currencyOptions, setCurrencyOptions] = useState([]);
	const [initialContractTypeOptions, setInitialContractTypeOptions] = useState([]);
	const [contractTypeOptions, setContractTypeOptions] = useState([]);
	const [docsForUpload, setDocsForUpload] = useState(null);
	const [docsForDelete, setDocsForDelete] = useState([]);

	const abortController = useAbortController();

	const isDisabled = useMemo(() => {
		if (propIsDisabled) {
			return true;
		}

		if (data.state === 'DECLINED' || data.state === 'OFFER_CREATED') {
			return true;
		}

		return false;
	}, [data.state, propIsDisabled]);

	const {
		errors,
		touched,
		values,
		handleChange,
		handleBlur,
		handleSubmit,
		dirty,
		setFieldValue,
		setFieldError,
		setFieldTouched,
		resetForm,
		isValid,
		isSubmitting,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			setIsLoading(true);
			try {
				if (mode === crudModes.CREATE) {
					const formData = new FormData();
					if (docsForUpload) {
						Object.keys(docsForUpload).forEach(slot => {
							docsForUpload[slot].forEach(doc => {
								formData.append(slot, doc);
							});
						});
					}

					const uploadData = new Blob(
						[
							JSON.stringify({
								...convertFormValuesToRequestData(values),
							}),
						],
						{
							type: 'application/json',
						},
					);
					formData.append('inquiry', uploadData);

					const response = await createEpcInquiry(abortController.signal, formData);
					dispatch(push(`/epc/inquiries/${response.data.id}`));
				} else {
					const formData = new FormData();
					if (docsForUpload) {
						Object.keys(docsForUpload).forEach(slot => {
							docsForUpload[slot].forEach(doc => {
								formData.append(slot, doc);
							});
						});
					}

					const uploadData = new Blob(
						[
							JSON.stringify({
								...data,
								...convertFormValuesToRequestData(values),
							}),
						],
						{
							type: 'application/json',
						},
					);
					formData.append('inquiry', uploadData);

					const response = await editEpcInquiry(abortController.signal, formData);
					onDataChange({ ...response.data });
					setDocsForUpload(null);

					// Re-set all fields and mark them as not touched
					resetForm({
						values,
					});
				}

				notify(t('Success! Inquiry sent!'), {
					type: toast.TYPE.SUCCESS,
				});
				setShowConfirmModal(false);
				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	const handleDelete = async () => {
		try {
			setIsLoading(true);
			await deleteEpcInquiry(abortController.signal, data.id);
			setIsLoading(false);

			dispatch(push('/epc/inquiries'));
			notify(t('Inquiry deleted succesfully!'), {
				type: toast.TYPE.SUCCESS,
			});
		} catch (err) {
			showToastError(err);
			if (!axios.isCancel(err)) {
				setIsLoading(false);
			}
		}
	};

	useEffect(() => {
		(async () => {
			try {
				let response = await loadClientContractTypeOptions(abortController.signal, undefined, false);
				setInitialContractTypeOptions(response);
				setContractTypeOptions(response);
			} catch (e) {
				showToastError(e, t("Can't load contract types options"));
			}
		})();
	}, [abortController.signal, t]);

	useEffect(() => {
		if (data.createdBy === currentUser.email) setIsCreatedByTheUser(true);
	}, [data.createdBy, currentUser.email]);

	useEffect(() => {
		(async () => {
			values.options.forEach((option, index) => {
				if (option.contractType?.label !== 'Lease' && values?.projectType?.label !== fullNameProjectTypes.PV) {
					setFieldValue(`[options][${index}][contractType]`, null);
				}
			});
			if (values?.projectType?.label !== fullNameProjectTypes.PV) {
				const contractTypes = initialContractTypeOptions.filter(e => e.label === 'Lease');
				setContractTypeOptions(contractTypes);
			} else {
				setContractTypeOptions(initialContractTypeOptions);
			}
		})();
		// eslint-disable-next-line
	}, [values.projectType, setFieldValue]);

	useEffect(() => {
		(async () => {
			try {
				const currencies = await loadCurrenciesOptions(abortController.signal, undefined, false);
				setCurrencyOptions(currencies);
			} catch (e) {
				showToastError(e);
			}
		})();
	}, [abortController.signal]);

	useEffect(() => {
		if (mode === crudModes.CREATE) {
			(async () => {
				try {
					const usdOption = currencyOptions.find(e => e.value === 'USD');
					const response = await getContactByEmail(abortController.signal, currentUser.email, undefined);
					if (response.data.epcPartner.country && usdOption) {
						resetForm({
							values: {
								...values,
								country: {
									value: response.data.epcPartner.country.id,
									label: response.data.epcPartner.country.name,
								},
								tariffCurrency: usdOption,
								offerCurrency: usdOption,
								epcOfferCurrency: usdOption,
							},
						});
					}
				} catch (e) {
					showToastError(e);
				}
			})();
		}
		// Intentionally, don't re-run the effect when the values have changed,
		// so that the useEffect can run only once in the beginning (initial render).
		// eslint-disable-next-line
	}, [currentUser.email, mode, resetForm, currencyOptions, abortController.signal]);

	return (
		<>
			<EpcInquiryFormHeader
				data={data}
				formParent={formParent}
				mode={mode}
				isValid={isValid}
				dirty={dirty}
				docsForUpload={docsForUpload}
				isLoading={isLoading}
				isCreatedByTheUser={isCreatedByTheUser}
				setShowDeleteModal={setShowDeleteModal}
				setShowConfirmModal={setShowConfirmModal}
				openRespondModal={openRespondModal}
			/>
			<Prompt message="" when={dirty && !isDisabled && !isSubmitting} />
			{Boolean(data.message) && Boolean(data.userResponded) && (
				<YellowMessage
					title="Message from ecoligo"
					user={data.userResponded}
					message={data.message}
					date={new Date(data.dateResponded)}
					initialsColor={data.userResponded.email !== currentUser.email ? colors.primary.dark : undefined}
					color={colors.common.beige}
				/>
			)}

			<FormWrapper>
				<Form onSubmit={handleSubmit}>
					<PaddedGrid container item spacing={10} alignItems="stretch">
						<TopSection
							values={values}
							errors={errors}
							touched={touched}
							handleChange={handleChange}
							handleBlur={handleBlur}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							isDisabled={isDisabled}
							currencyOptions={currencyOptions}
						/>
					</PaddedGrid>
					<PaddedGrid container item xs={12} sm={12} md={12} lg={8} xl={8}>
						<Title>
							{t('Solar-as-a-Service request')}
							<QuestionTooltip
								tooltip={
									<>
										Please provide a suggested Contract type and Duration.
										<br />
										ecoligo may suggest more suitable alternatives.
									</>
								}
							/>
						</Title>
						<OfferSection
							offerIndex={0}
							errors={errors}
							touched={touched}
							values={values}
							handleChange={handleChange}
							handleBlur={handleBlur}
							contractTypeOptions={contractTypeOptions}
							setFieldTouched={setFieldTouched}
							setFieldValue={setFieldValue}
							isDisabled={isDisabled}
							currencyOptions={currencyOptions}
						/>
					</PaddedGrid>
					<PaddedGrid container item xs={12} sm={12} md={12} lg={7} xl={7}>
						<Title>{t('EPC offer')}</Title>
						<EpcOfferSection
							errors={errors}
							touched={touched}
							values={values}
							handleChange={handleChange}
							handleBlur={handleBlur}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							resetForm={resetForm}
							currencyOptions={currencyOptions}
							isDisabled={isDisabled}
						/>
					</PaddedGrid>

					<PaddedGrid container>
						<Title>{t('Documentation')}</Title>
						<DocumentationSection
							setDocsForUpload={setDocsForUpload}
							documents={data.documents ?? []}
							docsForUpload={docsForUpload}
							setDocsForDelete={setDocsForDelete}
							docsForDelete={docsForDelete}
							inquiryId={data.id}
							handleDeleteDoc={uploadDocsForInquiry}
							onDataChange={onDataChange}
							isDisabled={isDisabled}
						/>
					</PaddedGrid>

					<ConfirmModal
						isOpen={showConfirmModal}
						isLoading={isLoading}
						onConfirm={handleSubmit}
						onCancel={() => setShowConfirmModal(false)}
						confirmText="Send"
						label="send-epc-inquiry-confirm"
						heading={t('Send EPC inquiry')}
						text={t(
							`You can update or delete the inquiry at any time until ecoligo have responded. If you need to delete the inquiry, use the menu in the top right, next to the Update button.

								Are you sure you want to send this inquiry?`,
						)}
					/>
					<StyledDeleteModal
						isOpen={showDeleteModal}
						isLoading={isLoading}
						onConfirm={() => handleDelete()}
						onCancel={() => setShowDeleteModal(false)}
						confirmText="Delete"
						CustomIcon={() => <Icon icon="delete" />}
						label="delete-this-epc-inquiry-confirm"
					>
						<H1>{t('Delete this EPC inquiry')}</H1>
						<H5>{t('Are you sure you want to delete this inquiry?')}</H5>
					</StyledDeleteModal>
				</Form>
			</FormWrapper>
		</>
	);
};

EpcInquiryForm.defaultProps = {
	data: {},
	onDataChange: () => {},
	isDisabled: false,
	openRespondModal: () => {},
};

EpcInquiryForm.propTypes = {
	data: PropTypes.shape({
		state: PropTypes.string,
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		createdBy: PropTypes.string,
		clientName: PropTypes.string,
		message: PropTypes.string,
		userResponded: PropTypes.shape({ email: PropTypes.string.isRequired }),
		dateResponded: PropTypes.string,
		documents: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	mode: PropTypes.string.isRequired,
	onDataChange: PropTypes.func,
	isDisabled: PropTypes.bool,
	formParent: PropTypes.string.isRequired,
	openRespondModal: PropTypes.func,
};

EpcInquiryForm.propTypes = {
	data: PropTypes.shape({
		state: PropTypes.string,
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		createdBy: PropTypes.string,
		clientName: PropTypes.string,
		message: PropTypes.string,
		userResponded: PropTypes.shape({ email: PropTypes.string.isRequired }),
		dateResponded: PropTypes.string,
		documents: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	mode: PropTypes.string.isRequired,
	onDataChange: PropTypes.func,
	isDisabled: PropTypes.bool,
	formParent: PropTypes.string.isRequired,
	openRespondModal: PropTypes.func,
};

export default EpcInquiryForm;
