import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { useLocation } from 'react-router-dom';

import CampaignsListTable from 'Campaigns/components/Tabs/CampaignsList/CampaignsListTable';
import useSmartResize from 'Common/hooks/table/useSmartResize';

const CampaignsList = () => {
	const filtersRef = useRef();
	const location = useLocation();
	const dispatch = useDispatch();
	const storedTableState = useSelector(state => state.tables.campaigns);
	const [shouldTableRender, setShouldTableRender] = useState(false);

	useEffect(() => {
		if (!shouldTableRender) {
			if (location.search === '' && storedTableState && storedTableState.url) {
				dispatch(push(`/campaigns${storedTableState?.url}`));
			}
			setShouldTableRender(true);
		}
	}, [dispatch, storedTableState, location.search, shouldTableRender]);

	// eslint-disable-next-line
	const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
	useSmartResize(filtersRef, setForceUpdateCounter);

	return <>{(shouldTableRender || !storedTableState?.url) && <CampaignsListTable />}</>;
};

export default CampaignsList;
