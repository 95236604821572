import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';

import sizes from 'Application/theme/sizes';
import colors from 'Application/theme/colors';

import EditableTile from 'Common/components/Tile/EditableTile';
import formatNumber from 'Common/utils/formatNumber';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';

import projectTypes from 'Projects/constants/projectTypes';
import getProductTypes from 'Projects/api/getProjectTypes';
import updateLifetimeInBatch from 'Projects/api/updateLifetimeInBatch';

import getProjectDefaults from 'Preferences/api/getProjectDefaults';
import editProjectDefaults from 'Preferences/api/editProjectDefaults';
import useProjectDefaultsFormInitialValues from './hooks/useProjectDefaultsFormInitialValues';
import useProjectDefaultsFormValidationSchema from './hooks/useProjectDefaultsFormValidationSchema';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';

const SectionTitle = styled.div`
	display: flex;
	font-size: 16px;
	font-weight: 700;
	color: ${colors.text.greyDark};
	line-height: 19.5px;
	align-items: center;
`;
const ProjectDefaultsGroup = styled.div`
	padding-top: ${sizes.spacing(1)};
	margin-bottom: ${sizes.spacing(3)};
`;

const NonPvDescription = styled.p`
	margin-bottom: ${sizes.spacing(3)};
`;

const ProjectDefaultsTile = () => {
	const { t } = useTranslation();

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [projectDefaults, setProjectDefaults] = useState(null);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [projectTypesDefaults, setProjectTypesDefaults] = useState([]);
	const [isLoadingContent, setIsLoadingContent] = useState(false);

	const abortController = useAbortController();

	const initialValues = useProjectDefaultsFormInitialValues(projectDefaults, projectTypesDefaults);
	const validationSchema = useProjectDefaultsFormValidationSchema();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.SALES,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.PM_TECHNICAL,
		roles.ASSET_MANAGER,
		roles.HEAD_OF_ESG,
	]);

	const { errors, touched, values, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			setIsSavingChanges(true);

			try {
				const defaults = await getProjectDefaults(abortController.signal);
				const defaultsData = defaults.data;

				const response = await editProjectDefaults(abortController.signal, {
					...defaultsData,
					...values,
					pvDegradationRate: values.pvDegradationRate / 100,
				});

				const updatedProjectTypesDefault = projectTypesDefaults
					.filter(item => Boolean(values[item.name]))
					.map(item => {
						return {
							id: item.id,
							lifetime: values[item.name],
						};
					});

				await updateLifetimeInBatch(abortController.signal, updatedProjectTypesDefault);

				setProjectDefaults(response.data);
				setIsInEditMode(false);
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					setIsInEditMode(false);
					setIsSavingChanges(false);
					resetForm({ values });
				}
			}
		},
	});

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			setIsInEditMode(true);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				setIsLoadingContent(true);
				const response = await getProjectDefaults(abortController.signal);
				const responseTypes = await getProductTypes(abortController.signal);
				setIsLoadingContent(false);

				setProjectDefaults(response.data);
				setProjectTypesDefaults(responseTypes.data.content);
			} catch (error) {
				showToastError(error);
			}
		})();
	}, [abortController.signal]);

	const euroPerKwpValue = isInEditMode
		? values.euroPerKwp
		: values.euroPerKwp
		? formatNumber(values.euroPerKwp, 0)
		: '';

	return (
		<EditableTile
			title={t('Project defaults')}
			tooltip={
				<QuestionTooltip
					large
					tooltip={
						<>
							These values are copied into new Projects when they are created, and can be adjusted inside each
							Project.
						</>
					}
				/>
			}
			isLoading={isSavingChanges}
			isLoadingContent={isLoadingContent}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<SectionTitle>{t('Lifetime')}</SectionTitle>
			<ProjectDefaultsGroup>
				<FormField>
					<FormikInput
						id={projectTypes.PV}
						name={projectTypes.PV}
						label={t('PV')}
						value={values.PV}
						error={errors.PV}
						touched={touched.PV}
						onChange={handleChange}
						onBlur={handleBlur}
						isTile
						isRequired
						isInEditMode={isInEditMode}
					/>
				</FormField>
				<FormField>
					<FormikInput
						id={projectTypes.EE_SHW}
						name={projectTypes.EE_SHW}
						label={t('EE-SHW')}
						value={values['EE-SHW']}
						error={errors['EE-SHW']}
						touched={touched['EE-SHW']}
						onChange={handleChange}
						onBlur={handleBlur}
						isTile
						isRequired
						isInEditMode={isInEditMode}
					/>
				</FormField>
				<FormField>
					<FormikInput
						id={projectTypes.EE_AC}
						name={projectTypes.EE_AC}
						label={t('EE-AC')}
						value={values['EE-AC']}
						error={errors['EE-AC']}
						touched={touched['EE-AC']}
						onChange={handleChange}
						onBlur={handleBlur}
						isTile
						isRequired
						isInEditMode={isInEditMode}
					/>
				</FormField>
				<FormField>
					<FormikInput
						id={projectTypes.EE_LED}
						name={projectTypes.EE_LED}
						label={t('EE-LED')}
						value={values['EE-LED']}
						error={errors['EE-LED']}
						touched={touched['EE-LED']}
						onChange={handleChange}
						onBlur={handleBlur}
						isTile
						isRequired
						isInEditMode={isInEditMode}
					/>
				</FormField>
				<FormField>
					<FormikInput
						id="pvDegradationRate"
						name="pvDegradationRate"
						label={t('Default PV degradation rate')}
						value={isInEditMode ? values.pvDegradationRate : values.pvDegradationRate + '% per year'}
						error={errors.pvDegradationRate}
						touched={touched.pvDegradationRate}
						onChange={handleChange}
						onBlur={handleBlur}
						isRequired
						isTile
						isInEditMode={isInEditMode}
					/>
				</FormField>
			</ProjectDefaultsGroup>

			<SectionTitle>
				{t('Non-PV Projects')}
				<QuestionTooltip large tooltip={<>Changing the value will not update existing calculations.</>} />
			</SectionTitle>
			<NonPvDescription>
				{t('Auto calculate the System size of non-PV Projects using the EPC volume.')}
			</NonPvDescription>
			<FormField>
				<FormikInput
					id="euroPerKwp"
					name="euroPerKwp"
					label={t('€ per kWp')}
					value={euroPerKwpValue}
					error={errors.euroPerKwp}
					touched={touched.euroPerKwp}
					onChange={handleChange}
					onBlur={handleBlur}
					isRequired
					isTile
					isInEditMode={isInEditMode}
				/>
			</FormField>
		</EditableTile>
	);
};

export default ProjectDefaultsTile;
