import api from 'Application/api/api';

const uploadClientOfferDocuments = async (signal, files, clientOfferId) => {
	const response = await api.post(`/client-offers/${clientOfferId}/upload-document`, files, {
		signal,
		timeout: 100000,
		headers: {
			'Content-Type': 'multipart/form-data',
			Accept: '*/*',
		},
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
	};
};

export default uploadClientOfferDocuments;
