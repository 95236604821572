import api from 'Application/api/api';

const getPlatformById = (signal, id, details) => {
	const params = {
		details: details,
	};

	return api.get(`/platforms/by-id/${id}`, {
		params,
		signal,
	});
};

export default getPlatformById;
