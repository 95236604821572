import { useCallback, useEffect } from 'react';
import Icon from 'Common/components/icons/Icon';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { LoadingIcon } from 'User/components/guestScreenComponents';
import colors from 'Application/theme/colors';
import { PropTypes } from 'prop-types';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
`;
const NavigationButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 25px;
	position: absolute;
	opacity: 0.5;
	background: ${colors.grey.main};
	top: 50%;
	height: 41px;
	width: 41px;
	cursor: pointer;
	transform: translateY(-50%);

	&:hover {
		opacity: 1;
	}

	${({ left }) =>
		left &&
		css`
			left: 5px;
		`};

	${({ right }) =>
		right &&
		css`
			right: 5px;
		`};

	&[disabled] {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;

const ImageContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;

	img {
		max-width: 100%;
		height: auto;
		border-radius: 10px;
	}
`;

const ImageViewer = ({
	image,
	isSaving,
	isLoadingNext,
	isLoadingPrevious,
	onNextClick,
	onPrevClick,
	hideNextButton,
	hidePrevButton,
	preventKeyboardNavigation,
}) => {
	const handleUserKeyPress = useCallback(
		event => {
			if (event.key === 'ArrowRight' && !hideNextButton && !preventKeyboardNavigation) {
				onNextClick();
			}
			if (event.key === 'ArrowLeft' && !hidePrevButton && !preventKeyboardNavigation) {
				onPrevClick();
			}
		},
		[hideNextButton, hidePrevButton, onNextClick, onPrevClick, preventKeyboardNavigation],
	);

	useEffect(() => {
		window.addEventListener('keydown', handleUserKeyPress);

		return () => {
			window.removeEventListener('keydown', handleUserKeyPress);
		};
	}, [handleUserKeyPress]);

	const isLoading = isSaving || isLoadingNext || isLoadingPrevious;

	return (
		<Wrapper>
			{!hidePrevButton && (
				<NavigationButton left onClick={onPrevClick} disabled={isLoading}>
					{isLoadingPrevious ? <LoadingIcon /> : <Icon icon="chevronleft" />}
				</NavigationButton>
			)}
			{!!image && (
				<ImageContainer>
					<img src={image.url} alt={image.id.name} />
				</ImageContainer>
			)}
			{!hideNextButton && (
				<NavigationButton right onClick={onNextClick} disabled={isLoading}>
					{isLoadingNext ? <LoadingIcon /> : <Icon icon="chevronright" />}
				</NavigationButton>
			)}
		</Wrapper>
	);
};

ImageViewer.propTypes = {
	image: PropTypes.shape({
		url: PropTypes.string,
		id: PropTypes.shape({ name: PropTypes.string }),
	}).isRequired,
	isSaving: PropTypes.bool.isRequired,
	isLoadingNext: PropTypes.bool.isRequired,
	isLoadingPrevious: PropTypes.bool.isRequired,
	onNextClick: PropTypes.func.isRequired,
	onPrevClick: PropTypes.func.isRequired,
	hideNextButton: PropTypes.bool.isRequired,
	hidePrevButton: PropTypes.bool.isRequired,
	preventKeyboardNavigation: PropTypes.bool.isRequired,
};

export default ImageViewer;
