import showToastError from 'Common/utils/showToastError';
import getEntities from 'Entities/api/getEntities';

const loadContractPartiesOptions = async (signal, query, countryName, filters) => {
	try {
		const response = await getEntities(signal, {}, filters, query);
		const contractParties = response.data.content;

		return contractParties
			.filter(contractParty => contractParty.name && contractParty.name !== '-')
			.map(contractParty => ({
				value: contractParty.id,
				label: contractParty.name,
				subtitle: `${contractParty.abbreviation}, ${countryName}`,
			}));
	} catch (e) {
		showToastError(e, 'Error loading contract parties options');
	}
};

export default loadContractPartiesOptions;
