import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import { statuses, statusesOptions } from 'Projects/constants/statuses';
import { closedReasonsOptions } from 'Projects/constants/closedReasons';
import ConfirmModal from 'Common/components/modals/ConfirmModal';

import patchProjectById from 'Projects/api/patchProjectById';
import useStatusFormInitialValues from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/StatusTile/hooks/useStatusFormInitialValues';
import useStatusFormValidationSchema from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/StatusTile/hooks/useStatusFormValidationSchema';
import { projectPriorityOptions, projectPriorityIcons } from 'Common/constants/projectPrioritiy';
import Icon from 'Common/components/icons/Icon';
import sizes from 'Application/theme/sizes';
import showToastError from 'Common/utils/showToastError';
import { useMemo } from 'react';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import useAbortController from 'Common/hooks/useAbortController';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';
import axios from 'axios';

const PriorityOption = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${sizes.spacing(1)};
`;

const formatCustomOptionLabel = ({ value, label }) => {
	const iconData = projectPriorityIcons[value];

	return (
		<PriorityOption>
			<Icon {...iconData} />
			{label}
		</PriorityOption>
	);
};

const StatusTile = ({
	project,
	onEnterEditMode,
	onExitEditMode,
	onDirtyForm,
	onTileSave,
	highlightLabels,
}) => {
	const { t } = useTranslation();
	const initialValues = useStatusFormInitialValues(project);
	const validationSchema = useStatusFormValidationSchema();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.SALES,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.ASSET_MANAGER,
		roles.PM_FUNDRAISING,
		roles.PM_TECHNICAL,
		roles.HEAD_OF_ESG,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const abortController = useAbortController();

	const {
		errors,
		touched,
		values,
		handleBlur,
		handleSubmit,
		setFieldValue,
		setFieldTouched,
		dirty,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			if (
				values?.status.value === statuses.ON_HOLD &&
				initialValues?.status.value !== statuses.ON_HOLD &&
				project.saasSignatureDate
			) {
				setShowConfirmModal(true);
			} else {
				confirmSubmit(false);
			}
		},
	});

	const filteredStatusOptions = useMemo(
		() => statusesOptions.filter(el => !(el.value === statuses.EXCLUDED)),
		[],
	);

	const isStatusFieldDisabled = useMemo(
		() => values?.status?.value === statuses.EXCLUDED,
		[values?.status?.value],
	);

	const confirmSubmit = async removeCloseDate => {
		setIsSavingChanges(true);
		try {
			await patchProjectById(abortController.signal, project.id, {
				condition: values?.status?.value,
				closedReason:
					values.status.value === 'CLOSED' && values?.closedReason
						? {
								closedReason: values?.closedReason?.value,
								displayName: values?.closedReason?.label,
						  }
						: null,
				saasSignatureDate: removeCloseDate ? null : project.saasSignatureDate,
				priority: values?.priority?.value,
			});

			if (onDirtyForm) {
				onDirtyForm(false);
			}

			await onTileSave();
			handleCancel();
			setIsSavingChanges(false);
			setShowConfirmModal(false);
			resetForm({ values });
		} catch (e) {
			showToastError(e);
			if (!axios.isCancel(e)) {
				handleCancel();
				setIsSavingChanges(false);
				setShowConfirmModal(false);
			}
		}
	};

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<>
			<EditableTile
				title={t('Status')}
				isLoading={isSavingChanges}
				isInEditMode={isInEditMode}
				onSubmit={handleSubmit}
				onCancel={handleCancel}
				onClick={handleTileClick}
			>
				<FormField>
					<HoverTooltip placement="top" title={isStatusFieldDisabled ? 'Status set by pulse' : ''}>
						<FormikSelect
							id="status"
							name="status"
							label={t('Status')}
							value={values.status}
							error={errors.status}
							touched={touched.status}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							options={filteredStatusOptions}
							isTile
							isInEditMode={isInEditMode}
							isHighlighted={highlightLabels.includes(PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_STATUS.STATUS)}
							isDisabled={isStatusFieldDisabled}
						/>
					</HoverTooltip>
				</FormField>
				{values?.status?.value === 'CLOSED' && (
					<FormField>
						<FormikSelect
							id="closedReason"
							name="closedReason"
							label={t('Closed Reason')}
							value={values.closedReason}
							error={errors.closedReason}
							touched={touched.closedReason}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
							options={closedReasonsOptions}
							isTile
							isInEditMode={isInEditMode}
							isRequired
							isClearable
							isHighlighted={highlightLabels.includes(
								PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_STATUS.CLOSED_REASON,
							)}
						/>
					</FormField>
				)}
				<FormField>
					<FormikSelect
						id="priority"
						name="priority"
						label={t('Priority')}
						value={values.priority}
						error={errors.priority}
						touched={touched.priority}
						setFieldValue={setFieldValue}
						setFieldTouched={setFieldTouched}
						options={projectPriorityOptions}
						isTile
						isInEditMode={isInEditMode}
						formatOptionLabel={formatCustomOptionLabel}
						inputPrefix={() => <Icon {...projectPriorityIcons[values.priority.value]} />}
						isHighlighted={highlightLabels.includes(
							PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_STATUS.PRIORITY,
						)}
					/>
				</FormField>
			</EditableTile>

			<ConfirmModal
				isOpen={showConfirmModal}
				onConfirm={() => confirmSubmit(true)}
				onCancel={() => setShowConfirmModal(false)}
				heading=""
				text={t(`Placing the project to On hold will remove the SaaS signature date.
				
				Are you sure?`)}
			/>
		</>
	);
};

StatusTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onSave: undefined,
	onDirtyForm: undefined,
	onTileSave: async () => {},
	highlightLabels: [],
};

StatusTile.propTypes = {
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		saasSignatureDate: PropTypes.string,
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onSave: PropTypes.func,
	onDirtyForm: PropTypes.func,
	onTileSave: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default StatusTile;
