import PropTypes from 'prop-types';

const CurrencySign = ({ currency }) => <>{currency?.value === 'EUR' ? '€' : currency?.value}</>;

CurrencySign.defaultProps = {
	currency: {},
};
CurrencySign.propTypes = {
	currency: PropTypes.shape({
		value: PropTypes.string,
		label: PropTypes.string,
	}),
};

export default CurrencySign;
