import styled from 'styled-components/macro';
import { css } from 'styled-components';
import colors from 'Application/theme/colors';
import PopperTooltip from 'Common/components/tooltip/PopperTooltip';
import { PropTypes } from 'prop-types';

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 0 0 1px 1px;
	position: relative;

	&:focus-within {
		padding: 0;
		border: 2px solid ${colors.primary.main};
	}

	${({ hasError }) =>
		hasError &&
		css`
			border: 2px solid ${colors.error.main};

			&:focus-within {
				padding: 0;
				border: 2px solid ${colors.error.main};
			}

			> div:first-child {
				position: absolute;
				top: 0;
				left: 50%;
			}
		`};
`;

const TableField = ({ className, children, error, touched }) => {
	const hasError = !!error && touched;

	return (
		<Wrapper className={className} hasError={hasError}>
			{hasError && (
				<PopperTooltip
					placement="top"
					trigger="focus"
					defaultVisible
					tooltip={error}
					containerStyle={{
						padding: '10px 20px',
						fontSize: 14,
						lineHeight: '21px',
						color: colors.error.main,
					}}
				/>
			)}
			{children}
		</Wrapper>
	);
};

TableField.defaultProps = {
	className: '',
	error: '',
	touched: false,
};

TableField.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	error: PropTypes.string,
	touched: PropTypes.bool,
};

export default TableField;
