import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import styled, { css } from 'styled-components';
import FormField from './FormField';

const ComparableFormField = styled(FormField)`
	position: relative;

	${({ $compareValue }) =>
		$compareValue &&
		css`
			${$compareValue ? 'max-width: 600px !important;' : ''}
			width: 100%;

			&::after {
				content: '${$compareValue}';
				position: absolute;
				height: 100%;
				top: 0;
				left: 60%;
				color: ${colors.primary.dark};
				line-height: 20px;
				${sizes.fontSize.main};
				display: flex;
				align-items: flex-end;
				padding-bottom: 5px;
			}

			&:focus-within::after {
				content: '';
			}
		`}

	.react-select__control {
		align-items: baseline;
	}
`;

export default ComparableFormField;
