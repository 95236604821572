import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import Tile from 'Common/components/Tile/Tile';

import FormikSelect from 'Common/components/form/FormikSelect';
import FormField from 'Common/components/form/FormField';
import loadCurrenciesOptions from 'Country/utils/loadCurrenciesOptions';
import FormikInput from 'Common/components/form/FormikInput';
import loadInvoiceTemplateOptions from 'Invoice/utils/loadInvoiceTemplateOptions';
import loadSaaSBankAccountOptions from 'Invoice/utils/loadSaaSBankAccountOptions';
import { INVOICE_STATUSES, INVOICE_STATUS_OPTIONS } from 'Invoice/constants/invoiceContstants';
import { Grid } from '@mui/material';
import { useMemo } from 'react';
import loadSaaSBankAccountOptionsFromEntity from 'Invoice/utils/loadSaaSBankAccountOptionsFromEntity';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import ConditionalGridSection from 'Common/components/ConditionalGridSection';
import { useEffect, useState } from 'react';
import getExchangeRateFromTo from 'Invoice/api/getExchangeRateFromTo';

const InvoiceGeneralTile = ({
	invoice,
	isEditMode,
	countryId,
	entityId,
	isLoading,
	values,
	errors,
	touched,
	setFieldValue,
	setFieldTouched,
	handleChange,
	handleBlur,
	displayedStatus,
}) => {
	const { t } = useTranslation();
	const [template, setTemplate] = useState();

	const isAuthorizedToEdit = isAuthorized([roles.ADMIN, roles.SALES, roles.FINANCE, roles.MANAGEMENT]);

	useEffect(() => {
		const getInvoiceTemplates = async () => {
			const templates = await loadInvoiceTemplateOptions([countryId], null, null);
			setTemplate(templates);
		};
		getInvoiceTemplates();
	}, [countryId]);

	const loadSaaSBankAccountOptionsBinded = useMemo(
		() =>
			isEditMode
				? loadSaaSBankAccountOptions.bind(null, [invoice?.id])
				: entityId
				? loadSaaSBankAccountOptionsFromEntity.bind(null, entityId)
				: () => [],
		[invoice?.id, entityId, isEditMode],
	);

	const isReadonly = useMemo(() => !isAuthorizedToEdit, [isAuthorizedToEdit]);

	const currenciesEqual = useMemo(
		() => values?.paymentCurrency?.value === values?.saasCurrency?.value,
		[values?.paymentCurrency?.value, values?.saasCurrency?.value],
	);

	useEffect(() => {
		if (!isEditMode) {
			getExchangeRateFromTo(null, values.saasCurrency.value, values.paymentCurrency.value).then(res =>
				setFieldValue('exchangeRate', res),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditMode]);

	// const showEurBankAccount = useMemo(
	// 	() => values?.bankAccount && values?.bankAccount?.currency !== 'EUR',
	// 	[values?.bankAccount],
	// );

	// const bankAccountOptionsEUR = useMemo(
	// 	() => bankAccountOptions.filter(account => account.currency === 'EUR'),
	// 	[bankAccountOptions],
	// );
	return (
		<Tile title={t('General')} isLoading={isLoading} isReadonly={isReadonly} fieldsMaxWidth="250px">
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6} md={3}>
					<FormField>
						<FormikSelect
							id="status"
							name="status"
							label={t('Status')}
							value={{ value: values.status, label: displayedStatus }}
							error={errors.status}
							touched={touched.status}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							options={INVOICE_STATUS_OPTIONS}
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSelect
							id="invoiceTemplate"
							name="invoiceTemplate"
							label={t('Invoice Template')}
							value={values.invoiceTemplate}
							error={errors.invoiceTemplate}
							touched={touched.invoiceTemplate}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							options={template}
							isRequired={isEditMode}
							isOverlay
						/>
					</FormField>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<FormField>
						<FormikSelect
							isAsync
							id="saasCurrency"
							name="saasCurrency"
							label={t('SaaS Currency')}
							value={values.saasCurrency}
							error={errors.saasCurrency}
							touched={touched.saasCurrency}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadCurrenciesOptions}
							isDisabled
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSelect
							isAsync
							id="bankAccount"
							name="bankAccount"
							label={t('Bank Account')}
							value={values.bankAccount}
							error={errors.bankAccount}
							touched={touched.bankAccount}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							loadOptions={loadSaaSBankAccountOptionsBinded}
							isRequired={isEditMode}
							isOverlay
						/>
					</FormField>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<FormField>
						<FormikSelect
							isAsync
							id="paymentCurrency"
							name="paymentCurrency"
							label={t('Payment Currency')}
							value={values.paymentCurrency}
							error={errors.paymentCurrency}
							touched={touched.paymentCurrency}
							setFieldValue={setFieldValue}
							onChange={option => {
								getExchangeRateFromTo(null, values.saasCurrency.value, option.value).then(res =>
									setFieldValue('exchangeRate', res),
								);
							}}
							onBlur={handleBlur}
							loadOptions={loadCurrenciesOptions}
							isRequired
							isOverlay
						/>
					</FormField>
					{/* <ConditionalGridSection hasFlag={showEurBankAccount}>
						<FormField>
							<FormikSelect
								id="eurBankAccount"
								name="eurBankAccount"
								label={t('EUR Bank Account')}
								value={values.eurBankAccount}
								error={errors.eurBankAccount}
								touched={touched.eurBankAccount}
								setFieldValue={setFieldValue}
								onBlur={handleBlur}
								options={bankAccountOptionsEUR}
								isOverlay
							/>
						</FormField>
					</ConditionalGridSection> */}
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<FormField>
						<FormikInput
							id="exchangeRate"
							name="exchangeRate"
							label={t('Exchange rate')}
							value={currenciesEqual ? '1' : values.exchangeRate}
							error={errors.exchangeRate}
							touched={touched.exchangeRate}
							onChange={handleChange}
							onBlur={handleBlur}
							isDisabled={currenciesEqual}
							isRequired={!currenciesEqual}
							isOverlay
						/>
					</FormField>
					<ConditionalGridSection hasFlag={values.status.value === INVOICE_STATUSES.PAID}>
						<FormField>
							<FormikSingleDatePicker
								id="paymentDate"
								label={t('Payment Date')}
								startDateName="paymentDate"
								error={errors.paymentDate}
								touched={touched.paymentDate}
								startDate={values.paymentDate}
								setFieldValue={setFieldValue}
								setFieldTouched={setFieldTouched}
								isRequired
								isOverlay
							/>
						</FormField>
					</ConditionalGridSection>
				</Grid>
			</Grid>
		</Tile>
	);
};

InvoiceGeneralTile.defaultProps = {
	isLoading: false,
	isEditMode: false,
	countryId: null,
	invoice: {},
	values: {},
	errors: {},
	touched: {},
	entityId: null,
	displayedStatus: '',
	setFieldTouched: () => {},
	setFieldValue: () => {},
	handleChange: () => {},
	handleBlur: () => {},
};

InvoiceGeneralTile.propTypes = {
	invoice: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	isEditMode: PropTypes.bool,
	countryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isLoading: PropTypes.bool,
	entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	setFieldTouched: PropTypes.func,
	values: PropTypes.shape({
		saasCurrency: PropTypes.string,
		paymentCurrency: PropTypes.string,
		status: PropTypes.string,
		invoiceTemplate: PropTypes.string,
		bankAccount: PropTypes.string,
		eurBankAccount: PropTypes.string,
		exchangeRate: PropTypes.number,
		paymentDate: PropTypes.string,
	}),
	errors: PropTypes.shape({
		saasCurrency: PropTypes.string,
		paymentCurrency: PropTypes.string,
		status: PropTypes.string,
		invoiceTemplate: PropTypes.string,
		bankAccount: PropTypes.string,
		eurBankAccount: PropTypes.string,
		exchangeRate: PropTypes.number,
		paymentDate: PropTypes.strng,
	}),
	touched: PropTypes.shape({
		saasCurrency: PropTypes.string,
		paymentCurrency: PropTypes.string,
		status: PropTypes.string,
		invoiceTemplate: PropTypes.string,
		bankAccount: PropTypes.string,
		eurBankAccount: PropTypes.string,
		exchangeRate: PropTypes.number,
		paymentDate: PropTypes.string,
	}),
	displayedStatus: PropTypes.string,
	setFieldValue: PropTypes.func,
	handleChange: PropTypes.func,
	handleBlur: PropTypes.func,
};

export default InvoiceGeneralTile;
