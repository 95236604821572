import { GoogleMap, useJsApiLoader, InfoWindow, Marker, MarkerClusterer } from '@react-google-maps/api';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import LoaderWrapper from '../LoaderWrapper';
import Loader from '../Loader';
import ProjectInfoPopup from 'Dashboard/components/ProjectInfoPopup/ProjectInfoPopup';
import styled from 'styled-components/macro';
import getLocations from './api/getLocations';
import { useTranslation } from 'react-i18next';
import signedProjectMarker from 'Common/assets/images/signedProjectMarker.svg';
import inOperationProjectMarker from 'Common/assets/images/inOperationProjectMarker.svg';
import { useRef } from 'react';
import useResponsive from 'Common/hooks/useResponsive';
import { css } from 'styled-components';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';
import showToastError from 'Common/utils/showToastError';
import { modifyCloseCoordinates } from 'Common/components/Map/utils/modifyCloseCoordinates';
import useAbortController from 'Common/hooks/useAbortController';

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const MAP_ID = process.env.REACT_APP_GOOGLE_MAPS_MAP_ID;

const containerStyle = {
	width: '100%',
	height: '100%',
};

const center = {
	lat: 10,
	lng: 0,
};

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	overflow: hidden;
	border-radius: 20px;

	${({ $isMobile }) =>
		$isMobile &&
		css`
			width: 100%;
		`}

	${({ $isLoading }) =>
		$isLoading
			? css`
					.gm-ui-hover-effect {
						display: none !important;
					}
			  `
			: css`
					.gm-ui-hover-effect {
						display: block !important;
					}
			  `};

	.gm-style {
		.gm-style-iw-t {
			&::after {
				display: none !important;
			}
		}

		.gm-style-iw {
			.gm-ui-hover-effect {
				display: flex !important;
				align-items: center !important;
				justify-content: center !important;
				top: 8px !important;
				right: 8px !important;
				width: 28px !important;
				height: 28px !important;
				border-radius: 50% !important;
				background-color: ${colors.primary.light} !important;
				color: ${colors.primary.dark} !important;
				z-index: 9999999;

				span {
					width: 12px !important;
					height: 12px !important;
					transform: scale(1.3) !important;
				}
			}
		}

		.cluster {
			width: 40px !important;
			height: 40px !important;

			div {
				border-radius: 6px !important;
				background-color: ${colors.common.darkGreen} !important;
				background-size: 50%;
				font-size: 22px !important;
				color: ${colors.common.white} !important;
				width: 40px !important;
				height: 40px !important;
				line-height: 40px !important;
			}

			img {
				display: none !important;
			}
		}
	}
`;

const Legend = styled.div`
	position: absolute;
	bottom: ${sizes.spacing(10)};
	left: ${sizes.spacing(1)};
	background-color: ${colors.common.white};
	border-radius: 20px;
	padding: ${sizes.spacing(2)};
	display: flex;
	flex-direction: column;
	gap: ${sizes.spacing(1)};
	z-index: 1;
`;

const LegendItem = styled.div`
	position: relative;
	color: ${colors.common.black};
	padding-left: ${sizes.spacing(2.5)};

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 15px;
		height: 15px;
		border-radius: 6px;
		background-color: ${({ $color }) => $color};
	}
`;

const ProjectName = styled.div`
	font-size: 16px;
	font-weight: 700;
	padding-bottom: ${sizes.spacing(1)};
	position: relative;
	cursor: pointer;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 32px;
		height: 1px;
		background: ${colors.secondary.dark};
	}
`;

const PopupContainer = styled.div`
	position: relative;
	width: fit-content;
	height: fit-content;
`;

const zoomsMap = {
	0: 10,
	1: 10,
	2: 8,
	3: 6,
	4: 2,
	5: 1.3,
	6: 0.8,
	7: 0.5,
	8: 0.2,
	9: 0.08,
	10: 0.05,
	11: 0.02,
	12: 0.01,
	13: 0.005,
	14: 0.002,
	15: 0.001,
	16: 0.0005,
	17: 0.0002,
	18: 0.0001,
	19: 0.00005,
	20: 0.00002,
};

const generateMarkerData = (
	iconUrl,
	toggleOpen,
	prevArrLength,
	{ latitude, longitude, id, externalId, cleintName },
	index,
) => ({
	key: id,
	projectName: `${externalId} - ${cleintName}`,
	latitude,
	longitude,
	isOpen: false,
	toggleOpen: () => toggleOpen(index + prevArrLength),
	projectId: id,
	iconUrl: iconUrl,
});

const Map = () => {
	const { isMobile } = useResponsive();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const mapRef = useRef();
	const [showMap, setShowMap] = useState(false);
	const [dataFetched, setDataFetched] = useState(false);
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: API_KEY,
	});

	const [map, setMap] = useState(null);
	const [markers, setMarkers] = useState();
	const [popupOffset, setPopupOffset] = useState(6);

	const abortController = useAbortController();

	const handleZoomChange = useCallback(() => {
		setPopupOffset(zoomsMap[map?.zoom] || 0);
	}, [map]);

	const toggleOpen = index => {
		setMarkers(prevMarkers => [
			...prevMarkers.slice(0, index).map(marker => ({
				...marker,
				isOpen: false,
			})),
			{ ...prevMarkers[index], isOpen: !prevMarkers[index].isOpen },
			...prevMarkers.slice(index + 1).map(marker => ({
				...marker,
				isOpen: false,
			})),
		]);
	};

	const fetchCoordinates = useCallback(async () => {
		setDataFetched(false);
		try {
			const {
				data: { signedProjectsCoordinates, inOperationProjectsCoordinates },
			} = await getLocations(abortController.signal);
			const generateInOperationMarker = generateMarkerData.bind(undefined, inOperationProjectMarker, toggleOpen);
			const generateSignedMarker = generateMarkerData.bind(undefined, signedProjectMarker, toggleOpen);

			const markerArrays = [
				{ generator: generateSignedMarker, array: signedProjectsCoordinates },
				{ generator: generateInOperationMarker, array: inOperationProjectsCoordinates },
			];

			const composedCoordinates = markerArrays.reduce((prevArr, { generator, array }) => {
				const bindedGenerator = generator.bind(undefined, prevArr.length);
				return [...prevArr, ...array.map(bindedGenerator)];
			}, []);

			setMarkers(modifyCloseCoordinates(composedCoordinates));
			setDataFetched(true);
		} catch (error) {
			showToastError(error, t("Can't fetch project coordinates"));
		}
	}, [abortController.signal, t]);

	const mapObserverHandler = useCallback((entries, observer) => {
		entries.forEach(entry => {
			if (entry.isIntersecting && entry.intersectionRatio > 0.2) {
				setShowMap(true);
				observer.unobserve(entry.target);
			}
		});
	}, []);

	const observer = useMemo(
		() => new IntersectionObserver(mapObserverHandler, { threshold: 0.2 }),
		[mapObserverHandler],
	);

	const onProjectNameClick = projectId => {
		dispatch(push(`/projects/details/${projectId}`));
	};

	useEffect(() => {
		const element = mapRef.current;

		if (!element) return;

		observer.observe(element);

		return () => {
			observer.unobserve(element);
		};
	}, [observer, mapRef]);

	useEffect(() => {
		fetchCoordinates();
	}, [fetchCoordinates]);

	const clusterOptions = {
		imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
	};

	return (
		<Wrapper $isMobile={isMobile} ref={mapRef} $isLoading={!(isLoaded && dataFetched && showMap)}>
			{isLoaded && dataFetched && showMap ? (
				<>
					<Legend>
						<LegendItem $color={colors.secondary.dark}>{t('In Operation')}</LegendItem>
						<LegendItem $color={colors.primary.dark}>{t('Signed')}</LegendItem>
					</Legend>
					<GoogleMap
						mapContainerStyle={containerStyle}
						onLoad={map => setMap(map)}
						center={center}
						zoom={2}
						options={{ mapId: MAP_ID }}
						onZoomChanged={handleZoomChange}
					>
						<MarkerClusterer options={clusterOptions}>
							{clusterer =>
								markers.map(marker => (
									<div key={marker.key}>
										{marker.isOpen && (
											<InfoWindow
												position={{ lat: marker.latitude + popupOffset, lng: marker.longitude }}
												onCloseClick={marker.toggleOpen}
											>
												<PopupContainer>
													<ProjectName onClick={() => onProjectNameClick(marker.projectId)}>
														{marker.projectName}
													</ProjectName>
													<ProjectInfoPopup projectId={marker.projectId} $isMapInfo disableUserInfoHover />
												</PopupContainer>
											</InfoWindow>
										)}
										<Marker
											icon={marker.iconUrl}
											clusterer={clusterer}
											onClick={marker.toggleOpen}
											position={{ lat: marker.latitude, lng: marker.longitude }}
										/>
									</div>
								))
							}
						</MarkerClusterer>
					</GoogleMap>
				</>
			) : (
				<LoaderWrapper>
					<Loader />
				</LoaderWrapper>
			)}
		</Wrapper>
	);
};

export default memo(Map);
