const mergeCo2Values = (values, finances) =>
	values.map(({ financeDetailsProjects, ...rest }) => ({
		...rest,
		financeDetailsProjects: financeDetailsProjects.map(({ externalId, ...rest }) => {
			const financeItem = finances.find(finance => finance.externalId === externalId);
			return {
				...rest,
				annualCo2Contribution: financeItem?.annualCo2Contribution,
				annualCo2SavingsEditable: financeItem?.annualCo2SavingsEditable,
			};
		}),
	}));

export default mergeCo2Values;
