import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import { rfepStates } from 'Projects/constants/rfepStates';
import isAuthorized from 'User/utils/isAuthorized';
import { roles } from 'User/constants/roles';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PropTypes } from 'prop-types';
import crudModes from 'Common/constants/crudModes';
import ClientOfferOverlay from '../Overlays/ClientOffer/ClientOfferOverlay';
import deleteClientOffer from '../../ClientContract/api/deleteClientOffer';

const Actions = styled.div`
	width: 40px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
`;

const ClientOfferTileActions = ({ project, epcOffer, reloadProject, clientOfferId }) => {
	const { t } = useTranslation();
	const isAuthorizedToEdit = isAuthorized([roles.ADMIN, roles.SALES, roles.MANAGEMENT, roles.FINANCE]);

	const [isOverlayOpen, setIsEditOverlayOpen] = useState(false);

	const [clientOfferForDeletion, setClientOfferForDeletion] = useState(null);

	const abortController = useAbortController();

	const handleDeleteClientOffer = async clientOfferId => {
		try {
			await deleteClientOffer(abortController.signal, clientOfferId);

			notify(t('Offer deleted successfully'), {
				type: toast.TYPE.SUCCESS,
			});
			setClientOfferForDeletion(null);
			await reloadProject();
		} catch (error) {
			showToastError(error);
		}
	};

	const handleEditClientOffer = useCallback(async () => {
		try {
			setIsEditOverlayOpen(false);
			await reloadProject();
		} catch (err) {
			showToastError(err);
		}
	}, [reloadProject]);

	return (
		<Actions>
			<MoreActionsButton
				noBackground
				controlledVisibility
				label="Projects Details Offers Client Offer Standard More Actions"
			>
				<MenuItem
					disabled={
						(epcOffer.type === 'RFEP' && epcOffer.status === rfepStates.CLOSED) ||
						(epcOffer.type === 'RFEP' && epcOffer.status === rfepStates.OPEN)
					}
					onClick={() => setIsEditOverlayOpen(true)}
					type="button"
					data-action="openClientOffer"
					label={`Projects Details Offers Client Offer Standard - ${
						isAuthorizedToEdit ? 'Edit' : 'View'
					} Menu Item`}
				>
					{isAuthorizedToEdit ? t('Edit') : t('View')}
				</MenuItem>
				<MenuItem
					type="button"
					disabled
					data-action="nopenClientOffer"
					label={'Projects Details Offers Client Offer Standard - Edit Contract Number Menu Item'}
				>
					{t('Edit Contract Number')}
				</MenuItem>
				<MenuItem
					disabled={!isAuthorizedToEdit}
					onClick={() =>
						setClientOfferForDeletion({
							clientOfferId,
							epcOfferId: epcOffer.id,
						})
					}
					type="button"
					data-action="delete"
					label="Projects Details Offers Client Offer Standard - Delete Menu Item"
				>
					{t('Delete')}
				</MenuItem>
			</MoreActionsButton>
			{isOverlayOpen && (
				<ClientOfferOverlay
					isOpen={isOverlayOpen}
					mode={isAuthorizedToEdit ? crudModes.EDIT : crudModes.VIEW}
					onClose={() => setIsEditOverlayOpen(false)}
					onFormSubmit={handleEditClientOffer}
					clientOfferId={clientOfferId}
					project={project}
				/>
			)}

			<ConfirmModal
				isOpen={clientOfferForDeletion !== null}
				onCancel={() => setClientOfferForDeletion(null)}
				onConfirm={() =>
					handleDeleteClientOffer(clientOfferForDeletion.clientOfferId, clientOfferForDeletion.epcOfferId)
				}
				label="delete-client-offer"
				heading={t('Delete Client offer')}
				text={t('Are you sure you want to delete the this Client Offer?')}
			/>
		</Actions>
	);
};

ClientOfferTileActions.defaultProps = {
	epcOffer: {},
	reloadProject: () => {},
	projectId: null,
	project: {},
};

ClientOfferTileActions.propTypes = {
	epcOffer: PropTypes.shape({
		clientOfferGroups: PropTypes.arrayOf(
			PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
		),
		type: PropTypes.string,
		status: PropTypes.string,
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	reloadProject: PropTypes.func,
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		epcOffers: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	clientOfferId: PropTypes.number.isRequired,
};

export default ClientOfferTileActions;
