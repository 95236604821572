import api from 'Application/api/api';
import adaptProjectResponseData from 'Projects/api/adapters/adaptProjectResponseData';

const patchProjectById = async (signal, id, fields) => {
	const response = await api.patch(`/projects/${id}`, {
		...fields,
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptProjectResponseData(response.data),
	};
};

export default patchProjectById;
