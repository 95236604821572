import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useRestrictedIndustriesValidationSchema = () => {
	const { t } = useTranslation();

	return useMemo(
		() =>
			yup.object({
				allowedPercentageOfRestrictedIndustries: yup
					.number()
					.nullable()
					.positive(t('Must be positive'))
					.max(100, t('Must be less than or equal to 100'))
					.typeError(t('Must be a number')),
			}),
		[t],
	);
};

export default useRestrictedIndustriesValidationSchema;
