import { useCallback, useEffect, useMemo, useState } from 'react';
import { pull } from 'lodash';
import { updateCurrentTableData, updateSelectedFilters } from 'Application/reducers/reduxTables';
import { useDispatch, useSelector } from 'react-redux';
import filterValueSerializer from 'Common/components/filter/utils/filterValuesSerializer';
import {
	FILTER_DEFAULTS,
	FILTER_TYPES,
	FILTER_LIFECYCLE_HOOKS,
} from 'Common/components/filter/constants/filterConstants';
import removeDuplicateHooks from 'Common/components/filter/utils/removeDuplicateHooks';
import useQueryFilterData from 'Common/components/filter/hooks/useQueryFilterData';
import useSavedUrl from 'Common/components/filter/hooks/useSavedUrl';
import { convertSanitizedDataToSelectedFilters } from '../utils/convertSanitizedDataToSelectedFilters';

const useFiltersData = (filtersData, table) => {
	const dispatch = useDispatch();
	const tableData = useSelector(state => state.tables[table]);
	const filtersCollapsed = tableData?.filtersCollapsed ?? false;
	const openedFilter = useMemo(() => tableData?.openedFilter ?? [], [tableData]);
	const omitedFilters = useMemo(() => tableData?.omitedFilters ?? [], [tableData]);
	const reduxSelectedFilters = useMemo(() => tableData?.selectedFilters ?? [], [tableData]);

	const selectedFilters = useMemo(
		() => reduxSelectedFilters.filter(selectedFilter => !omitedFilters.includes(selectedFilter)),
		[reduxSelectedFilters, omitedFilters],
	);

	const [filtersValues, setFiltersValues] = useState({});

	const filterLifecycleHooks = useMemo(() => Object.values(FILTER_LIFECYCLE_HOOKS), []);

	// Initial Filter hooks value is an object with each hook as key and value as empty array
	const initialFilterHooks = useMemo(
		() => filterLifecycleHooks.reduce((prev, hookName) => ({ ...prev, [hookName]: [] }), {}),
		[filterLifecycleHooks],
	);
	const [filterHooks, setFilterHooks] = useState(initialFilterHooks);

	const reduxUrl = useMemo(() => tableData?.url ?? [], [tableData]);

	const updateReduxTableData = useCallback(
		newData => {
			dispatch(
				updateCurrentTableData({
					table,
					tableData: newData,
				}),
			);
		},
		[dispatch, table],
	);

	useSavedUrl(reduxUrl, updateReduxTableData);

	const { sanitizedQueryData, updateQuery, clearQuery } = useQueryFilterData(
		filtersData,
		reduxUrl,
		updateReduxTableData,
	);

	const filledFiltersCount = useMemo(() => {
		const countableFilters = Object.keys(sanitizedQueryData).filter(filter => !omitedFilters.includes(filter));

		return countableFilters.length;
	}, [sanitizedQueryData, omitedFilters]);

	const toggleOpen = useCallback(
		(name, isOpen) => {
			updateReduxTableData({
				...tableData,
				openedFilter: isOpen && openedFilter !== name ? name : '',
			});
		},
		[openedFilter, updateReduxTableData, tableData],
	);

	const filtersList = useMemo(() => {
		const initialFiltersList = [];
		filtersData.forEach(el => {
			initialFiltersList.push({
				...el,
				opened: openedFilter === el.name,
				selected: selectedFilters?.includes(el.name),
				toggleOpen: isOpen => toggleOpen(el.name, isOpen),
			});
		});

		return initialFiltersList;
	}, [filtersData, selectedFilters, openedFilter, toggleOpen]);

	const initialFiltersValues = useMemo(() => {
		setFilterHooks(initialFilterHooks);
		const initialFiltersValues = {};

		const bindedSerializer = filterValueSerializer.bind(undefined, sanitizedQueryData);

		filtersData.forEach(el => {
			Object.assign(initialFiltersValues, bindedSerializer(el));

			filterLifecycleHooks.forEach(hookName => {
				if (typeof el[hookName] === 'function') {
					setFilterHooks(prevHooks => {
						const newHookArray = [...prevHooks[hookName], el[hookName]];
						const filteredHookArray = removeDuplicateHooks(newHookArray);

						return { ...prevHooks, [hookName]: filteredHookArray };
					});
				}
			});
		});

		return initialFiltersValues;
	}, [initialFilterHooks, sanitizedQueryData, filtersData, filterLifecycleHooks]);

	const setFilterValue = useCallback(
		(filter, newValues) => {
			setFiltersValues(prevFilterValues => {
				const newFiltersValues = filterHooks[FILTER_LIFECYCLE_HOOKS.BEFORE_UPDATE_VALUE].reduce(
					(prevNewValues, hook) => hook(prevNewValues, prevFilterValues, filter),
					newValues,
				);

				const newFilterValues = {
					...prevFilterValues,
					...newFiltersValues,
				};

				const modifiedForQueryValues = filterHooks[FILTER_LIFECYCLE_HOOKS.BEFORE_UPDATE_QUERY].reduce(
					(prevNewValues, hook) => hook(prevNewValues, filter),
					newFilterValues,
				);

				updateQuery(modifiedForQueryValues);

				return newFilterValues;
			});
		},
		[filterHooks, updateQuery],
	);

	const onSelectFilter = optionName => {
		const newSelectedFilters = selectedFilters.includes(optionName)
			? [...pull(selectedFilters, optionName)]
			: [...selectedFilters, optionName];

		const newFiltersCollapsed = filtersCollapsed ? newSelectedFilters.length < selectedFilters.length : false;

		updateReduxTableData({
			...tableData,
			filtersCollapsed: newFiltersCollapsed,
			selectedFilters: newSelectedFilters,
		});
	};

	const onFilterCollapse = useCallback(() => {
		updateReduxTableData({
			...tableData,
			filtersCollapsed: !filtersCollapsed,
		});
	}, [filtersCollapsed, updateReduxTableData, tableData]);

	const resetFiltersValues = useCallback(
		clearSelectedFilters => {
			const clearedFilterValues = {};
			filtersData.forEach(el => {
				if (el.type === FILTER_TYPES.NUMBER) {
					clearedFilterValues[el.minName] = FILTER_DEFAULTS[FILTER_TYPES.NUMBER].minValue;
					clearedFilterValues[el.maxName] = FILTER_DEFAULTS[FILTER_TYPES.NUMBER].maxValue;
				} else if (el.type === FILTER_TYPES.DATE) {
					clearedFilterValues[el.startDateName] = FILTER_DEFAULTS[FILTER_TYPES.DATE].startDateValue;
					clearedFilterValues[el.endDateName] = FILTER_DEFAULTS[FILTER_TYPES.DATE].endDateValue;
				} else if (el.type === FILTER_TYPES.STAGE) {
					clearedFilterValues[el.stageStatus] = FILTER_DEFAULTS[FILTER_TYPES.STAGE].stageStatus;
					clearedFilterValues[el.minName] = FILTER_DEFAULTS[FILTER_TYPES.STAGE].minValue;
					clearedFilterValues[el.maxName] = FILTER_DEFAULTS[FILTER_TYPES.STAGE].maxValue;
				} else {
					clearedFilterValues[el.name] = FILTER_DEFAULTS[el.type];
				}
			});
			setFiltersValues(clearedFilterValues);
			clearQuery();

			if (clearSelectedFilters) {
				updateReduxTableData({
					selectedFilters: [],
				});
			}
		},
		[filtersData, clearQuery, updateReduxTableData],
	);

	// ! Should be executed once since initialFiltersValues is memoized and depends on filtersData which doesn't change
	useEffect(() => {
		setFiltersValues(initialFiltersValues);
	}, [initialFiltersValues]);

	useEffect(() => {
		dispatch(
			updateSelectedFilters({
				table,
				newSelectedFilters: convertSanitizedDataToSelectedFilters(sanitizedQueryData),
			}),
		);
	}, [dispatch, sanitizedQueryData, table]);

	return {
		filtersList,
		filtersValues,
		selectedFilters,
		filledFiltersCount,
		onFilterCollapse,
		onSelectFilter,
		setFilterValue,
		resetFiltersValues,
	};
};

export default useFiltersData;
