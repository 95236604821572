import api from 'Application/api/api';

const uploadInsuranceDocuments = async (signal, files, insuranceId, type) => {
	const response = await api.post(`/insurances/${insuranceId}/upload-document`, files, {
		signal,
		timeout: 100000,
		headers: {
			'Content-Type': 'multipart/form-data',
			Accept: '*/*',
		},
		params: {
			type: type,
		},
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
	};
};

export default uploadInsuranceDocuments;
