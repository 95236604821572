import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import crudModes from 'Common/constants/crudModes';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import FormikTextArea from 'Common/components/form/FormikTextArea';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';
import { OverlayButtons } from 'Common/components/modals/Overlay';

import getEmissionSpvs from 'Preferences/api/getEmissionSpvs';
import editEmissionSpv from 'Preferences/api/editEmissionSpv';
import createEmissionSpv from 'Preferences/api/createEmissionSpv';
import useEmissionSpvFormInitialValues from './hooks/useEmissionSpvFormInitialValues';
import useEmissionSpvFormValidationSchema from './hooks/useEmissionSpvFormValidationSchema';
import convertFormValuesToRequestData from './utils/convertFormValuesToRequestData';
import EmissionSpvBalanceTable from './EmissionSpvBalanceTable';
import EmissionSpvProfitTable from './EmissionSpvProfitTable';
import useAbortController from 'Common/hooks/useAbortController';
import loadCountryOptions from 'Country/utils/loadCountryOptions';
import axios from 'axios';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const FieldsWrapper = styled.div`
	width: 100%;
`;

const EmissionSpvForm = ({ data, mode, onSubmit, onCancel, onDirty }) => {
	const isInEditMode = mode === crudModes.CREATE;
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);

	const abortController = useAbortController();

	const initialValues = useEmissionSpvFormInitialValues(data);
	const validationSchema = useEmissionSpvFormValidationSchema();

	const {
		errors,
		touched,
		values,
		handleSubmit,
		dirty,
		handleChange,
		handleBlur,
		setFieldError,
		setFieldValue,
		resetForm,
	} = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				if (mode === crudModes.CREATE) {
					await createEmissionSpv(abortController.signal, {
						...convertFormValuesToRequestData(values),
					});

					notify(t('Emission SPV created successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				} else if (mode === crudModes.EDIT) {
					await editEmissionSpv(abortController.signal, {
						...data,
						...convertFormValuesToRequestData(values),
					});

					notify(t('Emission SPV saved successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				}

				const response = await getEmissionSpvs(abortController.signal);
				onSubmit(response.data);

				// Re-set all fields and mark them as not touched
				resetForm({ values });
				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form onSubmit={handleSubmit}>
			<FieldsWrapper>
				<FormField>
					<FormikInput
						id="name"
						name="name"
						label={t('Name')}
						value={values.name}
						error={errors.name}
						touched={touched.name}
						onChange={handleChange}
						onBlur={handleBlur}
						isRequired
						isInEditMode={isInEditMode}
						isOverlay
					/>
				</FormField>
				<FormField>
					<FormikInput
						id="abbreviation"
						name="abbreviation"
						label={t('Abbreviation')}
						value={values.abbreviation}
						error={errors.abbreviation}
						touched={touched.abbreviation}
						onChange={handleChange}
						onBlur={handleBlur}
						isRequired
						isInEditMode={isInEditMode}
						isOverlay
					/>
				</FormField>
				<FormField>
					<FormikSelect
						id="countryIds"
						name="countryIds"
						label={t('Countries')}
						value={values.countryIds}
						error={errors.countryIds}
						touched={touched.countryIds}
						setFieldValue={setFieldValue}
						onBlur={handleBlur}
						loadOptions={loadCountryOptions}
						isAsync
						isMulti
						isClearable
						isInEditMode={true}
						isOverlay
					/>
				</FormField>
				<FormField>
					<FormikTextArea
						id="description"
						name="description"
						label={t('Description')}
						value={values.description}
						error={errors.description}
						touched={touched.description}
						onChange={handleChange}
						onBlur={handleBlur}
						isInEditMode={true}
						isOverlay
					/>
				</FormField>
				{mode === crudModes.EDIT && (
					<div>
						<FormField>
							<EmissionSpvBalanceTable emissionSpv={initialValues} />
						</FormField>
						<FormField>
							<EmissionSpvProfitTable emissionSpv={initialValues} />
						</FormField>
					</div>
				)}
			</FieldsWrapper>
			<OverlayButtons
				onCancel={onCancel}
				isLoading={isLoading}
				label="Preferences Emission SPV Form"
				submitButtonText={mode === crudModes.CREATE ? t('Create') : t('Save')}
			/>
		</Form>
	);
};

EmissionSpvForm.defaultProps = {
	data: null,
};

EmissionSpvForm.propTypes = {
	data: PropTypes.shape({}),
	mode: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
};

export default EmissionSpvForm;
