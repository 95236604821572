import { useTranslation } from 'react-i18next';
import FormikInput from 'Common/components/form/FormikInput';
import { components } from 'react-select';
import { PropTypes } from 'prop-types';
import { SectionWrapper } from 'Common/components/modals/OverlaySections';
import { Grid } from '@mui/material';
import ComparableFormField from 'Common/components/form/ComparableFormField';
import UNITS from 'Common/constants/units';
import Big from 'big.js';
import crudModes from 'Common/constants/crudModes';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

const SelectInput = ({ selectProps: { onPaste }, ...props }) => (
	<components.Input {...props} onPaste={onPaste} />
);

SelectInput.defaultProps = {
	selectProps: {
		onPaste: () => null,
	},
};

SelectInput.propTypes = {
	selectProps: PropTypes.shape({
		onPaste: PropTypes.func,
	}),
};

const EcoligoResultsSection = ({ errors, touched, values, handleChange, handleBlur, mode }) => {
	const { t } = useTranslation();

	return mode !== crudModes.VIEW ? (
		<SectionWrapper>
			<Grid container columnSpacing={10}>
				<Grid item xs={12} md={6}>
					<ComparableFormField
						$compareValue={
							values.compareOffer && values.primaryOfferData.irr
								? `${Big(values.primaryOfferData.irr).times(100).toString()} %`
								: ''
						}
						data-first-field
					>
						<FormikInput
							unit={UNITS.PERCENT}
							label={t('Projected IRR')}
							id="irr"
							name="irr"
							error={errors?.irr}
							touched={touched?.irr}
							value={values?.irr}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</ComparableFormField>
					<ComparableFormField $compareValue={values.compareOffer ? values.primaryOfferData.minimumDscr : ''}>
						<FormikInput
							label={t('Minimum DSCR')}
							id="minimumDscr"
							name="minimumDscr"
							error={errors?.minimumDscr}
							touched={touched?.minimumDscr}
							value={values?.minimumDscr}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</ComparableFormField>
					<ComparableFormField $compareValue={values.compareOffer ? values.primaryOfferData.averageDscr : ''}>
						<FormikInput
							label={t('Average DSCR')}
							id="averageDscr"
							name="averageDscr"
							error={errors?.averageDscr}
							touched={touched?.averageDscr}
							value={values?.averageDscr}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</ComparableFormField>
					<ComparableFormField
						$compareValue={
							values.compareOffer && values.primaryOfferData.worstCaseIrr
								? `${Big(values.primaryOfferData.worstCaseIrr).times(100).toString()} %`
								: ''
						}
						data-last-field
					>
						<FormikInput
							unit={UNITS.PERCENT}
							label={t('Worst Case IRR')}
							id="worstCaseIrr"
							name="worstCaseIrr"
							error={errors?.worstCaseIrr}
							touched={touched?.worstCaseIrr}
							value={values?.worstCaseIrr}
							onChange={handleChange}
							onBlur={handleBlur}
							isOverlay
						/>
					</ComparableFormField>
				</Grid>
			</Grid>
		</SectionWrapper>
	) : (
		<SectionWrapper>
			<Grid container columnSpacing={10}>
				<Grid item xs={12} md={6}>
					<KeyValueVisualization id="irr" title={t('Projected IRR')} value={values.irr} unit={UNITS.PERCENT} />
					<KeyValueVisualization id="minimumDscr" title={t('Minimum DSCR')} value={values.minimumDscr} />
					<KeyValueVisualization id="averageDscr" title={t('Average DSCR')} value={values.averageDscr} />
					<KeyValueVisualization
						id="worstCaseIrr"
						title={t('Worst Case IRR')}
						value={values.worstCaseIrr}
						unit={UNITS.PERCENT}
					/>
				</Grid>
			</Grid>
		</SectionWrapper>
	);
};

EcoligoResultsSection.defaultProps = {
	selectedPrimaryOption: {},
};

EcoligoResultsSection.propTypes = {
	selectedPrimaryOption: PropTypes.shape({
		name: PropTypes.string,
	}),
	errors: PropTypes.shape({
		irr: PropTypes.string,
		minimumDscr: PropTypes.string,
		averageDscr: PropTypes.string,
		worstCaseIrr: PropTypes.string,
	}).isRequired,
	touched: PropTypes.shape({
		irr: PropTypes.bool,
		minimumDscr: PropTypes.bool,
		averageDscr: PropTypes.bool,
		worstCaseIrr: PropTypes.bool,
	}).isRequired,
	values: PropTypes.shape({
		irr: PropTypes.string,
		minimumDscr: PropTypes.string,
		averageDscr: PropTypes.string,
		worstCaseIrr: PropTypes.string,
		compareOffer: PropTypes.bool,
		primaryOfferData: PropTypes.shape({
			irr: PropTypes.string,
			minimumDscr: PropTypes.number,
			averageDscr: PropTypes.number,
			worstCaseIrr: PropTypes.string,
		}),
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	handleBlur: PropTypes.func.isRequired,
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
};

export default EcoligoResultsSection;
