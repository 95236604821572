import AccordionSummary from '@mui/material/AccordionSummary';
import useReponsive from 'Common/hooks/useResponsive';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { forwardRef } from 'react';
import { PropTypes } from 'prop-types';

const _StyledAccordionSummary = styled(AccordionSummary)`
	&.MuiAccordionSummary-root {
		margin: 0px;
		padding: 0px 16px 0px 23px;
		min-height: 42px;
		max-height: 42px;
		align-items: center;

		${({ $isMobile }) =>
			$isMobile &&
			css`
				padding: 0px 8px;
				min-height: 35px;
				max-height: 35px;
			`}
	}

	.MuiAccordionSummary-content {
		justify-content: space-between;
		align-items: center;
	}

	&.MuiAccordionSummary-root.Mui-expanded {
		min-height: 44px;
		max-height: 44px;
	}
`;

const StyledAccordionSummary = forwardRef(({ children, ...props }, ref) => {
	const { isMobile } = useReponsive();

	return (
		<_StyledAccordionSummary ref={ref} $isMobile={isMobile} {...props}>
			{children}
		</_StyledAccordionSummary>
	);
});

StyledAccordionSummary.displayName = 'StyledAccordionSummary';

StyledAccordionSummary.propTypes = {
	children: PropTypes.node.isRequired,
};

export default StyledAccordionSummary;
