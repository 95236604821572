import KeyValueVisualization from '../KeyValueVisualization';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import Link from '../Link';
import colors from 'Application/theme/colors';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import showToastError from 'Common/utils/showToastError';
import notify from 'Common/utils/notify';
import { FILE_TYPES } from 'Common/constants/fileTypes';
import useFileUpload from 'Common/hooks/useFileUpload';
import { Icon } from 'Common/stories/icons/Icon.stories';
import sizes from 'Application/theme/sizes';
import MoreActionsButton from '../buttons/MoreActionsButton';
import MenuItem from '../buttons/MenuItemButton';
import Label from 'Common/components/form/Label';
import { useLabelColor } from './hooks/useLabelColor';
import ErrorMessage from 'Common/components/form/ErrorMessage';
import DocumentHistoryOverlay from './DocumentHistoryOverlay';
import getPresignedUrlForView from 'Application/api/getPresignedUrlForView';
import useAbortController from 'Common/hooks/useAbortController';

const disabledStyles = css`
	input {
		cursor: not-allowed;
		color: ${colors.text.greyLight};
	}
	label {
		cursor: not-allowed;
		color: ${colors.text.greyLight};
	}
`;
const FieldWrapper = styled.div`
	width: 90%;
	min-height: ${({ $hasValue }) => ($hasValue ? '80px' : '50px')};
	position: relative;
	${({ $isDisabled }) => $isDisabled && disabledStyles}
`;

const DropArea = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border: 2px dashed ${colors.text.black};
`;

const ValueContainer = styled.div``;

const FileInfoContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	gap: ${sizes.spacing(0.5)};

	&:hover {
		> .more-actions-button.controlledVisibility {
			visibility: visible;
			opacity: 1;
		}
	}
`;
const FileIcon = styled(Icon)``;
const FileName = styled.div``;

const UploadDocumentLink = styled(Link)``;

const FileUploadField = ({
	value,
	name,
	error,
	touched,
	hideErrors,
	setFieldValue,
	setFieldTouched,
	label,
	isMulti,
	displayValue,
	fileName,
	acceptedTypes,
	documents,
	onDocumentDownload,
	breadcrumbList,
	onBlur,
	onFocus,
	onMouseLeave,
	onMouseEnter,
	isTile,
	isDisabled,
	isInEditMode,
	isHighlighted,
	isRequired,
}) => {
	const hasError = Boolean(error) && touched;
	const [hasFocus, setHasFocus] = useState(false);
	const showError = hasError && !hideErrors && touched;
	const [hasHover, setHasHover] = useState(false);
	const isReadMode = useMemo(() => isTile && !isInEditMode, [isTile, isInEditMode]);
	const { t } = useTranslation();
	const hasValue = useMemo(() => Boolean(value) && (isMulti ? value.length > 0 : true), [value, isMulti]);
	const [isDocumentHistoryOverlayOpen, setIsDocumentHistoryOverlayOpen] = useState(false);

	const handleDocumentUpload = useCallback(
		async ({ files }) => {
			try {
				setFieldValue(name, isMulti ? files : files[0]);
				setFieldTouched(name, true);
				notify('Document uploaded succesfully', {
					type: toast.TYPE.SUCCESS,
				});
			} catch (e) {
				showToastError(e, t("Couldn't upload document"));
			}
		},

		[t, isMulti, name, setFieldValue, setFieldTouched],
	);

	const handleOnFocus = (e, ...args) => {
		if (!isReadMode) {
			setHasFocus(true);
			typeof onFocus === 'function' && onFocus(e, ...args);
		} else {
			e.target.blur();
		}
	};

	const handleOnBlur = (...args) => {
		if (!isReadMode) {
			setHasFocus(false);
			typeof setFieldTouched === 'function' && setFieldTouched(name, true);
			typeof onBlur === 'function' && onBlur(...args);
		}
	};

	const handleOpenDocumentHistory = useCallback(() => {
		setIsDocumentHistoryOverlayOpen(true);
	}, []);

	const {
		handleAddFile: handleAddDocument,
		generateRootProps,
		isDragActive,
		generateInputProps,
	} = useFileUpload({
		onFileUpload: handleDocumentUpload,
		fileName,
		acceptedTypes,
		multi: isMulti,
	});

	const labelColor = useLabelColor({
		isDisabled,
		isHighlighted,
		isInEditMode,
		hasHover,
		hasError,
	});

	const handleMouseEnter = (...args) => {
		setHasHover(true);
		typeof onMouseEnter === 'function' && onMouseEnter(...args);
	};

	const handleMouseLeave = (...args) => {
		setHasHover(false);
		typeof onMouseLeave === 'function' && onMouseLeave(...args);
	};

	const abortController = useAbortController();
	const handleDocumentView = async displayName => {
		try {
			const file = documents.find(doc => doc.displayName === displayName);
			const response = await getPresignedUrlForView(abortController.signal, file.name, file.versionId);
			window.open(response.data, '_blank');
		} catch (error) {
			showToastError(error);
		}
	};

	return (
		<FieldWrapper
			{...generateRootProps()}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			$hasValue={hasValue}
			$hasError={hasError}
			$hasFocus={hasFocus}
		>
			{isDragActive ? (
				<DropArea>Drop the file here</DropArea>
			) : (
				<>
					<Label label={label} isRequired={isTile ? isInEditMode && isRequired : isRequired} color={labelColor} />
					<KeyValueVisualization
						value={
							<ValueContainer>
								{(hasValue || displayValue) && (
									<>
										<FileInfoContainer>
											<FileIcon icon={FILE_TYPES.DOCX} />
											<FileName>{hasValue ? value.name : displayValue}</FileName>
											{!isDisabled ? (
												<MoreActionsButton noBackground controlledVisibility>
													<MenuItem onClick={() => handleDocumentView(displayValue)} type="button" data-action="view-file">
														{t('View')}
													</MenuItem>
													<MenuItem onClick={handleAddDocument} type="button" data-action="upload-file">
														{t('Upload file	')}
													</MenuItem>
													<MenuItem onClick={handleOpenDocumentHistory} type="button" data-action="open-history">
														{t('Document history')}
													</MenuItem>
												</MoreActionsButton>
											) : null}
										</FileInfoContainer>
									</>
								)}
								{!isDisabled ? (
									<UploadDocumentLink onClick={handleAddDocument} color={colors.common.darkBlue}>
										Upload document
									</UploadDocumentLink>
								) : null}

								<input {...generateInputProps()} />
							</ValueContainer>
						}
						onBlur={handleOnBlur}
						onFocus={handleOnFocus}
					/>
				</>
			)}
			{showError && <ErrorMessage message={error} />}

			<DocumentHistoryOverlay
				isOpen={isDocumentHistoryOverlayOpen}
				onClose={() => setIsDocumentHistoryOverlayOpen(false)}
				documents={documents}
				onDocumentDownload={onDocumentDownload}
				breadcrumbList={breadcrumbList}
			/>
		</FieldWrapper>
	);
};

FileUploadField.defaultProps = {
	value: null,
	name: '',
	label: '',
	error: '',
	isMulti: false,
	touched: false,
	displayValue: '',
	fileName: '',
	acceptedTypes: [],
	setFieldValue: () => {},
	setFieldTouched: () => {},
	documents: [],
	onDocumentDownload: () => {},
	breadcrumbList: [],
	onMouseLeave: () => {},
	onMouseEnter: () => {},
	onBlur: () => {},
	onFocus: () => {},
	hideErrors: false,
	isTile: false,
	isDisabled: false,
	isInEditMode: false,
	isHighlighted: false,
	isRequired: false,
};

FileUploadField.propTypes = {
	name: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.shape({
			name: PropTypes.string,
		}),
		PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
			}),
		),
	]),
	label: PropTypes.string,
	isMulti: PropTypes.bool,
	displayValue: PropTypes.string,
	fileName: PropTypes.string,
	acceptedTypes: PropTypes.arrayOf(PropTypes.string),
	setFieldValue: PropTypes.func,
	setFieldTouched: PropTypes.func,
	documents: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			versionId: PropTypes.string,
		}),
	),
	onDocumentDownload: PropTypes.func,
	breadcrumbList: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			href: PropTypes.string,
		}),
	),
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
	error: PropTypes.string,
	hideErrors: PropTypes.bool,
	isTile: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isInEditMode: PropTypes.bool,
	isHighlighted: PropTypes.bool,
	isRequired: PropTypes.bool,
	onMouseLeave: PropTypes.func,
	onMouseEnter: PropTypes.func,
};

export default FileUploadField;
