import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	min-width: 300px;
	width: ${props => props.width};
`;

const EditableFieldset = ({ customWidth, children }) => <Wrapper width={customWidth}>{children}</Wrapper>;

EditableFieldset.defaultProps = {
	customWidth: undefined,
};

EditableFieldset.propTypes = {
	customWidth: PropTypes.number,
	children: PropTypes.node.isRequired,
};

export default EditableFieldset;
