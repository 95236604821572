import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import {
	categories,
	documentTableOptions,
	formattedCategories,
	formattedDocumentTypes,
} from 'Projects/constants/documentTableOptions';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import {
	DefaultTable,
	TableBodyCell,
	TableBodyGroupCell,
	TableHeadCell,
	TableHeadRow,
} from 'Common/components/table/table';
import { useCustomRowStyle } from 'Common/hooks/table/plugin-hooks/useCustomRowStyle';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import { PropTypes } from 'prop-types';

const Wrapper = styled.div`
	position: relative;
	width: 100%;
`;

const TableWrapper = styled.div`
	${({ isLoading }) =>
		isLoading &&
		css`
			opacity: 0.3;
		`};

	${TableHeadCell}:not(${TableBodyGroupCell}) {
		background: transparent;
	}

	${TableBodyCell} {
		height: auto;
		background: transparent;
	}

	${TableHeadRow} {
		overflow: visible;
	}
`;

const StyledCheckbox = styled(FormikCheckbox)`
	.MuiButtonBase-root {
		margin-left: 0;
	}
`;

const Description = styled.p`
	margin-block-end: 0;
`;

const RfepDocumentsSection = ({ handleChange, handleBlur, values }) => {
	const { t } = useTranslation();

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Document'),
				accessor: 'document',
				minWidth: 700,
				customBodyCellStyles: {},
				customBodyCellContentStyles: {
					overflow: 'initial',
					display: 'flex',
					flexDirection: 'column',
					padding: '16px 0',
				},
			},
			{
				Header: t('Request'),
				accessor: 'request',
				minWidth: 100,
				width: 150,
				cellStyle: {
					display: 'flex',
					alignItems: 'center',
				},
			},
			{
				Header: t('Mandatory'),
				accessor: 'mandatory',
				minWidth: 100,
				width: 150,
				cellStyle: {
					display: 'flex',
					alignItems: 'center',
				},
			},
		],
		[t],
	);

	const data = useMemo(
		() =>
			documentTableOptions.map(documentType => ({
				bodyGroupIds: [documentType.group],
				document: (
					<>
						<span>
							<b>{formattedDocumentTypes[documentType.documentTitle]}</b>
						</span>
						<Description>{documentType.description}</Description>
					</>
				),
				request: (
					<StyledCheckbox
						id={`${documentType.documentTitle}_request`}
						name={`${documentType.documentTitle}_request`}
						checked={!!values[`${documentType.documentTitle}_request`]?.length > 0}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
				),
				mandatory: (
					<StyledCheckbox
						id={`${documentType.documentTitle}_mandatory`}
						name={`${documentType.documentTitle}_mandatory`}
						checked={
							values[`${documentType.documentTitle}_request`]?.length > 0
								? values[`${documentType.documentTitle}_mandatory`] !== undefined
									? values[`${documentType.documentTitle}_mandatory`]?.length > 0
									: true
								: false
						}
						onChange={handleChange}
						onBlur={handleBlur}
						disabled={
							!values[`${documentType.documentTitle}_request`] ||
							values[`${documentType.documentTitle}_request`]?.length === 0
						}
					/>
				),
			})),
		[handleBlur, handleChange, values],
	);

	const { getTableProps, getTableHeaderProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useStandardTable(
			{
				data,
				columns,
				getCustomRowStyles: () => ({
					height: 'auto',
				}),
			},
			useCustomRowStyle,
			useCustomCellStyles,
		);

	return (
		<Wrapper>
			<TableWrapper>
				<DefaultTable
					getTableProps={getTableProps}
					getTableHeaderProps={getTableHeaderProps}
					getTableBodyProps={getTableBodyProps}
					rows={rows}
					prepareRow={prepareRow}
					headerGroups={headerGroups}
					bodyGroups={[
						{
							id: categories.SCHEDULE_AND_OFFER,
							name: formattedCategories[categories.SCHEDULE_AND_OFFER],
						},
						{
							id: categories.TECHNICAL_DOCUMENTATION,
							name: formattedCategories[categories.TECHNICAL_DOCUMENTATION],
						},
					]}
				/>
			</TableWrapper>
		</Wrapper>
	);
};

RfepDocumentsSection.propTypes = {
	handleChange: PropTypes.func.isRequired,
	handleBlur: PropTypes.func.isRequired,
	values: PropTypes.shape({}).isRequired,
};

export default RfepDocumentsSection;
