import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';
import EpcOffersListTable from 'EpcPartner/components/EpcPartnerPortal/EpcOffersList/EpcOffersListTable';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';

const Title = styled.div`
	font-size: 26px;
	font-weight: 600;
	color: ${colors.text.primary};
	text-transform: uppercase;
`;

const EpcOffersList = () => {
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<title>{t('Offers')}</title>
			</Helmet>
			<HeaderContainer>
				<Title>{t('Offers')}</Title>
				<HeaderActions>
					<QuestionTooltip tooltip="Current and past Project opportunities" />
				</HeaderActions>
			</HeaderContainer>
			<EpcOffersListTable />
		</>
	);
};

export default EpcOffersList;
