import Grid from '@mui/material/Grid';
import { roleGroups, rolesByRoleGroup, roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import SalesChannelList from 'Preferences/components/Tabs/GeneralTab/SalesChannel/SalesChannelList';
import MarketSegmentsList from 'Preferences/components/Tabs/GeneralTab/MarketSegments/MarketSegmentsList';

import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import ProjectDefaultsTile from './ProjectDefaults/ProjectDefaultsTile';
import CO2EquivalentsTile from './CO2Equivalents/CO2EquivalentsTile';

import AccountingStandardsList from 'AccountingStandards/components/List/AccountingStandardsList';
import AssetAndRevenueRecognitionList from 'AssetAndRevenueRecognition/components/List/AssetAndRevenueRecognitionList';
import PartnerTypesList from 'Preferences/components/Tabs/GeneralTab/PartnerTypes/PartnerTypesList';

const GeneralTab = () => (
	<TilesGridWrapper>
		{isAuthorized([
			roles.ADMIN,
			roles.FINANCE,
			roles.MANAGEMENT,
			roles.SALES,
			roles.PM_TECHNICAL,
			roles.ASSET_MANAGER,
			roles.HEAD_OF_ESG,
		]) && (
			<Grid data-form="projectDefaults" item xs={12} sm={12} md={6} lg={5} xl={4}>
				<ProjectDefaultsTile />
			</Grid>
		)}

		{isAuthorized([
			roles.ADMIN,
			roles.FINANCE,
			roles.MANAGEMENT,
			roles.SALES,
			roles.PM_TECHNICAL,
			roles.ASSET_MANAGER,
			roles.HEAD_OF_ESG,
		]) && (
			<Grid data-form="co2equivalents" item xs={12} sm={12} md={6} lg={5} xl={4}>
				<CO2EquivalentsTile />
			</Grid>
		)}

		{isAuthorized(rolesByRoleGroup[roleGroups.ECOLIGO]) && (
			<Grid data-form="partnerTypes" item xs={12} sm={12} md={6} lg={5} xl={4}>
				<PartnerTypesList />
			</Grid>
		)}

		{isAuthorized(rolesByRoleGroup[roleGroups.ECOLIGO]) && (
			<Grid data-form="accountingStandards" item xs={12} sm={12} md={6} lg={5} xl={4}>
				<AccountingStandardsList />
			</Grid>
		)}

		{isAuthorized(rolesByRoleGroup[roleGroups.ECOLIGO]) && (
			<Grid data-form="assetAndRevenueRecognitions" item xs={12} sm={12} md={6} lg={5} xl={4}>
				<AssetAndRevenueRecognitionList />
			</Grid>
		)}

		{isAuthorized([roles.ADMIN, roles.FINANCE, roles.MANAGEMENT, roles.SALES]) && (
			<Grid data-form="salesChannels" item xs={12} sm={12} md={6} lg={5} xl={4}>
				<SalesChannelList />
			</Grid>
		)}

		{isAuthorized([
			roles.ADMIN,
			roles.FINANCE,
			roles.MANAGEMENT,
			roles.SALES,
			roles.ASSET_MARKETING,
			roles.HEAD_OF_ESG,
		]) && (
			<Grid data-form="marketSegments" item xs={12} sm={12} md={6} lg={5} xl={4}>
				<MarketSegmentsList />
			</Grid>
		)}
	</TilesGridWrapper>
);

export default GeneralTab;
