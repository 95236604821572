import { useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import { useFormik } from 'formik';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import Button from 'Common/components/buttons/Button';
import useConfirmPasswordResetFormValidationSchema from 'User/components/ConfirmPasswordReset/ConfirmPasswordResetForm/hooks/useConfirmPasswordResetFormValidationSchema';
import useConfirmPasswordResetFormInitialValues from 'User/components/ConfirmPasswordReset/ConfirmPasswordResetForm/hooks/useConfirmPasswordResetFormInitialValues';
import login from 'User/services/login';
import confirmPasswordReset from 'User/api/confirmPasswordReset';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';
import Icon from 'Common/components/icons/Icon';
import colors from 'Application/theme/colors';
import useAbortController from 'Common/hooks/useAbortController';

const ButtonWrapper = styled.div`
	display: flex;
	height: 100px;
	align-items: flex-end;
	justify-content: flex-end;
	padding-top: 132px;
`;

const StyledFormField = styled(FormField)`
	position: relative;
	margin-bottom: 32px;
`;

const IconContainer = styled.div`
	position: absolute;
	right: 5px;
	bottom: 5px;
`;

const ConfirmPasswordResetForm = () => {
	const { t } = useTranslation();
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

	const abortController = useAbortController();

	const email = useQueryParameter('email');
	const code = useQueryParameter('code');

	const initialValues = useConfirmPasswordResetFormInitialValues({});
	const validationSchema = useConfirmPasswordResetFormValidationSchema();

	const { errors, touched, values, handleSubmit, handleChange, handleBlur, setFieldError } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				await confirmPasswordReset(abortController.signal, email, code, values.password, values.passwordConfirm);

				await login(abortController.signal, email, values.password, true);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
					toastFields: ['username', 'code'],
					fieldsMapping: {
						username: 'E-mail',
						code: 'Code',
					},
				});
			}
		},
	});

	return (
		<form onSubmit={handleSubmit}>
			<StyledFormField>
				<FormikInput
					id="password"
					name="password"
					inputType={showPassword ? 'text' : 'password'}
					label={t('New Password')}
					value={values.password}
					error={errors.password}
					touched={touched.password}
					onChange={handleChange}
					onBlur={handleBlur}
					placeholder="Password"
				/>
				<IconContainer>
					<Icon
						icon="removeRedEyeOutlined"
						color={showPassword ? colors.primary.main : ''}
						onClick={() => setShowPassword(prev => !prev)}
					/>
				</IconContainer>
			</StyledFormField>
			<StyledFormField>
				<FormikInput
					id="passwordConfirm"
					name="passwordConfirm"
					inputType={showPasswordRepeat ? 'text' : 'password'}
					label={t('Confirm Password')}
					value={values.passwordConfirm}
					error={errors.passwordConfirm}
					touched={touched.passwordConfirm}
					onChange={handleChange}
					onBlur={handleBlur}
					placeholder="Confirm Password"
				/>
				<IconContainer>
					<Icon
						icon="removeRedEyeOutlined"
						color={showPasswordRepeat ? colors.primary.main : ''}
						onClick={() => setShowPasswordRepeat(prev => !prev)}
					/>
				</IconContainer>
			</StyledFormField>
			<ButtonWrapper>
				<Button type="submit" text={t('Reset Password')} label="Confirm Password Resset Form - Reset Button" />
			</ButtonWrapper>
		</form>
	);
};

export default ConfirmPasswordResetForm;
