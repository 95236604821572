import api from 'Application/api/api';
import convertToNullableValues from 'Common/utils/convertToNullableValues';
import adaptClientOfferResponseData from './adapters/adaptClientOfferResponseData';

const editClientOffer = async (signal, id, values) => {
	const nullableValues = convertToNullableValues(values, ['epcOfferId', 'epcVat', 'p90', 'primaryOffer']);

	const response = await api.patch(`/client-offers/${id}`, nullableValues, {
		signal,
		timeout: 100000,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptClientOfferResponseData(response.data),
	};
};

export default editClientOffer;
