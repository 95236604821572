import removeTrailingZeros from 'Common/utils/removeTrailingZeros';

const convertFormValuesToRequestData = values => ({
	minimum_volume: removeTrailingZeros(values?.minimum_volume),
	maximum_volume: removeTrailingZeros(values?.maximum_volume),
	externalVolume: removeTrailingZeros(values?.externalVolume),
	...values,
});

export default convertFormValuesToRequestData;
