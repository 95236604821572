import { useMemo } from 'react';
import { pick } from 'lodash';

const fallbackValues = {
	email: '',
	password: '',
};

const useLoginFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['email', 'password']),
		}),
		[data],
	);

export default useLoginFormInitialValues;
