import api from 'Application/api/api';

const getContactById = (signal, id, details) =>
	api.get(`/contacts/by-id/${id}`, {
		params: {
			details,
		},
		signal,
	});

export default getContactById;
