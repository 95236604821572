import api from 'Application/api/api';

const getAssetAndRevenueRecognition = (signal, query) =>
	api.get('/assets-and-revenue-recognitions', {
		signal,
		params: {
			name: query,
		},
		timeout: 100000,
	});

export default getAssetAndRevenueRecognition;
