import { pick, find } from 'lodash';
import Big from 'Common/utils/customBig';

const convertFormValuesToRequestData = (values, financeDetailsId) => ({
	systemSize: values.financeDetailsProjects.reduce(
		(sum, item) => Number(Big(sum).add(Number(item.systemSizeAllocatedValue)).toString()),
		0,
	),
	assignProjectsToFinanceDto: {
		financeDetailsProjects: values.financeDetailsProjects.map(project => ({
			...pick(project, ['systemSizeAllocatedValue', 'projectId']),
			annualCo2SavingsEditable: find(project.financeDetailsProjects, {
				financeDetailsId,
			})?.annualCo2SavingsEditable,
			financeDetailsId,
		})),
	},
});

export default convertFormValuesToRequestData;
