import React, { useState } from 'react';
import UserInitialsCircle from 'Common/components/usersPreview/UserInitialsCircle';
import styled from 'styled-components/macro';
import Icon from 'Common/components/icons/Icon';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';
import { ClickAwayListener } from '@mui/material';
import Loader from '../Loader';
import { css } from 'styled-components';
import { PropTypes } from 'prop-types';

const Avatar = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
`;

const UploadPhoto = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${colors.common.darkBlue};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${colors.common.white};
	position: relative;
	cursor: ${({ isNormalCursor }) => (isNormalCursor ? 'initial' : 'pointer')};

	${({ $isUploading }) =>
		$isUploading &&
		css`
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				border-radius: 50%;
				background-color: rgba(0, 0, 0, 0.3);
				z-index: 1;
			}
		`}
`;

const LoaderContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
`;

const PopupOptions = styled.div`
	position: absolute;
	left: 50%;
	top: 70%;
	width: max-content;
	display: ${props => (props.isOpen ? 'block' : 'none')};
	color: ${colors.common.black};
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	background-color: ${colors.common.white};
	transition: opacity 0.2s linear;
	overflow: hidden;
	z-index: 1;
`;

const PopupOption = styled.div`
	padding: ${sizes.spacing(2)};

	&:hover {
		background-color: ${colors.primary.dark};
		color: ${colors.common.white};
	}
`;

const UserAvatar = ({
	imgUrl,
	uploadAvailable,
	onUpload,
	onRemovePhoto,
	isNormalCursor,
	isUploading,
	...props
}) => {
	const [isOpenPopupOptions, setIsOpenPopupOptions] = useState(false);

	if (!imgUrl) {
		if (uploadAvailable) {
			return (
				<ClickAwayListener onClickAway={() => isOpenPopupOptions && setIsOpenPopupOptions(false)}>
					<UploadPhoto
						onClick={() => setIsOpenPopupOptions(prev => !prev)}
						isNormalCursor={isNormalCursor}
						$isUploading={isUploading}
					>
						{isUploading && (
							<LoaderContainer>
								<Loader />
							</LoaderContainer>
						)}
						<Icon icon="photoCameraOutlined" size="big" />
						<PopupOptions isOpen={isOpenPopupOptions}>
							<PopupOption onClick={onUpload}>Upload photo</PopupOption>
						</PopupOptions>
					</UploadPhoto>
				</ClickAwayListener>
			);
		} else {
			return (
				<UploadPhoto onClick={() => setIsOpenPopupOptions(prev => !prev)} isNormalCursor={isNormalCursor}>
					<UserInitialsCircle isNormalCursor={isNormalCursor} {...props} />
				</UploadPhoto>
			);
		}
	}

	return (
		<ClickAwayListener onClickAway={() => isOpenPopupOptions && setIsOpenPopupOptions(false)}>
			<UploadPhoto isNormalCursor={isNormalCursor} $isUploading={isUploading}>
				{isUploading && (
					<LoaderContainer>
						<Loader />
					</LoaderContainer>
				)}
				<Avatar src={imgUrl} onClick={() => setIsOpenPopupOptions(prev => !prev)} />

				<PopupOptions isOpen={isOpenPopupOptions && uploadAvailable}>
					<PopupOption
						onClick={(...args) => {
							setIsOpenPopupOptions(false);
							onUpload(...args);
						}}
					>
						Upload new photo
					</PopupOption>
					<PopupOption
						onClick={() => {
							setIsOpenPopupOptions(false);
							onRemovePhoto();
						}}
					>
						Remove photo
					</PopupOption>
				</PopupOptions>
			</UploadPhoto>
		</ClickAwayListener>
	);
};

UserAvatar.defaultProps = {
	imgUrl: '',
	uploadAvailable: false,
	onUpload: () => null,
	onRemovePhoto: () => null,
	isNormalCursor: false,
	isUploading: false,
};

UserAvatar.propTypes = {
	imgUrl: PropTypes.string,
	uploadAvailable: PropTypes.bool,
	onUpload: PropTypes.func,
	onRemovePhoto: PropTypes.func,
	isNormalCursor: PropTypes.bool,
	isUploading: PropTypes.bool,
};

export default UserAvatar;
