import { contractTypes } from 'Projects/components/ProjectDetails/Tabs/SalesTab/Tiles/ClientContract/constants/contractTypes';

const formatInvoiceField = number => Math.ceil(number * 1000) / 1000;

const calculateTaxes = (
	subTotalExcludingTax,
	countryVatPercentage,
	countryWhtPercentage,
	countryWhtVatPercentage,
	countryNHILPercentage,
	countryCovid19LevyPercentage,
	countryGetFundLevyPercentage,
	taxFree = false,
	isGhana,
) => {
	const maintenanceFee = subTotalExcludingTax * 0.2;
	if (taxFree) {
		return {
			nhil: 0,
			covid19Levy: 0,
			getFundLevy: 0,
			subTotalTaxableValue: subTotalExcludingTax,
			vat: 0,
			wht: 0,
			whtVat: 0,
			maintenanceFee,
		};
	}

	const nhil = (subTotalExcludingTax * countryNHILPercentage) / 100;
	const covid19Levy = (subTotalExcludingTax * countryCovid19LevyPercentage) / 100;
	const getFundLevy = (subTotalExcludingTax * countryGetFundLevyPercentage) / 100;

	const subTotalTaxableValue =
		Number(subTotalExcludingTax) + Number(nhil) + Number(covid19Levy) + Number(getFundLevy);
	const vat = isGhana
		? (subTotalTaxableValue * countryVatPercentage) / 100
		: (subTotalExcludingTax * countryVatPercentage) / 100;
	const whtVat = (subTotalExcludingTax * countryWhtVatPercentage) / 100;

	return { nhil, covid19Levy, getFundLevy, subTotalTaxableValue, vat, whtVat, maintenanceFee };
};

// eslint-disable-next-line complexity
const calculateInvoiceFields = (
	subTotalExcludingTax,
	countryVatPercentage,
	countryWhtPercentage = 0,
	countryWhtVatPercentage = 0, // https://infoleven.atlassian.net/browse/EC-5211
	countryNHILPercentage = 0,
	countryCovid19LevyPercentage = 0,
	countryGetFundLevyPercentage = 0,
	taxFree = false,
	isGhana,
	isKenya,
	contractType,
) => {
	const { nhil, covid19Levy, getFundLevy, subTotalTaxableValue, vat, whtVat, maintenanceFee } = calculateTaxes(
		Number(subTotalExcludingTax),
		countryVatPercentage,
		countryWhtPercentage,
		countryWhtVatPercentage,
		countryNHILPercentage,
		countryCovid19LevyPercentage,
		countryGetFundLevyPercentage,
		taxFree,
		isGhana,
		isKenya,
		contractType,
	);

	const leasingFee = isKenya && contractType !== contractTypes.PPA ? subTotalExcludingTax * 0.8 : undefined;
	const ppaFee = isKenya && contractType === contractTypes.PPA ? subTotalExcludingTax * 0.8 : undefined;
	const subTotal = isGhana ? subTotalTaxableValue + vat : Number(subTotalExcludingTax) + Number(vat);
	const wht = isKenya
		? (maintenanceFee * countryWhtPercentage) / 100
		: (countryWhtPercentage * subTotal) / 100;
	const totalAmount = subTotal - wht - whtVat;

	return {
		leasingFee: leasingFee ? formatInvoiceField(leasingFee) : '',
		ppaFee: ppaFee ? formatInvoiceField(ppaFee) : '',
		maintenanceFee: maintenanceFee && isKenya ? formatInvoiceField(maintenanceFee) : '',
		vat: vat ? formatInvoiceField(vat) : 0,
		subTotal: subTotal && Number(subTotal) !== 0 ? formatInvoiceField(subTotal) : '',
		wht: wht ? formatInvoiceField(wht) : 0,
		whtVat: whtVat ? formatInvoiceField(whtVat) : 0,
		nhil: nhil ? formatInvoiceField(nhil) : 0,
		covid19Levy: covid19Levy ? formatInvoiceField(covid19Levy) : 0,
		getFundLevy: getFundLevy ? formatInvoiceField(getFundLevy) : 0,
		subTotalTaxableValue: subTotalTaxableValue ? formatInvoiceField(subTotalTaxableValue) : '',
		totalAmount: totalAmount ? formatInvoiceField(totalAmount) : '',
	};
};

export default calculateInvoiceFields;
