import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import notify from 'Common/utils/notify';
import crudModes from 'Common/constants/crudModes';
import UserAvatar from 'Common/components/usersPreview/UserAvatar';
import Button from 'Common/components/buttons/Button';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import FormikCheckbox from 'Common/components/form/FormikCheckbox';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import { roles } from 'User/constants/roles';
import { updateCurrentUser } from 'User/reduxUser';
import isAuthorized from 'User/utils/isAuthorized';
import { roleGroups, roleOptionsByRoleGroup } from 'User/constants/roles';

import createUser from 'UserManagement/api/createUser';
import updateUser from 'UserManagement/api/updateUser';
import getUserAssignments from 'UserManagement/api/getUserAssignments';
import { titleOptions } from 'UserManagement/constants/titles';
import uploadNewPhoto from 'UserManagement/api/uploadNewPhoto';
import deleteUserPhoto from 'UserManagement/api/deleteUserPhoto';
import resendConfirmationEmail from 'UserManagement/api/resendConfirmationEmail';
import loadRegionalHubOptions from 'UserManagement/utils/loadRegionalHubOptions';
import useUserFormInitialValues from 'UserManagement/components/UserForm/hooks/useUserFormInitialValues';
import useUserFormValidationSchema from 'UserManagement/components/UserForm/hooks/useUserFormValidationSchema';
import convertFormValuesToRequestData from 'UserManagement/components/UserForm/utils/convertFormValuesToRequestData';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import { OverlayButtons } from 'Common/components/modals/Overlay';
import { Grid } from '@mui/material';
import useFileUpload from 'Common/hooks/useFileUpload';
import { FILE_TYPES } from 'Common/constants/fileTypes';

const Form = styled.form`
	width: 100%;
`;

const AvatarContainer = styled.div`
	width: 96px;
	aspect-ratio: 1;
	margin-bottom: 20px;
`;

const alertUnsavedChanges = event => {
	event.preventDefault();
	event.returnValue = '';
};

const UserForm = ({ data, mode, onSubmit, onCancel }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [assignUserMessage, setAssignUserMessage] = useState();

	const dispatch = useDispatch();
	const currentUserEmail = useSelector(state => state.user.email);

	const abortController = useAbortController();

	const roleGroup = data && data?.roles?.length > 0 ? data.roles[0].roleGroup : roleGroups.ECOLIGO;

	const initialValues = useUserFormInitialValues(data);
	const validationSchema = useUserFormValidationSchema();

	const isAuthorizedToCreateAndEdit = isAuthorized([
		roles.ADMIN,
		roles.SALES,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.PM_TECHNICAL,
		roles.ASSET_MANAGER,
	]);

	const {
		errors,
		touched,
		values,
		dirty,
		handleChange,
		handleBlur,
		setFieldValue,
		setFieldError,
		resetForm,
		submitForm,
	} = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async values => {
			try {
				setIsLoading(true);
				let response;
				if (mode === crudModes.CREATE) {
					response = await createUser(abortController.signal, convertFormValuesToRequestData(values));

					notify(t('User created successfully. A confirmation e-mail has been sent to the user for activation.'), {
						type: toast.TYPE.SUCCESS,
					});
				} else if (mode === crudModes.EDIT) {
					response = await updateUser(abortController.signal, {
						...data,
						...convertFormValuesToRequestData(values),
					});

					if (values.email === currentUserEmail) {
						dispatch(
							updateCurrentUser({
								...response.data,
								roles: response.data.roles.map(e => e.name),
							}),
						);
					}

					notify(t('User saved successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				}

				// Re-set all fields and mark them as not touched
				resetForm({ values });

				onSubmit(response.data);

				setIsLoading(false);
			} catch (error) {
				showAxiosResponseError({
					error,
					setFormikFieldError: setFieldError,
					fieldsMapping: {
						username: 'email',
					},
				});
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
	});

	useEffect(() => {
		if (dirty) {
			window.addEventListener('beforeunload', alertUnsavedChanges);
		} else {
			window.removeEventListener('beforeunload', alertUnsavedChanges);
		}

		return () => {
			window.removeEventListener('beforeunload', alertUnsavedChanges);
		};
	}, [dirty]);

	//Upload logic
	const handleImageUpload = useCallback(
		async ({ formData }) => {
			try {
				const res = await uploadNewPhoto(abortController.signal, values.email, formData);
				const photo = res.data;
				setFieldValue('profilePhoto', photo?.profilePhoto);

				notify('Profile photo uploaded successfully', {
					type: toast.TYPE.SUCCESS,
				});
			} catch (e) {
				showToastError(e, t("Couldn't upload images"));
			}
		},

		[abortController.signal, values.email, setFieldValue, t],
	);

	const {
		handleAddFile: handleAddImage,
		isUploading,
		generateInputProps,
	} = useFileUpload({
		onFileUpload: handleImageUpload,
		fileName: 'Image',
		fileIdentifier: 'file',
		acceptedTypes: [FILE_TYPES.IMAGE],
	});

	const isAuthorizedToViewRegionalHub = values.roles.filter(item => item.value === roles.SALES).length > 0;

	const handleResendConfirmationEmail = async () => {
		if (mode === crudModes.EDIT) {
			try {
				await resendConfirmationEmail(abortController.signal, initialValues.email);

				notify(t('A new confirmation e-mail has been sent successfully'), {
					type: toast.TYPE.SUCCESS,
				});
			} catch (error) {
				showAxiosResponseError({
					error,
					toastFields: ['username'],
					fieldsMapping: {
						username: 'E-mail',
					},
				});
			}
		}
	};

	const handleRemovePhoto = async () => {
		try {
			await deleteUserPhoto(abortController.signal, values.email);
			notify(t('Profile photo successfully'), {
				type: toast.TYPE.SUCCESS,
			});
			setFieldValue('profilePhoto', '');
		} catch (err) {
			showToastError(err);
		}
	};

	const handleConfirm = () => {
		if (mode === crudModes.EDIT && !values.active && initialValues.active) {
			handleAssignmentsModal();
		} else {
			submitForm();
		}
	};

	const handleAssignmentsModal = async () => {
		try {
			const response = await getUserAssignments(abortController.signal, data.email);
			if (response.data) {
				setAssignUserMessage(response.data);
			} else {
				submitForm();
			}
		} catch (error) {
			showToastError(error);
		}
	};

	return (
		<>
			<Form>
				<Grid container columnSpacing={10}>
					<Grid item xs={12} md={6}>
						<FormField>
							<FormikCheckbox
								id="active"
								name="active"
								label={t('Active')}
								checked={values.active}
								error={errors.active}
								touched={touched.active}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</FormField>
						{mode === crudModes.EDIT && (
							<AvatarContainer>
								<UserAvatar
									imgUrl={values?.profilePhoto?.url}
									uploadAvailable
									onUpload={handleAddImage}
									handleChange={handleChange}
									onRemovePhoto={handleRemovePhoto}
									isUploading={isUploading}
								/>
								<input {...generateInputProps()} />
							</AvatarContainer>
						)}
						<FormField>
							<FormikInput
								id="firstName"
								name="firstName"
								label={t('First Name')}
								value={values.firstName}
								error={errors.firstName}
								touched={touched.firstName}
								onChange={handleChange}
								onBlur={handleBlur}
								isRequired
								isOverlay
							/>
						</FormField>
						<FormField>
							<FormikInput
								id="lastName"
								name="lastName"
								label={t('Last Name')}
								value={values.lastName}
								error={errors.lastName}
								touched={touched.lastName}
								onChange={handleChange}
								onBlur={handleBlur}
								isRequired
								isOverlay
							/>
						</FormField>
					</Grid>
					<Grid item xs={12} md={6}>
						<FormField>
							<FormikSelect
								id="title"
								name="title"
								label={t('Gender')}
								value={values.title}
								error={errors.title}
								touched={touched.title}
								setFieldValue={setFieldValue}
								options={titleOptions}
								isSearchable={false}
								isClearable
								isOverlay
							/>
						</FormField>
						<FormField>
							<FormikInput
								id="email"
								name="email"
								label={t('E-mail')}
								value={values.email}
								error={errors.email}
								touched={touched.email}
								onChange={handleChange}
								onBlur={handleBlur}
								isRequired
								isOverlay
							/>
						</FormField>
						<FormField>
							<FormikInput
								id="phone"
								name="phone"
								label={t('Phone')}
								value={values.phone}
								error={errors.phone}
								touched={touched.phone}
								onChange={handleChange}
								onBlur={handleBlur}
								isOverlay
							/>
						</FormField>
						<FormField>
							<FormikSelect
								id="roles"
								name="roles"
								label={t('Roles')}
								value={values.roles}
								error={errors.roles}
								touched={touched.roles}
								setFieldValue={setFieldValue}
								options={roleOptionsByRoleGroup[roleGroup]}
								menuPlacement="top"
								isMulti
								isRequired
								isOverlay
							/>
						</FormField>
						{isAuthorizedToViewRegionalHub && (
							<FormField>
								<FormikSelect
									isAsync
									id="regionalHub"
									name="regionalHub"
									label={t('Regional Hub')}
									value={values.regionalHub}
									error={errors.regionalHub}
									touched={touched.regionalHub}
									setFieldValue={setFieldValue}
									loadOptions={loadRegionalHubOptions}
									isSearchable={false}
									isClearable
									isOverlay
								/>
							</FormField>
						)}
					</Grid>
				</Grid>

				<OverlayButtons
					onCancel={onCancel}
					onConfirm={handleConfirm}
					isLoading={isLoading}
					label="User Form"
					noSubmitButton={!isAuthorizedToCreateAndEdit}
					submitButtonText={mode === crudModes.CREATE ? t('Create User') : t('Edit User')}
					disableSubmit
				>
					{mode === crudModes.EDIT && !initialValues.verified && isAuthorizedToCreateAndEdit && (
						<Button
							icon="sendRounded"
							text={t('Re-send confirmation e-mail')}
							onClick={handleResendConfirmationEmail}
							label="User Form - e-send confirmation e-mail Button"
						/>
					)}
				</OverlayButtons>
			</Form>
			<ConfirmModal
				isOpen={assignUserMessage}
				onCancel={() => setAssignUserMessage('')}
				onConfirm={() => {
					setAssignUserMessage('');
					submitForm();
				}}
				label="edit-user-confirm"
				heading={t('Edit User')}
				text={assignUserMessage}
			/>
		</>
	);
};

UserForm.defaultProps = {
	data: null,
	onSubmit: () => null,
	onCancel: () => null,
};

UserForm.propTypes = {
	mode: PropTypes.string.isRequired,
	data: PropTypes.shape({ roles: PropTypes.arrayOf(PropTypes.string), email: PropTypes.string }),
	onSubmit: PropTypes.func,
	onCancel: PropTypes.func,
};

export default UserForm;
