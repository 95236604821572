import { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';
import FormField from 'Common/components/form/FormField';

import { useFormik } from 'formik';
import FormikSelect from 'Common/components/form/FormikSelect';

import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import assignContactToProject from 'Contacts/api/assignContactToProject';
import getProjects from 'Projects/api/getProjects';
import showToastError from 'Common/utils/showToastError';
import { PropTypes } from 'prop-types';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { ModalFormButtons } from 'Common/components/form/ModalForm';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 400px;
`;

const FieldsWrapper = styled.div`
	width: 100%;
`;

const AssignToProjectForm = ({
	onSubmit,
	onCancel,
	onDirty,
	contactId,
	epcPartnerId,
	data,
	clientId,
	partnerId,
}) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [projectOptions, setProjectOptions] = useState([]);
	const [isLoadingProjects, setIsLoadingProjects] = useState(false);

	const abortController = useAbortController();

	useEffect(() => {
		(async () => {
			setIsLoadingProjects(true);
			try {
				const response = await getProjects(
					abortController.signal,
					{ sortBy: 'externalId', sortDirection: 'DESC', limit: 9999, offset: 0 },
					{ clientId, epcPartnerId, partnerId },
					undefined,
				);

				setProjectOptions(
					response.data.content.map(project => ({
						value: project.id,
						label: project.description
							? `${project.externalId} (${project.description})`
							: String(project.externalId),
					})),
				);
				setIsLoadingProjects(false);
			} catch (error) {
				showToastError(error, t("Can't fetch project options"));
				if (!axios.isCancel(error)) {
					setIsLoadingProjects(false);
				}
			}
		})();
	}, [clientId, epcPartnerId, partnerId, abortController.signal, t]);

	const initialValues = useMemo(
		() =>
			data
				? {
						project: data.projects,
				  }
				: {
						project: [],
				  },
		[data],
	);

	const { errors, touched, values, handleSubmit, dirty, handleBlur, setFieldValue, setFieldError, resetForm } =
		useFormik({
			initialValues,
			onSubmit: async values => {
				setIsLoading(true);

				try {
					await assignContactToProject(abortController.signal, {
						contactId,
						projectIds: values.project && values.project.length > 0 ? values.project.map(e => e.value) : [],
					});

					notify(t('Contact assigned successfully'), {
						type: toast.TYPE.SUCCESS,
					});

					onSubmit();

					// Re-set all fields and mark them as not touched
					resetForm({ values });
					setIsLoading(false);
				} catch (error) {
					showAxiosResponseError({
						error,
						setFormikFieldError: setFieldError,
					});
					setIsLoadingProjects(false);
				}
			},
		});

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<>
			<Form onSubmit={handleSubmit}>
				<FieldsWrapper>
					<FormField>
						<FormikSelect
							id="project"
							name="project"
							label={t('Project')}
							value={values.project}
							error={errors.project}
							touched={touched.project}
							setFieldValue={setFieldValue}
							onBlur={handleBlur}
							options={projectOptions}
							menuPosition="fixed"
							isMulti
							isLoading={isLoadingProjects}
							customStyles={{
								menuList: provided => ({
									...provided,
									maxHeight: '120px',
								}),
							}}
						/>
					</FormField>
				</FieldsWrapper>

				<ModalFormButtons
					onCancel={onCancel}
					isLoading={isLoading}
					label="Contacts Assign to Project"
					submitButtonText={t('Assign')}
				/>
			</Form>
		</>
	);
};

AssignToProjectForm.defaultProps = {
	epcPartnerId: '',
	clientId: '',
	partnerId: '',
};

AssignToProjectForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func.isRequired,
	contactId: PropTypes.string.isRequired,
	epcPartnerId: PropTypes.string,
	clientId: PropTypes.string,
	partnerId: PropTypes.string,
	data: PropTypes.shape({
		projects: PropTypes.arrayOf(PropTypes.shape({})),
	}).isRequired,
};

export default AssignToProjectForm;
