import getCountries from 'Country/api/getCountries';

const loadCountryOptions = async (signal, query, additionalProps = []) => {
	const response = await getCountries(signal, query, 'isoCode,asc');
	const countries = response.data.content;

	return countries
		.filter(country => country.isoCode && country.isoCode !== '-')
		.map(country => ({
			value: country.id,
			label: country.isoCode,
			...additionalProps.reduce((acc, prop) => {
				acc[prop] = country[prop];
				return acc;
			}, {}),
		}));
};

export default loadCountryOptions;
