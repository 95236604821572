/* eslint-disable complexity */
import Big from 'big.js';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import { SectionWrapper } from 'Common/components/modals/OverlaySections';
import UNITS from 'Common/constants/units';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import crudModes from 'Common/constants/crudModes';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';

const SizeSection = ({
	values,
	errors,
	touched,
	handleChange,
	setFieldValue,
	setFieldTouched,
	handleBlur,
	isPVProject,
	mode,
}) => {
	const { t } = useTranslation();
	const omPriceSwitch = values.omPriceSwitch;
	const epcVolumeSwitch = values.epcVolumeSwitch;

	const handleSystemSizeChange = e => {
		handleChange(e);
		const systemSizeField = Number(e.target.value);

		if (!omPriceSwitch) {
			if (isNaN(systemSizeField) || systemSizeField === 0) {
				setFieldValue('annualOmPriceKwp', '', false);
			} else {
				setFieldValue(
					'annualOmPriceKwp',
					values.annualOmPrice
						? Number(Number(Big(values.annualOmPrice).div(Big(systemSizeField)).toString()).toFixed(2))
						: '',
					false,
				);
				if (errors['annualOmPriceKwp'] && values.annualOmPrice) {
					setFieldTouched('annualOmPriceKwp');
				}
			}
		}
		if (omPriceSwitch) {
			if (isNaN(systemSizeField) || systemSizeField === 0) {
				setFieldValue('annualOmPrice', '', false);
			} else {
				setFieldValue(
					'annualOmPrice',
					values.annualOmPriceKwp
						? Number(Big(values.annualOmPriceKwp).times(Big(systemSizeField)).toString()).toFixed(2)
						: '',
					false,
				);
				if (errors['annualOmPrice'] && values.annualOmPriceKwp) {
					setFieldTouched('annualOmPrice');
				}
			}
		}
		if (!epcVolumeSwitch) {
			if (isNaN(systemSizeField) || systemSizeField === 0) {
				setFieldValue('epcVolumeExclVatKwp', '', false);
			} else {
				setFieldValue(
					'epcVolumeExclVatKwp',
					values.epcVolumeExclVat
						? Number(Number(Big(values.epcVolumeExclVat).div(Big(systemSizeField)).toString()).toFixed(2))
						: '',
					false,
				);
				if (errors['epcVolumeExclVatKwp'] && values.epcVolumeExclVat) {
					setFieldTouched('epcVolumeExclVatKwp');
				}
			}
		}
		if (epcVolumeSwitch) {
			if (isNaN(systemSizeField) || systemSizeField === 0) {
				setFieldValue('epcVolumeExclVat', '', false);
			} else {
				setFieldValue(
					'epcVolumeExclVat',
					values.epcVolumeExclVatKwp
						? Number(Big(values.epcVolumeExclVatKwp).times(Big(systemSizeField)).toString()).toFixed(2)
						: '',
					false,
				);
				if (errors['epcVolumeExclVat'] && values.epcVolumeExclVatKwp) {
					setFieldTouched('epcVolumeExclVat');
				}
			}
		}
	};

	return mode !== crudModes.VIEW ? (
		<SectionWrapper>
			<FormField data-first-field>
				<FormikInput
					label={t('System size')}
					id="systemSize"
					name="systemSize"
					error={errors.systemSize}
					touched={touched.systemSize}
					value={values.systemSize}
					onChange={handleSystemSizeChange}
					onBlur={handleBlur}
					isRequired={isPVProject}
					isDisabled={!isPVProject}
					tooltip={
						!isPVProject
							? {
									tooltip: t('System size is calculated from the EPC Volume when the Estimate is saved.'),
							  }
							: ''
					}
					unit={UNITS.KWP}
					isOverlay
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="solarUtilisationRate"
					name="solarUtilisationRate"
					label={t('Solar utilization rate')}
					value={values.solarUtilisationRate}
					error={errors.solarUtilisationRate}
					touched={touched.solarUtilisationRate}
					onChange={handleChange}
					onBlur={handleBlur}
					tooltip={{
						tooltip: t(
							'The % of annual generated energy expected to be consumed.  The remaining % is the expected grid injection, if feed-in applies.',
						),
					}}
					unit={UNITS.PERCENT}
					isRequired
					isOverlay
				/>
			</FormField>
			<FormField data-last-field>
				<FormikInput
					label={t('Yield')}
					id="yield"
					name="yield"
					error={errors.yield}
					touched={touched.yield}
					value={values.yield}
					onChange={handleChange}
					onBlur={handleBlur}
					isRequired
					unit={UNITS.KWH_KWP}
					isOverlay
				/>
			</FormField>
		</SectionWrapper>
	) : (
		<SectionWrapper>
			<KeyValueVisualization
				id="systemSize"
				title={t('System size')}
				value={values.systemSize}
				unit={UNITS.KWP}
			/>
			<KeyValueVisualization
				id="solarUtilisationRate"
				title={t('Solar utilization rate')}
				value={values.solarUtilisationRate}
				unit={UNITS.PERCENT}
			/>
			<KeyValueVisualization id="yield" title={t('Yield')} value={values.yield} unit={UNITS.KWH_KWP} />
		</SectionWrapper>
	);
};

SizeSection.defaultProps = {
	values: {
		omPriceSwitch: false,
		epcVolumeSwitch: false,
		annualOmPriceKwp: '',
		annualOmPrice: '',
		epcVolumeExclVatKwp: '',
		epcVolumeExclVat: '',
		systemSize: '',
		solarUtilisationRate: '',
	},
	errors: {
		annualOmPriceKwp: '',
		annualOmPrice: '',
		epcVolumeExclVatKwp: '',
		epcVolumeExclVat: '',
		systemSize: '',
		solarUtilisationRate: '',
	},
	touched: {
		annualOmPriceKwp: false,
		annualOmPrice: false,
		epcVolumeExclVatKwp: false,
		epcVolumeExclVat: false,
		systemSize: false,
		solarUtilisationRate: false,
	},
	setFieldValue: () => {},
	setFieldTouched: () => {},
	handleBlur: () => {},
	handleChange: () => {},
	isPVProject: false,
};
SizeSection.propTypes = {
	values: PropTypes.shape({
		omPriceSwitch: PropTypes.bool,
		epcVolumeSwitch: PropTypes.bool,
		annualOmPriceKwp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		annualOmPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		epcVolumeExclVatKwp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		epcVolumeExclVat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		systemSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		solarUtilisationRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		yield: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	errors: PropTypes.shape({
		annualOmPriceKwp: PropTypes.string,
		annualOmPrice: PropTypes.string,
		epcVolumeExclVatKwp: PropTypes.string,
		epcVolumeExclVat: PropTypes.string,
		systemSize: PropTypes.string,
		solarUtilisationRate: PropTypes.string,
		yield: PropTypes.string,
	}),
	touched: PropTypes.shape({
		annualOmPriceKwp: PropTypes.bool,
		annualOmPrice: PropTypes.bool,
		epcVolumeExclVatKwp: PropTypes.bool,
		epcVolumeExclVat: PropTypes.bool,
		systemSize: PropTypes.bool,
		solarUtilisationRate: PropTypes.bool,
		yield: PropTypes.bool,
	}),
	setFieldValue: PropTypes.func,
	setFieldTouched: PropTypes.func,
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	isPVProject: PropTypes.bool,
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
};

export default SizeSection;
