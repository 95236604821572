import getIndustries from 'Industries/api/getIndustries';

const loadIndustryOptions = async (signal, query, usable = true) => {
	const response = await getIndustries(signal, usable, query);
	const industries = response.data.content;

	return industries.map(industry => ({
		value: industry.id,
		label: industry.name,
		marketSegment: industry.marketSegment,
		restricted: industry.restricted,
	}));
};

export default loadIndustryOptions;
