import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useQueryParameter from 'Common/hooks/useQueryParameter';
import { push } from 'redux-first-history';
import getPartners from 'Partners/api/getPartners';
import { DefaultTable } from 'Common/components/table/table';
import useStandardTable from 'Common/hooks/table/useStandardTable';
import { useClickableTableRow } from 'Common/hooks/table/plugin-hooks/useClickableTableRow';
import { useSortBy } from 'react-table';
import { useSortingQueryParameter } from 'Common/hooks/table/plugin-hooks/useSortingQueryParameter';
import { useScrollableTableBody } from 'Common/hooks/table/plugin-hooks/useScrollableTableBody';
import { useVirtualizedTableBody } from 'Common/hooks/table/plugin-hooks/useVirtualizedTableBody';
import { debounce, fill } from 'lodash';
import { useCustomCellStyles } from 'Common/hooks/table/plugin-hooks/useCustomCellStyles';
import Link from 'Common/components/Link';
import showToastError from 'Common/utils/showToastError';
import { PropTypes } from 'prop-types';
import useUniqueAbortSignal from 'Common/hooks/useUniqueAbortSignal';
import prefillTableData from 'Common/utils/prefillTableData';

const Wrapper = styled.div`
	position: relative;
	pointer-events: ${({ hasOpenFilter }) => (hasOpenFilter ? 'none' : 'all')};
`;

const defaultSortBy = {
	id: 'name',
	desc: false,
};

const sortPropertiesMap = {
	country: 'country.name',
	cityRegion: 'address.cityRegion',
	primaryContactName: 'firstName',
};

const PartnersListTable = () => {
	const { t } = useTranslation();
	const hasOpenFilter = !!useSelector(state => state?.tables?.partners?.openedFilter);

	const [totalItemsCount, setTotalItemsCount] = useState(25);

	const sortByQueryParam = useQueryParameter('sortBy');
	const sortBy = sortByQueryParam ?? defaultSortBy;
	const filters = useQueryParameter('filters');

	const searchQuery = useQueryParameter('searchQuery');

	const dispatch = useDispatch();

	const [partners, setPartners] = useState(() => fill(Array(25), {}));
	const [isLoading, setIsLoading] = useState(true);

	const getUniqueSignal = useUniqueAbortSignal();

	const hasLoadedFirstResultsRef = useRef(false);

	const getPartnersBatch = useCallback(
		async (startIndex, stopIndex, filters, sortBy, searchQuery, hasItemsChanges = false) => {
			if (hasItemsChanges) {
				setIsLoading(true);
			}

			const sortById = sortPropertiesMap[sortBy.id] ?? sortBy.id;

			const customFilters = { ...filters };

			try {
				let response = await getPartners(
					getUniqueSignal('get-partners'),
					{
						offset: startIndex,
						limit: stopIndex - startIndex + 1,
						sortBy: sortById,
						sortDirection: sortBy.desc ? 'DESC' : 'ASC',
					},
					customFilters,
					searchQuery,
				);

				if (hasItemsChanges || !hasLoadedFirstResultsRef.current) {
					const { itemsCount, itemsFilled, error } = prefillTableData(response.data, startIndex, stopIndex);
					if (error) {
						showToastError(error);
					} else {
						setPartners(itemsFilled);
						setTotalItemsCount(itemsCount);
					}
				} else {
					setPartners(prevClients =>
						prevClients.map((prevClient, index) => {
							if (startIndex <= index && index <= stopIndex) {
								const indexInResultSet = index - startIndex;

								return response.data.content[indexInResultSet];
							}

							return prevClient;
						}),
					);
				}

				hasLoadedFirstResultsRef.current = true;

				if (hasItemsChanges) {
					setIsLoading(false);
				}
			} catch (error) {
				showToastError(error, t("Can't fetch Partners"));
			}
		},
		[getUniqueSignal, t],
	);

	useEffect(() => {
		getPartnersBatch(
			0,
			25,
			filters,
			{
				id: sortBy.id,
				desc: sortBy.desc,
			},
			searchQuery,
			true,
		);
	}, [getPartnersBatch, searchQuery, filters, sortBy.desc, sortBy.id]);

	// eslint-disable-next-line
	const handleLoadMoreRows = useCallback(
		debounce(
			(startIndex, stopIndex) => {
				getPartnersBatch(
					startIndex,
					stopIndex,
					filters,
					{
						id: sortBy.id,
						desc: sortBy.desc,
					},
					searchQuery,
				);
			},
			350,
			{
				leading: false,
				trailing: true,
			},
		),
		[getPartnersBatch, searchQuery, filters, sortBy.desc, sortBy.id],
	);

	// Columns definition
	const columns = useMemo(
		() => [
			{
				Header: t('Name'),
				accessor: 'name',
				Cell: (() => {
					const Cell = ({ row: { original: partner } }) => (
						<Link color="#00b4d5" to={`/partners/details/${partner?.id}`} fontSize="14px">
							{partner?.name}
						</Link>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({
								id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
								name: PropTypes.string,
							}),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Country'),
				accessor: 'country',
				Cell: (() => {
					const Cell = ({ row: { original: partner } }) => (
						<div title={partner?.country?.isoCode}>{partner?.country?.isoCode}</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ country: PropTypes.shape({ isoCode: PropTypes.string }) }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Partner Type'),
				accessor: 'partnerType',
				Cell: (() => {
					const Cell = ({ row: { original: partner } }) => (
						<div title={partner?.partnerType?.name}>{partner?.partnerType?.name}</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ partnerType: PropTypes.shape({ name: PropTypes.string }) }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
			{
				Header: t('Description'),
				accessor: 'description',
				Cell: (() => {
					const Cell = ({ row: { original: partner } }) => (
						<div title={partner?.description}>{partner?.description}</div>
					);
					Cell.propTypes = {
						row: PropTypes.shape({
							original: PropTypes.shape({ description: PropTypes.string }),
						}).isRequired,
					};
					return Cell;
				})(),
			},
		],
		[t],
	);

	const data = useMemo(() => partners, [partners]);

	const itemsHash = useMemo(() => JSON.stringify({ filters, sortBy }), [filters, sortBy]);

	const handleRowClick = row => {
		if (!window.getSelection().toString()) {
			dispatch(push(`/partners/details/${row.original.id}`));
		}
	};

	// Fallback to default row id getter
	const getRowId = useCallback(
		(row, relativeIndex, parent) => (parent ? [parent.id, relativeIndex].join('.') : relativeIndex),
		[],
	);

	const {
		getTableProps,
		getTableHeaderProps,
		getTableBodyProps,
		getTableFooterProps,
		getVirtualizedTableBodyProps,
		headerGroups,
		footerGroups,
		rows,
		prepareRow,
	} = useStandardTable(
		{
			data,
			columns,
			defaultSortBy,
			searchTerm: searchQuery,
			onRowClick: handleRowClick,
			virtualization: {
				totalRowsCount: totalItemsCount,
				rowsHash: itemsHash,
				loadMoreRows: handleLoadMoreRows,
			},
			getRowId,
		},
		useClickableTableRow,
		useCustomCellStyles,
		useSortBy,
		useSortingQueryParameter,
		useScrollableTableBody,
		useVirtualizedTableBody,
	);

	return (
		<Wrapper hasOpenFilter={hasOpenFilter}>
			<DefaultTable
				getTableProps={getTableProps}
				getTableHeaderProps={getTableHeaderProps}
				getTableBodyProps={getTableBodyProps}
				getTableFooterProps={getTableFooterProps}
				getVirtualizedTableBodyProps={getVirtualizedTableBodyProps}
				rows={rows}
				headerGroups={headerGroups}
				footerGroups={footerGroups}
				prepareRow={prepareRow}
				isLoading={isLoading || rows.length !== totalItemsCount}
				showNoResultsFound
			/>
		</Wrapper>
	);
};

export default PartnersListTable;
