import api from 'Application/api/api';

const confirmPasswordReset = (signal, email, code, password, passwordConfirm) =>
	api.post(
		'/auth/confirm-password-reset',
		{
			username: email,
			password,
			passwordConfirm,
			code,
		},
		{
			signal,
		},
	);

export default confirmPasswordReset;
