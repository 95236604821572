import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import GlobalStyles from 'Common/components/layout/GlobalStyles';
import ToastContainer from 'Common/components/ToastContainer';
import SingleSectionLayout from 'Common/components/layout/SingleSectionLayout';
import UnsavedChangesModalRedux from 'Common/components/modals/UnsavedChangesModalRedux';
import Impact from 'Impact/Impact';
import MyActivities from 'Dashboard/components/MyActivities/MyActivities';
import TeamActivities from 'Dashboard/components/TeamActivities/TeamActivities';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import Login from 'User/components/Login/Login';
import { roleGroups, roles } from 'User/constants/roles';
import ReLoginPopup from 'User/components/ReLoginPopup/ReLoginPopup';
import ConfirmAccount from 'User/components/ConfirmAccount/ConfirmAccount';
import ForgottenPassword from 'User/components/ForgottenPassword/ForgottenPassword';
import ConfirmPasswordReset from 'User/components/ConfirmPasswordReset/ConfirmPasswordReset';

import UserList from 'UserManagement/components/UserList/UserList';

import PageNotFound from 'Application/components/PageNotFound';
import PrivacyPolicy from 'Application/components/PrivacyPolicy';
import RouteWithLayout from 'Application/components/RouteWithLayout';
import HomepageRedirectRoute from 'Application/components/HomepageRedirectRoute';

import ProjectList from 'Projects/components/ProjectList/ProjectList';
import ProjectDetails from 'Projects/components/ProjectDetails/ProjectDetails';

import Clients from 'Client/components/Clients';

import ClientDetails from 'Client/components/ClientDetails/ClientDetails';
import PartnersList from 'Partners/components/PartnersList/PartnersList';
import PartnerDetails from 'Partners/components/PartnerDetails/PartnerDetails';
import EpcPartnersList from 'EpcPartner/components/EpcPartnerList/EpcPartnersList';
import EpcPartnerPortal from 'EpcPartner/components/EpcPartnerPortal/EpcPartnerPortal';
import EpcPartnerDetails from 'EpcPartner/components/EpcPartnerDetails/EpcPartnerDetails';
import EpcOffersList from 'EpcPartner/components/EpcPartnerPortal/EpcOffersList/EpcOffersList';
import EpcInquiryEdit from 'EpcPartner/components/EpcPartnerPortal/EpcInquiryCreate/EpcInquiryEdit';
import EpcOfferDetails from 'EpcPartner/components/EpcPartnerPortal/EpcOfferDetails/EpcOfferDetails';
import EpcInquiriesList from 'EpcPartner/components/EpcPartnerPortal/EpcInquiriesList/EpcInquiriesList';
import EpcInquiryCreate from 'EpcPartner/components/EpcPartnerPortal/EpcInquiryCreate/EpcInquiryCreate';
import EpcPartnerProfile from 'EpcPartner/components/EpcPartnerPortal/EpcPartnerProfile/EpcPartnerProfile';
import InvoicesList from 'Invoice/components/InvoicesList/InvoicesList';

import Preferences from 'Preferences/components/Preferences';

import PMWorkflow from 'PMWorkflow/components/PMWorkflow';

import InquiriesList from 'Inquiries/components/InquiriesList/InquiriesList';
import InquiryDetails from 'Inquiries/components/InquiryDetails/InquiryDetails';

import InvestorsList from 'Investors/components/InvestorsList/InvestorsList';
import InvestorDetails from 'Investors/components/InvestorsDetails/InvestorsDetails';

import SharePointFoldersList from 'Integrations/components/SharePointFolders/SharePointFoldersList';

import Campaigns from 'Campaigns/components/Campaigns';
import CampaignDetails from 'Campaigns/components/CampaignDetails/CampaignDetails';

import useNotifications from 'Notifications/hooks/useNotifications';
import RouterErrorBoundary from './RouterErrorBoundary';
import CookieConsentDialog from 'Common/components/CookieConsentDialog';
import EntitiesList from 'Entities/components/EntitiesList/EntitiesList';
import EntitiesDetails from 'Entities/components/EntitiesDetails/EntitiesDetails';

const Routes = () => {
	let element = useRoutes([
		// These are the same as the props you provide to <Route>
		{ path: '/', element: <HomepageRedirectRoute /> },

		/* Public Routes (without authentication) */
		{
			path: '/login',
			element: <RouteWithLayout component={Login} layout={SingleSectionLayout} requiresAuthentication={false} />,
		},
		{
			path: '/forgotten-password',
			element: (
				<RouteWithLayout
					component={ForgottenPassword}
					layout={SingleSectionLayout}
					requiresAuthentication={false}
				/>
			),
		},
		{
			path: '/reset-password',
			element: (
				<RouteWithLayout
					component={ConfirmPasswordReset}
					layout={SingleSectionLayout}
					requiresAuthentication={false}
				/>
			),
		},
		{
			path: '/confirm-account',
			element: (
				<RouteWithLayout component={ConfirmAccount} layout={SingleSectionLayout} requiresAuthentication={false} />
			),
		},

		/* Protected Routes (require authentication) */
		{
			path: '/impact',
			element: <RouteWithLayout component={Impact} authorizedRoleGroups={[roleGroups.ECOLIGO]} />,
		},
		{
			path: '/my-activities',
			element: <RouteWithLayout component={MyActivities} authorizedRoleGroups={[roleGroups.ECOLIGO]} />,
		},
		{
			path: '/my-team-activities',
			element: <RouteWithLayout component={TeamActivities} authorizedRoleGroups={[roleGroups.ECOLIGO]} />,
		},
		{
			path: '/users',
			element: (
				<RouteWithLayout
					component={UserList}
					authorizedRoleGroups={[roleGroups.ECOLIGO]}
					authorizedRoles={[
						roles.ADMIN,
						roles.FINANCE,
						roles.ASSET_MANAGER,
						roles.MANAGEMENT,
						roles.PM_TECHNICAL,
						roles.SALES,
					]}
					isListPage
				/>
			),
		},
		{
			path: '/epc-users',
			element: (
				<RouteWithLayout
					component={UserList}
					authorizedRoleGroups={[roleGroups.ECOLIGO]}
					authorizedRoles={[
						roles.ADMIN,
						roles.FINANCE,
						roles.ASSET_MANAGER,
						roles.MANAGEMENT,
						roles.PM_TECHNICAL,
						roles.SALES,
					]}
					isListPage
				/>
			),
		},
		{
			path: '/projects',
			element: (
				<RouteWithLayout component={ProjectList} authorizedRoleGroups={[roleGroups.ECOLIGO]} isListPage />
			),
		},
		{
			path: '/projects/details/:projectId',
			element: <RouteWithLayout component={ProjectDetails} authorizedRoleGroups={[roleGroups.ECOLIGO]} />,
		},
		{
			path: '/entities',
			element: (
				<RouteWithLayout component={EntitiesList} authorizedRoleGroups={[roleGroups.ECOLIGO]} isListPage />
			),
		},
		{
			path: '/entities/details/:entityId',
			element: <RouteWithLayout component={EntitiesDetails} authorizedRoleGroups={[roleGroups.ECOLIGO]} />,
		},
		{
			path: '/clients/details/:clientId',
			element: <RouteWithLayout component={ClientDetails} authorizedRoleGroups={[roleGroups.ECOLIGO]} />,
		},
		{
			path: '/partners',
			element: (
				<RouteWithLayout component={PartnersList} authorizedRoleGroups={[roleGroups.ECOLIGO]} isListPage />
			),
		},
		{
			path: '/partners/details/:partnerId',
			element: <RouteWithLayout component={PartnerDetails} authorizedRoleGroups={[roleGroups.ECOLIGO]} />,
		},
		{
			path: '/epc-partners',
			element: (
				<RouteWithLayout component={EpcPartnersList} authorizedRoleGroups={[roleGroups.ECOLIGO]} isListPage />
			),
		},
		{
			path: '/epc-partners/details/:epcPartnerId',
			element: (
				<RouteWithLayout
					path="/epc-partners/details/:epcPartnerId"
					component={EpcPartnerDetails}
					authorizedRoleGroups={[roleGroups.ECOLIGO]}
				/>
			),
		},
		{
			path: '/invoices',
			element: (
				<RouteWithLayout component={InvoicesList} authorizedRoleGroups={[roleGroups.ECOLIGO]} isListPage />
			),
		},
		{
			path: '/preferences',
			element: <RouteWithLayout component={Preferences} authorizedRoleGroups={[roleGroups.ECOLIGO]} />,
		},
		{
			path: '/pm-workflow',
			element: (
				<RouteWithLayout
					component={PMWorkflow}
					authorizedRoles={[roles.ADMIN]}
					authorizedRoleGroups={[roleGroups.ECOLIGO]}
				/>
			),
		},
		{
			path: '/integrations',
			element: (
				<RouteWithLayout
					component={SharePointFoldersList}
					authorizedRoles={[roles.ADMIN, roles.FINANCE, roles.MANAGEMENT, roles.SALES]}
					authorizedRoleGroups={[roleGroups.ECOLIGO]}
					isListPage
				/>
			),
		},
		{
			path: '/inquiries',
			element: (
				<RouteWithLayout
					component={InquiriesList}
					authorizedRoles={[roles.ADMIN, roles.FINANCE, roles.MANAGEMENT, roles.SALES]}
					authorizedRoleGroups={[roleGroups.ECOLIGO]}
					isListPage
				/>
			),
		},
		{
			path: '/inquiries/details/:inquiryId',
			element: (
				<RouteWithLayout
					component={InquiryDetails}
					authorizedRoles={[roles.ADMIN, roles.FINANCE, roles.MANAGEMENT, roles.SALES]}
					authorizedRoleGroups={[roleGroups.ECOLIGO]}
				/>
			),
		},
		{
			path: '/investors',
			element: (
				<RouteWithLayout
					component={InvestorsList}
					authorizedRoles={[
						roles.ADMIN,
						roles.MANAGEMENT,
						roles.PM_FUNDRAISING,
						roles.ASSET_MARKETING,
						roles.HEAD_OF_ESG,
					]}
					authorizedRoleGroups={[roleGroups.ECOLIGO]}
					isListPage
				/>
			),
		},
		{
			path: '/investors/details/:investorId',
			element: (
				<RouteWithLayout
					component={InvestorDetails}
					authorizedRoles={[
						roles.ADMIN,
						roles.FINANCE,
						roles.MANAGEMENT,
						roles.PM_FUNDRAISING,
						roles.ASSET_MARKETING,
						roles.HEAD_OF_ESG,
					]}
					authorizedRoleGroups={[roleGroups.ECOLIGO]}
				/>
			),
		},

		/* Protected EPC Routes (require EPC authentication) */
		{
			path: '/epc/dashboard',
			element: (
				<RouteWithLayout
					component={EpcPartnerPortal}
					authorizedRoles={[roles.EPC_PARTNER]}
					authorizedRoleGroups={[roleGroups.EPC_PARTNER]}
				/>
			),
		},
		{
			path: '/epc/my-profile',
			element: (
				<RouteWithLayout
					component={EpcPartnerProfile}
					authorizedRoles={[roles.EPC_PARTNER]}
					authorizedRoleGroups={[roleGroups.EPC_PARTNER]}
				/>
			),
		},
		{
			path: '/epc/offers',
			element: (
				<RouteWithLayout
					component={EpcOffersList}
					authorizedRoles={[roles.EPC_PARTNER]}
					authorizedRoleGroups={[roleGroups.EPC_PARTNER]}
					isListPage
				/>
			),
		},
		{
			path: '/epc/offers/:id',
			element: (
				<RouteWithLayout
					component={EpcOfferDetails}
					authorizedRoles={[roles.EPC_PARTNER]}
					authorizedRoleGroups={[roleGroups.EPC_PARTNER]}
				/>
			),
		},
		{
			path: '/epc/inquiries',
			element: (
				<RouteWithLayout
					component={EpcInquiriesList}
					authorizedRoles={[roles.EPC_PARTNER]}
					authorizedRoleGroups={[roleGroups.EPC_PARTNER]}
					isListPage
				/>
			),
		},
		{
			path: '/epc/inquiries/create',
			element: (
				<RouteWithLayout
					component={EpcInquiryCreate}
					authorizedRoles={[roles.EPC_PARTNER]}
					authorizedRoleGroups={[roleGroups.EPC_PARTNER]}
				/>
			),
		},
		{
			path: '/epc/inquiries/:inquiryId',
			element: (
				<RouteWithLayout
					component={EpcInquiryEdit}
					authorizedRoles={[roles.EPC_PARTNER]}
					authorizedRoleGroups={[roleGroups.EPC_PARTNER]}
				/>
			),
		},
		{
			path: '/campaigns',
			element: <RouteWithLayout component={Campaigns} authorizedRoleGroups={[roleGroups.ECOLIGO]} isListPage />,
		},
		{
			path: '/clients',
			element: <RouteWithLayout component={Clients} authorizedRoleGroups={[roleGroups.ECOLIGO]} isListPage />,
		},
		{
			path: '/unsignedClients',
			element: <RouteWithLayout component={Clients} authorizedRoleGroups={[roleGroups.ECOLIGO]} isListPage />,
		},
		{
			path: '/tranches',
			element: <RouteWithLayout component={Campaigns} authorizedRoleGroups={[roleGroups.ECOLIGO]} isListPage />,
		},
		{
			path: '/campaigns/details/:campaignId',
			element: <RouteWithLayout component={CampaignDetails} authorizedRoleGroups={[roleGroups.ECOLIGO]} />,
		},
		{
			path: '/privacy-policy',
			element: <RouteWithLayout component={PrivacyPolicy} requiresAuthentication={false} />,
		},

		/* Fallback route */
		{ path: '*', element: <PageNotFound /> },
	]);

	return element;
};

const App = ({ isGAInitialized }) => {
	useNotifications();
	const location = useLocation();

	useEffect(() => {
		if (isGAInitialized) {
			ReactGA.pageview(location.pathname);
		}
	}, [isGAInitialized, location.pathname]);

	return (
		<>
			<Helmet titleTemplate="pulse - %s" defaultTitle="pulse">
				<link
					href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,600;0,700;1,600&display=swap"
					rel="stylesheet"
				/>
			</Helmet>
			<GlobalStyles />
			<ToastContainer />
			<ReLoginPopup />
			<UnsavedChangesModalRedux />
			<CookieConsentDialog />
			<RouterErrorBoundary>
				<Routes />
			</RouterErrorBoundary>
		</>
	);
};

App.propTypes = {
	isGAInitialized: PropTypes.bool.isRequired,
};

export default App;
