import api from 'Application/api/api';

const login = (signal, email, password) =>
	api.post(
		'/auth/login',
		{
			username: email,
			password,
		},
		{
			signal,
		},
	);

export default login;
