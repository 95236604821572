import api from 'Application/api/api';
import getProjectsParamEncoder from 'Projects/api/customParamEncoders/getProjectsParamEncoder';
import defaultDashboardParams from './constants/defaultDashboardParams';

const getMyTeamOnHoldProjects = (signal, filters, page = 0) =>
	api.get('/projects/my-team-on-hold-projects', {
		params: {
			page,
			size: 10,
			...filters,
			...defaultDashboardParams,
		},
		signal,
		paramsSerializer: {
			encode: getProjectsParamEncoder.bind(undefined, Object.keys(filters)),
			indexes: true,
			dots: true,
		},
	});

export default getMyTeamOnHoldProjects;
