import api from 'Application/api/api';

const uploadClientContractDocuments = async (signal, files, clientContractId) => {
	const response = await api.post(`/client-contracts/${clientContractId}/upload-document`, files, {
		signal,
		timeout: 100000,
		headers: {
			'Content-Type': 'multipart/form-data',
			Accept: '*/*',
		},
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
	};
};

export default uploadClientContractDocuments;
