import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import HeaderTitle from 'Common/components/PageHeader/HeaderTitle';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import { roles } from 'User/constants/roles';

import GeneralTab from 'Preferences/components/Tabs/GeneralTab/GeneralTab';
import AdvancedTab from 'Preferences/components/Tabs/AdvancedTab/AdvancedTab';
import CountriesTab from 'Preferences/components/Tabs/CountriesTab/CountriesTab';
import CampaignsTab from 'Preferences/components/Tabs/CampaignsTab/CampaignsTab';
import MarketingTab from 'Preferences/components/Tabs/MarketingTab/MarketingTab';
import IndustriesTab from 'Preferences/components/Tabs/IndustriesTab/IndustriesTab';
import TabsContainer from 'Common/components/tabs';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history';

const Preferences = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const onPMWorkflowTabSelect = useCallback(() => {
		dispatch(push('/pm-workflow'));
	}, [dispatch]);

	const tabsConfig = useMemo(
		() => [
			{
				label: 'General',
				PanelComponent: GeneralTab,
				authorizedRoles: [
					roles.ADMIN,
					roles.FINANCE,
					roles.MANAGEMENT,
					roles.SALES,
					roles.PM_TECHNICAL,
					roles.ASSET_MANAGER,
					roles.ASSET_MARKETING,
					roles.HEAD_OF_ESG,
				],
				forceRender: true,
			},
			{
				label: 'Industries',
				PanelComponent: IndustriesTab,
				authorizedRoles: [
					roles.ADMIN,
					roles.FINANCE,
					roles.MANAGEMENT,
					roles.SALES,
					roles.ASSET_MARKETING,
					roles.HEAD_OF_ESG,
				],
				forceRender: true,
			},
			{
				label: 'Countries',
				PanelComponent: CountriesTab,
				forceRender: true,
			},
			{
				label: 'Campaigns',
				PanelComponent: CampaignsTab,
				authorizedRoles: [roles.ADMIN, roles.FINANCE, roles.MANAGEMENT, roles.PM_FUNDRAISING],
				forceRender: true,
			},
			{
				label: 'Marketing',
				PanelComponent: MarketingTab,
				queryAnchor: 'marketing',
				authorizedRoles: [
					roles.ADMIN,
					roles.FINANCE,
					roles.MANAGEMENT,
					roles.PM_FUNDRAISING,
					roles.ASSET_MARKETING,
				],
				forceRender: true,
			},
			{
				label: 'PM Workflow',
				PanelComponent: () => <></>,
				authorizedRoles: [roles.ADMIN],
				onTabSelect: onPMWorkflowTabSelect,
			},
			{
				label: 'Advanced',
				PanelComponent: AdvancedTab,
				authorizedRoles: [roles.ADMIN],
				forceRender: true,
			},
		],
		[onPMWorkflowTabSelect],
	);

	return (
		<>
			<HeaderContainer>
				<HeaderTitle>{t('Preferences')}</HeaderTitle>
			</HeaderContainer>
			<TabsContainer page="preferences" tabsConfig={tabsConfig} />
		</>
	);
};

export default Preferences;
