import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import crudModes from 'Common/constants/crudModes';
import useAbortController from 'Common/hooks/useAbortController';
import Overlay, { useOverlayTitles } from 'Common/components/modals/Overlay';
import SectionContainer from 'Common/components/modals/SectionContainer';
import getEmissionSpvById from 'Preferences/api/getEmissionSpvById';
import EmissionSpvForm from 'Preferences/components/Tabs/CampaignsTab/EmissionsSpv/EmissionSpvForm/EmissionSpvForm';
import showToastError from 'Common/utils/showToastError';

const EmissionSpvOverlay = ({ mode, data, id, isOpen, onClose, onFormSubmit, breadcrumbList }) => {
	const { t } = useTranslation();
	const { name, label } = useOverlayTitles(t('Emission SPV'), mode);

	const [emissionSpv, setEmissionSpv] = useState(null);

	const abortController = useAbortController();

	useEffect(() => {
		if (mode === crudModes.EDIT && isOpen && id) {
			(async () => {
				try {
					const response = await getEmissionSpvById(abortController.signal, id, true);
					setEmissionSpv(response.data);
				} catch (e) {
					showToastError(e, t("Can't fetch emission SPV data"));
				}
			})();
		}
	}, [id, abortController.signal, t, mode, isOpen]);

	const isDataLoading = mode === crudModes.EDIT && emissionSpv === null;

	const overlayBreadcrumbList = useMemo(
		() =>
			breadcrumbList || [
				{
					label: 'Emissions SPV',
					href: '/preferences',
				},
				{
					label: name,
				},
			],
		[breadcrumbList, name],
	);

	return (
		<Overlay
			label={label}
			breadcrumbList={overlayBreadcrumbList}
			isOpen={isOpen}
			onClose={onClose}
			onSave={onFormSubmit}
			isLoadingContent={isDataLoading}
		>
			{(setIsFormDirty, handleSubmit, onCancel) => (
				<SectionContainer name="EmissionSPV">
					<EmissionSpvForm
						data={{ ...emissionSpv, ...data }}
						mode={mode}
						onSubmit={handleSubmit}
						onCancel={onCancel}
						onDirty={setIsFormDirty}
					/>
				</SectionContainer>
			)}
		</Overlay>
	);
};

EmissionSpvOverlay.defaultProps = {
	data: undefined,
	id: undefined,
	breadcrumbList: undefined,
};

EmissionSpvOverlay.propTypes = {
	data: PropTypes.shape({}),
	mode: PropTypes.oneOf(Object.values(crudModes)).isRequired,
	id: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	breadcrumbList: PropTypes.arrayOf(
		PropTypes.shape({ label: PropTypes.string.isRequired, href: PropTypes.string }),
	),
};

export default EmissionSpvOverlay;
