const loadBlockedByOptions = (activities, currentActivityId) => {
	const options = [];

	activities.forEach(activity => {
		if (currentActivityId !== activity.id) {
			options.push({
				label: activity.title,
				value: activity.id,
			});
		}
	});

	return options;
};

export default loadBlockedByOptions;
