import { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import TileHeader from 'Common/components/Tile/TileHeader';
import PropTypes from 'prop-types';
import InfoText from 'Impact/components/InfoText';

import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';

const Wrapper = styled(Grid)`
	height: 100%;
	background-color: ${({ $isLightbox }) =>
		$isLightbox ? colors.common.white : colors.common.tileBackgroundGrey};
	border-radius: 20px;
	border: 1px solid transparent;
	&:hover {
		border-color: ${colors.primary.main};
	}
`;

const TitleHeaderWrapper = styled.div`
	padding: 16px 16px 0px 16px;
`;
const InfoContainer = styled.div`
	display: flex;
	flex-basis: content;
	flex-direction: ${({ $break }) => ($break ? 'column' : 'row')};
	gap: 10px;
`;

const ContainerWrapper = styled.div`
	width: 100%;
	display: flex;
	padding: ${sizes.spacing(1.5)} ${sizes.spacing(1.5)} ${sizes.spacing(3)} ${sizes.spacing(3)};
`;

const ImpactInfoTile = ({ title, data, gradient, $isLightbox }) => {
	const wrapperRef = useRef();
	const [containerWidth, setContainerWidth] = useState(0);
	//magic number - ItemContainer width + gap + container padding
	const containerTreshhold = 210;

	useEffect(() => {
		const handleResize = () => {
			setContainerWidth(wrapperRef.current.offsetWidth);
		};
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [wrapperRef]);

	return (
		<Wrapper container justifyContent="flex-start" alignItems="flex-start" $isLightbox={$isLightbox}>
			<TitleHeaderWrapper>
				<TileHeader title={title} />
			</TitleHeaderWrapper>
			<ContainerWrapper ref={wrapperRef}>
				<InfoContainer $isMultiple={data?.length} $break={containerWidth / data?.length < containerTreshhold}>
					{data?.map((item, index) => (
						// eslint-disable-next-line react/no-array-index-key
						<InfoText key={`impact-info-${index}`} item={item} gradient={item.gradient || gradient} />
					))}
				</InfoContainer>
			</ContainerWrapper>
		</Wrapper>
	);
};

ImpactInfoTile.propTypes = {
	title: PropTypes.string.isRequired,
	data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	gradient: PropTypes.string.isRequired,
	$isLightbox: PropTypes.bool,
};

ImpactInfoTile.defaultProps = {
	title: '',
	data: [],
	gradient: '',
	$isLightbox: false,
};

export default ImpactInfoTile;
