import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import reassignIndustry from 'Client/api/reassignIndustry';

import notify from 'Common/utils/notify';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';
import showAxiosResponseError from 'Common/utils/showAxiosResponseError';

import getIndustries from 'Industries/api/getIndustries';
import deleteIndustry from 'Industries/api/deleteIndustry';
import loadIndustryOptions from 'Industries/utils/loadIndustryOptions';
import useIndustriesReassignClientFormValidationSchema from './hooks/useIndustriesReassignClientFormValidationSchema';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { ModalFormButtons } from 'Common/components/form/ModalForm';

const Form = styled.form`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`;

const FieldsWrapper = styled.div`
	width: 100%;
`;

const IndustriesReassignClientForm = ({ industryId, onSubmit, onCancel, onDirty }) => {
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingIndustries, setIsLoadingIndustries] = useState(false);

	const initialValues = { industry: null };
	const validationSchema = useIndustriesReassignClientFormValidationSchema();
	const [industries, setIndsutries] = useState([]);

	const abortController = useAbortController();

	const { errors, touched, values, handleSubmit, dirty, handleBlur, setFieldError, resetForm, setFieldValue } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: async values => {
				setIsLoading(true);

				try {
					await reassignIndustry(abortController.signal, {
						newIndustryId: values.industry.value,
						oldIndustryId: industryId,
					});

					notify(t('Client industry changed'), {
						type: toast.TYPE.SUCCESS,
					});

					await deleteIndustry(abortController.signal, industryId);

					notify(t('Industry deleted successfully'), {
						type: toast.TYPE.SUCCESS,
					});

					const response = await getIndustries(abortController.signal);
					onSubmit(response.data.content);

					// Re-set all fields and mark them as not touched
					resetForm({ values });
					setIsLoading(false);
				} catch (error) {
					showAxiosResponseError({
						error,
						setFormikFieldError: setFieldError,
					});
					if (!axios.isCancel(error)) {
						setIsLoading(false);
					}
				}
			},
		});

	useEffect(() => {
		(async () => {
			setIsLoadingIndustries(true);
			try {
				const response = await loadIndustryOptions(abortController.signal, undefined, true);
				setIndsutries(response.filter(e => e.value !== industryId));
				setIsLoadingIndustries(false);
			} catch (error) {
				showToastError(error);
				setIsLoadingIndustries(false);
			}
		})();
	}, [industryId, abortController.signal]);

	useEffect(() => {
		if (onDirty) {
			onDirty(dirty);
		}
	}, [dirty, onDirty]);

	return (
		<Form onSubmit={handleSubmit}>
			<FieldsWrapper>
				<FormField>
					<FormikSelect
						id="industry"
						name="industry"
						label={t('New Industry')}
						value={values.industry}
						error={errors.industry}
						touched={touched.industry}
						setFieldValue={setFieldValue}
						onBlur={handleBlur}
						options={industries}
						isLoading={isLoadingIndustries}
						isRequired
						menuPosition="fixed"
					/>
				</FormField>
			</FieldsWrapper>
			<ModalFormButtons
				onCancel={onCancel}
				isLoading={isLoading}
				isDisabled={!values.industry}
				label="Preferences Industries Reassign Client Form"
				submitButtonText={t('Delete')}
			/>
		</Form>
	);
};

IndustriesReassignClientForm.defaultProps = {
	onDirty: undefined,
};

IndustriesReassignClientForm.propTypes = {
	industryId: PropTypes.number.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDirty: PropTypes.func,
};

export default IndustriesReassignClientForm;
