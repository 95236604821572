import { INVOICE_STATUSES, RESTRICTED_INVOICE_STATUSES } from 'Invoice/constants/invoiceContstants';
import { omit } from 'lodash';

const convertInvoiceValuesToRequestData = values => {
	return RESTRICTED_INVOICE_STATUSES.includes(values.status.value)
		? {
				exchangeRate: values.currency
					? values.currency.value === 'EUR'
						? 1
						: values.exchangeRate
					: values.exchangeRate,
				status: { status: values.status.value },
				currency: values.currency ? values.currency.value : 'EUR',
				saasCurrency: values.saasCurrency.value ?? 'EUR',
				paymentCurrency: values.paymentCurrency.value ?? 'EUR',
				templateId: values?.invoiceTemplate?.value,
				bankAccountId: values?.bankAccount?.value,
				eurBankAccountId: values?.eurBankAccount?.value,
				paymentDate: values.status.value === INVOICE_STATUSES.PAID ? values?.paymentDate : null,
		  }
		: {
				...omit(values, ['invoiceTemplate', 'bankAccount', 'paymentDate']),
				exchangeRate: values.currency
					? values.currency.value === 'EUR'
						? 1
						: values.exchangeRate
					: values.exchangeRate,
				status: { status: values.status.value },
				currency: values.currency ? values.currency.value : 'EUR',
				saasCurrency: values.saasCurrency ? values.saasCurrency.value : 'EUR',
				paymentCurrency: values.paymentCurrency ? values.paymentCurrency.value : 'EUR',
				templateId: values?.invoiceTemplate?.value,
				bankAccountId: values?.bankAccount?.value,
				eurBankAccountId: values?.eurBankAccount ? values?.eurBankAccount?.value : null,
				paymentDate: null,
		  };
};

export default convertInvoiceValuesToRequestData;
