export const userStates = {
	ACTIVE: 'ACTIVE',
	NOT_INVITED: 'NOT_INVITED',
	INACTIVE: 'INACTIVE',
};

export const userStateLabels = {
	[userStates.ACTIVE]: 'Active',
	[userStates.NOT_INVITED]: 'Not invited',
	[userStates.INACTIVE]: 'Inactive',
};
