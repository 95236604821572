import api from 'Application/api/api';

const getAllStepTemplates = (signal, title = '') => {
	return api.get('/step-templates/get-all', {
		params: {
			title,
		},
		signal,
	});
};

export default getAllStepTemplates;
