const convertFormValuesToRequestData = values => ({
	name: values.companyName,
	abbreviation: values?.abbreviation ?? '',
	registrationNumber: values?.registrationNumber ?? '',
	dateOfRegistration: values?.dateOfRegistration ?? '',
	taxNumber: values?.taxNumber ?? '',
	vatNumber: values?.vatNumber ?? '',
	countryId: values?.country?.value ?? '',
	directorsEmails: [
		values?.director1?.email ?? '',
		values?.director2?.email ?? '',
		values?.director3?.email ?? '',
	],
	address: {
		line1: values?.line1 ?? '',
		line2: values?.line2 ?? '',
		apartmentBuilding: values?.apartmentBuilding ?? '',
		cityRegion: values?.cityRegion ?? '',
		postCodeZip: values?.postCodeZip ?? '',
	},
});

export default convertFormValuesToRequestData;
