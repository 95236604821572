import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Filter from 'Common/components/filter/Filter';
import sizes from 'Application/theme/sizes';
import $inputBorderColor from '../form/utils/$inputBorderColor';
import FormikInput from '../form/FormikInput';
import FilterPill from './FilterPill';
import ClearFilterButton from './ClearFilterButton';
import colors from 'Application/theme/colors';
import Icon from '../icons/Icon';

const StyledFilterPill = styled(FilterPill)`
	margin: ${sizes.spacing(0.2)};
	margin-right: ${sizes.spacing(0.5)};
	font-weight: 500;
	background-color: ${colors.common.darkBlue};
	color: ${colors.common.white};
`;

const StyledFormikInput = styled(FormikInput)`
	font-size: 14px !important;
	border-bottom: none !important;
`;

const FieldWrapper = styled.div`
	border-bottom: 1px solid transparent;
	border-color: ${$inputBorderColor};
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	max-width: ${sizes.base(120)};
`;

const InputWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	input {
		min-width: 50px;
	}
`;

const ClearIcon = styled(Icon)`
	right: ${sizes.spacing(1)};
	color: ${colors.text.primary};
	cursor: pointer;
	&:hover {
		color: ${colors.grey.dark};
	}
`;

const MultiInputFilter = ({ name, label, setFilterValue, value, toggleOpen, inputType, ...props }) => {
	const [inputHaveFocus, setInputFocus] = useState(false);
	const [valueInput, setValueInput] = useState('');

	const handleClearInput = () => {
		setFilterValue({ [name]: [] });
		setValueInput('');
	};

	const handleClearValue = itemIndex => {
		const updatedValue = [...value.slice(0, itemIndex), ...value.slice(itemIndex + 1)];

		setFilterValue({ [name]: updatedValue });
	};

	const handleKeyDown = e => {
		if (e.key === 'Backspace' && valueInput === '') {
			handleClearValue(value.length - 1);
		} else if (e.key === 'Enter' && valueInput) {
			handleValueInput(`${valueInput} `);
		}
	};

	const inputRef = useRef();
	const [hasFocus, setFocus] = useState(false);
	const handleToggleOpen = (...args) => {
		toggleOpen(...args);
		setFocus(prevFocus => !prevFocus);
	};

	useEffect(() => {
		if (hasFocus && inputRef.current) {
			inputRef.current.focus();
		}
	}, [hasFocus, inputRef]);

	const handleValueInput = input => {
		if (/^\d+[ ,\n\r]$/.test(input)) {
			const val = input.slice(0, -1);

			// If pill value exists, remove it and add new pill to the end, e.g. pills = 1, 2, 3, 4; val = 2; new pills = 1, 3, 4, 2
			const uniqueItems = value.filter(item => item !== val);

			setFilterValue({ [name]: [...uniqueItems, val] });
			setValueInput('');
		} else if (/^\d*$/.test(input)) {
			setValueInput(input);
		}
	};

	const AppliedItems = () =>
		useMemo(
			() => (
				<>
					{Array.isArray(value) && value.length > 0
						? value?.map((item, index) => (
								<StyledFilterPill key={item}>
									{item}
									<ClearFilterButton onClick={() => handleClearValue(index)} />
								</StyledFilterPill>
						  ))
						: null}
				</>
			),
			[],
		);

	return (
		<Filter
			label={label}
			hasSelected={value && value.length > 0}
			handleClearOptions={handleClearInput}
			hasPadding
			toggleOpen={handleToggleOpen}
			{...props}
			popupContent={
				<FieldWrapper
					onFocus={() => setInputFocus(true)}
					onBlur={() => setInputFocus(false)}
					onKeyDown={handleKeyDown}
					$hasFocus={inputHaveFocus}
				>
					<AppliedItems />
					<InputWrapper>
						<StyledFormikInput
							value={valueInput}
							onChange={({ target: { value } }) => handleValueInput(value)}
							placeholder={"Enter project id's"}
							ref={inputRef}
							inputType={inputType}
						/>
						<ClearIcon icon="close" onClick={handleClearInput} size="smaller" />
					</InputWrapper>
				</FieldWrapper>
			}
		/>
	);
};

MultiInputFilter.defaultProps = {
	value: '',
	inputType: 'text',
};

MultiInputFilter.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	setFilterValue: PropTypes.func.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	toggleOpen: PropTypes.func.isRequired,
	inputType: PropTypes.string,
};

export default MultiInputFilter;
