import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import Icon from 'Common/components/icons/Icon';

const StyledCloseSidepanleBtn = styled(Icon)`
	cursor: pointer;

	&:hover {
		color: ${colors.primary.main};
	}
`;

const CloseSidepanleBtn = props => (
	<StyledCloseSidepanleBtn data-action="closePanel" icon="sidepanelClose" size="small" {...props} />
);

export default CloseSidepanleBtn;
