import { useMemo } from 'react';
import { pick } from 'lodash';
import formatDecimal from 'Common/utils/formatDecimal';
import Big from 'Common/utils/customBig';

const fallbackValues = {
	country: null,
	primaryManager: null,
	secondaryManager: null,
	gridEmissionFactor: '',
	gridTariffEscalationRatePerYear: '',
	regionalHub: {},
	whtServiceFeeOnly: false,
};

const useCountryFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			...pick(data, ['gridEmissionFactor']),
			country: data?.id
				? {
						label: data?.name,
						value: data?.id,
				  }
				: null,
			regionalHub: data?.regionalHub
				? {
						label: data?.regionalHub?.name,
						value: data?.regionalHub?.id,
				  }
				: null,
			primaryManager: data?.primaryManager
				? {
						label: `${data?.primaryManager?.firstName} ${data?.primaryManager?.lastName}`,
						value: data?.primaryManager.email,
				  }
				: null,
			secondaryManager: data?.secondaryManager
				? {
						label: `${data?.secondaryManager?.firstName} ${data?.secondaryManager?.lastName}`,
						value: data?.secondaryManager.email,
				  }
				: null,
			gridEmissionFactor: data?.gridEmissionFactorString,
			gridTariffEscalationRatePerYear: formatDecimal(data?.gridTariffEscalationRatePerYear, 2),
			vat: data?.vat && formatDecimal(Big(data?.vat).times(100), 2),
			wht: data?.wht && formatDecimal(Big(data?.wht).times(100), 2),
			vatWht: data?.vatWht && formatDecimal(Big(data?.vatWht).times(100), 2),
			nhil: data?.nhil && formatDecimal(Big(data?.nhil).times(100), 2),
			covid19Levy: data?.covid19Levy && formatDecimal(Big(data?.covid19Levy).times(100), 2),
			getFundLevy: data?.getFundLevy && formatDecimal(Big(data?.getFundLevy).times(100), 2),
			whtServiceFeeOnly: Boolean(data?.whtServiceFeeOnly),
		}),
		[data],
	);

export default useCountryFormInitialValues;
