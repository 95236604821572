import { useMemo } from 'react';

const fallbackValues = {
	name: '',
	website: '',
	country: null,
	apartmentBuilding: '',
	line1: '',
	line2: '',
	cityRegion: '',
	postCodeZip: '',
};

const useProjectDeveloperOverlayValues = data => {
	const defaultValues = useMemo(
		() => ({
			name: data?.name || fallbackValues.name,
			website: data?.website || fallbackValues.website,
			country: data?.country
				? {
						value: data?.country?.id,
						label: data?.country?.name,
				  }
				: null,
			apartmentBuilding: data?.address?.apartmentBuilding || fallbackValues.apartmentBuilding,
			line1: data?.address?.line1 || fallbackValues.line1,
			line2: data?.address?.line2 || fallbackValues.line2,
			cityRegion: data?.address?.cityRegion || fallbackValues.cityRegion,
			postCodeZip: data?.address?.postCodeZip || fallbackValues.postCodeZip,
		}),
		[
			data?.address?.apartmentBuilding,
			data?.address?.cityRegion,
			data?.address?.line1,
			data?.address?.line2,
			data?.address?.postCodeZip,
			data?.country,
			data?.name,
			data?.website,
		],
	);

	const sectionFields = useMemo(
		() => ({
			details: ['name', 'website'],
			address: ['country', 'apartmentBuilding', 'line1', 'line2', 'cityRegion', 'postCodeZip'],
		}),
		[],
	);

	return {
		defaultValues,
		sectionFields,
	};
};

export default useProjectDeveloperOverlayValues;
