const modifyNumberFilterData = (ranges, el) => {
	const newEl = { ...el };
	if (el.type === 'number') {
		// 'rangeKey' is when the name of the filter is not the same as the range key
		// For example, the filter name is 'testNumber' but the range key is 'testNumberRange'

		newEl.lowerBound = ranges[el.rangeKey ? el.rangeKey + 'Min' : el.name + 'Min'];
		newEl.upperBound = ranges[el.rangeKey ? el.rangeKey + 'Max' : el.name + 'Max'];
	}
	return newEl;
};

export default modifyNumberFilterData;
