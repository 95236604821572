import React from 'react';
import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import Label from 'Common/components/form/Label';
import sizes from 'Application/theme/sizes';
import FormikStandaloneRadio from 'Common/components/form/FormikStandaloneRadio';
import { PropTypes } from 'prop-types';

const Option = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	max-height: 24px;
`;

const OptionLabel = styled(Label)`
	${sizes.fontSize.main};
	color: ${colors.common.black};
	font-weight: 500;
	width: 100%;
	cursor: pointer;
`;

const StyledRadio = styled(FormikStandaloneRadio)`
	&.MuiButtonBase-root {
		color: ${colors.primary.light}!important;

		&.Mui-checked {
			color: ${colors.primary.light};

			&:hover {
				background-color: initial;
			}
		}

		&.Mui-focusVisible {
			color: ${colors.primary.dark};
		}

		.MuiSvgIcon-root:nth-child(1) {
			background: radial-gradient(circle, ${colors.primary.light} 65%, transparent 35%);
		}
		.MuiSvgIcon-root:nth-child(2) {
			color: ${colors.primary.dark};
		}

		&.Mui-disabled {
			* {
				color: ${colors.grey.light} !important;
			}
		}
	}
`;

const OptionsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${sizes.spacing(1)};
`;

const RadioButtonsList = ({ options, selectedValue, handleChange, uniqueCode = Math.random() }) => (
	<OptionsContainer>
		{options.map((option, i) => (
			// eslint-disable-next-line react/no-array-index-key
			<Option key={`${option.value}-${i}`}>
				<OptionLabel label={option.label} htmlFor={`radio-${uniqueCode}-${option.value}`} />
				<StyledRadio
					id={`radio-${uniqueCode}-${option.value}`}
					checked={option.value === selectedValue}
					onChange={() => handleChange(option.value)}
				/>
			</Option>
		))}
	</OptionsContainer>
);

RadioButtonsList.propTypes = {
	options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	selectedValue: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	uniqueCode: PropTypes.string.isRequired,
};

export default RadioButtonsList;
