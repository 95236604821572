import { Grid } from '@mui/material';
import CurrencySign from 'Common/components/CurrencySign';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import FormikSingleDatePicker from 'Common/components/form/FormikSingleDatePicker';
import KeyValueVisualization from 'Common/components/KeyValueVisualization';
import { SectionWrapper } from 'Common/components/modals/OverlaySections';
import crudModes from 'Common/constants/crudModes';
import UNITS from 'Common/constants/units';
import useAbortController from 'Common/hooks/useAbortController';
import usePrevious from 'Common/hooks/usePrevious';
import formatDate from 'Common/utils/formatDate';
import parseDate from 'Common/utils/parseDate';
import loadContractPartyBankAccounts from 'Entities/utils/loadContractPartyBankAccounts';
import loadEntityOptions from 'Projects/utils/loadEntityOptions';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const OtherContractualTermsSection = ({
	mode,
	values,
	errors,
	touched,
	handleBlur,
	handleChange,
	setFieldValue,
	setFieldTouched,
	countryId,
}) => {
	const { t } = useTranslation();
	const [bankAccountOptions, setBankAccountOptions] = useState([]);
	const [isMounted, setIsMounted] = useState(false);
	const isViewMode = mode === crudModes.VIEW;

	const abortController = useAbortController();

	const isEntitySelected = useMemo(() => values?.contractParty?.value, [values?.contractParty?.value]);

	const prevContractParty = usePrevious(values?.contractParty?.value);
	useEffect(() => {
		(async () => {
			if (values?.contractParty?.value && prevContractParty !== values?.contractParty?.value) {
				const bankAccounts = await loadContractPartyBankAccounts(
					abortController.signal,
					values?.contractParty?.value,
				);
				setBankAccountOptions(bankAccounts);

				if (isMounted) {
					setFieldValue('bankAccount', null);
				}
			}
		})();
	}, [abortController.signal, isMounted, prevContractParty, setFieldValue, values?.contractParty?.value]);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	return !isViewMode ? (
		<SectionWrapper>
			<Grid container>
				<Grid item xs={12} md={6}>
					<FormField data-first-field>
						<FormikSelect
							isAsync
							id="contractParty"
							name="contractParty"
							label={t('Signing entity')}
							value={values.contractParty}
							error={errors.contractParty}
							touched={touched.contractParty}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
							loadOptions={() => loadEntityOptions(abortController.signal, countryId)}
							isRequired
							isClearable
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							id="saasVersion"
							name="saasVersion"
							label={t('SaaS version')}
							value={values.saasVersion}
							error={errors.saasVersion}
							touched={touched.saasVersion}
							onChange={handleChange}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikSingleDatePicker
							id="longstop"
							label={t('Longstop date')}
							startDateName="longstop"
							error={errors.longstop}
							touched={touched.longstop}
							startDate={parseDate(values.longstop)}
							setFieldValue={setFieldValue}
							setFieldTouched={setFieldTouched}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField>
						<FormikInput
							prefix={() => <CurrencySign currency={values.saasCurrency} />}
							id="handoverPrice"
							name="handoverPrice"
							label={t('Handover price')}
							value={values.handoverPrice}
							error={errors.handoverPrice}
							touched={touched.handoverPrice}
							onChange={handleChange}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					{isEntitySelected ? (
						<FormField>
							<FormikSelect
								id="bankAccount"
								name="bankAccount"
								label={t('Ecoligo bank account')}
								value={values.bankAccount}
								error={errors.bankAccount}
								touched={touched.bankAccount}
								setFieldValue={setFieldValue}
								setFieldTouched={setFieldTouched}
								onBlur={handleBlur}
								options={bankAccountOptions}
								isRequired
								isOverlay
							/>
						</FormField>
					) : (
						<KeyValueVisualization title={t('Ecoligo bank account')} value={t('Choose entity first')} disabled />
					)}
				</Grid>
				<Grid item xs={12} md={6}>
					<FormField>
						<FormikInput
							prefix={() => <CurrencySign currency={values.saasCurrency} />}
							id="easementFee"
							name="easementFee"
							label={t('Easement fee')}
							value={values.easementFee}
							error={errors.easementFee}
							touched={touched.easementFee}
							onChange={handleChange}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
					<FormField data-last-field>
						<FormikInput
							unit={UNITS.PERCENT}
							id="minimumWarrantedFunctionality"
							name="minimumWarrantedFunctionality"
							label={t('Minimum Warranted Functionality')}
							value={values.minimumWarrantedFunctionality}
							error={errors.minimumWarrantedFunctionality}
							touched={touched.minimumWarrantedFunctionality}
							onChange={handleChange}
							setFieldTouched={setFieldTouched}
							onBlur={handleBlur}
							isRequired
							isOverlay
						/>
					</FormField>
				</Grid>
			</Grid>
		</SectionWrapper>
	) : (
		<SectionWrapper>
			<Grid container>
				<Grid item xs={12} md={6}>
					<KeyValueVisualization
						id="contractParty"
						title={t('Signing entity')}
						value={values.contractParty?.label}
					/>
					<KeyValueVisualization id="saasVersion" title={t('SaaS version')} value={values.saasVersion} />
					<KeyValueVisualization
						id="longstop"
						title={t('Longstop date')}
						value={values.longstop ? formatDate(values.longstop) : ''}
					/>
					<KeyValueVisualization
						id="saasCurrency"
						prefix={() => <CurrencySign currency={values.saasCurrency} />}
						title={t('Handover price')}
						value={values.handoverPrice}
					/>
					<KeyValueVisualization
						id="bankAccount"
						title={t('Ecoligo bank account')}
						value={values.bankAccount?.label}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<KeyValueVisualization
						id="saasCurrency"
						prefix={() => <CurrencySign currency={values.saasCurrency} />}
						title={t('Easement fee')}
						value={values.easementFee}
					/>
					<KeyValueVisualization
						id="minimumWarrantedFunctionality"
						units={UNITS.PERCENT}
						title={t('Minimum Warranted Functionality')}
						value={values.minimumWarrantedFunctionality}
					/>
				</Grid>
			</Grid>
		</SectionWrapper>
	);
};

OtherContractualTermsSection.defaultProps = {
	mode: crudModes.CREATE,
	countryId: '',
	values: {},
	errors: {},
	touched: {},
	handleBlur: () => {},
	handleChange: () => {},
	setFieldValue: () => {},
	setFieldTouched: () => {},
};

OtherContractualTermsSection.propTypes = {
	mode: PropTypes.oneOf([crudModes.CREATE, crudModes.EDIT, crudModes.VIEW]),
	values: PropTypes.shape({
		saasCurrency: PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.string,
		}),

		contractParty: PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.string,
		}),
		saasVersion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		longstop: PropTypes.instanceOf(Date),
		handoverPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		bankAccount: PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.string,
		}),
		easementFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		minimumWarrantedFunctionality: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	errors: PropTypes.shape({
		contractParty: PropTypes.string,
		saasVersion: PropTypes.string,
		longstop: PropTypes.string,
		handoverPrice: PropTypes.string,
		bankAccount: PropTypes.string,
		easementFee: PropTypes.string,
		minimumWarrantedFunctionality: PropTypes.string,
	}),
	touched: PropTypes.shape({
		contractParty: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
		saasVersion: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
		longstop: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
		handoverPrice: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
		bankAccount: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
		easementFee: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
		minimumWarrantedFunctionality: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
	}),
	handleBlur: PropTypes.func,
	handleChange: PropTypes.func,
	setFieldValue: PropTypes.func,
	setFieldTouched: PropTypes.func,
	countryId: PropTypes.string,
};

export default OtherContractualTermsSection;
