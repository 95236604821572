import { toast } from 'react-toastify';
import notify from 'Common/utils/notify';
import i18n from 'Application/i18n';
import showToastError from './showToastError';

/**
 * @param error Axios error, containing the response
 * @param setFieldError Function for setting an error to a field (typically that's react-hook-form's setError)
 * @param setFormikFieldError Function for setting an error to a field
 * @param skipFields Input validation for which fields should be skipped
 * @param toastFields Input validation for which fields should be toasted, instead of set with setFieldError/setFormikFieldError
 * @param fieldsMapping Mapping between returned field names and the names that will be used for setFieldError/setFormikFieldError and toastFields
 */
const showAxiosResponseError = ({
	error,
	setFieldError = null,
	setFormikFieldError = null,
	skipFields = [],
	toastFields = [],
	fieldsMapping = {},
}) => {
	if (
		error?.response?.status === 400 &&
		error?.response?.data?.code === 'InputValidation' &&
		error?.response?.data?.violations?.length > 0
	) {
		error.response.data.violations.forEach(error => {
			const fieldName = fieldsMapping[error.fieldName] ?? error.fieldName;

			if (!skipFields.includes(error.fieldName)) {
				if (toastFields.includes(error.fieldName)) {
					notify(
						i18n.t('{{field}} {{error}}', {
							field: i18n.t(fieldName),
							error: i18n.t(error.message),
						}),
						{
							type: toast.TYPE.ERROR,
						},
					);
				} else if (setFieldError) {
					setFieldError(fieldName, 'error', i18n.t(error.message));
				} else if (setFormikFieldError) {
					setFormikFieldError(fieldName, i18n.t(error.message));
				}
			}
		});
	} else {
		showToastError(error);
	}
};

export default showAxiosResponseError;
