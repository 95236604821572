import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';
import EditableTile from 'Common/components/Tile/EditableTile';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import EpcContractEditModal from 'EpcContract/components/EpcContractEditModal';
import EpcContractCreateModal from 'EpcContract/components/EpcContractCreateModal';
import { useTranslation } from 'react-i18next';
import PaymentMilestonesModal from '../PaymentMilestonesList/PaymentMilestonesModal';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import styled from 'styled-components';
import KeyValueVisualization, { KeyValueVisualizationWrapper } from 'Common/components/KeyValueVisualization';
import colors from 'Application/theme/colors';
import StatusPill from 'Common/components/StatusPill';
import PopperTooltip from 'Common/components/tooltip/PopperTooltip';
import Icon from 'Common/components/icons/Icon';
import Big from 'big.js';
// import deleteEpcContract from 'EpcContract/api/deleteEpcContract';
// import useAbortController from 'Common/hooks/useAbortController';
// import notify from 'Common/utils/notify';
// import { toast } from 'react-toastify';
// import showToastError from 'Common/utils/showToastError';
import formatNumber from 'Common/utils/formatNumber';

const StyledStatusPill = styled(StatusPill)`
	width: fit-content;
`;

const EPCClientContractFieldsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	${KeyValueVisualizationWrapper} {
		width: 25%;
	}
`;

const MilestonesContainer = styled.div`
	display: flex;
	align-items: center;
`;

const MilestoneValuesCount = styled.span`
	display: flex;
	align-items: center;

	svg {
		margin-left: 10px;
	}
`;

const MilestonesWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const MilestoneName = styled.span`
	color: ${colors.text.black};
	font-weight: 300;
`;

const MilestoneValue = styled.span``;

const EpcContractTile = ({ project, onFormSubmit }) => {
	const { t } = useTranslation();
	const [isCreateEpcModalOpened, setIsCreateEpcModalOpened] = useState(false);
	const [editedEpcContractId, setEditedEpcContractId] = useState(null);
	const [editedMilestonesForContract, setEditedMilestonesForContract] = useState(null);
	const [isTooltipOpened, setIsTooltipOpened] = useState(false);

	const epcContract = useMemo(() => project?.epcContract, [project?.epcContract]);

	const hasEpcContract = useMemo(() => Boolean(epcContract), [epcContract]);

	// const abortController = useAbortController();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.MANAGEMENT,
		roles.SALES,
		roles.PM_TECHNICAL,
	]);

	// const handleDeleteEpcContract = async id => {
	// 	try {
	// 		await deleteEpcContract(abortController.signal, id);
	// 		notify(t('Contract deleted successfully'), {
	// 			type: toast.TYPE.SUCCESS,
	// 		});

	// 		await onFormSubmit();
	// 	} catch (e) {
	// 		showToastError(e);
	// 	}
	// };

	const milestonesValue = useMemo(
		() => (
			<MilestonesContainer>
				<PopperTooltip
					onVisibleChange={setIsTooltipOpened}
					placement="bottom"
					trigger={epcContract?.milestoneValues?.length > 0 ? 'click' : null}
					tooltip={
						<div style={{ minWidth: '300px' }}>
							{epcContract?.milestoneValues?.map(value => (
								<MilestonesWrapper key={value?.epcMilestone?.id}>
									<MilestoneName>{value?.epcMilestone?.name}</MilestoneName>
									<MilestoneValue>{`${Big(value.value).times(100).toString()} %`}</MilestoneValue>
								</MilestonesWrapper>
							))}
						</div>
					}
				>
					<MilestoneValuesCount>
						{epcContract?.milestoneValues?.length}
						{epcContract?.milestoneValues?.length > 0 && (
							<>{isTooltipOpened ? <Icon icon="expandLess" /> : <Icon icon="expandMore" />}</>
						)}
					</MilestoneValuesCount>
				</PopperTooltip>
			</MilestonesContainer>
		),
		[epcContract?.milestoneValues, isTooltipOpened],
	);

	return (
		<>
			<EditableTile
				title={t('EPC contracts')}
				rightComponent={
					isAuthorized([roles.ADMIN, roles.FINANCE, roles.PM_TECHNICAL, roles.MANAGEMENT, roles.SALES]) ? (
						hasEpcContract ? (
							<MoreActionsButton noBackground>
								<MenuItem
									onClick={() => setEditedEpcContractId(epcContract.id)}
									disabled={!isAuthorizedToEdit}
									type="button"
									data-action=""
									label="Projects Details Contract EPC Contract List - Edit Edit EPC contract"
								>
									{t('Edit')}
								</MenuItem>
								<MenuItem
									onClick={() => setEditedMilestonesForContract(project?.epcContract)}
									disabled={!isAuthorizedToEdit}
									type="button"
									data-action="paymentMilestones"
									label="Projects Details Contract EPC Contract List - Edit Payment Milestones Menu Item"
								>
									{t('Edit Payment milestones')}
								</MenuItem>
								{/* <MenuItem
									onClick={() => handleDeleteEpcContract(epcContract.id)}
									type="button"
									data-action="deleteEpcContract"
									label="Projects Details Contract EPC Contract List - Delete EPC Contract Menu Item"
								>
									{t('Delete')}
								</MenuItem> */}
							</MoreActionsButton>
						) : (
							<TileActionButton onClick={() => setIsCreateEpcModalOpened(true)} disabled={hasEpcContract} />
						)
					) : null
				}
			>
				{hasEpcContract ? (
					<EPCClientContractFieldsWrapper>
						<KeyValueVisualization
							title={t('EPC volume')}
							value={formatNumber(epcContract?.epcVolumeExclVat?.value, 0)}
							prefix={
								epcContract?.epcVolumeExclVat?.currency === 'EUR' || !epcContract?.epcVolumeExclVat?.currency
									? '€'
									: epcContract?.epcVolumeExclVat?.currency
							}
						/>
						<KeyValueVisualization
							title={t('Annual O&M price')}
							value={formatNumber(epcContract?.annualOmPrice?.value, 0)}
							prefix={
								epcContract?.annualOmPrice?.currency === 'EUR' || !epcContract?.annualOmPrice?.currency
									? '€'
									: epcContract?.annualOmPrice?.currency
							}
						/>
						<KeyValueVisualization
							title={t('Annual O&M escalation rate')}
							value={
								(epcContract.annualOmEscalationRate
									? Big(epcContract?.annualOmEscalationRate).times(100).toString()
									: 0) + ' %'
							}
						/>
						<KeyValueVisualization title={t('Payment milestones')} value={milestonesValue} />
					</EPCClientContractFieldsWrapper>
				) : (
					<StyledStatusPill color={colors.common.brown}>Not created</StyledStatusPill>
				)}
			</EditableTile>
			<EpcContractCreateModal
				isOpen={isCreateEpcModalOpened}
				onClose={() => setIsCreateEpcModalOpened(false)}
				onFormSubmit={onFormSubmit}
				project={project}
			/>
			{editedEpcContractId !== null && (
				<EpcContractEditModal
					isOpen={editedEpcContractId !== null}
					onClose={() => setEditedEpcContractId(null)}
					onFormSubmit={onFormSubmit}
					project={project}
					contractId={editedEpcContractId}
				/>
			)}
			{editedMilestonesForContract && (
				<PaymentMilestonesModal
					isOpen={editedMilestonesForContract !== null}
					onClose={() => setEditedMilestonesForContract(null)}
					epcContract={editedMilestonesForContract}
					onFormSubmit={onFormSubmit}
				/>
			)}
		</>
	);
};

EpcContractTile.defaultProps = {
	project: null,
	onFormSubmit: () => {},
};

EpcContractTile.propTypes = {
	project: PropTypes.shape({
		epcContract: PropTypes.arrayOf(PropTypes.shape({})),
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	onFormSubmit: PropTypes.func,
};

export default EpcContractTile;
