import api from 'Application/api/api';
import adaptEpcContractResponseData from 'EpcContract/api/adapters/adaptEpcContractResponseData';

const getEpcContractById = async (signal, id, details = false) => {
	const response = await api.get(`/epc-contracts/by-id/${id}`, {
		params: {
			details,
		},
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptEpcContractResponseData(response.data),
	};
};

export default getEpcContractById;
