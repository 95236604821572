import api from 'Application/api/api';
import adaptProjectResponseData from 'Projects/api/adapters/adaptProjectResponseData';

const changeExchangeRateDate = async (signal, exchangeRateDate, projectCurrencyId, projectId) => {
	const response = await api.post(`/projects/change-exchange-rate/${projectId}`, null, {
		signal,
		timeout: 100000,
		params: {
			projectCurrencyId,
			exchangeRateDate: exchangeRateDate ? exchangeRateDate : undefined,
		},
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: adaptProjectResponseData(response.data),
	};
};

export default changeExchangeRateDate;
