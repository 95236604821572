import styled from 'styled-components/macro';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';
import FormikSelect from 'Common/components/form/FormikSelect';
import { components } from 'react-select';
import Label from 'Common/components/form/Label';
import Mandatory from 'Common/components/form/Mandatory';
import { PropTypes } from 'prop-types';

const FieldsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
	bottom: 120px;
	top: 120px;
	width: 100%;
`;

const Block = styled.div`
	display: flex;
	flex-direction: column;
	width: 25%;
	div:last-child {
		margin-bottom: 0;
		border: ${props => props.isHoverable && 'none'};
	}

	& > div {
		padding: ${props => props.isHoverable && '10px 18px'};
	}

	border: ${props => (props.isHoverable ? '1px solid #00b4d5' : 'none')};
	border-radius: ${props => (props.isHoverable ? '10px' : '0')};
	padding: ${props => (props.isHoverable ? '0 1px' : '1px 0')};
`;

const StyledFormField = styled(FormField)`
	margin: 0;
	padding: ${props => (props.noPadding ? '10px 0px' : '10px 20px')};
	height: 70px;
`;

const EmptyHeading = styled.div`
	height: 45px;
`;

const Heading = styled.div`
	height: 41px;
	display: flex;
	align-items: center;
	font-size: 16px;
	font-weight: 700;
`;

const SelectInput = props => <components.Input {...props} onPaste={props.selectProps.onPaste} />;

SelectInput.propTypes = {
	selectProps: PropTypes.shape({ onPaste: PropTypes.func.isRequired }).isRequired,
};

// This is needed, because otherwise Object.keys(initialValuesForOption) is not guaranteed
// to always give the correct order of the keys.
const optionKeys = ['contractType', 'duration'];

const optionsCount = 3;

const OfferSection = ({
	contractId,
	errors,
	touched,
	values,
	handleChange,
	handleBlur,

	setFieldValue,
	setFieldTouched,
	contractTypeOptions,
	isDisabled,
}) => {
	const handlePaste = (e, index, fieldName) => {
		e.preventDefault();
		e.stopPropagation();

		const dataByRows = e.clipboardData.getData('Text').split('\n');
		const parsedTableData = dataByRows.map(rowData => rowData.replace(/\r$/g, '').split('\t'));

		const pastedCellCoordinates = {
			x: optionKeys.indexOf(fieldName),
			y: index,
		};

		parsedTableData.forEach((rowData, rowIndex) => {
			const realRowIndex = pastedCellCoordinates.x + rowIndex;

			if (realRowIndex + 1 <= optionKeys.length) {
				const rowFieldName = optionKeys[realRowIndex];

				rowData.forEach((cellData, columnIndex) => {
					const realColumnIndex = pastedCellCoordinates.y + columnIndex;

					if (realColumnIndex + 1 <= optionsCount) {
						let cellDataValue = cellData;

						if (rowFieldName === 'contractType') {
							cellDataValue = contractTypeOptions.find(option => option.label === cellDataValue);

							if (typeof cellDataValue === 'undefined') {
								cellDataValue = {
									value: '',
									label: '',
								};
							}
						}

						if (['escalationRateInPercent'].includes(rowFieldName)) {
							// eslint-disable-next-line
							cellDataValue = cellDataValue.replace(/\%/g, '');
						}

						setFieldTouched(`[options][${realColumnIndex}][${rowFieldName}]`, true, false);
						setFieldValue(`[options][${realColumnIndex}][${rowFieldName}]`, cellDataValue, false);
					}
				});
			}
		});

		const target = e.target;

		// Wait for the async values pre-fill before blurring out the field
		// and making the validation.
		setTimeout(() => {
			target.blur();
		}, 200);
	};

	return (
		<>
			<ContentWrapper>
				<FieldsWrapper>
					<Block>
						<StyledFormField noPadding>
							<EmptyHeading />
						</StyledFormField>
						<StyledFormField noPadding>
							<Heading>Contract type</Heading>
						</StyledFormField>
						<StyledFormField noPadding>
							<Heading>Duration</Heading>
						</StyledFormField>
					</Block>

					{values.options.map((optionValues, index) => {
						const idPrefix = `${contractId}_option_${index}`;
						const namePrefix = `[options][${index}]`;
						const optionErrors = errors.options ? errors?.options[index] ?? {} : {};

						const optionTouched = touched.options ? touched?.options[index] ?? {} : {};

						return (
							// eslint-disable-next-line react/no-array-index-key
							<Block key={`offer-option-${index}`}>
								<StyledFormField>
									<Label
										label={
											<>
												{`Option ${index + 1}`}

												{index === 0 && <Mandatory />}
											</>
										}
									/>
								</StyledFormField>

								{optionKeys.map(fieldName => (
									<StyledFormField key={fieldName}>
										{fieldName === 'contractType' ? (
											<FormikSelect
												id={`${idPrefix}_${fieldName}`}
												name={`${namePrefix}[${fieldName}]`}
												error={optionErrors[fieldName]}
												touched={optionTouched[fieldName]}
												value={optionValues[fieldName]}
												setFieldValue={setFieldValue}
												options={contractTypeOptions}
												onBlur={e => {
													handleBlur(e);
													setFieldTouched(`${namePrefix}[${fieldName}]`, true, true);
												}}
												components={{
													Input: SelectInput,
												}}
												onFocus={() => setFieldTouched(`${namePrefix}[${fieldName}]`, true, true)}
												onPaste={e => handlePaste(e, index, fieldName)}
												isDisabled={isDisabled}
											/>
										) : (
											<FormikInput
												id={`${namePrefix}[${fieldName}]`}
												name={`${namePrefix}[${fieldName}]`}
												error={optionErrors[fieldName]}
												touched={optionTouched[fieldName]}
												value={optionValues[fieldName]}
												onChange={handleChange}
												onPaste={e => handlePaste(e, index, fieldName)}
												onBlur={handleBlur}
												onFocus={() => setFieldTouched(`${namePrefix}[${fieldName}]`, true, true)}
												isDisabled={isDisabled}
											/>
										)}
									</StyledFormField>
								))}
							</Block>
						);
					})}
				</FieldsWrapper>
			</ContentWrapper>
		</>
	);
};

OfferSection.defaultProps = {
	contractId: null,
};

OfferSection.propTypes = {
	contractId: PropTypes.number,
	errors: PropTypes.shape({ options: PropTypes.arrayOf(PropTypes.shape({})) }).isRequired,
	touched: PropTypes.shape({ options: PropTypes.arrayOf(PropTypes.shape({})) }).isRequired,
	values: PropTypes.shape({
		options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	}).isRequired,
	handleBlur: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	contractTypeOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	isDisabled: PropTypes.bool.isRequired,
};

export default OfferSection;
