import styled from 'styled-components/macro';

import colors from 'Application/theme/colors';

const StatusPill = styled.div`
	background: ${props => props.color};
	color: ${props => (props.color === '#FEE300' || props.isBlackText ? colors.text.black : colors.text.white)};
	border-radius: 20px;
	padding: 2px 12px;
	font-size: 14px;
	font-weight: 500;
	height: 21px;
	display: flex;
	align-items: center;
	white-space: nowrap;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;

export default StatusPill;
