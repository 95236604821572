import api from 'Application/api/api';

const asseignAssetAndRevenueRecognition = async (
	signal,
	{ contractPartyId, assetAndRevenueRecognitionId },
) => {
	const response = await api.post(
		`/contract-parties/assign-asset-and-revenue-recognition/${contractPartyId}?assetAndRevenueRecognitionId=${assetAndRevenueRecognitionId}`,
		{
			signal,
			timeout: 100000,
		},
	);

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: response.data,
	};
};

export default asseignAssetAndRevenueRecognition;
