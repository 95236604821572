import { FOCUSABLE_ELEMENTS_SELECTOR } from 'Common/constants/focusableElementsSelector';
import { useCallback, useEffect, useRef } from 'react';

const useOverlayTabTrap = (selectedSectionIndex, moveSectionArrow) => {
	const sectionsContainerRef = useRef();

	const handleFirstFieldKeyDown = useCallback(
		e => {
			if (e.which === 9 && e.shiftKey) {
				e.preventDefault();
				e.stopPropagation();
				moveSectionArrow(-1);
				e.target.blur();
			}
		},
		[moveSectionArrow],
	);

	const handleLastFieldKeyDown = useCallback(
		e => {
			if (e.which === 9 && !e.shiftKey) {
				e.preventDefault();
				e.stopPropagation();
				moveSectionArrow(1);
				e.target.blur();
			}
		},
		[moveSectionArrow],
	);

	const prefIndex = useRef(selectedSectionIndex);
	useEffect(() => {
		if (prefIndex.current !== selectedSectionIndex) {
			if (sectionsContainerRef.current) {
				const section = sectionsContainerRef.current.querySelector(
					`[data-section-index="${selectedSectionIndex}"]`,
				);
				if (section) {
					if (prefIndex.current < selectedSectionIndex) {
						section.querySelector(`[data-first-field] ${FOCUSABLE_ELEMENTS_SELECTOR}`)?.focus({
							preventScroll: true,
						});
					} else {
						section.querySelector(`[data-last-field] ${FOCUSABLE_ELEMENTS_SELECTOR}`)?.focus({
							preventScroll: true,
						});
					}
				}
			}

			prefIndex.current = selectedSectionIndex;
		}
	}, [selectedSectionIndex]);

	useEffect(() => {
		const container = sectionsContainerRef.current;

		if (container) {
			[...container.querySelectorAll(`[data-first-field] ${FOCUSABLE_ELEMENTS_SELECTOR}`)]?.forEach(el => {
				el.addEventListener('keydown', handleFirstFieldKeyDown);
			});
			[...container.querySelectorAll(`[data-last-field] ${FOCUSABLE_ELEMENTS_SELECTOR}`)]?.forEach(el => {
				el.addEventListener('keydown', handleLastFieldKeyDown);
			});
		}

		return () => {
			if (container) {
				[...container.querySelectorAll(`[data-first-field] ${FOCUSABLE_ELEMENTS_SELECTOR}`)]?.forEach(el => {
					el.removeEventListener('keydown', handleFirstFieldKeyDown);
				});
				[...container.querySelectorAll(`[data-last-field] ${FOCUSABLE_ELEMENTS_SELECTOR}`)]?.forEach(el => {
					el.removeEventListener('keydown', handleLastFieldKeyDown);
				});
			}
		};
	}, [handleFirstFieldKeyDown, handleLastFieldKeyDown]);

	return { sectionsContainerRef };
};

export default useOverlayTabTrap;
