import api from 'Application/api/api';
import convertToNullableValues from 'Common/utils/convertToNullableValues';
import adaptInvoiceRequestData from './adapters/adaptInvoiceRequestData';

const createInvoice = async (signal, values) => {
	const nullableValues = convertToNullableValues(values, ['number', 'status', 'currency']);

	const response = await api.post('/invoices', adaptInvoiceRequestData(nullableValues), {
		signal,
		timeout: 100000,
	});

	if (!response || !response.data) {
		return response;
	}

	return {
		...response,
		data: response.data,
	};
};

export default createInvoice;
