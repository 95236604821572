export default class CustomDebounce {
	constructor() {
		this.lastCallback = undefined;
		this.timeout = undefined;
	}

	_startTimeout = waitTime => {
		this.timeout = setTimeout(() => {
			typeof this.lastCallback === 'function' && this.lastCallback();
			this.lastCallback = undefined;
			this.timeout = undefined;
		}, waitTime);
	};

	debounce = (func, waitTime) => {
		this.lastCallback = func;
		if (!this.timeout) {
			this._startTimeout(waitTime);
		}
	};
}
