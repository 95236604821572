import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import sizes from 'Application/theme/sizes';
import colors from 'Application/theme/colors';

import UsersPreview from 'Common/components/usersPreview/UsersPreview';
import CustomStatusDropdown from 'Common/components/StatusDropdown/StatusDropdown';

import { responsibleTeamOptions } from 'PMWorkflow/constants/options';

const StepInfo = styled.div``;

const ActivityName = styled.div`
	font-size: 14px;
	font-weight: 300;
`;

const StepHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: ${sizes.spacing(2)};
`;

const Title = styled.div`
	font-weight: 700;
`;

const StepHeaderActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${sizes.spacing(2)};
`;

const Description = styled.div`
	margin-bottom: ${sizes.spacing(2)};
`;

const DescriptionLabel = styled.div`
	font-size: 14px;
	color: ${colors.text.grey};
`;

const SidePanelStepInfo = ({ step, onStatusUpdate, dropdownProps }) => (
	<StepInfo>
		<ActivityName id="activityName">{step.activityTitle}</ActivityName>
		<StepHeader>
			<Title id="stepName">{step.title}</Title>
			<StepHeaderActions>
				<UsersPreview popupProps={{ $alignment: 'right' }} users={{ users: step.responsiblePerson }} />
				<CustomStatusDropdown
					step={step}
					onStatusUpdate={onStatusUpdate}
					projectId={dropdownProps.projectId}
					{...dropdownProps}
				/>
			</StepHeaderActions>
		</StepHeader>
		{step.description && (
			<Description>
				<DescriptionLabel>Description</DescriptionLabel>
				<div id="description">{step.description}</div>
			</Description>
		)}
		{step.responsibleTeam && (
			<Description>
				<DescriptionLabel>Responsible team</DescriptionLabel>
				<div id="responsibleTeam">
					{responsibleTeamOptions.find(option => option.value === step?.responsibleTeam?.name)?.label}
				</div>
			</Description>
		)}
	</StepInfo>
);

SidePanelStepInfo.defaultValues = {
	step: {},
	onStatusUpdate: () => {},
	dropdownProps: {},
};

SidePanelStepInfo.propTypes = {
	step: PropTypes.shape({
		activityTitle: PropTypes.string,
		title: PropTypes.string,
		responsiblePerson: PropTypes.shape({}),
		description: PropTypes.string,
		responsibleTeam: PropTypes.shape({ name: PropTypes.string }),
	}).isRequired,
	onStatusUpdate: PropTypes.func.isRequired,
	dropdownProps: PropTypes.shape({
		projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}).isRequired,
};

export default SidePanelStepInfo;
