import { useEffect, useMemo, useState } from 'react';
// import { compareDesc } from 'date-fns';

const setupComments = (deletedCommentsIds, prevComments, newComments) => {
	// ! newComments come from Redux. The sort method won't work directly so we need to make a copy of newComments
	const comments = [...newComments];
	const sortedComments = comments.sort((a, b) => b.id - a.id);
	const hasAddedComments = prevComments.length < newComments.length + deletedCommentsIds.length;

	const modifiedComments = sortedComments.map(comment => {
		const isDeleted = deletedCommentsIds.includes(comment.id);

		const isInPrevComments = prevComments.find(prevComment => prevComment.id === comment.id);
		const hasPrevComments = prevComments.length > 0;
		const isNew = hasPrevComments && hasAddedComments && !isInPrevComments;

		return {
			...comment,
			isDeleted,
			isNew,
		};
	});

	return modifiedComments;
};

const useDynamicComments = comments => {
	const [deletedCommentsIds, setDeletedCommentsIds] = useState([]);
	const [dynamicComments, setDynamicComments] = useState([]);

	const generateDynamicComments = useMemo(
		() => setupComments.bind(undefined, deletedCommentsIds),
		[deletedCommentsIds],
	);

	useEffect(() => {
		setDynamicComments(prevComments => generateDynamicComments(prevComments, comments));
	}, [comments, generateDynamicComments]);

	const handleCommentDelete = id => {
		setDeletedCommentsIds([...deletedCommentsIds, id]);
	};

	return { dynamicComments, handleCommentDelete, deletedCommentsIds };
};

export { useDynamicComments, setupComments };
