import getAssetAndRevenueRecognition from 'Preferences/api/getAssetAndRevenueRecognition';

const loadAssetAndRevenueRecognitionOptions = async (signal, inputValue) => {
	const { data: assetsAndRevenueRecognitionOptions } = await getAssetAndRevenueRecognition(signal, inputValue);
	return assetsAndRevenueRecognitionOptions.map(asset => ({
		label: asset.name,
		value: asset.id,
	}));
};

export default loadAssetAndRevenueRecognitionOptions;
