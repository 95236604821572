import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import EditableTile from 'Common/components/Tile/EditableTile';

import editEntity from 'Entities/api/editEntity';
import useAddressTileFormInitialValues from 'Entities/components/EntitiesDetails/Tabs/GeneralTab/Tiles/AddressTile/hooks/useAddressTileFormInitialValues';
import useAddressTileFormValidationSchema from 'Entities/components/EntitiesDetails/Tabs/GeneralTab/Tiles/AddressTile/hooks/useAddressTileFormValidationSchema';
import convertAddressTileFormValues from 'Entities/components/EntitiesDetails/Tabs/GeneralTab/Tiles/AddressTile/utils/convertAddressTileFormValues';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';
import loadCountryOptions from 'Country/utils/loadCountryOptions';
import FormikInput from 'Common/components/form/FormikInput';
import assignEntityCountry from 'Entities/api/assignEntityCountry';

const AddressTile = ({ entity, onEnterEditMode, onExitEditMode, onDirtyForm, onSave, highlightLabels }) => {
	const { t } = useTranslation();

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const abortController = useAbortController();

	const initialValues = useAddressTileFormInitialValues(entity);
	const validationSchema = useAddressTileFormValidationSchema();

	const { errors, touched, values, handleSubmit, dirty, handleBlur, handleChange, setFieldValue, resetForm } =
		useFormik({
			initialValues,
			validationSchema,
			onSubmit: async values => {
				setIsSavingChanges(true);

				try {
					await editEntity(abortController.signal, {
						...entity,
						pin: values.country.label === 'Kenya' ? entity.pin : '',
						...convertAddressTileFormValues(values),
					});

					await assignEntityCountry(abortController.signal, {
						contractPartyId: entity.id,
						countryId: values.country.value,
					});

					if (onDirtyForm) {
						onDirtyForm(false);
					}

					onSave();
					handleCancel();
					setIsSavingChanges(false);
					resetForm({ values });
				} catch (e) {
					showToastError(e);
					if (!axios.isCancel(e)) {
						handleCancel();
						setIsSavingChanges(false);
					}
				}
			},
		});

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	return (
		<EditableTile
			title={t('Address')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
			zIndex={1}
		>
			<FormField>
				<FormikSelect
					isAsync
					id="country"
					name="country"
					label={t('Country')}
					value={values.country}
					error={errors.country}
					touched={touched.country}
					setFieldValue={setFieldValue}
					onBlur={handleBlur}
					loadOptions={loadCountryOptions}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('country')}
					isRequired
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="apartmentBuilding"
					name="apartmentBuilding"
					label={t('Apartment / Building')}
					value={values.apartmentBuilding}
					error={errors.apartmentBuilding}
					touched={touched.apartmentBuilding}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('apartmentBuilding')}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="line1"
					name="line1"
					label={t('Address line 1')}
					value={values.line1}
					error={errors.line1}
					touched={touched.line1}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('line1')}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="line2"
					name="line2"
					label={t('Adddress line 2')}
					value={values.line2}
					error={errors.line2}
					touched={touched.line2}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('line2')}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="cityRegion"
					name="cityRegion"
					label={t('City / Region')}
					value={values.cityRegion}
					error={errors.cityRegion}
					touched={touched.cityRegion}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('cityRegion')}
				/>
			</FormField>
			<FormField>
				<FormikInput
					id="postCodeZip"
					name="postCodeZip"
					label={t('Post code / ZIP')}
					value={values.postCodeZip}
					error={errors.postCodeZip}
					touched={touched.postCodeZip}
					onChange={handleChange}
					onBlur={handleBlur}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes('postCodeZip')}
				/>
			</FormField>
		</EditableTile>
	);
};

AddressTile.defaultProps = {
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	onSave: async () => {},
	highlightLabels: [],
};

AddressTile.propTypes = {
	entity: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		pin: PropTypes.string,
	}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	onSave: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default AddressTile;
