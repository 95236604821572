import { useEffect, useState } from 'react';

export const formatStage = stage =>
	stage
		.split('_')
		.map(t => t.substring(0, 1).toUpperCase() + t.substring(1).toLowerCase())
		.join(' ');

export const pushIndex = (arr, ind) =>
	arr.length > 0 ? (arr[0] <= ind ? pushIndex(arr.slice(1), ind + 1) : ind) : ind;

export const diff = (prevSteps, newProjectSteps) => {
	const prevIds = prevSteps.map(step => step.id);
	const newIds = newProjectSteps.map(step => step.id);

	const removedSteps = {};
	prevSteps.forEach((step, ind) => {
		if (!newIds.includes(step.id)) {
			removedSteps[ind] = step;
		}
	});

	const addedSteps = {};
	const rest = {};
	const removedKeys = Object.keys(removedSteps);

	newProjectSteps.forEach((step, ind) => {
		const newInd = pushIndex(removedKeys, ind);
		if (!prevIds.includes(step.id)) {
			addedSteps[newInd] = step;
		} else {
			rest[newInd] = step;
		}
	});

	return { removedSteps, addedSteps, rest };
};

export const compareSteps = (prevSteps, newProjectSteps) => {
	const { removedSteps, addedSteps, rest } = diff(prevSteps, newProjectSteps);

	const combinedLength = [removedSteps, addedSteps, rest].reduce(
		(acc, curr) => acc + Object.keys(curr).length,
		0,
	);

	const visibleSteps = [];
	const allSteps = Array.apply(null, Array(combinedLength)).map((_, ind) => {
		if (removedSteps[ind]) {
			const removedStep = { ...removedSteps[ind], isRemoving: true, isNewlyAdded: false };
			return removedStep;
		} else if (addedSteps[ind]) {
			const addedStep = { ...addedSteps[ind], isNewlyAdded: true, isRemoving: false };
			visibleSteps.push(addedStep);
			return addedStep;
		} else {
			const restStep = { ...rest[ind], isNewlyAdded: false, isRemoving: false };
			visibleSteps.push(restStep);
			return restStep;
		}
	});

	return { visibleSteps, allSteps };
};

export const useGeneratedSteps = (stepModifier, project, currentUser) => {
	const [stepsState, setStepsState] = useState({ visibleSteps: [], allSteps: [] });

	useEffect(() => {
		setStepsState(({ allSteps: prevAllSteps }) => {
			const newProjectSteps = [];

			project.projectStages.forEach(projectStage => {
				const formattedProjectStage = { ...projectStage, stage: formatStage(projectStage?.stage) };
				projectStage.activities.forEach(activity =>
					activity.steps.forEach(step => {
						step.projectStatus = project.condition;
						step.activityTitle = activity.title;

						const modifiedStep = stepModifier({
							project,
							currentUser,
							projectStage: formattedProjectStage,
							activity,
							step,
							prevStepsState: prevAllSteps,
						});
						newProjectSteps.push(modifiedStep);
					}),
				);
			});

			if (prevAllSteps.length === 0) {
				return { visibleSteps: newProjectSteps, allSteps: newProjectSteps };
			}

			const { visibleSteps, allSteps } = compareSteps(prevAllSteps, newProjectSteps);

			return { allSteps, visibleSteps };
		});
	}, [stepModifier, project, currentUser]);

	return stepsState;
};
