/* eslint-disable react/prop-types */

import Grid from '@mui/material/Grid';
import { PropTypes } from 'prop-types';

import TileHeader from 'Common/components/Tile/TileHeader';
import { offersTableFields } from 'Impact/constants/options';
import ImpactTable from 'Impact/components/ImpactTable/ImpactTable';

import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import sizes from 'Application/theme/sizes';

import { useTranslation } from 'react-i18next';
import useReponsive from 'Common/hooks/useResponsive';

const TileContainer = styled(Grid)`
	height: 100%;
	background-color: ${colors.common.tileBackgroundGrey};
	border-radius: 20px;
	border: 1px solid transparent;
	&:hover {
		border-color: ${colors.primary.main};
	}
`;

const TitleHeaderWrapper = styled.div`
	padding: 16px 16px 0px 16px;
`;

const TableWrapper = styled.div`
	width: 100%;
	height: 100%;
	padding: ${sizes.spacing(2)} ${sizes.spacing(2)};
`;

const StyledGridHeader = styled(Grid)`
	height: 40px;
`;

const RestrictedWrapper = styled.div`
	display: flex;
	width: calc(83% - ${sizes.spacing(2)});
	margin-left: auto;
	margin-right: 12px;
	justify-content: center;
	padding: ${sizes.spacing(1)} ${sizes.spacing(2)};
`;

const RestrictedTitleText = styled.div`
	display: flex;
	width: 55%;
	justify-content: center;
	color: ${colors.common.darkGreen};
	font-size: 14px;
	font-weight: 700;
	border-bottom: 1px solid ${colors.common.borderGrey};
	padding: ${sizes.spacing(1)} ${sizes.spacing(1)};
`;

const RestrictedTitleMobile = styled.div`
	color: ${colors.common.darkGreen};
	font-size: 14px;
	font-weight: 700;
	padding: 0 ${sizes.spacing(1)} 0 ${sizes.spacing(2)};
`;

const RestrictedIcon = styled.div`
	background-color: ${colors.common.darkGreen};
	border-radius: 50%;
	padding: ${sizes.spacing(1)} ${sizes.spacing(1)};
	width: 8px;
	height: 8px;
`;

const ImpactOffersTile = ({ data }) => {
	const { t } = useTranslation();
	const { isMobile, isTablet } = useReponsive();

	return (
		<>
			<TileContainer container>
				<StyledGridHeader item xs={12} sm={12} md={12} lg={12} xl={12}>
					<TitleHeaderWrapper>
						<TileHeader title={t('offers')} />
					</TitleHeaderWrapper>
				</StyledGridHeader>
				{!isMobile && !isTablet && (
					<RestrictedWrapper>
						<RestrictedTitleText>{t('Restricted')}</RestrictedTitleText>
					</RestrictedWrapper>
				)}
				<TableWrapper>
					<ImpactTable
						data={data}
						rowLabels={offersTableFields.rowLabels}
						columnLabels={offersTableFields.columnLabels}
						cells={offersTableFields.cells}
						isBigTable={!isMobile && !isTablet}
					/>
					{(isMobile || isTablet) && (
						<RestrictedWrapper>
							<RestrictedIcon />
							<RestrictedTitleMobile>{t('Restricted')}</RestrictedTitleMobile>
						</RestrictedWrapper>
					)}
				</TableWrapper>
			</TileContainer>
		</>
	);
};

ImpactOffersTile.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ImpactOffersTile;
