import styled from 'styled-components/macro';
import colors from 'Application/theme/colors';
import { PropTypes } from 'prop-types';

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
`;

const FilterItem = styled.div`
	color: ${props => (props.isActive ? `${colors.primary.dark}` : `${colors.text.greyDark}`)};
	border-right: 1px solid #d9d9d9;
	padding: 4px 16px;
	font-size: 12px;
	font-weight: 500;
	cursor: pointer;
	&:last-child {
		border-right: none;
	}
`;

const GraphFilter = ({ filter, onFilterSelect }) => {
	const filterSteps = ['1M', '6M', 'YTD', '1Y', 'Max'];

	return (
		<>
			<Wrapper>
				{filterSteps?.map(item => (
					<FilterItem
						key={`filter-${item}`}
						onClick={() => onFilterSelect({ time: item })}
						isActive={filter === item}
					>
						{item}
					</FilterItem>
				))}
			</Wrapper>
		</>
	);
};

GraphFilter.propTypes = {
	filter: PropTypes.string.isRequired,
	onFilterSelect: PropTypes.func.isRequired,
};

export default GraphFilter;
