import Big from 'Common/utils/customBig';

const adaptClientContractRequestData = data => ({
	...data,
	annualOmEscalationRate: data.annualOmEscalationRate
		? Number(Big(data.annualOmEscalationRate).div(100).toString())
		: data.annualOmEscalationRate,
	escalationRateInPercent: data.escalationRateInPercent
		? Number(Big(data.escalationRateInPercent).div(100).toString())
		: data.escalationRateInPercent,
	minimumPurchaseAmount: data.minimumPurchaseAmount
		? Number(Big(data.minimumPurchaseAmount).div(100).toString())
		: data.minimumPurchaseAmount,
	discountOnGridTariff: data.discountOnGridTariff
		? Number(Big(data.discountOnGridTariff).div(100).toString())
		: data.discountOnGridTariff,
	minimumWarrantedFunctionality: data.minimumWarrantedFunctionality
		? Number(Big(data.minimumWarrantedFunctionality).div(100).toString())
		: null,
	solarUtilizationRate: data.solarUtilizationRate
		? Number(Big(data.solarUtilizationRate).div(100).toString())
		: null,
	percentageOnGridTariff: data.percentageOnGridTariff
		? Number(Big(data.percentageOnGridTariff).div(100).toString())
		: null,
	solarPvDegradation: data.solarPvDegradation
		? Number(Big(data.solarPvDegradation).div(100).toString())
		: null,
	projectedIrr: data.projectedIrr ? Number(Big(data.projectedIrr).div(100).toString()) : null,
	worstCaseIrr: data.worstCaseIrr ? Number(Big(data.worstCaseIrr).div(100).toString()) : null,
});

export default adaptClientContractRequestData;
