import Big from 'big.js';

const adaptClientContractResponseData = data => ({
	...data,
	clientUpfrontPaymentDate: data.clientUpfrontPaymentDate,
	annualOmEscalationRate: data.annualOmEscalationRate
		? Big(data.annualOmEscalationRate).times(100).toString()
		: data.annualOmEscalationRate,
	escalationRateInPercent: data.escalationRateInPercent
		? Big(data.escalationRateInPercent).times(100).toString()
		: data.escalationRateInPercent,
	minimumPurchaseAmount: data.minimumPurchaseAmount
		? Big(data.minimumPurchaseAmount).times(100).toString()
		: data.minimumPurchaseAmount,
	discountOnGridTariff: data.discountOnGridTariff
		? Big(data.discountOnGridTariff).times(100).toString()
		: data.discountOnGridTariff,
	minimumWarrantedFunctionality: data.minimumWarrantedFunctionality
		? Big(data.minimumWarrantedFunctionality).times(100).toString()
		: undefined,
	solarUtilizationRate: data.solarUtilizationRate
		? Big(data.solarUtilizationRate).times(100).toString()
		: undefined,
	percentageOnGridTariff: data.percentageOnGridTariff
		? Big(data.percentageOnGridTariff).times(100).toString()
		: undefined,
	solarPvDegradation: data.solarPvDegradation ? Big(data.solarPvDegradation).times(100).toString() : undefined,
	projectedIrr: data.projectedIrr ? Big(data.projectedIrr).times(100).toString() : data.projectedIrr,
	worstCaseIrr: data.worstCaseIrr ? Big(data.worstCaseIrr).times(100).toString() : data.worstCaseIrr,
});

export default adaptClientContractResponseData;
