const getProjectsParamEncoder = (keys, parsedParam, defaultEncoder) => {
	const type = keys?.find(key => typeof parsedParam === 'string' && parsedParam.includes(key))
		? 'key'
		: 'value';

	if (type === 'key' && !parsedParam.includes('combinedStepStatusTitles')) {
		const trimmedParam = parsedParam.replace(/\.\d+$/g, '');
		return trimmedParam;
	} else if (type === 'key' && parsedParam.includes('combinedStepStatusTitles')) {
		const trimmedParam = parsedParam.replace(/\.(\d+)/g, '%5B$1%5D');
		return trimmedParam;
	}
	return defaultEncoder(parsedParam, defaultEncoder);
};

export default getProjectsParamEncoder;
