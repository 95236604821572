import Big from 'Common/utils/customBig';

const adaptClientResponseData = data => ({
	...data,
	industryId: data?.industry?.id,
	scoringProfitabilityNetIncomeProfitMargin: data.scoringProfitabilityNetIncomeProfitMargin
		? Number(Big(data.scoringProfitabilityNetIncomeProfitMargin).times(100).toString())
		: data.scoringProfitabilityNetIncomeProfitMargin,
	wht: data.wht ? Number(Big(data.wht).times(100).toString()) : data.wht,
});

export default adaptClientResponseData;
