import { useMemo } from 'react';

const fallbackValues = {
	name: '',
	numberOfShares: '0',
};

const useShareholderFormInitialValues = data =>
	useMemo(
		() => ({
			...fallbackValues,
			name: data?.name || '',
			shareholderNumberOfShares: data?.numberOfShares || '0',
		}),
		[data],
	);

export default useShareholderFormInitialValues;
