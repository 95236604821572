import ReactGA from 'react-ga';

const customGAException = (description, fatal = false) => {
	return ReactGA.exception({
		description,
		fatal,
	});
};

export default customGAException;
