import getMyOnHoldProjects from 'Dashboard/api/getMyOnHoldProjects';
import DashboardTab from 'Dashboard/components/DashboardTab';
import useMyOnHoldTabStepModifier from 'Dashboard/components/MyActivities/Tabs/MyOnHoldTab/hooks/useMyOnHoldTabStepModifier';

const MyOnHoldTab = () => (
	<DashboardTab
		helpText="Projects assigned to you which are on hold.  To work on a Project, open it and change its status to ‘In progress’ on the General tab."
		getterFunction={getMyOnHoldProjects}
		stepModifier={useMyOnHoldTabStepModifier}
	/>
);

export default MyOnHoldTab;
