import Big from 'big.js';
import projectTypes from 'Projects/constants/projectTypes';
import { useMemo } from 'react';

const fallbackValues = {
	systemSize: '',
	yield: '',
	solarUtilisationRate: '',
	expectedGridInjection: '',
	epcVolumeExclVat: undefined,
	currency: {
		value: 'EUR',
	},
	annualOmPrice: undefined,
	annualOmEscalationRate: '',
	epcVolumeSwitch: false,
	epcVolumeExclVatKwp: undefined,
	omPriceSwitch: false,
	annualOmPriceKwp: undefined,
};

const useEstimateOverlayValues = (project, epcOffer) => {
	const defaultValues = useMemo(
		() => ({
			systemSize: epcOffer?.systemSize || fallbackValues.systemSize,
			yield: epcOffer?.yield || fallbackValues.yield,
			solarUtilisationRate: epcOffer?.solarUtilisationRate
				? Big(epcOffer?.solarUtilisationRate).times(100).toString()
				: fallbackValues.solarUtilisationRate,
			epcVolumeExclVat: epcOffer?.epcVolumeExclVat?.value || fallbackValues.epcVolumeExclVat,
			epcVolumeExclVatKwp: epcOffer?.epcVolumeExclVatKwp?.value || fallbackValues.epcVolumeExclVatKwp,
			annualOmPrice: epcOffer?.annualOmPrice?.value || fallbackValues.annualOmPrice,
			annualOmPriceKwp: epcOffer?.annualOmPriceKwp?.value || fallbackValues.annualOmPriceKwp,
			annualOmEscalationRate: epcOffer?.annualOmEscalationRate
				? Big(epcOffer?.annualOmEscalationRate).times(100).toString()
				: fallbackValues.annualOmEscalationRate,
			epcVolumeSwitch: !!epcOffer?.epcVolumeExclVat?.calculated || fallbackValues.epcVolumeSwitch,
			omPriceSwitch: !!epcOffer?.annualOmPrice?.calculated || fallbackValues.omPriceSwitch,
			currency: epcOffer?.currency ? { value: epcOffer.currency } : fallbackValues.currency,
			projectType: project?.projectType?.name,
			isProjectPV: project?.projectType?.name === projectTypes.PV,
		}),
		[
			epcOffer?.systemSize,
			epcOffer?.yield,
			epcOffer?.solarUtilisationRate,
			epcOffer?.epcVolumeExclVat?.value,
			epcOffer?.epcVolumeExclVat?.calculated,
			epcOffer?.epcVolumeExclVatKwp?.value,
			epcOffer?.annualOmPrice?.value,
			epcOffer?.annualOmPrice?.calculated,
			epcOffer?.annualOmPriceKwp?.value,
			epcOffer?.annualOmEscalationRate,
			epcOffer?.currency,
			project?.projectType?.name,
		],
	);

	const sectionFields = useMemo(
		() => ({
			size: ['systemSize', 'solarUtilisationRate', 'yield'],
			epcVolume: ['epcVolumeExclVat', 'epcVolumeExclVatKwp', 'currency'],
			om: ['annualOmPrice', 'annualOmPriceKwp', 'annualOmEscalationRate'],
		}),
		[],
	);

	return { defaultValues, sectionFields };
};

export default useEstimateOverlayValues;
