import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import colors from 'Application/theme/colors';

import Loader from 'Common/components/Loader';
import Button from 'Common/components/buttons/Button';
import useHoverMarker from 'Common/hooks/useHoverMarker';

import ProjectSection from 'PMWorkflow/components/Project/DashboardProject/DashboardProject';

import { parse as parseQueryString } from 'qs';
import queryParamsDecoder from 'Common/utils/queryParamsDecoder';
import { useLocation } from 'react-router-dom';
import useReponsive from 'Common/hooks/useResponsive';
import { css } from 'styled-components';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';

const Section = styled.div`
	padding: ${({ $isMobile }) => ($isMobile ? '12px 8px' : '12px 16px')};
	position: relative;
	height: 100%;

	${({ $isMobile }) =>
		$isMobile &&
		css`
			* {
				font-size: 13px;
			}
		`}
`;

const ProjectsContainer = styled.div`
	position: relative;
`;

const SectionInfoText = styled.div`
	color: ${colors.text.primary};
	padding-bottom: 12px;
	font-size: 14px;
`;

const SectionButton = styled.div`
	margin-top: 24px;
`;

const NoProjects = styled.div`
	margin: 0 auto;
	width: fit-content;
	font-size: 25px;
	padding-top: 20px;
`;

const DashboardTab = ({ helpText, getterFunction, singleProjectGetter, showResponsiblePeople, ...props }) => {
	const { t } = useTranslation();
	const { isMobile } = useReponsive();
	const markerContainerRef = useRef();
	const [state, setState] = useState({
		loading: true,
		loadingMore: false,
		data: [],
		page: 0,
		totalElements: 0,
	});
	const location = useLocation();
	const query = useMemo(
		() =>
			parseQueryString(location?.search, {
				ignoreQueryPrefix: true,
				arrayLimit: 50,
				decoder: queryParamsDecoder,
			}),
		[location?.search],
	);
	const filtersFromQuery = useMemo(() => query?.filters ?? {}, [query]);

	const abortController = useAbortController();

	useHoverMarker(markerContainerRef, 'dashboard-section-marker');

	const updateProject = useCallback(
		async projectId => {
			if (singleProjectGetter) {
				try {
					const response = await singleProjectGetter(abortController.signal, projectId);

					setState(prevState => {
						const projectInd = prevState.data.findIndex(project => project.id === projectId);
						return {
							...prevState,
							data: [...prevState.data.slice(0, projectInd), response.data, ...prevState.data.slice(projectInd + 1)],
						};
					});
				} catch (e) {
					showToastError(e, t("Can't update project data"));
				}
			}
		},
		[abortController.signal, singleProjectGetter, t],
	);

	const loadMoreProjects = async () => {
		setState(prevState => ({
			...prevState,
			loadingMore: true,
		}));

		try {
			const response = await getterFunction(abortController.signal, filtersFromQuery, state.page + 1);

			setState(prevState => ({
				...prevState,
				data: [...prevState.data, ...response.data.content],
				page: state.page + 1,
				loadingMore: false,
			}));
		} catch (err) {
			showToastError(err);
		}
	};

	const updateDashboard = useCallback(async () => {
		setState(prevState => ({
			...prevState,
			loading: true,
		}));

		try {
			const response = await getterFunction(abortController.signal, filtersFromQuery, 0);

			setState(prevState => ({
				...prevState,
				data: response.data.content,
				totalElements: response.data.totalElements,
				loading: false,
			}));
		} catch (err) {
			showToastError(err);
		}
	}, [getterFunction, filtersFromQuery, abortController.signal]);

	useEffect(() => {
		updateDashboard();
	}, [updateDashboard]);

	return (
		<Section ref={markerContainerRef} $isMobile={isMobile}>
			{state.loading ? (
				<Loader />
			) : state.totalElements === 0 ? (
				<NoProjects>You don&apos;t have any projects with open steps assigned to you</NoProjects>
			) : (
				<>
					<SectionInfoText>{helpText}</SectionInfoText>
					{state.data.map(project => (
						<ProjectsContainer key={project.id}>
							<ProjectSection
								showSteps={true}
								project={project}
								updateProject={updateProject}
								showResponsiblePeople={showResponsiblePeople}
								{...props}
							/>
						</ProjectsContainer>
					))}
					{state.data.length !== state.totalElements && (
						<SectionButton>
							<Button
								text={t('Show More')}
								onClick={loadMoreProjects}
								isLoading={state.loadingMore}
								label="Dashboard - Show More Button"
							/>
						</SectionButton>
					)}
				</>
			)}
		</Section>
	);
};

DashboardTab.defaultProps = {
	showResponsiblePeople: false,
	singleProjectGetter: null,
};

DashboardTab.propTypes = {
	helpText: PropTypes.string.isRequired,
	getterFunction: PropTypes.func.isRequired,
	singleProjectGetter: PropTypes.func,
	showResponsiblePeople: PropTypes.bool,
};

export default DashboardTab;
