import { useCallback, useEffect, useMemo, useState } from 'react';

import crudModes from 'Common/constants/crudModes';

import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import axios from 'axios';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import OverlaySections from 'Common/components/modals/OverlaySections';
import useClientContractOverlayValues from './hooks/useClientContractOverlayValues';
import useClientContractOverlaySections from './hooks/useClientContractOverlaySections';
import useClientContractOverlayValidationSchema from './hooks/useClientContractOverlayValidationSchema';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import convertClientContractValuesToRequestData from './utils/convertClientContractValuesToRequestData';
import { findProjectPrimaryOffer } from 'Projects/utils/salesHelpers';
import getClientContractById from '../../api/getClientContractById';
import createClientContract from '../../api/createClientContract';
import editClientContract from '../../api/editClientContract';
import { useOverlayTitles } from 'Common/components/modals/Overlay';
// import ClientContractOverlayActions from './Actions/ClientContractOverlayActions';
import uploadClientContractDocuments from '../../../ClientContract/api/uploadClientContractDocuments';

const ClientContractOverlay = ({ mode, isOpen, onClose, onFormSubmit, project, contractId }) => {
	const [contract, setClientContract] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();

	const abortController = useAbortController();

	const { name, label, saveBtnLabel, closeBtnLabel } = useOverlayTitles('SaaS Contract', mode);

	useEffect(() => {
		(async () => {
			if (mode === crudModes.CREATE) return;

			setIsLoading(true);
			try {
				const response = await getClientContractById(abortController.signal, contractId, true);

				setClientContract(response.data);
				setIsLoading(false);
			} catch (error) {
				showToastError(error);
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		})();
	}, [abortController.signal, mode, contractId]);

	const breadcrumbList = [
		{
			label: t('Sales'),
			path: `/projects/details/${project.id}`,
		},
		{
			label: name,
		},
	];

	const primaryOfferValues = useMemo(() => {
		const primaryClientOffer = findProjectPrimaryOffer(project.epcOffers);
		const primaryEpcOffer = project.epcOffers.find(offer => offer.primaryOffer);

		return {
			epcPartner: primaryEpcOffer?.epcPartner,
			...primaryClientOffer,
		};
	}, [project.epcOffers]);

	const { defaultValues, sectionFields } = useClientContractOverlayValues(
		mode,
		contract,
		primaryOfferValues,
		project,
	);
	const sections = useClientContractOverlaySections(project.country.id, mode, breadcrumbList);
	const validationSchema = useClientContractOverlayValidationSchema();

	const handleCreateSubmit = useCallback(
		async values => {
			try {
				const clientContract = await createClientContract(
					abortController.signal,
					convertClientContractValuesToRequestData(values, project),
				);

				if (values.document) {
					const formData = new FormData();
					formData.append('document', values.document);
					await uploadClientContractDocuments(abortController.signal, formData, clientContract.data.id);
				}

				await onFormSubmit();

				notify(t('SaaS Contract created successfully'), {
					type: toast.TYPE.SUCCESS,
				});

				if (values.saasSignatureDate) {
					notify(t('SaaS Contract signed successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				}
				onClose();
			} catch (error) {
				showToastError(error, "Couldn't create SaaS Contract");

				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
		[abortController.signal, project, onClose, onFormSubmit, t],
	);

	const handleEditSubmit = useCallback(
		async values => {
			try {
				await editClientContract(
					abortController.signal,
					contractId,
					convertClientContractValuesToRequestData(values, project),
				);

				if (values.document) {
					const formData = new FormData();
					formData.append('document', values.document);
					await uploadClientContractDocuments(abortController.signal, formData, contractId);
				}

				await onFormSubmit();

				notify(t('SaaS Contract saved successfully'), {
					type: toast.TYPE.SUCCESS,
				});

				if (values.saasSignatureDate) {
					notify(t('SaaS Contract signed successfully'), {
						type: toast.TYPE.SUCCESS,
					});
				}
				onClose();
			} catch (error) {
				showToastError(error, 'SaaS Contract could not be saved');
				if (!axios.isCancel(error)) {
					setIsLoading(false);
				}
			}
		},
		[abortController.signal, project, onClose, contractId, onFormSubmit, t],
	);

	// ? For testing purposes to delete SaaS contracts
	// const onContractDelete = useCallback(async () => {
	// 	await onFormSubmit();
	// 	onClose();
	// }, [onClose, onFormSubmit]);

	// const _ClientContractOverlayActions = useCallback(
	// 	() => <ClientContractOverlayActions onContractDelete={onContractDelete} contractId={contractId} />,
	// 	[onContractDelete, contractId],
	// );

	return (
		<OverlaySections
			label={label}
			breadcrumbList={breadcrumbList}
			isOpen={isOpen}
			onClose={onClose}
			onSaveOverlay={mode === crudModes.CREATE ? handleCreateSubmit : handleEditSubmit}
			isLoadingContent={isLoading}
			sections={sections}
			// headerContent={_ClientContractOverlayActions}
			defaultValues={defaultValues}
			sectionFields={sectionFields}
			validationSchema={validationSchema}
			mode={mode}
			saveBtnLabel={saveBtnLabel}
			closeBtnLabel={closeBtnLabel}
		/>
	);
};

ClientContractOverlay.defaultProps = {
	mode: crudModes.CREATE,
	isOpen: false,
	onClose: () => {},
	onFormSubmit: () => {},
	project: {},
	contractId: null,
};

ClientContractOverlay.propTypes = {
	mode: PropTypes.oneOf([crudModes.CREATE, crudModes.EDIT, crudModes.VIEW]),
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onFormSubmit: PropTypes.func,
	project: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		country: PropTypes.arrayOf(PropTypes.shape({})),
		epcOffers: PropTypes.arrayOf(PropTypes.shape({})),
	}),
	contractId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ClientContractOverlay;
