import styled from 'styled-components/macro';
import FormikInput from 'Common/components/form/FormikInput';
import { omit } from 'lodash';
import TableField from 'Common/components/form/Table/TableField';

const StyledFormikInput = styled(FormikInput)`
	height: 100%;
	border-bottom: none !important;
	padding-left: 8px;
	padding-right: 8px;
	font-size: 14px;
`;

const TableInput = props => (
	<TableField {...props}>
		<StyledFormikInput {...omit(props, ['label'])} hideErrors />
	</TableField>
);

export default TableInput;
