import api from 'Application/api/api';

const generateInvoice = async (signal, invoiceId) => {
	const response = await api.get(`/invoices/${invoiceId}`, {
		signal,
	});

	if (!response || !response.data) {
		return response;
	}

	return response;
};

export default generateInvoice;
