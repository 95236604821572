import { useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Icon from 'Common/components/icons/Icon';

import { useFormik } from 'formik';
import notify from 'Common/utils/notify';
import Link from 'Common/components/Link';
import Button from 'Common/components/buttons/Button';
import FormField from 'Common/components/form/FormField';
import FormikInput from 'Common/components/form/FormikInput';

import login from 'User/services/login';
import logout from 'User/services/logout';
import useReLoginPopupFormValidationSchema from 'User/components/ReLoginPopup/ReLoginPopupForm/hooks/useReLoginPopupFormValidationSchema';
import useReLoginPopupFormInitialValues from 'User/components/ReLoginPopup/ReLoginPopupForm/hooks/useReLoginPopupFormInitialValues';

import colors from 'Application/theme/colors';
import useAbortController from 'Common/hooks/useAbortController';

const ButtonWrapper = styled.div`
	display: flex;
	height: 100px;
	align-items: flex-end;
	justify-content: flex-end;
	padding-top: 111px;
`;

const IconContainer = styled.div`
	position: absolute;
	right: 5px;
	bottom: 5px;
`;

const FormFieldContainer = styled(FormField)`
	position: relative;
`;

const ReLoginPopupForm = () => {
	const { t } = useTranslation();
	const [showPassword, setShowPassword] = useState(false);
	const email = useSelector(state => state.user.email);

	const abortController = useAbortController();

	const initialValues = useReLoginPopupFormInitialValues({});
	const validationSchema = useReLoginPopupFormValidationSchema();

	const { errors, touched, values, handleSubmit, handleChange, handleBlur } = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async values => {
			try {
				await login(abortController.signal, email, values.password, false);
				window.location.reload(); //used to re-render component after session expire, think of better way to do this
			} catch (error) {
				notify(t('Invalid password'), {
					type: toast.TYPE.ERROR,
				});
			}
		},
	});

	const handleLogMeOutButtonClick = () => {
		// noinspection JSIgnoredPromiseFromCall
		logout();
	};

	return (
		<form onSubmit={handleSubmit}>
			<FormFieldContainer>
				<FormikInput
					id="password"
					name="password"
					inputType={showPassword ? 'text' : 'password'}
					label={t('Password')}
					value={values.password}
					error={errors.password}
					touched={touched.password}
					onChange={handleChange}
					onBlur={handleBlur}
					placeholder="Password"
				/>
				<IconContainer>
					<Icon
						icon="removeRedEyeOutlined"
						color={showPassword ? colors.primary.main : ''}
						onClick={() => setShowPassword(prev => !prev)}
					/>
				</IconContainer>
			</FormFieldContainer>
			<Link to="/login" onClick={handleLogMeOutButtonClick}>
				{t('back to the log in')}
			</Link>
			<ButtonWrapper>
				<Button
					overlay
					type="submit"
					text={t('Log in')}
					icon="exitToAppRounded"
					label="Expired Session - Log in Button"
				/>
			</ButtonWrapper>
		</form>
	);
};

export default ReLoginPopupForm;
