import { useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import MoreActionsButton from 'Common/components/buttons/MoreActionsButton';
import MenuItem from 'Common/components/buttons/MenuItemButton';
import QuestionTooltip from 'Common/components/tooltip/QuestionTooltip';
import TileActionButton from 'Common/components/Tile/TileActionButton';
import HoverTooltip from 'Common/components/tooltip/HoverTooltip';
import { PropTypes } from 'prop-types';
import crudModes from 'Common/constants/crudModes';
import EPCOfferOverlay from '../Overlays/EPCOffer/EPCOfferOverlay';
import { EPC_OFFER_TYPES } from '../constants';
import CollapseButton from 'Common/components/buttons/CollapseButton';
import sizes from 'Application/theme/sizes';
import colors from 'Application/theme/colors';

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: ${sizes.spacing(1)};
`;

const DisabledButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const TooltipHeading = styled.p`
	font-weight: 600;
`;

const createModals = {
	ESTIMATE: 'ESTIMATE',
	INQUIRY: 'INQUIRY',
	PROPOSAL: 'PROPOSAL',
};

const StyledSpan = styled.div`
	color: ${colors.common.black};
`;

const VisibleSectionsCount = styled.span`
	font-weight: 700;
	color: ${colors.secondary.dark};
`;

const TotalSectionCount = styled.span`
	color: ${colors.text.grey};
`;

const OfferCreationTooltipText = () => (
	<>
		<TooltipHeading>Estimate</TooltipHeading>
		<p>
			Create an Estimate to save assumed values for the construction of the Project.
			<br /> The estimated values can be used to create and send a Client offer before an EPC Partner have
			provided a proposal.
		</p>
		<TooltipHeading>EPC proposal</TooltipHeading>
		<p>
			Manually create an EPC proposal to save values for the construction of a Project received after contacting
			an EPC Partner.
			<br /> If an RFEP is sent to EPC Partners using pulse, an EPC proposal will be created automatically when
			each EPC responds to the RFEP.
		</p>
		<TooltipHeading>EPC inquiry</TooltipHeading>
		<p>
			If an EPC contacts ecoligo for finance options for the Project, create an EPC inquiry and record the Client
			offer provided to them.
		</p>
	</>
);

const EPCOfferTileActions = ({
	data,
	onSubmit,
	isAuthorized,
	isCollapsed,
	showCollapseButton,
	handleToggleCollapse,
	primaryOffersCount,
	totalOffersCount,
}) => {
	const { t } = useTranslation();

	const [currentCreateModalOpened, setCurrentCreateModalOpened] = useState(null);

	const isCreationBlockedByOtherContracts =
		data?.clientContracts?.length > 0 || data?.epcContracts?.length > 0;

	const missingPermission = !isAuthorized;

	const canCreateEpcProposal = data?.salesChannel?.name !== 'EPC Partner';

	const createButtonTooltipText = useMemo(() => {
		if (isCreationBlockedByOtherContracts) {
			return 'A SaaS contract or EPC contract exists for this Project. Use the Contracts tab enter contract details, or delete the contract details to create an Offer.';
		}
		if (missingPermission) {
			return 'You do not have the required permissions';
		}
		return '';
	}, [isCreationBlockedByOtherContracts, missingPermission]);

	return (
		<ButtonWrapper>
			{showCollapseButton && (
				<>
					<StyledSpan>
						<VisibleSectionsCount>{isCollapsed ? primaryOffersCount : totalOffersCount} </VisibleSectionsCount>/
						<TotalSectionCount> {totalOffersCount}</TotalSectionCount>
					</StyledSpan>
					<CollapseButton isCollapsed={isCollapsed} onClick={handleToggleCollapse} />
				</>
			)}
			<HoverTooltip placement="top" title={createButtonTooltipText ?? ''} arrow>
				<div>
					<MoreActionsButton
						disabled={isCreationBlockedByOtherContracts || missingPermission}
						CustomButton={() =>
							isCreationBlockedByOtherContracts || missingPermission ? (
								<DisabledButtonWrapper>
									<TileActionButton disabled />
								</DisabledButtonWrapper>
							) : (
								<div>
									<TileActionButton disabled={missingPermission} />
								</div>
							)
						}
						label="Projects Details Offers Create EPC More Actions"
					>
						<MenuItem
							onClick={() => setCurrentCreateModalOpened(createModals.ESTIMATE)}
							type="button"
							data-action="createEstimate"
							label="Projects Details Offers Create EPC - Estimate Menu Item"
						>
							{t('Estimate')}
						</MenuItem>
						<MenuItem
							disabled={!canCreateEpcProposal}
							onClick={() => setCurrentCreateModalOpened(createModals.PROPOSAL)}
							tooltip={
								!canCreateEpcProposal ? 'Cannot create EPC proposals when the Sales channel is EPC Partner' : null
							}
							tooltipplacement="right"
							type="button"
							data-action="createProposal"
							label="Projects Details Offers Create EPC - EPC Proposal Menu Item"
						>
							<span>{t('EPC proposal')}</span>
						</MenuItem>
						<MenuItem
							onClick={() => setCurrentCreateModalOpened(createModals.INQUIRY)}
							type="button"
							data-action="createInquiry"
							label="Projects Details Offers Create EPC - EPC inquiry Menu Item"
						>
							{t('EPC inquiry')}
						</MenuItem>
					</MoreActionsButton>
				</div>
			</HoverTooltip>
			<QuestionTooltip large tooltip={<OfferCreationTooltipText />} />
			<EPCOfferOverlay
				type={EPC_OFFER_TYPES.ESTIMATE}
				mode={crudModes.CREATE}
				isOpen={currentCreateModalOpened === createModals.ESTIMATE}
				onClose={() => setCurrentCreateModalOpened(null)}
				onFormSubmit={onSubmit}
				project={data}
			/>
			<EPCOfferOverlay
				type={EPC_OFFER_TYPES.INQUIRY}
				mode={crudModes.CREATE}
				isOpen={currentCreateModalOpened === createModals.INQUIRY}
				onClose={() => setCurrentCreateModalOpened(null)}
				onFormSubmit={onSubmit}
				project={data}
			/>
			<EPCOfferOverlay
				type={EPC_OFFER_TYPES.PROPOSAL}
				mode={crudModes.CREATE}
				isOpen={currentCreateModalOpened === createModals.PROPOSAL}
				onClose={() => setCurrentCreateModalOpened(null)}
				onFormSubmit={onSubmit}
				project={data}
			/>
		</ButtonWrapper>
	);
};

EPCOfferTileActions.defaultProps = {
	data: {},
	onSubmit: () => {},
	isAuthorized: false,
	isCollapsed: false,
	handleToggleCollapse: () => {},
	showCollapseButton: false,
};

EPCOfferTileActions.propTypes = {
	data: PropTypes.shape({
		clientContracts: PropTypes.arrayOf(PropTypes.shape({})),
		epcContracts: PropTypes.arrayOf(PropTypes.shape({})),
		epcOffers: PropTypes.arrayOf(PropTypes.shape({})),
		salesChannel: PropTypes.shape({
			name: PropTypes.string,
		}),
		projectType: PropTypes.shape({ name: PropTypes.string }),
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		systemSizeKwp: PropTypes.number,
	}),
	onSubmit: PropTypes.func,
	isAuthorized: PropTypes.bool,
	isCollapsed: PropTypes.bool,
	handleToggleCollapse: PropTypes.func,
	showCollapseButton: PropTypes.bool,
	primaryOffersCount: PropTypes.number.isRequired,
	totalOffersCount: PropTypes.number.isRequired,
};

export default EPCOfferTileActions;
