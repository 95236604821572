import formatDateForRequestData from './formatDateForRequestData';

const formatDateAsISO = date => {
	// !!! toISOString() is one day off fix
	// !!! Don't use this method in filters !!!
	// Fixes https://infoleven.atlassian.net/browse/EC-5357, https://infoleven.atlassian.net/browse/EC-5339, https://infoleven.atlassian.net/browse/EC-5258
	// For more info please refer to https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
	return new Date(formatDateForRequestData(date)).toISOString();
};
export default formatDateAsISO;
