import { makePropGetter, useGetLatest } from 'react-table';

const pluginName = 'useAdditionalTableProps';

export const useAdditionalTableProps = hooks => {
	hooks.getTableHeaderProps = [props => ({ ...props })];
	hooks.getTableFooterProps = [props => ({ ...props })];

	hooks.getHeaderCellContentProps = [props => ({ ...props })];
	hooks.getCellContentProps = [props => ({ ...props })];
	hooks.getFooterCellContentProps = [props => ({ ...props })];

	// eslint-disable-next-line
	hooks.useInstance.push(useInstance);
	hooks.prepareRow.push(prepareRow);
};

useAdditionalTableProps.pluginName = pluginName;

const useInstance = instance => {
	const { flatHeaders, getHooks } = instance;

	const getInstance = useGetLatest(instance);

	instance.getTableHeaderProps = makePropGetter(getHooks().getTableHeaderProps, {
		instance: getInstance(),
	});

	instance.getTableFooterProps = makePropGetter(getHooks().getTableFooterProps, {
		instance: getInstance(),
	});

	// Give columns/headers a default getHeaderCellContentProps
	flatHeaders.forEach(column => {
		column.getHeaderCellContentProps = makePropGetter(getHooks().getHeaderCellContentProps, {
			instance: getInstance(),
			column,
		});
	});

	flatHeaders.forEach(column => {
		column.getFooterCellContentProps = makePropGetter(getHooks().getFooterCellContentProps, {
			instance: getInstance(),
			column,
		});
	});
};

const prepareRow = (row, { instance }) => {
	const { getHooks } = instance;

	row.allCells.forEach(cell => {
		cell.getCellContentProps = makePropGetter(getHooks().getCellContentProps, {
			instance,
			cell,
		});
	});
};
