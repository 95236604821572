import { useTranslation } from 'react-i18next';
import deleteContact from 'Contacts/api/deleteContacts';
import ConfirmModal from 'Common/components/modals/ConfirmModal';
import useAbortController from 'Common/hooks/useAbortController';
import notify from 'Common/utils/notify';
import { toast } from 'react-toastify';
import showToastError from 'Common/utils/showToastError';
import PropTypes from 'prop-types';

const DeleteContactModal = ({ isOpen, onClose, onFormSubmit, contact }) => {
	const { t } = useTranslation();
	const abortController = useAbortController();

	const handleDeleteContact = async () => {
		try {
			await deleteContact(abortController.signal, contact.id);
			onFormSubmit();
			onClose();

			notify(t(`Contact ${contact.firstName} ${contact.lastName} deleted successfully`), {
				type: toast.TYPE.SUCCESS,
			});
		} catch (error) {
			showToastError(error, t("Can't delete client contact"));
		}
	};

	return (
		<ConfirmModal
			isOpen={isOpen}
			onCancel={onClose}
			onConfirm={handleDeleteContact}
			confirmText="Delete"
			label="delete-contact-confirm"
			heading={t('Delete contact')}
			text={t(`Are you sure you want to delete Contact ${contact?.firstName} ${contact?.lastName}?`)}
		/>
	);
};

DeleteContactModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	contact: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
	}).isRequired,
};

export default DeleteContactModal;
