import api from 'Application/api/api';

const getInquiries = (signal, configuration, filters = {}, searchQuery) => {
	const { sortBy = 'dateModified', sortDirection = 'DESC' } = configuration;

	const params = {
		...filters,
		...configuration,
		query: searchQuery,
	};

	// if (page) {
	//     params.page = page - 1;
	// }

	// if (itemsPerPage) {
	//     params.size = itemsPerPage;
	// }

	if (sortBy && sortDirection) {
		params.sort = `${sortBy},${sortDirection}`;
	}

	return api.get('/inquiries/get-pageable', {
		params,
		signal,
		timeout: 100000,
	});
};

export default getInquiries;
