import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TilesGridWrapper from 'Common/components/Tile/TilesGridWrapper';
import EpcPartnerDetailsTile from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/EpcPartnerDetailsTile/EpcPartnerDetailsTile';
import AddressTile from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/AddressTile/AddressTile';
import QualificationsTile from 'EpcPartner/components/EpcPartnerDetails/Tabs/GeneralTab/Tiles/QualificationsTile/QualificationsTile';
import AccountManagersTile from './Tiles/AccountManagersTile/AccountManagersTile';
import { EPC_PARTNER_DETAILS_PAGE_TAB_TILES } from 'EpcPartner/constants/epcPartnerTabForms';

const GeneralTab = ({
	epcPartner,
	onEnterEditMode,
	onExitEditMode,
	onDataChange,
	onDirtyForm,
	setEditedEpcPartner,
}) => {
	const handleEpcPartnerDetailsDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(EPC_PARTNER_DETAILS_PAGE_TAB_TILES.GENERAL.DETAILS, isDirty);
		},
		[onDirtyForm],
	);

	const handleAddressDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(EPC_PARTNER_DETAILS_PAGE_TAB_TILES.GENERAL.ADDRESS, isDirty);
		},
		[onDirtyForm],
	);

	const handleQualificationsDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(EPC_PARTNER_DETAILS_PAGE_TAB_TILES.GENERAL.QUALIFICATIONS, isDirty);
		},
		[onDirtyForm],
	);

	const handleAccountManagersDirtyForm = useCallback(
		isDirty => {
			onDirtyForm(EPC_PARTNER_DETAILS_PAGE_TAB_TILES.GENERAL.MANAGERS, isDirty);
		},
		[onDirtyForm],
	);

	return (
		<TilesGridWrapper>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<EpcPartnerDetailsTile
					epcPartner={epcPartner}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={onDataChange}
					onDirtyForm={handleEpcPartnerDetailsDirtyForm}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<AddressTile
					epcPartner={epcPartner}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={onDataChange}
					onDirtyForm={handleAddressDirtyForm}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<QualificationsTile
					epcPartner={epcPartner}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={onDataChange}
					onDirtyForm={handleQualificationsDirtyForm}
					setEditedEpcPartner={setEditedEpcPartner}
				/>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
				<AccountManagersTile
					epcPartner={epcPartner}
					onEnterEditMode={onEnterEditMode}
					onExitEditMode={onExitEditMode}
					onSave={onDataChange}
					onDirtyForm={handleAccountManagersDirtyForm}
				/>
			</Grid>
		</TilesGridWrapper>
	);
};

GeneralTab.defaultProps = {
	onEnterEditMode: () => null,
	onExitEditMode: () => null,
	onDirtyForm: () => null,
	setEditedEpcPartner: () => null,
};

GeneralTab.propTypes = {
	epcPartner: PropTypes.shape({}).isRequired,
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDataChange: PropTypes.func.isRequired,
	onDirtyForm: PropTypes.func,
	setEditedEpcPartner: PropTypes.func,
};

export default GeneralTab;
