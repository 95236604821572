import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import colors from 'Application/theme/colors';
import ButtonLink from 'Common/components/buttons/ButtonLink';
import EpcInquiriesListTable from 'EpcPartner/components/EpcPartnerPortal/EpcInquiriesList/EpcInquiriesListTable';
import HeaderContainer from 'Common/components/PageHeader/HeaderContainer';
import HeaderActions from 'Common/components/PageHeader/HeaderActions';

const Title = styled.div`
	font-size: 26px;
	font-weight: 600;
	color: ${colors.text.primary};
	text-transform: uppercase;
`;

const EpcInquiriesList = () => {
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<title>{t('Inquiries')}</title>
			</Helmet>
			<HeaderContainer>
				<Title>{t('Inquiries')}</Title>
				<HeaderActions>
					<ButtonLink
						label="Create EPC Partner - Inquiries"
						to="/epc/inquiries/create"
						text={t('Create Inquiry')}
						icon="addRounded"
					/>
				</HeaderActions>
			</HeaderContainer>

			<EpcInquiriesListTable />
		</>
	);
};

export default EpcInquiriesList;
