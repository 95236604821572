import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import { roles } from 'User/constants/roles';
import isAuthorized from 'User/utils/isAuthorized';

import EditableTile from 'Common/components/Tile/EditableTile';
import FormField from 'Common/components/form/FormField';
import FormikSelect from 'Common/components/form/FormikSelect';

import editProject from 'Projects/api/editProject';
import loadContractPartiesOptions from 'Projects/utils/loadContractPartiesOptions';
import useProjectEntitiesFormInitialValues from 'Projects/components/ProjectDetails/Tabs/ProjectTab/Tiles/ProjectEntitiesTile/hooks/useProjectEntitiesFormInitialValues';
import showToastError from 'Common/utils/showToastError';
import useAbortController from 'Common/hooks/useAbortController';
import { PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS } from 'PMWorkflow/constants/PMWorkflowTiles';
import axios from 'axios';
import useDynamicLoadedOptions from 'Common/components/form/hooks/useDynamicLoadedOptions';

const ProjectEntitiesTile = ({
	project,
	onEnterEditMode,
	onExitEditMode,
	onDirtyForm,
	onTileSave,
	highlightLabels,
}) => {
	const { t } = useTranslation();

	const abortController = useAbortController();

	const isAuthorizedToEdit = isAuthorized([
		roles.ADMIN,
		roles.FINANCE,
		roles.PM_TECHNICAL,
		roles.SALES,
		roles.MANAGEMENT,
	]);

	const [isInEditMode, setIsInEditMode] = useState(false);
	const [isSavingChanges, setIsSavingChanges] = useState(false);

	const initialValues = useProjectEntitiesFormInitialValues(project);

	const { errors, touched, values, handleSubmit, dirty, handleBlur, setFieldValue, resetForm } = useFormik({
		initialValues,
		onSubmit: async values => {
			setIsSavingChanges(true);
			try {
				let response = await editProject(abortController.signal, {
					...project,
					clientId: project.client?.id,
					regionalHubId: project.regionalHub?.id || null,
					contractPartyByType: {
						EPC: values.epc ? values.epc.value : undefined,
						SaaS: values.saas ? values.saas.value : undefined,
						'Transmitted Load': values.transmittedLoad ? values.transmittedLoad.value : undefined,
					},
				});

				onDirtyForm('projectEntities', false);

				onTileSave(response.data);
				handleCancel();
				setIsSavingChanges(false);
				resetForm({ values });
			} catch (e) {
				showToastError(e);
				if (!axios.isCancel(e)) {
					handleCancel();
					setIsSavingChanges(false);
				}
			}
		},
	});

	const handleEditButtonClick = () => {
		setIsInEditMode(true);

		if (onEnterEditMode) {
			onEnterEditMode();
		}
	};

	const handleCancel = () => {
		setIsInEditMode(false);
		resetForm({ values: initialValues });
		if (onDirtyForm) {
			onDirtyForm(false);
		}

		if (onExitEditMode) {
			onExitEditMode();
		}
	};

	const handleTileClick = () => {
		if (!isInEditMode && isAuthorizedToEdit) {
			handleEditButtonClick();
		}
	};

	useEffect(() => {
		if (onDirtyForm) {
			onDirtyForm(dirty);
		}
	}, [dirty, onDirtyForm]);

	const handleSetFieldValue = (name, value) => {
		if (!values.epc && !values.saas && !values.transmittedLoad) {
			setFieldValue('epc', value);
			setFieldValue('saas', value);
			setFieldValue('transmittedLoad', value);
		} else {
			setFieldValue(name, value);
		}
	};

	const loadContractPartiesOptionsFilteredByCountry = useCallback(
		(signal, inputValue) =>
			loadContractPartiesOptions(signal, inputValue, project?.country?.name, {
				countriesIds: [project?.country?.id],
			}),
		[project?.country?.id, project?.country?.name],
	);

	const {
		isLoading: isLoadingContractPartyOptions,
		// options: contractPartyOptions,
		onSearch,
	} = useDynamicLoadedOptions(loadContractPartiesOptionsFilteredByCountry);
	return (
		<EditableTile
			title={t('Entities')}
			isLoading={isSavingChanges}
			isInEditMode={isInEditMode}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			onClick={handleTileClick}
		>
			<FormField>
				<FormikSelect
					isAsync
					id="epc"
					name="epc"
					label={t('EPC entity')}
					value={values.epc}
					error={errors.epc}
					touched={touched.epc}
					isClearable
					setFieldValue={handleSetFieldValue}
					onBlur={handleBlur}
					// options={contractPartyOptions}
					loadOptions={() => loadContractPartiesOptionsFilteredByCountry()}
					isLoading={isLoadingContractPartyOptions}
					onSearch={onSearch}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_ENTITIES.EPC)}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					isAsync
					id="saas"
					name="saas"
					label={t('SaaS entity')}
					value={values.saas}
					error={errors.saas}
					touched={touched.saas}
					isClearable
					setFieldValue={handleSetFieldValue}
					onBlur={handleBlur}
					loadOptions={() => loadContractPartiesOptionsFilteredByCountry()}
					isLoading={isLoadingContractPartyOptions}
					onSearch={onSearch}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_ENTITIES.SAAS)}
				/>
			</FormField>
			<FormField>
				<FormikSelect
					isAsync
					id="transmittedLoad"
					name="transmittedLoad"
					label={t('Transmitted loan entity')}
					value={values.transmittedLoad}
					error={errors.transmittedLoad}
					touched={touched.transmittedLoad}
					isClearable
					setFieldValue={handleSetFieldValue}
					onBlur={handleBlur}
					loadOptions={() => loadContractPartiesOptionsFilteredByCountry()}
					isLoading={isLoadingContractPartyOptions}
					onSearch={onSearch}
					isTile
					isInEditMode={isInEditMode}
					isHighlighted={highlightLabels.includes(
						PM_WORKFLOW_TILES_HIGHLIGHT_FIELDS_KEYS.PROJECT_ENTITIES.TRANSMITTED_LOAD,
					)}
				/>
			</FormField>
		</EditableTile>
	);
};

ProjectEntitiesTile.defaultProps = {
	project: {},
	onEnterEditMode: undefined,
	onExitEditMode: undefined,
	onDirtyForm: undefined,
	onTileSave: async () => {},
	highlightLabels: [],
};

ProjectEntitiesTile.propTypes = {
	project: PropTypes.shape({
		client: PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		regionalHub: PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		country: PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			name: PropTypes.oneOfType([PropTypes.string, PropTypes.string]),
		}),
	}),
	onEnterEditMode: PropTypes.func,
	onExitEditMode: PropTypes.func,
	onDirtyForm: PropTypes.func,
	onTileSave: PropTypes.func,
	highlightLabels: PropTypes.arrayOf(PropTypes.string),
};

export default ProjectEntitiesTile;
